import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserRoles } from 'api/roles.js';
import Alert from 'components/Alert.jsx';
import Loader from 'components/Loader.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import { checkIPPremission, getUserId, getUserRole } from 'infrastructure/auth.js';
import { UserRole, UserRoles } from 'constants/enums.js';
import { getRegionSubTree } from 'api/tree.js';
import { buildTree, getHealthSystemDevices, getStorage } from 'infrastructure/helpers/commonHelpers.js';
import { actionCreators as healthSystemsActionCreators } from 'state/healthSystems/actions.js';
import { actionCreators as devicesActionCreators } from 'state/devices/actions.js';

const MyUserRoles = props => {
	const [roles, setRoles] = useState([]);
	const [error, setError] = useState('');
	const [isPageLoading, setIsPageLoading] = useState(true);
	const userId = getUserId();
	const userRole = getUserRole();
	const history = useHistory();
	const dispatch = useDispatch();
	const userSession = useSelector(state => state.user.userSession);
	const nurseRoleDisplayName = useSelector(state => state.company.companySettings.nurseDisplayName);

	useEffect(() => {
		const fetchData = async () => {
			const rolesResponse = await getUserRoles(userId);
			if (rolesResponse.error) {
				setError(rolesResponse.error.message);
				return;
			}
			setRoles(rolesResponse.roles);
			setIsPageLoading(false);
		};
		fetchData();
	}, [userId]);

	const getRoles = async () => {
		const rolesResponse = await getUserRoles(userId);
		if (rolesResponse.error) {
			setError(rolesResponse.error.message);
			return;
		}
		setRoles(rolesResponse.roles);
		setIsPageLoading(false);
	};

	const getUserRolesTab = role => {
		switch (role) {
			case UserRoles.DOCTOR:
				return 7;
			case UserRoles.ADMIN:
				return 6;
			case UserRoles.DIGITAL_CLINICIAN:
			case UserRoles.NURSE:
				return 4;
			default:
				return 0;
		}
	};

	const switchRole = async role => {
		getStorage().setItem('userRole', role);
		if (role !== UserRoles.ADMIN) {
			await checkIPPremission();
		}
		getRoles();
		await updateTree();
		history.push('/account-settings');
		props.onTabChange(getUserRolesTab(role));
		props.reloadMainLayout();
	};

	const updateTree = async () => {
		const subTreeResponse = await getRegionSubTree(userSession.healthSystem.id, userSession.regionId);
		if (UserRole.VISITOR === getUserRole()) {
			return;
		}
		if (!subTreeResponse.error) {
			const { healthSystem } = subTreeResponse.organization;
			const treeData = buildTree(healthSystem);
			const { online, busy, privacy, pairedRemote } = getHealthSystemDevices(healthSystem);
			dispatch(devicesActionCreators.setBulkDevicesBusy(busy));
			dispatch(devicesActionCreators.setBulkDevicesOnline(online));
			dispatch(devicesActionCreators.setBulkDevicesPrivacy(privacy));
			dispatch(devicesActionCreators.setBulkPairedRemoteDevice(pairedRemote));
			dispatch(healthSystemsActionCreators.setHealthSystem(healthSystem));
			dispatch(healthSystemsActionCreators.setTreeData(treeData));
		}
	};

	const getRoleDisplayName = role => (role === UserRoles.NURSE ? nurseRoleDisplayName : translate(role.toLowerCase()));

	return (
		<div className='account-settings-inner-wrapper add-role'>
			{isPageLoading && <Loader />}
			{!isPageLoading && (
				<>
					<h4>{translate('myUserRoles')}</h4>
					<div className='flex flex-wrap'>
						{roles.map(role => (
							<div key={role.id} className='flex flex-align-center cursor-pointer account-settings-user-role'>
								<div className='flex-1 full-width flex column-direction'>
									<img
										src={`${healthCareCdnUrl}user-types/${
											userRole !== role.name ? role.name.toLowerCase() : `${role.name.toLowerCase()}-green`
										}.svg?v4`}
										alt='icon'
									/>
									<h1 className={userRole === role.name ? 'current' : ''}>{getRoleDisplayName(role.name)}</h1>
								</div>
								{userRole === role.name && (
									<button type='button' className='user-role-btn'>
										<i className='material-icons-outlined check-icon'>done</i>
										{translate('currentSession')}
									</button>
								)}
								{userRole !== role.name && (
									<button type='button' className='user-role-btn switch-role' onClick={() => switchRole(role.name)}>
										<img src={`${healthCareCdnUrl}forward-arrow-white.svg`} alt='icon' />
										{translate('switch')}
									</button>
								)}
							</div>
						))}
					</div>
					<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
				</>
			)}
		</div>
	);
};

export default MyUserRoles;
