import React from 'react';
import { useIntl } from 'react-intl';
import { MedicalInfoTypes } from 'constants/enums.js';
import { genderItems } from 'constants/genderItems.js';
import translate from 'i18n-translations/translate.jsx';
import { stringToCamelCase } from 'infrastructure/helpers/commonHelpers.js';
import { monthDayYear } from 'infrastructure/helpers/dateHelper.js';

const VisitsReport = props => {
	const intl = useIntl();
	const medicalItem = (prescription, type, optionalText) => (
		<div>
			<p style={{ color: 'var(--gray-5)' }}>{translate(type)}</p>
			<p>
				{prescription[type]} {optionalText && <span>{optionalText}</span>}
			</p>
		</div>
	);
	return (
		<div
			id='doctor-report'
			ref={props.visitReportRef}
			style={{ position: 'fixed', width: '8.5in', minHeight: '11in', zIndex: '-1' }}>
			{props.selectedVisit && (
				<>
					<div>
						<div className='logo'>
							<h3>{translate('medicalReport')}</h3>
						</div>
						<div className='main-info flex'>
							<div>
								<p>{translate('patient')}</p>
								<p>
									{props.userInfo.firstName} {props.userInfo.lastName}
								</p>
							</div>
							<div>
								<p>{translate('dateOfBirth')}</p>
								<p>{monthDayYear(props.userInfo.dateOfBirth)}</p>
							</div>
							<div>
								<p>{translate('sex')}</p>
								<p>{genderItems.find(x => x.genderId === props.userInfo.genderId?.toString())?.text}</p>
							</div>
							<div>
								<p>{translate('doctorFullName')}</p>
								<p>
									{props.selectedVisit?.performer?.firstName} {props.selectedVisit?.performer?.lastName}
								</p>
							</div>
							{props.selectedVisit?.performer?.specialty?.name && (
								<div>
									<p>{translate('specialty')}</p>
									<p>{intl.formatMessage({ id: stringToCamelCase(props.selectedVisit?.performer?.specialty?.name) })}</p>
								</div>
							)}
						</div>
						<div className='main-diagnoses'>
							<div>
								<p>{translate('diagnoses')}</p>
							</div>
							<div className='flex flex-wrap'>
								{props.selectedVisit.diagnoses &&
									props.selectedVisit?.diagnoses?.map(diagnose => (
										<div key={diagnose.id}>
											<p>{diagnose.description}</p>
											<p>{diagnose.code}</p>
										</div>
									))}
								{!props.selectedVisit.diagnoses ||
									(props.selectedVisit.diagnoses?.length === 0 && (
										<div style={{ background: 'none', padding: '0', borderRadius: '0' }}>
											{translate('noAdded', {
												value: intl.formatMessage({ id: MedicalInfoTypes.DIAGNOSES }).toLowerCase(),
											})}
										</div>
									))}
							</div>
						</div>
						<div className='main-prescriptions'>
							<div>
								<p>{translate('prescriptions')}</p>
							</div>
							<div className='flex flex-wrap'>
								{props.selectedVisit.prescriptions &&
									props.selectedVisit.prescriptions.map(prescription => (
										<div key={prescription.id} className='simple-txt-modal-inner prescriptions-modal'>
											<div>
												<h4>{`${prescription.medicine.name} ${prescription?.medicine?.strengthAndForm}`}</h4>
												<div className='flex'>
													<p>
														{parseInt(prescription.dosage, 10) > 1 &&
															translate('capsulesDosage', {
																value: prescription.dosage,
															})}
														{parseInt(prescription.dosage, 10) === 1 &&
															translate('oneCapsule', {
																value: prescription.dosage,
															})}
													</p>
													<p>
														{prescription.frequency} {translate('timesAday')},
													</p>
													<p>
														{translate('for')} {prescription.duration} {translate('daysNo')},
													</p>
													<p>
														{prescription.refills} {translate('refillsLowercase')}
													</p>
												</div>

												<div className='flex'>{medicalItem(prescription, 'patientInstructions')}</div>
												<div className='flex'>{medicalItem(prescription, 'noteToPharmacist')}</div>
											</div>
										</div>
									))}
								{!props.selectedVisit.prescriptions ||
									(props.selectedVisit.prescriptions.length === 0 && (
										<div style={{ background: 'none', padding: '0', borderRadius: '0' }}>
											{translate('noAdded', {
												value: intl.formatMessage({ id: MedicalInfoTypes.PRESCRIPTIONS }).toLowerCase(),
											})}
										</div>
									))}
							</div>
						</div>
						<div className='main-prescriptions'>
							<div>
								<p>{translate('procedures')}</p>
							</div>
							<div className='flex flex-wrap'>
								{props.selectedVisit.procedures &&
									props.selectedVisit.procedures.map(procedure => (
										<div key={procedure.id} className='simple-txt-modal-inner prescriptions-modal'>
											<div>
												<h4>{procedure.code}</h4>
												<p>{procedure.description}</p>
											</div>
										</div>
									))}
								{!props.selectedVisit.procedures ||
									(props.selectedVisit.procedures.length === 0 && (
										<div style={{ background: 'none', padding: '0', borderRadius: '0' }}>
											{translate('noAdded', {
												value: intl.formatMessage({ id: MedicalInfoTypes.PROCEDURES }).toLowerCase(),
											})}
										</div>
									))}
							</div>
						</div>
						<div className='main-notes'>
							<div>
								<p>{translate('notes')}</p>
							</div>
							{props.selectedVisit?.notes &&
								props.selectedVisit?.notes?.map(item => (
									<div key={item.id}>
										<p>{item.title}</p>
										<p>{item.text}</p>
									</div>
								))}
							{!props.selectedVisit.notes ||
								(props.selectedVisit.notes.length === 0 && (
									<div>
										{translate('noAdded', {
											value: intl.formatMessage({ id: MedicalInfoTypes.NOTES }).toLowerCase(),
										})}
									</div>
								))}
						</div>
					</div>
					<div className='doctor-signature'>
						<p>{translate('doctorsStampSignature')}</p>
						<div className='flex'>
							{props.currentSignature && <img alt='signature' src={props.currentSignature} />}
							{props.stamp && <img alt='stamp' src={props.stamp} />}
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default VisitsReport;
