import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { NotificationTypes, UserRoles, AppointmentStatus, CanceledReschedulingType } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import ProfilePicture from 'components/ProfilePicture.jsx';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';
import 'moment/locale/sq.js';
import { getPreferredLanguageLocale } from 'infrastructure/helpers/commonHelpers.js';
import { getUserRole } from 'infrastructure/auth.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';

const notificationsImgBaseUrl = `${healthCareCdnUrl}header/`;

const appointmentStatusList = [
	{
		id: AppointmentStatus.ACCEPTED,
		text: translate('hasAcceptedAppointment'),
		image: 'accept-notification.svg',
	},
	{
		id: AppointmentStatus.PENDING,
		text: translate('pending'),
		image: '',
	},
	{
		id: AppointmentStatus.REJECTED,
		text: translate('hasRejectedAppointment'),
		image: 'reject-notification.svg',
	},
	{
		id: AppointmentStatus.RESCHEDULED,
		text: translate('rescheduled'),
		image: '',
	},
	{
		id: AppointmentStatus.CANCELED,
		text: translate('hasCanceledAppointment'),
		image: 'reject-notification.svg',
	},
];

const NotificationItem = props => {
	const appointmentStartDate = moment
		.utc(props.appointmentStartTime)
		.local()
		.locale(getPreferredLanguageLocale());

	const isToday = appointmentStartDate.isSame(moment(), 'days');

	const notificationItemContent = (src, content) => (
		<>
			<img src={src} alt='icon' />
			<p>{content}</p>
		</>
	);

	const notificationItemSwitch = () => {
		switch (props.type) {
			case NotificationTypes.ASSIGNED_REQUEST: {
				if (getUserRole() === UserRoles.PATIENT) {
					return notificationItemContent(
						`${notificationsImgBaseUrl}accept-notification.svg`,
						translate('hasAcceptedYourRequest', { value: translate('familyPhysician') })
					);
				}
				return notificationItemContent(
					`${notificationsImgBaseUrl}new-req-notification.svg`,
					translate('hasSentRequest', { value: translate('familyPhysician') })
				);
			}

			case NotificationTypes.REQUEST_ACCEPTED: {
				return notificationItemContent(
					`${notificationsImgBaseUrl}accept-notification.svg`,
					translate('hasAcceptedYourRequest', { value: translate('familyPhysician') })
				);
			}
			case NotificationTypes.REQUEST_REJECTED: {
				return notificationItemContent(
					`${notificationsImgBaseUrl}reject-notification.svg`,
					translate('hasRejectedYourRequest', { value: translate('familyPhysician') })
				);
			}
			case NotificationTypes.REQUEST_CANCELED: {
				return notificationItemContent(
					`${notificationsImgBaseUrl}reject-notification.svg`,
					translate('hasCanceledRequest', { value: translate('familyPhysician') })
				);
			}

			case NotificationTypes.APPOINTMENT_CREATED: {
				return notificationItemContent(
					`${notificationsImgBaseUrl}notification-created.svg`,
					<>
						{translate('newAppointmentRequest')} {props.userFirstName} {props.userLastName}{' '}
						{isToday ? translate('atDate') : translate('onDate')} {formattedDate(props.appointmentStartTime)}
					</>
				);
			}
			case NotificationTypes.APPOINTMENT_STATUS_CHANGED: {
				return notificationItemContent(
					`${notificationsImgBaseUrl}${appointmentStatusList.find(item => item.id === props.appointmentStatusId)?.image}`,
					appointmentStatusList.find(item => item.id === props.appointmentStatusId)?.text
				);
			}
			case NotificationTypes.APPOINTMENT_REMINDER: {
				return notificationItemContent(
					`${notificationsImgBaseUrl}notification-created.svg`,
					<>
						{translate('youHaveAppointmentWith')} {props.userFirstName} {props.userLastName}{' '}
						{translate('inMinutesOn', { value: 10 })} &quot;
						{props.place}&quot;
					</>
				);
			}
			case NotificationTypes.APPOINTMENT_UPDATED: {
				return notificationItemContent(
					`${notificationsImgBaseUrl}notification-created.svg`,
					<>
						{translate('youHaveAppointmentRequest')} {props.userFirstName} {props.userLastName} {translate('updated')}{' '}
						{isToday ? translate('atDate') : translate('onDate')} {formattedDate(props.appointmentStartTime)}
					</>
				);
			}
			case NotificationTypes.APPOINTMENT_RESCHEDULED: {
				return notificationItemContent(
					`${notificationsImgBaseUrl}notification-created.svg`,
					<>
						{translate('youHaveAppointmentRequest')} {props.userFirstName} {props.userLastName} {translate('rescheduled')}{' '}
						{isToday ? translate('atDate') : translate('onDate')} {formattedDate(props.appointmentStartTime)}
					</>
				);
			}
			default: {
				return '';
			}
		}
	};

	const isCurrentUserNotADoctor = props.isAppointmentNotification && getUserRole() !== UserRoles.DOCTOR;

	const isRequestAcceptedOrRejected =
		!props.isAppointmentNotification &&
		![NotificationTypes.ASSIGNED_REQUEST, NotificationTypes.REQUEST_CANCELED].includes(props.type);

	return (
		<div className='notification-item' onClick={props.onClick}>
			<div className='flex'>
				<ProfilePicture
					className='doctor-request-img available-doctor-request-img'
					firstName={props.userFirstName}
					lastName={props.userLastName}
					profilePicture={props.profilePicture}
				/>
				<div>
					<h4>
						{(isRequestAcceptedOrRejected || isCurrentUserNotADoctor) && 'Dr.'} {props.userFirstName} {props.userLastName}
					</h4>
					{notificationItemSwitch()}
					{props.type === NotificationTypes.APPOINTMENT_STATUS_CHANGED &&
						props.appointmentStatusId === AppointmentStatus.CANCELED &&
						getUserRole() === UserRoles.PATIENT && (
							<div className='flex'>
								<button
									type='button'
									onClick={() => props.rescheduleAppointment(props.notification, CanceledReschedulingType.RESCHEDULE)}>
									{translate('reSchedule')}
								</button>
								<button
									type='button'
									onClick={() => props.rescheduleAppointment(props.notification, CanceledReschedulingType.CHANGE_DOCTOR)}>
									{translate('changeDoctor')}
								</button>
							</div>
						)}
				</div>
			</div>
			<span>{formattedDate(props.time)}</span>
		</div>
	);
};

NotificationItem.propTypes = {
	type: PropTypes.number.isRequired,
	userFirstName: PropTypes.string.isRequired,
	userLastName: PropTypes.string.isRequired,
};

export default NotificationItem;
