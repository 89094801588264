import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import translate from 'i18n-translations/translate.jsx';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from 'components/Tabs.jsx';
import {
	GeneralAndMenuFeaturesCategories,
	MonitoringFeatureFlagCategories,
	RoundingFeaturesCategories,
	SettingsCategory,
	configurableGeneralAndMenuSettings,
	configurableHelloCareEngagement,
	configurableMonitoringMenu,
	configurableRoundingMenu,
	configurableAISettings,
	configurablePatientTabs,
	AiSettingFeaturesCategory,
	PatientTabsFeaturesCategories,
	configurableUserExperienceSettings,
	UserExperienceFeaturesCategories,
	PatientTabs,
} from 'constants/configurationEnums.js';
import { DeviceListLevel, UserRoles } from 'constants/enums.js';
import { getUserRole } from 'infrastructure/auth.js';
import FeatureFlags from 'components/FeatureFlags.jsx';
import { useSelector } from 'react-redux';
import AiFeatureFlags from 'components/AiFeatureFlags.jsx';
import { getSomeConfigurationsValues } from 'infrastructure/helpers/commonHelpers.js';

const FeatureFlagsCategories = props => {
	const intl = useIntl();
	const isNewExperience = useSelector(state => state.configurations.isNewExperience);
	const companyConfigurations = useSelector(state => state.company.companySettings?.companyConfigurations);

	const roundingRolesList = [
		{ value: UserRoles.NURSE, label: intl.formatMessage({ id: 'virtualCareProvider' }) },
		{ value: UserRoles.DOCTOR, label: intl.formatMessage({ id: 'doctor' }) },
	];

	const customizationRolesList = [
		...roundingRolesList,
		{ value: UserRoles.VIRTUAL_SITTER, label: intl.formatMessage({ id: 'virtualSitter' }) },
		{ value: UserRoles.DIGITAL_CLINICIAN, label: intl.formatMessage({ id: 'digitalClinician' }) },
		{ value: UserRoles.SUPER_USER, label: intl.formatMessage({ id: 'superUser' }) },
	];

	const [currentTab, setCurrentTab] = useState(0);
	const [selectedRole, setSelectedRole] = useState(currentTab === 0 ? customizationRolesList[0] : roundingRolesList[0]);
	const anyPatientTabEnabled = getSomeConfigurationsValues(companyConfigurations, Object.values(PatientTabs));

	const tabList = [
		{ id: 0, title: translate('roleCustomization') },
		{ id: 1, title: translate('monitoring') },
		{ id: 2, title: translate('rounding') },
		{ id: 3, title: translate('helloCareEngagement') },
		{ id: 4, title: translate('aiSettings') },
		{ id: 5, title: translate('userExperience') },
		anyPatientTabEnabled && { id: 6, title: translate('patients') },
	].filter(Boolean);

	const featureFlagsCategories = [
		{
			settingCategory: SettingsCategory.CUSTOMIZATION,
			defaultConfigurations: configurableGeneralAndMenuSettings(selectedRole.value),
			roleList: customizationRolesList,
			categoryOrder: Object.values(GeneralAndMenuFeaturesCategories),
		},
		{
			settingCategory: SettingsCategory.MONITORING,
			defaultConfigurations: configurableMonitoringMenu(getUserRole(), true, isNewExperience),
			categoryOrder: Object.values(MonitoringFeatureFlagCategories),
		},
		{
			settingCategory: SettingsCategory.ROUNDING,
			defaultConfigurations: configurableRoundingMenu(selectedRole.value),
			roleList: roundingRolesList,
			categoryOrder: Object.values(RoundingFeaturesCategories),
		},
		{
			settingCategory: SettingsCategory.HELLO_CARE_ENGAGEMENT,
			defaultConfigurations: configurableHelloCareEngagement(),
		},
		{
			settingCategory: SettingsCategory.AI_SETTINGS,
			defaultConfigurations: configurableAISettings(),
			categoryOrder: Object.values(AiSettingFeaturesCategory),
		},
		{
			settingCategory: SettingsCategory.USER_EXPERIENCE,
			defaultConfigurations: configurableUserExperienceSettings(getUserRole()),
			categoryOrder: Object.values(UserExperienceFeaturesCategories),
		},
		anyPatientTabEnabled && {
			settingCategory: SettingsCategory.PATIENTS,
			defaultConfigurations: configurablePatientTabs(),
			categoryOrder: Object.values(PatientTabsFeaturesCategories),
		},
	].filter(Boolean);

	return (
		<Tabs
			className='feature-flags-categories'
			isVertical={false}
			activeIndex={currentTab}
			onChange={index => {
				setSelectedRole(roundingRolesList[0]);
				setCurrentTab(index);
			}}>
			<TabList>
				{tabList.map(item => (
					<Tab key={item.id}>{item.title}</Tab>
				))}
			</TabList>
			<TabPanels>
				{featureFlagsCategories.map(category => (
					<TabPanel key={category.settingCategory}>
						{category.settingCategory !== SettingsCategory.AI_SETTINGS && (
							<FeatureFlags
								settingCategory={category.settingCategory}
								currentSector={DeviceListLevel.HEALTH_SYSTEM}
								healthSystemId={props.selectedHealthSystem.value}
								levelId={props.selectedHealthSystem.value}
								healthSystems={props.healthSystems}
								setError={props.setError}
								setSelectedHealthSystem={props.setSelectedHealthSystem}
								selectedHealthSystem={props.selectedHealthSystem}
								defaultConfigurations={category.defaultConfigurations}
								roleList={category.roleList}
								setSelectedRole={category.roleList ? setSelectedRole : null}
								selectedRole={category.roleList ? selectedRole : null}
								categoryOrder={category.categoryOrder}
							/>
						)}
						{category.settingCategory === SettingsCategory.AI_SETTINGS && (
							<AiFeatureFlags
								settingCategory={category.settingCategory}
								currentSector={DeviceListLevel.HEALTH_SYSTEM}
								healthSystemId={props.selectedHealthSystem.value}
								levelId={props.selectedHealthSystem.value}
								healthSystems={props.healthSystems}
								setError={props.setError}
								setSelectedHealthSystem={props.setSelectedHealthSystem}
								selectedHealthSystem={props.selectedHealthSystem}
								defaultConfigurations={category.defaultConfigurations}
								roleList={category.roleList}
								setSelectedRole={category.roleList ? setSelectedRole : null}
								selectedRole={category.roleList ? selectedRole : null}
								categoryOrder={category.categoryOrder}
							/>
						)}
					</TabPanel>
				))}
			</TabPanels>
		</Tabs>
	);
};

export default FeatureFlagsCategories;
