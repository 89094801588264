import React from 'react';
import classNames from 'classnames';
import translate from 'i18n-translations/translate.jsx';
import { useIntl } from 'react-intl';
import { WarningIndexes } from 'constants/enums.js';
import { measurementAlert } from 'constants/alerts.js';
import { stringToCamelCase } from 'infrastructure/helpers/commonHelpers.js';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';
import EmptyState from 'components/EmptyState.jsx';

const HealthDataHistory = ({ measurementAlerts, getMeasurementDetails }) => {
	const intl = useIntl();
	return (
		<>
			{measurementAlerts.length > 0 &&
				measurementAlerts.map(item => (
					<div key={item.id} className='patient-request-list-item cursor-pointer' onClick={() => getMeasurementDetails(item.id)}>
						<div className='flex'>
							<div className='flex alert-history-inner-wrapper'>
								<img src={measurementAlert.find(el => el.type === item.measurement.type)?.icon} alt='ico' />
								<p className='alert-history-description column-direction left-align-items left-s'>
									{`${intl.formatMessage({ id: stringToCamelCase(item.measurement.type) })}  ${intl.formatMessage({
										id: 'wasDetected',
									})} ${item.measurement.value} ${item.measurement.unit}`}
									<br />
									<span className='alert-time no-padding no-margin-left'>{formattedDate(item.dateCreated)}</span>
									<br />
									<span
										className={classNames(
											'no-margin-left',
											[WarningIndexes.LOW, WarningIndexes.HIGH].includes(item.type.warningIndex) ? 'moderate-risk' : 'high-risk'
										)}>
										{[WarningIndexes.LOW, WarningIndexes.HIGH].includes(item.type.warningIndex)
											? translate('moderateRisk')
											: translate('highRisk')}
									</span>
								</p>
								<i className='material-icons-outlined alert-icon position-absolute'>east</i>
							</div>
						</div>
					</div>
				))}
			{!measurementAlerts.length && (
				<div className='flex flex-align-center flex-justify-center empty-state-wrapper'>
					<EmptyState title={translate('noResultsFound')} image='alerts.svg?v2' />
				</div>
			)}
		</>
	);
};

export default HealthDataHistory;
