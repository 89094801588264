import React from 'react';
import { useHistory } from 'react-router';
import Grid from 'components/Grid.jsx';
import translate from 'i18n-translations/translate.jsx';

const NoHealthSystem = () => {
	const history = useHistory();

	return (
		<Grid className='member-error-wrapper' columns='1fr' rows='1fr' stretch='100vh' horizAlign='center' vertAlign='center'>
			<div className='member-error'>
				<h3>{translate('notAssignedHs')}. </h3>
				<img src='https://static.solaborate.com/banyan/healthsystem-error.svg' alt='member-error' />
				<p> {translate('ifYouThinkThereAreMistakes')} </p>
				<span className='logout-link' onClick={() => history.push('/logout')}>
					{translate('clickToLoginAnotherAcc')}
				</span>
			</div>
		</Grid>
	);
};

export default NoHealthSystem;
