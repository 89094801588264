import React from 'react';
import classNames from 'classnames';

const RadioButton = props => (
    <label htmlFor={props.id} className={classNames('radio-label', props.checked ? 'radio-checked' : '', props.disabled ? 'disabled' : '')}>
        <input
            className='radio-input'
            type='radio'
            name={props.name}
            id={props.id}
            value={props.value}
            onChange={props.onChange}
            checked={props.checked}
        />
        <span className='custom-radio' />
        <p className={classNames('radio-text', props.checked ? 'radio-checked' : '')}>{props.text}</p>
    </label>
);

export default RadioButton;