import React from 'react';
import ReactSelect from 'react-select';
import { useIntl } from 'react-intl';
import { AnalyticsCategory } from 'constants/enums.js';
import { generateCustomStyles } from 'constants/react-select-style.js';
import PatientPositionsAnalytics from 'components/Monitoring/PatientPositionsAnalytics.jsx';
import IvBagFluidLevelAnalytics from 'components/Monitoring/IvBagFluidLevelAnalytics.jsx';

const AiHistory = ({ selectedAiHistoryItem, setSelectedAiHistoryItem, isDarkMode, patientPositions, ivBagFluidsLevels }) => {
	const intl = useIntl();
	return (
		<>
			<div className='no-padding no-margin-bottom no-border font-14 top-20'>
				<ReactSelect
					options={[
						{ label: intl.formatMessage({ id: 'patientMobility' }), value: AnalyticsCategory.PATIENT_POSITION },
						{ label: intl.formatMessage({ id: 'ivBagFluidLevel' }), value: AnalyticsCategory.IV_BAG_FLUID_LEVEL },
					]}
					value={selectedAiHistoryItem}
					onChange={value => setSelectedAiHistoryItem(value)}
					classNamePrefix='react-select'
					styles={generateCustomStyles({ darkMode: isDarkMode })}
				/>
			</div>
			{selectedAiHistoryItem?.value === AnalyticsCategory.PATIENT_POSITION && (
				<PatientPositionsAnalytics patientPositions={patientPositions} isDarkMode={isDarkMode} />
			)}
			{selectedAiHistoryItem?.value === AnalyticsCategory.IV_BAG_FLUID_LEVEL && (
				<IvBagFluidLevelAnalytics ivBagFluidsLevels={ivBagFluidsLevels} isDarkMode={isDarkMode} />
			)}
		</>
	);
};

export default AiHistory;
