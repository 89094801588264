import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import _ from 'lodash';

/**
 * @param {string} format Duration string formatting
 * @param {Date} startTime Duration start time to get time difference
 * @returns {string} Formatted duration
 */
const getFormattedDuration = (format, startTime) => {
	if (_.isNil(startTime)) {
		return moment
			.utc()
			.set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
			.format(format);
	}

	const timeDiff = Math.abs(new Date().getTime() - startTime.getTime());
	const duration = moment.duration(Math.floor(timeDiff / 1000), 's');

	return moment.utc(duration.asMilliseconds()).format(format);
};

/**
 * @param {object} props
 * @param {string} [props.format] Duration string formatting
 * @param {Date} [props.startTime] Duration start time to get time difference
 */
const Duration = ({ format, startTime }) => {
	const startTimeRef = useRef(!_.isNil(startTime) ? startTime : new Date());
	const [duration, setDuration] = useState(getFormattedDuration(format, startTimeRef.current));
	const intervalRef = useRef(null);

	useEffect(() => {
		if (!intervalRef.current) {
			intervalRef.current = setInterval(() => {
				setDuration(getFormattedDuration(format, startTimeRef.current));
			}, 500);
		}
	}, [format]);

	useEffect(() => {
		return () => {
			if (intervalRef.current) {
				clearInterval(intervalRef.current);
			}
		};
	}, []);

	return <p>{duration}</p>;
};

Duration.defaultProps = {
	format: 'HH:mm:ss',
};

Duration.propTypes = {
	format: PropTypes.string,
};

export default Duration;
