import { getProviders } from 'api/dashboard.js';
import Alert from 'components/Alert.jsx';
import Pagination from 'components/Common/Pagination.jsx';
import ProfilePicture from 'components/ProfilePicture.jsx';
import Table from 'components/Table.jsx';
import translate from 'i18n-translations/translate.jsx';
import { doesParamValueExist, getFirstName, getLastName, getRoleValueById } from 'infrastructure/helpers/commonHelpers.js';
import { convertToTimestampSecondsWithMaxTime, getHumanizedDateWithHour } from 'infrastructure/helpers/dateHelper.js';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

const ProvidersCentricReport = props => {
	const [providers, setProviders] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0, totalCount: 0 });
	const intl = useIntl();

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			const response = await getProviders({
				start: Math.floor(new Date(props.start).getTime() / 1000),
				end: Math.floor(convertToTimestampSecondsWithMaxTime(props.end)),
				...(doesParamValueExist(props.selectedHealthSystem) && { healthSystemId: props.selectedHealthSystem.value }),
				...(doesParamValueExist(props.selectedHospital) && {
					regionId: props.selectedHospital.regionId,
					hospitalId: props.selectedHospital.value,
				}),
				...(doesParamValueExist(props.selectedDepartment) && { departmentId: props.selectedDepartment.value }),
				...(doesParamValueExist(props.selectedFloor) && { floorId: props.selectedFloor.value }),
				...(doesParamValueExist(props.selectedRoom) && { roomId: props.selectedRoom.value }),
				...(props.selectedTimezone.value && { timezone: props.selectedTimezone.value }),
				pageSize: pagination.pageSize,
				pageIndex: pagination.pageIndex,
				searchPattern: props.searchValue,
			});
			if (!response.error) {
				setProviders(response.data);
				setPagination(prevState => ({ ...prevState, totalCount: response.totalCount }));
			} else {
				setError(
					translate('somethingWentWrongSpecific', {
						value: intl.formatMessage({ id: 'providers' }),
					})
				);
			}
			setIsLoading(false);
		};
		if (props.selectedHealthSystem) {
			fetchData();
		}
	}, [
		props.end,
		props.selectedDepartment,
		props.selectedFloor,
		props.selectedHealthSystem,
		props.selectedHospital,
		props.selectedRoom,
		props.start,
		props.selectedTimezone,
		pagination.pageIndex,
		pagination.pageSize,
		props.searchValue,
	]);

	const getLocation = provider =>
		provider.location && (
			<div>
				{provider.location.healthSystemName && <span>{provider.location.healthSystemName}</span>}
				{provider.location.hospitalName && (
					<span>
						{' >'} {provider.location.hospitalName}
					</span>
				)}
				{provider.location.departmentName && (
					<span>
						{' >'} {provider.location.departmentName}
					</span>
				)}
				{provider.location.floorName && (
					<span>
						{' >'} {provider.location.floorName}
					</span>
				)}
				{provider.location.roomName && (
					<span>
						{' >'} {provider.location.roomName}
					</span>
				)}
			</div>
		);

	const groupMemberRoles = providerId =>
		providers.reduce((acc, item) => {
			const providerRole = props.providersRoles.find(role => role.id === item.companyMemberRoleId);
			if (item.objectId === providerId && providerRole) {
				acc.push(providerRole);
			}
			return acc;
		}, []);

	const displayProviders = () => {
		if (!providers || providers.length === 0 || isLoading) {
			return [];
		}
		return providers.map((provider, index) => ({
			id: provider.objectId + index,
			careGiver: (
				<div className='flex image-container'>
					<ProfilePicture
						fullName={provider.name}
						profilePicture={provider.picture}
						className='provider-intervening-img-wrapper'
					/>
					<p>{provider.name}</p>
				</div>
			),
			role: getRoleValueById(provider.companyMemberRoleId),
			count: provider.count,
			endedTime: getHumanizedDateWithHour(provider.lastCallDate),
			location: getLocation(provider),
			actions: (
				<div className='flex position-relative table-buttons'>
					<button
						type='button'
						className='button white'
						onClick={() => {
							props.setSelectedProvider({
								firstName: getFirstName(provider.name),
								lastName: getLastName(provider.name),
								profilePicture: provider.picture,
								userIntId: provider.objectId,
								memberRoles: groupMemberRoles(provider.objectId),
								...provider,
							});
						}}>
						{intl.formatMessage({ id: 'viewSessions' })}
					</button>
				</div>
			),
		}));
	};

	return (
		<>
			<Table
				headers={[
					{ title: translate('providers'), id: 0 },
					{ title: translate('role'), id: 1 },
					{ title: translate('completedSessions'), id: 2 },
					{ title: translate('lastSessionDate'), id: 3 },
					{ title: translate('location'), id: 4 },
					{ title: '', id: 5 },
				]}
				rows={displayProviders()}
				isEditable={false}
				isLoading={isLoading}
			/>
			<div className='pagination-bar'>
				<Pagination
					totalCount={pagination.totalCount}
					pageSize={pagination.pageSize}
					pageIndex={pagination.pageIndex}
					onChange={(size, index) => setPagination(prevState => ({ ...prevState, pageIndex: index, pageSize: size }))}
				/>
			</div>
			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
		</>
	);
};

export default ProvidersCentricReport;
