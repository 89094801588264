import { CallTypes, UserRoles, UserTypes } from 'constants/enums.js';

export const DashboardContent = [
	{ id: 'conferenceId', label: 'conferenceId', checked: false },
	{ id: 'provider', label: 'provider', checked: false },
	{ id: 'room', label: 'room', checked: false },
	{ id: 'patient', label: 'patient', checked: false },
	{ id: 'startTime', label: 'startTime', checked: false },
	{ id: 'endedTime', label: 'endedTime', checked: false },
	{ id: 'duration', label: 'duration', checked: false },
	{ id: 'calltype', label: 'callType', checked: false },
	{ id: 'usedaudio', label: 'usedAudio', checked: false },
	{ id: 'usedvideo', label: 'usedVideo', checked: false },
	{ id: 'usedscreen', label: 'usedScreen', checked: false },
];

export const sessionByHours = intl => [
	{
		label: intl.formatMessage({ id: 'total' }),
		data: Array.from({ length: 24 }).fill(0),
		borderColor: '#5390FF',
		borderWidth: 3,
		backgroundColor: 'transparent',
		pointRadius: 0,
		pointBackgroundColor: '#5390FF',
		fill: false,
		callTypes: [CallTypes.AUDIO, CallTypes.VIDEO, CallTypes.SECURITY_CAM, CallTypes.MONITORING],
	},
	{ label: '', data: [], borderColor: 'transparent', backgroundColor: 'transparent' },
	{
		label: intl.formatMessage({ id: 'monitoring' }),
		data: Array.from({ length: 24 }).fill(0),
		borderColor: '#F15E5E',
		borderWidth: 3,
		backgroundColor: 'transparent',
		pointRadius: 0,
		pointBackgroundColor: '#F15E5E',
		fill: false,
		callTypes: [CallTypes.MONITORING],
	},
	{ label: '', data: [], borderColor: 'transparent', backgroundColor: 'transparent' },
	{
		label: intl.formatMessage({ id: 'rounding' }),
		data: Array.from({ length: 24 }).fill(0),
		borderColor: '#33C27F',
		borderWidth: 3,
		backgroundColor: 'transparent',
		pointRadius: 0,
		pointBackgroundColor: '#33C27F',
		fill: false,
		callTypes: [CallTypes.AUDIO, CallTypes.VIDEO, CallTypes.SECURITY_CAM],
	},
];

export const admissionsDischarges = intl => [
	{
		label: intl.formatMessage({ id: 'admissions' }),
		data: Array.from({ length: 24 }).fill(0),
		borderColor: '#5390FF',
		borderWidth: 3,
		backgroundColor: 'transparent',
		pointRadius: 0,
		pointBackgroundColor: '#5390FF',
		fill: false,
		lineType: AdmittancesTypes.ADMIT,
	},
	{ label: '', data: [], borderColor: 'transparent', backgroundColor: 'transparent' },
	{
		label: intl.formatMessage({ id: 'discharges' }),
		data: Array.from({ length: 24 }).fill(0),
		borderColor: '#F15E5E',
		borderWidth: 3,
		backgroundColor: 'transparent',
		pointRadius: 0,
		pointBackgroundColor: '#F15E5E',
		fill: false,
		lineType: AdmittancesTypes.DISCHARGE,
	},
];

export const providersRoles = [
	{ id: UserTypes.DOCTOR, name: UserRoles.DOCTOR },
	{ id: UserTypes.NURSE, name: UserRoles.NURSE },
	{ id: UserTypes.VIRTUAL_SITTER, name: UserRoles.VIRTUAL_SITTER },
];

export const barChartColors = {
	NURSE: '#5390FF',
	DOCTOR: '#8383F4',
	VIRTUAL_SITTER: '#6BC6B5',
};

export const initialInitiatorRoundingData = [
	{ id: 1, roleId: UserTypes.NURSE, text: 'virtualCareProvider', count: 0, percentage: 0, color: barChartColors.NURSE },
	{ id: 2, roleId: UserTypes.DOCTOR, text: 'doctor', count: 0, percentage: 0, color: barChartColors.DOCTOR },
	{
		id: 3,
		roleId: UserTypes.VIRTUAL_SITTER,
		text: 'virtualSitter',
		value: 0,
		color: barChartColors.VIRTUAL_SITTER,
	},
	{
		id: 4,
		roleId: UserTypes.VISITOR,
		text: 'visitor',
		value: 0,
		color: barChartColors.NURSE,
	},
];

export const initialInitiatorMonitoringData = [
	{ id: 1, roleId: UserTypes.NURSE, text: 'virtualCareProvider', count: 0, percentage: 0, color: barChartColors.NURSE },
	{
		id: 2,
		roleId: UserTypes.VIRTUAL_SITTER,
		text: 'virtualSitter',
		count: 0,
		percentage: 0,
		color: barChartColors.VIRTUAL_SITTER,
	},
];

export const byHourChartLabels = [
	'12 AM',
	'1 AM',
	'2 AM',
	'3 AM',
	'4 AM',
	'5 AM',
	'6 AM',
	'7 AM',
	'8 AM',
	'9 AM',
	'10 AM',
	'11 AM',
	'12 PM',
	'1 PM',
	'2 PM',
	'3 PM',
	'4 PM',
	'5 PM',
	'6 PM',
	'7 PM',
	'8 PM',
	'9 PM',
	'10 PM',
	'11 PM',
];

export const byHourChartOptions = (datasets, xLabelScales) => ({
	scales: {
		yAxes: [
			{
				ticks: {
					min: 0,
					max: Math.ceil(Math.max(...datasets[0].data) / 100) * 100 || 100,
					stepSize: Math.max(...datasets[0].data) <= 100 ? 20 : 100,
					autoSkip: true,
					maxTicksLimit: 10,
				},
			},
		],
		xAxes: [
			{
				gridLines: {
					display: false,
				},
				ticks: {
					callback: (value, index) => (index % xLabelScales === 0 ? value : null),
				},
			},
		],
	},
	legend: {
		position: 'bottom',
	},
	tooltips: {
		mode: 'index',
		intersect: false,
		callbacks: {
			label: (tooltipItem, data) => {
				return data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
			},
		},
	},
	hover: {
		mode: 'nearest',
		intersect: true,
	},
});

export const AdmittancesTypes = {
	ADMIT: 1,
	DISCHARGE: 2,
};

export const buildDataPerHours = list => {
	const data = byHourChartLabels.map(label => {
		let hour = parseInt(label.split(' ')[0], 10);
		if (hour === 12 && label.includes('AM')) {
			hour = 0;
		} else if (hour !== 12 && label.includes('PM')) {
			hour += 12;
		}

		const countData = list.reduce((accumulator, item) => {
			let total = accumulator;
			if (item.hour === hour) {
				total += item.count;
			}

			return total;
		}, 0);

		return countData;
	});

	return data;
};

export const DevicesStatus = {
	OFFLINE: 'Offline',
	ONLINE: 'Online',
};

export const initialDevicesStatusData = {
	[DevicesStatus.OFFLINE]: {
		label: 'Offline',
		value: 0,
		percentage: 0,
		stroke: '#718093',
	},
	[DevicesStatus.ONLINE]: {
		label: 'Online',
		value: 0,
		percentage: 0,
		stroke: '#33C27F',
	},
};

export const tabEnums = { OVERVIEW: 0, CARE_TEAM_REPORT: 1, PATIENT_REPORT: 2 };
