import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import Table from 'components/Table.jsx';
import Input from 'components/Input.jsx';
import Form from 'components/Form.jsx';
import Button from 'components/Button.jsx';
import Modal from 'components/Modal.jsx';
import Grid from 'components/Grid.jsx';
import CreateCompanyWrapper from 'views/CreateCompanyWrapper.jsx';
import translate from 'i18n-translations/translate.jsx';
import { getHelloHealthCompanies, deleteCompany } from 'api/companies.js';
import { getPreferredLanguageLocale } from 'infrastructure/helpers/commonHelpers.js';
import Alert from 'components/Alert.jsx';
import Pagination from 'components/Common/Pagination.jsx';

const CompanyList = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [isDeleteLoading, setIsDeleteLoading] = useState(false);
	const [isEditLoading, setIsEditLoading] = useState(true);
	const [companyToDelete, setCompanyToDelete] = useState(null);
	const [selectedCompany, setSelectedCompany] = useState(null);
	const [error, setError] = useState(null);
	const [companies, setCompanies] = useState([]);
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0, totalCount: 0 });
	const intl = useIntl();
	const history = useHistory();
	const timeout = useRef(null);
	const [searchValue, setSearchValue] = useState('');

	const deviceConfigHeaders = [
		{ title: intl.formatMessage({ id: 'companyName' }), id: 0 },
		{ title: intl.formatMessage({ id: 'owner' }), id: 1 },
		{ title: intl.formatMessage({ id: 'companyId' }), id: 2 },
		{ title: intl.formatMessage({ id: 'dateCreated' }), id: 3 },
		{ title: intl.formatMessage({ id: 'active' }), id: 4 },
		{ title: '', id: 5 },
	];

	useEffect(() => {
		const fetchData = async () => {
			timeout.current = setTimeout(async () => {
				const params = { pageIndex: pagination.pageIndex, pageSize: pagination.pageSize, search: searchValue };
				const response = await getHelloHealthCompanies(params);
				if (response.error) {
					setError(response.error.message);
				} else {
					setPagination(prevState => ({ ...prevState, totalCount: response.totalCount }));
					const result = mapCompanies(response.organizations);
					setCompanies(result);
				}
				setIsLoading(false);
			}, 500);
		};
		fetchData();

		return () => clearTimeout(timeout.current);
	}, [pagination.pageSize, pagination.pageIndex, searchValue]);

	useEffect(() => {
		if (!selectedCompany) {
			setIsEditLoading(true);
		}
	}, [selectedCompany]);

	const mapCompanies = companyList =>
		companyList.map(item => ({
			id: item.id,
			companyName: item.name,
			owner: item.email,
			companyId: item.id,
			dateCreated: moment.utc(item.dateCreated).local().locale(getPreferredLanguageLocale()).format('MM/DD/YYYY-hh:mm A'),
			active: item.isActive ? translate('yes') : translate('no'),
			actions: item.isActive ? (
				<div className='wrapped'>
					<i className='material-icons-outlined boxed-icon' onClick={() => setSelectedCompany(item)}>
						edit
					</i>
					<i className='material-icons-outlined boxed-icon' onClick={() => setCompanyToDelete(item)}>
						delete
					</i>
				</div>
			) : (
				''
			),
		}));

	const onDeleteCompany = async () => {
		setIsDeleteLoading(true);
		const response = await deleteCompany(companyToDelete.id);
		if (response.error) {
			setError(response.error.message);
		} else {
			setIsDeleteLoading(prevState => !prevState);
			setCompanies(prevState => {
				const companiesToSet = _.cloneDeep(prevState);
				const foundCompany = companiesToSet.find(x => x.companyId === companyToDelete.id);
				foundCompany.active = translate('no');
				foundCompany.actions = '';
				return companiesToSet;
			});
		}
		setIsLoading(false);
		setCompanyToDelete(null);
	};

	const createCompany = () => {
		history.push('/create-company');
	};

	const onPageChange = async (pageSize, pageIndex) => {
		setIsLoading(true);
		setPagination(prevState => ({ ...prevState, pageSize, pageIndex }));
	};

	return (
		<div>
			<Table isLoading={isLoading} headers={deviceConfigHeaders} rows={isLoading ? [] : companies}>
				<Input
					validationOptions={{}}
					type='text'
					placeholder={intl.formatMessage({ id: 'search' })}
					bottomSpace='0'
					variant='filled flex-1'
					name='searchComapnies'
					value={searchValue}
					onChange={event => {
						setIsLoading(true);
						setSearchValue(event.target.value);
						setPagination(prevState => ({ ...prevState, pageIndex: 0 }));
					}}
					maxLength={100}
					inputWidth='300px'
				/>
				<Grid columns='1fr' gridGap='10px' vertAlign='center'>
					<Button onClick={createCompany} text={translate('createCompany')} horizAlign='end' />
				</Grid>
			</Table>
			<Pagination
				totalCount={pagination.totalCount}
				pageSize={pagination.pageSize}
				pageIndex={pagination.pageIndex}
				onChange={(pageSize, pageIndex) => onPageChange(pageSize, pageIndex)}
			/>
			{companyToDelete && (
				<Modal
					display={true}
					position='center'
					primaryButtonLabel={translate('delete')}
					primaryButtonLoading={isDeleteLoading}
					onModalSubmit={onDeleteCompany}
					onModalClose={() => setCompanyToDelete(null)}>
					<Form title={translate('warning')} onSubmit={event => event.preventDefault()}>
						<p>{translate('sureToDeleteOrg', { value: `"${companyToDelete.name}"` })}</p>
					</Form>
				</Modal>
			)}
			{selectedCompany && (
				<Modal
					className='edit-company-modal border-radius-modal-wrapper'
					display={true}
					position='center'
					hideActionButtons={true}
					isLoading={isEditLoading}
					onModalClose={() => setSelectedCompany(null)}
					shouldSubmitOnEnter={false}>
					<CreateCompanyWrapper selectedCompany={selectedCompany} stopEditLoading={() => setIsEditLoading(false)} />
				</Modal>
			)}

			<Alert display={error} message={error} variant='error' onClose={() => setError(null)} />
		</div>
	);
};

export default CompanyList;
