import React, { useEffect, useState } from 'react';
import { enums } from '@solaborate/calls';
import { useIntl } from 'react-intl';
import { Button, Icon, Modal, Input } from 'calls/components/index.js';
import { useConferenceConfigurations } from 'calls/hooks/index.js';
import { ButtonType } from 'constants/enums.js';
import { Dropdown } from 'components/index.js';
import { getPreferredLanguageLocale } from 'infrastructure/helpers/commonHelpers.js';
import { LOCALES } from 'i18n-translations/locales.js';
import translate from 'i18n-translations/translate.jsx';
import { StyledCameraBookmarks, StyledAddNewCameraBookmarksButton } from 'css/StyledComponents/index.js';

/**
 * @param {object} props
 * @param {import('calls/RemoteHelloParticipant.js').default} props.helloDevice
 * @param {any[]} props.bookmarks
 * @param {object} props.activeBookmark
 * @param {object} props.selectedCamera
 */
const CameraBookmarks = ({ helloDevice, bookmarks, activeBookmark, selectedCamera }) => {
	const [toggleBookmark, setToggleBookmark] = useState(true);
	const [bookmark, setBookmark] = useState('');
	const [bookmarkToDelete, setBookmarkToDelete] = useState(null);
	const [isDeleteBookmarkModalOpen, setIsDeleteBookmarkModalOpen] = useState(false);
	const [isBookmarkModalOpen, setIsBookmarkModalOpen] = useState(false);
	const locale = getPreferredLanguageLocale();
	const [activePreset, setActivePreset] = useState(null);
	const bookmarksLimit = 9;

	const conferenceConfigs = useConferenceConfigurations();

	const intl = useIntl();

	const deleteBookmark = id => {
		helloDevice.sendMediaControlsEvent(
			enums.MediaControlsCommands.REMOVE_BOOKMARK,
			enums.MediaTypes.CAMERA,
			JSON.stringify({
				cameraId: selectedCamera?.id,
				bookmarkId: id,
			})
		);
	};

	const editBookmark = id => {
		helloDevice.sendMediaControlsEvent(enums.MediaControlsCommands.EDIT_BOOKMARK, enums.MediaTypes.CAMERA, id);
	};

	const setCurrentPosition = async bookmarkId => {
		const response = await helloDevice.sendMediaControlsEvent(
			enums.MediaControlsCommands.MOVE_TO_BOOKMARK,
			enums.MediaTypes.CAMERA,
			JSON.stringify({
				bookmarkId,
				cameraId: selectedCamera?.id,
			})
		);

		if (response.ok) {
			setActivePreset(bookmarks?.find(x => x.id === bookmarkId));
		}
	};

	const addNewBookmark = text => {
		if (bookmarks.length >= bookmarksLimit) {
			return;
		}
		helloDevice.sendMediaControlsEvent(enums.MediaControlsCommands.ADD_BOOKMARK, enums.MediaTypes.CAMERA, text);
		setIsBookmarkModalOpen(null);
	};

	const handleDeleteBookmark = (itemId, itemName) => {
		setBookmarkToDelete({ itemId, itemName });
		setIsDeleteBookmarkModalOpen(true);
	};

	return (
		<StyledCameraBookmarks isDarkMode={conferenceConfigs.isDarkMode} isRightToLeft={locale === LOCALES.ARABIC}>
			<Button onClick={() => setToggleBookmark(prevState => !prevState)}>
				<div>
					<div>
						<Icon name='bookmark' />
						<div>
							<p>{intl.formatMessage({ id: 'presets' })}</p>
							<span>
								{activePreset && (
									<>
										{intl.formatMessage({ id: 'activePreset' })} {activePreset?.name}
									</>
								)}
								{!activePreset && intl.formatMessage({ id: 'noActivePreset' })}
							</span>
						</div>
					</div>
					<Icon name={toggleBookmark ? 'expand_less' : 'expand_more'} />
				</div>
			</Button>
			{toggleBookmark && (
				<div>
					{bookmarks?.length === 0 && <p>{intl.formatMessage({ id: 'noPresets' })}</p>}
					{bookmarks?.map(item => (
						<div>
							<button type='button' onClick={() => setCurrentPosition(item.id)}>
								{!item.isEdit && item.name}
							</button>
							<Dropdown position='right' icon='more_vert'>
								<div>
									<div>
										<button type='button' onClick={() => setCurrentPosition(item.id)}>
											<Icon name='my_location' />
											{intl.formatMessage({ id: 'goToPreset' })}
										</button>
									</div>
									<div>
										<button type='button' onClick={() => editBookmark(item.id)}>
											<Icon name='edit' />
											{intl.formatMessage({ id: 'updateToCurrentPosition' })}
										</button>
									</div>
									<div>
										<button type='button' onClick={() => handleDeleteBookmark(item.id, item.name)}>
											<Icon name='delete' />
											{intl.formatMessage({ id: 'deletePreset' })}
										</button>
									</div>
								</div>
							</Dropdown>
						</div>
					))}
					{/* bookmarksLimit - 1 => (because 1 is for the home position) */}
					{bookmarks?.length < bookmarksLimit - 1 && (
						<StyledAddNewCameraBookmarksButton>
							<button type='button' onClick={() => setIsBookmarkModalOpen(true)} disabled={bookmarks.length >= bookmarksLimit}>
								<Icon name='add' />
								{intl.formatMessage({ id: 'addNewPreset' })}
							</button>
						</StyledAddNewCameraBookmarksButton>
					)}
				</div>
			)}
			{isBookmarkModalOpen && (
				<Modal
					onDismiss={() => {
						setIsBookmarkModalOpen(null);
						setBookmark(null);
					}}
					title={intl.formatMessage({ id: 'createPreset' })}>
					<Modal.Content>
						<Input>
							<Input.Label>{translate('presetName')}</Input.Label>
							<Input.Field
								value={bookmark}
								placeholder={intl.formatMessage({ id: 'typePresetDescription' })}
								onChange={e => setBookmark(e.target.value)}
							/>
							<Input.Description>{translate('presetChangeDescription')}</Input.Description>
						</Input>
					</Modal.Content>
					<Modal.Actions>
						<Button
							type='submit'
							variant={ButtonType.SUBMIT}
							onClick={() => {
								addNewBookmark(bookmark);
								setIsBookmarkModalOpen(null);
								setBookmark(null);
							}}>
							{intl.formatMessage({ id: 'createNewPreset' })}
						</Button>
					</Modal.Actions>
				</Modal>
			)}
			{bookmarkToDelete && isDeleteBookmarkModalOpen && (
				<Modal onDismiss={() => setIsDeleteBookmarkModalOpen(false)} title={intl.formatMessage({ id: 'deleteConfirmation' })}>
					<Modal.Content>
						<p>
							{translate('areYouSureToDeletePreset', {
								value: bookmarkToDelete.itemName,
							})}
						</p>
					</Modal.Content>
					<Modal.Actions>
						<Button
							type='submit'
							onClick={() => {
								deleteBookmark(bookmarkToDelete.itemId);
								setIsDeleteBookmarkModalOpen(false);
							}}>
							{translate('delete')}
						</Button>
					</Modal.Actions>
				</Modal>
			)}
		</StyledCameraBookmarks>
	);
};

export default CameraBookmarks;
