import React, { useEffect, useState } from 'react';
import translate from 'i18n-translations/translate.jsx';
import classNames from 'classnames';
import { EditIcon } from 'icons/Monitoring/index.js';
import { useIntl } from 'react-intl';
import CustomDropdown from 'components/CustomDropdown.jsx';
import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';
import SpeechToText from 'SpeechToText.jsx';
import Textarea from 'components/Textarea.jsx';
import { DietTypes } from 'constants/enums.js';

const Diet = props => {
	const [expanded, setExpanded] = useState(true);
	const [showEditForm, setShowEditForm] = useState(false);
	const [dataToEdit, setDataToEdit] = useState(props.diet);
	const [recognizedTranscription, setRecognizedTranscription] = useState('');
	const [liveTranscription, setLiveTranscription] = useState('');
	const [isSpeechToText, setIsSpeechToText] = useState(false);

	useEffect(() => {
		setDataToEdit(props.diet);
		const found = props.diet?.find(item => item.value === DietTypes.NPO && item.comment);
		if (found) {
			setRecognizedTranscription(found.comment);
		}
	}, [props.diet]);

	useEffect(() => {
		setDataToEdit(prevState =>
			prevState.map(item => ({
				value: item.value,
				comment: item.value === DietTypes.NPO ? recognizedTranscription : '',
			}))
		);
	}, [recognizedTranscription]);

	const intl = useIntl();

	const diet = [
		{ id: 0, name: DietTypes.NPO },
		{ id: 1, name: DietTypes.CLEAR_LIQUID_DIET },
		{ id: 2, name: DietTypes.BARIATRIC_DIET },
		{ id: 3, name: DietTypes.FULL_LIQUID_DIET },
		{ id: 4, name: DietTypes.HEART_HEALTHY },
		{ id: 5, name: DietTypes.DIABETIC_DIET },
		{ id: 6, name: DietTypes.REGULAR_DIET },
		{ id: 7, name: DietTypes.DYSPHAGIA_DIET },
		{
			id: 8,
			name: DietTypes.RENAL_DIET,
		},
		{
			id: 9,
			name: DietTypes.LOW_SODIUM_DIET,
		},
		{
			id: 10,
			name: DietTypes.FLUID_RESTRICTIONS,
		},
	];

	return (
		<>
			<div className={classNames('timeline-box-header sub-timeline-box-header', expanded ? 'expanded' : '')}>
				<p className='timeline-box-title'>{translate('diet')}</p>
				<div className='timeline-box-actions'>
					<button
						type='button'
						onClick={() => {
							setShowEditForm(prevState => !prevState);
							setExpanded(true);
						}}>
						<EditIcon color={props.isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayThree} />
					</button>
					<button type='button' onClick={() => setExpanded(prevState => !prevState)}>
						<i className={classNames('material-icons', expanded ? ' rotate' : '')}>expand_more</i>
					</button>
				</div>
			</div>
			{expanded && (
				<>
					{!showEditForm && (
						<table className='patient-table-informations' style={{ width: '100%' }}>
							<tbody>
								<tr>
									<td>{translate('diet')}</td>
									<td>
										{props.diet.map((item, index) => (
											<React.Fragment key={index}>
												{item.value}
												{index < props.diet.length - 1 ? ', ' : ''}
											</React.Fragment>
										))}
									</td>
								</tr>
								{props.diet.find(item => item.value === DietTypes.NPO) && (
									<tr>
										<td>{translate('comment')}</td>
										<td>{props.diet.find(item => item.value === DietTypes.NPO).comment}</td>
									</tr>
								)}
							</tbody>
						</table>
					)}
					{showEditForm && (
						<div className='flex flex-space-between flex-wrap whiteboard-select'>
							<CustomDropdown
								defaultOptions={
									dataToEdit.length > 0
										? dataToEdit.map(item => ({
												value: item.value,
												label: item.value,
										  }))
										: []
								}
								initialOptions={diet.map(item => ({
									value: item.name,
									label: item.name,
								}))}
								onSelect={selectedOptions => {
									setDataToEdit(
										selectedOptions.map(item => ({
											value: item.value,
											comment: item.value === DietTypes.NPO ? recognizedTranscription : '',
										}))
									);

									if (!selectedOptions.find(item => item.value === DietTypes.NPO)) {
										setRecognizedTranscription('');
									}
								}}
								title={intl.formatMessage({ id: 'diet' })}
								placeholder=''
								showTitleInPlaceholder={true}
							/>
							{dataToEdit.find(item => item.value === DietTypes.NPO) && (
								<Textarea
									label={`NPO ${intl.formatMessage({ id: 'comment' })}`}
									labelClassName='npo-label'
									maxLength={1000}
									onChange={event => {
										setRecognizedTranscription(event.target.value);
									}}
									value={isSpeechToText ? `${recognizedTranscription} ${liveTranscription}`.trim() : recognizedTranscription}
									rows={10}
									name='text'
									placeholder={intl.formatMessage({ id: 'comment' })}
									className='full-width top-15'
									icon={
										<SpeechToText
											setRecognizedTranscription={setRecognizedTranscription}
											setLiveTranscription={setLiveTranscription}
											setIsSpeechToText={setIsSpeechToText}
											isSpeechToText={isSpeechToText}
										/>
									}
								/>
							)}

							<button
								className={classNames('button', props.isSubmitButtonLoading ? 'loading' : '')}
								type='button'
								onClick={() => props.setData(dataToEdit)}>
								{translate('save')}
							</button>
						</div>
					)}
				</>
			)}
		</>
	);
};

export default Diet;
