import React from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import LightTheme from 'calls/styles/LightTheme.js';
import { LOCALES } from 'i18n-translations/locales.js';
import { getPreferredLanguageLocale } from 'infrastructure/helpers/commonHelpers.js';

/**
 * @type {import('styled-components').StyledComponent<"div", any, { isRightToLeft: boolean }, never>}
 */

const backgroundColor = isDarkMode => (isDarkMode ? 'rgba(49, 58, 66, 1)' : 'rgba(255, 255, 255, 1)');
const fontColor = isDarkMode => (isDarkMode ? '#CFD3DA' : '#555555');

const StyledToggleGroup = styled.div`
	display: flex;
	justify-content: center;

	main {
		position: relative;
		padding: ${LightTheme.spacing[2]}px ${LightTheme.spacing[3]}px;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;

		&:first-of-type {
			${props =>
				props.isRightToLeft
					? css`
							border-bottom-right-radius: 30px;
							border-top-right-radius: 30px;
					  `
					: css`
							border-bottom-left-radius: 30px;
							border-top-left-radius: 30px;
					  `}
		}

		&:last-of-type {
			${props =>
				props.isRightToLeft
					? css`
							border-bottom-left-radius: 30px;
							border-top-left-radius: 30px;
					  `
					: css`
							border-bottom-right-radius: 30px;
							border-top-right-radius: 30px;
					  `}
		}

		div {
			position: relative;
			z-index: 1;
		}

		input {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: 0;
			z-index: 2;
			margin: 0;
			padding: 0;
			cursor: pointer;

			~ div {
				color: ${props => fontColor(props.isDarkMode)};
				font-size: 12px;
			}

			&:hover {
				~ span {
					opacity: 0.9;
				}
			}

			~ span {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				color: ${props => fontColor(props.isDarkMode)};
				background: ${props => backgroundColor(props.isDarkMode)};
				transition: 0.2s;
			}

			&:checked {
				~ span {
					background: var(--blue-light-5);
				}

				~ div {
					color: var(--gray-0);
				}
			}

			&:disabled {
				user-select: none;
				pointer-events: none;
				~ div,
				~ span {
					opacity: 0.75;
					user-select: none;
					pointer-events: none;
				}
			}
		}
	}
`;

const ToggleGroup = ({ children }) => {
	const user = useSelector(state => state.user);
	const locale = getPreferredLanguageLocale();

	return (
		<StyledToggleGroup isDarkMode={user.darkMode} isRightToLeft={locale === LOCALES.ARABIC}>
			{children}
		</StyledToggleGroup>
	);
};

/**
 * @param {{children: any} & React.PropsWithRef<React.InputHTMLAttributes<HTMLInputElement>>} props
 */
const ToggleItem = ({ children, ...inputHtmlAttributes }) => (
	<main>
		<input {...inputHtmlAttributes} type='radio' />
		<div>{children}</div>
		<span />
	</main>
);

ToggleGroup.Item = ToggleItem;

export default ToggleGroup;
