import * as React from 'react';
import LightTheme from 'calls/styles/LightTheme.js';

function SvgComponent(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} strokeWidth={1.5} fill='none' {...props}>
			<path
				d='M14 20.4v-5.8a.6.6 0 01.6-.6h5.8a.6.6 0 01.6.6v5.8a.6.6 0 01-.6.6h-5.8a.6.6 0 01-.6-.6zM3 20.4v-5.8a.6.6 0 01.6-.6h5.8a.6.6 0 01.6.6v5.8a.6.6 0 01-.6.6H3.6a.6.6 0 01-.6-.6zM14 9.4V3.6a.6.6 0 01.6-.6h5.8a.6.6 0 01.6.6v5.8a.6.6 0 01-.6.6h-5.8a.6.6 0 01-.6-.6zM3 9.4V3.6a.6.6 0 01.6-.6h5.8a.6.6 0 01.6.6v5.8a.6.6 0 01-.6.6H3.6a.6.6 0 01-.6-.6z'
				stroke={LightTheme.colors.grayFive}
			/>
		</svg>
	);
}

export default SvgComponent;
