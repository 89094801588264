import { gatewayApi, gatewayFileUploadApi } from 'constants/global-variables.js';
import { getCompanyId, getUserId } from 'infrastructure/auth.js';

const companyId = getCompanyId();

export const getNurseCallEvents = async ({ pageSize = 10, pageIndex = 0, searchParam = '', healthSystemId }) => {
	try {
		const url = `api/nurse-call-events/tenants/${companyId}/health-systems/${healthSystemId}/ehr-staffs?searchParam=${searchParam}&pageSize=${pageSize}&pageIndex=${pageIndex}`;
		const { data } = await gatewayApi.get(url);
		return data;
	} catch (error) {
		return { error };
	}
};

export const updateNurseCallEvent = async params => {
	const formData = new FormData();
	Object.keys(params).forEach(key => {
		if (key === 'file' && params[key]) {
			formData.append(`UploadDto.file`, params[key]);
			formData.append(`UploadDto.cropWidth`, '0');
			formData.append(`UploadDto.cropHeight`, '0');
			formData.append(`UploadDto.cropOffsetX`, '0');
			formData.append(`UploadDto.cropOffsetY`, '0');
		} else {
			formData.append(key, params[key]);
		}
	});
	formData.append('userId', getUserId());
	formData.append('tenantId', getCompanyId());

	try {
		const { data } = await gatewayFileUploadApi.put(`/api/nurse-call-events/ehr-staffs`, formData);
		return data;
	} catch (error) {
		return { error };
	}
};
