import React, { useState, useEffect, useContext } from 'react';
import { ChatOptionsViews } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { getConversationLinks } from 'api/messenger.js';
import { EmptyState } from 'components';
import translate from 'i18n-translations/translate.jsx';
import PopUpAlert from 'components/PopUpAlert.jsx';
import SocketEvents from 'constants/socket-events.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';

const Links = props => {
	const [links, setLinks] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const socket = useContext(SocketContext);

	useEffect(() => {
		const fetchLinks = async () => {
			const response = await getConversationLinks(props.conversationId);
			if (response.error) {
				setError(response.error.message);
				setIsLoading(false);
			} else {
				setLinks(response.links);
				setIsLoading(false);
			}
		};
		fetchLinks();
		const handleNewMessage = ({ conversationId, message }) => {
			if (props.conversationId === conversationId && message?.links?.length > 0) {
				fetchLinks();
			}
		};
		fetchLinks();
		socket.on(SocketEvents.Conversation.ON_NEW_MESSAGE, handleNewMessage);
		return () => {
			socket.off(SocketEvents.Conversation.ON_NEW_MESSAGE, handleNewMessage);
		};
	}, [props.conversationId, socket]);

	const redirect = link => {
		window.open(processLink(link), '_blank');
	};

	const processLink = link => {
		return link.indexOf('http://') !== -1 || link.indexOf('https://') !== -1 ? link : `http://${link}`;
	};

	return (
		<>
			<div className='chat-options-wrapper position-relative'>
				<div className='flex'>
					<div className='cursor-pointer' onClick={() => props.setDefaultOptions(ChatOptionsViews.DEFAULT)}>
						<img src={`${healthCareCdnUrl}chat/back.svg`} alt='back arrow icon' />
					</div>
					<div>
						<p>{translate('links')} </p>
						<p> {translate('sharedLinks')}</p>
					</div>
				</div>
				{links.length > 0 && (
					<div className='links-list'>
						<div>
							{/* <p>Today</p> */}
							<div className='link-list'>
								{links.map(item => (
									<div key={item.id} onClick={() => redirect(item.link)}>
										<p className='cursor-pointer --blue-color'>{item.link}</p>
									</div>
								))}
							</div>
						</div>
					</div>
				)}
				{links.length === 0 && !isLoading && (
					<div className='empty-state-wrapper-percent'>
						<EmptyState title={translate('noData')} image='no-files.svg' />
					</div>
				)}
			</div>
			<PopUpAlert display={error} onAlertClose={() => setError(null)} contentText={error} isSilent={true} center={true} />
		</>
	);
};

export default Links;
