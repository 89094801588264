import { CallStatus, CallTypes, CallTypesMessage, ParticipantState, SessionType } from 'constants/enums.js';

export const getCallType = callType => {
	switch (callType) {
		case CallTypes.AUDIO:
		case CallTypes.VIDEO:
		case CallTypes.FIRST_RESPONDER: {
			return CallTypesMessage.TALK_TO_PATIENT;
		}
		case CallTypes.SECURITY_CAM: {
			return CallTypesMessage.PATIENT_VIEW;
		}
		case CallTypes.MONITORING: {
			return CallTypesMessage.MONITORING;
		}
		default:
			return '';
	}
};

export const getSessionType = callType => {
	switch (callType) {
		case CallTypes.AUDIO:
		case CallTypes.SECURITY_CAM:
		case CallTypes.VIDEO: {
			return CallTypesMessage.ROUNDING;
		}
		case CallTypes.SCREENSHARE: {
			return CallTypesMessage.SCREENSHARE;
		}
		case CallTypes.MONITORING: {
			return CallTypesMessage.MONITORING;
		}
		default:
			return callType;
	}
};

export const getParticipantState = state => {
	switch (state) {
		case ParticipantState.CONNECTING.type: {
			return ParticipantState.CONNECTING.message;
		}
		case ParticipantState.CONNECTED.type: {
			return ParticipantState.CONNECTED.message;
		}
		case ParticipantState.BUSY.type: {
			return ParticipantState.BUSY.message;
		}
		case ParticipantState.DECLINED.type: {
			return ParticipantState.DECLINED.message;
		}
		case ParticipantState.OFFLINE.type: {
			return ParticipantState.OFFLINE.message;
		}
		case ParticipantState.LEFT_CALL.type: {
			return ParticipantState.LEFT_CALL.message;
		}
		case ParticipantState.NOT_ANSWERING.type: {
			return ParticipantState.NOT_ANSWERING.message;
		}
		case ParticipantState.CANT_CONNECT.type: {
			return ParticipantState.CANT_CONNECT.message;
		}
		case ParticipantState.DISRUPTED.type: {
			return ParticipantState.DISRUPTED.message;
		}
		case ParticipantState.REMOVED.type: {
			return ParticipantState.REMOVED.message;
		}
		default: {
			return 'N/A';
		}
	}
};

export const getCallStatus = status => {
	switch (status) {
		case CallStatus.UNDEFINED.type: {
			return CallStatus.UNDEFINED.message;
		}
		case CallStatus.SUCCESSFUL.type: {
			return CallStatus.SUCCESSFUL.message;
		}
		case CallStatus.PARTIALLY_SUCCESSFUL.type: {
			return CallStatus.PARTIALLY_SUCCESSFUL.message;
		}
		case CallStatus.FAILED.type: {
			return CallStatus.FAILED.message;
		}
		case CallStatus.DISRUPTED.type: {
			return CallStatus.DISRUPTED.message;
		}
		case CallStatus.PARTIALLY_DISRUPTED.type: {
			return CallStatus.PARTIALLY_DISRUPTED.message;
		}
		case CallStatus.MISSED.type: {
			return CallStatus.MISSED.message;
		}
		case CallStatus.ABORTED.type: {
			return CallStatus.ABORTED.message;
		}
		case CallStatus.DROPPED.type: {
			return CallStatus.DROPPED.message;
		}
		default:
			return '';
	}
};

export const getSessionTypeString = type => {
	switch (type) {
		case SessionType.VIDEO_MONITORING:
			return 'Video Monitoring';
		case SessionType.AMBIENT_MONITORING:
			return 'Ambient Monitoring';
		case SessionType.VIDEO_AMBIENT_MONITORING:
			return 'Video and Ambient Monitoring';
		default:
			return '';
	}
};
