import React from 'react';

const HeartRateSummaryChart = props => {
	const svgCoordinates = {
		x1: 20,
		x2: 480,
		y: 130,
	};

	const safeRange = {
		min: 60,
		max: 100,
	};

	const maxGraphValue = 220;
	const lineLength = svgCoordinates.x2 - svgCoordinates.x1;
	const graphNumbersPosition = svgCoordinates.y + 50;
	const calcFactor = value => value * (lineLength / maxGraphValue) + svgCoordinates.x1;
	const graphValue = props.measurementValue > maxGraphValue ? svgCoordinates.x2 : calcFactor(props.measurementValue);

	return (
		<>
			<div className='heart-rate-graph'>
				<svg viewBox='0 0 500 250' xmlns='http://www.w3.org/2000/svg' className='heart-rate'>
					<line
						className='graph-background'
						x1={svgCoordinates.x1}
						x2={svgCoordinates.x2}
						y1={svgCoordinates.y}
						y2={svgCoordinates.y}
					/>

					<line
						className='safe-range'
						x1={calcFactor(safeRange.min)}
						x2={calcFactor(safeRange.max)}
						y1={svgCoordinates.y}
						y2={svgCoordinates.y}
					/>

					<circle
						className='measurement-circle'
						cx={graphValue}
						cy={svgCoordinates.y}
						r='12.5'
						style={{ transformOrigin: `${(graphValue / 500) * 100}% ${(svgCoordinates.y / 250) * 100}%` }}
					/>

					<g className='graph-scale-numbers'>
						<text x={svgCoordinates.x1} y={graphNumbersPosition}>
							0
						</text>
						<text x={calcFactor(safeRange.min)} y={graphNumbersPosition}>
							{safeRange.min}
						</text>
						<text x={calcFactor(safeRange.max)} y={graphNumbersPosition}>
							{safeRange.max}
						</text>
						<text x={svgCoordinates.x2} y={graphNumbersPosition}>
							{maxGraphValue}
						</text>
					</g>
				</svg>
			</div>

			<div className='flex summary-chart-data heart-rate-data'>
				<h1>
					{props.measurementValue}
					{props.measurementValue !== 'No Data' && <span>{props.measurementUnit}</span>}
				</h1>
			</div>
		</>
	);
};

export default HeartRateSummaryChart;
