import { AiAlertNotificationType, PatientsAiSettings } from 'constants/enums.js';

export const AiNotificationTypes = [
	{ id: AiAlertNotificationType.TOAST_MESSAGE, name: 'toastMessage' },
	{ id: AiAlertNotificationType.MODAL_ON_FEED, name: 'alertOnMonitoringFeed' },
	{ id: AiAlertNotificationType.SILENT_MODE, name: 'silentMode' },
];

export const StatNotificationTypes = [
	{ id: AiAlertNotificationType.STAT_ALARM_HELLO, name: 'helloDevice' },
	{ id: AiAlertNotificationType.STAT_ALARM_DIGITAL_ROOM_SIGN, name: 'digitalSign' },
];

export const RadioButtonTypes = [
	AiAlertNotificationType.TOAST_MESSAGE,
	AiAlertNotificationType.MODAL_ON_FEED,
	AiAlertNotificationType.SILENT_MODE,
];

export const CheckBoxTypes = [AiAlertNotificationType.STAT_ALARM_HELLO, AiAlertNotificationType.STAT_ALARM_DIGITAL_ROOM_SIGN];

export const DefaultSnoozeTime = 30;

export const FallPreventionSettingTypes = [
	PatientsAiSettings.PERSON_GETTING_OUT_OF_BED,
	PatientsAiSettings.RAIL_DOWN,
	PatientsAiSettings.FALL_DETECTED,
];

