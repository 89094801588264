import React, { useState, useRef, useEffect, useContext } from 'react';
import styled, { css } from 'styled-components';
import { Cam, Mic, ScreenAudio, ScreenShare } from '@solaborate/calls/webrtc';
import { participant as CallsParticipant } from '@solaborate/calls';

import { useIntl } from 'react-intl';
import {
	ParticipantAudio,
	ParticipantVideo,
	IconButton,
	Participant,
	Avatar,
	Dropdown,
	List,
	ParticipantAudioLevel,
	Loader,
	StyledParticipantInfo,
	Tooltip,
	Icon,
} from 'calls/components/index.js';
import {
	useConference,
	useConferenceConfigurations,
	useRemoteParticipantState,
	useRemoteParticipant,
	useLocalParticipant,
	useControllerTracks,
	useHelloDeviceState,
} from 'calls/hooks/index.js';
import LightTheme from 'calls/styles/LightTheme.js';
import { VideocamIcon, VideocamOffIcon, VolumeUpIcon, VolumeOffIcon, PinIcon, UnPinIcon } from 'calls/icons/index.js';
import RemoteHelloParticipant from 'calls/RemoteHelloParticipant.js';

import { buildProfilePic } from 'infrastructure/helpers/thumbnailHelper.js';
import { getUserRole } from 'infrastructure/auth.js';
import translate from 'i18n-translations/translate.jsx';
import { ControlsActions, UserRoles, UserTypes } from 'calls/enums/index.js';
import { attachSinkId, getSavedStreamSettings } from 'calls/helpers/index.js';
import { getCallsButtonColor, getSomeRoleConfigurationsValues } from 'infrastructure/helpers/commonHelpers.js';
import ClosedCaptionsIcon from 'calls/icons/ClosedCaptions.jsx';
import ClosedCaptionsDisabled from 'calls/icons/ClosedCaptionsDisabled.jsx';
import { RoundingSettings } from 'constants/configurationEnums.js';
import TransferOwnershipIcon from 'calls/icons/TransferOwnership.jsx';
import { v4 as uuidv4 } from 'uuid';
import SocketEvents from 'constants/socket-events.js';
import { SocketFunctionsContext } from 'infrastructure/socket-client/SocketFunctions.jsx';
import PeerConnectionStatsIcon from 'calls/icons/PeerConnectionStats.jsx';
import PeerConnectionStats from 'calls/components/PeerConnectionStats.jsx';
import { isMobile } from 'react-device-detect';

/**
 * @type {import('styled-components').StyledComponent<"div", any, { isGridView: boolean }, never>}
 */
const StyledParticipantState = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	p {
		margin: 0;
		color: ${LightTheme.colors.grayFive};
		font-size: 12px;
	}

	@media (max-width: 768px) {
		${props =>
			props.isGridView &&
			css`
				p {
					color: ${LightTheme.colors.grayZero};
				}
			`}
	}
`;

const StyledParticipantActions = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	transition: 0.2s;
	z-index: 1;

	&:hover {
		opacity: 1;
	}

	> div {
		margin-right: ${LightTheme.spacing[2]}px;

		&:last-of-type {
			margin: 0;
		}

		> button {
			box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
		}
	}

	@media (max-width: 768px) {
		> div {
			margin-right: ${LightTheme.spacing[1]}px;

			> button {
				padding: ${LightTheme.spacing[2]}px;
			}
		}
	}
`;

const RemoveParticipantAction = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	padding: 10px;
	z-index: 1;

	> div {
		> button {
			padding: ${LightTheme.spacing[2]}px;
		}
	}
`;

const LimitedVisitorSideView = styled.div`
	position: absolute;
	z-index: 1;
	top: auto;
	bottom: 0;
	height: calc(100% - 33px);
	width: 20%;
	left: ${props => (props.position === 'left' ? '0' : 'auto')};
	right: ${props => (props.position === 'right' ? '0' : 'auto')};
	background: ${LightTheme.colors.graySix};
`;

const ParticipantInfo = ({ isScreenShare = false }) => {
	const [showStats, setShowStats] = useState(false);
	const participant = useRemoteParticipant();
	const connectionState = useRemoteParticipantState();
	const tracks = useControllerTracks(participant.remoteTrackController);
	const conferenceConfigs = useConferenceConfigurations();
	const deviceState = useHelloDeviceState(participant);

	return (
		<StyledParticipantInfo isGridView={conferenceConfigs.isGridView}>
			{connectionState instanceof CallsParticipant.StateConnected && (
				<>
					{(!tracks[Mic] || deviceState?.isMicPrivacyOn) && !isScreenShare && (
						<IconButton icon='mic_off' background={LightTheme.colors.redOne} color={LightTheme.colors.grayZero} />
					)}
					{tracks[Mic] && !deviceState?.isMicPrivacyOn && (
						// This check will avoid a web crash when an offer is sent incorrectly
						// on iOS side. This will be fixed when calls functionality will be implemented
						// correctly on iOS. This check should be removed in the future when that happens
						<ParticipantAudioLevel track={tracks[Mic].track.kind === 'audio' ? tracks[Mic].track : null} />
					)}
					{!isMobile && (
						<Dropdown isOpen={showStats} onClickOutside={() => setShowStats(false)}>
							<Dropdown.Button
								icon={<PeerConnectionStatsIcon color={getCallsButtonColor(conferenceConfigs.isDarkMode)} />}
								onMouseOver={e => {
									e.stopPropagation();
									setShowStats(true);
								}}
								onMouseOut={e => {
									e.stopPropagation();
									setShowStats(false);
								}}
							/>
							<Dropdown.Items renderIntoPortal={true}>
								<PeerConnectionStats participant={participant} isScreenShare={isScreenShare} />
							</Dropdown.Items>
						</Dropdown>
					)}
				</>
			)}
		</StyledParticipantInfo>
	);
};

/**
 * @param {object} props
 * @param {{[key: string]: import('@solaborate/calls/webrtc').Track}} props.tracks
 */
const ParticipantScreen = ({ tracks }) => {
	return (
		<>
			<ParticipantAudio track={tracks[ScreenAudio]} />
			<ParticipantVideo track={tracks[ScreenShare]} />
		</>
	);
};

/**
 * @param {object} props
 * @param {import('calls/LocalParticipant.js').default | import('calls/RemoteParticipant.js').default} props.mainParticipant Local or remote participant
 * @param {(trackType: Cam, Mic, ScreenShare) => void} [props.onClick=null]
 */
const RemoteParticipantView = ({ mainParticipant, onClick = null }) => {
	const intl = useIntl();
	const conference = useConference();
	const conferenceConfigs = useConferenceConfigurations();
	const localParticipant = useLocalParticipant();
	const participant = useRemoteParticipant();
	const socketFunctions = useContext(SocketFunctionsContext);
	const connectionState = useRemoteParticipantState();
	const tracks = useControllerTracks(participant.remoteTrackController);
	const [isCCEnabled, setIsCCEnabled] = useState(participant instanceof RemoteHelloParticipant ? participant.isCCEnabled : false);

	const audioRef = useRef(null);

	const [participantDisplayedName] = useState(participant.alias || participant.name);
	const [showControls, setShowControls] = useState(false);
	const [closingOwnerViews] = useState([
		{ action: ControlsActions.TOGGLE_LIVE_EXAMINATIONS, value: false },
		{ action: ControlsActions.TOGGLE_TELEMETRY, value: false },
		{ action: ControlsActions.TOGGLE_CARE_EVENTS, value: false },
		{ action: ControlsActions.TOGGLE_PATIENT_HISTORY, value: false },
		{ action: ControlsActions.TOGGLE_INVITE_PARTICIPANTS_MODAL, value: false },
		getUserRole() === UserRoles.DOCTOR && { action: ControlsActions.TOGGLE_DIAGNOSES, value: false },
	]);

	const onRemoveParticipant = participantId => {
		conferenceConfigs.setRemoveParticipantModal({
			isOpen: true,
			modalMessage: `${intl.formatMessage({ id: 'confirmationOfRemovalParticipant' }, { value: participantDisplayedName })}`,
			onSubmit: () => {
				if (!localParticipant.isOwner) {
					return;
				}
				conference.removeParticipant(participantId);
				conferenceConfigs.setRemoveParticipantModal({
					isOpen: false,
					message: '',
					onSubmit: () => {},
				});
			},
		});
	};

	const onToggleCC = isEnabled => {
		if (participant instanceof RemoteHelloParticipant) {
			participant.toggleCC(isEnabled);
		}
	};

	useEffect(() => {
		const setAudioTrackSinkId = async () => {
			const audioTrack = tracks[Mic];
			const streamSettings = getSavedStreamSettings();
			if (audioTrack && streamSettings?.audiooutput) {
				await attachSinkId(audioRef.current, streamSettings.audiooutput);
			}
		};
		setAudioTrackSinkId();
	}, [tracks, conferenceConfigs.sinkId]);

	useEffect(() => {
		return participant.on(event => {
			if (event instanceof CallsParticipant.CCStateChanged) {
				setIsCCEnabled(event.isCCEnabled);
			}
		});
	}, [participant]);

	const toggleTrack = trackType => {
		participant.remoteTrackController.toggle(trackType);
		setShowControls(false);
	};

	const togglePinnedParticipant = () => {
		conferenceConfigs.setPinnedParticipantId(conferenceConfigs.pinnedParticipantId === participant.id ? '' : participant.id);
		if (participant?.role === UserTypes.GUEST) {
			conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_LIVE_EXAMINATIONS, false);
			conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_PATIENT_HISTORY, false);
			conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_DIAGNOSES, false);
			conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_NOTES, false);
			conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_PROCEDURES, false);
			conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_PRESCRIPTIONS, false);
			conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_PHYSICAL_EXERCISES, false);
		}
	};

	const onSetMainParticipant = trackType => {
		if (trackType) {
			onClick(trackType);
			return;
		}

		onClick(!tracks[Cam] && tracks[ScreenShare] ? ScreenShare : Cam);
	};

	const makeHost = async participantId => {
		const response = await conference.transferOwnership(participantId);
		if (!response) {
			conferenceConfigs.setConferenceErrorMessages([{ id: uuidv4(), message: translate('somethingWentWrong') }]);
			return;
		}
		localParticipant.isGuest = true;
		conferenceConfigs.onConfigurationToggleAction(ControlsActions.SET_SHOW_PATIENT_MEASUREMENTS_BUTTONS, false);
		closingOwnerViews.forEach(({ action, value }) => {
			conferenceConfigs.onConfigurationToggleAction(action, value ?? null);
			if (action === ControlsActions.TOGGLE_LIVE_EXAMINATIONS) {
				socketFunctions.toggleHealthData({
					isEnabled: false,
					helloDeviceId: mainParticipant.objectId,
					conferenceId: conference.conferenceId,
					participantId: localParticipant.id,
					toolType: SocketEvents.HelloDevice.TOOLTYPE_LIVE_EXAMINATION,
					measurementType: '',
				});
			}
		});
	};

	const showClosedCaptionsButton = () =>
		getSomeRoleConfigurationsValues(conferenceConfigs.roundingConfigurations, [RoundingSettings.ClosedCaptions]) &&
		isHelloParticipant &&
		localParticipant.isOwner;

	const isStateConnected = connectionState instanceof CallsParticipant.StateConnected;
	const isHelloParticipant = participant instanceof RemoteHelloParticipant;

	return (
		<>
			{participant.isCompanion && <ParticipantAudio track={tracks[Mic]} />}
			{!participant.isCompanion && (
				<>
					<Participant
						style={{ opacity: !isStateConnected ? 0.75 : 1, pointerEvents: !isStateConnected ? 'none' : 'unset' }}
						onClick={() => {
							if (!conferenceConfigs.pinnedParticipantId) {
								onSetMainParticipant();
							}
						}}>
						<Participant.Info>
							{!conferenceConfigs.isGridView && conferenceConfigs.pinnedParticipantId === participant.id && (
								<PinIcon width={18} height={18} color={getCallsButtonColor(conferenceConfigs.isDarkMode)} />
							)}
							<p>
								{conferenceConfigs.isGridView && <ParticipantInfo />}
								<span>
									{participantDisplayedName} {participant.interpreterId ? `(${intl.formatMessage({ id: 'interpreter' })})` : ''}
								</span>
							</p>
							{!conferenceConfigs.isGridView && (
								<Participant.Controls>
									<Dropdown isOpen={showControls} onClickOutside={() => setShowControls(false)}>
										<Dropdown.Button onClick={() => setShowControls(prevState => !prevState)} />
										<Dropdown.Items renderIntoPortal={true}>
											<List>
												{isHelloParticipant && !localParticipant.isGuest && (
													<>
														<List.Item onClick={() => toggleTrack(Cam)}>
															{tracks[Cam] ? <VideocamIcon width={18} height={18} /> : <VideocamOffIcon width={18} height={18} />}
															{tracks[Cam] ? translate('cameraOff') : translate('cameraOn')}
														</List.Item>
														<List.Item onClick={() => toggleTrack(Mic)}>
															{tracks[Mic] ? <VolumeUpIcon width={18} height={18} /> : <VolumeOffIcon width={18} height={18} />}
															{tracks[Mic] ? translate('muteAudio') : translate('unMuteAudio')}
														</List.Item>
													</>
												)}
												<List.Item
													onClick={() => {
														togglePinnedParticipant();
														onSetMainParticipant();
														setShowControls(false);
													}}>
													{conferenceConfigs.pinnedParticipantId === participant.id && (
														<UnPinIcon width={18} height={18} color={getCallsButtonColor(conferenceConfigs.isDarkMode)} />
													)}
													{conferenceConfigs.pinnedParticipantId !== participant.id && (
														<PinIcon width={18} height={18} color={getCallsButtonColor(conferenceConfigs.isDarkMode)} />
													)}
													{conferenceConfigs.pinnedParticipantId === participant.id
														? translate('unpinFeed')
														: translate('pinFeed')}
												</List.Item>
												{localParticipant.isOwner && (
													<List.Item onClick={() => onRemoveParticipant(participant.id)}>
														<Icon name='close' size={18} />
														{translate('removeParticipant')}
													</List.Item>
												)}
												{showClosedCaptionsButton() && (
													<List.Item onClick={() => onToggleCC(!isCCEnabled)}>
														{isCCEnabled && (
															<ClosedCaptionsIcon
																height={18}
																width={18}
																color={getCallsButtonColor(conferenceConfigs.isDarkMode)}
															/>
														)}
														{!isCCEnabled && (
															<ClosedCaptionsDisabled
																height={18}
																width={18}
																color={getCallsButtonColor(conferenceConfigs.isDarkMode)}
															/>
														)}
														{isCCEnabled ? translate('disableClosedCaptions') : translate('enableClosedCaptions')}
													</List.Item>
												)}
												{!isHelloParticipant &&
													[UserTypes.DOCTOR, UserTypes.NURSE].includes(participant.role) &&
													localParticipant.isOwner && (
														<List.Item
															onClick={() => {
																makeHost(participant.id);
																setShowControls(false);
															}}>
															<TransferOwnershipIcon
																height={18}
																width={18}
																color={getCallsButtonColor(conferenceConfigs.isDarkMode)}
															/>
															{translate('makeHost')}
														</List.Item>
													)}
											</List>
										</Dropdown.Items>
									</Dropdown>
								</Participant.Controls>
							)}
						</Participant.Info>
						<Participant.Main
							style={{
								display:
									(mainParticipant !== participant && !tracks[ScreenShare]) || conferenceConfigs.isGridView ? null : 'none',
							}}>
							<ParticipantAudio ref={audioRef} track={tracks[Mic]} />
							{isStateConnected && (
								<>
									{tracks[Cam] && (mainParticipant !== participant || conferenceConfigs.isGridView) && (
										<ParticipantVideo track={tracks[Cam]} />
									)}
									{!tracks[Cam] && tracks[ScreenShare] && !isHelloParticipant && <ParticipantScreen tracks={tracks} />}
									{!tracks[Cam] && !tracks[ScreenShare] && (
										<Avatar
											size={conferenceConfigs.isGridView ? 60 : 48}
											src={participant.picture?.includes('user') ? null : buildProfilePic(participant.picture)}
											name={participantDisplayedName}
										/>
									)}
								</>
							)}
							{connectionState instanceof CallsParticipant.StateConnecting && !tracks[Cam] && !tracks[ScreenShare] && (
								<StyledParticipantState isGridView={conferenceConfigs.isGridView}>
									<Loader color={LightTheme.colors.blueTwo} />
									<p>Connecting</p>
								</StyledParticipantState>
							)}
							{!conferenceConfigs.isGridView && <ParticipantInfo />}
							{conferenceConfigs.isGridView && (
								<StyledParticipantActions>
									{isHelloParticipant && !localParticipant.isGuest && (
										<>
											<Tooltip text={intl.formatMessage({ id: tracks[Cam] ? 'cameraOff' : 'cameraOn' })}>
												<IconButton
													background={LightTheme.colors.grayZero}
													color={LightTheme.colors.grayFive}
													size={18}
													onClick={evt => {
														evt.stopPropagation();
														toggleTrack(Cam);
													}}>
													{tracks[Cam] && (
														<VideocamIcon color={getCallsButtonColor(conferenceConfigs.isDarkMode)} width={18} height={18} />
													)}
													{!tracks[Cam] && (
														<VideocamOffIcon color={getCallsButtonColor(conferenceConfigs.isDarkMode)} width={18} height={18} />
													)}
												</IconButton>
											</Tooltip>
											<Tooltip text={intl.formatMessage({ id: tracks[Mic] ? 'muteAudio' : 'unMuteAudio' })}>
												<IconButton
													background={LightTheme.colors.grayZero}
													color={LightTheme.colors.grayFive}
													size={18}
													onClick={evt => {
														evt.stopPropagation();
														toggleTrack(Mic);
													}}>
													{tracks[Mic] && (
														<VolumeUpIcon color={getCallsButtonColor(conferenceConfigs.isDarkMode)} width={18} height={18} />
													)}
													{!tracks[Mic] && (
														<VolumeOffIcon color={getCallsButtonColor(conferenceConfigs.isDarkMode)} width={18} height={18} />
													)}
												</IconButton>
											</Tooltip>
										</>
									)}
									{!conferenceConfigs.isMinimizedView && (
										<Tooltip
											text={intl.formatMessage({
												id: conferenceConfigs.pinnedParticipantId === participant.id ? 'unpinFeed' : 'pinFeed',
											})}>
											<IconButton
												background={LightTheme.colors.grayZero}
												color={LightTheme.colors.grayFive}
												size={18}
												onClick={evt => {
													evt.stopPropagation();
													togglePinnedParticipant();
													onSetMainParticipant();
													if (conferenceConfigs.pinnedParticipantId !== participant.id) {
														conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_GRID_VIEW);
													}
												}}>
												{conferenceConfigs.pinnedParticipantId === participant.id && (
													<UnPinIcon width={18} height={18} color={getCallsButtonColor(conferenceConfigs.isDarkMode)} />
												)}
												{conferenceConfigs.pinnedParticipantId !== participant.id && (
													<PinIcon width={18} height={18} color={getCallsButtonColor(conferenceConfigs.isDarkMode)} />
												)}
											</IconButton>
										</Tooltip>
									)}
									{localParticipant.isOwner && (
										<RemoveParticipantAction>
											<Tooltip
												text={intl.formatMessage({
													id: 'removeParticipant',
												})}
												position='bottom-left'>
												<IconButton
													background={LightTheme.colors.grayZero}
													color={LightTheme.colors.grayFive}
													size={18}
													onClick={() => onRemoveParticipant(participant.id)}>
													<Icon name='close' size={14} />
												</IconButton>
											</Tooltip>
										</RemoveParticipantAction>
									)}
								</StyledParticipantActions>
							)}
							{isHelloParticipant && conferenceConfigs.isMultiBed && getUserRole() === UserRoles.VISITOR && (
								<>
									<LimitedVisitorSideView position='left'></LimitedVisitorSideView>
									<LimitedVisitorSideView position='right'></LimitedVisitorSideView>
								</>
							)}
						</Participant.Main>
					</Participant>
					{tracks[Cam] && tracks[ScreenShare] && !isHelloParticipant && (
						<Participant onClick={() => onSetMainParticipant(ScreenShare)}>
							<Participant.Info>
								<p>
									{participantDisplayedName} ({translate('presenting')})
								</p>
							</Participant.Info>
							<Participant.Main>
								<ParticipantScreen tracks={tracks} />
								<ParticipantInfo isScreenShare />
							</Participant.Main>
						</Participant>
					)}
				</>
			)}
		</>
	);
};

export default RemoteParticipantView;
