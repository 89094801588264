import React from 'react';
import Select from 'react-select';
import { useIntl } from 'react-intl';
import translate from 'i18n-translations/translate.jsx';
import { validateMaxLength } from 'infrastructure/helpers/commonHelpers.js';

const Location = props => {
	const intl = useIntl();
	const getCountryValue = () => props.transformArray(props.countries).filter(option => option.value === props.values.country);

	const getStateValue = () => props.transformArray(props.states).filter(option => option.value === props.values.stateCode);
	return (
		<>
			<div className='flex personal-details-wrapper'>
				<div className='flex column-direction personal-details position-relative registration-select-wrapper'>
					<label>
						{translate('country')}
						<Select
							value={getCountryValue()}
							placeholder={intl.formatMessage({ id: 'selectCountry' })}
							classNamePrefix='react-select'
							options={props.transformArray(props.countries)}
							onChange={event => props.formikProps?.setFieldValue('country', event.value)}
							isSearchable
						/>
						<span className='red-error'>{props.errors.country}</span>
					</label>
					{props.values.country === 'US' && (
						<>
							<label>
								{translate('state')}
								<Select
									value={getStateValue()}
									placeholder={intl.formatMessage({ id: 'state' })}
									classNamePrefix='react-select'
									options={props.transformArray(props.states)}
									onChange={event => props.formikProps?.setFieldValue('stateCode', event.value)}
									isSearchable
									isClearable
								/>
								<span className='red-error'>{props.errors.stateCode}</span>
							</label>
						</>
					)}
				</div>
				<div className='flex column-direction personal-details'>
					<label>
						{translate('city')}
						<input
							type='text'
							placeholder={intl.formatMessage({ id: 'city' })}
							value={props.values.city}
							onChange={props.onChange}
							maxLength={30}
							name='city'
						/>
					</label>
				</div>
			</div>
			<div className='flex personal-details-wrapper'>
				<div className='flex column-direction personal-details'>
					<label>
						{translate('postalCode')}
						<input
							type='number'
							placeholder={intl.formatMessage({ id: 'postalCode' })}
							value={props.values.zipCode}
							onChange={props.onChange}
							maxLength={10}
							name='zipCode'
							onKeyDown={event => validateMaxLength(event, 10)}
							className='number-input-wo-arrows'
						/>
					</label>
				</div>
				<div className='flex column-direction personal-details'>
					<label>
						{translate('address')}
						<input
							type='text'
							placeholder={intl.formatMessage({ id: 'address' })}
							value={props.values.address}
							onChange={props.onChange}
							maxLength={50}
							name='address'
						/>
					</label>
				</div>
			</div>
		</>
	);
};
export default Location;
