import React from 'react';

const PeerConnectionStatsIcon = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
		<rect x='5' y='9' width='3' height='11' fill='currentColor' />
		<rect x='11' y='6' width='3' height='14' fill='currentColor' />
		<rect x='16' y='11' width='3' height='9' fill='currentColor' />
	</svg>
);

export default PeerConnectionStatsIcon;
