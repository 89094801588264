import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators as healthSystemsActionCreators } from 'state/healthSystems/actions.js';
import { Route, useRouteMatch } from 'react-router-dom';
import { SectorTypes, UserRoles, TreeHierarchyType, CareEventTypes } from 'constants/enums.js';
import Grid from 'components/Grid.jsx';
import TreeView from 'components/TreeView.jsx';
import Loader from 'components/Loader.jsx';
import Input from 'components/Input.jsx';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import { getCompanyId, getUserRole } from 'infrastructure/auth.js';
import Breadcrumb from 'components/Breadcrumb.jsx';
import CallPatient from 'views/Partials/CallPatient.jsx';
import Hospital from 'views/Partials/Hospital.jsx';
import Department from 'views/Partials/Department.jsx';
import Floor from 'views/Partials/Floor.jsx';
import Room from 'views/Partials/Room.jsx';
import translate from 'i18n-translations/translate.jsx';
import {
	searchSectors,
	showPath,
	buildTree,
	findSectorById,
	getHealthSystemDevices,
} from 'infrastructure/helpers/commonHelpers.js';
import { createFrontlineHospital, createHospital } from 'api/hospitals.js';
import { createDepartment } from 'api/departments.js';
import { createFloor, createFloorInHospital } from 'api/floors.js';
import { createRoom, createRoomInDepartment } from 'api/rooms.js';
import { getRegionSubTree } from 'api/tree.js';
import { useIntl } from 'react-intl';
import Alert from 'components/Alert.jsx';
import { actionCreators as devicesActionCreators } from 'state/devices/actions.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';
import _ from 'lodash';
import ListView from 'icons/TreeView/ListView.jsx';
import TreeViewIcon from 'icons/TreeView/TreeViewIcon.jsx';
import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';
import TreeViewMayo from 'components/TreeViewMayo.jsx';
import { getCareEvents } from 'api/teamConfigurationProfiles.js';
import { RoundingSettings } from 'constants/configurationEnums.js';

const HealthSystem = () => {
	const [treeItems, setTreeItems] = useState({});
	const [helloDeviceId, setHelloDeviceId] = useState(null);
	const [sectorSearchValue, setSectorSearchValue] = useState('');
	const [callEvents, setCallEvents] = useState([]);
	const [expandAllTreeItems, setExpandAllTreeItems] = useState(false);
	const healthSystems = useSelector(state => state.healthSystems);
	const userSession = useSelector(state => state.user.userSession);
	const darkMode = useSelector(state => state.user.darkMode);
	const [isLoadingView, setIsLoadingView] = useState(true);
	const [error, setError] = useState(null);
	const dispatch = useDispatch();
	const socket = useContext(SocketContext);
	const intl = useIntl();
	const match = useRouteMatch();
	const treeHierarchyTypeId = userSession.healthSystem.treeHierarchyTypeId;
	const isNewExperience = useSelector(state => state.configurations.isNewExperience);
	const roleRoundingConfigurations = useSelector(state => state.configurations.roleRoundingConfigurations);

	const getSelectedSectorId = useCallback(() => {
		// @ts-ignore
		const { hospital, department, floor, room } = match.params;
		return room || floor || department || hospital;
	}, [match.params]);

	useEffect(() => {
		// @ts-ignore
		const { hospital, department, floor, room } = match.params;
		const getExpandedOptions = () => {
			let treeObj = {};
			if (hospital) {
				treeObj = { [hospital]: {} };
			}
			if (department) {
				treeObj = {
					[hospital]: { [department]: {} },
				};
			}
			if (floor) {
				treeObj = {
					[hospital]: {
						[department]: { [floor]: {} },
					},
				};
			}
			if (room) {
				treeObj = {
					[hospital]: {
						[department]: {
							[floor]: { [room]: {} },
						},
					},
				};
			}
			return treeObj;
		};

		const foundSector = findSectorById(healthSystems.treeData.tree, getSelectedSectorId());
		setTreeItems(getExpandedOptions());
		if (foundSector && foundSector.type === SectorTypes.ROOM) {
			setHelloDeviceId(foundSector.helloDeviceId || null);
		}
		const breadCrumb = foundSector ? foundSector.breadcrumb : [];
		dispatch(healthSystemsActionCreators.updateBreadcrumb(breadCrumb));
		return () => {
			healthSystemsActionCreators.updateBreadcrumb([]);
		};
	}, [dispatch, getSelectedSectorId, healthSystems.treeData, match.params]);

	useEffect(() => {
		setIsLoadingView(true);
		if (healthSystems.selectedHealthSystem) {
			const treeData = buildTree(healthSystems.selectedHealthSystem, healthSystems.isRoomsOnlyView);
			dispatch(healthSystemsActionCreators.setTreeData(treeData));
		}
		setIsLoadingView(false);
	}, [dispatch, healthSystems.isRoomsOnlyView, healthSystems.selectedHealthSystem]);

	useEffect(() => {
		if (sectorSearchValue.length > 1) {
			const sectors = searchSectors(healthSystems.treeData.tree, sectorSearchValue.trim());
			showPath(healthSystems.treeData.tree, sectors);
			setExpandAllTreeItems(true);
		} else {
			searchSectors(healthSystems.treeData.tree, '');
			setExpandAllTreeItems(false);
		}
	}, [sectorSearchValue, healthSystems.treeData.tree]);

	const hasDefaultHierarchy = () =>
		[TreeHierarchyType.DEFAULT_TREE, TreeHierarchyType.HOSPITAL_DEPT_FLOOR_ROOM].includes(treeHierarchyTypeId);

	const onNewSector = async selection => {
		const data = { companyId: getCompanyId(), healthSystemId: userSession.healthSystem.id, name: selection.name };
		if (selection.name?.trim()) {
			if (selection.type === SectorTypes.HOSPITAL && hasDefaultHierarchy()) {
				const response = await createFrontlineHospital({ ...data, regionId: userSession.regionId });
				if (response.error) {
					setError(response.error.message);
					return;
				}
			} else if (selection.type === SectorTypes.HOSPITAL && !hasDefaultHierarchy()) {
				const response = await createHospital({
					...data,
					regionId: userSession.regionId,
					hasDefaultDepartment: treeHierarchyTypeId === TreeHierarchyType.HOSPITAL_ROOM,
				});
				if (response.error) {
					setError(response.error.message);
					return;
				}
			} else if (selection.type === SectorTypes.DEPARTMENT) {
				const response = await createDepartment({ ...data, hospitalId: selection.hospitalId });
				if (response.error) {
					setError(response.error.message);
					return;
				}
			} else if (selection.type === SectorTypes.FLOOR && hasDefaultHierarchy()) {
				const response = await createFloor({
					...data,
					hospitalId: selection.hospitalId,
					departmentId: selection.departmentId,
				});
				if (response.error) {
					setError(response.error.message);
					return;
				}
			} else if (selection.type === SectorTypes.FLOOR && treeHierarchyTypeId === TreeHierarchyType.HOSPITAL_FLOOR_ROOM) {
				const response = await createFloorInHospital({
					...data,
					hospitalId: selection.hospitalId,
					name: selection.name,
				});
				if (response.error) {
					setError(response.error.message);
					return;
				}
			} else if (
				selection.type === SectorTypes.ROOM &&
				(hasDefaultHierarchy() || treeHierarchyTypeId === TreeHierarchyType.HOSPITAL_FLOOR_ROOM)
			) {
				const response = await createRoom({
					...data,
					hospitalId: selection.hospitalId,
					departmentId: selection.departmentId,
					floorId: selection.floorId,
				});
				if (response.error) {
					setError(response.error.message);
					return;
				}
			} else if (
				selection.type === SectorTypes.ROOM &&
				[TreeHierarchyType.HOSPITAL_DEPT_ROOM, TreeHierarchyType.HOSPITAL_ROOM].includes(treeHierarchyTypeId)
			) {
				const response = await createRoomInDepartment({
					...data,
					hospitalId: selection.hospitalId,
					departmentId: selection.departmentId || selection.defaultDepartmentId,
					name: selection.name,
				});
				if (response.error) {
					setError(response.error.message);
					return;
				}
			}
		}
		await fetchTreeData();
	};

	const fetchTreeData = async () => {
		const subTreeResponse = await getRegionSubTree(userSession.healthSystem.id, userSession.regionId);
		if (subTreeResponse.error) {
			setError(subTreeResponse.error.message);
		} else {
			const { healthSystem } = subTreeResponse.organization;
			const treeData = buildTree(healthSystem);
			const { online, busy, privacy, pairedRemote } = getHealthSystemDevices(healthSystem);
			dispatch(devicesActionCreators.setBulkDevicesBusy(busy));
			dispatch(devicesActionCreators.setBulkDevicesOnline(online));
			dispatch(devicesActionCreators.setBulkDevicesPrivacy(privacy));
			dispatch(devicesActionCreators.setBulkPairedRemoteDevice(pairedRemote));
			dispatch(healthSystemsActionCreators.setHealthSystem(healthSystem));
			dispatch(healthSystemsActionCreators.setTreeData(treeData));
		}
	};

	const onTreeViewLinkClick = option => {
		setHelloDeviceId(option.helloDeviceId);
		dispatch(healthSystemsActionCreators.updateBreadcrumb(option.breadcrumb));
	};

	const onBreadcrumbClick = (options, breadcrumbIndex) => {
		const breadcrumb = [];
		options.forEach((option, index) => {
			if (breadcrumbIndex + 1 > index) {
				breadcrumb.push(option);
			}
		});
		dispatch(healthSystemsActionCreators.updateBreadcrumb(breadcrumb));
	};

	const createNewHospital = () => {
		dispatch(healthSystemsActionCreators.createNewHospital());
	};

	const toggleTreeView = isRoomsOnlyView => {
		if (isRoomsOnlyView === healthSystems.isRoomsOnlyView || isLoadingView) {
			return;
		}
		dispatch(healthSystemsActionCreators.toggleIsRoomsView());
		const treeData = buildTree(healthSystems.selectedHealthSystem, isRoomsOnlyView);
		dispatch(healthSystemsActionCreators.setTreeData(treeData));
		setIsLoadingView(false);
	};

	const shouldShowDarkModeIcons =
		darkMode && [UserRoles.NURSE, UserRoles.DOCTOR, UserRoles.VIRTUAL_SITTER].includes(getUserRole());

	useEffect(() => {
		const handleAlexaResponse = data => {
			const treeCloned = _.cloneDeep(healthSystems.treeData.tree);
			const foundSector = findSectorById(treeCloned, data.helloDeviceId);
			foundSector.alexaServiceStatus = data.isActive;
			dispatch(healthSystemsActionCreators.setTreeData(treeCloned));
		};
		const fetchCareEvents = async () => {
			if (!roleRoundingConfigurations[RoundingSettings.RoundingCareEvents]) {
				return;
			}
			const response = await getCareEvents({
				pageIndex: 0,
				pageSize: 20,
				teamCareEventType: CareEventTypes.Rounding,
				healthSystemId: userSession.healthSystem.id,
			});
			const events = [{ label: 'None', value: null }];
			if (!response.error) {
				const teamCareEvents = response?.teamCareEvents.map(item => ({ label: item.name, value: item.id })) ?? [];
				setCallEvents(() => [...events, ...teamCareEvents]);
			} else {
				setCallEvents(events);
			}
		};
		fetchCareEvents();
		socket.on(SocketEvents.HelloDevice.SETUP_ALEXA_ASP_RESPONSE, handleAlexaResponse);
		return () => {
			socket.off(SocketEvents.HelloDevice.SETUP_ALEXA_ASP_RESPONSE, handleAlexaResponse);
		};
	}, []);

	return (
		<MainLayout>
			{isLoadingView && (
				<Grid width='100%' stretch='100vh' vertAlign='center' horizAlign='center' rows='auto'>
					<Loader />
				</Grid>
			)}
			{healthSystems.allHealthSystems.length && !isLoadingView && (
				<>
					<Grid columns='1fr 3fr' stretch='100%'>
						<aside className='hello-list hospitals-list' data-cy='hospitalList'>
							<div>
								<header>
									<div>
										<p>{translate(!healthSystems.isRoomsOnlyView ? 'treeView' : 'listView')}</p>
									</div>
									<div className='flex flex-1 right-align-content tree-view-options'>
										<div
											onClick={() => toggleTreeView(!healthSystems.isRoomsOnlyView)}
											data-tooltip={intl.formatMessage({ id: healthSystems.isRoomsOnlyView ? 'treeView' : 'listView' })}
											data-position='bottom'
											className='cursor-pointer right-before transform-x-0-before'>
											{!healthSystems.isRoomsOnlyView && (
												<ListView color={shouldShowDarkModeIcons ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />
											)}
											{healthSystems.isRoomsOnlyView && (
												<TreeViewIcon color={shouldShowDarkModeIcons ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />
											)}
										</div>
									</div>
								</header>
								{[UserRoles.ADMIN, UserRoles.SUPER_USER].includes(getUserRole()) && (
									<div className='create-hospital' data-cy='createHospital' onClick={createNewHospital}>
										<div>
											<i className='material-icons-outlined'>add_box</i>
										</div>
										<p>{translate('createHospital')}</p>
									</div>
								)}

								<Input
									type='text'
									name='sectorSearchValue'
									placeholder={intl.formatMessage({ id: 'search' })}
									value={sectorSearchValue}
									onChange={event => setSectorSearchValue(event.target.value)}
									validationOptions={{}}
									bottomSpace='15px'
									autoComplete='off'
								/>

								{!isNewExperience && (
									<TreeView
										isMonitoring={false}
										data={healthSystems.treeData.tree}
										preSelected={treeItems}
										onAdd={onNewSector}
										onLinkClick={onTreeViewLinkClick}
										selectedSectorId={getSelectedSectorId()}
										expandAll={expandAllTreeItems}
									/>
								)}
								{isNewExperience && (
									<TreeViewMayo
										isMonitoring={false}
										data={healthSystems.treeData.tree}
										preSelected={treeItems}
										onAdd={onNewSector}
										onLinkClick={onTreeViewLinkClick}
										selectedSectorId={getSelectedSectorId()}
										expandAll={expandAllTreeItems}
									/>
								)}
							</div>
						</aside>
						<main className='main-view'>
							<div className='breadcrumb-container hs-breadcrumb-container'>
								{!match.params?.room && (
									<Breadcrumb links={healthSystems.breadcrumb} onBreadcrumbClick={onBreadcrumbClick} isFromHealthSystems={true} />
								)}
							</div>
							<section className='health-system-inner-section' data-cy='treeNavSection'>
								<Grid
									width='100%'
									vertAlign={[UserRoles.ADMIN, UserRoles.SUPER_USER].includes(getUserRole()) ? 'start' : 'center'}
									horizAlign='center'
									rows='auto'>
									{[UserRoles.ADMIN, UserRoles.SUPER_USER].includes(getUserRole()) && (
										<>
											<Route exact path='/health-system/:hospitalId' component={Hospital} />
											<Route exact path='/health-system/:hospitalId/department/:departmentId' component={Department} />
											<Route exact path='/health-system/:hospitalId/department/:departmentId/floor/:floorId' component={Floor} />
											<Route
												exact
												path='/health-system/:hospitalId/department/:departmentId/floor/:floorId/room/:roomId'
												component={Room}
											/>
										</>
									)}

									{[UserRoles.DOCTOR, UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN, UserRoles.VISITOR].includes(
										// visitor
										getUserRole()
									) && (
										<>
											{!match.params?.room && (
												<p>
													{translate('communicateWithARoom')}, <br />
													{translate('openHospitalChooseDepartment')}, <br />
													{translate('clickFloorSelectRoom')}.
												</p>
											)}
											{match.params.room && !helloDeviceId && (
												<p>
													{translate('roomNoDeviceAssigned')}. <br />
													{translate('roomsNeedDevice')} {translate('inOrderToCall')}.
												</p>
											)}
											{healthSystems.treeData.tree.length && helloDeviceId && (
												<Route
													exact
													path='/health-system/:hospitalId/department/:departmentId/floor/:floorId/room/:roomId'
													render={() => <CallPatient callEvents={callEvents} helloDeviceId={helloDeviceId} />}
												/>
											)}
										</>
									)}
								</Grid>
							</section>
						</main>
					</Grid>
				</>
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</MainLayout>
	);
};

export default HealthSystem;
