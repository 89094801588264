import {
	MeasurementTypes,
	MeasurementIcons,
	MeasurementDevices,
	MeasurementUnits,
	StressLevels,
	UnitCategoryTypes,
	UnitSystemTypes,
	MeasurementUnitOptions,
	IotDeviceState,
} from 'constants/enums.js';
import { MeasureStates } from 'constants/examinations.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import { convertSecondsToHoursFormat } from 'infrastructure/helpers/commonHelpers.js';

export function getMeasurementColor(type) {
	let color = '';
	switch (type) {
		case MeasurementTypes.HEART_RATE:
		case MeasurementTypes.PI:
		case MeasurementTypes.BLOOD_PRESSURE: {
			color = '#ff8080';
			break;
		}
		case MeasurementTypes.OXYGEN:
		case MeasurementTypes.RESPIRATORY_RATE: {
			color = '#62d3fd';
			break;
		}
		case MeasurementTypes.STEPS:
		case MeasurementTypes.FLIGHTS_CLIMBED:
		case MeasurementTypes.FALLEN_TIMES:
		case MeasurementTypes.DISTANCE_WALKING_RUNNING: {
			color = '#80cc52';
			break;
		}
		case MeasurementTypes.DIABETES:
		case MeasurementTypes.TOTAL_CHOLESTEROL:
		case MeasurementTypes.HDL_CHOLESTEROL: {
			color = '#247ef9';
			break;
		}
		case MeasurementTypes.WEIGHT:
		case MeasurementTypes.TEMPERATURE:
		case MeasurementTypes.LEAN_BODY_MASS:
		case MeasurementTypes.BODY_FAT:
		case MeasurementTypes.BODY_MASS_INDEX:
		case MeasurementTypes.WAIST_CIRCUMFERENCE: {
			color = '#ef9952';
			break;
		}
		case MeasurementTypes.SLEEP: {
			color = '#bf5bf1';
			break;
		}
		default: {
			color = '#000';
		}
	}
	return color;
}

export function getMeasurementSourceData(deviceType, intl) {
	let device;
	switch (deviceType) {
		case MeasurementDevices.FITNESS_TRACKER:
			device = {
				image: MeasurementIcons.IHEALTH_WAVE,
				title: intl.formatMessage({ id: 'fitnessTracker' }),
			};
			break;
		case MeasurementDevices.BODY_FAT_SCALE:
			device = {
				image: MeasurementIcons.APPLE_HEALTH,
				title: intl.formatMessage({ id: 'bodyWeightScale' }),
			};
			break;
		case MeasurementDevices.WEIGHT_SCALE:
			device = {
				image: MeasurementIcons.WEIGHT_SCALE,
				title: intl.formatMessage({ id: 'weightScale' }),
			};
			break;
		case MeasurementDevices.BLOOD_PRESSURE:
			device = {
				image: MeasurementIcons.SMART_BLOOD_PRESSURE,
				title: intl.formatMessage({ id: 'bloodPressure' }),
			};
			break;
		case MeasurementDevices.SMART_GLUCO_METER:
		case MeasurementDevices.GLUCOMETER:
			device = {
				image: MeasurementIcons.GLUCOMETER,
				title: intl.formatMessage({ id: 'glucometer' }),
			};
			break;
		case MeasurementDevices.INFRARED_EAR_THERMOMETER:
			device = {
				image: MeasurementIcons.INFRARED_EAR_THERMOMETER,
				title: intl.formatMessage({ id: 'infraredEarThermometer' }),
			};
			break;
		case MeasurementDevices.THERMOMETER:
			device = {
				image: MeasurementIcons.INFRARED_EAR_THERMOMETER,
				title: intl.formatMessage({ id: 'thermometer' }),
			};
			break;
		case MeasurementDevices.IPHONE:
			device = {
				image: MeasurementIcons.IPHONE,
				title: intl.formatMessage({ id: 'iphone' }),
			};
			break;
		case MeasurementDevices.PULSE_OXIMETER:
			device = {
				image: MeasurementIcons.PULSE_OXIMETER,
				title: intl.formatMessage({ id: 'pulseOximeter' }),
			};
			break;
		case MeasurementDevices.SAMSUNG_WATCH:
			device = {
				image: MeasurementIcons.SAMSUNG_WATCH,
				title: intl.formatMessage({ id: 'samsungWatch' }),
			};
			break;
		case MeasurementDevices.VITAL_KIT:
			device = {
				image: MeasurementIcons.VITAL_KIT,
				title: intl.formatMessage({ id: 'vitalKit' }),
			};
			break;
		case MeasurementDevices.STETHOSCOPE:
			device = {
				image: MeasurementIcons.STETHOSCOPE,
				title: intl.formatMessage({ id: 'stethoscope' }),
			};
			break;
		case MeasurementDevices.APPLE_WATCH:
			device = {
				image: MeasurementIcons.APPLE_WATCH,
				title: intl.formatMessage({ id: 'appleWatch' }),
			};
			break;
		default:
			device = {
				image: MeasurementIcons.UNKNOWN_SOURCE,
				title: intl.formatMessage({ id: 'unknownDevice' }),
			};
			break;
	}
	return device;
}

export function roundMeasurementValue(value, type, intl) {
	let result = value;
	if (![MeasurementTypes.BLOOD_PRESSURE, MeasurementTypes.STETHOSCOPE, MeasurementTypes.WEIGHT].includes(type)) {
		const toFixed = Number(value).toFixed(1);
		result = Number(toFixed);
	}
	if (MeasurementTypes.WEIGHT === type) {
		const toFixed = parseFloat(value).toFixed(1);
		result = toFixed;
	}
	if (MeasurementTypes.SLEEP === type) {
		result = convertSecondsToHoursFormat(result);
	}
	if (MeasurementTypes.STRESS_LEVEL === type) {
		result = intl ? stressLevel(result, intl) : result;
	}
	return result;
}

function stressLevel(value, intl) {
	let result = '';
	switch (value) {
		case StressLevels.HIGHLY_TENSE: {
			result = intl.formatMessage({ id: 'highlyTense' });
			break;
		}
		case StressLevels.SLIGHTLY_TENSE: {
			result = intl.formatMessage({ id: 'slightlyTense' });
			break;
		}
		case StressLevels.MIDLY_CALM: {
			result = intl.formatMessage({ id: 'mildlyCalm' });
			break;
		}
		case StressLevels.QUIETLY_RELEXAED: {
			result = intl.formatMessage({ id: 'quietlyRelaxed' });
			break;
		}
		case StressLevels.DEEPLY_RELAXED: {
			result = intl.formatMessage({ id: 'deeplyRelaxed' });
			break;
		}
		default: {
			result = '';
			break;
		}
	}
	return result;
}

export const convertTemperature = (value, unitType) =>
	UnitSystemTypes.METRIC === unitType || !unitType ? value : (value * 9) / 5 + 32;

export const convertWeight = (value, unitType) => (UnitSystemTypes.METRIC === unitType || !unitType ? value : value * 2.205);

export const convertDistance = (value, unitType) => (UnitSystemTypes.METRIC === unitType || !unitType ? value : value / 1.609);

export const convertWaistCircumference = (value, unitType) =>
	UnitSystemTypes.METRIC === unitType || !unitType ? value : value / 2.54;

export const convertHeight = (value, unitType) => (UnitSystemTypes.METRIC === unitType || !unitType ? value : value / 30.48);

export const convertBloodGlucose = (value, unitType) => (UnitSystemTypes.METRIC === unitType || !unitType ? value : value * 18);

export const convertMeasurementTypes = (categoryType, value, unitType, toFixed = 2) => {
	let result = value;
	switch (categoryType) {
		case UnitCategoryTypes.WEIGHT:
			result = +parseFloat(convertWeight(value, unitType)).toFixed(toFixed);
			break;
		case UnitCategoryTypes.DISTANCE:
			result = +parseFloat(convertDistance(value, unitType)).toFixed(toFixed);
			break;
		case UnitCategoryTypes.TEMPERATURE:
			result = +parseFloat(convertTemperature(value, unitType)).toFixed(toFixed);
			break;
		case UnitCategoryTypes.BLOOD_GLUCOSE:
			result = +parseFloat(convertBloodGlucose(value, unitType)).toFixed(toFixed);
			break;
		case UnitCategoryTypes.HEIGHT:
			result = +parseFloat(convertHeight(value, unitType)).toFixed(toFixed);
			break;
		default:
	}
	return result;
};

export function getDefaultUnit(type, unit) {
	let result = '';
	switch (type) {
		case MeasurementTypes.WEIGHT:
		case MeasurementTypes.LEAN_BODY_MASS:
			result = MeasurementUnits.KILOGRAMS;
			break;
		case MeasurementTypes.DISTANCE_WALKING_RUNNING:
			result = MeasurementUnits.METERS;
			break;
		case MeasurementTypes.TEMPERATURE:
			result = MeasurementUnits.CELSIUS;
			break;
		case MeasurementTypes.DIABETES:
		case MeasurementTypes.TOTAL_CHOLESTEROL:
		case MeasurementTypes.HDL_CHOLESTEROL:
			result = MeasurementUnits.MMOLL;
			break;
		case MeasurementTypes.WAIST_CIRCUMFERENCE:
			result = MeasurementUnits.CENTIMETERS;
			break;
		default:
			result = unit;
	}
	return result;
}

export const getFormattedMeasurement = selectedVitalSign =>
	selectedVitalSign.type === MeasurementTypes.BLOOD_PRESSURE
		? `${selectedVitalSign.value}/${selectedVitalSign.secondValue}`
		: selectedVitalSign.value;

export const convertImperialToMetric = (categoryType, value) => {
	let result = value;
	switch (categoryType) {
		case UnitCategoryTypes.TEMPERATURE:
			result = +convertToCelsius(value);
			break;
		case UnitCategoryTypes.BLOOD_GLUCOSE:
			result = +convertToMmol(value);
			break;
		default:
	}
	return result;
};

export const convertToCelsius = value => ((value - 32) * 5) / 9;

export const convertToMmol = value => value / 18;

export const convertFeetToCm = value => value * 30.48;

export const convertInchToCm = value => value * 2.54;

export const convertToKg = value => value / 2.205;

export const getPreferredUnit = (option, unitCategory, unitPreferences) =>
	MeasurementUnitOptions[option].value[
		unitPreferences.find(item => item.unitCategoryId === UnitCategoryTypes[unitCategory])?.unitSystemId
	]?.text;

export const getMeasurementValueBasedOnUnit = (value, convertFunction, measurement, unitPreferences) =>
	getPreferredUnit(measurement.option, measurement.unitCategory, unitPreferences) === measurement.unit
		? parseFloat(value).toFixed(measurement.decimal)
		: convertFunction;

export const getMeasurementTypes = intl => [
	{
		title: translate('heartRate'),
		type: MeasurementTypes.HEART_RATE,
		unit: MeasurementUnits.BPM,
		icon: `${healthCareCdnUrl}health-data/heart-rate.svg?v2`,
		tooltip: intl.formatMessage({ id: 'heartRateTooltip' }),
		position: 'bottom',
		id: 0,
	},
	{
		title: translate('bloodPressure'),
		type: MeasurementTypes.BLOOD_PRESSURE,
		unit: MeasurementUnits.MMHG,
		icon: `${healthCareCdnUrl}health-data/blood-presure.svg`,
		tooltip: intl.formatMessage({ id: 'bloodPressureTooltip' }),
		position: 'bottom',
		id: 1,
	},
	{
		title: translate('perfusionIndex'),
		type: MeasurementTypes.PI,
		unit: MeasurementUnits.PERCENTAGE,
		icon: `${healthCareCdnUrl}health-data/perfusion-index.svg`,
		tooltip: intl.formatMessage({ id: 'perfusionIndexTooltip' }),
		position: 'bottom',
		id: 2,
	},
	{
		title: translate('steps'),
		type: MeasurementTypes.STEPS,
		unit: translate(MeasurementUnits.STEPS),
		tooltip: intl.formatMessage({ id: 'stepsTooltip' }),
		position: 'bottom',
		id: 3,
	},

	{
		title: translate('flightsClimbed'),
		type: MeasurementTypes.FLIGHTS_CLIMBED,
		unit: translate(MeasurementUnits.FLOORS),
		tooltip: intl.formatMessage({ id: 'flightsClimbedTooltip' }),
		position: 'bottom',
		id: 4,
	},
	{
		title: translate('walkingRunningDistance'),
		type: MeasurementTypes.DISTANCE_WALKING_RUNNING,
		unit: '',
		id: 5,
	},
	{
		title: translate('weight'),
		type: MeasurementTypes.WEIGHT,
		unit: '',
		icon: `${healthCareCdnUrl}health-data/body-weight.svg`,
		position: 'bottom',
		id: 6,
	},
	{
		title: translate('leanBodyMass'),
		type: MeasurementTypes.LEAN_BODY_MASS,
		unit: '',
		tooltip: intl.formatMessage({ id: 'leanBodyMassTooltip' }),
		icon: `${healthCareCdnUrl}health-data/body-weight.svg`,
		position: 'bottom',
		id: 7,
	},
	{
		title: translate('bodyFatPercentage'),
		type: MeasurementTypes.BODY_FAT,
		unit: MeasurementUnits.PERCENTAGE,
		tooltip: intl.formatMessage({ id: 'bodyFatPercentageTooltip' }),
		icon: `${healthCareCdnUrl}health-data/body-weight.svg`,
		position: 'bottom',
		id: 8,
	},
	{
		title: translate('bmi'),
		type: MeasurementTypes.BODY_MASS_INDEX,
		unit: MeasurementUnits.BMI,
		tooltip: intl.formatMessage({ id: 'bodyMassIndexTooltip' }),
		icon: `${healthCareCdnUrl}health-data/body-weight.svg`,
		position: 'bottom',
		id: 9,
	},
	{
		title: translate('bodyTemperature'),
		type: MeasurementTypes.TEMPERATURE,
		unit: '',
		tooltip: intl.formatMessage({ id: 'bodyTemperatureTooltip' }),
		position: 'bottom',
		id: 10,
	},
	{
		title: translate('oxygenSaturation'),
		type: MeasurementTypes.OXYGEN,
		unit: MeasurementUnits.PERCENTAGE,
		icon: `${healthCareCdnUrl}health-data/oxygen-saturation.svg`,
		tooltip: intl.formatMessage({ id: 'oxygenSaturationTooltip' }),
		position: 'bottom',
		id: 11,
	},
	{
		title: translate('sleepAnalysis'),
		type: MeasurementTypes.SLEEP,
		unit: translate(MeasurementUnits.HOURS),
		tooltip: intl.formatMessage({ id: 'sleepAnalysisTooltip' }),
		position: 'bottom',
		id: 12,
	},
	{
		title: translate('bloodGlucose'),
		type: MeasurementTypes.DIABETES,
		unit: '',
		icon: `${healthCareCdnUrl}health-data/blood-glucose-level.svg`,
		tooltip: intl.formatMessage({ id: 'bloodGlucoseTooltip' }),
		position: 'bottom',
		id: 13,
	},
	{
		title: translate('heart'),
		type: MeasurementTypes.HEART,
		unit: MeasurementUnits.SECONDS,
		icon: `${healthCareCdnUrl}stetho_blue.svg?v3`,
		tooltip: intl.formatMessage({ id: 'stethoscopeTooltip' }),
		position: 'bottom',
		id: 14,
	},
	{
		title: translate('lungs'),
		type: MeasurementTypes.LUNGS,
		unit: MeasurementUnits.SECONDS,
		icon: `${healthCareCdnUrl}stetho_blue.svg?v3`,
		tooltip: intl.formatMessage({ id: 'stethoscopeTooltip' }),
		position: 'bottom',
		id: 15,
	},
	{
		title: translate('numberTimesFallen'),
		type: MeasurementTypes.FALLEN_TIMES,
		unit: '',
		icon: `${healthCareCdnUrl}footer-icons/number-of-falls.svg`,
		position: 'bottom',
		id: 16,
	},
	{
		title: translate('respiratoryRate'),
		type: MeasurementTypes.RESPIRATORY_RATE,
		unit: MeasurementUnits.BPM,
		icon: `${healthCareCdnUrl}health-data/oxygen-saturation.svg`,
		tooltip: intl.formatMessage({ id: 'respiratoryRateTooltip' }),
		position: 'bottom',
		id: 17,
	},
	{
		title: translate('totalCholesterol'),
		type: MeasurementTypes.TOTAL_CHOLESTEROL,
		unit: '',
		icon: `${healthCareCdnUrl}health-data/blood-glucose-level.svg`,
		tooltip: intl.formatMessage({ id: 'totalCholesterolTooltip' }),
		position: 'bottom',
		id: 18,
	},
	{
		title: translate('hdlCholesterol'),
		type: MeasurementTypes.HDL_CHOLESTEROL,
		unit: '',
		icon: `${healthCareCdnUrl}health-data/blood-glucose-level.svg`,
		tooltip: intl.formatMessage({ id: 'hdlCholesterolToolip' }),
		position: 'bottom',
		id: 19,
	},
	{
		title: translate('waistCircumference'),
		type: MeasurementTypes.WAIST_CIRCUMFERENCE,
		unit: '',
		icon: `${healthCareCdnUrl}health-data/body-weight.svg`,
		tooltip: intl.formatMessage({ id: 'waistCircumferenceTooltip' }),
		position: 'bottom',
		id: 20,
	},
	{
		title: translate('spirometer'),
		type: MeasurementTypes.SPIROMETER,
		unit: '%',
		icon: `${healthCareCdnUrl}health-data/spirometer.svg`,
		tooltip: intl.formatMessage({ id: 'spirometerTooltip' }),
		position: 'bottom',
		id: 21,
	},
];

export const getGalaxyWatchMeasurementTypes = intl => [
	{
		title: 'Blood Oxygen',
		type: MeasurementTypes.OXYGEN,
		unit: MeasurementUnits.PERCENTAGE,
		icon: `${healthCareCdnUrl}health-data/oxygen-saturation.svg`,
		tooltip: intl.formatMessage({ id: 'oxygenSaturationTooltip' }),
		position: 'bottom',
		id: 0,
	},
	{
		title: translate('heartRate'),
		type: MeasurementTypes.HEART_RATE,
		unit: MeasurementUnits.BPM,
		icon: `${healthCareCdnUrl}health-data/heart-rate.svg?v2`,
		tooltip: intl.formatMessage({ id: 'heartRateTooltip' }),
		position: 'bottom',
		id: 1,
	},
	{
		title: translate('bloodPressure'),
		type: MeasurementTypes.BLOOD_PRESSURE,
		unit: MeasurementUnits.MMHG,
		icon: `${healthCareCdnUrl}health-data/blood-presure.svg`,
		tooltip: intl.formatMessage({ id: 'bloodPressureTooltip' }),
		position: 'bottom',
		id: 2,
	},
	{
		title: translate('sleep'),
		type: MeasurementTypes.SLEEP,
		unit: translate(MeasurementUnits.HOURS),
		tooltip: intl.formatMessage({ id: 'sleepAnalysisTooltip' }),
		position: 'bottom',
		id: 3,
	},
	{
		title: translate('walkingRunningDistance'),
		type: MeasurementTypes.DISTANCE_WALKING_RUNNING,
		unit: '',
		id: 6,
	},
	{
		title: translate('steps'),
		type: MeasurementTypes.STEPS,
		unit: translate(MeasurementUnits.STEPS),
		tooltip: intl.formatMessage({ id: 'stepsTooltip' }),
		position: 'bottom',
		id: 4,
	},
	{
		title: translate('fallDetection'),
		type: MeasurementTypes.FALLEN_TIMES,
		unit: '',
		icon: `${healthCareCdnUrl}footer-icons/number-of-falls.svg`,
		position: 'bottom',
		id: 5,
	},

	{
		title: translate('stressLevel'),
		type: MeasurementTypes.STRESS_LEVEL,
		unit: '',
		icon: '',
		tooltip: '',
		position: 'bottom',
		id: 7,
	},
	{
		title: translate('bodyComposition'),
		type: '',
		unit: '',
		icon: '',
		position: 'bottom',
		id: 8,
	},
	{
		title: 'ECG',
		type: '',
		unit: '',
		icon: '',
		position: 'bottom',
		id: 9,
	},

	{
		title: translate('snoreDetection'),
		type: '',
		unit: '',
		icon: '',
		position: 'bottom',
		id: 10,
	},
	{
		title: 'VO2 MAX',
		type: '',
		unit: '',
		icon: '',
		position: 'bottom',
		id: 11,
	},
	{
		title: translate('caloriesBurned'),
		type: '',
		unit: '',
		icon: '',
		position: 'bottom',
		id: 12,
	},
];

export const getButtonText = deviceState => {
	let buttonText = '';
	switch (deviceState) {
		case IotDeviceState.CONNECTED:
		case IotDeviceState.STRIP_IN: {
			buttonText = MeasureStates.START_MEASURING;
			break;
		}
		case IotDeviceState.CONNECTING: {
			buttonText = MeasureStates.CONNECTING;
			break;
		}
		case IotDeviceState.MEASURING: {
			buttonText = MeasureStates.MEASURING;
			break;
		}
		case IotDeviceState.COMPLETED: {
			buttonText = MeasureStates.MEASURE_COMPLETED;
			break;
		}
		case IotDeviceState.STAND_ON_SCALE: {
			buttonText = MeasureStates.STAND_ON_SCALE;
			break;
		}
		case IotDeviceState.COULD_NOT_GET_PATIENT_DATA: {
			buttonText = MeasureStates.COULD_NOT_GET_PATIENT_DATA;
			break;
		}
		case IotDeviceState.DISCONNECTED: {
			buttonText = MeasureStates.DISCONNECTED;
			break;
		}
		case IotDeviceState.MISSING_PATIENT_INFO: {
			buttonText = MeasureStates.MISSING_PATIENT_INFO;
			break;
		}
		case IotDeviceState.TRYING_TO_CONNECT: {
			buttonText = MeasureStates.TRYING_TO_CONNECT;
			break;
		}
		case IotDeviceState.SCAN_FAILED: {
			buttonText = MeasureStates.SCAN_FAILED;
			break;
		}
		case IotDeviceState.CONNECTION_FAIL: {
			buttonText = MeasureStates.CONNECTION_FAILED;
			break;
		}
		case IotDeviceState.SCAN_NOT_FOUND: {
			buttonText = MeasureStates.SCAN_NOT_FOUND;
			break;
		}
		case IotDeviceState.PUT_NEW_STRIP: {
			buttonText = MeasureStates.PUT_NEW_STRIP;
			break;
		}
		default: {
			buttonText = MeasureStates.CONNECT;
		}
	}
	return buttonText;
};

export const getScaleAlertText = deviceState => {
	let alertText = '';
	switch (deviceState) {
		case IotDeviceState.SHOES_ON: {
			alertText = MeasureStates.SHOES_ON;
			break;
		}
		case IotDeviceState.UNREALISTIC_WEIGHT: {
			alertText = MeasureStates.UNREALISTIC_WEIGHT;
			break;
		}
		case IotDeviceState.MISSING_HEIGHT: {
			alertText = MeasureStates.MISSING_HEIGHT;
			break;
		}
		case IotDeviceState.MISSING_WEIGHT: {
			alertText = MeasureStates.MISSING_WEIGHT;
			break;
		}
		case IotDeviceState.MISSING_GENDER: {
			alertText = MeasureStates.MISSING_GENDER;
			break;
		}
		default: {
			alertText = 'additionalDataMissing';
		}
	}
	return alertText;
};

export const getMeasurementDetails = (measurementType, intl, getUnitPreference) => {
	switch (measurementType) {
		case MeasurementTypes.HEART_RATE:
			return {
				link: intl.formatMessage({ id: 'heartRate' }),
				type: MeasurementTypes.HEART_RATE,
				unit: MeasurementUnits.BPM,
				icon: `${healthCareCdnUrl}health-data/heart.svg`,
				minimumValue: 30,
				maximumValue: 350,
				isDecimal: false,
				id: 1,
			};
		case MeasurementTypes.BLOOD_PRESSURE:
			return {
				link: intl.formatMessage({ id: 'bloodPressure' }),
				type: MeasurementTypes.BLOOD_PRESSURE,
				unit: 'mmHg',
				icon: `${healthCareCdnUrl}health-data/heart.svg`,
				minimumValue: 40,
				maximumValue: 300,
				minimumValueSecond: 30,
				maximumValueSecond: 200,
				isDecimal: false,
				id: 2,
			};
		case MeasurementTypes.PI:
			return {
				link: intl.formatMessage({ id: 'perfusionIndex' }),
				type: MeasurementTypes.PI,
				unit: '%',
				icon: `${healthCareCdnUrl}health-data/heart.svg`,
				minimumValue: 0,
				maximumValue: 20,
				isDecimal: true,
				id: 3,
			};
		case MeasurementTypes.STEPS:
			return {
				link: intl.formatMessage({ id: 'steps' }),
				type: MeasurementTypes.STEPS,
				unit: MeasurementUnits.STEPS,
				icon: `${healthCareCdnUrl}health-data/activity.svg`,
				minimumValue: 0,
				maximumValue: 90000,
				isDecimal: false,
				id: 4,
			};
		case MeasurementTypes.FLIGHTS_CLIMBED:
			return {
				link: intl.formatMessage({ id: 'flightsClimbed' }),
				type: MeasurementTypes.FLIGHTS_CLIMBED,
				unit: MeasurementTypes.FLIGHTS_CLIMBED,
				icon: `${healthCareCdnUrl}health-data/activity.svg`,
				minimumValue: 0,
				maximumValue: 500,
				isDecimal: false,
				id: 5,
			};
		case MeasurementTypes.DISTANCE_WALKING_RUNNING:
			return {
				link: intl.formatMessage({ id: 'walkingRunningDistance' }),
				type: MeasurementTypes.DISTANCE_WALKING_RUNNING,
				unit: getUnitPreference(UnitCategoryTypes.DISTANCE)?.unit,
				icon: `${healthCareCdnUrl}health-data/activity.svg`,
				minimumValue: convertMeasurementTypes(
					UnitCategoryTypes.DISTANCE,
					0,
					getUnitPreference(UnitCategoryTypes.DISTANCE)?.unitSystemId
				),
				maximumValue: convertMeasurementTypes(
					UnitCategoryTypes.DISTANCE,
					15000,
					getUnitPreference(UnitCategoryTypes.DISTANCE)?.unitSystemId
				),
				isDecimal: true,
				id: 6,
				categoryId: UnitCategoryTypes.DISTANCE,
				unitSystemId: getUnitPreference(UnitCategoryTypes.DISTANCE)?.unitSystemId,
			};
		case MeasurementTypes.WEIGHT:
			return {
				link: intl.formatMessage({ id: 'weight' }),
				type: MeasurementTypes.WEIGHT,
				unit: getUnitPreference(UnitCategoryTypes.WEIGHT)?.unit,
				icon: `${healthCareCdnUrl}health-data/body-measurements.svg`,
				minimumValue: convertMeasurementTypes(
					UnitCategoryTypes.WEIGHT,
					5,
					getUnitPreference(UnitCategoryTypes.WEIGHT)?.unitSystemId
				),
				maximumValue: convertMeasurementTypes(
					UnitCategoryTypes.WEIGHT,
					400,
					getUnitPreference(UnitCategoryTypes.WEIGHT)?.unitSystemId
				),
				isDecimal: true,
				id: 7,
				categoryId: UnitCategoryTypes.WEIGHT,
				unitSystemId: getUnitPreference(UnitCategoryTypes.WEIGHT)?.unitSystemId,
			};
		case MeasurementTypes.TEMPERATURE:
			return {
				link: intl.formatMessage({ id: 'bodyTemperature' }),
				type: MeasurementTypes.TEMPERATURE,
				unit: getUnitPreference(UnitCategoryTypes.TEMPERATURE)?.unit,
				icon: `${healthCareCdnUrl}health-data/body-measurements.svg`,
				minimumValue: getUnitPreference(UnitCategoryTypes.TEMPERATURE)?.unitSystemId === UnitSystemTypes.METRIC ? 33 : 91.4,
				maximumValue: getUnitPreference(UnitCategoryTypes.TEMPERATURE)?.unitSystemId === UnitSystemTypes.METRIC ? 42 : 107.6,
				isDecimal: true,
				id: 8,
				categoryId: UnitCategoryTypes.TEMPERATURE,
				unitSystemId: getUnitPreference(UnitCategoryTypes.TEMPERATURE)?.unitSystemId,
			};

		case MeasurementTypes.LEAN_BODY_MASS:
			return {
				link: intl.formatMessage({ id: 'leanBodyMass' }),
				type: MeasurementTypes.LEAN_BODY_MASS,
				unit: getUnitPreference(UnitCategoryTypes.WEIGHT)?.unit,
				icon: `${healthCareCdnUrl}health-data/body-measurements.svg`,
				minimumValue: convertMeasurementTypes(
					UnitCategoryTypes.WEIGHT,
					30,
					getUnitPreference(UnitCategoryTypes.WEIGHT)?.unitSystemId
				),
				maximumValue: convertMeasurementTypes(
					UnitCategoryTypes.WEIGHT,
					275,
					getUnitPreference(UnitCategoryTypes.WEIGHT)?.unitSystemId
				),
				isDecimal: true,
				id: 9,
				categoryId: UnitCategoryTypes.WEIGHT,
				unitSystemId: getUnitPreference(UnitCategoryTypes.WEIGHT)?.unitSystemId,
			};
		case MeasurementTypes.BODY_FAT:
			return {
				link: intl.formatMessage({ id: 'bodyFatPercentage' }),
				type: MeasurementTypes.BODY_FAT,
				unit: '%',
				icon: `${healthCareCdnUrl}health-data/body-measurements.svg`,
				minimumValue: 0,
				maximumValue: 75,
				isDecimal: true,
				id: 10,
			};
		case MeasurementTypes.BODY_MASS_INDEX:
			return {
				link: intl.formatMessage({ id: 'bmi' }),
				type: MeasurementTypes.BODY_MASS_INDEX,
				unit: 'BMI',
				icon: `${healthCareCdnUrl}health-data/body-measurements.svg`,
				minimumValue: 7,
				maximumValue: 75,
				isDecimal: true,
				id: 11,
			};
		case MeasurementTypes.WAIST_CIRCUMFERENCE:
			return {
				link: intl.formatMessage({ id: 'waistCircumference' }),
				type: MeasurementTypes.WAIST_CIRCUMFERENCE,
				unit: getUnitPreference(UnitCategoryTypes.HEIGHT)?.unit,
				icon: `${healthCareCdnUrl}health-data/body-measurements.svg`,
				minimumValue: convertMeasurementTypes(
					UnitCategoryTypes.HEIGHT,
					45,
					getUnitPreference(UnitCategoryTypes.HEIGHT)?.unitSystemId
				),
				maximumValue: convertMeasurementTypes(
					UnitCategoryTypes.HEIGHT,
					250,
					getUnitPreference(UnitCategoryTypes.HEIGHT)?.unitSystemId
				),
				isDecimal: true,
				id: 12,
				categoryId: UnitCategoryTypes.HEIGHT,
				unitSystemId: getUnitPreference(UnitCategoryTypes.HEIGHT)?.unitSystemId,
			};
		case MeasurementTypes.OXYGEN:
			return {
				link: intl.formatMessage({ id: 'oxygenSaturation' }),
				type: MeasurementTypes.OXYGEN,
				unit: '%',
				icon: `${healthCareCdnUrl}health-data/respiratory.svg`,
				minimumValue: 70,
				maximumValue: 100,
				isDecimal: true,
				id: 13,
			};
		case MeasurementTypes.RESPIRATORY_RATE:
			return {
				link: intl.formatMessage({ id: 'respiratoryRate' }),
				type: MeasurementTypes.RESPIRATORY_RATE,
				unit: MeasurementUnits.BPM,
				icon: `${healthCareCdnUrl}health-data/respiratory.svg`,
				minimumValue: 0,
				maximumValue: 60,
				isDecimal: false,
				id: 14,
			};
		case MeasurementTypes.SLEEP:
			return {
				link: intl.formatMessage({ id: 'sleepAnalysis' }),
				type: MeasurementTypes.SLEEP,
				unit: 'hrs',
				icon: `${healthCareCdnUrl}health-data/sleep.svg`,
				minimumValue: 0,
				maximumValue: 24,
				isDecimal: true,
				id: 15,
			};
		case MeasurementTypes.DIABETES:
			return {
				link: intl.formatMessage({ id: 'bloodGlucose' }),
				type: MeasurementTypes.DIABETES,
				unit: getUnitPreference(UnitCategoryTypes.BLOOD_GLUCOSE)?.unit,
				icon: `${healthCareCdnUrl}health-data/blood-glucose.svg`,
				minimumValue: convertMeasurementTypes(
					UnitCategoryTypes.BLOOD_GLUCOSE,
					0,
					getUnitPreference(UnitCategoryTypes.BLOOD_GLUCOSE)?.unitSystemId
				),
				maximumValue: convertMeasurementTypes(
					UnitCategoryTypes.BLOOD_GLUCOSE,
					50,
					getUnitPreference(UnitCategoryTypes.BLOOD_GLUCOSE)?.unitSystemId
				),
				isDecimal: true,
				id: 16,
				categoryId: UnitCategoryTypes.BLOOD_GLUCOSE,
				unitSystemId: getUnitPreference(UnitCategoryTypes.BLOOD_GLUCOSE)?.unitSystemId,
			};
		case MeasurementTypes.FALLEN_TIMES:
			return {
				link: intl.formatMessage({ id: 'numberTimesFallen' }),
				type: MeasurementTypes.FALLEN_TIMES,
				unit: '',
				icon: `${healthCareCdnUrl}health-data/number-of-times-fallen.svg`,
				minimumValue: 1,
				maximumValue: 30,
				isDecimal: false,
				id: 18,
			};
		case MeasurementTypes.TOTAL_CHOLESTEROL:
			return {
				link: intl.formatMessage({ id: 'totalCholesterol' }),
				type: MeasurementTypes.TOTAL_CHOLESTEROL,
				unit: getUnitPreference(UnitCategoryTypes.BLOOD_GLUCOSE)?.unit,
				icon: `${healthCareCdnUrl}health-data/blood-glucose.svg`,
				minimumValue: convertMeasurementTypes(
					UnitCategoryTypes.BLOOD_GLUCOSE,
					5.6,
					getUnitPreference(UnitCategoryTypes.BLOOD_GLUCOSE)?.unitSystemId
				),
				maximumValue: convertMeasurementTypes(
					UnitCategoryTypes.BLOOD_GLUCOSE,
					14.4,
					getUnitPreference(UnitCategoryTypes.BLOOD_GLUCOSE)?.unitSystemId
				),
				isDecimal: true,
				id: 19,
				categoryId: UnitCategoryTypes.BLOOD_GLUCOSE,
				unitSystemId: getUnitPreference(UnitCategoryTypes.BLOOD_GLUCOSE)?.unitSystemId,
			};
		case MeasurementTypes.HDL_CHOLESTEROL:
			return {
				link: intl.formatMessage({ id: 'hdlCholesterol' }),
				type: MeasurementTypes.HDL_CHOLESTEROL,
				unit: getUnitPreference(UnitCategoryTypes.BLOOD_GLUCOSE)?.unit,
				icon: `${healthCareCdnUrl}health-data/blood-glucose.svg`,
				minimumValue: convertMeasurementTypes(
					UnitCategoryTypes.BLOOD_GLUCOSE,
					1.1,
					getUnitPreference(UnitCategoryTypes.BLOOD_GLUCOSE)?.unitSystemId
				),
				maximumValue: convertMeasurementTypes(
					UnitCategoryTypes.BLOOD_GLUCOSE,
					3.3,
					getUnitPreference(UnitCategoryTypes.BLOOD_GLUCOSE)?.unitSystemId
				),
				isDecimal: true,
				id: 20,
				categoryId: UnitCategoryTypes.BLOOD_GLUCOSE,
				unitSystemId: getUnitPreference(UnitCategoryTypes.BLOOD_GLUCOSE)?.unitSystemId,
			};
		default:
			return {};
	}
};

export const generateHealthDataTabs = devices => {
	let healthDataTabs = { healthSummary: { type: 0 } };
	let typeNumber = 1;

	const bodyMeasurements = getBodyMeasurements(
		devices.includes(MeasurementTypes.WEIGHT),
		devices.includes(MeasurementTypes.TEMPERATURE)
	);
	const heartMeasurements = getHeartMeasurements(
		devices.includes(MeasurementTypes.HEART_RATE),
		devices.includes(MeasurementTypes.BLOOD_PRESSURE)
	);
	const respiratory = ['respiratory', MeasurementTypes.OXYGEN];
	const bloodGlucose = [MeasurementTypes.DIABETES];
	const stethoscope = [MeasurementTypes.STETHOSCOPE, 'heartStethoscope', 'lungsStethoscope'];
	const defaultArray = [heartMeasurements, bodyMeasurements, respiratory, bloodGlucose, stethoscope];

	let measurementTypes = [];

	defaultArray.forEach(arr => {
		if (devices.some(element => arr.includes(element))) {
			measurementTypes.push(arr);
		}
	});

	measurementTypes.forEach(type => {
		type.forEach(type => {
			healthDataTabs[type] = { type: typeNumber };
			typeNumber++;
		});
	});

	return healthDataTabs;
};

const getBodyMeasurements = (weight, temperature) => {
	if (weight && !temperature) {
		return ['bodyMeasurements', MeasurementTypes.WEIGHT];
	} else if (temperature && !weight) {
		return ['bodyMeasurements', MeasurementTypes.TEMPERATURE];
	} else {
		return [
			'bodyMeasurements',
			MeasurementTypes.WEIGHT,
			MeasurementTypes.TEMPERATURE,
			MeasurementTypes.LEAN_BODY_MASS,
			MeasurementTypes.BODY_FAT,
			MeasurementTypes.BODY_MASS_INDEX,
			MeasurementTypes.WAIST_CIRCUMFERENCE,
		];
	}
};

const getHeartMeasurements = (heartRate, bloodPressure) => {
	if (heartRate && !bloodPressure) {
		return [MeasurementTypes.HEART, MeasurementTypes.HEART_RATE, MeasurementTypes.PI];
	} else if (bloodPressure && !heartRate) {
		return [MeasurementTypes.HEART, MeasurementTypes.BLOOD_PRESSURE];
	}
	return [MeasurementTypes.HEART, MeasurementTypes.HEART_RATE, MeasurementTypes.BLOOD_PRESSURE, MeasurementTypes.PI];
};
