import translate from 'i18n-translations/translate.jsx';
import { getUserRole } from 'infrastructure/auth.js';
import {
	AdminMenu,
	DigitalClinicianMenu,
	DoctorMenu,
	NurseMenu,
	PatientMenu,
	SuperAdminMenu,
	SuperUserMenu,
	VirtualSitterMenu,
} from 'constants/configurationEnums.js';
import { TreeHierarchyType } from 'constants/enums.js';
import { LOCALES } from 'i18n-translations/locales.js';
import { getConfigurationMenu, getConfigurationValue } from 'infrastructure/helpers/commonHelpers.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';

const menuUrl = `${healthCareCdnUrl}left-menu/`;

export const landingPageNav = [
	{
		link: 'Solutions',
		title: 'Solutions',
	},
	{
		link: 'HELLO2',
		title: 'HELLO 2',
	},
	{
		link: 'AI&IoT',
		title: 'AI & IoT',
	},
	{
		link: 'Providers',
		title: 'Providers',
	},
];

const CompaniesBtn = {
	id: 1,
	linkTo: '/companies',
	title: translate('companies'),
	image: `${menuUrl}Hospitals-1.svg?v2`,
	selectedImage: `${menuUrl}Hospitals.svg?v2`,
};

const UserManagementBtn = {
	id: 2,
	linkTo: '/users',
	title: translate('userManagement'),
	image: `${menuUrl}UserManagement-1.svg?v2`,
	selectedImage: `${menuUrl}UserManagement.svg?v2`,
};

const ConfigurationsBtn = {
	id: 3,
	linkTo: '/configurations',
	title: translate('configurations'),
	image: `${menuUrl}Configurations-1.svg?v2`,
	selectedImage: `${menuUrl}Configurations.svg?v2`,
};

const QueueManagementButton = {
	id: 4,
	linkTo: '/queue-management',
	title: translate('queueManagement'),
	image: `${menuUrl}queue-management.svg`,
	selectedImage: `${menuUrl}queue-management-active.svg`,
};

const AuditLogsBtn = {
	id: 5,
	linkTo: '/audit-logs',
	title: translate('auditLogs'),
	image: `${menuUrl}CallLogs-1.svg?v2`,
	selectedImage: `${menuUrl}CallLogs.svg?v2`,
};

const HomeBtn = {
	id: 6,
	linkTo: '/',
	title: translate('homePage'),
	image: `${menuUrl}home-icon.svg`,
	selectedImage: `${menuUrl}home-icon-active.svg`,
	darkModeUrl: 'home-icon',
};

const RecentBtn = {
	id: 7,
	linkTo: '/recent',
	title: translate('recentLowerCase'),
	image: `${menuUrl}Contacts-1.svg`,
	selectedImage: `${menuUrl}Contacts.svg`,
};

const AssignmentBtn = {
	id: 8,
	icon: 'assignment',
	linkTo: `/health-data`,
	title: translate('health'),
	image: `${menuUrl}Health-1.svg`,
	selectedImage: `${menuUrl}Health.svg`,
};

const SummaryBtn = {
	id: 9,
	linkTo: '/summary',
	title: translate('summary'),
	image: `${menuUrl}summary-1.svg`,
	selectedImage: `${menuUrl}summary.svg`,
};

const HelloDevicesBtn = {
	id: 10,
	linkTo: '/my-hello-devices',
	title: 'HELLO',
	image: `${menuUrl}Hello-1.svg`,
	selectedImage: `${menuUrl}Hello.svg`,
};

const PatientsBtn = {
	id: 11,
	title: translate('patients'),
	image: `${menuUrl}Contacts-1.svg`,
	selectedImage: `${menuUrl}Contacts.svg`,
	darkModeUrl: 'Contacts-1',
};

const AppointmentsBtn = {
	id: 12,
	linkTo: '/appointments',
	title: translate('appointments'),
	image: `${menuUrl}Appointments-1.svg`,
	selectedImage: `${menuUrl}Appointments.svg`,
	darkModeUrl: 'appointments',
};

const OrganizationBtn = {
	id: 13,
	linkTo: '/health-system',
	title: translate('organization'),
	image: `${menuUrl}Organisation-1.svg`,
	selectedImage: `${menuUrl}Organisation.svg`,
};

const CallLogsBtn = {
	id: 14,
	linkTo: '/call-logs',
	title: translate('callLogs'),
	image: `${menuUrl}CallLogs-1.svg?v2`,
	selectedImage: `${menuUrl}CallLogs.svg?v2`,
};

const HospitalsBtn = {
	id: 15,
	icon: 'panorama_fish_eye',
	linkTo: '/health-system',
	title: translate('rounding'),
	image: `${menuUrl}Hospitals-1.svg?v2`,
	selectedImage: `${menuUrl}Hospitals.svg?v2`,
	darkModeUrl: 'rounding',
};

const WaitingRoomBtn = {
	id: 16,
	linkTo: '/waiting-room',
	title: translate('waitingRoom'),
	image: `${menuUrl}waiting-room-1.svg`,
	selectedImage: `${menuUrl}waiting-room.svg`,
	darkModeUrl: 'waiting-room',
	hasWaitingRoomCount: true,
};

const SessionsQueueBtn = {
	id: 17,
	linkTo: '/sessions-queue',
	title: translate('sessionsQueue'),
	image: `${menuUrl}waiting-room-1.svg`,
	selectedImage: `${menuUrl}waiting-room.svg`,
	darkModeUrl: 'waiting-room',
	hasSessionsQueueCount: true,
};

const WaitingPrimaryCareRoomBtn = {
	id: 18,
	linkTo: '/waiting-room-inperson',
	title: translate('waitingRoom'),
	image: `${menuUrl}waiting-room-1.svg`,
	selectedImage: `${menuUrl}waiting-room.svg`,
	hasWaitingRoomCount: true,
};

const NursesChatBtn = {
	id: 19,
	title: translate('chat'),
	image: `${menuUrl}Contacts-1.svg`,
	selectedImage: `${menuUrl}Contacts.svg`,
	darkModeUrl: 'chat',
};

const TeamsBtn = {
	id: 20,
	linkTo: '/teams',
	title: translate('teams'),
	image: `${menuUrl}teams.svg`,
	selectedImage: `${menuUrl}teams-active.svg`,
	darkModeUrl: 'teams',
};

const MonitoringBtn = {
	id: 21,
	icon: 'remove_red_eye',
	linkTo: '/monitoring',
	title: translate('monitoring'),
	image: `${menuUrl}Monitoring-1.svg?v2`,
	selectedImage: `${menuUrl}Monitoring.svg?v2`,
	darkModeUrl: 'monitoring',
};

const RpmButon = {
	id: 22,
	linkTo: '/patient-monitoring',
	title: translate('rpm'),
	image: `${menuUrl}vsm.svg`,
	selectedImage: `${menuUrl}vsm-1.svg`,
	darkModeUrl: 'vsm',
};

const RpmDigitalClinicianButon = {
	id: 23,
	linkTo: '/vital-signs-monitoring',
	title: translate('rpm'),
	image: `${menuUrl}vsm.svg`,
	selectedImage: `${menuUrl}vsm-1.svg`,
	darkModeUrl: 'vsm',
};

const LogisticsButton = {
	id: 24,
	linkTo: '/logistics',
	title: translate('logistics'),
	image: `${menuUrl}logistics.svg`,
	selectedImage: `${menuUrl}logistics-blue.svg`,
	darkModeUrl: 'logistics',
};

// const NicuChatBtn = {
// 	id: 10,
// 	linkTo: '/family-members-chat',
// 	title: translate('nicuChat'),
// 	image: `${menuUrl}visitors-black.svg`,
// 	selectedImage: `${menuUrl}visitors-blue.svg`,
// };

const VisitorsBtn = {
	id: 25,
	linkTo: '/visitors',
	title: translate('visitors'),
	image: `${menuUrl}visitors-black.svg`,
	selectedImage: `${menuUrl}visitors-blue.svg`,
	darkModeUrl: 'visitors',
};

const DashboardBtn = {
	id: 26,
	linkTo: '/dashboard',
	title: translate('dashboard'),
	image: `${menuUrl}dashboard.svg`,
	selectedImage: `${menuUrl}dashboard-active.svg`,
	darkModeUrl: 'dashboard',
};

const CheckedInPatients = {
	id: 27,
	linkTo: '/digital-clinician/patients',
	title: translate('patients'),
	image: `${menuUrl}Contacts-1.svg`,
	selectedImage: `${menuUrl}Contacts.svg`,
};

const CheckInDCbtn = {
	id: 28,
	linkTo: '/check-in-dc',
	title: translate('checkInPage'),
	image: `${menuUrl}check-in.svg`,
	selectedImage: `${menuUrl}check-in-blue.svg`,
};

const CheckInVCPbtn = {
	id: 29,
	linkTo: '/nurse-home',
	title: translate('checkInPage'),
	image: `${menuUrl}check-in.svg`,
	selectedImage: `${menuUrl}check-in-blue.svg`,
};

const WaitingResultsBtn = {
	id: 30,
	linkTo: '/waiting-results',
	title: translate('waitingResults'),
	image: `${menuUrl}Contacts-1.svg`,
	selectedImage: `${menuUrl}Contacts.svg`,
};

const RegisterPatientBtn = {
	id: 31,
	linkTo: '/check-in-patient',
	title: translate('checkInPatient'),
	image: `${menuUrl}register-patient.svg`,
	selectedImage: `${menuUrl}register-patient-active.svg`,
};

const VisitsBtn = {
	id: 32,
	linkTo: '/front-desk-visits',
	title: translate('visits'),
	image: `${menuUrl}Contacts-1.svg`,
	selectedImage: `${menuUrl}Contacts.svg`,
};

const AlertCenterBtn = {
	id: 33,
	linkTo: '/ambient-monitoring',
	title: translate('ambientMonitoring'),
	image: `${menuUrl}alerting-center-dark-gray.svg`,
	selectedImage: `${menuUrl}alerting-center-blue.svg`,
	darkModeUrl: 'alerting-center',
};

const CareEventsBtn = {
	id: 34,
	linkTo: '/care-events-dashboard',
	title: translate('careEvents'),
	image: `${menuUrl}dashboard.svg`,
	selectedImage: `${menuUrl}dashboard-active.svg`,
	darkModeUrl: 'dashboard',
};

const FleetManagementBtn = {
	id: 35,
	linkTo: '/fleet-management',
	title: translate('fleetManagement'),
	image: `${menuUrl}fleet-management-gray.svg?v2`,
	selectedImage: `${menuUrl}fleet-management-active.svg`,
	darkModeUrl: 'fleet-management',
};

const showOption = (configurations, settingId) => {
	const adminConfigurableMenu = getConfigurationMenu(configurations.adminConfigurableMenu, getUserRole());
	const configurableMenu = getConfigurationMenu(configurations.configurableMenu, getUserRole());
	return getConfigurationValue(adminConfigurableMenu[settingId]) && getConfigurationValue(configurableMenu[settingId]);
};

const showOptionMyConfigs = (configurations, settingId) => {
	const configurableMenu = getConfigurationMenu(configurations.configurableMenu, getUserRole());
	return getConfigurationValue(configurableMenu[settingId]);
};

export const getPatientMenu = (configurations, language) =>
	[
		showOptionMyConfigs(configurations, PatientMenu.HOME) && { ...HomeBtn, linkTo: `/patient-home` },
		showOptionMyConfigs(configurations, PatientMenu.RECENT) && RecentBtn,
		showOptionMyConfigs(configurations, PatientMenu.APPOINTMENTS) && AppointmentsBtn,
		showOptionMyConfigs(configurations, PatientMenu.HEALTH) && AssignmentBtn,
		showOptionMyConfigs(configurations, PatientMenu.SUMMARY) && { ...SummaryBtn, isWordBreak: language === LOCALES.GERMAN },
		showOptionMyConfigs(configurations, PatientMenu.HELLO) && HelloDevicesBtn,
	].filter(Boolean);

export const getAdminMenu = (configurations, language) => {
	const configurableMenu = getConfigurationMenu(configurations.configurableMenu, getUserRole());
	return [
		getConfigurationValue(configurableMenu[AdminMenu.ORGANIZATION]) && OrganizationBtn,
		getConfigurationValue(configurableMenu[AdminMenu.CALL_LOGS]) && CallLogsBtn,
		getConfigurationValue(configurableMenu[AdminMenu.USER_MANAGEMENT]) && {
			...UserManagementBtn,
			isWordBreak: language === LOCALES.GERMAN,
		},
		getConfigurationValue(configurableMenu[AdminMenu.CONFIGURATION]) && ConfigurationsBtn,
		showOption(configurations, AdminMenu.AUDIT_LOGS) && AuditLogsBtn,
		showOption(configurations, AdminMenu.DASHBOARD) && DashboardBtn,
		showOption(configurations, AdminMenu.FLEET_MANAGEMENT) && FleetManagementBtn,
	].filter(Boolean);
};

export const getDoctorMenu = (configurations, healthSystems, patientId) =>
	[
		showOption(configurations, DoctorMenu.HOME) && { ...HomeBtn, linkTo: `/doctor-home` },
		showOption(configurations, DoctorMenu.WAITING_ROOM) && WaitingRoomBtn,
		showOption(configurations, DoctorMenu.SESSIONS_QUEUE) && SessionsQueueBtn,
		// SessionsQueueBtn,
		showOption(configurations, DoctorMenu.PATIENTS) && { ...PatientsBtn, linkTo: `/patients/${patientId ?? ''}` },
		showOption(configurations, DoctorMenu.APPOINTMENTS) && { ...AppointmentsBtn, id: 5 },
		showOption(configurations, DoctorMenu.ROUNDING) &&
			healthSystems.length > 0 && {
				...OrganizationBtn,
				title: translate('rounding'),
				id: 15,
				image: `${menuUrl}Hospitals-1.svg?v2`,
				selectedImage: `${menuUrl}Hospitals.svg?v2`,
				darkModeUrl: 'rounding',
				icon: 'panorama_fish_eye',
			},
		showOption(configurations, DoctorMenu.RPM) && healthSystems.length > 0 && RpmButon,
		showOption(configurations, DoctorMenu.RPM) && healthSystems.length > 0 && LogisticsButton,
	].filter(Boolean);

export const getSuperUserMenu = (configurations, language) => {
	return [
		showOption(configurations, SuperUserMenu.ORGANIZATION) && OrganizationBtn,
		showOption(configurations, SuperUserMenu.CALL_LOGS) && CallLogsBtn,
		showOption(configurations, SuperUserMenu.USER_MANAGEMENT) && {
			...UserManagementBtn,
			isWordBreak: language === LOCALES.GERMAN,
		},
		showOption(configurations, SuperUserMenu.CONFIGURATION) && ConfigurationsBtn,
		showOption(configurations, SuperUserMenu.QUEUE_MANAGEMENT) && QueueManagementButton,
		showOption(configurations, SuperUserMenu.DASHBOARD) && DashboardBtn,
		showOption(configurations, SuperUserMenu.FLEET_MANAGEMENT) && FleetManagementBtn,
	].filter(Boolean);
};

export const getNurseMenu = ({ configurations, waitingRoomCount, treeHierarchyTypeId, patientId, isAssignedToDoctors }) =>
	[
		showOption(configurations, NurseMenu.ROUNDING) && HospitalsBtn,
		showOption(configurations, NurseMenu.WAITING_ROOM) && isAssignedToDoctors && { ...WaitingRoomBtn, waitingRoomCount },
		showOption(configurations, NurseMenu.CHAT) && { ...NursesChatBtn, linkTo: `/nurses` },
		showOption(configurations, NurseMenu.TEAMS) &&
			[TreeHierarchyType.DEFAULT_TREE, TreeHierarchyType.HOSPITAL_DEPT_FLOOR_ROOM].includes(treeHierarchyTypeId) &&
			TeamsBtn,
		showOption(configurations, NurseMenu.MONITORING) && MonitoringBtn,
		showOption(configurations, NurseMenu.AMBIENT_MONITORING) && AlertCenterBtn,
		showOption(configurations, NurseMenu.RPM) && { ...RpmButon, id: 6 },
		showOption(configurations, NurseMenu.RPM) && { ...PatientsBtn, linkTo: `/nurse-patients/${patientId ?? ''}` },
		showOption(configurations, NurseMenu.VISITORS) && VisitorsBtn,
		showOption(configurations, NurseMenu.RPM) && LogisticsButton,
		showOption(configurations, NurseMenu.CARE_EVENTS) && CareEventsBtn,
	].filter(Boolean);

export const getDigitalClinicianMenuDefault = (configurations, userSession) =>
	[
		CheckInDCbtn,
		showOption(configurations, DigitalClinicianMenu.PATIENTS) && userSession?.checkedInChannelId && CheckedInPatients,
		showOption(configurations, DigitalClinicianMenu.RPM) && userSession?.checkedInChannelId && RpmDigitalClinicianButon,
	].filter(Boolean);

export const getSuperAdminMenu = (configurations, language) => {
	const configurableMenu = getConfigurationMenu(configurations.configurableMenu, getUserRole());
	return [
		getConfigurationValue(configurableMenu[SuperAdminMenu.COMPANIES]) && CompaniesBtn,
		getConfigurationValue(configurableMenu[SuperAdminMenu.USER_MANAGEMENT]) && {
			...UserManagementBtn,
			isWordBreak: language === LOCALES.GERMAN,
		},
		getConfigurationValue(configurableMenu[SuperAdminMenu.AUDIT_LOGS]) && AuditLogsBtn,
	].filter(Boolean);
};

export const getDigitalClinicianMenuPrimaryCare = (configurations, userSession) =>
	[
		CheckInDCbtn,
		showOption(configurations, DigitalClinicianMenu.REGISTER_PATIENT) && userSession.checkedInChannelId && RegisterPatientBtn,
		showOption(configurations, DigitalClinicianMenu.VISITS) && userSession.checkedInChannelId && VisitsBtn,
	].filter(Boolean);

export const getPrimaryCareDoctorMenu = (configurations, userSession) =>
	[
		showOption(configurations, DoctorMenu.HOME) && { ...CheckInDCbtn, linkTo: `/doctor-home` },
		showOption(configurations, DoctorMenu.WAITING_ROOM) && !userSession?.isInBreak && WaitingPrimaryCareRoomBtn,
	].filter(Boolean);

export const getLaborantMenu = (configurations, userSession) =>
	[
		CheckInVCPbtn,
		showOption(configurations, NurseMenu.WAITING_ROOM) &&
			!userSession?.isInBreak &&
			userSession.checkedInChannelId &&
			WaitingPrimaryCareRoomBtn,
		showOption(configurations, NurseMenu.WAITING_FOR_RESULTS) &&
			!userSession?.isInBreak &&
			userSession.checkedInChannelId &&
			WaitingResultsBtn,
	].filter(Boolean);

export const getRadiologyMenu = (configurations, userSession) =>
	[
		CheckInVCPbtn,
		showOption(configurations, NurseMenu.WAITING_ROOM) &&
			!userSession?.isInBreak &&
			userSession.checkedInChannelId &&
			WaitingPrimaryCareRoomBtn,
	].filter(Boolean);

export const getInjectionMenu = (configurations, userSession) =>
	[
		CheckInVCPbtn,
		showOption(configurations, NurseMenu.WAITING_ROOM) &&
			!userSession?.isInBreak &&
			userSession.checkedInChannelId &&
			WaitingPrimaryCareRoomBtn,
	].filter(Boolean);

export const getInfusionMenu = (configurations, userSession) =>
	[
		CheckInVCPbtn,
		showOption(configurations, NurseMenu.WAITING_ROOM) &&
			!userSession?.isInBreak &&
			userSession.checkedInChannelId &&
			WaitingPrimaryCareRoomBtn,
	].filter(Boolean);

export const getDefaultVCPmenu = () => [CheckInVCPbtn];

export const getVisitorMenu = () =>
	[
		{
			...HelloDevicesBtn,
			linkTo: '/devices',
			title: translate('devices'),
		},
		{
			...PatientsBtn,
			linkTo: '/dependants',
			title: translate('dependants'),
		},
	].filter(Boolean);

export const getVirtualSitterMenu = (configurations, treeHierarchyTypeId) =>
	[
		showOption(configurations, VirtualSitterMenu.MONITORING) && MonitoringBtn,
		showOption(configurations, VirtualSitterMenu.AMBIENT_MONITORING) && AlertCenterBtn,
		showOption(configurations, VirtualSitterMenu.TEAMS) &&
			[TreeHierarchyType.DEFAULT_TREE, TreeHierarchyType.HOSPITAL_DEPT_FLOOR_ROOM].includes(treeHierarchyTypeId) &&
			TeamsBtn,
	].filter(Boolean);
