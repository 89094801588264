import React from 'react';
import { Chart } from 'primereact/chart.js';

const VerticalBarChart = props => (
	<div>
		<div className='content-section implementation'>
			<Chart
				type='bar'
				data={{ labels: props.labels, datasets: props.datasets }}
				height='40vh'
				options={{ maintainAspectRatio: false }}
			/>
		</div>
	</div>
);

export default VerticalBarChart;
