import React from 'react';
import styled from 'styled-components';
import LightTheme from 'calls/styles/LightTheme.js';

/**
 * @type {import('styled-components').StyledComponent<"div", any, { background: string, containerWidth: number }, never>}
 */
const StyledPopup = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${props => props.background};
	z-index: 20;

	main {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: auto;
		text-align: center;
		max-width: 100%;
		width: ${props => props.containerWidth}px;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		p {
			padding: 0;
			color: ${props => props.color};
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			margin: 0 0 ${LightTheme.spacing[3]}px 0;
		}

		p {
			margin: 0;
		}
	}
`;

/**
 * @param {object} props
 * @param {any} props.children
 * @param {string} [props.background=LightTheme.colors.graySix]
 * @param {number} [props.containerWidth=960]
 * @param {string} [props.color=LightTheme.color.grayZero]
 */
const Popup = ({ children, containerWidth = 960, background = LightTheme.colors.graySix, color = LightTheme.colors.grayZero }) => {
	return (
		<StyledPopup containerWidth={containerWidth} background={background} color={color}>
			<main>{children}</main>
		</StyledPopup>
	);
};

export default Popup;
