import React from 'react';
import Dropdown from 'components/Dropdown.jsx';
import translate from 'i18n-translations/translate.jsx';
import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';
import { useSelector } from 'react-redux';
import Profile from 'icons/CareEvents/Profile.jsx';
import Alerts from 'icons/CareEvents/Alerts.jsx';
import CareEvents from 'icons/CareEvents/CareEvents.jsx';
import PatientHistoryIcon from 'calls/icons/PatientHistory.jsx';

const CareEventsDropDownOptions = ({ toggleViewHistory, patient, openViewProfileModal, openAlertsModal }) => {
	const isDarkMode = useSelector(state => state.user.darkMode);
	return (
		<Dropdown position='bottom' icon='more_vert' className='patient-view-more-wrapper'>
			<div className='patient-info-hello-list patient-info-view-more-list'>
				<div className='flex'>
					<button type='button' className='flex' onClick={() => toggleViewHistory(patient)}>
						<PatientHistoryIcon color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />
						<p className='margin-left-s'>{translate('history')}</p>
					</button>
				</div>
				<div className='flex'>
					<button type='button' className='flex' onClick={() => openViewProfileModal(patient)}>
						<Profile color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />
						<p className='margin-left-s'>{translate('viewProfile')}</p>
					</button>
				</div>
				<div className='flex'>
					<button type='button' className='flex' onClick={() => openAlertsModal(patient)}>
						<Alerts color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />
						<p className='margin-left-s'>{translate('viewAlerts')}</p>
					</button>
				</div>
				<div className='flex'>
					<button type='button' className='flex'>
						<CareEvents color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />
						<p className='margin-left-s'>{translate('careEvents')}</p>
					</button>
				</div>
			</div>
		</Dropdown>
	);
};

export default CareEventsDropDownOptions;
