import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import Pagination from 'components/Common/Pagination.jsx';
import translate from 'i18n-translations/translate.jsx';
import { getMembers, deleteMember, getHealthSystemMembers, resetTwoFactorAuth } from 'api/users.js';
import { UserRoles, InviteTypes, MembersType, AuthenticationOptions, CompanyRoles } from 'constants/enums.js';
import {
	decodeHtml,
	getConfigurationValue,
	getPreferredLanguageLocale,
	getRoleValueById,
	getUserTypeBasedOnCompanyRole,
} from 'infrastructure/helpers/commonHelpers.js';
import { getUserInfo, getUserRole, getCompanyId } from 'infrastructure/auth.js';
import { addMemberRole, deleteMemberRole } from 'api/roles.js';
import ManageHealthSystems from 'containers/UserManagement/Lists/EditUser/ManageHealthSystems.jsx';
import AddMemberRole from 'containers/UserManagement/Lists/AddMemberRole.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Input, MultiSelectCheckboxes, Alert, Badge, Table, Modal, Form, Button } from 'components/index.js';
import PinGenerate from 'components/Users/PinGenerate.jsx';
import { WhiteboardSettings } from 'constants/configurationEnums.js';

const UsersList = props => {
	const intl = useIntl();
	const companyId = getCompanyId();
	const userRole = getUserRole();
	const companySettings = useSelector(state => state.company.companySettings);
	const userSession = useSelector(state => state.user.userSession);
	const allHealthSystems = useSelector(state => state.healthSystems.allHealthSystems);
	const maxSelectItems = 5;
	const maxRolesLengthWithoutAdmin = 5;
	const delayTimerRef = useRef(null);

	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0 });
	const [totalCount, setTotalCount] = useState(0);
	const [members, setMembers] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [currentMemberObj, setCurrentMemberObj] = useState(null);
	const [currentMemberId, setCurrentMemberId] = useState(null);
	const [filterValues, setFilterValues] = useState({
		searchValue: '',
		roles: [],
		healthSystems: [],
	});
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [isEditMemberModalOpen, setIsEditMemberModalOpen] = useState(false);
	const [pinGenerateMember, setPinGenerateMember] = useState(null);
	const [healthSystemAddition, setHealthSystemAddition] = useState({
		loading: false,
		loaded: false,
		error: false,
	});
	const [error, setError] = useState(null);
	const [isAddRoleModalOpen, setIsAddRoleModalOpen] = useState(false);
	const [rolesToAdd, setRolesToAdd] = useState(null);
	const [selectedRole, setSelectedRole] = useState(null);
	const [selectedRoleError, setSelectedRoleError] = useState(null);
	const [isDeleteRoleModalOpen, setIsDeleteRoleModalOpen] = useState(false);
	const [roleId, setRoleId] = useState(null);
	const [isBtnLoading, setIsBtnLoading] = useState(false);
	const [isHealthSystemShown, setIsHealthSystemShown] = useState(false);
	const [isInviteUsersDropDown, setIsInviteUsersDropDown] = useState(false);
	const [isTwoFaResetModalOpen, setIsTwoFaResetModalOpen] = useState(false);
	const [resetTwoFaModalData, setResetTwoFaModalData] = useState(null);
	const usersTableHeaders = [
		{ title: intl.formatMessage({ id: 'userName' }), id: 0 },
		{ title: intl.formatMessage({ id: 'email' }), id: 1 },
		{ title: intl.formatMessage({ id: 'invitedBy' }), id: 2 },
		{ title: intl.formatMessage({ id: 'dateInvited' }), id: 3 },
		{ title: intl.formatMessage({ id: 'dateAdded' }), id: 4 },
		getUserRole() !== UserRoles.SUPER_ADMIN && { title: intl.formatMessage({ id: 'healthSystems' }), id: 5 },
		{ title: intl.formatMessage({ id: 'status' }), id: 6 },
		getUserRole() !== UserRoles.SUPER_ADMIN && { title: intl.formatMessage({ id: 'role' }), id: 7 },
		{ edit: '', id: 8 },
	].filter(Boolean);

	const rolesOptions = [
		{ value: CompanyRoles.OWNER, label: intl.formatMessage({ id: 'owner' }) },
		{ value: CompanyRoles.ADMIN, label: intl.formatMessage({ id: 'adminOnList' }) },
		{ value: CompanyRoles.VIRTUAL_CARE_PROVIDER, label: intl.formatMessage({ id: 'virtualCareProvider' }) },
		{ value: CompanyRoles.VIRTUAL_SITTER, label: intl.formatMessage({ id: 'virtualSitterOnList' }) },
		{ value: CompanyRoles.SUPER_USER, label: intl.formatMessage({ id: 'superUserOnList' }) },
		{ value: CompanyRoles.DIGITAL_CLINICIAN, label: intl.formatMessage({ id: 'digitalClinicianOnList' }) },
		{ value: CompanyRoles.DOCTOR, label: intl.formatMessage({ id: 'doctor' }) },
		{ value: CompanyRoles.VISITOR, label: intl.formatMessage({ id: 'visitor' }) },
	];

	useEffect(() => {
		const fetchMembers = async () => {
			let response = {};
			const params = {
				pageSize: pagination.pageSize,
				pageIndex: pagination.pageIndex,
				filterType: MembersType.NON_AD_MEMBERS,
				...filterValues,
			};
			if (userRole === UserRoles.SUPER_ADMIN || userRole === UserRoles.ADMIN) {
				if (userRole === UserRoles.SUPER_ADMIN) {
					params.roles.push(CompanyRoles.SUPER_ADMIN);
				}

				response = await getMembers(params);
			} else {
				response = await getHealthSystemMembers({
					healthSystemId: userSession.healthSystem.id,
					...params,
				});
			}

			if (response.error) {
				setError(intl.formatMessage({ id: 'errorDuringRequestProcessing' }));
			} else {
				setMembers(response.members);
				setTotalCount(response.totalCount);
			}
			setIsLoading(false);
		};

		if (!isDeleteModalOpen && isLoading && !isBtnLoading) {
			fetchMembers();
		}

		return () => {
			clearTimeout(delayTimerRef.current);
		};
	}, [isAddRoleModalOpen, isLoading, isBtnLoading, filterValues, pagination]);

	const handleSearchChanges = values => {
		setIsLoading(true);
		setPagination(prevState => ({ ...prevState, pageIndex: 0 }));
		setFilterValues({
			searchValue: values.searchValue,
			roles: values.roles.map(item => item.value),
			healthSystems: values.healthSystems.map(item => item.value),
		});
	};

	const handleDeleteMember = async () => {
		const response = await deleteMember(currentMemberId);
		if (response.error) {
			setError(response.error.message);
		} else {
			setMembers(prevMembers =>
				prevMembers.filter(member => {
					return member.memberId !== currentMemberId;
				})
			);
		}
		setCurrentMemberId(null);
		setIsDeleteModalOpen(false);
	};

	const updateMemberHealthSystems = healthSystems => {
		setMembers(prevMembers =>
			prevMembers.map(pm => {
				if (pm.memberId === currentMemberId) {
					return { ...pm, healthSystems: healthSystems };
				}
				return pm;
			})
		);
	};

	const getRoles = (roles, memberId, hasMultipleHs) => {
		if (!roles) return 'N/A';
		if (roles.length === 1)
			return <div className='badge blue'>{getRoleValueById(getUserTypeBasedOnCompanyRole(roles[0].id))}</div>;

		return roles.map(role => (
			<div key={role.id} className='badge blue'>
				{getRoleValueById(getUserTypeBasedOnCompanyRole(role.id))}

				{!shouldHideRemoveIcon(role.id, hasMultipleHs) && (
					<i className='material-icons remove-role-btn' onClick={() => openDeleteRoleModal(role.id, memberId)}>
						close
					</i>
				)}
			</div>
		));
	};

	const shouldHideRemoveIcon = (roleId, hasMultipleHs) =>
		userRole === UserRoles.SUPER_USER && (roleId === CompanyRoles.ADMIN || hasMultipleHs);

	const isNurseOrDoctor = roles => {
		if (!roles) return false;
		return roles.some(role =>
			[
				CompanyRoles.DOCTOR,
				CompanyRoles.VIRTUAL_CARE_PROVIDER,
				CompanyRoles.DIGITAL_CLINICIAN,
				CompanyRoles.VIRTUAL_SITTER,
			].includes(role.id)
		);
	};

	const showDeleteButton = (roles, memberId) => {
		if (roles && roles.length > 0) {
			if (userRole === UserRoles.ADMIN) {
				return !roles?.some(role => [CompanyRoles.OWNER].includes(role.id));
			}
			if (userRole === UserRoles.SUPER_USER) {
				const memberHealthSystems = members.find(x => x.memberId === memberId);
				let canDelete = false;
				if (memberHealthSystems && memberHealthSystems.healthSystems) {
					canDelete =
						memberHealthSystems.healthSystems.length === 1 &&
						memberHealthSystems.healthSystems[0].id === userSession.healthSystem.id;
				}
				return (
					!roles?.some(role => [CompanyRoles.OWNER, CompanyRoles.ADMIN, CompanyRoles.SUPER_USER].includes(role.id)) && canDelete
				);
			}
		}
		return true;
	};

	const shouldShowAddRoleButton = (roles, memberId) => {
		const maxRolesLength = 6;
		if (!roles || roles.length >= maxRolesLength) {
			return false;
		}

		if (userRole === UserRoles.SUPER_USER) {
			const memberHealthSystems = members.find(x => x.memberId === memberId);
			let canAddRoles = false;
			if (memberHealthSystems && memberHealthSystems.healthSystems) {
				canAddRoles =
					memberHealthSystems.healthSystems.length === 1 &&
					memberHealthSystems.healthSystems[0].id === userSession.healthSystem.id;
			}
			return (
				roles.some(role =>
					[
						CompanyRoles.DOCTOR,
						CompanyRoles.VIRTUAL_CARE_PROVIDER,
						CompanyRoles.DIGITAL_CLINICIAN,
						CompanyRoles.VIRTUAL_SITTER,
					].includes(role.id)
				) && canAddRoles
			);
		}

		return roles.some(role =>
			[
				CompanyRoles.DOCTOR,
				CompanyRoles.VIRTUAL_CARE_PROVIDER,
				CompanyRoles.DIGITAL_CLINICIAN,
				CompanyRoles.ADMIN,
				CompanyRoles.VIRTUAL_SITTER,
				CompanyRoles.SUPER_USER,
			].includes(role.id)
		);
	};

	const getMemberDetails = (user, presence, presenceColor) => {
		const currentUser = getUserInfo().userId === user.userInfo.userId;
		return {
			profileBox: getProfileBox(decodeHtml(`${user.userInfo.firstName} ${user.userInfo.lastName}`), currentUser),
			email: user.userInfo.email,
			invitedBy: user.inviter
				? getProfileBox(
						decodeHtml(`${user.inviter.firstName} ${user.inviter.lastName}`),
						getUserInfo().userId === user.inviter.userId
				  )
				: 'N/A',
			dateInvited: user.dateInvited
				? moment.utc(user.dateInvited).local().locale(getPreferredLanguageLocale()).format('MM/DD/YYYY-hh:mm A')
				: 'N/A',
			dateAdded: user.dateAdded
				? moment.utc(user.dateAdded).local().locale(getPreferredLanguageLocale()).format('MM/DD/YYYY-hh:mm A')
				: 'N/A',
			...(userRole !== UserRoles.SUPER_ADMIN && {
				healthSystem: user.healthSystems.length > 0 ? displayHealthSystems(user.healthSystems) : 'N/A',
			}),
			status: <Badge text={presence} variant={presenceColor} />,
			...(userRole !== UserRoles.SUPER_ADMIN && {
				role: getRoles(user.roles, user.memberId, user.healthSystems.length > 1),
			}),
			id: user.memberId,
			edit: !currentUser && [UserRoles.ADMIN, UserRoles.SUPER_USER].includes(userRole) && getEditButtons(user),
		};
	};

	const displayUsers = () => {
		if (members.length === 0) return [];
		return members.map(user => {
			let presence;
			let presenceColor;
			if (user.userInfo.presenceStatusTypeId === 1) {
				presence = translate('available');
				presenceColor = 'green';
			} else {
				presence = translate('unavailable');
				presenceColor = 'red';
			}
			return getMemberDetails(user, presence, presenceColor);
		});
	};

	const displayHealthSystems = healthSystems => healthSystems.map(hs => hs.name).join(',');

	const getProfileBox = (name, currentUser) => (
		<div className='div-container'>
			<span className={currentUser ? 'bold' : ''}>
				{name} {currentUser && `(${intl.formatMessage({ id: 'you' })})`}
			</span>
		</div>
	);

	const getEditButtons = ({ memberId, roles, userInfo }) => {
		const is2FAEnabled = companySettings?.twoFactorAuthenticator === AuthenticationOptions.EXTERNAL_AUTHENTICATOR;

		return (
			<div className='wrapped'>
				{is2FAEnabled && (
					<i
						className='material-icons boxed-icon two-fa-icon'
						data-tooltip={intl.formatMessage({ id: 'resetTwoFa' })}
						data-position='top'
						onClick={() => openResetTwoFaAuthenticationModal(userInfo?.email)}>
						lock_reset
					</i>
				)}
				{shouldShowAddRoleButton(roles, memberId) && (
					<i
						className='material-icons-outlined boxed-icon add-icon'
						data-tooltip={intl.formatMessage({ id: 'addARole' })}
						data-position='top'
						onClick={() => openAddRoleModal(memberId, roles)}>
						add
					</i>
				)}
				{isNurseOrDoctor(roles) && (
					<i
						className='material-icons-outlined boxed-icon edit-icon'
						onClick={() => openEditMemberModal(memberId)}
						data-position='top'
						data-tooltip={intl.formatMessage({ id: 'edit' })}>
						edit
					</i>
				)}
				{getConfigurationValue(companySettings?.companyConfigurations[WhiteboardSettings.PIN]) && isNurseOrDoctor(roles) && (
					<i
						className='material-symbols-outlined boxed-icon --orange-background'
						onClick={() => setPinGenerateMember(userInfo?.id)}
						data-position='top'
						data-tooltip='PIN'>
						passkey
					</i>
				)}
				{showDeleteButton(roles, memberId) && (
					<i
						className='material-icons-outlined boxed-icon'
						onClick={() => openDeleteMemberModal(memberId)}
						data-position='top'
						data-tooltip={intl.formatMessage({ id: 'delete' })}>
						delete
					</i>
				)}
			</div>
		);
	};

	const openEditMemberModal = memberId => {
		setIsEditMemberModalOpen(true);
		setCurrentMemberId(memberId);
		setCurrentMemberObj(members.find(m => m.memberId === memberId));
	};

	const openDeleteMemberModal = memberId => {
		setIsDeleteModalOpen(true);
		setCurrentMemberId(memberId);
	};

	const handleSelectedRole = role => {
		setSelectedRoleError(null);
		const selectedMember = members.find(m => m.memberId === currentMemberId);
		const showHealthSystem =
			rolesToAdd.filter(selectedMember => selectedMember.value !== CompanyRoles.ADMIN).length === maxRolesLengthWithoutAdmin ||
			(role.value === CompanyRoles.SUPER_USER && selectedMember.healthSystems?.length === 0);
		if (role.value === CompanyRoles.SUPER_USER && selectedMember.healthSystems?.length > 1) {
			setSelectedRoleError(translate('superUserRoleValidation'));
		}
		setIsHealthSystemShown(showHealthSystem);
		setSelectedRole(role);
	};

	const membersOnList = [
		{ label: translate('nurseOnList'), value: CompanyRoles.VIRTUAL_CARE_PROVIDER },
		{ label: translate('digitalClinicianOnList'), value: CompanyRoles.DIGITAL_CLINICIAN },
		{ label: translate('doctorOnList'), value: CompanyRoles.DOCTOR },
		{ label: translate('adminOnList'), value: CompanyRoles.ADMIN },
		{ label: translate('virtualSitterOnList'), value: CompanyRoles.VIRTUAL_SITTER },
		{ label: translate('superUserOnList'), value: CompanyRoles.SUPER_USER },
	];

	const openAddRoleModal = (memberId, roles) => {
		setSelectedRole(null);
		setSelectedRoleError(null);
		setIsBtnLoading(false);
		let rolesToAdd = membersOnList.filter(role => !roles.map(item => item.id).includes(role.value));
		const isHealthSystemShown =
			rolesToAdd.filter(member => member.value !== CompanyRoles.ADMIN).length === maxRolesLengthWithoutAdmin;
		if (userRole === UserRoles.SUPER_USER) {
			rolesToAdd = rolesToAdd.filter(r => r.value !== CompanyRoles.ADMIN);
		}
		setIsAddRoleModalOpen(true);
		setCurrentMemberId(memberId);
		setRolesToAdd(rolesToAdd);
		setIsHealthSystemShown(isHealthSystemShown);
	};

	const resetTwoFactorAuthentication = async () => {
		const email = resetTwoFaModalData?.email;
		const data = await resetTwoFactorAuth(email);
		if (data?.error) {
			setError(data?.error.message);
		}

		setIsTwoFaResetModalOpen(false);
		setResetTwoFaModalData(null);
	};

	const openResetTwoFaAuthenticationModal = email => {
		setIsTwoFaResetModalOpen(true);
		setResetTwoFaModalData({ email });
	};

	const createMemberRole = async teamId => {
		setIsBtnLoading(true);
		const params = {
			companyId,
			memberId: currentMemberId,
			role: selectedRole.value,
			teamId,
		};
		const addRoleResponse = await addMemberRole(params);
		if (addRoleResponse.error) {
			setError(addRoleResponse.error.message);
		}

		setIsAddRoleModalOpen(false);
		setIsLoading(true);
		setIsBtnLoading(false);
	};

	const openDeleteRoleModal = (roleId, memberId) => {
		setIsDeleteRoleModalOpen(true);
		setCurrentMemberId(memberId);
		setRoleId(roleId);
		setIsBtnLoading(false);
	};

	const handleDeleteMemberRole = async () => {
		setIsBtnLoading(true);
		const params = {
			companyId,
			memberId: currentMemberId,
			roleId,
		};
		const deleteRoleResponse = await deleteMemberRole(params);
		if (deleteRoleResponse.error) {
			setError(deleteRoleResponse.error.message);
		}
		setIsDeleteRoleModalOpen(false);
		setIsLoading(true);
		setIsBtnLoading(false);
	};

	const selectInviteType = id => {
		props.toggleSendInvitationsModal(id);
		setIsInviteUsersDropDown(false);
	};

	const getFiltersInitialValues = () => ({
		searchValue: '',
		roles: [],
		healthSystems: [],
	});

	return (
		<div>
			<Table
				isLoading={isLoading}
				headers={usersTableHeaders}
				rows={isLoading ? [] : displayUsers()}
				className='users-list-table'>
				<div className='flex'>
					<Formik initialValues={getFiltersInitialValues()} onSubmit={handleSearchChanges}>
						{formikProps => {
							const { values, handleSubmit, handleBlur, setFieldValue } = formikProps;
							const handleChange = (name, value) => {
								setFieldValue(name, value);
								if (name === 'searchValue') {
									clearTimeout(delayTimerRef.current);
									const timer = setTimeout(() => {
										handleSubmit();
									}, 500);

									delayTimerRef.current = timer;
								} else {
									handleSubmit();
								}
							};

							return (
								<div className='members-filters'>
									<Input
										validationOptions={{}}
										type='text'
										placeholder={intl.formatMessage({ id: 'nameOrEmail' })}
										bottomSpace='0'
										variant='filled'
										name='searchValue'
										onChange={event => handleChange('searchValue', event.target.value)}
										maxLength={100}
										value={values.searchValue}
									/>
									{getUserRole() !== UserRoles.SUPER_ADMIN && (
										<>
											{getUserRole() !== UserRoles.SUPER_USER && (
												<MultiSelectCheckboxes
													name='healthSystems'
													selectedOptions={values.healthSystems}
													searchable
													reorderSelected
													options={allHealthSystems.map(item => ({ value: item.id, label: item.name }))}
													placeholder={intl.formatMessage({ id: 'selectHealthSystem' })}
													onChange={value => handleChange('healthSystems', value)}
													selectedItemsPlaceholder={intl.formatMessage({ id: 'healthSystems' })}
													onBlur={handleBlur}
													maxSelect={maxSelectItems}
													errorMessage={intl.formatMessage(
														{ id: 'cannotSelectNrMoreThanValue' },
														{ value: maxSelectItems, item: intl.formatMessage({ id: 'healthSystems' }) }
													)}
												/>
											)}
											<MultiSelectCheckboxes
												name='roles'
												selectedOptions={values.roles}
												searchable
												reorderSelected
												options={rolesOptions}
												onChange={value => handleChange('roles', value)}
												placeholder={intl.formatMessage({ id: 'selectRole' })}
												selectedItemsPlaceholder={intl.formatMessage({ id: 'roles' })}
												onBlur={handleBlur}
												maxSelect={maxSelectItems}
												errorMessage={intl.formatMessage(
													{ id: 'cannotSelectNrMoreThanValue' },
													{ value: maxSelectItems, item: intl.formatMessage({ id: 'roles' }) }
												)}
											/>
										</>
									)}
								</div>
							);
						}}
					</Formik>
					{UserRoles.ADMIN === userRole && (
						<div className='position-relative invite-users-wrapper'>
							<button type='button' className='invite-user-btn' onClick={() => setIsInviteUsersDropDown(prevState => !prevState)}>
								<img
									src={`${healthCareCdnUrl}appointments/create-appointment.svg`}
									alt='icon'
									className='create-appointment-icon'
								/>
								{translate('inviteUsers')}
							</button>
							{isInviteUsersDropDown && (
								<div className='full-page-input-results' data-cy='symptomResults'>
									{props.inviteType.map(type => (
										<div key={type.inviteTypeId} className='cursor-pointer' onClick={() => selectInviteType(type.inviteTypeId)}>
											<span>{type.name}</span>
										</div>
									))}
								</div>
							)}
						</div>
					)}
					{userRole === UserRoles.SUPER_ADMIN && (
						<Button
							text={translate('addSuperAdmin')}
							horizAlign='end'
							onClick={() => selectInviteType(InviteTypes.SUPER_ADMIN.type)}
						/>
					)}
				</div>
			</Table>
			{!isLoading && (
				<Pagination
					totalCount={totalCount}
					pageSize={pagination.pageSize}
					pageIndex={pagination.pageIndex}
					onChange={(pageSize, pageIndex) => {
						setIsLoading(true);
						setPagination({ pageSize, pageIndex });
					}}
				/>
			)}
			{getConfigurationValue(companySettings?.companyConfigurations[WhiteboardSettings.PIN]) && (
				<PinGenerate memberId={pinGenerateMember} onModalClose={() => setPinGenerateMember(null)} />
			)}
			<Modal
				display={isDeleteModalOpen}
				position='center'
				className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal admin-delete-modal'
				primaryButtonLabel={translate('delete')}
				onModalSubmit={() => handleDeleteMember()}
				onModalClose={() => setIsDeleteModalOpen(false)}
				submitImgIcon={`${healthCareCdnUrl}appointments/save-icon.svg`}
				closeImgIcon={`${healthCareCdnUrl}appointments/cancel-appointment.svg`}>
				<form>
					<h3>{translate('deleteMember')}</h3>
					<p>{translate('areYouSureDeleteMember')}</p>
				</form>
			</Modal>
			{currentMemberObj !== null && (
				<ManageHealthSystems
					onLoading={() =>
						setHealthSystemAddition(prevState => ({
							...prevState,
							loading: true,
						}))
					}
					loaded={healthSystemAddition.loaded}
					error={healthSystemAddition.error}
					onSuccess={() =>
						setHealthSystemAddition(prevState => ({
							...prevState,
							loading: false,
							loaded: true,
						}))
					}
					onCloseAlert={() =>
						setHealthSystemAddition({
							loading: false,
							loaded: false,
							error: false,
						})
					}
					member={currentMemberObj}
					updateMemberHealthSystems={updateMemberHealthSystems}
					healthSystems={props.healthSystems}
					role={getRoles(currentMemberObj.roles)}
					modalSelector='editMemberModal'
					display={isEditMemberModalOpen}
					className='admin-page-modal'
					position='right'
					onModalClose={() => {
						setIsEditMemberModalOpen(false);
						setCurrentMemberId(null);
						setCurrentMemberObj(null);
					}}
				/>
			)}
			<AddMemberRole
				isAddRoleModalOpen={isAddRoleModalOpen}
				onAddMemberRole={createMemberRole}
				onModalClose={() => setIsAddRoleModalOpen(false)}
				isSubmitDisabled={!selectedRole?.value || !!selectedRoleError}
				rolesToAdd={rolesToAdd}
				selectedRole={selectedRole}
				setSelectedRole={handleSelectedRole}
				selectedRoleError={selectedRoleError}
				healthSystems={props.healthSystems}
				isBtnLoading={isBtnLoading}
				isHealthSystemShown={isHealthSystemShown}
			/>
			<Modal
				className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal'
				display={isDeleteRoleModalOpen}
				position='center'
				onModalSubmit={handleDeleteMemberRole}
				onModalClose={() => setIsDeleteRoleModalOpen(false)}
				shouldSubmitOnEnter={false}
				primaryButtonLabel={intl.formatMessage({ id: 'yes' })}
				closeImgIcon={`${healthCareCdnUrl}appointments/cancel-appointment.svg`}
				submitImgIcon={`${healthCareCdnUrl}appointments/save-icon.svg`}
				primaryButtonLoading={isBtnLoading}>
				<Form height={200} className='modal-form-wrapper'>
					<h3>{translate('deleteRole')}</h3>
					<p>{translate('areYouSureDeleteRole')}</p>
				</Form>
			</Modal>
			<Modal
				modalSelector='TwoFaResetModal'
				display={isTwoFaResetModalOpen}
				position='center'
				onModalSubmit={resetTwoFactorAuthentication}
				onModalClose={() => setIsTwoFaResetModalOpen(false)}>
				<form>
					<h3>{translate('warning')}</h3>
					<p>{translate('areYouSureTwoFaReset')}</p>
				</form>
			</Modal>
			<Alert display={error} fixed hideCloseButton message={error} variant='dark' />
		</div>
	);
};

export default UsersList;
