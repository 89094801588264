import React, { useState } from 'react';
import translate from 'i18n-translations/translate.jsx';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';
import { Icon } from 'calls/components/index.js';

const CasesAccordion = props => {
	const [showContent, setShowContent] = useState(false);
	const caseStatusTypes = [
		{ id: 1, text: 'active' },
		{ id: 2, text: 'completed' },
		{ id: 3, text: 'canceled' },
	];

	return (
		<>
			<div className='case-inner' key={props.id}>
				<div className='flex full-width'>
					<span className='flex-1'>
						<span>{props.patientFullName} </span> <br /> ID: {props.idCard}
					</span>
					<span className='flex-1'>{props.id}</span>
					<span className='flex-1'>{formattedDate(props.openedAt)}</span>
					<span className='flex-1'>{props.closedAt ? formattedDate(props.closedAt) : 'N/A'}</span>
					<div className='flex-1 visit-type'><Icon name={props.isRemote ? 'call' : 'meeting_room'} size={14} /> {translate(props.isRemote ? 'virtualCall' : 'inPerson')}</div>
					<div className='flex-1 flex flex-align-center'>
						<span className={`flex-1 status  ${caseStatusTypes.find(el => el.id === props.statusId).text} `}>
							{caseStatusTypes.find(el => el.id === props.statusId)
								? translate(caseStatusTypes.find(el => el.id === props.statusId).text)
								: ''}
						</span>
						<button
							type='button'
							onClick={() => setShowContent(prevContentState => !prevContentState)}
							className='margin-left-auto'>
							<i className='material-icons'>{showContent ? 'keyboard_arrow_right' : 'keyboard_arrow_down'}</i>
						</button>
					</div>
				</div>
				{showContent && props.children}
			</div>
		</>
	);
};

export default CasesAccordion;
