import React from 'react';

const FallDetection = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none' viewBox='0 0 24 24' {...props}>
		<path
			stroke='currentColor'
			strokeMiterlimit='10'
			strokeWidth='1.25'
			d='M3.544 11.649l.435 3.332c.091.375.318.632.672.709.144.031.292.03.435-.005.522-.127.76-.599.634-1.121l-.327-2.652 1.999-1.092 2.615 4.92c.113.127.237.244.37.351l6.077 4.272a1.059 1.059 0 101.217-1.732l-4.848-3.408 3.225-1.53 4.027 1.665a1.058 1.058 0 00.81-1.956l-4.463-1.846a1.06 1.06 0 00-.858.022l-2.244 1.064c-.328.113-.613-.043-.852-.312-.82-.912-1.957-2.973-1.416-3.23l1.894-.89a.97.97 0 00.505-.557l1.189-3.37a.975.975 0 00-.594-1.243c-.508-.18-.936.282-1.117.79l-1.092 2.736-7.743 4.046a1.055 1.055 0 00-.55 1.037zM6.75 7.5a1.75 1.75 0 100-3.5 1.75 1.75 0 000 3.5z'
		/>
		<path stroke='currentColor' strokeLinecap='round' strokeWidth='1.25' d='M22 23H4' />
	</svg>
);

export default FallDetection;
