import React, { useState, useEffect } from 'react';
import { Alert, Loader, Button } from 'components';
import { getConfigurations, updateConfigurations } from 'api/configurations';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { handleOnKeyDownNumeric, stringToCamelCase } from 'infrastructure/helpers/commonHelpers.js';
import translate from 'i18n-translations/translate.jsx';
import { getUserMeasurementUnits } from 'api/measurements.js';
import { getUserId } from 'infrastructure/auth.js';
import {
	AlertTypes,
	BloodPresureUnitTypeIds,
	HealthDataField,
	HealthDataType,
	MeasurementAlertTypeId,
	MeasurementsMinMax,
	UnitCategoryTypes,
	UnitSystemTypes,
} from 'constants/enums.js';
import { convertImperialToMetric, convertMeasurementTypes } from 'infrastructure/helpers/measurementsHelper.js';
import PopUpAlert from 'components/PopUpAlert.jsx';

const VitalSignsThreshold = props => {
	const [error, setError] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [isSaveDataLoading, setIsSaveDataLoading] = useState(false);
	const [alertConfigurations, setAlertConfigurations] = useState([]);
	const [unitPreferences, setUnitPreferences] = useState([]);
	const imgs = [
		{ type: 'Heart Rate', icon: `${healthCareCdnUrl}footer-icons/new/Heart.svg`, className: 'heart' },
		{ type: 'Blood Pressure', icon: `${healthCareCdnUrl}footer-icons/new/Heart.svg`, className: 'heart' },
		{ type: 'BMI', icon: `${healthCareCdnUrl}footer-icons/new/Body-Measurements.svg`, className: 'temperature' },
		{ type: 'Blood Glucose', icon: `${healthCareCdnUrl}vsm/OtherData-1.svg`, className: 'blood-glucose' },
		{ type: 'Body Temperature', icon: `${healthCareCdnUrl}footer-icons/new/Body-Measurements.svg`, className: 'temperature' },
		{ type: 'Oxygen Saturation', icon: `${healthCareCdnUrl}footer-icons/new/Respiratory.svg`, className: 'respiratory-rate' },
	];
	const [isBtnDisabled, setIsBtnDisabled] = useState(false);
	const [changesSaved, setChangesSaved] = useState(null);

	const getAlertByType = alert => imgs.find(item => item.type === alert.name);

	useEffect(() => {
		const fetchData = async () => {
			const [configurationsRes, unitPreferencesRes] = await Promise.all([
				getConfigurations(props.patientId),
				getUserMeasurementUnits(getUserId()),
			]);
			const resErrror = configurationsRes.error || unitPreferencesRes.error;
			if (resErrror) {
				setError(resErrror.message);
				setIsLoading(false);
				return;
			}
			const saturationIndex = configurationsRes.findIndex(item => item.id === HealthDataType.OXYGEN_SATURATION);
			if (saturationIndex === 5) {
				const [splicedElement] = configurationsRes.splice(5, 1);
				configurationsRes.splice(2, 0, splicedElement);
			}
			setAlertConfigurations(configurationsRes);
			setUnitPreferences(unitPreferencesRes.unitPreferences);
			setIsLoading(false);
		};
		fetchData();
	}, [props.patientId]);

	const getCategoryPreference = categoryId => unitPreferences.find(item => item.unitCategoryId === categoryId);

	const getUnitPreference = categoryId => {
		const selectedPreference = getCategoryPreference(categoryId);
		return selectedPreference?.options.find(item => item.unitSystemId === selectedPreference.unitSystemId);
	};

	const onSubmit = async () => {
		setIsSaveDataLoading(true);
		const response = await updateConfigurations(props.patientId, {
			alertConfigurations: alertConfigurations.flatMap(item =>
				item.fields.map(field => ({ alertFieldId: field.id, value: field.value.toString() }))
			),
		});
		if (response.error) {
			setError(response.error.message);
			setIsSaveDataLoading(false);
			return;
		}
		setIsSaveDataLoading(false);
		setChangesSaved(translate('changesSaved'));
	};

	const optimalValues = [
		{
			id: HealthDataType.BLOOD_GLUCOSE,
			fields: [
				{ id: 0, value: MeasurementsMinMax.MMOL.MIN },
				{
					id: 1,
					value:
						getUnitPreference(UnitCategoryTypes.BLOOD_GLUCOSE)?.unitSystemId === UnitSystemTypes.METRIC
							? MeasurementsMinMax.MMOL.MAX
							: MeasurementsMinMax.MGDL.MAX,
				},
			],
		},
		{
			id: HealthDataType.HEART_RATE,
			fields: [
				{ id: 3, value: MeasurementsMinMax.HEART_RATE.MIN },
				{ id: 2, value: MeasurementsMinMax.HEART_RATE.MAX },
			],
		},
		{
			id: HealthDataType.OXYGEN_SATURATION,
			fields: [
				{ id: 18, value: MeasurementsMinMax.OXYGEN_SATURATION.MIN },
				{ id: 19, value: MeasurementsMinMax.OXYGEN_SATURATION.MAX },
			],
		},
		{
			id: HealthDataType.BODY_TEMPERATURE,
			fields: [
				{
					id: 4,
					value:
						getUnitPreference(UnitCategoryTypes.TEMPERATURE)?.unitSystemId === UnitSystemTypes.METRIC
							? MeasurementsMinMax.CELSIUS.MIN
							: MeasurementsMinMax.FARENHEIT.MIN,
				},
				{
					id: 5,
					value:
						getUnitPreference(UnitCategoryTypes.TEMPERATURE)?.unitSystemId === UnitSystemTypes.METRIC
							? MeasurementsMinMax.CELSIUS.MAX
							: MeasurementsMinMax.FARENHEIT.MAX,
				},
			],
		},
		{
			id: HealthDataType.BMI,
			fields: [
				{ id: 7, value: MeasurementsMinMax.BMI.MIN },
				{ id: 8, value: MeasurementsMinMax.BMI.MAX },
			],
		},
		{
			id: HealthDataType.BLOOD_PRESSURE,
			fields: [
				{ id: 10, value: MeasurementsMinMax.SYSTOLIC.MIN },
				{ id: 11, value: MeasurementsMinMax.DIASTOLIC.MIN },
				{ id: 14, value: MeasurementsMinMax.SYSTOLIC.MAX },
				{ id: 15, value: MeasurementsMinMax.DIASTOLIC.MAX },
			],
		},
	];

	const getOptimalValues = (item, id) => {
		const foundItem = optimalValues.find(element => element.id === item.id);
		const isSystolic = [
			BloodPresureUnitTypeIds.LOW_SYSTOLIC,
			BloodPresureUnitTypeIds.EXTREMELY_LOW_SYSTOLIC,
			BloodPresureUnitTypeIds.HIGH_SYSTOLIC,
			BloodPresureUnitTypeIds.EXTREMELY_HIGH_SYSTOLIC,
		].includes(id);
		const isDiastolic = [
			BloodPresureUnitTypeIds.LOW_DIASTOLIC,
			BloodPresureUnitTypeIds.EXTREMELY_LOW_DIASTOLIC,
			BloodPresureUnitTypeIds.HIGH_DIASTOLIC,
			BloodPresureUnitTypeIds.EXTREMELY_HIGH_DIASTOLIC,
		].includes(id);
		const bloodPressureMin = isSystolic ? MeasurementsMinMax.SYSTOLIC.MIN : MeasurementsMinMax.DIASTOLIC.MIN;
		const bloodPressureMax = isSystolic ? MeasurementsMinMax.SYSTOLIC.MAX : MeasurementsMinMax.DIASTOLIC.MAX;
		const minValue =
			isSystolic || isDiastolic
				? bloodPressureMin
				: foundItem.fields.reduce((prev, curr) => (prev.value < curr.value ? prev : curr)).value;
		const maxValue =
			isSystolic || isDiastolic
				? bloodPressureMax
				: foundItem.fields.reduce((prev, curr) => (prev.value > curr.value ? prev : curr)).value;
		return { minValue, maxValue };
	};

	const onChange = (event, item, field) => {
		let { value } = event.target;
		const minMaxValues = getOptimalValues(item, field.id);
		if (+value > minMaxValues.maxValue) {
			return;
		}
		if (+value < minMaxValues.minValue) {
			setIsBtnDisabled(true);
		} else {
			setIsBtnDisabled(false);
		}
		if (
			item.id === HealthDataType.BLOOD_GLUCOSE &&
			getUnitPreference(UnitCategoryTypes.BLOOD_GLUCOSE)?.unitSystemId === UnitSystemTypes.IMPERIAL
		) {
			value = convertImperialToMetric(UnitCategoryTypes.BLOOD_GLUCOSE, value);
		}
		if (
			item.id === HealthDataType.BODY_TEMPERATURE &&
			getUnitPreference(UnitCategoryTypes.TEMPERATURE)?.unitSystemId === UnitSystemTypes.IMPERIAL
		) {
			value = convertImperialToMetric(UnitCategoryTypes.TEMPERATURE, value);
		}

		setAlertConfigurations(prevState => {
			const configs = [...prevState];
			const selectedField = configs.find(config => config.id === item.id).fields.find(el => el.id === field.id);
			selectedField.value = value === '' ? 0 : +value;
			return configs;
		});
	};

	const getTranslatedField = name => {
		if (name.toLowerCase().includes('critical high')) {
			return translate('criticalHigh');
		}
		if (name.toLowerCase().includes('critical low')) {
			return translate('criticalLow');
		}
		if (name.toLowerCase().includes('low')) {
			return translate('low');
		}
		if (name.toLowerCase().includes('high')) {
			return translate('high');
		}
		return '';
	};

	const getHealthDataTypeValue = (healthDataType, value) => {
		let healthDataValue = value;
		if (healthDataType === HealthDataType.BLOOD_GLUCOSE) {
			healthDataValue = convertMeasurementTypes(
				UnitCategoryTypes.BLOOD_GLUCOSE,
				healthDataValue,
				getUnitPreference(UnitCategoryTypes.BLOOD_GLUCOSE)?.unitSystemId
			);
		}
		if (healthDataType === HealthDataType.BODY_TEMPERATURE) {
			healthDataValue = convertMeasurementTypes(
				UnitCategoryTypes.TEMPERATURE,
				healthDataValue,
				getUnitPreference(UnitCategoryTypes.TEMPERATURE)?.unitSystemId
			);
		}
		return healthDataValue;
	};

	return (
		<div className='vital-signs-threshold'>
			{isLoading && (
				<div style={{ textAlign: 'center' }}>
					<Loader />
				</div>
			)}
			{!isLoading && (
				<>
					<div className={`vital-signs-threshold-items${isSaveDataLoading ? ' disabled' : ''}`}>
						{alertConfigurations.map(item => {
							const selectedPreference = unitPreferences.find(elem => item.name.includes(elem.unitCategoryName));
							const isBloodPresure = item.id === HealthDataType.BLOOD_PRESSURE && props.isFromMonitoring;
							const mapOrder = (array, orderedArr) => {
								array.sort((a, b) => orderedArr.indexOf(a.id) - orderedArr.indexOf(b.id));
								return array;
							};
							const ordered = [
								HealthDataField.LOW_HEART_RATE,
								HealthDataField.HIGH_HEART_RATE,
								HealthDataField.CRITICAL_LOW_OXYGEN,
								HealthDataField.LOW_OXYGEN,
								HealthDataField.CRITICAL_LOW_SYSTOLIC,
								HealthDataField.LOW_SYSTOLIC,
								HealthDataField.HIGH_SYSTOLIC,
								HealthDataField.CRITICAL_HIGH_SYSTOLIC,
								HealthDataField.CRITICAL_LOW_DIASTOLIC,
								HealthDataField.LOW_DIASTOLIC,
								HealthDataField.HIGH_DIASTOLIC,
								HealthDataField.CRITICAL_HIGH_DIASTOLIC,
							];
							mapOrder(item.fields, ordered);
							return (
								<div
									className={`flex flex-space-between left-align-items ${isBloodPresure ? 'column-direction' : ''}`}
									key={item.id}>
									<div className='flex flex-align-center'>
										<div className={`vs-icon ${getAlertByType(item).className}`}>
											<img src={getAlertByType(item).icon} alt='icon' />
										</div>
										<div className='vs-info flex column-direction'>
											<h3>{translate(stringToCamelCase(item.name))}</h3>
											<p>{getUnitPreference(selectedPreference?.unitCategoryId)?.unit || item.unit}</p>
										</div>
									</div>
									<div className={`${isBloodPresure ? 'flex flex-wrap' : ''}`}>
										{item.fields.map(field => (
											<>
												{field.id === HealthDataField.CRITICAL_LOW_SYSTOLIC && (
													<p
														className={`blood-pressure-title ${
															isBloodPresure ? 'full-width' : 'text-align-left no-left-margin'
														}`}>
														{translate('systolic')}
													</p>
												)}
												{field.id === HealthDataField.CRITICAL_LOW_DIASTOLIC && (
													<p
														className={`blood-pressure-title ${
															isBloodPresure ? 'full-width' : 'text-align-left no-left-margin'
														}`}>
														{translate('diastolic')}
													</p>
												)}
												<div className={`slider flex ${isBloodPresure ? 'flex-basis-25' : ''}`} key={field.id}>
													<div className={`slider-value ${isBloodPresure ? 'column-direction' : ''}`}>
														<span>{getTranslatedField(field.name)}</span>
														<div className='flex'>
															<div>
																<input
																	value={getHealthDataTypeValue(item.id, field.value)}
																	onChange={event => onChange(event, item, field)}
																	onKeyDown={event =>
																		handleOnKeyDownNumeric(
																			event,
																			![MeasurementAlertTypeId.BLOOD_PRESSURE, MeasurementAlertTypeId.HEART_RATE].includes(
																				item.id
																			)
																		)
																	}
																	type='number'
																	className='text-input number-input-wo-arrows'
																/>
															</div>
														</div>
													</div>
													{!props.isFromMonitoring && !props.isCallView && (
														<div className={`slider-input ${getAlertByType(item).className}`}>
															<input
																type='range'
																id='alerts-value'
																name='alerts-value'
																value={getHealthDataTypeValue(item.id, field.value)}
																onChange={event => onChange(event, item, field)}
																min={getOptimalValues(item, field.id).minValue}
																max={getOptimalValues(item, field.id).maxValue}
																step={
																	![MeasurementAlertTypeId.BLOOD_PRESSURE, MeasurementAlertTypeId.HEART_RATE].includes(item.id)
																		? '0.5'
																		: '1'
																}
																style={{ background: '(to right, blue, 50% red, 50%);' }}
															/>
														</div>
													)}
												</div>
											</>
										))}
									</div>
								</div>
							);
						})}
					</div>
					<Button
						type='submit'
						isLoading={isSaveDataLoading}
						text={translate('saveChanges')}
						onClick={onSubmit}
						isDisabled={isBtnDisabled}
					/>
				</>
			)}
			<PopUpAlert
				alertType={AlertTypes.SUCCESS}
				display={changesSaved}
				onAlertClose={() => setChangesSaved(null)}
				contentText={changesSaved}
				isSilent={true}
				center={true}
				selfCloseTimeOut={1500}
			/>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</div>
	);
};

export default VitalSignsThreshold;
