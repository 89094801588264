import React, { useState } from 'react';
import translate from 'i18n-translations/translate.jsx';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import { PatientMonitoringTab, PatientMonitoringTabs } from 'constants/rpm.js';
import RemotePatientMonitoring from 'containers/Rpm/RemotePatientMonitoring.jsx';
import ContinuousPatientMonitoring from 'containers/Rpm/ContinuousPatientMonitoring.jsx';
import { useHistory } from 'react-router';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

const PatientMonitoring = () => {
	const [currentTab, setCurrentTab] = useState(0);
	const [searchedPatient, setSearchedPatient] = useState('');
	const history = useHistory();
	const intl = useIntl();

	const openRpmPatient = () => history.push('/rpm-patient-onboarding');

	return (
		<MainLayout>
			<div className='patient-monitoring-wrapper'>
				<div className='flex'>
					{PatientMonitoringTabs.map((item, index) => (
						<div
							key={item}
							className={classNames('cursor-pointer right-15 rpm-tabs', currentTab === index ? 'active' : '')}
							onClick={() => setCurrentTab(index)}>
							{translate(item)}
						</div>
					))}
				</div>

				{currentTab === PatientMonitoringTab.REMOTE && (
					<div className='flex flex-space-between flex-align-center'>
						<button className='check-in-badge blue' type='button' onClick={openRpmPatient}>
							<p>{translate('selectPatient')}</p>
						</button>
					</div>
				)}
				{currentTab === PatientMonitoringTab.CONTINUOUS && (
					<div className='care-event-buttons'>
						<div className='flex'>
							<div className='input-wrapper'>
								<input
									type='text'
									placeholder={intl.formatMessage({ id: 'searchByName' })}
									value={searchedPatient}
									onChange={event => setSearchedPatient(event.target.value)}
								/>
							</div>
						</div>
					</div>
				)}
			</div>
			{currentTab === PatientMonitoringTab.REMOTE && <RemotePatientMonitoring />}
			{currentTab === PatientMonitoringTab.CONTINUOUS && <ContinuousPatientMonitoring searchedPatient={searchedPatient} />}
		</MainLayout>
	);
};

export default PatientMonitoring;
