import React, { useState } from 'react';
import translate from 'i18n-translations/translate.jsx';
import { Grid } from 'components/index.js';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from 'components/Tabs.jsx';
import RpmConfigurations from 'containers/Configurations/CompanyConfigurations/rpmConfigs.jsx';
import OtherConfigurations from 'containers/Configurations/CompanyConfigurations/other.jsx';

const CompanySettings = () => {
	const [currentTab, setCurrentTab] = useState(0);
	return (
		<>
			<div className='users-view test'>
				<Grid stretch='100%'>
					<main>
						<section>
							<Grid width='100%' vertAlign='start'>
								<h4>{translate('companySettings')}</h4>
								<Tabs activeIndex={currentTab} onChange={index => setCurrentTab(index)}>
									<TabList>
										<Tab className='cursor-pointer'>{translate('rpm')}</Tab>
										<Tab className='cursor-pointer'>{translate('other')}</Tab>
									</TabList>
									<TabPanels>
										<TabPanel>
											<RpmConfigurations />
										</TabPanel>
										<TabPanel>
											<OtherConfigurations />
										</TabPanel>
									</TabPanels>
								</Tabs>
							</Grid>
						</section>
					</main>
				</Grid>
			</div>
		</>
	);
};

export default CompanySettings;
