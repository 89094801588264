import LightTheme from 'calls/styles/LightTheme.js';
import translate from 'i18n-translations/translate.jsx';
import Alert from 'components/Alert.jsx';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';

/**
 * @param {Object} metaData
 */
const CardInfo = ({ metaData = {}, callEnded = false, isInConference = true, mrnDifferenceMessage = '' }) => {
	const [showMessage, setShowMessage] = useState(!!mrnDifferenceMessage);
	const isDarkMode = useSelector(state => state.user.darkMode);
	const StyledPatientInfo = styled.div`
		span {
			font-weight: 700;
		}
		${props =>
			!props.isInConference &&
			css`
				text-align: left;
				padding: var(--spacing-l);
				z-index: 2;
				background: ${isDarkMode ? 'var(--dark-theme-gray-4-v2)' : 'var(--gray-0)'};
				border-radius: 15px;
				width: 285px;
				margin-left: auto;
				margin-right: auto;
				display: block;
				margin-bottom: ${LightTheme.spacing[3]}px;
				border: 1px solid var(--gray-8-o05);
				color: ${isDarkMode ? 'white' : ''};
				span {
					color: ${isDarkMode ? 'white' : ''};
				}
			`}
		${props =>
			props.isInConference &&
			css`
				position: absolute;
				bottom: ${LightTheme.spacing[6]}px;
				left: ${LightTheme.spacing[6]}px;
				padding: ${LightTheme.spacing[2]}px;
				z-index: 2;
				background: ${isDarkMode ? 'var(--dark-theme-gray-4-v2)' : 'rgba(255, 255, 255, 0.75)'};
				border-radius: ${LightTheme.borderRadius.base}px;
				width: 250px;
				transition: 0.2s;
				color: ${isDarkMode ? 'white' : ''};
				span {
					color: ${isDarkMode ? 'white' : ''};
				}
			`}
	`;

	useEffect(() => {
		return () => {
			setShowMessage(null);
		};
	}, []);

	const showStyleComponent = () => Object.keys(metaData).length > 0;

	const renderProperties = () => {
		// patientName, location, MRN, dateOfBirth, bedIdentifier
		let result = [];
		Object.entries(metaData).forEach(([key, item]) => {
			if (key && item) {
				result.push(
					<li key={key}>
						<span>{translate(key)}:</span> {item}
					</li>
				);
			}
		});
		return result;
	};

	return (
		<>
			{showStyleComponent() && (
				<StyledPatientInfo className='metadata-card' isInConference={isInConference}>
					<ul>{renderProperties()}</ul>
				</StyledPatientInfo>
			)}
			<Alert
				display={showMessage && !callEnded}
				fixed={true}
				persist={true}
				hideCloseButton={false}
				message={mrnDifferenceMessage}
				onClose={() => setShowMessage(false)}
				variant='dark'
			/>
		</>
	);
};

export default CardInfo;
