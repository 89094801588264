import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import Callback from 'components/auth/callback.jsx';
import Logout from 'components/auth/logout.jsx';
import LogoutCallback from 'components/auth/logoutCallback.jsx';
import PrivateRoute from 'routes/privateRoute.jsx';
import SilentRenew from 'components/auth/silentRenew.jsx';
import HealthSystem from 'views/HealthSystem.jsx';
import Patients from 'views/Patients/Patients.jsx';
import Nurses from 'components/Nurses.jsx';
import Appointments from 'views/Appointments.jsx';
import CallLogs from 'views/CallLogs/CallLogs.jsx';
import Call from 'views/Call.jsx';
import Users from 'views/Users.jsx';
import Companies from 'views/Companies.jsx';
import CreateHealthSystem from 'views/CreateHealthSystem.jsx';
import CompleteProfile from 'views/CompleteProfile.jsx';
import SelectCountry from 'views/Onboarding/Doctor/SelectCountry.jsx';
import DoctorOnboarding from 'views/Onboarding/Doctor/DoctorOnboarding.jsx';
import UserActivation from 'views/Onboarding/UserActivation.jsx';
import CompleteCompany from 'views/CompleteCompany.jsx';
import PatientOnboarding from 'views/Onboarding/Patient/PatientOnboarding.jsx';
import MyDoctors from 'views/Doctors/MyDoctors.jsx';
import MyHelloDevices from 'views/HelloDevices/MyHelloDevices.jsx';
import SignsSymptoms from 'views/Doctors/Partials/SignsSymptoms.jsx';
import AvailableDoctors from 'views/Doctors/AvailableDoctors.jsx';
import MyVitalSigns from 'views/Patients/MyVitalSigns.jsx';
import AccountSettings from 'views/AccountSettings.jsx';
import { RouteTypes, UserRoles } from 'constants/enums.js';
import Configurations from 'views/Configurations.jsx';
import Recover from 'views/Recover.jsx';
import ResetPassword from 'views/ResetPassword.jsx';
import VerifyChangedEmail from 'views/VerifyChangedEmail.jsx';
import ComingSoon from 'components/ComingSoon.jsx';
import PrivacyPolicy from 'views/PrivacyPolicy.jsx';
import TermsOfUse from 'views/TermsOfUse.jsx';
import DoctorRequestsWrapper from 'views/DoctorRequestsWrapper.jsx';
import VitalSignsMonitoring from 'views/VitalSignsMonitoring.jsx';
import ConfigurationsHistory from 'views/ConfigurationsHistory.jsx';
import PatientSummary from 'components/PatientSummary.jsx';
import Teams from 'views/Teams.jsx';
import Help from 'views/Help.jsx';
import CompleteRegistration from 'views/Doctors/CompleteRegistration.jsx';
import PatientReports from 'containers/Patients/PatientReports.jsx';
import ConferenceCall from 'calls/Conference.jsx';
import conferenceRouteRender from 'calls/conferenceRouteRender.jsx';
import JoinAsGuest from 'calls/views/JoinAsGuest.jsx';
import CreateCompanyWrapper from 'views/CreateCompanyWrapper.jsx';
import ContinueAs from 'containers/ContinueAs.jsx';
import HelloLandingPage from 'views/HelloLandingPage.jsx';
import TechSpecs from 'views/TechSpecs.jsx';
import DigitalClinicianVisits from 'containers/PrimaryCare/DigitalClinician/DigitalClinicianVisits.jsx';
import DigitalClinicianCheckIn from 'views/DigitalClinicianCheckIn.jsx';
import AuditLogs from 'views/AuditLogs.jsx';
import WaitingRoom from 'views/WaitingRoom.jsx';
import Visitors from 'views/Visitors.jsx';
import FamilyMembersChat from 'views/FamilyMembersChat/FamilyMembersChat.jsx';
import Home from 'views/Partials/Home.jsx';
import SelectPatientCompany from 'views/Onboarding/Patient/SelectPatientCompany.jsx';
import LabWaitingResults from 'containers/PrimaryCare/LaboratoryAnalysis/LabWaitingResults.jsx';
import WaitingRoomHome from 'views/WaitingRoomHome.jsx';
import InPersonVisit from 'containers/PrimaryCare/Doctor/InPersonVisit.jsx';
import CheckInHome from 'views/CheckInHome.jsx';
import CallPatientOptions from 'views/ehr/CallPatientOptions.jsx';
import MonitorDevice from 'views/ehr/MonitorDevice.jsx';
import PatientHomePage from 'views/PatientHomePage.jsx';
import Visitor from 'views/Visitor.jsx';
import VcpCheckIn from 'containers/PrimaryCare/LaboratoryAnalysis/VcpCheckIn.jsx';
import DoctorHomePageWrapper from 'views/Doctors/DoctorHomePageWrapper.jsx';
import QrCode from 'views/QrCode.jsx';
import RpmPatientOnboarding from 'containers/Rpm/RpmOnboardingPatient.jsx';
import Logistics from 'views/Logistics.jsx';
import Dashboard from 'views/Dashboard.jsx';
import CallRedirect from 'calls/CallRedirect.jsx';
import AddEditRpmProgram from 'containers/Rpm/AddEditRpmProgram.jsx';
import EHRCallPatientJoinAsGuest from 'views/Partials/EHRCallPatientJoinAsGuest.jsx';
import QueueManagementWrapper from 'views/QueueManagement/QueueManagementWrapper.jsx';
import FamilyMemberPatients from 'containers/Rpm/FamilyMemberPatients.jsx';
import SessionsQueue from 'views/SessionsQueue.jsx';
import NursePatients from 'components/NursePatients.jsx';
import CareEventsDashboard from 'containers/CareEventsDashboard/CareEventsDashboard.jsx';
import Session from 'components/DashboardCharts/PatientCentric/Session.jsx';
import SessionDetails from 'components/DashboardCharts/PatientCentric/SessionDetails.jsx';
import MonitoringWrapper from 'views/MonitoringWrapper.jsx';
import AlertCenterWrapper from 'views/AlertCenterWrapper.jsx';
import FleetManagement from 'views/FleetManagement.jsx';
import PatientMonitoring from 'containers/Rpm/PatientMonitoring.jsx';

const DefaultRoutes = (
	<Switch>
		<Route exact={true} path='/signin-oidc' component={Callback} />
		<Route exact={true} path='/logout' component={Logout} />
		<Route exact={true} path='/logout/callback' component={LogoutCallback} />
		<Route exact={true} path='/silent-renew' component={SilentRenew} />
		<Route exact={true} path='/recover' component={Recover} />
		<Route exact={true} path='/validate/recoverPassword' component={ResetPassword} />
		<Route exact={true} path='/validate/changeEmail' component={VerifyChangedEmail} />
		<Route path='/complete-profile' component={CompleteProfile} />
		<Route path='/privacy-policy' component={PrivacyPolicy} />
		<Route path='/terms-of-use' component={TermsOfUse} />
		<Route path='/call-session/:conferenceId/invitation/:invitationSecret' component={JoinAsGuest} />
		<PrivateRoute
			roles={[UserRoles.GUEST]}
			path='/virtual-session/:conferenceId/invitation/:invitationSecret/:refToken'
			component={JoinAsGuest}
		/>
		<PrivateRoute
			roles={[UserRoles.GUEST]}
			path='/virtual-session/:startConferenceId/:callType/:deviceId/:name'
			component={JoinAsGuest}
		/>
		<PrivateRoute
			roles={[UserRoles.NURSE]}
			path='/virtual-care-session/:conferenceId/invitation/:invitationSecret/:refToken/:isDirect'
			component={EHRCallPatientJoinAsGuest}
		/>
		<PrivateRoute
			roles={[UserRoles.NURSE]}
			path='/virtual-care-session/:startConferenceId/:callType/:deviceId/:isDirect'
			component={EHRCallPatientJoinAsGuest}
		/>
		<Route path='/help' component={Help} />
		<Route path='/qrcode' component={QrCode} />
		<Route exact={true} path='/' component={Home} />
		<PrivateRoute exact={true} roles={[UserRoles.PATIENT]} path='/patient-home' component={PatientHomePage} />
		<PrivateRoute exact={true} roles={[UserRoles.DOCTOR]} path='/doctor-home' component={DoctorHomePageWrapper} />
		<PrivateRoute exact={true} roles={[UserRoles.NURSE]} path='/nurse-home' component={VcpCheckIn} />
		<Route exact={true} path='/hello/specs' component={TechSpecs} />
		<Route exact={true} path='/hello' component={HelloLandingPage} />
		<PrivateRoute exact path='/continue-as' component={ContinueAs} />
		<PrivateRoute roles={[UserRoles.DOCTOR]} path='/patients/:deviceId/:tab/:subTab' component={Patients} />
		<PrivateRoute roles={[UserRoles.DOCTOR]} path='/patients/:deviceId/:tab' component={Patients} />
		<PrivateRoute roles={[UserRoles.DOCTOR]} path='/patients/:deviceId' component={Patients} />
		<PrivateRoute roles={[UserRoles.DOCTOR]} path='/patients' component={Patients} />
		<PrivateRoute roles={[UserRoles.DIGITAL_CLINICIAN]} path='/digital-clinician/patients' component={PatientReports} />
		<PrivateRoute roles={[UserRoles.NURSE]} path='/nurses/:id' component={Nurses} />
		<PrivateRoute roles={[UserRoles.NURSE]} path='/nurses' component={Nurses} />
		<PrivateRoute roles={[UserRoles.NURSE]} path='/nurse-patients/:id' component={NursePatients} />
		<PrivateRoute roles={[UserRoles.NURSE]} path='/nurse-patients' component={NursePatients} />
		<PrivateRoute roles={[UserRoles.VISITOR, UserRoles.NURSE]} path='/family-members-chat' component={FamilyMembersChat} />
		<PrivateRoute roles={[UserRoles.PATIENT]} path='/health-data/:subTab' component={MyVitalSigns} />
		<PrivateRoute roles={[UserRoles.PATIENT]} path='/health-data' component={MyVitalSigns} />
		<PrivateRoute roles={[UserRoles.PATIENT]} path='/recent/:id' component={MyDoctors} />
		<PrivateRoute roles={[UserRoles.PATIENT]} path='/recent' component={MyDoctors} />
		<PrivateRoute roles={[UserRoles.PATIENT]} path='/summary' component={PatientSummary} />
		<PrivateRoute roles={[UserRoles.PATIENT]} path='/select-doctor' component={DoctorRequestsWrapper} />
		<PrivateRoute exact={true} roles={[UserRoles.SUPER_ADMIN, UserRoles.ADMIN]} path='/audit-logs' component={AuditLogs} />
		<PrivateRoute
			exact={true}
			roles={[UserRoles.NURSE, UserRoles.DOCTOR]}
			path='/call-patient-options'
			component={CallPatientOptions}
		/>
		<PrivateRoute
			roles={[UserRoles.NURSE, UserRoles.DOCTOR, UserRoles.ADMIN, UserRoles.SUPER_USER]}
			path='/health-system/:hospital/department/:department/floor/:floor/room/:room'
			component={HealthSystem}
		/>
		<PrivateRoute
			roles={[UserRoles.NURSE, UserRoles.DOCTOR, UserRoles.ADMIN, UserRoles.SUPER_USER]}
			path='/health-system/:hospital/department/:department/floor/:floor'
			component={HealthSystem}
		/>
		<PrivateRoute
			roles={[UserRoles.NURSE, UserRoles.DOCTOR, UserRoles.ADMIN, UserRoles.SUPER_USER]}
			path='/health-system/:hospital/department/:department'
			component={HealthSystem}
		/>
		<PrivateRoute
			roles={[UserRoles.NURSE, UserRoles.DOCTOR, UserRoles.ADMIN, UserRoles.SUPER_USER]}
			path='/health-system/:hospital'
			component={HealthSystem}
		/>
		<PrivateRoute
			roles={[UserRoles.NURSE, UserRoles.DOCTOR, UserRoles.ADMIN, UserRoles.SUPER_USER]}
			path='/health-system'
			component={HealthSystem}
		/>
		<PrivateRoute
			roles={[UserRoles.NURSE]}
			path='/visitors/:hospital/department/:department/floor/:floor/room/:room'
			component={Visitors}
		/>
		<PrivateRoute roles={[UserRoles.NURSE]} path='/visitors/:hospital/department/:department/floor/:floor' component={Visitors} />
		<PrivateRoute roles={[UserRoles.NURSE]} path='/visitors/:hospital/department/:department' component={Visitors} />
		<PrivateRoute roles={[UserRoles.NURSE]} path='/visitors/:hospital' component={Visitors} />
		<PrivateRoute roles={[UserRoles.NURSE]} path='/visitors' component={Visitors} />
		<PrivateRoute roles={[UserRoles.NURSE, UserRoles.VIRTUAL_SITTER]} path='/monitoring' component={MonitoringWrapper} />
		<PrivateRoute
			roles={[UserRoles.NURSE, UserRoles.DOCTOR]}
			type={RouteTypes.DEFAULT}
			path='/waiting-room'
			component={WaitingRoom}
		/>
		<PrivateRoute roles={[UserRoles.DOCTOR]} type={RouteTypes.DEFAULT} path='/sessions-queue' component={SessionsQueue} />
		<PrivateRoute
			roles={[UserRoles.NURSE, UserRoles.VIRTUAL_SITTER]}
			path='/monitor-device/:deviceId'
			component={MonitorDevice}
		/>
		<PrivateRoute roles={[UserRoles.DOCTOR, UserRoles.PATIENT]} path='/appointments' component={Appointments} />
		<PrivateRoute roles={[UserRoles.ADMIN]} path='/create-health-system' component={CreateHealthSystem} />
		<PrivateRoute roles={[UserRoles.ADMIN, UserRoles.SUPER_USER]} path='/dashboard/session/details' component={SessionDetails} />
		<PrivateRoute roles={[UserRoles.ADMIN, UserRoles.SUPER_USER]} path='/dashboard/session' component={Session} />
		<PrivateRoute roles={[UserRoles.ADMIN, UserRoles.SUPER_USER]} path='/dashboard/provider' component={Session} />
		<PrivateRoute roles={[UserRoles.ADMIN, UserRoles.SUPER_USER]} path='/fleet-management' component={FleetManagement} />
		<PrivateRoute roles={[UserRoles.ADMIN, UserRoles.SUPER_USER]} path='/dashboard' component={Dashboard} />
		<PrivateRoute roles={[UserRoles.ADMIN, UserRoles.SUPER_USER, UserRoles.SUPER_ADMIN]} path='/users' component={Users} />
		<PrivateRoute roles={[UserRoles.SUPER_ADMIN]} path='/companies' component={Companies} />
		<PrivateRoute roles={[UserRoles.SUPER_ADMIN]} path='/create-company' component={CreateCompanyWrapper} />
		<PrivateRoute roles={[UserRoles.ADMIN, UserRoles.SUPER_USER]} path='/call-logs' component={CallLogs} />
		<PrivateRoute roles={[UserRoles.ADMIN, UserRoles.SUPER_USER]} exact path='/configurations' component={Configurations} />
		<PrivateRoute
			roles={[UserRoles.ADMIN, UserRoles.SUPER_USER]}
			exact
			path='/configurations/:id/type/:type'
			component={ConfigurationsHistory}
		/>
		<Route exact={true} path='/onboarding/country' component={SelectCountry} />
		<Route exact={true} path='/onboarding/doctor' component={DoctorOnboarding} />
		<Route exact={true} path='/onboarding/patient' component={PatientOnboarding} />
		<Route exact={true} path='/onboarding/select-company' component={SelectPatientCompany} />
		<Route exact={true} path='/onboarding/activation' component={UserActivation} />
		<Route exact={true} path='/validate/company' component={CompleteCompany} />
		<PrivateRoute
			roles={[UserRoles.DOCTOR, UserRoles.PATIENT, UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN]}
			path='/call/:callType/:patientId/:conferenceName/:incoming'
			component={Call}
		/>
		<PrivateRoute
			roles={[UserRoles.DOCTOR, UserRoles.PATIENT, UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN, UserRoles.GUEST]}
			path='/call'
			component={ConferenceCall}
			render={conferenceRouteRender}
		/>
		<PrivateRoute
			roles={[UserRoles.DOCTOR, UserRoles.PATIENT, UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN]}
			path='/patient-feed'
			component={ConferenceCall}
			render={conferenceRouteRender}
		/>
		<PrivateRoute
			roles={[UserRoles.DOCTOR, UserRoles.PATIENT, UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN, UserRoles.GUEST]}
			path='/start-call/:objectId/:objectType/:name/:type/:conferenceId'
			component={CallRedirect}
		/>
		<PrivateRoute roles={[UserRoles.DIGITAL_CLINICIAN]} path='/check-in-dc' component={DigitalClinicianCheckIn} />
		<PrivateRoute roles={[UserRoles.DIGITAL_CLINICIAN]} path='/vital-signs-monitoring' component={VitalSignsMonitoring} />
		<PrivateRoute
			roles={[UserRoles.DOCTOR, UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN]}
			path='/vital-signs-monitoring'
			component={VitalSignsMonitoring}
		/>
		<PrivateRoute roles={[UserRoles.DOCTOR, UserRoles.NURSE]} path='/logistics' component={Logistics} />
		<PrivateRoute
			roles={[UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN, UserRoles.VIRTUAL_SITTER]}
			path='/teams'
			component={Teams}
		/>
		<PrivateRoute roles={[UserRoles.PATIENT]} path='/my-hello-devices/:id' component={MyHelloDevices} />
		<PrivateRoute roles={[UserRoles.PATIENT]} path='/my-hello-devices' component={MyHelloDevices} />
		<PrivateRoute roles={[UserRoles.VISITOR]} path='/devices' component={Visitor} />
		<PrivateRoute roles={[UserRoles.PATIENT]} path='/signs-symptoms' component={SignsSymptoms} />
		<PrivateRoute roles={[UserRoles.PATIENT]} path='/available-doctors' component={AvailableDoctors} />
		<PrivateRoute roles={[UserRoles.DIGITAL_CLINICIAN]} path='/front-desk-visits' component={DigitalClinicianVisits} />
		<PrivateRoute roles={[UserRoles.DOCTOR]} path='/complete-registration' component={CompleteRegistration} />
		<PrivateRoute path='/account-settings' component={AccountSettings} />
		<PrivateRoute path='/coming-soon' component={ComingSoon} />
		<PrivateRoute
			type={RouteTypes.PRIMARY_CARE}
			roles={[UserRoles.DOCTOR]}
			path='/waiting-room-inperson/:patientId'
			component={InPersonVisit}
		/>
		<PrivateRoute
			type={RouteTypes.PRIMARY_CARE}
			roles={[UserRoles.NURSE, UserRoles.DOCTOR]}
			path='/waiting-room-inperson'
			component={WaitingRoomHome}
		/>
		<PrivateRoute
			type={RouteTypes.PRIMARY_CARE}
			roles={[UserRoles.DOCTOR, UserRoles.PATIENT, UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN]}
			path='/call/:callType/:patientId/:conferenceName/:incoming'
			component={Call}
		/>
		<PrivateRoute roles={[UserRoles.DIGITAL_CLINICIAN]} path='/check-in-patient' component={CheckInHome} />
		<PrivateRoute roles={[UserRoles.NURSE]} path='/waiting-results' component={LabWaitingResults} />
		<PrivateRoute roles={[UserRoles.DOCTOR, UserRoles.NURSE]} path='/rpm-patient-onboarding' component={RpmPatientOnboarding} />
		<PrivateRoute roles={[UserRoles.DOCTOR, UserRoles.NURSE]} path='/patient-monitoring' component={PatientMonitoring} />
		<PrivateRoute roles={[UserRoles.ADMIN]} path='/add-edit-rpm' component={AddEditRpmProgram} />
		<PrivateRoute roles={[UserRoles.SUPER_USER]} path='/queue-management' component={QueueManagementWrapper} />
		<PrivateRoute roles={[UserRoles.VISITOR]} path='/dependants' component={FamilyMemberPatients} />
		<PrivateRoute roles={[UserRoles.NURSE, UserRoles.VIRTUAL_SITTER]} path='/ambient-monitoring' component={AlertCenterWrapper} />
		<PrivateRoute roles={[UserRoles.DOCTOR, UserRoles.NURSE]} path='/care-events-dashboard' component={CareEventsDashboard} />
		<PrivateRoute path='*' component={Home} />
	</Switch>
);

export default DefaultRoutes;
