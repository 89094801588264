import * as React from 'react';

const Download = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width={16} height={16} fill='none' {...props}>
		<path stroke='currentColor' strokeLinecap='round' strokeWidth={1.5} d='M2 10v1.333a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V10' />
		<path
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={1.5}
			d='M5.333 7.667 8 10l2.667-2.333'
		/>
		<path stroke='currentColor' strokeLinecap='round' strokeWidth={1.5} d='M8 10V3.333' />
	</svg>
);

Download.defaultProps = {
	color: '#5390FF',
};

export default Download;
