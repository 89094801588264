import React, { useState, useEffect } from 'react';
import { injectIntl, useIntl } from 'react-intl';
import classNames from 'classnames';
import { createMedicalInfo, deleteMedicalInfo, getClinicalDiagnoses, getClinicalProcedures } from 'api/medicalInfo.js';
import { AlertTypes, MedicalInfoTypes } from 'constants/enums.js';
import Alert from 'components/Alert.jsx';
import translate from 'i18n-translations/translate.jsx';
import Loader from 'components/Loader.jsx';
import Grid from 'components/Grid.jsx';
import Modal from 'components/Modal.jsx';
import PopUpAlert from 'components/PopUpAlert.jsx';
import { useConferenceConfigurations } from 'calls/hooks/index.js';

const AddDiagnosesOrProcedures = props => {
	const [searchValue, setSearchValue] = useState('');
	const [itemsToSelect, setItemsToSelect] = useState([]);
	const [error, setError] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [selectedItemId, setSelectedItemId] = useState(null);
	const [isAdding, setIsAdding] = useState(false);
	const [medicalItemExists, setMedicalItemExists] = useState(null);
	const intl = useIntl();
	const conferenceConfigurations = useConferenceConfigurations();

	useEffect(() => {
		const getClinicalItems = async () => {
			if (searchValue === '') {
				setItemsToSelect([]);
				return;
			}
			const response =
				props.type === MedicalInfoTypes.PROCEDURES
					? await getClinicalProcedures(searchValue)
					: await getClinicalDiagnoses(searchValue);
			if (response.error) {
				setError(response.error.message);
			} else {
				setItemsToSelect(response);
			}
		};

		getClinicalItems();
	}, [searchValue, props.type]);

	const itemExists = code =>
		(props.diagnoses?.length && props.diagnoses?.some(item => item.code === code)) ||
		(props.procedures?.length && props.procedures?.some(item => item.code === code));

	const saveItem = async ({ code, description }) => {
		setMedicalItemExists(null);
		const params = {
			visitId: props.visitId,
			code,
			description,
			type: props.type,
		};
		if (itemExists(code)) {
			setMedicalItemExists(
				translate('medicalItemExists', {
					value:
						props.type === MedicalInfoTypes.PROCEDURES
							? intl.formatMessage({ id: 'anotherProcedure' })
							: intl.formatMessage({ id: 'diagnose' }),
				})
			);
			return;
		}
		setSearchValue('');
		setIsLoading(true);
		const response = await createMedicalInfo(params);
		if (response.error) {
			setError(response.error.message);
			setIsAdding(false);
		} else {
			const item = {
				code,
				description,
				id: response.id,
			};
			props.setMedicalInfo([...props[props.type], item]);
			setIsAdding(false);
		}
		setIsLoading(false);
	};

	const deleteItem = async id => {
		const params = {
			visitId: props.visitId,
			id,
			type: props.type,
		};
		const response = await deleteMedicalInfo(params);
		if (response.error) {
			setError(response.error.message);
		} else {
			const items = props[props.type].filter(item => item.id !== id);
			props.setMedicalInfo(items);
			setSelectedItemId(null);
		}
	};

	const handleChange = e => {
		setSearchValue(e.target.value);
	};

	return (
		<aside
			className={classNames(
				'right-side medical-info-aside',
				conferenceConfigurations.isRightToLeft ? 'rtl-medical-info-aside' : '',
				conferenceConfigurations.isDarkMode ? 'dark-medical-info-aside' : ''
			)}>
			{!isLoading && (
				<>
					{props.type === MedicalInfoTypes.DIAGNOSES && <h4>{translate('diagnoses')} (ICD-10-CM)</h4>}
					{props.type === MedicalInfoTypes.PROCEDURES && <h4>{translate('procedures')} (CPT-4)</h4>}
					{isAdding && (
						<div className='mi-input-wrapper'>
							<input type='text' onChange={handleChange} value={searchValue} />
							{itemsToSelect.length > 0 && (
								<div>
									{itemsToSelect.map(item => (
										<div key={item.code} className='flex cursor-pointer' onClick={() => saveItem(item)}>
											<p>{item.code}</p>
											<p>{item.description}</p>
										</div>
									))}
								</div>
							)}
						</div>
					)}
					{isAdding && (
						<button className='button small block' type='button' onClick={() => setIsAdding(false)}>
							{translate('cancel')}
						</button>
					)}
					{!isAdding && props[props.type].length > 0 && (
						<button className='button small block' type='button' onClick={() => setIsAdding(true)}>
							{translate(props.type === MedicalInfoTypes.DIAGNOSES ? 'addDiagnose' : 'addProcedure')}
						</button>
					)}
					<div className='mi-selected-list'>
						<p>{translate('selectedItems')}</p>
						{props[props.type].map(item => (
							<div key={item.id} className='position-relative cursor-pointer medical-icons'>
								<i className='material-icons' onClick={() => setSelectedItemId(item.id)}>
									close
								</i>
								<p>{item.code}</p>
								<p>{item.description}</p>
							</div>
						))}
						{props[props.type].length === 0 && (
							<div className='d-p-no-items diagnoses-procedures-no-items'>
								<p>
									{translate('noAdded', {
										value: intl.formatMessage({ id: props.type }).toLowerCase(),
									})}
								</p>
							</div>
						)}
					</div>
					{props[props.type].length === 0 && (
						<button className='button small block' type='button' onClick={() => setIsAdding(true)}>
							{translate(props.type === MedicalInfoTypes.DIAGNOSES ? 'addDiagnose' : 'addProcedure')}
						</button>
					)}
				</>
			)}
			{isLoading && (
				<Grid columns='1fr' rows='1fr' stretch='calc(100vh - 200px)' horizAlign='center' vertAlign='center'>
					<div style={{ textAlign: 'center' }}>
						<Loader />
					</div>
				</Grid>
			)}
			<Modal
				display={selectedItemId}
				onModalSubmit={() => deleteItem(selectedItemId)}
				onModalClose={() => setSelectedItemId(null)}
				position='center'
				primaryButtonLabel={translate('delete')}>
				<form>
					<h3>{translate('delete')}</h3>
					<p>
						{translate('areYouSureDeleteItem', {
							value: intl
								.formatMessage({
									id: props.type.slice(0, -1),
								})
								.toLowerCase(),
						})}
					</p>
				</form>
			</Modal>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
			<PopUpAlert
				alertType={AlertTypes.DANGER}
				display={medicalItemExists}
				onAlertClose={() => setMedicalItemExists(null)}
				contentText={medicalItemExists}
				isSilent={true}
				center={true}
			/>
		</aside>
	);
};

export default injectIntl(AddDiagnosesOrProcedures);
