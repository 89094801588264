import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { createNoteOrUpdate } from 'api/notes.js';
import { NotesType } from 'constants/enums.js';
import { actionCreators as patientNotesActionCreators } from 'state/patientNotes/actions.js';
import { Alert, Form, Grid, Loader, Textarea } from 'components/index.js';
import { ProfileIcon } from 'icons/Monitoring/index.js';
import translate from 'i18n-translations/translate.jsx';

const PatientNotesSimplified = ({ deviceId, roomId, hierarchyNaming }) => {
	const intl = useIntl();
	const dispatch = useDispatch();
	const maxCharacterLength = 255;
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const patientDataSaved = useSelector(state => state.patientNotes.savedMode);
	const [patientNote, setPatientNote] = useState('');
	const [expandedBox, setExpandedBox] = useState(true);
	const modeEnums = { SAVED: 1, EDIT: 2 };
	const [fontSize, setFontSize] = useState(14);
	const [mode, setMode] = useState(modeEnums.SAVED);
	const modeRef = useRef(modeEnums.SAVED);
	const noteRef = useRef('');
	const maxFontSize = 16;
	const minFontSize = 12;
	const prevDeviceIdRef = useRef();
	const prevRoomIdRef = useRef();

	useEffect(() => {
		if (roomId !== prevRoomIdRef.current && mode === modeEnums.EDIT) {
			handleSubmit(true);
		}
		setPatientNote(patientDataSaved[deviceId]?.notes);
		prevDeviceIdRef.current = deviceId;
		prevRoomIdRef.current = roomId;
	}, [deviceId, roomId]);

	useEffect(() => {
		const updateOnLeave = () => {
			if (modeRef.current === modeEnums.EDIT && noteRef.current.length > 0) {
				createNoteOrUpdate(roomId, noteRef.current);
			}
		};
		return updateOnLeave;
	}, []);

	const handleSubmit = async savePrevious => {
		setIsLoading(true);
		const deviceIdToSave = savePrevious ? prevDeviceIdRef.current : deviceId;
		const roomIdToSave = savePrevious ? prevRoomIdRef.current : roomId;
		const response = await createNoteOrUpdate(roomIdToSave, patientNote, NotesType.SITTER);
		if (response.error) {
			setError(response.error.message);
			setIsLoading(false);
			return;
		}
		dispatch(
			patientNotesActionCreators.setPatientDataSaved({ ...patientDataSaved[deviceIdToSave], notes: patientNote }, deviceIdToSave)
		);
		setIsLoading(false);
		setMode(modeEnums.SAVED);
		modeRef.current = modeEnums.SAVED;
	};

	return (
		<div className='monitoring-timeline-box'>
			<div className={classNames('timeline-box-header', expandedBox ? 'expanded' : '')}>
				<p className='timeline-box-title'>
					<ProfileIcon />
					{translate('patientInfo')}
				</p>
				<div className='timeline-box-actions'>
					<button type='button' onClick={() => setExpandedBox(prevState => !prevState)}>
						<i className={classNames('material-icons', expandedBox ? ' rotate' : '')}>expand_more</i>
					</button>
				</div>
			</div>
			{expandedBox && (
				<div className={classNames('timeline-box-content', expandedBox ? ' expanded' : '')}>
					<div className='patient-informations'>
						{isLoading && (
							<Grid columns='1fr' rows='1fr' horizAlign='center' vertAlign='center'>
								<Loader />
							</Grid>
						)}
						{!isLoading && (
							<>
								<Form className='profile-simplified-information-form'>
									<p>{`${hierarchyNaming.hospital}, ${hierarchyNaming.floor}, ${hierarchyNaming.room}`}</p>
									<div className='flex flex-align-center simplified-form-actions'>
										<button
											type='button'
											disabled={fontSize === minFontSize}
											onClick={fontSize > minFontSize ? () => setFontSize(prevFont => prevFont - 2) : null}>
											<i className='material-icons-outlined'>text_decrease</i>
										</button>
										<button
											type='button'
											disabled={fontSize === maxFontSize}
											onClick={fontSize < maxFontSize ? () => setFontSize(prevFont => prevFont + 2) : null}>
											<i className='material-icons-outlined'>text_increase</i>
										</button>

										{mode === modeEnums.SAVED && (
											<button
												style={{ color: 'var(--gray-1)' }}
												onClick={() => {
													setMode(modeEnums.EDIT);
													modeRef.current = modeEnums.EDIT;
												}}
												type='button'>
												{intl.formatMessage({ id: 'edit' }).toUpperCase()}
											</button>
										)}

										{mode === modeEnums.EDIT && (
											<button style={{ color: 'var(--blue-2)' }} type='button' onClick={handleSubmit}>
												{intl.formatMessage({ id: 'save' }).toUpperCase()}
											</button>
										)}
									</div>

									<div className='flex flex-space-between flex-wrap'>
										<Textarea
											name='note'
											className='text-input'
											rows={10}
											maxNumberLimit={40}
											value={patientNote}
											maxLength={maxCharacterLength}
											disabled={mode === modeEnums.SAVED}
											placeholder={intl.formatMessage({ id: 'enterPatientInformation' })}
											onChange={event => {
												event.preventDefault();
												setPatientNote(event.target.value);
											}}
											style={{ fontSize: `${fontSize}px` }}
										/>
									</div>
								</Form>
							</>
						)}

						<Alert display={error} fixed hideCloseButton message={error} variant='dark' />
					</div>
				</div>
			)}
		</div>
	);
};

export default PatientNotesSimplified;
