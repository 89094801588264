import React, { useContext, useEffect, useState } from 'react';
import { subDays } from 'date-fns';
import { Alert, Modal, Table } from 'components';
import translate from 'i18n-translations/translate.jsx';
import { exportHandHygieneAudit, getHandHygieneAudits } from 'api/handHygiene.js';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';
import { SectorTypes, TaskStatus } from 'constants/enums.js';
import Pagination from 'components/Common/Pagination.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { getUserInfo } from 'infrastructure/auth.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';
import DateRange from 'components/DateRange.jsx';

const HandHygieneAudit = ({ currentSector }) => {
	const [handHygieneAudits, setHandHygieneAudits] = useState([]);
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0, totalCount: 0 });
	const [dateRange, setDateRange] = useState({ from: subDays(new Date(), 7), to: new Date() });
	const [error, setError] = useState(null);
	const [isExportLogsModalOpen, setIsExportLogsModalOpen] = useState(false);
	// const toInput = useRef(null);
	const [hasExportLogsModalErrors, setHasExportLogsModalErrors] = useState(false);
	const [isTaskLoading, setIsTaskLoading] = useState(false);
	const userProfile = getUserInfo();
	const socket = useContext(SocketContext);

	const getLevelId = () => {
		if (currentSector.sectorType === SectorTypes.DEPARTMENT) {
			return currentSector.departmentId;
		}
		if (currentSector.sectorType === SectorTypes.FLOOR) {
			return currentSector.floorId;
		}
		if (currentSector.sectorType === SectorTypes.ROOM) {
			return currentSector.floorId;
		}
		return null;
	};

	const getRoomId = () => currentSector?.roomId ?? null;

	useEffect(() => {
		const fetchHandHygieneAudit = async () => {
			const response = await getHandHygieneAudits({
				levelId: getLevelId(),
				pageSize: pagination.pageSize,
				pageIndex: pagination.pageIndex,
				roomId: getRoomId(),
				startDate: dateRange.from.toLocaleDateString(),
				endDate: dateRange.to.toLocaleDateString(),
			});
			if (response?.error) {
				setError(response.error.message);
				return;
			}
			setPagination(prevState => ({ ...prevState, totalCount: response.total ?? 0 }));
			setHandHygieneAudits(response?.handSanytizingAudits);
		};

		fetchHandHygieneAudit();
	}, [pagination.pageSize, pagination.pageIndex, dateRange, currentSector]);

	const displayHandHygieneAudit = () => {
		if (!handHygieneAudits || handHygieneAudits.length === 0) {
			return [];
		}
		return handHygieneAudits.map(handHygieneAudit => ({
			unit: handHygieneAudit.unit,
			serialNumber: handHygieneAudit.serialNumber,
			subjectIdentification: handHygieneAudit.subjectIdentification,
			employeeDirection: handHygieneAudit.employeeDirection,
			handHygieneViewed: handHygieneAudit.handHygieneViewed,
			startDate: handHygieneAudit.eventDate ? formattedDate(handHygieneAudit.eventDate) : '',
		}));
	};

	const closeExportLogsModal = () => {
		setIsExportLogsModalOpen(false);
		setHasExportLogsModalErrors(false);
	};

	useEffect(() => {
		const handleTaskStatusUpdated = data => {
			if (data.taskStatusId === TaskStatus.COMPLETED) {
				setIsTaskLoading(false);
			}
			if (data.taskStatusId === TaskStatus.FAULTED) {
				setError(translate('somethingWentWrong'));
				setIsTaskLoading(false);
			}
		};
		socket.on(SocketEvents.BACKGROUND.TASK_UPDATED, handleTaskStatusUpdated);
		return () => {
			socket.off(SocketEvents.BACKGROUND.TASK_UPDATED, handleTaskStatusUpdated);
		};
	}, [socket]);

	const startExportTask = async () => {
		setIsTaskLoading(true);
		setError(null);
		const response = await exportHandHygieneAudit({
			levelId: getLevelId(),
			roomId: getRoomId(),
			startDate: dateRange.from,
			endDate: dateRange.to,
		});
		setHasExportLogsModalErrors(!!response.error);
		setIsExportLogsModalOpen(!!response.error);
	};

	return (
		<div className='full-width'>
			<div className='position-relative flex flex-align-center hand-hygiene-wrapper'>
				<div className='flex'>
					<DateRange startDate={dateRange.from} endDate={dateRange.to} handleRangeChange={setDateRange} maxDays={90} />
				</div>
				<div
					className={`button-download-excel cursor-pointer ${dateRange.to && dateRange.from ? '' : 'disabled'}`}
					onClick={() => setIsExportLogsModalOpen(true)}>
					{translate('exportExcel')}
				</div>
			</div>

			<Table
				headers={[
					{ title: translate('unit'), id: 0 },
					{ title: translate('serialNumber'), id: 1 },
					{ title: translate('subjectIdentification'), id: 2 },
					{ title: translate('employeeDirection'), id: 3 },
					{ title: translate('handHygieneViewed'), id: 4 },
					{ title: translate('startDate'), id: 5 },
				]}
				rows={displayHandHygieneAudit()}
				className='admin-table'
				isEditable={false}
			/>

			<Pagination
				totalCount={pagination.totalCount}
				pageSize={pagination.pageSize}
				pageIndex={pagination.pageIndex}
				onChange={(size, index) => setPagination(prevState => ({ ...prevState, pageSize: size, pageIndex: index }))}
			/>
			<Modal
				display={isExportLogsModalOpen}
				position='center'
				isLoading={isTaskLoading}
				onModalSubmit={startExportTask}
				primaryButtonLabel={translate('continue')}
				onModalClose={closeExportLogsModal}
				className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal'
				submitImgIcon={`${healthCareCdnUrl}appointments/save-icon.svg`}
				closeImgIcon={`${healthCareCdnUrl}appointments/cancel-appointment.svg`}>
				<form>
					<h3>{translate('exportExcel')}</h3>
					{!hasExportLogsModalErrors && userProfile && (
						<p>{translate('exportExcelBackground', { value: <strong>{userProfile.email}</strong> })}</p>
					)}
					{hasExportLogsModalErrors && <p>{translate('somethingWentWrong')}</p>}
				</form>
			</Modal>
			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
		</div>
	);
};
export default HandHygieneAudit;
