import React from 'react';
import classNames from 'classnames';
import ReactDOMServer from 'react-dom/server';

const BackgroundIcon = ({ onClick = () => { }, children, className, Icon, backgroundImgSize = 124, top = 20, right = 20 }) => {
    const iconString = ReactDOMServer.renderToString(<Icon />);
    const encodedIcon = encodeURIComponent(iconString);
    const backgroundImage = `url("data:image/svg+xml;charset=UTF-8,${encodedIcon}")`;

    return (
        <div
            onClick={onClick}
            className={classNames('background-icon', className)}
            style={{
                backgroundImage,
                backgroundSize: `${backgroundImgSize}px`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: `${top}% ${right}%`,
            }}
        >
            {children}
        </div>
    );
};

export default BackgroundIcon;