import React from 'react';

const InactiveTime = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none' viewBox='0 0 24 24' {...props}>
		<rect
			width='14'
			height='22'
			x='5'
			y='1'
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth='1.25'
			rx='3'
		/>
		<path
			fillRule='evenodd'
			stroke='currentColor'
			strokeLinejoin='round'
			strokeWidth='1.25'
			d='M8.5 13.254a.688.688 0 00.14.423c.056.067.127.12.207.153.077.031.159.046.241.044a.525.525 0 00.328-.095.54.54 0 00.204-.277l.868-3.56-.547 10.652a.832.832 0 00.174.6.81.81 0 00.542.3.692.692 0 00.095 0c.203.007.401-.064.554-.199a.82.82 0 00.273-.53l.434-5.703.434 5.704a.823.823 0 00.267.527c.15.135.345.209.547.205a.757.757 0 00.097 0 .802.802 0 00.54-.3.829.829 0 00.174-.6l-.558-10.654.868 3.56a.538.538 0 00.204.278c.096.068.211.1.328.095a.612.612 0 00.24-.045.545.545 0 00.324-.386.903.903 0 000-.39c-.121-.521-.523-3.2-1.028-4.599-.454-1.253-1.302-.916-2.452-.916s-1.992-.33-2.44.905c-.509 1.392-.913 4.093-1.034 4.616a1.057 1.057 0 00-.024.192z'
			clipRule='evenodd'
		/>
		<path stroke='currentColor' strokeLinejoin='round' strokeWidth='1.25' d='M13.5 5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z' />
		<path
			stroke='currentColor'
			strokeLinejoin='round'
			strokeWidth='1.25'
			d='M13.366 7.5c.134-.023 1.74-.094 1.87-.122.405-1.346.295-3.252 0-4.598-1.73-.373-4.745-.373-6.476 0-.347 1.325-.347 3.272 0 4.598.129.03 1.611.058 1.74.08'
		/>
	</svg>
);

export default InactiveTime;
