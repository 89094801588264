import React from 'react';

const LayingLeft = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none' viewBox='0 0 24 24' {...props}>
		<rect
			width={14}
			height={22}
			x={5}
			y={1}
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth={1.25}
			rx={3}
		/>
		<path
			stroke='currentColor'
			strokeLinejoin='round'
			strokeWidth={1.25}
			d='M11.246 8.411a12.239 12.239 0 0 1-1.95-.302 9.79 9.79 0 0 1 0-4.778 13.682 13.682 0 0 1 5.983 0c.325 1.335.381 2.722.166 4.08'
		/>
		<path
			stroke='currentColor'
			strokeLinejoin='round'
			strokeWidth={1.25}
			d='M11.042 6.257a1.409 1.409 0 0 0 1.186 1.482l.138.017h.18a1.417 1.417 0 1 0-1.504-1.499Z'
			clipRule='evenodd'
		/>
		<path
			stroke='currentColor'
			strokeLinejoin='round'
			strokeWidth={1.25}
			d='m11.88 9.923 1.81.974.852.457a.734.734 0 0 0 1.08-.675l.06-2.945a.734.734 0 0 0-1-.695.732.732 0 0 0-.464.668l-.038 1.792-.598-.324-.558-.299.525.204c-.272-.775-.56-.718-1.21-.759-.313-.019-.522-.103-.726-.035a.775.775 0 0 0-.375.332c-.607.881-.93 3.402-1.001 3.954-.106.816-.163 1.21.063 1.67.2.384.514.697.9.895l1.727 1.727-1.994 2.222a1.012 1.012 0 1 0 1.501 1.36l.05-.055 2.583-2.877a1.008 1.008 0 0 0 .228-.944.996.996 0 0 0-.304-.503l-2.029-2.323c.049-.106.103-.23.155-.37.28-.761.468-1.554.56-2.36a16.846 16.846 0 0 1-1.797-1.09Z'
			clipRule='evenodd'
		/>
		<path
			stroke='currentColor'
			strokeLinejoin='round'
			strokeWidth={1.25}
			d='m15.08 14.783-1.846-1.316c-.035.095-.073.185-.109.272l1.964 2.246c.114.1.206.223.272.36l.12-.493a1.009 1.009 0 0 0-.4-1.069ZM14.999 17.786l-2.448 2.72a1.006 1.006 0 0 0 1.844-.273L15 17.786Z'
			clipRule='evenodd'
		/>
		<path stroke='currentColor' strokeLinejoin='round' strokeWidth={1.25} d='M14.034 8.31c-.373.052-.746.088-1.121.107' />
	</svg>
);
export default LayingLeft;
