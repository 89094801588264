import React from 'react';

const SpirometerSummaryChart = props => {
	const svgCoordinates = {
		x1: 20,
		x2: 480,
		y: 130,
	};

	const safeRange = {
		x1: 25,
		x2: 50,
		x3: 75,
	};

	const maxGraphValue = 100;
	const lineLength = svgCoordinates.x2 - svgCoordinates.x1;
	const graphNumbersPosition = svgCoordinates.y + 50;
	const calcFactor = value => value * (lineLength / maxGraphValue) + svgCoordinates.x1;

	return (
		<>
			<div className='spirometer-graph'>
				<svg viewBox='0 0 500 250' xmlns='http://www.w3.org/2000/svg'>
					<line
						className='graph-background'
						x1={svgCoordinates.x1}
						x2={svgCoordinates.x2}
						y1={svgCoordinates.y}
						y2={svgCoordinates.y}
					/>
					<line
						className='safe-range'
						x1={calcFactor(0)}
						x2={calcFactor(props.measurementValue)}
						y1={svgCoordinates.y}
						y2={svgCoordinates.y}
					/>
					<g className='graph-scale-numbers'>
						<text x={svgCoordinates.x1} y={graphNumbersPosition}>
							0
						</text>
						<text x={calcFactor(safeRange.x1)} y={graphNumbersPosition}>
							{safeRange.x1}
						</text>
						<text x={calcFactor(safeRange.x2)} y={graphNumbersPosition}>
							{safeRange.x2}
						</text>
						<text x={calcFactor(safeRange.x3)} y={graphNumbersPosition}>
							{safeRange.x3}
						</text>
						<text x={svgCoordinates.x2} y={graphNumbersPosition}>
							{maxGraphValue}
						</text>
					</g>
				</svg>
			</div>

			<div className='flex summary-chart-data spirometer-data'>
				<h1>
					{props.measurementValue}
					{props.measurementValue !== 'No Data' && <span>{props.measurementUnit}</span>}
				</h1>
			</div>
		</>
	);
};

export default SpirometerSummaryChart;
