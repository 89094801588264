import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';
const companyId = getCompanyId();

export const addTimeZone = async ({ healthSystemId, regionId, hospitalId, timezone }) => {
	try {
		const url = `/v1/organizations/${companyId}/health-systems/${healthSystemId}/regions/${regionId}/hospitals/${hospitalId}`;
		const { data } = await gatewayApi.put(url, { timezone });
		return data;
	} catch (error) {
		return { error };
	}
};
