import React, { useEffect, useState, useRef, useCallback, useContext } from 'react';
import { useIntl } from 'react-intl';
import { sendCommand } from 'api/devices.js';
import { Form, Loader, Modal } from 'components';
import { DeviceCommands, DeviceStateNames, DeviceStatus } from 'constants/enums.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';

const PairUnpairDeviceRemote = ({ deviceId, room, onModalClose }) => {
	const [isLoading, setLoading] = useState(false);
	const [hasErrors, setErrors] = useState(false);
	const [isCommandSend, setCommandSend] = useState(false);
	const [message, setMessage] = useState('');
	const [timeLeft, setTimeLeft] = useState(120);
	const [isActionCompleted, setActionCompleted] = useState(false);
	const [isRetry, setRetry] = useState(false);
	const [currentStateChanged, setStateChangd] = useState(false);

	const socket = useContext(SocketContext);
	const timer = useRef(null);
	const intl = useIntl();

	const onDeviceStateUpdate = useCallback(
		data => {
			if (data.name === DeviceStateNames.HasPairedRemote && data.deviceId === room.helloDeviceId && isLoading) {
				setStateChangd(true);
				setLoading(false);
			}
		},
		[room?.helloDeviceId, isLoading]
	);

	useEffect(() => {
		if (currentStateChanged) {
			setStateChangd(false);
		}
	}, [currentStateChanged]);

	useEffect(() => {
		socket.on(SocketEvents.HelloDevice.ON_STATE_CHANGED, onDeviceStateUpdate);
		return () => {
			socket.off(SocketEvents.HelloDevice.ON_STATE_CHANGED, onDeviceStateUpdate);
		};
	}, [socket, onDeviceStateUpdate]);

	const handleModalSubmit = async () => {
		if (deviceId) {
			setLoading(true);
			setRetry(false);
			setCommandSend(true);
			const dynamicData = { pairRemote: !room.pairedRemote };
			const sendCommandResponse = await sendCommand({
				deviceId,
				command: DeviceCommands.PAIR_UNAPIR_REMOTE,
				reason: null,
				dynamicData: JSON.stringify(dynamicData),
			});
			setErrors(!!sendCommandResponse.error);
			timer.current = setInterval(() => {
				setTimeLeft(state => state - 1);
			}, 1000);
		}
	};

	const setModalText = useCallback(
		isClosedFromUser => {
			if (!isClosedFromUser) {
				if (isCommandSend && hasErrors) {
					setMessage(intl.formatMessage({ id: 'somethingWentWrong' }));
					setActionCompleted(true);
				}
			}
			if (room?.status !== DeviceStatus.ONLINE) {
				setMessage(intl.formatMessage({ id: 'deviceOfflineNow' }));
				return;
			}
			if (room?.hasPairRemoteErrors) {
				setMessage(intl.formatMessage({ id: 'somethingWentWrong' }));
			} else if (!isCommandSend) {
				setMessage(intl.formatMessage({ id: room.pairedRemote ? 'remotePairedWithDevice' : 'remoteNotPairedWithDevice' }));
			} else {
				setMessage(intl.formatMessage({ id: room.pairedRemote ? 'remotePairedSuccessfully' : 'remoteUnpairedSuccessfully' }));
			}
		},
		[room?.status, room?.pairedRemote, hasErrors, room?.hasPairRemoteErrors, isCommandSend, intl]
	);

	const resetState = () => {
		setLoading(false);
		setErrors(false);
		setCommandSend(false);
		setTimeLeft(120);
		clearInterval(timer.current);
		setActionCompleted(false);
	};

	useEffect(() => {
		if (room?.hasPairRemoteErrors) {
			setMessage(intl.formatMessage({ id: 'somethingWentWrong' }));
			resetState();
		}
	}, [room?.hasPairRemoteErrors, room?.pairedRemote, intl]);

	useEffect(() => {
		setModalText(true);
		return () => {
			clearInterval(timer.current);
		};
	}, [deviceId, setModalText]);

	useEffect(() => {
		if (timeLeft === 0) {
			setMessage(intl.formatMessage({ id: 'pairingTimeout' }));
			clearInterval(timer.current);
			timer.current = null;
			setLoading(false);
			setTimeLeft(120);
			setRetry(true);
		}
	}, [timeLeft, intl]);

	const getSubmitText = () => {
		if (isRetry) {
			return intl.formatMessage({ id: 'retry' });
		}
		if (isActionCompleted || isLoading || room?.status !== DeviceStatus.ONLINE || isCommandSend) {
			return '';
		}
		return intl.formatMessage({ id: room?.pairedRemote ? 'unpair' : 'pair' });
	};

	const handleModalClose = () => {
		onModalClose();
		setLoading(false);
		setErrors(false);
		setTimeLeft(120);
		clearInterval(timer.current);
		setModalText(true);
		setActionCompleted(false);
		setCommandSend(false);
	};

	return (
		<Modal
			modalSelector='pairUnpairDeviceRemoteModal'
			display={true}
			position='center'
			primaryButtonLabel={getSubmitText()}
			closeButtonText={intl.formatMessage({ id: isCommandSend || room.pairedRemote ? 'cancel' : 'dismiss' })}
			onModalSubmit={handleModalSubmit}
			onModalClose={handleModalClose}>
			<Form title={intl.formatMessage({ id: 'remoteControl' })}>
				{isLoading && (
					<>
						<div className='modal__loader'>
							<Loader />
							<p> {intl.formatMessage({ id: room.pairedRemote ? 'tryingToUnpairRemote' : 'tryingToPairRemote' })}</p>
						</div>
					</>
				)}
				{!isLoading && <p>{`${message}`}</p>}
			</Form>
		</Modal>
	);
};

export default PairUnpairDeviceRemote;
