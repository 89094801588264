import axios from 'axios';
import { UserTypes, UserRoles } from 'calls/enums/index.js';

const baseURL =
	// @ts-ignore
	process.env.NODE_ENV === 'development' ? process.env.REACT_APP_SERVER_PUBLIC_URL : window.__env__.REACT_APP_PUBLIC_URL;

export const localApi = axios.create({ baseURL });

const setGuestUser = userInfo => {
	sessionStorage.setItem('userId', userInfo.GuidId);
	sessionStorage.setItem(
		'userProfile',
		JSON.stringify({
			id: userInfo.GuidId,
			firstName: userInfo.FirstName,
			lastName: userInfo.LastName,
			userId: userInfo.Id,
			profilePicture: userInfo.ProfilePicture,
			email: userInfo.Email,
		})
	);
	sessionStorage.setItem('userRole', UserRoles.GUEST);
	sessionStorage.setItem('userRoleId', UserTypes.GUEST.toString());
};

/**
 * @param {string} conferenceId
 * @param {string} invitationSecret
 * @param {string} [interpreterId]
 * @returns {Promise<{ exists: boolean, message?: string, conference?: any }>}
 */
const checkActiveConference = async (conferenceId, invitationSecret, interpreterId = '') => {
	let url = `conferences/${conferenceId}/link/${invitationSecret}?`;
	if (interpreterId) {
		url = `${url}interpreterId=${interpreterId}`;
	}
	try {
		const { data } = await localApi.get(url);
		return data;
	} catch (error) {
		return { exists: false };
	}
};

/**
 * @param {string} name
 * @param {string} conferenceId
 * @param {string} invitationSecret
 */
const addGuestAccount = async (name, conferenceId, invitationSecret) => {
	try {
		const userResponse = await localApi.post(`conferences/guest`, {
			name,
			conferenceId,
			invitationSecret,
		});

		const authResult = {
			accessToken: userResponse.data.result.AccessToken,
			idToken: userResponse.data.result.IdToken,
		};

		sessionStorage.setItem('access_token', authResult.accessToken);

		setGuestUser(userResponse.data.result.UserInfo);

		return userResponse;
	} catch (error) {
		throw new Error(error);
	}
};

export { checkActiveConference, addGuestAccount };
