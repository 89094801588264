import React from 'react';

const RadioButtonIcon = props => (
	<div className='radiobutton-icon'>
		<label>
			<div className='flex'>
				<input
					onBlur={props.handleBlur}
					type='radio'
					name={props.name}
					onClick={props.handleChange}
					onChange={props.handleChange}
					checked={props.value === props.type}
					value={props.value}
				/>
				<div className='radiobutton-wrapper'>
					<span className='radiobutton-check' />
				</div>
				{props.icon && <i className='material-icons'>{props.icon}</i>}
				{props.iconSrc && <img className='practice-images sign-image' src={props.iconSrc} alt='img' />}
				{props.iconSrcChecked && <img className='practice-images sign-image-checked' src={props.iconSrcChecked} alt='img-blue' />}
				<h4 className='sign-value'>{props.text}</h4>
				<div className='sign-checked' />
			</div>
		</label>
	</div>
);

export default RadioButtonIcon;
