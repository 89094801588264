import React from 'react';

const CustomBarChart = ({ data }) => {
	return (
		<div className='custom-bar-chart' style={{ gridTemplateColumns: `repeat(${data.length}, 1fr)`, gridGap: '40px' }}>
			{data.map(entry => (
				<div key={entry.id} className='custom-bar' style={{ height: `${entry.percentage}%`, backgroundColor: entry.color }} />
			))}
		</div>
	);
};

export default CustomBarChart;
