import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik, Field } from 'formik';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Grid from 'components/Grid.jsx';
import Input from 'components/Common/FormElements/Input.jsx';
import Button from 'components/Button.jsx';
import Loader from 'components/Loader.jsx';
import { registerUser, checkUserAccount, acceptInvite, getInvite } from 'api/users.js';
import translate from 'i18n-translations/translate.jsx';
import { APP_CONFIG, healthCareCdnUrl } from 'constants/global-variables.js';
import { InvitationCode, InviteTypes } from 'constants/enums.js';
import { getUserInfo, isAuthenticated } from 'infrastructure/auth.js';
import LogOutUser from 'views/LogOutUser.jsx';
import { isMobileOrTablet } from 'infrastructure/helpers/commonHelpers.js';
import { countriesData } from 'calls/constants/index.js';
import classNames from 'classnames';

const CompleteProfile = () => {
	const countries = [
		{
			name: `${countriesData.US.flag} ${countriesData.US.phonePrefix}`,
			value: countriesData.US.phonePrefix,
		},
		{
			name: `${countriesData.KOS.flag} ${countriesData.KOS.phonePrefix}`,
			value: countriesData.KOS.phonePrefix,
		},
	];

	const [inviteValidation, setInviteValidation] = useState({
		loading: false,
		error: null,
		accountExists: false,
	});
	const [submitUserRegistration, setSubmitUserRegistration] = useState({
		loading: false,
		error: false,
		success: false,
	});

	const [inviteId, setInviteId] = useState(null);
	const [email, setEmail] = useState('');
	const [companyId, setCompanyId] = useState(null);
	const [phoneNumber, setPhoneNumber] = useState(null);
	const [inviteType, setInviteType] = useState(null);
	const [isTermsChecked, setIsTermsChecked] = useState(false);
	const [healthSystemName, setHealthSystemName] = useState('');
	const [selectedCountry, setSelectedCountry] = useState(countries[0]);
	const location = useLocation();
	const intl = useIntl();

	useEffect(() => {
		const query = new URLSearchParams(location.search);
		const cid = query.get('cid');
		const e = query.get('e');
		const id = +query.get('id');

		if (isMobileOrTablet()) {
			const publicUrl = APP_CONFIG.URL.localApiBasePath.split('//')[1];
			const uriScheme = APP_CONFIG.mobileAppUriScheme;
			window.location.replace(`${uriScheme}${publicUrl}complete-profile?companyId=${cid}&email=${e}&id=${id}`);
		}
		setInviteId(id);
		setEmail(e);
		setCompanyId(cid);
		const fetchData = async () => {
			const inviteResponse = await getInvite(cid, id, e);
			setHealthSystemName(inviteResponse?.invitationInfo?.teamName);
			setInviteType(inviteResponse.inviteTypeId);
			setInviteValidation(prevState => ({
				...prevState,
				loading: true,
				error: inviteResponse.error,
			}));
			if (!isAuthenticated()) {
				try {
					const accountExists = await checkUserAccount(e);
					if (accountExists && !inviteResponse.error) {
						await acceptInvite(id, e, cid);
						setInviteValidation(prevState => ({
							...prevState,
							loading: false,
							accountExists: true,
						}));
					} else {
						setInviteValidation(prevState => ({
							...prevState,
							loading: false,
							accountExists: false,
						}));
					}
				} catch (error) {
					setInviteValidation(prevState => ({
						...prevState,
						loading: false,
						error,
					}));
				}
			}
		};
		fetchData();
	}, [location.search]);

	const submitForm = async values => {
		if (inviteType === InviteTypes.FAMILY_MEMBER.type && !isTermsChecked) {
			return;
		}
		try {
			setSubmitUserRegistration(prevState => ({
				...prevState,
				loading: true,
			}));
			const dataToSend = {
				values,
				inviteId: inviteId,
				compId: companyId,
			};
			if (phoneNumber) {
				dataToSend.phoneNumber = `${selectedCountry.value} ${phoneNumber}`;
			}
			const result = await registerUser(dataToSend);
			if (result.hasSucceeded) {
				setSubmitUserRegistration(prevState => ({
					...prevState,
					loading: false,
					success: true,
				}));
			}
		} catch {
			setSubmitUserRegistration(prevState => ({
				...prevState,
				loading: false,
				error: true,
			}));
		}
	};

	const getInvitationMessage = () => {
		switch (inviteValidation.error.code) {
			case InvitationCode.ACCEPTED:
				return translate('inviteAlreadyAccepted');
			case InvitationCode.CANCELLED:
				return translate('inviteCancelled');
			case InvitationCode.EXPIRED:
				return translate('inviteExpired');
			default:
				return translate('checkInvitationLinkValidity');
		}
	};

	const validateForm = () => {
		return Yup.object().shape({
			firstName: Yup.string()
				.required(
					intl.formatMessage({
						id: 'pleaseWriteFirstName',
					})
				)
				.min(
					2,
					intl.formatMessage({
						id: 'firstNameMinLength',
					})
				)
				.max(
					30,
					intl.formatMessage({
						id: 'maxCharacterLengthExceeded',
					})
				)
				.trim(),
			lastName: Yup.string()
				.required(intl.formatMessage({ id: 'pleaseWriteLastName' }))
				.min(
					2,
					intl.formatMessage({
						id: 'lastNameMinLength',
					})
				)
				.max(
					30,
					intl.formatMessage({
						id: 'maxCharacterLengthExceeded',
					})
				)
				.trim(),
			email: Yup.string()
				.email()
				.required(intl.formatMessage({ id: 'pleaseWriteEmail' }))
				.max(
					512,
					intl.formatMessage({
						id: 'maxCharacterLengthExceeded',
					})
				),
			password: Yup.string()
				.required(intl.formatMessage({ id: 'pleaseWritePassword' }))
				.matches(
					// eslint-disable-next-line no-useless-escape
					/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*\(\)_\+\-\={}<>,\.\|""'~`:;\\?\/\[\] ]){8,}/,
					intl.formatMessage({ id: 'passwordCannotBeLessThan8Chart' })
				)
				.min(8, 'Min length is 8'),
			passwordConfirm: Yup.string()
				.required(
					intl.formatMessage({
						id: 'pleaseWriteConfirmPassword',
					})
				)
				.oneOf(
					[Yup.ref('password')],
					intl.formatMessage({
						id: 'passwordsMustMatch',
					})
				),
		});
	};

	if (isAuthenticated() && email === getUserInfo()?.email) {
		return <Redirect to='/' />;
	}

	if (isAuthenticated()) {
		return <LogOutUser />;
	}
	if (submitUserRegistration.loading) {
		return (
			<Grid width='100%' stretch='100vh' horizAlign='center' vertAlign='center' rows='auto'>
				<div style={{ textAlign: 'center' }}>
					<Loader />
					<p>{translate('accountBeingCreatedMayTakeMinutes')}</p>
				</div>
			</Grid>
		);
	}

	if (inviteValidation.loading) {
		return (
			<Grid width='100%' stretch='100vh' horizAlign='center' vertAlign='center' rows='auto'>
				<Loader />
			</Grid>
		);
	}

	if (submitUserRegistration.success || inviteValidation.accountExists) {
		return <Redirect to='/login' />;
	}

	const onSelectedCountryChange = event => {
		setSelectedCountry(countries.find(country => country.value === event.target.value));
	};

	const isBanyanUrl = () => window.location.href.includes('banyan');

	return (
		<Grid
			className={classNames('login', isBanyanUrl() ? 'banyan-background' : '')}
			width='100%'
			horizAlign='center'
			vertAlign='center'
			stretch='100vh'
			minContent>
			<Grid className='login__wrapper'>
				<div style={{ width: '300px' }}>
					{isBanyanUrl() && <img src='https://static.solaborate.com/temp/banyanlogo.svg' alt='banyan-logo' />}
					{!isBanyanUrl() && <img src={`${healthCareCdnUrl}hellocare-white-logo.svg`} alt='hello-health-logo' />}
				</div>
				<div className='login-form-wrapper complete-profile-form-wrapper registration-form'>
					{inviteValidation.error && (
						<div className='invalid-invite'>
							<i className='material-icons'>warning</i>
							<h3>{translate('invalidInvitation')}</h3>
							<p>{getInvitationMessage()}</p>
						</div>
					)}
					{!inviteValidation.error && !inviteValidation.loading && (
						<Formik
							initialValues={{ firstName: '', lastName: '', email: email, password: '', passwordConfirm: '', phoneNumber: '' }}
							onSubmit={values => submitForm(values)}
							validationSchema={validateForm}>
							<Form>
								<Field
									name='firstName'
									type='text'
									label={translate('firstName')}
									placeholder={intl.formatMessage({ id: 'firstName' })}
									variant='filled'
									component={Input}
								/>
								<Field
									name='lastName'
									type='text'
									label={translate('lastName')}
									placeholder={intl.formatMessage({ id: 'lastName' })}
									variant='filled'
									component={Input}
								/>
								<Field
									name='email'
									disabled
									type='email'
									label={translate('email')}
									placeholder={intl.formatMessage({ id: 'email' })}
									value={email}
									variant='filled'
									component={Input}
								/>
								<Field
									name='password'
									type='password'
									label={translate('password')}
									placeholder={intl.formatMessage({ id: 'password' })}
									variant='filled'
									component={Input}
								/>
								<Field
									name='passwordConfirm'
									type='password'
									label={translate('confirmPassword')}
									placeholder={intl.formatMessage({ id: 'confirmPassword' })}
									variant='filled'
									component={Input}
								/>
								{[InviteTypes.VISITOR.type, InviteTypes.FAMILY_MEMBER.type].includes(inviteType) && (
									<div className='input filled phone-number-wrapper'>
										<p className='label'>
											{`${intl.formatMessage({ id: 'phoneNumber' })} (${intl.formatMessage({ id: 'optional' })})`}
										</p>
										<div className='flex flex-1'>
											<select name='color' onChange={onSelectedCountryChange} className='auto-width'>
												{countries.map(item => (
													<option value={item.value}>{item.name}</option>
												))}
											</select>
											<input
												className='number-input-wo-arrows'
												name='phoneNumber'
												type='number'
												placeholder={intl.formatMessage({ id: 'phoneNumber' })}
												onChange={event => {
													if (event.target.value.length > 14) {
														return;
													}
													setPhoneNumber(event.target.value);
												}}
												value={phoneNumber}
											/>
										</div>
									</div>
								)}
								<br />
								{inviteType === InviteTypes.FAMILY_MEMBER.type && (
									<>
										<div className='flex no-border-top no-margin-top full-width font-14'>
											<label className='remember-me'>
												<input
													type='checkbox'
													onChange={() => setIsTermsChecked(prevState => !prevState)}
													id='agreePrivacyPolicy'
													name='agreePrivacyPolicy'
													checked={isTermsChecked}
												/>
												<span>{translate('completeProfileTerms')}</span>
												<Link className='agreement-link-onboarding' target='_blank' to='/terms-of-use'>
													<span className='blue-color semi-bold'>{healthSystemName}</span>
												</Link>
												<span> {translate('terms')}</span>
												<br />
											</label>
										</div>
										<br />
									</>
								)}
								<Button
									type='submit'
									isDisabled={inviteType === InviteTypes.FAMILY_MEMBER.type && !isTermsChecked}
									text={translate('save')}
									display='block'
									className='center-children'
								/>
							</Form>
						</Formik>
					)}
				</div>
				<br />
				<br />
			</Grid>
		</Grid>
	);
};

export default CompleteProfile;
