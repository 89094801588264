import React, { useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { getCpmList } from 'api/rpm.js';
import classNames from 'classnames';
import Alert from 'components/Alert.jsx';
import ProfilePicture from 'components/ProfilePicture.jsx';
import { Gender, MeasurementTypes, UnitCategoryTypes, WearableProviderIds, WearablesStatus } from 'constants/enums.js';
import {
	CpmTableHeaders,
	LifeSignalsMeasurements,
	NoMeasurementsValueText,
	getAlertClassName,
	vsmImageUrl,
} from 'constants/rpm.js';
import translate from 'i18n-translations/translate.jsx';
import { getUserId, getUserInfo, getUserRole } from 'infrastructure/auth.js';
import { getGender } from 'infrastructure/helpers/commonHelpers.js';
import { monthDayYear } from 'infrastructure/helpers/dateHelper.js';
import { useSelector } from 'react-redux';
import { getExternalDevices } from 'api/lifeSignals.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';
import { convertMeasurementTypes } from 'infrastructure/helpers/measurementsHelper.js';
import { getUserMeasurementUnits } from 'api/measurements.js';

const ContinuousPatientMonitoring = ({ searchedPatient }) => {
	const intl = useIntl();
	const [patients, setPatients] = useState([]);
	const [pageIndex, setPageIndex] = useState(0);
	const [error, setError] = useState(null);
	const [iframeSrc, setIframeSrc] = useState(null);
	const [lifeSignalsWearableId, setLifeSignalsWearableId] = useState(null);
	const [isInit, setIsInit] = useState(true);
	const [measurements, setMeasurements] = useState(null);
	const [preferredUnits, setPreferredUnits] = useState([]);
	const [lifeSignalsAlerts, setLifeSignalsAlerts] = useState([]);
	const hasReachedEnd = useRef(null);
	const isDarkMode = useSelector(state => state.user.darkMode);
	const socket = useContext(SocketContext);

	useEffect(() => {
		const getPatients = async () => {
			const queryParams = {
				role: getUserRole(),
				pageIndex,
				pageSize: 50,
				search: searchedPatient,
				gender: null,
				rpm: null,
			};
			const response = await getCpmList({ userId: getUserId(), queryParams });
			if (response.error) {
				setError(response.error.message);
				return;
			}
			if (pageIndex === 0) {
				setPatients(response.cpmListPatients);
			} else {
				setPatients(prevState => [...prevState, ...response.cpmListPatients]);
			}
			setPatients(response.cpmListPatients);
			hasReachedEnd.current = response.cpmListPatients.length < 50;
			if (isInit) {
				socket.emit(SocketEvents.LifeSignals.CPM_SUBSCRIBE, {
					patientIds: response.patientUserIds,
					nurseId: getUserInfo()?.userId,
				});
			}
			setIsInit(false);
		};

		getPatients();
	}, [pageIndex, searchedPatient]);

	useEffect(() => {
		const getLifeSignalVitalSign = (type, lifeSignals, isTemperature = false) => {
			const lastValidValue = lifeSignals.SensorData.reduce((acc, current) => {
				if (current[type] && current[type].length > 0 && current[type][current[type].length - 1] >= 0) {
					return current[type][current[type].length - 1];
				}
				return acc;
			}, null);

			return lastValidValue && isTemperature ? lastValidValue / 1000 : lastValidValue;
		};

		const measurementsListener = result => {
			let newMeasurements = { ...measurements };
			newMeasurements = {
				RR: getLifeSignalVitalSign('RR', result),
				SPO2: getLifeSignalVitalSign('SPO2', result),
				HR: getLifeSignalVitalSign('HR', result),
				BPSYS: getLifeSignalVitalSign('BPSYS', result),
				BPDIA: getLifeSignalVitalSign('BPDIA', result),
				BODYTEMP: getLifeSignalVitalSign('BODYTEMP', result, true),
			};
			if (result.StreamAlert?.length > 0) {
				setLifeSignalsAlerts(result.StreamAlert);
			}

			setMeasurements(newMeasurements);
		};
		socket.on(SocketEvents.LifeSignals.PATCH_DATA, measurementsListener);
		return () => {
			socket.off(SocketEvents.LifeSignals.PATCH_DATA, measurementsListener);
			socket.emit(SocketEvents.LifeSignals.CPM_UN_SUBSCRIBE, {
				nurseId: getUserInfo()?.userId,
			});
		};
	}, [socket, measurements]);

	useEffect(() => {
		const getMeasurementUnits = async () => {
			const { error, unitPreferences } = await getUserMeasurementUnits(getUserId());
			if (error) {
				setError(error.message);
			} else {
				setPreferredUnits(unitPreferences);
			}
		};
		getMeasurementUnits();
	}, []);

	const getUnitPreference = categoryId => {
		const selectedPreference = getCategoryPreference(categoryId);
		return selectedPreference?.options.find(item => item.unitSystemId === selectedPreference.unitSystemId);
	};

	const getCategoryPreference = categoryId => preferredUnits.find(item => item.unitCategoryId === categoryId);

	const getMeasurementValue = item => {
		if (item.type === MeasurementTypes.BLOOD_PRESSURE) {
			return measurements?.BPSYS && measurements?.BPDIA
				? `${measurements.BPSYS}/${measurements?.BPDIA}`
				: NoMeasurementsValueText;
		}
		if (item.type === MeasurementTypes.TEMPERATURE) {
			return measurements?.BODYTEMP
				? convertMeasurementTypes(
						UnitCategoryTypes.TEMPERATURE,
						measurements?.BODYTEMP,
						getUnitPreference(UnitCategoryTypes.TEMPERATURE)?.unitSystemId
				  )
				: NoMeasurementsValueText;
		}
		return measurements?.[item.value] || NoMeasurementsValueText;
	};

	const getMeasurementUnit = item => {
		if (getMeasurementValue(item) === NoMeasurementsValueText) {
			return '';
		}
		if (item.type === MeasurementTypes.TEMPERATURE) {
			return getUnitPreference(UnitCategoryTypes.TEMPERATURE)?.unit || item.unit;
		}
		return item.unit;
	};

	const handleScroll = event => {
		const isBottom = event.target.scrollHeight - Math.ceil(event.target.scrollTop) === event.target.clientHeight;
		if (isBottom && !hasReachedEnd.current && event.target.scrollTop !== 0) {
			setPageIndex(prevState => prevState + 1);
		}
	};

	const getExternalDevice = async patientId => {
		const response = await getExternalDevices({ patientId, status: WearablesStatus.ACTIVE });
		if (response.error) {
			setError(response.error.message);
			return;
		}
		if (!response.error && response?.patientExternalIotDevices?.length > 0) {
			const found = response.patientExternalIotDevices.find(
				wearable => wearable.externalIotDeviceType?.vendorId === WearableProviderIds.LIFE_SIGNALS
			);
			if (found) {
				setLifeSignalsWearableId(found.iotDeviceId);
			}
		}
	};

	return (
		<div
			className={classNames('patient-alerts-wrapper rpm rpm-wrapper', iframeSrc ? 'life-signals-open' : 'full-width')}
			onScroll={handleScroll}>
			<div className='patients-alert-table'>
				<div className='flex patients-alert-table-header'>
					<div className='flex patients-alert-table-header'>
						{CpmTableHeaders.map(item => (
							<div key={item}>
								<p>{translate(item)}</p>
							</div>
						))}
					</div>
				</div>
				<div className='patients-alert-table-body'>
					{patients.map(patient => (
						<div key={patient.userId} className='flex full-width'>
							<div className='flex'>
								<ProfilePicture
									className='doctor-request-img'
									fullName={`${patient.firstName} ${patient.lastName}`}
									profilePicture={patient.profilePicture}
								/>
								<div>
									<h4>
										{patient.firstName} {patient.lastName}
									</h4>
									<span>
										{patient.genderId === Gender.UNAVAILABLE
											? `${intl.formatMessage({ id: 'sex' })}: N/A`
											: getGender(patient.genderId).description}
									</span>
									<p>
										<span>DOB: {patient.dateOfBirth ? monthDayYear(patient.dateOfBirth) : 'N/A'}</span>
									</p>
									<p>
										<span className='mrn'>MRN: {patient.mrn || 'N/A'}</span>
									</p>
								</div>
							</div>
							{LifeSignalsMeasurements.map(item => {
								const foundAlert = lifeSignalsAlerts.find(alert => alert[0] === item.value);
								return (
									<div
										className={classNames(
											'patient-alert-body-vs position-relative',
											foundAlert ? getAlertClassName(foundAlert) : ''
										)}
										key={item.id}>
										<div className='flex'>
											<img src={`${vsmImageUrl}${item.img}.svg`} alt='icon' />
											<p>{getMeasurementValue(item)}</p>
											<span className='left-s'>{getMeasurementUnit(item)}</span>
										</div>
									</div>
								);
							})}
							<div className='flex position-relative'>
								<button
									type='button'
									className='life-signals-btn'
									onClick={async () => {
										await getExternalDevice(patient.userId);
										setIframeSrc(`https://us-trials-2.lifesignals.com/#/dashboard/dashboard-zoom-view/${lifeSignalsWearableId}`);
									}}>
									{translate('openLifeSignals')}
								</button>
							</div>
						</div>
					))}

					{patients.length === 0 && <div className='cpm-no-data'>{translate('noResultsFound')}</div>}
				</div>
			</div>

			{iframeSrc && (
				<aside className='right-side live-examination-kit iframe-view life-signals-view'>
					<button
						className={classNames('back-button', isDarkMode ? 'back-button-dark' : '')}
						type='button'
						onClick={() => setIframeSrc(null)}>
						<span className='material-icons'>arrow_back</span>
					</button>
					<iframe title='Bio beat' src={iframeSrc} />
				</aside>
			)}
			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
		</div>
	);
};

export default ContinuousPatientMonitoring;
