import React from 'react';
import Checkbox from 'components/Checkbox.jsx';
import Grid from 'components/Grid.jsx';
import SkeletonLoader from 'components/SkeletonLoader.jsx';
import translate from 'i18n-translations/translate.jsx';

const Table = props => (
	<section className={`table ${props.className ? props.className : ''}`}>
		<section className='table__slot'>{props.children}</section>
		<header data-cy='tableHeader'>
			{props.isEditable ? <Checkbox /> : ''}
			{props.headers.map(header => (
				<div key={header.id}>{header.title}</div>
			))}
		</header>
		<main data-cy='tableData'>
			{props.rows?.length > 0 &&
				props.rows.map(row => (
					<div className={row.className} data-test-id='table-row' key={row.id}>
						{props.isEditable ? <Checkbox /> : ''}
						{Object.keys(row).map(key => key !== 'id' && key !== 'className' && <div key={key}>{row[key]}</div>)}
					</div>
				))}
			{!!props.isLoading && (
				<Grid width='100%' horizAlign='center'>
					<SkeletonLoader rows={10} padding='35px 20px' />
				</Grid>
			)}
			{!props.isLoading && props.rows?.length === 0 && (
				<span data-test-id='table-empty'>{props.tableEmptyText || translate('noData')}</span>
			)}
		</main>
	</section>
);

export default Table;
