import { PatientNotes } from 'constants/action-types.js';
import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';

const companyId = getCompanyId();

export const getNote = async (roomId, type = PatientNotes.SITTER) => {
	try {
		const { data } = await gatewayApi.get(`/v1/organizations/${companyId}/room/${roomId}/notes?type=${type}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const createNoteOrUpdate = async (roomId, note, type = 1) => {
	try {
		const { data } = await gatewayApi.post(`/v1/organizations/${companyId}/room/${roomId}/notes`, { content: note, type });
		return data;
	} catch (error) {
		return { error };
	}
};

export const deleteNote = async (roomId, type = PatientNotes.SITTER) => {
	try {
		const { data } = await gatewayApi.delete(`/v1/organizations/${companyId}/room/${roomId}/notes?type=${type}`);
		return data;
	} catch (error) {
		return { error };
	}
};
