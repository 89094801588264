import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Formik } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import { createNote, deleteMedicalInfo } from 'api/medicalInfo.js';
import { MedicalInfoTypes } from 'constants/enums.js';
import Alert from 'components/Alert.jsx';
import translate from 'i18n-translations/translate.jsx';
import Loader from 'components/Loader.jsx';
import Grid from 'components/Grid.jsx';
import Textarea from 'components/Textarea.jsx';
import Modal from 'components/Modal.jsx';
import Button from 'components/Button.jsx';
import SpeechToText from 'SpeechToText';
import { useConferenceConfigurations } from 'calls/hooks/index.js';

const AddNotes = props => {
	const intl = useIntl();
	const [showAddNote, setShowAddNote] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState('');
	const [selectedNoteId, setSelectedNoteId] = useState(null);
	const conferenceConfigurations = useConferenceConfigurations();
	const [recognizedTranscription, setRecognizedTranscription] = useState('');
	const [liveTranscription, setLiveTranscription] = useState('');
	const [isSpeechToText, setIsSpeechToText] = useState(false);

	const addNote = async values => {
		const { title } = values;
		if (!canSubmit(values)) {
			return;
		}
		const params = {
			visitId: props.visitId,
			title,
			text: recognizedTranscription.trim(),
		};
		setIsLoading(true);
		const response = await createNote(params);
		if (response.error) {
			setError(response.error.message);
		} else {
			const newNote = {
				title,
				text: recognizedTranscription.trim(),
				id: response.id,
			};
			props.setMedicalInfo([...props.notes, newNote]);
		}
		setIsSpeechToText(false);
		setRecognizedTranscription('');
		setShowAddNote(false);
		setIsLoading(false);
	};

	const deleteNote = async id => {
		const params = {
			visitId: props.visitId,
			id,
			type: MedicalInfoTypes.NOTES,
		};
		const response = await deleteMedicalInfo(params);
		if (response.error) {
			setError(response.error.message);
		} else {
			const notesToSet = props.notes.filter(item => item.id !== id);
			props.setMedicalInfo(notesToSet);
			setSelectedNoteId(null);
		}
	};

	const canSubmit = values => {
		return values.title && recognizedTranscription;
	};

	return (
		<aside
			className={classNames(
				'right-side medical-info-aside add-notes-wrapper',
				conferenceConfigurations.isRightToLeft ? 'rtl-medical-info-aside' : '',
				conferenceConfigurations.isDarkMode ? 'dark-medical-info-aside' : ''
			)}>
			{!isLoading && (
				<>
					<h4>{translate('notes')}</h4>
					{showAddNote && (
						<Formik
							initialValues={{
								text: '',
								title: '',
							}}
							onSubmit={addNote}
							validateOnBlur={false}
							validateOnChange={false}
							validationSchema={Yup.object().shape({
								title: Yup.string()
									.required(
										intl.formatMessage({
											id: 'titleIsRequired',
										})
									)
									.trim(),
							})}>
							{formikProps => {
								const { values, errors, touched, handleChange, handleSubmit } = formikProps;
								return (
									<>
										<div className='mi-input-wrapper'>
											<label>{translate('title')}</label>
											<input
												type='text'
												placeholder={intl.formatMessage({ id: 'title' })}
												onChange={handleChange}
												value={values.title}
												maxLength={70}
												name='title'
											/>
											{errors.title && touched.title && <span className='red-error create-app-error'>{errors.title}</span>}
											<Textarea
												disabled={isSpeechToText}
												maxLength={400}
												onChange={event => setRecognizedTranscription(event.target.value)}
												value={
													isSpeechToText ? `${recognizedTranscription} ${liveTranscription}`.trim() : recognizedTranscription
												}
												maxNumberLimit={40}
												rows={10}
												name='text'
												placeholder={intl.formatMessage({ id: 'message' })}
												icon={
													<SpeechToText
														setRecognizedTranscription={setRecognizedTranscription}
														setLiveTranscription={setLiveTranscription}
														setIsSpeechToText={setIsSpeechToText}
														isSpeechToText={isSpeechToText}
													/>
												}
											/>
										</div>
										<Button onClick={handleSubmit} text={translate('done')} isDisabled={!canSubmit(values)} />
									</>
								);
							}}
						</Formik>
					)}
					{!showAddNote && props.notes.length > 0 && (
						<button
							className='button small'
							type='button'
							onClick={() => setShowAddNote(true)}
							style={{ marginTop: 'var(--spacing-xl)' }}>
							{translate('addNote')}
						</button>
					)}
					<div className='mi-selected-list'>
						{props.notes.length > 0 && <p>{translate('addedNotes')}</p>}
						{props.notes.map(item => (
							<div key={item.id} className='position-relative cursor-pointer medical-icons'>
								<i className='material-icons' onClick={() => setSelectedNoteId(item.id)}>
									close
								</i>
								<p>{item.title}</p>
								<p>{item.text}</p>
							</div>
						))}
						{props.notes.length === 0 && !showAddNote && (
							<div className='d-p-no-items'>
								<p>
									{translate('noAdded', {
										value: intl.formatMessage({ id: MedicalInfoTypes.NOTES }).toLowerCase(),
									})}
								</p>
							</div>
						)}
					</div>
					{!showAddNote && props.notes.length === 0 && (
						<button className='button small block' type='button' onClick={() => setShowAddNote(true)}>
							{translate('addNote')}
						</button>
					)}
				</>
			)}
			{isLoading && (
				<Grid columns='1fr' rows='1fr' stretch='calc(100vh - 200px)' horizAlign='center' vertAlign='center'>
					<div style={{ textAlign: 'center' }}>
						<Loader />
					</div>
				</Grid>
			)}
			<Modal
				display={selectedNoteId}
				onModalSubmit={() => deleteNote(selectedNoteId)}
				onModalClose={() => setSelectedNoteId(null)}
				position='center'
				primaryButtonLabel={translate('delete')}>
				<form>
					<h3>{translate('delete')}</h3>
					<p>
						{translate('areYouSureDeleteItem', {
							value: intl.formatMessage({ id: 'note' }),
						})}
					</p>
				</form>
			</Modal>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</aside>
	);
};

export default AddNotes;
