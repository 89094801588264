import React from 'react';
import styled from 'styled-components';
import LightTheme from 'calls/styles/LightTheme.js';

import { useParticipantVolume } from 'calls/hooks/index.js';

const StyledVolume = styled.span`
	display: flex;
	align-items: center;
	margin-right: ${LightTheme.spacing[2]}px;
	height: 10px;
	> span {
		transition: 0.3s;
		width: 3px;
		height: 0;
		background: ${LightTheme.colors.blueOne};
		margin-right: 2px;
		border-radius: 5px;

		&:last-of-type {
			margin: 0;
		}
	}
`;

/**
 * @param {object} props
 * @param {MediaStreamTrack} props.track Audio media stream track
 */
const ParticipantAudioLevel = ({ track }) => {
	const volume = useParticipantVolume(track);

	return (
		<StyledVolume>
			{[
				{ id: 1, level: 3 },
				{ id: 2, level: 5 },
				{ id: 3, level: 3 },
			].map(({ id, level }) => (
				<span key={id} style={{ height: `${volume ? volume * level : 2}px` }} />
			))}
		</StyledVolume>
	);
};

export default ParticipantAudioLevel;
