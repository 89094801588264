import React, { useContext, useEffect, useRef, useState } from 'react';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';
import { useIntl } from 'react-intl';
import EmptyState from 'components/EmptyState.jsx';
import translate from 'i18n-translations/translate.jsx';
import { getAiAlertActivities, getManualAlertActivities } from 'api/alerts.js';
import { Grid, Loader } from 'components/index.js';
import AlertHistoryModal from 'components/AlertHistoryModal.jsx';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';
import { getInterventions } from 'api/interventions.js';

const ManualAlertsList = ({ isDarkMode, patientId, deviceId }) => {
	const intl = useIntl();
	const [error, setError] = useState([]);
	const [details, setDetails] = useState([]);
	const [interventions, setInterventions] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0, totalCount: 0 });
	const socket = useContext(SocketContext);
	const hasReachedEnd = useRef(null);

	useEffect(() => {
		const handleResponse = async intervention => {
			const { creationDate, alertTypeName, manualAlertTypeId, alertId, isDraft } = intervention;
			if (isDraft) {
				return;
			}
			setInterventions(prevState => [
				{ dateCreated: creationDate, mainAlertType: 3, typeId: manualAlertTypeId, typeName: alertTypeName, id: alertId },
				...prevState,
			]);
		};
		const handleInterventionUpdate = async intervention => {
			const { dateCreated, mainAlertType, typeId, typeName, interventionId, isDraft } = intervention;
			if (isDraft) {
				return;
			}
			setInterventions(prevState => [{ dateCreated, mainAlertType, typeId, typeName, id: interventionId }, ...prevState]);
		};
		socket.on(SocketEvents.Alerts.ALERTS_INTERVENTION_UPDATED, handleInterventionUpdate);
		socket.on(SocketEvents.HealthCare.ALERTS_MANUAL_ADDED, handleResponse);
		return () => {
			socket.off(SocketEvents.HealthCare.ALERTS_INTERVENTION_UPDATED, handleInterventionUpdate);
			socket.off(SocketEvents.HealthCare.ALERTS_MANUAL_ADDED, handleResponse);
		};
	}, [patientId]);

	useEffect(() => {
		const fetchIntervetions = async () => {
			const params = {
				patientId,
				deviceId,
				isDraft: false,
				pageIndex: pagination.pageIndex,
				pageSize: pagination.pageSize,
			};
			const response = await getInterventions(params);
			if (response.error) {
				return;
			}
			if (pagination.pageIndex === 0) {
				setInterventions(response.devicePatientInterventions || []);
			} else {
				setInterventions(prevState => [...prevState, ...response.devicePatientInterventions || []]);
			}
			setIsLoading(false);
			setPagination(prevState => ({ ...prevState, totalCount: response.totalCount || 0 }));
			hasReachedEnd.current = response?.devicePatientInterventions?.length < 10;
		};
		fetchIntervetions();
	}, [deviceId, patientId, pagination.pageIndex, pagination.pageSize]);

	const getDetails = async item => {
		if (item.mainAlertType === 3) {
			const response = await getManualAlertActivities(item.id);
			if (response.error) {
				setError(response.error.message);
				return;
			}
			setDetails(response.manualAlertActivities);
		}
		if (item.mainAlertType === 2) {
			const response = await getAiAlertActivities(item.id);
			if (response.error) {
				setError(response.error.message);
				return;
			}
			setDetails(response.aiAlertActivities);
		}
	};

	const handleScroll = event => {
		const isBottom = event.target.scrollHeight - Math.ceil(event.target.scrollTop) === event.target.clientHeight;
		if (isBottom && !hasReachedEnd.current) {
			setPagination(prevState => ({ ...prevState, pageIndex: prevState.pageIndex + 1 }));
		}
	};

	return (
		<div className='box-max-height' onScroll={handleScroll}>
			{isLoading && (
				<Grid columns='1fr' rows='1fr' stretch='250px' horizAlign='center' vertAlign='center'>
					<div style={{ textAlign: 'center' }}>
						<Loader />
					</div>
				</Grid>
			)}
			{!isLoading &&
				details.length === 0 &&
				interventions.length > 0 &&
				interventions.map(item => (
					<div key={item.id} className='interventions-history cursor-pointer' onClick={() => getDetails(item)}>
						<div className='flex flex-space-between flex-align-center'>
							<div>
								<h3>{intl.formatMessage({ id: item.typeName })}</h3>
								<p>{formattedDate(item.dateCreated)}</p>
							</div>
							<div className='flex flex-space-between flex-align-center draft-item-actions'>
								<button>
									<i className='material-icons-outlined'>east</i>
								</button>
							</div>
						</div>
					</div>
				))}
			{details.length > 0 && (
				<>
					<button type='button' onClick={() => setDetails([])}>
						<i className='material-icons-outlined alert-icon'>west</i>
					</button>

					<AlertHistoryModal alertDetails={details} isFromMonitoring={true} isMeasurementAlert={true} />
				</>
			)}
			{!interventions.length && !isLoading && (
				<div className='flex flex-align-center flex-justify-center empty-state-wrapper'>
					<EmptyState title={translate('noResultsFound')} image='alerts.svg?v2' />
				</div>
			)}
			<div className='flex alert-subtabs' />
		</div>
	);
};

export default ManualAlertsList;
