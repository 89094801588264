import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import translate from 'i18n-translations/translate.jsx';
import { changeUserEmail } from 'api/users.js';
import Button from 'components/Button.jsx';
import PopUpAlert from 'components/PopUpAlert.jsx';
import { AlertTypes } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';

const ChangeEmail = () => {
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);
	const [showPassword, setShowPassword] = useState(false);
	const [isRequestProcessing, setIsRequestProcessing] = useState(false);
	const intl = useIntl();
	const history = useHistory();

	const handleSubmit = async ({ currentPassword, currentEmail, newEmail }, { resetForm }) => {
		setIsRequestProcessing(true);
		const changeEmail = await changeUserEmail({ currentPassword, currentEmail, newEmail });
		if (changeEmail.error) {
			setError(changeEmail.error.ErrorMessage);
			setIsRequestProcessing(false);
		} else {
			setError(null);
			setSuccess(changeEmail.HasSucceed);
			setIsRequestProcessing(false);
			setTimeout(() => {
				closeResponseAlert();
				history.push('/logout');
			}, 1500);
		}
		resetForm();
	};

	const closeResponseAlert = () => {
		setError(null);
		setSuccess(null);
	};

	return (
		<div className='account-settings-inner-wrapper account-settings-tabs doctor-profile-settings patient-profile'>
			<Formik
				initialValues={{ currentPassword: '', currentEmail: '', newEmail: '' }}
				onSubmit={handleSubmit}
				validationSchema={Yup.object().shape({
					currentEmail: Yup.string().required(intl.formatMessage({ id: 'pleaseWriteEmail' })),
					currentPassword: Yup.string().required(intl.formatMessage({ id: 'pleaseWritePassword' })),
					newEmail: Yup.string()
						.required(intl.formatMessage({ id: 'pleaseWriteEmail' }))
						.notOneOf([Yup.ref('currentEmail')], intl.formatMessage({ id: 'newEmailDifferent' })),
				})}>
				{({ values, errors, handleChange, handleBlur }) => (
					<Form>
						<div className='flex personal-details-wrapper'>
							<div className='flex column-direction personal-details'>
								<label className='position-relative'>
									{intl.formatMessage({ id: 'currentEmail' })}
									<input
										type='text'
										placeholder={intl.formatMessage({ id: 'currentEmail' })}
										value={values.currentEmail}
										onChange={handleChange}
										onBlur={handleBlur}
										maxLength={50}
										name='currentEmail'
										className='margin-top-s'
										autoComplete='off'
									/>
									<span className='red-error'>{errors.currentEmail}</span>
								</label>
							</div>
						</div>
						<div className='flex personal-details-wrapper'>
							<div className='flex column-direction personal-details'>
								<label className='position-relative'>
									{intl.formatMessage({ id: 'currentPassword' })}
									<input
										type={showPassword ? 'text' : 'password'}
										placeholder={intl.formatMessage({ id: 'currentPassword' })}
										value={values.currentPassword}
										onChange={handleChange}
										onBlur={handleBlur}
										maxLength={30}
										name='currentPassword'
										className='margin-top-s'
										autoComplete='off'
									/>
									<div onClick={() => setShowPassword(!showPassword)}>
										<img
											src={`${healthCareCdnUrl}icon/show-password.svg`}
											alt='show-password-ico'
											className='show-password-icon'
										/>
									</div>
									<span className='red-error position-absolute'>{errors.currentPassword}</span>
								</label>
							</div>
							<div className='flex column-direction personal-details'>
								<label className='position-relative'>
									{intl.formatMessage({ id: 'newEmail' })}
									<input
										type='text'
										placeholder={intl.formatMessage({ id: 'newEmail' })}
										value={values.newEmail}
										onChange={handleChange}
										onBlur={handleBlur}
										maxLength={50}
										name='newEmail'
										className='margin-top-s'
										autoComplete='off'
									/>
									<span className='red-error'>{errors.newEmail}</span>
								</label>
							</div>
						</div>
						<div className='full-width flex right-align-content top-30'>
							<Button
								className=''
								type='submit'
								isLoading={isRequestProcessing}
								text={!isRequestProcessing ? translate('saveChanges') : ''}
								imgIcon={`${healthCareCdnUrl}appointments/save-icon.svg`}
								background='rgba(51, 194, 127, 1)'
							/>
						</div>
					</Form>
				)}
			</Formik>
			<PopUpAlert
				alertType={success ? AlertTypes.SUCCESS : AlertTypes.DANGER}
				display={error || success}
				onAlertClose={closeResponseAlert}
				contentText={success ? intl.formatMessage({ id: 'emailChangedSuccessfully' }) : error}
				isSilent={true}
				center={true}
			/>
		</div>
	);
};

export default ChangeEmail;
