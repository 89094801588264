import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { getHealthSystemHospitals } from 'api/userIdleConfigurations.js';
import { getDoctorAssigned } from 'api/doctors.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import PatientsTransferView from 'containers/QueueManagement/PatientsTransferView.jsx';
import { PresenceStatusType, SortByNameValues } from 'constants/enums.js';
import { Alert, Grid, Input, SkeletonLoader } from 'components';
import ProfilePicture from 'components/ProfilePicture.jsx';
import translate from 'i18n-translations/translate.jsx';
import PatientRisk from 'views/QueueManagement/PatientRisk.jsx';
import _ from 'lodash';

const QueueManagement = () => {
	const translator = id => intl.formatMessage({ id });
	const [error, setError] = useState(null);
	const userSession = useSelector(state => state.user.userSession);
	const [doctorsList, setDoctorsList] = useState([]);
	const [assignedCallRequests, setAssignedCallRequests] = useState({
		totalPendingRequests: 0,
		totalRapidRequests: 0,
		totalAdmissionRequests: 0,
		totalRegularRequests: 0,
	});
	const [selectedDoctor, setSelectedDoctor] = useState(null);
	const [pageIndex, setPageIndex] = useState(0);
	const [isQueuedPatientsModalOpen, setPatientsTransferViewModalOpen] = useState(null);
	const hasReachedEnd = useRef(null);
	const intl = useIntl();
	const [hospitals, setHospitals] = useState([]);
	const [isDoctorsLoading, setIsDoctorsLoading] = useState(true);
	const [isMoreDoctorsLoading, setIsMoreDoctorsLoading] = useState(false);
	const [searchQuery, setSearchQuery] = useState('');
	const [sortDoctorsByName, setSortDoctorsByName] = useState(SortByNameValues.SORTING_AZ);
	const [sortDoctorsByQueue, setSortDoctorsByQueue] = useState(SortByNameValues.SORTING_ZA);
	const [isPatientTransferSuccessful, setIsPatientTransferSuccessful] = useState(false);
	const [selectedHospital, setSelectedHospital] = useState({
		value: userSession.healthSystem.id,
		label: translator('all'),
	});

	const onSearchTypeTimeout = useRef(null);

	useEffect(() => {
		return () => {
			if (onSearchTypeTimeout.current) {
				clearTimeout(onSearchTypeTimeout.current);
			}
		};
	}, []);

	const DropdownIndicator = () => <i className='material-icons-outlined'>arrow_drop_down</i>;

	const transformHealthSystemsForSelect = healthSystems =>
		healthSystems.map(healthSystem => ({ label: healthSystem.name, value: healthSystem.id }));

	const tableItems = [
		{ id: 0, name: 'healthSystem' },
		{ id: 1, name: 'location' },
		{ id: 2, name: 'availability' },
		{ id: 3, name: 'status' },
		{ id: 4, name: 'patientQueue' },
		{ id: 5, name: 'manageQueue' },
	];

	const handleScroll = event => {
		const isBottom = event.target.scrollHeight - Math.ceil(event.target.scrollTop) === event.target.clientHeight;
		if (isBottom && !hasReachedEnd.current) {
			setPageIndex(prevState => prevState + 1);
			setIsMoreDoctorsLoading(true);
		}
	};

	useEffect(() => {
		const getHospitals = async () => {
			const response = await getHealthSystemHospitals(userSession.healthSystem.id);
			if (response.error) {
				setError(response.error.message);
				return;
			}
			const transformedHospitals = transformHealthSystemsForSelect(response);
			transformedHospitals.unshift({ value: userSession.healthSystem.id, label: translator('all') });
			setHospitals(transformedHospitals);
		};
		getHospitals();
	}, []);

	useEffect(() => {
		const fetchDoctors = async () => {
			if (onSearchTypeTimeout.current) {
				clearTimeout(onSearchTypeTimeout.current);
			}
			onSearchTypeTimeout.current = setTimeout(async () => {
				const response = await getDoctorAssigned(selectedHospital.value, {
					pageIndex,
					search: searchQuery,
					presenceStatus: PresenceStatusType.AVAILABLE,
				});
				if (response.error) {
					setError(response.error.message);
					setIsDoctorsLoading(false);
					setIsMoreDoctorsLoading(false);
					return;
				}
				hasReachedEnd.current = response.doctors.length < 20;
				setDoctorsList(prevState =>
					getSortedDoctorsByQueue(pageIndex === 0 ? response.doctors : [...prevState, ...response.doctors])
				);
				setIsDoctorsLoading(false);
				setIsMoreDoctorsLoading(false);
				setAssignedCallRequests(response);
			}, 500);
		};
		fetchDoctors();
	}, [selectedHospital.value, pageIndex, searchQuery]);

	const getSortedDoctorsByName = doctors => {
		if (sortDoctorsByName === SortByNameValues.SORTING_ZA) {
			doctors.sort((a, b) => (a.firstName?.toUpperCase() > b.firstName?.toUpperCase() ? -1 : 1));
		} else {
			doctors.sort((a, b) => (a.firstName?.toUpperCase() < b.firstName?.toUpperCase() ? -1 : 1));
		}
		return doctors;
	};

	const getSortedDoctorsByQueue = doctors => {
		if (sortDoctorsByQueue === SortByNameValues.SORTING_ZA) {
			doctors.sort((a, b) => (a.pendingRequests.length > b.pendingRequests.length ? -1 : 1));
		} else {
			doctors.sort((a, b) => (a.pendingRequests.length < b.pendingRequests.length ? -1 : 1));
		}
		return doctors;
	};

	useEffect(() => {
		setDoctorsList(getSortedDoctorsByName(_.cloneDeep(doctorsList)));
	}, [sortDoctorsByName]);

	useEffect(() => {
		setDoctorsList(getSortedDoctorsByQueue(_.cloneDeep(doctorsList)));
	}, [sortDoctorsByQueue]);

	const handleSearchChange = value => {
		setSearchQuery(value);
		setPageIndex(0);
	};

	const handleFilterChange = hospital => {
		setSelectedHospital(hospital);
		setPageIndex(0);
	};

	const getCellText = (doctor, item) => {
		let text = doctor[item.name];
		switch (item.name) {
			case 'healthSystem':
				text = userSession.healthSystem.name;
				break;
			case 'status':
				text = translate(doctor.presenceStatusTypeId === PresenceStatusType.AVAILABLE ? 'online' : 'offline');
				break;
			case 'patientQueue':
				text = doctor.pendingRequests.length;
				break;
			default:
				break;
		}
		return text;
	};

	return (
		<>
			{!isPatientTransferSuccessful && (
				<>
					<div className='queue-wrapper' onScroll={handleScroll}>
						<PatientRisk assignedCallRequests={assignedCallRequests} />
						<div className='flex patients-alert-filter'>
							<Select
								classNamePrefix='react-select'
								options={hospitals}
								components={{ DropdownIndicator }}
								onChange={handleFilterChange}
								value={selectedHospital}
							/>
							<Input
								validationOptions={{}}
								type='text'
								placeholder={intl.formatMessage({ id: 'search' })}
								bottomSpace='0'
								variant='filled flex-1'
								name='searchUsers'
								maxLength={100}
								value={searchQuery}
								onChange={event => handleSearchChange(event.target.value)}
							/>
						</div>
						<div className='patients-alert-table'>
							<div className='flex patients-alert-table-header'>
								<div className='flex'>
									<p>{translate('doctor')}</p>
									<i
										className={`material-icons${sortDoctorsByName === SortByNameValues.SORTING_ZA ? ' rotate' : ''}`}
										onClick={() =>
											setSortDoctorsByName(
												sortDoctorsByName === SortByNameValues.SORTING_ZA
													? SortByNameValues.SORTING_AZ
													: SortByNameValues.SORTING_ZA
											)
										}>
										arrow_downward
									</i>
									<span>{translate(sortDoctorsByName === SortByNameValues.SORTING_AZ ? 'ascAlphaOrder' : 'descAlphaOrder')}</span>
								</div>
								{tableItems.map(item => (
									<div className='flex' key={item.id}>
										<p className='queue-header-table'>{translate(item.name)}</p>
										{item.name === 'patientQueue' && (
											<>
												<i
													className={`material-icons${sortDoctorsByQueue === SortByNameValues.SORTING_ZA ? ' rotate' : ''}`}
													onClick={() =>
														setSortDoctorsByQueue(
															sortDoctorsByQueue === SortByNameValues.SORTING_ZA
																? SortByNameValues.SORTING_AZ
																: SortByNameValues.SORTING_ZA
														)
													}>
													arrow_downward
												</i>
												<span>
													{translate(sortDoctorsByQueue === SortByNameValues.SORTING_AZ ? 'ascNumOrder' : 'descNumOrder')}
												</span>
											</>
										)}
									</div>
								))}
							</div>
							<div className='patients-alert-table-body'>
								{doctorsList.length === 0 && !isDoctorsLoading && (
									<div style={{ textAlign: 'center' }}>
										<p className='no-data'>{translate('noData')}</p>
									</div>
								)}
								{doctorsList.map(doctor => (
									<div key={doctor.id} className='flex full-width'>
										<div className='flex'>
											<ProfilePicture
												className='doctor-request-img'
												fullName={`${doctor.firstName} ${doctor.lastName}`}
												profilePicture={doctor?.profilePicture}
											/>
											<div>
												<h4>{`${doctor.firstName} ${doctor.lastName}`}</h4>
												<p>
													<span>{doctor?.specialty?.name}</span>
												</p>
											</div>
										</div>
										{tableItems.slice(0, -1).map(item => (
											<div key={item.id} className='patient-alert-body-vs position-relative'>
												<div className='flex'>
													<p className='queue-body-table'>{getCellText(doctor, item)}</p>
												</div>
											</div>
										))}

										<div className='flex position-relative'>
											<div className='patient-info-call'>
												<button
													className='view-queue-patients-btn'
													type='button'
													onClick={() => {
														setSelectedDoctor(doctor);
														setPatientsTransferViewModalOpen(true);
													}}>
													<i className='material-icons'>list</i>
													{translate('viewQueue')}
												</button>
											</div>
										</div>
									</div>
								))}
								{isDoctorsLoading && (
									<Grid width='100%' horizAlign='center'>
										<SkeletonLoader rows={10} padding='35px 20px' />
									</Grid>
								)}
								{isMoreDoctorsLoading && (
									<Grid width='100%' horizAlign='center'>
										<SkeletonLoader rows={3} padding='35px 20px' />
									</Grid>
								)}
							</div>
						</div>
					</div>
					{selectedDoctor && (
						<PatientsTransferView
							selectedDoctor={selectedDoctor}
							display={isQueuedPatientsModalOpen}
							setPatientsTransferViewModalOpen={setPatientsTransferViewModalOpen}
							selectedHospitalId={selectedHospital.value}
							onPatientTransferComplete={() => setIsPatientTransferSuccessful(true)}
						/>
					)}
				</>
			)}
			{isPatientTransferSuccessful && (
				<div className='successful-request-wrapper' data-cy='successfulRequestModal'>
					<div className='successful-request'>
						<img src={`${healthCareCdnUrl}doctor-request/request-success.svg`} alt='icon' />
						<h3>{translate('successfullyTransferPatient')}</h3>
						<p>{translate('requestSuccessfullyTransferPatient')}</p>
						<button type='button' onClick={() => setIsPatientTransferSuccessful(false)}>
							{translate('done')}
						</button>
					</div>
				</div>
			)}
			<Alert display={error} message={error} variant='dark' fixed={true} onClose={() => setError(null)} />
		</>
	);
};

export default QueueManagement;
