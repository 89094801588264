import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import Modal from 'components/Modal.jsx';
import Form from 'components/Form.jsx';
import { getUserId } from 'infrastructure/auth.js';
import { getAppointments, getDoctorAvailability, rescheduleAppointment } from 'api/appointments.js';
import translate from 'i18n-translations/translate.jsx';
import { getPreferredLanguageLocale } from 'infrastructure/helpers/commonHelpers.js';
import Loader from 'components/Loader.jsx';
import Alert from 'components/Alert.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';

const RescheduleAppointment = props => {
	const [selectedAppointment, setSelectedAppointment] = useState(null);
	const [availableAppointments, setAvailableAppointments] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const intl = useIntl();

	useEffect(() => {
		const userId = getUserId();
		const getAppointmentDetails = async () => {
			const params = {
				userId: props.userId,
				startDate: moment().format('YYYY-MM-DD'),
				endDate: moment().format('YYYY-MM-DD'),
			};
			const [appointmentList, doctorAvailability] = await Promise.all([
				getAppointments(
					userId,
					moment().format('YYYY-MM-DD'),
					moment()
						.add(1, 'years')
						.format('YYYY-MM-DD')
				),
				getDoctorAvailability(params),
			]);
			const responseError = appointmentList.error || doctorAvailability.error;

			if (responseError) {
				setError(responseError.message);
			} else {
				const appointmentSelected = appointmentList.appointments.find(x => x.id === props.appointmentId);
				setSelectedAppointment(appointmentSelected || null);
				setAvailableAppointments(doctorAvailability.availableAppointments);
			}
			setIsLoading(false);
		};
		getAppointmentDetails();
	}, [props.appointmentId, props.userId]);

	const onAppointmentDateChange = async (event, setFieldValue) => {
		const { value } = event.target;
		setFieldValue('appointmentDate', value);
		const params = {
			userId: props.userId,
			startDate: moment(value).format('YYYY-MM-DD'),
			endDate: moment(value).format('YYYY-MM-DD'),
		};
		const response = await getDoctorAvailability(params);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		setAvailableAppointments(response.availableAppointments);
	};

	const rescheduleAppointmentDetails = async (values, { setSubmitting, resetForm }) => {
		setSubmitting(true);

		const appointment = {
			date: moment(values.appointmentDate).format('YYYY-MM-DD'),
			appointmentSlotId: values.appointmentSlotId,
		};

		const response = await rescheduleAppointment(selectedAppointment.id, appointment);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		resetForm();
		setSubmitting(false);
		props.toggleRescheduleModal();
		props.onSave();
	};

	return (
		<>
			<Formik
				initialValues={{
					appointmentDate: moment().format('YYYY-MM-DD'),
				}}
				onSubmit={rescheduleAppointmentDetails}
				validationSchema={Yup.object().shape({
					appointmentDate: Yup.string().required(intl.formatMessage({ id: 'appointmentDateRequired' })),
					appointmentSlotId: Yup.string().required(intl.formatMessage({ id: 'timeSlotRequired' })),
				})}>
				{formikProps => {
					const { values, handleChange, handleBlur, isSubmitting, handleSubmit, setFieldValue, errors } = formikProps;
					return (
						<Modal
							display={props.isRescheduleModalVisible}
							primaryButtonLabel={translate('saveChanges')}
							position='center'
							isLoading={false}
							onModalClose={() => props.toggleRescheduleModal()}
							shouldSubmitOnEnter={false}
							className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal set-up-availability-modal'
							onModalSubmit={handleSubmit}
							primaryButtonLoading={isSubmitting}
							submitImgIcon={`${healthCareCdnUrl}appointments/done.svg`}
							closeImgIcon={`${healthCareCdnUrl}appointments/cancel-appointment.svg`}>
							{isLoading && (
								<div className='patient-request-loader-sm'>
									<Loader />
								</div>
							)}
							{!isLoading && (
								<Form className='modal-form-wrapper'>
									<h3>
										{translate('chooseAvailableTime')}
										<span>{props.participantName}</span>
									</h3>
									<p>
										{translate('reSchedule')} {translate('appointment')}
									</p>

									<div className='create-appointment-modal'>
										<div>
											<div>
												<Field
													type='date'
													name='appointmentDate'
													value={values.appointmentDate}
													onKeyDown={event => event.preventDefault()}
													onChange={event => onAppointmentDateChange(event, setFieldValue)}
													min={moment().format('YYYY-MM-DD')}
												/>
											</div>
										</div>
										<div>
											<div className='flex available-appointments calendar-events-app-modal'>
												{availableAppointments.length === 0 && <p>{translate('noAppointmentsAvailableSelectDifferentDate')}</p>}
												{availableAppointments.map(appointmentSlot => (
													<div key={appointmentSlot.id} className='appointments-list'>
														<label>
															<Field
																type='radio'
																onChange={handleChange}
																onBlur={handleBlur}
																value={appointmentSlot.id}
																name='appointmentSlotId'
															/>
															<div className='active-av-app' />
															<span>
																{`${moment
																	.utc(appointmentSlot.startDateTime)
																	.local()
																	.locale(getPreferredLanguageLocale())
																	.format('hh:mm A')} -
																  ${moment
																		.utc(appointmentSlot.endDateTime)
																		.local()
																		.locale(getPreferredLanguageLocale())
																		.format('hh:mm A')}`}
															</span>
														</label>
													</div>
												))}
											</div>
											<div className='flex column-direction left-align-items'>
												{errors.appointmentSlotId && <span className='red-error'>{errors.appointmentSlotId}</span>}
												{errors.appointmentDate && <span className='red-error'>{errors.appointmentDate}</span>}
											</div>
										</div>
									</div>
								</Form>
							)}
						</Modal>
					);
				}}
			</Formik>

			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};

export default RescheduleAppointment;
