import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import { Modal, Form, Loader } from 'components';
import DeviceSelection from 'containers/DeviceSelection';
import { getLevelDevices } from 'infrastructure/helpers/commonHelpers.js';
import { addDeviceSettings } from 'api/devices.js';
import { SectorTypes } from 'constants/enums.js';

const VoiceCommandCallingModal = props => {
	const [changedDevices, setChangedDevices] = useState([]);
	const [isLoading, setLoading] = useState(false);

	const intl = useIntl();

	const translator = id => intl.formatMessage({ id });

	const handleCheckBoxChange = (option, e) => {
		const isChecked = !!e.target.checked;
		const newDevices = _.cloneDeep(changedDevices);

		if (option.type === SectorTypes.ROOM) {
			const exists = newDevices.find(x => x.solHelloDeviceId === option.helloDeviceId);
			if (!exists) {
				newDevices.push({ solHelloDeviceId: option.helloDeviceId, settingValue: isChecked });
			} else {
				exists.settingValue = isChecked;
			}

			setChangedDevices(newDevices);
			props.updateVoiceCommandCallingList([option.helloDeviceId], isChecked);
		} else {
			const sectorDevices = [];
			const rooms = getLevelDevices(option, true);
			rooms.forEach(room => {
				if (room.voiceCommandCalling !== isChecked) {
					const exists = newDevices.find(x => x.solHelloDeviceId === room.helloDeviceId);
					sectorDevices.push({ solHelloDeviceId: room.helloDeviceId, settingValue: isChecked })
					if (!exists) {
						newDevices.push({ solHelloDeviceId: room.helloDeviceId, settingValue: isChecked });
					} else {
						exists.settingValue = isChecked;
					}
				}
			});

			setChangedDevices(newDevices);
			props.updateVoiceCommandCallingList(
				sectorDevices.map(x => x.solHelloDeviceId),
				isChecked
			);
		}
	};

	const changeVoiceCommandCalling = async () => {
		if (changedDevices.length) {
			setLoading(true);
			const settings = changedDevices.map(s => ({ solHelloDeviceId: +s.solHelloDeviceId, settingValue: `${s.settingValue}` }));
			const response = await addDeviceSettings(settings);
			if (response.error) {
				return;
			}
		}

		setLoading(false);

		props.toggleVoiceCommandCallingModal();
	};

	const toggleModal = () => {
		setChangedDevices([]);
		props.toggleVoiceCommandCallingModal();
	};

	return (
		<Modal
			modalSelector='voiceCommandCallingModal'
			display={props.isVoiceCommandCallingModalOpen}
			position='right'
			onModalSubmit={changeVoiceCommandCalling}
			isSubmitDisabled={changedDevices.length === 0}
			onModalClose={toggleModal}>
			<Form title={translator('voiceCommandCalling')} onSubmit={event => event.preventDefault()}>
				<p>{translator('optonToActivateVCC')}</p>
				{isLoading && <Loader />}
				{!isLoading && (
					<div className='input'>
						<p className='label'>{translator('devices')}</p>
						<p>{translator('selectDeviceForFeature')}</p>
						<DeviceSelection data={props.currentSector} handleCheckBoxChange={handleCheckBoxChange} />
					</div>
				)}
			</Form>
		</Modal>
	);
};

export default VoiceCommandCallingModal;
