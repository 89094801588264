import { getPatientSession } from 'api/dashboard.js';
import Alert from 'components/Alert.jsx';
import Pagination from 'components/Common/Pagination.jsx';
import SessionByTime from 'components/DashboardCharts/PatientCentric//SessionByTime.jsx';
import HeaderInfo from 'components/DashboardCharts/PatientCentric/HeaderInfo.jsx';
import Grid from 'components/Grid.jsx';
import ProfilePicture from 'components/ProfilePicture.jsx';
import Table from 'components/Table.jsx';
import translate from 'i18n-translations/translate.jsx';
import { getCallType } from 'infrastructure/helpers/callLogsHelper.js';
import { convertSecondsToHoursFormat } from 'infrastructure/helpers/commonHelpers.js';
import { convertToTimestampSecondsWithMaxTime, getHumanizedDateWithHour } from 'infrastructure/helpers/dateHelper.js';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router';
import MainLayout from 'views/Layouts/MainLayout.jsx';

const Session = () => {
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0, totalCount: 0 });
	const intl = useIntl();
	const history = useHistory();
	const location = useLocation();
	const [sessions, setSessions] = useState([]);
	const [selectedPatient, setSelectedPatient] = useState(null);
	const { state } = location;
	const { from, to, patient, selectedTimezone, selectedLevel } = state;

	useEffect(() => {
		setSelectedPatient(patient);
	}, [location]);

	useEffect(() => {
		const fetchPatientSession = async () => {
			setIsLoading(true);
			const params = {
				patientId: patient.healthcareUserIntId,
				solHelloDeviceId: patient.solHelloDeviceId,
				start: Math.floor(new Date(from).getTime() / 1000),
				end: Math.floor(convertToTimestampSecondsWithMaxTime(to ?? new Date())),
				pageSize: pagination.pageSize,
				pageIndex: pagination.pageIndex,
			};
			const response = await getPatientSession(params);
			if (response.error) {
				setError(response.error.message);
			} else {
				setSessions(response.result.data);
				setPagination(prevState => ({ ...prevState, totalCount: response.result.totalCount }));
			}
			setIsLoading(false);
		};
		fetchPatientSession();
	}, [location, pagination.pageIndex, pagination.pageSize]);

	const displaySessions = () => {
		if (!sessions || sessions.length === 0 || isLoading) {
			return [];
		}
		return sessions.map(item => ({
			careGiver: (
				<div className='flex'>
					<ProfilePicture
						fullName={item.provider}
						profilePicture={item.profilePicture ?? null}
						className='provider-intervening-img-wrapper'
					/>
					<p style={{ margin: 'var(--spacing-s)', padding: '0' }}>{item.provider}</p>
				</div>
			),
			type: getCallType(item.callType),
			startTime: getHumanizedDateWithHour(item.startTime),
			endTime: getHumanizedDateWithHour(item.endedTime),
			duration: convertSecondsToHoursFormat(item.duration, true),
		}));
	};

	const getPatientProperty = property => selectedPatient?.[property] ?? 'N/A';

	return (
		<MainLayout>
			<div className='main-view'>
				<HeaderInfo
					patient={{
						name: getPatientProperty('fullName'),
						url: getPatientProperty('profilePicture'),
					}}
					goBack={() => history.push({ pathname: '/dashboard', state: { prevTab: location.state.prevTab } })}
					sections={[
						{
							id: 0,
							labels: ['MRN', intl.formatMessage({ id: 'age' }), `RN - ${intl.formatMessage({ id: 'nameNumber' })}`],
							values: [getPatientProperty('mrn'), getPatientProperty('age'), getPatientProperty('nameNumber')],
						},
						{
							id: 1,
							labels: [
								`PCT - ${intl.formatMessage({ id: 'nameNumber' })}`,
								`TL - ${intl.formatMessage({ id: 'nameNumber' })}`,
								intl.formatMessage({ id: 'primaryAdverseEvent' }),
							],
							values: [
								getPatientProperty('pctNameNumber'),
								getPatientProperty('TLNameNumber'),
								getPatientProperty('primaryAdverseEvent'),
							],
						},
						{
							id: 2,
							labels: [
								intl.formatMessage({ id: 'primaryPatientCondition' }),
								intl.formatMessage({ id: 'primaryPatientRisk' }),
								intl.formatMessage({ id: 'additionalTitleBarNotes' }),
							],
							values: [
								getPatientProperty('primaryPatientCondition'),
								getPatientProperty('primaryPatientRisk'),
								getPatientProperty('primaryPatientCondition'),
							],
						},
					]}
				/>
				<div className='padding-sl'>
					<div className='margin-bottom-xxl'>
						<Grid columns='1fr 1fr 1fr' gridGap='15px'>
							<SessionByTime />
						</Grid>
					</div>
					<Table
						headers={[
							{ title: translate('careGiver'), id: 0 },
							{ title: translate('type'), id: 1 },
							{ title: translate('admittedDate'), id: 2 },
							{ title: translate('dischargedDate'), id: 3 },
							{ title: translate('duration'), id: 4 },
						]}
						rows={displaySessions()}
						isEditable={false}
						isLoading={isLoading}
					/>
					<div className='pagination-bar'>
						<Pagination
							totalCount={pagination.totalCount}
							pageSize={pagination.pageSize}
							pageIndex={pagination.pageIndex}
							onChange={(size, index) => setPagination(prevState => ({ ...prevState, pageIndex: index, pageSize: size }))}
						/>
					</div>
					<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
				</div>
			</div>
		</MainLayout>
	);
};

export default Session;
