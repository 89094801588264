import React from 'react';
import Select from 'react-select';
import translate from 'i18n-translations/translate.jsx';

const Pagination = props => {
	const options = [10, 25, 50, 75, 100];

	const pageSizeChanged = page => {
		props.onChange(page.value, 0);
	};

	const goToNextPage = () => {
		props.onChange(props.pageSize, props.pageIndex + 1);
	};

	const goToPrevPage = () => {
		props.onChange(props.pageSize, props.pageIndex - 1);
	};

	const totalNumberOfPages = () => {
		const pages = parseInt(`${Math.ceil(parseFloat(`${props.totalCount / props.pageSize}`))}`, 10);
		return pages === 0 ? 1 : pages;
	};

	const DropdownIndicator = () => {
		return <i className='material-icons-outlined'>arrow_drop_down</i>;
	};

	return (
		<>
			{props.totalCount > props.pageSize && (
				<div className='pagination-container'>
					<div className='pagination'>
						<span>{translate('rowsPerPage')}:</span>
						<Select
							menuPlacement='top'
							value={{ value: props.pageSize, label: props.pageSize.toString() }}
							classNamePrefix='custom-select'
							options={options.map(item => ({ value: item, label: item.toString(), isDisabled: props.totalCount < item }))}
							components={{ DropdownIndicator }}
							onChange={pageSizeChanged}
						/>
						<span>
							{props.pageIndex + 1} {translate('of')} {totalNumberOfPages()}
						</span>
						<span className='pagination-controls' data-cy='nextPage'>
							{props.pageIndex !== 0 && (
								<button onClick={() => goToPrevPage()} type='button'>
									<i className='material-icons'>keyboard_arrow_left</i>
								</button>
							)}
							{props.pageIndex + 1 !== totalNumberOfPages() && (
								<button onClick={() => goToNextPage()} type='button'>
									<i className='material-icons'>keyboard_arrow_right</i>
								</button>
							)}
						</span>
					</div>
				</div>
			)}
		</>
	);
};

export default Pagination;
