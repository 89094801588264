import { APP_CONFIG } from 'constants/global-variables.js';
import { isDocumentAttachment, isPictureAttachment, isVideoAttachment } from 'infrastructure/helpers/commonHelpers.js';


export function getMediaCdnURL(fileName, fileType) {
	if (isDocumentAttachment(fileType)) {
		return `${APP_CONFIG.mediaCdnBaseUrl}files/${fileName}`;
	}
	if (isPictureAttachment(fileType)) {
		return `${APP_CONFIG.mediaCdnBaseUrl}images/${fileName}`;
	}
	if (isVideoAttachment(fileType)) {
		return `${APP_CONFIG.mediaCdnBaseUrl}videos/${fileName}`;
	}
	throw new Error('Invalid file type');
}
