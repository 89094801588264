import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import Cropper from 'react-easy-crop';
import { useIntl } from 'react-intl';
import Avatar from 'components/Avatar';
import Modal from 'components/Modal.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';

const ImageUploader = props => {
	const [previewImage, setPreviewImage] = useState(null);
	const [uploadedImg, setUploadedImg] = useState(null);
	const [isImageCropperVisible, setIsImageCropperVisible] = useState(false);
	const [croppedImage, setCroppedImage] = useState('');
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

	const intl = useIntl();

	useEffect(() => {
		if (props.existingLogo) {
			setPreviewImage(props.existingLogo);
		}
	}, [props.existingLogo]);

	const onImageUpload = async (uploadedFile, acceptedFile) => {
		const photo = uploadedFile[0];
		if (props.imageTypes && acceptedFile?.length > 0) {
			const arr = props.imageTypes.replace(/\s/g, '').split(',');
			const foundType = arr.find(item => item === acceptedFile[0].type);
			if (!foundType) {
				props.setError(translate('acceptedFilesTypesAre', { value: props.imageTypes }));
				return;
			}
		}
		if (!photo) {
			props.setError(intl.formatMessage({ id: 'pleaseSelectValidImageType' }));
			return;
		}
		const sizeInMB = photo.size / 1024 / 1024;
		if (sizeInMB > props.sizeInMB) {
			props.setError(translate('maxSizeExceeded'));
			return;
		}
		photo.preview = URL.createObjectURL(photo);
		toggleImageCropper(photo.preview);
		setUploadedImg(photo);
		props.setError(null);
	};

	const toggleImageCropper = croppedImg => {
		setIsImageCropperVisible(prevState => !prevState);
		setCroppedImage(croppedImg || '');
		setZoom(1);
		setCrop({ x: 0, y: 0 });
	};

	const uploadCroppedImage = async () => {
		if (props.isCompanyDetails) {
			props.setIsPictureUploading(true);
		}

		const formData = new FormData();

		formData.append('File', uploadedImg);
		formData.append('CropWidth', croppedAreaPixels ? croppedAreaPixels.width : '0');
		formData.append('CropHeight', croppedAreaPixels ? croppedAreaPixels.height : '0');
		formData.append('CropOffsetX', croppedAreaPixels ? croppedAreaPixels.x : '0');
		formData.append('CropOffsetY', croppedAreaPixels ? croppedAreaPixels.y : '0');

		const response = await props.uploadProfilePic(formData);
		if (response.error) {
			props.setError(response.error.message);
			setIsImageCropperVisible(false);
			return;
		}
		setPreviewImage(response.thumbnails[2].url);
		if (props.isCompanyDetails) {
			props.setPreviewImage(response.thumbnails[2].url);
		}
		props.setProfilePicture(response.fileName);
		setIsImageCropperVisible(false);
		if (props.isCompanyDetails) {
			props.setIsPictureUploading(false);
		}
	};

	return (
		<>
			<div>
				{previewImage && <Avatar src={previewImage} />}
				{props.profilePicture && !previewImage && <Avatar src={props.profilePicture} />}
			</div>
			<Dropzone accept={props.imageTypes || 'image/jpeg, image/jpg, image/png, image/jfif, image/bmp'} onDrop={onImageUpload}>
				{({ getRootProps, getInputProps }) => (
					<div style={{ textAlign: 'center' }} className={props.className} {...getRootProps()}>
						<input {...getInputProps()} />
						<button type='button' className='attestations-inputs-button top-15'>
							<img src={`${healthCareCdnUrl}edit-photo-icon.svg`} alt='icon' className='image-uploader-icon' />
							{props.buttonText || intl.formatMessage({ id: 'updatePicture' })}
						</button>
						<span className='red-error'>{props.error}</span>
					</div>
				)}
			</Dropzone>
			<Modal
				display={isImageCropperVisible}
				onModalClose={toggleImageCropper}
				onModalSubmit={uploadCroppedImage}
				className='image-cropper-modal'
				primaryButtonLabel={translate('upload')}>
				<Cropper
					image={croppedImage}
					crop={crop}
					zoom={zoom}
					aspect={1}
					onCropChange={cropValue => setCrop(cropValue)}
					onCropComplete={(croppedArea, croppedPixels) => setCroppedAreaPixels(croppedPixels)}
					onZoomChange={zoomValue => setZoom(zoomValue)}
				/>
			</Modal>
		</>
	);
};

export default ImageUploader;
