import { AllConnections } from '@solaborate/calls';
import { MsConnection, MsSubscriber, P2PConnection, SipSubConnection } from '@solaborate/calls/webrtc';
import useLocalParticipant from 'calls/hooks/useLocalParticipant.js';
import { RTCMediaType, RTCStatsType } from 'constants/enums.js';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const StyledPeerConnectionStats = styled.div`
	background: #222930;
	padding: 4px 12px 4px 12px;
	border-radius: 6px;

	p {
		font-size: 14px;
		color: white;
		margin: 0;
		padding: 0;
	}
`;

const PeerConnectionStats = ({ isLocalSrc = false, participant, isScreenShare = false }) => {
	const [currentStats, setCurrentStats] = useState(null);
	const [previousStats, setPreviousStats] = useState(null);
	const [videoBytesSent, setVideoBytesSent] = useState(null);
	const [audioBytesSent, setAudioBytesSent] = useState(null);
	const [videoBytesRec, setVideoBytesRec] = useState(null);
	const [audioBytesRec, setAudioBytesRec] = useState(null);
	const [frameRate, setFrameRate] = useState(null);
	const [resolution, setResolution] = useState({ width: 0, height: 0 });
	const [codec, setCodec] = useState(null);
	const [transportType, setTransportType] = useState(null);
	const [fetchTimeInSeconds] = useState(1);
	const [byteToKilobit] = useState(125);

	const localParticipant = useLocalParticipant();

	const currInboundReports = useRef(null);
	const currOutboundReports = useRef(null);

	const getStatsInterval = useRef(null);

	const getOutboundConnection = () => {
		let connection;
		if (localParticipant.connection instanceof AllConnections) {
			const msConnection = localParticipant.connection.connections.find(c => c instanceof MsConnection);
			if (msConnection) {
				connection = msConnection;
			} else {
				connection = localParticipant.connection.connections[0];
			}
		}
		return connection;
	};

	const getStats = async () => {
		const inboundStats = await participant.connection.getStats();
		const outboundStats = await getOutboundConnection().getStats();

		currInboundReports.current = [];
		currOutboundReports.current = [];

		inboundStats.forEach(report => {
			if (report.type === RTCStatsType.INBOUND) {
				currInboundReports.current.push(report);
				const codecReport = inboundStats.get(report.codecId);
				if (codecReport) {
					codecReport.kind = report.kind;
					codecReport.remoteSource = true;
					currInboundReports.current.push(codecReport);
				}
				if (report.kind === RTCMediaType.VIDEO) {
					const trackReport = inboundStats.get(report.trackId);
					if (trackReport) {
						trackReport.kind = report.kind;
						currInboundReports.current.push(trackReport);
					}
				}
			}
			if (report.type === RTCStatsType.REMOTE_CANDIDATE) {
				const transportReport = inboundStats.get(report.transportId);
				if (transportReport) {
					const candidatePair = inboundStats.get(transportReport.selectedCandidatePairId);
					if (candidatePair) {
						const remoteCandidate = inboundStats.get(candidatePair.remoteCandidateId);
						if (remoteCandidate) {
							remoteCandidate.kind = report.kind;
							currInboundReports.current.push(remoteCandidate);
						}
					}
				}
			}
		});
		outboundStats.forEach(report => {
			if (report.type === RTCStatsType.OUTBOUND) {
				currOutboundReports.current.push(report);
				const codecReport = outboundStats.get(report.codecId);
				if (codecReport) {
					codecReport.kind = report.kind;
					codecReport.remoteSource = false;
					currOutboundReports.current.push(codecReport);
				}

				if (report.kind === RTCMediaType.VIDEO) {
					const trackReport = outboundStats.get(report.trackId);
					if (trackReport) {
						trackReport.kind = report.kind;
						currOutboundReports.current.push(trackReport);
					}
				}
			}
		});

		let newStats = {};
		if (Object.values(newStats).length === 0) {
			newStats = {
				outbound: { video: [], audio: [] },
				inbound: { video: [], audio: [] },
				videoResolution: undefined,
				videoOutboundResolution: undefined,
				audioCodec: undefined,
				videoCodec: undefined,
				videoOutboundCodec: undefined,
				audioOutboundCodec: undefined,
				transportType: undefined,
			};
		}
		// Inbound
		newStats.inbound.video = currInboundReports.current
			.filter(x => x.kind === RTCMediaType.VIDEO && x.type === RTCStatsType.INBOUND)
			.sort((a, b) => (a.trackId > b.trackId ? -1 : 1));

		const [track] = currInboundReports.current.filter(x => x.kind === RTCMediaType.VIDEO).sort((a, b) => (a.id > b.id ? -1 : 1));
		if (track && track.frameHeight) {
			newStats.videoResolution = { width: track.frameWidth, height: track.frameHeight };
		} else {
			newStats.videoResolution = { width: 0, height: 0 };
		}

		const videoCodec = currInboundReports.current.find(
			x => x.kind === RTCMediaType.VIDEO && x.type === RTCStatsType.CODEC && x.remoteSource === true
		);

		if (videoCodec) {
			newStats.videoCodec = videoCodec.mimeType.replace('video/', '');
		}
		const audioCodec = currInboundReports.current.find(
			x => x.kind === RTCMediaType.AUDIO && x.type === RTCStatsType.CODEC && x.remoteSource === true
		);

		if (audioCodec) {
			newStats.audioCodec = audioCodec.mimeType.replace('audio/', '').toUpperCase();
		}

		newStats.inbound.audio = currInboundReports.current
			.filter(x => x.kind === RTCMediaType.AUDIO && x.type === RTCStatsType.INBOUND)
			.sort((a, b) => (a.trackId > b.trackId ? -1 : 1));

		const remoteCandidate = currInboundReports.current.find(x => x.type === RTCStatsType.REMOTE_CANDIDATE);
		if (participant.connection instanceof P2PConnection) {
			newStats.transportType = remoteCandidate === 'relay' ? 'Realy' : 'P2P';
		} else if (participant.connection instanceof MsSubscriber) {
			newStats.transportType = 'MS';
		} else if (participant.connection instanceof SipSubConnection) {
			newStats.transportType = 'SIP';
		}

		// Outbound
		newStats.outbound.video = currOutboundReports.current
			.filter(x => x.kind === RTCMediaType.VIDEO && x.type === RTCStatsType.OUTBOUND)
			.sort((a, b) => (a.trackId > b.trackId ? -1 : 1));

		newStats.outbound.audio = currOutboundReports.current
			.filter(x => x.kind === RTCMediaType.AUDIO && x.type === RTCStatsType.OUTBOUND)
			.sort((a, b) => (a.trackId > b.trackId ? -1 : 1));

		const [outboundTrack] = currOutboundReports.current
			.filter(x => x.kind === RTCMediaType.VIDEO && x.type === RTCStatsType.TRACK && x.remoteSource === false)
			.sort((a, b) => (a.id > b.id ? -1 : 1));

		if (outboundTrack && outboundTrack.frameWidth) {
			newStats.videoOutboundResolution = {
				width: outboundTrack.frameWidth,
				height: outboundTrack.frameHeight,
			};
		} else {
			newStats.videoOutboundResolution = {
				width: 0,
				height: 0,
			};
		}

		const videoOutboundCodec = currOutboundReports.current.find(
			x => x.kind === RTCMediaType.VIDEO && x.type === RTCStatsType.CODEC && x.remoteSource === false
		);

		if (videoOutboundCodec) {
			newStats.videoOutboundCodec = videoOutboundCodec.mimeType.replace('video/', '');
		}
		const audioOutboundCodec = currOutboundReports.current.find(
			x => x.kind === RTCMediaType.AUDIO && x.type === RTCStatsType.CODEC && x.remoteSource === false
		);

		if (audioOutboundCodec) {
			newStats.audioOutboundCodec = audioOutboundCodec.mimeType.replace('audio/', '').toUpperCase();
		}
		setCurrentStats(newStats);
	};

	const getDifference = (a, b) => {
		return a - b >= 0 ? a - b : 0;
	};

	const getInboundFrameRate = () => {
		return currentStats?.inbound?.video && previousStats?.inbound?.video
			? getDifference(currentStats?.inbound?.video[0]?.framesDecoded, previousStats?.inbound?.video[0]?.framesDecoded) /
					fetchTimeInSeconds
			: null;
	};

	const getState = () => {
		if (!isLocalSrc) {
			return {
				videoBytesSent:
					currentStats?.outbound?.video && previousStats?.outbound?.video
						? getDifference(currentStats?.outbound?.video[0]?.bytesSent, previousStats?.outbound.video[0]?.bytesSent || 0) /
						  fetchTimeInSeconds /
						  byteToKilobit
						: null,
				audioBytesSent:
					currentStats?.outbound?.audio && previousStats?.outbound?.audio
						? getDifference(currentStats?.outbound?.audio[0]?.bytesSent, previousStats?.outbound?.audio[0]?.bytesSent || 0) /
						  fetchTimeInSeconds /
						  byteToKilobit
						: null,
				videoBytesRec:
					currentStats?.inbound?.video && previousStats?.inbound?.video
						? getDifference(
								currentStats?.inbound?.video[0]?.bytesReceived,
								previousStats?.inbound?.video[0]?.bytesReceived || 0
						  ) /
						  fetchTimeInSeconds /
						  byteToKilobit
						: null,
				audioBytesRec:
					currentStats?.inbound?.audio && previousStats?.inbound?.audio
						? getDifference(
								currentStats?.inbound?.audio[0]?.bytesReceived,
								previousStats?.inbound?.audio[0]?.bytesReceived || 0
						  ) /
						  fetchTimeInSeconds /
						  byteToKilobit
						: null,
				frameRate: getInboundFrameRate(),
				resolution:
					getInboundFrameRate() && currentStats?.videoResolution ? currentStats?.videoResolution : { width: 0, height: 0 },
				codec:
					// eslint-disable-next-line no-nested-ternary
					currentStats?.videoCodec && currentStats?.audioCodec
						? `${currentStats?.audioCodec || 'N/A'}\\${currentStats?.videoCodec || 'N/A'}`
						: currentStats?.videoCodec
						? `${currentStats?.videoCodec || 'N/A'}`
						: `${currentStats?.audioCodec || 'N/A'}`,
				transportType: currentStats?.transportType ? currentStats?.transportType : 'N/A',
			};
		}
		return {
			frameRate:
				currentStats?.outbound.video && previousStats?.outbound.video
					? getDifference(currentStats?.outbound.video.framesEncoded, previousStats?.outbound.video.framesEncoded) /
					  fetchTimeInSeconds
					: null,
			resolution: currentStats?.videoOutboundResolution ? currentStats?.videoOutboundResolution : { width: 0, height: 0 },
			codec:
				// eslint-disable-next-line no-nested-ternary
				!currentStats?.videoOutboundCodec && !currentStats?.audioOutboundCodec
					? 'N/A'
					: currentStats?.videoOutboundCodec
					? `${currentStats?.audioOutboundCodec}\\${currentStats?.videoOutboundCodec}`
					: `${currentStats?.audioOutboundCodec}`,
		};
	};

	const getPeerStats = () => {
		let stats = {};
		if (!isLocalSrc) {
			stats = [
				{ key: 'Video', value: `${!isScreenShare ? `${Math.round(videoBytesSent)}kbs↑` : ''} ${Math.round(videoBytesRec)}kbs↓` },
				{ key: 'Audio', value: `${!isScreenShare ? `${Math.round(audioBytesSent)}kbs↑` : ''} ${Math.round(audioBytesRec)}kbs↓` },
				{ key: 'Codec', value: codec ?? '...' },
				{ key: 'Framerate', value: `${Math.round(frameRate)} FPS` },
				{ key: 'Resolution', value: `${resolution.width}x${resolution.height}` },
				{ key: 'TT', value: transportType ?? '...' },
			];
		} else {
			stats = [
				{ key: 'Framerate', value: `${Math.round(frameRate)} FPS` },
				{ key: 'Resolution', value: `${resolution.width}x${resolution.height}` },
				{ key: 'Codec', value: codec ?? '...' },
			];
		}

		return stats;
	};

	useEffect(() => {
		getStatsInterval.current = setInterval(async () => {
			await getStats();
			const newStats = { ...currentStats };
			setPreviousStats(newStats);
			const state = getState();
			setVideoBytesSent(state.videoBytesSent);
			setVideoBytesRec(state.videoBytesRec);
			setAudioBytesSent(state.audioBytesSent);
			setAudioBytesRec(state.audioBytesRec);
			setFrameRate(state.frameRate);
			setCodec(state.codec);
			setResolution(state.resolution);
			setTransportType(state.transportType);
		}, fetchTimeInSeconds * 1000);

		return () => {
			clearInterval(getStatsInterval.current);
		};
	}, [currentStats]);

	return (
		<StyledPeerConnectionStats>
			{getPeerStats().map(({ key, value }) => (
				<p key={key}>
					<b>{key}: </b>
					{value}
				</p>
			))}
		</StyledPeerConnectionStats>
	);
};

export default PeerConnectionStats;
