import React, { useContext, useEffect, useRef, useState } from 'react';
import { conference as Conference, enums, participant as Participant } from '@solaborate/calls';
import { useHistory } from 'react-router';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { useConference, useConferenceConfigurations, useConferenceState, useLocalParticipant } from 'calls/hooks/index.js';
import { reassignRequestNextMedic } from 'api/doctorRequests.js';
import { NotificationTypes, RequestStatus, UserRole, UserRoles, UserTypes, VisitReasons } from 'constants/enums.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';
import { getUserRole } from 'infrastructure/auth.js';
import { getRequest, updateAssignRequest } from 'api/patientRequests.js';
import { createVisit } from 'api/medicalInfo.js';
import Modal from 'components/Modal.jsx';
import translate from 'i18n-translations/translate.jsx';
import { ControlsActions } from 'calls/enums/index.js';
import { getDeviceOwner, getMyPatients } from 'api/patients.js';
import { getDescFromEnum, mapDevicesToPatients, skipDuplicatedObjects } from 'infrastructure/helpers/commonHelpers.js';
import { actionCreators as patientActionCreators } from 'state/patients/actions.js';
import { actionCreators as doctorsActionCreators } from 'state/doctors/actions.js';
import ContactInfo from 'containers/CardRequest/ContactInfo.jsx';

/**
 * @param {object} props
 * @param {boolean} [props.isJoin]
 */
const AssignedRequestConferenceFunctions = ({ isJoin }) => {
	const conference = useConference();
	const conferenceConfigurations = useConferenceConfigurations();
	const localParticipant = useLocalParticipant();
	const history = useHistory();
	const socket = useContext(SocketContext);
	const intl = useIntl();
	const dispatch = useDispatch();
	const conferenceState = useConferenceState();
	const { current: userRole } = useRef(getUserRole());
	const { current: queryParams } = useRef(new URLSearchParams(history.location.search));
	const [assignedRequestId] = useState(queryParams.get('assignedRequestId'));
	const [medicWhoCanceled, setMedicWhoCanceled] = useState('');
	const [doctorToTransfer, setDoctorToTransfer] = useState(null);
	const [, setError] = useState(null);
	const [assignedRequest, setAssignedRequest] = useState(null);

	useEffect(() => {
		getAssignedRequest();

		conference.on(event => {
			let unbindParticipantListeners = () => {};
			if (event instanceof Conference.ParticipantAdded) {
				const onParticipantConnected = async state => {
					if (conference.participants.size === 1 && state instanceof Participant.StateConnected) {
						const { participant } = event;
						const doctorId = UserRoles.DOCTOR === userRole ? localParticipant.objectId : participant.objectId;
						const patient = UserRoles.PATIENT === userRole ? localParticipant : participant;
						let patientId = patient.objectId;
						if (patient.objectType === enums.ObjectTypes.HELLO_DEVICE) {
							const response = await getDeviceOwner(patient.objectId);
							if (!response.error) {
								patientId = response.userId;
							}
						}
						const currentParams = queryString.parse(history.location.search);
						if (currentParams?.medicalVisitId) {
							conferenceConfigurations.setVisitId(currentParams.medicalVisitId);
						} else {
							getVisitId(patientId, doctorId);
						}
					}
				};

				unbindParticipantListeners = event.participant.on(onParticipantConnected);
			} else if (event instanceof Conference.ParticipantRemoved) {
				unbindParticipantListeners();
			} else if (event instanceof Conference.StateInitiated && isJoin) {
				updateRequest(RequestStatus.ACCEPTED);
			} else if (event instanceof Conference.OwnerChanged && event.participant.id === localParticipant.id) {
				getMedicalVisit();
			}
		});

		socket.on(SocketEvents.HealthCare.ON_NEW_NOTIFICATION, handleRequestUpdated);
		return () => {
			socket.off(SocketEvents.HealthCare.ON_NEW_NOTIFICATION, handleRequestUpdated);
		};
	}, [conference]);

	const getVisitId = async (patientId, doctorId) => {
		if (userRole !== UserRoles.DOCTOR || conferenceConfigurations.visitId) {
			return;
		}
		const params = { patientId, doctorId, place: conference.conferenceName, conferenceId: conference.conferenceId };
		const res = await createVisit(params);
		if (res.error) {
			setError(res.error.message);
		} else {
			conferenceConfigurations.setVisitId(res.id);
		}
	};

	const getMedicalVisit = async () => {
		const patient = [...conference.participants.values()].find(
			participant => participant.role === UserTypes.PATIENT || participant.objectType === enums.ObjectTypes.HELLO_DEVICE
		);
		let patientId = patient.objectId;
		if (patient.objectType === enums.ObjectTypes.HELLO_DEVICE) {
			const response = await getDeviceOwner(patient.objectId);
			if (!response.error) {
				patientId = response.userId;
			}
		}
		getVisitId(patientId, localParticipant.objectId);
	};

	const updateRequest = async (requestStatusId, cancelPending = false) => {
		if (!assignedRequestId) {
			return;
		}
		const response = await updateAssignRequest(assignedRequestId, { RequestStatusId: requestStatusId }, cancelPending);
		if (response.error) {
			// this.setState({ error: response.error.message });
		}
		if (userRole === UserRoles.DOCTOR) {
			const patients = await getMyPatients();
			if (patients.error) {
				return;
			}
			const patientsMaped = skipDuplicatedObjects(patients, 'userId');
			const patientsWithDevices = mapDevicesToPatients(patientsMaped, patients);
			dispatch(patientActionCreators.setPatients(patientsWithDevices));
			dispatch(doctorsActionCreators.setIsRequestUpdated(true));
		}
	};

	const handleRequestUpdated = data => {
		const request = JSON.parse(data.payload);
		if (data.notificationType === NotificationTypes.REQUEST_REJECTED) {
			setMedicWhoCanceled(`${request.Medic.FirstName} ${request.Medic.LastName}`);
		}
	};

	const submitCanceledRequest = () => {
		setMedicWhoCanceled(null);
		conference.leave();
	};

	const getRequestToSet = request => {
		if (!request) {
			return null;
		}
		return {
			mrn: request.patientExtendedProfile?.mrn,
			reasonToConnect: request.requestReason
				? intl.formatMessage({ id: getDescFromEnum(request.requestReason, VisitReasons) })
				: '',
			nurseInfo: request.requestIntakeInfo
				? {
						name: request.requestIntakeInfo.nurseFullName,
						phone: request.requestIntakeInfo.nurseStationPhone,
						ext: request.requestIntakeInfo?.nurseExt,
				  }
				: 'N/A',
			clinicalContact: request.requestIntakeInfo
				? {
						name: request.requestIntakeInfo.clinicalContactFullName,
						phone: request.requestIntakeInfo.clinicalContactPhone,
						ext: request.requestIntakeInfo.clinicalContactExt,
				  }
				: 'N/A',
		};
	};

	const getAssignedRequest = async () => {
		if (![UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN, UserRoles.DOCTOR].includes(getUserRole()) || !assignedRequestId) {
			return;
		}
		const response = await getRequest(assignedRequestId);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		setAssignedRequest(getRequestToSet(response.assignedRequest.request));
		if (getUserRole() === UserRole.DOCTOR) {
			return;
		}
		// setSymptoms(assignedRequest.request.requestSymptoms);
		setDoctorToTransfer(assignedRequest.nextMedic);
	};

	const transferToDoctor = async () => {
		const response = await reassignRequestNextMedic(assignedRequestId);
		if (response.error) {
			setError(response.error.message);
		} else {
			conference.leave();
			conference.close(enums.ConferenceEndReasons.PARTICIPANT_LEFT);
			conferenceConfigurations.onConfigurationToggleAction(ControlsActions.TOGGLE_TRANSFER_TO_DOCTOR_MODAL, false);
			setTimeout(() => {
				window.close();
			}, 3000);
		}
	};

	const isSubmitWrapUpShown = () => conferenceConfigurations.visitId && conferenceState instanceof Conference.StateEnded;

	const showCard = () =>
		!isSubmitWrapUpShown() &&
		(assignedRequest.mrn ||
			assignedRequest.reasonToConnect ||
			assignedRequest.nurseInfo?.name ||
			assignedRequest.nurseInfo?.phone ||
			assignedRequest.nurseInfo?.ext);

	return (
		<>
			{medicWhoCanceled && (
				<Modal
					className='small-popup-modal'
					display={true}
					position='center'
					onModalSubmit={submitCanceledRequest}
					onModalClose={submitCanceledRequest}
					primaryButtonLabel='OK'
					hideCloseButton={true}>
					<>
						<h4>{translate('requestRejected')}</h4>
						<p>
							{medicWhoCanceled} {translate('rejectedRequest')}
						</p>
					</>
				</Modal>
			)}
			{conferenceConfigurations.isTransferToDoctorModalOpen && (
				<Modal
					display={true}
					position='center'
					onModalSubmit={transferToDoctor}
					onModalClose={() =>
						conferenceConfigurations.onConfigurationToggleAction(ControlsActions.TOGGLE_TRANSFER_TO_DOCTOR_MODAL, false)
					}
					primaryButtonLabel={intl.formatMessage({ id: 'yes' })}>
					<form>
						<h3>{translate('warning')}</h3>
						<p>
							{translate('transferToDoctorWarning', {
								doctor: `${doctorToTransfer?.firstName} ${doctorToTransfer?.lastName}`,
								patient: conference.conferenceName,
							})}
						</p>
					</form>
				</Modal>
			)}
			{assignedRequest && showCard() && (
				<div className='assigned-request-card'>
					<ContactInfo
						hasBoldLabels={true}
						mrn={assignedRequest.mrn}
						reasonToConnect={assignedRequest.reasonToConnect}
						name={assignedRequest.nurseInfo.name}
						phone={assignedRequest.nurseInfo.phone}
						ext={assignedRequest.nurseInfo.ext}
					/>
				</div>
			)}
		</>
	);
};

export default AssignedRequestConferenceFunctions;
