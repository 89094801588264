import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MediaPermissionsErrorType, requestMediaPermissions } from 'mic-check';
import { enums } from '@solaborate/calls';
import { isChrome, isEdgeChromium, isMobileSafari } from 'react-device-detect';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import Grid from 'components/Grid.jsx';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import { getMemberRooms } from 'api/organization.js';
import { StartQueryStringKeys } from 'calls/enums/index.js';
import { actionCreators as healthSystemsActionCreators } from 'state/healthSystems/actions.js';
import {
	DeviceAvailability,
	DeviceListLevel,
	ErrorComponentTypes,
	StreamError,
	UserPermissionDeniedErrors,
	VisitorType,
} from 'constants/enums.js';
import HelloFeatureBlock from 'components/HelloFeatureBlock.jsx';
import Modal from 'components/Modal.jsx';
import Table from 'components/Table.jsx';
import EmptyState from 'components/EmptyState.jsx';
import Alert from 'components/Alert.jsx';
import translate from 'i18n-translations/translate.jsx';
import SocketEvents from 'constants/socket-events.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { checkIfMediaDevicesPlugged } from 'infrastructure/helpers/commonHelpers.js';
import { getVisitingHours } from 'api/visitingHours.js';
import { RoomTypes } from 'constants/visitEnums.js';
import { convertUTCDateToLocalDate, timeDifference } from 'infrastructure/helpers/dateHelper.js';
import DisabledCountDown from 'components/DisabledCountDown.jsx';
import {
	getInitialVisitingHoursMaped,
	reArrangeVisitingHours,
	removeSeconds,
} from 'infrastructure/helpers/visitingHoursHelper.js';

const Visitor = () => {
	const helloName = useSelector(state => state.company.companySettings.helloName);
	const [roomsAssigned, setRoomsAssigned] = useState([]);
	const [selectedRoom, setSelectedRoom] = useState(null);
	const [error, setError] = useState(null);
	const isAllowPermissionPrompt = useRef(false);
	const socket = useContext(SocketContext);
	const intl = useIntl();
	const [visitingHours, setVisitingHours] = useState([]);
	const [flattenedVisitingHours, setFlattenedVisitingHours] = useState([]);
	const [isDisabledModalShown, setIsDisabledModalShown] = useState(false);
	const [disabledVisitTime, setDisabledVisitTime] = useState({
		createdAt: new Date(),
		disableTime: 0,
		isDisabled: false,
	});
	const [showLeftSide, setShowLeftSide] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		const fetchRooms = async () => {
			const response = await getMemberRooms();
			if (response.error) {
				setError(response.error.message);
				return;
			}
			if (response.rooms.length > 0) {
				setRoomsAssigned(response.rooms);
				setSelectedRoom(response.rooms[0]);
			}
		};
		fetchRooms();
	}, []);

	useEffect(() => {
		if (selectedRoom?.helloDeviceId) {
			const fetchVisitingHours = async () => {
				const response = await getVisitingHours(DeviceListLevel.ROOM, selectedRoom.id);
				if (response.error) {
					setError(response.error.message);
				} else {
					const createdAt = new Date(response.createdAt);
					const localTime = convertUTCDateToLocalDate(createdAt);
					setDisabledVisitTime({
						createdAt: localTime,
						disableTime: response.disableTime,
						isDisabled: response.isVisitDisabled,
					});
					if (selectedRoom?.visitorTypeId === VisitorType.FAMILY_MEMBER) {
						return;
					}
					const flattend = response?.visitingHours
						?.map(item =>
							item.hours.map((hour, index) => ({
								...hour,
								weekDay: item.weekDay,
								from: removeSeconds(hour.from),
								to: removeSeconds(hour.to),
								id: index,
							}))
						)
						.flat();
					setFlattenedVisitingHours(flattend || []);
					const result = getInitialVisitingHoursMaped(response?.visitingHours);
					const visitingHoursRearragned = reArrangeVisitingHours(result, intl);
					setVisitingHours(visitingHoursRearragned);
				}
			};
			fetchVisitingHours();
		}
		if (selectedRoom?.visitorTypeId === VisitorType.FAMILY_MEMBER) {
			setVisitingHours([]);
			setDisabledVisitTime({
				createdAt: new Date(),
				disableTime: 0,
				isDisabled: false,
			});
		}
	}, [intl, selectedRoom]);

	useEffect(() => {
		const handlePrivacyModeUpdate = data => {
			if (selectedRoom && selectedRoom.deviceId === data.deviceId) {
				const room = { ...selectedRoom, aiPrivacyStatus: data.privacyMode };
				setSelectedRoom(room);
				setRoomsAssigned([...roomsAssigned.filter(item => item.helloDeviceId !== room.helloDeviceId), room]);
			}
		};

		const onDeviceStateUpdate = data => {
			if (data.deviceId === Number(selectedRoom.helloDeviceId)) {
				const room = { ...selectedRoom, [data.name]: data.value };
				setSelectedRoom(room);
				setRoomsAssigned([...roomsAssigned.filter(item => item.helloDeviceId !== room.helloDeviceId), room]);
			}
		};

		const handleVisitingHoursUpdate = ({ isDisabled, createdAt, disableTime, deviceId }) => {
			if (selectedRoom && +selectedRoom?.helloDeviceId === deviceId) {
				setDisabledVisitTime({
					isDisabled,
					createdAt: isDisabled ? new Date(createdAt) : new Date(),
					disableTime: isDisabled ? disableTime : 0,
				});
			}
		};

		socket.on(SocketEvents.HelloDevice.ON_STATE_CHANGED, onDeviceStateUpdate);
		socket.on(SocketEvents.HelloDevice.PRIVACY_MODE_UPDATE, handlePrivacyModeUpdate);
		socket.on(SocketEvents.HealthCare.VISITS_TOGGLE_UPDATED, handleVisitingHoursUpdate);
		return () => {
			socket.off(SocketEvents.HelloDevice.ON_STATE_CHANGED, onDeviceStateUpdate);
			socket.off(SocketEvents.HelloDevice.PRIVACY_MODE_UPDATE, handlePrivacyModeUpdate);
			socket.off(SocketEvents.HealthCare.VISITS_TOGGLE_UPDATED, handleVisitingHoursUpdate);
		};
	}, [socket, selectedRoom, roomsAssigned]);

	useEffect(() => {
		const timeout = setTimeout(() => {
			setError(null);
		}, 5000);

		return () => clearTimeout(timeout);
	}, [error]);

	useEffect(() => {
		const onDeviceOffline = data => {
			if (data.helloDeviceId === +selectedRoom?.helloDeviceId) {
				setSelectedRoom(prevState => ({ ...prevState, isOnline: false }));
			}
		};
		const onDeviceOnline = data => {
			if (data.helloDeviceId === +selectedRoom?.helloDeviceId) {
				setSelectedRoom(prevState => ({ ...prevState, isOnline: true }));
			}
		};

		socket.on(SocketEvents.Client.ON_DEVICE_OFFLINE, onDeviceOffline);
		socket.on(SocketEvents.Client.ON_DEVICE_ONLINE, onDeviceOnline);
		return () => {
			socket.off(SocketEvents.Client.ON_DEVICE_OFFLINE, onDeviceOffline);
			socket.off(SocketEvents.Client.ON_DEVICE_ONLINE, onDeviceOnline);
		};
	}, [socket, selectedRoom]);

	const showAllowPermissionModal = () => {
		isAllowPermissionPrompt.current = true;
		setTimeout(() => {
			if (!isAllowPermissionPrompt.current) {
				return;
			}

			dispatch(
				healthSystemsActionCreators.setStreamPermissionMessage({
					component: ErrorComponentTypes.Modal,
					type: isChrome || isEdgeChromium ? StreamError.MICROPHONE_BLOCKED.type : StreamError.MICROPHONE_BLOCKED_GENERIC.type,
				})
			);
		}, 500);
	};

	const openCallViewUrl = callType => {
		const queryParams = new URLSearchParams({
			[StartQueryStringKeys.OBJECT_ID]: selectedRoom.helloDeviceId,
			[StartQueryStringKeys.OBJECT_TYPE]: enums.ObjectTypes.HELLO_DEVICE,
			[StartQueryStringKeys.CONFERENCE_NAME]: selectedRoom.name,
			[StartQueryStringKeys.CALL_TYPE]: callType,
			[StartQueryStringKeys.ROOM_TYPE]: selectedRoom.type,
			[StartQueryStringKeys.IS_MULTI_BED]: selectedRoom.isMultiBed,
		});
		clearPermissions();
		const url = callType === enums.CallTypes.SECURITYCAM ? 'patient-feed' : 'call';
		if (isMobileSafari) {
			history.replace(`/${url}?${queryParams.toString()}`);
		} else {
			window.open(`/${url}?${queryParams.toString()}`, '_blank');
		}
	};

	const clearPermissions = () => {
		dispatch(healthSystemsActionCreators.setStreamPermissionMessage(null));
	};

	const talkToPatient = async () => {
		if (!canCall()) {
			setIsDisabledModalShown(true);
			return;
		}
		if (selectedRoom.aiPrivacyStatus) {
			setError(translate('enablePrivacyButtons', { value: helloName }));
			return;
		}
		if (selectedRoom.camera === DeviceAvailability.OFFLINE) {
			setError(translate('cameraOffline'));
			return;
		}
		try {
			showAllowPermissionModal();
			await requestMediaPermissions({ audio: true, video: false });
			openCallViewUrl(enums.CallTypes.VIDEO);
		} catch (err) {
			handlePermissionErrors(enums.CallTypes.VIDEO, err);
		}
		isAllowPermissionPrompt.current = false;
	};

	const handlePermissionErrors = async (callType, err) => {
		const { camera, microphone } = await checkIfMediaDevicesPlugged();
		if ([enums.CallTypes.VIDEO, enums.CallTypes.AUDIO].includes(callType) && (!camera || !microphone)) {
			dispatch(
				healthSystemsActionCreators.setStreamPermissionMessage({
					component: ErrorComponentTypes.Modal,
					type: microphone ? StreamError.MICROPHONE_NOT_FOUND.type : StreamError.CAMERA_NOT_FOUND.type,
				})
			);
			return;
		}

		const { type, name } = err;
		if (type === MediaPermissionsErrorType.UserPermissionDenied) {
			if (name === UserPermissionDeniedErrors.NotAllowedError) {
				dispatch(
					healthSystemsActionCreators.setStreamPermissionMessage({
						component: isChrome || isEdgeChromium ? ErrorComponentTypes.Popup : ErrorComponentTypes.Modal,
						type: isChrome || isEdgeChromium ? StreamError.MICROPHONE_BLOCKED.type : StreamError.MICROPHONE_BLOCKED_GENERIC.type,
					})
				);
			}
		}
	};

	const viewPatient = async () => {
		if (disabledVisitTime.isDisabled) {
			return;
		}
		try {
			if (selectedRoom.aiPrivacyStatus) {
				setError(translate('enablePrivacyButtons', { value: helloName }));
				return;
			}
			if (selectedRoom.camera === DeviceAvailability.OFFLINE) {
				setError(translate('enablePrivacyButtons', { value: helloName }));
				return;
			}
			showAllowPermissionModal();
			await requestMediaPermissions({ audio: true, video: false });
		} catch (err) {
			setError(err.message);
		}
		isAllowPermissionPrompt.current = false;
		openCallViewUrl(enums.CallTypes.SECURITYCAM);
	};

	const canCall = () => {
		if (disabledVisitTime.isDisabled) {
			return false;
		}
		if (selectedRoom?.visitorTypeId === VisitorType.FAMILY_MEMBER) {
			return true;
		}
		return isDuringVisitingHours();
	};

	const isDuringVisitingHours = () => {
		let result = false;
		const stringToDate = stringDate => {
			const date = new Date();
			const hours = stringDate.substring(0, stringDate.lastIndexOf(':'));
			const minutes = stringDate.substring(stringDate.lastIndexOf(':') + 1, stringDate.length);
			date.setHours(hours);
			date.setMinutes(minutes);
			return date;
		};
		const list = flattenedVisitingHours.filter(item => item.to && item.weekDay === new Date().getDay());
		for (let i = 0; i < list.length; i += 1) {
			const fromDate = stringToDate(list[i].from);
			const toDate = stringToDate(list[i].to);
			if (isInBetweenFromAndToDate(fromDate, toDate)) {
				result = true;
				break;
			}
		}
		return result;
	};

	const isInBetweenFromAndToDate = (fromDate, toDate) => {
		const dateNow = new Date();
		return timeDifference(dateNow, fromDate) > 0 && timeDifference(dateNow, toDate) < 0;
	};

	const isVisitorCameraAvailable = () => {
		if (!selectedRoom.isOnline) {
			return false;
		}
		if (selectedRoom.camera === DeviceAvailability.OFFLINE) {
			return false;
		}
		if (disabledVisitTime.isDisabled) {
			return false;
		}
		if (selectedRoom.visitorTypeId === VisitorType.FAMILY_MEMBER) {
			return true;
		}
		return isDuringVisitingHours();
	};

	return (
		<MainLayout isMonitoring={true}>
			<Grid
				columns={showLeftSide ? '1fr 3fr' : '0 1fr'}
				stretch='100%'
				className={classNames(
					'baby-room-view family-member-view',
					showLeftSide ? 'active' : '',
					!disabledVisitTime.isDisabled && visitingHours.length > 0 ? 'family-visitor-w-table' : ''
				)}>
				<aside className='hello-list'>
					<button
						type='button'
						className={`collapse-second-column z-index-1${!showLeftSide ? ' is-collapsed-active' : ''}`}
						onClick={() => setShowLeftSide(prevState => !prevState)}>
						<i className='material-icons-outlined'>{!showLeftSide ? 'keyboard_arrow_right' : 'keyboard_arrow_left'}</i>
					</button>
					{roomsAssigned.map(room => (
						<div
							key={room.id}
							className='flex'
							onClick={() => {
								setSelectedRoom(room);
								setShowLeftSide(false);
							}}>
							<img src={`${healthCareCdnUrl}treeview/Room${room.id === selectedRoom?.id ? '-active' : ''}.svg`} alt='room icon' />
							<p className={`cursor-pointer baby-rooms ${room.id === selectedRoom?.id ? 'active' : ''}`}>{room.name}</p>
						</div>
					))}
					{roomsAssigned.length === 0 && <p>{translate('noData')}</p>}
				</aside>
				<div className='users-view'>
					<Grid stretch='100%' columns='1fr'>
						<main className='main-view monitoring-view'>
							<section>
								{selectedRoom && (
									<>
										<h3>
											<button
												type='button'
												onClick={() => {
													setSelectedRoom(null);
													setShowLeftSide(true);
												}}>
												<span className='material-icons'>arrow_back</span>
											</button>
											{selectedRoom?.name}
										</h3>
										<div className='hello-feature-family-member top-15 bottom-15'>
											<div className='flex flex-align-center'>
												<div className='flex flex-align-center microphone-status'>
													<div>
														<img
															src={`${healthCareCdnUrl}monitoring/video-feed/mic-${
																selectedRoom.microphone === DeviceAvailability.ONLINE ? 'on' : 'off-color'
															}.svg`}
															alt='mic-status'
														/>
													</div>
													{translate(selectedRoom.microphone === DeviceAvailability.ONLINE ? 'microphoneOn' : 'microphoneMuted')}
												</div>
												<div className='flex flex-align-center camera-status'>
													<div>
														<img
															src={`${healthCareCdnUrl}monitoring/video-feed/camera-${
																selectedRoom.camera === DeviceAvailability.ONLINE ? 'on' : 'off-color'
															}.svg`}
															alt='camera-status'
														/>
													</div>
													{translate(selectedRoom.camera === DeviceAvailability.ONLINE ? 'cameraOn' : 'cameraOff')}
												</div>
											</div>

											{selectedRoom.helloDeviceId && (
												<>
													{isVisitorCameraAvailable() && (
														<div className='flex device-status available top-15'>
															<div>
																<i className='material-icons'>done</i>
															</div>
															{translate('available')}
														</div>
													)}
													{!isVisitorCameraAvailable() && (
														<div className='flex device-status unavailable top-15'>
															<div>
																<i className='material-icons'>close</i>
															</div>
															{translate('unavailable')}
														</div>
													)}
													{disabledVisitTime.isDisabled && (
														<div className='hello-feature-grid top-15'>
															<p>
																{translate('disabledFor')}:
																<DisabledCountDown
																	disabledTime={disabledVisitTime}
																	setDisabledTime={() => setDisabledVisitTime(prevState => ({ ...prevState, isDisabled: false }))}
																/>
															</p>
														</div>
													)}
													{selectedRoom.type !== RoomTypes.BABY_ROOM.type && (
														<HelloFeatureBlock
															onClick={talkToPatient}
															title={translate('talkToPatient')}
															className='hello-feature-audio top-15'
														/>
													)}
													{selectedRoom.type === RoomTypes.BABY_ROOM.type && (
														<HelloFeatureBlock
															title={translate('viewYourBaby')}
															onClick={viewPatient}
															className='hello-feature-camera-feed top-15'
														/>
													)}
												</>
											)}
										</div>

										{!disabledVisitTime.isDisabled && visitingHours.length > 0 && (
											<div className='visiting-hours-table'>
												<p>{translate('visitingHours')}</p>
												<Table
													headers={[
														{ title: translate('monday'), id: 0 },
														{ title: translate('tuesday'), id: 1 },
														{ title: translate('wednesday'), id: 2 },
														{ title: translate('thursday'), id: 3 },
														{ title: translate('friday'), id: 4 },
														{ title: translate('saturday'), id: 5 },
														{ title: translate('sunday'), id: 6 },
													]}
													rows={visitingHours}
													className='admin-table'
													isEditable={false}
												/>
											</div>
										)}
									</>
								)}
								{roomsAssigned.length === 0 && (
									<div className='empty-state-wrapper'>
										<EmptyState title={translate('noHelloIsSelected', { value: helloName })} image='no-hello.svg' />
									</div>
								)}
							</section>
						</main>
					</Grid>
				</div>
			</Grid>
			<Alert
				display={error !== null}
				message={error}
				variant='dark baby-room-alert'
				fixed={true}
				onClose={() => setError(null)}
			/>
			{isDisabledModalShown && (
				<Modal
					display={true}
					position='center'
					primaryButtonLabel='OK'
					hideCloseButton={true}
					onModalSubmit={() => setIsDisabledModalShown(false)}
					onModalClose={() => setIsDisabledModalShown(false)}>
					<form>
						<h3>{translate('visits')}</h3>
						<p>{translate('callDuringVisitingHours')}</p>
					</form>
				</Modal>
			)}
		</MainLayout>
	);
};

export default Visitor;
