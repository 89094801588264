import React, { useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import AddEditRpmProgram from 'containers/Rpm/AddEditRpmProgram.jsx';
import translate from 'i18n-translations/translate.jsx';
import Pagination from 'components/Common/Pagination.jsx';
import { Table, Alert, Modal, Form } from 'components';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { deleteRpmProgram, getRpmPrograms } from 'api/rpm.js';
import { RpmDevices, rpmMeasurements } from 'constants/rpm.js';
import { getFormattedDateWithHour } from 'infrastructure/helpers/dateHelper.js';

const ManageRpmPrograms = () => {
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0, totalCount: 0 });
	const [selectedRpmProgram, setSelectedRpmProgram] = useState(null);
	const [rpmPrograms, setRpmPrograms] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isAddOrEditOpen, setIsAddOrEditOpen] = useState(false);
	const [isDeleteOpen, setIsDeleteOpen] = useState(false);
	const [isBtnLoading, setIsBtnLoading] = useState(false);
	const [currentDeviceId, setCurrentDeviceId] = useState(null);
	const [showMore, setShowMore] = useState(false);
	const [isListUpdated, setIsListUpdated] = useState(false);
	const [error, setError] = useState(null);
	const devicesRefs = useRef([]);
	const intl = useIntl();

	const getHeaders = () => [
		{ id: 1, title: translate('program') },
		{ id: 2, title: translate('dateCreated') },
		{ id: 3, title: translate('devices') },
		{ id: 4, title: '' },
	];

	useEffect(() => {
		getRpmProgramList(pagination.pageSize, pagination.pageIndex);
	}, [pagination.pageSize, pagination.pageIndex, isListUpdated]);

	const getRpmProgramList = async (pageSize, pageIndex) => {
		const response = await getRpmPrograms({ pageSize, pageIndex });
		if (response.error) {
			setError(response.error.message);
		} else {
			setRpmPrograms(response.rpmPrograms);
			setPagination({ pageSize, pageIndex, totalCount: response.totalCount });
		}
		setIsLoading(false);
		setError(null);
	};

	const handleDeleteRpmProgram = async () => {
		setIsBtnLoading(true);
		setIsListUpdated(false);
		const response = await deleteRpmProgram({ id: selectedRpmProgram.id });
		if (response.error) {
			setError(response.error.message);
		} else {
			setError(null);
			setIsListUpdated(true);
		}
		setIsBtnLoading(false);
		setIsDeleteOpen(false);
	};

	const useCloseClick = (refs, callback) => {
		const handleClick = event => {
			if (refs?.current?.length > 0 && !refs?.current?.some(ref => ref.contains(event.target))) {
				callback();
			}
		};
		useEffect(() => {
			document.addEventListener('click', handleClick);
			return () => {
				document.removeEventListener('click', handleClick);
			};
		});
	};

	useCloseClick(devicesRefs, () => {
		setShowMore(false);
		setCurrentDeviceId(null);
	});

	const addToRefs = el => {
		if (el && !devicesRefs.current.includes(el)) {
			devicesRefs.current.push(el);
		}
	};

	const transformRows = () => {
		if (rpmPrograms.length === 0) {
			return [];
		}
		return rpmPrograms.map(item => ({
			rpmProgram: item.name,
			dateCreated: getFormattedDateWithHour(item.createdAt),
			devices: getRpmDevices(item),
			actions: getEditButtons(item),
		}));
	};

	const getMeasurement = measurementType => rpmMeasurements.find(item => item.type === measurementType);

	const getDevicesGroupedByMeasurementType = array =>
		array.reduce((acc, item) => {
			const foundMeasurement = acc.find(measurement => measurement.measurementType === item.measurementType);
			if (foundMeasurement) {
				foundMeasurement.devices.push({ deviceId: item.deviceId });
			} else {
				acc.push({
					measurementType: item.measurementType,
					devices: [{ deviceId: item.deviceId }],
				});
			}
			return acc;
		}, []);

	const getRpmDevices = item => {
		const iHealthDevices = item.devices.filter(device => Object.values(RpmDevices).includes(device.deviceId));
		const filteredArray = getDevicesGroupedByMeasurementType(iHealthDevices);
		return (
			<>
				<div
					className='rpm-devices'
					onClick={() => {
						setShowMore(prevState => !prevState);
						setCurrentDeviceId(item.id);
					}}
					ref={addToRefs}>
					{iHealthDevices.length} {intl.formatMessage({ id: 'devices' })}
					<span className='material-icons'>{showMore && item.id === currentDeviceId ? 'expand_less' : 'expand_more'}</span>
				</div>
				{showMore && item.id === currentDeviceId && (
					<div className='position-relative'>
						<div className='rpm-devices-wrapper'>
							{filteredArray.map(measurement => (
								<div className='rpm-devices-details'>
									<div className='flex'>
										<img src={getMeasurement(measurement.measurementType)?.measurementImg} alt='ico' />
										<p className='rpm-measurement-type'>{translate(`${getMeasurement(measurement.measurementType)?.name}`)}</p>
									</div>
									{measurement.devices.map(element => {
										const foundDevice = rpmMeasurements
											.flatMap(rpm => rpm.devices)
											.find(rpmDevice => rpmDevice.id === element.deviceId);
										return (
											<div>
												<p className='rpm-device'>{foundDevice?.value}</p>
											</div>
										);
									})}
								</div>
							))}
						</div>
					</div>
				)}
			</>
		);
	};

	const getEditButtons = item => (
		<div className='wrapped'>
			<Link
				to={{
					pathname: '/add-edit-rpm',
					state: {
						rpmProgramId: item.id,
					},
				}}>
				<span
					className='material-icons-outlined boxed-icon edit-icon'
					data-position='top'
					data-tooltip={intl.formatMessage({ id: 'edit' })}>
					edit
				</span>
			</Link>
			&nbsp;
			<i
				className='material-icons-outlined boxed-icon'
				onClick={() => {
					setIsDeleteOpen(prevState => !prevState);
					setSelectedRpmProgram(item);
				}}
				data-position='top'
				data-tooltip={intl.formatMessage({ id: 'delete' })}>
				delete
			</i>
		</div>
	);

	const onPageChange = async (pageSize, pageIndex) => {
		setIsLoading(true);
		setPagination(prevState => ({ ...prevState, pageSize, pageIndex }));
	};

	return (
		<>
			<Table isLoading={isLoading} headers={getHeaders()} rows={isLoading ? [] : transformRows()} className='users-list-table'>
				<div className='flex-end'>
					<Link to='add-edit-rpm'>
						<button
							type='button'
							className='invite-user-btn justify-self-end'
							onClick={() => {
								setIsAddOrEditOpen(prevState => !prevState);
								setSelectedRpmProgram(null);
							}}>
							<img src={`${healthCareCdnUrl}rpm/plus.svg`} alt='icon' className='create-appointment-icon' />
							{translate('createProgram')}
						</button>
					</Link>
				</div>
			</Table>
			{isAddOrEditOpen && <AddEditRpmProgram />}
			{isDeleteOpen && (
				<Modal
					className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal'
					display={isDeleteOpen}
					position='center'
					onModalSubmit={handleDeleteRpmProgram}
					onModalClose={() => setIsDeleteOpen(false)}
					shouldSubmitOnEnter={false}
					primaryButtonLabel={intl.formatMessage({ id: 'yes' })}
					closeImgIcon={`${healthCareCdnUrl}appointments/cancel-appointment.svg`}
					submitImgIcon={`${healthCareCdnUrl}appointments/save-icon.svg`}
					primaryButtonLoading={isBtnLoading}>
					<Form height={200} className='modal-form-wrapper'>
						<h3>{translate('deleteProgram')}</h3>
						<p>{translate('areYouSureDeleteProgram')}</p>
					</Form>
				</Modal>
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
			{rpmPrograms.length > 0 && !isLoading && (
				<Pagination
					totalCount={pagination.totalCount}
					pageSize={pagination.pageSize}
					pageIndex={pagination.pageIndex}
					onChange={(pageSize, pageIndex) => onPageChange(pageSize, pageIndex)}
				/>
			)}
		</>
	);
};

export default ManageRpmPrograms;
