import axios from 'axios'; 

export const getAzureSpeechToken = async () => {
	const speechKey = '54db6fea85cb485ca02d1a35d96973e6';
	const speechRegion = 'westeurope';
	const headers = {
		headers: {
			'Ocp-Apim-Subscription-Key': speechKey,
			'Content-Type': 'application/x-www-form-urlencoded',
		},
	};
	try {
		const tokenResponse = await axios.post(
			`https://${speechRegion}.api.cognitive.microsoft.com/sts/v1.0/issueToken`,
			null,
			headers
		);
		return tokenResponse;
	} catch (error) {
		return error;
	}
};
