import React, { useEffect, useRef, useState, forwardRef } from 'react';
import { LocalTrack, ScreenShare } from '@solaborate/calls/webrtc';
import { useConferenceConfigurations } from 'calls/hooks/index.js';
import styled, { css } from 'styled-components';

/**
 * @type {import('styled-components').StyledComponent<"video", any, { isLocalParticipantTrack?: boolean, isScreenShare?: isScreenShare, isPortrait?: boolean, isBlurred?: boolean }, never>}
 */
const StyledVideo = styled.video`
	width: 100%;
	object-fit: cover;
	position: relative;

	@media (min-width: 768px) {
		height: 100%;
	}

	${props => css`
		${props.isLocalParticipantTrack && !props.isScreenShare && `transform: scaleX(-1);`}

		${(props.isPortrait || props.isScreenShare) &&
		css`
			width: auto;
			object-fit: unset;
			z-index: 1;
		`}

		${props.isBlurred &&
		css`
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover !important;
			filter: blur(20px);
			opacity: 0.7;
		`}
	`}
`;

/**
 * @typedef {{ track: import('@solaborate/calls/webrtc').Track }} Props
 */
const ParticipantVideo = forwardRef(
	(
		/**
		 * @type {Props & React.DetailedHTMLProps<React.VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement>}
		 */
		{ track, ...htmlAttributes },
		/** @type {React.RefObject<HTMLVideoElement>} */
		ref
	) => {
		const videoRef = useRef(null);
		const localParticipantTrack = track instanceof LocalTrack;
		const { isFitScreen } = useConferenceConfigurations();
		const blurredVideoRef = useRef(null);
		const [isPortrait, setIsPortrait] = useState(false);

		useEffect(() => {
			// eslint-disable-next-line no-param-reassign
			videoRef.current.srcObject = track ? new MediaStream([track.track]) : null;

			const onLoaded = ({ target }) => {
				if (target) {
					const isPortraitVideo = target.videoWidth <= target.videoHeight;
					setIsPortrait(isPortraitVideo);
					if (isPortraitVideo && blurredVideoRef.current) {
						blurredVideoRef.current.srcObject = track ? new MediaStream([track.track]) : null;
					}
				}
			};

			if (videoRef.current) {
				videoRef.current.addEventListener('loadedmetadata', onLoaded);
			}

			if (ref) {
				// @ts-ignore
				// eslint-disable-next-line no-param-reassign
				ref.current = videoRef.current;
			}

			return () => {
				if (videoRef.current) {
					// eslint-disable-next-line react-hooks/exhaustive-deps
					videoRef.current.removeEventListener('loadedmetadata', onLoaded);
				}
			};
		}, [track, ref]);

		return (
			<>
				<StyledVideo
					{...htmlAttributes}
					ref={videoRef}
					isLocalParticipantTrack={localParticipantTrack}
					isScreenShare={track?.type === ScreenShare}
					isPortrait={isPortrait || isFitScreen}
					autoPlay
					playsInline
				/>
				{(isPortrait || isFitScreen) && (
					<StyledVideo
						{...htmlAttributes}
						ref={blurredVideoRef}
						isBlurred={true}
						autoPlay
						playsInline
						onDoubleClick={null}
						onMouseDown={null}
						onMouseMove={null}
						onMouseUp={null}
						onMouseLeave={null}
					/>
				)}
			</>
		); /* playsInline is put for Safari in iOS */
	}
);
ParticipantVideo.displayName = 'ParticipantVideo';

export default ParticipantVideo;
