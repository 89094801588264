import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import sq from 'date-fns/locale/sq/index.js';
import en from 'date-fns/locale/en-US/index.js';
import translate from 'i18n-translations/translate.jsx';
import { Form, Input, Modal, Select } from 'components';
import { MeasurementTypes } from 'constants/enums.js';

const PatientMeasurements = props => {
	registerLocale('en-US', en);
	registerLocale('sq', sq);
	const locale = useSelector(state => state.language.locale);
	const intl = useIntl();

	return (
		<Modal
			display={props.display}
			position='center'
			onModalClose={props.onModalClose}
			onModalSubmit={props.onModalSubmit}
			primaryButtonLabel={translate('add')}
			className={`add-measurement-popup ${props.measurement.type}`}
			isSubmitDisabled={props.isSubmitDisabled}>
			<Form title={translate('addDataManually')}>
				<div className='input add-measurement'>
					<p className='label'>{props.isSleep || props.isStep ? translate('startDate') : translate('dateAndTime')}</p>
					<DatePicker
						showTimeSelect
						selected={props.measurementDate}
						onChange={props.handleMeasurementDateChange}
						maxDate={new Date()}
						minTime={props.minTime}
						maxTime={props.maxTime}
						dateFormat='MMMM d, yyyy h:mm aa'
						popperPlacement='bottom-start'
						locale={locale}
					/>
					{props.isSleep && props.isSubmitDisabled && !props.isTimeValid && <span className='red-error'>{translate('twentyHrsBetween')}</span>}
				</div>
				{(props.isSleep || props.isStep) && (
					<div className='input add-measurement'>
						<p className='label'>{translate('endDate')}</p>
						<DatePicker
							showTimeSelect
							selected={props.measurementEndDate}
							onChange={props.handleDateChange}
							maxDate={new Date()}
							minDate={new Date(props.measurementDate)}
							minTime={new Date(props.measurementDate)}
							maxTime={new Date()}
							dateFormat='MMMM d, yyyy h:mm aa'
							popperPlacement='bottom-start'
							locale={locale}
						/>
					</div>
				)}
				{!props.isSleep && !props.isFromVitalSigns && (
					<Input
						onKeyDown={props.handleOnKeyDown}
						onChange={props.handleOnChange}
						name='measurementValue'
						label={intl.formatMessage({ id: 'inputValue' })}
						type='number'
						value={props.measurementValue}
						validationOptions={{}}
						bottomSpace='15px'
						placeholder={`${props.measurement.minimumValue} - ${props.measurement.maximumValue}`}
						description={props.getMeasurementDescription()}
						maxLength={255}
					/>
				)}
				{!props.isSleep && !props.measurement.isDecimal && (
					<Input
						onKeyDown={props.handleOnKeyDown}
						onChange={props.handleOnChange}
						name='measurementValue'
						label={intl.formatMessage({ id: 'inputValue' })}
						type='number'
						value={props.measurementValue}
						validationOptions={{}}
						bottomSpace='15px'
						placeholder={`${props.measurement.minimumValue} - ${props.measurement.maximumValue}`}
						description={props.getMeasurementDescription()}
						maxLength={255}
						min={props.measurement.minimumValue}
						max={props.measurement.maximumValue}
					/>
				)}
				{props.isFromVitalSigns && props.measurement.isDecimal && props.measurement.type !== MeasurementTypes.SLEEP && (
					<Input
						onChange={props.handleOnChange}
						name='measurementValue'
						label={intl.formatMessage({ id: 'inputValue' })}
						type='number'
						value={props.measurementValue}
						validationOptions={{}}
						bottomSpace='15px'
						placeholder={`${props.measurement.minimumValue} - ${props.measurement.maximumValue}`}
						description={props.getMeasurementDescription()}
						maxLength={255}
						min={props.measurement.minimumValue}
						max={props.measurement.maximumValue}
					/>
				)}
				{props.isSleep && (
					<Select
						onSelect={props.changeHealthSystem}
						value={props.selectedSleepValue}
						textField='label'
						valueField='value'
						items={[
							{ value: 'In Bed', label: intl.formatMessage({ id: 'inBed' }) },
							{ value: 'Asleep', label: intl.formatMessage({ id: 'asleep' }) },
						]}
						description='Type'
						placeholder={intl.formatMessage({ id: 'select' })}
						className='full-width'
					/>
				)}
				{props.isBloodPressure && (
					<Input
						onKeyDown={props.handleOnKeyDown}
						onChange={props.handleOnChange}
						name='measurementValueSecond'
						type='number'
						value={props.measurementValueSecond}
						validationOptions={{}}
						bottomSpace='15px'
						placeholder={`${props.measurement.minimumValueSecond} - ${props.measurement.maximumValueSecond}`}
						description={props.isBloodPressure ? translate('diastolic') : props.measurement.unit}
						maxLength={255}
						min={props.measurement.minimumValueSecond}
						max={props.measurement.maximumValueSecond}
					/>
				)}
			</Form>
		</Modal>
	);
};
export default PatientMeasurements;
