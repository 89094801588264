import { Formik } from 'formik';
import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { addTeamConfigurationProfile, updateTeamConfigurationProfile } from 'api/teamConfigurationProfiles.js';
import { Alert, Form, Input, Modal } from 'components';
import { TeamTypes, UserRoles } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import { getUserRole } from 'infrastructure/auth.js';
import { IntegrationTypesSettings, TeamConfigurationIntegrationTypes } from 'constants/configurationEnums.js';
import { getConfigurationValue, getIntegrationTypes } from 'infrastructure/helpers/commonHelpers.js';
import { Buffer } from 'buffer';

const HealthSystemConfigForm = props => {
	const [error, setError] = useState(null);
	const [currentIntegrationTypeId, setCurrentIntegrationTypeId] = useState(0);
	const [integrationTypes, setIntegrationTypes] = useState([]);
	const intl = useIntl();
	const translator = id => intl.formatMessage({ id });
	const healthSystemsArray = useSelector(state => state.healthSystems.allHealthSystems);
	const companyConfigurations = useSelector(state => state.company.companySettings?.companyConfigurations);

	useEffect(() => {
		const filteredIntegrationTypes = [
			IntegrationTypesSettings.TELEHEALTH,
			IntegrationTypesSettings.EVIDEON,
			IntegrationTypesSettings.PCARE,
			IntegrationTypesSettings.GET_WELL,
			IntegrationTypesSettings.SONIFI,
		];

		const filteredArray = filteredIntegrationTypes.filter(configId => getConfigurationValue(companyConfigurations[configId]));
		const enabledIntegrationTypes = getIntegrationTypes(filteredArray);
		setIntegrationTypes(enabledIntegrationTypes);
	}, [companyConfigurations]);

	const { EVIDEON, TELEHEALTH, PCARE, GET_WELL, SONIFI } = TeamConfigurationIntegrationTypes;

	const getInitialValues = () => {
		if (props.initialValues) {
			const { team, id, integrationType, secret, apiKey } = props.initialValues;
			let secretKey = '';
			let userName = '';
			let clientId;
			let clientSecret;
			const isBase64 = /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/;
			if (
				integrationType?.value === PCARE ||
				(integrationType?.value === GET_WELL &&
					secret.match(isBase64) &&
					Buffer.from(secret, 'base64').toString('utf8').split(':').length > 1)
			) {
				if (integrationType?.value === GET_WELL) {
					const secretDecoded = Buffer.from(secret, 'base64').toString('utf8').split(':');
					clientId = secretDecoded.at(0);
					clientSecret = secretDecoded.at(1);
				} else {
					[, secretKey] = Buffer.from(secret, 'base64').toString('utf8').split(':');
					[userName] = Buffer.from(secret, 'base64').toString('utf8').split(':');
				}
			}
			if (integrationType?.value === SONIFI) {
				clientId = apiKey;
				clientSecret = secret;
			} else {
				secretKey = secret;
				userName = '';
			}
			return {
				...props.initialValues,
				selectedHealthSystem: {
					value: getUserRole() === UserRoles.SUPER_USER ? healthSystemsArray[0].id : team.id,
					label: getUserRole() === UserRoles.SUPER_USER ? healthSystemsArray[0].name : team.name,
				},
				selectedId: id,
				secret: secretKey,
				...(secretKey && { maskedSecret: `${secretKey.slice(0, 3)}************` }),
				isSecretChanged: false,
				isEdit: true,
				userName,
				clientId,
				clientSecret,
			};
		}
		return {
			healthSystems: props.healthSystems,
			selectedHealthSystem: null,
			integrationType: integrationTypes[0],
			selectedId: null,
			profileName: '',
			url: '',
			port: 0,
			secret: '',
			apiKey: '',
			siteId: '',
			userName: '',
			isEdit: false,
		};
	};

	const addDeviceConfigurations = async values => {
		const changedValues = props.initialValues?.integrationType?.value !== values.integrationType?.value;
		props.setIsFormLoading(true);
		const params = {
			integrationTypeId: values.integrationType.value,
			profileName: values.profileName,
			url: values.url,
			userName: values.userName,
			...(changedValues && ![TELEHEALTH, PCARE, GET_WELL].includes(currentIntegrationTypeId)
				? { port: 0, secret: null }
				: {
						port: parseInt(values.port, 10),
						secret:
							currentIntegrationTypeId === PCARE
								? Buffer.from(`${values.userName}:${values.secret}`, 'utf8').toString('base64')
								: values.secret,
				  }),
			...(changedValues && { apiKey: currentIntegrationTypeId === EVIDEON ? values.apiKey : null }),
			...(changedValues && { siteId: [EVIDEON, PCARE].includes(currentIntegrationTypeId) ? values.siteId : null }),
			...(changedValues && currentIntegrationTypeId === PCARE && { port: 0 }),
			...((changedValues || props.initialValues) &&
				(currentIntegrationTypeId === GET_WELL || props.initialValues?.integrationTypeId === GET_WELL) && {
					siteId: values.siteId,
					secret: Buffer.from(`${values.clientId}:${values.clientSecret}`, 'utf8').toString('base64'),
				}),
			...((changedValues || props.initialValues) &&
				(currentIntegrationTypeId === SONIFI || props.initialValues?.integrationTypeId === SONIFI) && {
					siteId: values.siteId,
					apiKey: values.clientId,
					secret: values.clientSecret,
				}),
		};
		const response = !props.initialValues
			? await addTeamConfigurationProfile(values.selectedHealthSystem.value, TeamTypes.HEALTH_SYSTEM, params)
			: await updateTeamConfigurationProfile(
					values.selectedHealthSystem.value,
					TeamTypes.HEALTH_SYSTEM,
					values.selectedId,
					params
			  );
		if (!response.hasSucceeded || response.error) {
			props.setIsFormLoading(false);
			setError(response.error.message);
		}
		props.setIsFormLoading(false);
		props.toggleModal();
		props.getConfigurations();
	};

	const onCloseModal = resetForm => {
		resetForm();
		props.toggleModal();
	};

	const getValidationSchema = () => {
		const validation = {};
		validation.selectedHealthSystem = Yup.object().nullable().required(translator('pleaseSelectHealthSystem'));
		validation.profileName = Yup.string().required(translator('pleaseSelectProfileName'));
		validation.url = Yup.string()
			.required(translator('pleaseSetURL'))
			.max(63, `${intl.formatMessage({ id: 'maxLengthIs' })} 63`);
		validation.secret = Yup.string()
			.when('integrationType.value', {
				is: TELEHEALTH,
				then: schema => schema.required(translator('pleaseSetSecret')),
			})
			.when('integrationType.value', {
				is: PCARE,
				then: schema => schema.required(translator('pleaseSetPassword')),
			});
		validation.port = Yup.number().when('integrationType.value', {
			is: TELEHEALTH,
			then: schema => schema.required(translator('portValidation')).min(1, translator('portValidation')),
		});
		validation.apiKey = Yup.string().when('integrationType.value', {
			is: EVIDEON,
			then: schema => schema.required(translator('pleaseSetApiKey')),
		});
		validation.siteId = Yup.string().when('integrationType.value', {
			is: EVIDEON,
			then: schema => schema.required(translator('pleaseSetSiteId')),
		});
		validation.userName = Yup.string().when('integrationType.value', {
			is: PCARE,
			then: schema => schema.required(translator('pleaseSetUserName')),
		});
		validation.clientId = Yup.string().when('integrationType.value', {
			is: GET_WELL,
			then: schema => schema.required(translator('pleaseSetClientId')),
		});
		validation.clientId = Yup.string().when('integrationType.value', {
			is: SONIFI,
			then: schema => schema.required(translator('pleaseSetClientId')),
		});
		validation.clientSecret = Yup.string().when('integrationType.value', {
			is: GET_WELL,
			then: schema => schema.required(translator('pleaseSetSecret')),
		});
		validation.clientSecret = Yup.string().when('integrationType.value', {
			is: SONIFI,
			then: schema => schema.required(translator('pleaseSetSecret')),
		});
		validation.siteId = Yup.string().when('integrationType.value', {
			is: GET_WELL,
			then: schema => schema.required(translator('pleaseSetSiteId')),
		});
		validation.siteId = Yup.string().when('integrationType.value', {
			is: SONIFI,
			then: schema => schema.required(translator('pleaseSetSiteId')),
		});
		validation.siteId = Yup.string().when('integrationType.value', {
			is: PCARE,
			then: schema => schema.required(translator('pleaseSetSiteId')),
		});
		return validation;
	};

	const onFormHealthSystemSelect = async (values, setFieldValue) => {
		if (values.isEdit) {
			return;
		}
		const selectedHS = props.healthSystems.reduce((acc, item) => {
			const mapped = {
				label: item.name,
				value: item.id,
			};
			if (item.id === values.value) {
				return mapped;
			}
			return acc;
		}, null);
		setFieldValue('selectedHealthSystem', selectedHS);
	};

	const handleSecretKey = (e, values, setFieldValue) => {
		setFieldValue('isSecretChanged', true);
		if (values.maskedSecret) {
			setFieldValue('secret', '');
			setFieldValue('maskedSecret', '');
		} else {
			setFieldValue('secret', e.target.value);
		}
	};

	return (
		<>
			{integrationTypes.length > 0 && (
				<Formik
					enableReinitialize={true}
					initialValues={getInitialValues()}
					validationSchema={Yup.object().shape(getValidationSchema())}
					onSubmit={(values, { resetForm }) => {
						addDeviceConfigurations(values);
						resetForm({ values: '' });
					}}>
					{formikProps => {
						const { values, errors, handleSubmit, handleChange, resetForm, setFieldValue } = formikProps;
						return (
							<Modal
								modalSelector='deviceConfigurationsModal'
								className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal'
								display={props.isModalOpen}
								position='center'
								onModalSubmit={handleSubmit}
								onModalClose={() => onCloseModal(resetForm)}
								isLoading={props.isFormLoading}
								submitImgIcon={`${healthCareCdnUrl}appointments/save-icon.svg`}
								closeImgIcon={`${healthCareCdnUrl}appointments/cancel-appointment.svg`}
								shouldSubmitOnEnter={false}>
								<Form
									title={intl.formatMessage({ id: 'healthSystem' })}
									onSubmit={event => event.preventDefault()}
									height={600}
									className='manage-hs-form'>
									{!props.initialValues && (
										<div className='input'>
											<p className='label'>{translate('selectHealthSystem')}</p>
											<p className='font-14'>{translate('selectHSForConfiguration')}</p>
											<Select
												value={values.selectedHealthSystem}
												placeholder={intl.formatMessage({ id: 'selectHealthSystem' })}
												classNamePrefix='react-select'
												options={props.transformArray(props.healthSystems)}
												onChange={event => onFormHealthSystemSelect(event, setFieldValue)}
											/>
											<small>{errors.selectedHealthSystem}</small>
										</div>
									)}
									<div className='input'>
										<p className='label'>{translate('selectIntegrationType')}</p>
										<Select
											value={values.integrationType}
											placeholder={intl.formatMessage({ id: 'selectIntegrationType' })}
											classNamePrefix='react-select'
											options={integrationTypes}
											onChange={event => {
												setFieldValue('integrationType', event);
												setCurrentIntegrationTypeId(event.value);
											}}
										/>
									</div>
									<Input
										type='text'
										label={translate('setProfileName')}
										name='profileName'
										placeholder={intl.formatMessage({ id: 'profileName' })}
										value={values.profileName}
										onChange={handleChange}
										validationOptions={{}}
										error={errors.profileName}
										bottomSpace='20px'
									/>
									{values.integrationType.value === PCARE && (
										<Input
											type='text'
											label={translate('userName')}
											name='userName'
											placeholder={intl.formatMessage({ id: 'userName' })}
											value={values.userName}
											onChange={handleChange}
											validationOptions={{}}
											autoComplete='off'
											error={errors.userName}
											bottomSpace='20px'
										/>
									)}
									<Input
										type='text'
										label={translate('setURL')}
										name='url'
										placeholder='URL'
										value={values.url}
										onChange={handleChange}
										validationOptions={{}}
										error={errors.url}
										bottomSpace='20px'
									/>
									{[GET_WELL, SONIFI].includes(values.integrationType.value) && (
										<>
											<Input
												type='text'
												label={translate('setSiteId')}
												name='siteId'
												placeholder={intl.formatMessage({ id: 'siteId' })}
												value={values.siteId}
												onChange={handleChange}
												validationOptions={{}}
												autoComplete='off'
												error={errors.siteId}
												bottomSpace='20px'
											/>
											<Input
												type='text'
												label={translate('clientId')}
												name='clientId'
												placeholder={intl.formatMessage({ id: 'clientId' })}
												value={values.clientId}
												onChange={handleChange}
												validationOptions={{}}
												autoComplete='off'
												error={errors.clientId}
												bottomSpace='20px'
											/>
											<Input
												type='password'
												label={translate('clientSecret')}
												name='clientSecret'
												placeholder={intl.formatMessage({ id: 'clientSecret' })}
												value={values.clientSecret}
												onChange={handleChange}
												validationOptions={{}}
												autoComplete='off'
												error={errors.clientSecret}
												bottomSpace='20px'
											/>
										</>
									)}
									{values.integrationType.value === PCARE && (
										<Input
											type='text'
											label={translate('setSiteId')}
											name='siteId'
											placeholder={intl.formatMessage({ id: 'setSiteId' })}
											value={values.siteId}
											onChange={handleChange}
											validationOptions={{}}
											autoComplete='off'
											error={errors.siteId}
											bottomSpace='20px'
										/>
									)}
									{values.integrationType.value === TELEHEALTH && (
										<Input
											type='number'
											label={translate('setPort')}
											name='port'
											placeholder={intl.formatMessage({ id: 'setPort' })}
											value={values.port}
											onChange={handleChange}
											validationOptions={{}}
											autoComplete='off'
											error={errors.port}
											bottomSpace='20px'
										/>
									)}
									{[TELEHEALTH, PCARE].includes(values.integrationType.value) && (
										<Input
											type={values.isEdit && !values.isSecretChanged ? 'text' : 'password'}
											label={translate(values.integrationType.value === PCARE ? 'password' : 'setSecret')}
											autoComplete='off'
											name='secret'
											placeholder={intl.formatMessage({ id: values.integrationType.value === PCARE ? 'password' : 'secret' })}
											value={values.isEdit && !values.isSecretChanged ? values.maskedSecret : values.secret}
											onChange={e => handleSecretKey(e, values, setFieldValue)}
											validationOptions={{}}
											error={errors.secret}
											bottomSpace='20px'
										/>
									)}
									{values.integrationType.value === EVIDEON && (
										<>
											<Input
												type='text'
												label={translate('setSiteId')}
												name='siteId'
												placeholder={intl.formatMessage({ id: 'siteId' })}
												value={values.siteId}
												onChange={handleChange}
												validationOptions={{}}
												autoComplete='off'
												error={errors.siteId}
												bottomSpace='20px'
											/>
											<Input
												type='text'
												label={translate('setAPIKey')}
												autoComplete='off'
												name='apiKey'
												placeholder={intl.formatMessage({ id: 'apiKey' })}
												value={values.apiKey}
												onChange={handleChange}
												validationOptions={{}}
												error={errors.apiKey}
												bottomSpace='20px'
											/>
										</>
									)}
								</Form>
							</Modal>
						);
					}}
				</Formik>
			)}

			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};

export default HealthSystemConfigForm;
