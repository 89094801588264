import React, { useState, useEffect, useContext } from 'react';
import { ChatOptionsViews } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { getFormattedDate } from 'infrastructure/helpers/dateHelper.js';
import Modal from 'components/Modal.jsx';
import Form from 'components/Form.jsx';
import { getConversationAttachments, deleteMessage } from 'api/messenger.js';
import translate from 'i18n-translations/translate.jsx';
import { EmptyState } from 'components';
import PopUpAlert from 'components/PopUpAlert.jsx';
import SocketEvents from 'constants/socket-events.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';

const Attachments = props => {
	const [attachments, setAttachments] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [selectedMessageId, setSelectedMessageId] = useState(null);
	const [error, setError] = useState(null);
	const socket = useContext(SocketContext);

	useEffect(() => {
		const fetchAttachments = async () => {
			const response = await getConversationAttachments(props.conversationId, {
				getDocuments: true,
				getMedia: false,
				latest: null,
				limit: 20,
			});
			if (response.error) {
				setIsLoading(false);
				setError(response.error.message);
			} else {
				setAttachments(response.attachments);
				setIsLoading(false);
			}
		};
		fetchAttachments();
		const handleNewMessage = ({ conversationId, message }) => {
			if (props.conversationId === conversationId && message?.attachments?.length > 0) {
				fetchAttachments();
			}
		};
		socket.on(SocketEvents.Conversation.ON_NEW_MESSAGE, handleNewMessage);
		return () => {
			socket.off(SocketEvents.Conversation.ON_NEW_MESSAGE, handleNewMessage);
		};
	}, [props.conversationId, socket]);

	const handleDeleteFile = async () => {
		if (selectedMessageId) {
			const response = await deleteMessage(selectedMessageId, false);
			if (!response.error) {
				setAttachments(prevState => prevState.filter(item => item.messageId !== selectedMessageId));
				setSelectedMessageId(null);
			}
		}
	};

	return (
		<>
			<div className='chat-options-wrapper position-relative'>
				<div className='flex'>
					<div className='cursor-pointer' onClick={() => props.setDefaultOptions(ChatOptionsViews.DEFAULT)}>
						<img src={`${healthCareCdnUrl}chat/back.svg`} alt='back arrow icon' />
					</div>
					<div>
						<p>{translate('attachments')}</p>
						<p>{translate('sharedAttachments')}</p>
					</div>
				</div>
				{attachments.length > 0 && (
					<div className='attachments-list'>
						<div>
							{/* <p>Today</p> */}
							<div className='attachments-list'>
								{attachments.map(attachment => (
									<div key={attachment.id} className='flex flex-align-center'>
										<img src={`${healthCareCdnUrl}chat/word.svg`} alt='word icon' />
										<div>
											<p>{attachment.originalName}</p>
											<div>
												<a href={attachment.url}>{getFormattedDate(attachment.dateCreated)}</a>
												<i className='material-icons-outlined' onClick={() => setSelectedMessageId(attachment.messageId)}>
													delete
												</i>
												{selectedMessageId}
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				)}
				{attachments.length === 0 && !isLoading && (
					<div className='empty-state-wrapper-percent'>
						<EmptyState title={translate('noData')} image='no-files.svg' />
					</div>
				)}
			</div>
			{selectedMessageId && (
				<Modal
					modalSelector='deleteAttachmentModal'
					display={true}
					position='center'
					isLoading={false}
					onModalClose={() => setSelectedMessageId(null)}
					onModalSubmit={handleDeleteFile}
					primaryButtonLabel={translate('delete')}
					className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal admin-delete-modal'
					submitImgIcon={`${healthCareCdnUrl}appointments/save-icon.svg`}
					closeImgIcon={`${healthCareCdnUrl}appointments/cancel-appointment.svg`}>
					<Form title={translate('deleteMsgTitle')} height={225}>
						<p className='modal-paragraph'>{translate('deleteMsgWarning')}</p>
						<p className='modal-paragraph'>{translate('actionUndone')}</p>
					</Form>
				</Modal>
			)}
			<PopUpAlert display={error} onAlertClose={() => setError(null)} contentText={error} isSilent={true} center={true} />
		</>
	);
};

export default Attachments;
