import React, { useEffect, useState, useContext, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { enums } from '@solaborate/calls';
import FormInput from 'components/FormInput.jsx';
import ProfilePicture from 'components/ProfilePicture.jsx';
import {
	MeasurementTypes,
	SortByNameValues,
	Gender,
	PatientRpmStatus,
	RiskLevels,
	EWSType,
	RiskLevelValues,
} from 'constants/enums.js';
import { getRecent } from 'api/messenger.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { getUserId, getUserRole } from 'infrastructure/auth.js';
import { getUserMeasurementUnits } from 'api/measurements.js';
import { convertMeasurementTypes } from 'infrastructure/helpers/measurementsHelper.js';
import { getConfigurationValue, getGender, skipDuplicatedObjects } from 'infrastructure/helpers/commonHelpers.js';
import translate from 'i18n-translations/translate.jsx';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import Grid from 'components/Grid.jsx';
import Loader from 'components/Loader.jsx';
import SocketEvents from 'constants/socket-events.js';
import { defaultDateFormat, monthDayYear } from 'infrastructure/helpers/dateHelper.js';
import Alert from 'components/Alert.jsx';
import AlertsModal from 'containers/VitalSignsMonitoring/AlertsModal.jsx';
import { StartQueryStringKeys, UserRoles } from 'calls/enums/index.js';
import { getPatientByUserId } from 'api/patients.js';
import { getCountries } from 'api/users.js';
import ViewPatientProfile from 'containers/CheckInAPatient/ViewPatientProfile.jsx';
import { getPatientsCareTeam, getUsersRpmList, getEWSSettings, updateEWSSettings } from 'api/rpm.js';
import { RpmTableHeaders, VitalSignsList } from 'constants/rpm.js';
import Form from 'components/Form.jsx';
import Modal from 'components/Modal.jsx';
import CareLogs from 'containers/Rpm/CareLogs.jsx';
import Survey from 'containers/Rpm/Survey.jsx';
import EditPatientRpmProgram from 'containers/Rpm/EditPatientRpmProgram.jsx';
import {
	getBatteryColorBasedOnScore,
	getImageBasedOnScore,
	getRadioButtonOptions,
	getScoreRisk,
	getScoreRiskClassName,
	getScoreRiskInnerClassName,
	getVitalSignsScoreClassName,
} from 'infrastructure/helpers/rpmHelper.js';
import RpmDropDownOptions from 'containers/Rpm/RpmDropDownOptions.jsx';
import TodaysMedication from 'containers/Rpm/TodaysMedication.jsx';
import _ from 'lodash';
import { GeneralSettings, PatientTabs } from 'constants/configurationEnums.js';
import RemoveInactivePatient from 'containers/Rpm/RemoveInactivePatient.jsx';
import { getAdminGeneralSettingConfigValue } from 'infrastructure/helpers/configurationsHelpers.js';

const RemotePatientMonitoring = () => {
	const [searchBox, setSearchBox] = useState('');
	const [errorApiResponse, setErrorApiResponse] = useState(null);
	const [rpmPatients, setRpmPatients] = useState([]);
	const [totalCount, setTotalCount] = useState(0);
	const [sortByNameValue, setSortByNameValue] = useState(null);
	const [preferredUnits, setPreferredUnits] = useState([]);
	const [isViewProfileModalVisible, setViewProfileModalVisibility] = useState(false);
	const [selectedPatient, setSelectedPatient] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [conversations, setConversations] = useState([]);
	const [isAlertsModalOpen, setAlertsModalVisibility] = useState(false);
	const [patientDetails, setPatientDetails] = useState(null);
	const [selectedCountry, setSelectedCountry] = useState(null);
	const [countries, setCountries] = useState([]);
	const userSession = useSelector(state => state.user.userSession);
	const [careTeamForPatient, setCareTeamForPatient] = useState(null);
	const [modalPageIndex, setModalPageIndex] = useState(0);
	const [hasModalReachedEnd, setHasModalReachedEnd] = useState(false);
	const [shouldShowCareLogs, setShouldShowCareLogs] = useState(false);
	const [shouldShowEWSSettings, setShouldShowEWSSettings] = useState(false);
	const [levelOfConsciousness, setLevelOfConsciousness] = useState(null);
	const [supplementOxygen, setSupplementOxygen] = useState(null);
	const [riskType, setRiskType] = useState(null);
	const [lowScore, setLowScore] = useState(0);
	const [mediumScore, setMediumScore] = useState(0);
	const [highScore, setHighScore] = useState(0);
	const [shouldShowSurvey, setShouldShowSurvey] = useState(false);
	const [pageIndex, setPageIndex] = useState(0);
	const [shouldShowRpmProgram, setShouldShowRpmProgram] = useState(false);
	const [patientRpmProgram, setPatientRpmProgram] = useState(null);
	const [shouldShowMedication, setShouldShowMedication] = useState(false);
	const [shouldExcludeCpm, setShouldExcludeCpm] = useState(false);
	const [inactivePatient, setInactivePatient] = useState(null);
	const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
	const socket = useContext(SocketContext);
	const hasReachedEnd = useRef(null);
	const userId = getUserId();
	const intl = useIntl();
	const isDarkMode = useSelector(state => state.user.darkMode);
	const healthSystemConfigurations = useSelector(state => state.configurations.healthSystemConfigurations);
	const adminConfigurableMenu = useSelector(state => state.configurations.adminConfigurableMenu);

	const measurementTypes = [
		MeasurementTypes.BLOOD_PRESSURE,
		MeasurementTypes.HEART_RATE,
		MeasurementTypes.OXYGEN,
		MeasurementTypes.DIABETES,
		MeasurementTypes.TEMPERATURE,
		MeasurementTypes.WEIGHT,
	];

	const getCategoryPreference = useCallback(
		categoryId => preferredUnits.find(item => item.unitCategoryId === categoryId),
		[preferredUnits]
	);

	const getPatientDetails = async patientId => {
		if (!patientId) {
			return;
		}
		const [patientProfileDetails, countriesList] = await Promise.all([getPatientByUserId(patientId), getCountries()]);
		const responseError = patientProfileDetails.error || countriesList.error;
		if (responseError) {
			return;
		}
		setSelectedCountry(countriesList.countries.find(item => item.code === patientProfileDetails.patient.profile?.country)?.name);
		setCountries(countriesList.countries);
		setPatientDetails(patientProfileDetails.patient);
		setViewProfileModalVisibility(true);
	};

	const getUnitPreference = useCallback(
		categoryId => {
			const selectedPreference = getCategoryPreference(categoryId);
			return selectedPreference?.options.find(item => item.unitSystemId === selectedPreference.unitSystemId);
		},
		[getCategoryPreference]
	);

	const handleNewMessage = async () => {
		const recentConversations = await getRecent(userId);
		if (recentConversations.error) {
			setIsLoading(false);
			return;
		}
		setConversations(recentConversations.conversations);
	};

	const sortByName = () => {
		if (!sortByNameValue || sortByNameValue === SortByNameValues.SORTING_ZA) {
			setSortByNameValue(SortByNameValues.SORTING_AZ);
			return;
		}
		setSortByNameValue(SortByNameValues.SORTING_ZA);
	};

	const getMeasurementValue = measurement =>
		!measurement.unitCategoryId
			? measurement.measurementValue
			: convertMeasurementTypes(
					measurement.unitCategoryId,
					measurement.measurementValue,
					getUnitPreference(measurement.unitCategoryId)?.unitSystemId
			  );

	const getVitalSignTableCell = (signs, type) => {
		const selectedVitalSign = signs.find(item => item.measurementType === type);
		const selectedBatteryLevel = selectedVitalSign?.device?.batteryLevel;
		return (
			<div
				className={classNames(
					'patient-alert-body-vs position-relative',
					getVitalSignsScoreClassName(selectedVitalSign?.earlyWarningScore, false)
				)}
				key={type}>
				<div className={getVitalSignsScoreClassName(selectedVitalSign?.earlyWarningScore, true)} />
				<div className='flex'>
					<img src={VitalSignsList.find(item => item.type === type).icon} alt='ico' />
					{!selectedVitalSign?.measurementValue && <p>N/A</p>}
					{selectedVitalSign && (
						<p>
							{getMeasurementValue(selectedVitalSign)}
							<span
								className={classNames(
									!getVitalSignsScoreClassName(selectedVitalSign?.earlyWarningScore, false) ? 'unit-wrapper' : ''
								)}>
								{selectedVitalSign.unitCategoryId
									? getUnitPreference(selectedVitalSign.unitCategoryId)?.unit
									: selectedVitalSign.measurementUnit}
							</span>
						</p>
					)}
				</div>
				<span>{selectedVitalSign?.date && defaultDateFormat(selectedVitalSign.date)}</span>
				{selectedBatteryLevel >= 0 && (
					<div className='battery-status-wrapper'>
						<img
							className={classNames(selectedVitalSign?.earlyWarningScore !== RiskLevelValues.NORMAL ? 'battery-img' : '')}
							src={`${healthCareCdnUrl}rpm/${getBatteryColorBasedOnScore(
								selectedVitalSign?.earlyWarningScore,
								selectedBatteryLevel
							)}.svg`}
							alt='Battery status'
						/>
						<span>{selectedBatteryLevel.toFixed()}%</span>
					</div>
				)}
			</div>
		);
	};

	const patientRedirect = async (type, patient) => {
		try {
			const queryParams = new URLSearchParams({
				[StartQueryStringKeys.OBJECT_ID]: patient.userId,
				[StartQueryStringKeys.OBJECT_TYPE]: enums.ObjectTypes.USER,
				[StartQueryStringKeys.CONFERENCE_NAME]: `${patient.firstName} ${patient.lastName}`,
				[StartQueryStringKeys.CALL_TYPE]: type,
			});
			window.open(`/call?${queryParams.toString()}`, '_blank');
		} catch (err) {
			setErrorApiResponse(`${intl.formatMessage({ id: 'anErrorOccurred' })}: ${err.message}`);
		}
	};

	const toggleViewProfileModal = patient => {
		getPatientDetails(patient.userId);
	};

	const removeFromRpm = patient => {
		setIsDeleteModalShown(true);
		setInactivePatient(patient);
	};

	const getUnreadMessages = patient => {
		const foundConversation = conversations.find(item => item.contact && item.contact.objectId === patient.userId.toString());
		return foundConversation && foundConversation.unreadMessagesCount > 0;
	};

	const toggleAlertsModal = patient => {
		setAlertsModalVisibility(prevState => !prevState);
		setSelectedPatient(patient);
	};

	useEffect(() => {
		const fetchData = async () => {
			const { error, unitPreferences } = await getUserMeasurementUnits(userId);
			if (error) {
				setErrorApiResponse(error.message);
			} else {
				setPreferredUnits(unitPreferences);
			}
		};
		fetchData();
	}, [userId]);

	const hasOnlyOneScoreOfThree = arr => {
		let scoreOfThreeCount = 0;
		const highScorePerVitalSign = 3;
		const result = arr.every(obj => {
			if (obj.earlyWarningScore === highScorePerVitalSign) {
				scoreOfThreeCount += 1;
			} else if (obj.earlyWarningScore && obj.earlyWarningScore !== 0) {
				return false;
			}

			return true;
		});

		return result && scoreOfThreeCount === 1;
	};

	const getRpmPatients = useCallback(async () => {
		const getFilterBasedOnScoreLength = (list, score) => list?.filter(item => getScoreRisk(item?.score) === score).length;
		if (searchBox?.length === 1) {
			return;
		}
		const response = await getUsersRpmList({
			userId: getUserId(),
			pageIndex,
			search: searchBox,
			shouldExcludeCpm,
		});
		if (response.error) {
			setErrorApiResponse(response.error.message);
		} else {
			const newArr = response.rpmListPatients.map(patient => ({
				...patient,
				score: patient.totalEarlyWarningScore,
				isRedScore: patient.vitalSigns?.length > 0 ? hasOnlyOneScoreOfThree(patient.vitalSigns) : false,
			}));
			setIsLoading(false);
			hasReachedEnd.current = response.rpmListPatients.length < 60;
			if (searchBox) {
				setRpmPatients(newArr);
				return;
			}
			const concatenatedArray = rpmPatients.concat(newArr);
			const uniqueArray = skipDuplicatedObjects(concatenatedArray, 'id');

			if (riskType) {
				const filteredBasedOnRiskType = uniqueArray.filter(item => getScoreRisk(item?.score) === riskType);
				setRpmPatients(filteredBasedOnRiskType);
				return;
			}
			setRpmPatients(shouldExcludeCpm ? newArr : uniqueArray);
			setHighScore(getFilterBasedOnScoreLength(uniqueArray, RiskLevels.HIGH));
			setMediumScore(getFilterBasedOnScoreLength(uniqueArray, RiskLevels.MODERATE));
			setLowScore(getFilterBasedOnScoreLength(uniqueArray, RiskLevels.LOW));
			setTotalCount(response.totalCount);
		}
	}, [pageIndex, searchBox, shouldExcludeCpm, riskType]);

	const handleRemovePatient = data => {
		const newPatientArray = rpmPatients.filter(patient => patient.patientId !== data.patientId);
		setRpmPatients(newPatientArray);
	};

	useEffect(() => {
		socket.on(SocketEvents.Conversation.ON_NEW_MESSAGE, handleNewMessage);
		socket.on(SocketEvents.HealthCare.MEASUREMENT_ADDED, getRpmPatients);
		socket.on(SocketEvents.HealthCare.ON_RPM_PROGRAM_UPDATED, getRpmPatients);
		socket.on(SocketEvents.HealthCare.ON_PATIENT_MISSED_MEDICATIONS, getRpmPatients);
		socket.on(SocketEvents.HealthCare.ON_PATIENT_RPM_PROGRAM_REMOVED, handleRemovePatient);

		return () => {
			socket.off(SocketEvents.Conversation.ON_NEW_MESSAGE, handleNewMessage);
			socket.off(SocketEvents.HealthCare.MEASUREMENT_ADDED, getRpmPatients);
			socket.off(SocketEvents.HealthCare.ON_RPM_PROGRAM_UPDATED, getRpmPatients);
			socket.off(SocketEvents.HealthCare.ON_PATIENT_MISSED_MEDICATIONS, getRpmPatients);
			socket.on(SocketEvents.HealthCare.ON_PATIENT_RPM_PROGRAM_REMOVED, handleRemovePatient);
		};
	}, [socket, getRpmPatients, handleRemovePatient]);

	useEffect(() => {
		const getRecentConversation = async () => {
			const recentConversations = await getRecent(userId);
			if (!recentConversations.error) {
				setConversations(recentConversations.conversations);
			}
			setIsLoading(false);
		};

		if (userSession.healthSystem.id) {
			getRpmPatients();
			getRecentConversation();
		}
	}, [getRpmPatients, userSession.healthSystem.id, userSession.regionId, pageIndex]);

	useEffect(() => {
		if (sortByNameValue) {
			const patientsList = _.cloneDeep(rpmPatients);

			if (sortByNameValue === SortByNameValues.SORTING_ZA) {
				patientsList.sort((a, b) => (a.firstName?.toUpperCase() > b.firstName?.toUpperCase() ? 1 : -1));
			} else {
				patientsList.sort((a, b) => (a.firstName?.toUpperCase() < b.firstName?.toUpperCase() ? 1 : -1));
			}
			setRpmPatients(patientsList);
		}
	}, [sortByNameValue, rpmPatients]);

	const getPatientCareTeam = async patientId => {
		const response = await getPatientsCareTeam({ patientId, pageIndex: modalPageIndex });
		if (response.error) {
			setErrorApiResponse(response.error.message);
			return;
		}
		const careTeamResponse = response.patientCareTeam.concat(response.rpmCareTeam);
		setCareTeamForPatient(careTeamResponse);
		setHasModalReachedEnd(careTeamResponse.length < 10);
	};

	const handOnModalScroll = event => {
		const isBottom = event.target.scrollHeight - Math.ceil(event.target.scrollTop) === event.target.clientHeight;
		if (isBottom && !hasModalReachedEnd) {
			setModalPageIndex(prevState => prevState + 1);
		}
	};

	const toggleCarelogs = patient => {
		setShouldShowCareLogs(prevState => !prevState);
		setSelectedPatient(patient);
	};

	const getScoreTableCell = patient => (
		<div
			className={classNames(
				'patient-alert-body-vs position-relative',
				`${getScoreRiskClassName(patient?.score, patient.isRedScore)}`,
				`${getScoreRisk(patient?.score) !== 'N/A' ? 'score' : ''}`
			)}>
			<div className={classNames(getScoreRiskInnerClassName(patient?.score, patient.isRedScore))} />
			<div className='flex'>
				<img src={getImageBasedOnScore(patient?.score, patient.isRedScore, isDarkMode)} alt='EWS icon' />
				<p>
					{patient?.score}
					{patient.isRedScore ? '*' : ''}
				</p>
				{getScoreRisk(patient?.score) === 'N/A' && <p>{translate(getScoreRisk(patient?.score))}</p>}
			</div>
			{getScoreRisk(patient?.score) !== 'N/A' && (
				<span>{`${intl.formatMessage({ id: getScoreRisk(patient?.score, patient.isRedScore) })} clinical risk`}</span>
			)}
		</div>
	);

	const toggleEwsSettings = patient => {
		if (!patient) {
			setShouldShowEWSSettings(false);
			return;
		}
		setShouldShowEWSSettings(prevState => !prevState);
		getEWSSetting(patient?.id);
		setSelectedPatient(patient);
	};

	const updateEWSSetting = async healthcareUserId => {
		const params = {
			levelOfConsciousness,
			supplementOxygen,
		};
		const response = await updateEWSSettings({ healthcareUserId, params });
		if (response.error) {
			setErrorApiResponse(response.error.message);
		} else {
			getRpmPatients();
		}
		toggleEwsSettings(null);
	};

	const getEWSSetting = async healthcareUserId => {
		const response = await getEWSSettings(healthcareUserId);
		if (response.error) {
			setErrorApiResponse(response.error.message);
			return;
		}
		setSupplementOxygen(response.ewsSettings.supplementOxygen);
		setLevelOfConsciousness(response.ewsSettings.levelOfConsciousness);
	};

	const sortRisk = patientsList => [...patientsList].sort((a, b) => (a.dateCreated > b.dateCreated ? -1 : 1));

	const filterDefault = () => {
		setRpmPatients(sortRisk(_.cloneDeep(rpmPatients)));
		setSortByNameValue(null);
		setRiskType(null);
	};

	const setEWSValues = (name, value) => {
		if (name === EWSType.SUPPLEMENT_OXYGEN) {
			setSupplementOxygen(value);
		}
		if (name === EWSType.LEVEL_OF_CONSCIOUSNESS) {
			setLevelOfConsciousness(value);
		}
	};

	const getRadioButtons = name =>
		getRadioButtonOptions(name).map(item => (
			<div className='flex position-relative consent-from-patient margin-right-m' key={item.id}>
				<label className='remember-me flex'>
					<input
						className='auto-width cursor-pointer'
						type='radio'
						value={item.value.toString()}
						onChange={() => setEWSValues(name, item.value)}
						onBlur={null}
						name={name}
						checked={
							(levelOfConsciousness === item.value && name === EWSType.LEVEL_OF_CONSCIOUSNESS) ||
							(supplementOxygen === item.value && name === EWSType.SUPPLEMENT_OXYGEN)
						}
					/>
					<div className='onb-custom-checkbox-wrapper add-patient-checkbox-wrapper ews-setting cursor-pointer'>
						<div className='onb-custom-checkbox' />
					</div>
					<p>{item.content}</p>
				</label>
			</div>
		));

	const handleScroll = event => {
		const isBottom = event.target.scrollHeight - Math.ceil(event.target.scrollTop) === event.target.clientHeight;
		if (isBottom && !hasReachedEnd.current && event.target.scrollTop !== 0) {
			setPageIndex(prevState => prevState + 1);
		}
	};

	const toggleSurvey = patient => {
		setShouldShowSurvey(prevState => !prevState);
		setSelectedPatient(patient);
	};

	const toggleRpmProgram = patient => {
		setShouldShowRpmProgram(prevState => !prevState);
		setSelectedPatient(patient);
		if (patient?.rpmProgram) {
			setPatientRpmProgram({ value: patient.rpmProgram?.id, label: patient.rpmProgram?.name });
		}
	};

	const toggleMedicationModal = patient => {
		setShouldShowMedication(prevState => !prevState);
		setSelectedPatient(patient);
	};

	return (
		<>
			{isLoading && (
				<Grid width='100%' stretch='100vh' vertAlign='center' horizAlign='center' rows='auto' className='rpm-wrapper'>
					<Loader />
				</Grid>
			)}
			{!isLoading && !shouldShowCareLogs && (
				<>
					<div className='patient-alerts-wrapper rpm full-width rpm-wrapper' onScroll={handleScroll}>
						<div className='flex patient-risk-grid'>
							<div onClick={filterDefault} className='cursor-pointer'>
								<p>{translate('numberOfPatients')}</p>
								<h1>{totalCount}</h1>
							</div>
							<div onClick={() => setRiskType(RiskLevels.HIGH)} className='cursor-pointer'>
								<p>{translate('highRisk')}</p>
								<h1>{highScore}</h1>
							</div>
							<div onClick={() => setRiskType(RiskLevels.MODERATE)} className='cursor-pointer'>
								<p>{translate('moderateRisk')}</p>
								<h1>{mediumScore}</h1>
							</div>
							<div onClick={() => setRiskType(RiskLevels.LOW)} className='cursor-pointer'>
								<p>{translate('lowRisk')}</p>
								<h1>{lowScore}</h1>
							</div>
						</div>
						<div className='flex flex-align-center flex-space-between full-width patients-alert-filter'>
							<FormInput
								id='searchBox'
								labelClassName='full-width right-15'
								name='searchBox'
								text=''
								type='search'
								onChange={event => {
									setSearchBox(event.target.value);
									setPageIndex(0);
								}}
								value={searchBox}
								placeholder={intl.formatMessage({ id: 'searchByName' })}
								className='full-width'
							/>
							<div onClick={() => setShouldExcludeCpm(prevState => !prevState)} className='cursor-pointer'>
								<input
									className='cursor-pointer'
									type='checkbox'
									checked={shouldExcludeCpm}
									name='shouldExcludeCpm'
									onChange={() => null}
								/>
								<span className='font-14 left-margin-s'>{translate('excludeCpmPatients')}</span>
							</div>
						</div>
						<div className='patients-alert-table'>
							<div className='flex patients-alert-table-header'>
								<div className='flex'>
									<p>{translate('patient')}</p>
									<i
										className={`material-icons${sortByNameValue === SortByNameValues.SORTING_ZA ? ' rotate' : ''}`}
										onClick={sortByName}>
										arrow_downward
									</i>
								</div>
								{RpmTableHeaders.map(item => (
									<div key={item}>
										<p>{translate(item)}</p>
									</div>
								))}
							</div>
							<div className='patients-alert-table-body'>
								{rpmPatients.map(patient => (
									<div key={patient.userId} className='flex full-width'>
										<div
											className={classNames(
												'flex position-relative',
												patient.patientRpmStatus === PatientRpmStatus.INACTIVE ? 'patient-disabled' : ''
											)}>
											<ProfilePicture
												className='doctor-request-img'
												fullName={`${patient.firstName} ${patient.lastName}`}
												profilePicture={patient.profilePicture}
											/>
											{patient.hasMissedMedications && (
												<div
													className='warning-alert-icon'
													data-tooltip={intl.formatMessage({ id: 'patientSkippedMedication' })}
													data-position='right'>
													<img src={`${healthCareCdnUrl}rpm/warning-alert.svg`} alt='warning-alert' />
												</div>
											)}
											<div>
												<h4>
													{patient.firstName} {patient.lastName}
												</h4>
												<span>
													{patient.genderId === Gender.UNAVAILABLE
														? `${intl.formatMessage({ id: 'sex' })}: N/A`
														: getGender(patient.genderId).description}
												</span>
												<p>
													<span>DOB: {patient.dateOfBirth ? monthDayYear(patient.dateOfBirth) : 'N/A'}</span>
												</p>
												<p>
													<span className='mrn'>MRN: {patient.mrn || 'N/A'}</span>
												</p>
												<p>
													<span>RPM: {patient.rpmProgram?.name || 'N/A'}</span>
												</p>
											</div>
										</div>
										{getScoreTableCell(patient)}
										{measurementTypes.map(type => getVitalSignTableCell(patient.vitalSigns, type))}
										<div
											className={classNames(
												'flex position-relative',
												patient.patientRpmStatus === PatientRpmStatus.INACTIVE ? 'rpm-inactive-options' : ''
											)}>
											<div className='flex patient-info-call'>
												<div className='patient-info-audio' onClick={() => patientRedirect(enums.CallTypes.AUDIO, patient)} />
												<div className='patient-info-video' onClick={() => patientRedirect(enums.CallTypes.VIDEO, patient)} />
												{getUserRole() === UserRoles.DOCTOR &&
													getAdminGeneralSettingConfigValue(adminConfigurableMenu, GeneralSettings.ConversationHistory) && (
														<div className='patient-info-open-convo'>
															<Link
																to={{
																	pathname: `/patients/${patient.userId}/conversation`,
																}}
															/>
															{getUnreadMessages(patient) && (
																<div className='messages-count-inner'>
																	{
																		conversations.find(
																			item => item.contact && item.contact.objectId === patient.userId.toString()
																		).unreadMessagesCount
																	}
																</div>
															)}
														</div>
													)}
												<RpmDropDownOptions
													patient={patient}
													toggleViewProfileModal={toggleViewProfileModal}
													toggleAlertsModal={toggleAlertsModal}
													toggleEwsSettings={toggleEwsSettings}
													toggleCarelogs={toggleCarelogs}
													getPatientCareTeam={getPatientCareTeam}
													toggleSurvey={toggleSurvey}
													toggleRpmProgram={toggleRpmProgram}
													toggleMedicationModal={toggleMedicationModal}
													isDarkMode={isDarkMode}
													isAlertHistoryShown={getConfigurationValue(healthSystemConfigurations[PatientTabs.ALERT_HISTORY])}
													isDisabled={patient.patientRpmStatus === PatientRpmStatus.INACTIVE}
													removeFromRpm={removeFromRpm}
												/>
											</div>
										</div>
									</div>
								))}
								{rpmPatients.length === 0 && <div className='cpm-no-data'>{translate('noResultsFound')}</div>}
							</div>
						</div>
					</div>
					<ViewPatientProfile
						display={isViewProfileModalVisible}
						onModalClose={() => setViewProfileModalVisibility(false)}
						onModalSubmit={null}
						setIsSecondTabEnabled={false}
						patientDetails={patientDetails?.profile}
						userId={patientDetails?.userId}
						setSelectedPatient={null}
						setIsPatientFormVisible={null}
						selectedPatientId={patientDetails?.id}
						unitPreferences={preferredUnits}
						countries={countries}
						selectedCountry={selectedCountry}
						isViewOnly={true}
					/>
					{selectedPatient && isAlertsModalOpen && (
						<AlertsModal
							display={isAlertsModalOpen}
							isLoading={false}
							position='center'
							className='vsm-alerts-modal border-radius-modal-wrapper rpm-alerts-modal'
							onModalClose={() => toggleAlertsModal(null)}
							patientId={selectedPatient.id}
							isVitalSigns={false}
						/>
					)}
					<Modal
						display={careTeamForPatient}
						position='center'
						className='standard-modal-wrapper modal-wrapper-wo-btn wrapper-modal vsm-alerts-modal care-team-members-modal'
						onModalClose={() => setCareTeamForPatient(null)}>
						{careTeamForPatient && (
							<Form height={450}>
								<h3>{translate('careTeam')}</h3>
								{careTeamForPatient.length === 0 && !isLoading && (
									<div className='flex flex-align-center flex-justify-center'>
										<p>{translate('noResultsFound')}</p>
									</div>
								)}
								<div className='care-team-members-wrapper' onScroll={handOnModalScroll}>
									<div className='available-doctor-items care-team-members-list'>
										{careTeamForPatient.map(item => (
											<div key={item?.userIntId} className='care-team-info'>
												<ProfilePicture
													className='doctor-request-img available-doctor-request-img'
													firstName={item.firstName}
													lastName={item.lastName}
													profilePicture={item.profilePicture}
												/>
												<p className='team-member-name'>
													{item.firstName} {item.lastName}
												</p>
												<span className='additional-info'>{item.email}</span>
												{item.specialty?.name && <span className='additional-info'>{item.specialty.name}</span>}
											</div>
										))}
									</div>
								</div>
							</Form>
						)}
					</Modal>
				</>
			)}
			{shouldShowSurvey && <Survey selectedPatient={selectedPatient} setShouldShowSurvey={setShouldShowSurvey} />}
			{shouldShowCareLogs && (
				<CareLogs
					selectedPatient={selectedPatient}
					setShouldShowCareLogs={setShouldShowCareLogs}
					preferredUnits={preferredUnits}
				/>
			)}
			{selectedPatient && shouldShowEWSSettings && (
				<Modal
					display={shouldShowEWSSettings}
					isLoading={false}
					position='center'
					className='make-appointment-modal border-radius-modal-wrapper appoinment-next-arrow-modal discard rpm-modal-wrapper'
					onModalClose={() => toggleEwsSettings(null)}
					onModalSubmit={() => updateEWSSetting(selectedPatient.id)}
					isSubmitDisabled={supplementOxygen === null || levelOfConsciousness === null}
					submitImgIcon={`${healthCareCdnUrl}tick.svg`}
					closeImgIcon={`${healthCareCdnUrl}cancel.svg`}>
					<Form height={220} className='create-appointment-form'>
						<h3>{translate('ewsSettings')}</h3>
						<div className='row-direction full-width flex flex-align-center'>
							<label className='flex-1 ews-option'>{translate('levelOfConsciousness')}</label>
							{getRadioButtons(EWSType.LEVEL_OF_CONSCIOUSNESS)}
						</div>
						<div className='row-direction full-width flex flex-align-center'>
							<label className='flex-1 ews-option'>{translate('supplementOxygen')}</label>
							{getRadioButtons(EWSType.SUPPLEMENT_OXYGEN)}
						</div>
					</Form>
				</Modal>
			)}
			{shouldShowRpmProgram && (
				<EditPatientRpmProgram
					patientRpmProgram={patientRpmProgram}
					toggleRpmProgram={toggleRpmProgram}
					shouldShowRpmProgram={shouldShowRpmProgram}
					selectedPatient={selectedPatient}
				/>
			)}
			{shouldShowMedication && (
				<TodaysMedication
					selectedPatient={selectedPatient}
					setShouldShowMedication={setShouldShowMedication}
					isDarkMode={isDarkMode}
				/>
			)}
			{isDeleteModalShown && (
				<RemoveInactivePatient
					isDeleteModalShown={isDeleteModalShown}
					setIsDeleteModalShown={setIsDeleteModalShown}
					inactivePatient={inactivePatient}
				/>
			)}
			<Alert
				display={errorApiResponse}
				fixed={true}
				onClose={() => setErrorApiResponse(null)}
				message={errorApiResponse}
				variant='dark'
			/>
		</>
	);
};

export default RemotePatientMonitoring;
