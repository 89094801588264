import React from 'react';
import moment from 'moment';
import { addDays, subDays } from 'date-fns';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import DatePicker, { registerLocale } from 'react-datepicker';
import sq from 'date-fns/locale/sq/index.js';
import en from 'date-fns/locale/en-US/index.js';
import _ from 'lodash';
import {
	getMeasurements,
	getAggregatesByDateRange,
	getDailyAveragesByDateRange,
	addMeasurement,
	deleteMeasurement,
	deleteMeasurements,
} from 'api/measurements.js';
import LineChartComponent from 'components/Common/Charts/LineChart';
import VerticalBarChart from 'components/Common/Charts/VerticalBarChart';
import 'react-datepicker/dist/react-datepicker.css';
import EmptyState from 'components/EmptyState.jsx';
import Loader from 'components/Loader.jsx';
import Modal from 'components/Modal.jsx';
import Form from 'components/Form.jsx';
import Input from 'components/Input.jsx';
import { MeasurementTypes, MeasurementIcons, UnitSystemTypes, UnitCategoryTypes } from 'constants/enums.js';
import Select from 'components/Select.jsx';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import HealthDataAverages from 'components/Patients/HealthData/HealthDataAverages';
import {
	convertSecondsToHoursFormat,
	getPreferredLanguageLocale,
	handleOnKeyDownNumeric,
	stringToCamelCase,
} from 'infrastructure/helpers/commonHelpers.js';
import {
	getMeasurementColor,
	getMeasurementSourceData,
	convertMeasurementTypes,
	getDefaultUnit,
} from 'infrastructure/helpers/measurementsHelper.js';
import translate from 'i18n-translations/translate.jsx';
import PatientMeasurements from 'containers/CheckInAPatient/PatientMeasurements';
import Alert from 'components/Alert.jsx';
import { getUserRole } from 'infrastructure/auth.js';
import { UserRoles } from 'calls/enums/index.js';

registerLocale('en-US', en);
registerLocale('sq', sq);

class HealthDataTabContent extends React.Component {
	measurementRecentElement;

	keyCodeEnums = {
		period: 190,
		numPadPeriod: 110,
		minus: 189,
		numpadSubtract: 109,
		numpadAdd: 107,
		plus: 187,
	};

	constructor(props) {
		super(props);
		this.measurementRecentElement = React.createRef();
	}

	state = {
		average: null,
		highest: null,
		lowest: null,
		startDate: subDays(new Date(), 7),
		endDate: new Date(),
		measurements: [],
		chartLabels: [],
		chartData: [],
		chartDataSecond: [],
		isLoadingMore: false,
		isLoading: true,
		pageIndex: 0,
		reachedEnd: false,
		showAddMeasurementPopUp: false,
		measurementValue: null,
		measurementValueSecond: null, // this property is used when the type is bloodpressure since we have two input fields
		measurementDate: new Date(),
		measurementEndDate: new Date(), // this property is used when the type is sleep or step since we need two inputs with dates\
		selectedSleepValue: 'In Bed',
		isDataSourceModalVisible: false,
		isDeleteRecordModalVisible: false,
		selectedMeasurement: null,
		isDeleteAllDataModalVisible: false,
		disableOnSubmit: false,
		error: null,
		isTimeValid: true,
	};

	componentDidMount = () => {
		this.getAllMeasurements();
		this.mapChartDataAndLabels();
		this.getAggregatesByDateRange();
	};

	componentDidUpdate = prevProps => {
		if (prevProps.key !== this.props.key) {
			this.getAllMeasurements();
			this.mapChartDataAndLabels();
			this.getAggregatesByDateRange();
		}
	};

	mapChartDataAndLabels = async () => {
		const isBloodPressure = this.props.measurement.type === MeasurementTypes.BLOOD_PRESSURE;
		const isSleep = this.props.measurement.type === MeasurementTypes.SLEEP;
		const startDate = moment(this.state.startDate).format('MM/DD/YYYY');
		const endDate = moment(this.state.endDate).format('MM/DD/YYYY');
		const params = {
			patientId: this.props.selectedPatient?.userId,
			measurementType: this.props.measurement.type,
			startDate,
			endDate,
		};
		const response = await getDailyAveragesByDateRange(params);
		if (response.error) {
			this.setState({ error: response.error.message });
			return;
		}
		const { averages } = response;
		let chartData = [];
		let chartDataSecond = [];
		const chartLabels = averages.map(measurement =>
			moment(measurement.date)
				.local()
				.locale(getPreferredLanguageLocale())
				.format('MMM DD')
		);
		if (isBloodPressure) {
			chartData = averages.map(measurement => {
				const { value } = measurement;
				const indexFound = value.indexOf('/');
				return +value.substring(0, indexFound);
			});

			chartDataSecond = averages.map(measurement => {
				const { value } = measurement;
				const indexFound = value.indexOf('/');
				return +value.substring(indexFound + 1, value.length);
			});
		} else {
			chartData = averages.map(measurement => {
				return isSleep
					? parseInt(measurement.value, 10) / 60 / 60
					: convertMeasurementTypes(this.props.measurement.categoryId, measurement.value, this.props.measurement.unitSystemId);
			});
		}
		this.setState({
			chartLabels,
			chartData,
			chartDataSecond,
		});
	};

	getAggregatesByDateRange = async () => {
		const isSleep = this.props.measurement.type === MeasurementTypes.SLEEP;
		const startDate = moment(this.state.startDate).format('MM/DD/YYYY');
		const endDate = moment(this.state.endDate).format('MM/DD/YYYY');
		const params = {
			patientId: this.props.selectedPatient?.userId,
			measurementType: this.props.measurement.type,
			startDate,
			endDate,
		};
		const response = await getAggregatesByDateRange(params);
		if (response.error) {
			this.setState({ error: response.error.message });
			return;
		}
		const { lowest = { value: '0' }, average = { value: '0' }, highest = { value: '0' } } = response;
		if (isSleep) {
			lowest.value = convertSecondsToHoursFormat(lowest.value);
			average.value = convertSecondsToHoursFormat(average.value);
			highest.value = convertSecondsToHoursFormat(highest.value);
		} else {
			lowest.value = convertMeasurementTypes(
				this.props.measurement.categoryId,
				lowest.value,
				this.props.measurement.unitSystemId
			);
			average.value = convertMeasurementTypes(
				this.props.measurement.categoryId,
				average.value,
				this.props.measurement.unitSystemId
			);
			highest.value = convertMeasurementTypes(
				this.props.measurement.categoryId,
				highest.value,
				this.props.measurement.unitSystemId
			);
		}
		this.setState({
			lowest,
			average,
			highest,
		});
	};

	getAllMeasurements = async () => {
		if (this.state.isLoadingMore) {
			return;
		}
		this.setState({ isLoadingMore: true });
		const startDate = moment(this.state.startDate).format('MM/DD/YYYY');
		const endDate = moment(this.state.endDate).format('MM/DD/YYYY');
		const params = {
			patientId: this.props.selectedPatient?.userId,
			measurementType: this.props.measurement.type,
			startDate,
			endDate,
			pageIndex: this.state.pageIndex,
			pageSize: 20,
		};
		const response = await getMeasurements(params);
		let measurements = [];
		if (response.error) {
			this.setState({ isLoadingMore: false, isLoading: false, measurements, error: response.error.message });
		} else {
			this.setState({ reachedEnd: response.measurements.length < 20 });
			measurements = this.state.measurements.concat(response.measurements);
			measurements.sort((a, b) => (+new Date(b.startDate) > +new Date(a.startDate) ? 1 : -1));
			this.setState(prevState => ({ measurements, isLoadingMore: false, pageIndex: prevState.pageIndex + 1, isLoading: false }));
		}
	};

	handleStartDateChange = date => {
		if (!date) {
			return;
		}
		this.setState({ startDate: date, endDate: addDays(date, 7), pageIndex: 0, reachedEnd: false, measurements: [] }, () => {
			this.getAllMeasurements();
			this.mapChartDataAndLabels();
			this.getAggregatesByDateRange();
		});
	};

	handleEndDateChange = date => {
		if (!date) {
			return;
		}
		const daysDiference = moment.duration(moment(this.state.startDate).diff(moment(date))).asDays();
		const daysDiferenceInt = parseInt(daysDiference.toString(), 10);
		this.setState(
			prevState => ({
				endDate: date,
				startDate: daysDiferenceInt <= 0 ? prevState.startDate : addDays(date, 7),
				pageIndex: 0,
				reachedEnd: false,
				measurements: [],
			}),
			() => {
				this.getAllMeasurements();
				this.mapChartDataAndLabels();
				this.getAggregatesByDateRange();
			}
		);
	};

	handleMeasurementDateChange = date => {
		this.setState({ measurementDate: new Date(date), isTimeValid: this.isFormValid() });
	};

	getMaxTime = () => {
		const visitDateTime = moment(this.state.measurementDate);
		return new Date(visitDateTime.isSame(moment(), 'day') ? this.state.measurementEndDate : '12:00');
	};

	handleDateChange = date => {
		if (new Date(date).getTime() > new Date(this.state.measurementDate).getTime()) {
			this.setState({ measurementEndDate: new Date(date) });
		} else {
			this.setState(prevState => ({ measurementEndDate: new Date(prevState.measurementDate) }));
		}
	};

	downloadCSV = () => {
		let csvContent = 'data:text/csv;charset=utf-8,\uFEFF,';
		this.state.measurements.forEach(measurement => {
			const date = moment
				.utc(measurement.startDate)
				.local()
				.locale(getPreferredLanguageLocale())
				.format('YYYY-MM-DD HH:mm:ss A');
			const hasDeviceContent = measurement.device ? measurement.device.type : this.props.intl.formatMessage({ id: 'unknown' });
			const deviceType = measurement.isManuallyInserted
				? this.props.intl.formatMessage({ id: 'manuallyAdded' })
				: hasDeviceContent;
			const patientName = this.props.selectedPatient?.firstName
				? `${this.props.selectedPatient?.firstName} ${this.props.selectedPatient?.lastName}`
				: this.props.selectedPatient?.fullName;
			const unit =
				measurement.measurementUnit === '' ? this.props.intl.formatMessage({ id: 'noData' }) : measurement.measurementUnit;
			const row = `${this.props.intl.formatMessage({ id: 'patientName' })}: ${patientName}, ${this.props.intl.formatMessage({
				id: 'type',
			})}: ${deviceType}, ${this.props.intl.formatMessage({ id: 'unit' })}: ${unit},
			${this.props.intl.formatMessage({ id: 'value' })}: ${measurement.measurementValue}, ${this.props.intl.formatMessage({
				id: 'measuredAt',
			})}: ${date}`;
			csvContent += `${row}\r\n`;
		});
		const encodedUri = encodeURI(csvContent);
		const link = document.createElement('a');
		link.setAttribute('href', encodedUri);
		link.setAttribute('download', `${this.props.measurement.link}_data.csv`);
		document.body.appendChild(link);
		link.click();
	};

	handleScroll = e => {
		if (this.state.reachedEnd) {
			return;
		}
		const difference =
			this.measurementRecentElement.current.scrollHeight -
			Math.ceil(e.target.scrollTop) -
			this.measurementRecentElement.current.clientHeight;
		if (difference < 50 && difference > -10) {
			this.measurementRecentElement.current.scrollBottom += 100;
			this.getAllMeasurements();
		}
	};

	isFormValid = () => {
		let canInsert = true;
		if (MeasurementTypes.BLOOD_PRESSURE === this.props.measurement.type) {
			if (
				!this.state.measurementValue ||
				!this.state.measurementValueSecond ||
				(this.state.measurementValue && (this.state.measurementValue < 40 || this.state.measurementValue > 300)) ||
				(this.state.measurementValueSecond && (this.state.measurementValueSecond < 30 || this.state.measurementValueSecond > 200))
			) {
				canInsert = false;
			}
		}
		if (
			!this.state.measurementValue ||
			parseInt(this.state.measurementValue, 10) < parseInt(this.props.measurement.minimumValue, 10) ||
			parseInt(this.state.measurementValue, 10) > parseInt(this.props.measurement.maximumValue, 10)
		) {
			canInsert = false;
		}

		if (MeasurementTypes.SLEEP === this.props.measurement.type) {
			const duration = moment(this.state.measurementEndDate).diff(moment(this.state.measurementDate));
			const minutes = duration / 1000 / 60;
			canInsert = minutes > 1 && minutes < 1440;
		}
		return canInsert;
	};

	addMeasurement = async () => {
		if (!this.isFormValid()) {
			return;
		}
		this.setState({ disableOnSubmit: false });
		const { type } = this.props.measurement;
		const { unit } = this.props.measurement;
		const isStep = type === MeasurementTypes.STEPS;
		const isSleep = type === MeasurementTypes.SLEEP;
		const data = {
			userId: this.props.selectedPatient?.userId,
			deviceType: 'web',
			measurementType: type,
			measurementValue: this.getFormattedMeasurement(),
			measurementUnit: isSleep ? this.state.selectedSleepValue : getDefaultUnit(type, unit),
			startDate: this.state.measurementDate,
			endDate: isSleep || isStep ? this.state.measurementEndDate : this.state.measurementDate,
			isManuallyInserted: true,
			source: 'web',
		};
		const response = await addMeasurement(data);
		if (response.error) {
			this.setState({ error: response.error.message });
			return;
		}
		data.id = response.id;
		this.setState(
			prevState => {
				const measurements = [...prevState.measurements, data];
				measurements.sort((a, b) => (+new Date(b.startDate) > +new Date(a.startDate) ? 1 : -1));
				return { measurements };
			},
			() => {
				this.mapChartDataAndLabels();
				this.getAggregatesByDateRange();
				this.toggleAddMeasurementModal();
				if (this.props.isFromVitalSigns) {
					this.props.setIsAddManuallyOpen();
					this.props.fetchLatestMeasurements();
				}
			}
		);
	};

	convertToMetricUnit = (categoryType, value) => {
		let result = value;

		switch (categoryType) {
			case UnitCategoryTypes.WEIGHT:
				result = (value / 2.205).toFixed(2);
				break;
			case UnitCategoryTypes.DISTANCE:
				result = (value * 1.609).toFixed(2);
				break;
			case UnitCategoryTypes.TEMPERATURE:
				result = ((5 / 9) * (value - 32)).toFixed(2);
				break;
			case UnitCategoryTypes.BLOOD_GLUCOSE:
				result = (value * 0.0555).toFixed(2);
				break;
			case UnitCategoryTypes.HEIGHT:
				result = (value * 30.48).toFixed(2);
				break;
			default:
		}
		return result;
	};

	getFormattedMeasurement = () => {
		const { type } = this.props.measurement;
		const isBloodPressure = type === MeasurementTypes.BLOOD_PRESSURE;
		const isSleep = type === MeasurementTypes.SLEEP;
		let { measurementValue } = this.state;
		measurementValue = this.props.measurement.isDecimal ? (+measurementValue).toFixed(2) : measurementValue;
		if (isBloodPressure) {
			return `${this.state.measurementValue}/${this.state.measurementValueSecond}`;
		}

		if (isSleep) {
			const duration = moment(this.state.measurementEndDate).diff(moment(this.state.measurementDate));
			return (duration / 1000).toFixed().toString();
		}

		if (this.props.measurement.unitSystemId !== UnitSystemTypes.METRIC) {
			return this.convertToMetricUnit(this.props.measurement.categoryId, measurementValue);
		}

		return convertMeasurementTypes(
			this.props.measurement.categoryId,
			measurementValue,
			this.props.measurement.unitSystemId
		).toString();
	};

	toggleAddMeasurementModal = () => {
		this.setState(prevState => ({
			showAddMeasurementPopUp: !prevState.showAddMeasurementPopUp,
			measurementValue: '',
			measurementValueSecond: '',
			measurementDate: new Date(),
			measurementEndDate: new Date(),
		}));
	};

	handleOnChange = event => {
		if (Number(event.target.value) < 0) {
			return;
		}
		this.setState({ [event.target.name]: event.target.value });
	};

	handleOnKeyDown = event => {
		handleOnKeyDownNumeric(event);
		if (
			!this.props.measurement.isDecimal &&
			[
				this.keyCodeEnums.minus,
				this.keyCodeEnums.numPadPeriod,
				this.keyCodeEnums.numpadAdd,
				this.keyCodeEnums.numpadSubtract,
				this.keyCodeEnums.period,
				this.keyCodeEnums.plus,
			].includes(event.which)
		) {
			event.preventDefault();
		}
	};

	changeHealthSystem = value => {
		this.setState({ selectedSleepValue: value.target.value });
	};

	getMeasurementSource = measurement => {
		let data;
		if (measurement.isManuallyInserted) {
			data = {
				image: MeasurementIcons.MANUALLY_ADDED,
				title: this.props.intl.formatMessage({ id: 'manuallyAdded' }),
			};
		} else {
			const type = measurement.device ? measurement.device.type : null;
			data = getMeasurementSourceData(type, this.props.intl);
		}
		return (
			<div className='flex'>
				<img src={data.image} alt='add data' />
				<span className='measurement-src-text'>{data.title}</span>
			</div>
		);
	};

	toggleDataSourceModal = measurement => {
		this.setState(prevState => ({
			selectedMeasurement: measurement,
			isDataSourceModalVisible: !prevState.isDataSourceModalVisible,
		}));
	};

	toggleDeleteRecordModal = () => {
		this.setState(prevState => ({
			isDeleteRecordModalVisible: !prevState.isDeleteRecordModalVisible,
		}));
	};

	showDeleteAllDataModal = () => {
		this.setState(prevState => ({
			isDeleteAllDataModalVisible: !prevState.isDeleteAllDataModalVisible,
		}));
	};

	handleDeleteMeasurement = async () => {
		const { id } = this.state.selectedMeasurement;
		const response = await deleteMeasurement(id);
		if (!response.error || response.hasSucceeded) {
			const measurementsCopied = [...this.state.measurements];
			const measurements = measurementsCopied.filter(measurement => measurement.id !== id);
			this.setState({ measurements, isDeleteRecordModalVisible: false, isDataSourceModalVisible: false }, () => {
				this.mapChartDataAndLabels();
				this.getAggregatesByDateRange();
			});
		} else {
			this.setState({ error: response.error.message });
		}
	};

	handleDeleteMeasurements = async () => {
		const response = await deleteMeasurements(this.props.measurement.type);
		if (!response.error || response.hasSucceeded) {
			this.setState({ measurements: [], isDeleteAllDataModalVisible: false });
		}
	};

	getMeasurementDescription = () => {
		if (this.props.measurement) {
			switch (this.props.measurement.type) {
				case MeasurementTypes.BLOOD_PRESSURE:
					return translate('systolic');
				case MeasurementTypes.RESPIRATORY_RATE:
					return translate(this.props.measurement.unit);
				case MeasurementTypes.STEPS:
					return translate('steps');
				case MeasurementTypes.FLIGHTS_CLIMBED:
					return translate('flightsClimbed');
				default:
					return this.props.measurement.unit;
			}
		}
		return '';
	};

	render() {
		let dataSets = [];
		const isBloodPressure = this.props.measurement.type === MeasurementTypes.BLOOD_PRESSURE;
		const isStep = this.props.measurement.type === MeasurementTypes.STEPS;
		const isSleep = this.props.measurement.type === MeasurementTypes.SLEEP;

		const { selectedMeasurement } = this.state;
		if (isBloodPressure) {
			dataSets = [
				{
					label: this.props.intl.formatMessage({ id: 'systolic' }),
					data: this.state.chartData,
					fill: false,
					borderColor: getMeasurementColor(this.props.measurement.type),
				},
				{
					label: this.props.intl.formatMessage({ id: 'diastolic' }),
					data: this.state.chartDataSecond,
					fill: false,
					borderColor: '#804040',
				},
			];
		} else {
			dataSets = [
				{
					label: this.props.measurement.link,
					data: this.state.chartData,
					fill: false,
					borderColor: getMeasurementColor(this.props.measurement.type),
					backgroundColor: [
						MeasurementTypes.STEPS,
						MeasurementTypes.FLIGHTS_CLIMBED,
						MeasurementTypes.DISTANCE_WALKING_RUNNING,
					].includes(this.props.measurement.type)
						? '#80cc52'
						: 'transparent',
				},
			];
		}

		const activityTabs = [MeasurementTypes.STEPS, MeasurementTypes.FLIGHTS_CLIMBED, MeasurementTypes.DISTANCE_WALKING_RUNNING];
		const maxTime = this.getMaxTime();
		const minDate = new Date(this.state.measurementDate);
		minDate.setHours(0);
		minDate.setMinutes(0);
		minDate.setSeconds(0);

		return (
			<>
				{!this.props.isFromVitalSigns && (
					<div className='patient-iot-history-data' onScroll={this.handleScroll} ref={this.measurementRecentElement}>
						<div className='patient-iot-history-title flex'>
							<div>
								<h2>{this.props.measurement.link}</h2>
								<p>
									<img src={`${healthCareCdnUrl}add-data-manually.svg`} alt='add data' />
									<button type='button' data-cy='addNewMeasurement' onClick={this.toggleAddMeasurementModal}>
										{translate('addData')}
									</button>
								</p>
							</div>
							{this.state.measurements.length > 0 && (
								<div className='flex download-csv'>
									<span
										onClick={this.downloadCSV}
										data-tooltip={this.props.intl.formatMessage({ id: 'downloadCSV' })}
										data-position='bottom'>
										<img className='cursor-pointer' src={`${healthCareCdnUrl}health-data/download-csv.svg`} alt='icon' />
									</span>
								</div>
							)}
							<div className='flex patient-iot-start-end-date'>
								<div>
									<DatePicker
										selected={this.state.startDate}
										onChange={this.handleStartDateChange}
										maxDate={new Date()}
										placeholderText={this.props.intl.formatMessage({ id: 'selectStartDate' })}
										dateFormat='MMMM d, yyyy'
										locale={this.props.language.locale}
									/>
								</div>
								<div>
									<DatePicker
										selected={this.state.endDate}
										onChange={this.handleEndDateChange}
										minDate={this.state.startDate}
										maxDate={addDays(this.state.startDate, 7)}
										placeholderText={this.props.intl.formatMessage({ id: 'selectEndDate' })}
										dateFormat='MMMM d, yyyy'
										locale={this.props.language.locale}
									/>
								</div>
							</div>
						</div>
						{this.state.measurements.length > 0 && (
							<>
								<div className='patient-iot-history-graph'>
									{activityTabs.includes(this.props.measurement.type) && (
										<VerticalBarChart labels={this.state.chartLabels} datasets={dataSets} />
									)}
									{!activityTabs.includes(this.props.measurement.type) && (
										<LineChartComponent labels={this.state.chartLabels} datasets={dataSets} />
									)}
								</div>
								<HealthDataAverages
									lowest={this.state.lowest}
									average={this.state.average}
									highest={this.state.highest}
									measurement={this.props.measurement}
								/>
								<div className='patient-iot-history-recent'>
									<div className='flex patient-iot-history-delete'>
										<h2>{translate('allRecordedData')}</h2>
										{getUserRole() === UserRoles.PATIENT && (
											<span className='cursor-pointer flex' onClick={this.showDeleteAllDataModal}>
												<i className='material-icons'>close</i>
												{translate('deleteAllDataRecords')}
											</span>
										)}
									</div>
									{this.state.measurements.length > 0 &&
										this.state.measurements.map(measurement => (
											<div
												key={measurement.id}
												className='patient-iot-history-recent-item cursor-pointer flex'
												data-cy='measurementList'
												onClick={() => this.toggleDataSourceModal(measurement)}>
												<div>
													<div className='flex'>
														<p>
															{measurement.measurementType === MeasurementTypes.SLEEP &&
																convertSecondsToHoursFormat(measurement.measurementValue)}

															{measurement.measurementType !== MeasurementTypes.SLEEP &&
																convertMeasurementTypes(
																	this.props.measurement.categoryId,
																	measurement.measurementValue,
																	this.props.measurement.unitSystemId
																)}

															<span>
																{' '}
																{measurement.measurementType !== MeasurementTypes.SLEEP &&
																	this.props.measurement.unit &&
																	this.props.intl
																		.formatMessage({ id: stringToCamelCase(this.props.measurement.unit) })
																		.toLowerCase()}
															</span>
														</p>
													</div>
													{this.getMeasurementSource(measurement)}
												</div>
												<div className='patient-iot-item-source'>
													<div>
														<img src={`${healthCareCdnUrl}health-data/info.svg`} alt='icon' />
													</div>
													<div>
														<span>{formattedDate(measurement.startDate)}</span>
													</div>
												</div>
											</div>
										))}
									{!this.state.reachedEnd && this.state.isLoadingMore && (
										<div style={{ textAlign: 'center', width: '100%' }}>
											<Loader />
										</div>
									)}
								</div>
							</>
						)}
						{this.state.isLoading && (
							<div style={{ textAlign: 'center', width: '100%' }}>
								<Loader />
							</div>
						)}
						{!this.state.isLoadingMore && this.state.measurements.length === 0 && (
							<div className='empty-state-wrapper-percent'>
								<EmptyState title={translate('noDataFound')} image='health-data.svg' paragraph={translate('tryOtherDateRange')} />
							</div>
						)}
						<Modal
							modalSelector='addMeasurementModal'
							display={this.state.showAddMeasurementPopUp}
							position='center'
							onModalClose={this.toggleAddMeasurementModal}
							onModalSubmit={_.debounce(() => this.addMeasurement(), 500)}
							primaryButtonLabel={translate('add')}
							className={`add-measurement-popup ${this.props.measurement.type}`}
							isSubmitDisabled={!this.isFormValid()}>
							<Form title={translate('addDataManually')}>
								<div className='input add-measurement'>
									<p className='label'>{isSleep || isStep ? translate('startDate') : translate('dateAndTime')}</p>
									<DatePicker
										showTimeSelect
										selected={this.state.measurementDate}
										onChange={this.handleMeasurementDateChange}
										maxDate={new Date()}
										minTime={minDate}
										maxTime={maxTime}
										dateFormat='MMMM d, yyyy h:mm aa'
										popperPlacement='bottom-start'
										locale={this.props.language.locale}
									/>
									{isSleep && !this.isFormValid() && !this.state.isTimeValid && (
										<span className='red-error'>{translate('twentyHrsBetween')}</span>
									)}
								</div>
								{(isSleep || isStep) && (
									<div className='input add-measurement'>
										<p className='label'>{translate('endDate')}</p>
										<DatePicker
											showTimeSelect
											selected={this.state.measurementEndDate}
											onChange={this.handleDateChange}
											maxDate={new Date()}
											minDate={new Date(this.state.measurementDate)}
											minTime={minDate}
											maxTime={new Date()}
											dateFormat='MMMM d, yyyy h:mm aa'
											popperPlacement='bottom-start'
											locale={this.props.language.locale}
										/>
									</div>
								)}
								{!isSleep && this.props.measurement.isDecimal && (
									<Input
										onChange={this.handleOnChange}
										name='measurementValue'
										label={this.props.intl.formatMessage({ id: 'inputValue' })}
										type='number'
										value={this.state.measurementValue}
										validationOptions={{}}
										bottomSpace='15px'
										placeholder={`${this.props.measurement.minimumValue} - ${this.props.measurement.maximumValue}`}
										description={this.getMeasurementDescription()}
										maxLength={255}
										min={this.props.measurement.minimumValue}
										max={this.props.measurement.maximumValue}
									/>
								)}
								{!isSleep && !this.props.measurement.isDecimal && (
									<Input
										onKeyDown={this.handleOnKeyDown}
										onChange={this.handleOnChange}
										name='measurementValue'
										label={this.props.intl.formatMessage({ id: 'inputValue' })}
										type='number'
										value={this.state.measurementValue}
										validationOptions={{}}
										bottomSpace='15px'
										placeholder={`${this.props.measurement.minimumValue} - ${this.props.measurement.maximumValue}`}
										description={this.getMeasurementDescription()}
										maxLength={255}
										min={this.props.measurement.minimumValue}
										max={this.props.measurement.maximumValue}
									/>
								)}
								{isSleep && (
									<Select
										onSelect={this.changeHealthSystem}
										value={this.state.selectedSleepValue}
										textField='label'
										valueField='value'
										items={[
											{ value: 'In Bed', label: this.props.intl.formatMessage({ id: 'inBed' }) },
											{ value: 'Asleep', label: this.props.intl.formatMessage({ id: 'asleep' }) },
										]}
										description='Type'
										placeholder={this.props.intl.formatMessage({ id: 'select' })}
										className='full-width'
									/>
								)}
								{isBloodPressure && (
									<Input
										onKeyDown={this.handleOnKeyDown}
										onChange={this.handleOnChange}
										name='measurementValueSecond'
										type='number'
										value={this.state.measurementValueSecond}
										validationOptions={{}}
										bottomSpace='15px'
										placeholder={`${this.props.measurement.minimumValueSecond} - ${this.props.measurement.maximumValueSecond}`}
										description={isBloodPressure ? translate('diastolic') : this.props.measurement.unit}
										maxLength={255}
										min={this.props.measurement.minimumValueSecond}
										max={this.props.measurement.maximumValueSecond}
									/>
								)}
							</Form>
						</Modal>
						<Modal
							modalSelector='iotDataModal'
							className='iot-data-source-modal'
							primaryButtonLabel={translate('deleteThisDataRecord')}
							display={this.state.isDataSourceModalVisible}
							onModalClose={() => this.toggleDataSourceModal(null)}
							position='center'
							onModalSubmit={this.toggleDeleteRecordModal}
							closeButtonText={translate('done')}
							submitImgIcon={`${healthCareCdnUrl}measurements-icons/trash-can.svg?v2`}>
							<div className='iot-devices-source-record'>
								<div>
									<h2>{translate('dataDetails')}</h2>
								</div>
								{selectedMeasurement && (
									<div className='flex iot-devices-source-grid'>
										<div>
											<h3>{this.props.measurement.link}</h3>
											<div className='flex'>
												<img src={this.props.measurement.icon} alt='icon' />

												<p>
													{selectedMeasurement.measurementType === MeasurementTypes.SLEEP &&
														convertSecondsToHoursFormat(selectedMeasurement.measurementValue)}

													{selectedMeasurement.measurementType !== MeasurementTypes.SLEEP &&
														convertMeasurementTypes(
															this.props.measurement.categoryId,
															selectedMeasurement.measurementValue,
															this.props.measurement.unitSystemId
														)}

													<span>
														{selectedMeasurement.measurementType !== MeasurementTypes.SLEEP &&
															this.props.measurement.unit &&
															this.props.intl.formatMessage({ id: stringToCamelCase(this.props.measurement.unit) }).toLowerCase()}
													</span>
												</p>
											</div>
										</div>
										<div>
											<h3>{translate('dateOfMeasurement')}</h3>
											<div className='flex'>
												<img src={`${healthCareCdnUrl}measurements-icons/calendar-black-icon.svg`} alt='icon' />
												<span>{formattedDate(selectedMeasurement.endDate)}</span>
											</div>
										</div>
										<div>
											<h3>{translate('source')}</h3>
											<div className='flex'>
												<span>{this.getMeasurementSource(this.state.selectedMeasurement)}</span>
											</div>
										</div>
										{selectedMeasurement.device && selectedMeasurement.manufacturer && (
											<div>
												<h3>{translate('manufacturer')}</h3>
												<div className='flex'>
													<span>{selectedMeasurement.manufacturer}</span>
												</div>
											</div>
										)}
										{selectedMeasurement.device && selectedMeasurement.model && (
											<div>
												<h3>{translate('model')}</h3>
												<div className='flex'>
													<span>{selectedMeasurement.model}</span>
												</div>
											</div>
										)}
									</div>
								)}
							</div>
						</Modal>
						<Modal
							modalSelector='deleteIotModal'
							className='iot-delete-record-modal'
							display={this.state.isDeleteRecordModalVisible}
							onModalClose={this.toggleDeleteRecordModal}
							onModalSubmit={this.handleDeleteMeasurement}
							position='center'
							primaryButtonLabel={translate('delete')}>
							<div className='iot-devices-delete-record'>
								<img src={`${healthCareCdnUrl}measurements-icons/delete-records.svg`} alt='icon' />
								<h2>{translate('areYouSureDeleteThisRecord')}</h2>
							</div>
						</Modal>
						<Modal
							className='iot-delete-record-modal'
							display={this.state.isDeleteAllDataModalVisible}
							onModalClose={this.showDeleteAllDataModal}
							onModalSubmit={this.handleDeleteMeasurements}
							position='center'
							primaryButtonLabel={translate('delete')}>
							<div className='iot-devices-delete-record'>
								<img src={`${healthCareCdnUrl}measurements-icons/delete-records.svg`} alt='icon' />
								<h2>{translate('areYouSureDeleteAllRecords')}</h2>
							</div>
						</Modal>
					</div>
				)}
				{this.props.isFromVitalSigns && (
					<PatientMeasurements
						display={this.props.isAddManuallyOpen}
						onModalClose={this.props.setIsAddManuallyOpen}
						onModalSubmit={_.debounce(() => this.addMeasurement(), 500)}
						isSubmitDisabled={!this.isFormValid() || this.state.disableOnSubmit}
						handleMeasurementDateChange={this.handleMeasurementDateChange}
						handleOnChange={this.handleOnChange}
						handleDateChange={this.handleDateChange}
						handleOnKeyDown={this.handleOnKeyDown}
						changeHealthSystem={this.changeHealthSystem}
						getMeasurementDescription={this.getMeasurementDescription}
						measurement={this.props.measurement}
						measurementDate={this.state.measurementDate}
						measurementEndDate={this.state.measurementEndDate}
						measurementValue={this.state.measurementValue}
						measurementValueSecond={this.state.measurementValueSecond}
						selectedSleepValue={this.state.selectedSleepValue}
						minTime={minDate}
						maxTime={maxTime}
						isSleep={isSleep}
						isStep={isStep}
						isBloodPressure={isBloodPressure}
						isFromVitalSigns={this.props.isFromVitalSigns}
						isTimeValid={this.state.isTimeValid}
					/>
				)}
				<Alert display={this.state.error} fixed={true} hideCloseButton={true} message={this.state.error} variant='dark' />
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		user: state.user,
		language: state.language,
	};
};

export default injectIntl(connect(mapStateToProps)(HealthDataTabContent));
