import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';

const companyId = getCompanyId();

export const setWorkflow = async (doctorId, workflowTypeId) => {
	try {
		const { data } = await gatewayApi.put(`v1/healthcare/doctors/${doctorId}/workflow`, {
			workflowTypeId,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const getWorkflow = async doctorId => {
	try {
		const { data } = await gatewayApi.get(`v1/healthcare/doctors/${doctorId}/workflow`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getAssociatedNurses = async ({ doctorId, pageIndex, pageSize }) => {
	try {
		const { data } = await gatewayApi.get(`v1/healthcare/doctors/${doctorId}/nurses?pageIndex=${pageIndex}&pageSize=${pageSize}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const addAssociatedNurses = async (doctorId, nurseIds) => {
	try {
		const { data } = await gatewayApi.post(`v1/healthcare/doctors/${doctorId}/nurses`, { nurseIds });
		return data;
	} catch (error) {
		return { error };
	}
};

export const deleteAssociatedNurse = async (doctorId, nurseId) => {
	try {
		const { data } = await gatewayApi.delete(`v1/healthcare/doctors/${doctorId}/nurses/${nurseId}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const updateNurseAuthorization = async ({ doctorId, nurseId, isAuthorized }) => {
	try {
		const { data } = await gatewayApi.patch(`v1/healthcare/doctors/${doctorId}/nurses/${nurseId}/authorization`, {
			isAuthorized,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const searchNurses = async search => {
	try {
		const url = `v1/healthcare/organizations/${companyId}/nurses?search=${search}`;
		const { data } = await gatewayApi.get(url);
		return data;
	} catch (error) {
		return { error };
	}
};
