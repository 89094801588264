import React, { useState, useEffect, useRef } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { Modal, Loader, Form, Input, Alert } from 'components';
import SearchResultsItems from 'components/SearchResultsItems';
import useOutsideClick from 'infrastructure/helpers/useOutsideClick.js';
import translate from 'i18n-translations/translate.jsx';
import { searchPatient, createPatient } from 'api/patients.js';
import { AlertTypes } from 'constants/enums.js';
import { RoomTypes } from 'constants/visitEnums.js';
import { updatePatientDeviceOwner } from 'api/devices.js';
import PopUpAlert from 'components/PopUpAlert.jsx';

const AssignPatient = props => {
	const [searchedPatients, setSearchedPatients] = useState([]);
	const [searchPatientValue, setSearchPatientValue] = useState('');
	const [selectedPatient, setSelectedPatient] = useState(null);
	const [index, setIndex] = useState(-1);
	const [showSearchedPatientsDropdown, setShowSearchedPatientsDropdown] = useState(false);
	const [selectedAssignmentType, setSelectedAssignmentType] = useState(0);
	const [assignPatientSuccess, setAssignPatientSuccess] = useState(false);
	const [assignPatientFailureMsg, setAssignPatientFailureMsg] = useState(null);
	const [error, setError] = useState(null);
	const [totalCount, setTotalCount] = useState(0);
	const [pageIndex, setPageIndex] = useState(0);

	const intl = useIntl();

	const ref = useRef();
	const patientsType = {
		EXISTING: 0,
		NEW: 1,
	};
	const assignmentTypes = [
		{ id: patientsType.EXISTING, value: intl.formatMessage({ id: 'addExistingPatient' }) },
		{ id: patientsType.NEW, value: intl.formatMessage({ id: 'addNewPatient' }) },
	];
	const activeSearchItem = useRef(null);

	useEffect(() => {
		if (index === -1) {
			activeSearchItem.current.focus();
		}
	}, [index]);

	useEffect(() => {
		document.addEventListener('keydown', onItemKeyPress);

		return () => {
			document.removeEventListener('keydown', onItemKeyPress);
		};
	});

	useEffect(() => {
		const timeout = setTimeout(async () => {
			if (searchPatientValue.length < 2) {
				setSearchedPatients([]);
			} else {
				const response = await searchPatient(searchPatientValue, pageIndex);
				if (response.error) {
					setError(response.error.message);
					return;
				}
				const searchedPatientsList = response.patients.map((obj, i) => ({ ...obj, focusIndex: i }));

				setSearchedPatients(prevState => prevState.concat(searchedPatientsList));
				setTotalCount(response.totalCount);
			}
		}, 500);

		return () => clearTimeout(timeout);
	}, [searchPatientValue, pageIndex]);

	const validateForm = () => {
		if (patientsType.NEW === selectedAssignmentType && props.roomType === RoomTypes.DEFAULT.type) {
			return Yup.object().shape({
				firstName: Yup.string()
					.required(
						intl.formatMessage({
							id: 'pleaseWriteFirstName',
						})
					)
					.min(
						2,
						intl.formatMessage({
							id: 'firstNameMinLength',
						})
					)
					.max(
						30,
						intl.formatMessage({
							id: 'maxCharacterLengthExceeded',
						})
					)
					.trim(),
				lastName: Yup.string()
					.required(intl.formatMessage({ id: 'pleaseWriteLastName' }))
					.min(
						2,
						intl.formatMessage({
							id: 'lastNameMinLength',
						})
					)
					.max(
						30,
						intl.formatMessage({
							id: 'maxCharacterLengthExceeded',
						})
					)
					.trim(),
				email: Yup.string()
					.email(intl.formatMessage({ id: 'invalidEmail' }))
					.required(intl.formatMessage({ id: 'pleaseWriteEmail' })),
			});
		}
		if (patientsType.NEW === selectedAssignmentType && props.roomType === RoomTypes.BABY_ROOM.type) {
			return Yup.object().shape({
				firstName: Yup.string()
					.required(
						intl.formatMessage({
							id: 'pleaseWriteFirstName',
						})
					)
					.min(
						2,
						intl.formatMessage({
							id: 'firstNameMinLength',
						})
					)
					.max(
						30,
						intl.formatMessage({
							id: 'maxCharacterLengthExceeded',
						})
					)
					.trim(),
				lastName: Yup.string()
					.required(intl.formatMessage({ id: 'pleaseWriteLastName' }))
					.min(
						2,
						intl.formatMessage({
							id: 'lastNameMinLength',
						})
					)
					.max(
						30,
						intl.formatMessage({
							id: 'maxCharacterLengthExceeded',
						})
					)
					.trim(),
			});
		}
		return Yup.object().shape({
			searchPatients: Yup.string().trim(),
		});
	};

	const onItemKeyPress = event => {
		const code = event.keyCode || event.which;
		const keyCodes = { enter: 13, arrowUp: 38, arrowDown: 40 };
		if (!Object.values(keyCodes).includes(code)) {
			return;
		}
		event.preventDefault();
		if (!searchPatientValue && !searchedPatients.length) return;
		if (code === keyCodes.arrowDown && index < searchedPatients.length - 1) {
			setIndex(prevIndex => prevIndex + 1);
		}
		if (code === keyCodes.arrowUp && index >= 0) {
			setIndex(prevIndex => prevIndex - 1);
		}
		if (code === keyCodes.enter && index > -1 && index < searchedPatients.length) {
			const patientDevice = searchedPatients.find(item => item.focusIndex === index);
			onSearchPatientSelect(patientDevice);
		}
	};

	useOutsideClick(ref, () => {
		if (showSearchedPatientsDropdown) {
			setShowSearchedPatientsDropdown(false);
		}
	});

	const setSearchValue = event => {
		setPageIndex(0);
		setSearchedPatients([]);
		setSearchPatientValue(event.target.value);
		setShowSearchedPatientsDropdown(true);
	};

	const onSearchPatientSelect = patient => {
		setSelectedPatient(patient);
		setShowSearchedPatientsDropdown(false);
		setSearchPatientValue(`${patient?.firstName} ${patient?.lastName}`);
	};

	const toggleAssignmentType = () => {
		setSelectedAssignmentType(prevState => (prevState === patientsType.EXISTING ? patientsType.NEW : patientsType.EXISTING));
		setAssignPatientSuccess(false);
		setAssignPatientFailureMsg(null);
		resetSearchedPatient();
		resetSelectedPatient();
	};

	const updateTheDeviceOwner = async (userId, deviceId) => {
		props.setError(null);
		const deviceParams = {
			userId,
			deviceName: '',
			deviceId,
		};
		const deviceOwner = await updatePatientDeviceOwner(deviceParams);
		if (deviceOwner.error || !deviceOwner.hasSucceeded) {
			const errorMessage = deviceOwner.error?.response.data.message || deviceOwner.error?.message;
			setAssignPatientSuccess(false);
			setAssignPatientFailureMsg(errorMessage);
		} else {
			setAssignPatientFailureMsg(null);
			setAssignPatientSuccess(true);
		}
	};

	const addPatientSubmit = async (values, { resetForm }) => {
		const { firstName, lastName, email } = values;
		if (selectedAssignmentType === patientsType.EXISTING && !searchPatientValue) {
			return;
		}
		const deviceId = props.devices.length > 0 ? props.devices[0].solHelloDeviceId : null;
		if (patientsType.EXISTING === selectedAssignmentType) {
			await updateTheDeviceOwner(selectedPatient.userId, deviceId);
		} else {
			const formData = {
				firstName: firstName,
				lastName: lastName,
				email: email,
				channelTypeId: props.roomType,
				healthSystemId: props.healthSystemId,
			};
			const response = await createPatient(formData);
			const emailExistsErrorCode = 1008;
			if (response.error) {
				if (response.error.response.data.code === emailExistsErrorCode) {
					setError(`${intl.formatMessage({ id: 'emailExists' })}.`);
					return;
				}
				setError(response.error.message);
				return;
			}
			if (response.data?.result) {
				await updateTheDeviceOwner(response.data.result.userId, deviceId);
			}
		}
		resetForm();
		updateDeviceOwnerListItem(deviceId, values);
		props.loadRoom();
		resetSearchedPatient();
	};

	const resetSearchedPatient = () => {
		setSearchedPatients([]);
		setError(null);
	};

	const resetSelectedPatient = () => {
		setSearchPatientValue('');
		setSelectedPatient(null);
	};

	const updateDeviceOwnerListItem = (deviceId, { firstName, lastName }) => {
		const devices = [...props.devices];
		const patientFullName =
			selectedAssignmentType === patientsType.EXISTING
				? `${selectedPatient.firstName} ${selectedPatient.lastName}`
				: `${firstName} ${lastName}`;
		devices.forEach((device, i) => {
			if (device.solHelloDeviceId === deviceId) {
				const updatedDevice = device;
				updatedDevice.owner = patientFullName;
				devices[i] = updatedDevice;
			}
		});
		props.setDevices(devices);
	};

	const assignPatientLabel = selectedAssignmentType === patientsType.EXISTING ? patientsType.NEW : patientsType.EXISTING;

	const searchWords = searchPatientValue.split(' ');

	const modalClose = () => {
		props.toggleAddPatientModal();
		setError(null);
		setAssignPatientSuccess(false);
		resetSelectedPatient();
		setAssignPatientFailureMsg(null);
	};

	const handleScroll = event => {
		const isBottom = event.target.scrollHeight - Math.ceil(event.target.scrollTop) === event.target.clientHeight;
		const hasReachedEnd = totalCount - searchedPatients.length <= 0;
		if (isBottom && !hasReachedEnd) {
			setPageIndex(prevState => prevState + 1);
		}
	};

	return (
		<Formik
			initialValues={{
				firstName: '',
				lastName: '',
				email: '',
				searchPatientValue,
			}}
			onSubmit={addPatientSubmit}
			validationSchema={validateForm}
			validateOnBlur={false}
			validateOnChange={false}>
			{formikProps => {
				const { values, errors, handleSubmit, handleChange, isSubmitting } = formikProps;
				return (
					<Modal
						isLoading={isSubmitting}
						display={props.isAddPatientModalOpen}
						position='right'
						className='assign-patients-modal'
						onModalClose={() => modalClose()}
						onModalSubmit={handleSubmit}
						shouldSubmitOnEnter={false}>
						<Form title={translate('assignPatient')}>
							<button type='button' onClick={toggleAssignmentType}>
								{assignmentTypes.find(item => item.id === assignPatientLabel)?.value}
								<i className='material-icons-outlined'>expand_more</i>
							</button>
							{patientsType.EXISTING === selectedAssignmentType && (
								<>
									<div className='header-searchbar' ref={ref} onScroll={handleScroll}>
										{searchPatientValue && showSearchedPatientsDropdown && (
											<div className='header-searchbar-results'>
												{props.isLoading && <Loader />}
												{!props.isLoading && (
													<>
														<div>
															{searchedPatients.length > 0 && (
																<SearchResultsItems
																	title={translate('resultsFor', {
																		value: `"${searchPatientValue}"`,
																	})}
																	searchedItems={searchedPatients}
																	onItemClick={onSearchPatientSelect}
																	searchWords={searchWords}
																	isDoctorsList={false}
																	isDevicesSearchedItems={false}
																	index={index}
																	onItemKeyPress={onItemKeyPress}
																	isPatientsSearch={false}
																	searchBox={searchPatientValue}
																/>
															)}
															{searchedPatients.length === 0 && (
																<div className='chat-item-title'>
																	<span className='semi-bold'>{translate('noResultsFound')}</span>
																</div>
															)}
														</div>
													</>
												)}
											</div>
										)}
										<div>
											<input
												type='search'
												placeholder={intl.formatMessage({ id: 'searchPatient' })}
												onChange={setSearchValue}
												value={searchPatientValue}
												maxLength={100}
												ref={activeItem => {
													activeSearchItem.current = activeItem;
												}}
											/>
										</div>
									</div>
									<Alert
										display={assignPatientSuccess}
										message={translate('patientAssignedAsDeviceOwner')}
										variant='success'
										onClose={() => setAssignPatientSuccess(false)}
									/>
									<Alert
										display={!!assignPatientFailureMsg}
										message={assignPatientFailureMsg}
										variant='neutral'
										hideCloseButton
									/>
								</>
							)}
							{patientsType.NEW === selectedAssignmentType && (
								<>
									<Input
										type='text'
										validationOptions={{}}
										label={translate('patientFirstName')}
										value={values.firstName}
										onChange={handleChange}
										name='firstName'
										placeholder={intl.formatMessage({ id: 'patientFirstName' })}
										maxLength={127}
										error={errors.firstName}
									/>
									<Input
										type='text'
										validationOptions={{}}
										label={translate('patientLastName')}
										value={values.lastName}
										onChange={handleChange}
										name='lastName'
										placeholder={intl.formatMessage({ id: 'patientLastName' })}
										maxLength={127}
										error={errors.lastName}
									/>
									{props.roomType === RoomTypes.DEFAULT.type && (
										<Input
											type='text'
											label={translate('patientEmail')}
											validationOptions={{}}
											value={values.email}
											onChange={handleChange}
											name='email'
											placeholder={intl.formatMessage({ id: 'patientEmail' })}
											maxLength={127}
											error={errors.email}
										/>
									)}
									<Alert
										display={assignPatientSuccess}
										message={translate('patientAssignedAsDeviceOwner')}
										variant='success'
										onClose={() => setAssignPatientSuccess(false)}
									/>

									<PopUpAlert
										alertType={AlertTypes.DANGER}
										display={error}
										onAlertClose={() => setError(null)}
										contentText={error}
										isSilent={true}
										center={true}
									/>
								</>
							)}
						</Form>
					</Modal>
				);
			}}
		</Formik>
	);
};

export default AssignPatient;
