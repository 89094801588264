import { CallsActionTypes } from 'constants/action-types.js';

const initialState = {
	incomingCallRenderedElsewhere: null,
};


export const calls = (state = initialState, action) => {
	if (action.type === CallsActionTypes.incomingCallRenderedElsewhere) {
		return { ...state, incomingCallRenderedElsewhere: action.payload };
	}

	return state;
};
