import { forwardRef } from 'react';
import styled from 'styled-components';

import LightTheme from 'calls/styles/LightTheme.js';

const StyledInput = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: ${LightTheme.spacing[6]}px;

	label {
		display: block;
		width: 100%;
		margin-bottom: ${LightTheme.spacing[2]}px;
		font-size: 14px;
		color: ${LightTheme.colors.grayFive};
		font-weight: 500;
		text-align: left;
	}

	input {
		background: {props.inputColor};
		padding: ${LightTheme.spacing[2]}px;
		margin-bottom: ${LightTheme.spacing[3]}px;
		color: ${LightTheme.colors.grayFive};
		border: 1px solid transparent;
		font-size: 14px;
		border-radius: ${LightTheme.borderRadius.base}px;
		transition: 0.2s;
		color: ${LightTheme.colors.grayFive};
		box-shadow: 0 0 0 1px rgb(0 0 0 / 10%), 0 2px 2px rgb(0 0 0 / 8%);

		&:placeholder {
			color: ${LightTheme.colors.grayThree};
		}

		&:focus {
			border: 1px solid ${LightTheme.colors.blueTwo};
		}
	}

	small {
		margin-top: -${LightTheme.spacing[1]}px;
		color: ${LightTheme.colors.grayThree};
		font-size: 12px;
		text-align: left;
	}
`;

const Input = ({ children }) => {
	return <StyledInput>{children}</StyledInput>;
};

const InputLabel = ({ children }) => {
	return <label>{children}</label>;
};

const InputField = forwardRef((
	/**
	 * @type {React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>}
	 */
	{ ...htmlAttributes },
	ref
) => <input {...htmlAttributes} ref={ref} />);

const InputDescription = ({ children }) => {
	return <small>{children}</small>;
};

Input.Label = InputLabel;
Input.Field = InputField;
Input.Description = InputDescription;

export default Input;
