import React, { useState } from 'react';
import translate from 'i18n-translations/translate.jsx';
import classNames from 'classnames';
import { EditIcon } from 'icons/Monitoring/index.js';
import ReactSelect from 'react-select';
import { generateCustomStyles } from 'constants/react-select-style.js';
import { useIntl } from 'react-intl';
import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';

const FallRisk = props => {
	const [expanded, setExpanded] = useState(true);
	const [showEditForm, setShowEditForm] = useState(false);

	const intl = useIntl();

	const fallRiskOptions = [
		{ value: 'low', label: intl.formatMessage({ id: 'low' }) },
		{ value: 'medium', label: intl.formatMessage({ id: 'medium' }) },
		{ value: 'high', label: intl.formatMessage({ id: 'high' }) },
	];

	const getFallRiskValue = () =>
		props.fallRisk
			? {
					value: fallRiskOptions.find(item => item.value === props.fallRisk)?.value,
					label: fallRiskOptions.find(item => item.value === props.fallRisk)?.label,
			  }
			: null;

	return (
		<>
			<div className={classNames('timeline-box-header sub-timeline-box-header', expanded ? 'expanded' : '')}>
				<p className='timeline-box-title'>{translate('fallRisk')}</p>
				<div className='timeline-box-actions'>
					<button
						type='button'
						onClick={() => {
							setShowEditForm(prevState => !prevState);
							setExpanded(true);
						}}>
						<EditIcon color={props.isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayThree} />
					</button>
					<button type='button' onClick={() => setExpanded(prevState => !prevState)}>
						<i className={classNames('material-icons', expanded ? ' rotate' : '')}>expand_more</i>
					</button>
				</div>
			</div>
			{expanded && (
				<>
					{!showEditForm && (
						<table className='patient-table-informations' style={{ width: '100%' }}>
							<tbody>
								<tr>
									<td>{translate('fallRisk')}</td>
									<td>{props.fallRisk && fallRiskOptions.find(item => item.value === props.fallRisk)?.label}</td>
								</tr>
							</tbody>
						</table>
					)}
					{showEditForm && (
						<div className='flex flex-space-between flex-wrap whiteboard-select'>
							<div className='top-15'>
								<ReactSelect
									options={[
										{ value: 'low', label: intl.formatMessage({ id: 'low' }) },
										{ value: 'medium', label: intl.formatMessage({ id: 'medium' }) },
										{ value: 'high', label: intl.formatMessage({ id: 'high' }) },
									]}
									value={getFallRiskValue()}
									onChange={option => props.setData(option.value)}
									id='select'
									classNamePrefix='react-select full-width'
									styles={{
										...generateCustomStyles({ darkMode: props.isDarkMode }),
									}}
									placeholder={intl.formatMessage({ id: 'fallRisk' })}
								/>
							</div>
						</div>
					)}
				</>
			)}
		</>
	);
};

export default FallRisk;
