import React, { useContext, useEffect, useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import queryString from 'query-string';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { enums } from '@solaborate/calls';
import classNames from 'classnames';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import { getUpcomingAppointments } from 'api/appointments.js';
import {
	getPreferredLanguageLocale,
	stringToCamelCase,
	getRequestColor,
	getRequestType,
} from 'infrastructure/helpers/commonHelpers.js';
import translate from 'i18n-translations/translate.jsx';
import Loader from 'components/Loader.jsx';
import ProfilePicture from 'components/ProfilePicture.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { AlertTypes, AssignedRequestTypes, RequestStatus, RequestType } from 'constants/enums.js';
import Alert from 'components/Alert.jsx';
import EmptyState from 'components/EmptyState.jsx';
import SocketEvents from 'constants/socket-events.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import { getMedicAssignedRequests } from 'api/doctorRequests.js';
import { getUserInfo, setUserInfo, getUserId } from 'infrastructure/auth.js';
import { StartQueryStringKeys } from 'calls/enums/index.js';
import { actionCreators as userActionCreators } from 'state/user/actions.js';
import { actionCreators as healthSystemsActionCreators } from 'state/healthSystems/actions.js';
import PopUpAlert from 'components/PopUpAlert.jsx';
import { HelloIcon } from 'calls/icons/index.js';

const DoctorHomePage = () => {
	const socket = useContext(SocketContext);
	const dispatch = useDispatch();
	const [upcomingAppointments, setUpcomingAppointments] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [liveCallRequests, setLiveCallRequests] = useState([]);
	const [error, setError] = useState(null);
	const waitingRoomCount = useSelector(state => state.user.waitingRoomCount);
	const setUserWaitingRoomCount = count => dispatch(userActionCreators.setUserWaitingRoomCount(count));
	const intl = useIntl();
	const history = useHistory();
	const userId = useRef(getUserId());

	const { current: userInfo } = useRef(getUserInfo());
	const healthSystems = useSelector(state => state.healthSystems);

	useEffect(() => {
		const fetchData = async () => {
			const params = {
				userId: userId.current,
				pageIndex: 0,
				pageSize: 4,
			};

			const [response, liveCallRequestsResponse] = await Promise.all([
				getUpcomingAppointments(params),
				getMedicAssignedRequests({
					doctorGuidId: userId.current,
					pageIndex: 0,
					pageSize: 2,
					status: RequestStatus.PENDING,
					type: AssignedRequestTypes.WITHOUT_INTAKE_FORM,
				}),
			]);

			if (response.error || liveCallRequestsResponse.error) {
				const err = response.error || liveCallRequestsResponse.error;
				setError(`Error: ${err}`);
				setIsLoading(false);
				return;
			}
			setUpcomingAppointments(response.upcomingAppointments);
			setLiveCallRequests(liveCallRequestsResponse.assignedRequests);
			setIsLoading(false);
		};

		fetchData();
		socket.on(SocketEvents.HealthCare.ON_MEDIC_REQUEST_UPDATED, onLiveCallRequest);

		return () => {
			socket.off(SocketEvents.HealthCare.ON_MEDIC_REQUEST_UPDATED, onLiveCallRequest);
		};
	}, [userId, socket]);

	const onLiveCallRequest = async data => {
		if (data.userId !== userInfo.userId && liveCallRequests?.length === 2) {
			return;
		}
		setIsLoading(true);
		const liveCallRequestsResponse = await getMedicAssignedRequests({
			doctorGuidId: userId.current,
			pageIndex: 0,
			pageSize: 2,
			status: RequestStatus.PENDING,
		});
		if (liveCallRequestsResponse.error) {
			setError(`Error: ${liveCallRequestsResponse.error.message}`);
			setIsLoading(false);
		} else {
			setLiveCallRequests(liveCallRequestsResponse.assignedRequests);
			setIsLoading(false);
		}
	};

	const joinCall = async (requestConference, item) => {
		setUserInfo({ ...userInfo, incomingCallsDisabled: true });
		const queryParams = queryString.stringify(
			{
				[StartQueryStringKeys.CONFERENCE_ID]: requestConference.conferenceId,
				assignedRequestId: item.id,
			},
			{
				skipNull: true,
			}
		);
		window.open(`/call?${queryParams.toString()}`, '_blank');
		if (liveCallRequests.length) {
			setLiveCallRequests(prevState => prevState.filter(request => request.id !== item.id));
		}
		if (upcomingAppointments.length > 0) {
			setUpcomingAppointments(prevState => prevState.filter(request => request.id !== item.id));
		}

		setUserWaitingRoomCount(waitingRoomCount - 1);
	};

	const getRedirectLink = item =>
		`/call?${new URLSearchParams({
			[StartQueryStringKeys.OBJECT_ID]: item.participantId,
			[StartQueryStringKeys.OBJECT_TYPE]: enums.ObjectTypes.USER,
			[StartQueryStringKeys.CONFERENCE_NAME]: item.participantFullName,
			appointmentId: item.id,
		}).toString()}`;

	const getAlertMessageContent = data => ({
		title: data.measurementAlertType.name,
		contentText: translate('patientHasMeasured', {
			value1: data.patientFullName,
			value2: data.measurementAlertType.name,
		}),
	});

	const handleMeasurementAlertClick = () => {
		if (healthSystems.measurementAlertData.patientUserId) {
			const url = `/patients/${healthSystems.measurementAlertData.patientUserId}`;
			dispatch(healthSystemsActionCreators.setNewMeasurementsAlertData(null));
			history.push(url);
		}
	};

	return (
		<MainLayout>
			<div className='view-page-wrapper home-page-wrapper'>
				{isLoading && (
					<div className='full-width full-height loader-wrapper flex flex-align-center flex-justify-center'>
						<Loader />
					</div>
				)}
				{!isLoading && (
					<div>
						{upcomingAppointments.length === 0 &&
							(liveCallRequests?.length === 0 || liveCallRequests.every(item => !item.request?.requestConference)) && (
								<div className='empty-state-wrapper'>
									<EmptyState
										image='doctor-home.svg'
										title={translate('allCaughtUp')}
										paragraph={translate('noNewAppointmentsOrRequests')}
									/>
								</div>
							)}
						{liveCallRequests?.length > 0 && (
							<div className='home-waiting-room-wrapper'>
								<div className='flex home-title'>
									<h3>{translate('waitingRoom')}</h3>
								</div>
								{liveCallRequests
									?.filter(item => item.request?.requestConference)
									.map(item => (
										<div
											key={item.id}
											className={classNames(
												'home-waiting-room-inner',
												item.request?.requestType === RequestType.RAPID ? 'rapid-request-type' : ''
											)}>
											<div className='flex'>
												<ProfilePicture
													className='doctor-request-img patient-request-profile-img'
													firstName={item.request.patient.firstName}
													lastName={item.request.patient.lastName}
													profilePicture={item.request.patient.profilePicture}
												/>
												<div>
													<h4>
														{item.request.patient.firstName} {item.request.patient.lastName}
													</h4>
													<p>{item.request.description}</p>
												</div>
												<div className={classNames('request-type home-request-type', getRequestType(item.request?.requestType))}>
													<HelloIcon color={getRequestColor(item.request?.requestType)} />
													<span>{translate(getRequestType(item.request?.requestType))}</span>
												</div>
											</div>
											<div className='flex'>
												<div className='flex'>
													{item.request.requestSymptoms.map(elem => {
														const translated = intl.formatMessage({ id: stringToCamelCase(elem.symptom.name) });
														return (
															<div key={elem.id} className='doctor-request-info-btn flex doctor-position'>
																{translated}
															</div>
														);
													})}
												</div>
												<button type='button' onClick={() => joinCall(item?.request?.requestConference, item)}>
													<img src={`${healthCareCdnUrl}homepage/camera-on.svg`} alt='icon' />
													{translate('startCall')}
												</button>
											</div>
										</div>
									))}
							</div>
						)}
						{upcomingAppointments.length > 0 && (
							<>
								<div className='home-upcoming-appointments-wrapper'>
									<div className='flex home-title'>
										<h3>{translate('upcomingAppointments')}</h3>
										<Link to='/appointments'>{translate('goToMyAppointments')}</Link>
									</div>
									<div className='flex upcoming-appointments-doctor doctor-home-page-appointments'>
										{upcomingAppointments.length === 0 && <p>{translate('noUpcomingAppointment')}</p>}
										{upcomingAppointments.length > 0 &&
											upcomingAppointments.map(item => (
												<div className='flex column-direction'>
													<div>
														<p className='bold'>
															{`${intl.formatMessage({ id: 'meetingWithPatient' })} ${item.participantFullName}`}
														</p>
														<p>{item.title}</p>
														<span>
															{translate('from')}{' '}
															{moment.utc(item.startDateTime).local().locale(getPreferredLanguageLocale()).format('hh:mm A')}{' '}
															{translate('to')}{' '}
															{moment.utc(item.endDateTime).local().locale(getPreferredLanguageLocale()).format('hh:mm A')}
														</span>
													</div>
													<Link to={getRedirectLink(item)} target='_blank'>
														<button type='button' className='upcoming-appointment-btn'>
															{translate('callPatient')}
														</button>
													</Link>
												</div>
											))}
									</div>
								</div>
							</>
						)}
					</div>
				)}
			</div>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
			{healthSystems.measurementAlertData && (
				<PopUpAlert
					display={healthSystems.measurementAlertData}
					isRightBottom={true}
					title={getAlertMessageContent(healthSystems.measurementAlertData).title}
					alertType={AlertTypes.DANGER}
					contentText={getAlertMessageContent(healthSystems.measurementAlertData).contentText}
					isSilent={true}
					onTextClick={handleMeasurementAlertClick}
					onAlertClose={() => dispatch(healthSystemsActionCreators.setNewMeasurementsAlertData(null))}
					isMeasurementAlert={true}
				/>
			)}
		</MainLayout>
	);
};

export default DoctorHomePage;
