import { WhiteboardWidgets } from 'constants/enums.js';

export const privacyModeOptions = intl => [
	{ value: 5, label: `5 ${intl.formatMessage({ id: 'minutes' })}` },
	{ value: 10, label: `10 ${intl.formatMessage({ id: 'minutes' })}` },
	{ value: 15, label: `15 ${intl.formatMessage({ id: 'minutes' })}` },
	{ value: 30, label: `30 ${intl.formatMessage({ id: 'minutes' })}` },
];

export const initialWhiteboardData = {
	careTeam: {
		doctors: [],
		nurse: '',
		nursePhone: {
			maskedValue: '',
			unmaskedValue: '',
		},
		pca: '',
		pcaPhone: {
			maskedValue: '',
			unmaskedValue: '',
		},
		member: '',
		familyContact: {
			maskedValue: '',
			unmaskedValue: '',
		},
		caseManager: '',
		caseManagerPhone: {
			maskedValue: '',
			unmaskedValue: '',
		},
	},
	goingHome: {
		timeFrame: null,
		ride: false,
	},
	diet: [],
	painMed: {
		lastDose: '',
		nextDose: '',
	},
	activity: '',
	fallRisk: null,
	question: '',
	painLevel: '',
	currentPainLevel: null,
	safetyAlerts: '',
	newMedications: '',
	whatMetters: '',
	todaysPlan: '',
	bradenScore: {
		sensory: null,
		skin: null,
		activity: null,
		mobility: null,
		nutrition: null,
		friction: null,
	},
};

export const whiteboardWidgetList = [
	{
		id: WhiteboardWidgets.CARE_TEAM,
		name: 'careTeam',
	},
	{ id: WhiteboardWidgets.GOING_HOME, name: 'goingHome' },
	{ id: WhiteboardWidgets.DIET, name: 'diet' },
	{ id: WhiteboardWidgets.PAIN_MED, name: 'painMed' },
	{ id: WhiteboardWidgets.ACTIVITY, name: 'activity' },
	{ id: WhiteboardWidgets.QUESTION, name: 'question' },
	{ id: WhiteboardWidgets.SAFETY_ALERTS, name: 'safetyAlerts' },
	{ id: WhiteboardWidgets.NEW_MEDICATIONS, name: 'newMedications' },
	{ id: WhiteboardWidgets.WHAT_MATTERS, name: 'whatMetters' },
	{ id: WhiteboardWidgets.TODAYS_PLAN, name: 'todaysPlan' },
	{ id: WhiteboardWidgets.PAIN_LEVEL, name: 'painLevel' },
	{ id: WhiteboardWidgets.BRADEN_SCORE, name: 'bradenScore' },
	{ id: WhiteboardWidgets.FALL_RISK, name: 'fallRisk' },
];

export const painLevels = [
	{
		name: '0',
		value: 0,
		desc: 'very happy',
	},
	{
		name: '1-2',
		value: 2,
		desc: 'hurts a little',
	},
	{
		name: '3-4',
		value: 4,
		desc: 'hurts more',
	},
	{
		name: '5-6',
		value: 6,
		desc: 'hurts even more',
	},
	{
		name: '7-8',
		value: 8,
		desc: 'hurts a lot',
	},
	{
		name: '9-10',
		value: 10,
		desc: 'hurts really bad',
	},
];

export const painLevelOptions = [
	{
		label: '0',
		value: '0',
	},
	{
		label: '1-2',
		value: '2',
	},
	{
		label: '3-4',
		value: '4',
	},
	{
		label: '5-6',
		value: '6',
	},
	{
		label: '7-8',
		value: '8',
	},
	{
		label: '9-10',
		value: '10',
	},
];

const memorialBaseUrl = 'https://static.solaborate.com/memorial/logos/';

export const initialIsolations = [
	{
		src: `${memorialBaseUrl}contact.png`,
		name: 'Contact',
		key: 'CON',
	},
	{
		src: `${memorialBaseUrl}contact-plus.png`,
		name: 'Contact Plus',
		key: 'CONP',
	},
	{
		src: `${memorialBaseUrl}droplet.png`,
		name: 'Droplet Precautions',
		key: 'DRLPR',
	},
	{
		src: `${memorialBaseUrl}droplet-plus.png`,
		name: 'Droplet Plus',
		key: 'DRLP',
	},
	{
		src: `${memorialBaseUrl}airborne.png`,
		name: 'Airborne Precautions',
		key: 'ABP',
	},

	{
		src: `${memorialBaseUrl}chemo.png`,
		name: 'Chemo Precautions',
		key: 'CHP',
	},
	{
		src: `${memorialBaseUrl}neutropenic.png`,
		name: 'Neutropenic',
		key: 'NEU',
	},
];

export const isolationOptions = [
	{
		label: 'Contact',
		value: 'CON',
	},
	{
		label: 'Contact Plus',
		value: 'CONP',
	},
	{
		label: 'Droplet Precautions',
		value: 'DRLPR',
	},
	{
		label: 'Droplet Plus',
		value: 'DRLP',
	},
	{
		label: 'Airborne Precautions',
		value: 'ABP',
	},

	{
		label: 'Chemo Precautions',
		value: 'CHP',
	},
	{
		label: 'Neutropenic',
		value: 'NEU',
	},
];

export const goingHomeOptions = intl => [
	{ value: 1, label: intl.formatMessage({ id: 'today' }) },
	{ value: 2, label: intl.formatMessage({ id: 'within24Hours' }) },
	{ value: 3, label: intl.formatMessage({ id: 'is2or3days' }) },
	{ value: 4, label: intl.formatMessage({ id: 'more3days' }) },
];

export const rideAnswerOptions = intl => [
	{ value: true, label: intl.formatMessage({ id: 'rideIsConfirmed' }) },
	{ value: false, label: intl.formatMessage({ id: 'rideIsNotConfirmed' }) },
];

export const sensoryPerceptions = intl => [
	{
		label: intl.formatMessage({ id: 'completelyLimited' }),
		value: 1,
		key: intl.formatMessage({ id: 'completelyLimitedLabel' }),
	},
	{
		label: intl.formatMessage({ id: 'veryLimited' }),
		value: 2,
		key: intl.formatMessage({ id: 'veryLimitedLabel' }),
	},
	{
		label: intl.formatMessage({ id: 'slightlyLimited' }),
		value: 3,
		key: intl.formatMessage({ id: 'slightlyLimitedLabel' }),
	},
	{
		label: intl.formatMessage({ id: 'noImpairment' }),
		value: 4,
		key: intl.formatMessage({ id: 'noImpairmentLabel' }),
	},
];

export const skinMoisture = intl => [
	{
		label: intl.formatMessage({ id: 'constantlyMoistDesc' }),
		value: 1,
		key: intl.formatMessage({ id: 'constantlyMoist' }),
	},
	{
		label: intl.formatMessage({ id: 'veryMoistDesc' }),
		value: 2,
		key: intl.formatMessage({ id: 'veryMoist' }),
	},
	{
		label: intl.formatMessage({ id: 'occasionallyMoistDesc' }),
		value: 3,
		key: intl.formatMessage({ id: 'occasionallyMoist' }),
	},
	{
		label: intl.formatMessage({ id: 'rarelyMoistDesc' }),
		value: 4,
		key: intl.formatMessage({ id: 'rarelyMoist' }),
	},
];

export const activity = intl => [
	{
		label: intl.formatMessage({ id: 'bedfastDesc' }),
		value: 1,
		key: intl.formatMessage({ id: 'bedfast' }),
	},
	{
		label: intl.formatMessage({ id: 'chairfastDesc' }),
		value: 2,
		key: intl.formatMessage({ id: 'chairfast' }),
	},
	{
		label: intl.formatMessage({ id: 'walksOccasionallyDesc' }),
		value: 3,
		key: intl.formatMessage({ id: 'walksOccasionally' }),
	},
	{
		label: intl.formatMessage({ id: 'walksFrequentlyDesc' }),
		value: 4,
		key: intl.formatMessage({ id: 'walksFrequently' }),
	},
];

export const mobility = intl => [
	{
		label: intl.formatMessage({ id: 'completelyImmobileDesc' }),
		value: 1,
		key: intl.formatMessage({ id: 'completelyImmobile' }),
	},
	{
		label: intl.formatMessage({ id: 'veryLimitedDesc' }),
		value: 2,
		key: intl.formatMessage({ id: 'veryLimitedTitle' }),
	},
	{
		label: intl.formatMessage({ id: 'slightlyLimitedDesc' }),
		value: 3,
		key: intl.formatMessage({ id: 'slightlyLimitedTitle' }),
	},
	{
		label: intl.formatMessage({ id: 'noLimitationDesc' }),
		value: 4,
		key: intl.formatMessage({ id: 'noLimitation' }),
	},
];

export const nutrition = intl => [
	{
		label: intl.formatMessage({ id: 'veryPoorDesc' }),
		value: 1,
		key: intl.formatMessage({ id: 'veryPoor' }),
	},
	{
		label: intl.formatMessage({ id: 'probablyInadequateDesc' }),
		value: 2,
		key: intl.formatMessage({ id: 'probablyInadequate' }),
	},
	{
		label: intl.formatMessage({ id: 'adequateDesc' }),
		value: 3,
		key: intl.formatMessage({ id: 'adequate' }),
	},
	{
		label: intl.formatMessage({ id: 'excellentDesc' }),
		value: 4,
		key: intl.formatMessage({ id: 'excellent' }),
	},
];

export const frictionShear = intl => [
	{
		label: intl.formatMessage({ id: 'problemDesc' }),
		value: 1,
		key: intl.formatMessage({ id: 'problem' }),
	},
	{
		label: intl.formatMessage({ id: 'potentialProblemDesc' }),
		value: 2,
		key: intl.formatMessage({ id: 'potentialProblem' }),
	},
	{
		label: intl.formatMessage({ id: 'noApparentProblemDesc' }),
		value: 3,
		key: intl.formatMessage({ id: 'noApparentProblem' }),
	},
];
