import React, { useState } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { getDeviceRebootLogs } from 'api/devices.js';
import { utcToLocalTime } from 'infrastructure/helpers/dateHelper.js';
import CsvExport from 'components/Common/CsvExport';
import Alert from 'components/Alert.jsx';

const hasNameChanges = logs => {
	const hasChanges = {
		user: false,
		device: false,
	};
	logs.forEach(log => {
		if (!hasChanges.user) {
			if (log.user.fullName !== log.user.currentName) {
				hasChanges.user = true;
			}
		}
		if (!hasChanges.device) {
			if (log.device.name !== log.device.currentName) {
				hasChanges.device = true;
			}
		}
	});

	return hasChanges;
};

const exportLogsAsCsv = async deviceId => {
	const logs = await getDeviceRebootLogs(deviceId);
	if (logs.error) {
		return { error: logs.error };
	}
	if (logs.length === 0) {
		return [{ Info: 'This device has no reboot logs yet.' }];
	}
	const hasChanges = hasNameChanges(logs);

	return logs.map(log => {
		const user = log.user.fullName !== log.user.currentName ? `${log.user.fullName}` : '';
		const device = log.device.name !== log.device.currentName ? `${log.device.name}` : '';
		const report = {
			User: log.user.currentName,
		};
		if (hasChanges.user) {
			report['Old user name'] = user;
		}
		report.Device = log.device.currentName;
		if (hasChanges.device) {
			report['Old device name'] = device;
		}

		report['Serial number'] = log.device.sn;
		report.Date = moment(utcToLocalTime(log.createdDate)).format('MM/DD/YYYY-hh:mm A');
		report.Reason = log.reason || 'Not specified';
		return report;
	});
};

const RebootLogExport = ({ solHelloDeviceId, serialNumber }) => {
	const intl = useIntl();
	const [error, setError] = useState('');
	return (
		<>
			<CsvExport
				asyncExportMethod={async () => {
					const logs = await exportLogsAsCsv(solHelloDeviceId);
					if (logs?.error) {
						setError(logs.error);
					}
					return logs;
				}}
				fileName={`${serialNumber}-${(+new Date()).toString()}.csv`}
				className=''>
				<div className='device-option-details'>
					<i
						className='material-icons-outlined boxed-icon device-icon'
						data-cy='exportRebootLogsBtn'
						id={solHelloDeviceId}
						data-position='top'>
						get_app
					</i>
					<span>{intl.formatMessage({ id: 'rebootExportLogsButton' })}</span>
				</div>
			</CsvExport>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};

export default RebootLogExport;
