import React, { useState, useRef, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import { enums, participant } from '@solaborate/calls';
import { ToggleGroup, Modal, Button, CameraBookmarksMayo, Icon } from 'calls/components/index.js';
import { useConferenceConfigurations, useControllerTracks, useHelloDeviceState } from 'calls/hooks/index.js';
import { CameraMaxZoomLevels, CameraTypes } from 'calls/enums/index.js';
import { LOCALES } from 'i18n-translations/locales.js';
import translate from 'i18n-translations/translate.jsx';
import { getPreferredLanguageLocale } from 'infrastructure/helpers/commonHelpers.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';
import { ButtonType, CameraDirections, CameraZoomLevels } from 'constants/enums.js';
import { Dropdown } from 'components/index.js';
import { StyledPTZ, StyledCameraControls } from 'css/StyledComponents/index.js';

const { CameraEventActions } = enums;

/**
 * @param {object} props
 * @param {import('calls/RemoteHelloParticipant.js').default} props.helloDevice
 * @param {string} props.activeTrackType
 */
const CameraControls = ({ helloDevice, activeTrackType }) => {
	const socket = useContext(SocketContext);
	const [isRebootHuddleCamModalOpen, setIsRebootHuddleCamModalOpen] = useState(false);
	const [isSwitchDeviceDisabled, setIsSwitchDeviceDisabled] = useState(false);
	const intl = useIntl();

	const disabledSwitchTimeout = useRef(null);
	const moveCamInterval = useRef(null);
	const conferenceConfigs = useConferenceConfigurations();
	const locale = getPreferredLanguageLocale();

	const deviceState = useHelloDeviceState(helloDevice);
	const selectedCamera = deviceState?.mediaDevices?.find(
		device => device.isActive && [CameraTypes.HELLO, CameraTypes.HUDDLE].includes(device.type)
	);

	const [sliderRange, setSliderRange] = useState(0);
	const [bookmarks, setBookmarks] = useState(deviceState.bookmarkList);
	const [activeBookmark, setActiveBookmark] = useState(null);
	const [availableDirections, setAvailableDirections] = useState(selectedCamera?.capabilities?.tilt?.availableDirection);

	const tracks = useControllerTracks(helloDevice?.remoteTrackController);
	const videoTrack = tracks[activeTrackType];

	useEffect(() => {
		const onEvent = event => {
			if (event instanceof participant.HelloDeviceStateChanged) {
				setAvailableDirections(selectedCamera?.capabilities?.tilt?.availableDirection);
				if (isSwitchDeviceDisabled && selectedCamera?.isActive) {
					setIsSwitchDeviceDisabled(false);
				}
				setActiveBookmark(null);
			}
		};

		helloDevice.on(onEvent);

		return () => {
			helloDevice.off(onEvent);

			if (disabledSwitchTimeout.current) {
				clearTimeout(disabledSwitchTimeout.current);
			}
		};
	}, [helloDevice, deviceState, selectedCamera, isSwitchDeviceDisabled]);

	useEffect(() => {
		const onMediaControlsResponse = data => {
			if (data.command === enums.MediaControlsCommands.BOOKMARK_LIST) {
				setBookmarks(data.data);
				if (activeBookmark) {
					const isActivePresetInList = data?.data.some(item => item.id === activeBookmark?.id);
					if (!isActivePresetInList) {
						setActiveBookmark(null);
					}
				}
			}

			if (data.command === enums.MediaControlsCommands.MOVE_TO_BOOKMARK) {
				const active = bookmarks?.find(x => x.id === data.data);
				setActiveBookmark(active);
			}

			if (data.command === enums.MediaControlsCommands.ACTIVE_DEVICE) {
				setSliderRange(selectedCamera?.capabilities?.zoom?.current);
			}

			if (data.command === enums.MediaControlsCommands.DEVICES_LIST) {
				const incomingBookmarks = data?.data.find(item => item?.capabilities?.bookmarks?.isSupported)?.capabilities?.bookmarks
					?.list;
				const activeCamera = data?.data?.find(
					mediaDevice => mediaDevice.isActive && [CameraTypes.HELLO, CameraTypes.HUDDLE].includes(mediaDevice.type)
				);
				setAvailableDirections(activeCamera?.capabilities?.tilt?.availableDirection);
				setBookmarks(incomingBookmarks || []);
				setSliderRange(activeCamera?.capabilities?.zoom?.current);
			}
		};
		socket.on(SocketEvents.Conference.ON_MEDIA_CONTROLS_RESPONSE, onMediaControlsResponse);

		return () => {
			socket.off(SocketEvents.Conference.ON_MEDIA_CONTROLS_RESPONSE, onMediaControlsResponse);
		};
	}, [socket, bookmarks]);

	const sendDirection = (direction, action) => {
		if (!availableDirections[direction] || !selectedCamera?.capabilities?.tilt?.isSupported) {
			return;
		}
		helloDevice.sendMediaControlsEvent(
			enums.MediaControlsCommands.MOVE,
			enums.MediaTypes.CAMERA,
			JSON.stringify({
				cameraId: selectedCamera?.id,
				direction,
				action,
			})
		);
		enableOppositeDirection(direction);
	};

	const enableOppositeDirection = direction => {
		if (['right', 'left'].includes(direction)) {
			setAvailableDirections({
				...availableDirections,
				right: true,
				left: true,
			});
		} else {
			setAvailableDirections({
				...availableDirections,
				up: true,
				down: true,
			});
		}
	};

	const filteredDevices = deviceState.mediaDevices
		? deviceState.mediaDevices.filter(device => [CameraTypes.HELLO, CameraTypes.HUDDLE].includes(device.type))
		: [];

	const getCameraName = (name, type) => {
		if (CameraTypes.HUDDLE === type) {
			return name || '20x';
		}
		return name || type;
	};

	if (!videoTrack) {
		return <></>;
	}

	const saveHomePosition = () => {
		helloDevice.sendMediaControlsEvent(
			enums.MediaControlsCommands.UPDATE_HOME_POSITION,
			enums.MediaTypes.CAMERA,
			JSON.stringify({
				cameraId: selectedCamera?.id,
			})
		);
	};

	const moveToHomePosition = async event => {
		event.stopPropagation();
		const response = await helloDevice.sendMediaControlsEvent(
			enums.MediaControlsCommands.HOME,
			enums.MediaTypes.CAMERA,
			JSON.stringify({
				cameraId: selectedCamera?.id,
			})
		);
		if (!response.ok) {
			// error
		}
	};

	// send direction event every 500ms so camera won't stop moving
	const handleMoveTimeout = (direction, action) => {
		// clear interval on function call to prevent creating one on every call
		clearInterval(moveCamInterval.current);
		if (action === CameraEventActions.STOP) {
			sendDirection(direction, action);
		} else {
			sendDirection(direction, action);
			moveCamInterval.current = setInterval(() => {
				handleMoveTimeout(direction, action);
			}, 500);
		}
	};

	const directionIcon = {
		[CameraDirections.UP]: 'keyboard_arrow_up',
		[CameraDirections.RIGHT]: 'keyboard_arrow_right',
		[CameraDirections.LEFT]: 'keyboard_arrow_left',
		[CameraDirections.DOWN]: 'keyboard_arrow_down',
	};

	const isMouseDown = document.body.matches(':active');

	return (
		<>
			<StyledPTZ isRightToLeft={locale === LOCALES.ARABIC} isDarkMode={conferenceConfigs.isDarkMode}>
				{filteredDevices.length > 1 && (
					<header>
						<ToggleGroup>
							{filteredDevices.map(({ id, name, isActive, capabilities }) => (
								<ToggleGroup.Item
									key={id}
									onChange={() => {
										if (isSwitchDeviceDisabled) {
											return;
										}

										setIsSwitchDeviceDisabled(true);

										// If no response is received from Hello enable switch after 3 seconds
										if (disabledSwitchTimeout.current) {
											clearTimeout(disabledSwitchTimeout.current);
										}

										disabledSwitchTimeout.current = setTimeout(() => {
											setIsSwitchDeviceDisabled(false);
										}, 3000);
										helloDevice.sendMediaControlsEvent(enums.MediaControlsCommands.ACTIVE_DEVICE, enums.MediaTypes.CAMERA, id);
									}}
									disabled={isSwitchDeviceDisabled}
									name={name}
									checked={isActive}>
									{getCameraName(capabilities.customName, name)}
								</ToggleGroup.Item>
							))}
						</ToggleGroup>
					</header>
				)}
				{selectedCamera?.capabilities?.tilt?.isSupported && (
					<>
						<StyledCameraControls isDarkMode={conferenceConfigs.isDarkMode}>
							<div>
								{Object.values(CameraDirections).map(direction => (
									<button
										key={direction}
										type='button'
										onMouseDown={() => {
											handleMoveTimeout(direction, CameraEventActions.START);
										}}
										onMouseUp={() => {
											handleMoveTimeout(direction, CameraEventActions.STOP);
										}}
										onMouseOut={() => {
											if (!isMouseDown) {
												return;
											}
											handleMoveTimeout(direction, CameraEventActions.STOP);
										}}
										disabled={availableDirections && availableDirections[direction] === false}
										onBlur={() => {}}>
										<Icon name={directionIcon[direction]} />
									</button>
								))}
								<div>
									{selectedCamera?.capabilities?.home?.isSupported && (
										<button type='button' onClick={moveToHomePosition}>
											<Icon name='home' />
										</button>
									)}
								</div>
							</div>
							<div>
								<input
									type='range'
									min={0}
									max={selectedCamera?.capabilities?.zoom?.levels}
									step={1}
									value={sliderRange}
									onChange={evt => {
										evt.stopPropagation();
										setSliderRange(+evt.target.value);
									}}
									onMouseUp={evt => {
										helloDevice.sendMediaControlsEvent(
											enums.MediaControlsCommands.ZOOM,
											enums.MediaTypes.CAMERA,
											JSON.stringify({
												cameraId: selectedCamera?.id,
												level: +evt.target.value,
											})
										);
									}}
									onTouchEnd={evt => {
										helloDevice.sendMediaControlsEvent(
											enums.MediaControlsCommands.ZOOM,
											enums.MediaTypes.CAMERA,
											JSON.stringify({
												cameraId: selectedCamera?.id,
												level: +evt.target.value,
											})
										);
									}}
									disabled={selectedCamera?.capabilities?.zoom <= CameraZoomLevels.NO_ZOOM}
								/>
								{selectedCamera?.capabilities?.reboot?.isSupported && selectedCamera?.capabilities?.home?.isSupported && (
									<Dropdown position='right' icon='more_vert'>
										<div>
											<div>
												<button
													type='button'
													onClick={saveHomePosition}
													disabled={!selectedCamera?.capabilities?.home?.isSupported}>
													<Icon name='my_location' />
													{intl.formatMessage({ id: 'updateToCurrentPosition' })}
												</button>
											</div>
											<div>
												<button
													type='button'
													onClick={evt => {
														evt.stopPropagation();
														setIsRebootHuddleCamModalOpen(true);
													}}
													disabled={!selectedCamera?.capabilities?.reboot?.isSupported}>
													<Icon name='refresh' />
													{intl.formatMessage({ id: 'resetCamera' })}
												</button>
											</div>
										</div>
									</Dropdown>
								)}
							</div>
						</StyledCameraControls>
						{selectedCamera?.capabilities?.bookmarks?.isSupported && (
							<CameraBookmarksMayo
								selectedCamera={selectedCamera}
								helloDevice={helloDevice}
								activeBookmark={activeBookmark}
								bookmarks={bookmarks}
							/>
						)}
					</>
				)}
			</StyledPTZ>
			{isRebootHuddleCamModalOpen && (
				<Modal onDismiss={() => setIsRebootHuddleCamModalOpen(false)} title={intl.formatMessage({ id: 'rebootCamera' })}>
					<Modal.Content>
						<p>
							{translate('areYouSureRebootHuddleCam', {
								value: filteredDevices.find(device => device?.name === CameraTypes.HUDDLE)?.capabilities?.customName || '20x',
							})}
						</p>
					</Modal.Content>
					<Modal.Actions>
						<Button
							variant={ButtonType.SUBMIT}
							onClick={() => {
								if (deviceState.isHuddleConnected) {
									helloDevice.sendMediaControlsEvent(
										enums.MediaControlsCommands.REBOOT,
										enums.MediaTypes.CAMERA,
										JSON.stringify({
											cameraId: selectedCamera?.id,
										})
									);
									setIsRebootHuddleCamModalOpen(false);
								}
							}}>
							{translate('reboot')}
						</Button>
					</Modal.Actions>
				</Modal>
			)}
		</>
	);
};

export default CameraControls;
