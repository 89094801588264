import * as React from 'react';
import LightTheme from 'calls/styles/LightTheme.js';

function FitToScreen(props) {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} fill='none' {...props}>
			<rect x={3.602} y={3.6} width={16.8} height={16.8} rx={2} stroke={LightTheme.colors.grayFive} strokeWidth={1.5} />
			<path
				d='M10.8 10.8h-3m3 0v-3m0 3L6.6 6.6M13.203 13.2h3m-3 0v3m0-3l4.2 4.2'
				stroke={LightTheme.colors.grayFive}
				strokeWidth={1.5}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}

export default FitToScreen;
