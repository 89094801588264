import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import { getCompanyId, getUserInfo, getUserRole } from 'infrastructure/auth.js';
import Table from 'components/Table.jsx';
import Modal from 'components/Modal.jsx';
import Grid from 'components/Grid.jsx';
import Button from 'components/Button.jsx';
import Alert from 'components/Alert.jsx';
import Pagination from 'components/Common/Pagination.jsx';
import {
	ObjectType,
	CallStatus,
	DeviceListLevel,
	ParticipantState,
	UserRoles,
	TerminateRequestFailureReasonMessages,
	TerminateRequestFailureReason,
	CallTypesMessage,
} from 'constants/enums.js';
import SocketEvents from 'constants/socket-events.js';
import { getDeviceOngoingConferencesByLevel } from 'api/callLogs.js';
import { getHealthSystems } from 'api/healthSystems.js';
import { getPreferredLanguageLocale } from 'infrastructure/helpers/commonHelpers.js';
import translate from 'i18n-translations/translate.jsx';
import { getCallStatus, getCallType, getParticipantState, getSessionTypeString } from 'infrastructure/helpers/callLogsHelper.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';

const OngoingCallsList = () => {
	const intl = useIntl();
	const [logs, setLogs] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0, totalCount: 0 });
	const [currentLevel, setCurrentLevel] = useState(DeviceListLevel.ORGANIZATION);
	const [currentTeamId, setCurrentTeamId] = useState(null);
	const [showListOfRoomsModal, setShowListOfRoomsModal] = useState(false);
	const [showTerminateCallModal, setShowTerminateCallModal] = useState(false);
	const [showParticipantTerminateCallModal, setShowParticipantTerminateCallModal] = useState(false);
	const [isTerminateCallModalLoading, setIsTerminateCallModalLoading] = useState(false);
	const [terminateRequestFailureReason, setTerminateRequestFailureReason] = useState(null);
	const [terminateRequestSubmitButtonText, setTerminateRequestSubmitButtonText] = useState(intl.formatMessage({ id: 'endCall' }));
	const [terminateRequestCloseButtonText, setTerminateRequestCloseButtonText] = useState(intl.formatMessage({ id: 'cancel' }));
	const [terminateRequestInProgress, setTerminateRequestInProgress] = useState(false);
	const [selectedConferenceId, setSelectedConferenceId] = useState(null);
	const [selectedParticipantId, setSelectedParticipantId] = useState(null);
	const [error, setError] = useState(null);
	const socket = useContext(SocketContext);

	let terminateRequestTimeout = null;

	useEffect(() => {
		getOngoingCalls();
		socket.on(SocketEvents.Conference.ON_TERMINATE_REQUEST_ACCEPTED, onTerminateRequestAcceptedHandler);
		bindWindowEventListener();
		return () => {
			clearTerminateRequestTimeout();
			socket.off(SocketEvents.Conference.ON_TERMINATE_REQUEST_ACCEPTED, onTerminateRequestAcceptedHandler);
		};
	}, []);

	const getOngoingCalls = async () => {
		const userRole = getUserRole();
		const healthSystems = await getHealthSystems();
		if (healthSystems.error) {
			setError(healthSystems.error.message);
			return;
		}
		let currentLevelSelected = DeviceListLevel.ORGANIZATION;
		let currentTeamIdSelected;
		if (userRole !== UserRoles.ADMIN) {
			currentLevelSelected = DeviceListLevel.HEALTH_SYSTEM;
			currentTeamIdSelected = healthSystems[0].id;
		}
		const conferenceLevel = await getDeviceOngoingConferencesByLevel({ level: currentLevelSelected, id: currentTeamIdSelected });
		if (conferenceLevel.error) {
			setError(conferenceLevel.error.message);
			return;
		}
		const logsList = getLogs(conferenceLevel.conferences);
		setLogs(logsList);
		setIsLoading(false);
		setPagination(prevState => ({ ...prevState, pageIndex: conferenceLevel.pageIndex, totalCount: conferenceLevel.totalCount }));
		setCurrentLevel(currentLevelSelected);
		setCurrentTeamId(currentTeamIdSelected);
	};

	const onTerminateRequestAcceptedHandler = data => {
		if (selectedConferenceId === data.conferenceId) {
			clearTerminateRequestTimeout();
			const logsList = removeConferenceFromLogs(data.conferenceId);
			setIsTerminateCallModalLoading(false);
			setTerminateRequestSubmitButtonText('');
			setTerminateRequestInProgress(false);
			setTerminateRequestCloseButtonText(intl.formatMessage({ id: 'close' }));
			setLogs(logsList);
		}
	};

	const bindWindowEventListener = () => {
		window.addEventListener('beforeunload', event => {
			if (terminateRequestInProgress) {
				event.preventDefault();
				// eslint-disable-next-line no-param-reassign
				event.returnValue = '';
			}
		});

		window.addEventListener('unload', () => {
			if (terminateRequestInProgress) {
				sendTerminateRequestCloseEvent();
			}
		});
	};

	const isSuccessfulCall = status =>
		!(status === CallStatus.FAILED.type || status === CallStatus.MISSED.type || status === CallStatus.ABORTED.type);

	const getVirtualCareProvider = (participants, status) => {
		if (status === CallStatus.SUCCESSFUL.type && participants.length > 2) {
			return participants.find(
				participant =>
					participant.objectType === ObjectType.USER &&
					(participant.state === ParticipantState.CONNECTED.type ||
						participant.state === ParticipantState.LEFT_CALL.type ||
						participant.state === ParticipantState.CONNECTING.type ||
						participants.state === ParticipantState.DISRUPTED.type)
			);
		}
		return participants.find(participant => participant.objectType === ObjectType.USER);
	};

	const getPatientRoom = participants => participants.find(participant => participant.objectType === ObjectType.HELLO_DEVICE);

	const getLogs = conferences => {
		const filtered = conferences.filter(
			conference => typeof getVirtualCareProvider(conference.participants, conference.status) !== 'undefined'
		);
		return filtered.map(conference => {
			const room = getPatientRoom(conference.participants);
			const vcp = getVirtualCareProvider(conference.participants, conference.status);
			const status = getCallStatus(conference.status);
			const startedAt = moment
				.utc(conference.startedAt)
				.local()
				.locale(getPreferredLanguageLocale())
				.format('MM/DD/YYYY-hh:mm A');
			const initiator = vcp.isInitiator ? translate('virtualCareProvider') : translate('patient');
			return {
				id: conference.id,
				initiator: initiator,
				provider: room?.isInitiator && !isSuccessfulCall(conference.status) ? 'N/A' : vcp.name,
				room: room?.name,
				patient: room?.deviceOwner || '',
				startedAt: startedAt,
				origin: vcp.isInitiator ? translate('outgoing') : translate('incoming'),
				status: status,
				callType: getCallType(conference.callType),
				duration: conference.callDuration,
				usedAudio: { room: room?.usedAudio, vcp: vcp.usedAudio },
				usedVideo: { room: room?.usedVideo, vcp: vcp.usedVideo },
				usedScreen: { room: room?.usedScreen, vcp: vcp.usedScreen },
				participants: conference.participants,
				queue: room?.isInitiator ? conference.participants.filter(participant => participant.objectType === ObjectType.USER) : [],
				careEventType: conference.careEventType,
			};
		});
	};

	const getTableRows = () =>
		logs.map(log => ({
			id: log.id,
			conferenceId: log.id,
			initiator: log.initiator,
			provider: log.queue.some(q => !q.isInitiator) ? 'N/A' : log.provider,
			room:
				log.callType === CallTypesMessage.MONITORING ? (
					<span className='text-underline cursor-pointer' onClick={() => openListOfRoomsModal(log.id)}>
						{translate('viewAll')}
					</span>
				) : (
					log.room
				),
			patient: log.patient,
			date: (
				<div style={{ fontSize: '12px' }}>
					<span className='block'>{log.startedAt}</span>
				</div>
			),
			origin: log.origin,
			duration: log.duration,
			callType: log.callType,
			careEventType: log.careEventType || 'None',
			terminateCall: (
				<div className='wrapped'>
					<i className='material-icons-outlined boxed-icon' onClick={() => openForceTerminateCallModal(log.id)}>
						highlight_off
					</i>
				</div>
			),
		}));

	const onChange = async (pageSize, pageIndex) => {
		setIsLoading(true);
		const conferenceLevel = await getDeviceOngoingConferencesByLevel({
			level: currentLevel,
			id: currentTeamId,
			pageSize,
			pageIndex,
		});
		if (conferenceLevel.error) {
			setError(conferenceLevel.error.message);
			return;
		}
		const logsList = getLogs(conferenceLevel.conferences);
		setLogs(logsList);
		setIsLoading(false);
		setPagination(prevState => ({ ...prevState, pageIndex, pageSize, totalCount: conferenceLevel.totalCount }));
	};

	const openForceTerminateCallModal = conferenceId => {
		setShowTerminateCallModal(true);
		setTerminateRequestSubmitButtonText(intl.formatMessage({ id: 'endCall' }));
		setSelectedConferenceId(conferenceId);
	};

	const openParticipantForceTerminateCallModal = (conferenceId, participantId) => {
		setShowParticipantTerminateCallModal(true);
		setTerminateRequestSubmitButtonText(intl.formatMessage({ id: 'endCallForRoom' }));
		setSelectedParticipantId(participantId);
		setSelectedConferenceId(conferenceId);
		setShowListOfRoomsModal(false);
	};

	const closeParticipantTerminalCallModal = () => {
		if (terminateRequestInProgress) {
			sendTerminateRequestCloseEvent();
		}
		clearTerminateRequestTimeout();
		setShowParticipantTerminateCallModal(false);
		setIsTerminateCallModalLoading(false);
		setTerminateRequestFailureReason(null);
		setTerminateRequestSubmitButtonText('');
		setTerminateRequestCloseButtonText(intl.formatMessage({ id: 'close' }));
		setTerminateRequestInProgress(false);
		setSelectedParticipantId(null);
	};

	const closeTerminalCallModal = () => {
		if (terminateRequestInProgress) {
			sendTerminateRequestCloseEvent();
		}
		clearTerminateRequestTimeout();
		setShowTerminateCallModal(false);
		setIsTerminateCallModalLoading(false);
		setTerminateRequestFailureReason(null);
		setTerminateRequestSubmitButtonText('');
		setTerminateRequestCloseButtonText(intl.formatMessage({ id: 'close' }));
		setTerminateRequestInProgress(false);
		setSelectedConferenceId(null);
	};

	const failedTerminateConferenceHandler = response => {
		clearTerminateRequestTimeout();
		setIsTerminateCallModalLoading(false);
		setTerminateRequestFailureReason(response.failureReason);
		setTerminateRequestSubmitButtonText('');
		setTerminateRequestInProgress(false);
		if (response.failureReason === TerminateRequestFailureReason.NULL_CONFERENCE) {
			const logsList = removeConferenceFromLogs(selectedConferenceId);
			setLogs(logsList);
			setTerminateRequestSubmitButtonText(intl.formatMessage({ id: 'close' }));
		} else if (
			[TerminateRequestFailureReason.UNHANDLED_EXCEPTION, TerminateRequestFailureReason.NULL_CONNECTED_USER_PARTICIPANT].includes(
				response.failureReason
			)
		) {
			setTerminateRequestSubmitButtonText(intl.formatMessage({ id: 'retry' }));
		}
	};

	const onForceTerminalCallModalSubmit = async () => {
		setIsTerminateCallModalLoading(true);
		setTerminateRequestSubmitButtonText(intl.formatMessage({ id: 'retry' }));
		setTerminateRequestInProgress(true);
		const userProfile = getUserInfo();
		const user = {
			fullName: `${userProfile.firstName} ${userProfile.lastName}`,
		};
		// TODO check for tenantID if not needed
		socket.emit(
			SocketEvents.Conference.FORCE_TERMINATE,
			{ conferenceId: selectedConferenceId, user, tenantId: getCompanyId() },
			response => {
				if (!response.ok) {
					failedTerminateConferenceHandler(response);
					return;
				}
				const logsList = removeConferenceFromLogs(selectedConferenceId);
				setIsTerminateCallModalLoading(false);
				setTerminateRequestSubmitButtonText('');
				setTerminateRequestInProgress(false);
				setTerminateRequestCloseButtonText(intl.formatMessage({ id: 'close' }));
				setLogs(logsList);
			}
		);
	};

	const onParticipantForceTerminalCallModalSubmit = () => {
		setIsTerminateCallModalLoading(true);
		setTerminateRequestSubmitButtonText(intl.formatMessage({ id: 'retry' }));
		setTerminateRequestInProgress(true);
		const userProfile = getUserInfo();
		const user = {
			fullName: `${userProfile.firstName} ${userProfile.lastName}`,
		};
		socket.emit(SocketEvents.Conference.PARTICIPANT_FORCE_TERMINATE, { participantId: selectedParticipantId, user }, response => {
			if (!response.ok) {
				failedTerminateConferenceHandler(response);
				return;
			}
			const logsList = changeStatusOfParticipantLogs(selectedParticipantId);
			setIsTerminateCallModalLoading(false);
			setTerminateRequestSubmitButtonText('');
			setTerminateRequestInProgress(false);
			setTerminateRequestCloseButtonText(intl.formatMessage({ id: 'close' }));
			setLogs(logsList);
		});
	};

	const onTerminalCallModalSubmit = async () => {
		setIsTerminateCallModalLoading(true);
		setTerminateRequestSubmitButtonText(intl.formatMessage({ id: 'retry' }));
		setTerminateRequestInProgress(true);
		socket.emit(SocketEvents.Conference.TERMINATE_REQUEST, { conferenceId: selectedConferenceId }, response => {
			if (!response.ok) {
				failedTerminateConferenceHandler(response);
				return null;
			}
			terminateRequestTimeout = setTimeout(() => {
				clearTerminateRequestTimeout();
				setIsTerminateCallModalLoading(false);
			}, 30000);
			return null;
		});
	};

	const clearTerminateRequestTimeout = () => {
		if (terminateRequestTimeout) {
			clearTimeout(terminateRequestTimeout);
			terminateRequestTimeout = null;
		}
	};

	const sendTerminateRequestCloseEvent = () => {
		socket.emit(SocketEvents.Conference.TERMINATE_REQUEST_CANCEL, { conferenceId: selectedConferenceId });
	};

	const removeConferenceFromLogs = conferenceId => logs.filter(log => log.id !== conferenceId);

	const changeStatusOfParticipantLogs = participantId => {
		const logsList = [...logs];
		const log = logsList.find(conference => conference.id === selectedConferenceId);
		const participant = log.participants.find(x => x.id === participantId);
		participant.state = ParticipantState.REMOVED.type;
		return logsList;
	};

	const tableHeaders = [
		{ title: 'ID', id: 0 },
		{ title: translate('callInitiator'), id: 1 },
		{ title: translate('virtualCareProvider'), id: 2 },
		{ title: translate('room'), id: 3 },
		{ title: translate('patient'), id: 4 },
		{ title: translate('dateTime'), id: 5 },
		{ title: translate('callOrigin'), id: 6 },
		{ title: translate('callDurationSoFar'), id: 7 },
		{ title: translate('callType'), id: 8 },
		{ title: translate('careEventType'), id: 9 },
		{ edit: '', id: 10 },
	];

	const openListOfRoomsModal = conferenceId => {
		setSelectedConferenceId(conferenceId);
		setShowListOfRoomsModal(true);
	};

	const closeListOfRoomsModal = () => {
		setShowListOfRoomsModal(false);
		setSelectedConferenceId(null);
	};

	const showListOfRooms = () => {
		const headers = [
			{ title: translate('room'), id: 0 },
			{ title: translate('status'), id: 1 },
			{ title: translate('sessionType'), id: 2 },
			{ title: '', id: 3 },
		];
		const conference = logs.find(c => c.id === selectedConferenceId);
		if (!conference || !conference.participants) {
			// eslint-disable-next-line no-console
			return console.error('Error while getting list of rooms!');
		}
		const rooms = conference.participants.reduce((acc, participant) => {
			if (participant.objectType === ObjectType.HELLO_DEVICE) {
				acc.push({
					name: participant.name,
					status: getParticipantState(participant.state),
					id: participant.id,
					sessionType: getSessionTypeString(participant.sessionType),
					terminateCall: (
						<>
							{participant.state !== ParticipantState.REMOVED.type && (
								<div className='wrapped'>
									<i
										className='material-icons-outlined boxed-icon'
										onClick={() => openParticipantForceTerminateCallModal(conference.id, participant.id)}>
										highlight_off
									</i>
								</div>
							)}
						</>
					),
				});
			}
			return acc;
		}, []);
		return <Table isLoading={isLoading} headers={headers} rows={rooms} tableEmptyText={translate('noRooms')} />;
	};

	return (
		<div>
			<Table
				isLoading={isLoading}
				headers={tableHeaders}
				rows={isLoading ? [] : getTableRows()}
				tableEmptyText={translate('noOngoingConferences')}>
				<Grid vertAlign='center'>
					<Button
						text={translate('refresh')}
						isLoading={isLoading}
						horizAlign='end'
						onClick={() => onChange(pagination.pageSize, pagination.pageIndex)}
						className='capitalize-text'
					/>
				</Grid>
			</Table>
			<Pagination
				totalCount={pagination.totalCount}
				pageSize={pagination.pageSize}
				pageIndex={pagination.pageIndex}
				onChange={(pageSize, pageIndex) => onChange(pageSize, pageIndex)}
			/>
			<Modal
				isLoading={isTerminateCallModalLoading}
				display={showTerminateCallModal}
				position='center'
				primaryButtonLabel={terminateRequestSubmitButtonText}
				closeButtonText={terminateRequestCloseButtonText}
				onModalSubmit={onForceTerminalCallModalSubmit}
				onModalClose={closeTerminalCallModal}
				className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal'
				submitImgIcon={`${healthCareCdnUrl}appointments/save-icon.svg`}
				closeImgIcon={`${healthCareCdnUrl}appointments/cancel-appointment.svg`}>
				<form>
					{!terminateRequestFailureReason && (
						<>
							{terminateRequestSubmitButtonText === intl.formatMessage({ id: 'endCall' }) && (
								<>
									<h3>{translate('terminateSession')}</h3>
									<p>{translate('areYouSureToTerminate')}</p>
								</>
							)}
							{terminateRequestSubmitButtonText === intl.formatMessage({ id: 'retry' }) && (
								<>
									<h3>{translate('terminateRequestTimeOut')}</h3>
									<p>{translate('retryRequest')}</p>
								</>
							)}

							{terminateRequestSubmitButtonText === '' && (
								<>
									<h3>{translate('done')}</h3>
									<p>{translate('sessionTerminatedSuccessfully')}</p>
								</>
							)}
						</>
					)}
					{terminateRequestFailureReason !== null && (
						<>
							<h3>{translate('somethingWentWrong')}</h3>
							<p>{TerminateRequestFailureReasonMessages[terminateRequestFailureReason]}</p>
						</>
					)}
				</form>
			</Modal>
			<Modal
				isLoading={isTerminateCallModalLoading}
				display={showParticipantTerminateCallModal}
				position='center'
				primaryButtonLabel={terminateRequestSubmitButtonText}
				closeButtonText={terminateRequestCloseButtonText}
				onModalSubmit={onParticipantForceTerminalCallModalSubmit}
				onModalClose={closeParticipantTerminalCallModal}
				className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal'
				submitImgIcon={`${healthCareCdnUrl}appointments/save-icon.svg`}
				closeImgIcon={`${healthCareCdnUrl}appointments/cancel-appointment.svg`}>
				<form>
					{!terminateRequestFailureReason && (
						<>
							{terminateRequestSubmitButtonText === intl.formatMessage({ id: 'endCallForRoom' }) && (
								<>
									<h3>{translate('terminateRoomCall')}</h3>
									<p>{translate('terminateRoomSession')}</p>
								</>
							)}
							{terminateRequestSubmitButtonText === intl.formatMessage({ id: 'retry' }) && (
								<>
									<h3>{translate('terminateRequestTimeOut')}</h3>
									<p>{translate('retryRequest')}</p>
								</>
							)}

							{terminateRequestSubmitButtonText === '' && (
								<>
									<h3>{translate('done')}</h3>
									<p>{translate('roomSessionTerminated')}</p>
								</>
							)}
						</>
					)}
					{terminateRequestFailureReason !== null && (
						<>
							<h3>{translate('somethingWentWrong')}</h3>
							<p>{TerminateRequestFailureReasonMessages[terminateRequestFailureReason]}</p>
						</>
					)}
				</form>
			</Modal>
			<Modal
				display={showListOfRoomsModal}
				position='center'
				hideActionButtons={true}
				onModalSubmit={onTerminalCallModalSubmit}
				onModalClose={closeListOfRoomsModal}>
				<form>
					<h3>{translate('listOfRooms')}</h3>
					{showListOfRoomsModal && showListOfRooms()}
				</form>
			</Modal>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</div>
	);
};

export default OngoingCallsList;
