import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { enums } from '@solaborate/calls';
import FormInput from 'components/FormInput.jsx';
import ProfilePicture from 'components/ProfilePicture.jsx';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import {
	MeasurementTypes,
	SortByNameValues,
	RiskLevels,
	RiskLevelValues,
	Gender,
	UnitSystemTypes,
	DeviceFamilyTypes,
} from 'constants/enums.js';
import Dropdown from 'components/Dropdown.jsx';
import { getRecent } from 'api/messenger.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { getUserId, getUserRole } from 'infrastructure/auth.js';
import { getUserMeasurementUnits, getVitalSignPatients } from 'api/measurements.js';
import { convertMeasurementTypes } from 'infrastructure/helpers/measurementsHelper.js';
import { getGender, findSectorById } from 'infrastructure/helpers/commonHelpers.js';
import Modal from 'components/Modal.jsx';
import translate from 'i18n-translations/translate.jsx';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import Grid from 'components/Grid.jsx';
import Loader from 'components/Loader.jsx';
import SocketEvents from 'constants/socket-events.js';
import HelloItems from 'components/HelloItems.jsx';
import { defaultDateFormat } from 'infrastructure/helpers/dateHelper.js';
import Alert from 'components/Alert.jsx';
import AlertsModal from 'containers/VitalSignsMonitoring/AlertsModal.jsx';
import { StartQueryStringKeys, UserRoles } from 'calls/enums/index.js';
import { actionCreators as patientActionCreators } from 'state/patients/actions.js';
import { getPatientByUserId } from 'api/patients.js';
import { getCountries } from 'api/users.js';
import ViewPatientProfile from 'containers/CheckInAPatient/ViewPatientProfile.jsx';

const VitalSignsMonitoring = () => {
	const [searchBox, setSearchBox] = useState('');
	const [errorApiResponse, setErrorApiResponse] = useState(null);
	const [vitalSigns, setVitalSigns] = useState(null);
	const [vitalSignsMonitoringPatients, setVitalSignsMonitoringPatients] = useState([]);
	const [sortByNameValue, setSortByNameValue] = useState(null);
	const [preferredUnits, setPreferredUnits] = useState([]);
	const [isViewProfileModalVisible, setViewProfileModalVisibility] = useState(false);
	const [selectedPatient, setSelectedPatient] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [riskType, setRiskType] = useState(null);
	const [conversations, setConversations] = useState([]);
	const [isDevicesListModalVisible, setDevicesListModalVisibility] = useState(false);
	const [isAlertsModalOpen, setAlertsModalVisibility] = useState(false);
	const dispatch = useDispatch();
	const setPatient = item => dispatch(patientActionCreators.setSelectedPatient(item));
	const [patientDetails, setPatientDetails] = useState(null);
	const [selectedCountry, setSelectedCountry] = useState(null);
	const [countries, setCountries] = useState([]);

	const userId = getUserId();

	const imageUrl = `${healthCareCdnUrl}vsm/`;

	const intl = useIntl();

	const socket = useContext(SocketContext);

	const tree = useSelector(state => state.healthSystems.treeData.tree);

	const userSession = useSelector(state => state.user.userSession);

	const helloName = useSelector(state => state.company.companySettings.helloName);

	const vitalSignsList = [
		{
			id: 0,
			type: MeasurementTypes.BLOOD_PRESSURE,
			icon: `${imageUrl}Heart-1.svg`,
			warningIcon: `${imageUrl}Heart-2.svg`,
			riskIcon: `${imageUrl}Heart.svg`,
		},
		{
			id: 1,
			type: MeasurementTypes.HEART_RATE,
			icon: `${imageUrl}Heart-1.svg`,
			warningIcon: `${imageUrl}Heart-2.svg`,
			riskIcon: `${imageUrl}Heart.svg`,
		},
		{
			id: 2,
			type: MeasurementTypes.OXYGEN,
			icon: `${imageUrl}Respiratory-1.svg`,
			warningIcon: `${imageUrl}Respiratory-2.svg`,
			riskIcon: `${imageUrl}Respiratory.svg`,
		},
		{
			id: 3,
			type: MeasurementTypes.DIABETES,
			icon: `${imageUrl}OtherData-1.svg`,
			warningIcon: `${imageUrl}OtherData-2.svg`,
			riskIcon: `${imageUrl}OtherData.svg`,
		},
		{
			id: 4,
			type: MeasurementTypes.TEMPERATURE,
			icon: `${imageUrl}Body-Measurements-1.svg`,
			warningIcon: `${imageUrl}Body-Measurements-2.svg`,
			riskIcon: `${imageUrl}Body-Measurements.svg`,
		},
		{
			id: 5,
			type: MeasurementTypes.BODY_MASS_INDEX,
			icon: `${imageUrl}Body-Measurements-1.svg`,
			warningIcon: `${imageUrl}Body-Measurements-2.svg`,
			riskIcon: `${imageUrl}Body-Measurements.svg`,
		},
	];

	const getCategoryPreference = useCallback(categoryId => preferredUnits.find(item => item.unitCategoryId === categoryId), [
		preferredUnits,
	]);

	const getPatientDetails = async patientId => {
		if (!patientId) {
			return;
		}
		const [patientProfileDetails, countriesList] = await Promise.all([getPatientByUserId(patientId), getCountries()]);
		const responseError = patientProfileDetails.error || countriesList.error;
		if (responseError) {
			return;
		}
		setSelectedCountry(countriesList.countries.find(item => item.code === patientProfileDetails.patient.profile?.country)?.name);
		setCountries(countriesList.countries);
		setPatientDetails(patientProfileDetails.patient);
		setViewProfileModalVisibility(true);
	};

	const getUnitPreference = useCallback(
		categoryId => {
			const selectedPreference = getCategoryPreference(categoryId);
			return selectedPreference?.options.find(item => item.unitSystemId === selectedPreference.unitSystemId);
		},
		[getCategoryPreference]
	);

	const getVitalSignMeasurementRiskLevels = useCallback(
		(type, measurementValue, unitCategoryId) => {
			const high = RiskLevelValues.HIGH;
			const moderate = RiskLevelValues.MODERATE;
			const normal = RiskLevelValues.NORMAL;
			let value = parseInt(measurementValue, 10);

			if (type === MeasurementTypes.HEART_RATE) {
				if (value > 100 || value < 60) {
					return high;
				}
				if (value > 80 && value < 100) {
					return moderate;
				}
				return normal;
			}
			if (type === MeasurementTypes.OXYGEN) {
				if (value < 80) {
					return high;
				}
				if (value < 94 && value > 80) {
					return moderate;
				}
				return normal;
			}
			if (type === MeasurementTypes.DIABETES) {
				if (getCategoryPreference(unitCategoryId)?.unitSystemId !== UnitSystemTypes.METRIC) {
					value = convertMeasurementTypes(unitCategoryId, value, getCategoryPreference(unitCategoryId)?.unitSystemId);
				}
				if (value > 11) {
					return high;
				}
				if (value > 7.8 && value < 11) {
					return moderate;
				}
				return normal;
			}
			if (type === MeasurementTypes.TEMPERATURE) {
				if (getCategoryPreference(unitCategoryId)?.unitSystemId !== UnitSystemTypes.METRIC) {
					value = convertMeasurementTypes(unitCategoryId, value, getCategoryPreference(unitCategoryId)?.unitSystemId);
				}
				if (value > 36.1 && value < 37.2) {
					return normal;
				}
				return high;
			}
			if (type === MeasurementTypes.BLOOD_PRESSURE) {
				const systolic = value.toString().split('/')[0];
				const diastolic = value.toString().split('/')[1];
				if (parseInt(systolic, 10) > 140 || parseInt(diastolic, 10) > 90) {
					return high;
				}
				if (
					(parseInt(systolic, 10) > 120 && parseInt(systolic, 10) < 140) ||
					(parseInt(diastolic, 10) > 80 && parseInt(diastolic, 10) < 90)
				) {
					return moderate;
				}
				return normal;
			}
			if (type === MeasurementTypes.BODY_MASS_INDEX) {
				if (value > 39.9) {
					return high;
				}
				if (value < 29.9) {
					return moderate;
				}
				return normal;
			}
			return false;
		},
		[getCategoryPreference]
	);

	const handleNewMessage = async () => {
		const recentConversations = await getRecent(userId);
		if (recentConversations.error) {
			setIsLoading(false);
			return;
		}
		setConversations(recentConversations.conversations);
	};

	const sortRisk = patientsList => {
		const result = [...patientsList];
		result.sort((a, b) => {
			if (a.highRisk > b.highRisk) {
				return -1;
			}
			if (a.highRisk < b.highRisk) {
				return 1;
			}
			if (a.modRisk > b.modRisk) {
				return -1;
			}
			if (a.modRisk < b.modRisk) {
				return 1;
			}
			return 1;
		});
		return result;
	};

	const sortByRiskType = type => {
		setRiskType(type);
	};

	const onSearch = e => {
		setSearchBox(e.target.value);
	};

	const filterDefault = () => {
		setVitalSignsMonitoringPatients(sortRisk(vitalSignsMonitoringPatients));
		setSortByNameValue(null);
		setRiskType(null);
	};

	const sortByName = () => {
		if (!sortByNameValue || sortByNameValue === SortByNameValues.SORTING_ZA) {
			setSortByNameValue(SortByNameValues.SORTING_AZ);
			return;
		}
		setSortByNameValue(SortByNameValues.SORTING_ZA);
	};

	const getMeasurementValue = measurement =>
		!measurement.unitCategoryId
			? measurement.measurementValue
			: convertMeasurementTypes(
					measurement.unitCategoryId,
					measurement.measurementValue,
					getUnitPreference(measurement.unitCategoryId)?.unitSystemId
			  );

	const getVitalSignTableCell = (signs, type) => {
		const selectedVitalSign = signs.find(item => item.measurementType === type);
		return (
			<div
				className={classNames(
					'patient-alert-body-vs position-relative',

					selectedVitalSign && selectedVitalSign.riskLevel === RiskLevelValues.MODERATE ? 'mod-risk-wrapper' : '',
					selectedVitalSign && selectedVitalSign.riskLevel === RiskLevelValues.HIGH ? 'high-risk-wrapper' : ''
				)}>
				<div
					className={classNames(
						selectedVitalSign && selectedVitalSign.riskLevel === RiskLevelValues.MODERATE ? 'mod-risk' : '',
						selectedVitalSign && selectedVitalSign.riskLevel === RiskLevelValues.HIGH ? 'high-risk' : ''
					)}
				/>
				<div className='flex'>
					{selectedVitalSign && (
						<>
							{selectedVitalSign.riskLevel === RiskLevelValues.NORMAL && (
								<img src={vitalSignsList.find(item => item.type === type).icon} alt='ico' />
							)}
							{selectedVitalSign.riskLevel === RiskLevelValues.MODERATE && (
								<img src={vitalSignsList.find(item => item.type === type).warningIcon} alt='ico' />
							)}
							{selectedVitalSign.riskLevel === RiskLevelValues.HIGH && (
								<img src={vitalSignsList.find(item => item.type === type).riskIcon} alt='ico' />
							)}
						</>
					)}
					{(!selectedVitalSign || vitalSigns.length === 0) && (
						<>
							<img src={vitalSignsList.find(item => item.type === type).icon} alt='ico' />
							<p>N/A</p>
						</>
					)}

					{selectedVitalSign && (
						<p>
							{getMeasurementValue(selectedVitalSign)}
							<span>
								{selectedVitalSign.unitCategoryId
									? getUnitPreference(selectedVitalSign.unitCategoryId)?.unit
									: selectedVitalSign.measurementUnit}
							</span>
						</p>
					)}
				</div>
				<span>{selectedVitalSign && defaultDateFormat(selectedVitalSign.date)}</span>
			</div>
		);
	};

	const patientRedirect = async (type, patient) => {
		try {
			const queryParams = new URLSearchParams({
				[StartQueryStringKeys.OBJECT_ID]: patient.userId,
				[StartQueryStringKeys.OBJECT_TYPE]: enums.ObjectTypes.USER,
				[StartQueryStringKeys.CONFERENCE_NAME]: `${patient.firstName} ${patient.lastName}`,
				[StartQueryStringKeys.CALL_TYPE]: type,
			});
			window.open(`/call?${queryParams.toString()}`, '_blank');
		} catch (err) {
			setErrorApiResponse(`${intl.formatMessage({ id: 'anErrorOccurred' })}: ${err.message}`);
		}
	};

	const toggleViewProfileModal = patient => {
		getPatientDetails(patient.userId);
	};

	const toggleDeviceList = patient => {
		setDevicesListModalVisibility(prevState => !prevState);
		setSelectedPatient(patient);
	};

	const onModalClose = () => {
		setViewProfileModalVisibility(false);
		setDevicesListModalVisibility(false);
		setSelectedPatient(null);
	};

	const getUnreadMessages = patient => {
		const foundConversation = conversations.find(item => item.contact && item.contact.objectId === patient.userId.toString());
		return foundConversation && foundConversation.unreadMessagesCount > 0;
	};

	const getSearchedPatients = item => {
		const fullName = `${item.firstName} ${item.lastName}`;
		return (
			item.firstName.toUpperCase().includes(searchBox.toUpperCase()) ||
			item.lastName.toUpperCase().includes(searchBox.toUpperCase()) ||
			fullName.toUpperCase().includes(searchBox.toUpperCase()) ||
			item.devicesInfos.some(dev => dev.name.toUpperCase().includes(searchBox.toUpperCase()))
		);
	};

	const getFilteredPatients = () => {
		let patientsList = [...vitalSignsMonitoringPatients];
		if (searchBox) {
			patientsList = patientsList.filter(item => getSearchedPatients(item));
		}
		if (riskType) {
			if (riskType === RiskLevels.HIGH) {
				patientsList = vitalSignsMonitoringPatients.filter(
					item => item.vitalSigns && item.vitalSigns.some(sign => sign.riskLevel === RiskLevelValues.HIGH)
				);
				patientsList = patientsList.filter(item => getSearchedPatients(item));
			}
			if (riskType === RiskLevels.MODERATE) {
				patientsList = vitalSignsMonitoringPatients.filter(
					item => item.vitalSigns && item.vitalSigns.some(sign => sign.riskLevel === RiskLevelValues.MODERATE)
				);
				patientsList = patientsList.filter(item => getSearchedPatients(item));
			}
		}
		return patientsList;
	};

	const toggleAlertsModal = patient => {
		setAlertsModalVisibility(prevState => !prevState);
		setSelectedPatient(patient);
		setPatient(patient);
	};

	const patients = getFilteredPatients();
	const unavailableDateOfBirth = '0001-01-01T00:00:00';

	useEffect(() => {
		const fetchData = async () => {
			const { error, unitPreferences } = await getUserMeasurementUnits(userId);
			if (error) {
				setErrorApiResponse(error.message);
			} else {
				setPreferredUnits(unitPreferences);
			}
		};
		fetchData();
	}, [userId]);

	useEffect(() => {
		const onPatientHealthMeasurements = data => {
			const patientsList = [...vitalSignsMonitoringPatients];
			patientsList.forEach(patient => {
				if (patient.userId === data.userId) {
					if (patient.vitalSigns.find(sign => sign.measurementType === data.measurementType)) {
						patient.vitalSigns.forEach(item => {
							const vitalSign = item;
							if (item.measurementType === data.measurementType) {
								vitalSign.measurementValue = convertMeasurementTypes(
									item.unitCategoryId,
									item.measurementValue,
									getUnitPreference(item.unitCategoryId)?.unitSystemId
								);
								vitalSign.measurementUnit = data.measurementUnit;
								vitalSign.riskLevel = getVitalSignMeasurementRiskLevels(
									item.measurementType,
									item.measurementValue,
									item.unitCategoryId
								);
								vitalSign.date = data.startDate;
							}
						});
					} else {
						patient.vitalSigns.push({
							...data,
							riskLevel: getVitalSignMeasurementRiskLevels(data.measurementType, data.measurementValue, data.measurementUnit),
							date: data.startDate,
						});
					}
				}
			});
			patientsList.forEach(patient => {
				const highRiskCount = patient.vitalSigns.filter(item => item.riskLevel === RiskLevelValues.HIGH).length;
				const modRiskCount = patient.vitalSigns.filter(item => item.riskLevel === RiskLevelValues.MODERATE).length;
				const lowRiskCount = patient.vitalSigns.filter(item => item.riskLevel === RiskLevelValues.NORMAL).length;
				const patientItem = patient;
				patientItem.highRisk = patientItem.vitalSigns.length > 0 ? highRiskCount : 0;
				patientItem.modRisk = patientItem.vitalSigns.length > 0 ? modRiskCount : 0;
				patientItem.lowRisk = patientItem.vitalSigns.length > 0 ? lowRiskCount : 0;
			});
			const result = sortRisk(patientsList);
			setVitalSignsMonitoringPatients(result);
		};

		socket.on(SocketEvents.HelloDevice.PATIENT_HEALTH_MEASUREMENTS, onPatientHealthMeasurements);
		socket.on(SocketEvents.Conversation.ON_NEW_MESSAGE, handleNewMessage);

		return () => {
			socket.off(SocketEvents.HelloDevice.PATIENT_HEALTH_MEASUREMENTS, onPatientHealthMeasurements);
			socket.off(SocketEvents.Conversation.ON_NEW_MESSAGE, handleNewMessage);
		};
	}, [vitalSignsMonitoringPatients, preferredUnits, socket, getUnitPreference, getVitalSignMeasurementRiskLevels]);

	useEffect(() => {
		const getVitalSigns = async () => {
			const response = await getVitalSignPatients(userSession.healthSystem.id, userSession.regionId);
			if (response.error) {
				setErrorApiResponse(response.error.message);
				setIsLoading(false);
			} else {
				const patientsList = response.vitalSignsMonitoringPatients;
				patientsList.forEach(patient => {
					const highRiskCount = patient.vitalSigns.filter(item => item.riskLevel === RiskLevelValues.HIGH).length;
					const modRiskCount = patient.vitalSigns.filter(item => item.riskLevel === RiskLevelValues.MODERATE).length;
					const lowRiskCount = patient.vitalSigns.filter(item => item.riskLevel === RiskLevelValues.NORMAL).length;
					const patientItem = patient;
					patientItem.highRisk = patientItem.vitalSigns.length > 0 ? highRiskCount : 0;
					patientItem.modRisk = patientItem.vitalSigns.length > 0 ? modRiskCount : 0;
					patientItem.lowRisk = patientItem.vitalSigns.length > 0 ? lowRiskCount : 0;
				});
				const result = sortRisk(patientsList);
				setVitalSigns(response);
				setVitalSignsMonitoringPatients(result);
				const recentConversations = await getRecent(userId);
				if (recentConversations.error) {
					setIsLoading(false);
					return;
				}
				setConversations(recentConversations.conversations);
				setIsLoading(false);
			}
		};

		if (userSession.healthSystem.id) {
			getVitalSigns();
		}
	}, [userSession.healthSystem.id, userSession.regionId]);

	useEffect(() => {
		const patientsList = [...vitalSignsMonitoringPatients];
		if (sortByNameValue) {
			if (sortByNameValue === SortByNameValues.SORTING_ZA) {
				patientsList.sort((a, b) => (a.firstName.toUpperCase() > b.firstName.toUpperCase() ? 1 : -1));
			} else {
				patientsList.sort((a, b) => (a.firstName.toUpperCase() < b.firstName.toUpperCase() ? 1 : -1));
			}
		}
		setVitalSignsMonitoringPatients(patientsList);
	}, [sortByNameValue]);

	return (
		<MainLayout>
			{isLoading && (
				<Grid width='100%' stretch='100vh' vertAlign='center' horizAlign='center' rows='auto'>
					<Loader />
				</Grid>
			)}
			{!isLoading && (
				<>
					<div className='patient-alerts-wrapper rpm full-width'>
						<h4>{translate('remotePatientMonitoring')}</h4>
						<div className='flex patient-risk-grid'>
							<div onClick={filterDefault} className='cursor-pointer'>
								<p>{translate('numberOfPatients')}</p>
								<h1>{vitalSigns && vitalSigns.numberOfPatients}</h1>
							</div>
							<div
								onClick={() => {
									sortByRiskType(RiskLevels.HIGH);
								}}
								className='cursor-pointer'>
								<p>{translate('highRisk')}</p>
								<h1>{vitalSigns && vitalSigns.highRiskCount}</h1>
							</div>
							<div
								onClick={() => {
									sortByRiskType(RiskLevels.MODERATE);
								}}
								className='cursor-pointer'>
								<p>{translate('moderateRisk')}</p>
								<h1>{vitalSigns && vitalSigns.moderateRiskCount}</h1>
							</div>
						</div>
						<div className='flex full-width patients-alert-filter'>
							<FormInput
								id='searchBox'
								labelClassName='full-width'
								name='searchBox'
								text=''
								type='search'
								onChange={onSearch}
								value={searchBox}
								placeholder={intl.formatMessage({ id: 'searchByNameOrHello' }, { value: helloName })}
								className='full-width'
							/>
						</div>

						<div className='patients-alert-table'>
							<div className='flex patients-alert-table-header'>
								<div className='flex'>
									<p>{translate('patient')}</p>
									<i
										className={`material-icons${sortByNameValue === SortByNameValues.SORTING_ZA ? ' rotate' : ''}`}
										onClick={sortByName}>
										arrow_downward
									</i>
								</div>
								<div>
									<p>{translate('bloodPressure')}</p>
								</div>
								<div>
									<p>{translate('heartRate')}</p>
								</div>
								<div>
									<p>{translate('oxygenSaturation')}</p>
								</div>
								<div>
									<p>{translate('bloodGlucose')}</p>
								</div>
								<div>
									<p>{translate('bodyTemperature')}</p>
								</div>
								<div>
									<p>{translate('bmi')}</p>
								</div>
								<div>
									<p>{translate('actions')}</p>
								</div>
							</div>
							<div className='patients-alert-table-body'>
								{patients.map(patient => (
									<div key={patient.userId} className='flex full-width'>
										<div className='flex'>
											{patient.isConnectedPatient && (
												<Link to={`/patients/${patient.userId}`}>
													<ProfilePicture
														className='doctor-request-img cursor-pointer'
														fullName={`${patient.firstName} ${patient.lastName}`}
														profilePicture={patient.profilePicture}
													/>
												</Link>
											)}
											{!patient.isConnectedPatient && (
												<ProfilePicture
													className='doctor-request-img'
													fullName={`${patient.firstName} ${patient.lastName}`}
													profilePicture={patient.profilePicture}
												/>
											)}
											<div>
												<h4>
													{patient.firstName} {patient.lastName}
												</h4>
												<p>
													{patient.genderId === Gender.UNAVAILABLE
														? `${intl.formatMessage({ id: 'sex' })}: N/A`
														: getGender(patient.genderId).description}
													{patient.dateOfBirth !== unavailableDateOfBirth
														? `, ${moment().diff(patient.dateOfBirth, 'years', false)}`
														: `, ${intl.formatMessage({ id: 'age' })}: N/A`}
												</p>
												<span>
													{patient.currentLocation ? patient.currentLocation : `${intl.formatMessage({ id: 'location' })}: N/A`}
												</span>
											</div>
										</div>
										{getVitalSignTableCell(patient.vitalSigns, MeasurementTypes.BLOOD_PRESSURE)}
										{getVitalSignTableCell(patient.vitalSigns, MeasurementTypes.HEART_RATE)}
										{getVitalSignTableCell(patient.vitalSigns, MeasurementTypes.OXYGEN)}
										{getVitalSignTableCell(patient.vitalSigns, MeasurementTypes.DIABETES)}
										{getVitalSignTableCell(patient.vitalSigns, MeasurementTypes.TEMPERATURE)}
										{getVitalSignTableCell(patient.vitalSigns, MeasurementTypes.BODY_MASS_INDEX)}
										<div className='flex position-relative'>
											<div className='flex patient-info-call'>
												{patient.devicesInfos.length > 0 && (
													<div className='patient-info-hello' onClick={() => toggleDeviceList(patient)} />
												)}
												<div className='patient-info-audio' onClick={() => patientRedirect(enums.CallTypes.AUDIO, patient)} />
												<div className='patient-info-video' onClick={() => patientRedirect(enums.CallTypes.VIDEO, patient)} />
												{patient.isConnectedPatient && (
													<div className='patient-info-open-convo'>
														<Link
															to={{
																pathname: `/patients/${patient.userId}/conversation`,
															}}
														/>
														{getUnreadMessages(patient) && (
															<div className='messages-count-inner'>
																{
																	conversations.find(item => item.contact && item.contact.objectId === patient.userId.toString())
																		.unreadMessagesCount
																}
															</div>
														)}
													</div>
												)}
												<Dropdown position='bottom' icon='more_vert' className='patient-view-more-wrapper'>
													<div className='patient-info-hello-list patient-info-view-more-list'>
														{getUserRole() === UserRoles.DOCTOR && (
															<div className='flex'>
																<Link
																	className='no-text-decoration'
																	to={{
																		pathname: `/patients/${patient.userId}`,
																	}}>
																	<div className='flex'>
																		<img
																			src={`${healthCareCdnUrl}monitoring/view-profile.svg`}
																			className='margin-right-m'
																			alt='icon'
																		/>
																		<p className='no-margin font-14'>{translate('viewProfile')}</p>
																	</div>
																</Link>
															</div>
														)}
														{getUserRole() !== UserRoles.DOCTOR && (
															<div
																className='flex'
																onClick={() => {
																	toggleViewProfileModal(patient);
																}}>
																<img src={`${healthCareCdnUrl}monitoring/view-profile.svg`} alt='icon' />
																<p>{translate('viewProfile')}</p>
															</div>
														)}
														<div
															className='flex'
															onClick={() => {
																toggleAlertsModal(patient);
															}}>
															<img src={`${healthCareCdnUrl}vsm/view-alerts.svg`} alt='icon' />
															<p>{translate('viewAlerts')}</p>
														</div>
													</div>
												</Dropdown>
											</div>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
					<ViewPatientProfile
						display={isViewProfileModalVisible}
						onModalClose={() => setViewProfileModalVisibility(false)}
						onModalSubmit={null}
						setIsSecondTabEnabled={false}
						patientDetails={patientDetails?.profile}
						userId={patientDetails?.userId}
						setSelectedPatient={null}
						setIsPatientFormVisible={null}
						selectedPatientId={patientDetails?.id}
						unitPreferences={preferredUnits}
						countries={countries}
						selectedCountry={selectedCountry}
						isViewOnly={true}
					/>
					<Modal
						display={isDevicesListModalVisible}
						isLoading={false}
						position='center'
						className='standard-modal-wrapper modal-wrapper-wo-btn vsm-devices-modal-wrapper'
						onModalClose={onModalClose}>
						<div className='available-doctor-modal view-profile-modal view-patient-profile-modal vsm-devices-modal-wrapper'>
							<div className='hello-items-container'>
								{selectedPatient &&
									selectedPatient.devicesInfos.map(device => {
										const room = findSectorById(tree, device.id);
										const helloDeviceName = room ? room.name : device.name;
										return (
											<HelloItems
												deviceName={helloDeviceName}
												deviceId={device.id}
												isOnline={device.state.availability}
												privacyMode={device.state.privacyMode}
												isCameraOn={device.state.camera}
												isMicrophoneOn={device.state.microphone}
												isHelloDevice={device.deviceFamily !== DeviceFamilyTypes.TABLET}
											/>
										);
									})}
							</div>
						</div>
					</Modal>
					{selectedPatient && isAlertsModalOpen && (
						<AlertsModal
							display={isAlertsModalOpen}
							isLoading={false}
							position='center'
							className='vsm-alerts-modal border-radius-modal-wrapper'
							onModalClose={() => toggleAlertsModal(null)}
							patientId={selectedPatient.id}
							isVitalSigns={true}
						/>
					)}
				</>
			)}
			<Alert
				display={errorApiResponse}
				fixed={true}
				onClose={() => {
					setErrorApiResponse(null);
				}}
				message={errorApiResponse}
				variant='dark'
			/>
		</MainLayout>
	);
};

export default VitalSignsMonitoring;
