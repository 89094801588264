import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import Grid from 'components/Grid.jsx';
import translate from 'i18n-translations/translate.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import Conversation from 'views/Chat/Conversation.jsx';
import Loader from 'components/Loader.jsx';
import EmptyState from 'components/EmptyState.jsx';
import { getBabyRoomChannels } from 'api/messenger.js';
import useScreenType from 'hooks/useScreenType.js';

const FamilyMembersChat = () => {
	const screenType = useScreenType();

	const [isConversationOpen, setIsConversationOpen] = useState(false);
	const [isConversationInfoOpen, setIsConversationInfoOpen] = useState(false);
	const [conversationList, setConversationList] = useState([]);
	const [isLoading, setLoading] = useState(true);
	const [selectedConversation, setSelectedConversation] = useState(null);
	const [, setError] = useState(null);
	const userSession = useSelector(state => state.user.userSession);

	useEffect(() => {
		const fetchConversationData = async () => {
			const response = await getBabyRoomChannels();
			if (response.error) {
				setError(response.error.message);
				setLoading(false);
				return;
			}
			if (response.teamChannels.length > 0) {
				setConversationList(response.teamChannels);
				setSelectedConversation(response.teamChannels[0]);
			}
			setLoading(false);
		};
		fetchConversationData();
	}, [userSession.healthSystem.id]);

	return (
		<MainLayout>
			{isLoading && (
				<Grid columns='1fr' rows='1fr' stretch='calc(100vh - 60px)' horizAlign='center' vertAlign='center'>
					<Loader />
				</Grid>
			)}
			{!isLoading && (
				<Grid
					columns='2fr 7fr'
					stretch='100%'
					className={classNames(
						'family-members-chat',
						isConversationOpen ? 'conversation-open' : '',
						isConversationInfoOpen ? 'conversation-info-open' : ''
					)}>
					<aside className='teams-list full-width full-height'>
						<div className='full-height flex column-direction'>
							<div className='patients-list-header sub-page-header no-border-bottom'>
								<h2>{translate('teams')}</h2>
							</div>
							<div className='teams-list-inner flex-1'>
								<div className='teams-list-item'>
									{conversationList.map(conversation => (
										<div
											className={classNames(
												'flex cursor-pointer',
												conversation.conversationId === selectedConversation.conversationId ? 'active' : ''
											)}
											key={conversation.conversationId}
											onClick={() => {
												if (screenType.isSmall) {
													setIsConversationOpen(true);
												}
												setSelectedConversation(conversation);
											}}>
											<img src={`${healthCareCdnUrl}teams/department-ico.svg?v2`} alt='department icon' />
											<div>
												<p className='flex cursor-pointer'>{conversation.name}</p>
											</div>
										</div>
									))}
									{conversationList.length === 0 && (
										<div>
											<p className='--blue-color'>{translate('noData')}</p>
										</div>
									)}
								</div>
							</div>
						</div>
					</aside>
					<main className='main-view nurses-wrapper'>
						{selectedConversation && (
							<>
								{screenType.isSmall && (
									<header className='chat-mobile-header'>
										<h3>
											<button type='button' onClick={() => setIsConversationOpen(false)}>
												<span className='material-icons'>arrow_back</span>
											</button>
											{selectedConversation?.name}
											<button type='button' onClick={() => setIsConversationInfoOpen(old => !old)}>
												<i className='material-icons'>more_vert</i>
											</button>
										</h3>
									</header>
								)}
								<Conversation
									conversationId={selectedConversation.conversationId}
									selectedChannelId={selectedConversation.id}
									selectedTeamId={selectedConversation.teamId}
									teamName={selectedConversation.name}
								/>
							</>
						)}
						{conversationList.length === 0 && !isLoading && (
							<div className='empty-state-wrapper'>
								<EmptyState title={translate('noDataFound')} image='patients.svg' paragraph={translate('tryOtherDateRange')} />
							</div>
						)}
					</main>
				</Grid>
			)}
		</MainLayout>
	);
};

export default FamilyMembersChat;
