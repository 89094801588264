import React, { useState, useEffect, useContext } from 'react';
import { ChatOptionsViews } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { getConversationAttachments } from 'api/messenger.js';
import MediaViewer from 'views/Partials/MediaViewer.jsx';
import { EmptyState } from 'components';
import translate from 'i18n-translations/translate.jsx';
import PopUpAlert from 'components/PopUpAlert.jsx';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';

const ImagesOrVideos = props => {
	const [attachments, setAttachments] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [selectedAttachment, setSelectedAttachment] = useState(null);
	const [error, setError] = useState(null);
	const socket = useContext(SocketContext);

	useEffect(() => {
		const fetchAttachments = async () => {
			const response = await getConversationAttachments(props.conversationId, {
				getDocuments: false,
				getMedia: true,
				latest: null,
				limit: 20,
			});
			if (response.error) {
				setIsLoading(false);
				setError(response.error.message);
			} else {
				setIsLoading(false);
				setAttachments(response.attachments.filter(item => item.typeId === props.type));
			}
		};
		fetchAttachments();
	}, [props.conversationId, props.type]);

	useEffect(() => {
		socket.on(SocketEvents.Conversation.ON_MESSAGE_DELETED, handleMessageDeleted);
		return () => {
			socket.off(SocketEvents.Conversation.ON_MESSAGE_DELETED, handleMessageDeleted);
		};
	}, [props.conversationId, socket]);

	const handleMessageDeleted = data => {
		setAttachments(prevState => prevState.filter(item => item.id !== data.messageId));
	};

	return (
		<>
			<div className='chat-images-wrapper chat-options-wrapper position-relative'>
				<div className='flex'>
					<div className='cursor-pointer' onClick={() => props.setDefaultOptions(ChatOptionsViews.DEFAULT)}>
						<img src={`${healthCareCdnUrl}chat/back.svg`} alt='back arrow icon' />
					</div>
					<div>
						<p>
							{props.type === ChatOptionsViews.IMAGES && translate('images')}
							{props.type === ChatOptionsViews.VIDEOS && translate('videos')}
						</p>
						<p>
							{props.type === ChatOptionsViews.IMAGES && translate('sharedImages')}
							{props.type === ChatOptionsViews.VIDEOS && translate('sharedVideos')}
						</p>
					</div>
				</div>
				{attachments.length > 0 && (
					<div className='images-videos-list'>
						<div>
							{/* <p>June</p> */}
							<div className='flex chat-images-gallery cursor-pointer'>
								{attachments.map(attachment => (
									<div
										key={attachment.id}
										onClick={() => setSelectedAttachment(attachment)}
										style={{ backgroundImage: `url(${attachment.thumbnailUrl})` }}
									/>
								))}
							</div>
						</div>
					</div>
				)}
				{attachments.length === 0 && !isLoading && (
					<div className='empty-state-wrapper-percent'>
						<EmptyState title={translate('noData')} image='no-files.svg' />
					</div>
				)}
			</div>
			{selectedAttachment && (
				<MediaViewer
					display={true}
					onModalClose={() => setSelectedAttachment(null)}
					attachments={attachments}
					selectedAttachment={selectedAttachment}
					conversationId={props.conversationId}
					setSelectedAttachment={setSelectedAttachment}
					members={props.members}
				/>
			)}
			<PopUpAlert display={error} onAlertClose={() => setError(null)} contentText={error} isSilent={true} center={true} />
		</>
	);
};

export default ImagesOrVideos;
