import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import translate from 'i18n-translations/translate.jsx';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import ResultAccordion from 'containers/PrimaryCare/LaboratoryAnalysis/Partials/ResultAccordion.jsx';
import { healthCareCdnUrl, primaryCareImageUrl } from 'constants/global-variables.js';
import { getDiagnosticResearches, addDiagnosticResearchAttachments } from 'api/visits.js';
import Alert from 'components/Alert.jsx';
import EmptyState from 'components/EmptyState.jsx';
import Grid from 'components/Grid.jsx';
import Loader from 'components/Loader.jsx';
import Modal from 'components/Modal.jsx';
import { forms } from 'containers/PrimaryCare/LaboratoryAnalysis/constants/labForms.js';
import ProfilePicture from 'components/ProfilePicture.jsx';
import { VisitTypes } from 'constants/visitEnums.js';

const LabWaitingResults = () => {
	const intl = useIntl();
	const [patients, setPatients] = useState([]);
	const [error, setError] = useState(null);
	const [searchValue, setSearchValue] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [isSaveAnalysisLoading, setIsSaveAnalysisLoading] = useState(null);
	const [filteredPatients, setFilteredPatients] = useState([]);
	const [nextVisit, setNextVisit] = useState(null);

	useEffect(() => {
		const fetchWaitingResults = async () => {
			const response = await getDiagnosticResearches();

			if (response.error) {
				setError(response.error.message);
			} else {
				const mapped = response.map(item => ({
					...item,
					forms: _.cloneDeep(forms(intl).filter(form => item.tests.includes(form.id))),
				}));
				setPatients(mapped);
				setFilteredPatients(mapped);
			}
			setIsLoading(false);
		};
		fetchWaitingResults();
	}, []);

	useEffect(() => {
		const patientsArr = _.cloneDeep(patients);
		if (!searchValue) {
			setFilteredPatients(patientsArr);
			return;
		}
		const lowerCaseSearch = searchValue.toString().toLowerCase();
		const filtered = patientsArr.filter(
			item =>
				item.patientName.toLowerCase().includes(lowerCaseSearch) || item.patientIdCard.toLowerCase().includes(lowerCaseSearch)
		);
		setFilteredPatients(filtered);
	}, [searchValue]);

	const tableTitles = [
		{
			id: 0,
			name: translate('patient'),
		},
		{
			id: 1,
			name: translate('dateAndTime'),
		},
		{
			id: 2,
			name: translate('numberOfAnalysis'),
		},
		{
			id: 3,
			name: '',
		},
	];

	const handleResultChange = (value, selectedAnalysis, resultIndex, patient, sub) => {
		let finalValue = value;
		if (value < sub.min || value > sub.max) {
			finalValue = '';
		}
		setPatients(prevState => {
			const patientsArr = [...prevState];
			const foundVisitIndex = patientsArr.findIndex(item => patient.medicalVisitId === item.medicalVisitId);
			const foundAnalyses = patientsArr[foundVisitIndex].forms.find(item => item.id === selectedAnalysis.id);
			foundAnalyses.results[resultIndex].value = finalValue;
			return patientsArr;
		});
	};

	const handleResultChangeString = (value, selectedAnalysis, resultIndex, patient) => {
		if (value.length > 150) {
			return;
		}
		setPatients(prevState => {
			const patientsArr = [...prevState];
			const foundVisitIndex = patientsArr.findIndex(item => patient.medicalVisitId === item.medicalVisitId);
			const foundAnalyses = patientsArr[foundVisitIndex].forms.find(item => item.id === selectedAnalysis.id);
			foundAnalyses.results[resultIndex].value = value;
			return patientsArr;
		});
	};

	const canEnterResults = visit => {
		let result = true;
		for (let i = 0; i < visit.forms.length; i += 1) {
			if (visit.forms[i].results.some(item => !item.value)) {
				result = false;
				break;
			}
		}
		return result;
	};

	const handleSaveAnalysis = async visit => {
		setIsSaveAnalysisLoading(visit.medicalVisitId);
		if (!canEnterResults(visit)) {
			return;
		}
		let tests = [];
		visit.forms.forEach(form => {
			tests = [...tests, ...form.results.map(item => ({ value: `${item.value}`, typeId: item.id }))];
		});
		const response = await addDiagnosticResearchAttachments(visit.medicalVisitId, { attachments: tests });
		if (response.error) {
			setError(response.error.message);
		} else {
			setNextVisit(response);
			setPatients(prevState => prevState.filter(item => item.medicalVisitId !== visit.medicalVisitId));
			setFilteredPatients(prevState => prevState.filter(item => item.medicalVisitId !== visit.medicalVisitId));
		}
		setIsSaveAnalysisLoading(null);
	};

	return (
		<MainLayout>
			<div className='view-page-wrapper display-block'>
				{!isLoading && patients.length > 0 && (
					<div className='waiting-results-wrapper full-width'>
						<div>
							<h3>{translate('waitingForResults')}</h3>
							<div className='position-relative'>
								<img src={`${healthCareCdnUrl}search-icon.svg`} alt='ico' className='search-patient-icon' />
								<input
									type='search'
									className='full-width'
									onChange={e => setSearchValue(e.target.value)}
									placeholder={intl.formatMessage({ id: 'searchPatientNameOrNumber' })}
									value={searchValue}
								/>
							</div>
						</div>
						<div>
							<div className='flex'>
								{tableTitles.map(item => (
									<div key={item.id} className='flex-1'>
										{item.name}
									</div>
								))}
							</div>
							{filteredPatients.length > 0 &&
								filteredPatients.map(item => (
									<ResultAccordion
										key={item.medicalVisitId}
										saveAnalysis={handleSaveAnalysis}
										item={item}
										handleResultChange={handleResultChange}
										handleResultChangeString={handleResultChangeString}
										canEnterResults={canEnterResults}
										isSaveAnalysisLoading={isSaveAnalysisLoading}
									/>
								))}
						</div>
					</div>
				)}
				{!isLoading && patients.length === 0 && (
					<div className='patient-has-no-visits waiting-room-view-inner'>
						<EmptyState title={translate('noResultsFound')} image='no-patients-waiting-for-results.svg' />
					</div>
				)}
				{isLoading && (
					<Grid
						columns='1fr'
						width='100%'
						rows='1fr'
						stretch='calc(100vh - 200px)'
						className='max-initial-width'
						horizAlign='center'
						vertAlign='center'>
						<div style={{ textAlign: 'center' }}>
							<Loader />
						</div>
					</Grid>
				)}

				<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
				<Modal
					display={nextVisit}
					onModalSubmit={() => setNextVisit(null)}
					onModalClose={() => setNextVisit(null)}
					primaryButtonLabel={intl.formatMessage({ id: 'done' })}
					position='center'
					shouldSubmitOnEnter={false}
					hideCloseButton={true}
					className='wrapper-modal border-radius-modal-wrapper success-laboratory-modal appoinment-next-arrow-modal saved-successfully'
					submitImgIcon={`${healthCareCdnUrl}appointments/save-icon.svg`}>
					<div>
						<h3>{translate('success')}</h3>
						{nextVisit?.nextVisitInfo?.visitType === VisitTypes.DOCTOR && (
							<>
								<p>
									{translate('nextVisitIn', {
										value: intl.formatMessage({ id: 'doctor' }),
									})}
								</p>

								<div className='saved-successfully-details-wrapper'>
									<div className='profile-details flex-1'>
										<div className='full-width'>
											<>
												<ProfilePicture
													profilePicture={`${primaryCareImageUrl}icons/doctor.svg`}
													className='doctor-request-img appointment-img'
												/>
												<div>
													<p>{nextVisit?.nextVisitInfo?.roomName}</p>
												</div>
											</>
										</div>
									</div>
								</div>
							</>
						)}
					</div>
				</Modal>
			</div>
		</MainLayout>
	);
};

export default LabWaitingResults;
