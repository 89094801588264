import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import 'css/LandingPage/landing-page.scss';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader


const PartnersLogos = props => {
	const linkToLogos = `${healthCareCdnUrl}landing-page/logos/`;

	const twoPerRow = props.logos?.reduce((rows, key, index) => {
		return (index % 2 === 0 ? rows.push({ id: index, arr: [key] }) : rows[rows.length - 1].arr.push(key)) && rows;
	}, []);

	return (
		<div className='partner-logos-wrapper lp-container'>
			{props.title && <h2>{props.title}</h2>}
			<div className={`partners-logos ${props.showCarousel ? ' show-carousel' : ''}`}>
				{props.logos?.length > 0 &&
					props.logos.map(item => (
						<div key={item.id}>
							<img src={`${linkToLogos}${item.src}`} alt={item.alt} style={{ width: item.customSize ? '85px' : '' }} />
						</div>
					))}
			</div>
			{props.showCarousel && (
				<div className='carousel-wrapper'>
					<Carousel showArrows showThumbs={false} showIndicators={false} autoPlay swipeable emulateTouch>
						{twoPerRow?.length > 0 &&
							twoPerRow.map(item => (
								<div key={item.id}>
									{item.arr.map(elem => (
										<div key={elem.id}>
											<img src={`${linkToLogos}${elem.src}`} alt={elem.alt} style={{ width: elem.customSize ? '85px' : '' }} />
										</div>
									))}
								</div>
							))}
					</Carousel>
				</div>
			)}
		</div>
	);
};

export default PartnersLogos;
