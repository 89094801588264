import React from 'react';
import { useIntl } from 'react-intl';
import Select from 'react-select';
import moment from 'moment';
import { FieldArray } from 'formik';
import { Country } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { generateCustomStyles } from 'constants/react-select-style.js';

const EducationHistory = props => {
	const intl = useIntl();

	const getCountryValue = index =>
		props
			.transformArray(props.countries)
			.filter(option => option.value === props.formikProps.values.educationHistory[index].country3);

	const getStateValue = index =>
		props
			.transformArray(props.states)
			.filter(option => option.value === props.formikProps.values.educationHistory[index].educationStateId);

	const getDegreeValue = index =>
		props
			.translateAndTransformArray(props.academicDegrees)
			.filter(option => option.value === props.formikProps.values.educationHistory[index].degree);

	return (
		<div className='professional-details-wrapper'>
			<label>{translate('educationHistory')}</label>
			<div className='flex personal-details-wrapper professional-wrapper'>
				<FieldArray name='educationHistory'>
					{({ push, remove }) => (
						<>
							{props.formikProps.values.educationHistory.map((education, index) => {
								const { educationHistory } = props.formikProps.values;
								const eduErrors = props.formikProps.errors.educationHistory;
								return (
									<div className='flex flex-wrap' key={`${education?.country3}-country`}>
										<div className='flex column-direction personal-details'>
											<label>{translate('school')}</label>
											<input
												type='text'
												placeholder={intl.formatMessage({ id: 'school' })}
												maxLength={50}
												value={educationHistory[index].school}
												onChange={props.onChange}
												name={`educationHistory[${index}].school`}
											/>
											{eduErrors && <span className='red-error'>{eduErrors[index]?.school}</span>}
										</div>
										<div className='flex column-direction personal-details position-relative registration-select-wrapper'>
											<label>{translate('degree')}</label>
											<Select
												value={getDegreeValue(index)}
												placeholder={intl.formatMessage({ id: 'degree' })}
												classNamePrefix='react-select'
												options={props.translateAndTransformArray(props.academicDegrees)}
												onChange={event => props.formikProps.setFieldValue(`educationHistory[${index}].degree`, event?.value)}
												styles={{
													...generateCustomStyles({ darkMode: props.isDarkMode }),
												}}
											/>
											{eduErrors && <span className='red-error'>{eduErrors[index]?.degree}</span>}
										</div>
										<div className='flex full-width start-end-year'>
											<div className='flex column-direction personal-details'>
												<label>{translate('startDate')}</label>
												<input
													type='date'
													placeholder={intl.formatMessage({ id: 'selectDate' })}
													value={educationHistory[index].start}
													onChange={props.onChange}
													name={`educationHistory[${index}].start`}
													max={
														educationHistory[index].end
															? moment(educationHistory[index].start).subtract(1, 'years').format('YYYY-MM-DD')
															: new Date().toISOString().split('T')[0]
													}
												/>
												{eduErrors && <span className='red-error'>{eduErrors[index]?.start}</span>}
												<div className='flex flex-wrap input-auto-width-wrapper'>
													<div className='register-practice-modal-grid tick-box-active-input currently-working'>
														<label className='flex full-width remember-me'>
															<input
																type='checkbox'
																onChange={props.onChange}
																checked={educationHistory[index].currentlyStudying}
																name={`educationHistory[${index}].currentlyStudying`}
															/>
															<div className='onb-custom-checkbox-wrapper cursor-pointer'>
																<div className='onb-custom-checkbox' />
															</div>
															<span>{translate('currentlyStudyingHere')}</span>
														</label>
													</div>
												</div>
											</div>
											<div
												className={`flex column-direction personal-details ${
													educationHistory[index].currentlyStudying ? 'visibility-hidden' : ''
												}`}>
												<label>{translate('endDate')}</label>
												<input
													disabled={!educationHistory[index].start}
													type='date'
													placeholder={intl.formatMessage({ id: 'selectDate' })}
													value={educationHistory[index].end}
													onChange={props.onChange}
													name={`educationHistory[${index}].end`}
													min={
														educationHistory[index].start
															? moment(educationHistory[index].start).add(1, 'years').format('YYYY-MM-DD')
															: new Date().toISOString().split('T')[0]
													}
													max={new Date().toISOString().split('T')[0]}
												/>
												{eduErrors && <span className='red-error'>{eduErrors[index]?.end}</span>}
											</div>
										</div>
										<div className='flex column-direction personal-details position-relative registration-select-wrapper'>
											<label>{translate('country')}</label>
											<Select
												value={getCountryValue(index)}
												placeholder={intl.formatMessage({ id: 'selectCountry' })}
												classNamePrefix='react-select'
												options={props.transformArray(props.countries)}
												onChange={event => props.formikProps.setFieldValue(`educationHistory[${index}].country3`, event?.value)}
												isSearchable
												isClearable
												styles={{
													...generateCustomStyles({ darkMode: props.isDarkMode }),
												}}
											/>
											{eduErrors && <span className='red-error'>{eduErrors[index]?.country3}</span>}
											{educationHistory[index].country3 === Country.USA && (
												<>
													<label>*{translate('selectState')}</label>
													<Select
														value={getStateValue(index)}
														placeholder={intl.formatMessage({ id: 'selectState' })}
														classNamePrefix='react-select'
														options={props.transformArray(props.states)}
														onChange={event =>
															props.formikProps.setFieldValue(`educationHistory[${index}].educationStateId`, event?.value)
														}
														isSearchable
														isClearable
														styles={{
															...generateCustomStyles({ darkMode: props.isDarkMode }),
														}}
													/>
													{eduErrors && <span className='red-error'>{eduErrors[index]?.educationStateId}</span>}
												</>
											)}
										</div>
										{props.formikProps.values.educationHistory.length > 1 && (
											<div className='flex right-align-content cursor-pointer' style={{ alignItems: 'center', flex: '0 0 100%' }}>
												<span className='flex top-15' onClick={() => remove(index)}>
													<p className='blue-color'>{translate('removeEducation')}</p>
												</span>
											</div>
										)}
									</div>
								);
							})}
							{props.formikProps.values.educationHistory.length <= 4 && (
								<div className='flex full-width right-align-content personal-details cursor-pointer'>
									<span className='flex top-15' onClick={() => push({ currentlyStudying: false })}>
										<img src={`${healthCareCdnUrl}add-new.svg`} alt='add new icon' className='add-new' />
										<p className='blue-color'>{translate('addEducation')}</p>
									</span>
								</div>
							)}
						</>
					)}
				</FieldArray>
			</div>
		</div>
	);
};

export default EducationHistory;
