import { gatewayApi, localApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';
import { buildParamsFromArray } from 'infrastructure/helpers/commonHelpers.js';

const organization = getCompanyId();

export const getMemberRooms = async () => {
	try {
		const url = `/v1/organizations/${organization}/members/rooms`;
		const { data } = await gatewayApi.get(url);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getRoomMembers = async (healthSystem, room, roleId = null) => {
	try {
		const url = `v1/organizations/${organization}/health-system/${healthSystem}/rooms/${room}/members`;
		const { data } = await gatewayApi.get(url, { params: roleId });
		return data;
	} catch (error) {
		return { error };
	}
};

export const getRoomMembersAndInvitees = async (healthSystemId, roomId, roleId = null) => {
	try {
		const url = `v1.1/organizations/${organization}/health-system/${healthSystemId}/rooms/${roomId}/members`;
		const { data } = await gatewayApi.get(url, { params: roleId });
		return data;
	} catch (error) {
		return { error };
	}
};

export const getHelloHealthCompaniesList = async () => {
	try {
		const { data } = await localApi.get('/api/organizations/hello-health');
		return data;
	} catch (error) {
		return { error };
	}
};

export const getOrgTeamChildren = async teamId => {
	try {
		const { data } = await gatewayApi.get(`/v1/organizations/${organization}/teams/${teamId}/children`);
		return data.children;
	} catch (error) {
		return { error };
	}
};

export const getOrganizationSectorsChildren = async ({ level, teamsIds, hierarchyType }) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/organizations/${organization}/teams/multiple/children?hierarchyType=${hierarchyType}&level=${level}${buildParamsFromArray(
				'ids',
				teamsIds
			)}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};
