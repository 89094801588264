import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { saveManualAlert } from 'api/alerts.js';
import { getCareEvents } from 'api/teamConfigurationProfiles.js';
import ReactSelect from 'react-select';
import { CareEventTypes } from 'constants/enums.js';
import { actionCreators as patientNotesActionCreators } from 'state/patientNotes/actions.js';
import { generateCustomStyles } from 'constants/react-select-style.js';
import { PatientOutOfRoomIcon } from 'icons/Monitoring/index.js';
import translate from 'i18n-translations/translate.jsx';

const PatientOutOfRoom = ({ patientId, feed, patientOutOfRoomCareEvent, patientReturnedCareEvent, conferenceId }) => {
	const intl = useIntl();
	const outOfTheRoomOptions = [
		{ label: intl.formatMessage({ id: 'patientIsOutOfTheRoom' }), id: 0 },
		{ label: intl.formatMessage({ id: 'surgery' }), id: 1 },
		{ label: intl.formatMessage({ id: 'physicalTherapy' }), id: 2 },
		{ label: intl.formatMessage({ id: 'radiology' }), id: 3 },
		{ label: intl.formatMessage({ id: 'patientisGoingDownForACTScan' }), id: 4 },
		{ label: intl.formatMessage({ id: 'patientIsGoingDownForAnMRI' }), id: 5 },
		{ label: intl.formatMessage({ id: 'ultrasound' }), id: 6 },
		{ label: intl.formatMessage({ id: 'ambulating' }), id: 7 },
		{ label: intl.formatMessage({ id: 'paracentesis' }), id: 8 },
		{ label: intl.formatMessage({ id: 'sunshinePrivileges' }), id: 9 },
		{ label: intl.formatMessage({ id: 'transferring' }), id: 10 },
		{ label: intl.formatMessage({ id: 'erCp' }), id: 11 },
		{ label: intl.formatMessage({ id: 'dialysis' }), id: 12 },
		{ label: intl.formatMessage({ id: 'other' }), id: 13 },
	];
	const user = useSelector(state => state.user);
	const [expandedBox, setExpandedBox] = useState(true);
	const [selectedReason, setSelectedReason] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setIsLoading] = useState(false);

	const [noteValue, setNoteValue] = useState('');
	const userSession = useSelector(state => state.user.userSession);
	const patientNotes = useSelector(state => state.patientNotes.savedMode);
	const dispatch = useDispatch();

	useEffect(() => {
		const getEvents = async () => {
			const response = await getCareEvents({
				pageSize: 10,
				pageIndex: 0,
				healthSystemId: userSession.healthSystem.id,
				teamCareEventType: CareEventTypes.SafetyCompanions,
			});
			if (response.error) {
				setIsLoading(false);
				return;
			}

			setIsLoading(false);
		};
		getEvents();
	}, [userSession.healthSystem.id]);

	useEffect(() => {
		const foundItem = outOfTheRoomOptions.find(
			item => item.label.toLowerCase() === patientNotes[feed.deviceId]?.outOfRoomReason?.toLowerCase()
		);
		setSelectedReason(
			foundItem || {
				label: outOfTheRoomOptions[0].label,
				value: outOfTheRoomOptions[0].id,
			}
		);
	}, [feed.deviceId]);

	const togglePatientOutofRoom = async () => {
		if (!selectedReason) {
			setError('Select a reason please!');
			return;
		}
		const dataToSend = {
			patientId,
			deviceId: feed.deviceId,
			manualAlertTypeId: patientNotes[feed.deviceId].isPatientOutOfRoom
				? patientReturnedCareEvent?.id
				: patientOutOfRoomCareEvent?.id,
			comment: selectedReason.value === 13 ? noteValue : selectedReason.label,
			isDraft: !patientNotes[feed.deviceId].isPatientOutOfRoom,
			conferenceId,
		};
		const response = await saveManualAlert(dataToSend);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		dispatch(
			patientNotesActionCreators.setPatientDataSaved(
				{
					...patientNotes[feed.deviceId],
					isPatientOutOfRoom: !patientNotes[feed.deviceId].isPatientOutOfRoom,
					outOfRoomReason: selectedReason.label,
					draftsCount: patientNotes[feed.deviceId].isPatientOutOfRoom
						? patientNotes[feed.deviceId].draftsCount
						: patientNotes[feed.deviceId].draftsCount + 1,
				},
				feed.deviceId
			)
		);
		setError(null);
	};

	useEffect(() => {
		setError(null);
	}, [selectedReason]);

	return (
		<>
			<div className='monitoring-timeline-box patient-out-of-room'>
				<div className={classNames('timeline-box-header', expandedBox ? 'expanded' : '')}>
					<p className='timeline-box-title flex-1'>
						<PatientOutOfRoomIcon />
						{translate('patientOutOfRoom')}
					</p>
					<div className='timeline-box-actions'>
						<button type='button' onClick={() => setExpandedBox(prevState => !prevState)}>
							<i className={classNames('material-icons', expandedBox ? ' rotate' : '')}>expand_more</i>
						</button>
					</div>
				</div>
				{expandedBox && (
					<div className={classNames('timeline-box-content', expandedBox ? ' expanded' : '')}>
						<div className='flex flex-align-center'>
							<ReactSelect
								options={outOfTheRoomOptions.map(item => ({
									label: item.label,
									value: item.id,
								}))}
								value={selectedReason}
								onChange={item => setSelectedReason(item)}
								id='select'
								className='select-voice-announcements'
								classNamePrefix='react-select'
								styles={generateCustomStyles({ darkMode: user.darkMode })}
								isDisabled={patientNotes[feed.deviceId]?.isPatientOutOfRoom}
							/>
							<div className='toggle-switch' onClick={togglePatientOutofRoom}>
								<input type='checkbox' checked={patientNotes[feed.deviceId]?.isPatientOutOfRoom} />
								<span className='toggle-body'>
									<span className='on-text'>{translate('on')}</span>
									<span className='off-text'>{translate('off')}</span>
								</span>
							</div>
						</div>
						{error && <p className='error'>{error}</p>}
						{selectedReason?.value === 13 && (
							<textarea
								className='text-input'
								onChange={event => setNoteValue(event.target.value)}
								name='note'
								rows={3}
								value={noteValue}
								placeholder='Write one reasson'
								style={{ fontSize: '14px' }}
							/>
						)}
					</div>
				)}
			</div>
		</>
	);
};

export default PatientOutOfRoom;
