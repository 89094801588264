import React from 'react';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Grid from 'components/Grid.jsx';
import Loader from 'components/Loader.jsx';
import Unauthorized from 'components/Unauthorized.jsx';
import {
	isUserInfoLoaded,
	getUserRole,
	shouldCompleteRegistration,
	isAuthenticated,
	signinRedirect,
	isInvalidIPAddress,
	signinRedirectRefToken,
} from 'infrastructure/auth.js';
import ContinueAs from 'containers/ContinueAs.jsx';
import PoolingFlowWrapper from 'views/PoolingFlowWrapper.jsx';
import { RouteTypes, UserRoles } from 'constants/enums.js';
import NoHealthSystem from 'views/NoHealthSystem.jsx';
import CompleteRegistration from 'views/Doctors/CompleteRegistration.jsx';
import {
	getDoctorSpecialty,
	isStandAloneAppsUserAuthorized,
	isContinueAsSelected,
	getNursePoolingStatus,
	getConfigurationMenu,
	getConfigurationValue,
	getStorage,
	isSessionEhr,
	isRedirectVirtualCareSession,
} from 'infrastructure/helpers/commonHelpers.js';
import UserIPError from 'views/UserIPError.jsx';
import { GeneralSettings, menuUrls } from 'constants/configurationEnums.js';

const isRoleAuthorized = routeRoles => {
	return !routeRoles || routeRoles.includes(getUserRole());
};

const PrivateRoute = ({ component, ...rest }) => {
	const healthSystems = useSelector(state => state.healthSystems);
	const configurations = useSelector(state => state.configurations);
	const workflowTypeId = useSelector(state => state.user.userSession.healthSystem.workflowTypeId);
	const isUrlMenuItem = url => menuUrls.some(item => url.includes(item));

	const getMenus = configs => {
		const adminMenus = configs.adminConfigurableMenu[getUserRole()];
		const result = [];
		Object.entries(adminMenus).forEach(([, item]) => {
			if (
				item.value &&
				!item.isGeneralSetting &&
				!item.parentDependency &&
				!(workflowTypeId === RouteTypes.DEFAULT && item.reDirectUrl === '/nurse-home')
			) {
				result.push(item);
			}
			if (item.value && !item.isGeneralSetting && item.parentDependency && adminMenus[item.parentDependency].value) {
				result.push(item);
			}
		});
		result.sort((a, b) => a.orderNo - b.orderNo);
		return result;
	};

	const isMenuUnAvailable = (path, routeType) => {
		const areEditableByAdmin = [
			UserRoles.DOCTOR,
			UserRoles.DIGITAL_CLINICIAN,
			UserRoles.NURSE,
			UserRoles.VIRTUAL_SITTER,
			UserRoles.SUPER_USER,
			UserRoles.ADMIN,
		].includes(getUserRole());
		const isNotInMenu = isUrlMenuItem(path) && !getMenus(configurations).some(item => path.includes(item.reDirectUrl));
		const existsInRoutes = path !== '*';
		return (
			(areEditableByAdmin && isNotInMenu && existsInRoutes) ||
			(areEditableByAdmin && [RouteTypes.DEFAULT, RouteTypes.PRIMARY_CARE].includes(routeType) && workflowTypeId !== routeType)
		);
	};

	const renderFn = Component => props => {
		if (!!Component && isAuthenticated()) {
			const adminConfigurableMenu = getConfigurationMenu(configurations.adminConfigurableMenu, getUserRole());
			const configurableMenu = getConfigurationMenu(configurations.configurableMenu, getUserRole());
			const integrationId = getStorage().getItem('iframe_integration_id');
			const isNotAuthorized = !isRoleAuthorized(rest.roles) || isStandAloneAppsUserAuthorized() || !isUserInfoLoaded();
			if (isNotAuthorized) {
				return <Unauthorized />;
			}
			if (getUserRole() !== UserRoles.GUEST && isInvalidIPAddress()) {
				return <UserIPError />;
			}
			if (!isContinueAsSelected() && !integrationId) {
				return <ContinueAs />;
			}
			if (shouldCompleteRegistration(props) || (getUserRole() === UserRoles.DOCTOR && !getDoctorSpecialty())) {
				return <CompleteRegistration />;
			}
			if (
				healthSystems.isHealthSystemFetched &&
				healthSystems.allHealthSystems.length === 0 &&
				![UserRoles.GUEST, UserRoles.PATIENT, UserRoles.SUPER_ADMIN, UserRoles.VISITOR].includes(getUserRole())
			) {
				return <NoHealthSystem />;
			}
			if (isMenuUnAvailable(props.match.path, rest.type) && !isNotAuthorized) {
				return (
					<Unauthorized
						featureFlagsDisabled={!getMenus(configurations).some(item => props.match.path.includes(item.reDirectUrl))}
						isAuthorized={isNotAuthorized}
					/>
				);
			}
			if (
				getUserRole() === UserRoles.NURSE &&
				getConfigurationValue(adminConfigurableMenu[GeneralSettings.PoolingFlow]) &&
				getConfigurationValue(configurableMenu[GeneralSettings.PoolingFlow]) &&
				!getNursePoolingStatus() &&
				!isSessionEhr() &&
				!isRedirectVirtualCareSession() &&
				!integrationId
			) {
				return <PoolingFlowWrapper {...props} />;
			}
			return <Component {...props} />;
		}

		const refToken = getStorage().getItem('ref_token');
		if (refToken) {
			signinRedirectRefToken(refToken);
			return (
				<Grid columns='1fr' rows='1fr' stretch='100vh' horizAlign='center' vertAlign='center'>
					<div style={{ textAlign: 'center' }}>
						<Loader />
					</div>
				</Grid>
			);
		}

		signinRedirect();
		return (
			<Grid columns='1fr' rows='1fr' stretch='100vh' horizAlign='center' vertAlign='center'>
				<div style={{ textAlign: 'center' }}>
					<Loader />
				</div>
			</Grid>
		);
	};

	return <Route render={renderFn(component)} {...rest} />;
};

export default PrivateRoute;
