import React, { useEffect, useRef, useState, useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import ReactSelect from 'react-select';
import { getPatientMobilityHistory, getPatientsAiSettings, updatePatientsAiSettings } from 'api/patients.js';
import { Alert, ToastMessage } from 'components/index.js';
import {
	PatientsAiSettings,
	SensitivityType,
	RailType,
	ExcludedAiSettings,
	AiAlertTypes,
	PatientWarnings,
	AiAnalyticsTypeId,
} from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import { MonitoringSettings } from 'constants/configurationEnums.js';
import { actionCreators as aiSettingsActionCreators } from 'state/aiSettings/actions.js';
import { getConfigurationValue, validateMaxLength } from 'infrastructure/helpers/commonHelpers.js';
import HandWashing from 'icons/Monitoring/HandWashing.jsx';
import FallDetection from 'icons/Monitoring/FallDetection.jsx';
import Rails from 'icons/Monitoring/Rails.jsx';
import PatientPrivacy from 'icons/Monitoring/PatientPrivacy.jsx';
import PatientWalking from 'icons/Monitoring/PatientWalking.jsx';
import InactiveTime from 'icons/Monitoring/InactiveTime.jsx';
import HeartRate from 'icons/Monitoring/HeartRate.jsx';
import OutOfBed from 'icons/Monitoring/OutOfBed.jsx';
import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';
import { generateCustomStyles } from 'constants/react-select-style.js';
import IvBag from 'icons/Monitoring/IvBag.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';
import AiConfigurations from 'components/MonitoringSettings/AiConfigurations.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import {
	Option,
	MultiValueContainer,
	MultiValue,
	MenuList,
	Control,
} from 'components/Common/ReactSelectCustomComponents/index.js';
import { Chart } from 'primereact/chart.js';
import { StyledCircularChartWrapper } from 'css/StyledComponents/index.js';
import { getDurationFormat } from 'infrastructure/helpers/dateHelper.js';
import { aiFormatOptionLabel } from 'infrastructure/helpers/aiHelper.js';

const PatientAiSettings = ({
	deviceId,
	patientId,
	onAiSettingClick,
	adminAiSettingsConfigurations,
	areEmergencyButtonsHiden,
	isNoteShowing,
	isDarkMode,
	toggleFallPrevention,
	isFallPrevention,
	userId,
	onAlertInfoClose,
	feedWarning,
	stopVoiceOver,
	roomId,
}) => {
	const intl = useIntl();

	const socket = useContext(SocketContext);

	const aiTypesToShow = [
		{
			aiTypeId: PatientsAiSettings.FALL_PREVENTION,
			label: intl.formatMessage({ id: 'fallPrevention' }),
			img: '',
			show:
				getConfigurationValue(adminAiSettingsConfigurations[MonitoringSettings.GetOutOfBed]) ||
				getConfigurationValue(adminAiSettingsConfigurations[MonitoringSettings.Rails]) ||
				getConfigurationValue(adminAiSettingsConfigurations[MonitoringSettings.FallDetection]),
			noBorder: true,
			isTitle: true,
		},
		{
			aiTypeId: PatientsAiSettings.PERSON_GETTING_OUT_OF_BED,
			label: intl.formatMessage({ id: 'getOutOfBed' }),
			img: <OutOfBed color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
			show: getConfigurationValue(adminAiSettingsConfigurations[MonitoringSettings.GetOutOfBed]),
			siblings: [PatientsAiSettings.RAIL_DOWN, PatientsAiSettings.FALL_DETECTED],
			noBorder: true,
		},
		{
			aiTypeId: PatientsAiSettings.RAIL_DOWN,
			label: intl.formatMessage({ id: 'rails' }),
			img: <Rails color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
			show: getConfigurationValue(adminAiSettingsConfigurations[MonitoringSettings.Rails]),
			siblings: [PatientsAiSettings.PERSON_GETTING_OUT_OF_BED, PatientsAiSettings.FALL_DETECTED],
			noBorder: true,
		},
		{
			aiTypeId: PatientsAiSettings.FALL_DETECTED,
			label: intl.formatMessage({ id: 'fallDetection' }),
			img: <FallDetection color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
			show: getConfigurationValue(adminAiSettingsConfigurations[MonitoringSettings.FallDetection]),
			siblings: [PatientsAiSettings.PERSON_GETTING_OUT_OF_BED, PatientsAiSettings.RAIL_DOWN],
		},
		{
			aiTypeId: PatientsAiSettings.PERSON_STANDING_WALKING,
			label: intl.formatMessage({ id: 'patientMobility' }),
			img: <PatientWalking color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
			show: getConfigurationValue(adminAiSettingsConfigurations[MonitoringSettings.PatientWalking]),
			subTitle: intl.formatMessage({ id: 'patientMobility' }),
		},
		{
			aiTypeId: PatientsAiSettings.PERSON_INACTIVE,
			label: intl.formatMessage({ id: 'pressureInjuries' }),
			img: <InactiveTime color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
			show: getConfigurationValue(adminAiSettingsConfigurations[MonitoringSettings.InactiveTime]),
			subTitle: intl.formatMessage({ id: 'others' }),
			noBorder: true,
		},
		{
			aiTypeId: PatientsAiSettings.SKELETON_PRIVATE_MODE,
			label: intl.formatMessage({ id: 'privacyMode' }),
			img: <PatientPrivacy color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
			show: getConfigurationValue(adminAiSettingsConfigurations[MonitoringSettings.AIPrivacyMode]),
			noBorder: true,
		},
		{
			aiTypeId: PatientsAiSettings.HAND_WASHING,
			label: intl.formatMessage({ id: 'handWashing' }),
			img: <HandWashing color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
			show: getConfigurationValue(adminAiSettingsConfigurations[MonitoringSettings.HandWashing]),
			noBorder: true,
		},
		{
			aiTypeId: PatientsAiSettings.VITAL_SIGNS_AI,
			label: intl.formatMessage({ id: 'ewsAiVitalSigns' }),
			img: <HeartRate color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
			show: getConfigurationValue(adminAiSettingsConfigurations[MonitoringSettings.EWSAIVitalSigns]),
		},

		{
			aiTypeId: PatientsAiSettings.IV_BAG,
			label: intl.formatMessage({ id: 'ivBagFluidLevel' }),
			img: <IvBag color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
			show: getConfigurationValue(adminAiSettingsConfigurations[MonitoringSettings.IVBagFluidLevel]),
			subTitle: 'AI Rounding',
		},
	];

	const inactiveHours = useRef([
		{ label: `10 ${intl.formatMessage({ id: 'seconds' })}`, value: '10' },
		{ label: `1 ${intl.formatMessage({ id: 'hour' })}`, value: '3600' },
		{ label: `2 ${intl.formatMessage({ id: 'hour' })}`, value: '7200' },
		{ label: `3 ${intl.formatMessage({ id: 'hour' })}`, value: '10800' },
		{ label: `4 ${intl.formatMessage({ id: 'hour' })}`, value: '14400' },
	]);

	const sensitivityTypes = useRef([
		{ label: intl.formatMessage({ id: 'high' }), value: SensitivityType.HIGH },
		{ label: intl.formatMessage({ id: 'medium' }), value: SensitivityType.MEDIUM },
		{ label: intl.formatMessage({ id: 'low' }), value: SensitivityType.LOW },
	]);

	const railsOptions = useRef([
		{
			label: `${intl.formatMessage({ id: 'left' })} ${intl.formatMessage({ id: 'head' })} ${intl.formatMessage({ id: 'rail' })}`,
			value: RailType.TOP_LEFT,
		},
		{
			label: `${intl.formatMessage({ id: 'right' })} ${intl.formatMessage({ id: 'head' })} ${intl.formatMessage({ id: 'rail' })}`,
			value: RailType.TOP_RIGHT,
		},
		{
			label: `${intl.formatMessage({ id: 'left' })} ${intl.formatMessage({ id: 'foot' })} ${intl.formatMessage({
				id: 'rail',
			})} `,
			value: RailType.BOTTOM_LEFT,
		},

		{
			label: `${intl.formatMessage({ id: 'right' })} ${intl.formatMessage({ id: 'foot' })} ${intl.formatMessage({
				id: 'rail',
			})} `,
			value: RailType.BOTTOM_RIGHT,
		},
	]);

	const [selectedInactiveTime, setSelectedInactiveTime] = useState(inactiveHours.current[0]);
	const [selectedSensitivity, setSelectedSensitivity] = useState(sensitivityTypes.current[0]);
	const [selectedRails, setSelectedRails] = useState([railsOptions.current[1], railsOptions.current[3]]);
	const [error, setError] = useState(null);
	const [isMoreThanOneAi, setIsMoreThanOneAi] = useState(false);
	const [ivBagwarningValue, setIvBagwarningValue] = useState(null);
	const [isInit, setIsInit] = useState(true);
	const [isSettingsClicked, setIsSettingsClicked] = useState(false);
	const [selectedItem, setSelectedItem] = useState(null);
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [patientMobilityHistory, setPatientMobilityHistory] = useState(null);
	const [patientHistoryTypes] = useState([
		{
			id: 1,
			label: intl.formatMessage({ id: 'lying' }),
			duration: 'lying',
			percentage: 'lyingPercentage',
			color: '#5390FF',
		},
		{
			id: 2,
			label: intl.formatMessage({ id: 'sitting' }),
			duration: 'sitting',
			percentage: 'sittingPercentage',
			color: '#BED5FF',
		},
		{
			id: 1,
			label: intl.formatMessage({ id: 'walking' }),
			duration: 'walking',
			percentage: 'walkingPercentage',
			color: '#6BC6B5',
		},
	]);
	const aiSettingList = useSelector(state => state.aiSettingsList.aiSettings);
	const dispatch = useDispatch();
	const setAiSettingsAction = ai => dispatch(aiSettingsActionCreators.setPatientAiSettings(ai));
	const user = useSelector(state => state.user);

	const totalPatientMobilityHistory = getDurationFormat(
		patientMobilityHistory?.lying + patientMobilityHistory?.sitting + patientMobilityHistory?.walking || 0
	);

	useEffect(() => {
		const getPatientAiSettings = async () => {
			const response = await getPatientsAiSettings({ patientId, deviceId, roomId });
			if (response.error) {
				setError(response.error.message);
				return;
			}
			const inactiveSettingType = response.patientAiSettings.find(
				item => item.settingTypeId === PatientsAiSettings.PERSON_INACTIVE
			);
			if (inactiveSettingType?.value) {
				setSelectedInactiveTime(inactiveHours.current.find(item => item.value === inactiveSettingType.value));
			}
			const isAiSettingEnabled = response.patientAiSettings.some(item => !['false', undefined, null].includes(item.value));
			const fallPreventionStatus = response.patientAiSettings
				.filter(item =>
					[PatientsAiSettings.PERSON_GETTING_OUT_OF_BED, PatientsAiSettings.RAIL_DOWN, PatientsAiSettings.FALL_DETECTED].includes(
						item.settingTypeId
					)
				)
				.some(item => !['false', undefined, null].includes(item.value));
			toggleFallPrevention(fallPreventionStatus, deviceId);
			onAiSettingClick(isAiSettingEnabled);
			setAiSettingsAction({ deviceId, settings: response.patientAiSettings });
			setIsInit(false);
		};
		getPatientAiSettings();
	}, [patientId, deviceId, isSettingsClicked]);

	useEffect(() => {
		const onPatientAiSettingsUpdated = data => {
			if (deviceId !== data.deviceId) {
				return;
			}
			const inactiveSettingType = data.patientAiSettings.find(item => item.settingTypeId === PatientsAiSettings.PERSON_INACTIVE);
			if (inactiveSettingType?.value) {
				setSelectedInactiveTime(inactiveHours.current.find(item => item.value === inactiveSettingType.value));
			}
			const isAiSettingEnabled = data.patientAiSettings.some(item => !['false', undefined, null].includes(item.value));
			const fallPreventionStatus = data.patientAiSettings
				.filter(item =>
					[PatientsAiSettings.PERSON_GETTING_OUT_OF_BED, PatientsAiSettings.RAIL_DOWN, PatientsAiSettings.FALL_DETECTED].includes(
						item.settingTypeId
					)
				)
				.some(item => !['false', undefined, null].includes(item.value));

			toggleFallPrevention(fallPreventionStatus, deviceId);
			onAiSettingClick(isAiSettingEnabled);
			setAiSettingsAction({ deviceId, settings: data.patientAiSettings });
		};
		socket.on(SocketEvents.Alerts.PATIENT_AI_SETTINGS_UPDATED, onPatientAiSettingsUpdated);
		return () => {
			socket.off(SocketEvents.Alerts.PATIENT_AI_SETTINGS_UPDATED, onPatientAiSettingsUpdated);
		};
	}, [socket, userId, deviceId]);

	useEffect(() => {
		const found = aiSettingList.find(item => item.deviceId === deviceId);
		if (!found) {
			return;
		}
		const selectedSensitivityValue = found.settings.find(
			item => item.settingTypeId === PatientsAiSettings.PERSON_GETTING_OUT_OF_BED
		)?.value;
		if (selectedSensitivityValue) {
			setSelectedSensitivity(sensitivityTypes.current.find(item => item.value === selectedSensitivityValue));
		}
		if (!selectedSensitivityValue && isInit) {
			setSelectedSensitivity(null);
		}
	}, [aiSettingList, deviceId]);

	useEffect(() => {
		const foundAiSetting = aiSettingList.find(item => item.deviceId === deviceId);
		if (!foundAiSetting) {
			return;
		}
		const foundRailDown = foundAiSetting.settings.find(item => item.settingTypeId === PatientsAiSettings.RAIL_DOWN)?.value;
		if (foundRailDown) {
			const onMountRails = railsOptions.current.filter(rail => foundRailDown.includes(rail.value));
			setSelectedRails(onMountRails);
		}
		if (!foundRailDown && isInit) {
			setSelectedRails([]);
		}
	}, [aiSettingList, deviceId]);

	useEffect(() => {
		const foundAiSetting = aiSettingList.find(item => item.deviceId === deviceId);
		if (!foundAiSetting) {
			return;
		}
		const ivBagFluidLevel = foundAiSetting.settings.find(item => item.settingTypeId === PatientsAiSettings.IV_BAG)?.value;

		if (ivBagFluidLevel) {
			setIvBagwarningValue(parseInt(ivBagFluidLevel, 10));
		} else {
			setIvBagwarningValue(null);
		}
	}, [aiSettingList, deviceId]);

	useEffect(() => {
		const toastTimeOut = setTimeout(() => {
			setIsMoreThanOneAi(false);
		}, 3000);

		return () => {
			clearTimeout(toastTimeOut);
		};
	}, [isMoreThanOneAi]);

	useEffect(() => {
		const fetchPatientMobilityHistory = async () => {
			const response = await getPatientMobilityHistory(patientId);
			if (response.error) {
				setError(response.error.message);
				return;
			}
			setPatientMobilityHistory(response);
		};
		fetchPatientMobilityHistory();
	}, [patientId]);

	useEffect(() => {
		const onAlertAnalyticsAdded = data => {
			let totalSum = patientMobilityHistory.lying + patientMobilityHistory.sitting + patientMobilityHistory.walking;
			const { LYING_DOWN, SITTING, WALKING, LYING_FRONT, LYING_SIDE } = AiAnalyticsTypeId;
			if (
				![LYING_DOWN, LYING_FRONT, LYING_SIDE, SITTING, WALKING].includes(data.analyticsType) ||
				data.deviceId !== deviceId ||
				data.patientId !== patientId
			) {
				return;
			}
			switch (data.analyticsType) {
				case LYING_DOWN:
				case LYING_FRONT:
				case LYING_SIDE:
					totalSum += +data.value;
					setPatientMobilityHistory({
						...patientMobilityHistory,
						lying: patientMobilityHistory?.lying + +data.value,
						lyingPercentage: Math.round(((patientMobilityHistory.lying + +data.value) / totalSum || 0) * 100),
						sittingPercentage: Math.round((patientMobilityHistory.sitting / totalSum || 0) * 100),
						walkingPercentage: Math.round((patientMobilityHistory.walking / totalSum || 0) * 100),
					});
					break;
				case SITTING:
					totalSum += +data.value;
					setPatientMobilityHistory({
						...patientMobilityHistory,
						sitting: patientMobilityHistory?.sitting + +data.value,
						lyingPercentage: Math.round((patientMobilityHistory.lying / totalSum || 0) * 100),
						sittingPercentage: Math.round(((patientMobilityHistory.sitting + +data.value) / totalSum || 0) * 100),
						walkingPercentage: Math.round((patientMobilityHistory.walking / totalSum || 0) * 100),
					});
					break;
				case WALKING:
					totalSum += +data.value;
					setPatientMobilityHistory({
						...patientMobilityHistory,
						walking: patientMobilityHistory?.walking + +data.value,
						lyingPercentage: Math.round((patientMobilityHistory.lying / totalSum || 0) * 100),
						sittingPercentage: Math.round((patientMobilityHistory.sitting / totalSum || 0) * 100),
						walkingPercentage: Math.round(((patientMobilityHistory.walking + +data.value) / totalSum || 0) * 100),
					});
					break;
				default:
					setPatientMobilityHistory({ ...patientMobilityHistory });
			}
		};
		socket.on(SocketEvents.Alerts.ALERT_ANALYTICS_ADDED, onAlertAnalyticsAdded);
		return () => {
			socket.off(SocketEvents.Alerts.ALERT_ANALYTICS_ADDED, onAlertAnalyticsAdded);
		};
	}, [patientMobilityHistory]);

	const setPersonGettingOutOfBed = (aiType, isDisabled = false) => {
		if (!selectedSensitivity && aiType === PatientsAiSettings.PERSON_GETTING_OUT_OF_BED && !isDisabled) {
			setSelectedSensitivity({ label: intl.formatMessage({ id: 'medium' }), value: SensitivityType.MEDIUM });
		}
	};

	const setPersonBedRails = (aiType, isDisabled = false) => {
		if (!selectedRails && aiType === PatientsAiSettings.RAIL_DOWN && !isDisabled) {
			setSelectedRails([railsOptions.current[1], railsOptions.current[3]]);
		}
	};

	const toggleBulkSettings = async status => {
		let dataToSend = [];
		if (status) {
			dataToSend = [
				{
					settingTypeId: PatientsAiSettings.PERSON_GETTING_OUT_OF_BED,
					value: getConfigurationValue(adminAiSettingsConfigurations[MonitoringSettings.GetOutOfBed])
						? selectedSensitivity?.value || SensitivityType.MEDIUM
						: null,
				},
				{
					settingTypeId: PatientsAiSettings.RAIL_DOWN,
					value: getConfigurationValue(adminAiSettingsConfigurations[MonitoringSettings.Rails])
						? `${RailType.TOP_RIGHT}-${RailType.TOP_LEFT}`
						: null,
				},
				{
					settingTypeId: PatientsAiSettings.FALL_DETECTED,
					value: getConfigurationValue(adminAiSettingsConfigurations[MonitoringSettings.FallDetection]) ? 'true' : 'false',
				},
			];
		} else {
			dataToSend = [
				{
					settingTypeId: PatientsAiSettings.PERSON_GETTING_OUT_OF_BED,
					value: null,
				},
				{
					settingTypeId: PatientsAiSettings.RAIL_DOWN,
					value: null,
				},
				{
					settingTypeId: PatientsAiSettings.FALL_DETECTED,
					value: 'false',
				},
			];
		}
		if (shouldDisableClick(PatientsAiSettings.FALL_PREVENTION, status)) {
			setIsMoreThanOneAi(true);
			return;
		}
		toggleFallPrevention(!isFallPrevention, deviceId);
		if (isFallPrevention) {
			setSelectedSensitivity(null);
			setSelectedRails([]);
		}
		const found = aiSettingList.find(item => item.deviceId === deviceId);
		if (!found) {
			return;
		}
		const settings = _.cloneDeep(aiSettingList);
		const initialSettings = settings.find(item => item.deviceId === deviceId).settings;
		const newSettings = _.cloneDeep(initialSettings).filter(
			item =>
				![PatientsAiSettings.PERSON_GETTING_OUT_OF_BED, PatientsAiSettings.RAIL_DOWN, PatientsAiSettings.FALL_DETECTED].includes(
					item.settingTypeId
				)
		);

		const newDataToSend = dataToSend.map(item => {
			const matchingSetting = initialSettings.find(setting => setting.settingTypeId === item.settingTypeId);
			return {
				...item,
				snoozeTime: matchingSetting?.snoozeTime ?? null,
			};
		});

		setAiSettingsAction({
			settings: [...newSettings, ...newDataToSend],
			deviceId,
		});

		const params = {
			patientId,
			deviceId,
			roomId,
			sendData: newDataToSend,
		};

		const response = await updatePatientsAiSettings(params);

		if (response.error) {
			setError(response.error.message);
			setAiSettingsAction({
				settings: initialSettings,
				deviceId,
			});
			return;
		}
		onAiSettingClick(status);
	};

	const clickOnCheckBox = aiType => {
		closeFeedAlerts(aiType);
		if (aiType === PatientsAiSettings.FALL_PREVENTION) {
			toggleBulkSettings(!isFallPrevention);
			return;
		}
		setPersonGettingOutOfBed(aiType);
		setPersonBedRails(aiType);
	};

	const shouldDisableClick = (settingTypeId, value) => {
		const settings = _.cloneDeep(aiSettingList);
		const found = settings.find(item => item.deviceId === deviceId);
		const fallPreventionTypes = [
			PatientsAiSettings.FALL_PREVENTION,
			PatientsAiSettings.RAIL_DOWN,
			PatientsAiSettings.FALL_DETECTED,
			PatientsAiSettings.PERSON_GETTING_OUT_OF_BED,
		];

		const foundInFallPrevention = fallPreventionTypes.find(item => item === settingTypeId);

		let isAiSettingEnabled = found.settings.some(
			item =>
				!ExcludedAiSettings.includes(item.settingTypeId) &&
				!['false', undefined, null].includes(item.value) &&
				item.settingTypeId !== settingTypeId
		);

		if (foundInFallPrevention) {
			isAiSettingEnabled = found.settings.reduce((acc, item) => {
				const isValid =
					!ExcludedAiSettings.includes(item.settingTypeId) &&
					!['false', undefined, null].includes(item.value) &&
					item.settingTypeId !== settingTypeId &&
					!fallPreventionTypes.includes(item.settingTypeId);

				return acc || isValid;
			}, false);
		}

		return isAiSettingEnabled && !['false', undefined, null].includes(value) && settingTypeId !== PatientsAiSettings.HAND_WASHING;
	};

	const resetSelectedItem = (settingTypeId, value) => {
		if (settingTypeId === PatientsAiSettings.RAIL_DOWN && !value) {
			setSelectedRails([]);
		}
		if (settingTypeId === PatientsAiSettings.PERSON_GETTING_OUT_OF_BED && !value) {
			setSelectedSensitivity(null);
		}
	};

	const closeFeedAlerts = settingTypeId => {
		const found = aiSettingList.find(item => item.deviceId === deviceId);
		if (!found) {
			return;
		}
		const selectedSetting = found.settings.find(item => item.settingTypeId === settingTypeId);
		const isAiSettingOn = !['false', undefined, null].includes(selectedSetting?.value);

		const foundAiSettingKey = Object.keys(PatientsAiSettings).find(
			key => PatientsAiSettings[key] === selectedSetting?.settingTypeId
		);

		const isAnyAlertOnFallPrevention = [
			AiAlertTypes.PERSON_GETTING_OUT_OF_BED,
			AiAlertTypes.RAIL_DOWN,
			AiAlertTypes.FALL_DETECTION,
		].includes(feedWarning?.type);

		const isPatientMobilityAlert = Object.values(AiAlertTypes).find(alert => alert == feedWarning?.type);

		const foundActiveAlert =
			(AiAlertTypes[foundAiSettingKey] || PatientWarnings[foundAiSettingKey]) === feedWarning?.type ? feedWarning?.type : null;

		if (isAiSettingOn || feedWarning?.value) {
			switch (settingTypeId) {
				case PatientsAiSettings.PERSON_INACTIVE:
					onAlertInfoClose(deviceId, true);
					break;
				case PatientsAiSettings.HAND_WASHING:
					onAlertInfoClose(deviceId);
					break;
				case PatientsAiSettings.VITAL_SIGNS_AI:
				case PatientsAiSettings.IV_BAG:
					stopVoiceOver(feedWarning?.type, deviceId);
					break;
				case PatientsAiSettings.FALL_PREVENTION:
					if (isAnyAlertOnFallPrevention) {
						stopVoiceOver(feedWarning?.type, deviceId);
					}
					break;
				default:
					break;
			}
			if (![undefined, null].includes(foundActiveAlert) || isPatientMobilityAlert) {
				stopVoiceOver(foundActiveAlert || isPatientMobilityAlert, deviceId);
			}
		}
	};

	const toggleAiSettings = async (settingTypeId, customValue = '') => {
		const settings = _.cloneDeep(aiSettingList);
		const found = settings.find(item => item.deviceId === deviceId);
		if (!found) {
			return;
		}
		const selectedSetting = found.settings.find(item => item.settingTypeId === settingTypeId);

		let value = selectedSetting?.value === 'true' ? 'false' : 'true';

		if (!customValue) {
			if (settingTypeId === PatientsAiSettings.PERSON_INACTIVE && !selectedSetting?.value) {
				value = selectedInactiveTime.value;
			}
			if (settingTypeId === PatientsAiSettings.IV_BAG && !selectedSetting?.value) {
				value = ivBagwarningValue.toString();
			}
			if (settingTypeId === PatientsAiSettings.PERSON_GETTING_OUT_OF_BED && !selectedSetting?.value) {
				value = selectedSensitivity?.value || SensitivityType.MEDIUM;
			}
			if (settingTypeId === PatientsAiSettings.RAIL_DOWN && !selectedSetting?.value) {
				const stringifiedRailValues = selectedRails.map(rail => rail.value).join('-');
				value = stringifiedRailValues || `${RailType.TOP_RIGHT}-${RailType.TOP_LEFT}`;
			}
			if (
				[
					PatientsAiSettings.PERSON_INACTIVE,
					PatientsAiSettings.PERSON_GETTING_OUT_OF_BED,
					PatientsAiSettings.IV_BAG,
					PatientsAiSettings.RAIL_DOWN,
				].includes(settingTypeId) &&
				selectedSetting?.value
			) {
				value = null;
			}
		} else {
			value = customValue;
		}

		if (shouldDisableClick(settingTypeId, value)) {
			setIsMoreThanOneAi(true);
			return;
		}

		resetSelectedItem(settingTypeId, value);

		if (settingTypeId === PatientsAiSettings.PERSON_INACTIVE && !value) {
			onAlertInfoClose(deviceId, true);
		}

		const dataToSend = [
			{
				settingTypeId,
				value,
				snoozeTime: selectedSetting?.snoozeTime,
			},
		];

		setAiSettingsAction({
			settings: [
				...found.settings.filter(item => item.settingTypeId !== settingTypeId),
				{ settingTypeId, value, snoozeTime: selectedSetting?.snoozeTime },
			],
			deviceId,
		});

		const params = {
			patientId,
			deviceId,
			roomId,
			sendData: dataToSend,
		};

		const response = await updatePatientsAiSettings(params);
		if (settingTypeId === PatientsAiSettings.IV_BAG && !value) {
			setIvBagwarningValue('');
		}

		const initialSettings = found.settings;

		if (response.error) {
			setError(response.error.message);
			setAiSettingsAction({
				settings: initialSettings,
				deviceId,
			});
			return;
		}

		let isAiSettingEnabled = found.settings.reduce((acc, item) => {
			return acc || (item.settingTypeId !== settingTypeId && !['false', undefined, null].includes(item.value));
		}, false);

		if (
			[PatientsAiSettings.PERSON_GETTING_OUT_OF_BED, PatientsAiSettings.RAIL_DOWN].includes(settingTypeId) &&
			!['false', undefined, null].includes(value)
		) {
			isAiSettingEnabled = true;
		}

		onAiSettingClick(['false', undefined, null].includes(value) ? isAiSettingEnabled : true);

		if (
			[PatientsAiSettings.FALL_DETECTED, PatientsAiSettings.RAIL_DOWN, PatientsAiSettings.PERSON_GETTING_OUT_OF_BED].includes(
				settingTypeId
			) &&
			!isAiSettingEnabled
		) {
			toggleFallPrevention(false, deviceId);
		}
	};

	const getAiTypes = () => {
		const settings = _.cloneDeep(aiSettingList);
		const found = settings.find(item => item.deviceId === deviceId);
		if (!found) {
			return [];
		}
		return found.settings;
	};

	const getAiTypeValue = aiType => getAiTypes().find(item => item.settingTypeId === aiType)?.value;

	const isOptionChecked = aiTypeId => {
		if (aiTypeId === PatientsAiSettings.FALL_PREVENTION && isFallPrevention) {
			return isFallPrevention;
		}
		return !['false', undefined, null].includes(getAiTypeValue(aiTypeId));
	};

	const handleRailsOnSelect = item => {
		setSelectedRails(item);
		const stringifiedValues = item.map(rail => rail.value).join('-');
		if (isOptionChecked(PatientsAiSettings.RAIL_DOWN)) {
			toggleAiSettings(PatientsAiSettings.RAIL_DOWN, stringifiedValues);
		}
	};

	const data = useMemo(() => {
		return {
			labels: [intl.formatMessage({ id: 'lying' }), intl.formatMessage({ id: 'sitting' }), intl.formatMessage({ id: 'walking' })],
			datasets: [
				{
					data: [patientMobilityHistory?.lying || 0, patientMobilityHistory?.sitting || 0, patientMobilityHistory?.walking || 0],
					backgroundColor: ['#5390FF', '#BED5FF', '#6BC6B5'],
					borderColor: 'transparent',
				},
			],
		};
	}, [patientMobilityHistory]);

	const options = {
		legend: {
			display: false,
		},
		maintainAspectRatio: false,
		cutoutPercentage: 80,
		tooltips: {
			callbacks: {
				label: (ttItem, data) => getDurationFormat(data.datasets[0].data[ttItem.index]),
			},
		},
		animation: {
			duration: 0,
		},
	};

	return (
		<div className='ai-alerts-wrapper'>
			{aiTypesToShow.map(
				aiType =>
					!isSettingsClicked &&
					aiType.show && (
						<div
							className={classNames(
								'flex flex-space-between alert-settings',
								aiType.subTitle ||
									aiType.aiTypeId === PatientsAiSettings.PERSON_GETTING_OUT_OF_BED ||
									aiType.aiTypeId === PatientsAiSettings.RAIL_DOWN ||
									aiType.aiTypeId === PatientsAiSettings.PERSON_INACTIVE ||
									aiType.aiTypeId === PatientsAiSettings.PERSON_STANDING_WALKING
									? 'flex-wrap'
									: '',
								aiType.noBorder ? 'no-border-bottom' : '',
								aiType.isTitle ? 'title-alert-setting' : '',
								[
									PatientsAiSettings.PERSON_GETTING_OUT_OF_BED,
									PatientsAiSettings.RAIL_DOWN,
									PatientsAiSettings.FALL_DETECTED,
								].includes(aiType.aiTypeId) && !isFallPrevention
									? 'disabled'
									: ''
							)}
							key={aiType.aiTypeId}>
							{aiType.subTitle && <span>{aiType.subTitle}</span>}
							<div className='flex flex-justify-center flex-align-center'>
								{aiType.img}
								<label className='left-s right-s'>{aiType.label}</label>
							</div>
							<div className='flex flex-align-center'>
								<label
									className={classNames(
										'switch-label',
										aiType.aiTypeId === PatientsAiSettings.IV_BAG && !isOptionChecked(aiType.aiTypeId) && !ivBagwarningValue
											? 'disabled'
											: ''
									)}>
									<input
										type={isOptionChecked(aiType.aiTypeId) ? 'checkbox' : ''}
										checked={isOptionChecked(aiType.aiTypeId)}
										onClick={() => {
											clickOnCheckBox(aiType.aiTypeId);
											if (aiType.aiTypeId !== PatientsAiSettings.FALL_PREVENTION) {
												toggleAiSettings(aiType.aiTypeId);
											}
										}}
									/>
									<span className='slider-input-alert round' />
								</label>
								{[
									PatientsAiSettings.PERSON_GETTING_OUT_OF_BED,
									PatientsAiSettings.RAIL_DOWN,
									PatientsAiSettings.FALL_DETECTED,
									PatientsAiSettings.PERSON_STANDING_WALKING,
								].includes(aiType.aiTypeId) && (
									<button
										type='button'
										className='no-background padding-right-0'
										onClick={() => {
											setIsSettingsClicked(true);
											setSelectedItem(aiType);
										}}>
										<img
											className='dropdown-icon-img'
											src={`${healthCareCdnUrl}${!isDarkMode ? 'header' : 'dark-mode'}/account-settings.svg`}
											alt={intl.formatMessage({
												id: 'monitoringSettings',
											})}
										/>
									</button>
								)}
							</div>
							{aiType.aiTypeId === PatientsAiSettings.PERSON_INACTIVE && (
								<div className='flex full-width top-15'>
									<ReactSelect
										options={inactiveHours.current}
										value={selectedInactiveTime}
										id='inactiveElement'
										onChange={item => setSelectedInactiveTime(item)}
										classNamePrefix='react-select'
										styles={{
											...generateCustomStyles({ darkMode: user.darkMode }),
											menu: base => ({ ...base, width: '100%' }),
											menuList: base => ({
												...base,
												height: 'fit-content',
												width: '100%',
												backgroundColor: isDarkMode ? DarkTheme.colors.grayThree : LightTheme.colors.grayZero,
											}),
										}}
									/>
								</div>
							)}
							{aiType.aiTypeId === PatientsAiSettings.IV_BAG && (
								<>
									<div className='flex flex-basis-100 top-15 flex-align-center'>
										<label className='right-s'>{translate('warningAlertAt')}</label>
										<div className='position-relative'>
											<input
												type='number'
												name='ivBagFluidLevel'
												value={ivBagwarningValue}
												onChange={e => setIvBagwarningValue(e.target.value)}
												onKeyDown={event => validateMaxLength(event, 2)}
												max={100}
											/>
											<span>%</span>
										</div>
									</div>
									<div className='flex-basis-100 top-15'>
										<small>{translate('enableIvBagWarning')}</small>
									</div>
								</>
							)}
						</div>
					)
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
			<ToastMessage
				showToast={isMoreThanOneAi}
				onClose={() => setIsMoreThanOneAi(false)}
				className={classNames(
					'video-feed-toast-message',
					isNoteShowing || !areEmergencyButtonsHiden ? 'emergency-toast-message' : '',
					isNoteShowing && !areEmergencyButtonsHiden ? 'notes-toast-message' : ''
				)}>
				<span>{translate('noMoreThanOneAiType')}</span>
			</ToastMessage>
			{isSettingsClicked && (
				<>
					<div className='configuration-details-title'>
						<div className='cursor-pointer --blue-color go-back' onClick={() => setIsSettingsClicked(false)}>
							<img src={`${healthCareCdnUrl}monitoring/video-feed/back-arrow.svg`} alt='back arrow icon' />
						</div>
						<p className='semi-bold left-10 font-12'>{translate(selectedItem?.label)}</p>
					</div>
					{selectedItem.aiTypeId === PatientsAiSettings.PERSON_GETTING_OUT_OF_BED && (
						<>
							<p className='semi-bold font-14'>{intl.formatMessage({ id: 'sensitivity' })}</p>
							<div className='flex full-width top-15 ai-configurations-select no-border-bottom margin-bottom-0'>
								<ReactSelect
									options={sensitivityTypes.current}
									value={selectedSensitivity}
									onChange={item => {
										setSelectedSensitivity(item);
										if (isOptionChecked(PatientsAiSettings.PERSON_GETTING_OUT_OF_BED)) {
											toggleAiSettings(PatientsAiSettings.PERSON_GETTING_OUT_OF_BED, item.value);
										}
									}}
									classNamePrefix='react-select'
									placeholder={intl.formatMessage({ id: 'sensitivity' })}
									formatOptionLabel={data => aiFormatOptionLabel(data, intl)}
									styles={generateCustomStyles({ darkMode: user.darkMode })}
								/>
							</div>
						</>
					)}
					{selectedItem.aiTypeId === PatientsAiSettings.RAIL_DOWN && (
						<div className='flex full-width top-15 ai-configurations-select no-border-bottom margin-bottom-0'>
							<ReactSelect
								menuIsOpen={isMenuOpen}
								onMenuOpen={() => setIsMenuOpen(true)}
								onMenuClose={() => setIsMenuOpen(false)}
								options={railsOptions.current}
								value={selectedRails}
								placeholder={intl.formatMessage({ id: 'selectRails' })}
								onChange={handleRailsOnSelect}
								isClearable={false}
								isSearchable={false}
								closeMenuOnSelect={false}
								hideSelectedOptions={false}
								classNamePrefix='react-select'
								customContent={selectedRails.length > 0 ? `${selectedRails.length} ${intl.formatMessage({ id: 'rails' })}` : ''}
								menulistTitle={intl.formatMessage({ id: 'sendRailsAlert' })}
								styles={{
									...generateCustomStyles({ darkMode: user.darkMode }),
									menu: base => ({ ...base, width: '100%', color: isDarkMode && DarkTheme.colors.grayFive }),
									menuList: base => ({ ...base, height: 'fit-content', width: '100%' }),
									multiValue: base => ({
										...base,
										display: 'none',
									}),
									control: base => ({
										...base,
										paddingLeft: '10px',
										backgroundColor: isDarkMode && DarkTheme.colors.grayThree,
										color: isDarkMode && DarkTheme.colors.grayFive,
									}),
								}}
								isMulti
								components={{
									Option,
									MultiValueContainer,
									MultiValue,
									Control,
									MenuList,
								}}
							/>
						</div>
					)}
					{[
						PatientsAiSettings.PERSON_GETTING_OUT_OF_BED,
						PatientsAiSettings.RAIL_DOWN,
						PatientsAiSettings.FALL_DETECTED,
					].includes(selectedItem.aiTypeId) && (
						<AiConfigurations deviceId={deviceId} patientId={patientId} selectedItem={selectedItem} roomId={roomId} />
					)}
					{selectedItem.aiTypeId === PatientsAiSettings.PERSON_STANDING_WALKING &&
						Object.values(patientMobilityHistory).length !== 0 && (
							<>
								<h3 className='semi-bold font-14'>{intl.formatMessage({ id: 'history' })}</h3>
								<StyledCircularChartWrapper>
									<div className='position-relative'>
										<p className='flex column-direction'>
											<span>{intl.formatMessage({ id: 'total' })}</span>
											{totalPatientMobilityHistory}
										</p>
										<Chart type='RoundedDoughnut' data={data} options={options} />
									</div>

									<div className='flex full-width flex-space-around top-15'>
										{patientHistoryTypes.map(history => (
											<div key={history.id} className='flex flex-align-center'>
												<div
													style={{
														width: '16px',
														height: '16px',
														background: history.color,
														borderRadius: '5px',
														marginRight: '10px',
													}}
												/>
												<div className='flex column-direction'>
													<p className='font-14'>{history.label}</p>
													<p className='semi-bold font-14'>{getDurationFormat(patientMobilityHistory[history.duration])}</p>
													<p className='font-14 semi-bold'>{patientHistoryTypes[history.percentage]}</p>
												</div>
											</div>
										))}
									</div>
								</StyledCircularChartWrapper>
							</>
						)}
				</>
			)}
		</div>
	);
};

export default PatientAiSettings;
