import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import Button from 'components/Button.jsx';
import translate from 'i18n-translations/translate.jsx';
import { UserRoleIds } from 'constants/enums.js';
import CreateCompanyConfirmation from 'components/CreateCompanyConfirmation.jsx';

const CompanyInvite = props => {
	const [emailError, setEmailError] = useState(null);
	const intl = useIntl();
	const setCompanyDetails = async ({ vcp, dc, superUser }) => {
		setEmailError(null);
		const array = [vcp, dc, superUser];
		const removeEmptyItems = array.filter(Boolean);
		const emailIsSameAsCompanyEmail = props.companyEmail && [vcp, dc, superUser].includes(props.companyEmail);
		const hasDuplicates = removeEmptyItems.length > new Set(removeEmptyItems).size || emailIsSameAsCompanyEmail;
		if (hasDuplicates) {
			setEmailError(intl.formatMessage({ id: 'uniqueEmail' }));
			return;
		}

		const params = {
			vcp: {
				email: vcp,
				inviteTypeId: UserRoleIds.VCP,
			},
			dc: {
				email: dc,
				inviteTypeId: UserRoleIds.DIGITAL_CLINICIAN,
			},
			superUser: {
				email: superUser,
				inviteTypeId: UserRoleIds.SUPER_USER,
			},
		};
		props.setDataToSubmit(params);
		props.submitForm(params);
	};

	const onChange = (e, setFieldValue, setFieldTouched, field) => {
		setFieldTouched(field);
		setFieldValue(field, e.target.value);
	};

	const checkOnBlur = async (errors, values) => {
		if (Object.keys(errors).length === 0) {
			const params = {
				vcp: {
					email: values.vcp,
					inviteTypeId: UserRoleIds.VCP,
				},
				dc: {
					email: values.dc,
					inviteTypeId: UserRoleIds.DIGITAL_CLINICIAN,
				},
				superUser: {
					email: values.superUser,
					inviteTypeId: UserRoleIds.SUPER_USER,
				},
			};

			props.setDataToSubmit(params);
		}
	};

	return (
		<>
			{props.isSubmitSuccessful && <CreateCompanyConfirmation />}
			{!props.isSubmitSuccessful && (
				<Formik
					enableReinitialize
					initialValues={{
						vcp: props.dataToSubmit.vcp.email || '',
						dc: props.dataToSubmit.dc.email || '',
						superUser: props.dataToSubmit.superUser.email || '',
					}}
					onSubmit={setCompanyDetails}
					validationSchema={Yup.object().shape({
						vcp: Yup.string()
							.notRequired()
							.email(intl.formatMessage({ id: 'invalidEmail' })),
						dc: Yup.string()
							.notRequired()
							.email(intl.formatMessage({ id: 'invalidEmail' })),
						superUser: Yup.string()
							.notRequired()
							.email(intl.formatMessage({ id: 'invalidEmail' })),
					})}>
					{formikProps => {
						const { values, errors, touched, setFieldValue, setFieldTouched } = formikProps;

						return (
							<div className='create-company-wrapper create-hs'>
								<Form>
									<h3>{translate('companyDetails')}</h3>
									<div className='top-30'>
										<label htmlFor='companyName'>VCP ({translate('optional')})</label>
										<input
											type='email'
											name='vcp'
											value={values.vcp}
											onChange={e => onChange(e, setFieldValue, setFieldTouched, 'vcp')}
											onBlur={() => checkOnBlur(errors, values)}
											maxLength={255}
											placeholder='example@example.com'
										/>
										{errors.vcp && touched.vcp && <span className='red-error'>{errors.vcp}</span>}
									</div>
									<div>
										<label htmlFor='companyRegion'>DC ({translate('optional')})</label>
										<input
											type='email'
											name='dc'
											value={values.dc}
											onChange={e => onChange(e, setFieldValue, setFieldTouched, 'dc')}
											onBlur={() => checkOnBlur(errors, values)}
											maxLength={255}
											placeholder='example@example.com'
										/>
										{errors.dc && touched.dc && <span className='red-error'>{errors.dc}</span>}
									</div>
									<div>
										<label htmlFor='email'>Super User ({translate('optional')})</label>
										<input
											type='email'
											name='superUser'
											value={values.superUser}
											onChange={e => onChange(e, setFieldValue, setFieldTouched, 'superUser')}
											onBlur={() => checkOnBlur(errors, values)}
											maxLength={255}
											placeholder='example@example.com'
										/>
										{errors.superUser && touched.superUser && <span className='red-error'>{errors.superUser}</span>}
									</div>
									{emailError && <span className='red-error'>{emailError}</span>}
									<div className='create-hs__add'>
										<Button text={translate('goBack')} variant='white' onClick={props.goToPreviousTab} />
										&nbsp;&nbsp;
										<Button type='submit' text={translate('submit')} isLoading={props.isLoading} />
									</div>
								</Form>
							</div>
						);
					}}
				</Formik>
			)}
		</>
	);
};

export default CompanyInvite;
