import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'components';
import { UserRoles } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import { getUserRole } from 'infrastructure/auth.js';
import Profile from 'icons/CareEvents/Profile.jsx';
import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';
import Alerts from 'icons/CareEvents/Alerts.jsx';
import EwsSettings from 'icons/RPM/EwsSettings.jsx';
import CareTeam from 'icons/RPM/CareTeam.jsx';
import CareLogs from 'icons/RPM/CareLogs.jsx';
import Surveys from 'icons/RPM/Surveys.jsx';
import Medications from 'icons/RPM/Medications.jsx';
import ChangeRpm from 'icons/RPM/ChangeRpm.jsx';
import RemovePatient from 'icons/RPM/RemovePatient.jsx';
import classNames from 'classnames';

const RpmDropDownOptions = ({
	patient,
	toggleViewProfileModal,
	toggleAlertsModal,
	toggleEwsSettings,
	toggleCarelogs,
	getPatientCareTeam,
	toggleSurvey,
	toggleRpmProgram,
	toggleMedicationModal,
	isDarkMode,
	isAlertHistoryShown,
	isDisabled,
	removeFromRpm,
}) => {
	const getIconColor = isDarkMode => (isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive);

	return (
		<Dropdown
			position='bottom'
			icon='more_vert'
			className={classNames('patient-view-more-wrapper', isDisabled ? 'disabled-options' : '')}>
			{!isDisabled && (
				<div className='patient-info-hello-list patient-info-view-more-list'>
					{getUserRole() === UserRoles.DOCTOR && (
						<div className='flex'>
							<Link
								className='no-text-decoration'
								to={{
									pathname: `/patients/${patient.userId}`,
								}}>
								<button type='button' className='flex'>
									<Profile color={getIconColor(isDarkMode)} />
									<p className='margin-left-m font-14'>{translate('viewProfile')}</p>
								</button>
							</Link>
						</div>
					)}
					{getUserRole() !== UserRoles.DOCTOR && (
						<div className='flex'>
							<button type='button' className='flex' onClick={() => toggleViewProfileModal(patient)}>
								<Profile color={getIconColor(isDarkMode)} />
								<p className='margin-left-m'>{translate('viewProfile')}</p>
							</button>
						</div>
					)}
					{(isAlertHistoryShown || getUserRole() !== UserRoles.DOCTOR) && (
						<div className='flex'>
							<button type='button' className='flex' onClick={() => toggleAlertsModal(patient)}>
								<Alerts color={getIconColor(isDarkMode)} />
								<p className='margin-left-m'>{translate('viewAlerts')}</p>
							</button>
						</div>
					)}
					<div className='flex'>
						<button type='button' className='flex' onClick={() => toggleEwsSettings(patient)}>
							<EwsSettings color={getIconColor(isDarkMode)} />
							<p className='margin-left-m'>{translate('ewsSettings')}</p>
						</button>
					</div>
					<div className='flex'>
						<button type='button' className='flex' onClick={() => toggleCarelogs(patient)}>
							<CareLogs color={getIconColor(isDarkMode)} />
							<p className='margin-left-m font-14'>{translate('carelogs')}</p>
						</button>
					</div>
					<div className='flex'>
						<button
							type='button'
							className='flex'
							onClick={e => {
								e.stopPropagation();
								getPatientCareTeam(patient.patientId);
							}}>
							<CareTeam color={getIconColor(isDarkMode)} />
							<p className='margin-left-m'>{translate('careTeam')}</p>
						</button>
					</div>
					<div className='flex'>
						<button
							type='button'
							className='flex'
							onClick={e => {
								e.stopPropagation();
								toggleSurvey(patient);
							}}>
							<Surveys color={getIconColor(isDarkMode)} />
							<p className='margin-left-m'>{translate('surveys')}</p>
						</button>
					</div>
					<div className='flex'>
						<button
							type='button'
							className='flex'
							onClick={e => {
								e.stopPropagation();
								toggleMedicationModal(patient);
							}}>
							<Medications color={getIconColor(isDarkMode)} />
							<p className='margin-left-m'>{translate('viewMedications')}</p>
						</button>
					</div>
					<div className='flex'>
						<button
							type='button'
							className='flex'
							onClick={e => {
								e.stopPropagation();
								toggleRpmProgram(patient);
							}}>
							<ChangeRpm color={getIconColor(isDarkMode)} />
							<p className='margin-left-m'>{translate('changeRpmProgram')}</p>
						</button>
					</div>
				</div>
			)}
			{isDisabled && (
				<div className='patient-info-hello-list patient-info-view-more-list'>
					<div className='flex'>
						<button type='button' className='flex' onClick={() => removeFromRpm(patient)}>
							<RemovePatient color={getIconColor(isDarkMode)} />
							<p className='margin-left-m font-14'>{translate('removeFromRpm')}</p>
						</button>
					</div>
				</div>
			)}
		</Dropdown>
	);
};

export default RpmDropDownOptions;
