import React, { useState, useEffect, useRef, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import { GoogleMap, useLoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import Grid from 'components/Grid.jsx';
import Loader from 'components/Loader.jsx';
import Button from 'components/Button.jsx';
import translate from 'i18n-translations/translate.jsx';
import { getUserId } from 'infrastructure/auth.js';
import { APP_CONFIG } from 'constants/global-variables.js';
import { getPharmacies, saveUserPharmacy, getUserPharmacy } from 'api/pharmacy.js';
import { DeliveryTypes, KeyCodes } from 'constants/enums.js';
import { handleOnKeyDownCellPhoneNumbers, getUserLocation, calcDistanceInMiles } from 'infrastructure/helpers/commonHelpers.js';
import Alert from 'components/Alert.jsx';

const Pharmacy = props => {
	const [isLoading, setIsLoading] = useState(true);
	const [isSearchLoading, setSearchLoading] = useState(false);
	const [isPharmacyChangeActive, setPharmacyChangeStatus] = useState(false);
	const [pharmacies, setPharmacies] = useState([]);
	const [error, setError] = useState(null);
	const [selectedMarker, setSelectedSelectedMarker] = useState(null);
	const [selectedPharmacy, setSelectedPharmacy] = useState(null);
	const [hoveredStoreNumber, setHoveredStoreNumber] = useState(null);
	const [searchValue, setSearchValue] = useState('');
	const [userLocation, setUserLocation] = useState(null);
	const [mapCenter, setMapCenter] = useState({
		lat: 37.0902, // center of usa lat
		lng: -95.7129, // center of usa lng
	});
	const { isLoaded, loadError } = useLoadScript({
		googleMapsApiKey: APP_CONFIG.googleMapsKey,
	});

	const mapRef = useRef();

	const onMapLoad = useCallback(map => {
		mapRef.current = map;
	}, []);

	useEffect(() => {
		const getPharmacy = async () => {
			const response = await getUserPharmacy(getUserId());
			if (response.error) {
				setError(response.error.message);
			} else {
				setSelectedPharmacy(response.pharmacy);
			}
			setIsLoading(false);
		};
		getPharmacy();
		getUserLocation(onGeolocationSuccess, onGeolocationError);
	}, []);

	useEffect(() => {
		setSelectedSelectedMarker(null);
		setSearchValue('');
	}, [isPharmacyChangeActive]);

	useEffect(() => {
		if (mapRef && mapRef.current && mapCenter.lat && mapCenter.lng) {
			// @ts-ignore
			mapRef.current.panTo(mapCenter);
			// @ts-ignore
			mapRef.current.setZoom(12);
		}
	}, [mapCenter]);

	useEffect(() => {
		if (pharmacies[0]) {
			const lat = parseFloat(pharmacies[0].latitude);
			const lng = parseFloat(pharmacies[0].longitude);
			setMapCenter({ lat, lng });
		}
	}, [pharmacies]);

	useEffect(() => {
		setPharmacies(selectedPharmacy ? [selectedPharmacy] : []);
	}, [selectedPharmacy]);

	const changePharmacy = () => {
		setPharmacies([selectedPharmacy]);
		setPharmacyChangeStatus(false);
	};

	const closeChangePharmacy = () => {
		setPharmacies([]);
		setPharmacyChangeStatus(true);
	};

	const searchPharmacies = async () => {
		setSearchLoading(true);
		const response = await getPharmacies(searchValue);
		if (response.error) {
			setError(response.error.response.statusText);
		} else {
			const mappedResponse = response.map(pharmacy => ({
				storeNumber: pharmacy.store.storeNumber,
				name: pharmacy.store.name,
				email: '',
				phoneNr: `${pharmacy.store.phone.areaCode}${pharmacy.store.phone.number}`,
				latitude: pharmacy.latitude,
				longitude: pharmacy.longitude,
				distance: calcDistanceInMiles(userLocation, {
					latitude: parseFloat(pharmacy.latitude),
					longitude: parseFloat(pharmacy.longitude),
				}),
				address: {
					...pharmacy.store.address,
					zipCode: searchValue,
				},
			}));
			setPharmacies(mappedResponse);
		}
		setSearchLoading(false);
	};

	const saveSelectedPharmacy = async (pharmacy, deliveryTypeId) => {
		setIsLoading(true);
		const params = { userId: getUserId(), deliveryTypeId, pharmacy };
		const response = await saveUserPharmacy(params);
		if (response.error) {
			setError(response.error.message);
		} else {
			setSelectedPharmacy(pharmacy);
		}
		setIsLoading(false);
		setPharmacyChangeStatus(false);
	};

	const onGeolocationSuccess = pos => {
		const crd = pos.coords;
		setUserLocation({ latitude: crd.latitude, longitude: crd.longitude });
		setMapCenter({ lat: crd.latitude, lng: crd.longitude });
	};

	const onGeolocationError = err => {
		setError(`ERROR(${err.code}): ${err.message}`);
	};

	return (
		<>
			{isLoading && (
				<Grid width='100%' stretch='calc(100vh - 105px)' vertAlign='center' horizAlign='center' rows='auto'>
					<Loader />
				</Grid>
			)}
			{!isLoading && (
				<>
					<div className='account-settings-inner-wrapper position-relative'>
						<h4>{translate('pharmacy')}</h4>

						<div>
							<div className='position-relative'>
								{isPharmacyChangeActive && (
									<div className='pharmacy-next-btn'>
										<button type='button' onClick={changePharmacy}>
											<i className='material-icons'>close</i>
										</button>
									</div>
								)}
							</div>
							{isPharmacyChangeActive && (
								<div className='pharmacy-settings-input'>
									<div className='position-relative'>
										<i className='material-icons'>search</i>
										<input
											placeholder='Search by zip code'
											type='text'
											onChange={event => setSearchValue(event.target.value)}
											value={searchValue}
											onKeyDown={event =>
												event.keyCode === KeyCodes.ENTER ? searchPharmacies() : handleOnKeyDownCellPhoneNumbers(event)
											}
										/>
										<Button
											type='button'
											onClick={searchPharmacies}
											text={isSearchLoading ? '' : props.intl.formatMessage({ id: 'findPharmacy' })}
											isLoading={isSearchLoading}
										/>
									</div>
								</div>
							)}
							<div className={`pharmacy-settings-inner position-relative flex${pharmacies.length > 0 ? ' active' : ''}`}>
								{!isPharmacyChangeActive && selectedPharmacy && (
									<div>
										<div>
											<h3>{selectedPharmacy.name}</h3>
											<p>
												<i className='material-icons'>room</i>
												{selectedPharmacy.address && selectedPharmacy.address.postalCode}{' '}
												{selectedPharmacy.address && selectedPharmacy.address.street}
											</p>
											{selectedPharmacy.distance && (
												<p>
													<span>
														{selectedPharmacy.distance} {translate('miles')}
													</span>
												</p>
											)}
										</div>
										<div>
											<button type='button' onClick={closeChangePharmacy}>
												{translate('changePharmacy')}
											</button>
										</div>
									</div>
								)}
								{isPharmacyChangeActive && pharmacies.length > 0 && (
									<div className='pharmacy-list'>
										<div className='pharmacy-list-inner'>
											{pharmacies.map(pharmacy => (
												<div
													className='single-pharmacy-box'
													onMouseEnter={() => setHoveredStoreNumber(pharmacy.storeNumber)}
													onMouseLeave={() => setHoveredStoreNumber(null)}
													key={pharmacy.storeNumber}>
													<div className='flex'>
														<div>
															<div className='flex'>
																<h3>{pharmacy.name}</h3>

																{pharmacy.distance && (
																	<span>
																		{pharmacy.distance} {translate('miles')}
																	</span>
																)}
															</div>
															<div className='flex'>
																<p>
																	<i className='material-icons'>room</i>
																	{pharmacy.address.postalCode} {pharmacy.address.street}
																	<span />
																	<i className='material-icons'>call</i>
																	{pharmacy.phoneNr}
																</p>
															</div>
														</div>
													</div>
													<div className='pharmacy-delivery-options'>
														<button type='button' onClick={() => saveSelectedPharmacy(pharmacy, DeliveryTypes.HOME_DELIVERY)}>
															<i className='material-icons'>arrow_forward</i>
															{translate('homeDelivery')}
														</button>
														<button
															type='button'
															className='active'
															onClick={() => saveSelectedPharmacy(pharmacy, DeliveryTypes.PHARMACY_PICK_UP)}>
															<i className='material-icons'>arrow_forward</i>
															{translate('pickUp')}
														</button>
													</div>
												</div>
											))}
										</div>
									</div>
								)}
								<div
									className={classNames(
										isPharmacyChangeActive ? 'pharmacy-map' : '',
										!isPharmacyChangeActive ? 'pharmacy-map active' : '',
										isPharmacyChangeActive && pharmacies.length > 0 ? 'pharmacy-map-multiple' : ''
									)}>
									{isLoaded && (
										<GoogleMap
											mapContainerStyle={{
												height: '100%',
												width: '100%',
											}}
											zoom={3.5}
											center={mapCenter}
											options={{
												disableDefaultUI: true,
												zoomControl: true,
											}}
											onLoad={onMapLoad}>
											{pharmacies.map(marker => (
												<Marker
													icon={{
														url: `https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png`,
														origin: new window.google.maps.Point(0, 0),
														anchor: new window.google.maps.Point(15, 15),
														scaledSize: new window.google.maps.Size(
															marker.storeNumber === hoveredStoreNumber ? 45 : 30,
															marker.storeNumber === hoveredStoreNumber ? 45 : 30
														),
													}}
													key={`${marker.latitude}-${marker.longitude}`}
													position={{ lat: parseFloat(marker.latitude), lng: parseFloat(marker.longitude) }}
													onClick={() => {
														setSelectedSelectedMarker(marker);
													}}
												/>
											))}

											{selectedMarker && (
												<InfoWindow
													position={{ lat: parseFloat(selectedMarker.latitude), lng: parseFloat(selectedMarker.longitude) }}
													onCloseClick={() => {
														setSelectedSelectedMarker(null);
													}}>
													<div>
														<div className='single-pharmacy-box single-pharmacy-box-inside-map'>
															<div className='flex'>
																<div>
																	<div className='flex'>
																		<h3>{selectedMarker.name}</h3>
																		{selectedMarker.distance && (
																			<span>
																				{selectedMarker.distance} {translate('miles')}
																			</span>
																		)}
																	</div>
																	<div className='flex'>
																		<p>
																			<i className='material-icons'>room</i>
																			{selectedMarker.address.street}
																			<span className='bold --blue-color'>{selectedMarker.address.zip}</span>
																			<i className='material-icons'>call</i>
																			{selectedMarker.phoneNr}
																		</p>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</InfoWindow>
											)}
										</GoogleMap>
									)}
									{loadError && <p>{translate('errorLoading')}</p>}
								</div>
							</div>
						</div>
						<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
					</div>
				</>
			)}
		</>
	);
};

export default injectIntl(Pharmacy);
