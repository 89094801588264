import React from 'react';

const HandWashing = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none' viewBox='0 0 24 24' {...props}>
		<path
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth='1.25'
			d='M17.487 10.535a1 1 0 001.007-.995 1 1 0 00-1.007-.994H8.929l1.653-1.853a1.01 1.01 0 00-.045-1.394 1.041 1.041 0 00-1.468.009s-2.657 2.741-4.67 4.73C1.88 12.522 2.887 16.002 6.411 16.5h9.06a1 1 0 001.008-.994 1 1 0 00-1.007-.994h-3.02 5.033a1 1 0 001.007-.995 1 1 0 00-1.007-.994h-5.034 6.041a1 1 0 001.007-.994 1 1 0 00-1.007-.994h-6.04'
		/>
		<path
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth='1.25'
			d='M20.529 17.037a1 1 0 001.369-.358 1 1 0 00-.375-1.365l-2.983-1.722m-2.37.928l5.23 3.02a1 1 0 01.375 1.364 1 1 0 01-1.369.358L15.623 16.5l3.911 2.258a1 1 0 01.375 1.365 1 1 0 01-1.37.358l-4.359-2.517 2.616 1.51a1 1 0 01.375 1.364 1 1 0 01-1.37.358l-7.846-4.53M19 7c-1.102 0-2-.923-2-2.059 0-1.274 1.32-2.3 2-2.94.68.64 2 1.666 2 2.94 0 1.135-.898 2.06-2 2.06z'
		/>
	</svg>
);

export default HandWashing;
