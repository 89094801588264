import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import translate from 'i18n-translations/translate.jsx';
import Button from 'components/Button.jsx';
import { checkIfOwnerEmailExists, updateCompanyDetails } from 'api/companies.js';
import Alert from 'components/Alert.jsx';
import { doNotAllowSpaceAsFirstCharacter } from 'infrastructure/helpers/commonHelpers.js';
import { urlRegex } from 'infrastructure/helpers/validationHelper.js';
import { AlertTypes } from 'constants/enums.js';
import PopUpAlert from 'components/PopUpAlert.jsx';

const CreateCompanyDetails = props => {
	const [error, setError] = useState(null);
	const [emailExistsError, setEmailExistsError] = useState(null);
	const [isSaveLoading, setIsSaveLoading] = useState(false);
	const [alertType, setAlertType] = useState(null);
	const intl = useIntl();

	const setCompanyDetails = async values => {
		if (!props.selectedCompany) {
			const response = await checkIfOwnerEmailExists(values.email);
			if (response.error) {
				setError(response.error);
				return;
			}
			if (response.exists) {
				setEmailExistsError(intl.formatMessage({ id: 'emailExists' }));
				return;
			}
			setEmailExistsError(null);
		}

		const params = {
			name: values.companyName.trim(),
			website: values.companyWebsite,
			email: values.email,
			...(props.selectedCompany && { address: props.dataToSubmit.address, profilePicture: props.dataToSubmit.profilePicture }),
		};

		if (!props.selectedCompany) {
			props.setDataToSubmit(params);
			props.goToNextTab();
		} else {
			setIsSaveLoading(true);
			const response = await updateCompanyDetails({ ...params, companyId: props.selectedCompany.id });
			if (!response.error) {
				setAlertType(AlertTypes.SUCCESS);
				props.setDataToSubmit(params);
			} else {
				setAlertType(AlertTypes.DANGER);
			}
			setIsSaveLoading(false);
		}
	};

	const emailChange = (e, setFieldValue, setFieldTouched) => {
		setEmailExistsError(null);
		setFieldTouched('email');
		setFieldValue('email', e.target.value);
	};

	const checkOnBlur = async (errors, values) => {
		if (!props.selectedCompany && Object.keys(errors).length === 0) {
			const response = await checkIfOwnerEmailExists(values.email);
			if (response.error) {
				setError(response.error);
				return;
			}
			if (response.exists) {
				setEmailExistsError(intl.formatMessage({ id: 'emailExists' }));
				return;
			}
			setEmailExistsError(null);
		}
	};

	return (
		<Formik
			enableReinitialize
			initialValues={{
				companyName: props.dataToSubmit.name ? props.dataToSubmit.name.trim() : '',
				companyWebsite: props.dataToSubmit.website || '',
				email: props.dataToSubmit.email || '',
			}}
			onSubmit={setCompanyDetails}
			validateOnChange={true}
			validationSchema={Yup.object().shape({
				companyName: Yup.string()
					.matches(/^^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/, intl.formatMessage({ id: 'symbolsNotAllowed' }))
					.required(intl.formatMessage({ id: 'requiredCompanyName' })),
				companyWebsite: Yup.string()
					.strict(false)
					.trim()
					.required(intl.formatMessage({ id: 'requiredWebsiteName' }))
					.matches(urlRegex, intl.formatMessage({ id: 'badURL' })),
				email: Yup.string()
					.required(intl.formatMessage({ id: 'emailRequired' }))
					.matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, intl.formatMessage({ id: 'invalidEmail' }))
					.email(intl.formatMessage({ id: 'invalidEmail' })),
			})}>
			{formikProps => {
				const { values, errors, touched, handleChange, handleBlur, setFieldValue, setFieldTouched } = formikProps;

				const noErrors = Object.keys(errors).length === 0;
				const { companyName, companyWebsite, email } = values;
				let requiredValues = false;
				if (companyName && email && companyWebsite) {
					requiredValues = true;
				}

				if (!props.selectedCompany) {
					const isNextActive = noErrors && requiredValues && !emailExistsError;

					if (isNextActive) {
						const params = {
							name: companyName,
							website: companyWebsite,
							email,
						};
						props.allowNextTab(params);
					} else {
						props.disableNextTabs();
					}
				}
				return (
					<div className='create-company-wrapper create-hs'>
						<Form>
							<h3>{translate('companyDetails')}</h3>
							<div className='top-30'>
								<label htmlFor='companyName'>{translate('companyName')}*</label>
								<input
									type='text'
									name='companyName'
									value={values.companyName}
									onChange={handleChange}
									onBlur={handleBlur}
									maxLength={255}
									className='capitalize-text'
									autoComplete='off'
									placeholder={intl.formatMessage({ id: 'companyName' })}
									onKeyDown={doNotAllowSpaceAsFirstCharacter}
								/>
								{errors.companyName && touched.companyName && <span className='red-error'>{errors.companyName}</span>}
							</div>
							<div>
								<label htmlFor='companyWebsite'>{translate('companyWebsite')}*</label>
								<input
									type='text'
									name='companyWebsite'
									value={values.companyWebsite.trim()}
									onChange={handleChange}
									onBlur={handleBlur}
									maxLength={255}
									autoComplete='off'
									placeholder={`http://example.com ${intl.formatMessage({ id: 'or' })} https://example.com`}
								/>
								{errors.companyWebsite && touched.companyWebsite && <span className='red-error'>{errors.companyWebsite}</span>}
							</div>
							<div>
								<label htmlFor='email'>{translate('companyOwner')}*</label>
								<input
									type='text'
									name='email'
									value={values.email}
									onChange={e => emailChange(e, setFieldValue, setFieldTouched)}
									onBlur={() => checkOnBlur(errors, values)}
									maxLength={255}
									autoComplete='off'
									placeholder='example@example.com'
									disabled={props.selectedCompany}
								/>

								{!emailExistsError && errors.email && touched.email && <span className='red-error'>{errors.email}</span>}
								{emailExistsError && <span className='red-error'>{emailExistsError}</span>}
							</div>
							<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
							<div className='create-hs__add'>
								<Button
									type='submit'
									text={translate(props.selectedCompany ? 'save' : 'nextStep')}
									isLoading={isSaveLoading}
									isDisabled={Object.keys(errors).length > 0}
								/>
							</div>
						</Form>
						<PopUpAlert
							alertType={alertType}
							display={alertType}
							onAlertClose={() => setAlertType(null)}
							contentText={intl.formatMessage({ id: alertType === AlertTypes.SUCCESS ? 'changesSaved' : 'somethingWentWrong' })}
							isSilent={true}
							center={true}
							selfCloseTimeOut={1500}
						/>
					</div>
				);
			}}
		</Formik>
	);
};

export default CreateCompanyDetails;
