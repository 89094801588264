import { ConditionType, ObservationType } from 'constants/enums.js';
import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';

const companyId = getCompanyId();

export const getTvWidgets = async ownerId => {
	try {
		const { data } = await gatewayApi.get(`v1/healthcare/patients/${ownerId}/tv-widgets`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getIsolations = async ownerId => {
	try {
		const { data } = await gatewayApi.get(
			`v1.1/healthcare/patients/${ownerId}/conditions?conditionType=${ConditionType.ISOLATIONS}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPainLevel = async ownerId => {
	try {
		const { data } = await gatewayApi.get(
			`v1/healthcare/patients/${ownerId}/observations?ObservationType=${ObservationType.PAIN_MANAGEMENT}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const setTvWidgets = async (ownerId, params) => {
	try {
		const { data } = await gatewayApi.post(`v1/healthcare/patients/${ownerId}/tv-widgets/manual`, params);
		return data;
	} catch (error) {
		return { error };
	}
};

export const setPainLevel = async (ownerId, valueString, deviceId) => {
	try {
		const { data } = await gatewayApi.post(`v1/healthcare/patients/${ownerId}/observations`, {
			observations: [
				{
					code: 'HCI100',
					display: `Pain Score`,
					valueString,
					effectiveDateTime: new Date().toISOString(),
					observationTypeId: 2,
				},
			],
			deviceId,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const setConditions = async (ownerId, values) => {
	try {
		const { data } = await gatewayApi.post(`v1.1/healthcare/patients/${ownerId}/conditions`, values);
		return data;
	} catch (error) {
		return { error };
	}
};

export const deleteConditions = async (ownerId, values) => {
	try {
		const { data } = await gatewayApi.put(`v1/healthcare/patients/${ownerId}/conditions/abate`, values);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPin = async userId => {
	try {
		const { data } = await gatewayApi.get(`v1/tenants/${companyId}/users/${userId}/pin`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const generatePin = async userId => {
	try {
		const { data } = await gatewayApi.put(`v1/tenants/${companyId}/users/${userId}/pin`);
		return data;
	} catch (error) {
		return { error };
	}
};
