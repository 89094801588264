import DarkTheme from "calls/styles/DarkTheme.js";
import LightTheme from "calls/styles/LightTheme.js";
import { AiAlertTypes, PatientWarnings } from "constants/enums.js";
import { LOCALES } from "i18n-translations/locales.js";
import translate from "i18n-translations/translate.jsx";
import FallDetection from "icons/Monitoring/FallDetection.jsx";
import InactiveTime from "icons/Monitoring/InactiveTime.jsx";
import IvBag from "icons/Monitoring/IvBag.js";
import OutOfBed from "icons/Monitoring/OutOfBed.jsx";
import PatientWalking from "icons/Monitoring/PatientWalking.jsx";
import Rails from "icons/Monitoring/Rails.jsx";
import { getPreferredLanguageLocale } from "infrastructure/helpers/commonHelpers.js";

export const getLanguagePrefix = () => {
    let result = '';
    const locale = getPreferredLanguageLocale();
    if (locale === LOCALES.ARABIC) {
        result = 'arabic-';
    }
    if (locale === LOCALES.GERMAN) {
        result = 'german-';
    }
    if (locale === LOCALES.SPANISH) {
        result = 'spanish-';
    }
    return result;
};

export const getHealthConcern = (type, darkMode) => {
    let text = null;
    let imgIcon = null;
    switch (type) {
        case PatientWarnings.FALL_DETECTED: {
            text = translate('pleaseCheckPatient');
            imgIcon = <FallDetection color={darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />;
            break;
        }
        case AiAlertTypes.PERSON_SITTING: {
            text = translate('patientSitting');
            imgIcon = <PatientWalking color={darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />;
            break;
        }
        case AiAlertTypes.PERSON_STANDING: {
            text = translate('patientStanding');
            imgIcon = <PatientWalking color={darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />;
            break;
        }
        case AiAlertTypes.PERSON_LAYING_FRONT: {
            text = translate('patientLayingFront');
            imgIcon = <PatientWalking color={darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />;
            break;
        }
        case AiAlertTypes.PERSON_LAYING_SIDE: {
            text = translate('patientLayingSide');
            imgIcon = <PatientWalking color={darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />;
            break;
        }
        case AiAlertTypes.PERSON_FACE_DOWN: {
            text = translate('patientFaceDown');
            imgIcon = <PatientWalking color={darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />;
            break;
        }
        case AiAlertTypes.PERSON_INACTIVE: {
            text = translate('patientInactive');
            imgIcon = <InactiveTime color={darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />;
            break;
        }
        case AiAlertTypes.PERSON_GETTING_OUT_OF_BED: {
            text = translate('personGettingOutOfBed');
            imgIcon = <OutOfBed color={darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />;
            break;
        }
        case AiAlertTypes.RAIL_DOWN: {
            text = translate('railDownMessage');
            imgIcon = <Rails color={darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />;
            break;
        }
        case AiAlertTypes.PERSON_STANDING_WALKING: {
            text = translate('personIsDetectedWalking');
            imgIcon = <PatientWalking color={darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />;
            break;
        }
        case AiAlertTypes.IV_BAG:
        case AiAlertTypes.LOW_IV_BAG: {
            text = translate('ivBagCheck');
            imgIcon = <IvBag color={darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />;
            break;
        }
        case AiAlertTypes.PERSON_GETTING_OUT_OF_CHAIR: {
            text = translate('personGettingOutOfChairMessage');
            imgIcon = <PatientWalking color={darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />;
            break;
        }
        default: {
            text = translate('pleaseCheckPatient');
            imgIcon = <FallDetection color={darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />;
            break;
        }
    }
    return { text, imgIcon };
};
