import React from 'react';
import { useHistory } from 'react-router-dom';
import Grid from 'components/Grid.jsx';
import { getUserInfo } from 'infrastructure/auth.js';
import { decodeHtml } from 'infrastructure/helpers/commonHelpers.js';
import { Avatar } from 'components';
import translate from 'i18n-translations/translate.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';

const LogOutUser = () => {
	const userInfo = getUserInfo();
	const history = useHistory();
	const signOut = () => {
		history.push('/logout');
	};

	return (
		<Grid className='already-logged-in-wrapper' columns='1fr' rows='1fr' stretch='100vh' horizAlign='center' vertAlign='center'>
			<div className='member-error'>
				<p>{translate('currentlyLoggedInAs')}: </p>
				{userInfo && (
					<div className='member-details flex flex-justify-center flex-align-center cursor-pointer'>
						<Avatar
							src={userInfo.profilePicture || 'https://maxcdn.solaborate.com/media/profile-pictures/duser.jpg'}
							fullName={decodeHtml(`${userInfo.firstName} ${userInfo.lastName}`)}
						/>
						<p>{decodeHtml(`${userInfo.firstName} ${userInfo.lastName}`)}</p>
					</div>
				)}
				<p>{translate('pleaseLogOutFromThisAccount')}</p>
				<button type='button' className='sign-out-btn' onClick={signOut}>
					<img src={`${healthCareCdnUrl}sign-out-icon.svg`} alt='icon' />
					{translate('signOut')}
				</button>
			</div>
		</Grid>
	);
};

export default LogOutUser;
