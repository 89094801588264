import React from 'react';
import Select from 'react-select';

const MultiSelect = props => {
	const {
		id,
		name,
		label,
		placeholder,
		options,
		value,
		isMulti,
		isDisabled,
		description,
		touched,
		error,
		isClearable,
		backspaceRemovesValue,
	} = props;
	const handleChange = newValue => {
		const { onChange } = props;
		onChange(name, newValue);
	};
	const handleBlur = () => {
		const { onBlur } = props;

		onBlur(name, true);
	};
	const InputFeedback = ({ children }) => <small>{children}</small>;
	const DropdownIndicator = () => <i className='material-icons-outlined'>arrow_drop_down</i>;
	return (
		<div className='input'>
			<p className='label'>{label}</p>
			{description && <p>{description}</p>}
			<Select
				id={id}
				name={name}
				classNamePrefix='react-select'
				placeholder={placeholder}
				options={options}
				value={value}
				onChange={handleChange}
				onBlur={handleBlur}
				touched={touched}
				error={error}
				isMulti={isMulti}
				isDisabled={isDisabled}
				isClearable={isClearable}
				backspaceRemovesValue={backspaceRemovesValue}
				components={{ DropdownIndicator }}
			/>
			{touched && error && <InputFeedback>{error}</InputFeedback>}
		</div>
	);
};

export default MultiSelect;
