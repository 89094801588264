import React, { useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { AiTab } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import AlertHistoryModal from 'components/AlertHistoryModal.jsx';
import AiGeneralSettings from 'containers/Monitoring/AiGeneralSettings.jsx';
import { getMonitoringActiveIconColor, getMonitoringIconColor } from 'infrastructure/helpers/monitoringTimelineHelpers.js';
import { AiIcon } from 'icons/Monitoring/index.js';
import SetupIcon from 'icons/Monitoring/SetupIcon.jsx';
import translate from 'i18n-translations/translate.jsx';
import PatientAiSettingsMayo from 'components/PatientAiSettingsMayo.jsx';

const AiSettings = ({
	patientId,
	feed,
	isDarkMode,
	onAiSettingClick,
	isNoteShowing,
	toggleFallPrevention,
	stopVoiceOver,
	onAlertInfoClose,
	adminAiSettingsConfigurations,
}) => {
	const intl = useIntl();
	const user = useSelector(state => state.user);
	const [expandedBox, setExpandedBox] = useState(false);
	const [currentTab, setCurrentTab] = useState(AiTab.SETUP);
	const [alertDetails, setAlertDetails] = useState(null);

	return (
		<>
			<div className='monitoring-timeline-box ai-settings'>
				<div className={classNames('timeline-box-header', expandedBox ? 'expanded' : '')}>
					<p className='timeline-box-title flex-1'>
						<AiIcon />
						AI
					</p>
					<div className='timeline-box-actions'>
						<button type='button' onClick={() => setExpandedBox(prevState => !prevState)}>
							<i className={classNames('material-icons', expandedBox ? ' rotate' : '')}>expand_more</i>
						</button>
					</div>
				</div>
				{expandedBox && (
					<div className={classNames('timeline-box-content', expandedBox ? ' expanded' : '')}>
						<div className='flex flex-align-center bottom-20'>
							<div className='flex alert-subtabs'>
								<button
									type='button'
									className={classNames('setting-alert-tab', currentTab === AiTab.SETUP ? 'active' : '')}
									onClick={() => setCurrentTab(AiTab.SETUP)}>
									<SetupIcon
										color={
											isDarkMode && currentTab === AiTab.SETUP
												? getMonitoringActiveIconColor(user.darkMode)
												: getMonitoringIconColor(user.darkMode)
										}
									/>
									{translate('setup')}
								</button>

								<button
									type='button'
									className={classNames('setting-alert-tab', currentTab === AiTab.GENERAL ? 'active' : '')}
									onClick={() => setCurrentTab(AiTab.GENERAL)}>
									<img
										className='dropdown-icon-img margin-right-m'
										src={`${healthCareCdnUrl}${!isDarkMode ? 'header' : 'dark-mode'}/account-settings.svg`}
										alt={intl.formatMessage({
											id: 'monitoringSettings',
										})}
									/>
									{translate('General')}
								</button>
							</div>
						</div>
						{currentTab === AiTab.SETUP && patientId && (
							<PatientAiSettingsMayo
								deviceId={feed.deviceId}
								patientId={patientId}
								onAiSettingClick={onAiSettingClick}
								areEmergencyButtonsHiden={feed.areEmergencyButtonsHiden}
								isNoteShowing={isNoteShowing}
								isDarkMode={isDarkMode}
								isFallPrevention={feed.isFallPrevention}
								toggleFallPrevention={toggleFallPrevention}
								roomId={feed.roomId}
								feedWarning={feed?.warning}
								stopVoiceOver={stopVoiceOver}
								onAlertInfoClose={onAlertInfoClose}
							/>
						)}

						{currentTab === AiTab.GENERAL && patientId && (
							<AiGeneralSettings
								patientId={patientId}
								deviceId={feed.deviceId}
								isDarkMode={isDarkMode}
								roomId={feed.roomId}
								adminAiSettingsConfigurations={adminAiSettingsConfigurations}
							/>
						)}

						{alertDetails && (
							<div className='monitoring-timeline-wrapper'>
								<div className='flex flex-space-between'>
									<div className='cursor-pointer --blue-color go-back semi-bold' onClick={() => setAlertDetails(null)}>
										{translate('goBack')}
									</div>
									<div className='monitoring-alert-details-title'>{translate('eventDetails')}</div>
								</div>
								<AlertHistoryModal
									alertDetails={alertDetails}
									isFromMonitoring={true}
									isManual={false}
									isMeasurementAlert={false}
								/>
							</div>
						)}
					</div>
				)}
			</div>
		</>
	);
};

export default AiSettings;
