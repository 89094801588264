import { CompanyCallSettings, ConfigurableDigitalBackgroundSettings, MonitoringSettings, PatientTabs } from 'constants/configurationEnums.js';
import _ from 'lodash';
import { getConfigurationMenu, getConfigurationValue } from './commonHelpers.js';
import { getUserRole } from 'infrastructure/auth.js';

export const findConfigurationValue = (list, typeId) =>
	list.find(x => x.settingTypeId === typeId)?.value.toLowerCase() === 'true';

export const buildVisualsSettings = newSettings => {
	const defaultSettings = _.cloneDeep(ConfigurableDigitalBackgroundSettings);
	newSettings.forEach(item => {
		if (defaultSettings[item.settingTypeId]) {
			defaultSettings[item.settingTypeId].value = item.value;
			defaultSettings[item.settingTypeId].isLocked = item.isLocked;
		}
	});
	return defaultSettings;
};

export const buildHealthSystemConfigurations = settingsResponse => {
	const healthSystemConfigsList = [
		MonitoringSettings.EnableMultipleFeedAudio,
		MonitoringSettings.MixedMonitoringView,
		...Object.values(PatientTabs),
	];
	const healthSystemConfigs = {};
	settingsResponse.forEach(config => {
		if (healthSystemConfigsList.includes(config.settingTypeId)) {
			healthSystemConfigs[config.settingTypeId] = {
				value: JSON.parse(config.value),
				...(config.variant && { variant: config.variant }),
			};
		}
	});

	return healthSystemConfigs;
};

export const buildCallSettings = settings => {
	const result = {};
	Object.entries(CompanyCallSettings).forEach(([, value]) => {
		const found = settings.find(item => item.settingTypeId === value);
		result[value] = found ? JSON.parse(found.value) : false;
	});
	return result;
};

export const getAdminGeneralSettingConfigValue = (adminConfigurableMenu, settingTypeId) => {
	const adminConfigs = getConfigurationMenu(adminConfigurableMenu, getUserRole());
	return getConfigurationValue(adminConfigs[settingTypeId]);
};
