import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import { Alert, CustomTextarea } from 'components/index.js';
import { saveManualAlert } from 'api/alerts.js';
import SpeechToText from 'SpeechToText.jsx';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from './Tabs.jsx';
import { AiAlertActivityType, AlertTypes } from 'constants/enums.js';
import { generateCustomStyles } from 'constants/react-select-style.js';
import Drafts from 'components/Monitoring/Drafts.jsx';
import AiAlertsOnInterventions from 'components/AiAlertsOnInterventions.jsx';
import { getInterventionsCount } from 'api/interventions.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';
import { actionCreators as patientNotesActionCreators } from 'state/patientNotes/actions.js';
import { AlertIcon, ListIcon, PatientHistoryIcon, PlusIcon } from 'icons/Monitoring/index.js';
import translate from 'i18n-translations/translate.jsx';
import { getMonitoringActiveIconColor, getMonitoringIconColor } from 'infrastructure/helpers/monitoringTimelineHelpers.js';
import ManualAlertsListMayo from './Monitoring/ManualAlertsListMayo.jsx';

const InterventionsMonitoring = ({
	isDarkMode,
	patientId,
	deviceId,
	careEvents,
	feed,
	numberOfFeeds,
	setSnoozeType,
	stopVoiceOver,
	showForwardToNurses,
	onForwardToNurses,
	conferenceId,
}) => {
	const intl = useIntl();
	const user = useSelector(state => state.user);
	const [currentTab, setCurrentTab] = useState(0);
	const [expandedBox, setExpandedBox] = useState(true);
	const [error, setError] = useState(null);
	const [selectedAlertType, setSelectedAlertType] = useState(null);
	const [recognizedTranscription, setRecognizedTranscription] = useState('');
	const [liveTranscription, setLiveTranscription] = useState('');
	const [isSpeechToText, setIsSpeechToText] = useState(false);
	const socket = useContext(SocketContext);
	const patientNotes = useSelector(state => state.patientNotes.savedMode);
	const dispatch = useDispatch();

	useEffect(() => {
		const fetchDraftsCount = async () => {
			if (!patientId) {
				return;
			}
			const response = await getInterventionsCount(patientId, deviceId);
			if (response.error) {
				setError(response.error.message);
				return;
			}
			dispatch(
				patientNotesActionCreators.setPatientDataSaved(
					{
						...patientNotes[deviceId],
						draftsCount: response.totalCount,
					},
					deviceId
				)
			);
		};
		const handleResponse = async data => {
			if (
				[AiAlertActivityType.ACKNOWLEDGED, AiAlertActivityType.AI_FAKE_ALERT, AiAlertActivityType.AI_FORWARD_TO_NURSES].includes(
					data.aiAlertActivityTypeId
				)
			) {
				onAlertCloseClick();
			}
			if (!data.isDraft) {
				return;
			}
			dispatch(patientNotesActionCreators.increaseDraftsCount(null, deviceId));
		};

		const handleManualResponse = async data => {
			if (!data.isDraft) {
				return;
			}
			dispatch(patientNotesActionCreators.increaseDraftsCount(null, deviceId));
		};

		fetchDraftsCount();
		socket.on(SocketEvents.Alerts.NEW_AI_ALERT_ACTIVITY, handleResponse);
		socket.on(SocketEvents.HealthCare.ALERTS_MANUAL_ADDED, handleManualResponse);
		return () => {
			socket.off(SocketEvents.Alerts.NEW_AI_ALERT_ACTIVITY, handleResponse);
			socket.off(SocketEvents.HealthCare.ALERTS_MANUAL_ADDED, handleManualResponse);
		};
	}, [patientId, deviceId]);

	const handleSubmit = async () => {
		const dataToSend = {
			patientId,
			deviceId,
			manualAlertTypeId: selectedAlertType.value,
			comment: recognizedTranscription,
			isDraft: false,
			conferenceId,
		};
		const response = await saveManualAlert(dataToSend);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		setSelectedAlertType(null);
		setRecognizedTranscription('');
		setLiveTranscription('');
		setIsSpeechToText(false);
	};

	const onAlertCloseClick = () => {
		if (feed.warning?.value) {
			stopVoiceOver(feed.warning.type, feed.deviceId);
		}
	};

	return (
		<div className='monitoring-timeline-box interventions'>
			<div className={classNames('timeline-box-header', expandedBox ? 'expanded' : '')}>
				<p className='timeline-box-title'>
					<AlertIcon />
					{translate('interventionsAndAlerts')}
				</p>
				<div className='timeline-box-actions'>
					<button type='button' onClick={() => setExpandedBox(prevState => !prevState)}>
						<i className={classNames('material-icons', expandedBox ? 'rotate' : '')}>expand_more</i>
					</button>
				</div>
			</div>
			{expandedBox && (
				<div className={classNames('timeline-box-content', expandedBox ? 'expanded' : '')}>
					<Tabs activeIndex={currentTab} onChange={index => setCurrentTab(index)}>
						<TabList>
							<Tab className='cursor-pointer'>
								<PlusIcon
									color={currentTab === 0 ? getMonitoringActiveIconColor(user.darkMode) : getMonitoringIconColor(user.darkMode)}
								/>
								{translate('add')}
							</Tab>
							{patientNotes[deviceId]?.draftsCount > 0 && (
								<Tab className='cursor-pointer'>
									<ListIcon
										color={currentTab === 1 ? getMonitoringActiveIconColor(user.darkMode) : getMonitoringIconColor(user.darkMode)}
									/>
									{translate('drafts')}
									{patientNotes[deviceId]?.draftsCount > 0 && (
										<p className='drafts-count' data-position='bottom' data-tooltip='Undocumented interventions'>
											{patientNotes[deviceId]?.draftsCount}
										</p>
									)}
								</Tab>
							)}
							<Tab className='cursor-pointer'>
								<PatientHistoryIcon
									color={currentTab === 2 ? getMonitoringActiveIconColor(user.darkMode) : getMonitoringIconColor(user.darkMode)}
								/>
								{translate('history')}
							</Tab>
						</TabList>
						<TabPanels>
							<TabPanel>
								<div className='add-interventions-and-alerts'>
									{careEvents.length === 0 && (
										<>
											<p className='no-interventions'>{translate('noInterventions')}</p>
											<p className='no-interventions'>{translate('askAdministratorForInterventions')}</p>
										</>
									)}
									<ReactSelect
										options={careEvents?.map(item => ({
											label: item.name,
											value: item.id,
										}))}
										value={selectedAlertType}
										onChange={item => setSelectedAlertType(item)}
										id='select'
										classNamePrefix='react-select'
										styles={{
											...generateCustomStyles({ darkMode: user.darkMode }),
										}}
									/>
									{selectedAlertType && (
										<CustomTextarea
											disabled={!selectedAlertType || isSpeechToText || careEvents.length === 0}
											maxLength={199}
											onChange={event => setRecognizedTranscription(event.target.value)}
											value={isSpeechToText ? `${recognizedTranscription} ${liveTranscription}`.trim() : recognizedTranscription}
											maxNumberLimit={40}
											rows={5}
											name='text'
											placeholder={intl.formatMessage({ id: 'addAdditionalNotes' })}
											charactersCounter={false}
											icon={
												<SpeechToText
													setRecognizedTranscription={setRecognizedTranscription}
													setLiveTranscription={setLiveTranscription}
													setIsSpeechToText={setIsSpeechToText}
													isSpeechToText={isSpeechToText}
													isDisabled={careEvents.length === 0 || !selectedAlertType}
												/>
											}
											saveBtn={
												careEvents.length > 0 && (
													<button className='button' type='button' disabled={!selectedAlertType} onClick={handleSubmit}>
														<i className='material-icons-outlined'>done</i>
														{translate('save')}
													</button>
												)
											}
										/>
									)}
									{feed?.warning?.value && !feed.warning?.isToastMessage && (
										<AiAlertsOnInterventions
											showForwardToNurses={showForwardToNurses}
											warningObject={feed.warning}
											alertType={AlertTypes.DANGER}
											onAlertClose={onAlertCloseClick}
											isFeedExpanded={true}
											onAlertCloseClick={onAlertCloseClick}
											numberOfFeeds={numberOfFeeds}
											feedDeviceId={feed.deviceId}
											setSnoozeType={setSnoozeType}
											onForwardToNurses={onForwardToNurses}
										/>
									)}
								</div>
							</TabPanel>
							{patientNotes[deviceId]?.draftsCount > 0 && (
								<TabPanel>
									<Drafts patientId={patientId} deviceId={deviceId} />
								</TabPanel>
							)}
							<TabPanel>
								<ManualAlertsListMayo patientId={patientId} isDarkMode={isDarkMode} deviceId={deviceId} />
							</TabPanel>
						</TabPanels>
					</Tabs>
				</div>
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</div>
	);
};

export default InterventionsMonitoring;
