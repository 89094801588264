import { getTeamSettings, updateTeamSettings } from 'api/adminConfigurations.js';
import { CompanyCallSettings, ConfigurableDigitalBackgroundSettings } from 'constants/configurationEnums.js';
import DigitalBackground from 'containers/CallSettings/DigitalBackground.jsx';
import HelloBackground from 'containers/CallSettings/HelloBackground.jsx';
import HelloWallpaper from 'containers/CallSettings/HelloWallpaper.jsx';
import translate from 'i18n-translations/translate.jsx';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import Alert from 'components/Alert.jsx';
import SkeletonLoader from 'components/SkeletonLoader.jsx';
import { DeviceListLevel } from 'constants/enums.js';
import { buildVisualsSettings } from 'infrastructure/helpers/configurationsHelpers.js';
import CallSettings from 'calls/components/CallSettings.jsx';

const CallSettingsAdmin = ({ selectedHealthSystem, setSelectedHealthSystem, healthSystems }) => {
	const [wallpaperBackgroundConfigs, setWallpaperBackgroundConfigs] = useState(ConfigurableDigitalBackgroundSettings);
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [submitParams, setSubmitParams] = useState({
		patientCamera: {
			value: false,
			key: CompanyCallSettings.PATIENT_CAMERA,
		},
		providerMic: {
			value: false,
			key: CompanyCallSettings.PROVIDER_MIC,
		},
		providerCamera: {
			value: false,
			key: CompanyCallSettings.PROVIDER_CAMERA,
		},
		patientMicrophone: {
			value: false,
			key: CompanyCallSettings.PATIENT_MIC,
		},
	});

	const mapCallSettings = configs => {
		const result = {};
		[
			{ key: CompanyCallSettings.PROVIDER_MIC, value: 'providerMic' },
			{ key: CompanyCallSettings.PROVIDER_CAMERA, value: 'providerCamera' },
			{ key: CompanyCallSettings.PATIENT_CAMERA, value: 'patientCamera' },
			{ key: CompanyCallSettings.PATIENT_MIC, value: 'patientMicrophone' },
		].forEach(item => {
			const foundItem = configs.settings.find(setting => setting.settingTypeId === item.key);
			result[item.value] = { value: foundItem?.value === 'true', key: item.key };
		});
		return result;
	};

	useEffect(() => {
		const fetchSectorSettings = async () => {
			setIsLoading(true);
			const response = await getTeamSettings({
				teamId: selectedHealthSystem.value,
				levelId: DeviceListLevel.HEALTH_SYSTEM,
				settingsCategory: null,
			});

			if (response.error) {
				setError(response.error.message);
				setIsLoading(false);
				return;
			}
			setWallpaperBackgroundConfigs(buildVisualsSettings(response.settings));
			setSubmitParams(mapCallSettings(response));
			setIsLoading(false);
		};
		fetchSectorSettings();
	}, [selectedHealthSystem]);

	const handleChangeConfig = async obj => {
		const key = submitParams[obj.name].key;
		const value = (!submitParams[obj.name].value).toString();
		const params = {
			teamSettings: [
				{
					settingTypeId: key,
					value,
					isLocked: false,
				},
			],
			teamId: selectedHealthSystem.value,
			isOverride: false,
			healthSystemId: selectedHealthSystem.value,
		};
		const response = await updateTeamSettings(params);

		if (response.error) {
			setError(response.error.message);
			setIsLoading(false);
			return;
		}
		setSubmitParams(prevState => ({ ...prevState, [obj.name]: { key, value: value === 'true' } }));
	};

	return (
		<div className='account-settings-inner-wrapper general-menu-wrapper configurations-call-settings-admin'>
			<div className='background-health-system'>
				<h4>{translate('selectHealthSystem')}</h4>
				<Select
					value={selectedHealthSystem}
					classNamePrefix='react-select'
					options={healthSystems.map(item => ({ value: item.id, label: item.name || item.value }))}
					onChange={setSelectedHealthSystem}
				/>
			</div>
			{isLoading && <SkeletonLoader rows={10} padding='35px 20px' />}
			{!isLoading && (
				<div className='call-settings-wallpapers'>
					<CallSettings handleChangeConfig={handleChangeConfig} submitParams={submitParams} />
					<DigitalBackground
						selectedHealthSystemId={selectedHealthSystem.value}
						wallpaperBackgroundConfigs={wallpaperBackgroundConfigs}
						setWallpaperBackgroundConfigs={setWallpaperBackgroundConfigs}
					/>
					<HelloBackground
						selectedHealthSystemId={selectedHealthSystem.value}
						wallpaperBackgroundConfigs={wallpaperBackgroundConfigs}
						setWallpaperBackgroundConfigs={setWallpaperBackgroundConfigs}
					/>
					<HelloWallpaper
						selectedHealthSystemId={selectedHealthSystem.value}
						wallpaperBackgroundConfigs={wallpaperBackgroundConfigs}
						setWallpaperBackgroundConfigs={setWallpaperBackgroundConfigs}
					/>
				</div>
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</div>
	);
};

export default CallSettingsAdmin;
