import * as React from "react"
import LightTheme from 'calls/styles/LightTheme.js';

const Laboratory = (props) => (
    <svg
        color={props.color}
        width={35}
        height={35}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="m13.465 14.095 1.843 1.29a1 1 0 0 0 1.393-.245l5.353-7.646a1 1 0 0 0-.245-1.392l-1.844-1.29a1 1 0 0 0-1.392.245l-5.354 7.645a1 1 0 0 0 .246 1.393ZM13.806 14.334l-.406.58a.708.708 0 0 0 .174.987v0c.32.224.762.146.987-.174l.406-.58M23.093 3.542l-1.16-.813"
            stroke="currentColor"
            strokeWidth={props.strokeWidth}
            strokeLinecap="round"
        />
        <path
            d="m21.042 9 5.666 9.917-3.541 9.916m-3.542 0 3.542-9.916-3.896-6.73M9 28.833l1.417-4.25m0 0h2.833m-2.833 0H9m4.25 0 1.417 4.25m-1.417-4.25h1.417"
            stroke="currentColor"
            strokeWidth={props.strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M28.833 32.375H6.167l.708-3.542h21.25l.708 3.542Z"
            stroke="currentColor"
            strokeWidth={props.strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

Laboratory.defaultProps = {
    color: LightTheme.colors.graySeven,
    strokeWidth: 2
}

export default Laboratory
