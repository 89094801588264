import React from 'react';
import { useIntl } from 'react-intl';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';

const ConfigSettingRadioButtonInput = props => {
	const intl = useIntl();
	const { config } = props;

	return (
		<div className='grid grid-columns-2 call-config-settings' key={config.key}>
			<div className='call-config-type'>
				<div>
					<img src={`${healthCareCdnUrl}admin/super-admin/camera-type.svg`} alt='icon' />
				</div>
				<div className='call-config-description'>
					<p>{intl.formatMessage({ id: config.title })}</p>
					<span>{props.statusText}</span>
				</div>
			</div>
			<div className='radio-call-config grid grid-columns-2'>
				{props.cameraUseOptions.map(option => (
					<label className='flex' key={option.id}>
						<input
							type='radio'
							name='theme'
							value={option.variant}
							checked={props.cameraUseValue.variant === option.variant}
							onChange={event => props.handleCameraUseChange(event, config)}
							className='capitalized-text'
						/>
						{translate(option.content)}
					</label>
				))}
			</div>
		</div>
	);
};

export default ConfigSettingRadioButtonInput;
