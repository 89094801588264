import React, { Component } from 'react';
import { Form, Formik, Field } from 'formik';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Table from 'components/Table.jsx';
import { findSectorById, getSectorPath } from 'infrastructure/helpers/commonHelpers.js';
import SmallModal from 'components/SmallModal.jsx';
import AssignDropdown from 'components/AssignDropdown.jsx';
import Loader from 'components/Loader.jsx';
import Select from 'components/Common/FormElements/Select.jsx';
import Alert from 'components/Alert.jsx';
import { addToHealthSystem, getNurseHealthSystems, assignMember, deleteHealthSystemMember } from 'api/users.js';
import { getHealthSystemSubTree } from 'api/healthSystems.js';
import translate from 'i18n-translations/translate.jsx';
import Modal from 'components/Modal.jsx';
import { actionCreators } from 'state/user/actions.js';
import { getCompanyId, getUserRole } from 'infrastructure/auth.js';
import { CompanyRoles, UserRoles } from 'constants/enums.js';

class ManageHealthSystems extends Component {
	state = {
		healthSystems: [],
		showHealthSystemForm: false,
		currentHealthSystemId: null,
		currentUserSubTree: [],
		isDeleteModalOpen: false,
		healthSystemId: null,
		healthSystemError: '',
		errorMessage: null,
		isLoading: false,
	};

	companyId = getCompanyId();

	userRole = getUserRole();

	async componentDidMount() {
		this.setState({ isLoading: true });
		const healthSystems = await getNurseHealthSystems(this.props.member.memberId);
		this.setState({ healthSystems, isLoading: false });
	}

	displayHealthSystems() {
		return this.state.healthSystems.map(hs => {
			const hideButtons = this.userRole === UserRoles.SUPER_USER && this.props.userSession.healthSystem.id !== hs.id;
			return {
				name: hs.name,
				assignation: hideButtons ? '' : this.Assignation(hs),
				id: hs.id,
				edit: hideButtons ? '' : this.getRemoveBtn(hs.id),
			};
		});
	}

	getRemoveBtn = healthSystemId => (
		<div className='wrapped'>
			<i
				className='material-icons-outlined boxed-icon'
				data-cy='removeUserFromHS'
				onClick={() => this.openDeleteModal(healthSystemId)}>
				delete
			</i>
		</div>
	);

	openDeleteModal = healthSystemId => {
		if (this.props.member.roles.some(role => role.id === CompanyRoles.SUPER_USER) && this.state.healthSystems.length > 0) {
			this.setState({ healthSystemError: translate('superUserHealthSystemValidation') });
			return;
		}
		this.setState({ isDeleteModalOpen: true, healthSystemId });
	};

	deleteHealthSystem = async () => {
		const response = await deleteHealthSystemMember(this.state.healthSystemId, this.props.member.memberId);
		if (response.error) {
			this.setState({
				errorMessage: `${this.props.intl.formatMessage({ id: 'somethingWentWrong' })}: ${response.error.message}`,
			});
		}
		this.setState(prevState => {
			const healthSystems = prevState.healthSystems.filter(hs => hs.id !== this.state.healthSystemId);
			this.props.updateMemberHealthSystems(healthSystems);
			return {
				healthSystems,
				isDeleteModalOpen: false,
				healthSystemError: '',
			};
		});
	};

	Assignation = healthSystem => {
		const sector = healthSystem.location ? findSectorById(this.state.currentUserSubTree, healthSystem.location.id) : null;
		const preselected = sector ? getSectorPath(sector) : {};
		return (
			<div style={{ position: 'relative' }}>
				<div
					className={classNames('assign-btn', { unassigned: !healthSystem.location })}
					onClick={() => this.getTree(healthSystem.id)}>
					{healthSystem.location && healthSystem.location.name}
					{!healthSystem.location && (
						<span>
							<i className='material-icons'>how_to_reg</i>
							{translate('assignLevel')}
						</span>
					)}
				</div>
				{this.state.currentHealthSystemId === healthSystem.id && (
					<SmallModal
						isLoading={this.state.fetchingSubTree}
						display={true}
						onModalClose={() => this.setState({ currentHealthSystemId: null })}>
						{this.state.fetchingSubTree && (
							<div>
								<Loader />
							</div>
						)}
						{!this.state.fetchingSubTree && (
							<AssignDropdown
								onSelectLocation={level => this.onSelectLocation(level)}
								data={this.state.currentUserSubTree}
								preSelected={preselected}
								selectedId={healthSystem.location ? healthSystem.location.id : null}
							/>
						)}
					</SmallModal>
				)}
			</div>
		);
	};

	async onSelectLocation(level) {
		this.setState({
			fetchingSubTree: true,
		});
		const userId = this.props.member.userInfo.id;
		const healthSystemIndex = this.state.healthSystems.findIndex(hs => hs.id === this.state.currentHealthSystemId);
		assignMember(this.state.currentHealthSystemId, level.id, userId)
			.then(() => {
				this.setState(prevState => {
					const items = [...prevState.healthSystems];
					const item = {
						...items[healthSystemIndex],
						location: {
							...items[healthSystemIndex].location,
							id: level.id,
							name: level.name,
						},
					};
					items[healthSystemIndex] = item;
					return { healthSystems: items, currentHealthSystemId: null, fetchingSubTree: false };
				});
			})
			.catch(error =>
				this.setState({
					errorMessage: `${this.props.intl.formatMessage({ id: 'somethingWentWrong' })}: ${error.message}`,
				})
			);
	}

	async getTree(healthSystemId) {
		try {
			this.setState({ currentHealthSystemId: healthSystemId, fetchingSubTree: true });
			const subtree = await getHealthSystemSubTree(healthSystemId);
			this.setState({ fetchingSubTree: false, currentUserSubTree: subtree });
		} catch (e) {
			this.setState({ fetchingSubTree: false, currentUserSubTree: [] });
		}
	}

	getHealthSystems(allHealthSystems, assignedHealthSystems) {
		const filteredHealthSystems = allHealthSystems.filter(
			hs => !assignedHealthSystems.find(assignedHs => assignedHs.id === hs.id)
		);
		if (!filteredHealthSystems.length) return [];
		return filteredHealthSystems;
	}

	handleAddHealthSystem = () => {
		if (this.state.isLoading) {
			return;
		}
		if (this.props.member.roles.some(role => role.id === CompanyRoles.SUPER_USER) && this.state.healthSystems.length > 0) {
			this.setState({ healthSystemError: translate('superUserRoleValidation') });
			return;
		}
		this.setState(prevState => ({ showHealthSystemForm: !prevState.showHealthSystemForm }));
	};

	handleSelectHealthSystem = (healthSystemId, { resetForm }) => {
		this.setState({ isLoading: true });
		this.props.onLoading('healthSystemAddition');
		addToHealthSystem(healthSystemId, this.props.member.memberId)
			.then(() => {
				const healthSystemName = this.props.healthSystems.find(hs => hs.id === healthSystemId).value;
				this.props.onSuccess('healthSystemAddition');
				this.setState(prevState => ({
					healthSystems: [...prevState.healthSystems, { id: healthSystemId, name: healthSystemName }],
					showHealthSystemForm: !prevState.showHealthSystemForm,
				}));
				resetForm({
					healthSystem: null,
				});
				this.props.updateMemberHealthSystems([...this.state.healthSystems, { id: healthSystemId, name: healthSystemName }]);
			})
			.catch(error =>
				this.setState({
					errorMessage: `${this.props.intl.formatMessage({ id: 'somethingWentWrong' })}: ${error.message}`,
				})
			)
			.finally(() => this.setState({ isLoading: false }));
	};

	render() {
		return (
			<>
				<Modal
					modalSelector={this.props.modalSelector}
					display={this.props.display}
					className={this.props.className}
					position={this.props.position}
					onModalClose={this.props.onModalClose}
					primaryButtonLabel=''
					closeButtonText={this.props.intl.formatMessage({ id: 'close' })}>
					<Formik
						initialValues={{
							healthSystem: null,
						}}>
						{formikProps => {
							return (
								<Form>
									<h3>{translate('manageHealthSystems')}</h3>
									<span className='action' onClick={this.handleAddHealthSystem}>
										<i
											className={classNames('material-icons-outlined', this.state.isLoading ? 'disabled' : '')}
											data-cy='assignDoctorToAnotherHS'
											data-tooltip={this.props.intl.formatMessage({
												id: this.state.showHealthSystemForm ? 'hideForm' : 'addNewHealthSystem',
											})}
											data-position='right'>
											{this.state.showHealthSystemForm ? 'indeterminate_check_box' : 'add_box'}
										</i>
									</span>
									{this.state.healthSystemError && <p className='red-error'>{this.state.healthSystemError}</p>}
									{this.state.showHealthSystemForm &&
										this.getHealthSystems(this.props.healthSystems, this.state.healthSystems).length > 0 && (
											<div style={{ paddingTop: '15px' }}>
												<Field
													onChange={event => this.handleSelectHealthSystem(event.target.value, formikProps)}
													name='healthSystem'
													type='select'
													label={this.props.intl.formatMessage({ id: 'enterHealthSystem' })}
													placeholder={this.props.intl.formatMessage({ id: 'selectHealthSystem' })}
													description={this.props.intl.formatMessage({ id: 'selectHealthSystemNurse' })}
													items={this.getHealthSystems(this.props.healthSystems, this.state.healthSystems)}
													component={Select}
													disabled={this.props.healthSystems.length === this.state.healthSystems.length}
												/>
											</div>
										)}
									{this.state.showHealthSystemForm &&
										this.getHealthSystems(this.props.healthSystems, this.state.healthSystems).length === 0 && (
											<div style={{ paddingTop: '15px', paddingBottom: '15px' }}>{translate('noMoreHealthSystems')}</div>
										)}
									<Table
										isLoading={this.state.isLoading}
										headers={[
											{ title: translate('healthSystem'), id: 0 },
											{ title: translate('assignation'), id: 1 },
											{ edit: '', id: 2 },
										]}
										rows={this.state.isLoading ? [] : this.displayHealthSystems()}
									/>
									<Alert
										display={this.props.error}
										message={translate('somethingWentWrong')}
										variant='error'
										onClose={() => this.props.onCloseAlert('healthSystemAddition')}
									/>
									<Alert
										display={this.state.errorMessage}
										fixed
										hideCloseButton
										message={this.state.errorMessage}
										variant='dark'
									/>
								</Form>
							);
						}}
					</Formik>
				</Modal>
				<Modal
					modalSelector='deleteHSFromMember'
					display={this.state.isDeleteModalOpen}
					position='center'
					onModalSubmit={this.deleteHealthSystem}
					primaryButtonLabel={translate('delete')}
					onModalClose={() => this.setState({ isDeleteModalOpen: false })}>
					<form>
						<h3>{translate('warning')}</h3>
						<p>{translate('deleteHealthSystemMember')}</p>
					</form>
				</Modal>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		userSession: state.user.userSession,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		userActions: bindActionCreators(actionCreators, dispatch),
	};
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ManageHealthSystems));
