import React, { useEffect, useState } from 'react';

const Header = props => {
	const [windowDimensions, setWindowDimensions] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	const getWindowDimensions = () => {
		const { innerWidth: width, innerHeight: height } = window;
		return {
			width,
			height,
		};
	};

	useEffect(() => {
		setWindowDimensions(getWindowDimensions());
		const handleResize = () => {
			setWindowDimensions(getWindowDimensions());
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return (
		<div className='header-lp'>
			<div className='lp-container'>
				<div>
					<h1>The World’s Most Integrated and Powerful Virtual Care Delivery Platform</h1>
					<button type='button' onClick={() => props.setIsMessageModalVisible(true)}>
						<i className='material-icons'>east</i>Request a demo
					</button>
				</div>

				<div>
					{windowDimensions.width > 768 && <img src={props.mainImg} alt='banner' />}

					{windowDimensions.width <= 768 && <img src={props.mobileImg} className='mobile-image' alt='banner' />}
				</div>
			</div>
		</div>
	);
};

export default Header;
