import React, { useState, useEffect } from 'react';
import { defaultDateFormat } from 'infrastructure/helpers/dateHelper.js';
import { getEhrData } from 'api/ehr.js';
import Loader from 'components/Loader.jsx';
import translate from 'i18n-translations/translate.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';

const EhrIntegration = props => {
	const [isLoading, setIsLoading] = useState(true);
	const [ehrData, setEhrData] = useState(null);

	useEffect(() => {
		const hardCodedData = {
			allergies: [
				{ code: 'J30.1', display: 'Pollen', effectiveDateTime: '2021-07-08T12:00:00', id: 0 },
				{ code: 'J30.81', display: 'Cats', effectiveDateTime: '2021-07-08T12:00:00', id: 1 },
			],
			condition: { code: 'I50.9', display: 'Heart Failure', effectiveDateTime: '2021-07-08T12:00:00' },
			diseases: [{ code: 'E78.5', display: 'Huyperlipidemia', effectiveDateTime: '2021-07-08T12:00:00', id: 2 }],
			notes: [
				{
					code: 'Progress Note',
					effectiveDateTime: '2021-07-08T12:00:00',
					text: 'patient is revovering from surgery to resolve blocked coronary arteries.',
					id: 3,
				},
			],
			vitals: {
				bloodpressure: '140/90',
				effectiveDateTime: '2021-07-08T12:00:00',
				heartRate: 65,
				o2saturation: '92',
				temperature: '34.5',
			},
		};
		const fetchData = async () => {
			const response = await getEhrData();
			if (response.error) {
				setEhrData(hardCodedData);
			} else {
				setEhrData(response);
			}
			setIsLoading(false);
		};

		fetchData();
	}, []);

	return (
		<div className={`ehr-integration${isLoading ? ' is-ehr-loading' : ''}`}>
			<div className='ehr-close cursor-pointer' onClick={props.onClose}>
				<i className='material-icons'>close</i>
			</div>
			{isLoading && <Loader />}
			{!isLoading && (
				<>
					<div className='ehr-basic-info'>
						<div className='flex title'>
							<h3>{props.patientName}</h3>
						</div>
						<div className='flex content'>
							<div className='flex-1'>
								<p>{translate('sex')}</p>
								<p>Female</p>
							</div>
							<div className='flex-1'>
								<p>{translate('age')}</p>
								<p>63</p>
							</div>
							<div className='flex-1'>
								<p>{translate('bloodType')}</p>
								<p>AB-</p>
							</div>
						</div>
					</div>
					<div className='ehr-latest-vs'>
						<div className='title flex'>
							<h4>{translate('latestVitalSigns')}</h4>
							<i className='material-icons'>add</i>
						</div>
						<div className='content'>
							<div className='flex'>
								<img src={`${healthCareCdnUrl}health-data/heart.svg`} alt='health data icon' />
								<h3>
									{ehrData?.vitals.heartRate} <span>bpm</span>
								</h3>
								<span>{defaultDateFormat(ehrData?.vitals.effectiveDateTime)}</span>
							</div>
							<div className='flex'>
								<img src={`${healthCareCdnUrl}health-data/heart.svg`} alt='health data icon' />
								<h3>
									{ehrData?.vitals.bloodpressure} <span>mmHg</span>
								</h3>
								<span>{defaultDateFormat(ehrData?.vitals.effectiveDateTime)}</span>
							</div>
							<div className='flex'>
								<img src={`${healthCareCdnUrl}health-data/respiratory.svg`} alt='health data icon' />
								<h3>
									{ehrData?.vitals.o2saturation} <span>%</span>
								</h3>
								<span>{defaultDateFormat(ehrData?.vitals.effectiveDateTime)}</span>
							</div>
							<div className='flex'>
								<img src={`${healthCareCdnUrl}health-data/body-measurements.svg`} alt='health data icon' />
								<h3>
									{ehrData?.vitals.temperature} <span>°C</span>
								</h3>
								<span>{defaultDateFormat(ehrData?.vitals.effectiveDateTime)}</span>
							</div>
						</div>
						<div className='view-all'>
							<p>{translate('viewAll')}</p>
						</div>
					</div>
					<div className='ehr-latest-diagnosis'>
						<div className='title flex'>
							<h4>{translate('latestDiagnosis')}</h4>
							<i className='material-icons'>add</i>
						</div>
						<div className='content'>
							<div className='flex'>
								<div>
									<p>{ehrData?.condition.display}</p>
									<p>{ehrData?.condition.code}</p>
								</div>
							</div>
						</div>
						<div className='view-all'>
							<p>{translate('viewAll')}</p>
						</div>
					</div>
					<div className='ehr-chronic'>
						<div className='title flex'>
							<h4>{translate('chronicDiseases')}</h4>
							<i className='material-icons'>add</i>
						</div>
						<div className='content'>
							{ehrData?.diseases.map(x => (
								<p key={x.id}>{x.display}</p>
							))}
						</div>
						<div className='view-all'>
							<p>{translate('viewAll')}</p>
						</div>
					</div>
					<div className='ehr-allergies'>
						<div className='title flex'>
							<h4>{translate('allergies')}</h4>
							<i className='material-icons'>add</i>
						</div>
						<div className='content'>
							{ehrData?.allergies.map(allergy => (
								<p key={allergy.code}>{allergy.display}</p>
							))}
						</div>
						<div className='view-all'>
							<p>{translate('viewAll')}</p>
						</div>
					</div>
					<div className='ehr-notes'>
						<div className='title flex'>
							<h4>{translate('notes')}</h4>
							<i className='material-icons'>add</i>
						</div>
						<div className='content'>
							{ehrData?.notes.map(note => (
								<div key={note.id}>
									<p>{note.text}</p>
									<p>{defaultDateFormat(note.effectiveDateTime)}</p>
								</div>
							))}
						</div>
						<div className='view-all'>
							<p>{translate('viewAll')}</p>
						</div>
					</div>
					<button type='button'>{translate('goToAllScripts')}</button>
				</>
			)}
		</div>
	);
};

export default EhrIntegration;
