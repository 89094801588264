import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import translate from 'i18n-translations/translate.jsx';
import { changeUserPassword } from 'api/users.js';
import Button from 'components/Button.jsx';
import PopUpAlert from 'components/PopUpAlert.jsx';
import { AlertTypes } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';

const ChangePassword = () => {
	const [isRequestProcessing, setIsRequestProcessing] = useState(false);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);
	const [password, setPassword] = useState({ currentPassword: true, newPassword: true, reEnterNewPassword: true });
	const intl = useIntl();

	const handleSubmit = async (values, { resetForm }) => {
		setIsRequestProcessing(true);
		const changePassword = await changeUserPassword(values.currentPassword, values.newPassword);
		if (changePassword.error) {
			setError(changePassword.error.message);
			setIsRequestProcessing(false);
		} else {
			setError(null);
			setSuccess(changePassword.HasSucceed);
			setIsRequestProcessing(false);
			setTimeout(() => {
				closeResponseAlert();
			}, 1500);
		}
		resetForm();
	};

	const closeResponseAlert = () => {
		setError(null);
		setSuccess(null);
	};

	return (
		<div className='account-settings-inner-wrapper account-settings-tabs doctor-profile-settings patient-profile'>
			<Formik
				initialValues={{ currentPassword: '', newPassword: '', reEnterNewPassword: '' }}
				onSubmit={handleSubmit}
				validationSchema={Yup.object().shape({
					currentPassword: Yup.string().required(intl.formatMessage({ id: 'passwordRequired' })),
					newPassword: Yup.string()
						.required(intl.formatMessage({ id: 'pleaseWritePassword' }))
						.matches(
							// eslint-disable-next-line no-useless-escape
							/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*\(\)_\+\-\={}<>,\.\|""'~`:;\\?\/\[\] ]){8,}/,
							intl.formatMessage({ id: 'passwordCannotBeLessThan8Chart' })
						)
						.notOneOf([Yup.ref('currentPassword')], intl.formatMessage({ id: 'notMatchCurrentPassword' }))
						.min(8, `${intl.formatMessage({ id: 'minLengthIs' })} 8`),
					reEnterNewPassword: Yup.string()
						.required(intl.formatMessage({ id: 'passwordConfirmationRequired' }))
						.oneOf([Yup.ref('newPassword')], intl.formatMessage({ id: 'mustMatchPassword' }))
						.min(8, `${intl.formatMessage({ id: 'minLengthIs' })} 8`),
				})}>
				{({ values, errors, handleChange, handleBlur }) => (
					<Form>
						<div className='flex personal-details-wrapper'>
							<div className='flex column-direction personal-details'>
								<label className='position-relative'>
									{translate('currentPassword')}
									<input
										type={password.currentPassword ? 'password' : 'text'}
										placeholder={intl.formatMessage({ id: 'currentPassword' })}
										value={values.currentPassword}
										onChange={handleChange}
										onBlur={handleBlur}
										maxLength={30}
										name='currentPassword'
										className='margin-top-s'
									/>
									<div onClick={() => setPassword(prevState => ({ ...prevState, currentPassword: !prevState.currentPassword }))}>
										<img
											src={`${healthCareCdnUrl}icon/show-password.svg`}
											alt='show-password-ico'
											className='show-password-icon'
										/>
									</div>
									<span className='red-error position-absolute'>{errors.currentPassword}</span>
								</label>
							</div>
						</div>
						<div className='flex personal-details-wrapper'>
							<div className='flex column-direction personal-details'>
								<label className='position-relative'>
									{translate('newPassword')}
									<input
										type={password.newPassword ? 'password' : 'text'}
										placeholder={intl.formatMessage({ id: 'newPassword' })}
										value={values.newPassword}
										onChange={handleChange}
										onBlur={handleBlur}
										maxLength={30}
										name='newPassword'
										className='margin-top-s'
									/>
									<div onClick={() => setPassword(prevState => ({ ...prevState, newPassword: !prevState.newPassword }))}>
										<img
											src={`${healthCareCdnUrl}icon/show-password.svg`}
											alt='show-password-ico'
											className='show-password-icon'
										/>
									</div>
									<span className='red-error position-absolute'>{errors.newPassword}</span>
								</label>
							</div>
							<div className='flex column-direction personal-details'>
								<label className='position-relative'>
									{translate('reEnterNewPassword')}
									<input
										type={password.reEnterNewPassword ? 'password' : 'text'}
										placeholder={intl.formatMessage({ id: 'reEnterNewPassword' })}
										value={values.reEnterNewPassword}
										onChange={handleChange}
										onBlur={handleBlur}
										maxLength={30}
										name='reEnterNewPassword'
										className='margin-top-s'
									/>
									<div
										onClick={() =>
											setPassword(prevState => ({ ...prevState, reEnterNewPassword: !prevState.reEnterNewPassword }))
										}>
										<img
											src={`${healthCareCdnUrl}icon/show-password.svg`}
											alt='show-password-ico'
											className='show-password-icon'
										/>
									</div>

									<span className='red-error position-absolute'>{errors.reEnterNewPassword}</span>
								</label>
							</div>
						</div>
						<div className='full-width flex right-align-content top-30'>
							<Button
								className=''
								type='submit'
								isLoading={isRequestProcessing}
								text={!isRequestProcessing ? translate('saveChanges') : ''}
								imgIcon={`${healthCareCdnUrl}appointments/save-icon.svg`}
								background='rgba(51, 194, 127, 1)'
							/>
						</div>
					</Form>
				)}
			</Formik>
			<PopUpAlert
				alertType={success ? AlertTypes.SUCCESS : AlertTypes.DANGER}
				display={error || success}
				onAlertClose={closeResponseAlert}
				contentText={success ? intl.formatMessage({ id: 'passwordChangedSuccessfully' }) : error}
				isSilent={true}
				center={true}
			/>
		</div>
	);
};

export default ChangePassword;
