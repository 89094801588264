import classNames from 'classnames';
import Modal from 'components/Modal.jsx';
import { MonitoringSettings } from 'constants/configurationEnums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import React, { useRef, useState } from 'react';

const StatAlarms = ({
	isStatAlarmModalOpen,
	setIsStatAlarmModalOpen,
	availableAlarms,
	setSelectedStatAlarm,
	customizeConfig,
}) => {
	const [isPlaying, setIsPlaying] = useState(false);
	const [selectedAlarm, setSelectedAlarm] = useState(null);
	const [audioReady, setAudioReady] = useState(false);
	const audioRefs = useRef([]);
	const modalRef = useRef(null);

	const handlePlayPause = (item, index) => {
		setSelectedAlarm(item);
		const audio = audioRefs.current[index];
		if (!audioReady) {
			return;
		}
		if (selectedAlarm?.id !== item.id) {
			const prevAudio = audioRefs.current.find(ref => ref !== null && ref.paused === false);
			if (prevAudio) {
				prevAudio.pause();
			}
		}
		if (audio.paused) {
			audio.play();
			setIsPlaying(true);
			audio.loop = true;
		} else {
			audio.pause();
			setIsPlaying(false);
		}
	};

	const handleItemClick = item => {
		setSelectedAlarm(item);
		setIsPlaying(false);
	};

	const pauseAlarm = () => {
		if (selectedAlarm) {
			const audio = audioRefs.current.find(ref => ref !== null && !ref.paused);
			if (audio) {
				audio.pause();
				setIsPlaying(false);
			}
		}
	};

	const handleOnSubmit = () => {
		pauseAlarm();
		setSelectedStatAlarm(selectedAlarm);
		customizeConfig(MonitoringSettings.StatAlarm?.toString(), selectedAlarm?.id);
		setIsStatAlarmModalOpen(false);
	};

	const handleOnCancel = () => {
		pauseAlarm();
		setSelectedAlarm(null);
		setIsStatAlarmModalOpen(false);
	};

	return (
		<Modal
			display={isStatAlarmModalOpen}
			className='make-appointment-modal border-radius-modal-wrapper appoinment-next-arrow-modal stat-alarm-modal'
			onModalClose={handleOnCancel}
			onModalSubmit={handleOnSubmit}
			submitImgIcon={`${healthCareCdnUrl}tick.svg`}
			closeImgIcon={`${healthCareCdnUrl}cancel.svg`}
			primaryButtonLabel={translate('confirm')}
			isSubmitDisabled={!selectedAlarm}>
			<div ref={modalRef}>
				<div className='modal-form-wrapper stat-alarm-form'>
					<h3>{translate('selectAlarm')}</h3>
					<p>{translate('availableAlarms')}</p>
					<div>
						{availableAlarms.map((item, index) => (
							<div
								key={item.id}
								className={classNames(
									'flex flex-space-between stat-alarm-details cursor-pointer',
									selectedAlarm?.id === item.id ? 'active' : ''
								)}>
								<div className='flex flex-align-center full-width' onClick={() => handleItemClick(item)}>
									<input readOnly type='radio' name='choice' checked={selectedAlarm?.id === item.id} className='cursor-pointer' />
									<span className={classNames('font-14 left-s', selectedAlarm?.id === item.id ? '--blue-light-5' : '')}>
										{item.label}
									</span>
								</div>
								<div className='media-player'>
									<audio
										ref={audio => {
											audioRefs.current[index] = audio;
										}}
										controls={false}
										onCanPlayThrough={() => setAudioReady(true)}>
										<source src={item.url} type='audio/mpeg' />
									</audio>
									<button type='button' onClick={() => handlePlayPause(item, index)}>
										<img
											src={`${healthCareCdnUrl}stetho/${isPlaying && selectedAlarm?.id === item.id ? 'pause' : 'play'}.svg?v2`}
											alt='ico'
										/>
									</button>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default StatAlarms;
