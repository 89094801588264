import React from 'react';
import Select from 'react-select';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { TreeHierarchyType } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import { Grid, DateRange } from 'components/index.js';

const HeaderFilters = props => {
	const intl = useIntl();
	const DropdownIndicator = () => <i className='material-icons-outlined'>arrow_drop_down</i>;

	return (
		<Grid
			columns={classNames(
				`200px ${
					[TreeHierarchyType.DEFAULT_TREE, TreeHierarchyType.HOSPITAL_DEPT_FLOOR_ROOM].includes(props.treeHierarchyTypeId)
						? 'repeat(7, 1fr)'
						: ''
				}`,
				[TreeHierarchyType.HOSPITAL_DEPT_ROOM, TreeHierarchyType.HOSPITAL_FLOOR_ROOM].includes(props.treeHierarchyTypeId)
					? 'repeat(6, 1fr)'
					: '',
				props.treeHierarchyTypeId === TreeHierarchyType.HOSPITAL_ROOM ? 'repeat(5, 1fr)' : ''
			)}
			gridGap='10px'>
			<Select
				value={props.selectedSector.healthSystem}
				placeholder={translate('healthSystem')}
				classNamePrefix='custom-select'
				options={props.sectors.healthSystems.map(hs => ({
					value: hs.id,
					label: hs.name,
				}))}
				components={{ DropdownIndicator }}
				onChange={value => props.onHealthSystemSelected(value)}
				noOptionsMessage={() => intl.formatMessage({ id: 'noOptions' })}
			/>
			<Select
				value={props.selectedSector.region}
				placeholder={translate('location')}
				classNamePrefix='custom-select'
				options={props.sectors.regions}
				components={{ DropdownIndicator }}
				onChange={value => props.onRegionSelected(value)}
				noOptionsMessage={() => intl.formatMessage({ id: 'noOptions' })}
			/>
			<Select
				value={props.selectedSector.hospital}
				placeholder={translate('hospital')}
				classNamePrefix='custom-select'
				options={props.sectors.hospitals}
				components={{ DropdownIndicator }}
				onChange={value => props.onHospitalSelected(value)}
				noOptionsMessage={() => intl.formatMessage({ id: 'noOptions' })}
			/>
			{![TreeHierarchyType.HOSPITAL_FLOOR_ROOM, TreeHierarchyType.HOSPITAL_ROOM].includes(props.treeHierarchyTypeId) && (
				<Select
					value={props.selectedSector.department}
					placeholder={translate('department')}
					classNamePrefix='custom-select'
					options={props.sectors.departments}
					components={{ DropdownIndicator }}
					onChange={value => props.onDepartmentSelected(value)}
					noOptionsMessage={() => intl.formatMessage({ id: 'noOptions' })}
				/>
			)}
			{![TreeHierarchyType.HOSPITAL_DEPT_ROOM, TreeHierarchyType.HOSPITAL_ROOM].includes(props.treeHierarchyTypeId) && (
				<Select
					value={props.selectedSector.floor}
					placeholder={translate('floor')}
					classNamePrefix='custom-select'
					options={props.sectors.floors}
					components={{ DropdownIndicator }}
					onChange={value => props.onFloorSelected(value)}
					noOptionsMessage={() => intl.formatMessage({ id: 'noOptions' })}
				/>
			)}
			<Select
				value={props.selectedSector.room}
				placeholder={translate('room')}
				classNamePrefix='custom-select'
				options={props.sectors.rooms}
				components={{ DropdownIndicator }}
				onChange={value => props.onRoomSelected(value)}
				noOptionsMessage={() => intl.formatMessage({ id: 'noOptions' })}
			/>
			<div className='position-relative flex flex-align-center'>
				<DateRange
					startDate={props.dates.fromDate}
					endDate={props.dates.toDate}
					handleFromChange={props.handleFromChange}
					handleRangeChange={props.handleRangeChange}
					maxDays={90}
				/>
				{props.hasDateError && <p className='red-error position-absolute'>{translate('dateDifference')}</p>}
			</div>

			<div
				className={`button download export-csv ${
					props.dates.toDate && props.dates.fromDate && !props.dates.hasDateError ? '' : 'disabled'
				}`}
				onClick={() => props.setIsExportLogsModalOpen(true)}>
				<i className='material-icons'>attach_email</i>
				{translate('exportToCSV')}
			</div>
		</Grid>
	);
};

export default HeaderFilters;
