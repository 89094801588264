import React from 'react';
import Select from 'react-select';
import { useIntl } from 'react-intl';
import { Formik } from 'formik';
import translate from 'i18n-translations/translate.jsx';
import MultiSelect from 'components/Common/FormElements/MultiSelect.jsx';
import { Form, Modal } from 'components';
import { healthCareCdnUrl } from 'constants/global-variables.js';

const AddMemberRole = props => {
	const intl = useIntl();
	const healthSystemsArr = props.healthSystems.map(hs => ({ value: hs.id, label: hs.value }));
	return (
		<Formik
			initialValues={{
				healthSystem: null,
			}}
			onSubmit={props.onAddMemberRole}>
			{formikProps => {
				const { values, touched, errors, setFieldValue, setFieldTouched } = formikProps;
				return (
					<Modal
						className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal'
						display={props.isAddRoleModalOpen}
						position='center'
						onModalSubmit={() => props.onAddMemberRole(values.healthSystem?.value)}
						primaryButtonLabel={translate('add')}
						onModalClose={props.onModalClose}
						shouldSubmitOnEnter={false}
						isSubmitDisabled={props.isSubmitDisabled || (props.isHealthSystemShown && !values.healthSystem)}
						closeImgIcon={`${healthCareCdnUrl}appointments/cancel-appointment.svg`}
						submitImgIcon={`${healthCareCdnUrl}appointments/save-icon.svg`}
						primaryButtonLoading={props.isBtnLoading}>
						<Form height={500} className='add-a-role'>
							<h3>{translate('addARole')}</h3>
							<p>{translate('pleaseSelectRole')}</p>
							<Select
								options={props.rolesToAdd}
								value={props.selectedRole}
								onChange={role => props.setSelectedRole(role)}
								classNamePrefix='react-select'
								placeholder={`${intl.formatMessage({ id: 'selectRole' })}...`}
							/>
							{props.selectedRoleError && <span className='red-error'>{props.selectedRoleError}</span>}
							{props.isHealthSystemShown && (
								<MultiSelect
									name='healthSystem'
									placeholder={intl.formatMessage({ id: 'selectHealthSystem' })}
									description={translate('selectAtLeastOneHSToAddRole', { value: translate('memberRole') })}
									options={healthSystemsArr}
									value={values.healthSystem}
									onChange={setFieldValue}
									onBlur={setFieldTouched}
									touched={touched.healthSystem}
									error={errors.healthSystem}
									backspaceRemovesValue={true}
								/>
							)}
						</Form>
					</Modal>
				);
			}}
		</Formik>
	);
};

export default AddMemberRole;
