import React from 'react';
import { useHistory } from 'react-router';
import Grid from 'components/Grid.jsx';
import translate from 'i18n-translations/translate.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';

const DeactivatedUser = props => {
	const history = useHistory();
	const signOut = () => {
		history.push('/logout');
		props.setStartApplication();
	};

	return (
		<Grid className='already-logged-in-wrapper' columns='1fr' rows='1fr' stretch='100vh' horizAlign='center' vertAlign='center'>
			{props.deactivatedName && (
				<div className='member-error'>
					<div className='flex flex-justify-center flex-align-center cursor-pointer'>
						<img src={`${healthCareCdnUrl}empty-state/no-doctor.png`} className='member-profile' alt='deactivated-user' />
					</div>
					<p>
						{translate('helloUser', { value: props.deactivatedName })}, <br /> {translate('accountIsDeactivated')}
					</p>
					<p>{translate('reachAdministrator')}</p>
					<button type='button' className='sign-out-btn' onClick={signOut}>
						<img src={`${healthCareCdnUrl}sign-out-icon.svg`} alt='icon' />
						{translate('signOut')}
					</button>
				</div>
			)}
		</Grid>
	);
};

export default DeactivatedUser;
