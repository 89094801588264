import React from 'react';
import PropTypes from 'prop-types';
import { buildProfilePicUrl, onErrorInitialsSrc } from 'infrastructure/helpers/thumbnailHelper.js';
import Video from 'components/Call/Video.jsx';

const Participant = props => {
	return (
		<div className='participant'>
			<header>
				<p>{props.name}</p>
				<div>{props.children}</div>
			</header>
			<main>
				<div
					style={{ background: !(props.participantFeed && props.videoCamActive) ? 'var(--gray-1)' : '' }}
					onClick={() => props.onClick(props.participantFeed.id)}>
					{props.participantFeed && props.videoCamActive && (
						<>
							{props.imgSrc && (
								<img
									className={`call-view-static-image participant-static ${props.imgSrc ? 'active' : ''}`}
									src={props.imgSrc}
									alt='static'
								/>
							)}
							<Video autoPlay muted feed={props.participantFeed.mediaStream} className={props.imgSrc ? 'hidden' : ''} />
						</>
					)}
					{!(props.participantFeed && props.videoCamActive) && (
						<div>
							{props.participantAvatar && (
								<img
									onError={e => onErrorInitialsSrc(e)}
									src={buildProfilePicUrl(props.participantAvatar, 150)}
									alt='participant-img'
								/>
							)}
							{!props.participantAvatar && (
								<div className='participant-initials'>
									<div>
										<p>{props.nameInitials}</p>
									</div>
								</div>
							)}
						</div>
					)}
				</div>
			</main>
		</div>
	);
};

Participant.propTypes = {
	audioMute: PropTypes.bool,
	videoCamActive: PropTypes.bool,
	participantFeed: PropTypes.object,
	onClick: PropTypes.func,
	name: PropTypes.string,
	children: PropTypes.node,
	participantAvatar: PropTypes.string,
};

export default Participant;
