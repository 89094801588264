import React, { useEffect, useState } from 'react';
import translate from 'i18n-translations/translate.jsx';
import classNames from 'classnames';
import { EditIcon } from 'icons/Monitoring/index.js';
import { useIntl } from 'react-intl';
import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';
import DatePicker from 'react-datepicker';
import { format, parseISO } from 'date-fns';

const PainMed = props => {
	const [expanded, setExpanded] = useState(true);
	const [showEditForm, setShowEditForm] = useState(false);
	const [dataToEdit, setDataToEdit] = useState(props.data);
	const intl = useIntl();

	useEffect(() => {
		setDataToEdit(props.data);
	}, [props.data]);

	const handleChange = (name, date) => {
		setDataToEdit(prevState => ({
			...prevState,
			[name]: date ? date.toISOString() : '',
		}));
	};

	const formattedInitialValue = item => {
		if (!item) {
			return '';
		}
		const date = parseISO(item);

		return new Date(date);
	};

	return (
		<>
			<div className={classNames('timeline-box-header sub-timeline-box-header', expanded ? 'expanded' : '')}>
				<p className='timeline-box-title'>{translate('painMedAvailable')}</p>
				<div className='timeline-box-actions'>
					<button
						type='button'
						onClick={() => {
							setShowEditForm(prevState => !prevState);
							setExpanded(true);
						}}>
						<EditIcon color={props.isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayThree} />
					</button>
					<button type='button' onClick={() => setExpanded(prevState => !prevState)}>
						<i className={classNames('material-icons', expanded ? ' rotate' : '')}>expand_more</i>
					</button>
				</div>
			</div>
			{expanded && (
				<>
					{!showEditForm && (
						<table className='patient-table-informations' style={{ width: '100%' }}>
							<tbody>
								<tr>
									<td>{translate('lastDoseGiven')}</td>
									<td>
										{formattedInitialValue(props.data.lastDose)
											? format(parseISO(props.data.lastDose), 'MMMM d, yyyy h:mm a')
											: ''}
									</td>
								</tr>
								<tr>
									<td>{translate('nextDose')}</td>
									<td>
										{formattedInitialValue(props.data.nextDose)
											? format(parseISO(props.data.nextDose), 'MMMM d, yyyy h:mm a')
											: ''}
									</td>
								</tr>
							</tbody>
						</table>
					)}
					{showEditForm && (
						<div className='flex flex-space-between flex-wrap whiteboard-date-time'>
							<div className='full-width input'>
								<label>
									{translate('lastDoseGiven')}
									<DatePicker
										showTimeSelect
										selected={formattedInitialValue(dataToEdit.lastDose)}
										onChange={date => handleChange('lastDose', date)}
										maxDate={dataToEdit.nextDose ? formattedInitialValue(dataToEdit.nextDose) : new Date()}
										dateFormat='MMMM d, yyyy h:mm aa'
										popperPlacement='bottom-start'
										placeholderText={intl.formatMessage({ id: 'lastDoseGiven' })}
										locale={props.locale}
										timeIntervals={1}
										className='full-width'
									/>
								</label>
							</div>
							<div className='top-15 full-width input'>
								<label>
									{translate('nextDose')}
									<DatePicker
										showTimeSelect
										selected={formattedInitialValue(dataToEdit.nextDose)}
										minDate={dataToEdit.lastDose ? formattedInitialValue(dataToEdit.lastDose) : null}
										onChange={date => handleChange('nextDose', date)}
										maxDate={new Date()}
										dateFormat='MMMM d, yyyy h:mm aa'
										popperPlacement='bottom-start'
										placeholderText={intl.formatMessage({ id: 'nextDose' })}
										locale={props.locale}
										timeIntervals={1}
										className='full-width'
									/>
								</label>
							</div>
							<div className='margin-left-auto'>
								<button
									className={classNames('button', props.isSubmitButtonLoading ? 'loading' : '')}
									type='button'
									onClick={() =>
										props.setData({
											lastDose: dataToEdit.lastDose || '',
											nextDose: dataToEdit.nextDose || '',
										})
									}>
									{translate('save')}
								</button>
							</div>
						</div>
					)}
				</>
			)}
		</>
	);
};

export default PainMed;
