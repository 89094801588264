import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import useOutsideClick from 'infrastructure/helpers/useOutsideClick.js';
import { DeviceListLevel, SectorTypes } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import {
	CareEventFilterTab,
	CareEventFilterTabs,
	CareEventTypes,
	Priorities,
	SLAOptions,
	Statuses,
} from 'constants/care-events.js';
import Select from 'react-select';
import {
	SingleValue,
	filterOptions,
	formatOptionLabel,
	getCareEventTypeIcon,
	getOptionLabel,
	getPriorityColor,
	getSLAColor,
	getStatusDescription,
} from 'infrastructure/helpers/careEventsHelper.js';
import { useSelector } from 'react-redux';
import { buildTree, searchSectors, showPath } from 'infrastructure/helpers/commonHelpers.js';
import { getRegionSubTree } from 'api/tree.js';
import DateRange from 'components/DateRange.jsx';
import Input from 'components/Input.jsx';
import TreeView from 'components/TreeView.jsx';
import AsyncSelect from 'react-select/async';

const CareEventsFilters = props => {
	const intl = useIntl();
	const maximumRangeOfDays = 90;
	const [sectorSearchValue, setSectorSearchValue] = useState('');
	const [expandAllTreeItems, setExpandAllTreeItems] = useState(false);
	const [tree, setTree] = useState([]);
	const [tabsExpanded, setTabsExpanded] = useState([
		{ id: 1, isExpanded: false },
		{ id: 2, isExpanded: false },
		{ id: 3, isExpanded: false },
		{ id: 4, isExpanded: false },
		{ id: 5, isExpanded: false },
		{ id: 6, isExpanded: false },
		{ id: 7, isExpanded: false },
		{ id: 8, isExpanded: false },
	]);
	const treeRef = useRef(null);
	const userSession = useSelector(state => state.user.userSession);

	useEffect(() => {
		if (sectorSearchValue.length > 1) {
			const sectors = searchSectors(tree, sectorSearchValue.trim());
			showPath(tree, sectors);
			setExpandAllTreeItems(true);
		} else {
			searchSectors(tree, '');
			setExpandAllTreeItems(false);
		}
	}, [sectorSearchValue, tree]);

	useEffect(() => {
		const getHealthSystemTree = async () => {
			const subTreeResponse = await getRegionSubTree(userSession.healthSystem.id, userSession.healthSystem.regions[0]?.id);
			if (subTreeResponse.error) {
				props.setError(subTreeResponse.error.message);
				return;
			}
			const { healthSystem } = subTreeResponse.organization;
			const treeData = buildTree(healthSystem);
			setTree(treeData);
		};
		if (userSession.healthSystem.id) {
			getHealthSystemTree();
		}
	}, [userSession.healthSystem.id]);

	const onLinkClick = option => {
		switch (option.type) {
			case SectorTypes.HOSPITAL:
				return props.setSelectedLevel({ label: DeviceListLevel.HOSPITAL, value: option.hospitalId });
			case SectorTypes.DEPARTMENT:
				return props.setSelectedLevel({ label: DeviceListLevel.DEPARTMENT, value: option.departmentId });
			case SectorTypes.FLOOR:
				return props.setSelectedLevel({ label: DeviceListLevel.FLOOR, value: option.floorId });
			case SectorTypes.ROOM:
				return props.setSelectedLevel({ label: DeviceListLevel.ROOM, value: option.roomId });
			default:
				return props.setSelectedLevel({ label: DeviceListLevel.HOSPITAL, value: option.hospitalId });
		}
	};

	const getSelectedTab = type => tabsExpanded.find(x => x.id === type).isExpanded;
	const handleRangeChange = range => {
		props.setDateRange(range);
		props.setHasFilterChanged(prevState => !prevState);
	};

	useOutsideClick(treeRef, () => {
		setSectorSearchValue('');
	});

	const toggleFilterTabs = id => {
		const expandedTabs = [...tabsExpanded];
		const expandedTab = expandedTabs.find(tab => tab.id === id);
		expandedTab.isExpanded = !expandedTab.isExpanded;
		setTabsExpanded(expandedTabs);
	};

	return (
		<div className='care-events-wrapper full-width position-relative doctor-profile-settings'>
			{props.isFilterShown && (
				<div className='filters-wrapper'>
					<div className='filter-clear-all'>
						<p>{translate('filters')}</p>
						<p className='clear-filter' onClick={props.handleClearAll}>
							{translate('clearAll')}
						</p>
					</div>

					{CareEventFilterTabs.map(item => (
						<>
							<div key={item.id} onClick={() => toggleFilterTabs(item.id)}>
								<i className='material-icons-outlined'>
									{tabsExpanded.find(tab => tab.id === item.id)?.isExpanded ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
								</i>
								<p>{translate(item.title)}</p>
							</div>
							{getSelectedTab(CareEventFilterTab.PATIENTS) &&
								item.id === CareEventFilterTab.PATIENTS &&
								props.patients.length > 0 && (
									<div className='select-wrapper'>
										<Select
											placeholder={intl.formatMessage({ id: 'searchPatientByMrn' })}
											value={props.selectedPatient}
											classNamePrefix='custom-select'
											options={props.patients}
											onChange={value => {
												props.setSelectedPatient(value);
												props.setPageIndex(0);
											}}
											formatOptionLabel={formatOptionLabel}
											components={{ SingleValue }}
											filterOptions={filterOptions}
											getOptionLabel={getOptionLabel}
											isClearable
										/>
									</div>
								)}

							{getSelectedTab(CareEventFilterTab.DATE_RANGE) && item.id === CareEventFilterTab.DATE_RANGE && (
								<div className='dashboard-date'>
									<DateRange
										startDate={props.from}
										endDate={props.to}
										handleRangeChange={handleRangeChange}
										maxDays={maximumRangeOfDays}
									/>
								</div>
							)}

							{getSelectedTab(CareEventFilterTab.PATIENT_PLACEMENT) && item.id === CareEventFilterTab.PATIENT_PLACEMENT && (
								<div ref={treeRef} className='position-relative'>
									<div className='care-events-tree-view'>
										<Input
											type='text'
											name='helloDeviceId'
											placeholder={intl.formatMessage({ id: 'searchRoomName' })}
											value={sectorSearchValue}
											onChange={event => setSectorSearchValue(event.target.value)}
											validationOptions={{}}
											bottomSpace='15px'
											autoComplete='off'
										/>
										<TreeView data={tree} onLinkClick={onLinkClick} expandAll={expandAllTreeItems} isCareEvents={true} />
									</div>
								</div>
							)}

							{getSelectedTab(CareEventFilterTab.TYPE) &&
								item.id === CareEventFilterTab.TYPE &&
								CareEventTypes.map(item => (
									<div className='flex flex-wrap input-auto-width-wrapper doctor-profile-settings' key={item.careEventId}>
										<div
											className='register-practice-modal-grid tick-box-active-input'
											onClick={() => {
												props.setSelectedEventType(item);
												props.setHasFilterChanged(prevState => !prevState);
											}}>
											<label className='flex full-width remember-me cursor-pointer no-margin'>
												<input
													type='checkbox'
													checked={item.careEventId === props.selectedEventType?.careEventId}
													name='careEventType'
												/>
												<div className='onb-custom-checkbox-wrapper cursor-pointer'>
													<div className='onb-custom-checkbox' />
												</div>
												{getCareEventTypeIcon(item?.careEventId, props.isDarkMode)}
												<span className='font-14 left-margin-s'>{translate(item.name)}</span>
											</label>
										</div>
									</div>
								))}

							{getSelectedTab(CareEventFilterTab.SLA) &&
								item.id === CareEventFilterTab.SLA &&
								SLAOptions.map(item => (
									<div className='flex flex-wrap input-auto-width-wrapper doctor-profile-settings' key={item.id}>
										<div
											className='register-practice-modal-grid tick-box-active-input'
											onClick={() => {
												props.setSelectedSLAFilter(item);
												props.setHasFilterChanged(prevState => !prevState);
											}}>
											<label className='flex full-width remember-me cursor-pointer no-margin'>
												<input type='checkbox' checked={item.id === props.selectedSLAFilter?.id} name='sla' />
												<div className='onb-custom-checkbox-wrapper cursor-pointer'>
													<div className='onb-custom-checkbox' />
												</div>
												<span className={classNames('sla-options left-margin-s', getSLAColor(item.id))} />
											</label>
										</div>
									</div>
								))}

							{getSelectedTab(CareEventFilterTab.PRIORITY) &&
								item.id === CareEventFilterTab.PRIORITY &&
								Priorities.map(item => (
									<div className='flex flex-wrap input-auto-width-wrapper doctor-profile-settings' key={item.id}>
										<div
											className='register-practice-modal-grid tick-box-active-input'
											onClick={() => {
												props.setSelectedPriorityFilter(item);
												props.setHasFilterChanged(prevState => !prevState);
											}}>
											<label className='flex full-width remember-me cursor-pointer no-margin'>
												<input type='checkbox' checked={item.id === props.selectedPriorityFilter?.id} name='priority' />
												<div className='onb-custom-checkbox-wrapper cursor-pointer'>
													<div className='onb-custom-checkbox' />
												</div>
												<span className={classNames('font-14 left-margin-s priorities', getPriorityColor(item.id))}>
													{translate(item.name)}
												</span>
											</label>
										</div>
									</div>
								))}

							{getSelectedTab(CareEventFilterTab.STATUS) &&
								item.id === CareEventFilterTab.STATUS &&
								Statuses.map(item => (
									<div className='flex flex-wrap input-auto-width-wrapper doctor-profile-settings' key={item.id}>
										<div
											className='register-practice-modal-grid tick-box-active-input'
											onClick={() => {
												props.setSelectedStatusFilter(item);
												props.setHasFilterChanged(prevState => !prevState);
											}}>
											<label className='flex full-width remember-me cursor-pointer no-margin'>
												<input type='checkbox' checked={item.id === props.selectedStatusFilter?.id} name='priority' />
												<div className='onb-custom-checkbox-wrapper cursor-pointer'>
													<div className='onb-custom-checkbox' />
												</div>
												<span className={classNames('font-14 left-margin-s statuses', getStatusDescription(item.id))}>
													{translate(item.name)}
												</span>
											</label>
										</div>
									</div>
								))}

							{getSelectedTab(CareEventFilterTab.CARE_TEAM) && item.id === CareEventFilterTab.CARE_TEAM && (
								<div className='select-wrapper' onClick={() => props.getNurses('')}>
									<AsyncSelect
										loadOptions={value => props.getNurses(value)}
										placeholder={intl.formatMessage({ id: 'selectCareTeam' })}
										onChange={props.setSelectedNurse}
										getOptionValue={option => option.value}
										classNamePrefix='custom-select'
										defaultOptions={props.nurses}
										isClearable
										value={props.selectedNurse}
										formatOptionLabel={formatOptionLabel}
										components={{ SingleValue }}
									/>
								</div>
							)}
						</>
					))}
				</div>
			)}
		</div>
	);
};

export default CareEventsFilters;
