import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router';
import { actionCreators as healthSystemsActionCreators } from 'state/healthSystems/actions.js';
import { actionCreators as userActionCreators } from 'state/user/actions.js';
import { actionCreators as configurationActionCreators } from 'state/configurations/actions.js';
import {
	buildTree,
	getHealthSystemDevices,
	getUserRoleId,
	getIsOldUserExperience,
	setCurrentHealthSystem,
} from 'infrastructure/helpers/commonHelpers.js';
import Dropdown from 'components/Dropdown.jsx';
import ListGroup from 'components/ListGroup.jsx';
import { DeviceListLevel, HealthcareErrorCode, HealthSystemType, UserRole, UserRoles } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import { Modal, Alert } from 'components/index.js';
import Form from 'components/Form.jsx';
import Select from 'components/Select.jsx';
import EditHealthSystemModal from 'containers/EditHealthSystemModal.jsx';
import { getRegionSubTree } from 'api/tree.js';
import { getPatientQueueCount, getUserHealthSystemPreferences, updateSession } from 'api/users.js';
import { getHealthSystems } from 'api/healthSystems.js';
import { getCompanyId, getUserId, getUserRole } from 'infrastructure/auth.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { getHealthSystemRoleConfigs, getTeamSettings } from 'api/adminConfigurations.js';
import {
	buildHealthSystemMenuConfigs,
	buildRoleSettings,
	configurableRoundingMenu,
	SettingsCategory,
	UserSettings,
} from 'constants/configurationEnums.js';
import { actionCreators as devicesActionCreators } from 'state/devices/actions.js';
import {
	buildCallSettings,
	buildHealthSystemConfigurations,
	buildVisualsSettings,
} from 'infrastructure/helpers/configurationsHelpers.js';

const HealthSystemActions = () => {
	const userSession = useSelector(state => state.user.userSession);
	const healthSystems = useSelector(state => state.healthSystems);
	const companyConfigurations = useSelector(state => state.company.companySettings?.companyConfigurations);
	const [selectedHealthSystemId, setSelectedHealthSystemId] = useState(userSession.healthSystem.id);
	const [selectedRegionId, setSelectedRegionId] = useState(userSession.regionId);
	const [currentRegions, setCurrentRegions] = useState(userSession.healthSystem.regions);
	const [isEditHealthSystemModalOpen, setIsEditHealthSystemModalOpen] = useState(false);
	const [isSwitchHealthSystemModalOpen, setIsSwitchHealthSystemModalOpen] = useState(false);
	const [isSwitchHealthSystemModalLoading, setIsSwitchHealthSystemModalLoading] = useState(false);
	const [error, setError] = useState(null);
	const [switchHsError, setSwitchHsError] = useState(null);
	const intl = useIntl();
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();

	const healthSystemOptions = [
		{
			title: intl.formatMessage({ id: 'switchHealthSystem' }),
			icon: 'compare_arrows',
			id: 'switch_hs',
		},
		{
			title: intl.formatMessage({ id: 'editHealthSystem' }),
			id: 'edit_hs',
			icon: 'edit',
		},
		{
			title: intl.formatMessage({ id: 'createNewHealthSystem' }),
			icon: 'add_circle_outline',
			id: 'create-health-system',
		},
	];
	const fetchHealthSystemConfigurations = async healthSystemId => {
		const fetchUnboundHealthSystemConfigs = async () => {
			const response = await getTeamSettings({
				teamId: healthSystemId,
				levelId: DeviceListLevel.HEALTH_SYSTEM,
				settingsCategory: null,
			});
			if (!response.error) {
				const isNewExperience = !getIsOldUserExperience(companyConfigurations, response.settings);
				const healthSystemSettings = {
					callSettings: buildCallSettings(response.settings),
					visualsSettings: buildVisualsSettings(response.settings),
				};
				dispatch(configurationActionCreators.setIsNewExperience(isNewExperience));
				dispatch(configurationActionCreators.setHealthSystemUnboundSettings(healthSystemSettings));
			}
		};

		const fetchBoundedHealthSystemConfigs = async () => {
			const response = await getTeamSettings({
				teamId: healthSystemId,
				levelId: DeviceListLevel.HEALTH_SYSTEM,
				settingsCategory: [SettingsCategory.MONITORING, SettingsCategory.PATIENTS],
			});
			if (!response.error) {
				const hsConfigs = buildHealthSystemConfigurations(response.settings);
				dispatch(configurationActionCreators.setHealthSystemConfigs(hsConfigs));
			}
		};

		await fetchUnboundHealthSystemConfigs();
		await fetchBoundedHealthSystemConfigs();
	};

	useEffect(() => {
		if (isSwitchHealthSystemModalOpen) {
			getAllHealthSystems();
		}
	}, [userSession.healthSystem.id, isSwitchHealthSystemModalOpen]);

	const getAllHealthSystems = async () => {
		const healthSystemsRes = await getHealthSystems();
		if (healthSystemsRes.error) {
			setError(healthSystemsRes.error.message);
		} else {
			dispatch(healthSystemsActionCreators.setAllHealthSystems(healthSystemsRes));
		}
	};

	useEffect(() => {
		const currentHealthSystem = healthSystems.allHealthSystems.filter(healthSystem => healthSystem.id === selectedHealthSystemId);
		if (currentHealthSystem.length === 0) {
			return;
		}
		setCurrentRegions(currentHealthSystem[0].regions);
	}, [isEditHealthSystemModalOpen, isSwitchHealthSystemModalOpen, selectedHealthSystemId, healthSystems.allHealthSystems]);

	const hasActiveQueue = async () => {
		if ([UserRoles.DIGITAL_CLINICIAN, UserRoles.DOCTOR, UserRoles.NURSE].includes(getUserRole())) {
			const response = await getPatientQueueCount(getUserId());
			if (response.error) {
				setError(response.error.message);
				return true;
			}
			return response.queueSize > 0;
		}
		return false;
	};

	const setCurrentHealthSystemInfo = async (isSwitchModal, healthSystem) => {
		const currentWorkFlowTypeId = userSession.healthSystem.workflowTypeId;
		if (currentWorkFlowTypeId === HealthSystemType.PRIMARY_CARE && (await hasActiveQueue())) {
			setSwitchHsError(intl.formatMessage({ id: 'activeQueueError' }));
			setIsSwitchHealthSystemModalLoading(false);
			return;
		}

		const healthSystemId = healthSystem ? healthSystem.id : selectedHealthSystemId;
		const regionId = healthSystem ? healthSystem.regions[0].id : selectedRegionId;
		const selectedHs = healthSystems.allHealthSystems.find(hs => hs.id === healthSystemId);

		const sessionParam = {
			healthSystemId,
			checkedInChannelId: null,
			floorId: null,
			departmentId: null,
			regionId,
			companyId: getCompanyId(),
		};
		setIsSwitchHealthSystemModalLoading(true);
		const [sessionResponse, responseMenu, userPreferenceResponse] = await Promise.all([
			updateSession(sessionParam),
			getHealthSystemRoleConfigs(healthSystemId),
			getUserHealthSystemPreferences(healthSystemId),
		]);

		if (sessionResponse.error || responseMenu.error) {
			const err = sessionResponse.error || responseMenu.error;
			if (sessionResponse.error.response.data.code === HealthcareErrorCode.PATIENT_IN_QUEUE) {
				setSwitchHsError(translate('patientInQueue'));
			} else if (sessionResponse.error.response.data.code === HealthcareErrorCode.MEDICAL_VISIT_INITIATED) {
				setSwitchHsError(translate('medicalVisitInitiated'));
			} else {
				setSwitchHsError(err.message);
			}
			setIsSwitchHealthSystemModalLoading(false);
			return;
		} else {
			const menus = await buildHealthSystemMenuConfigs(responseMenu.settings);
			const roundingConfigs = await buildRoleSettings(
				responseMenu.settings,
				configurableRoundingMenu(),
				getUserRoleId(getUserRole())
			);
			dispatch(configurationActionCreators.setRoleRoundingConfigurations(roundingConfigs));
			dispatch(configurationActionCreators.setAdminConfigurableMenu(menus));
			dispatch(
				userActionCreators.setUserSession({
					...userSession,
					healthSystem: { ...selectedHs, id: healthSystemId, workflowTypeId: selectedHs.workflowTypeId },
					regionId,
				})
			);
		}
		await fetchHealthSystemConfigurations(healthSystemId);
		await buildTreeData(healthSystemId, regionId, healthSystems.isRoomsOnlyView);
		setIsSwitchHealthSystemModalLoading(false);
		dispatch(healthSystemsActionCreators.updateBreadcrumb([]));
		fetchUserHsPreferences(userPreferenceResponse);
		if (shouldGoHome(selectedHs.workflowTypeId, currentWorkFlowTypeId)) {
			history.replace('/');
		}
		if (isSwitchModal) {
			setIsSwitchHealthSystemModalOpen(prevState => !prevState);
		}
	};

	const fetchUserHsPreferences = async userPreferences => {
		if (getUserRole() === UserRoles.GUEST) {
			return;
		}
		if (userPreferences.error) {
			return;
		}
		let obj = {};
		userPreferences.teamMemberSettings.forEach(item => {
			if (Object.values(UserSettings).includes(item.settingTypeId)) {
				obj[item.settingTypeId] = item.value;
			}
		});
		dispatch(configurationActionCreators.setUserSettings(obj));
	};

	const shouldGoHome = (current, previous) =>
		location.pathname !== '/monitoring' || (location.pathname === '/monitoring' && current !== previous);

	const buildTreeData = async (healthSystemId, regionId, isRoomsOnlyView) => {
		const subTreeResponse = await getRegionSubTree(healthSystemId, regionId);
		if (UserRole.VISITOR === getUserRole()) {
			return;
		}
		if (subTreeResponse.error) {
			setError(subTreeResponse.error.message);
			dispatch(devicesActionCreators.setBulkDevicesBusy([]));
			dispatch(devicesActionCreators.setBulkDevicesOnline([]));
			dispatch(devicesActionCreators.setBulkDevicesPrivacy([]));
			dispatch(devicesActionCreators.setBulkPairedRemoteDevice([]));
			dispatch(healthSystemsActionCreators.setHealthSystem(null));
			dispatch(healthSystemsActionCreators.setTreeData([]));
		} else {
			const { healthSystem } = subTreeResponse.organization;
			const treeData = buildTree(healthSystem, isRoomsOnlyView);
			const { online, busy, privacy, pairedRemote } = getHealthSystemDevices(healthSystem);
			dispatch(devicesActionCreators.setBulkDevicesBusy(busy));
			dispatch(devicesActionCreators.setBulkDevicesOnline(online));
			dispatch(devicesActionCreators.setBulkDevicesPrivacy(privacy));
			dispatch(devicesActionCreators.setBulkPairedRemoteDevice(pairedRemote));
			dispatch(healthSystemsActionCreators.setHealthSystem(healthSystem));
			dispatch(healthSystemsActionCreators.setTreeData(treeData));
		}
	};

	const deleteHealthSystem = async () => {
		const healthSystemsRes = await getHealthSystems();
		if (healthSystemsRes.error) {
			setError(healthSystemsRes.error.message);
		} else {
			dispatch(healthSystemsActionCreators.setAllHealthSystems(healthSystemsRes));
		}
		const firstHealthSystem = healthSystems.allHealthSystems[0];
		setCurrentHealthSystem(firstHealthSystem);
		setSelectedHealthSystemId(firstHealthSystem.id);
		setSelectedRegionId(firstHealthSystem.regions[0].id);
		setIsEditHealthSystemModalOpen(false);
		setCurrentHealthSystemInfo(false, firstHealthSystem);
	};

	const getHealthSystemName = () => {
		const foundHealthSystem = healthSystems.allHealthSystems.find(hs => hs.id === userSession.healthSystem.id);
		return foundHealthSystem ? foundHealthSystem.name : '';
	};

	const handleHealthSystemOptions = (event, item) => {
		if (item.id === 'switch_hs') {
			setIsSwitchHealthSystemModalOpen(prevState => !prevState);
		}

		if (item.id === 'edit_hs') {
			setIsEditHealthSystemModalOpen(prevState => !prevState);
		}
		if (item.id === 'create-health-system') {
			history.replace('/create-health-system');
		}
	};

	const changeHealthSystem = e => {
		const healthSystem = healthSystems.allHealthSystems[e.target.selectedIndex - 1];
		setCurrentRegions(healthSystem.regions);
		setSelectedRegionId(healthSystem.regions.length > 0 ? healthSystem.regions[0].id : null);
		setSelectedHealthSystemId(healthSystem.id);
	};

	const changeRegion = e => {
		const index = e.target.selectedIndex - 1;
		setSelectedRegionId(currentRegions[index].id);
	};

	return (
		<div className='full-width'>
			<header className='flex'>
				<span>{getHealthSystemName()}</span>
				{getUserRole() === UserRoles.ADMIN && (
					<Dropdown position='bottom' icon='more_horiz' className='health-system-list-dropdown'>
						<ListGroup onItemClick={handleHealthSystemOptions} lists={healthSystemOptions} />
					</Dropdown>
				)}

				{![UserRoles.ADMIN, UserRoles.PATIENT, UserRoles.SUPER_ADMIN, UserRole.VISITOR].includes(getUserRole()) && (
					<div className='switch-hospital' onClick={() => setIsSwitchHealthSystemModalOpen(true)}>
						<i
							className='material-icons-outlined'
							data-position='bottom'
							data-tooltip={intl.formatMessage({ id: 'switchHealthSystem' })}>
							compare_arrows
						</i>
					</div>
				)}
			</header>
			{selectedHealthSystemId && (
				<Modal
					modalSelector='switchHealthSystemModal'
					display={isSwitchHealthSystemModalOpen}
					onModalSubmit={() => setCurrentHealthSystemInfo(true)}
					isLoading={isSwitchHealthSystemModalLoading}
					position='center'
					className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal'
					shouldSubmitOnEnter={false}
					onModalClose={() => {
						setSwitchHsError(null);
						setIsSwitchHealthSystemModalOpen(prevState => !prevState);
					}}
					submitImgIcon={`${healthCareCdnUrl}appointments/save-icon.svg`}
					closeImgIcon={`${healthCareCdnUrl}appointments/cancel-appointment.svg`}>
					<Form title={translate('switchHSorRegion')} className='hs-form' height={500}>
						<Select
							type='text'
							label={translate('selectHealthSystem')}
							name='currentHealthSystemId'
							onSelect={changeHealthSystem}
							items={healthSystems.allHealthSystems}
							valueField='id'
							textField='name'
							value={selectedHealthSystemId}
							placeholder={intl.formatMessage({ id: 'selectHealthSystem' })}
						/>
						<Select
							type='text'
							label={translate('selectRegion')}
							name='currentRegionId'
							onSelect={changeRegion}
							items={currentRegions}
							placeholder={intl.formatMessage({ id: 'selectRegion' })}
							textField='name'
							valueField='id'
							value={selectedRegionId}
						/>
						{switchHsError && <p className='red-error top-15 text-align-right'>{switchHsError}</p>}
					</Form>
				</Modal>
			)}
			{isEditHealthSystemModalOpen && (
				<EditHealthSystemModal
					onHealthSystemDelete={deleteHealthSystem}
					onHealthSystemChange={() => setCurrentRegions(healthSystems.allHealthSystems[0].regions)}
					isOpen={true}
					onModalClose={() => setIsEditHealthSystemModalOpen(prevState => !prevState)}
					currentHealthSystemId={selectedHealthSystemId}
					currentRegions={currentRegions}
				/>
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</div>
	);
};

export default HealthSystemActions;
