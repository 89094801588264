import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { v4 } from 'uuid';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import CallManager from 'owt/p2p/CallManager.js';
import { ConferenceInfo, FromUser } from 'owt/base/conference.js';
import SectorList from 'containers/SectorList.jsx';
import Grid from 'components/Grid.jsx';
import VideoFeed from 'containers/Monitoring/VideoFeed.jsx';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import {
	findSectorById,
	getPreferredLanguageLocale,
	findDeviceById,
	checkIfMediaDevicesPlugged,
	checkForPermission,
	askForPermission,
	getConfigurationMenu,
	getStorage,
	skipDuplicatedObjects,
	isJSON,
	getConfigurationValue,
} from 'infrastructure/helpers/commonHelpers.js';
import Notification from 'components/Notification.jsx';
import Loader from 'components/Loader.jsx';
import Modal from 'components/Modal.jsx';
import Form from 'components/Form.jsx';
import Input from 'components/Input.jsx';
import {
	CallTypes,
	ObjectType,
	ParticipantState,
	SocketState,
	ConferenceEndReason,
	RTCPeerConnectionEnum,
	SectorTypes,
	CameraEventTypes,
	CameraTiltDirection,
	CameraTiltAction,
	CameraType,
	PatientWarnings,
	WarningIndexes,
	UserRoles,
	AiAlertTypes,
	HelloSettings,
	MediaTypes,
	MediaPermissions,
	StreamError,
	PatientsAiSettings,
	DeviceListLevel,
	ParticipantRemoveReason,
	MeasureDeviceType,
	AiAlertSign,
	Roles,
	InviteParticipantFailureReason,
	ManualAlertTypes,
	VoiceOverAlertTypes,
	AnalyticsCategory,
	SettingsAndAlertTypes,
	AiAlertNotificationType,
	MeasurementActivityTypes,
} from 'constants/enums.js';
import SocketEvents from 'constants/socket-events.js';
import { APP_CONFIG, healthCareCdnUrl } from 'constants/global-variables.js';
import {
	getMonitoringSessions,
	getMonitoringSessionDevices,
	saveMonitoringSession,
	deleteMonitoringSession,
	updateMonitoringSessionDevices,
} from 'api/monitoring.js';
import styled from 'styled-components';
import classNames from 'classnames';
import translate from 'i18n-translations/translate.jsx';
import { injectIntl } from 'react-intl';
import { getDeviceOwner, getPatientLastCondition, getPatientsAiSettings, updatePatientsAiSettings } from 'api/patients.js';
import { actionCreators as healthSystemsActionCreators } from 'state/healthSystems/actions.js';
import _ from 'lodash';
import { getCompanyId, getUserId, getUserInfo, getUserRole } from 'infrastructure/auth.js';
import ReOrderFeeds from 'components/ReOrderFeeds.jsx';
import { getCheckedInNurses, getTeams } from 'api/teams.js';
import Conversation from 'views/Chat/Conversation.jsx';
import PTT from 'services/walkieTalkie/ptt.js';
import MonitoringAlertTimeline from 'containers/Monitoring/MonitoringAlertTimeline.jsx';
import EhrIntegration from 'containers/EhrIntegration.jsx';
import ChatAlert from 'components/ChatAlert.jsx';
import { getIceServers } from 'api/iceServers.js';
import callsActionCreators from 'state/calls/actions.js';
import EmergencyCall from 'containers/EmergencyCall.jsx';
import IncomingCall from 'views/IncomingCall.jsx';
import Alert from 'components/Alert.jsx';
import { getDeviceList, getDeviceSettings, updateDeviceSettings } from 'api/devices.js';
import moment from 'moment';
import StreamPermissions from 'components/StreamPermissions.jsx';
import { GeneralSettings, MonitoringSettings, SettingsCategory } from 'constants/configurationEnums.js';
import ConferenceModal from 'calls/views/ConferenceModal.jsx';
import { actionCreators as aiSettingsActionCreators } from 'state/aiSettings/actions.js';
import { LOCALES } from 'i18n-translations/locales.js';
import { setBioBeatDeviceState } from 'api/measurements.js';
import { snoozeAlertBasedOnTask } from 'api/tasks.js';
import { enums } from '@solaborate/calls';
import { actionCreators as healthDataActionCreators } from 'state/healthData/actions.js';
import { outGoingCallSound } from 'components/CallSounds.jsx';
import LifeSignalsPopup from 'components/LifeSignalsPopup.jsx';
import { Prompt } from 'react-router-dom';
import ChangeRoomName from 'components/ChangeRoomName.jsx';
import { getRoomSettings } from 'api/adminConfigurations.js';
import { addAlertActivity, forwardManualAlert } from 'api/alerts.js';
import { AiAnalyticType } from 'constants/alerts.js';
import { generateCustomStyles } from 'constants/react-select-style.js';
import CreatableSelect from 'react-select/creatable';
import { minutesToMilliseconds } from 'infrastructure/helpers/dateHelper.js';
import ToastMessage from 'components/ToastMessage.jsx';
import { privacyModeOptions } from 'constants/monitoring.js';

const VideoBitrateConfig = [
	{ maxFeeds: 1, maxBitrate: 900, minBitrate: 700 },
	{ maxFeeds: 4, maxBitrate: 700, minBitrate: 500 },
	{ maxFeeds: 9, maxBitrate: 500, minBitrate: 400 },
	{ maxFeeds: 16, maxBitrate: 400, minBitrate: 300 },
	{ maxFeeds: 24, maxBitrate: 300, minBitrate: 200 },
	{ maxFeeds: 32, maxBitrate: 200, minBitrate: 100 },
];

class Monitoring extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.callManager = new CallManager(this.context, {
			useCallStats: APP_CONFIG.useCallStats && APP_CONFIG.sendCallStatsOnMonitoring,
			sendCallStatsInterval: APP_CONFIG.sendCallStatsInterval,
		});
		this.pressToTalkAudioRef = React.createRef();
	}

	state = {
		videoFeeds: [],
		participants: {},
		conferenceInfo: null,
		fullScreenDeviceId: 0,
		isFeedZoomed: false,
		monitoringSessions: [],
		showSessionPopup: false,
		newSessionName: '',
		currentSessionId: null,
		showDeleteSessionModal: false,
		isSessionNotificationVissible: false,
		showDeviceControlsLockedModal: false,
		showMaxPeerConnectionsModal: false,
		currentSector: null,
		disableButtons: false,
		socketState: SocketState.CONNECTED,
		hasActiveConference: false,
		conferenceEndReason: null,
		shouldShowSwitchToHelloCamError: false,
		isVitalSignsVisible: false,
		errorFetchingSessions: '',
		isReorderFeedsVissible: false,
		isSessionLoading: false,
		isObserverConferenceModalOpen: false,
		hasObserverConferenceStarted: false,
		observerConferenceData: {
			participants: [],
			conferenceName: '',
			callType: null,
			roomId: null,
		},
		incomingConferenceInfo: null,
		selectedFeed: null,
		isConversationShown: false,
		channels: [],
		selectedChannel: null,
		isTeamsLoading: true,
		chatAlerts: [],
		unReadMessages: [],
		isEmergencyCallOpen: false,
		showAllowMicrophoneModal: false,
		dataAcquisition: null,
		recordingStatus: false,
		newEventFired: null,
		sessionIsBeingSaved: false,
		audioFromMonitoringFeeds: false,
		isMicrophoneForAllFeedsEnabled: false,
		isPttJoining: false,
		hasPttJoined: false,
		isNoteShowing: false,
		previousFeedAudioEnabled: {},
		statAlarmError: null,
		voiceOverError: null,
		isStartConferenceInProgress: false,
		showPrivacyModeError: false,
		alertToMap: null,
		shouldNotCloseTreeOnAlert: false,
		feedToSetPrivacyTimer: null,
		privacyTimer: null,
		timerError: '',
		privacyModeOptions: _.cloneDeep(privacyModeOptions(this.props.intl)),
	};

	companyId = getCompanyId();

	userId = getUserId();

	userInfo = getUserInfo();

	playingAlert = null;

	pressToTalkAudioRef = null;

	patientsListRef = null;

	ptt = null;

	alertInfoTimeout = null;

	analyticsDataTimeout = null;

	feedsToInviteQueue = [];

	bioBeatDeviceFeedState = {
		OFF: 0,
		ON: 1,
	};

	voiceOverLanguages = [
		{ label: this.props.intl.formatMessage({ id: 'english' }), value: LOCALES.ENGLISH, img: 'us-flag.svg' },
		{ label: this.props.intl.formatMessage({ id: 'albanian' }), value: LOCALES.ALBANIAN, img: 'al-flag.svg' },
		{ label: this.props.intl.formatMessage({ id: 'german' }), value: LOCALES.GERMAN, img: 'germany-flag.svg' },
		{ label: this.props.intl.formatMessage({ id: 'spanish' }), value: LOCALES.SPANISH, img: 'spain-flag.svg' },
		{ label: this.props.intl.formatMessage({ id: 'arabic' }), value: LOCALES.ARABIC, img: 'uae-flag.svg' },
	];

	getLanguagePrefix = () => {
		let result = '';
		const locale = getPreferredLanguageLocale();
		if (locale === LOCALES.ARABIC) {
			result = 'arabic-';
		}
		if (locale === LOCALES.GERMAN) {
			result = 'german-';
		}
		if (locale === LOCALES.SPANISH) {
			result = 'spanish-';
		}
		return result;
	};

	fallAttentionSound = new Audio(`${healthCareCdnUrl}sound/${this.getLanguagePrefix()}fall-detection-attention.mp3`);

	railSound = new Audio(`${healthCareCdnUrl}sound/${this.getLanguagePrefix()}rail-down-attention.mp3`);

	personPositionSound = new Audio(`${healthCareCdnUrl}sound/${this.getLanguagePrefix()}patient-at-risk.mp3`);

	extremelyHighPressure = new Audio(`${healthCareCdnUrl}sound/${this.getLanguagePrefix()}extremely-high-pressure.mp3`);

	extremelyLowPressure = new Audio(`${healthCareCdnUrl}sound/${this.getLanguagePrefix()}extremely-low-pressure.mp3`);

	extremelyHighTemperature = new Audio(`${healthCareCdnUrl}sound/${this.getLanguagePrefix()}extremely-high-temperature.mp3`);

	highBloodGlucose = new Audio(`${healthCareCdnUrl}sound/${this.getLanguagePrefix()}high-blood-glucose.mp3`);

	highBloodPressure = new Audio(`${healthCareCdnUrl}sound/${this.getLanguagePrefix()}high-blood-pressure.mp3`);

	highHeartRate = new Audio(`${healthCareCdnUrl}sound/${this.getLanguagePrefix()}high-heart-rate.mp3`);

	highTemperature = new Audio(`${healthCareCdnUrl}sound/${this.getLanguagePrefix()}high-temperature.mp3`);

	lowBloodPressure = new Audio(`${healthCareCdnUrl}sound/${this.getLanguagePrefix()}low-blood-pressure.mp3`);

	lowHeartRate = new Audio(`${healthCareCdnUrl}sound/${this.getLanguagePrefix()}low-heart-rate.mp3`);

	lowTemperature = new Audio(`${healthCareCdnUrl}sound/${this.getLanguagePrefix()}low-temperature.mp3`);

	obese = new Audio(`${healthCareCdnUrl}sound/${this.getLanguagePrefix()}obese.mp3`);

	overWeight = new Audio(`${healthCareCdnUrl}sound/${this.getLanguagePrefix()}over-weight.mp3`);

	sleepDeficient = new Audio(`${healthCareCdnUrl}sound/${this.getLanguagePrefix()}sleep-deficient.mp3`);

	underWeight = new Audio(`${healthCareCdnUrl}sound/${this.getLanguagePrefix()}under-weight.mp3`);

	lowOxygen = new Audio(`${healthCareCdnUrl}sound/${this.getLanguagePrefix()}low-saturation.mp3`);

	extremelyLowOxygen = new Audio(`${healthCareCdnUrl}sound/${this.getLanguagePrefix()}extremely-low-saturation.mp3`);

	dangerSound = new Audio(`${healthCareCdnUrl}sound/alert-danger-sound.mp3`);

	ivBagSound = new Audio(`${healthCareCdnUrl}sound/${this.getLanguagePrefix()}iv-bag.mp3`);

	previousParticipantId = null;

	currentSession = { id: 0, name: translate('currentSession'), isActive: true, isCurrent: true };

	userRole = null;

	chatAlertTimeouts = [];

	micStatus = null;

	componentDidMount = async () => {
		this.micStatus = await checkForPermission(MediaTypes.MICROPHONE);
		this.micStatus.onchange = this.onDevicePermissionChange;
		this.userRole = getUserRole();
		this.setMonitoringSessions();
		this.callManager
			.on('peer-stream', this.handlePeerStream)
			.on('update-participant', this.handleUpdateParticipant)
			.on('participant-status', this.handleParticipantStatus)
			.on('participant-busy', this.handleParticipantBusy)
			.on('device-controls-locked', () => this.setState({ showDeviceControlsLockedModal: true }))
			.on('camera-response', this.cameraResponseListener)
			.on('initial-state', this.handleDeviceStateChanged)
			.on('device-state', this.handleDeviceStateChanged)
			.on('socket-state', this.handleSocketState)
			.on('peer-connection-state', this.handlePeerConnectionState)
			.on('end-call', this.handleEndCall)
			.on('local-audio-error', this.onLocalAudioError)
			.on('participant-toggled-streams', this.onAudioTrackToggled)
			.on('re-add-feed', this.deviceStatusChanged)
			.on('feed-reassign', this.onFeedReassign)
			.on('on-disconnected-by-call', this.handleDisconnectedByCall)
			.on('p2p-publish-failed', this.handlePeerConnectionFailed)
			.bindSocketEventListeners(false, false);
		this.bindWindowListeners();
		this.context.on(SocketEvents.Conference.ON_DEVICE_CONTROLS_LOCKED, this.onDeviceLockedEventHandler);
		this.context.on(SocketEvents.Alerts.NEW_AI_ALERT, this.onAiAlert);
		this.context.on(SocketEvents.Alerts.ALERT_ANALYTICS_ADDED, this.onNewAnalyticsAdded);
		this.context.on(SocketEvents.Alerts.NEW_MEASUREMENT_ALERT, this.onMeasurementAlert);
		this.context.on(SocketEvents.HelloDevice.RTSP_CAMERA_EVENT, this.handleRTSPcamera);
		this.context.on(SocketEvents.HealthCare.PATIENT_CONDITION_CREATED, this.handleNewConditionCreated);
		this.context.on(SocketEvents.HealthCare.ON_DEVICE_SETTINGS_UPDATED, this.handleDevicePrivacySettingUpdated);
		this.context.on(SocketEvents.HelloDevice.PRIVACY_MODE_UPDATE, this.handlePrivacyModeUpdate);
		this.context.on(SocketEvents.Conversation.ON_NEW_MESSAGE, this.onMessageHandle);
		this.context.on(SocketEvents.Conversation.ON_NEW_EVENT, this.onEventHandle);
		this.context.on(SocketEvents.BACKGROUND.BIO_BEAT_DATA, this.onHandleBioBeatData);
		this.context.on(SocketEvents.Conference.ON_MEDIA_CONTROLS_RESPONSE, this.handleDevicesList);
		this.context.on(SocketEvents.Conference.ALERT_PATIENT_AT_RISK_RESPONSE, this.onAlertPatientResponse);
		this.context.on(SocketEvents.LifeSignals.UNASSIGN_EXTERNAL_DEVICE, this.onUnassignExternalDevice);
		this.context.on(SocketEvents.Conference.VOICE_OVER_ALERT_RESPONSE, this.onVoiceOverAlertResponse);
		this.context.on(SocketEvents.Alerts.MANUAL_ACTIVITY_ADDED, this.onAddedManualActivity);
		this.context.on(SocketEvents.Alerts.PATIENT_AI_SETTINGS_UPDATED, this.onPatientAiSettingsUpdated);
		this.context.on(SocketEvents.Alerts.AI_NOTIFICATION_TYPES_UPDATED, this.onAiNotificationTypesUpdated);

		const userObj = {
			id: this.userInfo.userId,
			name: `${this.userInfo.firstName} ${this.userInfo.lastName}`,
			profilePicture: this.userInfo.profilePicture,
		};
		this.ptt = new PTT(userObj, this.pressToTalkAudioRef.current, this.context, await getIceServers());
		this.props.setIncomingCallRenderedElsewhere(true);
		this.getTeams();

		this.props.healthSystemActions.updateMonitoringDevices({
			inCallDevices: [],
			initiatedDevices: [],
		});
		if (getStorage().activeBioBeatItems && JSON.parse(getStorage().activeBioBeatItems)?.length > 0) {
			const newFeeds = _.cloneDeep(JSON.parse(getStorage().activeBioBeatItems)).map(async item => {
				const { taskId, patientUserId, patientUserGuid } = item;
				await this.setBioBeatDeviceStatusToOff({ taskId, patientUserId, patientUserGuid });
			});
			await Promise.all(newFeeds);
			getStorage().removeItem('activeBioBeatItems');
		}
	};

	onMessageHandle = data => {
		this.mapChatAlert(data, false);
	};

	onEventHandle = data => {
		this.mapChatAlert(data, true);
	};

	componentDidUpdate = prevProps => {
		if (prevProps.user.userSession.healthSystem.id !== this.props.user.userSession.healthSystem.id) {
			this.getTeams();
		}
	};

	componentWillUnmount() {
		this.callManager.unbindSocketEventListeners().unbindTimeouts();
		this.unbindWindowListeners();
		this.context.off(SocketEvents.Conference.ON_DEVICE_CONTROLS_LOCKED, this.onDeviceLockedEventHandler);
		this.context.off(SocketEvents.Alerts.NEW_AI_ALERT, this.onAiAlert);
		this.context.off(SocketEvents.Alerts.ALERT_ANALYTICS_ADDED, this.onNewAnalyticsAdded);
		this.context.off(SocketEvents.Alerts.NEW_MEASUREMENT_ALERT, this.onMeasurementAlert);
		this.context.off(SocketEvents.HelloDevice.RTSP_CAMERA_EVENT, this.handleRTSPcamera);
		this.context.off(SocketEvents.Conversation.ON_NEW_MESSAGE, this.mapChatAlert);
		this.context.off(SocketEvents.HealthCare.PATIENT_CONDITION_CREATED, this.handleNewConditionCreated);
		this.context.off(SocketEvents.BACKGROUND.BIO_BEAT_DATA, this.onHandleBioBeatData);
		this.context.off(SocketEvents.Conference.ON_MEDIA_CONTROLS_RESPONSE, this.handleDevicesList);
		this.context.off(SocketEvents.Conference.ALERT_PATIENT_AT_RISK_RESPONSE, this.onAlertPatientResponse);
		this.context.off(SocketEvents.LifeSignals.UNASSIGN_EXTERNAL_DEVICE, this.onUnassignExternalDevice);
		this.context.off(SocketEvents.Conference.VOICE_OVER_ALERT_RESPONSE, this.onVoiceOverAlertResponse);
		this.context.off(SocketEvents.Alerts.MANUAL_ACTIVITY_ADDED, this.onAddedManualActivity);
		this.context.off(SocketEvents.Alerts.PATIENT_AI_SETTINGS_UPDATED, this.onPatientAiSettingsUpdated);
		this.context.off(SocketEvents.Alerts.AI_NOTIFICATION_TYPES_UPDATED, this.onAiNotificationTypesUpdated);

		const { videoFeeds } = this.state;
		const feeds = [...videoFeeds];

		if (feeds.length) {
			this.props.healthSystemActions.updateMonitoringDevices({
				inCallDevices: [],
				initiatedDevices: [],
			});

			this.endMonitoring();
			this.updateTreeOnUnmount(feeds);
		}

		if (this.playingAlert) {
			this.playingAlert.pause();
			this.playingAlert.currentTime = 0;
		}
		this.chatAlertTimeouts.forEach(element => {
			clearTimeout(element);
		});
		this.props.setIncomingCallRenderedElsewhere(false);
	}

	updateTreeOnUnmount = feeds => {
		const treeData = _.cloneDeep(this.props.healthSystems.treeData.tree);
		feeds.forEach(({ deviceId }) => {
			const sector = findSectorById(treeData, deviceId);
			if (sector) {
				sector.isMonitoring = false;
			}
		});
		this.props.healthSystemActions.setTreeData(treeData);
	};

	onDevicePermissionChange = res => {
		if (res.target.state === MediaPermissions.GRANTED || res.target.state === MediaPermissions.PROMPT) {
			this.props.healthSystemActions.setStreamPermissionMessage(null);
		} else {
			const { videoFeeds } = this.state;
			videoFeeds.forEach(feed => {
				if (feed.isMyMicActive) {
					this.toggleMonitoringMic(feed);
				}
			});
		}
	};

	onFeedReassign = async ({ objectId }) => {
		const { videoFeeds } = this.state;
		const newVideoFeeds = [...videoFeeds];
		const feed = newVideoFeeds.find(x => x.deviceId === objectId);
		if (!feed) {
			return;
		}

		const devicesResponse = await getDeviceList(DeviceListLevel.ROOM, feed.roomId);
		if (devicesResponse.error) {
			this.setState({ error: devicesResponse.error.message });
			return;
		}

		feed.deviceOwner = { patientFormattedName: '' };
		if (devicesResponse?.[0].isDefaultOwner) {
			feed.lastCondition = { code: '', display: '', risk: '' };
			feed.isDefaultOwner = devicesResponse?.[0].isDefaultOwner;
			this.setState({ videoFeeds: newVideoFeeds });
			return;
		}

		const deviceOwnerResponse = await getDeviceOwner(objectId);
		if (deviceOwnerResponse.error) {
			this.setState({ error: deviceOwnerResponse.error.message });
			return;
		}
		const lastConditionResponse = await getPatientLastCondition(deviceOwnerResponse.userId);
		if (lastConditionResponse.error) {
			this.setState({ error: lastConditionResponse.error.message });
		} else {
			feed.lastCondition = lastConditionResponse;
		}

		feed.deviceOwner = { ...deviceOwnerResponse, patientFormattedName: this.getPatientFormattedName(deviceOwnerResponse) };
		feed.isDefaultOwner = devicesResponse?.[0].isDefaultOwner;
		this.setState({ videoFeeds: newVideoFeeds });
	};

	handleDisconnectedByCall = data => {
		const { videoFeeds } = this.state;
		const newVideoFeeds = [...videoFeeds].map(item => {
			const newItem = { ...item };
			if (newItem?.participantId === data?.participantId && newItem?.isStatAlarmActive) {
				newItem.isStatAlarmActive = false;
			}
			return newItem;
		});
		this.setState({
			videoFeeds: newVideoFeeds,
		});
	};

	handlePeerConnectionFailed = error => {
		if (!this.state.showMaxPeerConnectionsModal && error?.message.includes('Cannot create so many PeerConnections')) {
			this.setState({ showMaxPeerConnectionsModal: true });
		}
	};

	handleRTSPcamera = ({ helloDeviceId, isGridView }) => {
		const { videoFeeds } = this.state;
		const selectedFeedIndex = videoFeeds.findIndex(item => item.deviceId === helloDeviceId);
		if (selectedFeedIndex === -1) {
			return;
		}
		const [expandedFeed] = videoFeeds.splice(selectedFeedIndex, 1);
		expandedFeed.isGridView = isGridView;
		videoFeeds.splice(selectedFeedIndex, 0, expandedFeed);
		this.setState({ videoFeeds });
	};

	getHierarchyNaming = deviceId => {
		const newTree = [...this.props.healthSystems.treeData.tree];
		const device = findDeviceById(newTree, deviceId);
		if (!device?.breadcrumb) {
			return {};
		}
		const { breadcrumb } = device;
		return {
			hospital: breadcrumb[0].name,
			department: breadcrumb[1]?.name,
			floor: breadcrumb[2]?.name,
			room: breadcrumb[3]?.name,
		};
	};

	careEventFired = event => {
		this.setState({ newEventFired: event });
	};

	getTeams = async () => {
		if (
			![UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN, UserRoles.VIRTUAL_SITTER].includes(this.userRole) ||
			!this.props.user.userSession.healthSystem.id
		) {
			return;
		}
		const response = await getTeams(this.props.user.userSession.healthSystem.id);
		if (!response.error) {
			let channels = [];
			response.teams.forEach(team => {
				channels = channels.concat(team.channels.map(channel => ({ ...channel, team })));
			});
			this.setState({
				channels,
				isTeamsLoading: false,
			});
		} else {
			this.setState({
				isTeamsLoading: false,
			});
		}
	};

	onNewIvBagDataAdded = (data, found) => {
		this.updateAnalyticsInfo({
			...data,
			...found,
			text: `${this.props.intl.formatMessage({ id: 'ivBagFluidLevel' })}: ${data.value}%`,
		});
	};

	updateAnalyticsInfo = data => {
		const { videoFeeds } = this.state;
		const newVideoFeeds = [...videoFeeds];
		const selectedFeed = newVideoFeeds.find(item => item.deviceId === data.deviceId);
		if (!selectedFeed) {
			return;
		}

		selectedFeed.analyticsData = data;
		this.setState({ videoFeeds: newVideoFeeds });
		this.analyticsDataTimeout = setTimeout(() => {
			this.onAnalyticsToastMessageClose(data.deviceId);
		}, 5000);
	};

	onNewAnalyticsAdded = data => {
		const found = AiAnalyticType().find(el => el.id === data.analyticsType);
		if (found?.category === AnalyticsCategory.IV_BAG_FLUID_LEVEL) {
			this.onNewIvBagDataAdded(data, found);
		}
	};

	getNotificationOptions = (aiAlertType, deviceId) => {
		const aiSettingsList = _.cloneDeep(this.props.aiSettings);
		const found = aiSettingsList.find(item => item.deviceId === deviceId);
		if (!found) {
			return [];
		}
		const foundSettingTypeId = SettingsAndAlertTypes.find(alert => alert.alertType === aiAlertType)?.settingTypeId;
		if (foundSettingTypeId) {
			return found.settings.find(item => item.settingTypeId === foundSettingTypeId).notificationOptions;
		}
		return [];
	};

	onAiAlert = data => {
		const { type, deviceId, alertId, signType } = data;
		if (signType === AiAlertSign.INFORMATION) {
			if (type === AiAlertTypes.HANDS_DISINFECTED) {
				this.updateVideoFeedAlertInfo(data);
			}
			if (type === AiAlertTypes.PERSON_INACTIVE_INFORMATION) {
				this.updateVideoFeedAiToastMessage(data);
			}
		} else {
			this.playVoiceOver({ ...data, isAiAlert: true });
		}
		this.mapEventsToFeed({ deviceId, type, alertId, isAiAlert: true });
	};

	updateVideoFeedAlertInfo = data => {
		const alertData = { imgUrl: null, text: null };
		const { videoFeeds } = this.state;
		const newVideoFeeds = [...videoFeeds];
		const selectedFeed = newVideoFeeds.find(item => item.deviceId === data.deviceId);
		if (!selectedFeed) {
			return;
		}

		if (data.type === AiAlertTypes.HANDS_DISINFECTED) {
			alertData.text = translate('handsDisinfectedSuccessfully');
			alertData.imgUrl = `${healthCareCdnUrl}alert-history/hand-washing-green.svg`;
		}

		selectedFeed.alertInfo = { ...data, ...alertData };
		this.setState({ videoFeeds: newVideoFeeds });
		this.alertInfoTimeout = setTimeout(() => {
			this.onAlertInfoClose(data.deviceId);
		}, 5000);
	};

	updateVideoFeedAiToastMessage = data => {
		const aiToastDetails = { message: null, buttonText: null, imgUrl: null };
		const { videoFeeds } = this.state;
		const newVideoFeeds = [...videoFeeds];
		const selectedFeed = newVideoFeeds.find(item => item.deviceId === data.deviceId);
		if (!selectedFeed) {
			return;
		}

		aiToastDetails.message = translate('patientIsInactiveToastMessage');
		aiToastDetails.buttonText = translate('forwardToNursesBtn');
		aiToastDetails.imgUrl = `${healthCareCdnUrl}alert-history/inactive-same-position.svg`;
		aiToastDetails.alertId = data.alertId;

		selectedFeed.aiToastDetails = { ...data, ...aiToastDetails };
		this.setState({ videoFeeds: newVideoFeeds });
	};

	setMonitoringSessions = async () => {
		const monitoringSessionsRes = await getMonitoringSessions();
		if (monitoringSessionsRes.error) {
			this.setState({ errorFetchingSessions: `${this.props.intl.formatMessage({ id: 'failedToLoad' })}` });
		} else {
			this.setState({ monitoringSessions: [...monitoringSessionsRes, this.currentSession] });
		}
	};

	handlePeerConnectionState = ({ peerConnectionState, participantObjectId }) => {
		const { videoFeeds } = this.state;
		const newVideoFeeds = [...videoFeeds];
		const selectedFeed = newVideoFeeds.find(item => item.deviceId === participantObjectId);
		if (!selectedFeed) {
			return;
		}
		selectedFeed.peerConnectionState = peerConnectionState;
		switch (peerConnectionState) {
			case RTCPeerConnectionEnum.CONNECTION_STATE.CONNECTED:
				selectedFeed.status = ParticipantState.CONNECTED.type;
				if (this.state.previousFeedAudioEnabled[selectedFeed.deviceId]) {
					this.callManager.toggleParticipantTrack(CallTypes.AUDIO, false, selectedFeed.participantId);
				}
				break;
			case RTCPeerConnectionEnum.CONNECTION_STATE.DISCONNECTED:
				selectedFeed.status = ParticipantState.RECONNECTING.type;
				if (selectedFeed.isStatAlarmActive) {
					selectedFeed.isStatAlarmActive = false;
					selectedFeed.voiceOverAlert = null;
					selectedFeed.isVoiceOverOtherInitiator = null;
				}
				break;
			case RTCPeerConnectionEnum.CONNECTION_STATE.FAILED:
				selectedFeed.status = ParticipantState.RECONNECTING.type;
				break;
			default:
				break;
		}
		this.setState({ videoFeeds: newVideoFeeds });
	};

	handleEndCall = data => {
		if ([ConferenceEndReason.CLEANUP, ConferenceEndReason.UNDEFINED, ConferenceEndReason.OWNER_LEFT].includes(data.endReason)) {
			this.closeFeedsAndUpdateTree(this.state.videoFeeds);
		}
		if (data.endReason === ConferenceEndReason.PARTICIPANT_BUSY && this.state.videoFeeds.length === 1) {
			this.removeFeed(this.state.videoFeeds[0].deviceId);
		}
		this.setState(
			prevState => {
				let videoFeeds = prevState.videoFeeds;

				if (prevState.videoFeeds.length === 1 || data.endReason === ConferenceEndReason.CLEANUP) {
					videoFeeds = [];
				}
				return {
					...prevState,
					participants: {},
					conferenceInfo: null,
					isFeedZoomed: false,
					showDeviceControlsLockedModal: false,
					hasActiveConference: data.hasActiveConference,
					conferenceEndReason: data.endReason,
					videoFeeds,
				};
			},
			() => {
				this.props.healthSystemActions.updateMonitoringDevices({
					inCallDevices: [],
					initiatedDevices: [],
				});
				if (data.endReason === ConferenceEndReason.PARTICIPANT_IDLE) {
					window.location.href = '/logout';
				}
			}
		);
	};

	handleSocketState = ({ socketState }) => {
		if (this.state.socketState.type === socketState.type) {
			return;
		}
		const newState = { socketState };
		switch (socketState.type) {
			case SocketState.DISCONNECTED.type:
				Object.assign(newState, {
					disableButtons: true,
				});
				break;
			case SocketState.CONNECTED.type:
				Object.assign(newState, {
					disableButtons: false,
				});
				break;
			default:
				break;
		}
		this.setState(newState);
	};

	handleDeviceStateChanged = data => {
		const {
			helloDeviceId,
			isNightVision,
			zoomLevel,
			isHuddleCamConnected,
			cameraType,
			RTSPcameras,
			isCameraPrivacyOn,
			isMicPrivacyOn,
			mediaDevices,
			voiceOverInfo,
			isStatAlarmActive,
		} = data;
		// TODO check for RTSPcameras
		const { videoFeeds } = this.state;
		const selectedFeedIndex = videoFeeds.findIndex(item => item.deviceId === helloDeviceId);
		if (selectedFeedIndex === -1) {
			return;
		}
		const [expandedFeed] = videoFeeds.splice(selectedFeedIndex, 1);
		expandedFeed.zoomLevel = zoomLevel;
		expandedFeed.cameraType = cameraType;
		expandedFeed.isNightVisionActive = isNightVision;
		expandedFeed.isHuddleCamConnected = isHuddleCamConnected;
		expandedFeed.RTSPcameras = RTSPcameras;
		expandedFeed.isGridView = !!RTSPcameras;
		expandedFeed.isCameraPrivacyOn = isCameraPrivacyOn;
		expandedFeed.isMicPrivacyOn = isMicPrivacyOn;
		expandedFeed.mediaDevices = skipDuplicatedObjects(mediaDevices, 'name');
		expandedFeed.voiceOverAlert = voiceOverInfo?.isEnabled
			? this.voiceOverLanguages.find(el => el.value === voiceOverInfo.language)
			: null;
		if (voiceOverInfo?.isEnabled && voiceOverInfo?.initiator?.id !== this.state.conferenceInfo.participantId) {
			expandedFeed.isVoiceOverOtherInitiator = voiceOverInfo?.initiator;
		} else {
			expandedFeed.isVoiceOverOtherInitiator = null;
		}
		expandedFeed.isStatAlarmActive = isStatAlarmActive;
		videoFeeds.splice(selectedFeedIndex, 0, expandedFeed);
		this.setState({ videoFeeds });
	};

	handleParticipantStatus = statusResponse => {
		const { status, objectId, reason } = statusResponse;
		const { videoFeeds } = this.state;
		const expandedFeed = videoFeeds.find(item => item.deviceId === objectId);
		if (
			!expandedFeed ||
			(expandedFeed.status === ParticipantState.LEFT_CALL.type && status !== ParticipantState.REMOVED.type) ||
			expandedFeed.status === ParticipantState.DISCONNECTED.type ||
			(status === ParticipantState.LEFT_CALL.type &&
				[ParticipantRemoveReason.DISCONNECTED, ParticipantRemoveReason.DISCONNECTED_PARTICIPANT_CLEANUP].includes(reason))
		) {
			return;
		}
		expandedFeed.status = status;
		expandedFeed.reason = reason;
		this.setState({ videoFeeds });
	};

	handleParticipantBusy = data => {
		const { videoFeeds } = this.state;

		if (!videoFeeds.some(item => item.deviceId === data.objectId)) {
			return;
		}

		const { activeConferences } = data;

		const nursesInConference = activeConferences.reduce((result, item) => {
			const user = item.participants.find(participant => participant.objectType === ObjectType.USER);
			if (user) {
				result.push(user);
			}
			return result;
		}, []);

		const newVideoFeeds = videoFeeds.map(item => {
			if (item.deviceId === data.objectId) {
				const newItem = { ...item };
				newItem.status = ParticipantState.BUSY.type;
				if (nursesInConference.length > 1) {
					newItem.nursesInConference = nursesInConference;
				}

				const conference = activeConferences?.find(ac => ac.initialCallType === CallTypes.MONITORING);
				const monitoringNurse = conference?.participants.find(p => p.objectType === ObjectType.USER);
				if (conference && !monitoringNurse) {
					// eslint-disable-next-line no-console
					console.error('On participant busy - monitoring nurse is missing from participants');
					newItem.onPatientBusyNurse = monitoringNurse;
				}

				return newItem;
			}

			return item;
		});

		this.setState({ videoFeeds: newVideoFeeds });
	};

	handleCallStarted = data => {
		this.setState(
			{
				conferenceInfo: data.conferenceInfo,
				hasActiveConference: false,
				isSessionLoading: false,
				isStartConferenceInProgress: false,
			},
			() => {
				this.feedsToInviteQueue.forEach(item => {
					this.addFeed(item);
				});

				this.feedsToInviteQueue = [];
			}
		);
	};

	handleUpdateParticipant = data => {
		const { participants, videoFeeds } = this.state;
		if (!this.state.conferenceInfo || this.state.videoFeeds?.length === 0) {
			return;
		}
		data.forEach(p => {
			participants[p.id] = {
				id: p.id,
				objectId: p.objectId,
			};
			const selectedFeedIndex = videoFeeds.findIndex(item => item.deviceId === p.objectId);

			if (selectedFeedIndex !== -1) {
				const [feed] = videoFeeds.splice(selectedFeedIndex, 1);
				if (feed) {
					feed.participantId = p.id;
				}
				videoFeeds.splice(selectedFeedIndex, 0, feed);
			}
		});

		this.setState({ participants, videoFeeds });
	};

	handlePeerStream = data => {
		const { mediaStream, origin } = data.peerSrc;
		const { videoFeeds } = this.state;

		const selectedFeedIndex = this.getVideoFeedIndexByParticipantId(origin, videoFeeds);
		if (selectedFeedIndex === -1) {
			return;
		}

		const [feed] = videoFeeds.splice(selectedFeedIndex, 1);
		feed.src = mediaStream;
		feed.status = ParticipantState.CONNECTED.type;

		videoFeeds.splice(selectedFeedIndex, 0, feed);
		this.addIdToInCallDevicesDevice(feed.deviceId);
		this.handleBitrateChange();
		this.setState({ videoFeeds });
	};

	handleDevicePrivacySettingUpdated = data => {
		if (data.settingTypeId === HelloSettings.PRIVACY_MODE) {
			const { videoFeeds } = this.state;
			const feed = videoFeeds.find(item => item.deviceId === data.deviceId);
			if (feed) {
				feed.aiPrivacyStatus = data.settingValue === 'true';
			}
			this.setState({ videoFeeds });
		}
	};

	handlePrivacyModeUpdate = data => {
		const { videoFeeds } = this.state;
		const feed = videoFeeds.find(item => item.deviceId === data.deviceId);
		if (feed) {
			feed.aiPrivacyStatus = data.privacyMode;
			this.setState({ videoFeeds });
		}
	};

	togglePrivacyMode = async (deviceId, privacyMode, timer = null) => {
		if (this.state.dataAcquisition === 'true') {
			this.setState({ showPrivacyModeError: true });
			return privacyMode;
		}
		const monitoringSource = 1;
		let start = null;
		let end = null;

		if (timer) {
			start = Math.floor(Date.now() / 1000);
			end = start + Math.ceil(minutesToMilliseconds(timer.value) / 1000);
		}

		const settingValue = JSON.stringify({
			source: monitoringSource,
			active: !!timer,
			timer: timer
				? {
						start: start.toString(),
						end: end.toString(),
				  }
				: null,
		});

		const { error } = await updateDeviceSettings({
			deviceId,
			settingTypeId: HelloSettings.PRIVACY_MODE_TIMEOUT,
			settingValue,
		});
		if (!error) {
			const { videoFeeds } = this.state;
			const selectedFeed = videoFeeds.find(item => item.deviceId === deviceId);
			if (selectedFeed) {
				selectedFeed.aiPrivacyStatus = !privacyMode;
				selectedFeed.privacyTimerMilliseconds = timer ? Date.now() + minutesToMilliseconds(timer.value) : 0;
				this.setState({ videoFeeds });
			}
		} else {
			this.setState({ error: this.props.intl.formatMessage({ id: 'anErrorOccurred' }) });
			return privacyMode;
		}
		return !privacyMode;
	};

	toggleOffPrivacy = async deviceId => {
		const { videoFeeds } = this.state;
		const selectedFeed = videoFeeds.find(item => item.deviceId === deviceId);
		const monitoringSource = 1;

		const settingValue = JSON.stringify({
			source: monitoringSource,
			active: false,
			timer: null,
		});
		if (selectedFeed && selectedFeed.aiPrivacyStatus) {
			const response = await updateDeviceSettings({
				deviceId,
				settingTypeId: HelloSettings.PRIVACY_MODE_TIMEOUT,
				settingValue,
			});
			if (!response.error) {
				selectedFeed.aiPrivacyStatus = false;
				selectedFeed.privacyTimerMilliseconds = 0;
				const prevSelectedFeed = { ...this.state.selectedFeed };
				prevSelectedFeed.aiPrivacyStatus = false;
				prevSelectedFeed.privacyTimerMilliseconds = 0;
				this.setState({ videoFeeds });
			} else {
				this.setState({ error: this.props.intl.formatMessage({ id: 'anErrorOccurred' }) });
			}
		}
	};

	toggleOrShowPrivacyTimer = (deviceId, aiPrivacyStatus) => {
		if (aiPrivacyStatus) {
			this.togglePrivacyMode(deviceId, aiPrivacyStatus);
			return;
		}
		this.setState({
			feedToSetPrivacyTimer: {
				deviceId,
				aiPrivacyStatus,
			},
		});
	};

	onPrivacyTimerSubmitModal = () => {
		this.togglePrivacyMode(
			this.state.feedToSetPrivacyTimer.deviceId,
			this.state.feedToSetPrivacyTimer.aiPrivacyStatus,
			this.state.privacyTimer
		);
		this.setState({
			feedToSetPrivacyTimer: null,
			privacyTimer: null,
		});
	};

	setAlertSnoozePerFeed = async (deviceId, alertType, snoozeTime) => {
		const newVideoFeeds = _.cloneDeep(this.state.videoFeeds);
		const selectedFeed = newVideoFeeds.find(item => item.deviceId === deviceId);
		let arr = [];
		if (selectedFeed && selectedFeed.bioBeatTaskId) {
			const { bioBeatTaskId } = selectedFeed;
			const response = await snoozeAlertBasedOnTask(bioBeatTaskId, snoozeTime);
			if (response.error) {
				this.setState({ error: response?.error?.message });
				return;
			}

			if (getStorage().snoozedAlerts) {
				arr = JSON.parse(getStorage().snoozedAlerts);
			}
			const found = arr.find(item => item.deviceId === deviceId);
			if (found) {
				found.time = new Date();
				found.alertType = alertType;
				found.taskId = bioBeatTaskId;
				found.snoozeTime = snoozeTime;
			} else {
				arr.push({
					deviceId,
					time: new Date(),
					alertType,
					taskId: bioBeatTaskId,
					snoozeTime,
				});
			}
			getStorage().setItem('snoozedAlerts', JSON.stringify(arr));
		}
	};

	onMeasurementAlert = async ({ deviceId, measurement, measurementAlertType, alertId, patientUserId = null }) => {
		const params = {
			type: measurement.type,
			deviceId,
			alertId,
			warningIndex: measurementAlertType.warningIndex,
			isAiAlert: false,
			patientUserId,
		};

		this.playVoiceOver(params);
	};

	isAnyFeedMicActive = () => this.state.videoFeeds.some(feed => feed.isMyMicActive);

	shouldShowAlert = (deviceId, selectedFeed) => {
		let shouldShowAlert = true;
		const parsedAlerts = getStorage().snoozedAlerts ? JSON.parse(getStorage().snoozedAlerts) : [];
		const foundFeed = parsedAlerts.find(item => item.deviceId === selectedFeed.deviceId);
		const shouldCheckForSnooze = !deviceId && foundFeed && selectedFeed.bioBeatTaskId === foundFeed.taskId;

		if (shouldCheckForSnooze) {
			const now = new Date();
			const snoozedTime = foundFeed?.time;
			const diffMs = now.getTime() - new Date(snoozedTime).getTime();
			const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
			if (diffMins < foundFeed?.snoozeTime) {
				shouldShowAlert = false;
			}
		}
		return shouldShowAlert;
	};

	playVoiceOver = async ({ type, deviceId, alertId, warningIndex, isAiAlert, patientUserId }) => {
		const newVideoFeeds = _.cloneDeep(this.state.videoFeeds);
		let selectedFeedIndex = -1;
		if (deviceId) {
			selectedFeedIndex = newVideoFeeds.findIndex(item => item.deviceId === deviceId);
		}

		if (!deviceId && patientUserId) {
			selectedFeedIndex = newVideoFeeds.findIndex(item => item.deviceOwner.userId === patientUserId && item.isBioBeatActive);
		}
		if (selectedFeedIndex === -1 || newVideoFeeds[selectedFeedIndex].status !== ParticipantState.CONNECTED.type) {
			return;
		}
		const [selectedFeed] = newVideoFeeds.splice(selectedFeedIndex, 1);

		if (this.shouldShowAlert(deviceId, selectedFeed)) {
			const time = moment.utc().local().locale(getPreferredLanguageLocale()).format('hh:mm:ss A');
			if (this.playingAlert) {
				this.playingAlert.currentTime = 0;
				this.playingAlert.pause();
			}

			const notificationOptions = this.getNotificationOptions(type, deviceId);

			const hasSilentMode =
				notificationOptions.length > 0 && notificationOptions.some(item => item === AiAlertNotificationType.SILENT_MODE);
			if (hasSilentMode) {
				return;
			}
			const { text, imgIcon } = this.getHealthConcern(type, warningIndex);

			const hasToastMessageType =
				notificationOptions.length > 0 && notificationOptions.some(item => item === AiAlertNotificationType.TOAST_MESSAGE);

			if (!this.isAnyFeedMicActive() && !hasToastMessageType) {
				this.tryToPlayAlertSound(deviceId, isAiAlert);
			}
			selectedFeed.warning = { value: true, type };
			selectedFeed.warning.text = text;
			selectedFeed.warning.alertId = alertId;
			selectedFeed.warning.isAiAlert = isAiAlert;
			selectedFeed.warning.isToastMessage = hasToastMessageType;
			selectedFeed.events.unshift({ text, time, type, imgIcon });
			newVideoFeeds.splice(selectedFeedIndex, 0, selectedFeed);
			this.setState({ videoFeeds: newVideoFeeds });
		}
	};

	handleNewConditionCreated = data => {
		if (data.channelIds.length === 0 || !data.channelIds) {
			return;
		}

		const { videoFeeds } = this.state;
		const newVideoFeeds = [...videoFeeds].map(item => {
			if (item.deviceOwner?.userId === data?.userId) {
				const newItem = { ...item };
				newItem.lastCondition = { code: data?.code, display: data?.display };

				return newItem;
			}
			return item;
		});

		this.setState({ videoFeeds: newVideoFeeds });
	};

	getHealthConcern = (type, warningIndex) => {
		let text = null;
		let imgIcon = null;
		switch (type) {
			case PatientWarnings.TEMPERATURE: {
				if (WarningIndexes.EXTREMELY_HIGH === warningIndex) {
					this.playingAlert = this.extremelyHighTemperature;
					text = translate('extremelyHighTemperature');
				}
				if (WarningIndexes.HIGH === warningIndex) {
					this.playingAlert = this.highTemperature;
					text = translate('highTemperature');
				}
				if (WarningIndexes.LOW === warningIndex) {
					this.playingAlert = this.lowTemperature;
					text = translate('lowTemperature');
				}
				imgIcon = 'bodyMeasurements.svg';
				break;
			}
			case PatientWarnings.BLOOD_PRESSURE: {
				if (WarningIndexes.EXTREMELY_HIGH === warningIndex) {
					this.playingAlert = this.extremelyHighPressure;
					text = translate('extremelyHighPressure');
				}
				if (WarningIndexes.HIGH === warningIndex) {
					this.playingAlert = this.highBloodPressure;
					text = translate('highBloodPressure');
				}
				if (WarningIndexes.EXTREMELY_LOW === warningIndex) {
					this.playingAlert = this.extremelyLowPressure;
					text = translate('extremelyLowBloodPressure');
				}
				if (WarningIndexes.LOW === warningIndex) {
					this.playingAlert = this.lowBloodPressure;
					text = translate('lowBloodPressure');
				}
				imgIcon = 'heart.svg';
				break;
			}
			case PatientWarnings.HEART_RATE: {
				if (WarningIndexes.HIGH === warningIndex) {
					this.playingAlert = this.highHeartRate;
					text = translate('highHeartRateDescription');
				}
				if (WarningIndexes.LOW === warningIndex) {
					this.playingAlert = this.lowHeartRate;
					text = translate('lowHeartRateDescription');
				}
				imgIcon = 'heart.svg';
				break;
			}
			case PatientWarnings.DIABETES: {
				this.playingAlert = this.highBloodGlucose;
				text = translate('highBloodGlucose');
				imgIcon = 'otherData.svg';
				break;
			}
			case PatientWarnings.WEIGHT: {
				if (WarningIndexes.EXTREMELY_HIGH === warningIndex) {
					this.playingAlert = this.obese;
					text = translate('obese');
				}
				if (WarningIndexes.HIGH === warningIndex) {
					this.playingAlert = this.overWeight;
					text = translate('overweight');
				}
				if (WarningIndexes.LOW === warningIndex) {
					this.playingAlert = this.underWeight;
					text = translate('underweight');
				}
				imgIcon = 'bodyMeasurements.svg';
				break;
			}
			case PatientWarnings.OXYGEN: {
				if (WarningIndexes.LOW === warningIndex) {
					this.playingAlert = this.lowOxygen;
					text = translate('lowOxygen');
				}
				if (WarningIndexes.EXTREMELY_LOW === warningIndex) {
					this.playingAlert = this.extremelyLowOxygen;
					text = translate('extremelyLowOxygen');
				}
				imgIcon = 'otherData.svg';
				break;
			}
			case PatientWarnings.sleepAnalysis: {
				this.playingAlert = this.sleepDeficient;
				text = translate('sleepDeficient');
				imgIcon = 'sleep.svg';
				break;
			}
			case AiAlertTypes.FALL_DETECTION: {
				this.playingAlert = this.fallAttentionSound;
				text = translate('pleaseCheckPatient');
				imgIcon = 'skeletonFallDetection.svg';
				break;
			}
			case AiAlertTypes.PERSON_SITTING: {
				this.playingAlert = this.personPositionSound;
				text = translate('patientSitting');
				imgIcon = 'skeletonFallDetection.svg';
				break;
			}
			case AiAlertTypes.PERSON_STANDING: {
				this.playingAlert = this.personPositionSound;
				text = translate('patientStanding');
				imgIcon = 'skeletonFallDetection.svg';
				break;
			}
			case AiAlertTypes.PERSON_LAYING_FRONT: {
				this.playingAlert = this.personPositionSound;
				text = translate('patientLayingFront');
				imgIcon = 'skeletonFallDetection.svg';
				break;
			}
			case AiAlertTypes.PERSON_LAYING_SIDE: {
				this.playingAlert = this.personPositionSound;
				text = translate('patientLayingSide');
				imgIcon = 'skeletonFallDetection.svg';
				break;
			}
			case AiAlertTypes.PERSON_FACE_DOWN: {
				this.playingAlert = this.personPositionSound;
				text = translate('patientFaceDown');
				imgIcon = 'skeletonFallDetection.svg';
				break;
			}
			case AiAlertTypes.PERSON_INACTIVE: {
				this.playingAlert = this.personPositionSound;
				text = translate('patientInactive');
				imgIcon = 'skeletonFallDetection.svg';
				break;
			}
			case AiAlertTypes.PERSON_GETTING_OUT_OF_BED: {
				this.playingAlert = this.personPositionSound;
				text = translate('personGettingOutOfBed');
				imgIcon = 'skeletonFallDetection.svg';
				break;
			}
			case AiAlertTypes.RAIL_DOWN: {
				this.playingAlert = this.railSound;
				text = translate('railDownMessage');
				imgIcon = 'skeletonFallDetection.svg';
				break;
			}
			case AiAlertTypes.PERSON_STANDING_WALKING: {
				this.playingAlert = this.personPositionSound;
				text = translate('personIsDetectedWalking');
				imgIcon = 'skeletonFallDetection.svg';
				break;
			}
			case AiAlertTypes.IV_BAG:
			case AiAlertTypes.LOW_IV_BAG: {
				this.playingAlert = this.ivBagSound;
				text = translate('ivBagCheck');
				imgIcon = 'infusion-room.svg';
				break;
			}
			case AiAlertTypes.PERSON_GETTING_OUT_OF_CHAIR: {
				this.playingAlert = this.personPositionSound;
				text = translate('personGettingOutOfChairMessage');
				imgIcon = 'personGettingOutOfChair.svg';
				break;
			}
			default: {
				this.playingAlert = this.dangerSound;
				text = translate('pleaseCheckPatient');
				imgIcon = 'skeletonFallDetection.svg';
				break;
			}
		}
		return { text, imgIcon };
	};

	stopVoiceOver = (type, deviceId) => {
		const { videoFeeds } = this.state;
		const newFeeds = [...videoFeeds];
		const selectedFeedIndex = newFeeds.findIndex(item => item.deviceId === deviceId);
		const [selectedFeed] = newFeeds.splice(selectedFeedIndex, 1);
		if (type === PatientWarnings.FALL_DETECTED) {
			this.onFallDetected(false, selectedFeed);
		}
		if (this.playingAlert) {
			this.playingAlert.currentTime = 0;
			this.playingAlert.pause();
		}
		selectedFeed.warning = {};
		newFeeds.splice(selectedFeedIndex, 0, selectedFeed);
		this.setState({ videoFeeds: newFeeds });
	};

	stopBioBeatMeasurements = async videoFeeds => {
		const newFeeds = [...videoFeeds].map(item => {
			if (!item.isBioBeatActive || !item.bioBeatTaskId) {
				return null;
			}
			return this.setBioBeatDeviceStatusToOff({
				taskId: item.bioBeatTaskId,
				patientUserId: item.deviceOwner.userId,
				patientUserGuid: item.deviceOwner.id,
			});
		});
		await Promise.all(newFeeds);
		if (getStorage().activeBioBeatItems) {
			getStorage().removeItem('activeBioBeatItems');
		}
	};

	beforeUnloadEvent = event => {
		if (this.state.conferenceInfo) {
			event.preventDefault();
			event.returnValue = '';
		}
	};

	unloadEvent = () => {
		if (this.state.conferenceInfo) {
			this.callManager.endCall({ endReason: ConferenceEndReason.PARTICIPANT_LEFT });
			this.stopBioBeatMeasurements(_.cloneDeep(this.state.videoFeeds));
		}
	};

	handleUserIdleMessage = message => {
		if (message.data === 'IN_CALL') {
			if (this.state.conferenceInfo) {
				this.callManager.endCall({ endReason: ConferenceEndReason.PARTICIPANT_IDLE });
			} else {
				window.location.href = '/logout';
			}
		}
	};

	bindWindowListeners = () => {
		window.addEventListener('beforeunload', this.beforeUnloadEvent);
		window.addEventListener('unload', this.unloadEvent);
		window.addEventListener('message', this.handleUserIdleMessage);
	};

	unbindWindowListeners = () => {
		window.removeEventListener('beforeunload', this.beforeUnloadEvent);
		window.removeEventListener('unload', this.unloadEvent);
		window.removeEventListener('message', this.handleUserIdleMessage);
	};

	mapEventsToFeed = data => {
		const { videoFeeds } = this.state;
		const newFeeds = [...videoFeeds];
		const selectedFeedIndex = newFeeds.findIndex(item => item.deviceId === data.helloId);
		if (!newFeeds[selectedFeedIndex]) {
			return;
		}
		const [selectedFeed] = newFeeds.splice(selectedFeedIndex, 1);
		selectedFeed.events.unshift({
			type: data.type,
			time: moment.utc().local().locale(getPreferredLanguageLocale()).format('hh:mm:ss A'),
			text: translate(data.type),
			imgIcon: 'otherData.svg',
			alertId: data.alertId,
		});
		videoFeeds.splice(selectedFeedIndex, 0, selectedFeed);
		this.setState({ videoFeeds });
	};

	prepareStartConferenceInfo = async () => {
		const userProfile = JSON.parse(getStorage().getItem('userProfile'));
		const fromUser = new FromUser(
			`${userProfile.firstName} ${userProfile.lastName}`,
			userProfile.jobTitle,
			userProfile.profilePicture,
			undefined
		);
		const conferenceId = v4();
		const participantId = v4();
		const roleId = Roles.find(r => r.role === this.userRole).id;

		const devices = await navigator.mediaDevices.enumerateDevices();
		const inputDevices = {
			devices,
			permissions: {
				microphone: this.micStatus.state,
			},
		};
		const instigator = {
			objectId: userProfile.userId,
			objectType: ObjectType.USER,
			role: roleId,
		};

		return new ConferenceInfo(
			CallTypes.MONITORING, // callType
			conferenceId, // conferenceId
			'Monitoring', // conferenceName
			null, // conversationId
			fromUser, // from
			false, // isAudio
			false, // isChat
			false, // isDirectToHello
			false, // isMeetingRoom
			false, // isMultiparty
			false, // isScreen
			false, // isVideo
			participantId, // participantId
			[], // participants
			inputDevices,
			true, // isSecurityCam,
			instigator // instigator
		);
	};

	getPatientFormattedName = deviceOwner => {
		const { fullName } = deviceOwner;
		const ownerLastName = fullName.split(' ').slice(-1).join(' ');
		const ownerFirstName = fullName.split(' ').slice(0, -1).join(' ');
		return `${ownerLastName}${ownerFirstName.length > 0 ? ', ' : ''}${ownerFirstName}`;
	};

	addFeed = async deviceId => {
		const { conferenceInfo } = this.state;
		const treeData = this.props.healthSystems.treeData.tree;
		const sector = findSectorById(treeData, deviceId);
		if (sector) {
			this.setIsMonitoringToTreeData(deviceId, true);
		}
		if (conferenceInfo) {
			const response = await this.callManager.addDeviceToMonitoring({
				conferenceId: conferenceInfo.conferenceId,
				participantId: conferenceInfo.participantId,
				participant: {
					objectType: ObjectType.HELLO_DEVICE,
					objectId: deviceId,
				},
			});
			if (response?.failedInvitationToParticipants?.length) {
				this.addIdToInitiatedDevice(deviceId);
				this.setState(prevState => {
					return {
						...prevState,
						videoFeeds: prevState.videoFeeds.map(feed => {
							const newFeed = { ...feed };
							if (feed.deviceId === deviceId) {
								const failedFeed = response.failedInvitationToParticipants.find(f => f.objectId === deviceId);
								if (failedFeed) {
									newFeed.status =
										failedFeed.reason === InviteParticipantFailureReason.INVITE_DENIED
											? ParticipantState.INVITE_DENIED.type
											: ParticipantState.FAILED_TO_GET_INFO.type;
								}
							}
							return newFeed;
						}),
					};
				});
				return;
			}
		}
		const selectedFeed = this.state.videoFeeds.find(item => item.deviceId === deviceId);
		if (!selectedFeed) {
			return;
		}
		let isDefaultOwner = false;
		const devicesResponse = await getDeviceList(DeviceListLevel.ROOM, selectedFeed.roomId);
		if (devicesResponse.error) {
			this.setState({ error: devicesResponse.error.message });
		} else {
			isDefaultOwner = devicesResponse[0].isDefaultOwner;
		}
		if (!isDefaultOwner) {
			const deviceOwnerResponse = await getDeviceOwner(deviceId);
			let deviceOwner = { patientFormattedName: '' };
			let lastCondition = { code: '', display: '', risk: '' };
			if (deviceOwnerResponse.error) {
				this.setState({ error: deviceOwnerResponse.error.message });
			} else {
				deviceOwner = { ...deviceOwnerResponse, patientFormattedName: this.getPatientFormattedName(deviceOwnerResponse) };
				const lastConditionResponse = await getPatientLastCondition(deviceOwner?.userId);
				if (lastConditionResponse.error) {
					this.setState({ error: lastConditionResponse.error.message });
				} else {
					lastCondition = lastConditionResponse;
				}
			}
			const foundIndex = this.state.videoFeeds.findIndex(feed => feed.deviceId === deviceId);
			if (foundIndex === -1) {
				return;
			}

			const deviceSettingsRes = await getDeviceSettings(deviceId);
			if (deviceSettingsRes.error) {
				this.setState({ error: deviceSettingsRes.error.message });
				return;
			}
			this.setState(prevState => {
				const feeds = [...prevState.videoFeeds];

				if (feeds.length > 0 && feeds?.[foundIndex]) {
					feeds[foundIndex].deviceOwner = deviceOwner;
					feeds[foundIndex].lastCondition = lastCondition;
					feeds[foundIndex].isDefaultOwner = isDefaultOwner;
					if (
						!deviceSettingsRes.error &&
						deviceSettingsRes.deviceSettings.find(item => item.settingTypeId === HelloSettings.PRIVACY_MODE_TIMEOUT)?.value &&
						isJSON(
							deviceSettingsRes.deviceSettings.find(item => item.settingTypeId === HelloSettings.PRIVACY_MODE_TIMEOUT)?.value
						)
					) {
						feeds[foundIndex].aiPrivacyStatus = JSON.parse(
							deviceSettingsRes.deviceSettings.find(item => item.settingTypeId === HelloSettings.PRIVACY_MODE_TIMEOUT)?.value
						).active;
					}
				}

				return { ...prevState, videoFeeds: feeds };
			});

			const dataAcquisition = deviceSettingsRes.deviceSettings.find(
				item => item.settingTypeId === HelloSettings.DATA_ACQUISITION
			)?.value;

			if (deviceOwner?.id) {
				this.fetchPatientAiSettings({
					patientId: deviceOwner?.id,
					deviceId,
					roomId: selectedFeed.roomId,
					dataAcquisition,
				});
			}
		}
		const foundIndex = this.state.videoFeeds.findIndex(feed => feed.deviceId === deviceId);
		if (foundIndex === -1) {
			return;
		}
		this.setState(prevState => {
			const feeds = [...prevState.videoFeeds];

			if (feeds.length > 0 && feeds?.[foundIndex]) {
				feeds[foundIndex].isDefaultOwner = isDefaultOwner;
				if (sector) {
					feeds[foundIndex].roomType = sector.roomType;
				}
			}

			return { ...prevState, videoFeeds: feeds };
		});

		this.addIdToInitiatedDevice(deviceId);
	};

	fetchPatientAiSettings = async ({ patientId, deviceId, roomId, dataAcquisition }) => {
		const response = await getPatientsAiSettings({ patientId, deviceId, roomId });
		if (response.error) {
			this.setState({ error: response.error.message });
			return;
		}

		const mappedResponse = { deviceId, settings: response.patientAiSettings };

		const isAiSettingEnabled = response.patientAiSettings.some(item => !['false', undefined, null].includes(item.value));

		const selectedFeed = this.state.videoFeeds.find(item => item.deviceId === deviceId);
		if (!selectedFeed) {
			return;
		}
		this.setState({
			dataAcquisition,
		});
		this.props.aiSettingsActions.setPatientAiSettings(mappedResponse);
		this.onAiSettingClick(isAiSettingEnabled, selectedFeed);
	};

	onPatientAiSettingsUpdated = async ({ patientAiSettings, deviceId }) => {
		const { videoFeeds } = this.state;
		if (!videoFeeds) {
			return;
		}
		const selectedFeed = videoFeeds.find(item => item.deviceId === deviceId);
		if (!selectedFeed || selectedFeed?.deviceId !== deviceId) {
			return;
		}
		const isAiSettingEnabled = patientAiSettings.some(item => !['false', undefined, null].includes(item.value));
		this.onAiSettingClick(isAiSettingEnabled, selectedFeed);
		this.props.aiSettingsActions.setPatientAiSettings({ deviceId, settings: patientAiSettings });
		const deviceSettingsRes = await getDeviceSettings(deviceId);
		if (deviceSettingsRes.error) {
			this.setState({ error: deviceSettingsRes.error.message });
			return;
		}
		this.setState({
			dataAcquisition: deviceSettingsRes.deviceSettings.find(item => item.settingTypeId === HelloSettings.DATA_ACQUISITION)
				?.value,
		});
	};

	onAiNotificationTypesUpdated = ({ deviceId, patientAiSettings }) => {
		const { videoFeeds } = this.state;
		const selectedFeed = videoFeeds.find(item => item.deviceId === deviceId);
		if (selectedFeed?.deviceId !== deviceId) {
			return;
		}
		const aiSettingsList = _.cloneDeep(this.props.aiSettings);
		const found = aiSettingsList.find(item => item.deviceId === deviceId);
		if (!found) {
			return;
		}
		const updatedArr = found.settings.map(item => {
			const match = patientAiSettings.find(el => el.settingTypeId === item.settingTypeId);
			if (match) {
				return { ...item, notificationOptions: match.notificationOptions };
			}
			return item;
		});
		this.props.aiSettingsActions.setPatientAiSettings({ deviceId: selectedFeed.deviceId, settings: updatedArr });
	};

	setIsMonitoringToTreeData = (helloId, isMonitoring) => {
		const treeData = _.cloneDeep(this.props.healthSystems.treeData.tree);
		const sector = findSectorById(treeData, helloId);
		if (sector) {
			sector.isMonitoring = isMonitoring;
		}
		this.props.healthSystemActions.setTreeData(treeData);
	};

	setBioBeatDeviceStatusToOff = async ({ taskId, patientUserId, patientUserGuid }) => {
		const response = await setBioBeatDeviceState({
			taskId,
			deviceId: this.state.selectedFeed.biobeatWearableId,
			state: this.bioBeatDeviceFeedState.OFF,
			patientUserId,
			patientUserGuid,
		});
		if (response.error) {
			this.setState({
				error: response?.error?.message,
			});
			return;
		}
		return response;
	};

	removeFeed = (key, isFromSession = false) => {
		this.ptt.leave();
		const { videoFeeds } = this.state;
		let { conferenceInfo } = this.state;
		const feedIndex = videoFeeds.findIndex(item => item.deviceId === key);
		const feed = videoFeeds.find(item => item.deviceId === key);
		if (!feed) {
			return;
		}
		if (feed.audioTrack) {
			feed.audioTrack.stop();
		}
		if (feed.warning.value && this.playingAlert) {
			this.playingAlert.currentTime = 0;
			this.playingAlert.pause();
		}
		if (feed.warning.type === PatientWarnings.FALL_DETECTED) {
			this.onFallDetected(false, feed);
		}
		const { departmentId, floorId, hospitalId, roomId, participantId } = feed;
		if (hospitalId) {
			this.props.healthSystemActions.removeDeviceFromMonitoring({ hospitalId, departmentId, floorId, roomId, deviceId: key });
		}
		if (!isFromSession) {
			this.setIsMonitoringToTreeData(key, false);
		}
		this.setState(prevState => {
			const feedWithAudio = { ...prevState.previousFeedAudioEnabled };
			delete feedWithAudio[feed.deviceId];

			return {
				previousFeedAudioEnabled: feedWithAudio,
			};
		});

		const [removedFeed] = videoFeeds.splice(feedIndex, 1);
		if (conferenceInfo && !isFromSession) {
			this.callManager.removeDeviceFromMonitoring({
				conferenceId: conferenceInfo.conferenceId,
				participantId: conferenceInfo.participantId,
				actioneeParticipantId: participantId,
			});
		}

		this.removeIdFromMonitoringDevices(removedFeed.deviceId);
		if (videoFeeds.length === 0) {
			this.endMonitoring();
			conferenceInfo = null;
			if (this.props.healthSystems.isLeftNavigationExpanded) {
				this.props.healthSystemActions.toggleLeftNavigation();
			}
			this.setState(prevState => ({
				monitoringSessions: prevState.monitoringSessions.map(item => ({ ...item, isActive: !item.id })),
				audioFromMonitoringFeeds: false,
				isMicrophoneForAllFeedsEnabled: false,
			}));
		}
		const { isFeedExpanded } = feed;

		this.closeBioBeatOnRemoveFeed(feed, key);

		this.setState(prevState => ({
			videoFeeds,
			conferenceInfo,
			isFeedZoomed: isFeedExpanded ? !isFeedExpanded : prevState.isFeedZoomed,
			selectedFeed: prevState.selectedFeed?.deviceId === feed.deviceId ? null : prevState.selectedFeed,
			hasPttJoined: false,
		}));
		this.handleBitrateChange();
		this.callManager.startMeasuringIoTDevice({
			iotDevice: MeasureDeviceType.WATCH,
			helloDeviceId: feed.deviceOwner?.userId,
			conferenceId: conferenceInfo?.conferenceId,
			participantId,
			iotDeviceType: '',
			objectType: enums.ObjectTypes.USER,
			measureDeviceType: MeasureDeviceType.WATCH,
			startMeasure: false,
			doctorId: getUserInfo().userId,
			deviceId: feed.deviceId,
		});
		this.props.healthDataActions.setWatchMeasurements({ value: false, deviceId: feed.deviceId });
	};

	closeBioBeatOnRemoveFeed = async (feed, key) => {
		if (feed.isBioBeatActive && feed.bioBeatTaskId) {
			const response = await this.setBioBeatDeviceStatusToOff({
				taskId: feed.bioBeatTaskId,
				patientUserId: feed.deviceOwner.userId,
				patientUserGuid: feed.deviceOwner.id,
			});
			if (response.error) {
				this.setState({
					error: response?.error?.message,
				});
				return;
			}
			const getStorageBioBeat = getStorage().activeBioBeatItems ? JSON.parse(getStorage().activeBioBeatItems) : [];

			const foundIndex = getStorageBioBeat.findIndex(item => item.deviceId === key);

			if (foundIndex !== -1) {
				getStorageBioBeat.splice(foundIndex, 1);
			}

			getStorage().setItem('activeBioBeatItems', JSON.stringify(getStorageBioBeat));
		}
	};

	endMonitoring = () => {
		this.setState({
			conferenceInfo: null,
		});
		this.callManager.endCall({ endReason: ConferenceEndReason.INITIATOR_LEFT });
	};

	changeFeedBitrate = (deviceId, minBitrate, maxBitrate) => {
		const { videoFeeds, conferenceInfo } = this.state;
		const feed = videoFeeds.find(item => item.deviceId === deviceId);
		if (!feed) {
			return;
		}
		const { participantId } = feed;
		this.callManager.requestToChangeBitrate({
			conferenceId: conferenceInfo.conferenceId,
			participantId: conferenceInfo.participantId,
			actioneeParticipantId: participantId,
			settings: {
				minBitrate,
				maxBitrate,
			},
		});
	};

	handleBitrateChange = () => {
		const { videoFeeds } = this.state;
		const videoFeedsLength = Object.keys(videoFeeds).length;
		const expandedFeed = Object.values(videoFeeds).find(videoFeed => videoFeed.isFeedExpanded);
		const setting = VideoBitrateConfig.find(config => config.maxFeeds >= videoFeedsLength);

		if (expandedFeed) {
			videoFeeds.forEach(item => {
				if (expandedFeed.deviceId !== item.deviceId) {
					this.changeFeedBitrate(item.deviceId, setting.minBitrate, setting.maxBitrate);
				} else {
					this.changeFeedBitrate(item.deviceId, VideoBitrateConfig[0].minBitrate, VideoBitrateConfig[0].maxBitrate);
				}
			});
		} else {
			videoFeeds.forEach(item => {
				this.changeFeedBitrate(item.deviceId, setting.minBitrate, setting.maxBitrate);
			});
		}
	};

	toggleExpandFeed = async feed => {
		await this.ptt.leave();
		const { videoFeeds, selectedFeed } = this.state;
		const newFeeds = _.cloneDeep(videoFeeds);
		const selectedFeedIndex = newFeeds.findIndex(item => item.deviceId === feed.deviceId);
		const [clickedFeed] = newFeeds.splice(selectedFeedIndex, 1);
		clickedFeed.isFeedExpanded = !feed.isFeedExpanded;
		clickedFeed.isLifeSignalsPopupOpen = false;
		if (!clickedFeed.isFeedExpanded && clickedFeed?.isAlertTimelineVisible) {
			clickedFeed.isAlertTimelineVisible = false;
		}
		newFeeds.forEach(item => {
			// eslint-disable-next-line no-param-reassign
			item.isFeedExpanded = false;
			item.isAlertTimelineVisible = false;
			item.isLifeSignalsPopupOpen = false;
		});
		newFeeds.splice(selectedFeedIndex, 0, clickedFeed);

		const feedWithAlertTimelineOn = { ...selectedFeed };

		if (feedWithAlertTimelineOn?.isLifeSignalsPopupOpen) {
			this.toggleTreeOnAlertClick(feedWithAlertTimelineOn, 'isLifeSignalsPopupOpen');
			feedWithAlertTimelineOn.isLifeSignalsPopupOpen = false;
		}
		this.setState(
			{
				isFeedZoomed: clickedFeed.isFeedExpanded,
				videoFeeds: newFeeds,
				selectedFeed: feedWithAlertTimelineOn,
			},
			() => {
				this.handleBitrateChange();
				if (feedWithAlertTimelineOn?.isAlertTimelineVisible) {
					this.toggleAlerts(feed);
				}
			}
		);
		if (clickedFeed.isFeedExpanded && clickedFeed.floorId) {
			this.ptt
				.join(clickedFeed.floorId, this.getSelectedFloor(feed.deviceId)?.conversationId)
				.then(() => {
					this.setState(prevState => {
						const feeds = _.cloneDeep(prevState.videoFeeds);
						if (feeds.length > 0 && feeds[selectedFeedIndex]) {
							feeds[selectedFeedIndex].isPttAvailable = true;
						}

						return { ...prevState, videoFeeds: feeds };
					});
				})
				.catch(err => {
					// eslint-disable-next-line
					console.warn('Unable to join ptt on feed:', err);
					this.setState(prevState => {
						const feeds = _.cloneDeep(prevState.videoFeeds);
						if (feeds.length > 0 && feeds[selectedFeedIndex]) {
							feeds[selectedFeedIndex].isPttAvailable = false;
						}
						return { ...prevState, videoFeeds: feeds };
					});
				});
		}
	};

	toggleNightVision = async feed => {
		const { conferenceInfo } = this.state;
		const nightVisionMode = !feed.isNightVisionActive;

		try {
			const toggleResponse = await this.callManager.toggleNightVision(
				nightVisionMode,
				feed.deviceId,
				conferenceInfo.conferenceId,
				feed.participantId
			);
			if (toggleResponse.deviceControlsLocked) {
				this.setState({ showDeviceControlsLockedModal: true });
			}
			this.toggleNightVisionIcon(feed);
		} catch (err) {
			// eslint-disable-next-line no-console
			console.log(err);
		}
	};

	checkForMicrophonePermission = async () => {
		const pluggedDevices = await checkIfMediaDevicesPlugged();

		if (!pluggedDevices.microphone) {
			this.props.healthSystemActions.setStreamPermissionMessage({
				component: 'modal',
				type: StreamError.MICROPHONE_NOT_FOUND.type,
			});
			return false;
		}

		if (this.micStatus.state === MediaPermissions.DENIED) {
			await askForPermission({ audio: true });
			this.props.healthSystemActions.setStreamPermissionMessage({
				component: 'popup',
				type: StreamError.MICROPHONE_BLOCKED.type,
			});
			return false;
		}

		if (this.micStatus.state === MediaPermissions.PROMPT) {
			this.props.healthSystemActions.setStreamPermissionMessage({
				component: 'modal',
				type: StreamError.MICROPHONE_BLOCKED.type,
			});
			await askForPermission({ audio: true });
			this.props.healthSystemActions.setStreamPermissionMessage(null);
			return false;
		}
		return true;
	};

	toggleMyMicrophone = async feed => {
		const hasPermission = await this.checkForMicrophonePermission();
		if (hasPermission) {
			this.toggleMonitoringMic(feed);
		}
	};

	toggleWalkieTalkie = async isWalkieTalkieOn => {
		const hasPermission = await this.checkForMicrophonePermission();
		if (!hasPermission) {
			return false;
		}
		const response = await this.ptt.mic(isWalkieTalkieOn);
		if (response?.error) {
			this.props.healthSystemActions.setStreamPermissionMessage({
				component: 'modal',
				type: StreamError.MICROPHONE_NOT_FOUND.type,
			});
			return false;
		}
		return true;
	};

	toggleMonitoringMic = async feed => {
		if (feed.audioTrack) {
			if (this.prevFeed && this.prevFeed.audioTrack && this.prevFeed.audioTrack.id !== feed.audioTrack.id) {
				this.prevFeed.audioTrack.enabled = false;
				this.callManager.monitoringTrackToggled(false, this.prevFeed.participantId);
			}
			this.prevFeed = feed;
			// eslint-disable-next-line no-param-reassign
			feed.audioTrack.enabled = !feed.audioTrack.enabled;
			this.removePreviousActiveMic(this.state.videoFeeds, feed);
			this.callManager.monitoringTrackToggled(feed.audioTrack.enabled, feed.participantId);
		} else {
			const { videoFeeds } = this.state;
			videoFeeds.forEach(videoFeed => {
				if (videoFeed.audioTrack && videoFeed.audioTrack.enabled) {
					// eslint-disable-next-line no-param-reassign
					videoFeed.audioTrack.enabled = false;
					this.callManager.monitoringTrackToggled(false, videoFeed.participantId);
				}
			});
			this.callManager.callerParticipantId = feed.participantId;
			const audioStream = await this.callManager.toggleAudio(feed.participantId);

			if (this.callManager.p2p.hasPeerConnectionChannelForRemoteId(feed.participantId)) {
				const pc = this.callManager.p2p.getChannel(feed.participantId).getRTCPeerConnection();
				pc.onconnectionstatechange = event =>
					this.callManager.onConnectionStateChange(
						event,
						feed.participantId,
						feed.audioTrack && feed.audioTrack.readyState === 'live' ? audioStream : null
					);
			}
			if (!audioStream || audioStream.error) {
				if (this.prevFeed && this.prevFeed.audioTrack) {
					const { videoFeeds: feeds } = this.state;
					feeds.forEach(key => {
						if (feeds[key].participantId === this.prevFeed.participantId) {
							feeds[key].isMyMicActive = false;
						}
					});
					this.setState({ showDeviceControlsLockedModal: !audioStream, videoFeeds: feeds });
				} else {
					this.setState({ showDeviceControlsLockedModal: !audioStream });
				}

				if (audioStream && audioStream.error) {
					this.props.healthSystemActions.setStreamPermissionMessage({
						component: 'modal',
						type: StreamError.MICROPHONE_NOT_FOUND.type,
					});
				}
				return;
			}

			const audioTrack = audioStream.mediaStream.getAudioTracks()[0];
			// eslint-disable-next-line no-param-reassign
			feed.audioTrack = audioTrack;
			this.prevFeed = feed;
			this.removePreviousActiveMic(this.state.videoFeeds, feed);
		}

		if (this.hasAnyFeedWarning()) {
			const notificationOptions = this.getNotificationOptions(feed.warning?.type, feed.deviceId);
			const hasToastMessageType =
				notificationOptions.length > 0 && notificationOptions.some(item => item === AiAlertNotificationType.TOAST_MESSAGE);
			if (
				(!this.isAnyFeedMicActive() && !hasToastMessageType && feed.warning.isAiAlert) ||
				(!this.isAnyFeedMicActive() && feed.warning?.value && !feed.warning.isAiAlert)
			) {
				this.tryToPlayAlertSound(feed.deviceId, feed.warning.isAiAlert);
			}
			if (this.isAnyFeedMicActive() && this.playingAlert) {
				this.playingAlert.currentTime = 0;
				this.playingAlert.pause();
			}
		}
	};

	tryToPlayAlertSound = async (deviceId, isAiAlert = false) => {
		const aiSettingsList = _.cloneDeep(this.props.aiSettings);
		const found = aiSettingsList.find(item => item.deviceId === deviceId);
		if (!found) {
			return;
		}
		const isSoundEnabled = found.settings.some(
			item => item.settingTypeId === PatientsAiSettings.SOUND_ON_AI_ALERT && item.value === 'true'
		);
		if (!isSoundEnabled && isAiAlert) {
			return;
		}

		try {
			this.playingAlert.loop = true;
			await this.playingAlert.play();
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log('Autoplay failed:', error);
		}
	};

	hasAnyFeedWarning = () => this.state.videoFeeds.some(feed => Object.keys(feed.warning).length > 0);

	togglePeerSpeaker = async feed => {
		const { videoFeeds } = this.state;
		const newVideoFeeds = [...videoFeeds];

		this.callManager.callerParticipantId = feed.participantId;
		const response = await this.callManager.toggleParticipantTrack(CallTypes.AUDIO, feed.isPeerSpeakerActive);
		if (response.deviceControlsLocked) {
			this.setState({ showDeviceControlsLockedModal: true });
			this.callManager.toggleParticipantTrack(CallTypes.AUDIO, feed.isPeerSpeakerActive);
			return;
		}

		const selectedFeedIndex = newVideoFeeds.findIndex(item => item.deviceId === feed.deviceId);
		const [selectedFeed] = newVideoFeeds.splice(selectedFeedIndex, 1);
		selectedFeed.isPeerSpeakerActive = !feed.isPeerSpeakerActive;
		this.setState(prevState => ({
			previousFeedAudioEnabled: {
				...(prevState.audioFromMonitoringFeeds && { ...prevState.previousFeedAudioEnabled }),
				[selectedFeed.deviceId]: selectedFeed.isPeerSpeakerActive,
			},
		}));

		// if peer(hello) is not in another call than disable speaker to the previous hellos
		if (
			!getConfigurationValue(this.props.healthSystemConfigurations[MonitoringSettings.EnableMultipleFeedAudio]) ||
			!this.state.audioFromMonitoringFeeds
		) {
			const previousFeeds = videoFeeds.filter(
				videoFeed => videoFeed.participantId !== feed.participantId && videoFeed.isPeerSpeakerActive
			);
			if (previousFeeds?.length) {
				previousFeeds.forEach(previousFeed => {
					this.callManager.toggleParticipantTrack(CallTypes.AUDIO, true, previousFeed.participantId);
					previousFeed.isPeerSpeakerActive = false;
				});
			}
		}

		newVideoFeeds.splice(selectedFeedIndex, 0, selectedFeed);

		this.setState({ videoFeeds: newVideoFeeds });
	};

	toggleAllSpeakers = async audioFromMonitoringFeeds => {
		const allVideoFeeds = [...this.state.videoFeeds];
		const videoFeeds = await Promise.all(
			allVideoFeeds.map(async feed => {
				const newFeed = { ...feed };
				if (feed.status !== ParticipantState.CONNECTED.type) {
					return newFeed;
				}
				this.callManager.callerParticipantId = newFeed.participantId;
				if (audioFromMonitoringFeeds === newFeed.isPeerSpeakerActive) {
					return newFeed;
				}
				const response = await this.callManager.toggleParticipantTrack(CallTypes.AUDIO, newFeed.isPeerSpeakerActive);
				if (response.deviceControlsLocked) {
					return newFeed;
				}
				newFeed.isPeerSpeakerActive = !newFeed.isPeerSpeakerActive;
				this.setState(prevState => ({
					previousFeedAudioEnabled: {
						...prevState.previousFeedAudioEnabled,
						[feed.deviceId]: newFeed.isPeerSpeakerActive,
					},
				}));
				return newFeed;
			})
		);
		this.setState({ videoFeeds, audioFromMonitoringFeeds });
	};

	toggleAllMicrophones = async () => {
		const setFeeds = async feeds => {
			const videoFeeds = await Promise.all(
				feeds.map(async feed => this.enableMicrophone(feed, this.state.isMicrophoneForAllFeedsEnabled))
			);
			this.setState({ videoFeeds });
		};

		this.setState(
			prevState => ({ isMicrophoneForAllFeedsEnabled: !prevState.isMicrophoneForAllFeedsEnabled }),
			() => {
				setFeeds(this.state.videoFeeds);
			}
		);
	};

	enableMicrophone = async (feed, isEnabled) => {
		if (feed.audioTrack) {
			feed.audioTrack.enabled = isEnabled;
			feed.isMyMicActive = isEnabled;
			this.callManager.monitoringTrackToggled(isEnabled);
			return feed;
		}

		this.callManager.callerParticipantId = feed.participantId;
		const audioStream = await this.callManager.toggleAudio(feed.participantId);

		if (this.callManager.p2p.hasPeerConnectionChannelForRemoteId(feed.participantId)) {
			const pc = this.callManager.p2p.getChannel(feed.participantId).getRTCPeerConnection();
			pc.onconnectionstatechange = event =>
				this.callManager.onConnectionStateChange(
					event,
					feed.participantId,
					feed.audioTrack && feed.audioTrack.readyState === 'live' ? audioStream : null
				);
		}

		if (!audioStream || audioStream.error) {
			return feed;
		}

		const audioTrack = audioStream.mediaStream.getAudioTracks()[0];
		feed.audioTrack = audioTrack;
		feed.isMyMicActive = isEnabled;

		return feed;
	};

	removePreviousActiveMic = (previousFeeds, currentFeed) => {
		if (this.state.videoFeeds.length === 0) {
			return;
		}
		previousFeeds.forEach(item => {
			if (item.participantId === currentFeed.participantId) {
				item.isMyMicActive = !item.isMyMicActive;
			} else {
				item.isMyMicActive = false;
				if (item?.audioTrack?.enabled) {
					item.audioTrack.enabled = false;
				}
			}
		});
		this.setState({
			videoFeeds: previousFeeds,
			isMicrophoneForAllFeedsEnabled: false,
		});
	};

	toggleNightVisionIcon = deviceId => {
		this.setState(prevState => {
			const newVideoFeeds = prevState.videoFeeds.map(feed => {
				const newFeed = { ...feed };
				if (newFeed.deviceId === deviceId) {
					newFeed.isNightVisionActive = !newFeed.isNightVisionActive;
				}
				return newFeed;
			});
			return { videoFeeds: newVideoFeeds };
		});
	};

	onDeviceLockedEventHandler = async data => {
		const { deviceId } = data;
		const { videoFeeds } = this.state;
		const newVideoFeeds = [...videoFeeds];
		const selectedFeedIndex = newVideoFeeds.findIndex(item => item.deviceId === deviceId);
		const [selectedFeed] = newVideoFeeds.splice(selectedFeedIndex, 1);
		let showDeviceControlsLockedModal = false;
		if (selectedFeed.audioTrack) {
			if (selectedFeed.audioTrack.enabled && selectedFeed.isMyMicActive) {
				this.removePreviousActiveMic(newVideoFeeds, selectedFeed);
				selectedFeed.isMyMicActive = !selectedFeed.isMyMicActive;
				selectedFeed.audioTrack.enabled = false;
				showDeviceControlsLockedModal = true;
			}
			this.callManager.removeTrackById(selectedFeed.participantId, selectedFeed.audioTrack.id);
			selectedFeed.audioTrack.stop();
			delete selectedFeed.audioTrack;
		}
		if (selectedFeed.isPeerSpeakerActive) {
			showDeviceControlsLockedModal = true;
			selectedFeed.isPeerSpeakerActive = false;
		}
		newVideoFeeds.splice(selectedFeedIndex, 0, selectedFeed);
		this.setState({ videoFeeds: newVideoFeeds, showDeviceControlsLockedModal });
	};

	setActiveSession = async session => {
		this.setState(prevState => ({
			monitoringSessions: prevState.monitoringSessions.map(item => ({ ...item, isActive: item.id === session.id })),
		}));
	};

	getMappedFeed = deviceId => {
		const sector = findSectorById(this.props.healthSystems.treeData.tree, deviceId);
		if (!sector || this.state.videoFeeds.some(feed => feed.deviceId === deviceId)) {
			return null;
		}
		const { roomId, hospitalId, departmentId, floorId, name, aiPrivacyStatus } = sector;
		return {
			disabledTiltDirections: {},
			events: [],
			warning: {},
			peerConnectionState: RTCPeerConnectionEnum.CONNECTION_STATE.NEW,
			roomName: name,
			roomId,
			hospitalId,
			departmentId,
			floorId,
			aiPrivacyStatus,
			src: null,
			status: ParticipantState.CONNECTING.type,
			hospitalName: name,
			zoomLevel: 0,
			cameraType: CameraType.HELLO,
			isPeerSpeakerActive: false,
			deviceOwner: { patientFormattedName: '', id: null },
			lastCondition: { code: '', display: '', risk: '' },
			deviceId,
			isCameraPrivacyOn: false,
			isMicPrivacyOn: false,
			isPttAvailable: true,
			isOnline: true,
			alertInfo: {},
			isPtzActive: true,
			healthSystemId: this.props.user.userSession.healthSystem.id,
		};
	};

	hasHsChanged = () => {
		const { id } = this.props.user.userSession.healthSystem;
		return this.state.videoFeeds.some(videoFeed => id !== videoFeed.healthSystemId);
	};

	queueOrAddFeedOnStartConference = deviceId => {
		if (this.state.isStartConferenceInProgress) {
			this.feedsToInviteQueue.push(deviceId);
			return;
		}
		this.addFeed(deviceId);
	};

	openSession = async (event, session) => {
		event.preventDefault();
		if (session.isActive) {
			return;
		}
		if (this.state.isSessionLoading) {
			return;
		}
		if (this.state.videoFeeds.length > 0) {
			this.closeCurrentSession();
			return;
		}
		this.setActiveSession(session);
		this.setState({ isSessionLoading: true });
		if (!session.id) {
			this.setState({ isSessionLoading: false });
			return;
		}
		const devices = await getMonitoringSessionDevices(session.id);
		if (devices.error) {
			this.setState({ error: devices.error.message, isSessionLoading: false });
			return;
		}
		const videoFeeds = devices.reduce((result, item) => {
			const feed = this.getMappedFeed(item.deviceId);
			if (feed) {
				result.push(feed);
			}
			return result;
		}, []);
		if (!this.state.isPttJoining && !this.state.hasPttJoined && videoFeeds.length !== 0) {
			this.setState({ isPttJoining: true });
			this.ptt
				.join(videoFeeds[0].floorId, this.getSelectedFloor(videoFeeds[0].helloDeviceId)?.conversationId)
				.then(() => {
					this.setState({ isPttJoining: false, hasPttJoined: true });
				})
				.catch(err => {
					// eslint-disable-next-line
					console.warn('Unable to join ptt on feed:', err);
					this.setState(prevState => {
						const feeds = _.cloneDeep(prevState.videoFeeds);
						if (feeds.length > 0) {
							feeds[0].isPttAvailable = false;
						}
						return { ...prevState, videoFeeds: feeds, isPttJoining: false, hasPttJoined: false };
					});
				});
		}
		this.isSession = true;
		this.setState(
			{
				currentSessionId: session.id,
				videoFeeds,
				isSessionLoading: true,
				isStartConferenceInProgress: true,
				error: null,
			},
			async () => {
				const data = await this.callManager.startMonitoring(await this.prepareStartConferenceInfo());
				if (data.response?.failureReason === enums.StartConferenceFailureReasons.GET_INITIATOR_INFO_FAILED) {
					const activeSession = this.state.monitoringSessions.find(s => s.id === this.state.currentSessionId);
					this.closeCurrentSession(true, activeSession);
					this.setState({ error: translate('failedToGetInitiatorInfo'), isSessionLoading: false });
					return;
				}
				this.handleCallStarted(data);
				devices.forEach(({ deviceId }) => {
					if (videoFeeds.length) {
						this.queueOrAddFeedOnStartConference(deviceId);
					}
				});
				this.setState({ isSessionLoading: false });
			}
		);
	};

	toggleReOrder = session => {
		if (this.state.videoFeeds.length <= 1 || (session && !session.isActive)) {
			return;
		}
		this.setState(prevState => ({ isReorderFeedsVissible: !prevState.isReorderFeedsVissible }));
	};

	saveReorder = videoFeeds => {
		this.setState(
			prevState => ({ videoFeeds, isReorderFeedsVissible: !prevState.isReorderFeedsVissible }),
			() => {
				this.saveSession();
			}
		);
	};

	saveSession = session => {
		if (this.state.videoFeeds.length === 0 || (session && !session.isActive)) {
			return;
		}
		if (this.state.currentSessionId) {
			this.updateSession(this.state.currentSessionId);
		} else {
			this.toggleSessionModal(true);
		}
	};

	createNewSession = async name => {
		if (!name || (name && name.trim() === '')) {
			return;
		}
		if (this.state.sessionIsBeingSaved) return;
		this.setState({ sessionIsBeingSaved: true });
		const sessionData = {
			name: name,
			healthSystemId: this.props.user.userSession.healthSystem.id,
			sessionDevices: [],
		};

		this.state.videoFeeds.forEach((feed, index) => {
			sessionData.sessionDevices.push({ id: +feed.deviceId, orderNo: index });
		});
		const response = await saveMonitoringSession(sessionData);
		if (response?.error) {
			this.setState({ error: response.error.message, sessionIsBeingSaved: false });
			return;
		}
		this.toggleSessionModal(false);
		const sessions = await getMonitoringSessions();
		if (sessions.error) {
			this.setState({
				errorFetchingSessions: `${this.props.intl.formatMessage({ id: 'failedToLoad' })}`,
				sessionIsBeingSaved: false,
			});
			return;
		}
		const savedSession = sessions[sessions.length - 1];
		savedSession.isActive = true;
		const currentSession = { ...this.currentSession };
		currentSession.isActive = false;
		this.setState({
			monitoringSessions: [...sessions, currentSession],
			currentSessionId: savedSession.id,
			sessionIsBeingSaved: false,
		});
	};

	updateSession = async sessionId => {
		const allSessions = this.state.videoFeeds.map((feed, index) => ({ id: +feed.deviceId, orderNo: index }));
		const response = await updateMonitoringSessionDevices(sessionId, { sessionDevices: allSessions });
		if (!response.error) {
			this.showSessionUpdatedNotification();
		} else {
			this.setState({ error: response.error.message });
		}
	};

	showSessionUpdatedNotification = () => {
		this.setState(
			{
				isSessionNotificationVissible: true,
			},
			() => {
				setTimeout(() => {
					this.setState({
						isSessionNotificationVissible: false,
					});
				}, 2000);
			}
		);
	};

	closeFeedsAndUpdateTree = feeds => {
		const treeData = _.cloneDeep(this.props.healthSystems.treeData.tree);
		feeds.forEach(({ deviceId }) => {
			this.removeFeed(deviceId, true);
			const sector = findSectorById(treeData, deviceId);
			if (sector) {
				sector.isMonitoring = false;
			}
		});
		this.props.healthSystemActions.setTreeData(treeData);
	};

	closeCurrentSession = (activateCurrentSession, clickedSession) => {
		const { videoFeeds } = this.state;
		if ((videoFeeds.length === 0 || (clickedSession && !clickedSession.isActive)) && !this.state.currentSessionId) {
			return;
		}

		const feeds = [...videoFeeds];

		this.closeFeedsAndUpdateTree(feeds);
		this.props.healthSystemActions.updateMonitoringDevices({
			inCallDevices: [],
			initiatedDevices: [],
		});
		this.setState({
			currentSessionId: null,
			newSessionName: '',
		});
		if (activateCurrentSession) {
			const currentSession = this.state.monitoringSessions.find(session => session.isCurrent);
			this.setActiveSession(currentSession);
		}
		this.stopBioBeatMeasurements(_.cloneDeep(this.state.videoFeeds));
	};

	setSessionName = event => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	};

	toggleSessionModal = isOpen => {
		this.setState({
			showSessionPopup: isOpen,
		});
	};

	toggleDeleteSessionModal = session => {
		if (session && (session.isCurrent || !session.isActive)) {
			return;
		}
		this.setState(prevState => ({
			showDeleteSessionModal: !prevState.showDeleteSessionModal,
			sessionToDelete: session,
		}));
	};

	deleteSession = async session => {
		const deleteMoitoringSessionRes = await deleteMonitoringSession(session.id);
		if (deleteMoitoringSessionRes.error) {
			this.setState({ error: deleteMoitoringSessionRes.error.message });
			return;
		}
		const monitoringSessions = await getMonitoringSessions();
		if (monitoringSessions.error) {
			this.setState({ error: monitoringSessions.error.message });
			return;
		}

		this.setState({
			monitoringSessions: [...monitoringSessions, this.currentSession],
		});
		if (this.state.currentSessionId === session.id) {
			this.setState({ currentSessionId: null });
		}
		this.stopBioBeatMeasurements(_.cloneDeep(this.state.videoFeeds));
		this.toggleDeleteSessionModal(null);
		this.closeCurrentSession(true);
	};

	onAddDevice = async selection => {
		const maxNumberOfFeeds = 32;
		const { floorId, helloDeviceId } = selection;
		const selectedFeed = this.state.videoFeeds.find(item => item.deviceId === helloDeviceId);
		if (selectedFeed) {
			return;
		}
		if (this.state.videoFeeds.length === maxNumberOfFeeds) {
			return;
		}
		if (selection.isMonitoring) {
			return;
		}
		if (this.state.videoFeeds.length === 0 && !this.state.isPttJoining && !this.state.hasPttJoined) {
			this.setState({ isPttJoining: true, isStartConferenceInProgress: true, error: null });
			const data = await this.callManager.startMonitoring(await this.prepareStartConferenceInfo());
			if (data.response?.failureReason === enums.StartConferenceFailureReasons.GET_INITIATOR_INFO_FAILED) {
				const activeSession = this.state.monitoringSessions.find(s => s.id === this.state.currentSessionId);
				this.closeCurrentSession(true, activeSession);
				this.setState({ error: translate('failedToGetInitiatorInfo'), isPttJoining: false });
				return;
			}
			this.handleCallStarted(data);
			this.ptt
				.join(floorId, this.getSelectedFloor(helloDeviceId)?.conversationId)
				.then(() => {
					this.setState({ isPttJoining: false, hasPttJoined: true });
				})
				.catch(err => {
					// eslint-disable-next-line
					console.warn('Unable to join ptt on feed:', err);
					this.setState(prevState => {
						const feeds = _.cloneDeep(prevState.videoFeeds);
						if (feeds.length > 0) {
							feeds[0].isPttAvailable = false;
						}
						return { ...prevState, videoFeeds: feeds, isPttJoining: false, hasPttJoined: false };
					});
				});

			if (!data.conferenceStarted) {
				return;
			}
		}
		const feed = this.getMappedFeed(helloDeviceId);
		if (this.state.videoFeeds.length === 1) {
			const firstFeed = this.state.videoFeeds[0];
			if (firstFeed.isAlertTimelineVisible) {
				this.toggleAlerts(firstFeed);
			}
		}
		if (feed) {
			this.setState(
				prevState => ({
					videoFeeds: [...prevState.videoFeeds, feed],
				}),
				() => {
					this.queueOrAddFeedOnStartConference(helloDeviceId);
				}
			);
		}
	};

	onFallDetected = async (showFall, feed) => {
		const data = {
			isFallDetected: showFall,
			helloDeviceId: feed.deviceId,
			conferenceId: this.state.conferenceInfo.conferenceId,
			participantId: feed.participantId,
			patientId: null,
			addToHealthMeasurements: false,
		};
		this.callManager.sendFallDetected(data);
	};

	sendCameraEvent = async (eventType, data) => {
		let response;
		switch (eventType) {
			case SocketEvents.HelloDevice.MOVE_CAMERA:
				response = await this.doSendPanTiltCameraEvent(data);
				break;
			case SocketEvents.HelloDevice.COMMAND:
				response = await this.callManager.rebootHuddleCam(data);
				break;
			default:
				response = await this.callManager.sendCameraEvent(eventType, data);
		}

		if (response.deviceControlsLocked) {
			this.setState({ showDeviceControlsLockedModal: true });
		}
		return response;
	};

	doSendPanTiltCameraEvent = async ({ direction, action }) => {
		const { videoFeeds, conferenceInfo } = this.state;
		const expandedFeed = videoFeeds.length === 1 ? videoFeeds[0] : videoFeeds.find(feed => feed.isFeedExpanded);
		if (!expandedFeed) {
			return {};
		}
		const { conferenceId } = conferenceInfo;
		const { participantId, deviceId } = expandedFeed;
		return this.callManager.panTiltCamera({ direction, helloDeviceId: deviceId, action, conferenceId, participantId });
	};

	cameraResponseListener = ({ event, message, isSuccessful, objectId }) => {
		const { videoFeeds } = this.state;
		const selectedFeedIndex = videoFeeds.findIndex(item => item.deviceId === objectId);
		if (selectedFeedIndex === -1) {
			return;
		}
		const [selectedFeed] = videoFeeds.splice(selectedFeedIndex, 1);
		switch (event) {
			case CameraEventTypes.SWITCH:
				if (isSuccessful) {
					selectedFeed.cameraType = message;
					selectedFeed.zoomLevel = 0;
				}
				break;
			case CameraEventTypes.ZOOM:
				selectedFeed.zoomLevel = +message;
				break;
			case CameraEventTypes.TILT: {
				const { disabledTiltDirections } = selectedFeed;
				if (isSuccessful) {
					if ([CameraTiltDirection.UP, CameraTiltDirection.DOWN].includes(message)) {
						disabledTiltDirections[CameraTiltDirection.UP] = false;
						disabledTiltDirections[CameraTiltDirection.DOWN] = false;
					} else {
						disabledTiltDirections[CameraTiltDirection.LEFT] = false;
						disabledTiltDirections[CameraTiltDirection.RIGHT] = false;
					}
				} else {
					disabledTiltDirections[message] = true;
					this.doSendPanTiltCameraEvent({ direction: message, action: CameraTiltAction.STOP });
				}
				selectedFeed.disabledTiltDirections = disabledTiltDirections;
				break;
			}
			case CameraEventTypes.HUDDLE_CONNECTED_STATE:
				selectedFeed.isHuddleCamConnected = isSuccessful;
				break;
			case CameraEventTypes.NIGHT_VISION:
				selectedFeed.isNightVisionActive = isSuccessful;
				break;
			case CameraEventTypes.CAMERA_PRIVACY_STATE: {
				selectedFeed.isCameraPrivacyOn = isSuccessful;
				break;
			}
			case CameraEventTypes.HELLO_MIC_PRIVACY_STATE: {
				selectedFeed.isMicPrivacyOn = isSuccessful;
				break;
			}
			default:
		}
		videoFeeds.splice(selectedFeedIndex, 0, selectedFeed);
		this.setState({ videoFeeds });
		if (!isSuccessful && message) {
			selectedFeed.cameraMessage = message;
		}
	};

	setActiveDevice = (deviceId, cameraType) => {
		const { videoFeeds } = this.state;

		if (!videoFeeds.some(x => x.deviceId === deviceId)) {
			return;
		}

		const feeds = videoFeeds.map(item => {
			if (item.deviceId === deviceId) {
				const newItem = { ...item };
				newItem.cameraType = cameraType;
				newItem.zoomLevel = 0;
				return newItem;
			}
			return item;
		});

		this.setState({
			videoFeeds: feeds,
		});
	};

	getConferenceEndedText = conferenceEndReason => {
		if (conferenceEndReason === ConferenceEndReason.DROPPED) {
			return translate('failedToReconnect');
		}

		if (conferenceEndReason === ConferenceEndReason.TERMINATED_BY_ADMINISTRATOR) {
			return translate('sessionEndedByAdmin');
		}

		if (conferenceEndReason === ConferenceEndReason.CLEANUP) {
			return translate('sessionMaintenance');
		}
		if (conferenceEndReason === ConferenceEndReason.OWNER_LEFT) {
			return translate('sessionDisrupted');
		}

		if (conferenceEndReason === ConferenceEndReason.UNDEFINED) {
			return translate('somethingWentWrong');
		}

		return undefined;
	};

	getVideoFeedIndexByParticipantId = (participantId, videoFeeds) => {
		const participant = this.state.participants[participantId];
		if (!participant) {
			// eslint-disable-next-line no-console
			console.warn('Participant not found for remote ID:', participantId);
			return -1;
		}

		const { objectId } = participant;
		if (!objectId) {
			// eslint-disable-next-line no-console
			console.warn('objectId not set for participant!');
			return -1;
		}

		return videoFeeds.findIndex(item => item.deviceId === objectId);
	};

	onAudioTrackToggled = data => {
		if (!data) {
			return;
		}

		const { participantId, isAudio } = data;
		const { videoFeeds } = this.state;

		const selectedFeedIndex = this.getVideoFeedIndexByParticipantId(participantId, videoFeeds);
		if (selectedFeedIndex === -1) {
			return;
		}

		const [feed] = videoFeeds.splice(selectedFeedIndex, 1);

		feed.isPeerSpeakerActive = isAudio;

		videoFeeds.splice(selectedFeedIndex, 0, feed);

		this.setState({ videoFeeds });
	};

	onLocalAudioError = ({ participantId, trackDeviceNotFound, inputDevices }) => {
		const { videoFeeds } = this.state;

		const selectedFeedIndex = this.getVideoFeedIndexByParticipantId(participantId, videoFeeds);
		if (selectedFeedIndex === -1) {
			return;
		}

		const [feed] = videoFeeds.splice(selectedFeedIndex, 1);

		if (feed.audioTrack.enabled && (!trackDeviceNotFound || !inputDevices.length)) {
			this.props.healthSystemActions.setStreamPermissionMessage({
				component: 'modal',
				type: StreamError.MICROPHONE_NOT_FOUND.type,
			});
		}

		feed.isMyMicActive = false;
		delete feed.audioTrack;

		videoFeeds.splice(selectedFeedIndex, 0, feed);

		this.setState({ videoFeeds });
	};

	onToggleCameraSwitch = feed => {
		const { participantId, deviceId, cameraType, isCameraPrivacyOn } = feed;
		const { conferenceId } = this.state.conferenceInfo;

		const notAllowedToSwitchToHelloCam = cameraType === CameraType.HUDDLE && isCameraPrivacyOn;
		if (notAllowedToSwitchToHelloCam) {
			this.setState({ shouldShowSwitchToHelloCamError: true });
			return;
		}

		this.sendCameraEvent(SocketEvents.HelloDevice.SWITCH_CAMERA, { participantId, conferenceId, helloDeviceId: deviceId });
	};

	toggleVitalSigns = feed => {
		let { videoFeeds } = this.state;
		videoFeeds = videoFeeds.map(videoFeed => ({ ...videoFeed, isVitalSignsVisible: false, isEhrIntegrationVisible: false }));
		const selectedFeed = videoFeeds.find(item => feed.deviceId === item.deviceId);
		selectedFeed.isVitalSignsVisible = !feed.isVitalSignsVisible;
		selectedFeed.isEhrIntegrationVisible = !feed.isEhrIntegrationVisible;
		selectedFeed.isAlertTimelineVisible = false;
		selectedFeed.isLifeSignalsPopupOpen = false;
		this.setState({
			videoFeeds,
			selectedFeed,
		});
	};

	toggleBioBeat = async (deviceId, status) => {
		const { videoFeeds } = this.state;
		const newVideoFeeds = _.cloneDeep(videoFeeds);
		const selectedFeedIndex = newVideoFeeds.findIndex(item => item.deviceId === deviceId);
		const [selectedFeed] = newVideoFeeds.splice(selectedFeedIndex, 1);

		const dataToSend = [
			{
				settingTypeId: PatientsAiSettings.VITAL_SIGNS_AI,
				value: 'false',
			},
		];

		const params = {
			patientId: selectedFeed.deviceOwner?.id,
			deviceId,
			roomId: selectedFeed.roomId,
			sendData: dataToSend,
		};

		const [bioBeatResponse, aiSettingsResponse] = await Promise.all([
			setBioBeatDeviceState({
				taskId: status ? null : selectedFeed.bioBeatTaskId,
				deviceId: selectedFeed.biobeatWearableId,
				state: status ? this.bioBeatDeviceFeedState.ON : this.bioBeatDeviceFeedState.OFF,
				patientUserId: selectedFeed.deviceOwner.userId,
				patientUserGuid: selectedFeed.deviceOwner.id,
			}),
			updatePatientsAiSettings(params),
		]);

		if (aiSettingsResponse.error) {
			this.setState({
				error: aiSettingsResponse.error.message,
			});
		}

		if (bioBeatResponse.error) {
			this.setState({
				error: bioBeatResponse.error.message,
			});
		}

		if (!aiSettingsResponse.error) {
			const aiSettingsList = _.cloneDeep(this.props.aiSettings);
			const found = aiSettingsList.find(item => item.deviceId === deviceId);
			this.props.aiSettingsActions.setPatientAiSettings({
				settings: [
					...found.settings.filter(item => item.settingTypeId !== PatientsAiSettings.VITAL_SIGNS_AI),
					{ settingTypeId: PatientsAiSettings.VITAL_SIGNS_AI, value: 'false' },
				],
				deviceId,
			});
		}

		if (!bioBeatResponse.error) {
			selectedFeed.isBioBeatActive = status;

			selectedFeed.bioBeatTaskId = bioBeatResponse.taskId;

			this.updateLocalBioBeatItems(deviceId, selectedFeed);

			newVideoFeeds.splice(selectedFeedIndex, 0, selectedFeed);
			this.setState({ videoFeeds: newVideoFeeds, selectedFeed });
		}
	};

	toggleLifeSignals = async (deviceId, status) => {
		const videoFeeds = [...this.state.videoFeeds];
		const { selectedFeed } = this.state;
		let selectedObj = { ...selectedFeed };

		const newVideoFeeds = videoFeeds.map(item => {
			if (item.deviceId === deviceId) {
				const newFeed = { ...item };
				newFeed.isLifeSignalsActive = status;
				selectedObj = newFeed;
				return newFeed;
			}
			return item;
		});

		this.context.emit(SocketEvents.Conference.LIFE_SIGNALS_TOGGLE, {
			toggle: status,
			conferenceId: this.state.conferenceInfo?.conferenceId,
			participantId: this.state.conferenceInfo?.participantId,
			actioneeParticipantId: selectedObj.participantId,
			patientId: selectedObj.deviceOwner?.userId,
		});
		this.setState({ videoFeeds: newVideoFeeds, selectedFeed: selectedObj });
	};

	forwardStatAlarmStatusToNurses = async (feed, manualAlertTypeId, comment) => {
		if (feed.deviceOwner) {
			const { hospital, department, floor, room } = this.getHierarchyNaming(feed.deviceId);
			const params = {
				conversationId: this.getSelectedFloor(feed.deviceId)?.conversationId,
				hospital,
				department,
				floor,
				room,
				patientId: feed.deviceOwner?.id,
				deviceId: feed.deviceId,
				manualAlertTypeId,
				comment,
			};
			const response = await forwardManualAlert(params);
			if (response.error) {
				this.setState({ error: response.error.message });
			}
		}
	};

	onAddedManualActivity = result => {
		const videoFeeds = [...this.state.videoFeeds];
		const currentFeed = videoFeeds.find(feed => result.deviceId === feed.deviceId);
		if (!currentFeed) {
			return;
		}
		const nurseStatAlarmGotThisId = 3;
		if (
			(currentFeed?.isStatAlarmActive || currentFeed?.voiceOverAlert) &&
			result.manualAlertActivityTypeId === nurseStatAlarmGotThisId &&
			[ManualAlertTypes.START_STAT_ALARM, ManualAlertTypes.START_VOICE_OVER].includes(result.manualAlertTypeId)
		) {
			const newVideoFeeds = videoFeeds.map(item => {
				if (item.deviceId === result.deviceId) {
					const newItem = { ...item };

					if (result.manualAlertTypeId === ManualAlertTypes.START_STAT_ALARM) {
						newItem.statAlarmConfirmation = translate('stoppedAlarmPopup', { value: result.causerFullName });
					}
					if (result.manualAlertTypeId === ManualAlertTypes.START_VOICE_OVER) {
						newItem.voiceOverAlarmConfirmation = translate('stoppedAlertPopup', { value: result.causerFullName });
					}
					return newItem;
				}
				return item;
			});
			if (result.manualAlertTypeId === ManualAlertTypes.START_STAT_ALARM) {
				this.toggleStatAlarm(currentFeed);
			}
			if (result.manualAlertTypeId === ManualAlertTypes.START_VOICE_OVER) {
				this.toggleVoiceOver(currentFeed, '', false);
			}

			this.setState({
				videoFeeds: newVideoFeeds,
			});
		}
	};

	toggleStatAlarm = feed => {
		if (!feed) {
			return;
		}
		const { conferenceInfo } = this.state;
		this.context.emit(SocketEvents.Conference.ALERT_PATIENT_AT_RISK, {
			conferenceId: conferenceInfo.conferenceId,
			participantId: conferenceInfo.participantId,
			actioneeParticipantId: feed.participantId,
			data: !feed.isStatAlarmActive,
			shouldForward: true,
		});
	};

	shouldAllowForwardStatAlarmToNurses = (currentFeed, result) =>
		!currentFeed.isDefaultOwner && result.shouldForward && (result.data || (!result.data && !currentFeed.statAlarmConfirmation));

	onAlertPatientResponse = result => {
		const videoFeeds = [...this.state.videoFeeds];
		const currentFeed = videoFeeds.find(feed => result.participantId === feed.participantId);
		if (!currentFeed) {
			return;
		}
		if (!result.isSuccessful) {
			this.setState({
				statAlarmError: translate('errorStatAlarm', {
					value: currentFeed.roomName,
				}),
			});
			return;
		}
		const newVideoFeeds = videoFeeds.map(item => {
			if (item.participantId === result.participantId) {
				const newItem = { ...item };
				newItem.isStatAlarmActive = result.data;
				return newItem;
			}
			return item;
		});

		const statusId = result.data ? ManualAlertTypes.START_STAT_ALARM : ManualAlertTypes.STOP_STAT_ALARM;
		if (this.shouldAllowForwardStatAlarmToNurses(currentFeed, result)) {
			this.forwardStatAlarmStatusToNurses(currentFeed, statusId, '');
		}

		this.setState({
			statAlarmError: null,
			...(result.data && { statAlarmConfirmation: '' }),
			videoFeeds: newVideoFeeds,
		});
	};

	closeStatAlarmConfirmation = feed => {
		const videoFeeds = [...this.state.videoFeeds];

		const newVideoFeeds = videoFeeds.map(item => {
			if (item.deviceId === feed.deviceId) {
				const newItem = { ...item };
				newItem.statAlarmConfirmation = null;
				return newItem;
			}
			return item;
		});
		this.setState({
			videoFeeds: newVideoFeeds,
		});
	};

	updateLocalBioBeatItems = (deviceId, selectedFeed) => {
		const getStorageBioBeat = getStorage().activeBioBeatItems ? JSON.parse(getStorage().activeBioBeatItems) : [];
		const foundIndex = getStorageBioBeat.findIndex(item => item.deviceId === selectedFeed.deviceId);

		if (foundIndex !== -1) {
			getStorageBioBeat.splice(foundIndex, 1);
		} else {
			getStorageBioBeat.push({
				deviceId,
				taskId: selectedFeed.bioBeatTaskId,
				patientUserId: selectedFeed.deviceOwner.userId,
				patientUserGuid: selectedFeed.deviceOwner.id,
			});
		}

		getStorage().setItem('activeBioBeatItems', JSON.stringify(getStorageBioBeat));
	};

	onHandleBioBeatData = result => {
		const videoFeeds = _.cloneDeep(this.state.videoFeeds);
		const found = videoFeeds.find(item => result.data.device_id === item.biobeatWearableId);
		if (!found) {
			return;
		}
		const selectedFeedIndex = videoFeeds.findIndex(item => item.deviceId === found.deviceId);
		if (selectedFeedIndex !== -1) {
			const [selectedFeed] = videoFeeds.splice(selectedFeedIndex, 1);
			if (selectedFeed?.isBioBeatActive && selectedFeed?.bioBeatTaskId === result.taskId) {
				const newObject = {
					rr: null,
					spo2: null,
					hr: null,
					hrv: null,
					sbp: null,
					dbp: null,
					sv: null,
					svr: null,
					co: null,
					ci: null,
					temp: null,
				};

				Object.keys(newObject).forEach(key => {
					if (result?.data[key]) {
						newObject[key] = result?.data[key];
					} else {
						newObject[key] = selectedFeed?.bioBeatmesurements?.[key] || null;
					}
				});

				selectedFeed.bioBeatmesurements = newObject;
				selectedFeed.bioBeatLastMeasurement = result?.data.datetime;
				videoFeeds.splice(selectedFeedIndex, 0, selectedFeed);
				this.setState({ videoFeeds });
			}
		}
	};

	handleDevicesList = result => {
		if (result.command === enums.MediaControlsCommands.DEVICES_LIST) {
			const { videoFeeds } = this.state;
			const newVideoFeeds = videoFeeds.map(item => {
				if (item.participantId === result.participantId) {
					const newItem = { ...item };
					newItem.mediaDevices = skipDuplicatedObjects(result.data, 'name');
					return newItem;
				}
				return item;
			});
			this.setState({ videoFeeds: newVideoFeeds });
		}
	};

	collapseTree = () => {
		this.props.healthSystemActions.toggleLeftNavigation();
	};

	onTogglePtz = feed => {
		const { videoFeeds } = this.state;

		const newFeeds = [...videoFeeds].map(item => {
			const newFeed = { ...item };
			if (item.deviceId === feed.deviceId) {
				newFeed.isPtzActive = !feed.isPtzActive;
				newFeed.isVitalSignsVisible = false;
			}
			return newFeed;
		});

		this.setState({ videoFeeds: newFeeds });
	};

	toggleFallPrevention = (status, deviceId) => {
		const { videoFeeds, selectedFeed } = this.state;
		let selectedObj = { ...selectedFeed };
		const newFeeds = [...videoFeeds].map(item => {
			const newFeed = { ...item };

			if (item.deviceId === deviceId) {
				newFeed.isFallPrevention = status;
				selectedObj = newFeed;
			}
			return newFeed;
		});

		this.setState({
			videoFeeds: newFeeds,
			selectedFeed: selectedObj,
		});
	};

	onToggleEmergencyButtons = feed => {
		const { videoFeeds } = this.state;
		const selectedFeedIndex = this.state.videoFeeds.findIndex(item => item.deviceId === feed.deviceId);
		const [selectedFeed] = videoFeeds.splice(selectedFeedIndex, 1);
		selectedFeed.areEmergencyButtonsHiden = !feed.areEmergencyButtonsHiden;
		videoFeeds.splice(selectedFeedIndex, 0, selectedFeed);
		this.setState({ videoFeeds });
	};

	onCallNurses = async (feed, user = null) => {
		if (this.state.isObserverConferenceModalOpen) {
			return;
		}

		let checkedInNurses = [];

		if (!user) {
			const { users, error } = await getCheckedInNurses(feed.floorId);
			if (error) {
				// eslint-disable-next-line no-console
				console.warn('Failed to get checked-in nurses!', error);
			} else {
				checkedInNurses = users;
				await outGoingCallSound();
			}
		} else {
			checkedInNurses.push(user);
		}

		this.setState({
			isObserverConferenceModalOpen: true,
			observerConferenceData: {
				conferenceName: 'Frontline',
				callType: CallTypes.FIRST_RESPONDER,
				participants: checkedInNurses.map(nurse => ({ objectId: nurse.userIntId, objectType: 0, ...nurse })),
				roomId: feed.roomId,
			},
		});
	};

	onObserverConferenceStarted = () => {
		this.setState({ hasObserverConferenceStarted: true });

		// Mute all video feeds when observer is talking to nurse
		const { videoFeeds } = this.state;

		videoFeeds.forEach(feed => {
			if (feed.isMyMicActive) {
				this.toggleMyMicrophone(feed);
			}

			if (feed.isPeerSpeakerActive) {
				this.togglePeerSpeaker(feed);
			}
		});
	};

	onObserverConferenceEnded = () =>
		this.setState({
			isObserverConferenceModalOpen: false,
			hasObserverConferenceStarted: false,
			observerConferenceData: {
				participants: [],
				conferenceName: '',
				callType: null,
				roomId: null,
			},
			incomingConferenceInfo: null,
		});

	getAlertMessageContent = (objectContent, isEvent) => {
		let content = '';
		if (!isEvent && objectContent.attachments.length > 0) {
			content = 'Shared a media.js';
		}
		if (!isEvent && objectContent.attachments.length === 0) {
			content = objectContent.content;
		}
		if (isEvent) {
			content = '';
		}
		return content;
	};

	mapChatAlert = (data, isEvent) => {
		const objectContent = isEvent ? data.event : data.message;
		const objectId = isEvent ? +objectContent?.owner?.objectId : +objectContent?.sender?.objectId;
		if (objectId === this.userInfo.userId) {
			return;
		}
		const selectedChannel = this.state.channels.find(channel => channel.conversationId === data.conversationId);
		if (!selectedChannel) {
			return;
		}
		if (
			selectedChannel.id === this.state.selectedChannel?.id &&
			this.state.isConversationShown &&
			!this.props.healthSystems.isLeftNavigationExpanded
		) {
			return;
		}
		if (selectedChannel && this.userRole === UserRoles.NURSE) {
			this.setState(
				prevState => ({
					unReadMessages:
						selectedChannel.id === prevState.selectedChannel?.id
							? prevState.unReadMessages
							: [...prevState.unReadMessages.filter(msg => msg !== selectedChannel.id), selectedChannel.id],
					chatAlerts: [
						{
							displayName: isEvent ? 'New message' : objectContent.sender.displayName,
							from: `${selectedChannel.team.name} - ${selectedChannel.name}`,
							id: prevState.chatAlerts.length + 1,
							date: moment.utc().local().locale(getPreferredLanguageLocale()).format('hh:mm:ss A'),
							message: this.getAlertMessageContent(objectContent, isEvent),
							profilePicture: isEvent ? null : objectContent.sender.profilePicture,
							channelId: selectedChannel.id,
							isEvent,
						},
						...prevState.chatAlerts.filter(alert => alert.channelId !== selectedChannel.id),
					],
				}),
				() => {
					if (this.chatAlertTimeouts[selectedChannel.id]) {
						clearTimeout(this.chatAlertTimeouts[selectedChannel.id]);
					}
					this.chatAlertTimeouts[selectedChannel.id] = setTimeout(() => {
						this.setState(prevState => ({
							chatAlerts: prevState.chatAlerts.filter(alert => alert.channelId !== selectedChannel.id),
						}));
					}, 10000);
				}
			);
		}
	};

	closeChatAlert = (event, id) => {
		event.stopPropagation();
		this.setState(prevState => ({ chatAlerts: prevState.chatAlerts.filter(item => item.id !== id) }));
	};

	sendToRelevantChannel = ({ channelId, id }) => {
		const selectedChannel = this.state.channels.find(channel => channel.id === channelId);
		if (selectedChannel && [UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN].includes(this.userRole)) {
			this.setState(
				prevState => ({
					selectedChannel,
					isConversationShown: true,
					chatAlerts: prevState.chatAlerts.filter(alert => alert.id !== id),
					unReadMessages:
						selectedChannel.id === prevState.selectedChannel?.id
							? prevState.unReadMessages
							: [...prevState.unReadMessages.filter(msg => msg !== selectedChannel.id)],
				}),
				() => {
					if (this.props.healthSystems.isLeftNavigationExpanded) {
						this.props.healthSystemActions.toggleLeftNavigation();
					}
				}
			);
		}
	};

	onTeamChange = async channelId => {
		const selectedChannel = this.state.channels.find(channel => channel.id === channelId);
		if (selectedChannel) {
			this.setState(prevState => ({
				selectedChannel,
				unReadMessages: [...prevState.unReadMessages.filter(item => item !== selectedChannel.id)],
				chatAlerts: prevState.chatAlerts.filter(alert => alert.channelId !== channelId),
			}));
		}
	};

	getSelectedFloor = deviceId => {
		const { channels } = this.state;
		const newTree = [...this.props.healthSystems.treeData.tree];
		const device = findDeviceById(newTree, deviceId);
		return channels.find(channel => channel.floorId === device?.floorId);
	};

	getSelectedChannel = floorId => {
		const { channels } = this.state;
		return channels.find(channel => channel.floorId === floorId);
	};

	toggleAlerts = feed => {
		this.toggleTreeOnAlertClick(feed, 'isAlertTimelineVisible');
		const videoFeeds = [...this.state.videoFeeds];
		let selectedFeed;
		videoFeeds.forEach(item => {
			if (item.deviceId === feed.deviceId) {
				selectedFeed = item;
				selectedFeed.isAlertTimelineVisible = !feed.isAlertTimelineVisible;
				selectedFeed.isEhrIntegrationVisible = false;
				selectedFeed.isVitalSignsVisible = false;
				selectedFeed.isLifeSignalsPopupOpen = false;
			} else {
				// eslint-disable-next-line no-param-reassign
				item.isAlertTimelineVisible = false;
			}
		});
		this.setState({
			videoFeeds,
			selectedFeed,
		});
	};

	onAiSettingClick = (status, feed) => {
		const { videoFeeds } = this.state;
		const selectedFeedIndex = this.state.videoFeeds.findIndex(item => item.deviceId === feed.deviceId);
		if (selectedFeedIndex === -1) {
			return;
		}
		const [selectedFeed] = videoFeeds.splice(selectedFeedIndex, 1);
		selectedFeed.isAiDetection = status;
		videoFeeds.splice(selectedFeedIndex, 0, selectedFeed);
		this.setState({ videoFeeds });
	};

	callHelloPatient = async ({ deviceId, roomName: conferenceName, roomId }) => {
		if (this.state.isObserverConferenceModalOpen) {
			return;
		}
		if (this.micStatus.state === MediaPermissions.DENIED) {
			this.props.healthSystemActions.setStreamPermissionMessage({
				component: 'modal',
				type: StreamError.MICROPHONE_BLOCKED.type,
				message: 'allowMicPermissions',
			});
			return;
		}
		const foundFeed = this.state.videoFeeds.find(item => item.deviceId === deviceId);
		if (foundFeed?.isPeerSpeakerActive) {
			this.togglePeerSpeaker(foundFeed);
		}
		await outGoingCallSound();
		this.setState({
			isObserverConferenceModalOpen: true,
			observerConferenceData: {
				conferenceName,
				callType: CallTypes.VIDEO,
				participants: [{ objectId: deviceId, objectType: ObjectType.HELLO_DEVICE }],
				roomId,
			},
		});
	};

	onAcceptIncomingCall = incomingConferenceInfo => {
		if (this.state.isObserverConferenceModalOpen) {
			return;
		}

		this.setState({ isObserverConferenceModalOpen: true, incomingConferenceInfo });
	};

	endEmergencyCall = () => {
		this.setState({ isEmergencyCallOpen: false });
	};

	toggleConversation = () => {
		const unReadMessages = [...this.state.unReadMessages];
		let selectedChannel = this.state.selectedChannel ? { ...this.state.selectedChannel } : this.state.channels[0];
		if (unReadMessages.length > 0) {
			const removedElement = unReadMessages.shift();
			selectedChannel = this.state.channels.find(channel => channel.id === removedElement);
		}
		this.setState(prevState => ({
			isConversationShown: !prevState.isConversationShown,
			selectedChannel,
			unReadMessages: prevState.unReadMessages.filter(channelId => channelId !== selectedChannel.id),
		}));
	};

	addIdToInitiatedDevice = deviceId => {
		const { initiatedDevices } = this.props.healthSystems.monitoredDevices;
		this.props.healthSystemActions.updateMonitoringDevices({
			...this.props.healthSystems.monitoredDevices,
			initiatedDevices: [...initiatedDevices, deviceId],
		});
	};

	addIdToInCallDevicesDevice = deviceId => {
		const { inCallDevices } = this.props.healthSystems.monitoredDevices;
		this.props.healthSystemActions.updateMonitoringDevices({
			...this.props.healthSystems.monitoredDevices,
			inCallDevices: [...inCallDevices, deviceId],
		});
	};

	removeIdFromMonitoringDevices = deviceId => {
		const { initiatedDevices, inCallDevices } = this.props.healthSystems.monitoredDevices;
		this.props.healthSystemActions.updateMonitoringDevices({
			inCallDevices: inCallDevices.filter(item => item !== deviceId),
			initiatedDevices: initiatedDevices.filter(item => item !== deviceId),
		});
	};

	toggleRTPSgridView = helloDeviceId => {
		const { videoFeeds } = this.state;
		const selectedFeedIndex = videoFeeds.findIndex(item => item.deviceId === helloDeviceId);
		if (selectedFeedIndex === -1) {
			return;
		}
		const [expandedFeed] = videoFeeds.splice(selectedFeedIndex, 1);
		expandedFeed.isGridView = !expandedFeed.isGridView;
		videoFeeds.splice(selectedFeedIndex, 0, expandedFeed);
		this.setState({ videoFeeds }, () => {
			this.context.emit(SocketEvents.HelloDevice.RTSP_CAMERA_EVENT, {
				helloDeviceId,
				coordinates: { x: 1, y: 1 },
				objectType: ObjectType.HELLO_DEVICE,
				isGridView: expandedFeed.isGridView,
			});
		});
	};

	deviceStatusChanged = async ({ objectId }) => {
		const { videoFeeds } = this.state;
		const newVideoFeeds = [...videoFeeds];
		const feed = { ...newVideoFeeds.find(x => x.deviceId === objectId) };
		if (!feed) {
			return;
		}

		if (feed.audioTrack) {
			feed.audioTrack.stop();
			feed.audioTrack = null;
		}
		feed.isPeerSpeakerActive = false;
		feed.isMyMicActive = false;
		feed.status = ParticipantState.RECONNECTING.type;
		this.setState({ videoFeeds: newVideoFeeds });
	};

	shouldShowPTZ = feed => {
		return (
			(feed.isFeedExpanded || (this.state.videoFeeds.length === 1 && this.state.videoFeeds[0].src)) &&
			!this.state.isVitalSignsVisible &&
			this.state.conferenceInfo &&
			feed.peerConnectionState === RTCPeerConnectionEnum.CONNECTION_STATE.CONNECTED &&
			feed.status === ParticipantState.CONNECTED.type &&
			feed.isPtzActive
		);
	};

	onAnalyticsToastMessageClose = deviceId => {
		const { videoFeeds } = this.state;
		const newVideoFeeds = [...videoFeeds];
		const selectedFeed = newVideoFeeds.find(item => item.deviceId === deviceId);
		if (!selectedFeed) {
			return;
		}
		selectedFeed.analyticsData = {};
		this.setState({ videoFeeds: newVideoFeeds });
		clearTimeout(this.analyticsDataTimeout);
	};

	onAlertInfoClose = (deviceId, isToastMessage = false) => {
		const { videoFeeds } = this.state;
		const newVideoFeeds = [...videoFeeds];
		const selectedFeed = newVideoFeeds.find(item => item.deviceId === deviceId);
		if (!selectedFeed) {
			return;
		}
		if (isToastMessage) {
			selectedFeed.aiToastDetails = {};
		} else {
			selectedFeed.alertInfo = {};
			clearTimeout(this.alertInfoTimeout);
		}
		this.setState({ videoFeeds: newVideoFeeds });
	};

	shouldShowAlertTimeline = () =>
		this.state.selectedFeed &&
		this.state.selectedFeed.isAlertTimelineVisible &&
		this.state.conferenceInfo &&
		!this.state.selectedFeed.isDefaultOwner;

	shouldShowLifeSignalsPopup = () =>
		this.state.selectedFeed &&
		this.state.selectedFeed.isLifeSignalsPopupOpen &&
		this.state.conferenceInfo &&
		!this.state.selectedFeed.isDefaultOwner &&
		this.state.selectedFeed.lifeSignalsWearableId;

	showSessions = () => {
		const adminConfigurableMenu = getConfigurationMenu(this.props.configurations.adminConfigurableMenu, getUserRole());
		const configurableMenu = getConfigurationMenu(this.props.configurations.configurableMenu, getUserRole());
		return (
			!this.hasHsChanged() &&
			adminConfigurableMenu[GeneralSettings.MonitoringSessions]?.value &&
			configurableMenu[GeneralSettings.MonitoringSessions]?.value
		);
	};

	setBiobeatWearableId = (feedDeviceId, biobeatWearableId) => {
		const { videoFeeds } = this.state;
		let selectedFeed;
		const newFeeds = videoFeeds.map(item => {
			const newFeed = { ...item };
			if (item.deviceId === feedDeviceId) {
				newFeed.biobeatWearableId = biobeatWearableId;
				selectedFeed = { ...newFeed };
			}
			return newFeed;
		});
		this.setState({
			videoFeeds: newFeeds,
			selectedFeed,
		});
	};

	toggleLifeSignalsFrame = feed => {
		const videoFeeds = [...this.state.videoFeeds];
		let selectedFeed;
		const newFeeds = videoFeeds.map(item => {
			const newFeed = { ...item };
			if (item.deviceId === feed.deviceId) {
				newFeed.isAlertTimelineVisible = false;
				newFeed.isEhrIntegrationVisible = false;
				newFeed.isVitalSignsVisible = false;
				newFeed.isLifeSignalsPopupOpen = !newFeed.isLifeSignalsPopupOpen;
				selectedFeed = { ...newFeed };
			} else {
				// eslint-disable-next-line no-param-reassign
				newFeed.isLifeSignalsPopupOpen = false;
			}
			return newFeed;
		});

		this.toggleTreeOnAlertClick(feed, 'isLifeSignalsPopupOpen');

		this.setState({
			videoFeeds: newFeeds,
			selectedFeed,
		});
	};

	setLifeSignalsWearableId = (feedDeviceId, lifeSignalsWearableId) => {
		const { videoFeeds } = this.state;
		let selectedFeed;
		const newFeeds = videoFeeds.map(item => {
			const newFeed = { ...item };
			if (item.deviceId === feedDeviceId) {
				newFeed.lifeSignalsWearableId = lifeSignalsWearableId;
				selectedFeed = { ...newFeed };
			}
			return newFeed;
		});
		this.setState({
			videoFeeds: newFeeds,
			selectedFeed,
		});
	};

	shouldShowAlerts = () =>
		this.state.selectedFeed &&
		(this.state.selectedFeed.isAlertTimelineVisible ||
			this.state.selectedFeed.isEhrIntegrationVisible ||
			this.state.selectedFeed.isLifeSignalsPopupOpen) &&
		!this.state.selectedFeed.isDefaultOwner;

	toggleTreeOnAlertClick = (feed, prop) => {
		this.setState({
			shouldNotCloseTreeOnAlert: false,
		});

		if (feed.isDefaultOwner && !this.state.shouldNotCloseTreeOnAlert) {
			this.props.healthSystemActions.toggleLeftNavigationWithValue(false);
			return;
		}

		if (prop === 'isLifeSignalsPopupOpen' && !feed.prop && feed.isAlertTimelineVisible) {
			return;
		}

		if (!feed[prop] && this.props.healthSystems.isLeftNavigationExpanded && !this.state.selectedFeed?.[prop]) {
			this.setState({
				shouldNotCloseTreeOnAlert: true,
			});
			return;
		}
		if (!this.state.shouldNotCloseTreeOnAlert) {
			this.props.healthSystemActions.toggleLeftNavigationWithValue(!feed[prop]);
		}
	};

	onUnassignExternalDevice = data => {
		if (data.patientUserId === this.state.selectedFeed.deviceOwner?.userId) {
			this.toggleLifeSignals(this.state.selectedFeed.deviceId, false);
		}
	};

	handleTreeNodeClick = async option => {
		let isRoomNameEditable = false;
		if (option.type === SectorTypes.ROOM) {
			const response = await getRoomSettings(option.roomId, SettingsCategory.MONITORING);
			if (response.error) {
				this.setState({ error: response.error.message });
			} else {
				isRoomNameEditable =
					this.userRole !== UserRoles.VIRTUAL_SITTER &&
					response.settings.find(config => config.settingTypeId === MonitoringSettings.EditRoomNameMonitoring)?.value === 'true';
			}
		}

		this.setState({
			currentSector: { ...option, isRoomNameEditable },
		});
	};

	toggleVoiceOver = (feed, language, enable) => {
		if (!feed) {
			return;
		}
		const { conferenceInfo } = this.state;
		this.context.emit(SocketEvents.Conference.VOICE_OVER_ALERT, {
			conferenceId: conferenceInfo.conferenceId,
			participantId: conferenceInfo.participantId,
			actioneeParticipantId: feed.participantId,
			enable,
			language,
			type: VoiceOverAlertTypes.GENERIC,
		});
	};

	shouldAllowForwardManualAlertToNurses = (currentFeed, result) =>
		!currentFeed.isDefaultOwner &&
		result.initiator?.id === result.actioneeParticipantId &&
		(result.data || (!result.data && !currentFeed.voiceOverAlarmConfirmation));

	onVoiceOverAlertResponse = result => {
		const videoFeeds = [...this.state.videoFeeds];
		const currentFeed = videoFeeds.find(feed => result.participantId === feed.participantId);
		if (!currentFeed) {
			return;
		}

		const newVideoFeeds = videoFeeds.map(item => {
			if (item.participantId === result.participantId) {
				const newItem = { ...item };
				newItem.voiceOverAlert = result.isEnabled ? this.voiceOverLanguages.find(el => el.value === result.language) : null;
				if (result.isEnabled && result.initiator?.id !== result.actioneeParticipantId) {
					newItem.isVoiceOverOtherInitiator = result.initiator;
				} else {
					newItem.isVoiceOverOtherInitiator = null;
				}
				return newItem;
			}
			return item;
		});
		const statusId = result.isEnabled ? ManualAlertTypes.START_VOICE_OVER : ManualAlertTypes.STOP_VOICE_OVER;
		if (this.shouldAllowForwardManualAlertToNurses(currentFeed, result)) {
			this.forwardStatAlarmStatusToNurses(currentFeed, statusId, '');
		}
		this.setState({
			voiceOverError: null,
			videoFeeds: newVideoFeeds,
		});
	};

	closeVoiceOverAlarmConfirmation = feed => {
		const videoFeeds = [...this.state.videoFeeds];

		const newVideoFeeds = videoFeeds.map(item => {
			if (item.deviceId === feed.deviceId) {
				const newItem = { ...item };
				newItem.voiceOverAlarmConfirmation = null;
				return newItem;
			}
			return item;
		});
		this.setState({
			videoFeeds: newVideoFeeds,
		});
	};

	handleTimerChange = privacyTimer => {
		let timerError = '';

		if (privacyTimer?.value) {
			if (privacyTimer.value < 1 || privacyTimer.value > 60) {
				timerError = this.props.intl.formatMessage({ id: 'writeWholeNumber' });
			}
		}

		this.setState(prevState => ({
			privacyTimer: timerError ? prevState.privacyTimer : privacyTimer,
			timerError,
			privacyModeOptions: _.cloneDeep(privacyModeOptions(this.props.intl)),
		}));
	};

	handleTimerInputChange = value => {
		if (!value || !value.trim()) {
			this.setState({
				privacyModeOptions: _.cloneDeep(privacyModeOptions(this.props.intl)),
			});
		}
	};

	isWholeNumber = inputValue => {
		const number = Number(inputValue);
		return !isNaN(number) && Number.isInteger(number);
	};

	checkIsValidNewOption = inputValue => {
		const number = Number(inputValue);
		let isValid =
			inputValue.trim() !== '' &&
			!isNaN(number) &&
			Number.isInteger(number) &&
			!this.state.privacyModeOptions.find(item => item.value === number);

		if (isValid) {
			let tempOptions = [
				...this.state.privacyModeOptions,
				{ value: number, label: `${number} ${this.props.intl.formatMessage({ id: 'minutes' })}` },
			];

			tempOptions.sort((a, b) => parseInt(a.value) - parseInt(b.value));

			this.setState({ privacyModeOptions: tempOptions });
		}

		return isValid;
	};

	render() {
		const {
			videoFeeds,
			conferenceInfo,
			monitoringSessions,
			isSessionLoading,
			disableButtons,
			hasActiveConference,
			conferenceEndReason,
			privacyModeOptions,
		} = this.state;
		const feeds = videoFeeds;
		const shouldShowConferenceEnded = [
			ConferenceEndReason.DROPPED,
			ConferenceEndReason.TERMINATED_BY_ADMINISTRATOR,
			ConferenceEndReason.UNDEFINED,
			ConferenceEndReason.CLEANUP,
			ConferenceEndReason.OWNER_LEFT,
		].includes(conferenceEndReason);

		return (
			<>
				<MainLayout isMonitoring={true}>
					<audio ref={this.pressToTalkAudioRef} />
					{!this.props.healthSystems.allHealthSystems.length && (
						<Grid width='100%' stretch='100vh' vertAlign='center' horizAlign='center' rows='auto'>
							<Loader />
						</Grid>
					)}
					{this.props.healthSystems.allHealthSystems.length && (
						<Grid
							className={classNames(
								'monitoring-grid',
								this.props.healthSystems.isLeftNavigationExpanded ? 'collapsed-second-column' : ''
							)}
							stretch='100%'>
							<aside
								className={`monitoring-left-side${
									!this.props.healthSystems.isLeftNavigationExpanded ? ' position-relative' : ''
								} ${this.state.isConversationShown ? '' : 'hidden'}`}>
								{this.state.isTeamsLoading && (
									<div className='full-width full-height flex flex-align-center flex-justify-center'>
										<Loader />
									</div>
								)}
								{this.state.channels.length > 0 && !this.state.isTeamsLoading && (
									<>
										<h4 className='monitoring-title'>{translate('monitoring')}</h4>
										<div>
											{!this.props.healthSystems.isLeftNavigationExpanded && (
												<div className='monitoring-switch is-monitoring' onClick={this.toggleConversation}>
													{this.state.unReadMessages.length > 0 && (
														<div className='conversation-label-blue position-absolute'>{this.state.unReadMessages.length}</div>
													)}
												</div>
											)}
											<span
												className={`collapse-second-column${
													this.props.healthSystems.isLeftNavigationExpanded ? ' is-collapsed-active' : ''
												}`}
												onClick={this.collapseTree}>
												<i className='material-icons-outlined'>
													{this.props.healthSystems.isLeftNavigationExpanded ? 'keyboard_arrow_right' : 'keyboard_arrow_left'}
												</i>
											</span>
											<select onChange={event => this.onTeamChange(event.currentTarget.value)}>
												{this.state.channels.map(channel => (
													<option
														key={channel.id}
														selected={this.state.selectedChannel?.id === channel.id}
														value={channel.id}
														className={
															this.state.unReadMessages.findIndex(message => message === channel.id) !== -1 ? 'has-message' : ''
														}>
														{`${channel.team.name} - ${channel.name}`}
													</option>
												))}
											</select>
										</div>
										<Conversation
											conversationId={this.state.selectedChannel?.conversationId}
											selectedTeam={this.state.selectedChannel?.team.id}
											selectedChannelId={this.state.selectedChannel?.id}
											isLeftNavigationExpanded={this.props.healthSystems.isLeftNavigationExpanded}
											hideRightSide={true}
											teamName={`${this.state.selectedChannel?.team.name} - ${this.state.selectedChannel?.name}`}
										/>
									</>
								)}
							</aside>

							<aside
								className={`hello-list hospitals-list monitoring-hospitals-list${
									!this.props.healthSystems.isLeftNavigationExpanded ? ' position-relative' : ''
								} ${!this.state.isConversationShown ? '' : 'hidden'}`}>
								<h4 className='monitoring-title'>{translate('monitoring')}</h4>
								{!this.props.healthSystems.isLeftNavigationExpanded && this.state.channels.length > 0 && (
									<div className='monitoring-switch is-monitoring' onClick={this.toggleConversation}>
										{this.state.unReadMessages.length > 0 && (
											<div className='conversation-label-blue position-absolute'>{this.state.unReadMessages.length}</div>
										)}
									</div>
								)}

								<span
									className={`collapse-second-column${
										this.props.healthSystems.isLeftNavigationExpanded ? ' is-collapsed-active' : ''
									}`}
									onClick={this.collapseTree}>
									<i className='material-icons-outlined'>
										{this.props.healthSystems.isLeftNavigationExpanded ? 'keyboard_arrow_right' : 'keyboard_arrow_left'}
									</i>
								</span>
								{this.showSessions() && (
									<Sessions>
										<h4>{translate('savedSessions')}</h4>
										{this.state.errorFetchingSessions && <ul>{this.state.errorFetchingSessions}</ul>}
										<ul className='monitoring-session-ul'>
											{monitoringSessions.map(session => (
												<li className={session.isActive ? 'active-monitoring-session' : ''} key={session.id}>
													<div onClick={event => this.openSession(event, session)}>
														<img
															src={`${healthCareCdnUrl}monitoring/session${session.isActive ? '-active' : ''}.svg`}
															alt='close_session'
														/>
														<span> {session.name}</span>
													</div>
													{session.isActive && (
														<div className='flex'>
															<div
																className={videoFeeds.length === 0 || !session.isActive ? 'disabled' : ''}
																onClick={() => this.saveSession(session)}
																id='save-session'>
																<img
																	src={`${healthCareCdnUrl}${
																		this.props.user.darkMode ? 'dark-mode/save-gray.svg' : 'monitoring/save.svg'
																	}`}
																	alt='save_session'
																/>
																<img
																	className='active-session-img'
																	src={`${healthCareCdnUrl}monitoring/save-active.svg`}
																	alt='save_session'
																/>
																<span>{translate('save')}</span>
															</div>
															<div
																className={videoFeeds.length <= 1 || !session.isActive ? 'disabled' : ''}
																onClick={() => this.toggleReOrder(session)}
																id='reorder-session'>
																<img
																	src={`${healthCareCdnUrl}${
																		this.props.user.darkMode ? 'dark-mode/sort-gray.svg' : 'monitoring/sort.svg'
																	}`}
																	alt='re-order-session'
																	className='position-relative edit-order-img'
																/>
																<img
																	src={`${healthCareCdnUrl}monitoring/sort-active.svg`}
																	alt='re-order-session'
																	className='position-relative edit-order-img active-session-img'
																/>
																<span>{translate('sort')}</span>
															</div>
															<div
																onClick={() => this.toggleDeleteSessionModal(session)}
																className={session.isCurrent || !session.isActive ? 'disabled' : ''}
																id='delete-session'>
																<img
																	src={`${healthCareCdnUrl}${
																		this.props.user.darkMode ? 'dark-mode/delete-gray.svg' : 'monitoring/trash.svg'
																	}`}
																	alt='delete_session'
																/>
																<img
																	className='active-session-img'
																	src={`${healthCareCdnUrl}monitoring/trash-active.svg`}
																	alt='delete_session'
																/>
																<span>{translate('delete')}</span>
															</div>

															<div
																className={videoFeeds.length === 0 || !session.isActive ? 'disabled' : ''}
																onClick={() => this.closeCurrentSession(true, session)}
																id='close-session'>
																<img
																	src={`${healthCareCdnUrl}${
																		this.props.user.darkMode ? 'dark-mode/remove-gray.svg' : 'monitoring/remove.svg'
																	}`}
																	alt='close_session'
																/>
																<img
																	className='active-session-img'
																	src={`${healthCareCdnUrl}monitoring/remove-active.svg`}
																	alt='close_session'
																/>
																<span>{translate('close')}</span>
															</div>
														</div>
													)}
												</li>
											))}
										</ul>
									</Sessions>
								)}
								<SectorList
									isMonitoring={true}
									isSavedSessionsHidden={!this.showSessions()}
									onAddDevice={this.onAddDevice}
									onTreeViewLinkClick={option => this.handleTreeNodeClick(option)}
								/>
							</aside>
							<div className='users-view'>
								<Grid
									stretch='100%'
									className={classNames(
										this.shouldShowAlerts() ? 'alert-view-on' : 'alert-view-off',
										this.state.selectedFeed?.isLifeSignalsPopupOpen ? 'life-signals-view-on' : ''
									)}>
									<main className='main-view monitoring-view'>
										<section
											className={
												!this.props.healthSystems.isLeftNavigationExpanded
													? 'not-expanded-monitoring-grid'
													: 'expanded-monitoring-grid'
											}>
											{!shouldShowConferenceEnded && (
												<>
													{!hasActiveConference && (
														<>
															{feeds.length !== 0 && !isSessionLoading && (
																<>
																	{getConfigurationValue(
																		this.props.healthSystemConfigurations[MonitoringSettings.EnableMultipleFeedAudio]
																	) && (
																		<div className='monitoring-header audio-monitoring-header'>
																			<div>
																				<i className='material-icons'>volume_up</i>
																				<p>{translate('audioFromMonitoringFeeds')}</p>
																				<div
																					className='rounded-slider-switch'
																					onClick={() => {
																						this.toggleAllSpeakers(!this.state.audioFromMonitoringFeeds);
																					}}>
																					<input type='checkbox' checked={this.state.audioFromMonitoringFeeds} />
																					<span className='rounded-slider' />
																				</div>
																			</div>
																			<button
																				type='button'
																				onClick={this.toggleAllMicrophones}
																				style={{
																					background: this.state.isMicrophoneForAllFeedsEnabled ? 'var(--red-1)' : 'inherit',
																					color: this.state.isMicrophoneForAllFeedsEnabled ? 'var(--gray-0)' : 'inherit',
																				}}>
																				<i className='material-icons'>mic</i>
																				{this.state.isMicrophoneForAllFeedsEnabled
																					? translate('microphoneIsOn')
																					: translate('talkToPatients')}
																			</button>
																		</div>
																	)}
																	<Grid
																		className={classNames(
																			'monitoring-feeds',
																			`feeds-${feeds.length}`,
																			this.state.isFeedZoomed ? 'zoomed-feed' : '',
																			getConfigurationValue(
																				this.props.healthSystemConfigurations[MonitoringSettings.EnableMultipleFeedAudio]
																			)
																				? 'has-enable-audio'
																				: '',
																			feeds.length > 0 && this.props.healthSystems.isLeftNavigationExpanded
																				? 'no-monitoring-list'
																				: '',
																			feeds.length > 1 ? 'feeds-with-basic-icons' : '',
																			feeds.length > 16 ? 'smaller-icons-wrapper' : '',
																			this.shouldShowLifeSignalsPopup() ? 'iframe-is-active' : '',
																			feeds.length > 12 ? 'more-12-feeds' : ''
																		)}
																		gridGap='5px'>
																		{conferenceInfo?.conferenceId &&
																			feeds.map(feed => (
																				<React.Fragment key={feed.deviceId}>
																					<VideoFeed
																						className={feed.isFeedExpanded ? 'expand-feed' : ''}
																						feed={feed}
																						disableButtons={disableButtons}
																						onCloseClick={() => this.removeFeed(feed.deviceId)}
																						onExpandClick={() => this.toggleExpandFeed(feed)}
																						onToggleNightVision={() => this.toggleNightVision(feed)}
																						onToggleMyMicrophone={() => this.toggleMyMicrophone(feed)}
																						onTogglePeerSpeaker={() => this.togglePeerSpeaker(feed)}
																						onToggleWalkieTalkie={this.toggleWalkieTalkie}
																						conferenceInfo={conferenceInfo}
																						callManager={this.callManager}
																						stopVoiceOver={this.stopVoiceOver}
																						toggleVitalSigns={() => this.toggleVitalSigns(feed)}
																						togglePrivacyMode={(deviceId, aiPrivacyStatus) =>
																							this.toggleOrShowPrivacyTimer(deviceId, aiPrivacyStatus)
																						}
																						toggleOffPrivacy={() => this.toggleOffPrivacy(feed.deviceId)}
																						onTogglePtz={() => this.onTogglePtz(feed)}
																						onToggleEmergencyButtons={() => this.onToggleEmergencyButtons(feed)}
																						numberOfFeeds={feeds.length}
																						onCallNurses={user => this.onCallNurses(feed, user)}
																						ptt={this.ptt}
																						participantsTalking={this.state.participantsTalking}
																						hierarchyNaming={this.getHierarchyNaming(feed.deviceId)}
																						floorName={this.getSelectedFloor(feed.deviceId)?.floorName}
																						conversationId={this.getSelectedFloor(feed.deviceId)?.conversationId}
																						toggleAlerts={() => this.toggleAlerts(feed)}
																						channelId={this.getSelectedChannel(feed.floorId)?.id}
																						callHelloPatient={() => this.callHelloPatient(feed)}
																						startEmergencyCall={() => this.setState({ isEmergencyCallOpen: true })}
																						context={this.context}
																						toggleRTPSgridView={this.toggleRTPSgridView}
																						onAiSettingClick={status => this.onAiSettingClick(status, feed)}
																						onSetFeedColor={color => this.setFeedColor(color, feed)}
																						dataAcquisition={this.state.dataAcquisition}
																						careEventFired={this.careEventFired}
																						reAddFeed={deviceId => this.addFeed(deviceId)}
																						aiSettings={this.props.aiSettings}
																						videoFeeds={this.state.videoFeeds}
																						setAiSettings={this.props.aiSettingsActions.setPatientAiSettings}
																						setSnoozeType={this.setAlertSnoozePerFeed}
																						toggleBioBeat={this.state.selectedFeed?.isBioBeatActive ? this.toggleBioBeat : null}
																						toggleLifeSignals={
																							this.state.selectedFeed?.isLifeSignalsActive ? this.toggleLifeSignals : null
																						}
																						watchMeasurementsVisible={this.props.watchMeasurementsVisible}
																						isDarkMode={this.props.user.darkMode}
																						onAlertInfoClose={this.onAlertInfoClose}
																						onAnalyticsToastMessageClose={this.onAnalyticsToastMessageClose}
																						showLifeSignalsIframe={() => this.toggleLifeSignalsFrame(feed)}
																						setIsNoteShowing={val => this.setState({ isNoteShowing: val })}
																						toggleStatAlarm={() => this.toggleStatAlarm(feed)}
																						setAlertToMap={alertToMap => this.setState({ alertToMap })}
																						toggleVoiceOver={this.toggleVoiceOver}
																						closeVoiceOverAlarmConfirmation={() => this.closeVoiceOverAlarmConfirmation(feed)}
																						voiceOverLanguages={this.voiceOverLanguages}
																						closeStatAlarmConfirmation={() => this.closeStatAlarmConfirmation(feed)}
																						showDeviceControlsLockedModal={() => {
																							this.setState({
																								showDeviceControlsLockedModal: true,
																							});
																						}}
																						helloName={this.props.helloName}
																						setActiveDevice={this.setActiveDevice}
																						sendCameraEvent={this.sendCameraEvent}
																						shouldShowPTZ={this.shouldShowPTZ(feed)}
																						showDeviceControlsLockedModalState={this.state.showDeviceControlsLockedModal}
																						toggleFallPrevention={this.toggleFallPrevention}
																					/>
																				</React.Fragment>
																			))}
																	</Grid>
																</>
															)}
															{!isSessionLoading && feeds.length === 0 && (
																<Grid width='100%' rows='auto' vertAlign='center' gridGap='15px' stretch='100%'>
																	<div style={{ textAlign: 'center' }}>
																		{this.state.currentSector?.type === SectorTypes.ROOM && (
																			<>
																				<ChangeRoomName
																					currentRoom={{
																						...this.state.currentSector,
																						sectorName: this.state.currentSector.name,
																					}}
																					setError={msg => this.setState({ error: msg })}
																				/>
																				{!this.state.currentSector.helloDeviceId && (
																					<p>
																						{translate('roomNoDeviceAssigned')}, <br />
																						{translate('roomsNeedDevice')} {translate('monitorRoom')}.
																					</p>
																				)}
																				{this.state.currentSector.helloDeviceId && (
																					<p>
																						{translate('monitorRoom')}, <br />
																						{translate('clickAddIconInTree')}.
																					</p>
																				)}
																			</>
																		)}
																		{this.state.currentSector?.type !== SectorTypes.ROOM && (
																			<p>
																				{translate('monitorRoom')}, <br />
																				{translate('openHospitalChooseDepartment')}, <br />
																				{translate('clickFloorSelectRoom')}.
																			</p>
																		)}
																	</div>
																</Grid>
															)}
															{isSessionLoading && (
																<div className='loading-session'>
																	<p>{translate('loadingSession')}</p>
																	<Loader />
																</div>
															)}
														</>
													)}
													{hasActiveConference && (
														<Grid width='100%' rows='auto' vertAlign='center' gridGap='15px' stretch='100%'>
															<div style={{ textAlign: 'center' }}>
																<p>
																	{translate('alreadyMonitoringPatients')} <br />
																	{translate('tryAgainAfterSessionEnds')}
																</p>
															</div>
														</Grid>
													)}
												</>
											)}
											{shouldShowConferenceEnded && (
												<Grid width='100%' rows='auto' vertAlign='center' gridGap='15px' stretch='100%'>
													<div style={{ textAlign: 'center' }}>
														<p>
															{this.getConferenceEndedText(conferenceEndReason)} <br />
														</p>
													</div>
												</Grid>
											)}

											<Alert
												display={this.state.shouldShowSwitchToHelloCamError}
												onClose={() => {
													this.setState({ shouldShowSwitchToHelloCamError: false });
												}}
												fixed={true}
												persist={true}
												message={translate('youCantSwitchCam', { value: this.props.helloName })}
												variant='dark'
												position='top'
											/>
										</section>
									</main>
									{this.shouldShowAlertTimeline() && (
										<MonitoringAlertTimeline
											onAlertInfoClose={this.onAlertInfoClose}
											key={this.state.selectedFeed?.deviceId}
											onClose={() => this.toggleAlerts(this.state.selectedFeed)}
											deviceId={this.state.selectedFeed.deviceId}
											conferenceId={this.state.conferenceInfo.conferenceId}
											participantId={this.state.selectedFeed.participantId}
											callManager={this.callManager}
											onAiSettingClick={status => this.onAiSettingClick(status, this.state.selectedFeed)}
											deviceDetails={`${this.state.selectedFeed.roomName}${
												!this.state.selectedFeed.deviceOwner?.isOwnerVirtualPatient
													? ` - ${this.state.selectedFeed.deviceOwner?.patientFormattedName}`
													: ''
											}`}
											roomId={this.state.selectedFeed.roomId}
											newEventFired={this.state.newEventFired}
											careEventFired={this.careEventFired}
											roomName={this.state.selectedFeed.roomName}
											isDefaultOwner={this.state.selectedFeed.isDefaultOwner}
											isBioBeatActive={this.state.selectedFeed?.isBioBeatActive || false}
											toggleBioBeat={this.toggleBioBeat}
											toggleLifeSignals={this.toggleLifeSignals}
											isLifeSignalsActive={this.state.selectedFeed?.isLifeSignalsActive || false}
											areEmergencyButtonsHiden={this.state.selectedFeed.areEmergencyButtonsHiden}
											isNoteShowing={this.state.isNoteShowing}
											setLifeSignalsWearableId={this.setLifeSignalsWearableId}
											setBiobeatWearableId={this.setBiobeatWearableId}
											alertToMap={this.state.alertToMap}
											isDarkMode={this.props.user.darkMode}
											isFallPrevention={this.state.selectedFeed.isFallPrevention}
											toggleFallPrevention={this.toggleFallPrevention}
											feed={this.state.selectedFeed}
											stopVoiceOver={this.stopVoiceOver}
										/>
									)}
									{this.state.selectedFeed && this.state.selectedFeed.isEhrIntegrationVisible && (
										<EhrIntegration
											onClose={() => this.toggleVitalSigns(this.state.selectedFeed)}
											patientName={this.state.selectedFeed.deviceOwner?.patientFormattedName}
										/>
									)}
									{this.shouldShowLifeSignalsPopup() && (
										<LifeSignalsPopup
											onClose={() => this.toggleLifeSignalsFrame(this.state.selectedFeed)}
											lifeSignalsWearableId={this.state.selectedFeed.lifeSignalsWearableId}
										/>
									)}
								</Grid>
							</div>
							<Modal
								display={this.state.showDeviceControlsLockedModal}
								position='center'
								closeButtonText={translate('dismiss')}
								primaryButtonLabel=''
								onModalClose={() => {
									this.setState({ showDeviceControlsLockedModal: false });
								}}>
								<Form title={translate('deviceUnavailable')}>
									<p>{translate('virtualCaseProviderIsInCall')}</p>
								</Form>
							</Modal>
							<Modal
								display={this.state.showMaxPeerConnectionsModal}
								position='center'
								closeButtonText={translate('dismiss')}
								primaryButtonLabel=''
								onModalClose={() => {
									this.setState({ showMaxPeerConnectionsModal: false });
								}}>
								<Form title={translate('warning')}>
									<p>{translate('browserPeerConnectionsLimitReached')}</p>
								</Form>
							</Modal>
							<Modal
								display={this.state.showSessionPopup}
								position='center'
								onModalClose={() => this.toggleSessionModal(false)}
								onModalSubmit={() => this.createNewSession(this.state.newSessionName)}>
								<div className='save-session'>
									<h3>{translate('saveSession')}</h3>
									<Input
										type='text'
										onChange={this.setSessionName}
										name='newSessionName'
										value={this.state.newSessionName}
										validationOptions={{}}
										label={translate('sessionName')}
										placeholder={this.props.intl.formatMessage({ id: 'enterSessionName' })}
										maxLength={100}
									/>
								</div>
							</Modal>
							<Modal
								display={this.state.showDeleteSessionModal}
								position='center'
								onModalClose={() => this.toggleDeleteSessionModal(null)}
								onModalSubmit={() => this.deleteSession(this.state.sessionToDelete)}
								primaryButtonLabel={translate('delete')}>
								<Form title={translate('deleteSession')}>
									<p>{translate('sureDeleteSession')}</p>
								</Form>
							</Modal>
							<Modal
								display={this.state.feedToSetPrivacyTimer}
								position='center'
								onModalClose={() => {
									this.setState({
										feedToSetPrivacyTimer: null,
										privacyTimer: null,
									});
								}}
								isSubmitDisabled={!this.state.privacyTimer}
								onModalSubmit={this.onPrivacyTimerSubmitModal}
								className='patient-privacy-modal'>
								<form>
									<h3>{translate('patientPrivacy')}</h3>
									<p>{translate('enablingPrivacyMode')}</p>
									<div>
										<CreatableSelect
											isClearable
											options={privacyModeOptions}
											onInputChange={this.handleTimerInputChange}
											onChange={this.handleTimerChange}
											value={this.state.privacyTimer}
											placeholder={this.props.intl.formatMessage({ id: 'enterSelectTimer' })}
											formatCreateLabel={inputValue => `${inputValue} ${this.props.intl.formatMessage({ id: 'minutes' })}`}
											styles={generateCustomStyles({ darkMode: this.props.user.darkMode })}
											isValidNewOption={inputValue => {
												this.checkIsValidNewOption(inputValue);
											}}
											onKeyDown={e => {
												if (e.key === 'Enter') {
													e.preventDefault();
												}
											}}
										/>
										{this.state.timerError && <span className='red-error'>{this.state.timerError}</span>}
									</div>
								</form>
							</Modal>
							<Modal
								display={shouldShowConferenceEnded}
								position='center'
								onModalClose={() => {
									window.location.reload();
								}}
								hideActionButtons={true}>
								<Form title={this.getConferenceEndedText(conferenceEndReason)}>
									<p>{translate('pleaseRefreshThePage')}</p>
								</Form>
							</Modal>
							<Prompt
								when={this.state.videoFeeds.length > 0}
								message={this.props.intl.formatMessage({ id: 'switchingToOtherTab' })}
							/>
							{this.state.isReorderFeedsVissible && (
								<ReOrderFeeds
									feeds={this.state.videoFeeds}
									toggleReOrder={() => this.toggleReOrder(null)}
									saveReorder={items => this.saveReorder(items)}
								/>
							)}
							{this.state.isSessionNotificationVissible && <Notification text={translate('sessionUpdatedSuccessfully')} />}
							{this.state.isObserverConferenceModalOpen && (
								<ConferenceModal
									startConferenceData={this.state.observerConferenceData}
									incomingConferenceInfo={this.state.incomingConferenceInfo}
									onCallStarted={this.onObserverConferenceStarted}
									onCallEnded={this.onObserverConferenceEnded}
									isEmergencyCallOpen={this.state.isEmergencyCallOpen}
									isDarkMode={this.props.user.darkMode}
								/>
							)}

							{this.state.isEmergencyCallOpen && <EmergencyCall onCallEnded={this.endEmergencyCall} />}
							{this.state.chatAlerts.length > 0 && (
								<ChatAlert
									alerts={this.state.chatAlerts}
									sendToRelevantChannel={alert => this.sendToRelevantChannel(alert)}
									closeChatAlert={(event, id) => this.closeChatAlert(event, id)}
								/>
							)}
						</Grid>
					)}
					{this.props.incomingCallRenderedElsewhere && (
						<div className='monitoring-incoming-call'>
							<IncomingCall onAcceptIncomingCall={this.onAcceptIncomingCall} />
						</div>
					)}
					<ToastMessage
						showToast={this.state.showPrivacyModeError}
						onClose={() => this.setState({ showPrivacyModeError: false })}
						className='feed-toast-message privacy-toast-message'>
						<span>{translate('cannotEnablePrivacyMode')}</span>
					</ToastMessage>
					<Alert display={this.state.error} fixed hideCloseButton message={this.state.error} variant='dark' />
					<Alert
						display={this.state.statAlarmError}
						onClose={() => this.setState({ statAlarmError: null })}
						persist={true}
						message={this.state.statAlarmError}
						variant='dark'
						position='top'
					/>
					<Alert
						display={this.state.voiceOverError}
						onClose={() => this.setState({ voiceOverError: null })}
						persist={true}
						message={this.state.voiceOverError}
						variant='dark'
						position='top'
					/>
					{this.state.streamPermission && <StreamPermissions reason={this.state.streamPermission} />}
				</MainLayout>
			</>
		);
	}
}

const Sessions = styled.div`
	padding: var(--spacing-l) 0;

	+ div {
		padding: 0 !important;
	}

	> h4 {
		margin: 0 0 2px 0;
		padding: 0;
		font-size: 18px;
		font-weight: 700;
	}

	ul {
		margin: var(--spacing-m) 0;
		li {
			div:first-of-type {
				display: flex;
				align-items: center;
				color: var(--gray-5);
				font-size: 14px;

				i {
					margin-right: var(--spacing-s);
					color: var(--gray-4);
					font-size: 18px;
				}

				&:not(:first-of-type) {
					margin-left: 5px;
				}

				&:first-of-type {
					flex: 1;
				}

				img {
					height: 18px;
					margin-right: 5px;
				}
			}

			&:last-of-type {
				div:first-of-type {
					span {
						font-weight: 500;
						margin-bottom: 0;
					}
				}
			}
		}
	}
`;

Monitoring.contextType = SocketContext;

const mapStateToProps = state => {
	return {
		healthSystems: state.healthSystems,
		patients: state.myPatients.patients,
		incomingCallRenderedElsewhere: state.calls.incomingCallRenderedElsewhere,
		user: state.user,
		aiSettings: state.aiSettingsList.aiSettings,
		helloName: state.company.companySettings.helloName,
		configurations: state.configurations,
		healthSystemConfigurations: state.configurations.healthSystemConfigurations,
		watchMeasurementsVisible: state.watchMeasurementsList.watchMeasurementsVisible,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		healthSystemActions: bindActionCreators(healthSystemsActionCreators, dispatch),
		setIncomingCallRenderedElsewhere: isRenderedElsewhere =>
			dispatch(callsActionCreators.setIncomingCallRenderedElsewhere(isRenderedElsewhere)),
		aiSettingsActions: bindActionCreators(aiSettingsActionCreators, dispatch),
		healthDataActions: bindActionCreators(healthDataActionCreators, dispatch),
	};
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Monitoring));
