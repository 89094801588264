import React, { useState, useEffect, useContext, useRef } from 'react';
import queryString from 'query-string';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import ProfilePicture from 'components/ProfilePicture.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';
import { getUserInfo, setUserInfo, getUserId } from 'infrastructure/auth.js';
import { RequestStatus, MeasurementTypes, VisitReasons, RequestType, AssignedRequestTypes } from 'constants/enums.js';
import Pagination from 'components/Common/Pagination.jsx';
import { getMedicAssignedRequests } from 'api/doctorRequests.js';
import { updateAssignRequest } from 'api/patientRequests.js';
import Grid from 'components/Grid.jsx';
import Loader from 'components/Loader.jsx';
import Alert from 'components/Alert.jsx';
import EmptyState from 'components/EmptyState.jsx';
import translate from 'i18n-translations/translate.jsx';
import { convertMeasurementTypes } from 'infrastructure/helpers/measurementsHelper.js';
import { getUserMeasurementUnits } from 'api/measurements.js';
import { formattedDate, getMonthDayYearDateFormat, getLocalTimeHHmmss } from 'infrastructure/helpers/dateHelper.js';
import {
	stringToCamelCase,
	convertSecondsToHoursFormat,
	getAge,
	getGender,
	getRequestType,
	getRequestColor,
} from 'infrastructure/helpers/commonHelpers.js';
import { measurementAlert } from 'constants/alerts.js';
import { StartQueryStringKeys } from 'calls/enums/index.js';
import { actionCreators as userActionCreators } from 'state/user/actions.js';
import { HelloIcon } from 'calls/icons/index.js';

const WaitingRoom = () => {
	const socket = useContext(SocketContext);
	const dispatch = useDispatch();
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0, totalCount: 0 });
	const [errorApiResponse, setErrorApiResponse] = useState('');
	const [requests, setRequests] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [userUnits, setUserUnits] = useState([]);
	const intl = useIntl();
	const [requestsTime, setRequestsTime] = useState({});
	const intervals = useRef([]);
	const waitingRoomCount = useSelector(state => state.user.waitingRoomCount);
	const setUserWaitingRoomCount = count => dispatch(userActionCreators.setUserWaitingRoomCount(count));
	const medicalQuestions = [{ id: 1, question: intl.formatMessage({ id: 'howLongSymptoms' }) }];

	const visitReasons = [
		{
			id: 0,
			value: VisitReasons.INSTRUCTION,
			text: intl.formatMessage({ id: 'instruction' }),
		},
		{
			id: 1,
			value: VisitReasons.THERAPY_DESCRIPTION,
			text: intl.formatMessage({ id: 'therapyDescription' }),
		},
		{
			id: 2,
			value: VisitReasons.CHECK_UP,
			text: intl.formatMessage({ id: 'checkUp' }),
		},
		{
			id: 3,
			value: VisitReasons.RE_CHECK_UP,
			text: intl.formatMessage({ id: 'reCheckUp' }),
		},
		{
			id: 4,
			value: VisitReasons.REFERRAL,
			text: intl.formatMessage({ id: 'referral' }),
		},
		{
			id: 5,
			value: VisitReasons.OTHER,
			text: intl.formatMessage({ id: 'other' }),
		},
	];

	useEffect(() => {
		intervals.current.forEach(item => clearInterval(item));
		requests.forEach(request => {
			setRequestsTime(prevState => ({ ...prevState, [request.id]: getLocalTimeHHmmss(request.dateCreated) }));
			intervals.current = [
				...intervals.current,
				setInterval(() => {
					setRequestsTime(prevState => ({ ...prevState, [request.id]: getLocalTimeHHmmss(request.dateCreated) }));
				}, 60000),
			];
			return () => {
				intervals.current.forEach(item => clearInterval(item));
			};
		});
	}, [requests]);

	const getRequests = async (pageSize, pageIndex) => {
		const params = {
			doctorGuidId: getUserId(),
			pageSize,
			pageIndex,
			type: AssignedRequestTypes.WITHOUT_INTAKE_FORM,
		};
		const response = await getMedicAssignedRequests(params);
		if (response.error) {
			setErrorApiResponse(response.error.message);
			setIsLoading(false);
			return;
		}
		setRequests(response.assignedRequests);
		setPagination({ pageSize, pageIndex, totalCount: response.totalCount });
		setUserWaitingRoomCount(response.totalCount);
		setIsLoading(false);
	};

	const getPreferedUnit = async () => {
		const { error, unitPreferences } = await getUserMeasurementUnits(getUserId());
		if (error) {
			setErrorApiResponse(error.message);
		} else {
			setUserUnits(unitPreferences);
		}
	};

	useEffect(() => {
		const handleRequestUpdated = () => {
			getRequests(pagination.pageSize, pagination.pageIndex);
		};
		getPreferedUnit();
		getRequests(pagination.pageSize, pagination.pageIndex);
		socket.on(SocketEvents.HealthCare.ON_MEDIC_REQUEST_UPDATED, handleRequestUpdated);
		return () => {
			socket.off(SocketEvents.HealthCare.ON_MEDIC_REQUEST_UPDATED, handleRequestUpdated);
		};
	}, [pagination.pageIndex, pagination.pageSize, pagination.totalCount, socket]);

	const joinCall = async item => {
		const { requestConference } = item.request;
		setUserInfo({ ...getUserInfo(), incomingCallsDisabled: true });
		const queryParams = queryString.stringify(
			{
				[StartQueryStringKeys.CONFERENCE_ID]: requestConference.conferenceId,
				assignedRequestId: item.id,
			},
			{
				skipNull: true,
			}
		);
		window.open(`/call?${queryParams.toString()}`, '_blank');
		setRequests(prevState => prevState.filter(request => request.id !== item.id));
		setUserWaitingRoomCount(waitingRoomCount - 1);
	};

	const rejectRequest = async item => {
		const { requestConference } = item.request;
		const response = await updateAssignRequest(item.id, {
			RequestStatusId: RequestStatus.REJECTED,
		});
		if (response.error) {
			setErrorApiResponse(response.error.message);
			return;
		}
		if (requestConference) {
			const { conferenceId, creatorId } = requestConference;
			socket.emit(
				SocketEvents.Conference.END,
				{
					conferenceId,
					participantId: creatorId,
				},
				() => {}
			);
		}
		setRequests(prevState => prevState.filter(request => request.id !== item.id));
		setUserWaitingRoomCount(waitingRoomCount - 1);
	};

	const getCategoryPreference = categoryId => userUnits.find(item => item.unitCategoryId === categoryId);

	const getUnitPreference = categoryId => {
		const selectedPreference = getCategoryPreference(categoryId);
		return selectedPreference?.options.find(item => item.unitSystemId === selectedPreference.unitSystemId);
	};

	const getMeasurementValue = (measurement, foundMeasurement) => {
		let val = measurement.measurementValue;

		if (measurement.measurementType === MeasurementTypes.SLEEP) {
			val = convertSecondsToHoursFormat(measurement.measurementValue);
		}

		return !foundMeasurement.unitCategoryId
			? val
			: convertMeasurementTypes(
					foundMeasurement.unitCategoryId,
					measurement.measurementValue,
					getUnitPreference(foundMeasurement.unitCategoryId)?.unitSystemId
			  );
	};

	const getMeasurementUnit = measurement =>
		measurement.unitCategoryId ? getUnitPreference(measurement.unitCategoryId)?.unit : measurement.unit;

	const renderRequest = item => {
		const { patient, description, requestReason, medicalQuestionAnswers, requestSymptoms, requestConference } = item.request;
		const unavailableDateOfBirth = '0001-01-01T00:00:00';
		return (
			<div key={item.id} className={item.request?.requestType === RequestType.RAPID ? 'rapid-request-type' : ''}>
				<div className='flex flex-align-center'>
					<ProfilePicture
						firstName={patient.firstName}
						lastName={patient.lastName}
						className='doctor-request-img'
						profilePicture={patient.profilePicture}
					/>
					<div className='flex-1 left-15'>
						<h4>
							{patient.firstName} {patient.lastName}
						</h4>
						<span className='--blue-color'>
							{item.request?.location}
							<br />
						</span>
						{patient.dateOfBirth !== unavailableDateOfBirth && (
							<span className='--blue-color'>
								{getMonthDayYearDateFormat(patient.dateOfBirth)} ({translate('age')}: {getAge(patient.dateOfBirth)}),{' '}
							</span>
						)}
						{patient.genderId !== 0 && <span className='--blue-color'>{getGender(patient.genderId).description}</span>}
					</div>
					<div className={`request-type ${getRequestType(item.request?.requestType)}`}>
						<HelloIcon color={getRequestColor(item.request?.requestType)} />
						<span>{translate(getRequestType(item.request?.requestType))}</span>
					</div>
					{requestConference && (
						<button type='button' onClick={() => joinCall(item)}>
							<img src={`${healthCareCdnUrl}workflow/start-call.svg`} alt='start-call-icon' />
							{translate('joinCall')}
						</button>
					)}
					<button onClick={() => rejectRequest(item)} type='button' className='reject'>
						<i className='material-icons-outlined'>cancel</i>
						{translate('rejectRequest')}
					</button>
				</div>
				{requestReason && (
					<div className='top-15'>
						<h4>{translate('reasonOfVisit')}</h4>
						<p className='break-word'>{visitReasons?.find(reason => reason.value === requestReason)?.text}</p>
					</div>
				)}

				{medicalQuestionAnswers &&
					medicalQuestionAnswers.length > 0 &&
					medicalQuestionAnswers.map(medicalQuestion => (
						<div className='top-15'>
							<h4>{medicalQuestions?.find(question => question.id === medicalQuestion.medicalQuestionId)?.question}</h4>
							<p className='break-word'>{medicalQuestion.answer}</p>
						</div>
					))}
				{description && (
					<div className='top-15'>
						<h4>{translate('healthConcern')}</h4>
						<p className='break-word'>{description}</p>
					</div>
				)}
				<div className='top-15'>
					{requestSymptoms.length > 0 && (
						<>
							<h4>{translate('symptoms')}</h4>
							<div className='flex symptoms-grid flex-wrap'>
								{requestSymptoms.map(element => {
									const translated = intl.formatMessage({ id: stringToCamelCase(element.symptom.name) });
									return <div key={item.id}>{translated}</div>;
								})}
							</div>
						</>
					)}
				</div>
				<div className='flex top-30 flex-align-center'>
					<div className='flex-1'>
						{item.latestMeasurements && item.latestMeasurements.length > 0 && (
							<>
								<div className='latest-health-data'>
									<button
										type='button'
										onClick={() =>
											setRequests(prevState =>
												[...prevState].map(req => {
													const newItem = { ...req };
													if (newItem.id === item.id) {
														newItem.isExpanded = !newItem.isExpanded;
													}
													return newItem;
												})
											)
										}>
										<i className={classNames('material-icons', item.isExpanded ? ' rotate' : '')}>expand_more</i>
										{translate(item.isExpanded ? 'collapseLatestHealthData' : 'expandLatestHealthData')}
									</button>
								</div>
								{item.isExpanded && (
									<div className='flex flex-wrap health-data-small-grid'>
										{item.latestMeasurements.map(measurement => {
											const foundMeasurement = measurementAlert.find(el => el.type === measurement.measurementType);
											const measurementType =
												measurement.measurementType === MeasurementTypes.DIABETES
													? MeasurementTypes.BLOOD_GLUCOSE
													: measurement.measurementType;
											return (
												<div key={measurement.id}>
													{foundMeasurement?.icon && <img src={foundMeasurement.icon} alt='ico' />}

													<p>{translate(measurementType)}</p>

													<div className='flex flex-align-center'>
														<h4 className='line-height-1'>{getMeasurementValue(measurement, foundMeasurement)}</h4>
														{measurement.measurementType !== MeasurementTypes.SLEEP && (
															<span className='break-word'>{getMeasurementUnit(foundMeasurement)}</span>
														)}
													</div>
													<span>{formattedDate(measurement.endDate)}</span>
												</div>
											);
										})}
									</div>
								)}
							</>
						)}
					</div>
					<div className='flex-end column-direction text-align-right request-time'>
						<h5>{requestsTime[item.id]}</h5>
					</div>
				</div>
			</div>
		);
	};

	const hasRequestConference = requests.some(item => item.request?.requestConference);
	return (
		<MainLayout>
			<div className='view-page-wrapper'>
				{!isLoading && requests.length > 0 && (
					<div
						className={`waiting-room-view-inner ${requests.every(item => !item.request?.requestConference) ? '' : 'full-width'}`}>
						{hasRequestConference && (
							<div className='flex home-title'>
								<h3>
									{translate('youHaveLengthPatients', {
										length: requests.length,
										patients: intl.formatMessage({ id: requests.length === 1 ? 'onePatient' : 'multiplePatients' }).toLowerCase(),
									})}
								</h3>
							</div>
						)}
						<div className='waiting-room-list'>
							{requests.filter(item => item.request?.requestConference).map(request => renderRequest(request))}
						</div>
						{hasRequestConference && (
							<Pagination
								totalCount={pagination.totalCount}
								pageSize={pagination.pageSize}
								pageIndex={pagination.pageIndex}
								onChange={(pageSize, pageIndex) => {
									setIsLoading(true);
									getRequests(pageSize, pageIndex);
								}}
							/>
						)}
					</div>
				)}
				{!isLoading && (requests.length === 0 || requests.every(item => !item.request?.requestConference)) && (
					<div className='empty-state-wrapper'>
						<EmptyState title={translate('noRequestsFound')} image='no-requests.svg' />
					</div>
				)}
				{isLoading && (
					<Grid columns='1fr' rows='1fr' stretch='calc(100vh - 200px)' horizAlign='center' vertAlign='center'>
						<div style={{ textAlign: 'center' }}>
							<Loader />
						</div>
					</Grid>
				)}
				<Alert display={errorApiResponse} fixed={true} hideCloseButton={true} message={errorApiResponse} variant='dark' />
			</div>
		</MainLayout>
	);
};

export default WaitingRoom;
