import { gatewayApi } from 'constants/global-variables.js';
import { DeviceSettings } from 'constants/enums.js';
import { getCompanyId } from 'infrastructure/auth.js';
import { buildParamsFromArray } from 'infrastructure/helpers/commonHelpers.js';

const companyId = getCompanyId();

export const getDeviceList = async (level, id) => {
	try {
		const { data } = await gatewayApi.get(`/v1/organizations/${companyId}/level/${level}/id/${id}/devices`);
		return data.devices;
	} catch (error) {
		return { error };
	}
};

export const getMultipleLevelsDevices = async ({
	currentPage,
	pageSize,
	search = null,
	levelIds = [],
	levelType = null,
	isOnline = null,
	fromDate = null,
	toDate = null,
	connectionType = null,
}) => {
	const params = { currentPage, pageSize, search, levelType, isOnline, fromDate, toDate, connectionType };
	try {
		const { data } = await gatewayApi.get(
			`/v1/organizations/${companyId}/devices?${buildParamsFromArray('levelIds', levelIds, true)}`,
			{ params }
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export function getUnassociatedDevices() {
	return new Promise((res, rej) => {
		gatewayApi
			.get(`/v1/organizations/${companyId}/unassociated-devices`)
			.then(response => res(response.data.devices))
			.catch(e => rej(e));
	});
}

export function deleteDevice(deviceId) {
	return new Promise((res, rej) => {
		gatewayApi
			.delete(`/v1/organizations/${companyId}/devices/${deviceId}`)
			.then(response => res(response.data.devices))
			.catch(e => rej(e));
	});
}

export function getMyDoctorsAndDevices(searchText) {
	const url = `v1/healthcare/doctors-devices${searchText ? `?searchText=${searchText}` : ''}`;
	return new Promise((res, rej) => {
		gatewayApi
			.get(url)
			.then(response => {
				res(response.data);
			})
			.catch(e => rej(e));
	});
}

export function getDeviceDetails(deviceId) {
	return new Promise((res, rej) => {
		gatewayApi
			.get(`v1/devices/${deviceId}/detail`)
			.then(response => res(response.data))
			.catch(e => rej(e));
	});
}

export const getDeviceSettings = async deviceId => {
	try {
		const { data } = await gatewayApi.get(`/v1/organizations/${companyId}/devices/${deviceId}/settings`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getDeviceSetting = async (deviceId, settingTypeId) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/organizations/${companyId}/devices/${deviceId}/settings?settingTypeId=${settingTypeId}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const updateDeviceSettings = async ({ deviceId, settingTypeId, settingValue }) => {
	try {
		const { data } = await gatewayApi.put(`/v1/organizations/${companyId}/devices/${deviceId}/settings`, {
			settingTypeId,
			settingValue,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const sendCommand = async ({ deviceId, command, reason = null, dynamicData = null }) => {
	try {
		const { data } = await gatewayApi.post(`/v1/devices/${deviceId}/command`, {
			command,
			reason,
			dynamicData,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const updatePatientDeviceOwner = async ({ userId, deviceName, deviceId }) => {
	try {
		const { data } = await gatewayApi.put(`/v1.1/healthcare/organizations/${companyId}/devices/${deviceId}/owner`, {
			userId,
			deviceName,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const deletePatientDeviceOwner = async deviceId => {
	try {
		const { data } = await gatewayApi.delete(`/v1.1/healthcare/organizations/${companyId}/devices/${deviceId}/owner`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getDeviceRebootLogs = async deviceId => {
	try {
		const { data } = await gatewayApi.get(`/v1/devices/${deviceId}/logs/type/command`);
		return data.deviceCommandLogs;
	} catch (error) {
		return { error };
	}
};

export const addDeviceSettings = async settings => {
	try {
		const { data } = await gatewayApi.post(`/v1/organizations/${getCompanyId()}/device-settings`, {
			settings,
			settingTypeId: DeviceSettings.VoiceCommandCalling,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const addUpdateCompanionDevice = async ({ solHelloDeviceId, roomName, hospitalId, departmentId, floorId, roomId }) => {
	try {
		const { data } = await gatewayApi.put(
			`/v1/devices/companion/${companyId}/hospitals/${hospitalId}/departments/${departmentId}/floors/${floorId}/rooms/${roomId}/location`,
			{
				solHelloDeviceId,
				roomName,
			}
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const deleteCompanionDevice = async deviceId => {
	try {
		const { data } = await gatewayApi.delete(`/v1/devices/companion/${companyId}/devices/${deviceId}/location`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getCompanionDevicesByRoom = async (roomId, companionType) => {
	try {
		const { data } = await gatewayApi.get(`/v1/devices/companion/${companyId}/rooms/${roomId}?companionModeId=${companionType}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getUnassignedCompanionDevices = async companionType => {
	try {
		const { data } = await gatewayApi.get(`/v1/devices/companion/${companyId}?unassigned=true&companionModeId=${companionType}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getDevicesFromMRN = async mrnId => {
	try {
		const { data } = await gatewayApi.get(`v1.1/healthcare/organizations/${getCompanyId()}/patients/${mrnId}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getCompanyDeviceOwner = async deviceId => {
	try {
		const { data } = await gatewayApi.get(`v1/organizations/${getCompanyId()}/devices/${deviceId}/owner`);
		return data;
	} catch (error) {
		return { error };
	}
};
