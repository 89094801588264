import React, { useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import translate from 'i18n-translations/translate.jsx';
import Form from 'components/Form.jsx';
import PopUpAlert from 'components/PopUpAlert.jsx';
import { AlertTypes } from 'constants/enums.js';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from 'components/Tabs.jsx';
import CreateEditFeatureFlags from 'containers/CreateEditFeatureFlags.jsx';

const CreateEditCompanyFeaturesFlags = props => {
	const intl = useIntl();
	const [alertType, setAlertType] = useState(null);
	const [currentTab, setCurrentTab] = useState(0);
	const sectionRef = useRef(null);

	const handleNextStepButton = () => {
		if (currentTab + 1 === props.tabsList.length) {
			props.goToNextTab();
		} else {
			sectionRef.current.scrollTop = 0;
			setCurrentTab(prevState => prevState + 1);
		}
	};

	const handleGoBack = () => {
		if (currentTab === 0) {
			props.goToPreviousTab();
		} else {
			sectionRef.current.scrollTop = 0;
			setCurrentTab(prevState => prevState - 1);
		}
	};

	return (
		<div ref={sectionRef} className='create-company-wrapper create-company-settings create-hs feature-flags'>
			<Form>
				<h3>{translate(props.title)}</h3>
				{props.description && <p>{translate(props.description)}</p>}
				<Tabs
					className='create-edit-feature-flags'
					isVertical={false}
					activeIndex={currentTab}
					onChange={index => setCurrentTab(index)}>
					<TabList>
						{props.tabsList.map(item => (
							<Tab key={item.id}>{item.title}</Tab>
						))}
					</TabList>
					<TabPanels>
						{props.panelsList.map(item => (
							<TabPanel key={item.settingCategory}>
								<CreateEditFeatureFlags
									setDataToSubmit={featureFlags => props.setDataToSubmit(featureFlags)}
									setError={props.setError}
									selectedCompany={props.selectedCompany}
									goToNextTab={handleNextStepButton}
									goToPreviousTab={handleGoBack}
									dataToSubmit={props.dataToSubmit}
									settingCategory={item.settingCategory}
									featureTypes={item.featureTypes}
									typeTitle={item.typeTitle}
									categoryOrder={item.categoryOrder}
									cameraNames={props.cameraNames}
								/>
							</TabPanel>
						))}
					</TabPanels>
				</Tabs>
			</Form>
			<PopUpAlert
				alertType={alertType}
				display={alertType}
				onAlertClose={() => setAlertType(null)}
				contentText={intl.formatMessage({ id: alertType === AlertTypes.SUCCESS ? 'changesSaved' : 'somethingWentWrong' })}
				isSilent={true}
				center={true}
				selfCloseTimeOut={1500}
			/>
		</div>
	);
};

export default CreateEditCompanyFeaturesFlags;
