import React from 'react';
import styled, { css } from 'styled-components';
import _ from 'lodash';
import LightTheme from 'calls/styles/LightTheme.js';

import { useConferenceConfigurations, useConferenceParticipants, useLocalParticipant } from 'calls/hooks/index.js';
import RemoteParticipantView from 'calls/views/RemoteParticipantView.jsx';
import ParticipantProvider from 'calls/ParticipantProvider.jsx';
import LocalParticipantView from 'calls/views/LocalParticipantView.jsx';
import { isMedicalInfoModalsOpen } from 'calls/helpers/index.js';
/**
 * @type {import('styled-components').StyledComponent<"aside", any, { isMinimizedView: boolean, isGridView: boolean, isParticipantsViewHidden: boolean, isPatientMeasurementsOpen: boolean, isMedicalInfoOpen: boolean, isInviteParticipantsModalViewOpen: boolean }, never>}
 */
const StyledParticipantWrapper = styled.aside`
	${props =>
		!props.isGridView &&
		props.isParticipantsViewHidden &&
		`
		display: none;
	`}

	${props =>
		props.isPatientMeasurementsOpen &&
		css`
			@media (max-width: 769px) {
				display: none;
			}
		`}

	${props =>
		!props.isGridView &&
		css`
			position: absolute;
			top: ${LightTheme.spacing[5]}px;
			right: ${LightTheme.spacing[5]}px;
			overflow: auto;
			width: 150px;
			z-index: 1;
		`}

		${props =>
		!props.isGridView &&
		props.isEmbeddedView &&
		css`
			z-index: 10;
		`}

		${props =>
		!props.isGridView &&
		props.isRightToLeft &&
		props.isPatientMeasurementsOpen &&
		css`
			left: calc(50% + ${LightTheme.spacing[2]}px);
			right: auto;
		`}

		${props =>
		!props.isGridView &&
		props.isPatientMeasurementsOpen &&
		css`
			position: absolute;
			top: ${LightTheme.spacing[5]}px;
			right: calc(50% + ${LightTheme.spacing[2]}px);
			overflow: auto;
			width: 150px;
			z-index: 1;
		`}
		
		${props =>
		!props.isGridView &&
		(props.isMedicalInfoOpen || props.isInviteParticipantsModalViewOpen) &&
		css`
			position: absolute;
			top: ${LightTheme.spacing[5]}px;
			right: calc(30% + ${LightTheme.spacing[2]}px);
			overflow: auto;
			width: 150px;
			z-index: 1;
		`}

		${props =>
		!props.isGridView &&
		props.isRightToLeft &&
		(props.isMedicalInfoOpen || props.isInviteParticipantsModalViewOpen) &&
		css`
			left: calc(30% + ${LightTheme.spacing[2]}px);
			right: auto;
		`}

		${props =>
		!props.isGridView &&
		props.isRightToLeft &&
		!props.isPatientMeasurementsOpen &&
		css`
			left: ${LightTheme.spacing[5]}px;
			right: auto;
		`}

	${props =>
		props.isGridView &&
		css`
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			background: ${LightTheme.colors.grayFive};
			height: 100vh;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;

			> section {
				display: grid;
				> div {
					margin: 0;
					box-shadow: none;
					border-radius: 0;
					> header {
						position: absolute;
						width: 100%;
						bottom: 0;
						left: 0;
						background: linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.5) 33%, rgba(0, 0, 0, 0) 100%);
						z-index: 2;
						padding: ${LightTheme.spacing[3]}px;
						border: none;

						p {
							color: var(--gray-0);
							text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);

							> span {
								&:last-of-type {
									color: var(--gray-0);
								}
							}
						}
					}

					> main {
						background: ${LightTheme.colors.graySix};
					}
				}

				${!props.isMinimizedView &&
				css`
					@media (min-width: 768px) {
						&.grid-2,
						&.grid-3,
						&.grid-4 {
							grid-template-columns: repeat(2, 1fr);
						}

						&.grid-2 > div {
							width: calc(16 / 9 * 100vh);
							max-width: 50vw;
							height: calc(9 / 16 * 50vw);
							max-height: 100vh;
						}

						&.grid-3 > div:last-of-type {
							left: 50%;
						}

						&.grid-3 > div,
						&.grid-4 > div {
							width: calc(16 / 9 * 50vh);
							max-width: 50vw;
							height: calc(9 / 16 * 50vw);
							max-height: 50vh;
						}

						&.grid-5,
						&.grid-6 {
							grid-template-columns: repeat(3, 1fr);
						}

						&.grid-5 > div,
						&.grid-6 > div {
							width: calc(16 / 9 * 50vh);
							max-width: 33.333vw;
							height: calc(9 / 16 * 33.333vw);
							max-height: 50vh;
						}

						&.grid-7,
						&.grid-8,
						&.grid-9 {
							grid-template-columns: repeat(3, 1fr);
						}

						&.grid-7 > div,
						&.grid-8 > div,
						&.grid-9 > div {
							width: calc(16 / 9 * 33.333vh);
							max-width: 33.333vw;
							height: calc(9 / 16 * 33.333vw);
							max-height: 33.333vh;
						}

						&.grid-10,
						&.grid-11,
						&.grid-12,
						&.grid-13,
						&.grid-14,
						&.grid-15,
						&.grid-16 {
							grid-template-columns: repeat(4, 1fr);
						}

						&.grid-10 > div,
						&.grid-11 > div,
						&.grid-12 > div {
							width: calc(16 / 9 * 33.333vh);
							max-width: 25vw;
							height: calc(9 / 16 * 25vw);
							max-height: 33.333vh;
						}

						&.grid-13 > div,
						&.grid-14 > div,
						&.grid-15 > div,
						&.grid-16 > div {
							width: calc(16 / 9 * 25vh);
							max-width: 25vw;
							height: calc(9 / 16 * 25vw);
							max-height: 25vh;
						}
					}
				`}

				${props.isMinimizedView &&
				css`
					width: 100%;
					grid-template-columns: repeat(2, 1fr);
					margin: auto;
					padding: ${LightTheme.spacing[5]}px;
					grid-gap: ${LightTheme.spacing[3]}px;
				`}

				@media (max-width: 768px) {
					width: 100%;
					grid-template-columns: repeat(2, 1fr);
					margin: auto;
					padding: ${LightTheme.spacing[5]}px;
					grid-gap: ${LightTheme.spacing[3]}px;
				}
			}

			@media (max-width: 768px) {
				overflow: auto;
			}

			${props.isMinimizedView &&
			css`
				overflow: auto;
			`}
		`}
`;

/**
 * @param {object} props
 * @param {import('calls/LocalParticipant.js').default | import('calls/RemoteParticipant.js').default} props.mainParticipant Local or remote participant
 * @param {(...args: any)=>void} [props.setMainParticipant] Set main participant callback
 * @param {(...args: any)=>void} [props.setActiveTrackType] Set main participant active track type callback
 */
const ParticipantsView = ({ mainParticipant, setMainParticipant, setActiveTrackType }) => {
	const conferenceConfigs = useConferenceConfigurations();
	const localParticipant = useLocalParticipant();
	const conferenceParticipants = useConferenceParticipants();

	return (
		<StyledParticipantWrapper
			isMinimizedView={conferenceConfigs.isMinimizedView}
			isGridView={conferenceConfigs.isGridView}
			isParticipantsViewHidden={conferenceConfigs.isParticipantsViewHidden}
			isPatientMeasurementsOpen={
				conferenceConfigs.isLiveExaminationOpen ||
				conferenceConfigs.isPatientHistoryOpen ||
				conferenceConfigs.isTelemetryModalOpen
			}
			isRightToLeft={conferenceConfigs.isRightToLeft}
			isMedicalInfoOpen={isMedicalInfoModalsOpen(conferenceConfigs.medicalDataControls)}
			isEmbeddedView={conferenceConfigs.isEmbeddedView}
			isInviteParticipantsModalViewOpen={conferenceConfigs.isInviteParticipantsModalViewOpen}>
			<section className={`grid-${conferenceParticipants.length + 1} `}>
				<LocalParticipantView
					mainParticipant={mainParticipant}
					onClick={trackType => {
						if (_.isFunction(setMainParticipant)) {
							setMainParticipant(localParticipant);
						}
						if (_.isFunction(setActiveTrackType)) {
							setActiveTrackType(trackType);
						}
					}}
				/>
				{conferenceParticipants.map(participant => (
					<ParticipantProvider key={participant.id} participantData={participant}>
						<RemoteParticipantView
							mainParticipant={mainParticipant}
							onClick={trackType => {
								if (_.isFunction(setMainParticipant)) {
									setMainParticipant(participant);
								}
								if (_.isFunction(setActiveTrackType)) {
									setActiveTrackType(trackType);
								}
							}}
						/>
					</ParticipantProvider>
				))}
			</section>
		</StyledParticipantWrapper>
	);
};

export default ParticipantsView;
