import React, { useEffect, useState } from 'react';
import translate from 'i18n-translations/translate.jsx';
import classNames from 'classnames';
import { EditIcon } from 'icons/Monitoring/index.js';
import { useIntl } from 'react-intl';
import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';
import Textarea from 'components/Textarea.jsx';
import SpeechToText from 'SpeechToText.jsx';

const SafetyAlerts = props => {
	const [expanded, setExpanded] = useState(true);
	const [showEditForm, setShowEditForm] = useState(false);
	const [recognizedTranscription, setRecognizedTranscription] = useState('');
	const [liveTranscription, setLiveTranscription] = useState('');
	const [isSpeechToText, setIsSpeechToText] = useState(false);

	const intl = useIntl();

	useEffect(() => {
		setRecognizedTranscription(props.data);
	}, [props.data]);

	return (
		<>
			<div className={classNames('timeline-box-header sub-timeline-box-header', expanded ? 'expanded' : '')}>
				<p className='timeline-box-title'>{translate('safetyPatientNeeds')}</p>
				<div className='timeline-box-actions'>
					<button
						type='button'
						onClick={() => {
							setShowEditForm(prevState => !prevState);
							setExpanded(true);
						}}>
						<EditIcon color={props.isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayThree} />
					</button>
					<button type='button' onClick={() => setExpanded(prevState => !prevState)}>
						<i className={classNames('material-icons', expanded ? ' rotate' : '')}>expand_more</i>
					</button>
				</div>
			</div>
			{expanded && (
				<>
					{!showEditForm && (
						<table className='patient-table-informations' style={{ width: '100%' }}>
							<tbody>
								<tr>
									<td>{translate('safetyPatientNeeds')}</td>
									<td>{props.data}</td>
								</tr>
							</tbody>
						</table>
					)}
					{showEditForm && (
						<div className='flex flex-space-between flex-wrap whiteboard-select'>
							<Textarea
								maxLength={1000}
								onChange={event => setRecognizedTranscription(event.target.value)}
								value={isSpeechToText ? `${recognizedTranscription} ${liveTranscription}`.trim() : recognizedTranscription}
								rows={10}
								name='text'
								placeholder={intl.formatMessage({ id: 'message' })}
								className='full-width'
								icon={
									<SpeechToText
										setRecognizedTranscription={setRecognizedTranscription}
										setLiveTranscription={setLiveTranscription}
										setIsSpeechToText={setIsSpeechToText}
										isSpeechToText={isSpeechToText}
									/>
								}
							/>
							<button
								className={classNames('button', props.isSubmitButtonLoading ? 'loading' : '')}
								type='button'
								onClick={() => props.setData(recognizedTranscription)}>
								{translate('save')}
							</button>
						</div>
					)}
				</>
			)}
		</>
	);
};

export default SafetyAlerts;
