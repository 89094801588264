import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { getUserMeasurementUnits } from 'api/measurements.js';
import Alert from 'components/Alert.jsx';
import { MeasurementTypes } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import SocketEvents from 'constants/socket-events.js';
import { getUserId } from 'infrastructure/auth.js';
import { convertMeasurementTypes } from 'infrastructure/helpers/measurementsHelper.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';

const WatchMeasurements = props => {
	const socket = useContext(SocketContext);
	const intl = useIntl();
	const [preferredUnits, setPreferredUnits] = useState([]);
	const [errorApiResponse, setErrorApiResponse] = useState(null);
	const [vitalSigns, setVitalSigns] = useState([
		{
			id: 1,
			name: intl.formatMessage({ id: 'oxygenSaturation' }),
			value: 'N/A',
			unit: '',
			img: 'respiratory',
			type: MeasurementTypes.OXYGEN,
			shortName: 'SPO2',
		},
		{
			id: 2,
			name: intl.formatMessage({ id: 'heartRate' }),
			value: 'N/A',
			unit: '',
			img: 'heart',
			type: MeasurementTypes.HEART_RATE,
			shortName: 'HR',
		},
	]);

	useEffect(() => {
		const getMeasurementData = async () => {
			const { error, unitPreferences } = await getUserMeasurementUnits(getUserId());
			if (error) {
				setErrorApiResponse(error.message);
			} else {
				setPreferredUnits(unitPreferences);
			}
		};
		getMeasurementData();
	}, []);

	useEffect(() => {
		socket.on(SocketEvents.HelloDevice.PATIENT_HEALTH_MEASUREMENTS, healthMeasurementsListener);
		return () => {
			socket.off(SocketEvents.HelloDevice.PATIENT_HEALTH_MEASUREMENTS, healthMeasurementsListener);
		};
	}, [socket]);

	const healthMeasurementsListener = data => {
		const newVitalSigns = [...vitalSigns];
		const { measurementType, measurementValue, measurementUnit, unitCategoryId } = data;
		const found = newVitalSigns.find(element => measurementType === element.type);
		if (found) {
			found.value = convertMeasurementTypes(unitCategoryId, measurementValue, getUnitPreference(unitCategoryId)?.unitSystemId);
			found.unit = measurementUnit;
			setVitalSigns(newVitalSigns);
		}
	};

	const getCategoryPreference = categoryId => preferredUnits.find(item => item.unitCategoryId === categoryId);

	const getUnitPreference = categoryId => {
		const selectedPreference = getCategoryPreference(categoryId);
		return selectedPreference?.options.find(item => item.unitSystemId === selectedPreference.unitSystemId);
	};

	const getVitalSignsDetails = (isRight = false) => (
		<div
			className={classNames(
				'flex vital-signs-ai',
				isRight ? 'right' : '',
				props.numberOfFeeds > 1 ? 'multiple-feeds vital-signs-left' : '',
				props.isPtzActive ? 'ptz-active' : '',
				props.shouldShowAIVitals ? 'ai-vital-signs-showing' : '',
				props.hasConditions ? 'has-conditions-watch' : ''
			)}>
			{vitalSigns.map(vitalSign => {
				return (
					<div className='flex vital-signs-details' key={vitalSign.id}>
						<img src={`${healthCareCdnUrl}vital-signs/${vitalSign.img}.svg`} alt='icon' />
						<div className='flex column-direction left-10'>
							<span>{props.shouldShowAllItems ? vitalSign.name : vitalSign.shortName}</span>
							<div className='flex'>
								<span className='right-s'>{vitalSign.value}</span>
								<span>{vitalSign.unit}</span>
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);

	return (
		<>
			<div className={classNames('flex monitoring-vital-signs-ai-wrapper', !props.shouldShowAllItems ? 'row' : '')}>
				{getVitalSignsDetails(true)}
			</div>
			<Alert display={errorApiResponse} fixed={true} hideCloseButton={true} message={errorApiResponse} variant='dark' />
		</>
	);
};

export default WatchMeasurements;
