import React from 'react';
import PropTypes from 'prop-types';

const DottedCircle = props => {
	const rotationValue = props.isSemiCircle ? 180 / props.numberOfLines : 360 / props.numberOfLines;

	return (
		<svg
			viewBox={`0 0 500 ${props.isSemiCircle ? '250' : '500'}`}
			xmlns='http://www.w3.org/2000/svg'
			className={props.isSemiCircle ? 'semi-dotted-circle-graph' : 'dotted-circle-graph'}>
			{[...Array(Math.round(props.numberOfLines)).keys()].map(item => (
				<line
					key={item}
					className={Math.round(props.value) > item ? 'active' : ''}
					x1={props.svgCoordinates.x1 || (props.isSemiCircle ? 15 : 250)}
					x2={props.svgCoordinates.x2 || (props.isSemiCircle ? 35 : 250)}
					y1={props.svgCoordinates.y1 || (props.isSemiCircle ? 240 : 15)}
					y2={props.svgCoordinates.y2 || (props.isSemiCircle ? 240 : 35)}
					style={{
						transform: props.isSemiCircle
							? `rotate(${((180 + rotationValue) / Math.round(props.numberOfLines)) * item}deg)`
							: `rotate(${rotationValue * item}deg)`,
						transformOrigin: props.isSemiCircle ? `50% ${((props.svgCoordinates.y2 || 240) / 250) * 100}%` : '50% 50%',
					}}
				/>
			))}
		</svg>
	);
};

DottedCircle.defaultProps = {
	isSemiCircle: false,
	svgCoordinates: {
		x1: 0,
		x2: 0,
		y1: 0,
		y2: 0,
	},
};

DottedCircle.propTypes = {
	numberOfLines: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
	isSemiCircle: PropTypes.bool,
	svgCoordinates: PropTypes.shape({
		x1: PropTypes.number,
		x2: PropTypes.number,
		y1: PropTypes.number,
		y2: PropTypes.number,
	}),
};

export default DottedCircle;
