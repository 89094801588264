import React from 'react';
import { useIntl } from 'react-intl';
import { Table } from 'components';
import { RequestAcuity, RequestType, VisitReasons } from 'constants/enums.js';
import { getDescFromEnum } from 'infrastructure/helpers/commonHelpers.js';
import { healthCareCdnUrl } from 'constants/global-variables';
import ContactInfo from 'containers/CardRequest/ContactInfo';

const CardRequest = ({ requests, joinCall, rejectRequest }) => {
	const intl = useIntl();
	const tableHeaders = [
		{ title: 'MRN', id: 0 },
		{ title: intl.formatMessage({ id: 'reasonToConnect' }), id: 1 },
		{ title: intl.formatMessage({ id: 'acuity' }), id: 2 },
		{ title: intl.formatMessage({ id: 'clinicalConcern' }), id: 3 },
		{ title: intl.formatMessage({ id: 'nurseInfo' }), id: 4 },
		{ title: intl.formatMessage({ id: 'clinicalContact' }), id: 5 },
		{ title: '', id: 6 },
	];

	const getAcuityDesc = typeId => {
		let result = '';
		switch (typeId) {
			case RequestAcuity.IMMEDIATE: {
				result = intl.formatMessage({ id: 'immediate' });
				break;
			}
			case RequestAcuity.TEN_MINUTES: {
				result = `10 ${intl.formatMessage({ id: 'minutes' })}`;
				break;
			}
			case RequestAcuity.THIRTY_MINUTES: {
				result = `30 ${intl.formatMessage({ id: 'minutes' })}`;
				break;
			}
			case RequestAcuity.SIXTY_MINUTES: {
				result = `60 ${intl.formatMessage({ id: 'minutes' })}`;
				break;
			}
			case RequestAcuity.ROUNDING: {
				result = intl.formatMessage({ id: 'rounding' });
				break;
			}
			default: {
				result = 'N/A';
				break;
			}
		}
		return result;
	};

	const transformRows = () => {
		if (!requests || requests.length === 0) {
			return [];
		}
		return requests.map(item => ({
			id: item.id,
			className: item.request?.requestType === RequestType.RAPID ? 'rapid-request-type' : '',
			mrn: item.request.patientExtendedProfile?.mrn,
			reasonToConnect: item.request?.requestReason
				? intl.formatMessage({ id: getDescFromEnum(item.request.requestReason, VisitReasons) })
				: 'N/A',
			acuity: item.request.requestAcuity ? getAcuityDesc(item.request?.requestAcuity) : 'N/A',
			clinicalConcern: item.request.description ? item.request.description : 'N/A',
			nurseInfo: item.request.requestIntakeInfo ? (
				<ContactInfo
					name={item.request.requestIntakeInfo.nurseFullName}
					phone={item.request.requestIntakeInfo.nurseStationPhone}
					ext={item.request.requestIntakeInfo.nurseExt}
				/>
			) : (
				'N/A'
			),
			clinicalContact: item.request.requestIntakeInfo ? (
				<ContactInfo
					name={item.request.requestIntakeInfo.clinicalContactFullName}
					phone={item.request.requestIntakeInfo.clinicalContactPhone}
					ext={item.request.requestIntakeInfo.clinicalContactExt}
				/>
			) : (
				'N/A'
			),
			actions: (
				<div className='flex position-relative patient-card-btns'>
					<button type='button' onClick={() => joinCall(item)}>
						<img src={`${healthCareCdnUrl}workflow/start-call.svg`} alt='start-call-icon' />
						{intl.formatMessage({ id: 'Join' })}
					</button>
					<button onClick={() => rejectRequest(item)} type='button' className='reject'>
						<i className='material-icons-outlined'>cancel</i>
						{intl.formatMessage({ id: 'Reject' })}
					</button>
				</div>
			),
		}));
	};
	return <Table headers={tableHeaders} rows={transformRows()} isEditable={false} className='waiting-room-table' />;
};
export default CardRequest;
