import React, { useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import { signUpDoctor, getSpecialties, getStates, uploadOnboardingProfilePicture } from 'api/doctorOnBoarding.js';
import * as Yup from 'yup';
import  Alert  from 'components/Alert.jsx';
import  Modal  from 'components/Modal.jsx';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Select from 'components/Select.jsx';
import FormInput from 'components/FormInput.jsx';
import translate from 'i18n-translations/translate.jsx';
import { HttpStatusCodes, Country, Agreements } from 'constants/enums.js';
import GoogleReCaptchaV3 from 'services/GoogleReCaptchaV3.js';
import { healthCareCdnUrl, APP_CONFIG } from 'constants/global-variables.js';
import {
	capitalizeFirstLetter,
	handleOnKeyDownNumeric,
	handleOnKeyDownCellPhoneNumbers,
} from 'infrastructure/helpers/commonHelpers.js';
import { checkUserEmailExists } from 'api/users.js';
import Button from 'components/Button.jsx';
import UserSignUpSuccess from 'views/Onboarding/UserSignUpSuccess.jsx';
import 'views/Onboarding/css/onboarding.css';
import { useIntl } from 'react-intl';
import ImageUploader from 'containers/ImageUploader.jsx';

const DoctorOnboarding = () => {
	const [success, setSuccess] = useState(null);
	const [error, setError] = useState(null);
	const [specialties, setSpecialties] = useState([]);
	const [states, setStates] = useState([]);
	const [isStatesModalVisible, setIsStatesModalVisible] = useState(false);
	const location = useLocation();
	const history = useHistory();
	const intl = useIntl();
	const [isBriefSignUp, setIsBriefSignUp] = useState(false);
	const onboardingImgUrl = 'https://static.solaborate.com/onboarding-process/';
	const { current: reCaptchaV3 } = useRef(new GoogleReCaptchaV3(APP_CONFIG.googleRecaptchaKey));

	const initialValues = {
		firstName: '',
		lastName: '',
		email: '',
		phoneNr: '',
		npiNumber: '',
		profilePicture: '',
		statesLicensedIn: [],
		licenseNumber: '',
		primarySpecialty: '',
		password: '',
		cPassword: '',
		agreeTerms: false,
		agreePrivacyPolicy: false,
	};

	useEffect(() => {
		const getSpecialtiesAndStates = async () => {
			const { state } = location;
			if (!state || (state && !state.country)) {
				history.push('/onboarding/country');
			}
			const [specialtiesList, statesList] = await Promise.all([getSpecialties(), getStates()]);
			const responseError = specialtiesList.error || statesList.error;
			if (responseError) {
				setError(responseError.message);
				return;
			}
			setSpecialties(specialtiesList.specialties);
			setStates(statesList.states);
			const googleReCaptcha = await reCaptchaV3.loadReCaptcha();
			if (!googleReCaptcha) {
				setError(translate('recaptchaLoadError'));
			}
		};
		setIsBriefSignUp([Country.KOSOVO, Country.ALBANIA].includes(location.state?.country?.value));
		getSpecialtiesAndStates();
	}, [location, history, reCaptchaV3]);

	const onModalClose = statesProps => {
		statesProps.setFieldValue(`statesLicensedIn`, []);
		setIsStatesModalVisible(prevState => !prevState);
	};

	const validateEmail = async val => {
		if (!val) {
			return true;
		}
		let emailExists = false;
		const schema = Yup.string().email().required();
		if (await schema.isValid(val)) {
			const response = await checkUserEmailExists(val);
			if (response.error) {
				setError(response.error.message);
				emailExists = false;
			} else {
				emailExists = response;
			}
		}
		return !emailExists;
	};

	const getStateLicenses = statesLicensedIn =>
		statesLicensedIn
			.filter(x => x)
			.map(x => ({
				...x,
				countryId: location.state.country.value,
			}));

	const onSubmitHandler = async (values, { setSubmitting, validateForm }) => {
		const reCaptchaToken = await reCaptchaV3.getToken();
		if (!reCaptchaToken) {
			setError(translate('recaptchaLoadError'));
		}
		const validationErrors = await validateForm();
		if (Object.keys(validationErrors).length) {
			return;
		}
		const userAgreements = [
			{
				agreementId: Agreements.TERMS_AND_CONDITIONS,
				hasAgreed: values.agreeTerms,
			},
			{
				agreementId: Agreements.PRIVACY_POLICY,
				hasAgreed: values.agreePrivacyPolicy,
			},
		];
		let signUpData = {
			reCaptchaToken,
			companyId: APP_CONFIG.companyId,
			countryId: location.state.country.value,
			firstName: capitalizeFirstLetter(values.firstName),
			lastName: capitalizeFirstLetter(values.lastName),
			password: values.password,
			profilePicture: values.profilePicture,
			email: values.email,
			phoneNr: values.phoneNr.toString(),
			specialtyId: parseInt(values.primarySpecialty, 10),
			userAgreements,
		};
		if (location.state.country.value === Country.USA) {
			signUpData = {
				...signUpData,
				npiNumber: values.npiNumber.toString(),
				doctorStateLicences: getStateLicenses(values.statesLicensedIn),
			};
		}
		if (location.state.country.value !== Country.USA) {
			signUpData = {
				...signUpData,
				doctorStateLicences: [{ countryId: location.state.country.value, licenceNr: values.licenseNumber }],
			};
		}
		setSubmitting(true);
		const response = await signUpDoctor(signUpData);
		if (response.error) {
			setError(response.error.message);
		} else {
			setSuccess(response.status === HttpStatusCodes.CREATED);
		}
		setSubmitting(false);
	};

	const validateForm = () => {
		return Yup.object().shape({
			firstName: Yup.string()
				.required(
					intl.formatMessage({
						id: 'pleaseWriteFirstName',
					})
				)
				.min(
					2,
					intl.formatMessage({
						id: 'firstNameMinLength',
					})
				)
				.max(
					30,
					intl.formatMessage({
						id: 'maxCharacterLengthExceeded',
					})
				)
				.trim(),
			lastName: Yup.string()
				.required(intl.formatMessage({ id: 'pleaseWriteLastName' }))
				.min(
					2,
					intl.formatMessage({
						id: 'lastNameMinLength',
					})
				)
				.max(
					30,
					intl.formatMessage({
						id: 'maxCharacterLengthExceeded',
					})
				)
				.trim(),
			email: Yup.string()
				.email(intl.formatMessage({ id: 'invalidEmail' }))
				.required(intl.formatMessage({ id: 'pleaseWriteEmail' }))
				.test('existing-email', intl.formatMessage({ id: 'userAlreadyExists' }), val => validateEmail(val)),
			phoneNr: Yup.string()
				.required(
					intl.formatMessage({
						id: 'pleaseWritePhoneNumber',
					})
				)
				.max(
					15,
					intl.formatMessage({
						id: 'maxCharacterLengthExceeded',
					})
				)
				.trim(),
			password: Yup.string()
				.required(intl.formatMessage({ id: 'pleaseWritePassword' }))
				.matches(
					// eslint-disable-next-line no-useless-escape
					/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*\(\)_\+\-\={}<>,\.\|""'~`:;\\?\/\[\] ]){8,}/,
					intl.formatMessage({ id: 'passwordCannotBeLessThan8Chart' })
				)
				.min(8, `${intl.formatMessage({ id: 'minLengthIs' })} 8`),
			cPassword: Yup.string()
				.oneOf(
					[Yup.ref('password'), null],
					intl.formatMessage({
						id: 'passwordsMustMatch',
					})
				)
				.required(
					intl.formatMessage({
						id: 'pleaseWriteConfirmPassword',
					})
				)
				.min(8, `${intl.formatMessage({ id: 'minLengthIs' })} 8`),
			primarySpecialty: Yup.string().required(
				intl.formatMessage({
					id: 'pleaseSelectPrimarySpecialty',
				})
			),
			profilePicture: Yup.string().required(
				intl.formatMessage({
					id: 'shouldUploadProfilePicture',
				})
			),
			licenseNumber: isBriefSignUp && Yup.string().required(intl.formatMessage({ id: 'pleaseWriteLicenseNumber' })),
			statesLicensedIn: !isBriefSignUp && Yup.array().required(intl.formatMessage({ id: 'atLeastOneState' })),
			npiNumber:
				!isBriefSignUp &&
				Yup.string()
					.required(intl.formatMessage({ id: 'pleaseWriteNPINumber' }))
					.max(10, intl.formatMessage({ id: 'npiMaxLength' })),
			agreeTerms: Yup.bool()
				.test('consent', intl.formatMessage({ id: 'haveToAgreeToTermsAndConditions' }), value => value === true)
				.required(intl.formatMessage({ id: 'haveToAgreeToTermsAndConditions' })),
			agreePrivacyPolicy: Yup.bool()
				.test('consent', intl.formatMessage({ id: 'haveToAgreePrivacyPolicy' }), value => value === true)
				.required(intl.formatMessage({ id: 'haveToAgreePrivacyPolicy' })),
		});
	};

	return (
		<>
			{!success && (
				<div className='patient-onboarding flex doctor-onboarding-wrapper'>
					<div className='patient-box lg-box'>
						<div className='flex flex-space-between'>
							<div className='logo'>
								<img src={`${healthCareCdnUrl}hellocare-logo.svg`} alt='logo' />
							</div>
						</div>
						<Formik
							initialValues={initialValues}
							onSubmit={onSubmitHandler}
							validateOnBlur={false}
							validateOnChange={false}
							validationSchema={validateForm}>
							{formikProps => {
								const { values, errors, touched, handleSubmit, handleChange, handleBlur, isSubmitting, setFieldValue } =
									formikProps;
								return (
									<>
										<div className='flex'>
											<div className='patient-box-left-info'>
												<h4>Registration</h4>
												<p>
													Make healthcare easier and earn extra income with telemedicine. The self-enrollment application will
													take a few minutes to complete.
												</p>
												<div className='flex profile-info-image flex-align-center column-direction'>
													<ImageUploader
														setError={setError}
														existingLogo={values.profilePicture}
														setProfilePicture={val => setFieldValue('profilePicture', val)}
														uploadProfilePic={uploadOnboardingProfilePicture}
														error={errors.profilePicture}
														sizeInMB={2}
														buttonText='Add Profile Picture'
													/>
												</div>
											</div>
											<div className='patient-box-right-form'>
												<div className='flex flex-space-between flex-wrap'>
													<FormInput
														id='firstName'
														text='First Name'
														type='text'
														onChange={handleChange}
														onBlur={handleBlur}
														placeholder={intl.formatMessage({ id: 'firstName' })}
														value={capitalizeFirstLetter(values.firstName)}
														error={errors.firstName}
														maxLength={256}
														validateOnSubmit={true}
													/>
													<FormInput
														id='lastName'
														text='Last Name'
														type='text'
														onChange={handleChange}
														onBlur={handleBlur}
														placeholder={intl.formatMessage({ id: 'lastName' })}
														value={capitalizeFirstLetter(values.lastName)}
														error={errors.lastName}
														maxLength={256}
														validateOnSubmit={true}
													/>
													<FormInput
														id='email'
														text='Email Address'
														type='email'
														onChange={handleChange}
														onBlur={handleBlur}
														placeholder={intl.formatMessage({ id: 'writeYourEmail' })}
														value={values.email}
														error={errors.email}
														maxLength={512}
														validateOnSubmit={true}
													/>
													<FormInput
														id='phoneNr'
														text='Cell Phone'
														type='text'
														onKeyDown={handleOnKeyDownCellPhoneNumbers}
														onChange={handleChange}
														onBlur={handleBlur}
														placeholder={intl.formatMessage({ id: 'verifyCellPhone' })}
														value={values.phoneNr}
														min='0'
														error={errors.phoneNr}
														maxLength={15}
														validateOnSubmit={true}
													/>
													<FormInput
														id='password'
														text='Password'
														type='password'
														onChange={handleChange}
														onBlur={handleBlur}
														className='full-width'
														placeholder={intl.formatMessage({ id: 'password' })}
														value={values.password}
														error={errors.password}
														validateOnSubmit={true}
														maxLength={32}
													/>
													<FormInput
														id='cPassword'
														text='Confirm Password'
														type='password'
														onChange={handleChange}
														onBlur={handleBlur}
														className='full-width'
														placeholder={intl.formatMessage({ id: 'confirmPassword' })}
														value={values.cPassword}
														error={errors.cPassword}
														validateOnSubmit={true}
														maxLength={32}
													/>
												</div>
												{!isBriefSignUp && (
													<div
														tabIndex={-1}
														className='flex flex-space-between flex-wrap'
														onBlur={() => formikProps.setFieldTouched('statesLicensedIn', true)}>
														<label>
															States Licensed In:
															<div className='doctor-onboarding-appears-here'>
																{values.statesLicensedIn.map(
																	state => state && <p key={state.stateId}>{state.checked ? state.name : ''}</p>
																)}
															</div>
														</label>
														<label className='height-0'>
															<button
																onBlur={() => formikProps.setFieldTouched('statesLicensedIn', true)}
																className='onboarding-show-options-btn text-transform-none'
																type='button'
																onClick={() => setIsStatesModalVisible(prevState => !prevState)}>
																Select State(s)
															</button>
														</label>
														{errors.statesLicensedIn && <span className='red-error'>{errors.statesLicensedIn}</span>}
													</div>
												)}
												<div className='flex flex-space-between flex-wrap'>
													{!isBriefSignUp && (
														<FormInput
															id='npiNumber'
															text='NPI Number'
															type='number'
															min='0'
															onKeyDown={handleOnKeyDownNumeric}
															onChange={handleChange}
															onBlur={handleBlur}
															placeholder={intl.formatMessage({ id: 'npiNumber' })}
															value={values.npiNumber}
															error={errors.npiNumber}
															validateOnSubmit={true}
															maxLength={10}
														/>
													)}
													{isBriefSignUp && (
														<FormInput
															id='licenseNumber'
															text='Licence Number'
															type='text'
															onChange={handleChange}
															onBlur={handleBlur}
															onKeyDown={handleOnKeyDownNumeric}
															placeholder={intl.formatMessage({ id: 'licenseNumber' })}
															value={values.licenseNumber}
															min='0'
															error={errors.licenseNumber}
															validateOnSubmit={true}
															maxLength={15}
														/>
													)}
													<Select
														label='Primary Specialty'
														name='primarySpecialty'
														items={specialties}
														valueField='id'
														textField='name'
														value={values.primarySpecialty}
														placeholder={intl.formatMessage({ id: 'select' })}
														onSelect={handleChange}
														onBlur={handleBlur}
														error={errors.primarySpecialty}
														validateOnSubmit={true}
														className={!values.primarySpecialty ? 'defaultColor' : ''}
													/>
												</div>
											</div>
										</div>
										<div className='flex'>
											<div className='patient-box-left-info no-padding-top' />
											<div className='patient-box-right-form no-padding-top'>
												<div className='flex flex-space-between flex-wrap full-width'>
													<div className='flex no-margin-bottom full-width'>
														<label className='remember-me'>
															<input
																onBlur={handleBlur}
																type='checkbox'
																onChange={handleChange}
																id='agreeTerms'
																name='agreeTerms'
																value={values.agreeTerms}
																checked={values.agreeTerms}
															/>
															<div className='onb-custom-checkbox-wrapper cursor-pointer'>
																<div className='onb-custom-checkbox' />
															</div>
															I agree to the{' '}
															<Link className='agreement-link-onboarding' target='_blank' to='/terms-of-use'>
																<span className='blue-color semi-bold'>Terms of Use</span>
															</Link>
															<br />
															{errors.agreeTerms && touched.agreeTerms && <span className='red-error'>{errors.agreeTerms}</span>}
														</label>
													</div>
													<div className='flex no-border-top no-margin-top full-width'>
														<label className='remember-me'>
															<input
																onBlur={handleBlur}
																type='checkbox'
																onChange={handleChange}
																id='agreePrivacyPolicy'
																name='agreePrivacyPolicy'
																value={values.agreePrivacyPolicy}
																checked={values.agreePrivacyPolicy}
															/>
															<div className='onb-custom-checkbox-wrapper'>
																<div className='onb-custom-checkbox' />
															</div>
															I agree to the{' '}
															<Link className='agreement-link-onboarding' target='_blank' to='/privacy-policy'>
																<span className='blue-color semi-bold'>Privacy Policy</span>
															</Link>
															<br />
															{errors.agreePrivacyPolicy && touched.agreePrivacyPolicy && (
																<span className='red-error'>{errors.agreePrivacyPolicy}</span>
															)}
														</label>
													</div>
													<div className='flex no-border-top no-margin-top full-width'>
														<label className='google-recaptcha-wrapper full-width'>
															<span>
																This site is protected by reCAPTCHA and the Google&nbsp;
																<a rel='noopener noreferrer' target='_blank' href='https://policies.google.com/privacy'>
																	Privacy Policy
																</a>
																&nbsp;and&nbsp;
																<a rel='noopener noreferrer' target='_blank' href='https://policies.google.com/terms'>
																	Terms of Service
																</a>
																&nbsp;apply.
															</span>
														</label>
													</div>
												</div>
											</div>
										</div>
										<div className='flex onboarding-buttons'>
											<div className='flex-1'>
												<span>
													Already have an Account? <Link to='/login'>Click here to sign in</Link>
												</span>
											</div>
											<div />
											<div>
												<Button
													onClick={handleSubmit}
													variant='contained'
													type='button'
													isLoading={isSubmitting}
													text='Sign Up'
													nextStepIcon={`${onboardingImgUrl}continue.svg`}
												/>
											</div>
										</div>
										<Modal
											display={isStatesModalVisible}
											position='center'
											onModalClose={() => onModalClose(formikProps)}
											primaryButtonLabel='Save & Continue'
											closeButtonText='Reset'
											submitIcon='close'
											className='standard-modal-wrapper send-request-modal-wrapper register-to-practice-modal'
											submitImgIcon={`${healthCareCdnUrl}doctor-request/send-request.svg`}
											onModalSubmit={() => setIsStatesModalVisible(false)}>
											<div className='standard-modal-inner'>
												<div className='standard-modal-title'>
													<h3>Select State(s)</h3>
												</div>
												<div className='flex flex-wrap input-auto-width-wrapper'>
													{states.length > 0 &&
														states.map(state => (
															<div key={state.id} className='register-practice-modal-grid tick-box-active-input'>
																<label className='full-width remember-me'>
																	<input
																		onChange={event => {
																			const selectedState = {
																				stateId: state.id,
																				name: state.name,
																				checked: event.target.checked,
																			};
																			formikProps.setFieldValue(`statesLicensedIn[${state.id}]`, selectedState);
																		}}
																		type='checkbox'
																		checked={values.statesLicensedIn[state.id] && values.statesLicensedIn[state.id].checked}
																	/>
																	<div className='onb-custom-checkbox-wrapper cursor-pointer'>
																		<div className='onb-custom-checkbox' />
																	</div>
																	<span>{state.name}</span>
																</label>
															</div>
														))}
												</div>
											</div>
										</Modal>
									</>
								);
							}}
						</Formik>
					</div>
					<div style={{ textAlign: 'center' }}>
						<Alert display={error} message={error} variant='error' onClose={() => setError(null)} />
					</div>
				</div>
			)}
			{success && <UserSignUpSuccess />}
		</>
	);
};

export default DoctorOnboarding;
