import React, { useState } from 'react';
import Modal from 'components/Modal.jsx';
import translate from 'i18n-translations/translate.jsx';
import { setUserPreference } from 'api/users.js';
import { getConfigurationValue } from 'infrastructure/helpers/commonHelpers.js';
import { actionCreators as patientNotesActionCreators } from 'state/patientNotes/actions.js';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { MonitoringSettingTab, MonitoringSettingsTabs } from 'constants/configurationEnums.js';

const ConfigurableButtonsMonitoring = ({
	setIsMonitoringSettingsOpen,
	roomId,
	adminConfigurations,
	updateMonitoringSetting,
	deviceId,
	adminAiSettingsConfigurations,
}) => {
	const patientNotes = useSelector(state => state.patientNotes.savedMode);
	const editablePanels = [
		'nightVision',
		'rails',
		'getOutOfBed',
		'fallDetection',
		'patientMobility',
		'inactiveTime',
		'aiPrivacyMode',
		'handWashing',
		'ewsAiVitalSigns',
		'ivBagFluidLevel',
		'patientInfo',
		'whiteboard',
	];

	const dispatch = useDispatch();
	const isNewExperience = useSelector(state => state.configurations.isNewExperience);
	const [currentTab, setCurrentTab] = useState(MonitoringSettingTab.GENERAL);

	const toggleConfigurableAlert = async key => {
		const configuration = currentTab === MonitoringSettingTab.GENERAL ? adminConfigurations : adminAiSettingsConfigurations;
		const value = !getConfigurationValue(configuration[key]);
		const dataToSend = {
			preference: {
				preferenceType: key,
				value: value.toString(),
			},
			roomId,
		};
		const response = await setUserPreference(dataToSend);
		if (response.error) {
			return;
		}
		updateMonitoringSetting({ ...configuration[key], value }, key);
		if (currentTab === MonitoringSettingTab.GENERAL && isNewExperience) {
			updatePatientData(configuration[key].title);
		}
	};

	const updatePatientData = property => {
		if (!editablePanels.includes(property)) {
			return;
		}
		dispatch(
			patientNotesActionCreators.setPatientDataSaved(
				{
					...patientNotes[deviceId],
					[property]: !patientNotes[deviceId][property],
				},
				deviceId
			)
		);
	};

	return (
		<Modal
			display={true}
			position='center'
			className='configurable-settings-modal border-radius-modal-wrapper standard-modal-wrapper'
			onModalClose={setIsMonitoringSettingsOpen}
			onModalSubmit={setIsMonitoringSettingsOpen}>
			<div className='configurable-settings-modal-inner no-padding'>
				<div className='configurable-settings-main-title'>
					<h4>{translate('monitoringSettings')}</h4>
				</div>
				<div className='flex overflow-hidden'>
					{MonitoringSettingsTabs.map((item, index) => (
						<div
							className={classNames('flex-1 monitoring-settings-tab', currentTab === index ? 'active' : '')}
							onClick={() => setCurrentTab(index)}>
							{translate(item.title)}
						</div>
					))}
				</div>
				<div className='flex flex-wrap'>
					{Object.entries(currentTab === MonitoringSettingTab.GENERAL ? adminConfigurations : adminAiSettingsConfigurations).map(
						([key, item]) => (
							<React.Fragment key={key}>
								{!item.isLocked && !item.isNotUserPreference && (
									<div className='flex' key={key} onClick={() => toggleConfigurableAlert(+key)}>
										<p className='flex-1'>{translate(item.title)}</p>
										<div className='rounded-slider-switch'>
											<input type='checkbox' checked={item.value} />
											<span className='rounded-slider' />
										</div>
									</div>
								)}
							</React.Fragment>
						)
					)}
				</div>
			</div>
		</Modal>
	);
};

export default ConfigurableButtonsMonitoring;
