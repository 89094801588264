import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { getPatientsBasedOnProvider } from 'api/dashboard.js';
import Alert from 'components/Alert.jsx';
import Pagination from 'components/Common/Pagination.jsx';
import ProfilePicture from 'components/ProfilePicture.jsx';
import translate from 'i18n-translations/translate.jsx';
import { adjustTimeForUTC, formatTimeDurationWithSeconds, getHumanizedDateWithHour } from 'infrastructure/helpers/dateHelper.js';
import classNames from 'classnames';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { doesParamValueExist, getConfigurationValue, getRoleValueById } from 'infrastructure/helpers/commonHelpers.js';
import { getSessionType } from 'infrastructure/helpers/callLogsHelper.js';
import { useSelector } from 'react-redux';
import { CompanySettings } from 'constants/configurationEnums.js';
import Table from 'components/Table.jsx';
// import FormInput from 'components/FormInput.jsx';

const ProvidersVisits = props => {
	const [patients, setPatients] = useState([]);
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0, totalCount: 0 });
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	// const [searchValue, setSearchValue] = useState('');
	const companyConfigurations = useSelector(state => state.company.companySettings.companyConfigurations);

	const intl = useIntl();

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			const response = await getPatientsBasedOnProvider({
				start: Math.floor(adjustTimeForUTC(props.start).getTime() / 1000),
				end: Math.floor(adjustTimeForUTC(props.end, true).getTime() / 1000),
				pageIndex: pagination.pageIndex,
				pageSize: pagination.pageSize,
				roleId: props.currentRole?.id,
				...(doesParamValueExist(props.selectedHealthSystem) && { healthSystemId: props.selectedHealthSystem.value }),
				...(doesParamValueExist(props.selectedHospital) && {
					regionId: props.selectedHospital.regionId,
					hospitalId: props.selectedHospital.value,
				}),
				...(doesParamValueExist(props.selectedDepartment) && { departmentId: props.selectedDepartment.value }),
				...(doesParamValueExist(props.selectedFloor) && { floorId: props.selectedFloor.value }),
				...(doesParamValueExist(props.selectedRoom) && { roomId: props.selectedRoom.value }),
				...(props.selectedProvider && { providerId: props.selectedProvider.userIntId }),
				...(props.selectedTimezone.value && { timezone: props.selectedTimezone.value }),
			});
			if (!response.error) {
				setPagination({ pageSize: pagination.pageSize, pageIndex: pagination.pageIndex, totalCount: response.totalCount || 0 });
				setPatients(response.data);
			} else {
				setError(
					translate('somethingWentWrongSpecific', {
						value: intl.formatMessage({ id: 'patients' }),
					})
				);
			}
			setIsLoading(false);
		};
		if (props.selectedProvider && props.currentRole.id) {
			fetchData();
		}
	}, [
		props.start,
		props.end,
		props.selectedDepartment,
		props.selectedFloor,
		props.selectedHealthSystem,
		props.selectedHospital,
		props.selectedRoom,
		props.selectedProvider,
		pagination.pageIndex,
		pagination.pageSize,
		props.currentRole,
		props.selectedTimezone,
	]);

	const isPatientDisabled = () =>
		getConfigurationValue(companyConfigurations[CompanySettings.PATIENT_VIRTUAL_ADMISSION]) &&
		!getConfigurationValue(companyConfigurations[CompanySettings.PATIENT_MANUAL_ADMISSION]);

	const displayProviders = () => {
		if (!patients || patients.length === 0 || isLoading) {
			return [];
		}
		return patients.map(patient => ({
			...(!isPatientDisabled() && {
				patient: (
					<div className='flex flex-align-center'>
						{patient.patient && (
							<ProfilePicture
								className='patient-request-details-profile'
								fullName={patient.patient}
								profilePicture={patient.picture}
							/>
						)}
						<span>{patient.patient || translate('unassigned')}</span>
					</div>
				),
			}),
			name: getSessionType(patient.name),
			callType: getSessionType(patient.callType),
			timeJoined: getHumanizedDateWithHour(patient.timeJoined),
			endedTime: getHumanizedDateWithHour(patient.endedTime),
			duration: formatTimeDurationWithSeconds(
				(new Date(patient.endedTime).getTime() - new Date(patient.timeJoined).getTime()) / 1000,
				' '
			),
		}));
	};
	return (
		<>
			<>
				{props.selectedProvider && (
					<span className='visits-header'>
						<h3>
							{translate('possessiveSessions', {
								value: props.selectedProvider?.firstName,
							})}
						</h3>
						{props.selectedProvider?.memberRoles.map(role => (
							<span
								key={role.id}
								onClick={() => props.setCurrentRole(role)}
								className={classNames('provider-role', props.currentRole?.name === role.name ? 'active' : '')}>
								<img
									src={`${healthCareCdnUrl}user-types/${
										props.currentRole?.name === role.name ? `${role.name.toLowerCase()}-active` : role.name.toLowerCase()
									}.svg?v3`}
									alt='icon'
								/>
								{getRoleValueById(role.id)}
								<div>
									<i className='material-icons'>done</i>
								</div>
							</span>
						))}
						{/* <div className='flex flex-align-center dashboard-header'>
							<label>
								<FormInput
									id='hospitalTimezone'
									name='hospitalTimezone'
									placeholder={intl.formatMessage({ id: 'searchPatient' })}
									type='text'
									onChange={event => setSearchValue(event.target.value)}
									error={null}
									value={searchValue}
									maxLength={10}
									labelClassName='header-searchbar no-padding-left no-padding-right'
									validateOnSubmit={true}
								/>
							</label>
						</div> */}
					</span>
				)}

				<Table
					headers={[
						!isPatientDisabled() && { title: translate('patients'), id: 0 },
						{ title: translate('room'), id: 1 },
						{ title: translate('sessionByType'), id: 2 },
						{ title: translate('sessionStart'), id: 3 },
						{ title: translate('sessionEnd'), id: 4 },
						{ title: translate('sessionDuration'), id: 5 },
					]}
					rows={displayProviders()}
					isEditable={false}
					isLoading={isLoading}
				/>
				<div className='pagination-bar'>
					<Pagination
						totalCount={pagination.totalCount}
						pageSize={pagination.pageSize}
						pageIndex={pagination.pageIndex}
						onChange={(pageSize, pageIndex) => setPagination(prevState => ({ ...prevState, pageSize, pageIndex }))}
					/>
				</div>
				<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
			</>
		</>
	);
};

export default ProvidersVisits;
