import NightVision from 'calls/icons/NightVision.jsx';
import translate from 'i18n-translations/translate.jsx';
import { useEffect, useRef, useState } from 'react';

const VideoFeedActions = ({ feed, toggleNightVision }) => {
	const [isSwitchDeviceDisabled, setIsSwitchDeviceDisabled] = useState(false);

	const disableSwitchTimeout = useRef(null);

	useEffect(() => {
		return () => {
			if (disableSwitchTimeout) {
				clearTimeout(disableSwitchTimeout.current);
			}
		};
	}, []);

	return (
		<>
			<div className='monitoring-timeline-box video-feed-actions'>
				<div className='timeline-box-header'>
					<p className='timeline-box-title'>
						<NightVision color={'var(--gray-0)'} />
						{translate('nightVision')}
					</p>
					<div className='timeline-box-actions'>
						<div className='flex'>
							<div
								className='toggle-switch'
								onClick={() => {
									if (isSwitchDeviceDisabled) {
										return;
									}
									setIsSwitchDeviceDisabled(true);
									disableSwitchTimeout.current = setTimeout(() => {
										setIsSwitchDeviceDisabled(false);
									}, 5000);

									toggleNightVision();
								}}>
								<input type='checkbox' checked={feed.isNightVisionActive} />
								<span className='toggle-body'>
									<span className='on-text'>{translate('on')}</span>
									<span className='off-text'>{translate('off')}</span>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default VideoFeedActions;
