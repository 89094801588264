import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import Alert from 'components/Alert.jsx';
import translate from 'i18n-translations/translate.jsx';
import Loader from 'components/Loader.jsx';
import Grid from 'components/Grid.jsx';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';
import Pagination from 'components/Common/Pagination.jsx';
import { UserRoles, WarningIndexes } from 'constants/enums.js';
import { getMeasurementAlertActivities, getPatientMeasurementAlerts } from 'api/alerts.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import AlertHistoryModal from 'components/AlertHistoryModal.jsx';
import { measurementAlert } from 'constants/alerts.js';
import { stringToCamelCase } from 'infrastructure/helpers/commonHelpers.js';
import VitalSignsThreshold from 'containers/VitalSignsThreshold.jsx';
import { getUserRole } from 'infrastructure/auth.js';
import { TabPanel, TabPanels, Tabs } from 'components/Tabs.jsx';
import { LOCALES } from 'i18n-translations/locales.js';
import { useSelector } from 'react-redux';

const MeasurementAlerts = props => {
	const tabs = {
		HEALTH_DATA: 0,
		DETAILS: 1,
	};
	const [currentTab, setCurrentTab] = useState(tabs.HEALTH_DATA);
	const [measurementAlerts, setMeasurementAlerts] = useState([]);
	const [error, setError] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0, totalCount: 0 });
	const [alertDetails, setAlertDetails] = useState(null);
	const [isVitalSignsThresholdVisible, setVitalSignsThresholdVisibility] = useState(false);
	const intl = useIntl();
	const userRole = getUserRole();
	const locale = useSelector(state => state.language.locale);
	const isArabic = locale === LOCALES.ARABIC;

	useEffect(() => {
		setIsLoading(true);
		const fetchMeasurementAlerts = async (pageSize, pageIndex) => {
			const params = {
				pageSize,
				pageIndex,
				userId: props.patientId,
			};
			const response = await getPatientMeasurementAlerts(params);
			if (response.error) {
				setError(response.error.message);
			} else {
				setMeasurementAlerts(response.measurementAlerts);
			}
			setIsLoading(false);
			setPagination({ pageSize, pageIndex, totalCount: response.totalCount || 0 });
		};
		fetchMeasurementAlerts(pagination.pageSize, pagination.pageIndex);
	}, [props.patientId, props.type, pagination.pageSize, pagination.pageIndex]);

	const onPageChange = async (pageSize, pageIndex) => {
		setPagination(prevState => ({ ...prevState, pageSize, pageIndex }));
		setIsLoading(false);
	};

	const imageUrl = `${healthCareCdnUrl}vsm/`;

	const handleChangeTab = async (tab, id = null) => {
		if (id) {
			const response = await getMeasurementAlertActivities(id);
			if (response.error) {
				setError(response.error.message);
				return;
			}
			setAlertDetails(response.measurementAlertActivities);
		}
		setCurrentTab(tab);
	};

	return (
		<>
			<Tabs activeIndex={currentTab}>
				<TabPanels>
					<TabPanel>
						<div className='patient-summary-wrapper measurements-alerts-wrapper'>
							{UserRoles.DOCTOR === userRole && (
								<div className='alerts-title-wrapper'>
									<div className='flex'>
										<h3>{translate(isVitalSignsThresholdVisible ? 'healthDataSafeRanges' : 'healthDataAlerts')}</h3>
										<button
											type='button'
											className={`flex${isVitalSignsThresholdVisible ? ' go-back' : ''}`}
											onClick={() => setVitalSignsThresholdVisibility(prevState => !prevState)}>
											{isVitalSignsThresholdVisible && (
												<>
													<i className='material-icons'>chevron_left</i>
													{translate('goBack')}
												</>
											)}
											{!isVitalSignsThresholdVisible && (
												<>
													<i className='material-icons-outlined'>edit</i> {translate('editSafeRanges')}
												</>
											)}
										</button>
									</div>
									{isVitalSignsThresholdVisible && <p>{translate('thresholdAlertDescription')}</p>}
								</div>
							)}
							{isVitalSignsThresholdVisible && <VitalSignsThreshold patientId={props.patientId} isCallView={props.isCallView} />}
							{measurementAlerts.length === 0 && !isLoading && !isVitalSignsThresholdVisible && (
								<div className='flex flex-align-center flex-justify-center empty-state-wrapper'>
									<p>{translate('noResultsFound')}</p>
								</div>
							)}
							{measurementAlerts.length > 0 && !isLoading && !isVitalSignsThresholdVisible && (
								<>
									<div className='patient-summary-table-wrapper'>
										{measurementAlerts.map(item => (
											<div key={item.id} className='patient-request-list-item cursor-pointer'>
												<div className='alert-icon'>
													<img src={measurementAlert.find(el => el.type === item.measurement.type)?.icon} alt='ico' />
												</div>
												<div>
													<p>
														{`${intl.formatMessage({ id: stringToCamelCase(item.measurement.type) })} ${intl.formatMessage({
															id: 'wasDetected',
														})} ${item.measurement.value} ${item.measurement.unit}`}
														<span
															className={`${
																[WarningIndexes.LOW, WarningIndexes.HIGH].includes(item.type.warningIndex)
																	? 'moderate-risk'
																	: 'high-risk'
															}`}>
															{[WarningIndexes.LOW, WarningIndexes.HIGH].includes(item.type.warningIndex)
																? translate('moderateRisk')
																: translate('highRisk')}
														</span>
													</p>
													<p className={classNames('alert-time', isArabic ? 'left-position' : '')}>
														<img src={`${imageUrl}Time.svg`} alt='ico' />
														{formattedDate(item.dateCreated)}
													</p>
												</div>
												<div className='flex-1 flex flex-justify-center right-align-items'>
													<button
														type='button'
														className={classNames('alert-view-details-btn', isArabic ? 'left-position' : '')}
														onClick={() => handleChangeTab(tabs.DETAILS, item.id)}>
														<img src={`${imageUrl}View-Details.svg`} alt='ico' />
														{translate('viewDetails')}
													</button>
												</div>
											</div>
										))}
									</div>
									<Pagination
										totalCount={pagination.totalCount}
										pageSize={pagination.pageSize}
										pageIndex={pagination.pageIndex}
										onChange={(pageSize, pageIndex) => onPageChange(pageSize, pageIndex)}
									/>
								</>
							)}
							<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
						</div>
					</TabPanel>
					{alertDetails && (
						<TabPanel>
							<div
								className={classNames(
									`view-request-history-wrapper patient-summary-wrapper ${!alertDetails.length ? 'center-data' : ''}`
								)}>
								<div className='flex flex-align-center left-margin-s'>
									<button type='button' className='alert-details-btn' onClick={() => handleChangeTab(tabs.HEALTH_DATA)}>
										<i className='material-icons'>chevron_left</i>
									</button>
									<p className='bold'>{translate('alertDetails')}</p>
								</div>
								<AlertHistoryModal alertDetails={alertDetails} isMeasurementAlert={true} />
							</div>
						</TabPanel>
					)}
				</TabPanels>
			</Tabs>
			{isLoading && (
				<Grid columns='1fr' rows='1fr' stretch='calc(100vh - 200px)' horizAlign='center' vertAlign='center'>
					<div style={{ textAlign: 'center' }}>
						<Loader />
					</div>
				</Grid>
			)}
		</>
	);
};

export default MeasurementAlerts;
