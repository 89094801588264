import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { conference as Conference } from '@solaborate/calls';
import LightTheme from 'calls/styles/LightTheme.js';
import { useConference } from 'calls/hooks/index.js';
import { ParticipantStateMessage } from 'calls/enums/index.js';
import ParticipantRemovedMessages from 'calls/enums/ParticipantRemovedMessages.js';
import { ParticipantRemoveReason } from 'constants/enums.js';

const StyledToaster = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	position: absolute;
	left: ${LightTheme.spacing[4]}px;
	top: ${LightTheme.spacing[4]}px;
	z-index: 10;

	span {
		display: inline-block;
		padding: ${LightTheme.spacing[3]}px;
		background: ${LightTheme.colors.graySix};
		color: ${LightTheme.colors.grayZero};
		border-radius: ${LightTheme.borderRadius.base}px;
		transition: 0.15s;
		transform: scale(1);
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
		font-size: 14px;
		margin-bottom: ${LightTheme.spacing[2]}px;
		transform-origin: center;
		animation: slideIn 5s;
	}

	@keyframes slideIn {
		0% {
			transform: scale(0);
		}

		5% {
			transform: scale(1);
		}

		95% {
			transform: scale(1);
		}

		100% {
			transform: scale(0);
		}
	}
`;

/**
 * Used for displaying conference toast messages
 * @typedef {object} Message
 * @property {string} id
 * @property {string} message
 * @param {object} props
 * @param {number} [props.displayLimit] Simultaneous display limit of toast messages
 * @param {Message[]} [props.messages] Extra optional parameter to push toast messages from parent component
 */
const Toaster = ({ displayLimit, messages }) => {
	const conference = useConference();
	const displayLimitRef = useRef(displayLimit ?? 3);

	const [toasts, setToasts] = useState([]);
	const toastMessagesRef = useRef([]);
	const queuedToastMessagesRef = useRef([]);

	const pushMessage = message => {
		if (toastMessagesRef.current.length >= displayLimitRef.current) {
			queuedToastMessagesRef.current.push(message);
			return;
		}

		toastMessagesRef.current.push(message);
		setToasts([...toastMessagesRef.current]);

		const timeout = setTimeout(() => {
			toastMessagesRef.current.shift();

			if (toastMessagesRef.current.length < displayLimitRef.current && queuedToastMessagesRef.current.length > 0) {
				pushMessage(queuedToastMessagesRef.current.shift());
			}
			setToasts([...toastMessagesRef.current]);
			clearTimeout(timeout);
		}, 5000);
	};

	useEffect(() => {
		const getParticipantLeftMessage = participant => {
			if (participant.state.reason === ParticipantRemoveReason.FAILED_TO_GET_INFO) {
				return `${participant.name}'s ${ParticipantRemovedMessages[participant.state.reason]}`;
			}

			return `${participant.name} ${ParticipantStateMessage[participant.state.constructor.name]}`;
		};

		const off = conference.on(event => {
			if (event instanceof Conference.ParticipantRemoved) {
				const { participant } = event;

				if (!participant.isCompanion) {
					pushMessage({
						id: participant.id,
						message: getParticipantLeftMessage(participant),
					});
				}
			}
		});

		return off;
	}, [conference]);

	useEffect(() => {
		if (messages) {
			messages.forEach(message => pushMessage(message));
		}
	}, [messages]);

	return (
		<StyledToaster>
			{toasts.map(({ id, message }) => (
				<span key={id}>{message}</span>
			))}
		</StyledToaster>
	);
};

export default Toaster;
