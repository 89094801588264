import React, { useEffect, useState } from 'react';
import AiConfigurationDetails from 'components/MonitoringSettings/AiConfigurationDetails.jsx';
import { getPatientsAiSettings, updateAiAlertNotificationTypes } from 'api/patients.js';
import Alert from 'components/Alert.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators as aiSettingsActionCreators } from 'state/aiSettings/actions.js';
import { CheckBoxTypes, FallPreventionSettingTypes, RadioButtonTypes } from 'constants/ai.js';

const AiConfigurations = ({ deviceId, patientId, selectedItem, roomId }) => {
	const [checkedItems, setCheckedItems] = useState([]);
	const [radioButtons, setRadioButtons] = useState([]);
	const [isItemClicked, setIsItemClicked] = useState(false);
	const [error, setError] = useState(null);
	const dispatch = useDispatch();
	const setAiSettingsAction = ai => dispatch(aiSettingsActionCreators.setPatientAiSettings(ai));
	const aiSettingList = useSelector(state => state.aiSettingsList.aiSettings);

	const getFilteredArr = (arr, numbers) =>
		arr.map(item => ({
			notificationOptions: item.notificationOptions?.filter(num => numbers.includes(num)),
			settingTypeId: item.settingTypeId,
		}));

	useEffect(() => {
		const getPatientAiSettings = async () => {
			const response = await getPatientsAiSettings({ patientId, deviceId, roomId });
			if (response.error) {
				setError(response.error.message);
				return;
			}
			const fallPreventionAiSettings = response.patientAiSettings.reduce((acc, item) => {
				if (FallPreventionSettingTypes.includes(item.settingTypeId)) {
					acc.push({ settingTypeId: item.settingTypeId, notificationOptions: item.notificationOptions });
				}
				return acc;
			}, []);
			setRadioButtons(getFilteredArr(fallPreventionAiSettings, RadioButtonTypes));
			setCheckedItems(getFilteredArr(fallPreventionAiSettings, CheckBoxTypes));
			setAiSettingsAction({ deviceId, settings: response.patientAiSettings });
		};
		getPatientAiSettings();
	}, [patientId, deviceId]);

	const getMergedArr = () => {
		const mergedArray = checkedItems.map(checkedItem => {
			const foundRadioButton = radioButtons.find(radioItem => radioItem.settingTypeId === checkedItem.settingTypeId);
			if (foundRadioButton) {
				return {
					settingTypeId: checkedItem.settingTypeId,
					notificationOptions: [...checkedItem.notificationOptions, ...foundRadioButton.notificationOptions],
				};
			} else {
				return checkedItem;
			}
		});
		radioButtons.forEach(radioItem => {
			const existsInMergedArray = mergedArray.some(mergedItem => mergedItem.settingTypeId === radioItem.settingTypeId);
			if (!existsInMergedArray) {
				mergedArray.push(radioItem);
			}
		});
		return mergedArray;
	};

	useEffect(() => {
		const mergedArr = getMergedArr();
		const updateAiConfiguration = async () => {
			setError(null);

			const params = {
				patientId,
				deviceId,
				roomId,
				sendData: mergedArr,
			};

			const response = await updateAiAlertNotificationTypes(params);
			if (response.error) {
				setError(response.error.message);
				return;
			}
		};
		if (mergedArr.length > 0) {
			updateAiConfiguration();
		}
	}, [checkedItems, isItemClicked, deviceId, patientId]);

	return (
		<>
			<AiConfigurationDetails
				setCheckedItems={setCheckedItems}
				setRadioButtons={setRadioButtons}
				setIsItemClicked={setIsItemClicked}
				checkedItems={checkedItems}
				radioButtons={radioButtons}
				settingTypeId={selectedItem.aiTypeId}
				aiSettingList={aiSettingList}
				deviceId={deviceId}
				patientId={patientId}
				roomId={roomId}
			/>

			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
		</>
	);
};

export default AiConfigurations;
