import React from 'react';
import styled from 'styled-components';

import LightTheme from 'calls/styles/LightTheme.js';

const StyledSwitch = styled.span`
	position: relative;
	display: flex;
	width: 40px;
	height: 24px;

	input {
		opacity: 0;
		z-index: 2;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		margin: 0;

		~ span::before,
		~ span::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: ${LightTheme.colors.grayTwo};
			border-radius: 30px;
		}

		&:checked ~ span::before {
			background: ${LightTheme.colors.greenThree};
		}
		&:checked ~ span::after {
			background: ${LightTheme.colors.greenTwo};
		}
		~ span::after {
			content: '';
			width: 18px;
			height: 18px;
			border-radius: 100%;
			background: ${LightTheme.colors.grayZero};
			display: flex;
			margin: 3px;
			transition: 0.2s;
		}

		&:checked ~ span::after {
			transform: translateX(15px);
		}
	}
`;

/**
 * @param {React.PropsWithRef<React.InputHTMLAttributes<HTMLInputElement>>} props
 */
const Switch = ({ ...inputHtmlAttributes }) => {
	return (
		<StyledSwitch>
			<input {...inputHtmlAttributes} type='checkbox' />
			<span />
		</StyledSwitch>
	);
};

export default Switch;
