import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { APP_CONFIG } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import { getCompanyId, getUserId, getUserInfo, getUserProfile, getUserRole } from 'infrastructure/auth.js';
import { CountryIds, AlertTypes, UserRoles, Country } from 'constants/enums.js';
import FormInput from 'components/FormInput.jsx';
import { uploadProfilePic } from 'api/media.js';
import { getCountries, getUserAddress, updateUserProfileInformation } from 'api/users.js';
import Genders from 'components/Genders.jsx';
import { genderItems } from 'constants/genderItems.js';
import { getStates } from 'api/doctorOnBoarding.js';
import Button from 'components/Button.jsx';
import Grid from 'components/Grid.jsx';
import Loader from 'components/Loader.jsx';
import Select from 'components/Select.jsx';
import Form from 'components/Form.jsx';
import PopUpAlert from 'components/PopUpAlert.jsx';
import {
	doNotAllowSpaceAsFirstCharacter,
	getStorage,
	handleOnKeyDownNumeric,
	isAdUser,
} from 'infrastructure/helpers/commonHelpers.js';
import ImageUploader from './ImageUploader.jsx';

const ProfileInformation = () => {
	const [profile, setProfile] = useState(null);
	const [success, setSuccess] = useState(null);
	const [error, setError] = useState(null);
	const [isRequestProcessing, setIsRequestProcessing] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [isExpanded, setIsExpanded] = useState(false);
	const [countries, setCountries] = useState([]);
	const [states, setStates] = useState([]);
	const [profilePicture, setProfilePicture] = useState('');
	const [userAddress, setUserAddress] = useState(null);
	const intl = useIntl();
	const prefixesList = [
		{ code: '', id: 99, name: 'None' },
		{ code: 'APRN', id: 0, name: 'APRN' },
		{ code: 'LPN', id: 1, name: 'LPN' },
		{ code: 'LVN', id: 2, name: 'LVN' },
		{ code: 'NP', id: 3, name: 'NP' },
		{ code: 'RN', id: 4, name: 'RN' },
		{ code: 'ACNP', id: 5, name: 'ACNP' },
		{ code: 'CNS', id: 6, name: 'CNS' },
		{ code: 'ANP', id: 7, name: 'ANP' },
		{ code: 'FNP', id: 8, name: 'FNP' },
		{ code: 'GNP', id: 9, name: 'GNP' },
		{ code: 'PNP', id: 10, name: 'PNP' },
		{ code: 'TCRN', id: 11, name: 'TCRN' },
		{ code: 'APP', id: 12, name: 'APP' },
		{ code: 'Med Tech', id: 13, name: 'Med Tech' },
	];
	const userInfo = getUserInfo();
	const companyId = getCompanyId();

	useEffect(() => {
		getProfileInformationDetails();
	}, []);

	useEffect(() => {
		let timeout;
		if (success) {
			timeout = setTimeout(() => {
				setSuccess(null);
			}, 1500);
		}
		return () => clearTimeout(timeout);
	}, [success]);

	const getProfileInformationDetails = async () => {
		const [countriesList, statesList, profileInformation, addressResponse] = await Promise.all([
			getCountries(),
			getStates(),
			getUserProfile(getUserId(), companyId),
			getUserAddress(getUserId()),
		]);
		const responseError = countriesList.error || statesList.error || profileInformation.error || addressResponse.error;
		if (responseError) {
			setError(responseError.message);
		} else {
			setCountries(countriesList.countries);
			setStates(statesList.states);
			setProfile(profileInformation);
			setProfilePicture(profileInformation.profilePicture);
			setUserAddress(addressResponse.address);
		}
		setIsLoading(false);
	};

	const updateProfileInformation = async values => {
		const { firstName, lastName, genderId, dateOfBirth, countryId, stateId, city, zipCode, address1, address2, prefix } = values;
		const userProfileValues = {
			firstName,
			lastName,
			profilePicture,
			genderId: parseInt(genderId, 10),
			dateOfBirth,
			...(prefix && {
				prefix,
			}),
			userAddress:
				countryId && address1
					? {
							countryId: parseInt(countryId, 10),
							stateId: parseInt(stateId, 10),
							city,
							zipCode: zipCode.toString(),
							address1,
							address2,
					  }
					: null,
		};
		if (!profilePicture) {
			setError(translate('shouldUploadProfilePicture'));
			return;
		}
		setIsRequestProcessing(true);
		const response = await updateUserProfileInformation(userProfileValues);
		if (response.error) {
			setError(response.error.message);
		}
		if (response.hasSucceeded) {
			updateUserProfile(userProfileValues);
			if (getUserRole() === UserRoles.NURSE) {
				userInfo.prefix = prefix;
				getStorage().setItem('userProfile', JSON.stringify(userInfo));
			}
			setSuccess(response.hasSucceeded);
		}
		setIsRequestProcessing(false);
	};

	const updateUserProfile = values => {
		const userProfile = getUserInfo();
		userProfile.firstName = values.firstName;
		userProfile.lastName = values.lastName;
		userProfile.profilePicture = `${APP_CONFIG.profilePicBaseUrl}${values.profilePicture}`;
		localStorage.setItem('userProfile', JSON.stringify(userProfile));
	};

	const closeResponseAlert = () => {
		setError(null);
		setSuccess(null);
	};

	const getInitialValues = () => {
		if (profile) {
			return {
				firstName: profile.firstName,
				lastName: profile.lastName,
				genderId: profile.genderId?.toString(),
				dateOfBirth: moment(profile.dateOfBirth).format('YYYY-MM-DD'),
				countryId: userAddress?.countryId ?? null,
				stateId: userAddress?.stateId ?? null,
				city: userAddress?.city ?? '',
				zipCode: userAddress?.zipCode ?? '',
				address1: userAddress?.address1 ?? '',
				address2: userAddress && userAddress.address2 ? userAddress.address2 : '',
				prefix: userInfo.prefix,
			};
		}
		return null;
	};

	const getValidationSchema = () => {
		let validationSchema = {
			firstName: getValidation('firstNameCannotBeEmpty'),
			lastName: getValidation('lastNameCannotBeEmpty'),
			genderId: Yup.string().required(intl.formatMessage({ id: 'pleaseSelectSex' })),
			dateOfBirth: Yup.string()
				.test('dateOfBirth', intl.formatMessage({ id: 'greaterThanEighteen' }), value =>
					moment(value).isBetween(moment().subtract(120, 'years'), moment().subtract(18, 'years'))
				)
				.required(intl.formatMessage({ id: 'pleaseWriteDateOfBirth' })),
			countryId: Yup.string()
				.when('address1', {
					is: address1 => address1 && address1.trim() !== '',
					then: () => Yup.string().required(intl.formatMessage({ id: 'pleaseSelectCountry' })),
				})
				.nullable(),
			stateId: Yup.string()
				.when('countryId', {
					is: countryId => countryId === Country.USA.toString(),
					then: () => Yup.string().required(intl.formatMessage({ id: 'atLeastOneState' })),
				})
				.nullable(),

			city: getValidation('cityRequired').matches(
				/[a-zA-Z]/,
				intl.formatMessage({
					id: 'cityMustHaveLetter',
				})
			),
			zipCode: Yup.number().max(9999999999, intl.formatMessage({ id: 'zipCodeDigitsMax' })),
			address1: Yup.string()
				.when(['countryId', 'zipCode', 'address2', 'city'], {
					is: (countryId, zipCode, address2, city) => countryId || zipCode || address2 || city,
					then: () => Yup.string().required(intl.formatMessage({ id: 'addressRequired' })),
				})
				.nullable(),
		};
		return Yup.object().shape(validationSchema, [['countryId', 'address1']]);
	};

	const getValidation = (key, type = 0) => {
		// type 0 = required, 1 = nullable
		if (type === 0) {
			return Yup.string().min(2, `${intl.formatMessage({ id: 'minLengthIs' })} 2`);
		}
		return Yup.string()
			.min(2, `${intl.formatMessage({ id: 'minLengthIs' })} 2`)
			.nullable();
	};

	return (
		<>
			{isLoading && (
				<Grid width='100%' stretch='calc(100vh - 105px)' vertAlign='center' horizAlign='center' rows='auto'>
					<Loader />
				</Grid>
			)}
			{!isLoading && (
				<div className='account-settings-panel-wrapper account-settings-inner-wrapper'>
					<h4>{translate('profileInformation')}</h4>
					<h5>{translate('profilePicture')}</h5>
					<div className='flex profile-info-image flex-align-center'>
						<ImageUploader
							setError={setError}
							existingLogo={profilePicture}
							setProfilePicture={setProfilePicture}
							uploadProfilePic={uploadProfilePic}
							error=''
							sizeInMB={2}
						/>
					</div>

					<Formik
						enableReinitialize={true}
						initialValues={getInitialValues()}
						onSubmit={updateProfileInformation}
						validationSchema={getValidationSchema()}>
						{formikProps => {
							const { values, touched, errors, handleChange, handleBlur, handleSubmit, isValid } = formikProps;
							return (
								<Form className='profile-information-form'>
									<div className='flex flex-space-between flex-wrap'>
										<FormInput
											text={translate('firstName')}
											id='firstName'
											placeholder={intl.formatMessage({ id: 'enterFirstName' })}
											type='text'
											value={values.firstName}
											onChange={handleChange}
											onBlur={handleBlur}
											className={errors.firstName && touched.firstName ? 'text-input error' : 'text-input'}
											error={errors.firstName}
											touched={touched.firstName}
											readOnly={isAdUser()}
											maxLength={256}
										/>
										<FormInput
											text={translate('lastName')}
											id='lastName'
											placeholder={intl.formatMessage({ id: 'enterLastName' })}
											type='text'
											value={values.lastName}
											onChange={handleChange}
											onBlur={handleBlur}
											className={errors.lastName && touched.lastName ? 'text-input error' : 'text-input'}
											error={errors.lastName}
											touched={touched.lastName}
											readOnly={isAdUser()}
											maxLength={256}
										/>
										{getUserRole() === UserRoles.NURSE && (
											<Select
												label={translate('prefixes')}
												name='prefix'
												items={prefixesList}
												valueField='code'
												textField='name'
												value={values.prefix || ''}
												placeholder={intl.formatMessage({ id: 'select' })}
												onSelect={handleChange}
												onBlur={handleBlur}
												error={errors.prefix}
												touched={touched.prefix}
											/>
										)}
										<div className='genders-title'>
											{intl.formatMessage({ id: 'sex' })}
											<Genders
												items={genderItems}
												handleChange={handleChange}
												handleBlur={handleBlur}
												errors={errors}
												touched={touched}
												value={values.genderId}
											/>
										</div>
										<FormInput
											text={translate('dateOfBirth')}
											id='dateOfBirth'
											placeholder={intl.formatMessage({ id: 'pleaseSelectDateOfBirth' })}
											type='date'
											value={values.dateOfBirth}
											onChange={handleChange}
											onBlur={handleBlur}
											max={new Date().toISOString().split('T')[0]}
											className={errors.dateOfBirth && touched.dateOfBirth ? 'text-input error' : 'text-input'}
											error={errors.dateOfBirth}
											touched={touched.dateOfBirth}
										/>
									</div>
									<div className='addreses-wrapper'>
										<div
											className='flex flex-align-center cursor-pointer position-relative'
											onClick={() => setIsExpanded(prevState => !prevState)}>
											<i className='material-icons-outlined'>{isExpanded ? 'arrow_drop_down' : 'arrow_right'}</i>
											<div className='flex column-direction'>
												<h5>{translate('locationAndAddress')}</h5>
												<span>{`${intl.formatMessage({ id: 'addOrUpdate' })} ${intl.formatMessage({
													id: 'locationAndAddress',
												})}`}</span>
											</div>
										</div>
										{isExpanded && (
											<div className='flex flex-space-between flex-wrap'>
												<Select
													label={translate('country')}
													name='countryId'
													items={countries}
													valueField='id'
													textField='name'
													value={values.countryId || ''}
													placeholder={intl.formatMessage({ id: 'select' })}
													onSelect={handleChange}
													onBlur={handleBlur}
													error={errors.countryId}
													touched={touched.countryId}
												/>
												{CountryIds.USA === parseInt(values.countryId, 10) && (
													<Select
														label={translate('state')}
														name='stateId'
														items={states}
														valueField='id'
														textField='name'
														value={values.stateId || ''}
														placeholder={intl.formatMessage({ id: 'select' })}
														onSelect={handleChange}
														onBlur={handleBlur}
														error={errors.stateId}
														touched={touched.stateId}
													/>
												)}
												{CountryIds.USA !== parseInt(values.countryId, 10) && <label />}
												<FormInput
													text={translate('city')}
													id='city'
													placeholder={intl.formatMessage({ id: 'enterCity' })}
													type='text'
													value={values.city}
													onChange={handleChange}
													onBlur={handleBlur}
													className={errors.city && touched.city ? 'text-input error' : 'text-input'}
													error={errors.city}
													touched={touched.city}
													maxLength={100}
													onKeyDown={doNotAllowSpaceAsFirstCharacter}
												/>
												<FormInput
													text={translate('zipCode')}
													id='zipCode'
													placeholder={intl.formatMessage({ id: 'enterZipCode' })}
													type='number'
													value={values.zipCode}
													onChange={handleChange}
													onBlur={handleBlur}
													className={
														errors.zipCode && touched.zipCode
															? 'text-input error number-input-wo-arrows'
															: 'text-input number-input-wo-arrows'
													}
													error={errors.zipCode}
													touched={touched.zipCode}
													onKeyDown={handleOnKeyDownNumeric}
													maxLength={10}
												/>
												<FormInput
													text={`${intl.formatMessage({ id: 'address' })} 1`}
													id='address1'
													placeholder={intl.formatMessage({ id: 'enterAddress' })}
													type='text'
													value={values.address1}
													onChange={handleChange}
													onBlur={handleBlur}
													className={errors.address1 && touched.address1 ? 'text-input error' : 'text-input'}
													error={errors.address1}
													touched={touched.address1}
													maxLength={256}
													onKeyDown={doNotAllowSpaceAsFirstCharacter}
												/>
												<FormInput
													text={`${intl.formatMessage({ id: 'address' })} 2`}
													id='address2'
													placeholder={intl.formatMessage({ id: 'enterAddress' })}
													type='text'
													value={values.address2}
													onChange={handleChange}
													onBlur={handleBlur}
													className={errors.address2 && touched.address2 ? 'text-input error' : 'text-input'}
													error={errors.address2}
													touched={touched.address2}
													maxLength={256}
													onKeyDown={doNotAllowSpaceAsFirstCharacter}
												/>
											</div>
										)}
									</div>
									<div className='full-width flex right-align-content top-30 padding-bottom-30'>
										<Button
											disabled={!isValid}
											className='save-changes-btn'
											onClick={handleSubmit}
											isLoading={isRequestProcessing}
											icon={!isRequestProcessing ? 'done' : ''}
											text={!isRequestProcessing ? translate('saveChanges') : ''}
										/>
									</div>
								</Form>
							);
						}}
					</Formik>
					<PopUpAlert
						alertType={success ? AlertTypes.SUCCESS : AlertTypes.DANGER}
						display={error || success}
						onAlertClose={closeResponseAlert}
						contentText={success ? intl.formatMessage({ id: 'changesSaved' }) : error}
						isSilent={true}
						center={true}
					/>
				</div>
			)}
		</>
	);
};

export default ProfileInformation;
