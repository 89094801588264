import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';
import { buildQueryString } from 'infrastructure/helpers/commonHelpers.js';

export const getRoleKpis = async ({
	start,
	end,
	healthSystemId = '',
	regionId = '',
	hospitalId = '',
	departmentId = '',
	floorId = '',
	roomId = '',
	providerId = '',
	timezone = null,
}) => {
	try {
		const { data } = await gatewayApi.get(`v1/organizations/${getCompanyId()}/callstats/role-kpis`, {
			params: {
				start,
				end,
				...(healthSystemId && {
					healthSystemId,
				}),
				...(hospitalId && {
					regionId,
					hospitalId,
				}),
				...(departmentId && {
					departmentId,
				}),
				...(floorId && {
					floorId,
				}),
				...(roomId && {
					roomId,
				}),
				...(providerId && {
					providerId,
				}),
				...(timezone && {
					timezone,
				}),
			},
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const getHourlySessionsTimeline = async ({
	start,
	end,
	healthSystemId = '',
	regionId = '',
	hospitalId = '',
	departmentId = '',
	floorId = '',
	roomId = '',
	nurseId = '',
	roleId = null,
	timezone = null,
}) => {
	try {
		const { data } = await gatewayApi.get(`v1/organizations/${getCompanyId()}/callstats/hourly-timeline`, {
			params: {
				start,
				end,
				...(healthSystemId && {
					healthSystemId,
				}),
				...(hospitalId && {
					regionId,
					hospitalId,
				}),
				...(departmentId && {
					departmentId,
				}),
				...(floorId && {
					floorId,
				}),
				...(roomId && {
					roomId,
				}),
				...(nurseId && {
					nurseId,
				}),
				...(roleId && {
					roleId,
				}),
				...(timezone && {
					timezone,
				}),
			},
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const getHourlyPatientKpiTimeline = async ({ fromDate, toDate, levelType, levelId }) => {
	try {
		const { data } = await gatewayApi.get(`v1/organizations/${getCompanyId()}/patient-kpi`, {
			params: {
				'level-id': levelId,
				'level-type': levelType,
				'from-date': fromDate,
				'to-date': toDate,
			},
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const downloadConferencesCsv = async ({
	start,
	end,
	healthSystemId = '',
	regionId = '',
	hospitalId = '',
	departmentId = '',
	floorId = '',
	roomId = '',
	providerId = '',
	fields = '',
	timezone = null,
}) => {
	try {
		const url = `v1.1/tasks/companies/${getCompanyId()}/conferences${fields ? `?fields=${fields}` : ''}`;
		const { data } = await gatewayApi.get(url, {
			params: {
				start,
				end,
				...(healthSystemId && {
					healthSystemId,
				}),
				...(regionId && {
					regionId,
				}),
				...(hospitalId && {
					hospitalId,
				}),
				...(departmentId && {
					departmentId,
				}),
				...(floorId && {
					floorId,
				}),
				...(roomId && {
					roomId,
				}),
				...(providerId && {
					providerId,
				}),
				...(timezone && {
					timezone,
				}),
			},
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const sendEmailDashboardData = async ({
	start,
	end,
	healthSystemId = '',
	regionId = '',
	hospitalId = '',
	departmentId = '',
	floorId = '',
	roomId = '',
	providerId = '',
	fields = '',
	timezone = null,
}) => {
	try {
		const url = `v1/tasks/organization/${getCompanyId()}/rooms/conferences${fields ? `?fields=${fields}` : ''}`;
		const { data } = await gatewayApi.get(url, {
			params: {
				start,
				end,
				...(healthSystemId && {
					healthSystemId,
				}),
				...(regionId && {
					regionId,
				}),
				...(hospitalId && {
					hospitalId,
				}),
				...(departmentId && {
					departmentId,
				}),
				...(floorId && {
					floorId,
				}),
				...(roomId && {
					roomId,
				}),
				...(providerId && {
					providerId,
				}),
				...(timezone && {
					timezone,
				}),
			},
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const getProviders = async ({
	start,
	end,
	healthSystemId = '',
	regionId = '',
	hospitalId = '',
	departmentId = '',
	floorId = '',
	roomId = '',
	pageIndex = 0,
	pageSize = 20,
	timezone = null,
	searchPattern = '',
}) => {
	try {
		const { data } = await gatewayApi.get(`v1/organizations/${getCompanyId()}/callstats/providers`, {
			params: {
				start,
				end,
				pageIndex,
				pageSize,
				...(healthSystemId && {
					healthSystemId,
				}),
				...(hospitalId && {
					regionId,
					hospitalId,
				}),
				...(departmentId && {
					departmentId,
				}),
				...(floorId && {
					floorId,
				}),
				...(roomId && {
					roomId,
				}),
				...(timezone && {
					timezone,
				}),
				searchPattern,
			},
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPatientsBasedOnProvider = async ({
	start,
	end,
	healthSystemId = '',
	regionId = '',
	hospitalId = '',
	departmentId = '',
	floorId = '',
	roomId = '',
	providerId = '',
	roleId = null,
	pageIndex = 0,
	pageSize = 20,
	timezone = null,
}) => {
	try {
		const { data } = await gatewayApi.get(
			`v1/organizations/${getCompanyId()}/callstats/providers/${providerId}/role/${roleId}/patients`,
			{
				params: {
					start,
					end,
					pageIndex,
					pageSize,
					...(healthSystemId && {
						healthSystemId,
					}),
					...(hospitalId && {
						regionId,
						hospitalId,
					}),
					...(departmentId && {
						departmentId,
					}),
					...(floorId && {
						floorId,
					}),
					...(roomId && {
						roomId,
					}),
					...(providerId && {
						providerId,
					}),
					...(timezone && {
						timezone,
					}),
				},
			}
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getKpis = async ({ levelId, levelType, fromDate, toDate }) => {
	try {
		const { data } = await gatewayApi.get(
			`v1/organizations/
		${getCompanyId()}/kpi`,
			{
				params: {
					'level-id': levelId,
					'level-type': levelType,
					'from-date': fromDate,
					'to-date': toDate,
				},
			}
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getAssignedMembers = async ({ healthSystemId, search = '', pageIndex = 0, pageSize = 20 }) => {
	try {
		const { data } = await gatewayApi.get(
			`v1/organizations/${getCompanyId()}/health-systems/${healthSystemId}/assigned-members?pageIndex=${pageIndex}&pageSize=${pageSize}&search=${search}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getAiAlerts = async ({ fromDate, toDate, levelType, levelId, patientId = null, timezone = null }) => {
	const params = { fromDate, toDate, levelType, levelId, patientId, timezone };
	try {
		const { data } = await gatewayApi.get(`v1/alerts/ai/organization/${getCompanyId()}/statistics?${buildQueryString(params)}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getHealthAlerts = async ({ fromDate, toDate, levelType, levelId, timezone = null }) => {
	try {
		const { data } = await gatewayApi.get(`v1/alerts/measurement/organization/${getCompanyId()}/statistics`, {
			params: {
				'level-id': levelId,
				'level-type': levelType,
				'from-date': fromDate,
				'to-date': toDate,
				...(timezone && {
					'time-zone': timezone,
				}),
			},
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPatientCentricReport = async ({
	pageSize,
	pageIndex,
	from,
	to,
	search = '',
	healthSystemId,
	hospitalId,
	departmentId,
	floorId,
	roomId,
}) => {
	try {
		const { data } = await gatewayApi.get(`v1/healthcare/organizations/${getCompanyId()}/patients/centric-reports`, {
			params: {
				'page-size': pageSize,
				'page-index': pageIndex,
				from,
				to,
				search: search,
				'health-system': healthSystemId,
				hospital: hospitalId,
				department: departmentId,
				floor: floorId,
				room: roomId,
			},
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const getTimeKpis = async ({ patientId, start, end, solHelloDeviceId }) => {
	try {
		const { data } = await gatewayApi.get(
			`v1/organizations/${getCompanyId()}/callstats/patients/${patientId}/time-kpis?solHelloDeviceId=${solHelloDeviceId}`,
			{
				params: {
					start,
					end,
				},
			}
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPatientSession = async ({
	patientId,
	start,
	end,
	companyId = getCompanyId(),
	timezone = null,
	pageSize = 10,
	pageIndex = 0,
	solHelloDeviceId,
}) => {
	try {
		const { data } = await gatewayApi.get(`v1/conferences/patients/${patientId}`, {
			params: {
				start,
				end,
				companyId,
				pageSize,
				pageIndex,
				...(timezone && {
					timezone,
				}),
				...(solHelloDeviceId && {
					solHelloDeviceId,
				}),
			},
		});
		return data;
	} catch (error) {
		return { error };
	}
};
