import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Select from 'react-select';
import FormInput from 'components/FormInput.jsx';
import {
	AddEditPatientRadioTypes,
	KeyCodes,
	MeasurementUnitOptions,
	PatientHeightLimit,
	PatientWaistCircumferenceRangeLimit,
	PatientWeightLimit,
	TotalCholesterolLimit,
	UnitCategoryTypes,
	UnitSystemTypes,
} from 'constants/enums.js';
import { diabeticStatusCodes, tobaccoSmokeCodes } from 'constants/health-codes.js';
import translate from 'i18n-translations/translate.jsx';
import { capitalizeFirstLetter, doNotAllowSpaceAsFirstCharacter, isDecimal } from 'infrastructure/helpers/commonHelpers.js';
import { Allergies } from 'constants/visitEnums.js';
import { getUserMeasurementUnits } from 'api/measurements.js';
import { getUserId } from 'infrastructure/auth.js';

const HealthInformation = props => {
	const [unitPreferences, setUnitPreferences] = useState([]);
	const [errorOnAllergyEnter, setErrorOnAllergyEnter] = useState({});
	const intl = useIntl();
	const radioButtons = [
		{ content: intl.formatMessage({ id: 'yes' }), value: AddEditPatientRadioTypes.YES },
		{ content: intl.formatMessage({ id: 'no' }), value: AddEditPatientRadioTypes.NO },
		{
			content: 'N/A',
			value: AddEditPatientRadioTypes.NO_ANSWER,
		},
	];

	const getValue = value => {
		const selectedValue = props.healthInformationStatusList.find(item => item?.code === parseInt(value, 10));
		return { value: selectedValue?.code?.toString(), label: selectedValue?.name };
	};

	const getRadioButtons = (name, onChange) =>
		radioButtons.map(item => (
			<div key={item.value} className='flex position-relative consent-from-patient margin-right-m'>
				<label className='remember-me position-relative flex'>
					<input
						className='auto-width cursor-pointer'
						type='radio'
						value={item.value.toString()}
						onChange={onChange}
						name={name}
						onBlur={props.onBlur}
						checked={props.values[name] === item.value.toString()}
					/>
					<div className='onb-custom-checkbox-wrapper add-patient-checkbox-wrapper cursor-pointer margin-top-s'>
						<div className='onb-custom-checkbox' />
					</div>
					<p className={`${props.values[name] === item.value.toString() ? 'bold-font' : ''}`}>{item.content}</p>
				</label>
			</div>
		));

	const onEnter = (e, formikProps) => {
		if (!e.target.value) {
			return;
		}
		const enter = KeyCodes.ENTER;
		const value = capitalizeFirstLetter(e.target.value);
		const foodArr = [...props.foodAllergies];
		const medicArr = [...props.medicationAllergies];
		const environmentalArr = [...props.environmentalAllergies];
		const biologicalArr = [...props.biologicalAllergies];

		if (e.keyCode === enter) {
			if (!/^[a-zA-Z\s]*$/.test(value)) {
				setErrorOnAllergyEnter({ errorAt: e.target.name, errorMessage: intl.formatMessage({ id: 'allergyNameNonNumber' }) });
				return;
			}
			if (e.target.name === 'foodAllergy') {
				if (!foodArr.find(item => item.toLowerCase() === value.toLowerCase())) {
					foodArr.push(value);
					props.setFoodAllergies(foodArr);
					formikProps.setFieldValue('foodAllergy', '');
				}
			}
			if (e.target.name === 'medicationAllergy') {
				if (!medicArr.find(item => item.toLowerCase() === value.toLowerCase())) {
					medicArr.push(value);
					props.setMedicationAllergies(medicArr);
					formikProps.setFieldValue('medicationAllergy', '');
				}
			}
			if (e.target.name === 'environmentalAllergy') {
				if (!environmentalArr.find(item => item.toLowerCase() === value.toLowerCase())) {
					environmentalArr.push(value);
					props.setEnvironmentalAllergies(environmentalArr);
					formikProps.setFieldValue('environmentalAllergy', '');
				}
			}
			if (e.target.name === 'biologicalAllergy') {
				if (!biologicalArr.find(item => item.toLowerCase() === value.toLowerCase())) {
					biologicalArr.push(value);
					props.setBiologicalAllergies(biologicalArr);
					formikProps.setFieldValue('biologicalAllergy', '');
				}
			}
			setErrorOnAllergyEnter({});
		}
	};

	const removeAllergy = (item, type) => {
		const foodArr = [...props.foodAllergies];
		const medicArr = [...props.medicationAllergies];
		const environmentalArr = [...props.environmentalAllergies];
		const biologicalArr = [...props.biologicalAllergies];
		let foundIndex = null;
		if (type.id === Allergies.FOOD.id) {
			foundIndex = foodArr.findIndex(el => el === item);
			if (foundIndex > -1) {
				foodArr.splice(foundIndex, 1);
			}
			props.setFoodAllergies(foodArr);
		}
		if (type.id === Allergies.MEDICATION.id) {
			foundIndex = medicArr.findIndex(el => el === item);
			if (foundIndex > -1) {
				medicArr.splice(foundIndex, 1);
			}
			props.setMedicationAllergies(medicArr);
		}
		if (type.id === Allergies.ENVIRONMENTAL.id) {
			foundIndex = environmentalArr.findIndex(el => el === item);
			if (foundIndex > -1) {
				environmentalArr.splice(foundIndex, 1);
			}
			props.setEnvironmentalAllergies(environmentalArr);
		}
		if (type.id === Allergies.BIOLOGICAL.id) {
			foundIndex = biologicalArr.findIndex(el => el === item);
			if (foundIndex > -1) {
				biologicalArr.splice(foundIndex, 1);
			}
			props.setBiologicalAllergies(biologicalArr);
		}
	};

	useEffect(() => {
		const fetchMeasurementUnits = async () => {
			const response = await getUserMeasurementUnits(getUserId());
			if (response.error) {
				return;
			}
			setUnitPreferences(response.unitPreferences);
		};
		fetchMeasurementUnits();
	}, []);

	const getPreferredUnit = (type, preferredType) =>
		MeasurementUnitOptions[type].value[
			unitPreferences.find(item => item.unitCategoryId === UnitCategoryTypes[preferredType])?.unitSystemId
		]?.unitValue;

	const inputLimits = {
		HEIGHT:
			getPreferredUnit('HEIGHT', 'HEIGHT') === UnitSystemTypes.METRIC
				? PatientHeightLimit.CENTIMETER.MAX
				: PatientHeightLimit.FEET.MAX,
		WEIGHT:
			getPreferredUnit('WEIGHT', 'WEIGHT') === UnitSystemTypes.METRIC
				? PatientWeightLimit.KILOGRAMS.MAX
				: PatientWeightLimit.LBS.MAX,
		TOTAL_CHOLESTEROL:
			getPreferredUnit('BLOOD_GLUCOSE', 'BLOOD_GLUCOSE') === UnitSystemTypes.METRIC
				? TotalCholesterolLimit.MMOLL.MAX
				: TotalCholesterolLimit.MGDL.MAX,
		HDL_CHOLESTEROL:
			getPreferredUnit('BLOOD_GLUCOSE', 'BLOOD_GLUCOSE') === UnitSystemTypes.METRIC
				? TotalCholesterolLimit.MMOLL.MAX
				: TotalCholesterolLimit.MGDL.MAX,
		WAIST_CIRCUMFERENCE:
			getPreferredUnit('WAIST_CIRCUMFERENCE', 'HEIGHT') === UnitSystemTypes.METRIC
				? PatientWaistCircumferenceRangeLimit.CM.MAX
				: PatientWaistCircumferenceRangeLimit.INCH.MAX,
	};

	const getMeasurementUnit = measurementType => {
		const measurementDetails = { unitCategoryId: 0, value: '', name: '', limit: 0, error: '' };
		switch (measurementType) {
			case MeasurementUnitOptions.HEIGHT:
				measurementDetails.unitCategoryId = UnitCategoryTypes.HEIGHT;
				measurementDetails.value = props.values.height;
				measurementDetails.name = 'height';
				measurementDetails.limit = inputLimits.HEIGHT;
				measurementDetails.error = props.errors.height;
				break;
			case MeasurementUnitOptions.WEIGHT:
				measurementDetails.unitCategoryId = UnitCategoryTypes.WEIGHT;
				measurementDetails.value = props.values.weight;
				measurementDetails.name = 'weight';
				measurementDetails.limit = inputLimits.WEIGHT;
				measurementDetails.error = props.errors.weight;
				break;
			case MeasurementUnitOptions.WAIST_CIRCUMFERENCE:
				measurementDetails.unitCategoryId = UnitCategoryTypes.HEIGHT;
				measurementDetails.value = props.values.waistCircumference;
				measurementDetails.name = 'waistCircumference';
				measurementDetails.limit = inputLimits.WAIST_CIRCUMFERENCE;
				measurementDetails.error = props.errors.waistCircumference;
				break;
			case MeasurementUnitOptions.TOTAL_CHOLESTEROL:
				measurementDetails.unitCategoryId = UnitCategoryTypes.BLOOD_GLUCOSE;
				measurementDetails.value = props.values.totalCholesterol;
				measurementDetails.name = 'totalCholesterol';
				measurementDetails.limit = inputLimits.TOTAL_CHOLESTEROL;
				measurementDetails.error = props.errors.totalCholesterol;
				break;
			case MeasurementUnitOptions.HDL_CHOLESTEROL:
				measurementDetails.unitCategoryId = UnitCategoryTypes.BLOOD_GLUCOSE;
				measurementDetails.value = props.values.hdlCholesterol;
				measurementDetails.name = 'hdlCholesterol';
				measurementDetails.limit = inputLimits.HDL_CHOLESTEROL;
				measurementDetails.error = props.errors.hdlCholesterol;
				break;
			default:
				break;
		}
		return (
			<div className='flex column-direction personal-details position-relative'>
				<p className='patient-measurement-unit'>
					{
						measurementType.value[
							unitPreferences.find(item => item.unitCategoryId === measurementDetails.unitCategoryId)?.unitSystemId
						]?.abbr
					}
				</p>
				<label>
					{translate(measurementDetails.name)}
					<input
						type='number'
						placeholder={intl.formatMessage({ id: measurementDetails.name })}
						value={measurementDetails.value}
						onChange={event => {
							if (isDecimal(event.target.value)) {
								props.onChange(event);
							}
						}}
						name={measurementDetails.name}
						className='number-input-wo-arrows'
					/>
					{measurementDetails.error && <span className='red-error'>{measurementDetails.error}</span>}
				</label>
			</div>
		);
	};

	const getAllergyType = (allergyType, label) => (
		<label className='remember-me'>
			<input
				type='checkbox'
				name='allergies'
				value={allergyType}
				onChange={props.onChange}
				onBlur={props.onBlur}
				checked={props.values.allergies?.find(item => item === allergyType.toString())}
				className='cursor-pointer'
			/>
			<div className='onb-custom-checkbox-wrapper cursor-pointer'>
				<div className='onb-custom-checkbox' />
			</div>
			{translate(label)}
		</label>
	);

	const getAllergiesList = allergyType => {
		const allergyDetails = { label: '', value: '', name: '', list: [] };
		switch (allergyType) {
			case Allergies.FOOD:
				allergyDetails.label = 'foodAllergies';
				allergyDetails.value = props.values.foodAllergy;
				allergyDetails.name = 'foodAllergy';
				allergyDetails.list = props.foodAllergies;
				break;
			case Allergies.MEDICATION:
				allergyDetails.label = 'medicationAllergies';
				allergyDetails.value = props.values.medicationAllergy;
				allergyDetails.name = 'medicationAllergy';
				allergyDetails.list = props.medicationAllergies;
				break;
			case Allergies.ENVIRONMENTAL:
				allergyDetails.label = 'environmentalAllergies';
				allergyDetails.value = props.values.environmentalAllergy;
				allergyDetails.name = 'environmentalAllergy';
				allergyDetails.list = props.environmentalAllergies;
				break;
			case Allergies.BIOLOGICAL:
				allergyDetails.label = 'biologicalAllergies';
				allergyDetails.value = props.values.biologicalAllergy;
				allergyDetails.name = 'biologicalAllergy';
				allergyDetails.list = props.biologicalAllergies;
				break;
			default:
				break;
		}
		return (
			props.values.allergies?.find(item => item === allergyType.id.toString()) && (
				<div className='health-information-details'>
					<div className='patient-registration-allergy-list'>
						<div>
							<label>{translate(allergyDetails.label)}</label>
							<input
								type='text'
								value={allergyDetails.value}
								onChange={props.onChange}
								onBlur={props.onBlur}
								name={allergyDetails.name}
								placeholder={intl.formatMessage({ id: 'typeAllergyAndPressEnter' })}
								onKeyDown={e => onEnter(e, props.formikProps)}
								enterKeyHint='go'
							/>
						</div>
						<div className='allergies-errors'>
							{errorOnAllergyEnter.errorAt === allergyDetails.name && (
								<span className='red-error'>{errorOnAllergyEnter.errorMessage}</span>
							)}
							{allergyDetails.list.length === 0 && (
								<span className='red-error'> {intl.formatMessage({ id: 'pleaseWriteAllergy' })}</span>
							)}
						</div>
						{allergyDetails.list.length > 0 && (
							<div className='flex allergies-list flex-wrap'>
								{allergyDetails.list.map(item => (
									<div className='flex'>
										<i className='material-icons' onClick={() => removeAllergy(item, allergyType)}>
											close
										</i>
										<p>{item}</p>
									</div>
								))}
							</div>
						)}
					</div>
				</div>
			)
		);
	};

	return (
		<>
			<h5 className='top-15'>{translate('allergies')}</h5>
			<div className='flex'>
				<div className='health-information-details'>
					<label>{translate('anyAllergies')}</label>
					<div className='flex'>{getRadioButtons('hasAllergy', props.onChange)}</div>
				</div>
				{parseInt(props.values.hasAllergy, 10) === AddEditPatientRadioTypes.YES && (
					<div className='health-information-details'>
						<div className='patient-registration-checkboxes'>
							<div>
								<label>{translate('typeOfAllergies')}</label>
								<div className='flex allergies-checkboxes'>
									{getAllergyType(Allergies.FOOD.id, 'food')}
									{getAllergyType(Allergies.MEDICATION.id, 'medication')}
									{getAllergyType(Allergies.ENVIRONMENTAL.id, 'environmental')}
									{getAllergyType(Allergies.BIOLOGICAL.id, 'biological')}
								</div>
							</div>
							{props.touched.allergies && props.errors.allergies && (
								<label>
									<span className='red-error'>{props.errors.allergies}</span>
								</label>
							)}
						</div>
					</div>
				)}
			</div>
			{parseInt(props.values.hasAllergy, 10) === AddEditPatientRadioTypes.YES && (
				<>
					<div className='flex'>
						{getAllergiesList(Allergies.FOOD)}
						{getAllergiesList(Allergies.MEDICATION)}
					</div>
					<div className='flex'>
						{getAllergiesList(Allergies.ENVIRONMENTAL)}
						{getAllergiesList(Allergies.BIOLOGICAL)}
					</div>
				</>
			)}
			<h5 className='top-15'>{translate('bodyMeasurements')}</h5>
			<div className='flex personal-details-wrapper'>
				{getMeasurementUnit(MeasurementUnitOptions.HEIGHT)}
				{getMeasurementUnit(MeasurementUnitOptions.WEIGHT)}
			</div>
			<div className='flex personal-details-wrapper'>{getMeasurementUnit(MeasurementUnitOptions.WAIST_CIRCUMFERENCE)}</div>
			<h5 className='top-15'>{translate('cholesterol')}</h5>
			<div className='flex personal-details-wrapper'>
				{getMeasurementUnit(MeasurementUnitOptions.TOTAL_CHOLESTEROL)}
				{getMeasurementUnit(MeasurementUnitOptions.HDL_CHOLESTEROL)}
			</div>
			<h5 className='top-15'>{translate('otherData')}</h5>
			<div className='flex'>
				<div className='flex column-direction personal-details position-relative registration-select-wrapper health-information-details'>
					<label>
						{translate('sufferFromHypertension')}
						<Select
							value={getValue(props.values.hasHyperTension)}
							placeholder={intl.formatMessage({ id: 'selectOption' })}
							classNamePrefix='react-select'
							options={props.transformArray(props.healthInformationStatusList)}
							onChange={event => props.formikProps?.setFieldValue('hasHyperTension', event.value)}
							isSearchable
						/>
					</label>
				</div>
				<div className='flex column-direction personal-details position-relative registration-select-wrapper health-information-details'>
					<label>
						{translate('medicalStatus')}
						<Select
							value={getValue(props.values.isTakingMedication)}
							placeholder={intl.formatMessage({ id: 'selectOption' })}
							classNamePrefix='react-select'
							options={props.transformArray(props.healthInformationStatusList)}
							onChange={event => props.formikProps?.setFieldValue('isTakingMedication', event.value)}
							isSearchable
						/>
					</label>
				</div>
			</div>
			<div className='flex'>
				<div className='flex column-direction personal-details position-relative registration-select-wrapper health-information-details'>
					<label>
						{translate('areYouSmoker')}
						<Select
							value={getValue(props.values.isTobaccoSmoker)}
							placeholder={intl.formatMessage({ id: 'selectOption' })}
							classNamePrefix='react-select'
							options={props.transformArray(props.healthInformationStatusList)}
							onChange={event => props.formikProps?.setFieldValue('isTobaccoSmoker', event.value.toString())}
							isSearchable
						/>
					</label>
				</div>
				{(props.values.isTobaccoSmoker === AddEditPatientRadioTypes.NO.toString() ||
					props.values.isTobaccoSmoker === AddEditPatientRadioTypes.YES.toString()) && (
					<div className='row-direction flex right-align-items'>
						{tobaccoSmokeCodes.map(
							item =>
								item.isSmoker === props.values.isTobaccoSmoker && (
									<div className='flex position-relative consent-from-patient margin-right-m'>
										<label className='remember-me position-relative flex'>
											<input
												className='auto-width cursor-pointer'
												type='radio'
												value={item.code}
												onChange={props.onChange}
												name={item.name}
												checked={props.values[item.name] === item.code}
											/>
											<div className='onb-custom-checkbox-wrapper add-patient-checkbox-wrapper cursor-pointer margin-top-s'>
												<div className='onb-custom-checkbox' />
											</div>
											<p className={`${props.values[item.name] === item.code ? 'bold-font' : ''}`}>{translate(item.content)}</p>
										</label>
									</div>
								)
						)}
					</div>
				)}
			</div>
			<label>
				<span className='red-error margin-left-auto'>
					{props.touched?.isTobaccoSmoker &&
						props.values.isTobaccoSmoker !== AddEditPatientRadioTypes.NO_ANSWER.toString() &&
						props.errors?.tobaccoYesCode}
					{props.touched?.isTobaccoSmoker &&
						props.values.isTobaccoSmoker !== AddEditPatientRadioTypes.NO_ANSWER.toString() &&
						props.errors?.tobaccoNoCode}
				</span>
			</label>
			<div className='flex'>
				<div className='flex column-direction personal-details position-relative registration-select-wrapper health-information-details'>
					<label>
						{translate('haveDiabeticStatus')}
						<Select
							value={getValue(props.values.hasDiabet)}
							placeholder={intl.formatMessage({ id: 'selectOption' })}
							classNamePrefix='react-select'
							options={props.transformArray(props.healthInformationStatusList)}
							onChange={event => props.formikProps?.setFieldValue('hasDiabet', event.value.toString())}
							isSearchable
						/>
					</label>
				</div>
				{(props.values.hasDiabet === AddEditPatientRadioTypes.YES.toString() ||
					props.values.hasDiabet === AddEditPatientRadioTypes.NO.toString()) && (
					<div className='row-direction flex right-align-items'>
						{diabeticStatusCodes.map(
							item =>
								item.isDiabetic === props.values.hasDiabet && (
									<div className='flex position-relative consent-from-patient margin-right-m'>
										<label className='remember-me position-relative flex'>
											<input
												className='auto-width cursor-pointer'
												type='radio'
												value={item.code}
												onChange={props.onChange}
												name={item.name}
												checked={props.values[item.name] === item.code}
											/>
											<div className='onb-custom-checkbox-wrapper add-patient-checkbox-wrapper cursor-pointer margin-top-s'>
												<div className='onb-custom-checkbox' />
											</div>
											<p className={`${props.values[item.name] === item.code ? 'bold-font' : ''}`}>{translate(item.content)}</p>
										</label>
									</div>
								)
						)}
					</div>
				)}
			</div>
			{props.touched?.hasDiabet && props.errors?.diabeticStatusCode && (
				<label>
					<span className='red-error margin-left-auto'>{props.errors.diabeticStatusCode}</span>
				</label>
			)}
			<div className='flex'>
				<div className='flex column-direction personal-details position-relative registration-select-wrapper health-information-details'>
					<label>{translate('preExistingCondition')}</label>
					<div className='flex'>{getRadioButtons('hasPreExistingCondition', props.onChange)}</div>
					{props.values.hasPreExistingCondition === AddEditPatientRadioTypes.YES.toString() && (
						<div className='patient-registration-allergy-list'>
							<FormInput
								id='preExistingCondition'
								placeholder={intl.formatMessage({ id: 'preExistingDescription' })}
								type='text'
								value={props.values.preExistingCondition}
								onChange={props.onChange}
								onBlur={props.onBlur}
								className={
									props.errors.preExistingCondition && props.touched.preExistingCondition ? 'text-input error' : 'text-input'
								}
								error={props.errors.preExistingCondition}
								touched={props.touched.preExistingCondition}
								maxLength={256}
								onKeyDown={doNotAllowSpaceAsFirstCharacter}
							/>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default HealthInformation;
