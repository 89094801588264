import React, { useEffect, useRef } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import MaskedTextInput from 'react-text-mask';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import sq from 'date-fns/locale/sq/index.js';
import en from 'date-fns/locale/en-US/index.js';
import Select from 'react-select';
import translate from 'i18n-translations/translate.jsx';
import { capitalizeFirstLetter, validateMaxLength } from 'infrastructure/helpers/commonHelpers.js';
import { getUserRole } from 'infrastructure/auth.js';
import { UserRoles } from 'constants/enums.js';

const PersonalInformation = props => {
	const intl = useIntl();
	const firstNameInput = useRef(null);
	registerLocale('en-US', en);
	registerLocale('sq', sq);
	const locale = useSelector(state => state.language.locale);

	useEffect(() => {
		let timeout = null;
		timeout = setTimeout(() => firstNameInput && firstNameInput.current.focus(), 300);
		return () => {
			clearTimeout(timeout);
		};
	}, []);

	const transformArray = array => array.map(item => ({ value: item.code, label: item.name }));

	return (
		<>
			<h4>{translate('personalInformation')}</h4>

			<div className='flex view-profile-modal-wrapper flex-wrap patient-input-wrapper'>
				{[UserRoles.DOCTOR, UserRoles.NURSE].includes(getUserRole()) && (
					<div>
						<div>
							<p>MRN</p>
							<input type='text' value={props.mrn} readOnly disabled />
						</div>
					</div>
				)}
				<div>
					<div>
						<p>*{translate('firstName')}</p>
						<input
							type='text'
							value={capitalizeFirstLetter(props.values.firstName)}
							onChange={props.onChange}
							onBlur={props.onBlur}
							name='firstName'
							minLength={2}
							maxLength={30}
							ref={firstNameInput}
						/>
					</div>
					<span className='red-error'>{props.errors?.firstName}</span>
				</div>
				<div>
					<div>
						<p>*{translate('lastName')}</p>
						<input
							type='text'
							value={capitalizeFirstLetter(props.values.lastName)}
							onChange={props.onChange}
							onBlur={props.onBlur}
							name='lastName'
							minLength={2}
							maxLength={30}
						/>
					</div>
					<span className='red-error'>{props.errors?.lastName}</span>
				</div>
				<div className='patient-birth-date'>
					<div>
						<p>*{translate('dateOfBirth')}</p>
						<div className='flex'>
							<input
								type='number'
								value={props.values.birthDay}
								onChange={props.onChange}
								name='birthDay'
								className='number-input-wo-arrows'
								onKeyDown={event => validateMaxLength(event, 2)}
								onBlur={val => {
									if (!val.target.value) {
										props.setBirthDateError(intl.formatMessage({ id: 'dateOfBirthRequired' }));
									} else if (val.target.value && props.values.birthYear && props.values.birthMonth) {
										props.setBirthDateError(null);
									}
								}}
								placeholder={`${intl.formatMessage({ id: 'day' })} (DD)`}
							/>
							<DatePicker
								selected={props.values.birthMonth}
								onChange={val => {
									props.setFieldValue('birthMonth', val);
									if (val && props.values.birthYear && props.values.birthDay) {
										props.setBirthDateError(null);
									}
								}}
								showMonthYearPicker
								showFullMonthYearPicker
								onBlur={val => {
									if (!val.target.value) {
										props.setBirthDateError(intl.formatMessage({ id: 'dateOfBirthRequired' }));
									}
								}}
								dateFormat='MMMM'
								placeholderText={`${intl.formatMessage({ id: 'month' })} (MM)`}
								locale={locale}
							/>
							<DatePicker
								selected={props.values.birthYear}
								onChange={val => {
									props.setFieldValue('birthYear', val);
									if (val && props.values.birthMonth && props.values.birthDay) {
										props.setBirthDateError(null);
									}
								}}
								showYearPicker
								onBlur={val => {
									if (!val.target.value) {
										props.setBirthDateError(intl.formatMessage({ id: 'dateOfBirthRequired' }));
									}
								}}
								dateFormat='yyyy'
								placeholderText={`${intl.formatMessage({ id: 'year' })} (YY)`}
								maxDate={new Date()}
								customInput={
									<MaskedTextInput type='text' mask={[/\d/, /\d/, /\d/, /\d/]} onKeyDown={event => validateMaxLength(event, 4)} />
								}
							/>
						</div>
					</div>
					<span className='red-error'>{props.birthDateError}</span>
				</div>
				<div className='position-relative' ref={props.genderWrapperRef}>
					<div>
						<p>*{translate('sex')}</p>
						<div className='flex no-margin sex-select-wrapper' onClick={props.setIsGenderOpen}>
							<input type='select' value={props.values.gender.name} readOnly className='cursor-pointer' />
							<i className='material-icons'>expand_more</i>
						</div>
					</div>
					<span className='red-error'>{props.errors?.gender}</span>
					{props.isGenderOpen && (
						<div className='custom-select-result'>
							{props.genders.map(item => (
								<div
									className='flex cursor-pointer header-elements'
									key={item.id}
									onClick={() => props.toggleGenderDropDown(item)}>
									<p>{item.name}</p>
								</div>
							))}
						</div>
					)}
				</div>
				<div>
					<div>
						<p>*{translate('idNumber')}</p>
						<input
							type='number'
							value={props.values.idNumber}
							onChange={props.onChange}
							onBlur={props.onBlur}
							name='idNumber'
							className='number-input-wo-arrows'
							onKeyDown={event => validateMaxLength(event, 10)}
						/>
					</div>
					<span className='red-error'>{props.errors?.idNumber}</span>
				</div>
				<div>
					<div>
						<p>*{translate('emailAddress')}</p>
						<input
							type='email'
							value={props.values.emailAddress}
							onChange={props.onChange}
							onBlur={props.onBlur}
							name='emailAddress'
							maxLength={100}
							readOnly={props.isEmailReadOnly}
						/>
					</div>
					<span className='red-error'>
						{props.emailExistsError}
						{!props.emailExistsError && props.errors?.emailAddress}
					</span>
				</div>
				<div>
					<div>
						<p>{translate('country')}</p>
						<Select
							value={transformArray(props.countries).filter(option => option.value === props.values.country)}
							placeholder={intl.formatMessage({ id: 'selectCountry' })}
							classNamePrefix='custom-select'
							options={transformArray(props.countries)}
							onChange={event => props.setFieldValue('country', event?.value)}
							isSearchable
							isClearable
						/>
					</div>
				</div>
				<div>
					<div>
						<p>{translate('city')}</p>
						<input type='text' value={props.values.city} onChange={props.onChange} name='city' maxLength={50} />
					</div>
					<span className='red-error'>{props.errors?.city}</span>
				</div>
				<div>
					<div>
						<p>{translate('zipCode')}</p>
						<input
							type='number'
							value={props.values.zipCode}
							onChange={props.onChange}
							name='zipCode'
							className='number-input-wo-arrows'
							maxLength={10}
							onKeyDown={event => validateMaxLength(event, 10)}
						/>
					</div>
				</div>
				<div>
					<div>
						<p>{translate('address')}</p>
						<input type='text' value={props.values.address} onChange={props.onChange} name='address' maxLength={100} />
					</div>
				</div>
				<div>
					<div>
						<p>{translate('phoneNumber')}</p>
						<input
							type='number'
							value={props.values.phoneNumber}
							onChange={props.onChange}
							onBlur={props.onBlur}
							name='phoneNumber'
							className='number-input-wo-arrows'
							onKeyDown={event => validateMaxLength(event, 15)}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default PersonalInformation;
