import React, { useState } from 'react';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { default as ReactSelect } from 'react-select';
import Input from 'components/Common/FormElements/Input.jsx';
import Alert from 'components/Alert.jsx';
import Form from 'components/Form.jsx';
import Modal from 'components/Modal.jsx';
import { addActiveDirectory, addGroupRole, updateActiveDirectory, updateGroupRole } from 'api/activeDirectory.js';
import { ExternalIdentityProviders } from 'constants/enums.js';
import Select from 'components/Common/FormElements/Select.jsx';
import translate from 'i18n-translations/translate.jsx';
import { useSelector } from 'react-redux';
import { IntegrationTypesSettings } from 'constants/configurationEnums.js';
import { getConfigurationValue } from 'infrastructure/helpers/commonHelpers.js';

const ActiveDirectoryForm = props => {
	const [isErrorsAlertOn, setErrorAlertOn] = useState(false);
	const [isCompanyLevelConfiguration, setIsCompanyLevelConfiguration] = useState(
		props.initialValues?.isCompanyLevelConfiguration ?? true
	);
	const [errorText, setErrorText] = useState('');
	const intl = useIntl();
	const translator = id => intl.formatMessage({ id });
	const companyConfigurations = useSelector(state => state.company.companySettings?.companyConfigurations);

	const userRolesList = [
		{
			id: 2,
			value: translator('admin'),
		},
		{
			id: 8,
			value: translator('superUser'),
		},
		{
			id: 11,
			value: translator('doctor'),
		},
		{
			id: 6,
			value: translator('virtualCareProvider'),
		},
		{
			id: 7,
			value: translator('virtualSitter'),
		},
		{
			id: 10,
			value: translator('digitalClinician'),
		},
	];

	const shouldShowConfigType = settingTypeId =>
		getConfigurationValue(companyConfigurations[settingTypeId]) || props.initialValues;

	const configTypes = [
		shouldShowConfigType(IntegrationTypesSettings.AZURE_AD) && {
			value: ExternalIdentityProviders.AZURE,
			label: 'Azure',
		},
		shouldShowConfigType(IntegrationTypesSettings.PING_FEDERATE) && {
			value: ExternalIdentityProviders.PING_FEDERATE,
			label: 'Ping Federate',
		},
		shouldShowConfigType(IntegrationTypesSettings.OKTA) && {
			value: ExternalIdentityProviders.OKTA,
			label: 'OKTA',
		},
	].filter(Boolean);

	const [selectedConfig, setSelectedConfig] = useState(
		props.initialValues?.adConfigurationTypeId
			? configTypes.find(item => item.value === props.initialValues?.adConfigurationTypeId)
			: configTypes[0]
	);

	const getValidationSchema = () => {
		const validation = {};
		if (!props.isGroupRoleModal && !props.initialValues && !isCompanyLevelConfiguration) {
			validation.healthSystemId = Yup.string().required(translator('healthSystemRequired'));
			if (+selectedConfig.value === ExternalIdentityProviders.AZURE) {
				validation.validGroupName = Yup.string().required(translator('setValidGroupNameRequired'));
			}
			return validation;
		}
		if (!props.isGroupRoleModal && isCompanyLevelConfiguration) {
			validation.domain = Yup.string().required(translator('domainRequired'));
			validation.username = Yup.string().required(translator('clientIdRequired'));
			validation.password = Yup.string().required(translator('clientSecretRequired'));
			if (+selectedConfig.value === ExternalIdentityProviders.AZURE) {
				validation.validGroupName = Yup.string().required(translator('setValidGroupNameRequired'));
			}
			return validation;
		}
		if (!props.isGroupRoleModal && props.initialValues) {
			validation.domain = Yup.string().required(translator('domainRequired'));
			validation.username = Yup.string().required(translator('clientIdRequired'));
			validation.password = Yup.string().required(translator('clientSecretRequired'));
			if (+selectedConfig.value === ExternalIdentityProviders.AZURE) {
				validation.validGroupName = Yup.string().required(translator('setValidGroupNameRequired'));
			}
			return validation;
		}
		if (!props.isGroupRoleModal && !props.initialValues) {
			validation.healthSystemId = Yup.object().required(translator('healthSystemRequired'));
			validation.domain = Yup.string().required(translator('domainRequired'));
			validation.username = Yup.string().required(translator('clientIdRequired'));
			validation.password = Yup.string().required(translator('clientSecretRequired'));
			if (+selectedConfig.value === ExternalIdentityProviders.AZURE) {
				validation.validGroupName = Yup.string().required(translator('setValidGroupNameRequired'));
			}
			return validation;
		}
		if (props.isGroupRoleModal && props.initialValues) {
			validation.roleId = Yup.string().required(translator('poolRoleRequired'));
			validation.group = Yup.string().required(translator('groupRolesRequired'));
			return validation;
		}
		validation.healthSystemId = Yup.string().required(translator('healthSystemRequired'));
		validation.roleId = Yup.string().required(translator('poolRoleRequired'));
		validation.group = Yup.string().required(translator('groupRolesRequired'));
		return validation;
	};

	const getDomainDescription = config => {
		const configDomain = {
			[ExternalIdentityProviders.AZURE]: translator('azureAdUrlDescription'),
			[ExternalIdentityProviders.PING_FEDERATE]: 'https://fed.sample.com',
			[ExternalIdentityProviders.OKTA]: 'https://00000000.okta.com/',
		};
		return configDomain[+config];
	};

	const getInitialValues = () => {
		if (props.initialValues) {
			return props.initialValues;
		}
		if (props.isGroupRoleModal) {
			return {
				healthSystemId: null,
				group: '',
				hospitalId: null,
				roleId: '',
			};
		}
		return {
			healthSystemId: null,
			hospitalId: null,
			validGroupName: '',
			username: '',
			password: '',
			domain: '',
			includeRoles: false,
			isSingleSignOutEnabled: false,
			autoAdd: true,
			autoUpdate: true,
			autoDelete: true,
			isCompanyLevelConfiguration: true,
		};
	};

	const handleSubmitMyForm = async (values, { resetForm }) => {
		let response;
		if (props.isGroupRoleModal) {
			if (props.initialValues) {
				response = await updateGroupRole(values.id, { roleId: values.roleId, group: values.group });
			} else {
				response = await addGroupRole(values);
			}
		}
		const getIsAutoAdd = () => {
			if (!isCompanyLevelConfiguration) {
				return values.autoAdd;
			}
			return false;
		};

		if (!props.isGroupRoleModal) {
			if (props.initialValues) {
				response = await updateActiveDirectory(values.id, {
					domain: values.domain,
					username: values.username,
					password: values.password,
					validGroupName: values.validGroupName,
					adConfigurationTypeId: +selectedConfig.value,
					isCompanyLevelConfiguration:
						+selectedConfig.value === ExternalIdentityProviders.AZURE ? isCompanyLevelConfiguration : true,
					includeRoles: values.includeRoles,
					autoAdd: getIsAutoAdd(),
					autoUpdate: +selectedConfig.value === ExternalIdentityProviders.AZURE ? values.autoUpdate : false,
					autoDelete: +selectedConfig.value === ExternalIdentityProviders.AZURE ? values.autoDelete : false,
					isSingleSignOutEnabled: [ExternalIdentityProviders.AZURE, ExternalIdentityProviders.OKTA].includes(
						+selectedConfig.value
					)
						? values.isSingleSignOutEnabled
						: false,
				});
			} else {
				const hospitalId = values.hospitalId || null;
				response = await addActiveDirectory({
					...values,
					hospitalId: isCompanyLevelConfiguration ? null : hospitalId,
					healthSystemId: isCompanyLevelConfiguration ? null : values.healthSystemId,
					validGroupName: values.validGroupName,
					adConfigurationTypeId: +selectedConfig.value,
					isCompanyLevelConfiguration:
						+selectedConfig.value === ExternalIdentityProviders.AZURE ? isCompanyLevelConfiguration : true,
					includeRoles: isCompanyLevelConfiguration ? null : values.includeRoles,
					autoAdd: getIsAutoAdd(),
					autoDelete: +selectedConfig.value === ExternalIdentityProviders.AZURE ? values.autoDelete : false,
					autoUpdate: +selectedConfig.value === ExternalIdentityProviders.AZURE ? values.autoUpdate : false,
					isSingleSignOutEnabled: [ExternalIdentityProviders.AZURE, ExternalIdentityProviders.OKTA].includes(
						+selectedConfig.value
					)
						? values.isSingleSignOutEnabled
						: false,
				});
			}
		}
		if (response.error) {
			setErrorAlertOn(true);
			setErrorText(response.error.message);
			return;
		}
		resetForm();
		props.onSucceeded();
	};

	const onCloseModal = resetForm => {
		setErrorText('');
		setErrorAlertOn(false);
		resetForm();
		props.toggleModal();
	};

	const handlePreventDefaultOnEnter = event => {
		if (event.key === 'Enter') event.preventDefault();
	};

	return (
		<Formik
			enableReinitialize={true}
			initialValues={getInitialValues()}
			validationSchema={Yup.object().shape(getValidationSchema())}
			onSubmit={handleSubmitMyForm}>
			{({ values, handleSubmit, isSubmitting, resetForm, setFieldValue }) => (
				<Modal
					display={props.isModalOpen}
					position='right'
					onModalSubmit={handleSubmit}
					onModalClose={() => onCloseModal(resetForm)}
					onKeyDown={handlePreventDefaultOnEnter}
					isLoading={isSubmitting}>
					<Form>
						{props.isGroupRoleModal && (
							<>
								{!props.initialValues && (
									<Field
										name='healthSystemId'
										type='select'
										label={translator('selectHealthSystem')}
										placeholder={translator('selectHealthSystem')}
										items={props.healthSystems}
										onChange={props.onHealthSystemChange}
										value={values.healthSystemId}
										component={Select}
									/>
								)}
								{!props.initialValues && (
									<Field
										name='hospitalId'
										type='select'
										label={translator('selectHospital')}
										placeholder={translator('hospital')}
										items={props.hospitals}
										value={values.hospitalId}
										onChange={props.onHospitalChange}
										component={Select}
									/>
								)}
								{props.initialValues && (
									<Field name='hospitalId' label={translator('name')} description='' disabled={true} component={Input} />
								)}
								<Field
									name='roleId'
									type='select'
									label={translator('selectGroupRole')}
									placeholder={translator('selectGroupRole')}
									items={userRolesList}
									value={values.roleId}
									component={Select}
								/>
								<Field
									name='group'
									type='text'
									label={translator('group')}
									description={translator('typeNameForPool')}
									component={Input}
									value={values.group}
								/>
							</>
						)}
						{!props.isGroupRoleModal && (
							<>
								<h3>{translator('externalIdentityProviders')}</h3>
								<div className='input'>
									<p className='label'>{translator('selectConfiguration')}</p>
									<p className='font-14'>{translator('selectConfigurationDesc')}</p>
									<ReactSelect
										isDisabled={props.initialValues?.adConfigurationTypeId}
										value={selectedConfig}
										placeholder={intl.formatMessage({ id: 'selectConfiguration' })}
										classNamePrefix='react-select'
										options={configTypes}
										onChange={event => {
											setIsCompanyLevelConfiguration(true);
											setSelectedConfig(event);
										}}
									/>
								</div>
								{+selectedConfig.value === ExternalIdentityProviders.AZURE && (
									<div className='bottom-30'>
										<Field
											type='checkbox'
											checked={isCompanyLevelConfiguration}
											name='isCompanyLevelConfiguration'
											onChange={() => {
												setIsCompanyLevelConfiguration(prevState => !prevState);
												setFieldValue('autoUpdate', !isCompanyLevelConfiguration);
												setFieldValue('autoDelete', !isCompanyLevelConfiguration);
											}}
											disabled={props.initialValues}
										/>
										<label className='font-14'>{translator('isCompanyLevelConfiguration')}</label>
									</div>
								)}
								{!props.initialValues && !isCompanyLevelConfiguration && (
									<Field
										name='healthSystemId'
										type='select'
										label={translator('selectHealthSystem')}
										placeholder={translator('selectHealthSystem')}
										items={props.healthSystems}
										onChange={props.onHealthSystemChange}
										value={values.healthSystemId}
										component={Select}
									/>
								)}
								{!props.initialValues && !isCompanyLevelConfiguration && (
									<Field
										name='hospitalId'
										type='select'
										label={translator('selectHospital')}
										placeholder={translator('hospital')}
										items={props.hospitals}
										value={values.hospitalId}
										onChange={props.onHospitalChange}
										component={Select}
									/>
								)}
								{props.initialValues && !isCompanyLevelConfiguration && (
									<Field
										name='hospitalId'
										label={translator('name')}
										value={values.hospitalId}
										disabled={true}
										component={Input}
									/>
								)}
								<Field
									name='domain'
									type='text'
									label={translator(+selectedConfig.value === ExternalIdentityProviders.AZURE ? 'azureAdUrl' : 'domain')}
									description={getDomainDescription(selectedConfig.value)}
									value={values.domain}
									component={Input}
								/>
								{+selectedConfig.value === ExternalIdentityProviders.AZURE && (
									<Field
										name='validGroupName'
										type='text'
										label={translator('setValidGroupName')}
										description={translator('setValidGroupNameDescription')}
										value={values.validGroupName}
										component={Input}
									/>
								)}
								<Field
									name='username'
									type='text'
									label={translator('clientId')}
									description={translate('clientIdDescription', {
										value:
											+selectedConfig.value === ExternalIdentityProviders.AZURE
												? translator('activeDirectory')
												: selectedConfig.label,
									})}
									component={Input}
									value={values.username}
								/>
								<Field
									name='password'
									type='password'
									label={translator('clientSecret')}
									description={translate('clientSecretDescription', {
										value:
											+selectedConfig.value === ExternalIdentityProviders.AZURE
												? translator('activeDirectory')
												: selectedConfig.label,
									})}
									component={Input}
									value={values.password}
								/>
								{[ExternalIdentityProviders.AZURE, ExternalIdentityProviders.OKTA].includes(+selectedConfig.value) && (
									<div>
										<Field type='checkbox' name='isSingleSignOutEnabled' />
										<label className='font-14'>{translator('isSingleSignOutEnabled')}</label>
									</div>
								)}
								{+selectedConfig.value === ExternalIdentityProviders.AZURE && (
									<>
										{!isCompanyLevelConfiguration && (
											<div>
												<Field type='checkbox' name='includeRoles' />
												<label className='font-14'>{translator('includeRoles')}</label>
											</div>
										)}
										{!isCompanyLevelConfiguration && (
											<div>
												<Field type='checkbox' name='autoAdd' />
												<label className='font-14'>{translator('autoAdd')}</label>
											</div>
										)}
										<div>
											<Field type='checkbox' name='autoUpdate' />
											<label className='font-14'>{translator('autoUpdate')}</label>
										</div>
										<div>
											<Field type='checkbox' name='autoDelete' />
											<label className='font-14'>{translator('autoDelete')}</label>
										</div>
									</>
								)}
							</>
						)}
						<Alert
							alertSelector='networkAccessConfigsMessage'
							display={isErrorsAlertOn}
							message={translator(errorText || 'somethingWentWrong')}
							variant='error'
							onClose={() => {
								setErrorAlertOn(false);
								setErrorText('');
							}}
						/>
					</Form>
				</Modal>
			)}
		</Formik>
	);
};

export default ActiveDirectoryForm;
