import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import translate from 'i18n-translations/translate.jsx';
import Select from 'react-select';
import { generateCustomStyles } from 'constants/react-select-style.js';
import { useSelector } from 'react-redux';
import { updatePatientsAiSettings } from 'api/patients.js';
import Alert from 'components/Alert.jsx';
import { AiNotificationTypes, StatNotificationTypes } from 'constants/ai.js';
import { getSelectedAiType } from 'infrastructure/helpers/aiHelper.js';

const AiConfigurationDetails = ({
	setCheckedItems,
	setRadioButtons,
	setIsItemClicked,
	settingTypeId,
	checkedItems,
	radioButtons,
	aiSettingList,
	deviceId,
	patientId,
	roomId,
}) => {
	const intl = useIntl();
	const foundAiSettings = aiSettingList.find(item => item.deviceId === deviceId);
	const selectedSetting = foundAiSettings ? foundAiSettings.settings.find(item => item.settingTypeId === settingTypeId) : null;
	const user = useSelector(state => state.user);
	const [selectedSnoozeTime, setSelectedSnoozeTime] = useState(null);
	const [error, setError] = useState(null);

	const snoozeTimers = useRef([
		{ label: `30 ${intl.formatMessage({ id: 'seconds' })}`, value: 30 },
		{ label: `1 ${intl.formatMessage({ id: 'minute' })}`, value: 60 },
		{ label: `2 ${intl.formatMessage({ id: 'minutes' })}`, value: 120 },
		{ label: `5 ${intl.formatMessage({ id: 'minutes' })}`, value: 300 },
		{ label: `10 ${intl.formatMessage({ id: 'minutes' })}`, value: 600 },
		{ label: `30 ${intl.formatMessage({ id: 'minutes' })}`, value: 1800 },
		{ label: `1 ${intl.formatMessage({ id: 'hour' })}`, value: 3600 },
	]);

	useEffect(() => {
		setSelectedSnoozeTime({
			label: snoozeTimers.current.find(item => item.value === selectedSetting?.snoozeTime)?.label,
			value: selectedSetting?.snoozeTime,
		});
	}, []);

	const handleCheckboxChange = (id, settingTypeId) => {
		setIsItemClicked(prevState => !prevState);
		setCheckedItems(prevCheckedItems => {
			const isChecked = prevCheckedItems.some(item => item.settingTypeId === settingTypeId);

			if (isChecked) {
				return prevCheckedItems.map(item =>
					item.settingTypeId === settingTypeId
						? {
								...item,
								notificationOptions: item.notificationOptions.includes(id)
									? item.notificationOptions.filter(notificationOptions => notificationOptions !== id)
									: [...item.notificationOptions, id],
						  }
						: item
				);
			} else {
				return [...prevCheckedItems, { settingTypeId, notificationOptions: [id] }];
			}
		});
	};

	const handleRadioChange = (id, settingTypeId) => {
		setIsItemClicked(prevState => !prevState);
		setRadioButtons(prevCheckedItems => {
			const isChecked = prevCheckedItems.some(item => item.settingTypeId === settingTypeId);
			if (isChecked) {
				return prevCheckedItems.map(item =>
					item.settingTypeId === settingTypeId
						? {
								...item,
								notificationOptions: [id],
						  }
						: item
				);
			} else {
				return [...prevCheckedItems, { settingTypeId, notificationOptions: [id] }];
			}
		});
	};

	const isOptionChecked = alertId =>
		checkedItems.find(el => el.settingTypeId === settingTypeId)?.notificationOptions?.some(item => item === alertId);

	const isRadioBtnEnabled = alertId =>
		radioButtons.find(el => el.settingTypeId === settingTypeId)?.notificationOptions?.some(item => item === alertId);

	const handleChange = async item => {
		setSelectedSnoozeTime(item);
		const dataToSend = [
			{
				settingTypeId,
				value: selectedSetting?.value ?? null,
				snoozeTime: item?.value ?? null,
			},
		];

		const params = {
			patientId,
			deviceId,
			roomId,
			sendData: dataToSend,
		};

		const response = await updatePatientsAiSettings(params);
		if (response.error) {
			setError(response.error.message);
		}
	};

	return (
		<>
			<div className='ai-configurations no-margin'>
				<p className='semi-bold font-14 margin-bottom-0'>{translate('alerts')}</p>
				<div className='flex flex-wrap margin-top-s no-border-bottom margin-bottom-0'>
					{AiNotificationTypes.map(alert => (
						<div className='flex position-relative consent-from-patient margin-right-m margin-top-s'>
							<label className='remember-me cursor-pointer position-relative flex' key={alert.id}>
								<input
									className='auto-width ai-configuration-details'
									type='radio'
									checked={isRadioBtnEnabled(alert.id)}
									value={alert.id}
									name='aiAlertType'
									onChange={() => handleRadioChange(alert.id, settingTypeId)}
								/>
								<div className='onb-custom-checkbox-wrapper add-patient-checkbox-wrapper ai-configurations-checkbox'>
									<div className='onb-custom-checkbox full-border-radius' />
								</div>
								<p className='left-s font-14'>{translate(alert.name)}</p>
							</label>
						</div>
					))}
				</div>
			</div>
			<div className='doctor-profile-settings ai-configurations no-margin'>
				<p className='semi-bold font-14'>{translate('statAlarmActivation')}</p>
				<div className='flex margin-top-m no-border-bottom'>
					{StatNotificationTypes.map(alert => (
						<div className='register-practice-modal-grid tick-box-active-input' key={alert.id}>
							<label className='flex remember-me cursor-pointer no-margin'>
								<input
									type='checkbox'
									name={alert.name}
									checked={isOptionChecked(alert.id)}
									onChange={() => handleCheckboxChange(alert.id, settingTypeId)}
								/>
								<div className='onb-custom-checkbox-wrapper cursor-pointer top-0 ai-configurations-checkbox'>
									<div className='onb-custom-checkbox' />
								</div>
								<span className='font-14 left-s margin-right-xxl'>{translate(alert.name)}</span>
							</label>
						</div>
					))}
				</div>
				<p className='semi-bold top-15 font-14'>
					{translate('snoozeTimerForAlert', {
						value: `${intl.formatMessage({ id: getSelectedAiType(settingTypeId) })}`,
					})}
				</p>
				<div className='flex full-width top-15 ai-configurations-select no-border-bottom margin-bottom-0'>
					<Select
						options={snoozeTimers?.current}
						value={selectedSnoozeTime}
						placeholder={translate('selectTimer')}
						onChange={handleChange}
						classNamePrefix='react-select'
						styles={generateCustomStyles({ darkMode: user.darkMode })}
					/>
				</div>
			</div>
			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
		</>
	);
};

export default AiConfigurationDetails;
