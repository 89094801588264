import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import styled, { createGlobalStyle } from 'styled-components';
import { LocalTrackFactory, Cam, Mic, ScreenShare } from '@solaborate/calls/webrtc';

import { useIntl } from 'react-intl';
import { enums } from '@solaborate/calls';
import { isAuthenticated, getUserInfo, unbindUserManagerEvents } from 'infrastructure/auth.js';
import { Button, Input, IconButton, Loader, Popup } from 'calls/components/index.js';
import { addGuestAccount, checkActiveConference } from 'calls/api/index.js';
import LightTheme from 'calls/styles/LightTheme.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import { UserTypes, MediaDeviceKinds } from 'calls/enums/index.js';
import { getUserRoleId, getSavedStreamSettings } from 'calls/helpers/index.js';
import translate from 'i18n-translations/translate.jsx';
import { StreamSettingsView } from 'calls/views/index.js';
import { getStorage } from 'infrastructure/helpers/commonHelpers.js';
import ConferenceInitData from 'calls/ConferenceInitData.js';
import { ButtonType } from 'constants/enums.js';

const StyledJoinAsGuest = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	background: ${LightTheme.colors.grayFive};
	overflow: auto;

	> section {
		width: 960px;
		display: flex;
		align-items: center;
		margin: auto;

		h1,
		h2,
		p {
			margin: 0;
			padding: 0;
			color: ${LightTheme.colors.grayZero};
		}

		h1 {
			font-size: 18px;
			margin-bottom: ${LightTheme.spacing[2]}px;
		}

		h2 {
			font-size: 16px;
		}

		main {
			position: relative;
			flex: 2;
			aspect-ratio: 16/9;
			background: ${LightTheme.colors.graySix};
			border-radius: ${LightTheme.borderRadius.base}px;
			box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
			overflow: hidden;

			> video {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			> header,
			> div {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: flex-end;
				padding: ${LightTheme.spacing[5]}px;

				> button {
					margin-right: ${LightTheme.spacing[2]}px;
				}
			}

			> header {
				align-items: center;
				flex-direction: column;

				> div {
					margin-bottom: ${LightTheme.spacing[2]}px;
				}
			}
		}

		aside {
			padding: ${LightTheme.spacing[8]}px;
			flex: 1;
		}
	}

	@media (max-width: 768px) {
		min-height: 100vh;
		> section {
			flex-direction: column;
			padding: ${LightTheme.spacing[5]}px;

			> main {
				width: 100%;
			}

			> aside {
				padding: ${LightTheme.spacing[7]}px ${LightTheme.spacing[5]}px;
			}
		}
	}
`;

const GlobalStyle = createGlobalStyle`
	html,
	body {
		margin: 0;
		padding: 0;
		height: 100%;
	}

	#root {
		display: flex;
		height: 100%;
	}
`;

const JoinAsGuest = ({ location }) => {
	const history = useHistory();
	/** @type {import('react-router').match<{ conferenceId: string, invitationSecret: string, callType: string, deviceId: string, name: string, startConferenceId: string, refToken: string }>} */
	const match = useRouteMatch();
	const intl = useIntl();
	const queryParams = new URLSearchParams(location.search);
	const [activeConference, setActiveConference] = useState(null);
	const [startConferenceData, setStartConferenceData] = useState(null);
	const [tracks, setTracks] = useState({});
	const [name, setName] = useState('');
	const [, setShowStreamErrorMessage] = useState(false);
	const [loadingConference, setLoadingConference] = useState(true);
	const [isJoiningConference, setIsJoiningConference] = useState(false);
	const [isStreamSettingsModalOpen, setIsStreamSettingsModalOpen] = useState(false);

	const socket = useContext(SocketContext);

	const videoRef = useRef(null);
	const localTrackFactoryRef = useRef(null);

	useEffect(() => {
		const {
			conferenceId,
			invitationSecret,
			callType,
			deviceId,
			name: startName,
			startConferenceId,
			refToken: queryRefToken,
			iid,
		} = match.params;
		const refToken = queryRefToken ?? getStorage().getItem('ref_token');
		const integrationId = iid ?? getStorage().getItem('iframe_integration_id');

		const initCheckConference = async () => {
			const response = await checkActiveConference(conferenceId, invitationSecret, queryParams.get('interpreterId'));

			setLoadingConference(false);

			if (!response.exists) {
				return;
			}

			setActiveConference({ ...response.conference, refToken });
			getStorage().removeItem('ref_token');
			const interpreterName = response.conference?.interpreter?.firstName;
			if (interpreterName) {
				setName(interpreterName);
			}
			createLocalTracks();
		};

		if (conferenceId) {
			initCheckConference();
		} else {
			setLoadingConference(false);
			setStartConferenceData({
				conferenceId: startConferenceId,
				callType,
				actioneeObject: { actioneeObjectId: +deviceId, actioneeObjectType: enums.ObjectTypes.HELLO_DEVICE },
				name: startName,
				refToken,
				iid: +integrationId,
			});
			getStorage().removeItem('ref_token');
			getStorage().removeItem('iframe_integration_id');
			createLocalTracks();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [match.params]);

	const createLocalTracks = async () => {
		const { [MediaDeviceKinds.AUDIO_INPUT]: audioInputId, [MediaDeviceKinds.VIDEO_INPUT]: videoInputId } =
			getSavedStreamSettings();

		/** @type {import('@solaborate/calls/webrtc').Constraints} */
		const constraints = { [Mic]: true, [Cam]: true, [ScreenShare]: false };
		if (audioInputId) {
			constraints.audio = { deviceId: audioInputId };
		}
		if (videoInputId) {
			constraints.video = { deviceId: videoInputId };
		}

		try {
			localTrackFactoryRef.current = new LocalTrackFactory(constraints);

			setLocalTracks(await localTrackFactoryRef.current.createTracks([Mic, Cam]));
		} catch (error) {
			setShowStreamErrorMessage(true);
		}
	};

	const setLocalTracks = (createdFactoryTracks, toggleTrackType) => {
		const localTracks = createdFactoryTracks.reduce((acc, track) => ({ ...acc, [track.type]: track.track }), {});
		setTracks(localTracks);

		if (!toggleTrackType || toggleTrackType !== Mic) {
			videoRef.current.srcObject = new MediaStream(createdFactoryTracks.map(tr => tr.track));
		}
	};

	const toggleTrack = async trackType => {
		const { current: localTrackFactory } = localTrackFactoryRef;
		if (tracks[trackType]) {
			tracks[trackType].stop();
			delete tracks[trackType];
			localTrackFactory.tracks.delete(trackType);
		} else {
			await localTrackFactory.createTracks(trackType);
		}

		setLocalTracks([...localTrackFactory.tracks.values()], trackType);
	};

	const joinConference = () => {
		if (!activeConference && !startConferenceData) {
			return;
		}

		const conferenceData = new ConferenceInitData({
			callType: activeConference ? activeConference.initialCallType : +startConferenceData.callType,
			...(activeConference ? { conferenceId: activeConference.id } : { conferenceId: startConferenceData.conferenceId }),
			conferenceName: activeConference ? activeConference.name : startConferenceData.name,
			...(activeConference ? { conferenceLink: activeConference.link } : null),
			localTrackTypes: Object.keys(tracks),
			isGuest: true,
			...(activeConference?.interpreter?.uid ? { interpreterId: activeConference.interpreter.uid } : null),
			...(startConferenceData
				? {
						actioneeObjectId: startConferenceData.actioneeObject.actioneeObjectId,
						actioneeObjectType: startConferenceData.actioneeObject.actioneeObjectType,
				  }
				: null),
			refToken: activeConference ? activeConference.refToken : startConferenceData.refToken,
			isJoin: !startConferenceData,
			...(startConferenceData?.integrationId ? { integrationId: +startConferenceData.integrationId } : {}),
		});

		if (localTrackFactoryRef.current) {
			localTrackFactoryRef.current.destroy();
		}

		history.replace('/call', conferenceData);
	};

	const askToJoin = async () => {
		setIsJoiningConference(true);
		const { conferenceId, invitationSecret } = match.params;
		const activeConf = await checkActiveConference(conferenceId, invitationSecret);
		if (!activeConf?.exists) {
			setLoadingConference(false);
			setActiveConference(false);
			return;
		}

		const user = await addGuestAccount(name, conferenceId, invitationSecret);
		if (user) {
			if (getUserRoleId() === UserTypes.GUEST) {
				unbindUserManagerEvents();
			}
			await socket.doConnect();
			joinConference();
		}
	};

	return (
		<StyledJoinAsGuest>
			<GlobalStyle />
			<section>
				<main>
					<video ref={videoRef} autoPlay muted playsInline />
					{loadingConference && (
						<header>
							<Loader color='white' />
							<p> {translate('conferenceLoading')}</p>
						</header>
					)}
					{!loadingConference && !tracks[Cam] && (
						<header>
							<p>{translate('cameraIsOff')}</p>
						</header>
					)}
					<div>
						<IconButton
							icon={tracks[Cam] ? 'videocam' : 'videocam_off'}
							background={LightTheme.colors.grayZero}
							color={LightTheme.colors.grayFive}
							onClick={evt => {
								evt.stopPropagation();
								toggleTrack(Cam);
							}}
						/>
						<IconButton
							icon={tracks[Mic] ? 'mic_none' : 'mic_off'}
							background={LightTheme.colors.grayZero}
							color={LightTheme.colors.grayFive}
							onClick={evt => {
								evt.stopPropagation();
								toggleTrack(Mic);
							}}
						/>
						<IconButton
							icon='settings'
							background={LightTheme.colors.grayZero}
							color={LightTheme.colors.grayFive}
							onClick={evt => {
								evt.stopPropagation();
								setIsStreamSettingsModalOpen(true);
							}}
						/>
					</div>
				</main>
				<aside>
					{isAuthenticated() && (
						<>
							<h1>
								{translate('youAreLoggedInAs')} {getUserInfo().firstName} {getUserInfo().lastName}.
							</h1>
							<Button
								type='submit'
								variant={ButtonType.SUBMIT}
								onClick={ev => {
									ev.preventDefault();
									joinConference();
								}}>
								{translate('joinNow')}
							</Button>
						</>
					)}
					{!isAuthenticated() && activeConference?.interpreter && (
						<>
							<h1>{translate('youAreAboutToParticipate')}</h1>
							<form>
								<h1>
									{translate('youAreLoggedInAs')} {activeConference.interpreter.firstName}
								</h1>
								<Button
									type='submit'
									variant={ButtonType.SUBMIT}
									onClick={ev => {
										ev.preventDefault();
										if (name.length > 1) {
											askToJoin();
										}
									}}>
									{translate('joinNow')}
								</Button>
							</form>
						</>
					)}
					{!isAuthenticated() && !activeConference?.interpreter && (
						<>
							<h1>{translate('youAreAboutToParticipate')}</h1>
							<h2>{translate('pleaseIntroduceYourself')}</h2>
							<form>
								<Input>
									<Input.Label>{translate('yourName')}</Input.Label>
									<Input.Field
										id='stress-test-guest-name-input'
										placeholder={intl.formatMessage({ id: 'yourName' })}
										value={name}
										onChange={event => setName(event.target.value)}
									/>
									{name.length < 1 && <Input.Description>{translate('firstNameRequired')}</Input.Description>}
								</Input>
								<Button
									id='stress-test-ask-to-join-button'
									type='submit'
									variant={ButtonType.SUBMIT}
									onClick={ev => {
										ev.preventDefault();
										if (name.length > 1) {
											askToJoin();
										}
									}}>
									{translate('askToJoin')}
								</Button>
							</form>
						</>
					)}
				</aside>
			</section>
			{!loadingConference && !activeConference && !startConferenceData && (
				<Popup containerWidth={600}>
					<h4>{translate('conferenceNotActive')}</h4>
				</Popup>
			)}
			{isJoiningConference && (
				<Popup containerWidth={600}>
					<Loader color='white' />
					<br />
					<h4>{translate('joiningConference')}</h4>
				</Popup>
			)}
			{isStreamSettingsModalOpen && (
				<StreamSettingsView
					onDismiss={() => setIsStreamSettingsModalOpen(false)}
					localTracks={tracks}
					setLocalTracks={setLocalTracks}
					createLocalTracks={createLocalTracks}
				/>
			)}
		</StyledJoinAsGuest>
	);
};

export default JoinAsGuest;
