import React, { useState } from 'react';
import { Cam, Mic, ScreenShare } from '@solaborate/calls/webrtc';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import translate from 'i18n-translations/translate.jsx';

import { useLocalParticipant, useConferenceConfigurations, useControllerTracks } from 'calls/hooks/index.js';
import {
	ParticipantVideo,
	Participant,
	Avatar,
	ParticipantAudioLevel,
	IconButton,
	StyledParticipantInfo,
	Dropdown,
	List,
	Tooltip,
} from 'calls/components/index.js';
import { PinIcon, UnPinIcon } from 'calls/icons/index.js';
import LightTheme from 'calls/styles/LightTheme.js';

import { buildProfilePic } from 'infrastructure/helpers/thumbnailHelper.js';
import { ControlsActions, UserTypes } from 'calls/enums/index.js';
import { getCallsButtonColor } from 'infrastructure/helpers/commonHelpers.js';

const StyledParticipantActions = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	transition: 0.2s;
	z-index: 1;

	&:hover {
		opacity: 1;
	}

	> div {
		margin-right: ${LightTheme.spacing[2]}px;

		&:last-of-type {
			margin: 0;
		}

		> button {
			box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
		}
	}

	@media (max-width: 768px) {
		> div {
			margin-right: ${LightTheme.spacing[1]}px;

			> button {
				padding: ${LightTheme.spacing[2]}px;
			}
		}
	}
`;

/**
 * @param {object} props
 * @param {import('calls/LocalParticipant.js').default | import('calls/RemoteParticipant.js').default} props.mainParticipant Local or remote participant
 */
const ParticipantInfo = ({ mainParticipant }) => {
	const participant = useLocalParticipant();
	const tracks = useControllerTracks(participant.localTrackController);
	const conferenceConfigs = useConferenceConfigurations();

	if (mainParticipant === participant && !conferenceConfigs.isGridView) {
		return null;
	}

	return (
		<StyledParticipantInfo isGridView={conferenceConfigs.isGridView}>
			{!tracks[Mic] && <IconButton icon='mic_off' background={LightTheme.colors.redOne} color={LightTheme.colors.grayZero} />}
			{tracks[Mic] && <ParticipantAudioLevel track={tracks[Mic].track} />}
		</StyledParticipantInfo>
	);
};

/**
 * @param {object} props
 * @param {(trackType: import('@solaborate/calls/webrtc').TrackType) => void} [props.onClick=null]
 * @param {import('calls/LocalParticipant.js').default | import('calls/RemoteParticipant.js').default} props.mainParticipant Local or remote participant
 */
const LocalParticipantView = ({ onClick = null, mainParticipant }) => {
	const intl = useIntl();
	const participant = useLocalParticipant();
	const tracks = useControllerTracks(participant.localTrackController);
	const conferenceConfigs = useConferenceConfigurations();

	const [showControls, setShowControls] = useState(false);

	const onSetMainParticipant = trackType => {
		if (trackType) {
			onClick(trackType);
			return;
		}

		onClick(!tracks[Cam] && tracks[ScreenShare] ? ScreenShare : Cam);
	};

	const togglePinnedParticipant = () => {
		conferenceConfigs.setPinnedParticipantId(conferenceConfigs.pinnedParticipantId === participant.id ? '' : participant.id);
		if (participant?.role === UserTypes.GUEST) {
			conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_LIVE_EXAMINATIONS, false);
			conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_PATIENT_HISTORY, false);
			conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_DIAGNOSES, false);
			conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_NOTES, false);
			conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_PROCEDURES, false);
			conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_PRESCRIPTIONS, false);
			conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_PHYSICAL_EXERCISES, false);
		}
	};

	return (
		<>
			<Participant
				onClick={() => {
					if (!conferenceConfigs.pinnedParticipantId) {
						onSetMainParticipant();
					}
				}}>
				<Participant.Info>
					{!conferenceConfigs.isGridView && conferenceConfigs.pinnedParticipantId === participant.id && (
						<PinIcon width={18} height={18} color={getCallsButtonColor(conferenceConfigs.isDarkMode)} />
					)}
					{conferenceConfigs.isGridView && <ParticipantInfo mainParticipant={mainParticipant} />}
					<p>{translate('you')}</p>
					{!conferenceConfigs.isGridView && (
						<Participant.Controls>
							<Dropdown isOpen={showControls} onClickOutside={() => setShowControls(false)}>
								<Dropdown.Button onClick={() => setShowControls(prevState => !prevState)} />
								<Dropdown.Items renderIntoPortal={true}>
									<List>
										<List.Item
											onClick={() => {
												togglePinnedParticipant();
												onSetMainParticipant();
												setShowControls(false);
											}}>
											{conferenceConfigs.pinnedParticipantId === participant.id && (
												<UnPinIcon width={18} height={18} color={getCallsButtonColor(conferenceConfigs.isDarkMode)} />
											)}
											{conferenceConfigs.pinnedParticipantId !== participant.id && (
												<PinIcon width={18} height={18} color={getCallsButtonColor(conferenceConfigs.isDarkMode)} />
											)}
											{conferenceConfigs.pinnedParticipantId === participant.id ? translate('unpinFeed') : translate('pinFeed')}
										</List.Item>
									</List>
								</Dropdown.Items>
							</Dropdown>
						</Participant.Controls>
					)}
				</Participant.Info>
				<Participant.Main
					style={{
						display: conferenceConfigs.isGridView || (mainParticipant !== participant && !tracks[ScreenShare]) ? null : 'none',
					}}>
					{tracks[Cam] && <ParticipantVideo track={tracks[Cam]} />}
					{!tracks[Cam] && tracks[ScreenShare] && <ParticipantVideo track={tracks[ScreenShare]} />}
					{!tracks[Cam] && !tracks[ScreenShare] && (
						<Avatar
							size={conferenceConfigs.isGridView ? 60 : 48}
							src={buildProfilePic(participant.picture)}
							name={participant.name}
						/>
					)}
					{conferenceConfigs.isGridView && !conferenceConfigs.isMinimizedView && (
						<StyledParticipantActions>
							<Tooltip
								text={intl.formatMessage({
									id: conferenceConfigs.pinnedParticipantId === participant.id ? 'unpinFeed' : 'pinFeed',
								})}>
								<IconButton
									background={LightTheme.colors.grayZero}
									color={LightTheme.colors.grayFive}
									size={18}
									onClick={evt => {
										evt.stopPropagation();
										togglePinnedParticipant();
										onSetMainParticipant();
										if (conferenceConfigs.pinnedParticipantId !== participant.id) {
											conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_GRID_VIEW);
										}
									}}>
									<PinIcon width={18} height={18} color={getCallsButtonColor(conferenceConfigs.isDarkMode)} />
								</IconButton>
							</Tooltip>
						</StyledParticipantActions>
					)}
				</Participant.Main>
				{!conferenceConfigs.isGridView && <ParticipantInfo mainParticipant={mainParticipant} />}
			</Participant>
			{tracks[Cam] && tracks[ScreenShare] && (
				<Participant onClick={() => onSetMainParticipant(ScreenShare)}>
					<Participant.Info>
						<p>You (Presenting)</p>
					</Participant.Info>
					<Participant.Main>
						<ParticipantVideo track={tracks[ScreenShare]} />
					</Participant.Main>
				</Participant>
			)}
		</>
	);
};

export default LocalParticipantView;
