import CameraMaxZoomLevelsMayo from 'calls/enums/CameraMaxZoomLevelsMayo.js';
import CameraTypes from 'calls/enums/CameraTypes.js';
import SocketEvents from 'constants/socket-events.js';
import React from 'react';

const ZoomButtons = props => {
	const ZoomType = {
		ZOOM_IN: 'zoom_in',
		ZOOM_OUT: 'zoom_out',
	};

	const sendEvent = async (e, type) => {
		e.stopPropagation();

		const max =
			props.activeCamera?.type === CameraTypes.HUDDLE ? CameraMaxZoomLevelsMayo.HUDDLE_MAX : CameraMaxZoomLevelsMayo.HELLO_MAX;

		if (
			(type === ZoomType.ZOOM_OUT && props.activeCamera?.capabilities?.zoom?.current === 0) ||
			(type === ZoomType.ZOOM_IN && props.activeCamera?.capabilities?.zoom?.current === max)
		) {
			return;
		}

		const response = await props.sendCameraEvent(SocketEvents.Conference.ON_MEDIA_CONTROLS, {
			command: 'zoom',
			type: 'camera',
			data: JSON.stringify({
				cameraId: props.activeCamera?.id,
				level:
					type === ZoomType.ZOOM_IN
						? props.activeCamera?.capabilities?.zoom?.current + 1
						: props.activeCamera?.capabilities?.zoom?.current - 1,
			}),
			participantId: props.conferenceInfo.participantId,
			conferenceId: props.conferenceInfo.conferenceId,
			actioneeParticipantId: props.feed.participantId,
		});
		if (response.deviceControlsLocked) {
			props.showDeviceControlsLockedModal();
		}
	};
	return (
		<div className='zoom-buttons'>
			<button type='button' onClick={e => sendEvent(e, ZoomType.ZOOM_IN)}>
				<i className='material-icons-outlined'>add</i>
			</button>
			<button type='button' onClick={e => sendEvent(e, ZoomType.ZOOM_OUT)}>
				<i className='material-icons-outlined'>remove</i>
			</button>
		</div>
	);
};

export default ZoomButtons;
