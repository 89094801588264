import { addDays, subDays } from 'date-fns';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';
import translate from 'i18n-translations/translate.jsx';
import { Alert, EmptyState } from 'components';
import { getMeasurements } from 'api/measurements.js';
import { MeasurementTypes, SpirometerIndex, SpirometerTypes, SpirometerUnits } from 'constants/enums.js';

const SpirometerHistory = props => {
	const intl = useIntl();
	const [spirometerMeasurements, setSpirometerMeasurements] = useState([]);
	const [pageIndex, setPageIndex] = useState(0);
	const [startDate, setStartDate] = useState(subDays(new Date(), 7));
	const [endDate, setEndDate] = useState(new Date());
	const [error, setError] = useState(null);
	const locale = useSelector(state => state.language.locale);
	const [hasReachedEnd, setHasReachedEnd] = useState(false);
	const measurementRecentElement = useRef(null);

	useEffect(() => {
		const getSpirometerMeasurement = async index => {
			const params = {
				patientId: props.selectedPatient?.userId,
				measurementType: MeasurementTypes.SPIROMETER,
				startDate: moment(startDate).format('MM/DD/YYYY'),
				endDate: moment(endDate).format('MM/DD/YYYY'),
				pageIndex: index,
				pageSize: 10,
			};
			const response = await getMeasurements(params);
			if (response.error) {
				setError(response.error.message);
			} else {
				setSpirometerMeasurements(prevState => prevState.concat(response.measurements));
				setHasReachedEnd(response.measurements.length < 10);
			}
		};
		getSpirometerMeasurement(pageIndex);
	}, [startDate, endDate, pageIndex]);

	const handleStartDateChange = date => {
		if (!date) {
			return;
		}
		setStartDate(date);
		setEndDate(addDays(date, 7));
		setPageIndex(0);
		setSpirometerMeasurements([]);
	};

	const handleEndDateChange = date => {
		if (!date) {
			return;
		}
		const daysDifference = moment.duration(moment(startDate).diff(moment(date))).asDays();
		const daysDifferenceInt = parseInt(daysDifference.toString(), 10);
		setEndDate(date);
		setStartDate(prevState => (daysDifferenceInt <= 0 ? prevState : addDays(date, 7)));
		setSpirometerMeasurements([]);
	};

	const handleScroll = e => {
		if (hasReachedEnd) {
			return;
		}
		const difference =
			measurementRecentElement.current.scrollHeight -
			Math.ceil(e.target.scrollTop) -
			measurementRecentElement.current.clientHeight;
		if (difference < 50 && difference > -10) {
			measurementRecentElement.current.scrollBottom += 100;
			setPageIndex(prevState => prevState + 1);
		}
	};

	const getSpirometerArr = value => value.split('/');

	const getSpirometerDetails = (value, index) => {
		let spirometerType,
			spirometerUnit = '';
		switch (index) {
			case SpirometerIndex.ZERO:
				spirometerType = SpirometerTypes.FEV1;
				spirometerUnit = SpirometerUnits.LITER;
				break;
			case SpirometerIndex.ONE:
				spirometerType = SpirometerTypes.PEF;
				spirometerUnit = SpirometerUnits.LITER_MIN;
				break;
			case SpirometerIndex.TWO:
				spirometerType = SpirometerTypes.RES;
				spirometerUnit = SpirometerUnits.PERCENTAGE;
				break;
			default:
				spirometerType = '';
		}
		return (
			<div className='column-direction'>
				<p>{spirometerType}</p>
				<div>
					<span>{value}</span> <span>{spirometerUnit}</span>
				</div>
			</div>
		);
	};

	return (
		<>
			<div className='patient-iot-history-data' onScroll={handleScroll} ref={measurementRecentElement}>
				<div className='flex flex-space-between patient-iot-history-title'>
					<h4 className='text-align-left left-margin-l semi-bold margin-top-m margin-bottom-0'>
						{translate('spirometerMeasurements')}
					</h4>
					<div className='flex patient-iot-start-end-date'>
						<div>
							<DatePicker
								selected={startDate}
								onChange={handleStartDateChange}
								maxDate={new Date()}
								placeholderText={intl.formatMessage({ id: 'selectStartDate' })}
								dateFormat='MMMM d, yyyy'
								locale={locale}
							/>
						</div>
						<div>
							<DatePicker
								selected={endDate}
								onChange={handleEndDateChange}
								minDate={startDate}
								maxDate={addDays(startDate, 7)}
								placeholderText={intl.formatMessage({ id: 'selectEndDate' })}
								dateFormat='MMMM d, yyyy'
								locale={locale}
							/>
						</div>
					</div>
				</div>
				{spirometerMeasurements.length === 0 && (
					<div className='flex flex-align-center flex-justify-center empty-state-wrapper'>
						<EmptyState title={translate('noResultsFound')} image='health-data.svg' />
					</div>
				)}
				<div>
					{spirometerMeasurements.map(item => (
						<div className='spirometer-details' key={item.id}>
							<div className='flex flex-space-between flex-align-center left-10 margin-right-m padding-top-s padding-bottom-m'>
								{getSpirometerArr(item.measurementValue).map((value, valueIndex) => getSpirometerDetails(value, valueIndex))}
								<p>{formattedDate(item.endDate)}</p>
							</div>
						</div>
					))}
				</div>
			</div>
			<Alert display={error} fixed={true} message={error} variant='dark' />
		</>
	);
};

export default SpirometerHistory;
