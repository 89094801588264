import React from 'react';
import classNames from 'classnames';

const ListGroups = props => {
	const onListClick = (event, item) => {
		if (props.onItemClick) {
			props.onItemClick(event, item);
		}
	};

	const onScrollClick = (...args) => {
		if (props.onScroll) {
			props.onScroll(...args);
		}
	};

	return (
		<ul
			className={classNames('list-group', props.className)}
			onScroll={e => {
				onScrollClick(e);
			}}>
			{props.lists.map(item => (
				<li key={item.key || item.title}>
					{!!item.component && item.component}
					{!item.component && (
						<a href={item.linkTo} onClick={e => onListClick(e, item)}>
							{item.imgIcon && <img src={item.imgIcon} alt='icon' />}
							{!item.imgIcon && item.icon && <i className='material-icons-outlined'>{item.icon}</i>}
							{item.title}
							{item.subLists && <ListGroups lists={item.subLists} onItemClick={props.onItemClick} />}
						</a>
					)}
				</li>
			))}
		</ul>
	);
};

const ListGroup = props => (
	<ListGroups lists={props.lists} className={props.className} onScroll={props.onScroll} onItemClick={props.onItemClick} />
);
export default ListGroup;
