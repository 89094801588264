import { getHospitalFloors } from 'api/floors.js';
import { getOrgTeamChildren } from 'api/organization.js';
import { getDepartmentRooms, getHospitalRooms } from 'api/rooms.js';
import { getHealthSystemHospitals } from 'api/userIdleConfigurations.js';
import classNames from 'classnames';
import Alert from 'components/Alert.jsx';
import AllProviders from 'components/DashboardCharts/AllProviders.jsx';
import CallsByInitiator from 'components/DashboardCharts/CallsByInitiator.jsx';
import PatientCentricReport from 'components/DashboardCharts/PatientCentric/PatientCentricReport.jsx';
import ProvidersCentricReport from 'components/DashboardCharts/ProvidersCentricReport.jsx';
import VisitsByHour from 'components/DashboardCharts/VisitsByHour.jsx';
import DateRange from 'components/DateRange.jsx';
import EmptyState from 'components/EmptyState.jsx';
import FormInput from 'components/FormInput.jsx';
import Grid from 'components/Grid.jsx';
import SelectDashboardContent from 'components/SelectDashboardContent.jsx';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from 'components/Tabs.jsx';
import ToastMessage from 'components/ToastMessage.jsx';
import { DashboardSettings } from 'constants/configurationEnums.js';
import { initialInitiatorMonitoringData, initialInitiatorRoundingData, providersRoles, tabEnums } from 'constants/dashboard.js';
import { CallTypes, DeviceListLevel, SessionTimezones, TreeHierarchyType, UserRoles } from 'constants/enums.js';
import { subDays } from 'date-fns';
import translate from 'i18n-translations/translate.jsx';
import Download from 'icons/Dashboard/Download.jsx';
import { getCompanyId, getUserRole } from 'infrastructure/auth.js';
import { getConfigurationValue, getSomeConfigurationsValues } from 'infrastructure/helpers/commonHelpers.js';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import Select from 'react-select';
import MainLayout from 'views/Layouts/MainLayout.jsx';

const Dashboard = () => {
	const userSession = useSelector(state => state.user.userSession);
	const allHealthSystems = useSelector(state => state.healthSystems.allHealthSystems);
	const companyConfigurations = useSelector(state => state.company.companySettings?.companyConfigurations);
	const intl = useIntl();
	const sectionRef = useRef(null);
	const DropdownIndicator = () => <i className='material-icons-outlined'>arrow_drop_down</i>;
	const healthSystemsOptions = allHealthSystems.map(hs => ({ value: hs.id, label: hs.name }));
	const initialHs = healthSystemsOptions.find(item => item.value === userSession.healthSystem.id);
	const maximumRangeOfDays = 90;
	const defaultRangeOfDays = 30;
	const today = new Date();
	const fromDefault = subDays(today, defaultRangeOfDays);
	const toDefault = today;
	const [hospitals, setHospitals] = useState([]);
	const [departments, setDepartments] = useState([]);
	const [floors, setFloors] = useState([]);
	const [rooms, setRooms] = useState([]);
	const [selectedHealthSystem, setSelectedHealthSystem] = useState(initialHs);
	const [selectedHospital, setSelectedHospital] = useState(null);
	const [selectedDepartment, setSelectedDepartment] = useState(null);
	const [selectedFloor, setSelectedFloor] = useState(null);
	const [selectedRoom, setSelectedRoom] = useState(null);
	const [selectedProvider, setSelectedProvider] = useState(null);
	const [selectedLevel, setSelectedLevel] = useState({ levelId: '', levelType: 0 });
	const [dateRange, setDateRange] = useState({ from: fromDefault, to: toDefault });
	const [treeHierarchyTypeId, setTreeHierarchyTypeId] = useState(TreeHierarchyType.DEFAULT_TREE);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [totalNumberOfProviders, setTotalNumberOfProviders] = useState(0);
	const [showDownloadReport, setShowDownloadReport] = useState(false);
	const [timezoneSelected, setTimezoneSelected] = useState(SessionTimezones[0]);
	const defaultLevelValue = 0;
	const [currentTab, setCurrentTab] = useState(0);
	const [searchValue, setSearchValue] = useState('');
	const location = useLocation();
	const tabs = [
		getConfigurationValue(companyConfigurations[DashboardSettings.OVERVIEW]) && {
			id: tabEnums.OVERVIEW,
			description: 'overview',
		},
		getConfigurationValue(companyConfigurations[DashboardSettings.PROVIDER_CENTRIC]) && {
			id: tabEnums.CARE_TEAM_REPORT,
			description: 'careTeamReport',
		},
		getConfigurationValue(companyConfigurations[DashboardSettings.PATIENT_CENTRIC]) && {
			id: tabEnums.PATIENT_REPORT,
			description: 'patientReport',
		},
	].filter(Boolean);

	const allDashboardConfigsDisabled = !getSomeConfigurationsValues(companyConfigurations, [
		DashboardSettings.OVERVIEW,
		DashboardSettings.PATIENT_CENTRIC,
		DashboardSettings.PROVIDER_CENTRIC,
	]);

	useEffect(() => {
		const { state } = location;
		if (state?.prevTab) {
			setCurrentTab(state?.prevTab);
		}
	}, [location]);

	const getHospitalsListOnHsSelect = async hs => {
		setTreeHierarchyTypeId(allHealthSystems.find(item => item.id === hs.value)?.treeHierarchyTypeId);
		const hsHospitals = hs.value !== '0' ? await getHealthSystemHospitals(hs.value) : [];
		if (hsHospitals?.error) {
			setError(translate('somethingWentWrong'));
			setIsLoading(false);
			return;
		}
		setHospitals(hsHospitals);
		setSelectedHospital(null);
		setSelectedDepartment(null);
		setSelectedFloor(null);
		setSelectedRoom(null);
		setIsLoading(false);
	};

	useEffect(() => {
		if (!allDashboardConfigsDisabled) {
			getHospitalsListOnHsSelect(initialHs);
			setSelectedLevel({ levelId: initialHs.value, levelType: DeviceListLevel.HEALTH_SYSTEM });
		}
	}, [userSession.healthSystem.id]);

	const onHealthSystemSelected = async hs => {
		setSelectedLevel({ levelId: hs.value, levelType: DeviceListLevel.HEALTH_SYSTEM });
		setRooms([]);
		setFloors([]);
		setDepartments([]);
		setHospitals([]);
		setSelectedFloor(null);
		setSelectedRoom(null);
		setSelectedDepartment(null);
		setSelectedHospital(null);
		setIsLoading(true);
		setSelectedHealthSystem(hs);
		getHospitalsListOnHsSelect(hs);
	};

	const onHospitalSelected = async hospital => {
		setRooms([]);
		setFloors([]);
		setDepartments([]);
		setSelectedFloor(null);
		setSelectedRoom(null);
		setSelectedDepartment(null);
		if (hospital.value === defaultLevelValue) {
			onHealthSystemSelected(selectedHealthSystem);
			return;
		}
		setSelectedLevel({ levelId: hospital.value, levelType: DeviceListLevel.HOSPITAL });
		setIsLoading(true);
		setSelectedHospital(hospital);
		let response = [];
		if (![TreeHierarchyType.HOSPITAL_FLOOR_ROOM, TreeHierarchyType.HOSPITAL_ROOM].includes(treeHierarchyTypeId)) {
			response = await getOrgTeamChildren(hospital.value);
			setDepartments(response.error ? [] : response);
		}
		if (treeHierarchyTypeId === TreeHierarchyType.HOSPITAL_FLOOR_ROOM) {
			response = await getHospitalFloors(getCompanyId(), selectedHealthSystem.value, hospital.value);
			if (!response.error) {
				setSelectedDepartment({ value: response.departmentId });
				setFloors(response.floors);
			}
		}
		if (treeHierarchyTypeId === TreeHierarchyType.HOSPITAL_ROOM) {
			response = await getHospitalRooms(getCompanyId(), selectedHealthSystem.value, hospital.value);
			if (!response.error) {
				setSelectedDepartment({ value: response.departmentId });
				setSelectedFloor({ value: response.floorId });
				setRooms(response.rooms);
			}
		}

		if (response?.error) {
			setError(translate('somethingWentWrong'));
			setIsLoading(false);
			return;
		}
		setSelectedRoom(null);
		setIsLoading(false);
	};

	const onDepartmentSelected = async dep => {
		setRooms([]);
		setFloors([]);
		setSelectedFloor(null);
		setSelectedRoom(null);
		if (dep.value === defaultLevelValue) {
			onHospitalSelected(selectedHospital);
			return;
		}
		setSelectedLevel({ levelId: dep.value, levelType: DeviceListLevel.DEPARTMENT });
		setIsLoading(true);
		setSelectedDepartment(dep);
		let response = [];
		if (TreeHierarchyType.HOSPITAL_DEPT_ROOM === treeHierarchyTypeId) {
			response = await getDepartmentRooms(selectedHealthSystem.value, dep.value);
			if (!response.error) {
				setSelectedFloor({ value: response.floorId });
				setRooms(response.rooms);
			}
		} else {
			response = await getOrgTeamChildren(dep.value);
			setFloors(response.error ? [] : response);
		}

		if (response?.error) {
			setError(translate('somethingWentWrong'));
			setIsLoading(false);
			return;
		}
		setIsLoading(false);
	};

	const onFloorSelected = async floor => {
		setIsLoading(true);
		if (floor.value === defaultLevelValue) {
			if (TreeHierarchyType.HOSPITAL_FLOOR_ROOM === treeHierarchyTypeId) {
				onHospitalSelected(selectedHospital);
			} else {
				onDepartmentSelected(selectedDepartment);
			}
			setRooms([]);
			setSelectedRoom(null);
			return;
		}
		setSelectedLevel({ levelId: floor.value, levelType: DeviceListLevel.FLOOR });
		setSelectedFloor(floor);
		const floorRooms = await getOrgTeamChildren(floor.value);
		if (floorRooms?.error) {
			setError(translate('somethingWentWrong'));
			setIsLoading(false);
			return;
		}
		setRooms(floorRooms);
		setSelectedRoom(null);
		setIsLoading(false);
	};

	const onRoomSelected = room => {
		if (room.value === defaultLevelValue) {
			if (TreeHierarchyType.HOSPITAL_ROOM === treeHierarchyTypeId) {
				onHospitalSelected(selectedHospital);
			} else if (TreeHierarchyType.HOSPITAL_DEPT_ROOM === treeHierarchyTypeId) {
				onDepartmentSelected(selectedDepartment);
			} else {
				onFloorSelected(selectedFloor);
			}
			return;
		}
		setSelectedLevel({ levelId: room.value, levelType: DeviceListLevel.ROOM });
		setSelectedRoom(room);
	};

	const transformArray = (array, sector) => {
		if (array.length === 0) {
			return [];
		}
		let newArray = array
			.map(item => ({
				value: item.id,
				label: item.name,
				regionId: item.regionId,
			}))
			.sort((a, b) => a.label?.toLowerCase().localeCompare(b.label?.toLowerCase()));
		newArray.unshift({ value: defaultLevelValue, label: intl.formatMessage({ id: sector }) });
		return newArray;
	};

	return (
		<MainLayout>
			<div className='dashboard-view'>
				<main className='main-view'>
					{!selectedProvider && (
						<section ref={sectionRef}>
							<div className='full-width'>
								<>
									<div
										className={classNames(
											'flex flex-align-center dashboard-header',
											allDashboardConfigsDisabled ? 'disabled' : ''
										)}>
										<h3 className='title'>{translate('dashboard')}</h3>
										<label>
											<span>{translate('hospitalTimeZone')}</span>

											<Select
												value={timezoneSelected}
												placeholder={intl.formatMessage({ id: 'hospitalTimeZone' })}
												classNamePrefix='custom-select'
												options={SessionTimezones}
												components={{ DropdownIndicator }}
												onChange={value => setTimezoneSelected(value)}
												isSearchable
											/>
										</label>
										<div className='dashboard-filter'>
											<button type='button' onClick={() => setShowDownloadReport(true)}>
												<Download />
												<span>{translate('download')}</span>
											</button>
										</div>
									</div>
									<ToastMessage className='dashboard' onClose={() => setSuccess(false)} showToast={success} timer={10000}>
										<span>
											<Download color='#33C27F' />
										</span>
										<div>
											<span>{translate('reportSentSuccessfully')}.</span>
											<p>{translate('toAccessCheckEmail')}.</p>
										</div>
									</ToastMessage>
									<div className={classNames('dashboard-filters', allDashboardConfigsDisabled ? 'disabled' : '')}>
										<label>
											<span>{translate('healthSystem')}</span>
											<Select
												value={selectedHealthSystem}
												placeholder={intl.formatMessage({ id: 'healthSystem' })}
												classNamePrefix='custom-select'
												options={healthSystemsOptions}
												components={{ DropdownIndicator }}
												onChange={onHealthSystemSelected}
												isDisabled={getUserRole() !== UserRoles.ADMIN}
											/>
										</label>
										<label>
											<span>{translate('hospital')}</span>
											<Select
												value={selectedHospital}
												placeholder={intl.formatMessage({ id: 'hospital' })}
												classNamePrefix='custom-select'
												options={transformArray(hospitals, 'allHospitals')}
												components={{ DropdownIndicator }}
												onChange={onHospitalSelected}
											/>
										</label>
										{![TreeHierarchyType.HOSPITAL_FLOOR_ROOM, TreeHierarchyType.HOSPITAL_ROOM].includes(treeHierarchyTypeId) && (
											<label>
												<span>{translate('department')}</span>
												<Select
													value={selectedDepartment}
													placeholder={intl.formatMessage({ id: 'department' })}
													classNamePrefix='custom-select'
													options={transformArray(departments, 'allDepartments')}
													components={{ DropdownIndicator }}
													onChange={onDepartmentSelected}
												/>
											</label>
										)}
										{![TreeHierarchyType.HOSPITAL_DEPT_ROOM, TreeHierarchyType.HOSPITAL_ROOM].includes(treeHierarchyTypeId) && (
											<label>
												<span>{translate('floor')}</span>
												<Select
													value={selectedFloor}
													placeholder={intl.formatMessage({ id: 'floor' })}
													classNamePrefix='custom-select'
													options={transformArray(floors, 'allFloors')}
													components={{ DropdownIndicator }}
													onChange={onFloorSelected}
												/>
											</label>
										)}

										<label>
											<span>{translate('room')}</span>
											<Select
												value={selectedRoom}
												placeholder={intl.formatMessage({ id: 'room' })}
												classNamePrefix='custom-select'
												options={transformArray(rooms, 'allRooms')}
												components={{ DropdownIndicator }}
												onChange={onRoomSelected}
											/>
										</label>

										<div className='dashboard-date'>
											<DateRange
												startDate={dateRange.from}
												endDate={dateRange.to}
												handleRangeChange={setDateRange}
												maxDays={maximumRangeOfDays}
												isUSTimesFormat={true}
												isUTCCalendar={true}
												showLabels={true}
											/>
										</div>
									</div>
									{!allDashboardConfigsDisabled && (
										<div className='dashboard-tabs'>
											{[tabEnums.PATIENT_REPORT, tabEnums.CARE_TEAM_REPORT].includes(tabs[currentTab].id) && (
												<div className='flex flex-align-center dashboard-header position-absolute'>
													<label>
														<FormInput
															id='hospitalTimezone'
															name='hospitalTimezone'
															placeholder={intl.formatMessage({
																id: tabs[currentTab].id === tabEnums.PATIENT_REPORT ? 'searchByPatientNameMrn' : 'search',
															})}
															type='text'
															onChange={event => setSearchValue(event.target.value)}
															error={null}
															value={searchValue}
															maxLength={10}
															labelClassName='header-searchbar no-padding-left no-padding-right'
															validateOnSubmit={true}
														/>
													</label>
												</div>
											)}
											<Tabs activeIndex={currentTab} onChange={index => setCurrentTab(index)}>
												<TabList>
													{tabs.map(tab => (
														<Tab key={tab.id}>{translate(tab.description)}</Tab>
													))}
												</TabList>
												<TabPanels>
													{getConfigurationValue(companyConfigurations[DashboardSettings.OVERVIEW]) && (
														<TabPanel>
															<div className='dashboard-items'>
																<VisitsByHour
																	start={dateRange.from}
																	end={dateRange.to}
																	selectedHealthSystem={selectedHealthSystem}
																	selectedHospital={selectedHospital}
																	selectedDepartment={selectedDepartment}
																	selectedFloor={selectedFloor}
																	selectedRoom={selectedRoom}
																	selectedTimezone={timezoneSelected}
																	xLabelScales={2}
																/>
															</div>
															<Grid columns='repeat(2, 1fr)' gridGap='10px' className='dashboard-items top-10'>
																<CallsByInitiator
																	type='rounding'
																	callTypes={[CallTypes.AUDIO, CallTypes.VIDEO, CallTypes.SECURITY_CAM]}
																	start={dateRange.from}
																	end={dateRange.to}
																	selectedHealthSystem={selectedHealthSystem}
																	selectedHospital={selectedHospital}
																	selectedDepartment={selectedDepartment}
																	selectedFloor={selectedFloor}
																	selectedRoom={selectedRoom}
																	initialData={initialInitiatorRoundingData}
																	selectedTimezone={timezoneSelected}
																/>
																<CallsByInitiator
																	type='monitoring'
																	callTypes={[CallTypes.MONITORING]}
																	start={dateRange.from}
																	end={dateRange.to}
																	selectedHealthSystem={selectedHealthSystem}
																	selectedHospital={selectedHospital}
																	selectedDepartment={selectedDepartment}
																	selectedFloor={selectedFloor}
																	selectedRoom={selectedRoom}
																	initialData={initialInitiatorMonitoringData}
																	selectedTimezone={timezoneSelected}
																/>
															</Grid>
														</TabPanel>
													)}
													{getConfigurationValue(companyConfigurations[DashboardSettings.PROVIDER_CENTRIC]) && (
														<TabPanel>
															<ProvidersCentricReport
																searchValue={searchValue}
																setShouldShowAllProviders={value => {
																	setSuccess(false);
																	if (!value) {
																		setSelectedProvider(null);
																	}
																}}
																start={dateRange.from}
																end={dateRange.to}
																selectedHealthSystem={selectedHealthSystem}
																selectedHospital={selectedHospital}
																selectedDepartment={selectedDepartment}
																selectedFloor={selectedFloor}
																selectedRoom={selectedRoom}
																setSelectedProvider={setSelectedProvider}
																setIsChangeLoading={setIsLoading}
																totalNumberOfProviders={totalNumberOfProviders}
																providersRoles={providersRoles}
																selectedTimezone={timezoneSelected}
															/>
														</TabPanel>
													)}
													{getConfigurationValue(companyConfigurations[DashboardSettings.PATIENT_CENTRIC]) && (
														<TabPanel>
															<PatientCentricReport
																currentTab={currentTab}
																start={dateRange.from}
																end={dateRange.to}
																selectedHealthSystem={selectedHealthSystem}
																selectedHospital={selectedHospital}
																selectedDepartment={selectedDepartment}
																selectedFloor={selectedFloor}
																selectedRoom={selectedRoom}
																searchValue={searchValue}
																selectedTimezone={timezoneSelected}
																selectedLevel={selectedLevel}
															/>
														</TabPanel>
													)}
												</TabPanels>
											</Tabs>
										</div>
									)}
									{allDashboardConfigsDisabled && (
										<EmptyState
											className='all-tabs-disabled'
											title={translate('plsReachAdministratorToHaveThisFeatureAvailable')}
											image='no-files.svg'
										/>
									)}
								</>
							</div>
						</section>
					)}
					{selectedProvider && (
						<div style={{ margin: 'var(--spacing-sssl)' }}>
							<AllProviders
								selectedProvider={selectedProvider}
								setSelectedProvider={setSelectedProvider}
								start={dateRange.from}
								end={dateRange.to}
								selectedHealthSystem={selectedHealthSystem}
								selectedHospital={selectedHospital}
								selectedDepartment={selectedDepartment}
								selectedFloor={selectedFloor}
								selectedRoom={selectedRoom}
								isChangeLoading={isLoading}
								setTotalNumberOfProviders={setTotalNumberOfProviders}
								display={true}
								totalNumberOfProviders={totalNumberOfProviders}
								providersRoles={providersRoles}
								selectedTimezone={timezoneSelected}
							/>
						</div>
					)}
				</main>

				<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
				<SelectDashboardContent
					showDownloadReport={showDownloadReport && !allDashboardConfigsDisabled}
					from={dateRange.from}
					to={dateRange.to}
					selectedHealthSystem={selectedHealthSystem}
					selectedHospital={selectedHospital}
					selectedDepartment={selectedDepartment}
					selectedFloor={selectedFloor}
					selectedRoom={selectedRoom}
					setSuccess={setSuccess}
					setShowDownloadReport={setShowDownloadReport}
					selectedTimezone={timezoneSelected}
					setError={setError}
				/>
			</div>
		</MainLayout>
	);
};

export default Dashboard;
