import React, { useState, useEffect } from 'react';
import { publicIp } from 'public-ip';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import Grid from 'components/Grid.jsx';
import ContinueAs from 'containers/ContinueAs.jsx';
import Button from 'components/Button.jsx';
import Alert from 'components/Alert.jsx';
import { getUserRoles } from 'api/roles.js';
import { getUserId } from 'infrastructure/auth.js';
import { getStorage } from 'infrastructure/helpers/commonHelpers.js';


const UserIPError = () => {
	const [ipAddress, setIPAddress] = useState('');
	const [canChangeRole, setCanChangeRole] = useState(false);
	const [showSwitchRole, setShowSwitchRole] = useState(false);
	const [rolesResponseError, setRolesResponseError] = useState(false);
	const intl = useIntl();
	const history = useHistory();

	const translator = id => intl.formatMessage({ id });

	useEffect(() => {
		const getRoles = async () => {
			const rolesResponse = await getUserRoles(getUserId());
			if (rolesResponse.error) {
				setRolesResponseError(rolesResponse.error.message);
				return;
			}
			if (rolesResponse.roles.length > 1) {
				setCanChangeRole(true);
			}
		};
		getRoles();
	}, []);

	useEffect(() => {
		const fetchIpAddress = async () => {
			try {
				const ip = await publicIp();
				setIPAddress(ip);
			} catch (error) {
				setIPAddress('Failed to fetch IP');
			}
		};

		fetchIpAddress();
	}, []);

	const switchRole = () => {
		setShowSwitchRole(true);
		getStorage().removeItem('isContinueAs');
		getStorage().removeItem('IPAddressDenied');
	};

	const signOut = () => {
		history.push('/logout');
	};

	if (showSwitchRole) {
		return <ContinueAs />;
	}

	return (
		<Grid columns='1fr' rows='1fr' stretch='100vh' horizAlign='center' vertAlign='center'>
			<div className='member-error'>
				<h3>{`${translator('notAuthorizedFromThisIP')} ${ipAddress}`}</h3>
				<img src='https://static.solaborate.com/errors/user-ip-error-1.svg' alt='member-error' />
				<p>{translator('ifYouThinkThereAreMistakes')}</p>
				{canChangeRole && <Button text={translator('switchRole')} onClick={switchRole} variant='white' />}{' '}
				<Button text={translator('logOut')} onClick={signOut} />
			</div>
			<Alert display={rolesResponseError} fixed={true} hideCloseButton={true} message={rolesResponseError} variant='dark' />
		</Grid>
	);
};

export default UserIPError;
