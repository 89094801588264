import React, { useEffect, useRef, useState } from 'react';
import styled, { css, createGlobalStyle } from 'styled-components';
import { useHistory, useLocation } from 'react-router';
import { LocalTrackRemoved, RemoteTrackAdded, RemoteTrackRemoved, Cam, Mic, ScreenShare } from '@solaborate/calls/webrtc';
import { conference as Conference, enums, participant as Participant } from '@solaborate/calls';

import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { isChrome, isEdgeChromium, isMobileSafari } from 'react-device-detect';
import classNames from 'classnames';
import {
	MainParticipantView,
	MainParticipantViewMayo,
	CallControlsView,
	ParticipantsView,
	InviteParticipantsView,
	StreamSettingsView,
	VirtualBackgroundView,
	CallControlsViewMayo,
} from 'calls/views/index.js';
import {
	useConference,
	useConferenceConfigurations,
	useConferenceParticipants,
	useConferenceState,
	useLocalParticipant,
	useScreenType,
} from 'calls/hooks/index.js';
import { Toaster, Popup, CameraControls, Button, Modal, CameraControlsMayo } from 'calls/components/index.js';
import {
	callTypeToTrackTypes,
	getUserRoleId,
	captureFrame,
	getConferenceEndedTimeout,
	isMedicalInfoModalsOpen,
} from 'calls/helpers/index.js';
import LightTheme from 'calls/styles/LightTheme.js';
import { ConferenceEndReasonMessages, ControlsActions, StartQueryStringKeys, UserTypes } from 'calls/enums/index.js';
import RemoteParticipant from 'calls/RemoteParticipant.js';
import RemoteHelloParticipant from 'calls/RemoteHelloParticipant.js';
import { VirtualBackgroundProvider } from 'calls/views/VirtualBackgroundProvider.jsx';

// #region Healthcare
import HealthDataContainer from 'containers/HealthDataContainer.jsx';
import CameraMeasurements from 'containers/NewCameraMeasurements.jsx';
import AssignedRequestConferenceFunctions from 'components/AssignedRequestConferenceFunctions.jsx';
import translate from 'i18n-translations/translate.jsx';
import { actionCreators as healthSystemsActionCreators } from 'state/healthSystems/actions.js';
import {
	StreamError,
	MediaPermissions,
	MediaTypes,
	UserPermissionDeniedErrors,
	ErrorComponentTypes,
	UserRoles,
	InviteParticipantFailureReason,
	JoinConferenceFailureReasonEnum,
	AppointmentStatus,
	HealthSystemType,
	ButtonType,
	IframeIntregrationsIds,
	ObjectType,
} from 'constants/enums.js';
import { getCompanyId, getUserRole } from 'infrastructure/auth.js';
import {
	checkForPermission,
	checkIfMediaDevicesPlugged,
	isSmartLauncher,
	isSessionEhr,
	getRoleConfigurationValue,
	findSectorById,
	setUserBackground,
} from 'infrastructure/helpers/commonHelpers.js';
import { APP_CONFIG } from 'constants/global-variables.js';
import { sendDoctorRequest } from 'api/doctorRequests.js';
import LOCALES from 'i18n-translations/translate.jsx';
import VisitorContainer from 'containers/VisitorContainer.jsx';
import { busySound, dropSound, outGoingCallSound, stopOutgoingCallSound } from 'components/CallSounds.jsx';
import { RoundingSettings } from 'constants/configurationEnums.js';
import { RoomTypes } from 'constants/visitEnums.js';
import { updateAppointmentStatus } from 'api/appointments.js';
import { saveVisitEndDateTime } from 'api/medicalInfo.js';
import { updateMedicalVisitStatus } from 'api/visits.js';
import queryString from 'query-string';
import { getCurrentSessionPatientData, getRoomInfo } from 'api/ehr.js';
import CardInfo from 'calls/components/CardInfo.jsx';
import { v4 as uuidv4 } from 'uuid';
import { getBlobSasPicture } from 'api/doctors.js';
import RemoveParticipantModal from 'components/Modal.jsx';
import { getDeviceOwner, getOwnerMrn } from 'api/patients.js';
import Alert from 'components/Alert.jsx';
import Star from 'icons/Rounding/Star.jsx';
import { sendFeedBack } from 'api/callStats.js';
// #endregion Healthcare

const MediaBlockedPopup = (camera, microphone) => {
	const intl = useIntl();
	return (
		<Popup containerWidth={600} background='rgba(0,0,0,.6)'>
			<img src='https://static.solaborate.com/banyan/banyan-camera-permission.svg' alt='Microphone permission browser' />
			<h3>
				{camera && microphone && translate('cameraAndMicBlocked')}
				{camera && !microphone && translate('cameraBlocked')}
				{!camera && microphone && translate('micBlocked')}
			</h3>
			<p>
				{translate('clickMicOrCamera', {
					value: intl.formatMessage({ id: !camera && microphone ? 'microphone' : 'camera' }),
				})}
			</p>
			<p>{translate('allowAccessRefreshPage')}</p>
		</Popup>
	);
};

const RateCallQuality = () => {
	const feedBackArr = [
		{ id: 1, description: translate('veryPoor') },
		{ id: 2, description: translate('poor') },
		{ id: 3, description: translate('good') },
		{ id: 4, description: translate('veryGood') },
		{ id: 5, description: translate('excellent') },
	];
	const darkMode = useSelector(state => state.user.darkMode);
	const [selectedStar, setSelectedStar] = useState(null);
	const [hoveredId, setHoveredId] = useState(null);
	const [comment, setComment] = useState(null);
	const conference = useConference();
	const localParticipant = useLocalParticipant();

	const submitCallQuality = async () => {
		if (!selectedStar) {
			window.close();
			return;
		}
		const params = {
			conferenceId: conference.conferenceId,
			participantId: localParticipant.id,
			rate: selectedStar,
			comment,
		};
		await sendFeedBack(params);
		window.close();
	};

	return (
		<Popup containerWidth={600} background={darkMode ? LightTheme.colors.graySix : LightTheme.colors.grayOne}>
			<div className={classNames('call-feedback', darkMode ? 'dark-mode' : '')}>
				<div className='flex feedback-title'>
					<h4>{translate('howWasQuality')}</h4>
					<span className='modal__close cursor-pointer' onClick={() => window.close()}>
						<i className='material-icons-outlined'>close</i>
					</span>
				</div>
				<div className='flex stars'>
					{feedBackArr.map(item => (
						<div
							onClick={() => setSelectedStar(item.id)}
							onMouseEnter={() => setHoveredId(item.id)}
							onMouseLeave={() => setHoveredId(null)}>
							<Star
								color={selectedStar >= item.id || hoveredId >= item.id ? LightTheme.colors.blueOne : LightTheme.colors.grayTwo}
							/>
							<p>{item.description}</p>
						</div>
					))}
				</div>
				{selectedStar && (
					<div>
						<textarea
							value={comment}
							onChange={event => setComment(event.target.value)}
							className='flex call-feedback-textarea'></textarea>
					</div>
				)}
				<div className='flex'>
					<button className='call-feedback-btn' type='button' onClick={submitCallQuality}>
						{translate(selectedStar ? 'submit' : 'dismiss')}
					</button>
				</div>
			</div>
		</Popup>
	);
};

const CallEnded = ({ refToken = '', busyVisitorParticipant = null, failedInviteMessage }) => {
	const conferenceConfigs = useConferenceConfigurations();
	const history = useHistory();
	const conference = useConference();
	const localParticipant = useLocalParticipant();
	const conferenceState = useConferenceState();
	if (!(conferenceState instanceof Conference.StateEnded)) {
		return null;
	}
	const getConferenceEndedMessage = () => {
		if (conferenceState.endReason === enums.ConferenceEndReasons.PARTICIPANT_INVITE_FAILED) {
			return failedInviteMessage;
		}
		if (getUserRole() === UserRoles.VISITOR) {
			// familymember
			if (conferenceState.endReason === enums.ConferenceEndReasons.PARTICIPANT_BUSY) {
				if (busyVisitorParticipant) {
					return translate('babyRoomDeviceBusy', { value: busyVisitorParticipant.name });
				}
				return translate('deviceIsBusy');
			}

			if (conferenceState.endReason === enums.ConferenceEndReasons.REMOVED) {
				return translate('youAreRemovedFromConference');
			}

			if (
				conferenceState.endReason !== enums.ConferenceEndReasons.TERMINATED_BY_ADMINISTRATOR &&
				conferenceState.endReason !== enums.ConferenceEndReasons.PARTICIPANT_DECLINED
			) {
				return translate('callEnded');
			}

			return conferenceState.endReason === enums.ConferenceEndReasons.TERMINATED_BY_ADMINISTRATOR
				? translate('meetingEndedFamilyMember', { value: conferenceConfigs.helloName })
				: translate('babyOutsideVisitingHoursMessage');
		}

		if (conferenceState.endReason === enums.ConferenceEndReasons.PARTICIPANT_LEFT && localParticipant.isGuest) {
			return translate('youHaveLeftMeeting');
		}

		if (conferenceState.endReason === enums.ConferenceEndReasons.REMOVED) {
			window.history.replaceState({}, document.title);
			return translate('participantRemovedByAdministrator');
		}

		return ConferenceEndReasonMessages[conferenceState.endReason];
	};

	return (
		<Popup containerWidth={600} background={LightTheme.colors.blueThree}>
			<h4>{getConferenceEndedMessage()}</h4>
			{conferenceState.endReason === enums.ConferenceEndReasons.PARTICIPANT_LEFT && localParticipant.isGuest && !refToken && (
				<Button variant={ButtonType.SUBMIT} onClick={() => history.replace(conference.link.replace(/^.*\/\/[^/]+/, ''))}>
					{translate('rejoin')}
				</Button>
			)}
		</Popup>
	);
};

const deviceUnavailable = endReason =>
	[
		enums.ConferenceEndReasons.PARTICIPANT_OFFLINE,
		enums.ConferenceEndReasons.PARTICIPANT_BUSY,
		enums.ConferenceEndReasons.PARTICIPANT_NOT_ANSWERING,
		enums.ConferenceEndReasons.PARTICIPANT_DECLINED,
		enums.ConferenceEndReasons.HAS_ACTIVE_CONFERENCE,
		enums.ConferenceEndReasons.FAILED_TO_GET_INITIATOR_INFO,
		enums.ConferenceEndReasons.PARTICIPANT_INVITE_FAILED,
		enums.ConferenceEndReasons.PARTICIPANT_INVITE_DENIED,
		enums.ConferenceEndReasons.OWNER_LEFT,
		enums.ConferenceEndReasons.TERMINATED_BY_ADMINISTRATOR,
	].includes(endReason);

const ConferenceEndedMessage = ({ refToken = '', busyVisitorParticipant = null, failedInviteMessage }) => {
	const roleRoundingConfigurations = useSelector(state => state.configurations.roleRoundingConfigurations);
	const conferenceState = useConferenceState();

	if (
		deviceUnavailable(conferenceState?.endReason) &&
		getRoleConfigurationValue(roleRoundingConfigurations, RoundingSettings.RateCall)
	) {
		return (
			<Popup containerWidth={600} background={LightTheme.colors.blueThree}>
				<h4>{ConferenceEndReasonMessages[conferenceState?.endReason]}</h4>
			</Popup>
		);
	}

	return (
		<>
			{getRoleConfigurationValue(roleRoundingConfigurations, RoundingSettings.RateCall) && <RateCallQuality />}
			{!getRoleConfigurationValue(roleRoundingConfigurations, RoundingSettings.RateCall) && (
				<CallEnded
					refToken={refToken}
					busyVisitorParticipant={busyVisitorParticipant}
					failedInviteMessage={failedInviteMessage}
				/>
			)}
		</>
	);
};

/**
 * @type {import('styled-components').StyledComponent<"div", any, { isCursorStill: boolean, showCallControls: boolean, isPatientMeasurementsOpen: boolean, isMedicalInfoOpen: boolean, isInviteParticipantsModalViewOpen: boolean, isMultiBed: boolean }, never>}
 */
const StyledConference = styled.div`
	width: 100%;
	height: 100%;
	overflow: hidden;
	background: #2f3640;

	~ .conversation-modal-visible .right-side.measurement-right-side,
	~ .right-side {
		top: 0;
		right: 0;
		height: 100%;
		padding: ${LightTheme.spacing[4]}px;
		z-index: 1;
		border-radius: 0;
		width: 400px;

		h4 {
			padding: 0;
			font-size: 16px;
			margin-bottom: ${LightTheme.spacing[2]}px;
		}
	}

	> main {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		${props =>
			props.isInviteParticipantsModalViewOpen &&
			!props.isPatientMeasurementsOpen &&
			!props.isMedicalInfoOpen &&
			css`
				width: 75%;
				height: 80%;
				margin-top: 100px;
				@media (min-width: 768px) {
					height: 100%;
					margin-top: 0px;
				}
			`}

		${props =>
			props.isMultiBed &&
			css`
				@media (max-width: 768px) {
					max-width: unset;
					width: 166%;
				}
			`}
	}

	${props =>
		(props.isPatientMeasurementsOpen || props.isMedicalInfoOpen || props.isInviteParticipantsModalViewOpen) &&
		css`
			@media (min-width: 769px) {
				> main {
					width: 50%;
					display: flex;
					align-items: center;
					position: relative;
					height: 100vh;

					&:not(.mobile-client-wrapper) {
						> video {
							position: relative;
						}
					}

					> video {
						width: auto;
						object-fit: unset;
						z-index: 1;
						height: 100%;

						&:last-of-type {
							display: ${props.isFitScreen ? 'none' : 'initial'};
						}
					}

					&.less-aspect-ratio:not(.mobile-client-wrapper) {
						> video {
							width: 100%;
							height: auto;
						}
					}
				}
			}
		`}

	${props =>
		(props.isMedicalInfoOpen || props.isInviteParticipantsModalViewOpen) &&
		css`
			@media (min-width: 769px) {
				> main {
					width: 70%;
				}
			}
		`}

	${props =>
		props.isInviteParticipantsModalViewOpen &&
		props.isFitScreen &&
		css`
			> main {
				margin: 0 auto;
			}
		`}

	${props =>
		props.isPatientMeasurementsOpen &&
		props.isInviteParticipantsModalViewOpen &&
		css`
			@media (min-width: 769px) {
				> main {
					width: 70%;
				}
			}
		`}

	@media (max-width: 768px) {
		> section {
			transition: 0.2s;
		}

		> main {
			height: 100vh;
		}
		${props =>
			props.showCallControls &&
			css`
				> section {
					&:last-of-type {
						opacity: 1;
						transform: translateY(0);
					}
				}
			`}

		${props =>
			!props.showCallControls &&
			css`
				> section:last-of-type {
					opacity: 0;
					transform: translateY(100%);
				}
			`}
	}

	~ .patient-chat-grid {
		position: absolute;
		top: ${LightTheme.spacing[5]}px;
		right: ${LightTheme.spacing[5]}px;
		z-index: 10;
		width: 280px;
		.patient-chat {
			height: calc(100% - 120px);
			border-radius: ${LightTheme.borderRadius.base}px;
			overflow: hidden;

			.patient-chat-area {
				border-radius: 0;
			}
		}

		.circle-loader {
			position: static;
		}

		.gif-loading {
			transform: none;
			position: relative;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			left: 0;
			top: 0;
		}
	}

	${props =>
		props.isCursorStill &&
		css`
			> section:last-of-type {
				transition: 0.2s;
				opacity: 0;
				transform: translateY(100%);
			}
		`}
`;

const GlobalStyle = createGlobalStyle`
	html,
	body {
		margin: 0;
		padding: 0;
		height: 100%;
	}

	video::-webkit-media-controls {
		display: none;
	}

	@media (min-width: 1300px) {
		body:hover {
			.monitoring-vital-signs-ai-wrapper.row {
				.vital-signs-ai {
					&.rpm-measurements-ttp {
				      bottom: 100px;
			        }
		        }
	        }
			.call-wrapper {
				> section {
					&:last-of-type {
						opacity: 1;
						transform: translateY(0);
					}
				}
			
				~ .conversation-modal-visible .right-side.measurement-right-side,
				~ .right-side.live-examination-kit,
				~ .right-side.medical-info-aside,
				~ .right-side {
					height: calc(100% - 89px);
					.d-p-no-items {
						height: calc(100vh - 180px);
					}
					.d-p-no-items.diagnoses-procedures-no-items {
						height: calc(100vh - 220px);
					}
				}

				.invite-participants-view {
					> div {
						height: calc(100vh - 80px);
					}
				}
			}
				
			.live-examination-wrapper { 
				.assigned-request-card {
					bottom: 155px;
					transition: 0.2s;
					left: 43px;
				}
			}
			
			.assigned-request-card {
				bottom: calc(90px + 24px);
				transition: 0.2s;
			}
			aside.right-side.live-examination-kit {
				padding-bottom: 90px;
			}

			#otoscopeNotification {
				transform: translateY(0);
			}
		}

		.call-wrapper {
			> section {
				&:last-of-type {
					transition: 0.2s;
					opacity: 0;
					transform: translateY(100%);
				}
			}

			~ .right-side.invite-participants-view {
				z-index: 0;
				> div {
					height: 100%;
					display: flex;
					flex-direction: column;
				}
			}
		}

		.monitoring-vital-signs-ai-wrapper.row {
			.vital-signs-ai {
				&.rpm-measurements-ttp {
			      bottom: 15px;
		        }
	        }
        } 
}


	#root {
		display: flex;
		height: 100%;
	}
`;

/**
 * @param {object} props
 * @param {object} props.participantsToCall Participants to call on conference start
 * @param {boolean} [props.isJoin=false]
 * @param {string} [props.refToken='']
 */
const ConferenceCall = ({ participantsToCall = [], isJoin = false, refToken = '' }) => {
	const conference = useConference();
	const conferenceParticipants = useConferenceParticipants();
	const conferenceConfigs = useConferenceConfigurations();
	const conferenceState = useConferenceState();
	const localParticipant = useLocalParticipant();
	const screenType = useScreenType();
	const location = useLocation();
	const [busyVisitorParticipant, setBusyVisitorParticipant] = useState(null);
	const [isPatientView] = useState(conference.callType === enums.CallTypes.SECURITYCAM);

	/**
	 * @typedef {import('calls/LocalParticipant.js').default | import('calls/RemoteParticipant.js').default} Participant
	 * @type {[Participant, import('react').Dispatch<import('react').SetStateAction<Participant>>]}
	 */
	const [mainParticipant, setMainParticipant] = useState(conference.localParticipant);
	const [canScreenShare, setCanScreenShare] = useState(null);
	const [activeTrackType, setActiveTrackType] = useState(Cam);
	const intl = useIntl();
	const [isCursorStill] = useState(false);
	const [metaData, setMetaData] = useState({});
	const [mrnDifferenceMessage, setMrnDifferenceMessage] = useState(null);
	const [showCallControlsOnMobile, setShowCallControlsOnMobile] = useState(false);
	const [failedInvites, setFailedInvites] = useState([]);
	const [failedInviteMessage, setFailedInviteMessage] = useState(translate('failedToGetParticipantInfo'));
	const [trasnferredOwnerMessage, setTransferredOwnerMessage] = useState('');
	const locale = useSelector(state => state.language.locale);
	const darkMode = useSelector(state => state.user.darkMode);
	const helloName = useSelector(state => state.company.companySettings.helloName);
	const userSession = useSelector(state => state.user.userSession);
	const healthSystems = useSelector(state => state.healthSystems);
	const mainParticipantVideoRef = useRef(null);
	const mainViewRef = useRef(null);
	const afterConferenceEndedTimeoutRef = useRef(null);
	const { roundingConfigurations } = conferenceConfigs;
	const isPrimaryCareType = useRef(null);
	const closeTabTimeout = useRef(null);
	const transferredOwnerMessageTimeout = useRef(null);
	const visualSettings = useSelector(state => state.configurations.unboundHealthSystemSettings.visualsSettings);
	const isNewExperience = useSelector(state => state.configurations.isNewExperience);
	const userSettings = useSelector(state => state.configurations.userSettings);
	const roleRoundingConfigurations = useSelector(state => state.configurations.roleRoundingConfigurations);
	const [roomName, setRoomName] = useState('');

	let micPermissionPromiseResolveCallback = null;
	const micPermissionPromise = new Promise(resolve => {
		micPermissionPromiseResolveCallback = resolve;
	});

	const history = useHistory();

	const dispatch = useDispatch();

	const micStatus = useRef(null);

	const WindowMessages = {
		Window: {
			CLOSE: 'window.close',
			MAXIMIZE: 'window.maximize',
			MINIMIZE: 'window.minimize',
		},
		Call: {
			STARTED: 'call.started',
		},
	};

	const canLeaveConference = conferenceState instanceof Conference.StateInitiated;
	const transferrableParticipants = conferenceParticipants.filter(
		participant =>
			!(participant instanceof RemoteHelloParticipant) &&
			[UserTypes.NURSE, UserTypes.DOCTOR].includes(participant.role) &&
			participant.objectType !== ObjectType.SIP_USER
	);

	useEffect(() => {
		const foundHs =
			healthSystems.allHealthSystems.find(item => item.id === userSession.healthSystem.id)?.workflowTypeId ===
			HealthSystemType.PRIMARY_CARE;
		isPrimaryCareType.current = foundHs;
	}, [healthSystems, userSession]);

	useEffect(() => {
		conferenceConfigs.onConfigurationToggleAction(ControlsActions.IS_RIGHT_TO_LEFT, locale === LOCALES.ARABIC);
		conferenceConfigs.onConfigurationToggleAction(ControlsActions.SET_HELLO_NAME, helloName);
		if ([UserRoles.NURSE, UserRoles.DOCTOR].includes(getUserRole())) {
			conferenceConfigs.onConfigurationToggleAction(ControlsActions.IS_DARK_MODE, darkMode);
		}
		const queryParams = new URLSearchParams(location.search);
		const roomType = queryParams.get(StartQueryStringKeys.ROOM_TYPE);
		const isMultiBedQueryParam = queryParams.get(StartQueryStringKeys.IS_MULTI_BED);

		conferenceConfigs.setIsFitScreen(+roomType === RoomTypes.BABY_ROOM.type);
		if (isMultiBedQueryParam === 'true') {
			conferenceConfigs.onConfigurationToggleAction(ControlsActions.IS_MULTI_BED);
		}

		const bindMediaEvents = async () => {
			micStatus.current = await checkForPermission(MediaTypes.MICROPHONE);
			micStatus.current.onchange = onDevicePermissionChange;
		};
		bindMediaEvents();
	}, []);

	const onDevicePermissionChange = res => {
		if (res.target.state === MediaPermissions.GRANTED || res.target.state === MediaPermissions.PROMPT) {
			dispatch(healthSystemsActionCreators.setStreamPermissionMessage(null));
			if (res.target.state === MediaPermissions.GRANTED) {
				micPermissionPromiseResolveCallback();
			}
		}
	};

	useEffect(() => {
		let trackConfig = { substream: 0, temporal: 0 }; // LowResLowFps
		const participantsSize = conferenceParticipants.length;
		if (participantsSize > 9 && conferenceConfigs.isGridView) {
			trackConfig = { substream: 0, temporal: 1 }; // LowResHighFps
		} else if (participantsSize > 4 && conferenceConfigs.isGridView) {
			trackConfig = { substream: 1, temporal: 1 }; // MidResHighFps
		} else if (conferenceConfigs.isGridView) {
			trackConfig = { substream: 2, temporal: 1 }; // HighResHighFps
		}

		const config = {};
		conferenceParticipants.forEach(participant => {
			config[participant.id] = {
				[Cam]:
					!conferenceConfigs.isGridView && participant.id === mainParticipant?.id ? { substream: 2, temporal: 1 } : trackConfig, // HighResHighFps
			};
		});

		conference.config(config);
	}, [conference, conferenceParticipants, mainParticipant, conferenceConfigs.isGridView]);

	useEffect(() => {
		return localParticipant.localTrackController.on(event => {
			if (event instanceof LocalTrackRemoved && mainParticipant === localParticipant && event.type === ScreenShare) {
				setActiveTrackType(Cam);
			}
		});
	}, [localParticipant, mainParticipant]);

	const getFailedInvitationMessage = participant => {
		if (participant.reason === InviteParticipantFailureReason.INVITE_DENIED) {
			return translate('participantInviteDenied', { value: participant.name });
		}
		return participant.name
			? translate('unableToGetInfo', { value: participant.name })
			: translate('unableToGetInfoGeneralMessage');
	};

	const leaveConference = async (evt, shouldEndCall = false) => {
		if (!canLeaveConference) {
			conferenceConfigs.setIsTransferOwnershipModalopen(false);
			return;
		}
		evt.stopPropagation();
		if (transferrableParticipants.length > 0 && localParticipant.isOwner && !shouldEndCall) {
			const transferred = await conference.transferOwnership(transferrableParticipants[0].id);
			if (transferred) {
				conferenceConfigs.setIsTransferOwnershipModalopen(false);
			}
		}
		conference.leave();
		conference.close(enums.ConferenceEndReasons.PARTICIPANT_LEFT);
	};

	const startConference = async () => {
		if (!(conference.state instanceof Conference.StateInitialized)) {
			return;
		}

		await conference.socket.authPromise;

		if (!localParticipant.isGuest) {
			// When participant is not a guest at talk to patient, at least microphone permissions should be allowed
			const { microphone } = await checkIfMediaDevicesPlugged();
			if (!microphone) {
				dispatch(
					healthSystemsActionCreators.setStreamPermissionMessage({
						component: ErrorComponentTypes.Modal,
						type: StreamError.MICROPHONE_NOT_FOUND.type,
					})
				);
				return;
			}

			if (localParticipant.requestedLocalTracks.includes(Mic)) {
				try {
					await localParticipant.localTrackController.add(Mic);
				} catch (error) {
					if (error?.error?.name === UserPermissionDeniedErrors.NotAllowedError) {
						dispatch(
							healthSystemsActionCreators.setStreamPermissionMessage({
								component: isChrome || isEdgeChromium ? ErrorComponentTypes.Popup : ErrorComponentTypes.Modal,
								type:
									isChrome || isEdgeChromium ? StreamError.MICROPHONE_BLOCKED.type : StreamError.MICROPHONE_BLOCKED_GENERIC.type,
							})
						);
						await micPermissionPromise;
					} else {
						conferenceConfigs.setConferenceErrorMessages([{ id: uuidv4(), message: translate('somethingWentWrong') }]);
					}
				}
			}

			if (localParticipant.requestedLocalTracks.includes(Cam)) {
				try {
					await localParticipant.localTrackController.add(Cam);
					if (localParticipant.localTrackController.tracks[Cam]) {
						setUserBackground({
							localParticipant,
							userSettings,
							visualSettings,
							getBlobSasPicture,
							healthSystemId: userSession.healthSystem.id,
						});
					}
					// eslint-disable-next-line no-empty
				} catch (error) {
					conferenceConfigs.setConferenceErrorMessages([{ id: uuidv4(), message: translate('somethingWentWrong') }]);
				}
			}
		} else {
			try {
				await localParticipant.localTrackController.add(localParticipant.requestedLocalTracks);
				if (localParticipant.localTrackController.tracks[Cam]) {
					setUserBackground({
						localParticipant,
						userSettings,
						visualSettings,
						getBlobSasPicture,
						healthSystemId: userSession.healthSystem.id,
					});
				}
			} catch (error) {
				conferenceConfigs.setConferenceErrorMessages([{ id: uuidv4(), message: translate('somethingWentWrong') }]);
			}
		}

		localParticipant.remoteTrackController.request(callTypeToTrackTypes(conference.callType).remoteTrackTypes);
		let isSuccessful;
		if (isJoin) {
			const response = await conference.join(refToken);
			if (response.data.initiator.integrationId === IframeIntregrationsIds.PHILIPS && location.state) {
				location.state.integrationId = response.data.initiator.integrationId;
			}
			if (response.data.failureReason === JoinConferenceFailureReasonEnum.FAILED_TO_GET_PARTICIPANT_INFO) {
				setFailedInviteMessage(translate('failedToGetYourInfo'));
			}
			if (response.data.failureReason === JoinConferenceFailureReasonEnum.NULL_CONFERENCE && clearGuestUserCheck(true)) {
				conference.socket.doDisconnect();
			}
			isSuccessful = response.ok;
		} else {
			const response = await conference.start(participantsToCall, false, refToken);
			isSuccessful = response.ok;
			if (response.data?.failedInvitationToParticipants?.length) {
				conferenceConfigs.setConferenceErrorMessages(
					response.data.failedInvitationToParticipants.map(participant => ({
						id: participant.id,
						message: getFailedInvitationMessage(participant),
					}))
				);
			}
			window.postMessage(isSuccessful ? 'conference.hasStarted' : 'conference.hasFailed', APP_CONFIG.URL.localApiBasePath);
			window.parent.postMessage(WindowMessages.Call.STARTED, '*');
		}
		if (isSuccessful) {
			conference.logger.debug(`Conference ${isJoin ? 'join()' : 'start()'} successful.`);
		}
	};

	const fetchEpicConverge = async () => {
		const response = await getCurrentSessionPatientData();
		if (!response.error) {
			setMetaData(() => ({
				name: `${response.firstName} ${response.lastName}`,
				mrn: response.mrn,
				dateOfBirth: response.dateOfBirth,
			}));
		}
	};

	const fetchEHRmetaData = async deviceId => {
		const queryParams = new URLSearchParams(location.search);
		const assignedRequestId = queryParams.get('assignedRequestId');
		if (assignedRequestId) {
			return;
		}

		const isEpicOrConverge = isSessionEhr() && !isSmartLauncher();
		if (isEpicOrConverge) {
			fetchEpicConverge();
			return;
		}

		if (!refToken && UserRoles.VISITOR !== getUserRole()) {
			// const deviceOwnerResponse = await getDeviceOwner(deviceId);
			// const foundSector = findSectorById(healthSystems.treeData.tree, deviceId);
			// setMetaData(prevState => ({
			// 	...prevState,
			// 	bedIdentifier: foundSector?.name ?? '',
			// }));
			// if (!deviceOwnerResponse.error) {
			// 	setMetaData(prevState => ({
			// 		...prevState,
			// 		name: deviceOwnerResponse.fullName,
			// 		bedIdentifier: foundSector?.name ?? '',
			// 	}));
			// }
			return;
		}

		if (!refToken) {
			return;
		}

		const [roomInfoResponse, mrnResponse] = await Promise.all([
			getRoomInfo(conference.conferenceId, refToken),
			getOwnerMrn(deviceId),
		]);
		if (roomInfoResponse.error) {
			return;
		}
		if (mrnResponse.error) {
			mrnResponse.mrn = '';
		}
		const metadata = JSON.parse(roomInfoResponse.metadata);

		const getMrnMessage = (mrn1, mrn2) => (mrn1 && mrn2 && mrn1 !== mrn2 ? translate('mrnNoMatch') : '');

		const buildFullName = patient => {
			if (!patient) return '';
			const fullName = [patient.firstName, patient.lastName].filter(Boolean).join(' ');
			return fullName || '';
		};

		setMetaData({
			name: buildFullName(metadata?.patient),
			dateOfBirth: metadata?.patient?.dateOfBirth,
			mrn: metadata?.patient?.mrn,
			bedIdentifier: metadata?.bedIdentifier,
		});
		setMrnDifferenceMessage(getMrnMessage(metadata?.patient?.mrn, mrnResponse.mrn));
	};

	useEffect(() => {
		startConference();
		// Disable reason: useEffect should be invoked once
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (failedInvites) {
			conferenceConfigs.setConferenceErrorMessages(
				failedInvites.map(failedParticipant => ({
					id: failedParticipant.userId,
					message: getFailedInvitationMessage(failedParticipant),
				}))
			);
		}
	}, [failedInvites]);

	useEffect(() => {
		const beforeUnloadEvent = event => {
			if (!(conference.state instanceof Conference.StateEnded)) {
				event.preventDefault();
				// eslint-disable-next-line no-param-reassign
				event.returnValue = '';
			}
		};

		const onMessage = async ({ origin, data }) => {
			// remove last ending slash
			if (origin.replace(/\/$/, '') !== APP_CONFIG.URL.localApiBasePath.replace(/\/$/, '')) {
				return;
			}
			if (data && typeof data === 'object' && data?.messageType === 'doctorRequestData') {
				const doctorRequestData = data.requestData;
				doctorRequestData.conferenceId = conference.conferenceId;
				const response = await sendDoctorRequest(doctorRequestData, getCompanyId());
				if (response.error) {
					window.postMessage('create.request.hasFailed', APP_CONFIG.URL.localApiBasePath);
					conference.leave();
					conference.close(enums.ConferenceEndReasons.PARTICIPANT_LEFT);
				}
			}
			if (data === 'IN_CALL') {
				window.removeEventListener('beforeunload', beforeUnloadEvent);

				conference.leave();
				if (clearGuestUserCheck()) {
					conference.socket.doDisconnect();
				}
				conference.close(enums.ConferenceEndReasons.PARTICIPANT_IDLE);
			}
		};

		const leaveOnUnload = () => {
			if (!(conference.state instanceof Conference.StateEnded)) {
				conference.leave();
			}
			if (clearGuestUserCheck()) {
				conference.socket.doDisconnect();
			}
		};

		window.addEventListener('beforeunload', beforeUnloadEvent);
		window.addEventListener('message', onMessage);
		window.addEventListener('unload', leaveOnUnload);

		return () => {
			leaveOnUnload();
			stopOutgoingCallSound();

			if (afterConferenceEndedTimeoutRef.current) {
				clearTimeout(afterConferenceEndedTimeoutRef.current);
			}

			if (closeTabTimeout.current) {
				clearTimeout(closeTabTimeout.current);
			}

			if (transferredOwnerMessageTimeout.current) {
				clearTimeout(transferredOwnerMessageTimeout.current);
			}

			window.removeEventListener('beforeunload', beforeUnloadEvent);
			window.removeEventListener('message', onMessage);
			window.removeEventListener('unload', leaveOnUnload);
		};
	}, [conference]);

	const findRemoteHelloParticipant = () => {
		let result = null;
		conference.participants.forEach(item => {
			if (item instanceof RemoteHelloParticipant) {
				result = item;
			}
		});
		return result;
	};

	useEffect(() => {
		return conference.on(event => {
			const participantListeners = new Map();
			if (event instanceof Conference.ParticipantAdded) {
				const helloParticipant = findRemoteHelloParticipant();
				if (conference.participants.size > 1 && helloParticipant) {
					setMainParticipant(helloParticipant);
					setActiveTrackType(Cam);
				}
				const onParticipantStateChanged = state => {
					if (state instanceof Participant.StateConnected) {
						if (conference.participants.size === 1) {
							stopOutgoingCallSound();
							setMainParticipant(event.participant);
							setActiveTrackType(Cam);
						}
						if (helloParticipant) {
							const foundSector = findSectorById(healthSystems.treeData.tree, helloParticipant.objectId);
							setRoomName(foundSector?.name ?? '');
							fetchEHRmetaData(helloParticipant.objectId);
						}
						if (conference.participants.size > 1 && helloParticipant) {
							stopOutgoingCallSound();
							setMainParticipant(helloParticipant);
							setActiveTrackType(Cam);
						}
					}

					if (state instanceof Participant.HelloDeviceStateChanged) {
						const { isCameraPrivacyOn } = event.participant.deviceState;
						if (getUserRole() === UserRoles.VISITOR && isCameraPrivacyOn) {
							// familymember
							conference.leave();
							let endReason;
							if (state instanceof Participant.MicPrivacyStateChanged || state instanceof Participant.CameraPrivacyStateChanged) {
								endReason = enums.ConferenceEndReasons.TERMINATED_BY_ADMINISTRATOR;
							} else {
								endReason = enums.ConferenceEndReasons.PARTICIPANT_DECLINED;
							}
							conference.close(endReason);
						}
					}
				};

				const onRemoteTrackChanged = trackEvent => {
					const sortedParticipants = [localParticipant, ...conference.participants.values()]
						.filter(p => !(p instanceof RemoteHelloParticipant))
						.sort((a, b) => a.objectId - b.objectId);
					if (trackEvent instanceof RemoteTrackAdded) {
						if (
							(conference.participants.size === 1 && trackEvent.track.type === Cam) ||
							(trackEvent.track.type === ScreenShare && !(event.participant instanceof RemoteHelloParticipant))
						) {
							setMainParticipant(event.participant);
							setActiveTrackType(trackEvent.track.type === ScreenShare ? ScreenShare : Cam);
						}
						if (
							localParticipant.localTrackController.tracks[ScreenShare] &&
							trackEvent.track.type === ScreenShare &&
							!(event.participant instanceof RemoteHelloParticipant)
						) {
							sortedParticipants.forEach(p => {
								if (localParticipant.id !== sortedParticipants[0].id) {
									setCanScreenShare({
										...canScreenShare,
										[p.id]: false,
									});
									p.localTrackController.remove(ScreenShare);
								}
							});
						}
					} else if (
						trackEvent instanceof RemoteTrackRemoved &&
						event.participant === mainParticipant &&
						trackEvent.type === ScreenShare
					) {
						setActiveTrackType(Cam);
					}
				};
				const unbindParticipantListeners = event.participant.on(onParticipantStateChanged);
				const unbindParticipantTrackListeners = event.participant.remoteTrackController.on(onRemoteTrackChanged);
				participantListeners.set(event.participant, () => {
					unbindParticipantListeners();
					unbindParticipantTrackListeners();
				});
			} else if (event instanceof Conference.ParticipantRemoved) {
				const { participant } = event;
				if (!(event.participant instanceof RemoteHelloParticipant) || event.participant.objectType !== ObjectType.SIP_USER) {
					transferrableParticipants.filter(p => p.id !== participant.id);
				}
				setMainParticipant(prevState => {
					if (participant !== prevState) {
						return prevState;
					}

					setActiveTrackType(Cam);
					if (conference.participants.size > 0) {
						return [...conference.participants.values()].reverse()[0];
					}

					return conference.localParticipant;
				});

				if (participantListeners.has(participant)) {
					participantListeners.get(participant)();
				}
			}
		});
	}, [conference, mainParticipant, localParticipant]);

	useEffect(() => {
		return conference.on(event => {
			const queryParams = new URLSearchParams(location.search);
			const roomType = queryParams.get(StartQueryStringKeys.ROOM_TYPE);
			if (event instanceof Conference.StateInitiating && !isJoin && !conference.isMultiparty && !conference.isMeetingRoom) {
				outGoingCallSound();
			} else if (event instanceof Conference.ParticipantAdded && screenType.isSmall && conference.participants.size > 1) {
				conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_GRID_VIEW, true);
			} else if (event instanceof Conference.StateEnded) {
				if (
					event.activeConferences &&
					event.endReason === enums.ConferenceEndReasons.PARTICIPANT_BUSY &&
					+roomType === RoomTypes.BABY_ROOM.type
				) {
					setBusyVisitorParticipant(event.activeConferences[0].participants[0]);
				}
				const playDropSound = async () => {
					stopOutgoingCallSound();
					await dropSound();
				};
				if (event.endReason === enums.ConferenceEndReasons.TERMINATED_BY_ADMINISTRATOR) {
					playDropSound();
				} else if (!event.endReason || event.endReason === enums.ConferenceEndReasons.PARTICIPANT_LEFT) {
					stopOutgoingCallSound();
				}

				const shouldDisconnectSocket = clearGuestUserCheck(event.endReason !== enums.ConferenceEndReasons.PARTICIPANT_LEFT);
				if (shouldDisconnectSocket) {
					conference.socket.doDisconnect();
				}
			}
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [conference, conferenceConfigs, location.state, screenType.isSmall, localParticipant]);

	useEffect(() => {
		if (conferenceState instanceof Conference.StateEnded) {
			afterConferenceEndedTimeoutRef.current = setTimeout(async () => {
				if (getUserRole() === UserRoles.VISITOR && isMobileSafari) {
					history.push('/devices');
					return;
				}

				if (isSessionEhr()) {
					history.push('/call-patient-options');
					return;
				}

				if (
					!conferenceConfigs.visitId &&
					!location.state &&
					(!getRoleConfigurationValue(roleRoundingConfigurations, RoundingSettings.RateCall) ||
						deviceUnavailable(conferenceState.endReason))
				) {
					window.close();
				}

				if (conferenceConfigs.visitId && !getRoleConfigurationValue(roleRoundingConfigurations, RoundingSettings.SessionWrapUp)) {
					await submitVisit();
					conferenceConfigs.setVisitId(null);
					if (
						getRoleConfigurationValue(roleRoundingConfigurations, RoundingSettings.RateCall) &&
						!deviceUnavailable(conferenceState.endReason)
					) {
						return;
					}
					window.close();
				}

				window.parent.postMessage(WindowMessages.Window.CLOSE, '*');
			}, getConferenceEndedTimeout());
		}
	}, [conferenceState]);

	useEffect(() => {
		return conference.on(event => {
			if (event instanceof Conference.StateEnded) {
				const playConferenceEndedSound = async () => {
					switch (event.endReason) {
						case enums.ConferenceEndReasons.PARTICIPANT_OFFLINE:
						case enums.ConferenceEndReasons.PARTICIPANT_BUSY:
						case enums.ConferenceEndReasons.PARTICIPANT_NOT_ANSWERING:
						case enums.ConferenceEndReasons.PARTICIPANT_DECLINED:
						case enums.ConferenceEndReasons.HAS_ACTIVE_CONFERENCE:
						case enums.ConferenceEndReasons.FAILED_TO_GET_INITIATOR_INFO:
						case enums.ConferenceEndReasons.PARTICIPANT_INVITE_FAILED:
						case enums.ConferenceEndReasons.PARTICIPANT_INVITE_DENIED:
							stopOutgoingCallSound();
							await busySound();
							break;
						case enums.ConferenceEndReasons.OWNER_LEFT:
						case enums.ConferenceEndReasons.TERMINATED_BY_ADMINISTRATOR:
							stopOutgoingCallSound();
							await dropSound();
							break;
						default:
							stopOutgoingCallSound();
							break;
					}
				};
				playConferenceEndedSound();
			}
		});
	}, [conference]);

	useEffect(() => {
		const canFetchRoomConfigs = state => state instanceof Participant.StateConnected && conference.participants.size === 1;

		return conference.on(event => {
			if (event instanceof Conference.ParticipantAdded) {
				const onParticipantStateChanged = state => {
					if (canFetchRoomConfigs(state)) {
						conferenceConfigs.onSetCategoryConfigurationsAction(
							ControlsActions.SET_ROUNDING_CONFIGURATIONS,
							roleRoundingConfigurations
						);
					}
				};

				event.participant.on(onParticipantStateChanged);
			}
			if (event instanceof Conference.OwnerChanged && event.participant.id === localParticipant.id) {
				localParticipant.isGuest = false;
				setTransferredOwnerMessage(intl.formatMessage({ id: 'transferOwnershipMessage' }));
				conferenceConfigs.onConfigurationToggleAction(ControlsActions.SET_SHOW_PATIENT_MEASUREMENTS_BUTTONS, true);
				conferenceConfigs.onSetCategoryConfigurationsAction(
					ControlsActions.SET_ROUNDING_CONFIGURATIONS,
					roleRoundingConfigurations
				);
				transferredOwnerMessageTimeout.current = setTimeout(() => {
					setTransferredOwnerMessage('');
				}, 3000);
			}
		});
	}, [conference, conferenceConfigs, healthSystems.treeData.tree, localParticipant, roleRoundingConfigurations]);

	const clearGuestUserCheck = (shouldClearSession = false) => {
		const isGuestUserRole = getUserRoleId() === UserTypes.GUEST && shouldClearSession;
		if (isGuestUserRole) {
			conference.collector.close(sessionStorage.getItem('access_token'));
			sessionStorage.clear();
			localStorage.clear();
		}

		return isGuestUserRole;
	};

	const onControlsAction = actionType => {
		switch (actionType) {
			case ControlsActions.MAIN_PARTICIPANT_SCREEN_CAPTURE:
				captureFrame(mainViewRef.current);
				break;
			default:
				break;
		}
	};

	const submitVisit = async visitStatus => {
		const currentParams = queryString.parse(history.location.search);
		if (currentParams.appointmentId) {
			await updateAppointmentStatus(currentParams.appointmentId, AppointmentStatus.COMPLETED);
		}

		if (isPrimaryCareType.current) {
			await updateMedicalVisitStatus(currentParams.medicalVisitId, { visitStatus });
		} else {
			await saveVisitEndDateTime(conferenceConfigs.visitId);
		}

		if (getRoleConfigurationValue(roundingConfigurations, RoundingSettings.SessionWrapUp)) {
			startCloseTabTimeout();
		}
	};

	const startCloseTabTimeout = () => {
		conferenceConfigs.setVisitId(null);
		closeTabTimeout.current = setTimeout(() => {
			window.parent.postMessage(WindowMessages.Window.CLOSE, '*');
			window.close();
		}, getConferenceEndedTimeout());
	};

	const showMessageOnSubmitWrapUp = () =>
		!getRoleConfigurationValue(roundingConfigurations, RoundingSettings.SessionWrapUp) &&
		conferenceConfigs.visitId &&
		conferenceState instanceof Conference.StateEnded;

	const isSubmitWrapUpShown = () => conferenceConfigs.visitId && conferenceState instanceof Conference.StateEnded;

	return (
		<div
			className={classNames(
				'full-width full-height overflow-hidden',
				isNewExperience ? 'new-experience' : 'old-experience',
				conferenceConfigs.isLiveExaminationOpen ? 'live-examination-wrapper' : ''
			)}>
			{conferenceConfigs.isMediaBlockedPopupOpen && <MediaBlockedPopup />}
			{((conferenceState instanceof Conference.StateEnded && !conferenceConfigs.visitId) || showMessageOnSubmitWrapUp()) && (
				<ConferenceEndedMessage
					refToken={refToken}
					busyVisitorParticipant={busyVisitorParticipant}
					failedInviteMessage={failedInviteMessage}
				/>
			)}
			{!(conferenceState instanceof Conference.StateEnded) && (
				<>
					<GlobalStyle />
					<StyledConference
						className={classNames('call-wrapper', conferenceConfigs.isRightToLeft ? 'direction-rtl-wrapper' : '')}
						showCallControls={showCallControlsOnMobile}
						isCursorStill={isCursorStill}
						ref={mainViewRef}
						isPatientMeasurementsOpen={
							conferenceConfigs.isLiveExaminationOpen ||
							conferenceConfigs.isPatientHistoryOpen ||
							conferenceConfigs.isTelemetryModalOpen
						}
						isMedicalInfoOpen={isMedicalInfoModalsOpen(conferenceConfigs.medicalDataControls)}
						isInviteParticipantsModalViewOpen={conferenceConfigs.isInviteParticipantsModalViewOpen}
						isFitScreen={conferenceConfigs.isFitScreen}
						isMultiBed={conferenceConfigs.isMultiBed}
						onClick={() => setShowCallControlsOnMobile(prevState => !prevState)}>
						{!isNewExperience && (
							<>
								<MainParticipantView
									ref={mainParticipantVideoRef}
									participant={mainParticipant}
									activeTrackType={activeTrackType}
								/>
								<CallControlsView
									transferrableParticipants={transferrableParticipants}
									mainParticipant={mainParticipant}
									setCanScreenShare={setCanScreenShare}
									onAction={onControlsAction}
									roomName={roomName}
								/>
							</>
						)}
						{isNewExperience && (
							<>
								<MainParticipantViewMayo
									ref={mainParticipantVideoRef}
									participant={mainParticipant}
									activeTrackType={activeTrackType}
								/>
								<CallControlsViewMayo
									transferrableParticipants={transferrableParticipants}
									mainParticipant={mainParticipant}
									onAction={onControlsAction}
									setCanScreenShare={setCanScreenShare}
									roomName={roomName}
								/>
							</>
						)}

						{!isPatientView && (
							<>
								<ParticipantsView
									mainParticipant={mainParticipant}
									setMainParticipant={participant => setMainParticipant(participant)}
									setActiveTrackType={setActiveTrackType}
								/>
								{getUserRoleId() !== UserTypes.GUEST &&
									mainParticipant instanceof RemoteParticipant &&
									mainParticipant.clientType !== enums.ClientTypes.WEB && <CameraMeasurements patient={mainParticipant} />}
								{conferenceConfigs.isInviteParticipantsModalViewOpen && (
									<InviteParticipantsView
										position='right'
										roomId={
											mainParticipant.objectType === enums.ObjectTypes.HELLO_DEVICE
												? findSectorById(healthSystems.treeData.tree, mainParticipant.objectId)?.roomId
												: null
										}
										configurations={{
											isMeetingURLOn: getRoleConfigurationValue(roundingConfigurations, RoundingSettings.MeetingUrl),
											isInviteViaSmsOn: getRoleConfigurationValue(roundingConfigurations, RoundingSettings.InviteViaSms),
											isDialInOn: getRoleConfigurationValue(roundingConfigurations, RoundingSettings.DialIn),
											isDialOutOn: getRoleConfigurationValue(roundingConfigurations, RoundingSettings.DialOut),
											isInviteViaEmailOn: getRoleConfigurationValue(roundingConfigurations, RoundingSettings.InviteViaEmail),
											isTranslationServicesOn: getRoleConfigurationValue(
												roundingConfigurations,
												RoundingSettings.TranslationServices
											),
										}}
										setFailedInvites={setFailedInvites}
										onDismiss={() =>
											conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_INVITE_PARTICIPANTS_MODAL)
										}
									/>
								)}
							</>
						)}
					</StyledConference>
					{mainParticipant instanceof RemoteHelloParticipant &&
						conferenceConfigs.isCameraControlsOpen &&
						localParticipant.isOwner && (
							<>
								{!isNewExperience && <CameraControls helloDevice={mainParticipant} activeTrackType={activeTrackType} />}
								{isNewExperience && <CameraControlsMayo helloDevice={mainParticipant} activeTrackType={activeTrackType} />}
							</>
						)}

					{getUserRoleId() !== UserTypes.GUEST && <AssignedRequestConferenceFunctions isJoin={isJoin} />}
					{conferenceConfigs.isCECSupportedModalOpen && (
						<Modal
							onDismiss={() => conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_CEC_SUPPORTED_MODAL)}
							title={intl.formatMessage({ id: 'unSupportedTV' })}>
							<Modal.Content>
								<p>{translate('commandIsNotSupportedPatientTV')}</p>
							</Modal.Content>
							<Modal.Actions>
								<Button
									type='submit'
									variant={ButtonType.SUBMIT}
									onClick={() => conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_CEC_SUPPORTED_MODAL)}>
									Ok
								</Button>
							</Modal.Actions>
						</Modal>
					)}
					{conferenceConfigs.isTransferOwnershipModalOpen && (
						<Modal
							onDismiss={() => conferenceConfigs.setIsTransferOwnershipModalopen(false)}
							title={intl.formatMessage({ id: 'transferOwnership' })}>
							<Modal.Content>
								<p>{translate('transferOwnershipDescription')}</p>
							</Modal.Content>
							<Modal.Actions>
								<div className='transfer-ownership-actions'>
									<Button type='submit' variant={ButtonType.CANCEL} onClick={leaveConference}>
										{translate('leaveCall')}
									</Button>
									<Button type='submit' variant={ButtonType.SUBMIT} onClick={evt => leaveConference(evt, true)}>
										{translate('endCall')}
									</Button>
								</div>
							</Modal.Actions>
						</Modal>
					)}
					{canScreenShare && !canScreenShare[localParticipant.id] && (
						<Modal
							onDismiss={() => setCanScreenShare({ ...canScreenShare, [localParticipant.id]: true })}
							title={intl.formatMessage({ id: 'screenShareNotAllowed' })}>
							<Modal.Content>
								<p>{translate('screenSharingNotAllowed')}</p>
							</Modal.Content>
							<Modal.Actions>
								<Button type='submit' onClick={() => setCanScreenShare({ ...canScreenShare, [localParticipant.id]: true })}>
									Ok
								</Button>
							</Modal.Actions>
						</Modal>
					)}
					{conferenceConfigs.isStreamSettingsModalOpen && (
						<StreamSettingsView
							localParticipant={localParticipant}
							onDismiss={() => conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_STREAM_SETTINGS_MODAL)}
						/>
					)}
					<VirtualBackgroundProvider>
						{conferenceConfigs.isVirtualBackgroundModalOpen && (
							<VirtualBackgroundView
								onDismiss={() => conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_SELECT_BACKGROUND_MODAL)}
							/>
						)}
					</VirtualBackgroundProvider>
					{conferenceConfigs.removeParticipantModal.isOpen && (
						<RemoveParticipantModal
							display={true}
							onModalClose={() =>
								conferenceConfigs.setRemoveParticipantModal({ isOpen: false, modalMessage: '', onSubmit: () => {} })
							}
							primaryButtonLabel={intl.formatMessage({ id: 'removeParticipant' })}
							onModalSubmit={() => conferenceConfigs.removeParticipantModal.onSubmit()}
							position='center'>
							<form>
								<h4>{intl.formatMessage({ id: 'confirmParticipantRemoval' })}</h4>
								<p>{conferenceConfigs.removeParticipantModal.modalMessage}</p>
							</form>
						</RemoveParticipantModal>
					)}
				</>
			)}
			{getUserRole() === UserRoles.VISITOR && <VisitorContainer />}
			{getUserRoleId() !== UserTypes.GUEST && <HealthDataContainer patient={mainParticipant} submitVisit={submitVisit} />}
			{metaData && !isSubmitWrapUpShown() && (
				<CardInfo
					isInConference={true}
					metaData={metaData}
					callEnded={conferenceState instanceof Conference.StateEnded}
					mrnDifferenceMessage={mrnDifferenceMessage}
				/>
			)}
			<Toaster messages={conferenceConfigs.conferenceErrorMessages} />
			<Alert display={trasnferredOwnerMessage} fixed message={trasnferredOwnerMessage} variant='dark' />
		</div>
	);
};

export default ConferenceCall;
