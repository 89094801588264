import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import LineChartComponent from 'components/Common/Charts/LineChart.jsx';
import Average from 'icons/Dashboard/Average.jsx';
import Highest from 'icons/Dashboard/Highest.jsx';
import translate from 'i18n-translations/translate.jsx';
import { getHourlySessionsTimeline } from 'api/dashboard.js';
import Loader from 'components/Loader.jsx';
import Grid from 'components/Grid.jsx';
import Alert from 'components/Alert.jsx';
import { doesParamValueExist } from 'infrastructure/helpers/commonHelpers.js';
import { adjustTimeForUTC } from 'infrastructure/helpers/dateHelper.js';
import { buildDataPerHours, byHourChartLabels, byHourChartOptions } from 'constants/dashboard.js';
import { sessionByHours } from 'constants/dashboard.js';

const ChartWrapper = styled.div`
	> p {
		font-size: 12px;
		margin: 0;
		padding: 0;
		margin-top: var(--spacing-xl);
		text-align: left;
	}
	> div {
		&:first-of-type {
			margin-top: var(--spacing-xl);
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: var(--spacing-l);

			> div {
				display: flex;
				align-items: center;

				> div {
					margin-left: var(--spacing-l);

					p {
						margin: 0;
						padding: 0;
						text-align: left;
						font-size: 12px;
						color: var(--gray-7);
					}

					h3 {
						font-size: 18px;
					}
				}
			}
		}

		canvas {
			width: 100%;
			height: 250px;
		}
	}
`;

const VisitsByHour = props => {
	const intl = useIntl();
	const translator = useCallback(id => intl.formatMessage({ id }), [intl]);
	const initialDatasets = useMemo(() => sessionByHours(intl), []);
	const [chartData, setChartData] = useState({ datasets: initialDatasets, numberOfCalls: 0, hours: '', average: 0 });
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			const response = await getHourlySessionsTimeline({
				start: Math.floor(adjustTimeForUTC(props.start).getTime() / 1000),
				end: Math.floor(adjustTimeForUTC(props.end, true).getTime() / 1000),
				...(doesParamValueExist(props.selectedHealthSystem) && { healthSystemId: props.selectedHealthSystem.value }),
				...(doesParamValueExist(props.selectedHospital) && {
					regionId: props.selectedHospital.regionId,
					hospitalId: props.selectedHospital.value,
				}),
				...(doesParamValueExist(props.selectedDepartment) && { departmentId: props.selectedDepartment.value }),
				...(doesParamValueExist(props.selectedFloor) && { floorId: props.selectedFloor.value }),
				...(doesParamValueExist(props.selectedRoom) && { roomId: props.selectedRoom.value }),
				...(props.selectedProvider && { nurseId: props.selectedProvider.userIntId }),
				...(props.currentRole && { roleId: props.currentRole.id }),
				...(props.selectedTimezone.value && { timezone: props.selectedTimezone.value }),
			});

			if (!response.error) {
				setHourlyTimelineResponse(response.data);
			} else {
				setError(
					translate('somethingWentWrongSpecific', {
						value: translator('sessionsByHour'),
					})
				);
			}
			setIsLoading(false);
		};
		if (
			(!props.isForSpecificNurse && props.selectedHealthSystem) ||
			(props.isForSpecificNurse && props.selectedHealthSystem && props.selectedProvider)
		) {
			fetchData();
		}
	}, [
		props.end,
		props.selectedHealthSystem,
		props.selectedDepartment,
		props.selectedFloor,
		props.selectedHospital,
		props.selectedProvider,
		props.selectedRoom,
		props.start,
		props.isForSpecificNurse,
		props.currentRole,
		props.selectedTimezone,
	]);

	const setHourlyTimelineResponse = responseData => {
		const modifiedData = initialDatasets.map(dataset => {
			if (dataset.callTypes) {
				const relevantCalls = responseData.filter(call => dataset.callTypes.includes(call.callType));
				const modifiedDataSet = {
					...dataset,
					data: buildDataPerHours(relevantCalls),
				};
				return modifiedDataSet;
			}

			return dataset;
		});

		const numberOfCalls = Math.max(...modifiedData[0].data);
		const findIndexOfHighest = modifiedData[0].data.indexOf(numberOfCalls);
		const highestVolumeText = `${translator('from')} ${byHourChartLabels[findIndexOfHighest]} ${translator('to')} ${
			byHourChartLabels[findIndexOfHighest + 1] || byHourChartLabels[0]
		}`;
		const avg = modifiedData[0].data.reduce((acc, value) => acc + value, 0) / modifiedData[0].data.length;
		setChartData({ datasets: modifiedData, numberOfCalls, hours: highestVolumeText, average: Math.ceil(avg) });
	};

	return useMemo(
		() => (
			<>
				<ChartWrapper>
					<h3>{translate('sessionsByHour')}</h3>
					{isLoading && (
						<Grid columns='1fr' rows='1fr' stretch='300px' horizAlign='center' vertAlign='center'>
							<Loader />
						</Grid>
					)}
					{!isLoading && (
						<>
							<div>
								<div>
									<Average />
									<div>
										<p>{translator('avgSessions')}</p>
										<h3>{chartData.average > 0 ? `${chartData.average} ${translator('sessions')}` : translator('noData')}</h3>
									</div>
								</div>
								{chartData.numberOfCalls > 0 && (
									<div>
										<Highest />
										<div>
											<p>{translator('highestVolumeOfSessions')}</p>
											<h3>
												{chartData.numberOfCalls} {translator('sessions')} {chartData.hours}
											</h3>
										</div>
									</div>
								)}
							</div>

							<div className='top-30'>
								<LineChartComponent
									labels={byHourChartLabels}
									datasets={chartData.datasets}
									options={byHourChartOptions(chartData.datasets, props.xLabelScales)}
									height='300px'
								/>
							</div>
						</>
					)}
				</ChartWrapper>
				<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
			</>
		),
		[byHourChartLabels, error, chartData, isLoading]
	);
};

export default VisitsByHour;
