import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { createNoteOrUpdate, deleteNote } from 'api/notes.js';
import { actionCreators as patientNotesActionCreators } from 'state/patientNotes/actions.js';
import { actionCreators as aiSettingsActionCreators } from 'state/aiSettings/actions.js';
import { ExcludedAiSettings, NotesType, PatientsAiSettings, RailType, SensitivityType } from 'constants/enums.js';
import { Alert, CustomDropdown, Dropdown, Form, Grid, Loader } from 'components/index.js';
import FormInput from 'components/FormInput.jsx';
import { DischargeIcon, EditIcon, ProfileIcon } from 'icons/Monitoring/index.js';
import { mapSelectedOptions } from 'infrastructure/helpers/monitoringTimelineHelpers.js';
import translate from 'i18n-translations/translate.jsx';
import { getPrecautions, setPrecautions } from 'api/monitoring.js';
import _ from 'lodash';
import {
	MonitoringSettings,
	SettingsCategory,
	UserSettingTypes,
	configurableAISettings,
	configurableMonitoringMenu,
} from 'constants/configurationEnums.js';
import { getAiSettingsConfigurations, getConfigurationValue, updateConfigsList } from 'infrastructure/helpers/commonHelpers.js';
import { getRoomSettings } from 'api/adminConfigurations.js';
import { getUserPreferences } from 'api/users.js';
import { updatePatientsAiSettings } from 'api/patients.js';
import ToastMessage from 'components/ToastMessage.jsx';
import { getUserRole } from 'infrastructure/auth.js';
import LightTheme from 'calls/styles/LightTheme.js';
import DarkTheme from 'calls/styles/DarkTheme.js';

const initialPrecautions = [
	{
		id: '22631001',
		abbreviation: 'F',
		name: 'Falls',
		textColor: '#282D30',
		boxColor: '#F3C752',
	},
	{
		id: '77272004',
		abbreviation: 'SZ',
		name: 'Seizure',
		textColor: '#FFFFFF',
		boxColor: '#E270DA',
	},
	{
		id: '441862004',
		abbreviation: 'ISO',
		name: 'Isolation',
		textColor: '#FFFFFF',
		boxColor: '#D64F2D',
	},
	{
		id: '413322009',
		abbreviation: 'SW',
		name: 'Suicide Watch',
		textColor: '#282D30',
		boxColor: '#88D9FB',
	},
	{
		id: '26544005',
		abbreviation: 'SB',
		name: 'Suspicious Behavior',
		textColor: '#FFFFFF',
		boxColor: '#7B35C1',
	},
	{
		id: '49436004',
		abbreviation: 'HP',
		name: 'Hospice',
		textColor: '#FFFFFF',
		boxColor: '#000000',
	},
	{
		id: '71388002',
		abbreviation: 'D',
		name: 'Detox',
		textColor: '#282D30',
		boxColor: '#F2A356',
	},
	{
		id: '233604007',
		abbreviation: 'PO2',
		name: 'Pulling O2',
		textColor: '#282D30',
		boxColor: '#B6D7E4',
	},
	{
		id: '35489007',
		abbreviation: 'IC',
		name: 'Impulsive/Confused',
		textColor: '#282D30',
		boxColor: '#D3D3D3',
	},
	{
		id: '43998006',
		abbreviation: 'NR',
		name: 'Non-redirectable',
		textColor: '#FFFFFF',
		boxColor: '#4BA5F8',
	},
];

const PatientNotes = ({
	deviceId,
	roomId,
	hierarchyNaming,
	deviceOwnerId,
	aiSettings,
	toggleFallPrevention,
	onAiSettingClick,
	isDefaultOwner,
	isDarkMode,
}) => {
	const intl = useIntl();
	const dispatch = useDispatch();
	const maxCharacterLength = 256;
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const patientDataSaved = useSelector(state => state.patientNotes.savedMode);
	const aiSettingList = useSelector(state => state.aiSettingsList.aiSettings);
	const setAiSettingsAction = ai => dispatch(aiSettingsActionCreators.setPatientAiSettings(ai));

	const [patientNotes, setPatientNotes] = useState({
		patientName: '',
		patientAge: null,
		nurseNameNumber: '',
		nurseAssistantNameNumber: '',
		tlNameNumber: '',
		primaryAdverseEvent: [],
		primaryPatientCondition: [],
		primaryPatientRiskFactor: [],
		titleBarNotes: '',
		additionalNotes: '',
	});
	const [expandedBox, setExpandedBox] = useState(true);
	const [showEditForm, setShowEditForm] = useState(false);
	const [precautions, setPrecautionsList] = useState([]);
	const [adminConfigurations, setAdminConfigurations] = useState(configurableMonitoringMenu(getUserRole()));
	const [adminAiSettingsConfigurations, setAdminAiConfigurations] = useState(configurableAISettings());
	const [isMoreThanOneAi, setIsMoreThanOneAi] = useState(false);

	const prevDeviceIdRef = useRef();
	const prevRoomIdRef = useRef();

	const patientNotesProperties = [
		'patientName',
		'patientAge',
		'nurseNameNumber',
		'nurseAssistantNameNumber',
		'tlNameNumber',
		'primaryAdverseEvent',
		'primaryPatientCondition',
		'primaryPatientRiskFactor',
		'titleBarNotes',
		'additionalNotes',
	];

	const primaryAdverseEvent = [
		{ label: `${intl.formatMessage({ id: 'falls' })}`, value: 0 },
		{ label: `${intl.formatMessage({ id: 'staffInjury' })}`, value: 1 },
		{ label: `${intl.formatMessage({ id: 'isolationExposure' })}`, value: 2 },
		{ label: `${intl.formatMessage({ id: 'medicalDeviceInterference' })}`, value: 3 },
		{ label: `${intl.formatMessage({ id: 'elopement' })}`, value: 4 },
		{ label: `${intl.formatMessage({ id: 'intentionalSelfHarm' })}`, value: 5 },
		{ label: `${intl.formatMessage({ id: 'suicide' })}`, value: 6 },
		{ label: `${intl.formatMessage({ id: 'others' })}`, value: 7 },
	];

	const primaryPatientCondition = [
		{ label: `${intl.formatMessage({ id: 'delirium' })}`, value: 0 },
		{ label: `${intl.formatMessage({ id: 'dementia' })}`, value: 1 },
		{ label: `${intl.formatMessage({ id: 'substanceWithdrawal' })}`, value: 2 },
		{ label: `${intl.formatMessage({ id: 'brianInjury' })}`, value: 3 },
		{ label: `${intl.formatMessage({ id: 'psychiatricDisorder' })}`, value: 4 },
		{ label: `${intl.formatMessage({ id: 'stroke' })}`, value: 5 },
		{ label: `${intl.formatMessage({ id: 'infectiousDiseaseIsolation' })}`, value: 6 },
		{ label: `${intl.formatMessage({ id: 'others' })}`, value: 7 },
	];

	const primaryPatientRiskFactor = [
		{ label: `${intl.formatMessage({ id: 'poorShortTermMemory' })}`, value: 0 },
		{ label: `${intl.formatMessage({ id: 'poorComprehension' })}`, value: 1 },
		{ label: `${intl.formatMessage({ id: 'impulsivity' })}`, value: 2 },
		{ label: `${intl.formatMessage({ id: 'agitation' })}`, value: 3 },
		{ label: `${intl.formatMessage({ id: 'anxiety' })}`, value: 4 },
		{ label: `${intl.formatMessage({ id: 'distortedPerceptionOfReality' })}`, value: 5 },
		{ label: `${intl.formatMessage({ id: 'angerFrustration' })}`, value: 6 },
		{ label: `${intl.formatMessage({ id: 'none' })}`, value: 7 },
	];

	useEffect(() => {
		const fetchPrecautions = async () => {
			const response = await getPrecautions(deviceOwnerId);
			if (response.error) {
				setError(response.error.message);
				return;
			}
			const newPrecautions = _.cloneDeep(initialPrecautions).map(x => {
				const precaution = response.conditions.find(p => p.code === x.id);
				if (precaution) {
					x.active = true;
				}

				return x;
			});
			setPrecautionsList(newPrecautions);
		};

		if (deviceOwnerId) {
			fetchPrecautions();
		}
	}, [deviceOwnerId]);

	useEffect(() => {
		const fetchRoomSettings = async () => {
			const [adminRoomSettings, myRoomSettings, adminAiSettings, aiRoomSettings] = await Promise.all([
				getRoomSettings(roomId, SettingsCategory.MONITORING),
				getUserPreferences(UserSettingTypes.Monitoring, roomId),
				getRoomSettings(roomId, SettingsCategory.AI_SETTINGS),
				getUserPreferences(UserSettingTypes.AiSettings, roomId),
			]);
			const response = updateConfigsList(adminRoomSettings, myRoomSettings);
			const aiResponse = getAiSettingsConfigurations(adminAiSettings, aiRoomSettings);
			const resError = response.error || aiResponse.error;
			if (resError) {
				setError(resError);
			} else {
				setAdminConfigurations(response.configs);
				setAdminAiConfigurations(aiResponse.configs);
			}
		};

		fetchRoomSettings();
	}, [roomId]);

	useEffect(() => {
		if (roomId !== prevRoomIdRef.current && showEditForm) {
			handleSubmit(true);
		}
		setPatientNotes(patientDataSaved[deviceId]);
		prevDeviceIdRef.current = deviceId;
		prevRoomIdRef.current = roomId;
	}, [deviceId, roomId]);

	const handleSubmit = async savePrevious => {
		setIsLoading(true);
		const deviceIdToSave = savePrevious ? prevDeviceIdRef.current : deviceId;
		const roomIdToSave = savePrevious ? prevRoomIdRef.current : roomId;
		const response = await createNoteOrUpdate(roomIdToSave, JSON.stringify(patientNotes), NotesType.PATIENT);
		if (response.error) {
			setError(response.error.message);
			setIsLoading(false);
			return;
		}
		dispatch(
			patientNotesActionCreators.setPatientDataSaved(
				{ ...patientNotes, notes: patientDataSaved[deviceIdToSave]?.notes || '' },
				deviceIdToSave
			)
		);
		setIsLoading(false);
		setShowEditForm(false);
	};

	const hasPatientInfo = () => {
		let result = false;
		for (let i = 0; i < patientNotesProperties.length; i++) {
			const item = patientDataSaved?.[deviceId]?.[patientNotesProperties?.[i]];
			if (item && Array.isArray(item) && item.length > 0) {
				result = true;
				break;
			}
			if (item && !Array.isArray(item)) {
				result = true;
				break;
			}
		}
		return result;
	};

	const getTableData = () => {
		const tableRows = [];
		for (let i = 0; i < patientNotesProperties.length; i++) {
			const patientNote = patientNotesProperties[i];
			const noteItem = patientNotes[patientNote];
			if (noteItem && Array.isArray(noteItem) && noteItem.length > 0) {
				const row = (
					<tr key={patientNote}>
						<td>{intl.formatMessage({ id: patientNote })}</td>
						<td>{mapSelectedOptions(noteItem)}</td>
					</tr>
				);
				tableRows.push(row);
			}
			if (noteItem && !Array.isArray(noteItem)) {
				const row = (
					<tr key={patientNote}>
						<td>{intl.formatMessage({ id: patientNote })}</td>
						<td>{noteItem}</td>
					</tr>
				);
				tableRows.push(row);
			}
		}
		return tableRows;
	};

	const PatientInformation = () => {
		if (!hasPatientInfo()) {
			return (
				<table className='patient-table-informations' style={{ width: '100%' }}>
					<tbody>
						<tr>
							<td>{translate('roomWithoutPatientData')}</td>
						</tr>
					</tbody>
				</table>
			);
		}

		return (
			<>
				<p>{`${hierarchyNaming.hospital}, ${hierarchyNaming.floor}, ${hierarchyNaming.room}`}</p>
				<table className='patient-table-informations' style={{ width: '100%' }}>
					<tbody>{getTableData()}</tbody>
				</table>
			</>
		);
	};

	const turnOffAi = async () => {
		const settings = _.cloneDeep(aiSettingList);
		const found = settings.find(item => item.deviceId === deviceId);
		if (!found) {
			return;
		}
		const initialSettings = settings.find(item => item.deviceId === deviceId).settings;
		const newSettings = _.cloneDeep(initialSettings).map(item => {
			if (
				[
					PatientsAiSettings.PERSON_INACTIVE,
					PatientsAiSettings.PERSON_GETTING_OUT_OF_BED,
					PatientsAiSettings.IV_BAG,
					PatientsAiSettings.RAIL_DOWN,
				].includes(item.settingTypeId)
			) {
				return { ...item, value: null };
			}
			return { ...item, value: 'false' };
		});

		const settingsToClose = _.cloneDeep(initialSettings)
			.filter(item => !['false', undefined, null].includes(item.value) && !ExcludedAiSettings.includes(item.settingTypeId))
			.map(item => {
				if (
					[
						PatientsAiSettings.PERSON_INACTIVE,
						PatientsAiSettings.PERSON_GETTING_OUT_OF_BED,
						PatientsAiSettings.IV_BAG,
						PatientsAiSettings.RAIL_DOWN,
					].includes(item.settingTypeId)
				) {
					return { ...item, value: null };
				}
				return { ...item, value: 'false' };
			});

		setAiSettingsAction({
			settings: newSettings,
			deviceId: deviceId,
		});

		const params = {
			patientId: deviceOwnerId,
			deviceId,
			roomId,
			sendData: settingsToClose,
		};

		const response = await updatePatientsAiSettings(params);
		if (response.error) {
			setError(response.error.message);
			setAiSettingsAction({
				settings: initialSettings,
				deviceId: deviceId,
			});
			return;
		}
		toggleFallPrevention(false, deviceId);
		onAiSettingClick(false);
	};

	const deleteAllPrecautions = async () => {
		const precautionsInitial = [...precautions];
		const noPrecautions = [
			{
				code: '00000000',
				name: 'Stability',
				conditionType: 3,
			},
		];

		const newPrecautions = precautionsInitial.map(precaution => {
			const newPrecaution = { ...precaution };

			newPrecaution.active = false;

			return newPrecaution;
		});
		const dataToSend = newPrecautions.filter(x => x.active).map(x => ({ conditionType: 3, code: x.id }));
		await setPrecautions(deviceOwnerId, {
			deviceId,
			conditions: dataToSend.length > 0 ? dataToSend : noPrecautions,
		});
		setPrecautionsList(newPrecautions);
	};

	const handleDischarge = async event => {
		event.preventDefault();
		setIsLoading(true);
		const response = await deleteNote(roomId, NotesType.PATIENT);
		if (response.error) {
			setError(response.error.message);
			setIsLoading(false);
			return;
		}
		setPatientNotes({
			patientName: '',
			patientAge: '',
			nurseNameNumber: '',
			nurseAssistantNameNumber: '',
			tlNameNumber: '',
			primaryAdverseEvent: [],
			primaryPatientCondition: [],
			primaryPatientRiskFactor: [],
			titleBarNotes: '',
			additionalNotes: '',
		});
		dispatch(
			patientNotesActionCreators.setPatientDataSaved(
				{
					...patientDataSaved[deviceId],
					notes: '',
					patientName: '',
					patientAge: '',
					nurseNameNumber: '',
					nurseAssistantNameNumber: '',
					tlNameNumber: '',
					primaryAdverseEvent: [],
					primaryPatientCondition: [],
					primaryPatientRiskFactor: [],
					titleBarNotes: '',
					additionalNotes: '',
				},
				deviceId
			)
		);

		let isAnyAiSettingEnabled = false;
		const found = _.cloneDeep(aiSettingList).find(item => item.deviceId === deviceId);

		if (found) {
			isAnyAiSettingEnabled = found.settings
				.filter(el => !ExcludedAiSettings.includes(el.settingTypeId))
				.some(item => !['false', undefined, null].includes(item.value));
		}

		if (isAnyAiSettingEnabled) {
			turnOffAi();
		}

		deleteAllPrecautions();
		setIsLoading(false);
	};

	useEffect(() => {
		const toastTimeOut = setTimeout(() => {
			setIsMoreThanOneAi(false);
		}, 3000);

		return () => {
			clearTimeout(toastTimeOut);
		};
	}, [isMoreThanOneAi]);

	const isAnyFallPreventionConfigEnabled = () =>
		getConfigurationValue(adminAiSettingsConfigurations[MonitoringSettings.Rails]) ||
		getConfigurationValue(adminAiSettingsConfigurations[MonitoringSettings.GetOutOfBed]) ||
		getConfigurationValue(adminAiSettingsConfigurations[MonitoringSettings.FallDetection]);

	const shouldDisableClick = (settingTypeId, value) => {
		const settings = _.cloneDeep(aiSettingList);
		const found = settings.find(item => item.deviceId === deviceId);
		const fallPreventionTypes = [
			PatientsAiSettings.FALL_PREVENTION,
			PatientsAiSettings.RAIL_DOWN,
			PatientsAiSettings.FALL_DETECTED,
			PatientsAiSettings.PERSON_GETTING_OUT_OF_BED,
		];

		const foundInFallPrevention = fallPreventionTypes.find(item => item === settingTypeId);
		if (!found) {
			return true;
		}
		let isAiSettingEnabled = found.settings
			.filter(el => !ExcludedAiSettings.includes(el.settingTypeId))
			.some(item => !['false', undefined, null].includes(item.value) && item.settingTypeId !== settingTypeId);

		if (foundInFallPrevention) {
			isAiSettingEnabled = found.settings
				.filter(el => !ExcludedAiSettings.includes(el.settingTypeId))
				.reduce((acc, item) => {
					return (
						acc ||
						(!fallPreventionTypes.includes(item.settingTypeId) &&
							!['false', undefined, null].includes(item.value) &&
							item.settingTypeId !== settingTypeId)
					);
				}, false);
		}

		return isAiSettingEnabled && !['false', undefined, null].includes(value) && settingTypeId !== PatientsAiSettings.HAND_WASHING;
	};

	const submitFallPrevention = async status => {
		if (shouldDisableClick(PatientsAiSettings.FALL_PREVENTION, status)) {
			setIsMoreThanOneAi(true);
			return;
		}
		let dataToSend = [];
		if (status) {
			dataToSend = [
				{
					settingTypeId: PatientsAiSettings.PERSON_GETTING_OUT_OF_BED,
					value: getConfigurationValue(adminAiSettingsConfigurations[MonitoringSettings.GetOutOfBed])
						? SensitivityType.MEDIUM
						: null,
				},
				{
					settingTypeId: PatientsAiSettings.RAIL_DOWN,
					value: getConfigurationValue(adminAiSettingsConfigurations[MonitoringSettings.Rails])
						? `${RailType.TOP_RIGHT}-${RailType.TOP_LEFT}`
						: null,
				},
				{
					settingTypeId: PatientsAiSettings.FALL_DETECTED,
					value: getConfigurationValue(adminAiSettingsConfigurations[MonitoringSettings.FallDetection]) ? 'true' : 'false',
				},
			];
		} else {
			dataToSend = [
				{
					settingTypeId: PatientsAiSettings.PERSON_GETTING_OUT_OF_BED,
					value: null,
				},
				{
					settingTypeId: PatientsAiSettings.RAIL_DOWN,
					value: null,
				},
				{
					settingTypeId: PatientsAiSettings.FALL_DETECTED,
					value: 'false',
				},
			];
		}

		toggleFallPrevention(status, deviceId);
		const found = aiSettingList.find(item => item.deviceId === deviceId);
		if (!found) {
			return;
		}
		const settings = _.cloneDeep(aiSettingList);
		const initialSettings = settings.find(item => item.deviceId === deviceId).settings;
		const filteredSettings = _.cloneDeep(initialSettings).filter(
			item =>
				![PatientsAiSettings.PERSON_GETTING_OUT_OF_BED, PatientsAiSettings.RAIL_DOWN, PatientsAiSettings.FALL_DETECTED].includes(
					item.settingTypeId
				)
		);

		const newDataToSend = dataToSend.map(item => {
			const matchingSetting = initialSettings.find(setting => setting.settingTypeId === item.settingTypeId);
			return {
				...item,
				snoozeTime: matchingSetting?.snoozeTime ?? null,
			};
		});

		setAiSettingsAction({
			settings: [...filteredSettings, ...newDataToSend],
			deviceId: deviceId,
		});

		const params = {
			patientId: deviceOwnerId,
			deviceId,
			roomId,
			sendData: newDataToSend,
		};

		const response = await updatePatientsAiSettings(params);

		if (response.error) {
			setError(response.error.message);
			setAiSettingsAction({
				settings: initialSettings,
				deviceId: deviceId,
			});

			return;
		}
		onAiSettingClick(true);
	};

	const setFeedColor = async items => {
		const precautionsInitial = [...precautions];
		const noPrecautions = [
			{
				code: '00000000',
				name: 'Stability',
				conditionType: 3,
			},
		];

		const newPrecautions = precautionsInitial.map(precaution => {
			const newPrecaution = { ...precaution };

			newPrecaution.active = !!items.find(item => item.value === precaution.id);

			return newPrecaution;
		});
		const dataToSend = newPrecautions.filter(x => x.active).map(x => ({ conditionType: 3, code: x.id }));
		await setPrecautions(deviceOwnerId, {
			deviceId,
			conditions: dataToSend.length > 0 ? dataToSend : noPrecautions,
		});

		const fallsPrecautionsCode = '22631001';
		let fallPreventionStatus = false;

		const foundAiSettings = aiSettings.find(item => item.deviceId === deviceId);
		if (foundAiSettings) {
			fallPreventionStatus = foundAiSettings.settings.reduce((acc, item) => {
				if (
					[PatientsAiSettings.PERSON_GETTING_OUT_OF_BED, PatientsAiSettings.RAIL_DOWN, PatientsAiSettings.FALL_DETECTED].includes(
						item.settingTypeId
					)
				) {
					return acc || !['false', undefined, null].includes(item.value);
				}
				return acc;
			}, false);
		}

		if (
			items.find(item => item.value === fallsPrecautionsCode) &&
			!fallPreventionStatus &&
			dataToSend.find(precaution => precaution.code === fallsPrecautionsCode) &&
			isAnyFallPreventionConfigEnabled()
		) {
			submitFallPrevention(true);
		}

		if (
			precautionsInitial.find(item => item.id === fallsPrecautionsCode)?.active &&
			!items.find(item => item.value === fallsPrecautionsCode)
		) {
			submitFallPrevention(false);
		}

		setPrecautionsList(newPrecautions);
	};

	const filteredPrecautions = precautions.filter(item => item.active);

	const showPrecautions = getConfigurationValue(adminConfigurations[MonitoringSettings.Precautions]);

	return (
		<>
			<div className='monitoring-timeline-box'>
				<div className={classNames('timeline-box-header', expandedBox ? 'expanded' : '')}>
					<p className='timeline-box-title'>
						<ProfileIcon />
						{translate('patientInfo')}
					</p>
					<div className='timeline-box-actions'>
						<button
							type='button'
							onClick={() => {
								setExpandedBox(true);
								setShowEditForm(prevState => !prevState);
							}}>
							<EditIcon />
						</button>
						<button type='button' onClick={() => setExpandedBox(prevState => !prevState)}>
							<i className={classNames('material-icons', expandedBox ? ' rotate' : '')}>expand_more</i>
						</button>
					</div>
				</div>
				{expandedBox && (
					<div className={classNames('timeline-box-content', expandedBox ? ' expanded' : '')}>
						<div className='patient-informations'>
							{isLoading && (
								<Grid columns='1fr' rows='1fr' horizAlign='center' vertAlign='center'>
									<Loader />
								</Grid>
							)}
							{!isLoading && (
								<>
									{!showEditForm && <PatientInformation />}
									{showEditForm && (
										<Form className='profile-information-form'>
											<div className='flex flex-space-between flex-wrap'>
												<>
													<div className='room-level'>
														<p>{`${hierarchyNaming.hospital}, ${hierarchyNaming.floor}, ${hierarchyNaming.room}`}</p>
														<Dropdown position='bottom' icon='more_vert'>
															<button type='button' className='button discharge-btn' onClick={handleDischarge}>
																<DischargeIcon
																	height={16}
																	width={16}
																	color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive}
																/>
																{translate('discharge')}
															</button>
														</Dropdown>
													</div>
													<FormInput
														text={translate('patientName')}
														id='patientName'
														placeholder={intl.formatMessage({ id: 'enterPatientName' })}
														type='text'
														value={patientNotes?.patientName}
														onChange={event => setPatientNotes(prevState => ({ ...prevState, patientName: event.target.value }))}
														className='text-input'
														maxLength={256}
													/>
													<FormInput
														text={translate('patientAge')}
														id='patientAge'
														placeholder={intl.formatMessage({ id: 'enterPatientAge' })}
														type='text'
														value={patientNotes?.patientAge}
														onChange={event => setPatientNotes(prevState => ({ ...prevState, patientAge: event.target.value }))}
														className='text-input'
														maxLength={256}
													/>
													<FormInput
														text={translate('nurseNameNumber')}
														id='nurseNameNumber'
														placeholder={intl.formatMessage({ id: 'enterNurseNameNnumber' })}
														type='text'
														value={patientNotes?.nurseNameNumber}
														onChange={event =>
															setPatientNotes(prevState => ({ ...prevState, nurseNameNumber: event.target.value }))
														}
														className='text-input'
														maxLength={256}
													/>
													<FormInput
														text={translate('nurseAssistantNameNumber')}
														id='nurseAssistantNameNumber'
														placeholder={intl.formatMessage({ id: 'enterNurseAssistantNameNumber' })}
														type='text'
														value={patientNotes?.nurseAssistantNameNumber}
														onChange={event =>
															setPatientNotes(prevState => ({ ...prevState, nurseAssistantNameNumber: event.target.value }))
														}
														className='text-input'
														maxLength={256}
													/>
													<FormInput
														text={translate('tlNameNumber')}
														id='tlNameNumber'
														placeholder={intl.formatMessage({ id: 'enterTlNameNumber' })}
														type='text'
														value={patientNotes?.tlNameNumber}
														onChange={event => setPatientNotes(prevState => ({ ...prevState, tlNameNumber: event.target.value }))}
														className='text-input'
														maxLength={256}
													/>
													<CustomDropdown
														labelName={translate('primaryAdverseEvent')}
														defaultOptions={patientNotes?.primaryAdverseEvent || []}
														initialOptions={primaryAdverseEvent}
														onSelect={selectedOptions =>
															setPatientNotes(prevState => ({
																...prevState,
																primaryAdverseEvent: selectedOptions,
															}))
														}
														placeholder={`${intl.formatMessage({ id: 'select' })} ${intl.formatMessage({
															id: 'primaryAdverseEvent',
														})}`}
													/>
													<CustomDropdown
														labelName={translate('primaryPatientCondition')}
														defaultOptions={patientNotes?.primaryPatientCondition || []}
														initialOptions={primaryPatientCondition}
														onSelect={selectedOptions =>
															setPatientNotes(prevState => ({
																...prevState,
																primaryPatientCondition: selectedOptions,
															}))
														}
														placeholder={`${intl.formatMessage({ id: 'select' })} ${intl.formatMessage({
															id: 'primaryPatientCondition',
														})}`}
													/>
													<CustomDropdown
														labelName={translate('primaryPatientRiskFactor')}
														defaultOptions={patientNotes?.primaryPatientRiskFactor || []}
														initialOptions={primaryPatientRiskFactor}
														onSelect={selectedOptions =>
															setPatientNotes(prevState => ({ ...prevState, primaryPatientRiskFactor: selectedOptions }))
														}
														placeholder={`${intl.formatMessage({ id: 'select' })} ${intl.formatMessage({
															id: 'primaryPatientRiskFactor',
														})}`}
													/>
													<FormInput
														text={translate('titleBarNotes')}
														id='titleBarNotes'
														placeholder={intl.formatMessage({ id: 'enterTitleBarNotes' })}
														type='text'
														value={patientNotes?.titleBarNotes}
														onChange={event =>
															setPatientNotes(prevState => ({ ...prevState, titleBarNotes: event.target.value }))
														}
														className='text-input'
														maxLength={256}
													/>
												</>
												<label>
													{translate('additionalPatientNotes')}
													<textarea
														className='text-input'
														maxLength={maxCharacterLength}
														onChange={event => {
															event.preventDefault();
															setPatientNotes(prevState => ({ ...prevState, additionalNotes: event.target.value }));
														}}
														value={patientNotes?.additionalNotes}
														name='note'
														rows={3}
														placeholder={intl.formatMessage({ id: 'enterAdditionalPatientNotes' })}
														style={{ fontSize: '14px' }}
													/>
												</label>
												<button className='button' onClick={handleSubmit}>
													{translate('save')}
												</button>
											</div>
										</Form>
									)}
								</>
							)}
							<Alert display={error} fixed hideCloseButton message={error} variant='dark' />
							<ToastMessage
								showToast={isMoreThanOneAi}
								onClose={() => setIsMoreThanOneAi(false)}
								className='video-feed-toast-message'>
								<span>{translate('noMoreThanOneAiType')}</span>
							</ToastMessage>
						</div>
					</div>
				)}
				{showPrecautions && !isDefaultOwner && expandedBox && deviceOwnerId && (
					<div className='flex full-height text-align-center gap-m padding-other-top-m flex-wrap'>
						{filteredPrecautions.map(item => (
							<div
								className='precaution-box'
								style={{
									background: item.boxColor,
								}}>
								<span style={{ color: item.textColor }}>{item.abbreviation}</span>
							</div>
						))}
						<div className='flex-basis-100'>
							<CustomDropdown
								defaultOptions={
									filteredPrecautions?.length > 0
										? filteredPrecautions.map(item => ({
												value: item.id,
												label: item.name,
										  }))
										: []
								}
								initialOptions={initialPrecautions.map(item => ({
									value: item.id,
									label: item.name,
								}))}
								onSelect={selectedOptions => setFeedColor(selectedOptions)}
								title={intl.formatMessage({ id: 'precautions' })}
								showTitleInPlaceholder={true}
								placeholder=''
							/>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default PatientNotes;
