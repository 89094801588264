import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import Table from 'components/Table.jsx';
import Grid from 'components/Grid.jsx';
import Button from 'components/Button.jsx';
import { getUserIdleConfigurations, updateUserIdleConfiguration } from 'api/userIdleConfigurations.js';
import Pagination from 'components/Common/Pagination.jsx';
import { getUserRole } from 'infrastructure/auth.js';
import { UserRoles, UserRolesWithLabels } from 'constants/enums.js';
import UserIdleForm from 'components/Configurations/UserIdleForm.jsx';
import { ConfigHistoryTypes } from 'constants/configurationEnums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { getFormattedDateWithHour } from 'infrastructure/helpers/dateHelper.js';

const UserIdleConfiguration = props => {
	const intl = useIntl();
	const role = getUserRole();
	const [isLoading, setLoading] = useState(true);
	const [isModalOpen, setModalOpen] = useState(false);
	const [configs, setConfigs] = useState({
		userIdleConfigurations: [],
		total: 0,
	});
	const [editConfig, setEditConfig] = useState(null);

	useEffect(() => {
		const getConfigurations = async (isSubmitted = false) => {
			if (props.isLoading) {
				return;
			}
			if (isSubmitted) {
				setLoading(true);
			}
			const { userIdleConfigurations, total, error } = await getUserIdleConfigurations({
				pageSize: props.pagination.size,
				pageIndex: props.pagination.index,
				healthSystemId: props.selectedHealthSystem.value === '0' ? null : props.selectedHealthSystem.value,
				roleId: props.selectedRole.value === '0' ? null : props.selectedRole.value,
			});

			if (!error) {
				setConfigs({ userIdleConfigurations, total });
			}
			setLoading(false);
		};
		getConfigurations();
	}, [props.pagination.index, props.pagination.size, props.selectedHealthSystem, props.isLoading, isLoading, props.selectedRole]);

	const onHealthSystemSelected = selectedHealthSystem => {
		setLoading(true);
		props.setSelectedHealthSystem(selectedHealthSystem);
	};

	const onRoleSelected = async selectedRole => {
		setLoading(true);
		props.setSelectedRole(selectedRole);
	};

	const onPaginationChange = value => {
		setLoading(true);
		props.onPaginationChange(value);
	};

	const transformArray = array => {
		const newArray = array.map(item => ({ id: item.id, value: item.value }));
		if (role === UserRoles.ADMIN) {
			newArray.unshift({ id: '0', value: intl.formatMessage({ id: 'all' }) });
		}
		return newArray;
	};

	const transformArrayWithLabels = array => {
		const newArray = array.map(item => ({ value: item.id, label: item.value }));
		if (role === UserRoles.ADMIN) {
			newArray.unshift({ value: '0', label: intl.formatMessage({ id: 'all' }) });
		}
		return newArray;
	};

	const openEditModal = configuration => {
		setEditConfig({
			healthSystem: configuration.team.id,
			role: configuration.role.id,
			minutes: configuration.timeoutMinutes,
		});
		setModalOpen(true);
	};

	const handleTimeTrackerSwitch = async config => {
		const response = await updateUserIdleConfiguration(config.id, !config.isEnabled);
		if (!response.error) {
			setConfigs(prevState => {
				const configsCopied = _.cloneDeep(prevState);
				const findIndex = configsCopied.userIdleConfigurations.findIndex(item => config.id === item.id);
				configsCopied.userIdleConfigurations[findIndex].isEnabled = !config.isEnabled;
				return configsCopied;
			});
		} else {
			props.setError(response.error.message);
		}
	};

	const getButtons = configuration => {
		return (
			<div className='wrapped idle-configs-button'>
				<span
					className='cursor-pointer mr-20'
					data-tooltip={intl.formatMessage(
						{ id: 'timeTrackerValue' },
						{ value: intl.formatMessage({ id: !configuration.isEnabled ? 'enable' : 'disable' }) }
					)}
					data-position='top'
					onClick={() => handleTimeTrackerSwitch(configuration)}>
					<img src={`${healthCareCdnUrl}admin/time-tracker-${configuration.isEnabled ? 'off' : 'on'}.svg`} alt='icon' />
				</span>
				<Link to={`/configurations/${configuration.id}/type/${ConfigHistoryTypes.UserIdle}`} onClick={props.onHistoryClick}>
					<span
						className='material-icons-outlined cursor-pointer mr-20'
						data-cy='viewHistory'
						data-tooltip={intl.formatMessage({ id: 'viewHistory' })}
						data-position='top'>
						list_alt
					</span>
				</Link>
				<span
					className='material-icons-outlined cursor-pointer mr-20'
					data-cy='edit'
					data-tooltip={intl.formatMessage({ id: 'edit' })}
					data-position='top'
					onClick={() => openEditModal(configuration)}>
					create
				</span>
			</div>
		);
	};

	const getTableConfigurations = () => {
		if (isLoading) {
			return [];
		}
		return configs.userIdleConfigurations.map(item => ({
			healthSystem: item.team.name,
			role: item.role.name,
			timeoutMinutes: item?.isEnabled ? item.timeoutMinutes : intl.formatMessage({ id: 'disabled' }),
			createdBy: `${item.userCreated.firstName} ${item.userCreated.lastName}`,
			dateCreated: getFormattedDateWithHour(item.dateCreated),
			edit: getButtons(item),
			id: item.id,
		}));
	};

	return (
		<div>
			<Table
				isLoading={isLoading}
				headers={[
					{ title: intl.formatMessage({ id: 'healthSystem' }), id: 0 },
					{ title: intl.formatMessage({ id: 'role' }), id: 1 },
					{ title: intl.formatMessage({ id: 'minutes' }), id: 2 },
					{ title: intl.formatMessage({ id: 'createdBy' }), id: 3 },
					{ title: intl.formatMessage({ id: 'dateCreated' }), id: 4 },
					{ title: '', id: 5 },
				]}
				rows={getTableConfigurations()}>
				<Grid columns='1fr 1fr 2fr' gridGap='10px' vertAlign='center'>
					<Select
						value={props.selectedHealthSystem}
						placeholder={intl.formatMessage({ id: 'selectHealthSystem' })}
						isDisabled={role === UserRoles.SUPER_USER}
						classNamePrefix='react-select'
						options={transformArrayWithLabels(props.healthSystems)}
						onChange={hs => onHealthSystemSelected(hs)}
					/>
					<Select
						value={props.selectedRole}
						placeholder={`${intl.formatMessage({ id: 'selectRole' })}...`}
						classNamePrefix='react-select'
						options={transformArrayWithLabels(Object.values(UserRolesWithLabels))}
						onChange={selectedRole => onRoleSelected(selectedRole)}
					/>
					<Button
						className='text-transform-none border-radius-m'
						text={intl.formatMessage({ id: 'setTimeOut' })}
						horizAlign='end'
						onClick={() => setModalOpen(true)}
					/>
				</Grid>
			</Table>
			{!isLoading && (
				<Pagination
					totalCount={configs.total}
					pageSize={props.pagination.size}
					pageIndex={props.pagination.index}
					onChange={(size, index) => onPaginationChange({ size, index })}
				/>
			)}
			<UserIdleForm
				isModalOpen={isModalOpen}
				initialValues={editConfig || null}
				isSuperUser={role === UserRoles.SUPER_USER}
				healthSystems={props.healthSystems}
				toggleModal={() => {
					setModalOpen(!isModalOpen);
					setEditConfig(null);
				}}
				onSucceeded={() => setLoading(true)}
				roles={transformArray(Object.values(UserRolesWithLabels))}
			/>
		</div>
	);
};

export default UserIdleConfiguration;
