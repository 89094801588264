import React from 'react';
import { useIntl } from 'react-intl';
import { LastConditionTypes, ConditionRiskLevels, ParticipantState } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';

const RiskAssessmentBadge = props => {
	const intl = useIntl();
	const showLastCondition = props.feed.lastCondition && props.feed.status === ParticipantState.CONNECTED.type;
	let foundLastCondition = null;
	let lastConditionRiskLevel = null;
	let lastConditionDisplayText = null;

	if (props.feed.lastCondition && Object.keys(props.feed.lastCondition).length > 0) {
		foundLastCondition = LastConditionTypes.find(item => item.code === props.feed.lastCondition.code);
		if (foundLastCondition) {
			lastConditionRiskLevel = ConditionRiskLevels.find(item => item.type === foundLastCondition.risk);
		}
	}
	if (props.feed.lastCondition && Object.keys(props.feed.lastCondition).length > 0 && foundLastCondition) {
		lastConditionDisplayText = intl.formatMessage({ id: foundLastCondition?.text });
	}
	if (props.feed.lastCondition && Object.keys(props.feed.lastCondition).length > 0 && !foundLastCondition) {
		lastConditionDisplayText = props.feed.lastCondition.display;
	}
	return (
		<>
			{showLastCondition &&
				props.feed.lastCondition &&
				Object.keys(props.feed.lastCondition).length > 0 &&
				lastConditionDisplayText && (
					<div className='video-feed-condition flex'>
						<div className='flex' data-tooltip={lastConditionDisplayText} data-position='right'>
							{props.feed.lastCondition && Object.keys(props.feed.lastCondition).length > 0 && foundLastCondition && (
								<>
									<img src={`${healthCareCdnUrl}monitoring/video-feed/${lastConditionRiskLevel?.img}`} alt='icon' />
									<p style={{ color: lastConditionRiskLevel?.color || 'var(--red-1)' }}>{lastConditionDisplayText}</p>
								</>
							)}
							{props.feed.lastCondition && Object.keys(props.feed.lastCondition).length > 0 && !foundLastCondition && (
								<>
									<img src={`${healthCareCdnUrl}monitoring/video-feed/high-risk.svg`} alt='icon' />
									<p style={{ color: 'var(--red-1)' }}>{lastConditionDisplayText}</p>
								</>
							)}
						</div>
					</div>
				)}
		</>
	);
};

export default RiskAssessmentBadge;
