import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { CSVLink } from 'react-csv';
import _ from 'lodash';
import Button from 'components/Button.jsx';
import Table from 'components/Table.jsx';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from 'components/Tabs.jsx';
import Form from 'components/Form.jsx';
import Modal from 'components/Modal.jsx';
import DescriptionBox from 'components/DescriptionBox.jsx';
import { actionCreators } from 'state/healthSystems/actions.js';
import { getDeviceList } from 'api/devices.js';
import { deleteOrgUnit } from 'api/healthSystems.js';
import { DeviceListLevel, TeamTypes, ConnectivityStatus, UserRoles } from 'constants/enums.js';
import EditSectorModal from 'containers/EditSectorModal.jsx';
import {
	findSectorById,
	getParentSector,
	buildTree,
	decodeHtml,
	getCodeNameByOsVersion,
	getAppVersion,
	getHierarchyByHelloDeviceId,
	findDeviceById,
	getHealthSystemDevices,
	sortDevicesByTreeRoomsOrder,
	getConfigurationValue,
	getLevelDescriptionById,
} from 'infrastructure/helpers/commonHelpers.js';
import translate from 'i18n-translations/translate.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import Alert from 'components/Alert.jsx';
import AssignDoctor from 'components/AssignDoctor.jsx';
import { getDoctorAssigned } from 'api/doctors.js';
import { unAssignMember } from 'api/users.js';
import { getRegionSubTree } from 'api/tree.js';
import DeviceActions from 'components/DeviceActions.jsx';
import VoiceCommandCallingModal from 'containers/VoiceCommandCallingModal.jsx';
import Dropdown from 'components/Dropdown.jsx';
import VisitingHoursConfiguration from 'components/VisitingHoursConfiguration.jsx';
import { getVisitingHours } from 'api/visitingHours.js';
import { getInitialVisitingHoursMaped, reArrangeVisitingHours } from 'infrastructure/helpers/visitingHoursHelper.js';
import { actionCreators as devicesActionCreators } from 'state/devices/actions.js';
import AlexaSmartPropertiesWrapper from 'containers/AlexaSmartPropertiesWrapper.jsx';
import { getRoomsBySectorId, getTeamSettings } from 'api/adminConfigurations.js';
import { GeneralSettings, MonitoringSettings, SettingsCategory, CompanySettings } from 'constants/configurationEnums.js';
import { getUserRole } from 'infrastructure/auth.js';
import HandHygieneAudit from 'components/HandHygieneAudit.jsx';
import { mapSectionsToRender } from 'infrastructure/helpers/mapSectorsToRender.js';
import Pagination from 'components/Common/Pagination.jsx';
import MonitoringAiFeatureFlags from 'containers/Organization/MonitoringAiFeatureFlags.jsx';

const Floor = props => {
	const [devices, setDevices] = useState([]);
	const [selectedOrg, setSelectedOrg] = useState({ name: '', level: null, id: null });
	const [isDeleteOrgUnitModalOpen, setIsDeleteOrgUnitModalOpen] = useState(false);
	const [sectorData, setSectorData] = useState({ sectorName: '', hospitalId: '' });
	const [isDeleteDeviceModalLoading, setIsDeleteDeviceModalLoading] = useState(false);
	const [currentTab, setCurrentTab] = useState(0);
	const [isEditSectorModalOpen, setIsEditSectorModalOpen] = useState(false);
	const [error, setError] = useState(null);
	const [doctors, setDoctors] = useState([]);
	const [isAddDoctorShown, setIsAddDoctorShown] = useState(false);
	const [selectedDoctor, setSelectedDoctor] = useState(null);
	const [currentFloor, setCurrentFloor] = useState({
		sectorName: '',
		floorName: '',
		hospitalId: '',
		departmentId: '',
		floorId: '',
		sectorType: '',
	});

	const [currentSector, setCurrentSector] = useState([]);
	const [isVoiceCommandCallingModalOpen, setVoiceCommandCallingModalOpen] = useState(false);
	const intl = useIntl();
	const history = useHistory();
	const dispatch = useDispatch();
	const setHealthSystem = hs => dispatch(actionCreators.setHealthSystem(hs));
	const updateBreadcrumb = breadcrumb => dispatch(actionCreators.updateBreadcrumb(breadcrumb));
	const setTreeData = tree => dispatch(actionCreators.setTreeData(tree));
	const healthSystems = useSelector(state => state.healthSystems);
	const userSession = useSelector(state => state.user.userSession);
	const [helloDeviceId, setHelloDeviceId] = useState('');
	const [isVisitingHoursModalOpen, setIsVisitingHoursModalOpen] = useState(false);
	const treeHierarchyTypeId = healthSystems.allHealthSystems.find(
		item => item.id === userSession.healthSystem.id
	)?.treeHierarchyTypeId;
	const [visitingHours, setVisitingHours] = useState([]);
	const [isAlexaSmartPropertiesOpen, setIsAlexaSmartPropertiesOpen] = useState(false);
	const [isTableDataLoading, setIsTableDataLoading] = useState(true);
	const [isAlexaPropertiesEnabled, setIsAlexaPropertiesEnabled] = useState(false);
	const [showHandHygiene, setShowHandHygiene] = useState(false);
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0, totalCount: 0 });
	const companyConfigurations = useSelector(state => state.company.companySettings?.companyConfigurations);

	useEffect(() => {
		const getCurrentFloor = () => {
			let floor = {
				sectorName: '',
				floorName: '',
				hospitalId: '',
				departmentId: '',
				floorId: '',
				sectorType: '',
			};
			if (healthSystems.treeData.tree.length > 0) {
				const selectedFloor = findSectorById(healthSystems.treeData.tree, props.match.params.floorId);
				floor = {
					sectorName: selectedFloor ? selectedFloor.name : '',
					floorName: selectedFloor ? selectedFloor.name : '',
					hospitalId: selectedFloor ? selectedFloor.hospitalId : '',
					departmentId: selectedFloor ? selectedFloor.departmentId : '',
					floorId: selectedFloor ? selectedFloor.floorId : '',
					sectorType: selectedFloor ? selectedFloor.type : '',
				};
				if (selectedFloor) {
					setCurrentSector([selectedFloor]);
				}
			}
			return floor;
		};
		setCurrentFloor(getCurrentFloor());
	}, [healthSystems.treeData.tree, props.match.params.floorId]);

	useEffect(() => {
		const loadFloor = async () => {
			const [devicesRes, assignedDoctorsRes] = await Promise.all([
				getDeviceList(DeviceListLevel.FLOOR, props.match.params.floorId),
				getDoctorAssigned(props.match.params.floorId, pagination),
			]);
			if (devicesRes.error) {
				setError(devicesRes.error.message);
			} else {
				const sector = findSectorById(healthSystems.treeData.tree, props.match.params.floorId);
				if (sector?.subOptions) {
					for (let index = 0; index < devicesRes.length; index += 1) {
						const device = findDeviceById(sector.subOptions, devicesRes[index].solHelloDeviceId);
						if (device) {
							device.voiceCommandCalling = devicesRes[index].voiceCommandCalling;
							devicesRes[index].roomName = device.name;
							devicesRes[index].roomId = device.roomId;
						}
					}
				}
				if (sector) {
					setCurrentSector([sector]);
				}

				setDevices(devicesRes);
			}
			if (assignedDoctorsRes.error) {
				setError(assignedDoctorsRes.error.message);
			} else {
				setDoctors(mapDoctors(assignedDoctorsRes.doctors));
				setPagination(prevState => ({ ...prevState, totalCount: assignedDoctorsRes.totalCount }));
			}
			setIsTableDataLoading(false);
		};

		const loadRoomsHandHygieneConfigs = async () => {
			const response = await getRoomsBySectorId(props.match.params.departmentId, MonitoringSettings.HandWashing, 'false');
			if (response.error) {
				setError(response.error.message);
			} else {
				const allRoomsHaveHandHygieneOff = response.rooms.length < getSections().rooms.length;
				setShowHandHygiene(allRoomsHaveHandHygieneOff);
			}
		};

		const fetchVisitingHours = async () => {
			const response = await getVisitingHours(DeviceListLevel.FLOOR, props.match.params.floorId);
			if (response.error) {
				setError(response.error.message);
			} else {
				const result = getInitialVisitingHoursMaped(response.visitingHours);
				const visitingHoursRearragned = reArrangeVisitingHours(result, intl);
				setVisitingHours(visitingHoursRearragned);
			}
		};

		const fetchSectorSettings = async () => {
			const response = await getTeamSettings({
				teamId: props.match.params.floorId,
				levelId: DeviceListLevel.FLOOR,
				settingsCategory: [SettingsCategory.GENERAL],
			});

			if (response.error) {
				setError(response.error.message);
				return;
			}

			const alexaSetting = response.settings.filter(x => x.settingTypeId === GeneralSettings.Alexa)[0];
			setIsAlexaPropertiesEnabled(alexaSetting?.value.toLowerCase() === 'true');
		};
		fetchSectorSettings();
		fetchVisitingHours();
		loadFloor();

		if (getUserRole() !== UserRoles.SUPER_USER) {
			loadRoomsHandHygieneConfigs();
		}

		return () => {
			setIsTableDataLoading(true);
		};
	}, [currentFloor, pagination.pageSize, pagination.pageIndex]);

	const openEditSectorModal = selectedSector => {
		const sector = findSectorById(healthSystems.treeData.tree, selectedSector[`${selectedSector.sectorType}Id`]);
		const sectorParent = getParentSector(healthSystems.treeData.tree, sector);
		selectedSector.parentSectorName = sectorParent.name;
		selectedSector.parentSectorType = sectorParent.type;
		setSectorData(selectedSector);
		setIsEditSectorModalOpen(prevState => !prevState);
	};

	const getConnectionType = device => (
		<>
			{[device.wifi, device.ethernet].includes(ConnectivityStatus.ON) && (
				<>
					{device.wifi === ConnectivityStatus.ON && 'WiFi'}
					{device.ethernet === ConnectivityStatus.ON && device.wifi === ConnectivityStatus.ON && ','}{' '}
					{device.ethernet === ConnectivityStatus.ON && 'Ethernet'}
				</>
			)}

			{device.wifi === ConnectivityStatus.OFF && device.ethernet === ConnectivityStatus.OFF && 'N/A'}
		</>
	);

	const getDeviceOwner = device => (
		<div className='flex'>{isPatientAssigned(device) ? decodeHtml(device.owner) : translate('unassigned')}</div>
	);

	const displayDevices = () => {
		if (devices.length === 0 || healthSystems.treeData.tree.length === 0) return [];
		const sortedDevicesList = sortDevicesByTreeRoomsOrder(devices, getSections().rooms);

		return sortedDevicesList.map(device => ({
			id: device.id,
			roomName: device.roomName,
			...(isPatientDisabled() ? [] : { owner: getDeviceOwner(device) }),
			serialNumber: device.serialNumber || 'N/A',
			macAddress: device.macAddress || 'N/A',
			appVersion: device.appVersion ? getAppVersion(device.appVersion) : 'N/A',
			androidVersion: device.appVersion ? getCodeNameByOsVersion(device.osVersion) : 'N/A',
			osVersion: device.firmwareRevision || 'N/A',
			ipAddress: device.ipAddress || 'N/A',
			ssid: device.ssid || 'N/A',
			connectionType: getConnectionType(device),
			voiceCommandCalling: intl.formatMessage({ id: device.voiceCommandCalling ? 'active' : 'notActive' }),
			actions: (
				<DeviceActions
					setHelloDeviceId={setHelloDeviceId}
					setIsDeleteDeviceModalLoading={setIsDeleteDeviceModalLoading}
					fetchTreeData={fetchTreeData}
					isDeleteDeviceModalLoading={isDeleteDeviceModalLoading}
					userSession={userSession}
					healthSystems={healthSystems}
					helloDeviceId={helloDeviceId}
					device={device}
					isPatientDisabled={isPatientDisabled()}
				/>
			),
		}));
	};

	const initDeleteOrg = data => {
		setSelectedOrg(data);
		setIsDeleteOrgUnitModalOpen(prevState => !prevState);
	};

	const deleteOrgUnitSubmit = async () => {
		await deleteOrgUnit(selectedOrg.level, selectedOrg.id);
		setIsDeleteOrgUnitModalOpen(prevState => !prevState);
		fetchTreeData();
		if (selectedOrg.level === DeviceListLevel.FLOOR) {
			history.push('/health-system');
			updateBreadcrumb([]);
		}
	};

	const fetchTreeData = async () => {
		const subTreeResponse = await getRegionSubTree(userSession.healthSystem.id, userSession.regionId);
		if (subTreeResponse.error) {
			setError(subTreeResponse.error.message);
		} else {
			const { healthSystem } = subTreeResponse.organization;
			const tree = buildTree(healthSystem);
			const { online, busy, privacy, pairedRemote } = getHealthSystemDevices(healthSystem);
			dispatch(devicesActionCreators.setBulkDevicesBusy(busy));
			dispatch(devicesActionCreators.setBulkDevicesOnline(online));
			dispatch(devicesActionCreators.setBulkDevicesPrivacy(privacy));
			dispatch(devicesActionCreators.setBulkPairedRemoteDevice(pairedRemote));
			setHealthSystem(healthSystem);
			setTreeData(tree);
		}
	};

	const onAssignDoctorSubmit = async () => {
		const response = await getDoctorAssigned(props.match.params.floorId, pagination);
		if (response.error) {
			setError(response.error.message);
			setIsAddDoctorShown(false);
			return;
		}
		setPagination(prevState => ({ ...prevState, totalCount: response.totalCount }));
		setIsAddDoctorShown(false);
		setDoctors(mapDoctors(response.doctors));
	};

	const mapDoctors = docs =>
		docs.map(item => ({
			id: item.id,
			name: `${item.firstName} ${item.lastName}`,
			actions: (
				<>
					{item.teamTypeId === TeamTypes.FLOOR && (
						<div className='wrapped'>
							<i className='material-icons-outlined boxed-icon' id={item.id} onClick={() => setSelectedDoctor(item)}>
								delete
							</i>
						</div>
					)}
				</>
			),
		}));

	const deleteDoctor = async () => {
		const response = await unAssignMember(userSession.healthSystem.id, props.match.params.floorId, selectedDoctor.id);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		const filteredDoctors = _.cloneDeep(doctors).filter(doc => doc.id !== selectedDoctor.id);
		setDoctors(filteredDoctors);
		setSelectedDoctor(null);
	};

	const getSections = () => {
		let sections = {
			hospitals: [],
			departments: [],
			floors: [],
			rooms: [],
		};
		if (healthSystems.treeData.tree.length > 0) {
			sections = mapSectionsToRender(
				healthSystems.treeData.tree,
				currentFloor,
				sector => openEditSectorModal(sector),
				initDeleteOrg,
				treeHierarchyTypeId
			);
		}
		return sections;
	};

	const exportAsCsv = () => {
		return devices.map(({ serialNumber, macAddress, appVersion, firmwareRevision, osVersion, solHelloDeviceId }) => ({
			serialNumber,
			room: getHierarchyByHelloDeviceId(healthSystems.treeData.tree, solHelloDeviceId),
			macAddress,
			appVersion,
			osVersion: firmwareRevision,
			androidVersion: getCodeNameByOsVersion(osVersion),
		}));
	};

	const updateVCCList = (solHelloDeviceIds, isChecked) => {
		const sector = _.cloneDeep(currentSector);
		solHelloDeviceIds.forEach(solHelloDeviceId => {
			const device = findDeviceById(sector, solHelloDeviceId);
			if (device) {
				device.voiceCommandCalling = isChecked;
			}
		});

		if (sector) {
			setCurrentSector(sector);
		}
	};

	const isPatientDisabled = () =>
		getConfigurationValue(companyConfigurations[CompanySettings.PATIENT_VIRTUAL_ADMISSION]) &&
		!getConfigurationValue(companyConfigurations[CompanySettings.PATIENT_MANUAL_ADMISSION]);

	const isPatientAssigned = device => !device?.isVirtualPatientAssigned && !device?.isDefaultOwner;

	return (
		<div className='organization'>
			<h3>
				<Button
					text={translate('deleteFloor')}
					variant='red'
					imgIcon={`${healthCareCdnUrl}admin/delete-room.svg`}
					onClick={() =>
						initDeleteOrg({ level: DeviceListLevel.FLOOR, id: props.match.params.floorId, name: currentFloor.floorName })
					}
				/>
				<Button
					imgIcon={`${healthCareCdnUrl}admin/edit.svg`}
					text={translate('editDetails')}
					onClick={() => openEditSectorModal(currentFloor)}
					variant='yellow'
				/>
				<Dropdown position='bottom' icon='expand_more' className='organization-dropdown'>
					<Button
						text={translate('addDoctor')}
						imgIcon={`${healthCareCdnUrl}admin/add-doctor.svg`}
						onClick={() => setIsAddDoctorShown(true)}
					/>
					<Button
						variant='white'
						imgIcon={`${healthCareCdnUrl}admin/visiting-hours.svg`}
						onClick={() => setIsVisitingHoursModalOpen(true)}
						text={intl.formatMessage({ id: 'visitingHours' })}
					/>
					{devices.length > 0 && (
						<>
							<div className='csv-link'>
								<CSVLink
									className='button white'
									data-cy='exportAsCSVBtn'
									data={exportAsCsv()}
									filename={`${currentFloor.floorName}-${(+new Date()).toString()}.csv`}>
									{translate('exportAsCSV')}
								</CSVLink>
							</div>
							<Button
								variant='white'
								text={intl.formatMessage({ id: 'voiceCommandCalling' })}
								size='medium'
								imgIcon={`${healthCareCdnUrl}admin/voice-command-calling.svg`}
								onClick={() => setVoiceCommandCallingModalOpen(prev => !prev)}
							/>
							{isAlexaPropertiesEnabled && (
								<Button
									variant='white blue-icon'
									text={intl.formatMessage({ id: 'alexaSmartProperties' })}
									size='medium'
									icon='home_max'
									onClick={() => setIsAlexaSmartPropertiesOpen(prev => !prev)}
								/>
							)}
						</>
					)}
				</Dropdown>
			</h3>
			<DescriptionBox
				data={[
					{ title: translate('roomsInThisFloor'), description: getSections().rooms.length, id: 0 },
					{ title: translate('enrolledDevices'), description: devices ? devices.length : 0, id: 1 },
				]}
			/>
			<Tabs activeIndex={currentTab} onChange={index => setCurrentTab(index)}>
				<TabList>
					<Tab>{translate('devices')}</Tab>
					<Tab>{translate('rooms')}</Tab>
					<Tab>{translate('doctorPooling')}</Tab>
					<Tab>{translate('visitingHours')}</Tab>
					{getUserRole() !== UserRoles.SUPER_USER && <Tab>{translate('featureFlags')}</Tab>}
					{getUserRole() !== UserRoles.SUPER_USER && showHandHygiene && <Tab>{translate('handHygieneAudits')}</Tab>}
				</TabList>
				<TabPanels>
					<TabPanel>
						<Table
							headers={[
								{ title: `${intl.formatMessage({ id: 'room' })}`, id: 0 },
								...(isPatientDisabled() ? [] : [{ title: translate('patient'), id: 1 }]),
								{ title: translate('deviceSerialNumber'), id: 2 },
								{ title: translate('macAddress'), id: 3 },
								{ title: translate('appVersion'), id: 4 },
								{ title: translate('androidVersion'), id: 5 },
								{ title: translate('osVersion'), id: 6 },
								{ title: translate('ipAddress'), id: 7 },
								{ title: 'SSID', id: 8 },
								{ title: translate('connectionType'), id: 9 },
								{ title: translate('voiceCommandCalling'), id: 10 },
								{ title: translate('actions'), id: 11 },
							]}
							rows={displayDevices()}
							className='admin-table'
							isEditable={false}
							isLoading={isTableDataLoading}
						/>
					</TabPanel>
					<TabPanel>
						<Table
							headers={[
								{ title: translate('roomNames'), id: 0 },
								{ title: '', id: 1 },
							]}
							rows={getSections().rooms}
							isEditable={false}
						/>
					</TabPanel>
					<TabPanel>
						<Table headers={[{ title: translate('doctorPooling'), id: 0 }]} rows={doctors} isEditable={false} />
						<Pagination
							totalCount={pagination.totalCount}
							pageSize={pagination.pageSize}
							pageIndex={pagination.pageIndex}
							onChange={(pageSize, pageIndex) => setPagination(prevState => ({ ...prevState, pageSize, pageIndex }))}
						/>
					</TabPanel>
					<TabPanel>
						<Table
							headers={[
								{ title: translate('monday'), id: 0 },
								{ title: translate('tuesday'), id: 1 },
								{ title: translate('wednesday'), id: 2 },
								{ title: translate('thursday'), id: 3 },
								{ title: translate('friday'), id: 4 },
								{ title: translate('saturday'), id: 5 },
								{ title: translate('sunday'), id: 6 },
							]}
							rows={visitingHours}
							className='admin-table'
							isEditable={false}
						/>
					</TabPanel>
					{getUserRole() !== UserRoles.SUPER_USER && (
						<TabPanel>
							<MonitoringAiFeatureFlags
								healthSystemId={userSession.healthSystem.id}
								levelId={props.match.params.floorId}
								currentSector={DeviceListLevel.FLOOR}
								setError={setError}
								setShowHandHygiene={setShowHandHygiene}
							/>
						</TabPanel>
					)}
					{getUserRole() !== UserRoles.SUPER_USER && showHandHygiene && (
						<TabPanel>
							<HandHygieneAudit currentSector={currentFloor} />
						</TabPanel>
					)}
				</TabPanels>
			</Tabs>
			<Modal
				modalSelector='deleteFloorModal'
				display={isDeleteOrgUnitModalOpen}
				position='center'
				primaryButtonLabel={translate('delete')}
				onModalSubmit={deleteOrgUnitSubmit}
				onModalClose={() => setIsDeleteOrgUnitModalOpen(prevState => !prevState)}>
				<Form title={translate('warning')} onSubmit={event => event.preventDefault()}>
					<p className='paragraph-break-word'>
						{translate('sureToDeleteOrg', {
							value: `${intl.formatMessage({ id: getLevelDescriptionById(selectedOrg.level) })} ${selectedOrg.name}`,
						})}
					</p>
				</Form>
			</Modal>
			{isEditSectorModalOpen && (
				<EditSectorModal
					onEditSector={fetchTreeData}
					onModalClose={() => setIsEditSectorModalOpen(false)}
					isEditSectorModalOpen={true}
					sectorData={sectorData}
				/>
			)}
			{isAddDoctorShown && (
				<AssignDoctor
					onSubmit={onAssignDoctorSubmit}
					onModalClose={() => setIsAddDoctorShown(false)}
					levelId={props.match.params.floorId}
					doctors={doctors}
				/>
			)}
			{selectedDoctor && (
				<Modal
					display={true}
					position='center'
					primaryButtonLabel={translate('delete')}
					onModalSubmit={deleteDoctor}
					onModalClose={() => setSelectedDoctor(null)}>
					<Form title={translate('deleteDoctor')} onSubmit={event => event.preventDefault()}>
						<p>
							{translate('sureToDeleteDoctor', {
								value: `${selectedDoctor.firstName} ${selectedDoctor.lastName}`,
							})}
						</p>
					</Form>
				</Modal>
			)}
			<Alert display={error} message={error} variant='dark' fixed={true} onClose={() => setError(null)} />
			<VoiceCommandCallingModal
				currentSector={currentSector}
				isVoiceCommandCallingModalOpen={isVoiceCommandCallingModalOpen}
				toggleVoiceCommandCallingModal={() => setVoiceCommandCallingModalOpen(prev => !prev)}
				updateVoiceCommandCallingList={updateVCCList}
			/>
			{isVisitingHoursModalOpen && (
				<VisitingHoursConfiguration setIsVisitingHoursModalOpen={setIsVisitingHoursModalOpen} sector={currentFloor} />
			)}
			{isAlexaSmartPropertiesOpen && (
				<AlexaSmartPropertiesWrapper
					sector={currentFloor}
					setIsAlexaSmartPropertiesOpen={setIsAlexaSmartPropertiesOpen}
					isAlexaSmartPropertiesOpen={isAlexaSmartPropertiesOpen}
				/>
			)}
		</div>
	);
};

export default Floor;
