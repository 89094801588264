import { Chart as ChartJs } from 'chart.js';
import styled from 'styled-components';

ChartJs.defaults.RoundedDoughnut = ChartJs.helpers.clone(ChartJs.defaults.doughnut);
ChartJs.controllers.RoundedDoughnut = ChartJs.controllers.doughnut.extend({
	draw: function (ease) {
		const ctx = this.chart.ctx;
		const easingDecimal = ease || 1;
		const arcs = this.getMeta().data;
		ChartJs.helpers.each(arcs, function (arc, i) {
			arc.transition(easingDecimal).draw();

			const pArc = arcs[i === 0 ? arcs.length - 1 : i - 1];
			const pColor = pArc._view.backgroundColor;

			const vm = arc._view;
			const radius = (vm.outerRadius + vm.innerRadius) / 2;
			const thickness = (vm.outerRadius - vm.innerRadius) / 2;
			const startAngle = Math.PI - vm.startAngle - Math.PI / 2;
			const angle = Math.PI - vm.endAngle - Math.PI / 2;

			ctx.save();
			ctx.translate(vm.x, vm.y);

			ctx.fillStyle = i === 0 ? vm.backgroundColor : pColor;
			ctx.beginPath();
			ctx.arc(radius * Math.sin(startAngle), radius * Math.cos(startAngle), thickness, 0, 2 * Math.PI);
			ctx.fill();

			ctx.fillStyle = vm.backgroundColor;
			ctx.beginPath();
			ctx.arc(radius * Math.sin(angle), radius * Math.cos(angle), thickness, 0, 2 * Math.PI);
			ctx.fill();

			ctx.restore();
		});
	},
});

/**
 * @type {import('styled-components').StyledComponent<"aside", any, props, never>}
 */
const StyledCircularChartWrapper = styled.div`
	.p-chart {
		position: relative;
	}
	> div {
		> div {
			> div {
				p:last-child {
					color: #898989;
				}
			}
		}
	}
	> div {
		width: 300px;
		margin-left: auto;
		margin-right: auto;
		&:first-of-type {
			p {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				font-size: 14px;
				font-weight: 700;
				margin: 0;
				padding: 0;
				text-align: center;
				color: #fff;

				span {
					font-size: 14px;
					font-weight: 400;
					width: 120px;
					line-height: 20px;
				}
			}

			span {
				font-size: 12px;
				color: #898989;
				text-align: center;
			}
		}
		&:last-of-type {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: var(--spacing-l);
			> div {
				display: flex;
				align-items: center;
				overflow: hidden;
				justify-content: center;
				> div {
					&:first-of-type {
						width: 12px;
						height: 12px;
						border-radius: 5px;
						margin-right: 5px;
						flex-shrink: 0;
					}
				}
				p {
					margin: 0;
					padding: 0;
					text-align: left;
					display: block;
					font-size: 16px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;

					&:last-of-type {
						font-size: 18px;
						font-weight: 600;
						line-height: 18px;
					}
				}
			}
		}
	}
`;

export { StyledCircularChartWrapper };
