import React, { useEffect, useState } from 'react';
import translate from 'i18n-translations/translate.jsx';
import { HelloSettings } from 'constants/enums.js';
import { isJSON } from 'infrastructure/helpers/commonHelpers.js';

const AspectRatio = ({ updateDeviceSetting, deviceSettings, isSettingUpdating }) => {
	const getAspectRatioObj = () => {
		const foundSetting = deviceSettings.find(item => item.settingTypeId === HelloSettings.ASPECT_RATIO);
		if (foundSetting?.value && isJSON(foundSetting.value)) {
			return JSON.parse(foundSetting.value);
		}
		return { height: 0, width: 0 };
	};
	const [aspectRatio, setAspectRatio] = useState(getAspectRatioObj());
	const step = 5;

	useEffect(() => {
		updateDeviceSetting(HelloSettings.ASPECT_RATIO, JSON.stringify(aspectRatio));
	}, [aspectRatio]);

	return (
		<div className='configurable-settings-modal-inner'>
			<h3>{translate('aspectRatio')}</h3>
			<div className='aspect-ratio-controller'>
				<div className='aspect-ratio-buttons-wrapper'>
					<button
						className={isSettingUpdating || aspectRatio.height === 100 ? 'disabled' : ''}
						onClick={() =>
							aspectRatio.height !== 100 ? setAspectRatio(prevState => ({ ...prevState, height: prevState.height + step })) : {}
						}>
						<div className='aspect-ratio-icon-wrapper column-direction top-15 left-15'>
							<span className='material-icons-outlined'>south</span>
							<div>
								<span className='material-icons-outlined'>remove</span>
							</div>
							<span className='material-icons-outlined'>north</span>
						</div>
					</button>
					<button
						className={isSettingUpdating || aspectRatio.width === 0 ? 'disabled' : ''}
						onClick={() =>
							aspectRatio.width !== 0 ? setAspectRatio(prevState => ({ ...prevState, width: prevState.width - step })) : {}
						}>
						<div className='aspect-ratio-icon-wrapper top-15 right-15'>
							<span className='material-icons-outlined'>west</span>
							<div>
								<span className='material-icons-outlined'>add</span>
							</div>
							<span className='material-icons-outlined'>east</span>
						</div>
					</button>
					<button
						className={isSettingUpdating || aspectRatio.width === 100 ? 'disabled' : ''}
						onClick={() =>
							aspectRatio.width !== 100 ? setAspectRatio(prevState => ({ ...prevState, width: prevState.width + step })) : {}
						}>
						<div className='aspect-ratio-icon-wrapper bottom-15 left-15'>
							<span className='material-icons-outlined'>east</span>
							<div>
								<span className='material-icons-outlined'>remove</span>
							</div>
							<span className='material-icons-outlined'>west</span>
						</div>
					</button>
					<button
						className={isSettingUpdating || aspectRatio.height === 0 ? 'disabled' : ''}
						onClick={() =>
							aspectRatio.height !== 0 ? setAspectRatio(prevState => ({ ...prevState, height: prevState.height - step })) : {}
						}>
						<div className='aspect-ratio-icon-wrapper column-direction bottom-15 right-15'>
							<span className='material-icons-outlined'>north</span>
							<div>
								<span className='material-icons-outlined'>add</span>
							</div>
							<span className='material-icons-outlined'>south</span>
						</div>
					</button>
					<div className='inner-circle'></div>
				</div>
			</div>
		</div>
	);
};

export default AspectRatio;
