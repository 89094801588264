import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { getUserRole, isAuthenticated } from 'infrastructure/auth.js';
import { HealthSystemType, UserRoles } from 'constants/enums.js';
import PatientHomePage from 'views/PatientHomePage.jsx';
import DigitalClinicianCheckIn from 'views/DigitalClinicianCheckIn.jsx';
import HealthSystem from 'views/HealthSystem.jsx';
import Companies from 'views/Companies.jsx';
import Monitoring from 'views/Monitoring.jsx';
import Visitor from 'views/Visitor.jsx';
import LandingPage from 'views/LandingPage.jsx';
import Unauthorized from 'components/Unauthorized.jsx';
import {
	getConfigurationMenu,
	getConfigurationValue,
	getNursePoolingStatus,
	getProperUrl,
	getStorage,
	isStandAloneAppsUserAuthorized,
	isRedirectVirtualCareSession,
	isSessionEhr,
} from 'infrastructure/helpers/commonHelpers.js';
import ContinueAs from 'containers/ContinueAs.jsx';
import WaitingRoom from 'views/WaitingRoom.jsx';
import Patients from 'views/Patients/Patients.jsx';
import Appointments from 'views/Appointments.jsx';
import VitalSignsMonitoring from 'views/VitalSignsMonitoring.jsx';
import Nurses from 'components/Nurses.jsx';
import Teams from 'views/Teams.jsx';
import DigitalClinicianVisits from 'containers/PrimaryCare/DigitalClinician/DigitalClinicianVisits.jsx';
import DigitalClinicianPrimaryCareCheckIn from 'containers/PrimaryCare/DigitalClinician/DigitalClinicianPrimaryCareCheckIn.jsx';
import PatientReports from 'containers/Patients/PatientReports.jsx';
import Configurations from 'views/Configurations.jsx';
import Users from 'views/Users.jsx';
import CallLogs from 'views/CallLogs/CallLogs.jsx';
import MyDoctors from 'views/Doctors/MyDoctors.jsx';
import MyVitalSigns from 'views/Patients/MyVitalSigns.jsx';
import PatientSummary from 'components/PatientSummary.jsx';
import MyHelloDevices from 'views/HelloDevices/MyHelloDevices.jsx';
import Visitors from 'views/Visitors.jsx';
import WaitingRoomHome from 'views/WaitingRoomHome.jsx';
import { GeneralSettings } from 'constants/configurationEnums.js';
import PoolingFlowWrapper from 'views/PoolingFlowWrapper.jsx';
import CallPatientOptions from 'views/ehr/CallPatientOptions.jsx';
import CheckInHome from 'views/CheckInHome.jsx';
import DoctorHomePageWrapper from 'views/Doctors/DoctorHomePageWrapper.jsx';
import Dashboard from 'views/Dashboard.jsx';
import AlertCenter from 'views/AlertCenter.jsx';
import MonitoringMayo from 'views/MonitoringMayo.jsx';
import AlertCenterMayo from 'views/AlertCenterMayo.jsx';
import FleetManagement from 'views/FleetManagement.jsx';
import PatientMonitoring from 'containers/Rpm/PatientMonitoring.jsx';

const Home = () => {
	const userSession = useSelector(state => state.user.userSession);
	const configurations = useSelector(state => state.configurations);
	const isNewExperience = useSelector(state => state.configurations.isNewExperience);

	const getDoctorRoleComponent = url => {
		let result = null;
		switch (url) {
			case '/doctor-home': {
				result = <DoctorHomePageWrapper />;
				break;
			}
			case '/waiting-room': {
				result = <WaitingRoom />;
				break;
			}
			case '/waiting-room-inperson': {
				result = <WaitingRoomHome />;
				break;
			}
			case '/patients': {
				result = <Patients />;
				break;
			}
			case '/appointments': {
				result = <Appointments />;
				break;
			}
			case '/health-system': {
				result = <HealthSystem />;
				break;
			}
			case '/patient-monitoring': {
				result = <PatientMonitoring />;
				break;
			}
			default: {
				result = <DoctorHomePageWrapper />;
			}
		}
		return result;
	};

	const isPoolingFlow = () => {
		if (isRedirectVirtualCareSession() && !getStorage().getItem('ref_token')) {
			return false;
		}
		const adminConfigurableMenu = getConfigurationMenu(configurations.adminConfigurableMenu, getUserRole());
		const configurableMenu = getConfigurationMenu(configurations.configurableMenu, getUserRole());
		return (
			getUserRole() === UserRoles.NURSE &&
			getConfigurationValue(adminConfigurableMenu[GeneralSettings.PoolingFlow]) &&
			getConfigurationValue(configurableMenu[GeneralSettings.PoolingFlow]) &&
			!getNursePoolingStatus()
		);
	};

	const getNurseRoleComponent = url => {
		let result = null;
		if (isPoolingFlow()) {
			return <PoolingFlowWrapper />;
		}

		switch (url) {
			case '/nurse-home': {
				result = <CheckInHome />;
				break;
			}
			case '/health-system': {
				result = <HealthSystem />;
				break;
			}
			case '/waiting-room': {
				result = <WaitingRoom />;
				break;
			}
			case '/waiting-room-inperson': {
				result = <WaitingRoomHome />;
				break;
			}
			case '/nurses': {
				result = <Nurses />;
				break;
			}
			case '/teams': {
				result = <Teams />;
				break;
			}
			case '/monitoring': {
				result = isNewExperience ? <MonitoringMayo /> : <Monitoring />;
				break;
			}
			case '/patient-monitoring': {
				result = <PatientMonitoring />;
				break;
			}
			case '/visitors': {
				result = <Visitors />;
				break;
			}
			case '/ambient-monitoring': {
				result = isNewExperience ? <AlertCenterMayo /> : <AlertCenter />;
				break;
			}
			default: {
				result = <HealthSystem />;
			}
		}

		return result;
	};

	const getDigitalClinicianComponent = url => {
		let result = null;
		switch (url) {
			case '/check-in-dc': {
				result = <DigitalClinicianCheckIn />;
				break;
			}
			case '/digital-clinician/patients': {
				result = <PatientReports />;
				break;
			}
			case '/vital-signs-monitoring': {
				result = <VitalSignsMonitoring />;
				break;
			}
			case '/front-desk-visits': {
				result = <DigitalClinicianVisits />;
				break;
			}
			case '/check-in-patient': {
				result = <DigitalClinicianPrimaryCareCheckIn />;
				break;
			}
			default: {
				result = <DigitalClinicianCheckIn />;
			}
		}
		return result;
	};

	const getPatientComponent = url => {
		let result = null;
		switch (url) {
			case '/patient-home': {
				result = <PatientHomePage />;
				break;
			}
			case '/recent': {
				result = <MyDoctors />;
				break;
			}
			case '/appointments': {
				result = <Appointments />;
				break;
			}
			case '/health-data': {
				result = <MyVitalSigns />;
				break;
			}
			case '/summary': {
				result = <PatientSummary />;
				break;
			}
			case '/my-hello-devices': {
				result = <MyHelloDevices />;
				break;
			}
			default: {
				result = <PatientHomePage />;
			}
		}
		return result;
	};

	const getAdminComponent = url => {
		let result = null;
		switch (url) {
			case '/health-system': {
				result = <HealthSystem />;
				break;
			}
			case '/call-logs': {
				result = <CallLogs />;
				break;
			}
			case '/users': {
				result = <Users />;
				break;
			}
			case '/configurations': {
				result = <Configurations />;
				break;
			}
			case '/dashboard': {
				result = <Dashboard />;
				break;
			}
			case '/fleet-management': {
				result = <FleetManagement />;
				break;
			}
			default: {
				result = <HealthSystem />;
			}
		}
		return result;
	};

	const getVirtualSitterComponent = url => {
		let result = null;
		switch (url) {
			case '/teams': {
				result = <Teams />;
				break;
			}
			case '/monitoring': {
				result = isNewExperience ? <MonitoringMayo /> : <Monitoring />;
				break;
			}
			case '/ambient-monitoring': {
				result = isNewExperience ? <AlertCenterMayo /> : <AlertCenter />;
				break;
			}
			default: {
				result = isNewExperience ? <MonitoringMayo /> : <Monitoring />;
			}
		}
		return result;
	};

	const getComponentToRender = () => {
		const result = { component: null, url: '/' };
		const userRole = getUserRole();
		if (!isAuthenticated()) {
			if (window.location.href.includes('banyan')) {
				return <Redirect to='/login' />;
			}
			return <LandingPage />;
		}

		if (isSessionEhr()) {
			return <CallPatientOptions />;
		}

		result.url = getProperUrl(configurations, userSession.healthSystem.workflowTypeId === HealthSystemType.PRIMARY_CARE);
		if (!userRole || isStandAloneAppsUserAuthorized()) {
			result.url = '/continue-as';
			result.component = <ContinueAs />;
		}
		switch (userRole) {
			case UserRoles.VIRTUAL_SITTER: {
				result.component = getVirtualSitterComponent(result.url);
				break;
			}
			case UserRoles.SUPER_ADMIN: {
				result.component = <Companies />;
				break;
			}
			case UserRoles.ADMIN:
			case UserRoles.SUPER_USER: {
				result.component = getAdminComponent(result.url);
				break;
			}
			case UserRoles.NURSE: {
				const isPrimaryCare = userSession.healthSystem.workflowTypeId === HealthSystemType.PRIMARY_CARE;
				const integrationId = getStorage().getItem('iframe_integration_id');
				result.url = isPrimaryCare ? 'nurse-home' : result.url;
				result.url = isPoolingFlow() && !integrationId ? 'pooling-flow' : result.url;
				result.url =
					isRedirectVirtualCareSession() && getStorage().getItem('ref_token') ? getStorage().getItem('redirectUri') : result.url;
				result.url = result.url.includes('nurse-home') && !isPrimaryCare ? 'health-system' : result.url;
				result.component = getNurseRoleComponent(result.url);
				break;
			}
			case UserRoles.PATIENT: {
				result.component = getPatientComponent(result.url);
				break;
			}
			case UserRoles.DIGITAL_CLINICIAN: {
				result.component = getDigitalClinicianComponent(result.url);
				break;
			}
			case UserRoles.DOCTOR: {
				const isPrimaryCare = userSession.healthSystem.workflowTypeId === HealthSystemType.PRIMARY_CARE;
				result.url = isPrimaryCare ? 'doctor-home' : result.url;
				result.component = getDoctorRoleComponent(result.url);
				break;
			}
			case UserRoles.VISITOR: {
				result.component = <Visitor />;
				break;
			}
			default: {
				result.component = <Unauthorized />;
			}
		}
		window.history.replaceState(null, 'healthcare', result.url);
		return <Redirect to={result.url} />;
	};

	return getComponentToRender();
};

export default Home;
