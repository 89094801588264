import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import OverviewDataBox from 'components/HealthMeasurements/OverviewDataBox';
import { MeasurementTypes, UnitCategoryTypes } from 'constants/enums.js';
import { getUserMeasurementUnits } from 'api/measurements.js';
import { convertMeasurementTypes } from 'infrastructure/helpers/measurementsHelper.js';
import { cameraMeasurementsToShow } from 'constants/measurements.js';
import { getUserId } from 'infrastructure/auth.js';
import Alert from 'components/Alert.jsx';
import SocketEvents from 'constants/socket-events.js';

class OldCameraMeasurements extends Component {
	socket = this.context;

	state = {
		analyses: {
			[MeasurementTypes.HEART_RATE]: {
				value: 0,
				startDate: null,
			},
			[MeasurementTypes.BLOOD_PRESSURE]: {
				value: 0,
				startDate: null,
			},
			[MeasurementTypes.STRESS_LEVEL]: {
				value: '--',
				startDate: null,
			},
			[MeasurementTypes.BLOOD_GLUCOSE]: {
				value: 0,
				startDate: null,
				unitCategoryId: UnitCategoryTypes.BLOOD_GLUCOSE,
			},
			[MeasurementTypes.RESPIRATORY_RATE]: {
				value: 0,
				startDate: null,
			},
			[MeasurementTypes.OXYGEN]: {
				value: 0,
				startDate: null,
			},
			[MeasurementTypes.HRV_INDEX]: {
				value: 0,
				startDate: null,
			},
			[MeasurementTypes.BILIRUBIN]: {
				value: 0,
				startDate: null,
			},
		},
		isLoading: true,
		unitPreferences: [],
		error: '',
	};

	userId = getUserId();

	getUnitByType = (type, unitPreferences) => {
		return unitPreferences.length > 0 && unitPreferences.find(item => item.type === type).value;
	};

	componentDidMount = async () => {
		this.socket.on(SocketEvents.Conference.ON_CAMERA_MEASUREMENTS, this.cameraMeasurementsListener);
		const response = await getUserMeasurementUnits(this.userId);
		if (response.error) {
			this.setState({ error: response.error.message, isLoading: false });
		} else {
			this.setState({ unitPreferences: response.unitPreferences, isLoading: false });
		}
	};

	componentWillUnmount() {
		this.socket.off(SocketEvents.Conference.ON_CAMERA_MEASUREMENTS, this.cameraMeasurementsListener);
	}

	getCategoryPreference = categoryId => this.state.unitPreferences.find(item => item.unitCategoryId === categoryId);

	getUnitPreference = categoryId => {
		const selectedPreference = this.getCategoryPreference(categoryId);
		return selectedPreference?.options.find(item => item.unitSystemId === selectedPreference.unitSystemId);
	};

	getMeasurementValue = measurement => {
		return !measurement.unitCategoryId
			? measurement.value
			: convertMeasurementTypes(
					measurement.unitCategoryId,
					measurement.value,
					this.getUnitPreference(measurement.unitCategoryId)?.unitSystemId
			  );
	};

	cameraMeasurementsListener = data => {
		// Show data only if it belongs to the current conference.
		if (this.props.conferenceId === data.conferenceId) {
			const analyses = { ...this.state.analyses };
			const { measurementType, measurementValue, startDate, unitCategoryId } = data;
			if (analyses[measurementType]) {
				analyses[measurementType].value = convertMeasurementTypes(
					unitCategoryId,
					measurementValue,
					this.getUnitPreference(unitCategoryId)?.unitSystemId
				);
				analyses[measurementType].startDate = startDate;
				// static atm
				analyses[MeasurementTypes.BILIRUBIN].startDate = startDate;
				analyses[MeasurementTypes.BILIRUBIN].value = 115;
				analyses[MeasurementTypes.BLOOD_GLUCOSE].startDate = startDate;
				analyses[MeasurementTypes.BLOOD_GLUCOSE].value = 126;
				this.setState({ analyses }, () => {
					this.props.toggleOnCameraMeasurements();
				});
			}
		}
	};

	render() {
		return (
			<div>
				<Alert display={this.state.error} fixed={true} hideCloseButton={true} message={this.state.error} variant='dark' />
				{!this.state.isLoading && (
					<>
						<aside className='left-side right-side-measurements-show'>
							{cameraMeasurementsToShow.map(
								item =>
									item.id <= 3 && (
										<OverviewDataBox
											title={item.title}
											measurement={item}
											measurementUnit={
												this.state.analyses[item.type].unitCategoryId
													? this.getUnitPreference(this.state.analyses[item.type].unitCategoryId)?.unit
													: item.unit
											}
											measurementValue={this.getMeasurementValue(this.state.analyses[item.type])}
											startDate={this.state.analyses[item.type].startDate}
											key={item.type}
										/>
									)
							)}
						</aside>
						<aside
							className={`right-side right-side-measurements-show${
								this.props.isCameraFeedVisible ? ' camera-feed-measurements-show' : ''
							}`}>
							{cameraMeasurementsToShow.map(
								item =>
									item.id > 3 && (
										<OverviewDataBox
											title={item.title}
											measurement={item}
											measurementUnit={
												this.state.analyses[item.type].unitCategoryId
													? this.getUnitPreference(this.state.analyses[item.type].unitCategoryId)?.unit
													: item.unit
											}
											measurementValue={this.getMeasurementValue(this.state.analyses[item.type])}
											startDate={this.state.analyses[item.type].startDate}
											key={item.type}
										/>
									)
							)}
						</aside>
					</>
				)}
			</div>
		);
	}
}

export default injectIntl(OldCameraMeasurements);
