import { useIntl } from 'react-intl';
import { CompanyCallSettings } from 'constants/configurationEnums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';

const CallSettings = ({ handleChangeConfig, submitParams }) => {
	const intl = useIntl();

	const settings = [
		{
			key: CompanyCallSettings.PROVIDER_MIC,
			title: 'providerMic',
			description: 'providerMicDesc',
			icon: `${healthCareCdnUrl}microphone/mic-black.svg`,
		},
		{
			key: CompanyCallSettings.PATIENT_MIC,
			title: 'patientMicrophone',
			description: 'patientMicDesc',
			icon: `${healthCareCdnUrl}microphone/mic-black.svg`,
		},
		{
			key: CompanyCallSettings.PROVIDER_CAMERA,
			title: 'providerCamera',
			description: 'disableProviderCameraDesc',
			icon: `${healthCareCdnUrl}admin/super-admin/talk-to-patient.svg`,
		},
		{
			key: CompanyCallSettings.PATIENT_CAMERA,
			title: 'patientCamera',
			description: 'patientCameraDesc',
			icon: `${healthCareCdnUrl}admin/super-admin/talk-to-patient.svg`,
		},
	];

	return (
		<>
			<h3>{translate('callSettings')}</h3>
			{settings.map(item => (
				<div className='flex call-config-settings' key={item.key}>
					<div className='call-config-type'>
						<div>
							<img src={item.icon} alt='icon' />
						</div>
						<section>
							<p>
								{intl.formatMessage({ id: item.title })}
								<br />
								<span> {intl.formatMessage({ id: item.description })}</span>
							</p>
						</section>
					</div>
					<div className='toggle-switch' onClick={() => handleChangeConfig({ name: item.title }, false)}>
						<input type='checkbox' checked={submitParams[item.title].value} onChange={() => null} />
						<span className='toggle-body' />
					</div>
				</div>
			))}
		</>
	);
};

export default CallSettings;
