import React from 'react';
import { MeasurementUnits, MeasurementsMinMax } from 'constants/enums.js';
import DottedCircle from 'components/Patients/HealthData/HealthDataSummary/HealthDataSummaryCharts/GenericCharts/DottedCircle';

const TemperatureSummaryChart = props => {
	const maxTemperatureValue =
		props.measurementUnit === MeasurementUnits.FARENHEIT ? MeasurementsMinMax.FARENHEIT.MAX : MeasurementsMinMax.CELSIUS.MAX;

	return (
		<>
			<div className='body-temperature-graph'>
				<DottedCircle isSemiCircle={true} value={props.measurementValue / 2} numberOfLines={maxTemperatureValue / 2} />
			</div>
			<div className='flex summary-chart-data body-temperature-data'>
				<h1>
					{props.measurementValue} {props.measurementValue !== 'No Data' && <span>{props.measurementUnit}</span>}
				</h1>
			</div>
		</>
	);
};

export default TemperatureSummaryChart;
