import React, { useContext, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { actionCreators as PatientsActionCreators } from 'state/patients/actions.js';
import PatientMain from 'views/Patients/Partials/PatientMain.jsx';
import ChatList from 'views/Partials/ChatList.jsx';
import Grid from 'components/Grid.jsx';
import Loader from 'components/Loader.jsx';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import EmptyState from 'components/EmptyState.jsx';
import AdvancedSearch from 'views/Partials/AdvancedSearch.jsx';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';
import translate from 'i18n-translations/translate.jsx';
import { getMyPatients } from 'api/patients.js';
import { dissociateUserById } from 'api/users.js';
import Alert from 'components/Alert.jsx';
import { UserStatus } from 'constants/enums.js';

const Patients = props => {
	const match = { props };
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const socket = useContext(SocketContext);
	const history = useHistory();
	const dispatch = useDispatch();
	const setPatients = patients => dispatch(PatientsActionCreators.setPatients(patients));
	const setSelectedPatient = selectedPatient => dispatch(PatientsActionCreators.setSelectedPatient(selectedPatient));
	const [pageIndex, setPageIndex] = useState(0);
	const [isScrollLoading, setIsScrollLoading] = useState(true);
	const [searchValue, setSearchValue] = useState('');
	const timeout = useRef(null);
	const helloName = useSelector(state => state.company.companySettings.helloName);
	const hasReachedEnd = useRef(null);

	const patientSelector = useSelector(state => ({
		patients: state.myPatients.patients,
		fetchingMyPatients: state.myPatients.fetchingMyPatients,
		fetchingMyPatientsError: state.myPatients.fetchingMyPatientsError,
		selectedPatient: state.myPatients.selectedPatient,
		isAdvancedSearchActive: state.myPatients.isAdvancedSearchActive,
	}));

	const getPatients = async () => {
		const params = {
			pageIndex,
			pageSize: 20,
			search: searchValue,
		};
		const patients = await getMyPatients(params);
		if (patients.error) {
			setError(patients.error.message);
		} else {
			hasReachedEnd.current = patients.length < 20;
			setPatients(patients.concat(patientSelector.patients));
		}
		setIsScrollLoading(false);
		setIsLoading(false);
	};

	useEffect(() => {
		getPatients();
	}, [pageIndex]);

	useEffect(() => {
		const onUserUpdatedPresence = async ({ objectId, presenceStatusTypeId }) => {
			const patients = await getMyPatients();
			const foundPatient = patients.find(patient => patient.userId === parseInt(objectId, 10));
			if (foundPatient) {
				foundPatient.presenceStatusTypeId = presenceStatusTypeId;
				dispatch(PatientsActionCreators.setPatients(patients));
			}
		};
		socket.on(SocketEvents.Client.ON_NOTIFY_UPDATED_USER_PRESENCE, onUserUpdatedPresence);
		socket.on(SocketEvents.HealthCare.DOCTOR_PATIENT_DISSOCIATED, onDoctorPatientDissociate);

		return () => {
			socket.off(SocketEvents.Client.ON_NOTIFY_UPDATED_USER_PRESENCE, onUserUpdatedPresence);
			socket.off(SocketEvents.HealthCare.DOCTOR_PATIENT_DISSOCIATED, onDoctorPatientDissociate);
		};
	}, [socket, dispatch]);

	useEffect(() => {
		const onUserOnlineStatus = async ({ userId }) => {
			const patients = await getMyPatients();
			const foundPatient = patients.find(patient => patient.userId === userId);
			if (foundPatient) {
				foundPatient.availability = UserStatus.ONLINE;
				dispatch(PatientsActionCreators.setPatients(patients));
			}
		};
		socket.on(SocketEvents.Client.ON_USER_ONLINE, onUserOnlineStatus);

		return () => {
			socket.off(SocketEvents.Client.ON_USER_ONLINE, onUserOnlineStatus);
		};
	}, [socket, dispatch]);

	useEffect(() => {
		const onUserOfflineStatus = async ({ userId }) => {
			const patients = await getMyPatients();
			const foundPatient = patients.find(patient => patient.userId === userId);
			if (foundPatient) {
				foundPatient.availability = UserStatus.OFFLINE;
				dispatch(PatientsActionCreators.setPatients(patients));
			}
		};
		socket.on(SocketEvents.Client.ON_USER_OFFLINE, onUserOfflineStatus);

		return () => {
			socket.off(SocketEvents.Client.ON_USER_OFFLINE, onUserOfflineStatus);
		};
	}, [socket, dispatch]);

	useEffect(() => {
		const searchPatients = async () => {
			timeout.current = setTimeout(async () => {
				const response = await getMyPatients({ search: searchValue });
				if (response.error) {
					setError(response.error.message);
				} else {
					setPatients(response);
					setIsScrollLoading(false);
					hasReachedEnd.current = response.length < 20;
				}
			}, 500);
		};
		searchPatients();
		return () => clearTimeout(timeout.current);
	}, [searchValue]);

	useEffect(() => {
		const getPatientsInit = async () => {
			const params = {
				pageIndex: 0,
				pageSize: 20,
			};
			const patients = await getMyPatients(params);
			if (patients.error) {
				setError(patients.error.message);
			} else {
				hasReachedEnd.current = patients.length < 20;
				dispatch(PatientsActionCreators.setPatients(patients));
			}
			setIsScrollLoading(false);
			setIsLoading(false);
		};
		getPatientsInit();
	}, [dispatch]);

	const onChatListItemClicked = patient => {
		setSelectedPatient(patient);
		history.push(`/patients/${patient.userId}`);
	};

	const toggleRemoveConnectionDropdown = id => {
		const patients = [...patientSelector.patients];
		const selectedPatient = patients.find(item => item.id === id);
		if (selectedPatient) {
			selectedPatient.isConnectionDropdownVisible = !selectedPatient.isConnectionDropdownVisible;
		}
		setPatients(patients);
	};

	const removeConnection = async id => {
		setIsLoading(true);
		toggleRemoveConnectionDropdown(id);
		const response = await dissociateUserById(id);
		if (response.error) {
			setError(response.error.message);
		} else {
			const patients = [...patientSelector.patients];
			const newPatients = patients.filter(item => item.id !== id);
			const selectedPatient = newPatients.length > 0 ? newPatients[0] : null;
			setPatients(newPatients);
			setSelectedPatient(selectedPatient);
			const url = newPatients.length > 0 ? `/patients/${selectedPatient.userId}` : `/patients`;
			history.push(url);
		}
		setIsLoading(false);
	};

	const handleScroll = async event => {
		const isBottom = event.target.scrollHeight - Math.ceil(event.target.scrollTop) === event.target.clientHeight;
		if (isBottom && !isScrollLoading && !hasReachedEnd.current && event.target.scrollTop !== 0) {
			setIsScrollLoading(true);
			setPageIndex(prevState => prevState + 1);
		}
	};

	const onDoctorPatientDissociate = async () => {
		await getPatients();
		const selectedPatient = patientSelector.patients.length > 0 ? patientSelector.patients[0] : null;
		setSelectedPatient(selectedPatient);
	};

	useEffect(() => {
		if (match.path === '/patients/:deviceId' || match.path === '/patients/:deviceId/:tab/:subTab') {
			const findPatient = patientSelector.patients.find(patient => patient.userId === Number(match.params.deviceId));
			if (findPatient) {
				dispatch(PatientsActionCreators.setSelectedPatient(findPatient));
			}
		}
	}, [dispatch, match, patientSelector.patients]);

	return (
		<MainLayout>
			{patientSelector.fetchingMyPatients && (
				<Grid width='100%' stretch='100vh' vertAlign='center' horizAlign='center' rows='auto'>
					<Loader />
				</Grid>
			)}

			{patientSelector.fetchingMyPatientsError && (
				/* TODO: Use Error alert here */
				<p>{translate('errorWhileFetchingPatients')}</p>
			)}

			{!patientSelector.fetchingMyPatients && !patientSelector.fetchingMyPatientsError && patientSelector.patients && (
				<Grid columns='1fr 3fr' stretch='100%'>
					<aside className='patients-list'>
						{patientSelector.isAdvancedSearchActive && <AdvancedSearch />}
						{!isLoading && !patientSelector.isAdvancedSearchActive && (
							<ChatList
								removeConnection={removeConnection}
								onChatListItemClicked={onChatListItemClicked}
								isDoctorView={true}
								selectedPatient={patientSelector.selectedPatient}
								patients={patientSelector.patients}
								handleScroll={handleScroll}
								isScrollLoading={isScrollLoading}
								setSearchValue={setSearchValue}
								searchValue={searchValue}
							/>
						)}
						{isLoading && (
							<div className='patient-request-loader'>
								<Loader />
							</div>
						)}
					</aside>
					<main className='main-view'>
						<div className='flex full-height'>
							{patientSelector.selectedPatient && <PatientMain />}

							{!patientSelector.selectedPatient && (
								<div className='full-width full-height center-children'>
									{patientSelector.isAdvancedSearchActive && (
										<EmptyState title={translate('selectPatientHelloDevice', { value: helloName })} image='patients.svg' />
									)}
								</div>
							)}
						</div>
					</main>
				</Grid>
			)}
			<Alert display={error !== null} message={error} variant='dark' fixed={true} onClose={() => setError(null)} />
		</MainLayout>
	);
};

export default Patients;
