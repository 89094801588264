import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import CheckboxTree from 'react-checkbox-tree';
import { useIntl } from 'react-intl';
import { getUserInfo } from 'infrastructure/auth.js';
import Alert from 'components/Alert.jsx';
import Button from 'components/Button.jsx';
import Loader from 'components/Loader.jsx';
import { getHealthSystemSubTreeBasic } from 'api/users.js';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import {
	deleteMemberCallAvailability,
	addOrUpdateMemberCallAvailability,
	getNurseHealthSystemAvailabilities,
} from 'api/nursePooling.js';
import { NursePoolingOptions } from 'constants/enums.js';
import { Wrapper, HealthSystemsListItem, TreeViewListWrapper } from 'containers/PoolingFlow/style.js';
import { getNursePoolingStatus, getStorage } from 'infrastructure/helpers/commonHelpers.js';
import translate from 'i18n-translations/translate.jsx';

const PoolingFlow = props => {
	const [userProfile] = useState(getUserInfo);
	const [page, setPage] = useState(0);
	const [nursePoolingStatus, setNursePoolingStatus] = useState('');
	const [healthSystems, setHealthSystems] = useState([]);
	const [selectedHealthSystem, setSelectedHealthSystem] = useState(null);
	const [nodes, setNodes] = useState([]);
	const [filteredText, setFilteredText] = useState('');
	const [nodesFiltered, setFilteredNodes] = useState([]);
	const [checked, setChecked] = useState([]);
	const [expanded, setExpanded] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [initialValues, setInitialValues] = useState([]);
	const [alertErrorText, setAlertErrorText] = useState(null);

	const intl = useIntl();

	const healthSystemsSelector = useSelector(state => state.healthSystems);

	const darkMode = useSelector(state => state.user.darkMode);

	const answerExists = useRef(getNursePoolingStatus());

	const changePage = useRef(props.changePage);

	useEffect(() => {
		const mapped = healthSystemsSelector.allHealthSystems.map(item => ({
			...item,
			isChecked: false,
		}));
		setHealthSystems(mapped);
	}, [healthSystemsSelector.allHealthSystems]);

	useEffect(() => {
		if (answerExists.current && !props.isEdit) {
			changePage.current();
		}
	}, [props.isEdit]);

	useEffect(() => {
		const setCheckedBasedOnInitialValues = values => {
			const checkedTeamIds = [];
			setHealthSystems(prevState => {
				const prevHs = [...prevState];
				prevHs.forEach(hs => {
					const initialValue = values.find(val => val.healthSystemId === hs.id);
					// eslint-disable-next-line no-param-reassign
					hs.isChecked = initialValue && initialValue.teams.length > 0;
					if (hs.isChecked) {
						checkedTeamIds.push({ healthSystemId: initialValue.healthSystemId, teamIds: initialValue.teams });
					}
				});
				return prevHs;
			});
			setChecked(checkedTeamIds);
		};
		const fetchData = async () => {
			const res = await getNurseHealthSystemAvailabilities();
			if (res.error) {
				setAlertErrorText(intl.formatMessage({ id: 'somethingWrong' }));
				return;
			}
			setNursePoolingStatus(answerExists.current);
			setInitialValues(res.nurseAvailabilities);
			if (res.nurseAvailabilities.length > 0) {
				setCheckedBasedOnInitialValues(res.nurseAvailabilities);
			}
		};
		const resetData = async () => {
			const deleteRes = await deleteMemberCallAvailability();
			if (deleteRes.error) {
				setAlertErrorText(intl.formatMessage({ id: 'somethingWrong' }));
			}
			setInitialValues([]);
		};
		if (!answerExists.current && !props.isEdit) {
			resetData();
		}
		if (answerExists.current && props.isEdit) {
			fetchData();
		}
	}, [props.isEdit, intl]);

	useEffect(() => {
		const filterNodes = (filtered, element) => {
			const children = (element.children || []).reduce(filterNodes, []);
			if (element.label.toLocaleLowerCase().indexOf(filteredText.toLocaleLowerCase()) > -1 || children.length) {
				filtered.push({ ...element, ...(children && children.length > 0 && { children }) });
			}
			return filtered;
		};
		if (!filteredText) {
			setFilteredNodes([...nodes]);
			return;
		}
		setFilteredNodes([...nodes].reduce(filterNodes, []));
	}, [filteredText, nodes]);

	useEffect(() => {
		if (selectedHealthSystem) {
			const getTree = async () => {
				setIsLoading(true);
				const response = await getHealthSystemSubTreeBasic(selectedHealthSystem.id);
				if (response.error) {
					setAlertErrorText(intl.formatMessage({ id: 'somethingWrong' }));
					setIsLoading(false);
					return;
				}
				setNodes(processTreeData(response.regions.reduce((acc, value) => [...acc, ...value.hospitals], [])));
				setFilteredNodes(processTreeData(response.regions.reduce((acc, value) => [...acc, ...value.hospitals], [])));
				setIsLoading(false);
			};
			getTree();
		}
	}, [intl, selectedHealthSystem]);

	const processTreeData = hospitals => {
		const getNode = (node, icon) => ({
			value: node.id,
			label: node.name,
			icon: <i className='material-icons'>{icon}</i>,
			...(node.children && node.children.length > 0 && { children: node.children || [] }),
		});

		const mapChildren = (items, icon) => items.map(item => getNode(item, icon));

		return hospitals.map(hospital => {
			const departments = hospital.departments.map(department => ({
				...department,
				children: mapChildren(department.floors, 'money'),
			}));

			return {
				...getNode(hospital, 'business'),
				...(departments && departments.length > 0 && { children: mapChildren(departments, 'account_balance') }),
			};
		});
	};

	const setNoReceiveCalls = async () => {
		const deleteRes = await deleteMemberCallAvailability();

		if (deleteRes.error) {
			setAlertErrorText(intl.formatMessage({ id: 'somethingWentWrong' }));
			return;
		}

		getStorage().setItem('nursePoolingStatus', nursePoolingStatus);
		props.changePage();
		setChecked([]);
	};

	const setCheckedBasedOnHs = (itemIds, hs) => {
		setChecked(prevState => {
			const prevChecked = [...prevState];
			const checkedItem = prevChecked.find(prev => prev.healthSystemId === hs.id);
			if (!checkedItem) {
				prevChecked.push({
					healthSystemId: hs.id,
					teamIds: itemIds,
				});
			} else {
				checkedItem.teamIds = itemIds;
			}
			return prevChecked;
		});
	};

	const setHealthSystemsList = item => {
		setHealthSystems(prevState => {
			const hsList = [...prevState];
			const hs = hsList.find(hsItem => hsItem.id === item.id);
			hs.isChecked = !hs.isChecked;
			if (!hs.isChecked) {
				setCheckedBasedOnHs([], hs);
			}
			return hsList;
		});
	};

	const flatMapArray = arr =>
		arr.flat().map(item => ({
			teamId: item,
			isAvailable: true,
		}));

	const submit = async () => {
		const flattedNewParams = flatMapArray([...checked].map(item => item.teamIds));
		const flattedInitialValues = flatMapArray([...initialValues].map(item => item.teams));
		flattedInitialValues.forEach(item => {
			if (!flattedNewParams.some(elem => elem.teamId === item.teamId)) {
				flattedNewParams.push({
					teamId: item.teamId,
					isAvailable: false,
				});
			}
		});

		if (flattedNewParams.length > 0) {
			const submitRes = await addOrUpdateMemberCallAvailability(flattedNewParams);
			if (submitRes.error) {
				setAlertErrorText(intl.formatMessage({ id: 'somethingWentWrong' }));
				return;
			}
		} else {
			const deleteRes = await deleteMemberCallAvailability();

			if (deleteRes.error) {
				setAlertErrorText(intl.formatMessage({ id: 'somethingWentWrong' }));
				return;
			}
		}

		getStorage().setItem('nursePoolingStatus', nursePoolingStatus);

		props.changePage();
		setPage(0);
		if (!props.isEdit) {
			setChecked([]);
		}
	};

	const showHealthSystems = () => {
		setPage(prev => prev + 1);
		const checkedHealthSystems = healthSystems.filter(item => item.isChecked);
		if (checkedHealthSystems.length > 0) {
			setSelectedHealthSystem(checkedHealthSystems[0]);
		}
	};

	const goBackToHsSelection = () => {
		setPage(prev => prev - 1);
		setSelectedHealthSystem(null);
		setNodes([]);
		setFilteredNodes([]);
	};

	const checkedHealthSystems = healthSystems.filter(item => item.isChecked);
	const foundHealthSystemIndex = checkedHealthSystems.findIndex(item => selectedHealthSystem?.id === item.id);

	return (
		<>
			<Wrapper isDarkMode={darkMode}>
				{page === 0 && (
					<div>
						<h3>
							{translate('hi')}, {userProfile.firstName} {userProfile.lastName}
						</h3>
						<p>{translate('selectReceivingCalls')}</p>
						<div className='select-wrapper'>
							<select value={nursePoolingStatus} onChange={event => setNursePoolingStatus(event.target.value)}>
								<option value=''>{intl.formatMessage({ id: 'selectOptionsBelow' })}</option>
								<option value={NursePoolingOptions.YES}>{intl.formatMessage({ id: 'willBeReceivingCalls' })}</option>
								<option value={NursePoolingOptions.NO}>{intl.formatMessage({ id: 'willNotBeReceivingCalls' })}</option>
							</select>
						</div>
						{nursePoolingStatus === NursePoolingOptions.YES.toString() && (
							<>
								<h3>{translate('selectHealthSystems')}</h3>
								<p>{translate('selectHealthSystemsReceiveCalls')}</p>

								{healthSystems.map(item => (
									<HealthSystemsListItem key={item.id} isDarkMode={darkMode}>
										{item.name}{' '}
										<input
											type='checkbox'
											name={item.name}
											onChange={() => setHealthSystemsList(item)}
											checked={item.isChecked}
										/>
									</HealthSystemsListItem>
								))}
							</>
						)}
						{nursePoolingStatus !== NursePoolingOptions.NO.toString() && (
							<Button
								type='button'
								isDisabled={checkedHealthSystems.length === 0}
								onClick={showHealthSystems}
								text={translate('continue')}
							/>
						)}

						{nursePoolingStatus === NursePoolingOptions.NO.toString() && (
							<Button type='button' onClick={setNoReceiveCalls} text={translate('submit')} />
						)}
					</div>
				)}

				{page === 1 && selectedHealthSystem && (
					<TreeViewListWrapper isDarkMode={darkMode}>
						<h3>
							{checkedHealthSystems.length > 1 &&
								`${intl.formatMessage({ id: 'healthSystems' })} [${foundHealthSystemIndex + 1} / ${checkedHealthSystems.length}]`}
							{checkedHealthSystems.length === 1 && intl.formatMessage({ id: 'healthSystem' })}
						</h3>

						<p>{translate('selectLevelReceiveCalls')}</p>
						{isLoading && (
							<div>
								<Loader />
							</div>
						)}
						{!isLoading && (
							<>
								<h4>{selectedHealthSystem.name}</h4>
								<input
									className='filter-text'
									placeholder={intl.formatMessage({ id: 'search' })}
									type='text'
									value={filteredText}
									onChange={event => setFilteredText(event.target.value)}
								/>
								<CheckboxTree
									nodes={nodesFiltered}
									checked={checked.find(item => item.healthSystemId === selectedHealthSystem.id)?.teamIds || []}
									expanded={expanded}
									onCheck={itemIds => setCheckedBasedOnHs(itemIds, selectedHealthSystem)}
									onExpand={data => setExpanded(data)}
								/>
								<div className='flex'>
									{foundHealthSystemIndex + 1 === 1 && (
										<Button type='button' variant='white' onClick={goBackToHsSelection} text={translate('back')} />
									)}
									{foundHealthSystemIndex + 1 !== 1 && (
										<Button
											type='button'
											variant='white'
											text={intl.formatMessage({ id: 'previous' })}
											onClick={() => setSelectedHealthSystem(checkedHealthSystems[foundHealthSystemIndex - 1])}
										/>
									)}
									{foundHealthSystemIndex + 1 !== checkedHealthSystems.length && (
										<Button
											type='button'
											text={intl.formatMessage({ id: 'next' })}
											onClick={() => setSelectedHealthSystem(checkedHealthSystems[foundHealthSystemIndex + 1])}
										/>
									)}
									{foundHealthSystemIndex + 1 === checkedHealthSystems.length && (
										<Button type='button' text={intl.formatMessage({ id: 'submit' })} onClick={submit} />
									)}
								</div>
							</>
						)}
					</TreeViewListWrapper>
				)}
			</Wrapper>
			<Alert
				display={alertErrorText}
				fixed={true}
				message={alertErrorText}
				variant='error'
				onClose={() => setAlertErrorText(null)}
			/>
		</>
	);
};

export default PoolingFlow;
