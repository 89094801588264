import React from 'react';

const PatientPosition = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none' viewBox='0 0 18 18' {...props}>
		<path
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth='1.5'
			d='M4.14.9H2.9a2 2 0 00-2 2v1.24M13.86 17.1h1.24a2 2 0 002-2v-1.24m0-9.72V2.9a2 2 0 00-2-2h-1.24M.9 13.86v1.24a2 2 0 002 2h1.24'
		/>
		<path
			stroke='currentColor'
			strokeMiterlimit='10'
			strokeWidth='1.5'
			d='M9 5.626A1.012 1.012 0 109 3.6a1.012 1.012 0 000 2.025zM10.627 7.973h1.748c.18 0 .35-.07.477-.195a.664.664 0 000-.945.679.679 0 00-.477-.195h-6.75c-.179 0-.35.07-.477.195a.664.664 0 000 .945.679.679 0 00.477.195h1.748l-.06 1.082-.19 2.083-.256 2.932a.589.589 0 00.352.617.606.606 0 00.85-.497l.398-3.045a.47.47 0 01.459-.394h.148a.47.47 0 01.46.394l.398 3.045a.599.599 0 00.193.389.612.612 0 00.86-.04.593.593 0 00.148-.47c-.08-.867-.169-2.07-.256-2.93l-.19-2.084-.06-1.082z'
		/>
	</svg>
);

export default PatientPosition;
