import React from 'react';

const Rails = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none' viewBox='0 0 24 24' {...props}>
		<path stroke='currentColor' strokeLinejoin='round' strokeWidth='1.25' d='M7 11H5v6h2v-2h10v2h2v-6h-2v2H7v-2z' />
		<path stroke='currentColor' strokeLinejoin='round' strokeWidth='1.25' d='M2 9H22V11H2z' />
		<path
			stroke='currentColor'
			strokeMiterlimit='10'
			strokeWidth='1.25'
			d='M6 20a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM18 20a1.5 1.5 0 100-3 1.5 1.5 0 000 3z'
		/>
		<path
			stroke='currentColor'
			strokeWidth='1.25'
			d='M2 9v0a1 1 0 01-1-1V6a1 1 0 011-1h1.586a1 1 0 01.707.293l1.414 1.414A1 1 0 006.414 7H22a1 1 0 011 1v0a1 1 0 01-1 1v0'
		/>
	</svg>
);

export default Rails;
