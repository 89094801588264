import React, { useContext, useEffect, useRef, useState } from 'react';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Input from 'components/Common/FormElements/Input.jsx';
import { createInvite, checkIfCompanyMemberExists, bulkUserInvite } from 'api/users.js';
import Alert from 'components/Alert.jsx';
import translate from 'i18n-translations/translate.jsx';
import { ExternalIdentityProviders, InviteTypes, TaskStatus } from 'constants/enums.js';
import MultiSelect from 'components/Common/FormElements/MultiSelect.jsx';
import Modal from 'components/Modal.jsx';
import BulkUsersExelFile from 'components/BulkUsersExelFile.jsx';
import Select from 'components/Common/FormElements/Select.jsx';
import { companyAdUserExists, createAdUserInvite } from 'api/activeDirectory.js';
import SocketEvents from 'constants/socket-events.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';

const InviteNurses = props => {
	const Views = {
		DEFAULT: 0,
		BULK: 1,
	};
	const [extraEmailsCount, setExtraEmailsCount] = useState(0);
	const [emailError, setEmailError] = useState(null);
	const [currentView, setCurrentView] = useState(Views.DEFAULT);
	const [healthSystemsArr] = useState(props.healthSystems.map(hs => ({ value: hs.id, label: hs.value })));
	const [hasEmailError, setHasEmailError] = useState(null);
	const [emailList, setEmailList] = useState([]);
	const intl = useIntl();
	const [error, setError] = useState(null);
	const adUsersTab = 2;
	const userSession = useSelector(state => state.user.userSession);
	const uploadedFileRef = useRef(null);
	const socket = useContext(SocketContext);

	useEffect(() => {
		const handleTaskStatusUpdated = data => {
			if (data.taskStatusId === TaskStatus.COMPLETED) {
				setError(null);
			}
			if (data.taskStatusId === TaskStatus.FAULTED) {
				setError(translate('somethingWentWrong'));
			}
		};
		socket.on(SocketEvents.BACKGROUND.TASK_UPDATED, handleTaskStatusUpdated);
		return () => {
			socket.off(SocketEvents.BACKGROUND.TASK_UPDATED, handleTaskStatusUpdated);
		};
	}, [socket]);

	const checkEmail = async val => {
		setEmailError(null);
		if (!val) {
			return true;
		}
		const schema = Yup.string().email().required();

		let response = null;
		let companyResponse = null;
		if (await schema.isValid(val)) {
			response = await checkIfCompanyMemberExists(val);
			if (response.error) {
				setEmailError(`${intl.formatMessage({ id: 'emailExists' })}: ${response?.error?.message}`);
				return true;
			}
			if (props.currentTab !== adUsersTab) {
				return !response.exists;
			}
			if (response.exists) {
				return false;
			}
			if (props.currentTab === adUsersTab) {
				const params = {
					usersHealthSystemId: userSession.healthSystem.id,
				};
				companyResponse = await companyAdUserExists(val, params);
				if (companyResponse.error) {
					setEmailError(companyResponse.error.message);
					return true;
				}
				return companyResponse.exists;
			}
		}
		return true;
	};

	const onSubmitHandler = async (values, { resetForm }) => {
		if (!emailError && currentView === Views.DEFAULT) {
			props.onLoading();
			const newValues = Object.keys(values).reduce((object, key) => {
				const newObject = { ...object };
				if (!['healthSystems', 'firstName', 'lastName'].includes(key)) {
					newObject[key] = values[key];
				}
				return newObject;
			}, {});
			const emails = Object.values(newValues).filter(item => item !== '');
			const transformedHealthSystems = values.healthSystems.map(({ value, label }) => ({
				id: value,
				name: label,
			}));

			const params = {
				users: [ExternalIdentityProviders.PING_FEDERATE, ExternalIdentityProviders.OKTA].includes(props.adConfigType)
					? emails.map(email => ({
							email,
							inviteTypeId: props.inviteTypeId,
							firstName: values.firstName,
							lastName: values.lastName,
					  }))
					: emails.map(email => ({
							email,
							inviteTypeId: props.inviteTypeId,
					  })),
				inviteInfo: {
					channels: transformedHealthSystems.map(healthSystem => ({ id: healthSystem.id, name: healthSystem.name })),
				},
			};
			let response;
			if (props.currentTab === adUsersTab) {
				params.usersHealthSystemId = userSession.healthSystem.id;
				response = await createAdUserInvite(params);
				if (response.error) {
					setError(response.error);
				}
			} else {
				response = await createInvite(params);
			}

			if (response.error) {
				props.onError();
			} else {
				onModalClose();
			}
		}
		if (currentView === Views.BULK) {
			if (!uploadedFileRef.current.value) {
				return;
			}
			if (emailList.some(item => item.errorType) || hasEmailError) {
				uploadedFileRef.current.value = null;
				return;
			}
			if (emailList.length > 100) {
				uploadedFileRef.current.value = null;
				setHasEmailError(intl.formatMessage({ id: 'maxEmail' }));
				return;
			}
			const response = await bulkUserInvite({
				healthSystemId: values.healthSystemId,
				inviteTypeId: props.inviteTypeId,
				emails: emailList.map(item => item.email),
			});
			if (response.error) {
				props.onError();
			} else {
				props.onSuccess();
				resetForm({
					healthSystemId: [],
					firstName: '',
					lastName: '',
				});
				uploadedFileRef.current.value = null;
			}
		}
	};

	const onModalClose = resetForm => {
		props.onModalClose();
		if (resetForm) {
			resetForm();
		}
	};

	return (
		<div className='user-management-form'>
			<Formik
				initialValues={{
					healthSystems: [],
					firstName: '',
					lastName: '',
					email: '',
					email0: '',
					email1: '',
					email2: '',
					email3: '',
					healthSystemId: '',
				}}
				onSubmit={onSubmitHandler}
				validationSchema={
					currentView === Views.DEFAULT
						? Yup.object().shape({
								email: Yup.string()
									.required(intl.formatMessage({ id: 'emailRequired' }))
									.email()
									.test(
										'existing-email',
										intl.formatMessage({ id: props.currentTab === adUsersTab ? 'adUserDoesNotExist' : 'userAlreadyExists' }),
										val => checkEmail(val)
									),
								email0: Yup.string()
									.email()
									.test(
										'existing-email',
										intl.formatMessage({ id: props.currentTab === adUsersTab ? 'adUserDoesNotExist' : 'userAlreadyExists' }),
										val1 => checkEmail(val1)
									),
								email1: Yup.string()
									.email()
									.test(
										'existing-email',
										intl.formatMessage({ id: props.currentTab === adUsersTab ? 'adUserDoesNotExist' : 'userAlreadyExists' }),
										val => checkEmail(val)
									),
								email2: Yup.string()
									.email()
									.test(
										'existing-email',
										intl.formatMessage({ id: props.currentTab === adUsersTab ? 'adUserDoesNotExist' : 'userAlreadyExists' }),
										val => checkEmail(val)
									),
								email3: Yup.string()
									.email()
									.test(
										'existing-email',
										intl.formatMessage({ id: props.currentTab === adUsersTab ? 'adUserDoesNotExist' : 'userAlreadyExists' }),
										val => checkEmail(val)
									),
								healthSystems: Yup.array()
									.required(
										intl.formatMessage({
											id: 'healthSystemRequired',
										})
									)
									.nullable(),
						  })
						: Yup.object().shape({
								healthSystemId: Yup.string()
									.required(
										intl.formatMessage({
											id: 'healthSystemRequired',
										})
									)
									.nullable(),
						  })
				}>
				{formikProps => {
					// bind the submission handler remotely
					const { values, touched, errors, setFieldValue, setFieldTouched, resetForm } = formikProps;
					props.bindSubmitForm(formikProps.submitForm);
					let role, title, description;
					if (props.inviteTypeId === InviteTypes.VIRTUAL_CARE_PROVIDER.type) {
						role = translate(InviteTypes.VIRTUAL_CARE_PROVIDER.message);
						title = translate('addVirtualCareProvider');
						description = translate('writeEmailOfVcpToInvite');
					}
					if (props.inviteTypeId === InviteTypes.VIRTUAL_SITTER.type) {
						role = translate(InviteTypes.VIRTUAL_SITTER.message);
						title = translate('addVirtualSitter');
						description = translate('writeEmailOfVSToInvite');
					}
					if (props.inviteTypeId === InviteTypes.DIGITAL_CLINICIAN.type) {
						role = translate(InviteTypes.DIGITAL_CLINICIAN.message);
						title = translate('addDigitalClinician');
						description = translate('writeEmailOfDCToInvite');
					}
					if (props.inviteTypeId === InviteTypes.DOCTOR.type) {
						role = translate(InviteTypes.DOCTOR.message);
						title = translate('addDoctor');
						description = translate('writeEmailOfDoctorsToInvite');
					}
					return (
						<Modal
							display={props.display}
							position='right'
							onModalClose={() => onModalClose(resetForm)}
							onModalSubmit={props.onModalSubmit}
							isLoading={false}>
							<Form>
								<h3 className='top-30'>{title}</h3>
								{[InviteTypes.VIRTUAL_CARE_PROVIDER.type, InviteTypes.VIRTUAL_SITTER.type].includes(props.inviteTypeId) &&
									props.currentTab !== adUsersTab && (
										<button
											type='button'
											className='change-assign-view'
											onClick={() => setCurrentView(prevState => (prevState === Views.DEFAULT ? Views.BULK : Views.DEFAULT))}>
											{translate(currentView === Views.DEFAULT ? 'bulkView' : 'defaultView')}
											<i className='material-icons'>compare_arrows</i>
										</button>
									)}
								{currentView === Views.DEFAULT && (
									<>
										<MultiSelect
											name='healthSystems'
											label={translate('enterHealthSystem')}
											placeholder={intl.formatMessage({ id: 'selectHealthSystem' })}
											description={translate('selectAtLeastOneHSToAddRole', { value: role })}
											options={healthSystemsArr}
											value={values.healthSystems}
											isMulti={true}
											onChange={setFieldValue}
											onBlur={setFieldTouched}
											touched={touched.healthSystems}
											error={errors.healthSystems}
											isClearable={true}
											backspaceRemovesValue={true}
										/>
										{props.currentTab === adUsersTab &&
											[ExternalIdentityProviders.PING_FEDERATE, ExternalIdentityProviders.OKTA].includes(props.adConfigType) && (
												<>
													<Field
														name='firstName'
														type='firstName'
														label={translate('firstName')}
														placeholder={intl.formatMessage({ id: 'firstName' })}
														component={Input}
													/>
													<Field
														name='lastName'
														type='lastName'
														label={translate('lastName')}
														placeholder={intl.formatMessage({ id: 'lastName' })}
														component={Input}
													/>
												</>
											)}
										<Field
											name='email'
											type='email'
											label={translate('enterEmail')}
											placeholder={intl.formatMessage({ id: 'email' })}
											description={description}
											component={Input}
										/>
										{[...Array(extraEmailsCount).keys()].map(key => (
											<Field
												key={key}
												name={`email${key}`}
												type='email'
												placeholder={intl.formatMessage({ id: 'email' })}
												component={Input}
											/>
										))}
										{[...Array(extraEmailsCount).keys()].length < 4 && props.currentTab !== adUsersTab && (
											<div className='cursor-pointer'>
												<span
													className='action'
													onClick={() => setExtraEmailsCount(prevState => prevState + 1)}
													data-tooltip={intl.formatMessage({ id: 'addNewField' })}
													data-position='right'>
													<i className='material-icons-outlined'>add_box</i>
												</span>
											</div>
										)}
									</>
								)}
								{currentView === Views.BULK && (
									<>
										<Field
											name='healthSystemId'
											type='select'
											label={intl.formatMessage({ id: 'selectHealthSystem' })}
											placeholder={intl.formatMessage({ id: 'selectHealthSystem' })}
											description={intl.formatMessage({ id: 'selectHealthSystemOrAll' })}
											items={props.healthSystems}
											component={Select}
										/>
										<BulkUsersExelFile
											hasEmailError={hasEmailError}
											setHasEmailError={setHasEmailError}
											emailList={emailList}
											setEmailList={setEmailList}
											uploadedFileRef={uploadedFileRef}
										/>
									</>
								)}
								<Alert
									display={props.success}
									message={translate('usersAddedOnHelloHealth')}
									variant='success'
									onClose={props.onCloseAlert}
								/>
								<Alert
									display={props.error}
									message={translate('somethingWentWrongTryAgain')}
									variant='dark'
									onClose={props.onCloseAlert}
									fixed
									hideCloseButton
								/>
								<Alert display={props.emailError} message={props.emailError} variant='error' onClose={props.onCloseAlert} />
								<Alert display={error} message={props.emailError} variant='error' onClose={() => setError(null)} />
							</Form>
						</Modal>
					);
				}}
			</Formik>
		</div>
	);
};

export default InviteNurses;
