import { DeviceListLevel, SectorTypes, TreeHierarchyType } from "constants/enums.js";
import { findSectorById } from "infrastructure/helpers/commonHelpers.js";

export const mapSectionsToRender = (hospitals, sector, toggleEditSectorModal, initDeleteOrg, treeHierarchyTypeId) => {
	const result = {
		hospitals: [],
		departments: [],
		floors: [],
		rooms: [],
	};
	if (!hospitals || hospitals.length === 0 || !sector) {
		return result;
	}
	const selectedSector = findSectorById(hospitals, sector[`${sector.sectorType}Id`]);
	if (!selectedSector) {
		return result;
	}
	if ([TreeHierarchyType.DEFAULT_TREE, TreeHierarchyType.HOSPITAL_DEPT_FLOOR_ROOM].includes(treeHierarchyTypeId)) {
		if (sector.sectorType === SectorTypes.HOSPITAL) {
			selectedSector.subOptions.forEach(department => {
				result.departments.push({
					name: department.name,
					id: department.departmentId,
					edit: (
						<div className='wrapped'>
							<i
								className='material-icons-outlined boxed-icon'
								onClick={() =>
									toggleEditSectorModal({
										sectorName: department.name,
										sectorType: department.type,
										hospitalId: department.hospitalId,
										departmentId: department.departmentId,
									})
								}>
								edit
							</i>{' '}
							&nbsp;
							<i
								className='material-icons-outlined boxed-icon'
								onClick={() =>
									initDeleteOrg({ level: DeviceListLevel.DEPARTMENT, id: department.departmentId, name: department.name })
								}>
								delete
							</i>
						</div>
					),
				});
				department.subOptions.forEach(floor => {
					result.floors.push({
						name: floor.name,
						id: floor.floorId,
						edit: (
							<div className='wrapped'>
								<i
									className='material-icons-outlined boxed-icon'
									onClick={() =>
										toggleEditSectorModal({
											sectorName: floor.name,
											sectorType: floor.type,
											hospitalId: floor.hospitalId,
											departmentId: department.departmentId,
											floorId: floor.floorId,
										})
									}>
									edit
								</i>{' '}
								&nbsp;
								<i
									className='material-icons-outlined boxed-icon'
									onClick={() => initDeleteOrg({ level: DeviceListLevel.FLOOR, id: floor.floorId, name: floor.name })}>
									delete
								</i>
							</div>
						),
					});
					floor.subOptions.forEach(room => {
						result.rooms.push({
							name: room.name,
							id: room.roomId,
							edit: (
								<div className='wrapped'>
									<i
										className='material-icons-outlined boxed-icon'
										onClick={() =>
											toggleEditSectorModal({
												sectorName: room.name,
												sectorType: room.type,
												hospitalId: floor.hospitalId,
												departmentId: department.departmentId,
												floorId: floor.floorId,
												roomId: room.roomId,
											})
										}>
										edit
									</i>{' '}
									&nbsp;
									<i
										className='material-icons-outlined boxed-icon'
										onClick={() => initDeleteOrg({ level: DeviceListLevel.ROOM, id: room.roomId, name: room.name })}>
										delete
									</i>
								</div>
							),
						});
					});
				});
			});
		}

		if (sector.sectorType === SectorTypes.DEPARTMENT) {
			selectedSector.subOptions.forEach(floor => {
				result.floors.push({
					name: floor.name,
					id: floor.floorId,
					edit: (
						<div className='wrapped'>
							<i
								className='material-icons-outlined boxed-icon'
								onClick={() =>
									toggleEditSectorModal({
										sectorName: floor.name,
										sectorType: floor.type,
										hospitalId: floor.hospitalId,
										departmentId: floor.departmentId,
										floorId: floor.floorId,
									})
								}>
								edit
							</i>{' '}
							&nbsp;
							<i
								className='material-icons-outlined boxed-icon'
								onClick={() => initDeleteOrg({ level: DeviceListLevel.FLOOR, id: floor.floorId, name: floor.name })}>
								delete
							</i>
						</div>
					),
				});
				floor.subOptions.forEach(room => {
					result.rooms.push({
						name: room.name,
						id: room.roomId,
						edit: (
							<div className='wrapped'>
								<i
									className='material-icons-outlined boxed-icon'
									onClick={() =>
										toggleEditSectorModal({
											sectorName: room.name,
											sectorType: room.type,
											hospitalId: floor.hospitalId,
											departmentId: room.departmentId,
											floorId: floor.floorId,
											roomId: room.roomId,
										})
									}>
									edit
								</i>{' '}
								&nbsp;
								<i
									className='material-icons-outlined boxed-icon'
									onClick={() => initDeleteOrg({ level: DeviceListLevel.ROOM, id: room.roomId, name: room.name })}>
									delete
								</i>
							</div>
						),
					});
				});
			});
		}

		if (sector.sectorType === SectorTypes.FLOOR) {
			selectedSector.subOptions.forEach(room => {
				result.rooms.push({
					name: room.name,
					id: room.roomId,
					edit: (
						<div className='wrapped'>
							<i
								className='material-icons-outlined boxed-icon'
								onClick={() =>
									toggleEditSectorModal({
										sectorName: room.name,
										sectorType: room.type,
										hospitalId: room.hospitalId,
										departmentId: room.departmentId,
										floorId: room.floorId,
										roomId: room.roomId,
									})
								}>
								edit
							</i>{' '}
							&nbsp;
							<i
								className='material-icons-outlined boxed-icon'
								onClick={() => initDeleteOrg({ level: DeviceListLevel.ROOM, id: room.roomId, name: room.name })}>
								delete
							</i>
						</div>
					),
				});
			});
		}
	}

	if (treeHierarchyTypeId === TreeHierarchyType.HOSPITAL_DEPT_ROOM) {
		if (sector.sectorType === SectorTypes.HOSPITAL) {
			selectedSector.subOptions.forEach(department => {
				result.departments.push({
					name: department.name,
					id: department.departmentId,
					edit: (
						<div className='wrapped'>
							<i
								className='material-icons-outlined boxed-icon'
								onClick={() =>
									toggleEditSectorModal({
										sectorName: department.name,
										sectorType: department.type,
										hospitalId: department.hospitalId,
										departmentId: department.departmentId,
									})
								}>
								edit
							</i>{' '}
							&nbsp;
							<i
								className='material-icons-outlined boxed-icon'
								onClick={() =>
									initDeleteOrg({ level: DeviceListLevel.DEPARTMENT, id: department.departmentId, name: department.name })
								}>
								delete
							</i>
						</div>
					),
				});
				department.subOptions.forEach(room => {
					result.rooms.push({
						name: room.name,
						id: room.roomId,
						edit: (
							<div className='wrapped'>
								<i
									className='material-icons-outlined boxed-icon'
									onClick={() =>
										toggleEditSectorModal({
											sectorName: room.name,
											sectorType: room.type,
											hospitalId: room.hospitalId,
											departmentId: room.departmentId,
											floorId: room.floorId,
											roomId: room.roomId,
										})
									}>
									edit
								</i>{' '}
								&nbsp;
								<i
									className='material-icons-outlined boxed-icon'
									onClick={() => initDeleteOrg({ level: DeviceListLevel.ROOM, id: room.roomId, name: room.name })}>
									delete
								</i>
							</div>
						),
					});
				});
			});
		}
		if (sector.sectorType === SectorTypes.DEPARTMENT) {
			selectedSector.subOptions.forEach(room => {
				result.rooms.push({
					name: room.name,
					id: room.roomId,
					edit: (
						<div className='wrapped'>
							<i
								className='material-icons-outlined boxed-icon'
								onClick={() =>
									toggleEditSectorModal({
										sectorName: room.name,
										sectorType: room.type,
										hospitalId: room.hospitalId,
										departmentId: room.departmentId,
										floorId: room.floorId,
										roomId: room.roomId,
									})
								}>
								edit
							</i>{' '}
							&nbsp;
							<i
								className='material-icons-outlined boxed-icon'
								onClick={() => initDeleteOrg({ level: DeviceListLevel.ROOM, id: room.roomId, name: room.name })}>
								delete
							</i>
						</div>
					),
				});
			});
		}
	}

	if (treeHierarchyTypeId === TreeHierarchyType.HOSPITAL_FLOOR_ROOM) {
		if (sector.sectorType === SectorTypes.HOSPITAL) {
			selectedSector.subOptions.forEach(floor => {
				result.floors.push({
					name: floor.name,
					id: floor.floorId,
					edit: (
						<div className='wrapped'>
							<i
								className='material-icons-outlined boxed-icon'
								onClick={() =>
									toggleEditSectorModal({
										sectorName: floor.name,
										sectorType: floor.type,
										hospitalId: floor.hospitalId,
										departmentId: floor.departmentId,
										floorId: floor.floorId,
									})
								}>
								edit
							</i>{' '}
							&nbsp;
							<i
								className='material-icons-outlined boxed-icon'
								onClick={() => initDeleteOrg({ level: DeviceListLevel.FLOOR, id: floor.floorId, name: floor.name })}>
								delete
							</i>
						</div>
					),
				});
				floor.subOptions.forEach(room => {
					result.rooms.push({
						name: room.name,
						id: room.roomId,
						edit: (
							<div className='wrapped'>
								<i
									className='material-icons-outlined boxed-icon'
									onClick={() =>
										toggleEditSectorModal({
											sectorName: room.name,
											sectorType: room.type,
											hospitalId: room.hospitalId,
											departmentId: room.departmentId,
											floorId: room.floorId,
											roomId: room.roomId,
										})
									}>
									edit
								</i>{' '}
								&nbsp;
								<i
									className='material-icons-outlined boxed-icon'
									onClick={() => initDeleteOrg({ level: DeviceListLevel.ROOM, id: room.roomId, name: room.name })}>
									delete
								</i>
							</div>
						),
					});
				});
			});
		}
		if (sector.sectorType === SectorTypes.FLOOR) {
			selectedSector.subOptions.forEach(room => {
				result.rooms.push({
					name: room.name,
					id: room.roomId,
					edit: (
						<div className='wrapped'>
							<i
								className='material-icons-outlined boxed-icon'
								onClick={() =>
									toggleEditSectorModal({
										sectorName: room.name,
										sectorType: room.type,
										hospitalId: room.hospitalId,
										departmentId: room.departmentId,
										floorId: room.floorId,
										roomId: room.roomId,
									})
								}>
								edit
							</i>{' '}
							&nbsp;
							<i
								className='material-icons-outlined boxed-icon'
								onClick={() => initDeleteOrg({ level: DeviceListLevel.ROOM, id: room.roomId, name: room.name })}>
								delete
							</i>
						</div>
					),
				});
			});
		}
	}

	if (treeHierarchyTypeId === TreeHierarchyType.HOSPITAL_ROOM) {
		selectedSector.subOptions.forEach(room => {
			result.rooms.push({
				name: room.name,
				id: room.roomId,
				edit: (
					<div className='wrapped'>
						<i
							className='material-icons-outlined boxed-icon'
							onClick={() =>
								toggleEditSectorModal({
									sectorName: room.name,
									sectorType: room.type,
									hospitalId: room.hospitalId,
									departmentId: room.departmentId,
									floorId: room.floorId,
									roomId: room.roomId,
								})
							}>
							edit
						</i>{' '}
						&nbsp;
						<i
							className='material-icons-outlined boxed-icon'
							onClick={() => initDeleteOrg({ level: DeviceListLevel.ROOM, id: room.roomId, name: room.name })}>
							delete
						</i>
					</div>
				),
			});
		});
	}
	return result;
};