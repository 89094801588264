import React, { useState, useRef, useEffect } from 'react';
import ReactSelect from 'react-select';
import { generateCustomStyles } from 'constants/react-select-style.js';
import DarkTheme from 'calls/styles/DarkTheme.js';
import {
	Option,
	MultiValueContainer,
	MultiValue,
	MenuList,
	Control,
} from 'components/Common/ReactSelectCustomComponents/index.js';
import { useSelector } from 'react-redux';

const CustomDropdown = ({ onSelect, placeholder, initialOptions, defaultOptions, labelName, showTitleInPlaceholder = false, title = '' }) => {
	const user = useSelector(state => state.user);
	const dropdownOptions = useRef(initialOptions);
	const [selectedOptions, setSelectedOptions] = useState(defaultOptions || []);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const customContentLabel =
		selectedOptions && Array.isArray(selectedOptions) && selectedOptions.map(option => option.label).join(', ');

	const handleSelectOptions = (items, { action }) => {
		setSelectedOptions(items);
		if (action === 'deselect-option' && selectedOptions.length === 1) {
			setIsDropdownOpen(false);
		}
		if (onSelect) {
			onSelect(items);
		}
	};

	useEffect(() => {
		setSelectedOptions(defaultOptions);
	}, [defaultOptions]);

	let customContent = selectedOptions.length > 0 ? `${customContentLabel}` : '';

	if (showTitleInPlaceholder) {
		customContent = title;
	}

	return (
		<>
			<label>{labelName}</label>
			<ReactSelect
				menuIsOpen={isDropdownOpen}
				onMenuOpen={() => setIsDropdownOpen(true)}
				onMenuClose={() => setIsDropdownOpen(false)}
				options={dropdownOptions.current}
				value={selectedOptions}
				placeholder={placeholder}
				onChange={handleSelectOptions}
				isClearable={false}
				isSearchable={false}
				closeMenuOnSelect={false}
				hideSelectedOptions={false}
				classNamePrefix='react-select'
				className={'costum-dropdown'}
				customContent={customContent}
				styles={{
					...generateCustomStyles({ darkMode: user.darkMode }),
					menu: base => ({ ...base, width: '100%', color: user.darkMode && DarkTheme.colors.grayFive }),
					menuList: base => ({ ...base, height: 'fit-content', width: '100%' }),
					multiValue: base => ({
						...base,
						display: 'none',
					}),
					control: base => ({
						...base,
						paddingLeft: '10px',
						backgroundColor: user.darkMode && 'var(--dark-theme-gray-2)',
						color: user.darkMode && DarkTheme.colors.grayFive,
					}),
				}}
				isMulti
				components={{
					Option,
					MultiValueContainer,
					MultiValue,
					Control,
					MenuList,
				}}
			/>
		</>
	);
};

export default CustomDropdown;
