import React from 'react';
import Modal from 'components/Modal.jsx';
import Table from 'components/Table.jsx';
import CallDetails from 'components/CallLogs/CallDetails.jsx';
import { ObjectType } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import { getUserInfo } from 'infrastructure/auth.js';
import { downloadConferencesCsv } from 'api/dashboard.js';
import { getSessionTypeString } from 'infrastructure/helpers/callLogsHelper.js';
import { convertToTimestampSecondsWithMaxTime } from 'infrastructure/helpers/dateHelper.js';

const PastCallsViewActions = props => {
	const userProfile = getUserInfo();
	const showListOfUnavailableNurses = () => {
		const headers = [
			{ title: translate('name'), id: 0 },
			{ title: translate('status'), id: 1 },
		];
		const unavailableNurses = props.modal.currentCallLogQueue.map(({ name, state, id }) => ({
			name,
			status: props.getParticipantState(state),
			id,
		}));
		return <Table isLoading={props.isLoading} headers={headers} rows={unavailableNurses} />;
	};

	const showListOfRooms = () => {
		const headers = [
			{ title: translate('room'), id: 0 },
			{ title: translate('status'), id: 1 },
			{ title: translate('sessionType'), id: 2 },
		];
		if (!props.selectedConference || !props.selectedConference.participants) {
			return console.error('Error while getting list of participants!');
		}

		const roomsList = props.selectedConference.participants.reduce((acc, participant) => {
			if (participant.objectType === ObjectType.HELLO_DEVICE) {
				acc.push({
					id: participant.conferenceId,
					room: participant.name,
					status: translate(props.getParticipantState(participant.state).toLowerCase()),
					sessionType: getSessionTypeString(participant.sessionType),
				});
			}
			return acc;
		}, []);

		return (
			<Table
				isLoading={props.isLoading}
				headers={headers}
				rows={roomsList}
				tableEmptyText={translate('noRooms')}
				className='call-details-table'
			/>
		);
	};

	const showListOfParticipants = () => {
		const headers = [
			{ title: translate('participant'), id: 0 },
			{ title: translate('status'), id: 1 },
		];
		if (!props.selectedConference || !props.selectedConference.participants) {
			return console.error('Error while getting list of participants!');
		}

		const participantsList = props.selectedConference.participants.reduce((acc, participant) => {
			if (!participant.isInitiator) {
				acc.push({
					room: participant.name,
					status: translate(props.getParticipantState(participant.state).toLowerCase()),
				});
			}
			return acc;
		}, []);

		return (
			<Table
				isLoading={props.isLoading}
				headers={headers}
				rows={participantsList}
				tableEmptyText={translate('noParticipants')}
				className='call-details-table'
			/>
		);
	};

	const downloadCsv = async () => {
		props.setIsExportLogsModalLoading(true);
		const response = await downloadConferencesCsv({
			start: props.dates.toDate && props.dates.fromDate && Math.floor(new Date(props.dates.fromDate).getTime() / 1000),
			end: props.dates.toDate && props.dates.fromDate && Math.floor(convertToTimestampSecondsWithMaxTime(props.dates.toDate)),
			...(props.selectedSector.healthSystem && { healthSystemId: props.selectedSector.healthSystem.value }),
			...(props.selectedSector.region && { regionId: props.selectedSector.region.value }),
			...(props.selectedSector.hospital && { hospitalId: props.selectedSector.hospital.value }),
			...(props.selectedSector.department && { departmentId: props.selectedSector.department.value }),
			...(props.selectedSector.floor && { floorId: props.selectedSector.floor.value }),
			...(props.selectedSector.room && { roomId: props.selectedSector.room.value }),
		});

		if (!response.error) {
			props.setHasExportLogsModalErrors(!!response.error);
			props.setIsExportLogsModalOpen(!!response.error);
		}
		props.setIsExportLogsModalLoading(false);
	};

	return (
		<>
			<Modal
				display={props.modal.showCallQueueModal}
				position='center'
				hideActionButtons={true}
				onModalSubmit={null}
				onModalClose={props.closeCallQueueModal}>
				<form>
					<h3>{translate('queueOfNursesThatWereCalled')}</h3>
					{showListOfUnavailableNurses()}
				</form>
			</Modal>
			<Modal
				modalSelector='callDetailsModal'
				display={props.modal.showCallDetailsModal}
				position='center'
				hideActionButtons={true}
				onModalSubmit={null}
				onModalClose={props.closeCallDetailsModal}>
				<CallDetails selectedConference={props.selectedConference} />
			</Modal>
			<Modal
				modalSelector='listOfRoomsModal'
				display={props.modal.showListOfRoomsModal}
				position='center'
				hideActionButtons={true}
				onModalSubmit={null}
				onModalClose={props.closeListOfRooms}>
				<form>
					<>
						<h3>{translate('listOfRooms')}</h3>
						{props.modal.showListOfRoomsModal && showListOfRooms()}
					</>
				</form>
			</Modal>
			<Modal
				modalSelector='listOfParticipantsModal'
				display={props.modal.showListOfParticipantsModal}
				position='center'
				hideActionButtons={true}
				onModalSubmit={null}
				onModalClose={props.closeListOfParticipants}>
				<form>
					<>
						<h3>{translate('listOfParticipants')}</h3>
						{props.modal.showListOfParticipantsModal && showListOfParticipants()}
					</>
				</form>
			</Modal>
			<Modal
				display={props.modal.isExportLogsModalOpen}
				position='center'
				isLoading={props.isExportLogsModalLoading}
				onModalSubmit={downloadCsv}
				primaryButtonLabel={translate('continue')}
				onModalClose={() => props.closeExportLogsModal(false)}
				className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal'
				submitImgIcon={`${healthCareCdnUrl}appointments/save-icon.svg`}
				closeImgIcon={`${healthCareCdnUrl}appointments/cancel-appointment.svg`}>
				<form>
					<h3>{translate('exportCSV')}</h3>
					{!props.hasExportLogsModalErrors && userProfile && (
						<p>{translate('exportCSVInBackground', { value: <strong>{userProfile.email}</strong> })}</p>
					)}
					{props.hasExportLogsModalErrors && <p>{translate('somethingWentWrong')}</p>}
				</form>
			</Modal>
		</>
	);
};

export default PastCallsViewActions;
