import React from 'react';
import { FieldArray } from 'formik';
import { useIntl } from 'react-intl';
import Select from 'react-select';
import translate from 'i18n-translations/translate.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { Country } from 'constants/enums.js';
import { generateCustomStyles } from 'constants/react-select-style.js';

const EmploymentHistory = props => {
	const intl = useIntl();

	const getCountryValue = index =>
		props
			.transformArray(props.countries)
			.filter(option => option.value === props.formikProps.values.employmentHistory[index].country4);

	const getStateValue = index =>
		props
			.transformArray(props.states)
			.filter(option => option.value === props.formikProps.values.employmentHistory[index].employmentStateId);

	return (
		<div className='professional-details-wrapper'>
			<label>{translate('employmentHistory')}</label>
			<div className='flex personal-details-wrapper professional-wrapper'>
				<FieldArray name='employmentHistory'>
					{({ push, remove }) => (
						<>
							{props.formikProps.values.employmentHistory.map((employment, index) => {
								const { employmentHistory } = props.formikProps.values;
								const empErrors = props.formikProps.errors.employmentHistory;
								return (
									<div className='flex flex-wrap' key={`${employment?.country4}-country`}>
										<div className='flex column-direction personal-details'>
											<label>{translate('company')}</label>
											<input
												type='text'
												placeholder={intl.formatMessage({ id: 'company' })}
												value={employmentHistory[index].company}
												onChange={props.onChange}
												name={`employmentHistory[${index}].company`}
												maxLength={50}
											/>
											{empErrors && <span className='red-error'>{empErrors[index]?.company}</span>}
										</div>
										<div className='flex column-direction personal-details'>
											<label>{translate('position')}</label>
											<input
												type='text'
												placeholder={intl.formatMessage({ id: 'position' })}
												value={employmentHistory[index].position}
												onChange={props.onChange}
												maxLength={30}
												name={`employmentHistory[${index}].position`}
											/>
											{empErrors && <span className='red-error'>{empErrors[index]?.position}</span>}
										</div>
										<div className='flex column-direction personal-details'>
											<label>{translate('startDate')}</label>
											<input
												type='date'
												placeholder={intl.formatMessage({ id: 'selectDate' })}
												value={employmentHistory[index].startDate}
												onChange={props.onChange}
												name={`employmentHistory[${index}].startDate`}
												max={new Date().toISOString().split('T')[0]}
											/>
											{empErrors && <span className='red-error'>{empErrors[index]?.startDate}</span>}
											<div className='flex flex-wrap input-auto-width-wrapper'>
												<div className='register-practice-modal-grid tick-box-active-input currently-working'>
													<label className='flex full-width remember-me'>
														<input
															type='checkbox'
															onChange={props.onChange}
															checked={employmentHistory[index].currentlyWorking}
															name={`employmentHistory[${index}].currentlyWorking`}
														/>
														<div className='onb-custom-checkbox-wrapper cursor-pointer'>
															<div className='onb-custom-checkbox' />
														</div>
														<span>{translate('currentlyWorkingHere')}</span>
													</label>
												</div>
											</div>
										</div>
										<div
											className={`flex column-direction personal-details ${
												employmentHistory[index].currentlyWorking ? 'visibility-hidden' : ''
											}`}>
											<label>{translate('endDate')}</label>
											<input
												type='date'
												placeholder={intl.formatMessage({ id: 'selectDate' })}
												value={employmentHistory[index].endDate}
												onChange={props.onChange}
												name={`employmentHistory[${index}].endDate`}
												max={new Date().toISOString().split('T')[0]}
											/>
											{empErrors && <span className='red-error'>{empErrors[index]?.endDate}</span>}
										</div>

										<div className='flex column-direction personal-details position-relative registration-select-wrapper'>
											<label>{translate('country')}</label>
											<Select
												value={getCountryValue(index)}
												placeholder={intl.formatMessage({ id: 'selectCountry' })}
												classNamePrefix='react-select'
												options={props.transformArray(props.countries)}
												onChange={event => props.formikProps.setFieldValue(`employmentHistory[${index}].country4`, event?.value)}
												isSearchable
												isClearable
												styles={{
													...generateCustomStyles({ darkMode: props.isDarkMode }),
												}}
											/>
											{empErrors && <span className='red-error'>{empErrors[index]?.country4}</span>}
											{employmentHistory[index].country4 === Country.USA && (
												<>
													<label>*{translate('selectState')}</label>
													<Select
														value={getStateValue(index)}
														placeholder={intl.formatMessage({ id: 'selectState' })}
														classNamePrefix='react-select'
														options={props.transformArray(props.states)}
														onChange={event =>
															props.formikProps.setFieldValue(`employmentHistory[${index}].employmentStateId`, event?.value)
														}
														isSearchable
														isClearable
														styles={{
															...generateCustomStyles({ darkMode: props.isDarkMode }),
														}}
													/>
													{empErrors && <span className='red-error'>{empErrors[index]?.employmentStateId}</span>}
												</>
											)}
										</div>
										{props.formikProps.values.employmentHistory.length > 1 && (
											<div
												className='flex full-width right-align-content personal-details cursor-pointer'
												style={{ alignItems: 'center', flex: '0 0 100%' }}>
												<span className='flex top-15' onClick={() => remove(index)}>
													<p className='blue-color'>{translate('removePosition')}</p>
												</span>
											</div>
										)}
									</div>
								);
							})}
							{props.formikProps.values.employmentHistory.length <= 9 && (
								<div className='flex full-width right-align-content personal-details cursor-pointer'>
									<span className='flex top-15' onClick={() => push({ currentlyWorking: false })}>
										<img src={`${healthCareCdnUrl}add-new.svg`} alt='add new icon' className='add-new' />
										<p className='blue-color'>{translate('addPosition')}</p>
									</span>
								</div>
							)}
						</>
					)}
				</FieldArray>
			</div>
		</div>
	);
};

export default EmploymentHistory;
