import { gatewayApi, gatewayFileUploadApi } from 'constants/global-variables.js';
import { getCompanyId, getUserRole } from 'infrastructure/auth.js';
import { getUserRoleId } from 'infrastructure/helpers/commonHelpers.js';
import { SectorTypes } from 'constants/enums.js';

const CompanyId = getCompanyId();

export const getHealthSystems = async () => {
	try {
		const companyRoleId = getUserRoleId(getUserRole());
		const { data } = await gatewayApi.get(`/v1/organizations/${CompanyId}/health-systems?roleId=${companyRoleId}`);
		return data.healthSystems;
	} catch (error) {
		return { error };
	}
};

export const getHealthSystemSubTree = healthSystemId => {
	return new Promise((res, rej) => {
		gatewayApi
			.get(`/v1/organizations/${CompanyId}/health-systems/${healthSystemId}/full`)
			.then(response => {
				const { regions } = response.data;
				const treeData = [];
				regions.forEach((region, regionIndex) => {
					treeData.push({
						name: region.name,
						icon: 'local_hospital',
						type: SectorTypes.REGION,
						subOptions: [],
						id: region.id,
					});

					region.hospitals.forEach((hospital, hospitalIndex) => {
						treeData[regionIndex].subOptions.push({
							name: hospital.name,
							icon: 'business',
							type: SectorTypes.HOSPITAL,
							subOptions: [],
							id: hospital.id,
							regionId: region.id,
						});

						hospital.departments.forEach((department, departmentIndex) => {
							treeData[regionIndex].subOptions[hospitalIndex].subOptions.push({
								name: department.name,
								icon: 'account_balance',
								type: SectorTypes.DEPARTMENT,
								subOptions: [],
								id: department.id,
								hospitalId: hospital.id,
								regionId: region.id,
							});

							department.floors.forEach((floor, floorIndex) => {
								treeData[regionIndex].subOptions[hospitalIndex].subOptions[departmentIndex].subOptions.push({
									name: floor.name,
									icon: 'money',
									type: SectorTypes.FLOOR,
									subOptions: [],
									id: floor.id,
									departmentId: department.id,
									hospitalId: hospital.id,
									regionId: region.id,
								});

								floor.rooms.forEach((room, roomIndex) => {
									treeData[regionIndex].subOptions[hospitalIndex].subOptions[departmentIndex].subOptions[
										floorIndex
									].subOptions.push({
										name: room.name,
										icon: 'meeting_room',
										type: SectorTypes.ROOM,
										subOptions: [],
										floorId: floor.id,
										departmentId: department.id,
										hospitalId: hospital.id,
										regionId: region.id,
										id: room.id,
										roomIndex: roomIndex,
										status: 'online',
										helloDeviceId: room.helloDeviceId ? Number(room.helloDeviceId) : null,
									});
								});
							});
						});
					});
				});
				res(treeData);
			})
			.catch(e => rej(e));
	});
};

export const createHealthSystem = async ({ name, regions, workflowTypeId, treeHierarchyTypeId }) => {
	try {
		const url = `/v1.4/organizations/${CompanyId}/health-systems`;
		const { data } = await gatewayApi.post(url, {
			name,
			regions,
			workflowTypeId,
			treeHierarchyTypeId,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const deleteOrgUnit = async (level, id) => {
	try {
		const { data } = await gatewayApi.delete(`/v1/organizations/${CompanyId}/level/${level}/id/${id}`);
		return data.devices;
	} catch (error) {
		return { error };
	}
};

export const editHealthSystemName = async (healthSystemId, newHealthSystemName, workflowTypeId) => {
	try {
		const { data } = await gatewayApi.put(`/v1.2/organizations/${CompanyId}/health-systems/${healthSystemId}`, {
			name: newHealthSystemName,
			workflowTypeId,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const editRegionName = async (healthSystemId, regionId, newRegionName) => {
	try {
		const { data } = await gatewayApi.put(`/v1/organizations/${CompanyId}/health-systems/${healthSystemId}/regions/${regionId}`, {
			name: newRegionName,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const createNewRegion = async (healthSystemId, newRegionName) => {
	try {
		const { data } = await gatewayApi.post(`/v1/organizations/${CompanyId}/health-systems/${healthSystemId}/regions`, {
			name: newRegionName,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const uploadLogo = async formInput => {
	const formData = new FormData();
	formData.append('file', formInput, 'blob.png');
	try {
		const { data } = await gatewayFileUploadApi.post(`/v1/media/attachments`, formData);
		return data;
	} catch (error) {
		return { error };
	}
};

export const saveLogo = async (teamId, sendData) => {
	try {
		const { data } = await gatewayApi.put(`/v1/companies/${CompanyId}/teams/${teamId}/picture`, sendData);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getLogoUrl = async teamId => {
	try {
		const { data } = await gatewayApi.get(`/v1/companies/${CompanyId}/teams/${teamId}/picture`);
		return data.picture;
	} catch (error) {
		return { error };
	}
};

export const reAssignDevice = async ({ hospitalId, departmentId, floorId, roomId, deviceId }) => {
	try {
		const { data } = await gatewayApi.put(
			`/v1/organizations/${CompanyId}/hospitals/${hospitalId}/departments/${departmentId}/floors/${floorId}/rooms/${roomId}/devices/${deviceId}/re-assign`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getHospitalDepartments = async (healthSystemId, hospitalId) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/organizations/${CompanyId}/health-systems/${healthSystemId}/hospitals/${hospitalId}/departments`
		);

		return data;
	} catch (erorr) {
		return { erorr };
	}
};
