import React from 'react';
import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';
import { manualAlertType } from 'constants/alerts.js';
import ReportAsFalse from 'icons/Monitoring/ReportAsFalse';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';
import { useIntl } from 'react-intl';
import EmptyState from 'components/EmptyState.jsx';
import translate from 'i18n-translations/translate.jsx';

const ManualAlertsList = ({ manualAlerts, isLoading, isDarkMode, getManualAlertDetails }) => {
	const intl = useIntl();
	return (
		<>
			{!isLoading &&
				manualAlerts.length > 0 &&
				manualAlerts.map(item => (
					<div key={item.id} className='patient-request-list-item cursor-pointer' onClick={() => getManualAlertDetails(item.id)}>
						<div className='flex'>
							<div className='flex alert-history-inner-wrapper'>
								{manualAlertType(isDarkMode).find(el => el.type === item.manualAlertType.id)?.icon || (
									<ReportAsFalse color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />
								)}
								<p className='alert-history-description flex column-direction left-align-items left-s'>
									{intl.formatMessage({ id: item.manualAlertType.name })}
									<br />
									<span className='alert-time no-padding no-margin-left'>{formattedDate(item.dateCreated)}</span>
								</p>
								<i className='material-icons-outlined alert-icon position-absolute'>east</i>
							</div>
						</div>
					</div>
				))}
			{!manualAlerts.length && !isLoading && (
				<div className='flex flex-align-center flex-justify-center empty-state-wrapper'>
					<EmptyState title={translate('noResultsFound')} image='alerts.svg?v2' />
				</div>
			)}
			<div className='flex alert-subtabs' />
		</>
	);
};

export default ManualAlertsList;
