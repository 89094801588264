import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';

const companyId = getCompanyId();

export const deleteMemberCallAvailability = async () => {
	try {
		const url = `/v1/organizations/${companyId}/members/call-availability`;
		const { data } = await gatewayApi.delete(url);
		return data;
	} catch (error) {
		return { error };
	}
};

export const addOrUpdateMemberCallAvailability = async teams => {
	try {
		const url = `/v1/organizations/${companyId}/members/call-availability`;
		const { data } = await gatewayApi.post(url, { teams });
		return data;
	} catch (error) {
		return { error };
	}
};

export const getNurseHealthSystemAvailabilities = async () => {
	try {
		const url = `/v1/organizations/${companyId}/members/call-availability`;
		const { data } = await gatewayApi.get(url);
		return data;
	} catch (error) {
		return { error };
	}
};
