import React from 'react';
import { RadioButtonIcon } from 'components/index.js';

const Genders = props => (
	<label className='genders-wrapper-label'>
		<div className='flex gender-select-label'>
			{props.items.map(gender => (
				<RadioButtonIcon
					type={props.value}
					text={gender.text}
					iconSrc={gender.iconSrc}
					iconSrcChecked={gender.iconSrcChecked}
					handleChange={props.handleChange}
					handleBlur={props.handleBlur}
					value={gender.genderId}
					key={gender.genderId}
					name='genderId'
				/>
			))}
		</div>
		{props.errors.genderId && props.touched.genderId && <span className='red-error'>{props.errors.genderId}</span>}
	</label>
);

export default Genders;
