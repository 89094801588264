import { TeamTypes } from 'constants/enums.js';
import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';

export const getTeamConfigurationProfiles = async ({
	pageSize = 10,
	pageIndex = 0,
	teamId = null,
	teamTypeId = TeamTypes.HEALTH_SYSTEM,
}) => {
	try {
		const { data } = await gatewayApi.get(`v1/healthcare/organizations/${getCompanyId()}/configurations/team-profiles`, {
			params: {
				pageSize,
				pageIndex,
				teamId,
				teamTypeId,
			},
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const addTeamConfigurationProfile = async (teamId, teamType, params) => {
	try {
		const { data } = await gatewayApi.post(
			`/v1.1/healthcare/organizations/${getCompanyId()}/teams/${teamId}/team-types/${teamType}/configuration-profiles`,
			params
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const updateTeamConfigurationProfile = async (teamId, teamType, id, param) => {
	try {
		const { data } = await gatewayApi.put(
			`/v1.1/healthcare/organizations/${getCompanyId()}/teams/${teamId}/team-types/${teamType}/configuration-profiles/${id}`,
			param
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getTeamConfigurationProfilesHistory = async ({ id, pageSize = 10, pageIndex = 0 }) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/healthcare/organizations/${getCompanyId()}/configurations/team-profiles/${id}/history`,
			{
				params: {
					pageSize,
					pageIndex,
				},
			}
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const deleteTeamConfigurationProfile = async (teamId, teamType, id) => {
	try {
		const { data } = await gatewayApi.delete(
			`/v1/healthcare/organizations/${getCompanyId()}/teams/${teamId}/team-types/${teamType}/configuration-profiles/${id}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getDeviceConfigurationHistory = async (teamId, params) => {
	try {
		const { data } = await gatewayApi.get(
			`v1/healthcare/organizations/${getCompanyId()}/team/${teamId}/configurations/tv-device/history`,
			{
				params,
			}
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getTvDeviceTeamConfigurationProfile = async ({ teamId, teamType }) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/healthcare/organizations/${getCompanyId()}/team/${teamId}/configurations/tv-device?teamTypeId=${teamType}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getCareEvents = async ({ healthSystemId, pageIndex, pageSize, teamCareEventType }) => {
	try {
		let url;
		if (healthSystemId !== '0') {
			url = `v1/healthcare/organizations/${getCompanyId()}/care-events?pageIndex=${pageIndex}&pageSize=${pageSize}&teamCareEventType=${teamCareEventType}&healthSystemId=${healthSystemId}`;
		} else {
			url = `v1/healthcare/organizations/${getCompanyId()}/care-events?pageIndex=${pageIndex}&pageSize=${pageSize}&teamCareEventType=${teamCareEventType}`;
		}

		const { data } = await gatewayApi.get(url);
		return data.result;
	} catch (error) {
		return { error };
	}
};

export const createCareEvent = async ({ teamId, params }) => {
	try {
		const { data } = await gatewayApi.post(`/v1/healthcare/organizations/${getCompanyId()}/teams/${teamId}/care-events`, params);
		return data;
	} catch (error) {
		return { error };
	}
};

export const deleteCareEvent = async ({ id }) => {
	try {
		const { data } = await gatewayApi.delete(`/v1/healthcare/organizations/${getCompanyId()}/care-events/${id}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const editCareEvent = async ({ id, params }) => {
	try {
		const { data } = await gatewayApi.put(`/v1/healthcare/organizations/${getCompanyId()}/care-events/${id}`, params);
		return data;
	} catch (error) {
		return { error };
	}
};
