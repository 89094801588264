import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { actionCreators } from 'state/healthSystems/actions.js';
import GoogleCalendarService from 'services/CalendarEvents/GoogleCalendarService.js';
import MicrosoftGraphService from 'services/CalendarEvents/MicrosoftGraphService.js';
import { CalendarActions } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import ProfilePicture from 'components/ProfilePicture.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import Alert from 'components/Alert.jsx';
import Modal from 'components/Modal.jsx';
import Form from 'components/Form.jsx';

class CalendarAccountsTab extends React.Component {
	MicrosoftGraphService = new MicrosoftGraphService();

	constructor(props) {
		super(props);

		GoogleCalendarService.onLoad(() => {
			GoogleCalendarService.listenSign(this.signUpdate);
			if (GoogleCalendarService.isSignedIn) {
				this.googleAuth(CalendarActions.AUTHORIZE);
			}
		});

		this.MicrosoftGraphService.getAccount().then(data => {
			if (data !== null) {
				this.setState({ isOutlookAuth: data !== null, accountName: '', accountEmail: '', accountImage: null });
			}
		});
	}

	state = {
		isGoogleAuth: GoogleCalendarService.isSignedIn,
		accountName: '',
		accountEmail: '',
		accountImage: null,
		isOutlookAuth: false,
		errorMessage: null,
		isConfirmModalOpen: false,
		isInit: true,
	};

	authPlatform = {
		google: 'Google',
		outlook: 'Outlook',
	};

	toggleErrorModal = errorMessage => {
		this.setState(prevState => {
			return {
				errorMessage: prevState.errorMessage !== null ? null : errorMessage,
			};
		});
	};

	signUpdate = isSignedIn => {
		this.setState({ isGoogleAuth: isSignedIn }, () => {
			this.getGoogleSignInData(isSignedIn);
		});
	};

	googleAuth = async status => {
		try {
			if (status === CalendarActions.AUTHORIZE) {
				await GoogleCalendarService.handleAuthClick();
				this.signUpdate(GoogleCalendarService.isSignedIn);
				this.outlookAuth(CalendarActions.UNAUTHORIZE);
			} else if (status === CalendarActions.UNAUTHORIZE) {
				await GoogleCalendarService.handleSignoutClick();
				this.setState({ isGoogleAuth: false });
			}
		} catch (error) {
			if (error.result) {
				const { result } = error;
				this.setState({ errorMessage: result.error.message });
			}
		}
	};

	outlookAuth = async status => {
		try {
			if (status === CalendarActions.AUTHORIZE) {
				if (!this.state.isOutlookAuth) {
					this.googleAuth(CalendarActions.UNAUTHORIZE);
					const loginResult = await this.MicrosoftGraphService.login();
					const account = await this.MicrosoftGraphService.getAccount();
					if (loginResult.idToken !== null) {
						this.setState({
							isOutlookAuth: loginResult.idToken !== null,
							accountName: account.name,
							accountEmail: account.userName,
							accountImage: null,
							isConfirmModalOpen: true,
						});
					}
				}
			} else {
				const logoutStatus = this.MicrosoftGraphService.logout();

				if (logoutStatus) {
					this.setState({ isOutlookAuth: false });
				}
			}
		} catch (error) {
			if (error.errorCode !== 'user_cancelled') {
				this.setState({ errorMessage: `Authentication failed! ${error.errorMessage}`, isOutlookAuth: false, isConfirmModalOpen: true });
			}
		}
	};

	getGoogleSignInData = async status => {
		try {
			if (status) {
				const userData = GoogleCalendarService.getSignedInUserData();
				this.setState(prevState => ({
					accountName: userData.sd,
					accountEmail: userData.nt,
					accountImage: userData.jI,
					isConfirmModalOpen: !prevState.isInit,
					isInit: false,
				}));
			}
		} catch (error) {
			this.setState({ isGoogleAuth: false, errorMessage: error.message, isConfirmModalOpen: true });
			this.googleAuth(CalendarActions.UNAUTHORIZE);
		}
	};

	getCalendarTab = ({ imageUrl, authState, authType, title }) => {
		return (
			<div>
				<div className='flex'>
					<img src={`${healthCareCdnUrl}general-settings/${imageUrl}`} alt='ico' />
					<p>{title}</p>
					{authState && (
						<div className='flex'>
							<div>
								<ProfilePicture
									firstName={this.state.accountName}
									lastName={this.state.accountName}
									profilePicture={this.state.accountImage}
								/>
							</div>
							<div>
								<p>{this.state.accountName}</p>
								<span>{this.state.accountEmail}</span>
							</div>
						</div>
					)}
					{authType === this.authPlatform.google && (
						<span
							className='cursor-pointer'
							onClick={() => {
								const calendarAction = this.state.isGoogleAuth ? CalendarActions.UNAUTHORIZE : CalendarActions.AUTHORIZE;
								this.googleAuth(calendarAction);
							}}>
							{this.state.isGoogleAuth && (
								<>
									<i className='material-icons'>close</i> {translate('unauthorize')}
								</>
							)}
							{!this.state.isGoogleAuth && (
								<>
									<i className='material-icons'>add</i> {translate('connectGoogleAccount')}
								</>
							)}
						</span>
					)}
					{authType === this.authPlatform.outlook && (
						<span
							className='cursor-pointer'
							onClick={() => {
								const calendarAction = this.state.isOutlookAuth ? CalendarActions.UNAUTHORIZE : CalendarActions.AUTHORIZE;
								this.outlookAuth(calendarAction);
							}}>
							{this.state.isOutlookAuth && (
								<>
									<i className='material-icons'>close</i> {translate('unauthorize')}
								</>
							)}
							{!this.state.isOutlookAuth && (
								<>
									<i className='material-icons'>add</i> {translate('connectOutlookAccount')}
								</>
							)}
						</span>
					)}
				</div>
			</div>
		);
	};

	toggleConfirmModal = () => {
		this.setState(prevState => ({ isConfirmModalOpen: !prevState.isConfirmModalOpen }));
	};

	render() {
		return (
			<div className='account-settings-inner-wrapper'>
				<h4>{translate('calendarAccounts')}</h4>
				<div className='measurement-units-table calendar-acc-table'>
					{this.getCalendarTab({
						imageUrl: 'google.svg',
						authState: this.state.isGoogleAuth,
						authType: this.authPlatform.google,
						title: translate('googleAccount'),
					})}
					{this.getCalendarTab({
						imageUrl: 'outlook.svg',
						authState: this.state.isOutlookAuth,
						authType: this.authPlatform.outlook,
						title: translate('outlookAccount'),
					})}
				</div>

				<Alert display={this.state.error} fixed={true} hideCloseButton={true} message={this.state.error} variant='dark' />
				<Modal
					display={this.state.isConfirmModalOpen}
					onModalSubmit={() => this.toggleConfirmModal()}
					primaryButtonLabel={translate('done')}
					position='center'
					isLoading={this.state.isLoading}
					onModalClose={() => this.toggleConfirmModal()}
					shouldSubmitOnEnter={false}
					className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal discard'
					submitImgIcon={`${healthCareCdnUrl}appointments/save-icon.svg`}>
					<Form height={200} className='modal-form-wrapper'>
						<h3>{!this.state.errorMessage ? translate('successfulSignIn') : translate('errorSignIn')}</h3>
						<p>
							{!this.state.errorMessage
								? translate('successfulSignInDescription', {
										value: this.state.isGoogleAuth ? translate('googleAccount') : translate('outlookAccount'),
								  })
								: translate('errorSignInDescription', {
										value: !this.state.isGoogleAuth ? translate('googleAccount') : translate('outlookAccount'),
								  })}
						</p>
					</Form>
				</Modal>
			</div>
		);
	}
}

export default withRouter(
	connect(
		state => state,
		dispatch => bindActionCreators(actionCreators, dispatch)
	)(CalendarAccountsTab)
);
