import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import _ from 'lodash';
import { addMinutes, setHours, setMinutes } from 'date-fns';
import translate from 'i18n-translations/translate.jsx';
import Alert from 'components/Alert.jsx';
import Form from 'components/Form.jsx';
import Modal from 'components/Modal.jsx';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';


import { getDay, parseStringToDate, scheduledDays, subtractMinutes } from 'infrastructure/helpers/dateHelper.js';
import { SectorTypes } from 'constants/enums.js';
import { findSectorById, getLevelIdByType } from 'infrastructure/helpers/commonHelpers.js';
import VisitingHoursTree from 'containers/VisitingHoursTree';
import { addVisitingHours, getVisitingHours } from 'api/visitingHours.js';

const VisitingHoursConfiguration = props => {
	const intl = useIntl();
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const [isConfirmed, setIsConfirmed] = useState(false);
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const [overRideChildrenConfigs, setOverRideChildrenConfigs] = useState(false);
	const [applyAllDaysOfWeekChecked, setApplyAllDaysOfWeekChecked] = useState(false);
	const [alertErrorText, setAlertErrorText] = useState('');
	const [visitingHoursTree, setVisitingHoursTree] = useState([]);
	const [visitingHours, setVisitingHours] = useState(scheduledDays);

	const [step, setStep] = useState(1);
	const treeData = useSelector(state => state.healthSystems.treeData.tree);

	const getVisitingHoursParams = isDelete => {
		let objs = [];
		const visitingHoursTreeCloned = _.cloneDeep(visitingHoursTree);
		gatherItemsToSend(visitingHoursTreeCloned, objs);
		visitingHours.filter(visitingHour => visitingHour.hours.some(hour => hour.to));
		if (isDelete) {
			objs = objs.map(({ level, levelId }) => ({
				level,
				levelId,
				visitingHours: [],
			}));
		}
		return objs;
	};

	useEffect(() => {
		if (isConfirmed) {
			const handleSubmit = async isDelete => {
				if (!hasNodesChecked()) {
					return;
				}
				setIsSubmitLoading(true);
				const response = await addVisitingHours({
					levelVisitingHours: getVisitingHoursParams(isDelete),
					deleteChildrenConfigurations: overRideChildrenConfigs,
				});
				if (response.error) {
					setAlertErrorText(response.error.message);
				} else {
					props.setIsVisitingHoursModalOpen(false);
				}
				setIsSubmitLoading(false);
				setShowConfirmModal(false);
			};
			handleSubmit(step === 1);
		}
	}, [isConfirmed]);

	const getInitialVisitingHoursMaped = list => {
		const mapedVisitingHours = list.map(item => ({
			...item,
			isChecked: true,
			hours: item.hours.map((hour, index) => ({
				to: parseStringToDate(hour.to),
				from: parseStringToDate(hour.from),
				id: index,
			})),
		}));
		for (let i = 1; i < 8; i += 1) {
			if (!mapedVisitingHours.find(item => item.weekDay === i)) {
				mapedVisitingHours.push({
					weekDay: i,
					hours: [{ from: null, to: null, id: 0 }],
				});
			}
		}
		mapedVisitingHours.sort((a, b) => a.weekDay - b.weekDay);
		return mapedVisitingHours;
	};

	useEffect(() => {
		if (props.sector.sectorType) {
			const foundSector = findSectorById(treeData, props.sector[`${props.sector.sectorType}Id`]);
			if (props.sector.sectorType === SectorTypes.ROOM) {
				foundSector.hasVisitingHours = true;
			}
			const fetchVisitingHours = async () => {
				const response = await getVisitingHours(
					getLevelIdByType(props.sector.sectorType),
					props.sector[`${props.sector.sectorType}Id`]
				);
				if (response.error) {
					setAlertErrorText(response.error.message);
				} else {
					const checkedSector = findSectorById([foundSector], response.levelId);
					if (response?.visitingHours?.length > 0) {
						toggleChildren([checkedSector], true);
					}
					setVisitingHours(getInitialVisitingHoursMaped(response.visitingHours));
				}
			};
			fetchVisitingHours();
			setVisitingHoursTree([foundSector]);
		}
	}, []);

	const gatherItemsToSend = (list, objs) => {
		const visitingHoursTreeCloned = _.cloneDeep(visitingHoursTree);
		for (let i = 0; i < list.length; i += 1) {
			const item = list[i];
			if (item.subOptions.length > 0 && item.subOptions.every(options => options.hasVisitingHours)) {
				const visitingHoursToSend = visitingHoursMaped();
				objs.push({
					levelId: item[`${item.type}Id`],
					level: getLevelIdByType(item.type),
					visitingHours: visitingHoursToSend,
					isDisabled: visitingHoursToSend.length === 0,
				});
			}
			if (
				item.type === SectorTypes.ROOM &&
				!findSectorById(visitingHoursTreeCloned, item.floorId).subOptions.every(subOption => subOption.hasVisitingHours) &&
				item.hasVisitingHours
			) {
				const visitingHoursToSend = visitingHoursMaped();
				objs.push({
					levelId: item[`${item.type}Id`],
					level: getLevelIdByType(item.type),
					visitingHours: visitingHoursToSend,
					isDisabled: visitingHoursToSend.length === 0,
				});
			}
			if (props.sector.sectorType === SectorTypes.ROOM) {
				const visitingHoursToSend = visitingHoursMaped();
				objs.push({
					levelId: item[`${item.type}Id`],
					level: getLevelIdByType(item.type),
					visitingHours: visitingHoursToSend,
					isDisabled: visitingHoursToSend.length === 0,
				});
			}
			if (item.subOptions.length > 0 && !item.subOptions.every(options => options.hasVisitingHours)) {
				gatherItemsToSend(item.subOptions, objs);
			}
		}
	};

	const onVistingHoursChange = (date, weekDay, hoursIndex, isTo) => {
		setVisitingHours(prevState => {
			const clonedState = _.cloneDeep(prevState);
			const [selectedItem] = clonedState.splice(weekDay - 1, 1);
			const [splicedHours] = selectedItem.hours.splice(hoursIndex, 1);
			splicedHours[isTo ? 'to' : 'from'] = date;
			selectedItem.hours.splice(hoursIndex, 0, splicedHours);
			selectedItem.isChecked = selectedItem.hours.some(item => item.to && item.from);
			clonedState.splice(weekDay - 1, 0, selectedItem);
			return clonedState;
		});
	};

	const handleCheckBoxChange = (option, e) => {
		const isChecked = !!e.target.checked;
		const visitingHoursTreeCloned = _.cloneDeep(visitingHoursTree);
		const foundSector = findSectorById(visitingHoursTreeCloned, option[`${option.type}Id`]);
		foundSector.hasVisitingHours = isChecked;
		toggleChildren(foundSector.subOptions, isChecked);
		checkParent(option, visitingHoursTreeCloned);
		setVisitingHoursTree(visitingHoursTreeCloned);
	};

	const toggleChildren = (list, isChecked) => {
		list.forEach(item => {
			// eslint-disable-next-line no-param-reassign
			item.hasVisitingHours = isChecked;
			toggleChildren(item.subOptions, isChecked);
		});
	};

	const checkParent = (option, visitingHoursTreeCloned) => {
		if (visitingHoursTreeCloned[0][`${option.type}Id`] !== option[`${option.type}Id`]) {
			const parentType = getParent(option.type);
			const parent = findSectorById(visitingHoursTreeCloned, option[`${parentType}Id`]);
			parent.hasVisitingHours = parent.subOptions.every(item => item.hasVisitingHours);
			checkParent(parent, visitingHoursTreeCloned);
		}
	};

	const handleToggleWeekDay = weekDay => {
		if (!visitingHours.find(item => item.weekDay === weekDay).hours.some(item => item.to && item.from)) {
			return;
		}
		setVisitingHours(prevState => {
			const clonedState = _.cloneDeep(prevState);
			const selectedItem = clonedState.find(item => item.weekDay === weekDay);
			selectedItem.isChecked = !selectedItem.isChecked;
			if (!selectedItem.isChecked) {
				selectedItem.hours = [{ to: null, from: null, id: 0 }];
			}
			return clonedState;
		});
	};
	const handleAddVisitingHour = weekDay => {
		const visitingHour = visitingHours.find(item => item.weekDay === weekDay);
		if (visitingHour.hours.length === 5) {
			return;
		}
		setVisitingHours(prevState => {
			const clonedState = _.cloneDeep(prevState);
			const selectedItem = clonedState.find(item => item.weekDay === weekDay);
			selectedItem.hours.push({ from: null, to: null, id: selectedItem.hours.length });
			return clonedState;
		});
	};

	const handleRemoveVisitingHour = (weekDay, index) => {
		setVisitingHours(prevState => {
			const clonedState = _.cloneDeep(prevState);
			const selectedItem = clonedState.find(item => item.weekDay === weekDay);
			if (selectedItem.hours.length === 1) {
				selectedItem.isChecked = false;
				selectedItem.hours[index] = { to: null, from: null, id: 0 };
			} else {
				selectedItem.hours.splice(index, 1);
			}
			return clonedState;
		});
	};

	const getParent = type => {
		let result;
		switch (type) {
			case SectorTypes.ROOM: {
				result = SectorTypes.FLOOR;
				break;
			}
			case SectorTypes.FLOOR: {
				result = SectorTypes.DEPARTMENT;
				break;
			}
			case SectorTypes.DEPARTMENT: {
				result = SectorTypes.HOSPITAL;
				break;
			}
			default: {
				result = null;
				break;
			}
		}
		return result;
	};

	const handleSelectAll = () => {
		const isChecked = !visitingHoursTree[0].hasVisitingHours;
		const visitingHoursTreeCloned = _.cloneDeep(visitingHoursTree);
		toggleChildren(visitingHoursTreeCloned, isChecked);
		setVisitingHoursTree(visitingHoursTreeCloned);
	};

	const visitingHoursMaped = () =>
		visitingHours.reduce((result, element) => {
			if (element.isChecked) {
				const hours = element.hours.reduce((hoursRes, hour) => {
					if (hour.from && hour.to) {
						hoursRes.push({ from: dateToString(hour.from), to: dateToString(hour.to) });
					}
					return hoursRes;
				}, []);
				if (hours.length > 0) {
					result.push({ hours, weekDay: element.weekDay });
				}
			}
			return result;
		}, []);

	const dateToString = date =>
		date.toLocaleTimeString('en-GB', {
			hour: '2-digit',
			minute: '2-digit',
			second: '2-digit' 
		});

	const hasNodesChecked = () => {
		let found = false;
		const searchForNode = list => {
			for (let i = 0; i < list.length; i += 1) {
				if (list[i].hasVisitingHours) {
					found = true;
					break;
				}
				if (list[i].subOptions.length > 0) {
					searchForNode(list[i].subOptions);
					if (found) {
						break;
					}
				}
			}
		};
		searchForNode(visitingHoursTree);
		return found;
	};

	const applyVisitingHourToAll = (weekDay = null) => {
		const clonedVisitingHours = _.cloneDeep(visitingHours);
		const selectedVisitingHour = clonedVisitingHours.find(item => item.weekDay === weekDay);
		const hoursToSet = selectedVisitingHour ? selectedVisitingHour.hours : [{ from: null, to: null, id: 0 }];
		const result = clonedVisitingHours.map((item, index) => ({
			...item,
			isChecked: index === 0 ? true : !!weekDay,
			hours: index === 0 ? item.hours : _.cloneDeep(hoursToSet),
		}));
		setVisitingHours(result);
	};

	return (
		<>
			<Modal
				isViewOnly={step === 1}
				display={true}
				position='right'
				primaryButtonLabel={translate('delete')}
				onModalClose={() => props.setIsVisitingHoursModalOpen(false)}
				className='visiting-hours-modal'>
				<Form title={translate('visitingHours')} onSubmit={event => event.preventDefault()}>
					<p>{translate('addedConfigsRecurring')}</p>
					<div onClick={() => applyVisitingHourToAll(applyAllDaysOfWeekChecked ? null : 1)}>
						<label className='checkbox'>
							<input
								type='checkbox'
								onChange={() => setApplyAllDaysOfWeekChecked(prevState => !prevState)}
								checked={applyAllDaysOfWeekChecked}
							/>
							<span className='link flex-initial'>
								<span className='semi-bold'>{translate('applyVisitingHoursAllDays')}</span>
							</span>
						</label>
					</div>
					<div>
						{step === 1 && (
							<div>
								<div className='visiting-hours-wrapper'>
									{visitingHours.map(item => (
										<div key={item.id}>
											<div className='flex'>
												<div className='rounded-slider-switch' onClick={() => handleToggleWeekDay(item.weekDay)}>
													<input type='checkbox' checked={item.isChecked} />
													<span className='rounded-slider' />
												</div>
												<p className='flex-1'>{getDay(item.weekDay, intl)} </p>
												<div
													className='flex visiting-hours-btn margin-left-auto'
													onClick={() => handleAddVisitingHour(item.weekDay)}>
													<i className='material-icons'>add</i>
													<span>{translate('addVisitingHours')}</span>
												</div>
											</div>
											{item.hours.map((hours, index) => (
												<div className='flex' key={hours.id}>
													<div className='flex-1'>
														<div className='flex available-hours'>
															<DatePicker
																selected={hours.from}
																placeholderText=''
																onChange={date => onVistingHoursChange(date, item.weekDay, index, false)}
																showTimeSelect
																showTimeSelectOnly
																timeIntervals={30}
																dateFormat='h:mm aa'
																popperPlacement='top'
																minTime={setHours(setMinutes(new Date(), 0), 0)}
																maxTime={hours.to ? subtractMinutes(hours.to) : setHours(setMinutes(new Date(), 30), 23)}
															/>
															<DatePicker
																selected={hours.to}
																placeholderText=''
																onChange={date => onVistingHoursChange(date, item.weekDay, index, true)}
																showTimeSelect
																showTimeSelectOnly
																timeIntervals={30}
																dateFormat='h:mm aa'
																popperPlacement='top'
																minTime={hours.from ? addMinutes(hours.from, 15) : setHours(setMinutes(new Date(), 0), 0)}
																maxTime={setHours(setMinutes(new Date(), 30), 23)}
															/>
														</div>
													</div>

													<div className='flex visiting-hours-btn' onClick={() => handleRemoveVisitingHour(item.weekDay, index)}>
														{!(index === 0 && !hours.to && !hours.from) && (
															<>
																<i className='material-icons'>remove</i>
																<span>{translate('remove')}</span>
															</>
														)}
													</div>
												</div>
											))}
										</div>
									))}
								</div>
							</div>
						)}
						{step === 2 && (
							<div>
								{props.sector.sectorType !== SectorTypes.ROOM && (
									<VisitingHoursTree data={visitingHoursTree} handleCheckBoxChange={handleCheckBoxChange} />
								)}
								{props.sector.sectorType === SectorTypes.ROOM && <p>{props.sector.roomName}</p>}
								{props.sector.sectorType !== SectorTypes.ROOM && (
									<button type='button' onClick={handleSelectAll}>
										{translate(
											visitingHoursTree.length > 0 && visitingHoursTree[0].hasVisitingHours ? 'deSelectAll' : 'selectAll'
										)}
									</button>
								)}
							</div>
						)}
					</div>
				</Form>
				{step === 1 && (
					<div className='buttons-flex'>
						<button type='button' onClick={() => setShowConfirmModal(prevState => !prevState)}>
							{translate('applyGlobalSettings')}
						</button>
						<button type='button' onClick={() => setStep(2)}>
							{translate('next')}
						</button>
					</div>
				)}
				{step === 2 && (
					<div className='buttons-flex'>
						<button type='button' onClick={() => setStep(1)}>
							{translate('back')}
						</button>
						<button
							type='button'
							onClick={() => setShowConfirmModal(prevState => !prevState)}
							disabled={isSubmitLoading || !hasNodesChecked()}>
							{translate('submit')}
						</button>
					</div>
				)}
				<Alert
					display={alertErrorText}
					fixed={true}
					message={alertErrorText}
					variant='error'
					onClose={() => setAlertErrorText(null)}
				/>
			</Modal>
			<Modal
				display={showConfirmModal}
				position='center'
				primaryButtonLabel={translate('yes')}
				onModalClose={() => setShowConfirmModal(false)}
				className='visiting-hours-modal visiting-hours-popup border-radius-modal-wrapper'>
				<>
					<h3 className='no-margin no-padding'>{translate('confirmationModal')}</h3>
					<p className='no-padding no-margin-bottom'>{translate('areYouSureConfigurations')}</p>
					<div>
						<div className='tree no-padding-left'>
							<div className='tree__child'>
								<div>
									<label className='checkbox'>
										<input
											type='checkbox'
											onChange={() => setOverRideChildrenConfigs(prevState => !prevState)}
											checked={overRideChildrenConfigs}
										/>
										<span className='link flex-initial'>
											<span className='semi-bold'>{translate('enforceConfiguration')}</span>
										</span>
									</label>
									<p>{translate('overrideLocalConfiguration')}</p>
								</div>
							</div>
						</div>
						<button type='button' onClick={() => setIsConfirmed(true)}>
							{translate('confirm')}
						</button>
					</div>
				</>
			</Modal>
		</>
	);
};

export default VisitingHoursConfiguration;
