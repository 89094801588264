import React, { useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import translate from 'i18n-translations/translate.jsx';
import { getHealthSystemHospitals } from 'api/userIdleConfigurations.js';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import Alert from 'components/Alert.jsx';
import { DeviceListLevel, HealthcareErrorCode } from 'constants/enums.js';
import { getUserInfo } from 'infrastructure/auth.js';
import { ActiveTab, CareEventProp, CareEventStatusType, CareEventStatuses, CareEventsHeaders } from 'constants/care-events.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import Button from 'components/Button.jsx';
import CareEventsDropdown from 'containers/CareEventsDashboard/CareEventsDropdown.jsx';
import AddCareEventModal from 'containers/CareEventsDashboard/AddCareEventModal.jsx';
import PatientsCareEvents from 'containers/CareEventsDashboard/PatientsCareEvents.jsx';
import classNames from 'classnames';
import { getCareEventsDashboard, getPatientsByLevel, updateCareEventStatus } from 'api/careEvents.js';
import { getCareEventTabClassName, getSelectedStatus } from 'infrastructure/helpers/careEventsHelper.js';
import useOutsideClick from 'infrastructure/helpers/useOutsideClick.js';
import { getNursesAssigned } from 'api/rpm.js';
import Loader from 'components/Loader.jsx';
import CareEventHistory from 'containers/CareEventsDashboard/CareEventHistory.jsx';
import CareEventNotes from 'containers/CareEventsDashboard/CareEventNotes.jsx';
import { subDays } from 'date-fns';
import CareEventsFilters from 'containers/CareEventsDashboard/CareEventsFilters.jsx';
import { convertToUnixTimeStamp } from 'infrastructure/helpers/dateHelper.js';
import Retry from 'icons/CareEvents/Retry.jsx';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';
import ViewPatientProfile from 'containers/CheckInAPatient/ViewPatientProfile.jsx';
import AlertsModal from 'containers/VitalSignsMonitoring/AlertsModal.jsx';

const CareEventsDashboard = () => {
	const intl = useIntl();
	const defaultRangeOfDays = 30;
	const today = new Date();
	const fromDefault = subDays(today, defaultRangeOfDays);
	const toDefault = today;
	const userInfo = getUserInfo();
	const [careEventTitle, setCareEventTitle] = useState(' ');
	const [selectedStatuses, setSelectedStatuses] = useState({});
	const [careEventsDashboard, setCareEventsDashboard] = useState([]);
	const [totalCareEvents, setTotalCareEvents] = useState({ totalInProgress: 0, totalNew: 0, totalDone: 0, totalMine: 0 });
	const [isLoading, setIsLoading] = useState(false);
	const [pageIndex, setPageIndex] = useState(0);
	const [error, setError] = useState(null);
	const [currentStatus, setCurrentStatus] = useState(ActiveTab.ALL_EVENTS);
	const [isFilterShown, setIsFilterShown] = useState(false);
	const [isCareEventDropdownShown, setIsCareEventDropdownShown] = useState(false);
	const [isCareEventModalOpen, setIsCareEventModalOpen] = useState(false);
	const [patients, setPatients] = useState([]);
	const [selectedPatient, setSelectedPatient] = useState(null);
	const [nurses, setNurses] = useState([]);
	const [selectedNurse, setSelectedNurse] = useState(null);
	const [careEventId, setCareEventId] = useState(null);
	const [isPatientAdmitted, setIsPatientAdmitted] = useState(false);
	const [isCareEventHistory, setIsCareEventHistory] = useState(false);
	const [selectedCareEvent, setSelectedCareEvent] = useState(null);
	const [isNotesModalShown, setIsNotesModalShown] = useState(false);
	const [selectedStatus, setSelectedStatus] = useState(null);
	const [recognizedTranscription, setRecognizedTranscription] = useState('');
	const [liveTranscription, setLiveTranscription] = useState('');
	const [isSpeechToText, setIsSpeechToText] = useState(false);
	const [selectedCareTeamOptions, setSelectedCareTeamOptions] = useState({});
	const [selectedCareMember, setSelectedCareMember] = useState(null);
	const [selectedEventType, setSelectedEventType] = useState(null);
	const [selectedPriorityFilter, setSelectedPriorityFilter] = useState(null);
	const [selectedStatusFilter, setSelectedStatusFilter] = useState(null);
	const [hasFilterChanged, setHasFilterChanged] = useState(false);
	const [selectedSLAFilter, setSelectedSLAFilter] = useState(null);
	const [selectedPriorities, setSelectedPriorities] = useState({});
	const [selectedPriority, setSelectedPriority] = useState(null);
	const [searchedPatient, setSearchedPatient] = useState('');
	const [dateRange, setDateRange] = useState({ from: fromDefault, to: toDefault });
	const [isCareEventUpdated, setIsCareEventUpdated] = useState(false);
	const [shouldUpdateList, setShouldUpdateList] = useState(false);
	const [patientDetails, setPatientDetails] = useState(null);
	const [selectedCountry, setSelectedCountry] = useState(null);
	const [countries, setCountries] = useState(null);
	const [preferredUnits, setPreferredUnits] = useState([]);
	const [isViewProfileModalVisible, setViewProfileModalVisibility] = useState(false);
	const [isAlertsModalVisible, setAlertsModalVisibility] = useState(false);
	const [patientAlerts, setPatientAlerts] = useState(null);
	const userSession = useSelector(state => state.user.userSession);
	const [selectedLevel, setSelectedLevel] = useState({
		label: DeviceListLevel.HEALTH_SYSTEM,
		value: userSession.healthSystem.id,
	});
	const hasReachedEnd = useRef(null);
	const dropdownRef = useRef(null);
	const filtersRef = useRef(null);
	const isDarkMode = useSelector(state => state.user.darkMode);
	const socket = useContext(SocketContext);

	useEffect(() => {
		const getHospitalsByHealthSystem = async () => {
			const hsHospitals = await getHealthSystemHospitals(userSession.healthSystem.id);
			if (hsHospitals.error) {
				setError(hsHospitals.error.message);
				setIsLoading(false);
				return;
			}
			setIsLoading(false);
		};
		getHospitalsByHealthSystem();
	}, [userSession.healthSystem.id]);

	useEffect(() => {
		const getPatients = async () => {
			const response = await getPatientsByLevel({ level: DeviceListLevel.HEALTH_SYSTEM, id: userSession.healthSystem.id });
			if (response.error) {
				setError(response.error.message);
				return;
			}
			setPatients(response.patients);
		};

		getPatients();
	}, [userSession.healthSystem?.id, isPatientAdmitted]);

	useEffect(() => {
		const getAssignedTo = () => {
			if (currentStatus === ActiveTab.MY_CARE_EVENTS) {
				return userInfo.id;
			}
			if (selectedNurse?.value) {
				return selectedNurse?.value;
			}
			return '';
		};
		const fetchCareEventsDashboard = async () => {
			setIsLoading(true);
			setIsPatientAdmitted(false);
			const level = selectedLevel.label ?? DeviceListLevel.HEALTH_SYSTEM;
			const id = selectedLevel.value;
			const queryParams = {
				assignedTo: getAssignedTo(),
				statusId: selectedStatusFilter?.id ?? getSelectedStatus(currentStatus),
				pageIndex,
				pageSize: 20,
				patientId: selectedPatient?.value,
				eventId: selectedEventType?.careEventId,
				from: dateRange.from && convertToUnixTimeStamp(new Date(dateRange.from)),
				to: dateRange.to && convertToUnixTimeStamp(new Date(dateRange.to).setHours(23, 59, 59, 999)),
				search: searchedPatient,
				priorityId: selectedPriorityFilter?.id,
				sla: selectedSLAFilter?.id,
			};
			const response = await getCareEventsDashboard({ level, id, queryParams });
			if (response.error) {
				setError(response.error.message);
				setIsLoading(false);
				return;
			}
			setIsLoading(false);

			if (pageIndex === 0) {
				setCareEventsDashboard(response.patientCareEvents);
			}
			if (pageIndex > 0) {
				setCareEventsDashboard(prevState => [...prevState, ...response.patientCareEvents]);
			}
			setTotalCareEvents(response.totals);
			if (!response.totals) {
				setTotalCareEvents(prevState => ({ ...prevState, totalInProgress: 0, totalNew: 0, totalDone: 0, totalMyCareEvents: 0 }));
			}
			hasReachedEnd.current = response.patientCareEvents.length < 20;
		};
		fetchCareEventsDashboard();
	}, [
		selectedPatient,
		selectedNurse,
		currentStatus,
		isPatientAdmitted,
		pageIndex,
		selectedLevel,
		selectedStatuses,
		selectedCareTeamOptions,
		selectedPriorities,
		hasFilterChanged,
		shouldUpdateList,
	]);

	useEffect(() => {
		socket.on(SocketEvents.HealthCare.ON_PATIENT_CARE_EVENT_UPDATED, () => setIsCareEventUpdated(true));
		return () => {
			socket.off(SocketEvents.HealthCare.ON_PATIENT_CARE_EVENT_UPDATED, () => setIsCareEventUpdated(true));
		};
	}, [socket]);

	const handlePatientSearch = event => {
		setSearchedPatient(event.target.value);
		setHasFilterChanged(prevState => !prevState);
	};

	const handleScroll = event => {
		const isBottom = event.target.scrollHeight - Math.ceil(event.target.scrollTop) === event.target.clientHeight;
		if (isBottom && !hasReachedEnd.current && event.target.scrollTop !== 0) {
			setPageIndex(prevState => prevState + 1);
		}
	};

	useOutsideClick(dropdownRef, () => {
		setIsCareEventDropdownShown(false);
	});

	useOutsideClick(filtersRef, () => {
		setIsFilterShown(false);
	});

	const handleChangeProp = ({ item, propItem, propName, nestedProp = null }) => {
		setError(null);
		if (nestedProp) {
			if (item[propName][nestedProp] === propItem.value) {
				return;
			}
		}
		if (item.hasOwnProperty(propName)) {
			if (item[propName] === propItem.value) {
				return;
			}
		}
		setIsNotesModalShown(prevState => !prevState);
		setSelectedCareEvent(item);
		switch (propName) {
			case CareEventProp.STATUS_ID:
				setSelectedStatus(propItem);
				setSelectedPriority(null);
				setSelectedCareMember(null);
				break;
			case CareEventProp.CARE_MEMBER:
				setSelectedCareMember(propItem);
				setSelectedPriority(null);
				setSelectedStatus(null);
				break;
			case CareEventProp.PRIORITY_ID:
				setSelectedPriority(propItem);
				setSelectedCareMember(null);
				setSelectedStatus(null);
				break;
			default:
				break;
		}
	};

	const handleSubmitNote = async () => {
		const response = await updateCareEventStatus({
			patientId: selectedCareEvent.patientId,
			careEventId: selectedCareEvent.id,
			statusId: selectedStatus?.value || selectedCareEvent.statusId,
			note: recognizedTranscription,
			careMemberId: selectedCareMember?.value || selectedCareEvent.careMember.id,
			priorityId: selectedPriority?.value || selectedCareEvent.priorityId,
		});
		if (response.error) {
			if (response.error.response.data.code === HealthcareErrorCode.RESOURCE_EXISTS) {
				setError(translate('statusAlreadyExists'));
			} else {
				setError(response.error.message);
			}
			return;
		}

		if (selectedStatus) {
			setSelectedStatuses(prevOptions => ({
				...prevOptions,
				[selectedCareEvent.id]: selectedStatus,
			}));
		}

		if (selectedCareMember) {
			setSelectedCareTeamOptions(prevOptions => ({
				...prevOptions,
				[selectedCareEvent.id]: selectedCareMember,
			}));
		}

		if (selectedPriority) {
			setSelectedPriorities(prevOptions => ({
				...prevOptions,
				[selectedCareEvent.id]: selectedPriority,
			}));
		}

		setIsNotesModalShown(false);
		setRecognizedTranscription('');
		setLiveTranscription('');
	};

	const getNurses = async (value = '') => {
		const response = await getNursesAssigned({ healthSystemId: userSession.healthSystem.id, search: value });
		if (response.error) {
			setError(response.error.message);
			return [];
		}
		const newOptions = response.users.map(user => ({
			value: user.userId,
			label: `${user.firstName} ${user.lastName}`,
			email: user.email,
			profilePicture: user.profilePicture,
		}));
		setNurses(newOptions);
		return newOptions;
	};

	const getCareEventsByStatus = status => {
		switch (status) {
			case CareEventStatusType.IN_PROGRESS:
				return totalCareEvents?.totalInProgress;
			case CareEventStatusType.NEW:
				return totalCareEvents?.totalNew;
			case CareEventStatusType.COMPLETED:
				return totalCareEvents?.totalDone;
			case CareEventStatusType.MY_CARE_EVENTS:
				return totalCareEvents?.totalMine;
			default:
				return totalCareEvents?.totalInProgress + totalCareEvents?.totalNew + totalCareEvents?.totalDone;
		}
	};

	const transformArray = array =>
		array.map(item => ({
			value: item.id,
			label: `${item.firstName} ${item.lastName}`,
			mrn: item.mrn,
			profilePicture: item.profilePicture,
		}));

	const toggleViewHistory = careEvent => {
		setIsCareEventHistory(prevState => !prevState);
		setSelectedCareEvent(careEvent);
	};

	const handleClearAll = () => {
		setSelectedPatient(null);
		setDateRange({ from: fromDefault, to: toDefault });
		setSelectedLevel({ label: DeviceListLevel.HEALTH_SYSTEM, value: userSession.healthSystem.id });
		setSelectedEventType(null);
		setSelectedPriorityFilter(null);
		setSelectedStatusFilter(null);
		setSelectedNurse(null);
		setSelectedSLAFilter(null);
	};

	const handleUpdateCareEvents = () => {
		setShouldUpdateList(prevState => !prevState);
		setIsCareEventUpdated(false);
	};

	return (
		<MainLayout>
			<div className='patient-alerts-wrapper rpm full-width care-events-dashboard' onScroll={handleScroll}>
				<div>
					<div className='flex flex-space-between flex-align-center'>
						<div className='flex care-event-tabs'>
							{CareEventStatuses.map((careEvent, index) => (
								<div
									onClick={() => {
										setCurrentStatus(index);
										setPageIndex(0);
									}}
									key={careEvent}
									className={classNames(currentStatus === index ? 'active' : '', getCareEventTabClassName(index))}>
									{translate(careEvent)}
									<span className='counter'>{getCareEventsByStatus(careEvent)}</span>
								</div>
							))}
						</div>

						<div className='care-event-buttons' ref={filtersRef}>
							<div className='flex'>
								<div className='input-wrapper'>
									<input
										type='text'
										placeholder={intl.formatMessage({ id: 'searchPatientByMrn' })}
										value={searchedPatient}
										onChange={handlePatientSearch}
									/>
								</div>
								<Button
									className={classNames('filter-button', isFilterShown ? 'active' : '')}
									onClick={() => setIsFilterShown(prevState => !prevState)}
									imgIcon={`${healthCareCdnUrl}care-events/filter-icon.svg`}
								/>
								<CareEventsDropdown
									setIsCareEventDropdownShown={setIsCareEventDropdownShown}
									isCareEventDropdownShown={isCareEventDropdownShown}
									setIsCareEventModalOpen={setIsCareEventModalOpen}
									setCareEventTitle={setCareEventTitle}
									dropdownRef={dropdownRef}
									setCareEventId={setCareEventId}
									setIsFilterShown={setIsFilterShown}
									isDarkMode={isDarkMode}
								/>
							</div>

							<CareEventsFilters
								from={dateRange.from}
								to={dateRange.to}
								isFilterShown={isFilterShown}
								selectedPatient={selectedPatient}
								selectedEventType={selectedEventType}
								selectedSLAFilter={selectedSLAFilter}
								selectedPriorityFilter={selectedPriorityFilter}
								selectedNurse={selectedNurse}
								nurses={nurses}
								patients={transformArray(patients)}
								isPatientAdmitted={isPatientAdmitted}
								setSelectedLevel={setSelectedLevel}
								handleClearAll={handleClearAll}
								setError={setError}
								setDateRange={setDateRange}
								getNurses={getNurses}
								setPageIndex={setPageIndex}
								setHasFilterChanged={setHasFilterChanged}
								setSelectedPatient={setSelectedPatient}
								setSelectedEventType={setSelectedEventType}
								setSelectedSLAFilter={setSelectedSLAFilter}
								setSelectedStatusFilter={setSelectedStatusFilter}
								setSelectedPriorityFilter={setSelectedPriorityFilter}
								selectedStatusFilter={selectedStatusFilter}
								setSelectedNurse={setSelectedNurse}
								isDarkMode={isDarkMode}
							/>
						</div>
					</div>

					{isCareEventUpdated && (
						<div className='full-width care-events-updates'>
							<p>{translate('updateCareEvents')}</p>
							<button type='button' onClick={handleUpdateCareEvents}>
								<Retry />
								{translate('updateList')}
							</button>
						</div>
					)}

					<div className='patients-alert-table'>
						<div className='flex patients-alert-table-header care-events-table-header'>
							{CareEventsHeaders.map(item => (
								<div key={item} className='care-event-details'>
									<p className='font-14'>{translate(item)}</p>
								</div>
							))}
						</div>
						<div className='patients-alert-table-body care-events'>
							{careEventsDashboard.length > 0 && !isLoading && (
								<PatientsCareEvents
									nurses={nurses}
									careEventsDashboard={careEventsDashboard}
									getNurses={getNurses}
									handleChangeProp={handleChangeProp}
									toggleViewHistory={toggleViewHistory}
									setPatientDetails={setPatientDetails}
									setCountries={setCountries}
									setPreferredUnits={setPreferredUnits}
									setViewProfileModalVisibility={setViewProfileModalVisibility}
									setSelectedCountry={setSelectedCountry}
									setAlertsModalVisibility={setAlertsModalVisibility}
									setPatientAlerts={setPatientAlerts}
								/>
							)}
							{careEventsDashboard.length === 0 && !isLoading && (
								<div className='flex full-width flex-justify-center flex-align-center'>
									<p className='font-14'>{translate('noData')}</p>
								</div>
							)}
							{isLoading && (
								<div className='flex flex-justify-center'>
									<Loader />
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			{isCareEventModalOpen && (
				<AddCareEventModal
					isCareEventModalOpen={isCareEventModalOpen}
					setIsCareEventModalOpen={setIsCareEventModalOpen}
					careEventTitle={careEventTitle}
					careEventId={careEventId}
					setIsPatientAdmitted={setIsPatientAdmitted}
				/>
			)}
			{isNotesModalShown && (
				<CareEventNotes
					onModalClose={() => setIsNotesModalShown(false)}
					onModalSubmit={handleSubmitNote}
					selectedCareEvent={selectedCareEvent}
					selectedStatus={selectedStatus}
					isSpeechToText={isSpeechToText}
					liveTranscription={liveTranscription}
					recognizedTranscription={recognizedTranscription}
					setIsSpeechToText={setIsSpeechToText}
					setLiveTranscription={setLiveTranscription}
					setRecognizedTranscription={setRecognizedTranscription}
					selectedCareMember={selectedCareMember}
					selectedPriority={selectedPriority}
					isDarkMode={isDarkMode}
				/>
			)}
			{isCareEventHistory && (
				<CareEventHistory onModalClose={() => toggleViewHistory(null)} selectedCareEvent={selectedCareEvent} />
			)}
			<ViewPatientProfile
				display={isViewProfileModalVisible}
				onModalClose={() => {
					setViewProfileModalVisibility(false);
					setPatientDetails(null);
				}}
				onModalSubmit={null}
				setIsSecondTabEnabled={false}
				patientDetails={patientDetails?.profile}
				userId={patientDetails?.userId}
				setSelectedPatient={null}
				setIsPatientFormVisible={null}
				selectedPatientId={patientDetails?.id}
				unitPreferences={preferredUnits}
				countries={countries}
				selectedCountry={selectedCountry}
				isViewOnly={true}
			/>
			{patientAlerts && (
				<AlertsModal
					display={isAlertsModalVisible}
					isLoading={false}
					position='center'
					className='vsm-alerts-modal border-radius-modal-wrapper rpm-alerts-modal'
					onModalClose={() => setAlertsModalVisibility(false)}
					patientId={patientAlerts.healthCareUserId}
					isVitalSigns={false}
				/>
			)}
			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
		</MainLayout>
	);
};

export default CareEventsDashboard;
