import React from 'react';
import Grid from 'components/Grid.jsx';
import Avatar from 'components/Avatar';
import { ConferenceEndReasonMessage } from 'constants/enums.js';

const CallEndReason = props => (
	<div style={{ textAlign: 'center' }}>
		<Grid className='incoming-calls' columns='1fr' rows='1fr' horizAlign='center' vertAlign='center' stretch='100vh'>
			<div>
				<Avatar
					src={props.url ? props.url : 'https://maxcdn.solaborate.com/media/profile-pictures/duser.jpg'}
					size='medium'
					fullName=''
				/>
				{props.reason && (
					<p className='incoming-call-text'>{ConferenceEndReasonMessage[props.reason]}</p>
				)}

			</div>
		</Grid>
	</div>
);

export default CallEndReason;
