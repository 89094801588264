import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { RequestType } from 'constants/enums.js';
import { getMonthDayYearDateFormat } from 'infrastructure/helpers/dateHelper.js';
import { getAge, getGender, getRequestColor, getRequestType } from 'infrastructure/helpers/commonHelpers.js';
import { Checkbox } from 'components';
import ProfilePicture from 'components/ProfilePicture.jsx';
import { HelloIcon } from 'calls/icons/index.js';
import translate from 'i18n-translations/translate.jsx';

const QueuedPatientsTable = ({
	selectedHospitalId,
	patients,
	selectedPatientsIds,
	handleSelectedAllPatientsIds,
	handleSelectedPatientsIds,
	handlePatientTransfer,
}) => {
	const userSession = useSelector(state => state.user.userSession);
	const hasSelectedHospital = selectedHospitalId !== userSession.healthSystem.id;

	if (patients.length === 0) {
		return (
			<div className='inner-queue-modal queued-patients-list text-align-center'>
				<p className='no-data'>{translate('noData')}</p>
			</div>
		);
	}
	return (
		<div className='inner-queue-modal queued-patients-list'>
			<div className='select-all-queued-patients'>
				<Checkbox checked={selectedPatientsIds.length === patients.length} onChange={handleSelectedAllPatientsIds} />
				<p>{translate('selectAll')}</p>
			</div>
			{patients.map(patient => (
				<div
					className={classNames(
						'home-waiting-room-inner',
						patient.request.requestType === RequestType.RAPID ? 'rapid-request-type' : ''
					)}
					key={patient.id}>
					<div className='flex'>
						<Checkbox checked={selectedPatientsIds.includes(patient.id)} onChange={() => handleSelectedPatientsIds(patient.id)} />
						<ProfilePicture
							className='doctor-request-img patient-request-profile-img'
							firstName={patient.request.patient.firstName}
							lastName={patient.request.patient.lastName}
							profilePicture={patient.request.patient.profilepic}
						/>
						<div className='queue-patient-details'>
							<h4>
								{patient.request.patient.firstName} {patient.request.patient.lastName}
							</h4>
							<span className='--blue-color'>
								{getMonthDayYearDateFormat(patient.request.patient.dateOfBirth)} ({translate('age')}:{' '}
								{getAge(patient.request.patient.dateOfBirth)}), {getGender(patient.request.patient.genderId).description}
							</span>
						</div>
						<div className={classNames('request-type home-request-type', getRequestType(patient.request?.requestType))}>
							<HelloIcon color={getRequestColor(patient.request.requestType)} />
							<span>{translate(getRequestType(patient.request.requestType))}</span>
						</div>
					</div>
				</div>
			))}
			{hasSelectedHospital && (
				<button
					type='button'
					className='patient-transfer-btn'
					onClick={handlePatientTransfer}
					disabled={!selectedPatientsIds.length}>
					<i className='material-icons-outlined'>sync_alt</i>
					{translate('transferToDoctor')}
				</button>
			)}
		</div>
	);
};

export default QueuedPatientsTable;
