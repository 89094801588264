import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Modal, Form, Textarea } from 'components/index.js';
import translate from 'i18n-translations/translate.jsx';
import { UserRoles } from 'constants/enums.js';
import { capitalizeFirstLetter } from 'infrastructure/helpers/commonHelpers.js';
import { getUserRole } from 'infrastructure/auth.js';
import { getCareEvents } from 'api/teamConfigurationProfiles.js';
import SpeechToText from 'SpeechToText.jsx';

const CareEventsModal = props => {
	const intl = useIntl();
	const [careEvents, setCareEvents] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const userSession = useSelector(state => state.user.userSession);

	useEffect(() => {
		const getEvents = async () => {
			const response = await getCareEvents({
				pageSize: 10,
				pageIndex: 0,
				healthSystemId: userSession.healthSystem.id,
				teamCareEventType: props.eventType,
			});
			if (response.error) {
				setIsLoading(false);
				return;
			}
			setCareEvents(response?.teamCareEvents || []);
			setIsLoading(false);
		};
		getEvents();
	}, [props.eventType, userSession.healthSystem.id]);

	const [selectedAlertType, setSelectedAlertType] = useState(null);
	const [recognizedTranscription, setRecognizedTranscription] = useState('');
	const [liveTranscription, setLiveTranscription] = useState('');
	const [isSpeechToText, setIsSpeechToText] = useState(false);

	return (
		<Modal
			display={true}
			onModalSubmit={() => props.onForwardToNurses(selectedAlertType, recognizedTranscription)}
			primaryButtonLabel={getUserRole() !== UserRoles.VIRTUAL_SITTER ? translate('forwardToNurses') : ''}
			position='center'
			onModalClose={() => props.onReportClose()}
			shouldSubmitOnEnter={false}
			className='border-radius-modal-wrapper'
			isSubmitDisabled={!selectedAlertType}
			isLoading={props.isManualReportLoading || props.isForwardMSTeamsLoading || isLoading}
			isThirdButtonShown={true}
			thirdButtonLabel={translate('save')}
			onThirdButtonClick={() => props.onCareEventSave(selectedAlertType, recognizedTranscription)}
			primaryButtonVariant='white'>
			<Form
				height={550}
				className='modal-form-wrapper send-group-message add-care-events'
				onSubmit={event => event.preventDefault()}>
				<h3>{translate('typeOfCareEvent')}</h3>
				{careEvents.length > 0 && <p>{translate('selectCareEvent')}:</p>}
				{careEvents.length === 0 && (
					<>
						<p className='no-padding'>{translate('noCareEvents')}</p>
						<p className='no-padding'>{translate('askAdministratorForCareEvents')}</p>
					</>
				)}
				<div className='flex flex-wrap' style={{ marginTop: '20px' }}>
					{careEvents?.map(event => (
						<div
							key={event.id}
							className={selectedAlertType === event.id ? `pill-button selected-pill` : `pill-button`}
							onClick={() => setSelectedAlertType(event.id)}>
							<input type='radio' name='choice' checked={selectedAlertType === event.id} />
							<h5 className={selectedAlertType === event.id ? 'selected-pill' : ''}>{event.name}</h5>
						</div>
					))}
				</div>
				<p>{capitalizeFirstLetter(intl.formatMessage({ id: 'note' }))}</p>

				<Textarea
					disabled={!selectedAlertType || isSpeechToText || careEvents.length === 0}
					maxLength={400}
					onChange={event => setRecognizedTranscription(event.target.value)}
					value={isSpeechToText ? `${recognizedTranscription} ${liveTranscription}`.trim() : recognizedTranscription}
					maxNumberLimit={40}
					rows={10}
					name='text'
					placeholder={intl.formatMessage({ id: 'message' })}
					icon={
						<SpeechToText
							setRecognizedTranscription={setRecognizedTranscription}
							setLiveTranscription={setLiveTranscription}
							setIsSpeechToText={setIsSpeechToText}
							isSpeechToText={isSpeechToText}
							isDisabled={careEvents.length === 0 || !selectedAlertType}
						/>
					}
				/>
			</Form>
		</Modal>
	);
};

export default CareEventsModal;
