import * as React from 'react';

const Patch = props => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width={18}
		height={18}
		fill='none'
		stroke={props.strokeColor}
		strokeLinecap='round'
		strokeLinejoin='round'
		strokeWidth={1.5}
		viewBox='0 0 24 24'
		{...props}>
		<path d='M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z' />
	</svg>
);
export default Patch;
