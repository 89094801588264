import React from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import translate from 'i18n-translations/translate.jsx';
import { TestTypes, ParenteralTherapyType } from 'constants/visitEnums.js';
import { primaryCareImageUrl, healthCareCdnUrl } from 'constants/global-variables.js';
import { forms } from 'containers/PrimaryCare/LaboratoryAnalysis/constants/labForms.js';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';
import { MeasurementTypes, MeasurementUnits } from 'constants/enums.js';
import Textarea from 'components/Textarea.jsx';

const VisitSummary = (props) => {
    const intl = useIntl();
    const linkToHealthDataIcons = `${healthCareCdnUrl}health-data/`;

    const reducedVisitsData = () => {
        const reducedVisits = props.currentCase?.visits.reduce((acc, visit) => {
            if (visit.medicalVisitTypeId === 2 && visit.id !== props.currentCase?.activeVisit.id) {
                acc.push({
                    mainConcerns: visit.mainConcerns,
                    recommendations: visit.recommendations,
                    examinations: visit.examinations,
                    anamnesis: visit.anamnesis,
                    visitDate: visit.visitDate,
                    performerName: `${visit.performer.firstName}  ${visit.performer.lastName}`,
                    place: visit.place,
                    measurementsForVisit: visit.measurementsForVisit
                });
            }
            return acc;
        }, []);
        return reducedVisits;
    }

    const getSignValue = (measurementType) => props.vitalSignsToShow.find(sign => sign.enum === measurementType).img

    return (
        <div className='returned-report'>
            <div className='previous-diagnoses'>
                {props.previousDiagnoses.length > 0 && (
                    <>
                        <p>{translate('previousDiagnoses')}</p>
                        <div className='full-width results-accordion-content'>
                            {props.previousDiagnoses.map(item => (
                                <span className='diagnosis-badge'>{item}</span>
                            ))}
                        </div>
                    </>
                )}
            </div>
            {props?.labCaseAnalyses?.length > 0 && (
                <div>
                    <p>{translate('returnReportFromLab')}</p>
                    <div className='full-width results-accordion-content'>
                        <div />
                        {props.labCaseAnalyses.map(analyses => {
                            const selectedAnalysis = forms(intl).find(formItem => formItem.id === analyses.testType);
                            return (
                                <React.Fragment key={selectedAnalysis.id}>
                                    <div className='flex flex-align-center'>
                                        <div>
                                            <img src={`${primaryCareImageUrl}${selectedAnalysis.icon}`} alt='icon' />
                                        </div>
                                        <div>
                                            <p className='no-margin'>{selectedAnalysis.description}</p>
                                        </div>
                                    </div>
                                    <div
                                        className={classNames(
                                            'flex flex-wrap',
                                            selectedAnalysis.id === TestTypes.URINE ? 'urine-results' : ''
                                        )}>
                                        {analyses.attachments.map(sub => {
                                            const selectedResult = selectedAnalysis.results.find(resultItem => resultItem.id === sub.typeId);
                                            return (
                                                <div className='flex flex-align-center' style={{ marginTop: '5px' }}>
                                                    <p className='flex-1 no-margin'>{selectedResult.description}</p>
                                                    {selectedAnalysis.id === TestTypes.URINE && (
                                                        <textarea
                                                            name={selectedResult.description}
                                                            value={sub.value}
                                                            readOnly
                                                            placeholder={intl.formatMessage({ id: 'description' })}
                                                            rows={selectedResult.isTextarea ? 2 : 1}
                                                        />
                                                    )}
                                                    {selectedAnalysis.id !== TestTypes.URINE && (
                                                        <>
                                                            <input name={selectedResult.description} type='text' value={sub.value} readOnly />
                                                            <span>{selectedResult.unit || ''}</span>
                                                        </>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </React.Fragment>
                            );
                        })}
                    </div>
                </div>
            )}
            {props.radiologyCaseTests.length > 0 && (
                <div>
                    <p>{translate('returnReportFromRadiology')}</p>
                    <div className='full-width radiology-note'>
                        {props.radiologyCaseTests.map(note => (
                            <Textarea readOnly name='recommendations' value={note.text} />
                        ))}
                    </div>
                </div>
            )}

            {props.previousParentalTherapy().length > 0 && (
                <div>
                    <p>{translate('returnReportFromParentalTherapy')}</p>
                    <div className='full-width'>
                        {props.previousParentalTherapy().map(therapy => (
                            <div className='top-15 lab-visit-content results-accordion-content'>
                                <h4 className='no-margin no-padding'>
                                    {translate(
                                        therapy.parenteralTherapy.type === ParenteralTherapyType.INFUSION ? 'infusionTherapy' : 'injectionTherapy'
                                    )}
                                </h4>
                                <div className='flex flex-wrap parenteral-therapy-box'>
                                    {therapy.parenteralTherapy.medications?.map(medication => (
                                        <div>
                                            <p className='no-margin no-padding'>{medication.name}</p>
                                            <p className='font-12'>
                                                {translate('dosage')}: {medication.dosage}ml, {translate('duration')}: {medication.duration}
                                                {translate('durationDays')}, {translate('frequency')}: {medication.frequency}
                                                {translate('timesADay')}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            {reducedVisitsData().length > 0 && (
                <div>
                    <p>{translate('visitSummaries')}</p>
                    {reducedVisitsData().map(visit => (
                        <div className='visit-accordion-content descriptions'>
                            <div className='des-title'>
                                <p>{`${visit.performerName} > ${visit.place}`} </p>
                                <p>{formattedDate(visit.visitDate)}</p>
                            </div>
                            <div className='des-content'>
                                {visit.measurementsForVisit.length > 0 &&
                                    <div className='visit-vital-signs'>
                                        <div className='flex flex-space-between'>
                                            <h3>{translate('vitalSigns')}</h3>
                                        </div>
                                        <div className='full-width flex flex-wrap'>
                                            {visit.measurementsForVisit.map(sign => (
                                                <div key={sign.measurementValue} className='flex'>
                                                    <img src={`${linkToHealthDataIcons}${getSignValue(sign.measurementType)}`} alt='icon' />
                                                    <p className='flex-1'>{translate(sign.measurementType)}</p>
                                                    {sign.measurementValue && (
                                                        <h3>
                                                            {sign.measurementValue}
                                                            <span>{sign.measurementType === MeasurementTypes.BLOOD_PRESSURE ? MeasurementUnits.MMHG : sign.measurementUnit}</span>
                                                        </h3>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>}
                                <Textarea readOnly name='anamnesis' label={intl.formatMessage({ id: 'anamnesis' })} value={visit.anamnesis} />
                                {visit.recommendations && visit.recommendations.length > 0 && <Textarea readOnly name='recommendations' label={intl.formatMessage({ id: 'recommendations' })} value={visit.recommendations} />}
                                <Textarea readOnly name='examinations' label={intl.formatMessage({ id: 'examinations' })} value={visit.examinations} />
                                <Textarea readOnly name='mainConcerns' label={intl.formatMessage({ id: 'mainConcerns' })} value={visit.mainConcerns} />
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default VisitSummary;