import React, { useEffect, useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Device } from '@twilio/voice-sdk';
import axios from 'axios';
import translate from 'i18n-translations/translate.jsx';
import CallDuration from 'containers/CallDuration.jsx';

const EmergencyCall = props => {
	const [callStartTime, setCallStartTime] = useState(null);
	const [emergencyCallStarted, setEmergencyCallStarted] = useState(false);
	const [endedCall, setEndedCall] = useState(false);
	const deviceNumber = '+12134258514';

	const emergencyCallDevice = useRef(null);

	const intl = useIntl();

	useEffect(() => {
		const startEmergencyCall = async () => {
			const response = await axios.get('/api/twilio/token');
			const device = new Device(response.data.token, {
				// @ts-ignore
				codecPreferences: ['opus', 'pcmu'],
				fakeLocalDTMF: true,
				enableRingingState: true,
				debug: true,
			});
			emergencyCallDevice.current = device;
			const call = await device.connect({ params: { To: deviceNumber } });

			call.on('accept', () => {
				setEmergencyCallStarted(true);
				setCallStartTime(new Date());
			});

			call.on('disconnect', () => {
				setTimeout(props.onCallEnded, 3000);
				setEndedCall(true);
			});
		};
		startEmergencyCall();

		return () => {
			if (emergencyCallDevice.current) {
				emergencyCallDevice.current.disconnectAll();
			}
		};
	}, [props.onCallEnded]);

	return (
		<div className='observers-popup'>
			<div className='flex'>
				<p className='emergency-call-title'>{translate('emergencyCall')}</p>
			</div>
			{endedCall && <p>{translate('callEnded')}</p>}
			{!emergencyCallStarted && !endedCall && <p>{translate('calling')} 911</p>}
			<div className='nurse-call-notification'>
				<button
					type='button'
					data-tooltip={intl.formatMessage({ id: 'hangUp' })}
					data-position='left'
					id='end-call-icon-observers'
					className='call-button'
					onClick={props.onCallEnded}
				/>
			</div>
			{emergencyCallStarted && !endedCall && <CallDuration callStartTime={callStartTime} />}
		</div>
	);
};

export default EmergencyCall;
