import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import translate from 'i18n-translations/translate.jsx';
import ProfilePicture from 'components/ProfilePicture.jsx';
import Alert from 'components/Alert.jsx';
import { capitalizeFirstLetter, getPreferredLanguageLocale } from 'infrastructure/helpers/commonHelpers.js';
import Pagination from 'components/Common/Pagination.jsx';
import Table from 'components/Table.jsx';
import moment from 'moment';
import { useHistory } from 'react-router';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import HeaderInfo from 'components/DashboardCharts/PatientCentric/HeaderInfo.jsx';

const SessionDetails = () => {
	const [showPatient, setShowPatient] = useState(false);
	const [error, setError] = useState(null);
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0, totalCount: 20 });
	const intl = useIntl();
	const history = useHistory();

	const arr = [
		{
			intervention: 'Stat Alarm',
			actionTaken: 'Turn on alarm',
			note: 'Nothing',
			time: new Date('06/02/2024').toDateString(),
			interventedBy: 'Sami ballabani',
		},
		{
			intervention: 'Stat Alarm',
			actionTaken: 'Turn on alarm',
			note: 'Nothing',
			time: new Date('06/02/2024').toDateString(),
			interventedBy: 'Sami ballabani',
		},
	];

	const displayInteractions = () => {
		if (!arr || arr.length === 0) {
			return [];
		}
		return arr.map(item => ({
			intervention: item.intervention,
			actionTaken: item.actionTaken,
			note: moment.utc(item.startTime).local().locale(getPreferredLanguageLocale()).format('lll'),
			time: moment.utc(item.endTime).local().locale(getPreferredLanguageLocale()).format('lll'),
			interventedBy: (
				<div className='flex'>
					<ProfilePicture fullName={'Ismet Imeri'} className='provider-intervening-img-wrapper' />
					<p style={{ margin: 'var(--spacing-s)', padding: '0' }}>{item.interventedBy}</p>
				</div>
			),
		}));
	};

	return (
		<MainLayout>
			<div className='main-view'>
				<HeaderInfo
					patient={{
						name: 'Nolan Gabon',
						url: 'https://maxcdn.dev.solaborate.com/media/profile-pictures/50/50/9e7f75f5-6b48-4cce-b9c5-2c7136b26b09.jpeg',
					}}
					goBack={() => history.goBack()}
					sections={[
						{
							id: 0,
							labels: [intl.formatMessage({ id: 'startTime' }), intl.formatMessage({ id: 'endTime' })],
							values: [new Date('06/02/2024').toDateString(), new Date('06/013/2024').toDateString()],
						},
						{
							id: 1,
							labels: [intl.formatMessage({ id: 'duration' }), intl.formatMessage({ id: 'type' })],
							values: ['37 min', 'Monitoring'],
						},
						{
							id: 2,
							labels: [intl.formatMessage({ id: 'patient' }), 'MRN'],
							values: ['Nolan Omar', '332312321'],
						},
						{
							id: 3,
							labels: [intl.formatMessage({ id: 'gender' }), intl.formatMessage({ id: 'age' })],
							values: ['Male', '43'],
						},
					]}
					hideProfile={
						<div
							style={{ alignItems: 'center' }}
							className='info-value flex cursor-pointer'
							onClick={() => setShowPatient(prevState => !prevState)}>
							<span className='font-14'>{translate(showPatient ? 'hidePatientProfile' : 'showPatientProfile')}</span>
							<i className='material-icons'>{showPatient ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}</i>
						</div>
					}
				/>
				{showPatient && (
					<HeaderInfo
						patient={{
							name: 'Alb Imeri',
							url: null,
						}}
						sections={[
							{
								id: 0,
								labels: ['MRN', intl.formatMessage({ id: 'age' }), `RN - ${intl.formatMessage({ id: 'nameNumber' })}`],
								values: ['15551223', '39', 'Harper 321'],
							},
							{
								id: 1,
								labels: [
									`PCT - ${intl.formatMessage({ id: 'nameNumber' })}`,
									`TL - ${intl.formatMessage({ id: 'nameNumber' })}`,
									intl.formatMessage({ id: 'primaryAdverseEvent' }),
								],
								values: ['Owen 53113', 'Jim 4311', 'Fatigue'],
							},
							{
								id: 2,
								labels: [
									intl.formatMessage({ id: 'primaryPatientCondition' }),
									intl.formatMessage({ id: 'primaryAdverseEvent' }),
									intl.formatMessage({ id: 'additionalTitleBarNotes' }),
								],
								values: ['Headache and mild fever', 'YES', 'Note one'],
							},
						]}
					/>
				)}
				<div className='padding-sl '>
					<Table
						headers={[
							{ title: translate('intervention'), id: 0 },
							{ title: translate('actionTaken'), id: 1 },
							{ title: capitalizeFirstLetter(intl.formatMessage({ id: 'note' })), id: 2 },
							{ title: translate('time'), id: 3 },
							{ title: translate('interventedBy'), id: 4 },
						]}
						rows={displayInteractions()}
						isEditable={false}
					/>
					<div className='pagination-bar'>
						<Pagination
							totalCount={pagination.totalCount}
							pageSize={pagination.pageSize}
							pageIndex={pagination.pageIndex}
							onChange={(size, index) => setPagination(prevState => ({ ...prevState, pageIndex: index, pageSize: size }))}
						/>
					</div>
					<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
				</div>
			</div>
		</MainLayout>
	);
};

export default SessionDetails;
