import React, { useMemo } from 'react';
import Grid from 'components/Grid.jsx';
import Loader from 'components/Loader.jsx';
import SemiPieProgressBar from 'components/Common/Charts/SemiPieProgressBar.jsx';

const FleetProgressBar = ({ chartData, isLoading }) =>
	useMemo(() => {
		return (
			<div className='fleet-progressbar'>
				<label>{chartData.chartTitle}</label>
				{isLoading && (
					<Grid columns='1fr' rows='1fr' stretch='100%' horizAlign='center' vertAlign='center'>
						<Loader />
					</Grid>
				)}
				{!isLoading && chartData && (
					<SemiPieProgressBar
						options={chartData.progressData ?? []}
						totalProgressTitle={chartData.totalProgressTitle}
						totalProgress={chartData.totalProgress}
					/>
				)}
			</div>
		);
	}, [isLoading, chartData]);

export default FleetProgressBar;
