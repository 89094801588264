import React from 'react';

const PatientWalking = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none' viewBox='0 0 24 24' {...props}>
		<path
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth='1.25'
			d='M13.203 4.929a1.38 1.38 0 10-1.953-1.953 1.38 1.38 0 001.953 1.953zM13.39 9.73c.13.293.475.74.626.87.36.306 1.132.803 1.604 1.05.344.18.754.131.975-.188a.722.722 0 00-.112-.934l-1.688-1.236c-.188-.344-.285-.703-.447-1.059-.245-.54-.467-1.35-1.214-1.576-.331-.1-.468-.137-.814-.144-.333-.007-.669.002-.991.083-.862.218-1.453 1.028-1.953 1.686-.172.272-.41.51-.508.822L8 12.457v.002a.659.659 0 00.596.652c.346.028.698-.232.72-.579l.845-2.818 1.164-1.377M10.703 13.983c.03 1.063-.053 1.422-.44 2.746l-.986 3.489c-.152.457-.016 1.008.482 1.144.562.167.848.075 1.06-.563l1.21-3.159.359-1.214'
		/>
		<path
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeWidth='1.25'
			d='M10.675 9.163l-.21 2.306-.021.975a3.827 3.827 0 00.75 2.356l1.794 2.427 1.027 3.979c.098.494.39.844.925.789.535-.056.869-.434.724-.999l-.886-4.642a.807.807 0 00-.045-.116l-1.56-3.49.232-4.424'
		/>
	</svg>
);

export default PatientWalking;
