import React, { useState, Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { capitalizeFirstLetter, getBaseUrl, checkIfSectorNameExists } from 'infrastructure/helpers/commonHelpers.js';
import { getUserRole } from 'infrastructure/auth.js';
import { KeyCodes, SectorTypes, TreeHierarchyType, UserRoles } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import { useSelector } from 'react-redux';
import _ from 'lodash';

const OptionName = ({ option }) => {
	const busyDevices = useSelector(state => state.devices.busyDevices);
	const onlineDevices = useSelector(state => state.devices.onlineDevices);
	const intl = useIntl();
	const isDeviceBusy = () => {
		if (!option.helloDeviceId) {
			return false;
		}
		return busyDevices.some(id => id === option.helloDeviceId);
	};
	const isDeviceOnline = () => {
		if (!option.helloDeviceId) {
			return false;
		}
		return onlineDevices.some(id => id === option.helloDeviceId);
	};
	return (
		<>
			<div className='flex flex-align-center'>
				<span>{option.name}</span>

				{option.helloDeviceId && (
					<i className={classNames('material-icons-round', 'room-status', isDeviceOnline() ? 'online' : 'offline')}>
						fiber_manual_record
					</i>
				)}
				{isDeviceBusy() && (
					<i
						className={classNames('material-icons-round', 'room-status', 'on-call')}
						data-tooltip={intl.formatMessage({ id: 'thisDeviceIsOnACall' })}
						data-position='left'>
						fiber_manual_record
					</i>
				)}
			</div>
		</>
	);
};

const OptionsList = ({
	options,
	expandedOptions,
	onChange,
	onAdd,
	onLinkClick,
	onAddDevice,
	isMonitoring,
	isAlertCenter,
	alertFeeds = [],
	selectedSectorId,
	setSelectedId,
	expandAll,
	isReassign,
	isCareEvents,
}) => {
	const [isValidationShown, setIsValidationShown] = useState(false);
	const [isLoadingCreateSector, setIsLoadingCreateSector] = useState(false);
	const intl = useIntl();
	const monitoredDevices = useSelector(state => state.healthSystems.monitoredDevices);
	const treeData = useSelector(state => state.healthSystems.treeData.tree);
	const findProperId = item => item[`${item.type}Id`];
	const childrenHidden = option => option.subOptions.every(item => item.hidden === true);
	const childrenMatchSearch = option => option.subOptions.some(item => item.matchesSearch === true);
	const allHealthSystems = useSelector(state => state.healthSystems.allHealthSystems);
	const userSession = useSelector(state => state.user.userSession);
	const [sectorExistsMessage, setSectorExistsMessage] = useState(null);
	const treeHierarchyTypeId = allHealthSystems.find(item => item.id === userSession.healthSystem.id)?.treeHierarchyTypeId;
	const privacyDevices = useSelector(state => state.devices.privacyDevices);

	const getHierarchyType = () => {
		switch (treeHierarchyTypeId) {
			case TreeHierarchyType.DEFAULT_TREE:
			case TreeHierarchyType.HOSPITAL_DEPT_FLOOR_ROOM:
				return {
					hospital: {
						subtype: 'department',
						icon: 'account_balance',
					},
					department: {
						subtype: 'floor',
						icon: 'money',
					},
					floor: {
						subtype: 'room',
						icon: 'meeting_room',
					},
				};
			case TreeHierarchyType.HOSPITAL_DEPT_ROOM:
				return {
					hospital: {
						subtype: 'department',
						icon: 'account_balance',
					},
					department: {
						subtype: 'room',
						icon: 'meeting_room',
					},
				};
			case TreeHierarchyType.HOSPITAL_FLOOR_ROOM:
				return {
					hospital: {
						subtype: 'floor',
						icon: 'money',
					},
					floor: {
						subtype: 'room',
						icon: 'meeting_room',
					},
				};
			case TreeHierarchyType.HOSPITAL_ROOM:
				return {
					hospital: {
						subtype: 'room',
						icon: 'meeting_room',
					},
				};
			default:
				return {
					hospital: {
						subtype: 'department',
						icon: 'account_balance',
					},
					department: {
						subtype: 'floor',
						icon: 'money',
					},
					floor: {
						subtype: 'room',
						icon: 'meeting_room',
					},
				};
		}
	};

	const toggleOptions = option => {
		if ((childrenHidden(option) && !childrenMatchSearch(option)) || !childrenMatchSearch(option)) {
			option.subOptions.forEach(item => {
				// eslint-disable-next-line no-param-reassign
				item.hidden = !item.hidden;
			});
		} else {
			option.subOptions
				.filter(i => i.matchesSearch)
				.forEach(item => {
					// eslint-disable-next-line no-param-reassign
					item.hidden = !item.hidden;
				});
		}
	};

	const toggleExpand = (selectedOptionId, isAddingSection, option) => {
		if (expandedOptions[selectedOptionId] && !isAddingSection) {
			// eslint-disable-next-line no-param-reassign
			if (option?.subOptions[option.subOptions.length - 1]?.isNewOption) {
				option.subOptions.pop();
			}
			// eslint-disable-next-line no-param-reassign
			delete expandedOptions[selectedOptionId];
		} else {
			// eslint-disable-next-line no-param-reassign
			expandedOptions[selectedOptionId] = {};
		}
		if (expandAll) {
			toggleOptions(option);
		}
		onChange(expandedOptions);
	};

	const addNewOptionClick = selection => {
		const sectors = getHierarchyType();
		if (isMonitoring && selection.isMonitoring) {
			return;
		}
		if (isAlertCenter && alertFeeds.find(item => +item.deviceId === selection.helloDeviceId)) {
			return;
		}
		if (selection.type === SectorTypes.ROOM) {
			onAddDevice(selection);
			return;
		}

		selection.subOptions.push({
			...selection,
			icon: sectors[selection.type].icon,
			type: sectors[selection.type].subtype,
			isNewOption: true,
			subOptions: [],
		});
		handleSubOptionsListChange();
		toggleExpand(findProperId(selection), true, selection);
	};

	const addNewOptionOnEnter = async (event, selection) => {
		const newSelection = { ...selection };
		if (
			(event.which === KeyCodes.ENTER && event.target.value?.trim() && !isLoadingCreateSector) ||
			(event.which === KeyCodes.ENTER && event.target.value?.trim() && isReassign)
		) {
			const isValid = checkIfSectorNameExists(newSelection, treeData, event.target.value);
			if (!isValid) {
				setSectorExistsMessage(translate('sectorExistsMessage', { value: intl.formatMessage({ id: newSelection.type }) }));
				return;
			}
			setSectorExistsMessage(null);
			setIsLoadingCreateSector(true);
			newSelection.isNewOption = false;
			newSelection.name = event.target.value;
			newSelection.subOptions = [];
			newSelection.tooltip = `add${capitalizeFirstLetter(selection.type)}`;
			newSelection.isCreatingSector = true;
			handleSubOptionsListChange();
			if (!isMonitoring && !isAlertCenter) {
				setIsValidationShown(false);
			}
			await onAdd(newSelection);
			setIsLoadingCreateSector(false);
		}
		if (event.which === KeyCodes.ENTER && !event.target.value?.trim()) {
			setIsValidationShown(true);
		}
	};

	const handleSubOptionsListChange = (optionId, subSelections) => {
		// eslint-disable-next-line no-param-reassign
		expandedOptions[optionId] = subSelections;
		onChange(expandedOptions);
	};

	const selectOption = (event, option) => {
		const newOption = { ...option };
		setSelectedId(newOption[`${option.type}Id`]);
		newOption.isSelected = true;
		if (onLinkClick) {
			onLinkClick(option, event);
		}
		toggleExpand(findProperId(option), !isReassign, option);
	};

	const treeListOnModal = option => {
		return (
			<div
				className='tree-option-wrapper'
				onClick={() => {
					toggleExpand(findProperId(option), false, option);
				}}>
				<i className={classNames('material-icons-outlined', option.type === SectorTypes.ROOM ? 'hide' : '')}>
					{expandedOptions[findProperId(option)] || (expandAll && !childrenHidden(option))
						? 'keyboard_arrow_down'
						: 'keyboard_arrow_right'}
				</i>
				{option.imgIcon && option[`${option.type}Id`] !== selectedSectorId && <img src={option.imgIcon} alt='icon' />}
				{option.imgIcon && option[`${option.type}Id`] === selectedSectorId && <img src={option.activeImgIcon} alt='icon' />}
				{!option.imgIcon && <i className='material-icons-outlined'>{option.icon}</i>}
				{!!option?.link && !isMonitoring && !isAlertCenter && (
					<Link
						to={`${getBaseUrl()}/${option.link}`}
						className='link'
						onClick={event => {
							selectOption(event, option);
						}}>
						<OptionName option={option} />
					</Link>
				)}
				{!(!!option?.link && !isMonitoring && !isAlertCenter) && (
					<span
						className='option link cursor-pointer'
						onClick={event => {
							selectOption(event, option);
						}}>
						<OptionName option={option} />
					</span>
				)}
			</div>
		);
	};
	const treeListOnMenu = option => {
		return (
			<>
				<i
					className={classNames('material-icons-outlined', option.type === SectorTypes.ROOM ? 'hide' : '')}
					onClick={() => {
						toggleExpand(findProperId(option), false, option);
					}}>
					{expandedOptions[findProperId(option)] || (expandAll && !childrenHidden(option))
						? 'keyboard_arrow_down'
						: 'keyboard_arrow_right'}
				</i>
				{option.imgIcon && option[`${option.type}Id`] !== selectedSectorId && <img src={option.imgIcon} alt='icon' />}
				{option.imgIcon && option[`${option.type}Id`] === selectedSectorId && <img src={option.activeImgIcon} alt='icon' />}
				{!option.imgIcon && <i className='material-icons-outlined'>{option.icon}</i>}
				{!!option?.link && !isMonitoring && !isAlertCenter && !isReassign && !isCareEvents && (
					<Link
						to={`${getBaseUrl()}/${option.link}`}
						className='link'
						onClick={event => {
							selectOption(event, option);
						}}>
						<OptionName option={option} />
					</Link>
				)}
				{!(!!option?.link && !isMonitoring && !isAlertCenter && !isReassign) && (
					<span
						className='option link cursor-pointer'
						onClick={event => {
							selectOption(event, option);
						}}>
						<OptionName option={option} />
					</span>
				)}
				{!!option?.link && !isMonitoring && !isReassign && isCareEvents && (
					<span
						className='link cursor-pointer'
						onClick={event => {
							selectOption(event, option);
						}}>
						<OptionName option={option} />
					</span>
				)}
			</>
		);
	};

	const isPrivacyOn = helloDeviceId => {
		if (!helloDeviceId) {
			return false;
		}
		return privacyDevices.some(id => id === helloDeviceId);
	};

	return (
		<div>
			{options.map(option => (
				<div
					key={option[`${option.type}Id`]}
					className={classNames('tree', expandAll ? 'search-mode' : '', !option.name && !option.isNewOption ? 'hidden' : '')}>
					<div
						className={classNames(
							'tree__child sector-name',
							option[`${option.type}Id`] === selectedSectorId ? 'selected' : '',
							option.hidden && expandAll ? 'hidden' : ''
						)}
						data-cy='healthSystemTree'>
						{!!option.isNewOption && (
							<>
								<div className='tree__new'>
									<i className='material-icons-outlined'>{option.icon}</i>
									<input
										onKeyUp={event => {
											addNewOptionOnEnter(event, option);
										}}
										type='text'
										placeholder={intl.formatMessage({ id: `add${capitalizeFirstLetter(option.type)}` })}
										maxLength={127}
									/>
								</div>
								<span className='red-error'>{sectorExistsMessage}</span>
								{isValidationShown && <span className='red-error'>{translate('sectorIsRequired')}</span>}
							</>
						)}
						{!option.isNewOption && (
							<div>
								{isReassign ? treeListOnModal(option) : treeListOnMenu(option)}
								{isPrivacyOn(option.helloDeviceId) && (
									<div
										className='monitoring-privacy-mode-icon'
										data-tooltip={intl.formatMessage({ id: 'privacyModeIsOn' })}
										data-position='left'>
										<img
											alt='Privacy on'
											className='privacy-mode-tree-view'
											src={`${healthCareCdnUrl}privacy-mode/privacy.svg?v3`}
										/>
									</div>
								)}
								{option.tooltip && !option.helloDeviceId && [UserRoles.ADMIN, UserRoles.SUPER_USER].includes(getUserRole()) && (
									<span
										className='option action'
										onClick={_.debounce(() => addNewOptionClick(option), 500)}
										data-tooltip={intl.formatMessage({ id: option.tooltip })}
										data-position='left'>
										<i
											className='material-icons-outlined'
											style={{ color: monitoredDevices?.initiatedDevices.includes(option.helloDeviceId) ? '#4cd137' : '' }}>
											add_box
										</i>
									</span>
								)}
								{isMonitoring &&
									option.tooltip &&
									option.helloDeviceId &&
									UserRoles.ADMIN !== getUserRole() &&
									option.isHelloDevice && (
										<span
											className='option action'
											onClick={_.debounce(() => addNewOptionClick(option), 500)}
											data-tooltip={intl.formatMessage({ id: option.tooltip })}
											data-position='left'>
											<i
												className='material-icons-outlined'
												style={{
													color: monitoredDevices?.initiatedDevices.includes(option.helloDeviceId) ? '#4cd137' : '',
												}}>
												{monitoredDevices?.initiatedDevices.includes(option.helloDeviceId) ? 'check_circle' : 'add_box'}
											</i>
										</span>
									)}
								{isAlertCenter &&
									option.tooltip &&
									option.helloDeviceId &&
									UserRoles.ADMIN !== getUserRole() &&
									option.isHelloDevice && (
										<span
											className='option action'
											onClick={_.debounce(() => addNewOptionClick(option), 500)}
											data-tooltip={intl.formatMessage({ id: option.tooltip })}
											data-position='left'>
											<i
												className='material-icons-outlined'
												style={{ color: alertFeeds.find(item => +item.deviceId === option.helloDeviceId) ? '#4cd137' : '' }}>
												{alertFeeds.find(item => +item.deviceId === option.helloDeviceId) ? 'check_circle' : 'add_box'}
											</i>
										</span>
									)}

								{isReassign && option.type === SectorTypes.ROOM && !option.helloDeviceId && (
									<span
										className='option action'
										onClick={_.debounce(() => addNewOptionClick(option), 500)}
										data-tooltip={intl.formatMessage({ id: 'reAssignDevice' })}
										data-position='left'>
										<i className='material-icons-outlined'>add_box</i>
									</span>
								)}
							</div>
						)}
					</div>
					{option.subOptions && expandAll && (
						<OptionsList
							options={option.subOptions}
							expandedOptions={expandedOptions}
							onChange={subSelections => handleSubOptionsListChange(findProperId(option), subSelections)}
							onAdd={onAdd}
							onLinkClick={onLinkClick}
							onAddDevice={onAddDevice}
							isMonitoring={isMonitoring}
							isAlertCenter={isAlertCenter}
							alertFeeds={alertFeeds}
							selectedSectorId={selectedSectorId}
							setSelectedId={setSelectedId}
							expandAll={expandAll}
							isReassign={isReassign}
							isCareEvents={isCareEvents}
						/>
					)}
					{option.subOptions && expandedOptions[findProperId(option)] && !expandAll && (
						<OptionsList
							options={option.subOptions}
							expandedOptions={expandedOptions ? expandedOptions[findProperId(option)] : {}}
							onChange={subSelections => handleSubOptionsListChange(findProperId(option), subSelections)}
							onAdd={onAdd}
							onLinkClick={onLinkClick}
							onAddDevice={onAddDevice}
							isMonitoring={isMonitoring}
							isAlertCenter={isAlertCenter}
							alertFeeds={alertFeeds}
							selectedSectorId={selectedSectorId}
							setSelectedId={setSelectedId}
							expandAll={expandAll}
							isReassign={isReassign}
							isCareEvents={isCareEvents}
						/>
					)}
				</div>
			))}
		</div>
	);
};

class TreeView extends Component {
	state = {
		selectedSectorId: this.props.selectedSectorId,
		expandedOptions: this.props.preSelected ? this.props.preSelected : {},
	};

	setSelectedId = sectorId => {
		this.setState({ selectedSectorId: sectorId });
	};

	render() {
		const { selectedSectorId } = this.state;
		return (
			<OptionsList
				onAdd={this.props.onAdd}
				onAddDevice={this.props.onAddDevice}
				onLinkClick={this.props.onLinkClick}
				options={this.props.data}
				onChange={expandedOptions => {
					this.setState({ expandedOptions });
				}}
				expandedOptions={this.state.expandedOptions}
				isMonitoring={this.props.isMonitoring}
				isAlertCenter={this.props.isAlertCenter}
				alertFeeds={this.props.alertFeeds}
				selectedSectorId={selectedSectorId}
				setSelectedId={this.setSelectedId}
				expandAll={this.props.expandAll}
				isReassign={this.props.isReassign}
				isCareEvents={this.props.isCareEvents}
			/>
		);
	}
}
export default TreeView;
