import React, { useEffect, useState } from 'react';
import translate from 'i18n-translations/translate.jsx';
import { Alert, Button, Form, Input, Modal, Table } from 'components/index.js';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import * as Yup from 'yup';
import Pagination from 'components/Common/Pagination.jsx';
import ProfilePicture from 'components/ProfilePicture.jsx';
import { useIntl } from 'react-intl';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import MultiSelect from 'components/Common/FormElements/MultiSelect.jsx';
import { Formik } from 'formik';
import { checkIfCompanyMemberExists } from 'api/users.js';
import { DeviceSubscriptionType, KeyCodes } from 'constants/enums.js';
import { addOrEditDeviceAlert, deleteDeviceAlert, getDeviceAlertsList } from 'api/fleetManagement.js';
import { monthDayYear, getFormattedHours } from 'infrastructure/helpers/dateHelper.js';
import { isValidEmail } from 'infrastructure/helpers/validationHelper.js';

const DeviceAlerts = props => {
	const intl = useIntl();
	const userSession = useSelector(state => state.user.userSession);
	const allHealthSystems = useSelector(state => state.healthSystems.allHealthSystems);
	const healthSystemsArr = allHealthSystems.map(hs => ({ value: hs.id, label: hs.name }));
	const initialHs = {
		value: userSession.healthSystem.id,
		label: userSession.healthSystem.name,
	};
	const initialSubscriptionData = {
		deviceIsOffline: true,
		healthSystems: [initialHs],
		emails: [],
		email: '',
	};
	const [isListLoading, setIsListLoading] = useState(true);
	const [totalCount, setTotalCount] = useState(0);
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0 });
	const [isSetDeviceAlertOpen, setIsSetDeviceAlertOpen] = useState(false);
	const [error, setError] = useState(null);
	const [deviceReportSubscriptions, setDeviceReportSubscriptions] = useState([]);
	const [addAlertModalLoading, setAddAlertModalLoading] = useState(false);
	const [subscriptionData, setSubscriptionData] = useState(initialSubscriptionData);
	const [deleteAlertModalOpen, setDeleteAlertModalOpen] = useState(false);
	const [deleteModalLoading, setDeleteModalLoading] = useState(false);
	const alertsHeader = [
		{ title: translate('email'), id: 'email' },
		{ title: translate('assignationLevel'), id: 'assignationLevel' },
		{ title: translate('alertType'), id: 'alertType' },
		{ title: translate('invitedBy'), id: 'invitedBy' },
		{ title: translate('invitationDate'), id: 'invitationDate' },
		{ title: translate('actions'), id: 'actions' },
	];

	useEffect(() => {
		const fetchSubscriptions = async () => {
			setIsListLoading(true);
			const params = {
				levelIds: [userSession.healthSystem.id],
				pageSize: pagination.pageSize,
				pageIndex: pagination.pageIndex,
			};
			const response = await getDeviceAlertsList(params);
			if (response.error) {
				setError(response.error.message);
			} else {
				setTotalCount(response.totalCount);
				setDeviceReportSubscriptions(response.deviceReportSubscriptions);
			}
			setIsListLoading(false);
		};
		fetchSubscriptions();
	}, [pagination]);

	const displayRows = () => {
		if (deviceReportSubscriptions.length === 0) return [];
		return deviceReportSubscriptions.map(item => ({
			id: item.subscriberEmail,
			email: item.subscriberEmail,
			assignationLevel: item.teams.map(hs => hs.name).join(','),
			alertType: getSubscriptionName(item.subscriptionTypes[0]),
			invitedBy: (
				<div className='flex'>
					<ProfilePicture
						className='doctor-request-img'
						isActive={false}
						firstName={item.createdByUser.firstName}
						lastName={item.createdByUser.lastName}
						profilePicture={item.createdByUser.profilePicture}
					/>
					<div className='profile-data'>
						<span>
							{item.createdByUser.firstName ?? 'N/A'} {item.createdByUser.lastName ?? 'N/A'}
						</span>
						<span>{item.createdByUser.email ?? 'N/A'}</span>
					</div>
				</div>
			),
			invitationDate: (
				<div className='profile-data'>
					<span>{monthDayYear(item.dateCreated)}</span>
					<span>{getFormattedHours(item.dateCreated)}</span>
				</div>
			),
			actions: (
				<div className='subscription-actions'>
					<i
						data-tooltip={intl.formatMessage({ id: 'delete' })}
						className='material-icons-outlined'
						onClick={() => {
							setSubscriptionData({
								...initialSubscriptionData,
								emails: [item.subscriberEmail],
								healthSystems: item.teams.map(hs => ({ value: hs.id, label: hs.name })),
							});
							setDeleteAlertModalOpen(true);
						}}>
						delete
					</i>
					<i
						data-tooltip={intl.formatMessage({ id: 'edit' })}
						className='material-icons-outlined'
						onClick={() => {
							setSubscriptionData({
								...initialSubscriptionData,
								emails: [item.subscriberEmail],
								healthSystems: item.teams.map(hs => ({ value: hs.id, label: hs.name })),
							});
							setIsSetDeviceAlertOpen(true);
						}}>
						edit
					</i>
				</div>
			),
		}));
	};

	const handleDeleteActions = async () => {
		setDeleteModalLoading(true);
		const response = await deleteDeviceAlert(subscriptionData.emails[0]);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		setSubscriptionData(initialSubscriptionData);
		setDeviceReportSubscriptions(prevState => prevState.filter(item => item.subscriberEmail !== subscriptionData.emails[0]));
		setDeleteAlertModalOpen(false);
		setDeleteModalLoading(false);
	};

	const getSubscriptionName = id => {
		switch (id) {
			case DeviceSubscriptionType.DEVICE_OFFLINE:
				return translate('deviceIsOffline');
			default:
				return 'N/A';
		}
	};

	const submitDeviceAlert = async (values, resetForm) => {
		setAddAlertModalLoading(true);
		const params = {
			levelIds: values.healthSystems.map(item => item.value),
			emails: values.emails,
			subscriptionType: DeviceSubscriptionType.DEVICE_OFFLINE,
		};
		const response = await addOrEditDeviceAlert(params);
		if (response.error) {
			props.setError(response.error.message);
		} else {
			setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
			resetForm();
			setSubscriptionData(initialSubscriptionData);
		}
		setAddAlertModalLoading(false);
		setIsSetDeviceAlertOpen(false);
	};

	const validateForm = () =>
		Yup.object().shape({
			deviceIsOffline: Yup.boolean().oneOf([true], intl.formatMessage({ id: 'anAlertMustBeSelected' })),
			emails: Yup.array().min(1, intl.formatMessage({ id: 'formShouldContainOneEmail' })),
		});

	const handleEmailInput = async ({ value, setFieldValue, setFieldError, name, emails }) => {
		if (!value) {
			return;
		}

		if (emails.includes(value)) {
			setFieldError(name, intl.formatMessage({ id: 'alreadyAddedThisEmail' }));
			return;
		}

		if (!isValidEmail(value)) {
			setFieldError(name, intl.formatMessage({ id: 'invalidEmail' }));
			return;
		}

		const response = await checkIfCompanyMemberExists(value);
		if (response.error) {
			setError(response.error.message);
		} else {
			if (response.exists) {
				const newEmails = [...emails, value];
				setFieldValue(name, newEmails);
				setFieldValue('email', '');
				setFieldError(name, '');
			} else {
				setFieldError(name, intl.formatMessage({ id: 'couldNotFindThisEmail' }));
			}
		}
	};

	const removeEmailFromList = (value, setFieldValue, emails) => {
		const newArray = emails.filter(item => item !== value);
		setFieldValue('emails', newArray);
	};

	return (
		<div className='device-alerts'>
			<div className='flex device-alerts-header'>
				<i className='material-icons-outlined' onClick={() => props.setIsDeviceAlertsOpen(false)}>
					arrow_back
				</i>
				<h3>{translate('deviceAlerts')}</h3>
				<Button
					text={intl.formatMessage({ id: 'setupDeviceAlerts' })}
					imgIcon={`${healthCareCdnUrl}rpm/alerts-white.svg`}
					onClick={() => setIsSetDeviceAlertOpen(true)}
				/>
			</div>
			<Table
				headers={alertsHeader}
				rows={!isListLoading ? displayRows() : []}
				className='user-alerts-table'
				isEditable={false}
				isLoading={isListLoading}
			/>
			<Pagination
				totalCount={totalCount}
				pageSize={pagination.pageSize}
				pageIndex={pagination.pageIndex}
				onChange={(pageSize, pageIndex) => {
					setIsListLoading(true);
					setPagination({ pageSize, pageIndex });
				}}
			/>
			<Formik
				validateOnChange={false}
				validateOnBlur={true}
				enableReinitialize={true}
				initialValues={subscriptionData}
				validationSchema={validateForm}
				onSubmit={(values, { resetForm }) => submitDeviceAlert(values, resetForm)}>
				{formikProps => {
					const { values, errors, handleSubmit, resetForm, setFieldValue, setFieldError } = formikProps;
					return (
						<Modal
							display={isSetDeviceAlertOpen}
							isSubmitDisabled={values.emails.length === 0}
							isLoading={addAlertModalLoading}
							position='center'
							className='wrapper-modal border-radius-modal-wrapper subscription-modal'
							onModalSubmit={handleSubmit}
							onModalClose={() => {
								setSubscriptionData(initialSubscriptionData);
								setIsSetDeviceAlertOpen(false);
								resetForm();
							}}
							submitImgIcon={`${healthCareCdnUrl}appointments/save-icon.svg`}
							closeImgIcon={`${healthCareCdnUrl}appointments/cancel-appointment.svg`}
							shouldSubmitOnEnter={false}>
							<h3>{translate('setupDeviceAlerts')}</h3>
							<div>
								<p>{translate('setupAlertsFor')}</p>
								<div className='alert-types'>
									<label className={classNames('checkbox', values.deviceIsOffline ? 'checked' : '')}>
										<div>
											<img src={`${healthCareCdnUrl}admin/super-admin/hello-2.svg`} alt='icon' />
										</div>
										<span className='checkbox-name'>{translate('deviceIsOffline')}</span>
										<input
											type='checkbox'
											id='deviceIsOffline'
											name='deviceIsOffline'
											checked={values.deviceIsOffline}
											onChange={() => null}
										/>
									</label>
								</div>
								<span className='red-error'>{errors.deviceIsOffline}</span>
							</div>
							<MultiSelect
								name='healthSystems'
								placeholder={intl.formatMessage({ id: 'selectHealthSystems' })}
								label={translate('assignationLevel')}
								options={healthSystemsArr}
								value={values.healthSystems}
								onChange={setFieldValue}
								backspaceRemovesValue={true}
								onBlur={() => null}
								isMulti
							/>
							<div>
								<p>{translate('emailAddress')}</p>
								<Input
									value={values.email}
									onChange={event => setFieldValue('email', event.target.value)}
									postfixTooltip={intl.formatMessage({ id: 'addEmail' })}
									type='email'
									postfixIcon='person_add'
									placeholder={intl.formatMessage({ id: 'enterEmailAddress' })}
									name='email'
									onPostfixClick={() =>
										handleEmailInput({ value: values.email, setFieldValue, setFieldError, name: 'emails', emails: values.emails })
									}
									onKeyDown={event =>
										event.keyCode === KeyCodes.ENTER
											? handleEmailInput({
													value: event.target.value,
													setFieldValue,
													setFieldError,
													name: 'emails',
													emails: values.emails,
											  })
											: null
									}
								/>
								<span className='red-error'>{errors.emails}</span>
								<div className='email-list'>
									{values.emails.map(item => (
										<div key={item}>
											<span>{item}</span>
											<i
												className='material-icons prefix-icon'
												onClick={() => removeEmailFromList(item, setFieldValue, values.emails)}>
												close
											</i>
										</div>
									))}
								</div>
							</div>
						</Modal>
					);
				}}
			</Formik>
			<Modal
				display={deleteAlertModalOpen}
				position='center'
				isLoading={deleteModalLoading}
				onModalClose={() => {
					setSubscriptionData(initialSubscriptionData);
					setDeleteAlertModalOpen(false);
				}}
				onModalSubmit={handleDeleteActions}
				primaryButtonLabel={translate('delete')}
				className='wrapper-modal border-radius-modal-wrapper admin-delete-modal'
				submitImgIcon={`${healthCareCdnUrl}appointments/save-icon.svg`}
				closeImgIcon={`${healthCareCdnUrl}appointments/cancel-appointment.svg`}>
				<Form>
					<h3>{translate('deleteAlertSubscription')}</h3>
					<p className='modal-paragraph'>
						{translate('areYouSureYouWantToDeleteSubscription', {
							value: <span className='bold'>{subscriptionData.emails[0]}</span>,
						})}
					</p>
				</Form>
			</Modal>
			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
		</div>
	);
};

export default DeviceAlerts;
