import React from 'react';

const LayingOnBack = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none' viewBox='0 0 24 24' {...props}>
		<path stroke='currentColor' strokeLinejoin='round' strokeWidth={1.25} d='M2 12h20v2H2z' />
		<path stroke='currentColor' strokeWidth={1.25} d='M5 14v5M19 14v5' />
		<path stroke='currentColor' strokeLinecap='round' strokeWidth={1.25} d='M4 19h2M18 19h2' />
		<path stroke='currentColor' strokeMiterlimit={10} strokeWidth={1.25} d='M20.5 9a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z' />
		<path
			stroke='currentColor'
			strokeLinejoin='round'
			strokeWidth={1.25}
			d='M10 12h7s1.5 0 1.5-1.5S17 9 17 9h-7m0 3V9m0 3H3l-1-2 8-1'
		/>
	</svg>
);
export default LayingOnBack;
