import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';

const companyId = getCompanyId();

export const getOtherRequests = async ({ userId, pageIndex = 0, pageSize = 10 }) => {
	try {
		const { data } = await gatewayApi.get(`/v1/healthcare/users/${userId}/requests`, {
			params: {
				as: 'doctor',
				pageIndex,
				pageSize,
			},
		});
		return data.requests;
	} catch (error) {
		return { error };
	}
};

export const getAssignedToMeRequests = async ({ userId, pageIndex = 0, pageSize = 10, status = null, isLiveCall = null }) => {
	try {
		const { data } = await gatewayApi.get(`v1/healthcare/users/${userId}/assigned-requests`, {
			params: {
				pageIndex,
				pageSize,
				status,
				isLiveCall,
			},
		});
		return data.assignedRequests;
	} catch (error) {
		return { error };
	}
};

export const getSignAndSymptoms = async () => {
	try {
		const { data } = await gatewayApi.get(`v1/healthcare/symptoms`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const assignRequest = async (id, values) => {
	try {
		const { data } = await gatewayApi.put(`v1/healthcare/assigned-requests/${id}`, values);
		return data;
	} catch (error) {
		return { error };
	}
};

export const updateAssignRequest = async (id, values, cancelPendingRequests = false) => {
	try {
		const { data } = await gatewayApi.put(
			`v1.1/healthcare/assigned-requests/${id}?cancel-requests=${cancelPendingRequests}`,
			values
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const assignOtherRequest = async id => {
	try {
		const { data } = await gatewayApi.post(`v1/healthcare/assigned-requests/${id}/assign`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getAssignedRequest = async id => {
	try {
		const { data } = await gatewayApi.get(`v1/healthcare/assigned-requests/${id}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getRequest = async assignedRequestId => {
	try {
		const { data } = await gatewayApi.get(`v1.1/healthcare/assigned-requests/${assignedRequestId}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPatientQueueRequest = async ({ pageIndex, pageSize, search, healthSystemId }) => {
	try {
		const { data } = await gatewayApi.get(
			`v1/healthcare/organizations/${companyId}/health-systems/${healthSystemId}/queued-requests?search=${search}&pageIndex=${pageIndex}&pageSize=${pageSize}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const enQueueToMedic = async ({ healthSystemId, requestId, medicId }) => {
	try {
		const { data } = await gatewayApi.post(
			`v1/healthcare/organizations/${companyId}/health-systems/${healthSystemId}/queued-requests/${requestId}/enqueue-to-medic`,
			{ medicId }
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const updatePatientQueueRequestStatus = async ({ healthSystemId, patientQueuedRequestId, statusId, message }) => {
	try {
		const { data } = await gatewayApi.patch(
			`v1/healthcare/organizations/${companyId}/health-systems/${healthSystemId}/queued-requests/${patientQueuedRequestId}/status`,
			{ statusId, message }
		);
		return data;
	} catch (error) {
		return { error };
	}
};
