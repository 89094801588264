import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import 'react-day-picker/dist/style.css';
import { useSelector } from 'react-redux';
import { getDevicePastConferences } from 'api/callLogs.js';
import { getOrgTeamChildren } from 'api/organization.js';
import { Table, Badge, Alert } from 'components/index.js';
import Pagination from 'components/Common/Pagination.jsx';
import { convertToTimestampSecondsWithMaxTime, getDateDifference } from 'infrastructure/helpers/dateHelper.js';
import {
	ObjectType,
	CallStatus,
	CallTypes,
	ParticipantState,
	UserRoles,
	CallTypesMessage,
	TreeHierarchyType,
} from 'constants/enums.js';
import { getCompanyId, getUserRole } from 'infrastructure/auth.js';
import { getPreferredLanguageLocale } from 'infrastructure/helpers/commonHelpers.js';
import translate from 'i18n-translations/translate.jsx';
import { getCallStatus, getCallType, getParticipantState } from 'infrastructure/helpers/callLogsHelper.js';
import HeaderFilters from 'containers/CallLogs/HeaderFilters.jsx';
import PastCallsViewActions from 'containers/CallLogs/PastCallsViewActions.jsx';
import { getHospitalRooms, getDepartmentRooms } from 'api/rooms.js';
import { getHospitalFloors } from 'api/floors.js';

const PastCallsList = () => {
	const allHealthSystems = useSelector(state => state.healthSystems.allHealthSystems);
	const userSession = useSelector(state => state.user.userSession);
	const today = new Date();

	const [logs, setLogs] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0 });
	const [totalCount, setTotalCount] = useState(0);
	const [dateRange, setDateRange] = useState({ from: null, to: null });
	const [sectorsSelected, setSectorsSelected] = useState({
		healthSystem:
			getUserRole() === UserRoles.SUPER_USER
				? { value: userSession.healthSystem.id, label: userSession.healthSystem.name }
				: null,
		region: null,
		hospital: null,
		department: null,
		floor: null,
		room: null,
	});
	const [selectedConference, setSelectedConference] = useState({});
	const [regions, setRegions] = useState([]);
	const [hospitals, setHospitals] = useState([]);
	const [departments, setDepartments] = useState([]);
	const [floors, setFloors] = useState([]);
	const [rooms, setRooms] = useState([]);
	const [showCallQueueModal, setShowCallQueueModal] = useState(false);
	const [showCallDetailsModal, setShowCallDetailsModal] = useState(false);
	const [showListOfRoomsModal, setShowListOfRoomsModal] = useState(false);
	const [showListOfParticipantsModal, setShowListOfParticipantsModal] = useState(false);
	const [currentCallLogQueue, setCurrentCallLogQueue] = useState([]);
	const [isExportLogsModalOpen, setIsExportLogsModalOpen] = useState(false);
	const [hasDateError, setHasDateError] = useState(false);
	const [isExportLogsModalLoading, setIsExportLogsModalLoading] = useState(false);
	const [hasExportLogsModalErrors, setHasExportLogsModalErrors] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const [treeHierarchyTypeId, setTreeHierarchyTypeId] = useState(TreeHierarchyType.DEFAULT_TREE);
	const maximumRangeOfDays = 90;
	const toInput = useRef(null);

	useEffect(() => {
		const getPastCalls = async () => {
			const params = {
				pageSize: pagination.pageSize,
				pageIndex: pagination.pageIndex,
				start: dateRange.from && dateRange.to ? new Date(dateRange.from).getTime() / 1000 : null,
				end: dateRange.to && dateRange.from ? Math.floor(convertToTimestampSecondsWithMaxTime(dateRange.to)) : null,
				healthSystemId: sectorsSelected.healthSystem?.value,
				regionId: sectorsSelected.region?.value,
				hospitalId: sectorsSelected.hospital?.value,
				departmentId: sectorsSelected.department?.value,
				floorId: sectorsSelected.floor?.value,
				roomId: sectorsSelected.room?.value,
			};

			const conferenceLevel = await getDevicePastConferences(params);
			if (conferenceLevel.error) {
				setErrorMessage(conferenceLevel.error.message);
			} else {
				const logsList = getLogs(conferenceLevel.conferences);
				setLogs(logsList);
				setTotalCount(conferenceLevel.totalCount);
			}
			if (!sectorsSelected.region && getUserRole() === UserRoles.SUPER_USER) {
				const regionsList = await getOrgTeamChildren(userSession.healthSystem.id);
				if (regionsList.error) {
					setErrorMessage(regionsList.error.message);
					return;
				}
				const regionsOptions = regionsList.map(region => ({ value: region.id, label: region.name }));
				setRegions(regionsOptions);
			}

			setIsLoading(false);
		};

		setIsLoading(true);
		getPastCalls();
	}, [dateRange, pagination, sectorsSelected, userSession.healthSystem.id]);

	const isSuccessfulCall = status =>
		!(status === CallStatus.FAILED.type || status === CallStatus.MISSED.type || status === CallStatus.ABORTED.type);

	const getRoom = conference => {
		if (conference.callType === CallTypes.MONITORING) {
			return getMonitoredRooms(conference.participants);
		}
		if (conference.participants.length > 2 && !conference.participants.some(p => p.isInitiator && p.objectType === ObjectType.HELLO_DEVICE)) {
			return getMultipartyRoom(conference.participants);
		}
		return getPatientRoom(conference.participants);
	};

	const getLogs = conferences => {
		return conferences.map(conference => {
			const room = getRoom(conference);
			const vcp = getVirtualCareProvider(conference.participants, room.isInitiator);
			const status = getCallStatus(conference.status);
			const startedAt = moment
				.utc(conference.startedAt)
				.local()
				.locale(getPreferredLanguageLocale())
				.format('MM/DD/YYYY-hh:mm A');
			const endedAt = moment.utc(conference.endedAt).local().locale(getPreferredLanguageLocale()).format('MM/DD/YYYY-hh:mm A');
			const initiator = vcp?.isInitiator ? translate('virtualCareProvider') : translate('patient');
			const roomsList = conference.participants.filter(p => p.objectType === ObjectType.HELLO_DEVICE);
			return {
				id: conference.id,
				initiator,
				provider:
					 	!vcp
						? 'N/A'
						: vcp.name,
				room: conference.callType === CallTypes.MONITORING ? room : room?.name,
				patient: room?.deviceOwner || '',
				startedAt,
				endedAt,
				origin: vcp?.isInitiator ? translate('outgoing') : translate('incoming'),
				status,
				callType: getCallType(conference.callType),
				duration: conference.callDuration,
				usedAudio: { room: room?.usedAudio, vcp: vcp?.usedAudio },
				usedVideo: { room: room?.usedVideo, vcp: vcp?.usedVideo },
				usedScreen: { room: room?.usedScreen, vcp: vcp?.usedScreen },
				participants: conference.participants,
				queue: room?.isInitiator ? conference.participants.filter(participant => participant.objectType === ObjectType.USER) : [],
				roomCount: roomsList.length,
				careEventType: conference.careEventType,
			};
		});
	};

	const getMonitoredRooms = participants => {
		const roomsList = participants.reduce((acc, value) => {
			if (value.objectType === ObjectType.HELLO_DEVICE) {
				return `${acc}${acc && ','}${value.name}`;
			}
			return acc;
		}, '');

		return roomsList;
	};

	const getTableRows = () =>
		logs.map(log => ({
			id: log.id,
			conferenceId: log.id,
			initiator: log.initiator,
			provider: log.provider,
			room:
				log.callType === CallTypesMessage.MONITORING || (log.participants.length > 2 && !log.participants.some(p => p.isInitiator && p.objectType === ObjectType.HELLO_DEVICE)) ? (
					<span
						className='text-underline cursor-pointer'
						onClick={() => {
							if (log.callType === CallTypesMessage.MONITORING) {
								openListOfRoomsModal(log);
								return;
							}
							if (log.participants.length > 2) {
								openListOfParticipantsModal(log);
								return;
							}
						}}>
						{translate('viewAll')}
					</span>
				) : (
					log.room
				),
			patient: log.patient,
			date: (
				<div style={{ fontSize: '12px' }}>
					<span className='block'>{log.startedAt}</span>
					<span className='block'>{log.endedAt}</span>
				</div>
			),
			origin: log.origin,
			status: getStatusBadge(log.status.toUpperCase()),
			duration: log.duration,
			callType: log.callType,
			careEventType: log.careEventType || 'None',
			details: (
				<span className='text-underline cursor-pointer' onClick={() => openCallDetailsModal(log)}>
					{translate('viewDetails')}
				</span>
			),
			queue:
				log.queue.length > 0 ? (
					<span className='text-underline cursor-pointer' onClick={() => openCallLogQueueModal(log.queue)}>
						{translate('viewAll')}
					</span>
				) : (
					'N/A'
				),
		}));

	const openCallLogQueueModal = participants => {
		setShowCallQueueModal(true);
		setCurrentCallLogQueue(participants);
	};

	const openCallDetailsModal = conference => {
		setShowCallDetailsModal(true);
		setSelectedConference(conference);
	};

	const getVirtualCareProvider = (participants, isRoomInitiator) => 
		 participants
		.sort((f, s) => f.timeJoined - s.timeJoined)
		.find(participant => participant.objectType === ObjectType.USER && participant.isInitiator === !isRoomInitiator && participant.timeJoined);

	const getPatientRoom = participants => participants.find(participant => participant.objectType === ObjectType.HELLO_DEVICE);

	const getMultipartyRoom = participants => (participants.length > 2 ? participants.map(participant => participant) : []);

	const getStatusBadge = status => {
		const statusBadgeDescription = {
			UNDEFINED: 'undefinedOnList',
			SUCCESSFUL: 'successfulOnList',
			PARTIALLY_SUCCESSFUL: 'partiallySuccessfulOnList',
			FAILED: 'failedOnList',
			DISRUPTED: 'disruptedOnList',
			PARTIALLY_DISRUPTED: 'partiallyDisruptedOnList',
			MISSED: 'missedOnList',
			ABORTED: 'abortedOnList',
			DROPPED: 'droppedOnList',
		};
		return (
			<Badge
				text={statusBadgeDescription[status] ? translate(statusBadgeDescription[status]) : 'N/A'}
				variant={statusBadgeDescription[status] === 'successfulOnList' ? 'green' : 'red'}
			/>
		);
	};

	const handleFromChange = from => {
		const diffFromToday = getDateDifference(from, today);
		setDateRange({ from, to: diffFromToday > maximumRangeOfDays ? from : today });
	};

	const onHealthSystemSelected = async value => {
		setTreeHierarchyTypeId(allHealthSystems.find(item => item.id === value.value)?.treeHierarchyTypeId);
		setSectorsSelected({ healthSystem: value, region: null, hospital: null, department: null, floor: null, room: null });
		setRegions([]);
		setHospitals([]);
		setDepartments([]);
		setFloors([]);
		setRooms([]);
		const regionsList = await getOrgTeamChildren(value.value);
		if (regionsList.error) {
			setErrorMessage(regionsList.error.message);
			return;
		}
		const regionsOptions = regionsList.map(region => ({ value: region.id, label: region.name }));
		setRegions(regionsOptions);
	};

	const onRegionSelected = async value => {
		setSectorsSelected(prevState => ({ ...prevState, region: value, hospital: null, department: null, floor: null, room: null }));
		setHospitals([]);
		setDepartments([]);
		setFloors([]);
		setRooms([]);
		const hospitalsList = await getOrgTeamChildren(value.value);
		if (hospitalsList.error) {
			setErrorMessage(hospitalsList.error.message);
			return;
		}
		const hospitalOptions = hospitalsList.map(hospital => ({ value: hospital.id, label: hospital.name }));
		setHospitals(hospitalOptions);
	};

	const onHospitalSelected = async hospital => {
		setDepartments([]);
		setFloors([]);
		setRooms([]);
		let response = null;
		if (
			[TreeHierarchyType.DEFAULT_TREE, TreeHierarchyType.HOSPITAL_DEPT_FLOOR_ROOM, TreeHierarchyType.HOSPITAL_DEPT_ROOM].includes(
				treeHierarchyTypeId
			)
		) {
			response = await getOrgTeamChildren(hospital.value);
			setSectorsSelected(prevState => ({ ...prevState, hospital, department: null, floor: null, room: null }));
		}
		if (treeHierarchyTypeId === TreeHierarchyType.HOSPITAL_FLOOR_ROOM) {
			const floorsResponse = await getHospitalFloors(getCompanyId(), sectorsSelected.healthSystem.value, hospital.value);
			if (!floorsResponse.error) {
				response = floorsResponse.floors;
				setSectorsSelected(prevState => ({
					...prevState,
					hospital,
					department: { value: floorsResponse.departmentId },
				}));
			}
		}
		if (treeHierarchyTypeId === TreeHierarchyType.HOSPITAL_ROOM) {
			response = await getHospitalRooms(getCompanyId(), sectorsSelected.healthSystem.value, hospital.value);
		}
		if (response.error) {
			setErrorMessage(response.error.message);
			return;
		}
		const array = treeHierarchyTypeId === TreeHierarchyType.HOSPITAL_ROOM ? response.rooms : response;
		const responseOptions = array.map(option => ({ value: option.id, label: option.name }));
		if (
			[TreeHierarchyType.DEFAULT_TREE, TreeHierarchyType.HOSPITAL_DEPT_FLOOR_ROOM, TreeHierarchyType.HOSPITAL_DEPT_ROOM].includes(
				treeHierarchyTypeId
			)
		) {
			setDepartments(responseOptions);
		} else if (treeHierarchyTypeId === TreeHierarchyType.HOSPITAL_FLOOR_ROOM) {
			setFloors(responseOptions);
		} else {
			setSectorsSelected(prevState => ({
				...prevState,
				hospital,
				department: { value: response.departmentId },
				floor: { value: response.floorId },
			}));
			setRooms(responseOptions);
		}
	};

	const onDepartmentSelected = async department => {
		setFloors([]);
		setRooms([]);

		let response = [];
		if (TreeHierarchyType.HOSPITAL_DEPT_ROOM === treeHierarchyTypeId) {
			const depResponse = await getDepartmentRooms(sectorsSelected.healthSystem.value, department.value);
			setSectorsSelected(prevState => ({ ...prevState, department, floor: { value: depResponse.floorId }, room: null }));
			response = depResponse.error ? [] : depResponse.rooms;
			setRooms(response);
		} else {
			response = await getOrgTeamChildren(department.value);
			setSectorsSelected(prevState => ({ ...prevState, department, floor: null, room: null }));
		}

		if (response.error) {
			setErrorMessage(response.error.message);
			return;
		}
		const options = response.map(floor => ({ value: floor.id, label: floor.name }));
		if (TreeHierarchyType.HOSPITAL_DEPT_ROOM === treeHierarchyTypeId) {
			setRooms(options);
		} else {
			setFloors(options);
		}
	};

	const onFloorSelected = async value => {
		setSectorsSelected(prevState => ({ ...prevState, floor: value, room: null }));
		setRooms([]);
		const roomsList = await getOrgTeamChildren(value.value);
		if (roomsList.error) {
			setErrorMessage(roomsList.error.message);
			return;
		}
		const roomOptions = roomsList.map(region => ({ value: region.id, label: region.name }));
		setRooms(roomOptions);
	};

	const openListOfRoomsModal = conference => {
		setSelectedConference(conference);
		setShowListOfRoomsModal(true);
	};

	const openListOfParticipantsModal = conference => {
		setSelectedConference(conference);
		setShowListOfParticipantsModal(true);
	};

	const closeCallQueueModal = () => {
		setShowCallQueueModal(false);
		setCurrentCallLogQueue([]);
	};

	const closeCallDetailsModal = () => {
		setShowCallDetailsModal(false);
		setSelectedConference({});
	};

	const closeListOfRooms = () => {
		setShowListOfRoomsModal(false);
		setSelectedConference({});
	};

	const closeListOfParticipantsModal = () => {
		setShowListOfParticipantsModal(false);
		setSelectedConference({});
	};

	const closeExportLogsModal = () => {
		setIsExportLogsModalOpen(false);
		setIsExportLogsModalLoading(false);
		setHasExportLogsModalErrors(false);
	};

	const tableHeaders = [
		{ title: 'ID', id: 0 },
		{ title: translate('callInitiator'), id: 1 },
		{ title: translate('virtualCareProvider'), id: 2 },
		{ title: translate('room'), id: 3 },
		{ title: translate('patient'), id: 4 },
		{ title: translate('dateTime'), id: 5 },
		{ title: translate('callOrigin'), id: 6 },
		{ title: translate('callStatus'), id: 7 },
		{ title: translate('callDuration'), id: 9 },
		{ title: translate('callType'), id: 10 },
		{ title: translate('careEventType'), id: 11 },
		{ title: translate('details'), id: 12 },
		{ title: translate('queue'), id: 13 },
	];

	return (
		<div>
			<Table isLoading={isLoading} headers={tableHeaders} rows={isLoading ? [] : getTableRows()}>
				<HeaderFilters
					sectors={{
						healthSystems: allHealthSystems,
						regions,
						hospitals,
						departments,
						floors,
						rooms,
					}}
					selectedSector={sectorsSelected}
					dates={{ fromDate: dateRange.from, toDate: dateRange.to, toInput, hasDateError }}
					onHealthSystemSelected={onHealthSystemSelected}
					onRegionSelected={onRegionSelected}
					onHospitalSelected={onHospitalSelected}
					onDepartmentSelected={onDepartmentSelected}
					onFloorSelected={onFloorSelected}
					onRoomSelected={value => setSectorsSelected(prevState => ({ ...prevState, room: value }))}
					handleFromChange={handleFromChange}
					handleRangeChange={setDateRange}
					setIsExportLogsModalOpen={setIsExportLogsModalOpen}
					treeHierarchyTypeId={treeHierarchyTypeId}
					maximumRangeOfDays={maximumRangeOfDays}
				/>
			</Table>
			<Pagination
				totalCount={totalCount}
				pageSize={pagination.pageSize}
				pageIndex={pagination.pageIndex}
				onChange={(pageSize, pageIndex) => setPagination({ pageSize, pageIndex })}
			/>
			<PastCallsViewActions
				modal={{
					currentCallLogQueue,
					showCallQueueModal,
					showCallDetailsModal,
					showListOfRoomsModal,
					showListOfParticipantsModal,
					isExportLogsModalOpen,
				}}
				selectedSector={sectorsSelected}
				selectedConference={selectedConference}
				isLoading={isLoading}
				isExportLogsModalLoading={isExportLogsModalLoading}
				hasExportLogsModalErrors={hasExportLogsModalErrors}
				getParticipantState={getParticipantState}
				closeCallQueueModal={closeCallQueueModal}
				closeCallDetailsModal={closeCallDetailsModal}
				closeListOfRooms={closeListOfRooms}
				closeListOfParticipants={closeListOfParticipantsModal}
				closeExportLogsModal={closeExportLogsModal}
				setShowCallQueueModal={setShowCallQueueModal}
				setIsExportLogsModalLoading={setIsExportLogsModalLoading}
				setHasExportLogsModalErrors={setHasExportLogsModalErrors}
				setIsExportLogsModalOpen={setIsExportLogsModalOpen}
				dates={{ fromDate: dateRange.from, toDate: dateRange.to }}
			/>

			<Alert display={errorMessage} fixed={true} hideCloseButton={true} message={errorMessage} variant='dark' />
		</div>
	);
};

export default PastCallsList;
