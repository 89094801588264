import React from 'react';
import styled from 'styled-components';
import LightTheme from 'calls/styles/LightTheme.js';

const StyledRangeSlider = styled.input`
	background-color: ${LightTheme.colors.grayTwo} !important;
	-webkit-appearance: none;
	width: 130px;
	height: 5px;
	border-radius: 5px;
	background: linear-gradient(90deg, ${LightTheme.colors.blueSix} 0%, ${LightTheme.colors.blueFour} 92.09%);
	background-repeat: no-repeat;
	margin: 0 !important;

	&::-webkit-slider-thumb,
	&::-webkit-slider-runnable-track {
		-webkit-appearance: none;
	}

	&::-webkit-slider-thumb {
		background: ${LightTheme.colors.grayZero};
		width: ${LightTheme.spacing[4]}px;
		height: ${LightTheme.spacing[4]}px;
		border-radius: 100%;
		box-shadow: none;
		border: 3px solid ${LightTheme.colors.blueFour};
	}

	&::-webkit-slider-runnable-track {
		box-shadow: none;
		border: none;
		background: transparent;
	}
`;

export default function RangeSlider({ ...htmlAttributes }) {
	return <StyledRangeSlider {...htmlAttributes} type='range' />;
}
