import React, { useEffect, useRef, useState } from 'react';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import { Alert, Button, Grid, Input, Modal, Table, Form } from 'components/index.js';
import translate from 'i18n-translations/translate.jsx';
import { DeviceCommands, DeviceConnectionStatus, DeviceListLevel } from 'constants/enums.js';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import FleetProgressBar from 'components/FleetManagementsCharts/FleetProgressBar.jsx';
import classNames from 'classnames';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import FleetManagementFilters from 'components/FleetManagementFilters.jsx';
import EthernetIcon from 'icons/Devices/EthernetIcon.jsx';
import { getMultipleLevelsDevices, sendCommand } from 'api/devices.js';
import { getFleetChartsData } from 'api/fleetManagement.js';
import Pagination from 'components/Common/Pagination.jsx';
import { DevicesStatus, initialDevicesStatusData } from 'constants/dashboard.js';
import { adjustTimeForUTC } from 'infrastructure/helpers/dateHelper.js';
import { isJSON } from 'infrastructure/helpers/commonHelpers.js';
import DeviceAlerts from 'containers/DeviceAlerts.jsx';

const FleetManagement = () => {
	const intl = useIntl();
	const userSession = useSelector(state => state.user.userSession);
	const delayTimerRef = useRef(null);
	const initialHs = {
		value: userSession.healthSystem.id,
		label: userSession.healthSystem.name,
		treeHierarchyTypeId: userSession.healthSystem.treeHierarchyTypeId,
	};
	const [isRebootDeviceOpen, setIsRebootDeviceOpen] = useState(false);
	const [selectedDevice, setSelectedDevice] = useState({
		serialNumber: '',
		deviceId: '',
	});
	const [rebootReason, setRebootReason] = useState('');
	const [isFilterShown, setIsFilterShown] = useState(false);
	const [filterValues, setFilterValues] = useState({
		healthSystems: [initialHs],
		hospitals: [],
		departments: [],
		floors: [],
		rooms: [],
		status: null,
		connection: null,
		fromDate: '',
		toDate: '',
		search: '',
		lastLevel: { name: 'healthSystems', id: DeviceListLevel.HEALTH_SYSTEM },
	});
	const [chartsData, setChartsData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isChartsLoading, setIsChartsLoading] = useState(true);
	const [error, setError] = useState(null);
	const [totalCount, setTotalCount] = useState(0);
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 1 });
	const [devices, setDevices] = useState([]);
	const [isDeviceAlertsOpen, setIsDeviceAlertsOpen] = useState(false);

	const ChartsTypes = {
		CONNECTION_STATUS: 1,
		DEVICE_TYPES: 2,
	};

	useEffect(() => {
		setIsLoading(true);
		const lastLevelIds = filterValues[filterValues.lastLevel.name].map(item =>
			isJSON(item.value) ? JSON.parse(item.value).id : item.value
		);

		const fetchDevices = async () => {
			const params = {
				...filterValues,
				currentPage: pagination.pageIndex,
				pageSize: pagination.pageSize,
				levelType: filterValues.lastLevel.id,
				levelIds: lastLevelIds,
				...(filterValues.status && { isOnline: filterValues.status === DeviceConnectionStatus.ONLINE }),
				connectionType: filterValues.connection,
				toDate: adjustTimeForUTC(filterValues.toDate, true),
				fromDate: adjustTimeForUTC(filterValues.fromDate),
			};

			const response = await getMultipleLevelsDevices(params);
			if (response.error) {
				setError(response.error.message);
			} else {
				setTotalCount(response.totalRows);
				setDevices(response.data);
			}
			setIsLoading(false);
		};

		fetchDevices();
		return () => {
			clearTimeout(delayTimerRef.current);
		};
	}, [filterValues, pagination, userSession]);

	useEffect(() => {
		setIsChartsLoading(true);
		const lastLevelIds = filterValues[filterValues.lastLevel.name].map(item =>
			isJSON(item.value) ? JSON.parse(item.value).id : item.value
		);

		const fetchChartsDate = async () => {
			const response = await getFleetChartsData(filterValues.lastLevel.id, lastLevelIds);
			if (response.error) {
				setError(response.error.message);
			} else {
				setChartsData(response.charts);
			}
			setIsChartsLoading(false);
		};
		fetchChartsDate();
	}, [filterValues.lastLevel]);

	const rebootDevice = async () => {
		if (selectedDevice.deviceId) {
			const params = {
				deviceId: selectedDevice.deviceId,
				command: DeviceCommands.REBOOT,
				reason: rebootReason,
			};
			const response = await sendCommand(params);
			if (response.errorResponse) {
				setError(response.errorResponse.message);
			}
		}
		setIsRebootDeviceOpen(false);
		setRebootReason('');
	};

	const devicesHeader = [
		{ title: translate('device'), id: 'device' },
		{ title: translate('room'), id: 'room' },
		{ title: translate('appVersion'), id: 'appVersion' },
		{ title: translate('status'), id: 'status' },
		{ title: translate('connection'), id: 'connection' },
		{ title: translate('actions'), id: 'actions' },
	];

	const buildDevicesAssigned = () => {
		const devicesData = chartsData.find(item => item.id === ChartsTypes.DEVICE_TYPES);
		const devicesPercentage = devicesData?.presentationFields.find(item => item.label === 'Devices').percentage ?? 0;
		const strokeColors = ['#5390FF', '#BED5FF'];
		const data = devicesData?.presentationFields.reduce((acc, item, index) => {
			acc.push({ ...item, stroke: strokeColors[index] });
			return acc;
		}, []);

		return {
			chartTitle: translate('devicesAssigned'),
			totalProgressTitle: translate('ofTheRooms'),
			totalProgress: `${Math.floor(devicesPercentage ?? 0)}%`,
			progressData: data,
		};
	};

	const buildStatusChartData = () => {
		const statusesData = chartsData.find(item => item.id === ChartsTypes.CONNECTION_STATUS);
		const onlinePercentage = statusesData?.presentationFields.find(item => item.label === DevicesStatus.ONLINE)?.percentage;

		return {
			chartTitle: translate('status'),
			totalProgressTitle: translate('online'),
			totalProgress: `${Math.floor(onlinePercentage ?? 0)}%`,
			progressData: statusesData?.presentationFields.map(item => ({ ...initialDevicesStatusData[item.label], ...item })),
		};
	};

	const devicesRows = () => {
		if (devices.length === 0) return [];
		return devices.map(device => ({
			id: device.id,
			sn: device.serialNumber,
			room: (
				<div className='assignation'>
					{device.floorName && (
						<span>
							{device.floorName} {`>`}
						</span>
					)}
					<span>{device.deviceName ?? 'N/A'}</span>
				</div>
			),
			appVersion: device.appVersion ?? 'N/A',
			status: (
				<span className={classNames('status', device.isOnline ? 'online' : '')}>
					{device.isOnline ? translate('online') : translate('offline')}
				</span>
			),
			connection: (
				<div className='connection flex'>
					{device.isEthernetConnected && <EthernetIcon />}
					{device.isWiFiConnected && !device.isEthernetConnected && <i className='material-icons-outlined'>wifi</i>}
					<div>
						<span>{device.ssId ?? 'N/A'}</span>
						<span>{device.ipAddress ?? 'N/A'}</span>
					</div>
				</div>
			),
			action: (
				<div className='device-action'>
					{device.isOnline && (
						<i
							data-tooltip={intl.formatMessage({ id: 'rebootDevice' })}
							data-position='top'
							className='material-icons-outlined'
							onClick={() => {
								setIsRebootDeviceOpen(true);
								setSelectedDevice({
									deviceId: device.solHelloDeviceId,
									serialNumber: device.serialNumber,
								});
							}}>
							restart_alt
						</i>
					)}
				</div>
			),
		}));
	};

	const handleSearch = e => {
		setIsLoading(true);
		clearTimeout(delayTimerRef.current);
		const timer = setTimeout(() => {
			setFilterValues(prevState => ({ ...prevState, search: e.target.value }));
		}, 500);

		delayTimerRef.current = timer;
	};

	return (
		<MainLayout>
			<div className='fleet-management-view'>
				{!isDeviceAlertsOpen && (
					<main className='main-view'>
						<div className='flex flex-align-center fleet-management-header'>
							<h3 className='title'>{translate('fleetManagement')}</h3>
							<Button
								className={classNames('filter-button', isFilterShown ? 'active' : '')}
								onClick={() => setIsFilterShown(prevState => !prevState)}
								imgIcon={`${healthCareCdnUrl}care-events/filter-icon.svg`}
							/>
							<Button
								text={intl.formatMessage({ id: 'deviceAlerts' })}
								imgIcon={`${healthCareCdnUrl}rpm/alerts-white.svg`}
								onClick={() => setIsDeviceAlertsOpen(prevState => !prevState)}
							/>
							<FleetManagementFilters
								isFilterShown={isFilterShown}
								setError={setError}
								setFilterValues={setFilterValues}
								filterValues={filterValues}
								setIsLoading={setIsLoading}
							/>
						</div>
						<Grid columns='repeat(2, 1fr' gridGap='15px' className='top-15 fleet-component'>
							<FleetProgressBar key='2' isLoading={isChartsLoading} chartData={buildDevicesAssigned()} />
							<FleetProgressBar key='3' isLoading={isChartsLoading} chartData={buildStatusChartData()} />
						</Grid>
						<div className='top-15 fleet-component table-devices'>
							<div>
								<div>
									<label>{translate('devices')}</label>
									<Input
										type='text'
										prefixIcon='search'
										variant='filled'
										placeholder={intl.formatMessage({ id: 'searchByRoomOrSN' })}
										onChange={handleSearch}
									/>
								</div>
								<Table
									headers={devicesHeader}
									rows={!isLoading ? devicesRows() : []}
									className='devices-fleet-table'
									isEditable={false}
									isLoading={isLoading}
								/>
								<Pagination
									totalCount={totalCount}
									pageSize={pagination.pageSize}
									pageIndex={pagination.pageIndex - 1}
									onChange={(pageSize, pageIndex) => {
										setIsLoading(true);
										setPagination({ pageSize, pageIndex: pageIndex + 1 });
									}}
								/>
							</div>
						</div>
					</main>
				)}
				{isDeviceAlertsOpen && <DeviceAlerts setIsDeviceAlertsOpen={setIsDeviceAlertsOpen} setError={setError} />}
				<Modal
					display={isRebootDeviceOpen}
					position='center'
					primaryButtonLabel={translate('reboot')}
					onModalSubmit={rebootDevice}
					onModalClose={() => setIsRebootDeviceOpen(false)}>
					<Form title={intl.formatMessage({ id: 'rebootDevice' })} onSubmit={event => event.preventDefault()}>
						<p>
							{translate('rebootDeviceQuestion')} {selectedDevice.serialNumber}
						</p>
						<Input
							type='text'
							value={rebootReason}
							validationOptions={{ maxLength: 100 }}
							placeholder={intl.formatMessage({ id: 'rebootDeviceReason' })}
							onChange={e => setRebootReason(e.target.value)}
						/>
					</Form>
				</Modal>
				<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
			</div>
		</MainLayout>
	);
};

export default FleetManagement;
