import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import Alert from 'components/Alert.jsx';
import translate from 'i18n-translations/translate.jsx';
import { deleteMedicalInfo } from 'api/medicalInfo.js';
import { AlertTypes, MedicalInfoTypes } from 'constants/enums.js';
import Modal from 'components/Modal.jsx';
import PopUpAlert from 'components/PopUpAlert.jsx';
import AddEditPrescription from 'containers/Prescriptions/AddEditPrescription';
import { useConferenceConfigurations } from 'calls/hooks/index.js';

const PrescriptionsWrapper = props => {
	const intl = useIntl();
	const [error, setError] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const conferenceConfigurations = useConferenceConfigurations();
	const [selectedMedicine, setSelectedMedicine] = useState(null);
	const [medicalItemExists, setMedicalItemExists] = useState(null);
	const [shouldShowAddEdit, setShouldShowAddEdit] = useState(false);
	const [selectedPrescriptionId, setSelectedPrescriptionId] = useState(null);

	const deletePrescription = async id => {
		setIsLoading(true);
		const params = { id, visitId: props.visitId, type: MedicalInfoTypes.PRESCRIPTIONS };
		const response = await deleteMedicalInfo(params);
		if (response.error) {
			setError(response.error.message);
		} else {
			const items = props.prescriptions.filter(item => item.id !== id);
			props.setMedicalInfo(items, MedicalInfoTypes.PRESCRIPTIONS);
		}
		setIsLoading(false);
		setSelectedPrescriptionId(null);
	};

	const medicalItem = (prescription, type, optionalText) => (
		<div>
			<h5>{translate(type)}</h5>
			<p>
				{prescription[type]} {optionalText && <span>{optionalText}</span>}
			</p>
		</div>
	);

	return (
		<aside
			className={classNames(
				'right-side medical-info-aside add-prescriptions-wrapper',
				conferenceConfigurations.isRightToLeft ? 'rtl-medical-info-aside' : '',
				conferenceConfigurations.isDarkMode ? 'dark-medical-info-aside' : ''
			)}>
			{!shouldShowAddEdit && (
				<div className={classNames('mi-selected-list', props.prescriptions.length > 0 ? 'prescriptions-list' : '')}>
					{props.prescriptions.length > 0 && <p>{translate('addedPrescriptions')}</p>}
					{props.prescriptions.map(prescription => (
						<div key={prescription.id} className='position-relative prescription-icons'>
							<i
								className='material-icons'
								onClick={() => {
									setSelectedMedicine(prescription);
									setShouldShowAddEdit(true);
								}}>
								edit
							</i>
							<i className='material-icons' onClick={() => setSelectedPrescriptionId(prescription.id)}>
								close
							</i>
							<div>
								<p className='medicine-name'>{`${prescription.medicine.name} ${prescription.medicine.strengthAndForm}`}</p>
							</div>
							<div className='flex'>
								{medicalItem(prescription, 'dosage', translate('capsule'))}
								{medicalItem(prescription, 'frequency', translate('timesAday'))}
							</div>
							<div className='flex'>
								{medicalItem(prescription, 'quantityToDispense')}
								{medicalItem(prescription, 'refills')}
							</div>
							<div>
								<div>{medicalItem(prescription, 'duration', translate('day'))}</div>
							</div>
							{prescription.patientInstructions && (
								<div>
									<p>{translate('patientInstructions')}</p>
									<p>{prescription.patientInstructions}</p>
								</div>
							)}
							{prescription.noteToPharmacist && (
								<div>
									<p>{translate('noteToPharmacist')}</p>
									<p>{prescription.noteToPharmacist}</p>
								</div>
							)}
						</div>
					))}
					{props.prescriptions.length === 0 && (
						<div className='d-p-no-items'>
							<p>
								{translate('noAdded', {
									value: intl.formatMessage({ id: 'prescriptions' }).toLowerCase(),
								})}
							</p>
						</div>
					)}
				</div>
			)}
			{!isLoading && !shouldShowAddEdit && (
				<button
					className='button small block'
					type='button'
					onClick={() => {
						setShouldShowAddEdit(true);
						setSelectedMedicine({
							dispenseAsWritten: false,
						});
					}}>
					{translate('addPrescription')}
				</button>
			)}
			<Modal
				display={selectedPrescriptionId}
				onModalSubmit={() => deletePrescription(selectedPrescriptionId)}
				onModalClose={() => setSelectedPrescriptionId(null)}
				position='center'
				primaryButtonLabel={translate('delete')}>
				<form>
					<h3>{translate('delete')}</h3>
					<p>
						{translate('areYouSureDeleteItem', {
							value: intl.formatMessage({ id: 'prescription' }),
						})}
					</p>
				</form>
			</Modal>
			{shouldShowAddEdit && (
				<AddEditPrescription
					prescriptions={props.prescriptions}
					type={MedicalInfoTypes.PRESCRIPTIONS}
					visitId={props.visitId}
					setMedicalInfo={props.setMedicalInfo}
					selectedMedicine={selectedMedicine}
					setSelectedMedicine={setSelectedMedicine}
					closePrescriptions={() => setShouldShowAddEdit(false)}
				/>
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
			<PopUpAlert
				alertType={AlertTypes.DANGER}
				display={medicalItemExists}
				onAlertClose={() => setMedicalItemExists(null)}
				contentText={medicalItemExists}
				isSilent={true}
				center={true}
			/>
		</aside>
	);
};

export default PrescriptionsWrapper;
