import { getAssignedMembers } from 'api/dashboard.js';
import Alert from 'components/Alert.jsx';
import ProvidersVisits from 'components/DashboardCharts/ProvidersVisits.jsx';
import VisitsByHour from 'components/DashboardCharts/VisitsByHour.jsx';
import Grid from 'components/Grid.jsx';
import translate from 'i18n-translations/translate.jsx';
import { doesParamValueExist, getUserTypeBasedOnCompanyRole } from 'infrastructure/helpers/commonHelpers.js';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

const AllProviders = props => {
	const intl = useIntl();
	const [providers, setProviders] = useState([]);
	const [pageIndex, setPageIndex] = useState(0);
	const [searchValue, setSearchValue] = useState('');
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(null);
	const { setSelectedProvider, setTotalNumberOfProviders } = props;
	const [currentRole, setCurrentRole] = useState({});

	useEffect(() => {
		setIsLoading(true);
		const fetchData = async () => {
			const response = await getAssignedMembers({
				pageIndex,
				...(doesParamValueExist(props.selectedHealthSystem) && { healthSystemId: props.selectedHealthSystem.value }),
				...(searchValue && { search: searchValue }),
				...(props.selectedTimezone.value && { timezone: props.selectedTimezone.value }),
				pageSize: 20,
			});
			if (!response.error) {
				setTotalNumberOfProviders(response.totalCount);
				if (props.display) {
					const providersList = response.users.reduce((acc, provider) => {
						if (provider.userIntId !== props.selectedProvider?.userIntId) {
							const newItem = { ...provider, memberRoles: filterRoles(provider.memberRoles) };
							acc.push(newItem);
						}
						return acc;
					}, []);

					const updatedSelectedProvider = response.users.find(
						provider => provider.userIntId === props.selectedProvider?.userIntId
					);

					if (pageIndex === 0 && !searchValue) {
						if (!props.selectedProvider && providersList.length > 0) {
							setSelectedProvider({ ...providersList[0] });
							setCurrentRole(providersList[0].memberRoles[0]);
							setProviders(providersList);
						} else {
							setSelectedProvider({
								...props.selectedProvider,
								...(updatedSelectedProvider && { memberRoles: filterRoles(updatedSelectedProvider.memberRoles) }),
							});
							setProviders([props.selectedProvider, ...providersList]);
							setCurrentRole(props.selectedProvider.memberRoles[0]);
						}
					}

					if (pageIndex > 0) {
						setProviders(prevState => [...prevState, ...providersList]);
					}

					if (searchValue && pageIndex === 0) {
						setProviders([props.selectedProvider, ...providersList]);
					}
				}
				setIsLoading(false);
				return;
			}
			setError(
				translate('somethingWentWrongSpecific', {
					value: intl.formatMessage({ id: 'providers' }),
				})
			);
		};
		fetchData();
	}, [pageIndex, props.selectedHealthSystem, searchValue, props.display, props.selectedTimezone]);

	const filterRoles = companyRoles =>
		companyRoles.reduce((acc, current) => {
			const isProvider = props.providersRoles.find(provider => provider.id === getUserTypeBasedOnCompanyRole(current));
			if (isProvider) acc.push(isProvider);

			return acc;
		}, []);

	if (!props.display) {
		return <></>;
	}

	const handleClickBack = () => {
		setSearchValue('');
		setSelectedProvider(null);
		setProviders([]);
		setPageIndex(0);
	};

	return (
		<>
			<div className='nurses-pagination-list'>
				<div className='flex'>
					<div onClick={handleClickBack}>
						<i className='material-icons-outlined'>arrow_back</i>
					</div>
					<span>
						{props.selectedProvider.firstName} {props.selectedProvider.lastName}
					</span>
				</div>
			</div>
			<div>
				<div className='top-15 dashboard-items'>
					<VisitsByHour
						currentRole={currentRole}
						start={props.start}
						end={props.end}
						selectedHealthSystem={props.selectedHealthSystem}
						selectedHospital={props.selectedHospital}
						selectedDepartment={props.selectedDepartment}
						selectedFloor={props.selectedFloor}
						selectedRoom={props.selectedRoom}
						xLabelScales={3}
						selectedProvider={props.selectedProvider}
						isForSpecificNurse={true}
						selectedTimezone={props.selectedTimezone}
					/>
				</div>
				<Grid columns='1fr' gridGap='15px' className='top-15 dashboard-items'>
					<ProvidersVisits
						currentRole={currentRole}
						setCurrentRole={setCurrentRole}
						start={props.start}
						end={props.end}
						selectedHealthSystem={props.selectedHealthSystem}
						selectedHospital={props.selectedHospital}
						selectedDepartment={props.selectedDepartment}
						selectedFloor={props.selectedFloor}
						selectedRoom={props.selectedRoom}
						selectedProvider={props.selectedProvider}
						selectedTimezone={props.selectedTimezone}
					/>
				</Grid>
			</div>
			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
		</>
	);
};

export default AllProviders;
