import React, { Component } from 'react';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import Modal from 'components/Modal.jsx';
import Form from 'components/Form.jsx';
import SocketEvents from 'constants/socket-events.js';
import { SerialTVCommands } from 'constants/enums.js';
import DropupItem from 'components/DropupItem.jsx';
import translate from 'i18n-translations/translate.jsx';

export class TvControls extends Component {
	state = {
		volumeRange: 0,
		powerTv: false,
		showHdmiOptions: false,
		showVolumeControls: false,
		showCecNotSupportedModal: false,
		isCecSupported: false,
	};

	amwellImgUrl = 'https://static.solaborate.com/americanwell/';

	constructor(props, context) {
		super(props, context);
		this.callManager = props.callManagerInstance;
		this.hdmiTypeButtons = [
			{
				title: `${this.props.intl.formatMessage({ id: 'togglePatientTVtoHDMI' })}1`,
				icon: 'looks_one',
				hdmiType: SerialTVCommands.HDMI.SWITCH_HDMI1,
				image: `${healthCareCdnUrl}footer-icons/hdmi_1.svg?v2`,
			},
			{
				title: `${this.props.intl.formatMessage({ id: 'togglePatientTVtoHDMI' })}2`,
				icon: 'looks_two',
				hdmiType: SerialTVCommands.HDMI.SWITCH_HDMI2,
				image: `${healthCareCdnUrl}footer-icons/hdmi_2.svg?v2`,
			},
			{
				title: `${this.props.intl.formatMessage({ id: 'togglePatientTVtoHDMI' })}3`,
				icon: 'looks_3',
				hdmiType: SerialTVCommands.HDMI.SWITCH_HDMI3,
				image: `${healthCareCdnUrl}footer-icons/hdmi_3.svg?v2`,
			},
		];
	}

	componentDidMount() {
		this.context.on(SocketEvents.HelloDevice.ON_TV_RESPONSE, this.onTvResponse);
	}

	componentWillUnmount = () => {
		this.context.off(SocketEvents.HelloDevice.ON_TV_RESPONSE, this.onTvResponse);
	};

	onTvResponse = data => {
		if (!data.isSuccessful) {
			return;
		}

		if (data.tvState.isVolume) {
			this.setState({
				// volumeStatus: data, Not used ATM
				volumeRange: data.tvState.tvStatus,
			});
			return;
		}

		switch (data.tvState.tvStatus) {
			case SerialTVCommands.INITIAL_TV_POWER:
				this.setState({
					powerTv: true,
				});
				break;
			case SerialTVCommands.POWER.POWER_ON:
			case SerialTVCommands.POWER.POWER_OFF:
				this.setState({
					powerTv: data.tvState.tvStatus !== SerialTVCommands.POWER.POWER_OFF,
				});
				break;
			case SerialTVCommands.HDMI.SWITCH_HDMI1:
			case SerialTVCommands.HDMI.SWITCH_HDMI2:
			case SerialTVCommands.HDMI.SWITCH_HDMI3:
				// this.setState({ hdmiStatus: data }); not used ATM
				break;
			default:
				break;
		}

		this.setState({
			isCecSupported: data.tvState.isCecSupported,
		});
	};

	serialCommandsTv = async (commandType, volumeRange) => {
		switch (commandType) {
			case 'Power': {
				const tvPowerStatus = this.state.powerTv ? SerialTVCommands.POWER.POWER_OFF : SerialTVCommands.POWER.POWER_ON;
				await this.callManager.serialCommandsTv(
					tvPowerStatus,
					false,
					this.props.helloDeviceId,
					this.props.conferenceId,
					this.props.participantId
				);
				break;
			}
			case 'Volume': {
				this.setState({
					volumeRange: volumeRange,
				});

				if (this.volumeTimeout) {
					clearTimeout(this.volumeTimeout);
				}
				// Delays this function call when we adjust the volume to prevent sending many requests
				this.volumeTimeout = setTimeout(() => {
					this.callManager.serialCommandsTv(
						this.state.volumeRange,
						true,
						this.props.helloDeviceId,
						this.props.conferenceId,
						this.props.participantId
					);
				}, 1000);

				break;
			}
			default:
				break;
		}
	};

	onHdmiTypeChanged = async hdmi => {
		await this.callManager.serialCommandsTv(
			hdmi,
			false,
			this.props.helloDeviceId,
			this.props.conferenceId,
			this.props.participantId
		);
	};

	toggleHdmiControls = () => {
		this.setState(prevState => ({
			showHdmiOptions: !prevState.showHdmiOptions,
			showVolumeControls: false,
		}));
	};

	toggleVolumeControls = () => {
		this.setState(prevState => ({
			showVolumeControls: !prevState.showVolumeControls,
			showHdmiOptions: false,
		}));
	};

	changeVolume = increaseVolume => {
		this.setState(
			{
				volumeRange: increaseVolume ? 1 : 0,
			},
			() => {
				this.serialCommandsTv('Volume', this.state.volumeRange);
			}
		);
	};

	checkIfCecSupported = next => {
		if (this.state.isCecSupported) {
			if (next instanceof Function) {
				next();
			}
		} else {
			this.toggleCecNotSupportedModal();
		}
	};

	toggleCecNotSupportedModal = () => {
		this.setState(prevState => ({
			showCecNotSupportedModal: !prevState.showCecNotSupportedModal,
		}));
	};

	render() {
		return (
			<>
				<DropupItem
					isActive={this.state.powerTv}
					onClick={() =>
						this.checkIfCecSupported(() => {
							this.serialCommandsTv('Power');
						})
					}
					icon='power_settings_new'
					content={this.state.powerTv ? translate('powerOffTV') : translate('powerOnTV')}
					hasActiveToggle={true}
				/>
				<DropupItem
					icon='volume_up'
					content={
						<div className='flex volume-dropdown'>
							<button
								type='button'
								onClick={() =>
									this.checkIfCecSupported(() => {
										this.changeVolume(false);
									})
								}>
								<i className='material-icons'>remove</i>
							</button>
							<span>{translate('volume')}</span>
							<button
								type='button'
								onClick={() =>
									this.checkIfCecSupported(() => {
										this.changeVolume(true);
									})
								}>
								<i className='material-icons'>add</i>
							</button>
						</div>
					}
				/>
				<DropupItem
					onClick={() =>
						this.checkIfCecSupported(() => {
							this.onHdmiTypeChanged(SerialTVCommands.HDMI.SWITCH_HDMI1);
						})
					}
					imgSrc={`${this.amwellImgUrl}hdmi-port-1.svg?v2`}
					content={translate('switchToHelloSource')}
				/>
				<Modal
					display={this.state.showCecNotSupportedModal}
					position='center'
					closeButtonText='Close'
					primaryButtonLabel='OK'
					hideCloseButton={true}
					onModalSubmit={this.toggleCecNotSupportedModal}
					onModalClose={this.toggleCecNotSupportedModal}>
					<Form title='Unsupported TV'>
						<p>{translate('commandNotSupported')}</p>
					</Form>
				</Modal>
			</>
		);
	}
}

TvControls.contextType = SocketContext;
export default TvControls;
