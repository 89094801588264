import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { conference as Conference, enums, participant as Participant } from '@solaborate/calls';
import { Cam, Mic } from '@solaborate/calls/webrtc';
import styled, { css } from 'styled-components';
import translate from 'i18n-translations/translate.jsx';

import ConferenceProvider from 'calls/ConferenceProvider.jsx';
import {
	useConference,
	useConferenceConfigurations,
	useConferenceState,
	useControllerTracks,
	useLocalParticipant,
} from 'calls/hooks/index.js';
import { Duration, IconButton, Tooltip } from 'calls/components/index.js';
import { callTypeToTrackTypes } from 'calls/helpers/index.js';
import { ConferenceEndReasonMessages, ControlsActions } from 'calls/enums/index.js';
import LightTheme from 'calls/styles/LightTheme.js';
import {
	CallEndIcon,
	VideocamIcon,
	VideocamOffIcon,
	MicIcon,
	MicOffIcon,
	InviteUserIcon,
	MaximizeIcon,
	MinimizeIcon,
} from 'calls/icons/index.js';
import { ParticipantsView, InviteParticipantsView, MainParticipantView, MainParticipantViewMayo } from 'calls/views/index.js';
import { getCallsButtonBackground, getCallsButtonColor, setUserBackground } from 'infrastructure/helpers/commonHelpers.js';
import { busySound, dropSound, stopOutgoingCallSound } from 'components/CallSounds.jsx';
import RemoveParticipantModal from 'components/Modal.jsx';
import { UserPermissionDeniedErrors } from 'constants/enums.js';
import { useSelector } from 'react-redux';
import { getBlobSasPicture } from 'api/doctors.js';

const StyledObserverConference = styled.div`
	position: fixed;
	bottom: ${LightTheme.spacing[7]}px;
	left: ${LightTheme.spacing[5]}px;
	width: 500px;
	background: ${LightTheme.colors.grayFive};
	border-radius: 5px;
	padding: ${LightTheme.spacing[5]}px;
	box-shadow:
		rgba(0, 0, 0, 0.09) 0px 2px 1px,
		rgba(0, 0, 0, 0.09) 0px 4px 2px,
		rgba(0, 0, 0, 0.09) 0px 8px 4px,
		rgba(0, 0, 0, 0.09) 0px 16px 8px,
		rgba(0, 0, 0, 0.09) 0px 32px 16px;
	z-index: 1000;

	> p {
		margin: 0;
		padding: 0;
		color: var(--gray-0);
	}

	> aside {
		position: ${props => (props.isGridView ? 'relative' : 'fixed')};
		height: auto;
		max-height: 410px;
		overflow: auto;

		~ footer {
			padding: ${LightTheme.spacing[3]}px 0 ${LightTheme.spacing[2]}px;
			flex-direction: column;
		}
	}

	> aside > section {
		padding: 0;
	}

	> footer {
		display: flex;
		align-items: center;

		> p {
			margin: 0;
			padding: 0;
			color: var(--gray-0);
		}

		> div {
			margin-left: auto;
		}
	}

	> main {
		display: flex;
		justify-content: center;

		> div {
			margin-right: ${LightTheme.spacing[1]}px;

			&:last-of-type {
				margin: 0;
			}
		}
	}

	+ div {
		> div {
			z-index: 9999;
		}
	}

	${props =>
		!props.isMinimizedView &&
		css`
			top: 0;
			left: 0;
			width: 100%;
			height: 100vh;
			padding: 0;

			> main,
			> footer {
				position: fixed;
				bottom: 0;
				z-index: 10;
			}

			> main {
				&:first-of-type {
					${!props.isGridView &&
					css`
						height: 100%;
						display: flex;
						align-items: center;
						justify-content: center;
					`}
				}
				width: 100%;
			}

			> aside {
				max-height: none;
				height: 100vh;
				right: var(--spacing-l);
				margin-right: auto;

				~ footer {
					align-items: flex-start;
				}

				~ main,
				~ footer {
					padding: ${LightTheme.spacing[5]}px;
				}
			}
		`}
`;

/**
 * @param {object} props
 * @param {object[]} props.participantsToCall
 */
const InitiatingConference = ({ participantsToCall }) => {
	const conference = useConference();
	const [message, setMessage] = useState('');
	const intl = useIntl();

	useEffect(() => {
		let startConferenceMessage = `${intl.formatMessage({ id: 'calling' })}`;
		if (participantsToCall?.length === 1 && participantsToCall[0].objectType === enums.ObjectTypes.USER) {
			startConferenceMessage = `${startConferenceMessage} ${participantsToCall[0].firstName} ${participantsToCall[0].lastName}`;
		} else if (participantsToCall?.length > 1 && conference.callType === enums.CallTypes.FIRST_RESPONDER) {
			startConferenceMessage = `${startConferenceMessage} ${intl.formatMessage({ id: 'nurses' })}`;
		} else if (conference.conferenceName && conference.callType === enums.CallTypes.SECURITYCAM) {
			startConferenceMessage = `${intl.formatMessage({ id: 'viewing' })} ${conference.conferenceName}`;
		} else if (conference.conferenceName) {
			startConferenceMessage = `${startConferenceMessage} ${conference.conferenceName}`;
		} else {
			startConferenceMessage = `${intl.formatMessage({ id: 'initiatingCall' })}`;
		}
		setMessage(startConferenceMessage);
	}, [conference, intl, participantsToCall]);

	return <>{message}</>;
};

/**
 * @param {object} props
 * @param {object[]} props.participantsToCall
 * @param {boolean} [props.permissionsDenied]
 */
const ConferenceEnded = ({ participantsToCall, permissionsDenied }) => {
	const conference = useConference();
	const conferenceState = useConferenceState();

	const [message, setMessage] = useState('');

	useEffect(() => {
		if (conferenceState instanceof Conference.StateEnded) {
			let endReason = ConferenceEndReasonMessages[conferenceState.endReason];

			if (!endReason) {
				setMessage(permissionsDenied ? translate('allowPermissions') : endReason);
				return;
			}

			if (
				conference.callType === enums.CallTypes.FIRST_RESPONDER &&
				(enums.ConferenceEndReasons.PARTICIPANT_OFFLINE === conferenceState.endReason ||
					enums.ConferenceEndReasons.PARTICIPANT_BUSY === conferenceState.endReason)
			) {
				if (participantsToCall?.length === 1) {
					endReason = translate(
						enums.ConferenceEndReasons.PARTICIPANT_OFFLINE === conferenceState.endReason
							? 'deviceOfflineTryLaterExtended'
							: 'deviceOnCallTryLaterExtended',
						{ value1: `${participantsToCall[0].firstName} ${participantsToCall[0].lastName} ` }
					);
				} else {
					endReason = translate('nursesUnavailable');
				}
			}
			setMessage(endReason);
		}
	}, [conference, conferenceState, participantsToCall]);

	return <>{message}</>;
};

/**
 * @param {object} props
 * @param {object[]} props.participantsToCall
 * @param {() => void} props.onCallEnded
 * @param {() => void} props.onCallStarted
 * @param {boolean} props.isEmergencyCallOpen
 * @param {boolean} [props.isJoin=false]
 * @param {string} props.roomId
 */
const ConferenceModalComponent = props => {
	const { participantsToCall, isJoin, roomId, isDarkMode } = props;
	const intl = useIntl();
	const conference = useConference();
	const conferenceState = useConferenceState();
	const localParticipant = useLocalParticipant();
	const localTracks = useControllerTracks(localParticipant.localTrackController);
	const conferenceConfigs = useConferenceConfigurations();
	const [permissionsDenied, setPermissionsDenied] = useState(false);
	const userSession = useSelector(state => state.user.userSession);
	const afterConferenceEndedTimeoutRef = useRef(null);
	const mainParticipantVideoRef = useRef(null);

	const [mainParticipant, setMainParticipant] = useState(conference.localParticipant);
	const [callStarted, setCallStarted] = useState(false);
	const [isInviteParticipantViewOpen, setIsInviteParticipantViewOpen] = useState(false);
	const [activeTrackType, setActiveTrackType] = useState(Cam);
	const visualSettings = useSelector(state => state.configurations.unboundHealthSystemSettings.visualsSettings);
	const userSettings = useSelector(state => state.configurations.userSettings);
	const isNewExperience = useSelector(state => state.configurations.isNewExperience);

	useEffect(() => {
		return conference.on(event => {
			if (event instanceof Conference.ParticipantAdded) {
				event.participant.on(state => {
					if (state instanceof Participant.StateConnected) {
						setCallStarted(true);
						stopOutgoingCallSound();
						setMainParticipant(event.participant);
						setActiveTrackType(Cam);
					}
				});
			}
			if (event instanceof Conference.ParticipantRemoved) {
				if (event.participant?.id === conferenceConfigs.pinnedParticipantId) {
					conferenceConfigs.setPinnedParticipantId('');
				}
			}
			if (event instanceof Conference.StateEnded) {
				conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_MINIMIZED_VIEW, true);
				const playConferenceEndedSound = async () => {
					switch (event.endReason) {
						case enums.ConferenceEndReasons.PARTICIPANT_OFFLINE:
						case enums.ConferenceEndReasons.PARTICIPANT_BUSY:
						case enums.ConferenceEndReasons.PARTICIPANT_NOT_ANSWERING:
						case enums.ConferenceEndReasons.PARTICIPANT_DECLINED:
						case enums.ConferenceEndReasons.HAS_ACTIVE_CONFERENCE:
							stopOutgoingCallSound();
							await busySound();
							break;
						case enums.ConferenceEndReasons.OWNER_LEFT:
						case enums.ConferenceEndReasons.TERMINATED_BY_ADMINISTRATOR:
							stopOutgoingCallSound();
							await dropSound();
							break;
						default:
							stopOutgoingCallSound();
							break;
					}
				};
				playConferenceEndedSound();
			}
		});
	}, [conference, conferenceConfigs]);

	useEffect(() => {
		setPermissionsDenied(false);
		if (conference.callType !== enums.CallTypes.SECURITYCAM) {
			conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_GRID_VIEW, true);
		}
		conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_MINIMIZED_VIEW, true);
		conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_EMBEDDED_VIEW, true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [conference.callType]);

	useEffect(() => {
		if (
			!conferenceConfigs.pinnedParticipantId &&
			!conferenceConfigs.isGridView &&
			conference.callType !== enums.CallTypes.SECURITYCAM
		) {
			conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_GRID_VIEW, true);
		}

		if (
			conferenceConfigs.isMinimizedView &&
			!conferenceConfigs.isGridView &&
			conference.callType !== enums.CallTypes.SECURITYCAM
		) {
			conferenceConfigs.setPinnedParticipantId('');
		}
	}, [conference.callType, conferenceConfigs]);

	const startConference = async () => {
		if (!(conference.state instanceof Conference.StateInitialized)) {
			return;
		}

		await conference.socket.authPromise;

		try {
			await localParticipant.localTrackController.add(localParticipant.requestedLocalTracks);
			if (localParticipant.localTrackController.tracks[Cam]) {
				setUserBackground({
					localParticipant,
					userSettings,
					visualSettings,
					getBlobSasPicture,
					healthSystemId: userSession.healthSystem.id,
				});
			}
		} catch (error) {
			if (error?.error?.name === UserPermissionDeniedErrors.NotAllowedError) {
				setPermissionsDenied(true);
				conference.close();
				return;
			}
		}

		localParticipant.remoteTrackController.request(callTypeToTrackTypes(conference.callType).remoteTrackTypes);

		let isSuccessful;
		if (isJoin) {
			isSuccessful = await conference.join();
		} else {
			isSuccessful = await conference.start(participantsToCall);
		}

		if (isSuccessful) {
			conference.logger.debug(`Conference ${isJoin ? 'join()' : 'start()'} successful.`);
		}
	};

	const endCall = async evt => {
		evt.stopPropagation();
		conference.leave();
		conference.close(enums.ConferenceEndReasons.PARTICIPANT_LEFT);
	};

	useEffect(() => {
		startConference();

		return () => {
			stopOutgoingCallSound();
		};
		// Disable reason: useEffect should be invoked once
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const onBeforeUnload = event => {
			if (!(conference.state instanceof Conference.StateEnded)) {
				event.preventDefault();
				// eslint-disable-next-line no-param-reassign
				event.returnValue = '';
			}
		};

		const onUnload = () => {
			if (!(conference.state instanceof Conference.StateEnded)) {
				conference.leave();
			}
		};

		window.addEventListener('beforeunload', onBeforeUnload);
		window.addEventListener('unload', onUnload);

		const unbindConferenceEvents = conference.on(event => {
			if (event instanceof Conference.StateEnded) {
				afterConferenceEndedTimeoutRef.current = () => {
					setTimeout(props.onCallEnded, 3000);
				};
				afterConferenceEndedTimeoutRef.current();
			}
		});

		return () => {
			unbindConferenceEvents();

			if (afterConferenceEndedTimeoutRef.current) {
				clearTimeout(afterConferenceEndedTimeoutRef.current);
			}

			window.removeEventListener('beforeunload', onBeforeUnload);
			window.removeEventListener('unload', onUnload);
		};
	}, [conference, props.onCallEnded]);

	const iconSize = conferenceConfigs.isMinimizedView ? 18 : 24;

	return (
		<>
			<StyledObserverConference isMinimizedView={conferenceConfigs.isMinimizedView} isGridView={conferenceConfigs.isGridView}>
				{conferenceState instanceof Conference.StateEnded && (
					<p>
						<ConferenceEnded participantsToCall={participantsToCall} permissionsDenied={permissionsDenied} />
					</p>
				)}
				{!callStarted && !(conferenceState instanceof Conference.StateEnded) && (
					<footer>
						<p>
							<InitiatingConference participantsToCall={participantsToCall} />
						</p>
						<Tooltip text={intl.formatMessage({ id: 'endCall' })}>
							<IconButton
								background={LightTheme.colors.redOne}
								color={LightTheme.colors.grayZero}
								onClick={evt => {
									evt.stopPropagation();
									conference.leave();
								}}>
								<CallEndIcon height={iconSize} width={iconSize} />
							</IconButton>
						</Tooltip>
					</footer>
				)}
				{callStarted && !(conferenceState instanceof Conference.StateEnded) && (
					<>
						{((!conferenceConfigs.isGridView && conferenceConfigs.pinnedParticipantId) ||
							conference.callType === enums.CallTypes.SECURITYCAM) && (
							<>
								{!isNewExperience && (
									<MainParticipantView
										ref={mainParticipantVideoRef}
										participant={mainParticipant}
										activeTrackType={activeTrackType}
									/>
								)}
								{isNewExperience && (
									<MainParticipantViewMayo
										ref={mainParticipantVideoRef}
										participant={mainParticipant}
										activeTrackType={activeTrackType}
									/>
								)}
							</>
						)}

						{conference.callType !== enums.CallTypes.SECURITYCAM && (
							<ParticipantsView
								mainParticipant={mainParticipant}
								setMainParticipant={setMainParticipant}
								setActiveTrackType={setActiveTrackType}
							/>
						)}
						<footer className='gap-s'>
							{conference?.conferenceName && <p>{conference.conferenceName}</p>}
							<Duration startTime={conference.startTime} />
						</footer>
						<main>
							{conference.callType !== enums.CallTypes.SECURITYCAM && (
								<Tooltip
									text={
										localTracks[Cam] ? intl.formatMessage({ id: 'turnCameraOff' }) : intl.formatMessage({ id: 'turnCameraOn' })
									}>
									<IconButton
										background={getCallsButtonBackground(isDarkMode)}
										color={getCallsButtonColor(isDarkMode)}
										onClick={async evt => {
											evt.preventDefault();
											evt.stopPropagation();
											try {
												await localParticipant.localTrackController.toggle(Cam);
												if (localParticipant.localTrackController.tracks[Cam]) {
													setUserBackground({
														localParticipant,
														userSettings,
														visualSettings,
														getBlobSasPicture,
														healthSystemId: userSession.healthSystem.id,
													});
												}
											} catch (error) {
												// handleLocalCameraErrors(error);
											}
										}}>
										{localTracks[Cam] ? (
											<VideocamIcon height={iconSize} width={iconSize} color={getCallsButtonColor(isDarkMode)} />
										) : (
											<VideocamOffIcon height={iconSize} width={iconSize} color={getCallsButtonColor(isDarkMode)} />
										)}
									</IconButton>
								</Tooltip>
							)}
							<Tooltip
								text={localTracks[Mic] ? intl.formatMessage({ id: 'turnOffMic' }) : intl.formatMessage({ id: 'turnOnMic' })}>
								<IconButton
									background={getCallsButtonBackground(isDarkMode)}
									color={getCallsButtonColor(isDarkMode)}
									onClick={async evt => {
										evt.preventDefault();
										evt.stopPropagation();
										try {
											await localParticipant.localTrackController.toggle(Mic);
										} catch (error) {
											// handleLocalParticipantMicErrors(error);
										}
									}}>
									{localTracks[Mic] ? (
										<MicIcon height={iconSize} width={iconSize} color={getCallsButtonColor(isDarkMode)} />
									) : (
										<MicOffIcon height={iconSize} width={iconSize} color={getCallsButtonColor(isDarkMode)} />
									)}
								</IconButton>
							</Tooltip>
							<Tooltip text={intl.formatMessage({ id: 'endCall' })}>
								<IconButton background={LightTheme.colors.redOne} color={LightTheme.colors.grayZero} onClick={endCall}>
									<CallEndIcon height={iconSize} width={iconSize} />
								</IconButton>
							</Tooltip>
							{conference.callType !== enums.CallTypes.FIRST_RESPONDER && conference.callType !== enums.CallTypes.SECURITYCAM && (
								<Tooltip text={intl.formatMessage({ id: 'invitePeople' })}>
									<IconButton
										background={getCallsButtonBackground(isDarkMode)}
										color={getCallsButtonColor(isDarkMode)}
										onClick={evt => {
											evt.stopPropagation();
											setIsInviteParticipantViewOpen(true);
										}}>
										<InviteUserIcon height={iconSize} width={iconSize} color={getCallsButtonColor(isDarkMode)} />
									</IconButton>
								</Tooltip>
							)}
							<Tooltip text={intl.formatMessage({ id: conferenceConfigs.isMinimizedView ? 'maximizeFeed' : 'minimizeFeed' })}>
								<IconButton
									background={getCallsButtonBackground(isDarkMode)}
									color={getCallsButtonColor(isDarkMode)}
									onClick={evt => {
										evt.stopPropagation();
										conferenceConfigs.onConfigurationToggleAction(ControlsActions.TOGGLE_MINIMIZED_VIEW);
									}}>
									{conferenceConfigs.isMinimizedView ? (
										<MaximizeIcon height={iconSize} width={iconSize} color={getCallsButtonColor(isDarkMode)} />
									) : (
										<MinimizeIcon height={iconSize} width={iconSize} color={getCallsButtonColor(isDarkMode)} />
									)}
								</IconButton>
							</Tooltip>
						</main>
						{conferenceConfigs.removeParticipantModal.isOpen && (
							<RemoveParticipantModal
								display={true}
								onModalClose={() =>
									conferenceConfigs.setRemoveParticipantModal({ isOpen: false, modalMessage: '', onSubmit: () => {} })
								}
								primaryButtonLabel={intl.formatMessage({ id: 'removeParticipant' })}
								onModalSubmit={() => conferenceConfigs.removeParticipantModal.onSubmit()}
								position='center'>
								<form>
									<h4>{intl.formatMessage({ id: 'confirmParticipantRemoval' })}</h4>
									<p>{conferenceConfigs.removeParticipantModal.modalMessage}</p>
								</form>
							</RemoveParticipantModal>
						)}
					</>
				)}
			</StyledObserverConference>
			{isInviteParticipantViewOpen && (
				<InviteParticipantsView
					roomId={roomId}
					onDismiss={() => setIsInviteParticipantViewOpen(false)}
					configurations={{
						isMeetingURLOn: true,
						isInviteViaSmsOn: true,
						isDialInOn: true,
						isInviteViaEmailOn: true,
						isTranslationServicesOn: true,
					}}
				/>
			)}
		</>
	);
};

const ConferenceModal = props => {
	const { incomingConferenceInfo, startConferenceData, isDarkMode, ...rest } = props;

	const callType = incomingConferenceInfo?.callType || startConferenceData?.callType;

	const conferenceInfo = {
		callType,
		...(incomingConferenceInfo ? { conferenceId: incomingConferenceInfo.conferenceId } : null),
		...(incomingConferenceInfo ? { participantId: incomingConferenceInfo.participantId } : null),
		conferenceName: incomingConferenceInfo?.conferenceName || startConferenceData?.conferenceName,
	};

	if (!conferenceInfo.conferenceId && !startConferenceData?.participants?.length) {
		// eslint-disable-next-line no-console
		console.warn(`Missing participants to call!`);
		props.onCallEnded();
		return <></>;
	}

	return (
		<ConferenceProvider conferenceInfo={conferenceInfo}>
			<ConferenceModalComponent
				{...rest}
				participantsToCall={startConferenceData?.participants}
				isJoin={!!conferenceInfo.conferenceId}
				roomId={startConferenceData.roomId}
				isDarkMode={isDarkMode}
			/>
		</ConferenceProvider>
	);
};

export default ConferenceModal;
