import React, { useState } from 'react';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import { DeviceSettingType, ScreenMode } from 'constants/enums.js';
import { useSelector } from 'react-redux';
import { RoomModesSettings } from 'constants/configurationEnums.js';
import { EmptyState } from 'components/index.js';
import classNames from 'classnames';

const healthCareCdnUrlAdmin = `${healthCareCdnUrl}admin/`;
const modesList = [
	{
		settingTypeId: RoomModesSettings.HELLOCARE,
		name: translate('hellocareMode'),
		img: `${healthCareCdnUrlAdmin}hello-care-home.svg`,
		active: false,
		value: ScreenMode.HELLO_CARE_HOME,
		deviceSettingTypeId: DeviceSettingType.SCREEN_MODE,
	},
	{
		settingTypeId: RoomModesSettings.AI_WIDGET,
		name: translate('aiWidgetMode'),
		img: `${healthCareCdnUrlAdmin}ai-widget.svg`,
		active: false,
		value: ScreenMode.WIDGET_AI,
		deviceSettingTypeId: DeviceSettingType.SCREEN_MODE,
	},
	{
		settingTypeId: RoomModesSettings.WIDGET,
		name: translate('widgetMode'),
		img: `${healthCareCdnUrlAdmin}ai-widget.svg`,
		active: false,
		value: ScreenMode.WIDGET,
		deviceSettingTypeId: DeviceSettingType.SCREEN_MODE,
	},
	{
		settingTypeId: RoomModesSettings.DIGITAL_CLINICIAN,
		name: translate('digitalClinicMode'),
		img: `${healthCareCdnUrlAdmin}DigitalClinic.svg`,
		active: false,
		value: ScreenMode.DIGITAL_CLINIC,
		deviceSettingTypeId: DeviceSettingType.SCREEN_MODE,
	},
	{
		settingTypeId: RoomModesSettings.TELEMEDICINE,
		name: translate('telemedicineMode'),
		img: `${healthCareCdnUrlAdmin}telemedicine-mode.svg`,
		active: false,
		value: ScreenMode.TELEMEDICINE,
		deviceSettingTypeId: DeviceSettingType.SCREEN_MODE,
	},
	{
		settingTypeId: RoomModesSettings.HOSPITAL,
		name: translate('hospitalMode'),
		img: `${healthCareCdnUrl}treeview/Hospital-active.svg`,
		active: false,
		value: ScreenMode.HOSPITAL,
		deviceSettingTypeId: DeviceSettingType.SCREEN_MODE,
	},
	{
		settingTypeId: RoomModesSettings.NICU,
		name: translate('nicuMode'),
		img: `${healthCareCdnUrlAdmin}baby-blue.svg`,
		active: false,
		value: ScreenMode.NICU,
		deviceSettingTypeId: DeviceSettingType.SCREEN_MODE,
	},
	{
		settingTypeId: RoomModesSettings.VIRTUAL_EXAMINATION,
		name: translate('virtualExaminationMode'),
		img: `${healthCareCdnUrlAdmin}virtual-examination.svg`,
		active: false,
		value: ScreenMode.VIRTUAL_EXAMINATION,
		deviceSettingTypeId: DeviceSettingType.SCREEN_MODE,
	},
	{
		settingTypeId: RoomModesSettings.SIMPLIFIED,
		name: translate('simplifiedMode'),
		img: `${healthCareCdnUrlAdmin}simplified.svg`,
		active: false,
		value: ScreenMode.SIMPLIFIED,
		deviceSettingTypeId: DeviceSettingType.SCREEN_MODE,
	},
	{
		settingTypeId: RoomModesSettings.E_VIDEON,
		name: translate('eVideonMode'),
		img: `${healthCareCdnUrlAdmin}tv.svg`,
		active: false,
		value: ScreenMode.EVIDEON,
		deviceSettingTypeId: DeviceSettingType.SCREEN_MODE,
	},
	{
		settingTypeId: RoomModesSettings.HELLOCARE_ENGAGEMENT,
		name: translate('hellocareEngagement'),
		img: `${healthCareCdnUrlAdmin}monitoring.svg`,
		active: false,
		value: ScreenMode.PATIENT_EXPERIENCE,
		deviceSettingTypeId: DeviceSettingType.SCREEN_MODE,
	},
];

const SelectMode = ({ setSelectedMode, roomType, modeData, isLoading }) => {
	const companyConfigurations = useSelector(state => state.company.companySettings?.roomModesConfigurations);
	const [loadingMode, setLoadingMode] = useState();

	const getModeConfigurationValue = (settingTypeId, modeType) =>
		companyConfigurations[`${settingTypeId}${modeType}`] ? companyConfigurations[`${settingTypeId}${modeType}`].value : false;

	const filteredModes = modesList.filter(room => getModeConfigurationValue(room.settingTypeId, roomType));
	return (
		<div className='configurable-settings-modal-inner'>
			<h3>{translate('selectMode')}</h3>
			<div className='flex flex-wrap grid-50'>
				{filteredModes.map(item => (
					<div
						key={item.settingTypeId}
						className='flex'
						onClick={() => {
							if (item.value === modeData.value) {
								return;
							}
							setLoadingMode(item.value);
							setSelectedMode(item.deviceSettingTypeId, item.value);
						}}>
						<img src={item.img} alt={item.name} /> <p>{item.name}</p>
						{modeData && (
							<div className={classNames('cursor-pointer', 'checked-circle', item.value === modeData.value ? 'active' : '')}>
								{!isLoading && <i className='material-icons'>done</i>}
								{item.value === loadingMode && isLoading && <span className='button loading' />}
							</div>
						)}
					</div>
				))}

				{filteredModes.length === 0 && <EmptyState title={translate('noData')} image='no-files.svg' />}
			</div>
		</div>
	);
};

export default SelectMode;
