import { getPatientCentricReport } from 'api/dashboard.js';
import Alert from 'components/Alert.jsx';
import Pagination from 'components/Common/Pagination.jsx';
import ProfilePicture from 'components/ProfilePicture.jsx';
import Table from 'components/Table.jsx';
import translate from 'i18n-translations/translate.jsx';
import { convertToTimestampSecondsWithMaxTime, getHumanizedDateWithHour } from 'infrastructure/helpers/dateHelper.js';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router';

const PatientCentricReport = ({
	start,
	end,
	selectedHealthSystem,
	selectedHospital,
	selectedDepartment,
	selectedFloor,
	selectedRoom,
	currentTab,
	searchValue,
	selectedTimezone,
	selectedLevel,
}) => {
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0, total: 0 });
	const [patients, setPatients] = useState([]);
	const intl = useIntl();
	const history = useHistory();
	const location = useLocation();

	useEffect(() => {
		const fetchPatients = async () => {
			setIsLoading(true);
			const params = {
				pageSize: pagination.pageSize,
				pageIndex: searchValue.length > 0 ? 0 : pagination.pageIndex,
				from: Math.floor(new Date(start).getTime() / 1000),
				to: Math.floor(convertToTimestampSecondsWithMaxTime(end)),
				search: searchValue,
				healthSystemId: selectedHealthSystem?.value ?? null,
				hospitalId: selectedHospital?.value ?? null,
				regionId: selectedHospital?.regionId ?? null,
				departmentId: selectedDepartment?.value ?? null,
				floorId: selectedFloor?.value ?? null,
				roomId: selectedRoom?.value ?? null,
			};
			const response = await getPatientCentricReport(params);
			if (response.error) {
				setError(response.error.message);
			} else {
				setPagination(response.pagination);
				setPatients(response.patients);
			}
			setIsLoading(false);
		};
		fetchPatients();
	}, [
		searchValue,
		pagination.pageIndex,
		pagination.pageSize,
		selectedRoom,
		selectedFloor,
		selectedDepartment,
		selectedHospital,
		selectedHealthSystem,
		start,
		end,
	]);

	const openViewSessions = patient => {
		history.push({
			pathname: `/dashboard/session`,
			state: {
				prevPath: location.pathname,
				prevTab: currentTab,
				patient,
				from: patient.admissionDate,
				to: patient.dischargeDate,
				search: '',
				healthSystemId: selectedHealthSystem ? selectedHealthSystem.value : null,
				hospitalId: selectedHospital ? selectedHospital.value : null,
				departmentId: selectedDepartment ? selectedDepartment.value : null,
				floorId: selectedFloor ? selectedFloor.value : null,
				roomId: selectedRoom ? selectedRoom.value : null,
				selectedTimezone: selectedTimezone ?? null,
				selectedLevel,
			},
		});
	};

	const getLocation = patient =>
		patient && (
			<div>
				{patient.healthSystemName && <span>{patient.healthSystemName}</span>}
				{patient.hospitalName && (
					<span>
						{' >'} {patient.hospitalName}
					</span>
				)}
				{patient.departmentName && (
					<span>
						{' >'} {patient.departmentName}
					</span>
				)}
				{patient.floorName && (
					<span>
						{' >'} {patient.floorName}
					</span>
				)}
				{patient.roomName && (
					<span>
						{' >'} {patient.roomName}
					</span>
				)}
			</div>
		);

	const displayPatients = () => {
		if (!patients || patients.length === 0 || isLoading) {
			return [];
		}
		return patients.map((patient, index) => ({
			id: patient.patientId + index,
			patient: (
				<>
					<div className='flex image-container'>
						<ProfilePicture
							fullName={patient.fullName}
							profilePicture={patient.profilePicture ?? null}
							className='provider-intervening-img-wrapper'
						/>
						<p>{patient.fullName}</p>
					</div>
					{patient.mrn && (
						<p style={{ margin: '0 0 0 var(--spacing-sl)', color: 'var(--blue-2)', padding: '0' }}>MRN {patient.mrn}</p>
					)}
				</>
			),
			location: getLocation(patient),
			admittedDate: getHumanizedDateWithHour(patient.admissionDate),
			dischargedDate: patient.dischargeDate ? getHumanizedDateWithHour(patient.dischargeDate) : 'N/A',
			actions: (
				<div className='flex position-relative table-buttons'>
					<button type='button' className='button white' onClick={() => openViewSessions(patient)}>
						{intl.formatMessage({ id: 'viewSessions' })}
					</button>
				</div>
			),
		}));
	};

	return (
		<>
			<div>
				<Table
					headers={[
						{ title: translate('patient'), id: 0 },
						{ title: translate('location'), id: 1 },
						{ title: translate('admittedDate'), id: 2 },
						{ title: translate('dischargedDate'), id: 3 },
						{ title: translate('actions'), id: 4 },
					]}
					rows={displayPatients()}
					isEditable={false}
					isLoading={isLoading}
				/>
				<div className='pagination-bar'>
					<Pagination
						totalCount={pagination.total}
						pageSize={pagination.pageSize}
						pageIndex={pagination.pageIndex}
						onChange={(size, index) => setPagination(prevState => ({ ...prevState, pageIndex: index, pageSize: size }))}
					/>
				</div>
			</div>
			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
		</>
	);
};

export default PatientCentricReport;
