import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import translate from 'i18n-translations/translate.jsx';
import { AiAlertNotificationType, PatientsAiSettings, RailType, SensitivityType } from 'constants/enums.js';
import Select from 'react-select';
import Alert from 'components/Alert.jsx';
import classNames from 'classnames';
import MultiSelectCheckboxes from 'components/MultiSelectCheckboxes.jsx';
import { AiNotificationTypes, CheckBoxTypes, RadioButtonTypes, StatNotificationTypes } from 'constants/ai.js';
import { aiFormatOptionLabel, convertStringToArray } from 'infrastructure/helpers/aiHelper.js';

const AiConfigurationFeatureFlags = ({
	aiConfigurationTypeId,
	isEnabled,
	title,
	selectedSensitivity,
	onSensitivityChange,
	selectedRails,
	onRailsChange,
	selectedSnoozeTime,
	onSnoozeTimeChange,
	onAlertNotificationChange,
	alertNotificationOptions,
}) => {
	const intl = useIntl();

	const sensitivityTypes = useRef([
		{ label: intl.formatMessage({ id: 'high' }), value: SensitivityType.HIGH },
		{ label: intl.formatMessage({ id: 'medium' }), value: SensitivityType.MEDIUM },
		{ label: intl.formatMessage({ id: 'low' }), value: SensitivityType.LOW },
	]);

	const snoozeTimers = useRef([
		{ label: `30 ${intl.formatMessage({ id: 'seconds' })}`, value: 30 },
		{ label: `1 ${intl.formatMessage({ id: 'minute' })}`, value: 60 },
		{ label: `2 ${intl.formatMessage({ id: 'minutes' })}`, value: 120 },
		{ label: `5 ${intl.formatMessage({ id: 'minutes' })}`, value: 300 },
		{ label: `10 ${intl.formatMessage({ id: 'minutes' })}`, value: 600 },
		{ label: `30 ${intl.formatMessage({ id: 'minutes' })}`, value: 1800 },
		{ label: `1 ${intl.formatMessage({ id: 'hour' })}`, value: 3600 },
	]);

	const railsOptions = useRef([
		{
			label: `${intl.formatMessage({ id: 'left' })} ${intl.formatMessage({ id: 'head' })} ${intl.formatMessage({ id: 'rail' })}`,
			value: RailType.TOP_LEFT,
		},
		{
			label: `${intl.formatMessage({ id: 'right' })} ${intl.formatMessage({ id: 'head' })} ${intl.formatMessage({ id: 'rail' })}`,
			value: RailType.TOP_RIGHT,
		},
		{
			label: `${intl.formatMessage({ id: 'left' })} ${intl.formatMessage({ id: 'foot' })} ${intl.formatMessage({
				id: 'rail',
			})} `,
			value: RailType.BOTTOM_LEFT,
		},

		{
			label: `${intl.formatMessage({ id: 'right' })} ${intl.formatMessage({ id: 'foot' })} ${intl.formatMessage({
				id: 'rail',
			})} `,
			value: RailType.BOTTOM_RIGHT,
		},
	]);

	const [error, setError] = useState(null);
	const [checkedItems, setCheckedItems] = useState([
		{
			aiConfigurationTypeId,
			alertNotificationOptions:
				alertNotificationOptions.length > 0 ? alertNotificationOptions.filter(item => CheckBoxTypes.includes(item)) : [],
		},
	]);
	const [radioButtons, setRadioButtons] = useState([
		{
			aiConfigurationTypeId,
			alertNotificationOptions:
				alertNotificationOptions.length > 0
					? alertNotificationOptions.filter(item => RadioButtonTypes.includes(item))
					: [AiAlertNotificationType.MODAL_ON_FEED],
		},
	]);

	const handleCheckboxChange = id => {
		setCheckedItems(prevCheckedItems => {
			const isChecked = prevCheckedItems.some(item => item.aiConfigurationTypeId === aiConfigurationTypeId);

			if (isChecked) {
				return prevCheckedItems.map(item =>
					item.aiConfigurationTypeId === aiConfigurationTypeId
						? {
								...item,
								alertNotificationOptions: item.alertNotificationOptions.includes(id)
									? item.alertNotificationOptions.filter(alertNotificationOptions => alertNotificationOptions !== id)
									: [...item.alertNotificationOptions, id],
						  }
						: item
				);
			} else {
				return [...prevCheckedItems, { aiConfigurationTypeId, alertNotificationOptions: [id] }];
			}
		});
	};

	const handleRadioChange = id => {
		setRadioButtons(prevCheckedItems => {
			const isChecked = prevCheckedItems.some(item => item.aiConfigurationTypeId === aiConfigurationTypeId);
			if (isChecked) {
				return prevCheckedItems.map(item =>
					item.aiConfigurationTypeId === aiConfigurationTypeId
						? {
								...item,
								alertNotificationOptions: [id],
						  }
						: item
				);
			} else {
				return [...prevCheckedItems, { aiConfigurationTypeId, alertNotificationOptions: [id] }];
			}
		});
	};

	const getMergedArr = () => {
		const mergedArray = checkedItems.map(checkedItem => {
			const foundRadioButton = radioButtons.find(
				radioItem => radioItem.aiConfigurationTypeId === checkedItem.aiConfigurationTypeId
			);
			if (foundRadioButton) {
				return {
					alertNotificationOptions: [...checkedItem.alertNotificationOptions, ...foundRadioButton.alertNotificationOptions],
				};
			} else {
				return checkedItem;
			}
		});
		radioButtons.forEach(radioItem => {
			const existsInMergedArray = mergedArray.some(
				mergedItem => mergedItem.aiConfigurationTypeId === radioItem.aiConfigurationTypeId
			);
			if (!existsInMergedArray) {
				mergedArray.push(radioItem);
			}
		});
		return mergedArray;
	};

	useEffect(() => {
		if (getMergedArr().length > 0) {
			onAlertNotificationChange(getMergedArr()[0].alertNotificationOptions);
		}
	}, [checkedItems, radioButtons]);

	const isItemEnabled = (items, alertId) =>
		items
			.find(el => el.aiConfigurationTypeId === aiConfigurationTypeId)
			?.alertNotificationOptions?.some(item => item === alertId);

	return (
		<div className={classNames('ai-feature-flags', isEnabled ? '' : 'disabled')}>
			<div>
				{aiConfigurationTypeId === PatientsAiSettings.PERSON_GETTING_OUT_OF_BED && (
					<div className='top-15'>
						<p>{translate('sensitivity')}</p>
						<div className='flex full-width margin-top-s ai-configurations-select'>
							<Select
								options={sensitivityTypes.current}
								value={sensitivityTypes.current.find(item => item.value === selectedSensitivity)}
								onChange={onSensitivityChange}
								classNamePrefix='custom-select'
								placeholder={intl.formatMessage({ id: 'sensitivity' })}
								formatOptionLabel={data => aiFormatOptionLabel(data, intl)}
							/>
						</div>
					</div>
				)}
				{aiConfigurationTypeId === PatientsAiSettings.RAIL_DOWN && (
					<div className='flex full-width margin-top-m ai-configurations-select'>
						<MultiSelectCheckboxes
							name='rails'
							selectedOptions={convertStringToArray(selectedRails)}
							reorderSelected
							options={railsOptions.current}
							placeholder={intl.formatMessage({ id: 'selectRails' })}
							onChange={onRailsChange}
							selectedItemsPlaceholder={intl.formatMessage({ id: 'rails' })}
							darkMode={false}
							isClearable={false}
							className='full-width'
							menulistTitle={intl.formatMessage({ id: 'sendRailsAlert' })}
							closeOnDeselectedLastItem
						/>
					</div>
				)}
				<div className='top-15'>
					<p>{translate('alerts')}</p>
					<div className='flex flex-wrap margin-top-s'>
						{AiNotificationTypes.map(alert => (
							<div className='flex position-relative consent-from-patient margin-right-m margin-top-s' key={alert.id}>
								<label className='remember-me cursor-pointer position-relative flex' key={alert.id}>
									<input
										className='auto-width ai-configuration-details'
										type='radio'
										checked={isItemEnabled(radioButtons, alert.id)}
										value={alert.id}
										name={`aiAlertType${aiConfigurationTypeId}`}
										onChange={() => handleRadioChange(alert.id)}
									/>
									<div className='onb-custom-checkbox-wrapper cursor-pointer top-0 ai-configurations-checkbox'>
										<div className='onb-custom-checkbox' />
									</div>
									<span className='left-s'>{translate(alert.name)}</span>
								</label>
							</div>
						))}
					</div>
				</div>
			</div>
			<div className='doctor-profile-settings ai-configurations top-15'>
				<p>{translate('statAlarmActivation')}</p>
				<div className='flex margin-top-s'>
					{StatNotificationTypes.map(alert => (
						<div className='register-practice-modal-grid tick-box-active-input' key={alert.id}>
							<label className='flex remember-me cursor-pointer no-margin'>
								<input
									type='checkbox'
									name={alert.name}
									checked={isItemEnabled(checkedItems, alert.id)}
									onChange={() => handleCheckboxChange(alert.id)}
								/>
								<div className='onb-custom-checkbox-wrapper cursor-pointer top-0 ai-configurations-checkbox'>
									<div className='onb-custom-checkbox' />
								</div>
								<span className='left-s margin-right-xxl'>{translate(alert.name)}</span>
							</label>
						</div>
					))}
				</div>
			</div>
			<div className='top-15'>
				<p>
					{translate('snoozeTimerForAlert', {
						value: `${intl.formatMessage({ id: title })}`,
					})}
				</p>
				<div className='flex full-width margin-top-s ai-configurations-select'>
					<Select
						options={snoozeTimers?.current}
						value={snoozeTimers.current.find(item => item.value === selectedSnoozeTime)}
						placeholder={translate('selectTimer')}
						onChange={onSnoozeTimeChange}
						classNamePrefix='custom-select'
					/>
				</div>
			</div>
			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
		</div>
	);
};

export default AiConfigurationFeatureFlags;
