import { updateHelloPin } from 'api/adminConfigurations.js';
import Modal from 'components/Modal.jsx';
import translate from 'i18n-translations/translate.jsx';
import React, { useState } from 'react';
import PinInput from 'react-pin-input';
import Alert from 'components/Alert.jsx';

const AddHelloPin = props => {
	const DEFAULT_VALUE = 1000;
	const [pinError, setPinError] = useState(null);
	const [error, setError] = useState(null);

	const handleSubmit = async () => {
		setPinError(null);
		const helloPin = props.pin;

		if (helloPin < DEFAULT_VALUE) {
			setPinError(translate('pinMinValue'));
			return;
		}

		const response = await updateHelloPin({
			healthSystemId: props.healthSystemId,
			regionId: props.regionId,
			hospitalId: props.hospitalId,
			dataToSend: {
				helloSettingsPin: +helloPin,
			},
		});

		if (response.error) {
			setError(response.error.message);
			return;
		}
		props.setIsPinLockOpen(false);
	};

	return (
		<>
			<Modal
				display={props.isPinLockOpen}
				position='center'
				primaryButtonLabel={translate('confirm')}
				onModalClose={() => props.setIsPinLockOpen(false)}
				onModalSubmit={handleSubmit}>
				<div className='pin-lock-modal'>
					<p className='pin-lock-title'>{translate('pinLock')}</p>
					<div className='pin-lock-wrapper'>
						<div>
							<p className='pin-lock-text'>{translate('pinLock')}</p>
							<PinInput
								length={4}
								secret={false}
								type='numeric'
								inputMode='number'
								onComplete={value => props.setPin(value)}
								autoSelect={true}
								regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
							/>
							<p className='red-error'>{pinError}</p>
						</div>
					</div>
				</div>
			</Modal>
			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
		</>
	);
};

export default AddHelloPin;
