import React, { useState, useRef, useCallback, useEffect } from 'react';
import Select from 'react-select';
import { useIntl } from 'react-intl';
import translate from 'i18n-translations/translate.jsx';
import { getRpmPrograms } from 'api/rpm.js';
import Alert from 'components/Alert.jsx';
import RpmProgramDetails from 'containers/Rpm/RpmProgramDetails.jsx';

const RpmPrograms = props => {
	const [pageIndex, setPageIndex] = useState(0);
	const [programs, setPrograms] = useState([]);
	const [error, setError] = useState(null);
	const hasReachedEnd = useRef(null);
	const intl = useIntl();

	const getRpmProgramList = useCallback(async () => {
		if (hasReachedEnd.current) {
			return;
		}
		const response = await getRpmPrograms({ pageIndex });
		if (response.error) {
			setError(response.error.message);
			return;
		}
		setPrograms(prevState => [
			...prevState,
			...response.rpmPrograms.filter(program => prevState.every(existingProgram => existingProgram.id !== program.id)),
		]);
		setPageIndex(prevState => prevState + 1);
		hasReachedEnd.current = response.rpmPrograms.length < 10;
	}, [pageIndex]);

	useEffect(() => {
		getRpmProgramList();
	}, []);

	const transformForSelect = array => array.map(program => ({ value: program.id, label: program.name }));

	const handleScroll = event => {
		const isBottom = event.target.scrollHeight - Math.ceil(event.target.scrollTop) === event.target.clientHeight;
		if (isBottom && !hasReachedEnd.current) {
			getRpmProgramList();
		}
	};

	return (
		<div className='check-in-patient-wrapper rpm-program-list select-doctor-tabs-wrapper'>
			<div className='full-page-input-wrapper additional-health select-doctor-wrapper'>
				<div className='flex flex-align-center column-direction'>
					<div>
						<h3>{translate('rpmProgram')}</h3>
					</div>
					<div className='flex column-direction'>
						<div className='flex column-direction rpm-onboarding-select' onClick={getRpmProgramList} onScroll={handleScroll}>
							<p>{translate('pleaseSelectRpmProgram')}</p>
							<Select
								classNamePrefix='custom-select'
								onChange={program => props.setSelectedProgram(program)}
								value={props.selectedProgram}
								options={transformForSelect(programs)}
								placeholder={intl.formatMessage({ id: 'selectProgram' })}
								isClearable
							/>
							{!props.selectedProgram && (
								<span className='red-error margin-top-s margin-left-s'>{translate('rpmCannotBeEmpty')}</span>
							)}
						</div>
						{props.selectedProgram && <RpmProgramDetails selectedRpmProgram={props.selectedProgram} />}
					</div>
				</div>
			</div>
			<Alert display={error} fixed hideCloseButton message={error} variant='dark' />
		</div>
	);
};

export default RpmPrograms;
