import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const Breadcrumb = props => (
	<ul className='breadcrumb'>
		{props.links?.map((item, index) => (
			<li key={item.link} className={classNames(props.links.length - 1 === index ? 'active' : '')}>
				<Link
					to={props.isFromHealthSystems ? `/health-system/${item.link}` : item.link}
					onClick={() => props.onBreadcrumbClick(props.links, index)}>
					{item.name}
				</Link>
			</li>
		))}
	</ul>
);

export default Breadcrumb;
