import styled, { css } from 'styled-components';
import LightTheme from 'calls/styles/LightTheme.js';

/**
 * @type {import('styled-components').StyledComponent<"span", any, { isGridView?: boolean, title?: string }, never>}
 */
const StyledParticipantInfo = styled.span`
	display: flex;
	align-items: center;
	z-index: 1;

	${props =>
		!props.isGridView &&
		css`
			position: absolute;
			bottom: ${LightTheme.spacing[2]}px;
			left: ${LightTheme.spacing[2]}px;
		`}

	${props =>
		props.isGridView &&
		css`
			bottom: ${LightTheme.spacing[2]}px;
			left: ${LightTheme.spacing[2]}px;
		`}

	button {
		margin-right: 6px;
		padding: ${LightTheme.spacing[1]}px;
		span {
			font-size: 12px;
		}
	}
`;
export default StyledParticipantInfo;
