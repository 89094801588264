export const FeedColors = {
	red: '#e84118',
	blue: '#5390FF',
	orange: '#ff9f43',
	yellow: '#fbc531',
	pink: '#f368e0',
	black: '#000',
	cyan: '#6BDCFF',
	purple: '#852FC8',
	darkBlue: '#5e75aa',
	gray: '#d3d3d3',
	lightBlue: '#add8e6',
};

export const precautions = [
	{
		title: 'suspiciousBehavior',
		color: FeedColors.purple,
		id: 0,
	},
	{
		title: 'suicideWatch',
		color: FeedColors.cyan,
		id: 1,
	},
	{
		title: 'falls',
		color: FeedColors.yellow,
		id: 2,
	},
	{
		title: 'isolation',
		color: FeedColors.red,
		id: 3,
	},
	{
		title: 'seizure',
		color: FeedColors.pink,
		id: 4,
	},
	{
		title: 'hospice',
		color: FeedColors.black,
		id: 5,
	},
	{
		title: 'detox',
		color: FeedColors.orange,
		id: 6,
	},
	{
		title: 'pullingO2',
		color: FeedColors.lightBlue,
		id: 7,
	},
	{
		title: 'impulsiveConfused',
		color: FeedColors.gray,
		id: 8,
	},
	{
		title: 'nonRedirectable',
		color: FeedColors.blue,
		id: 9,
	},
];

export const VoiceAnnouncementsTypes = {
	DO_NOT_GET_UP: 1,
	DO_NOT_TOUCH_THAT: 2,
	STOP_WHAT_YOU_ARE_DOING: 3,
	STOP_PLEASE: 4,
	GET_SOMEONE_TO_YOUR_ROOM: 5,
	USE_YOUR_CALL_LIGHT: 6,
	DO_YOU_NEED_NURSE: 7,
};

export const VoiceAnnouncementsEnums = {
	[VoiceAnnouncementsTypes.DO_NOT_GET_UP]: {
		url: `do_not_get_up.mp3`,
		text: 'pleaseDoNotGetUp',
		id: VoiceAnnouncementsTypes.DO_NOT_GET_UP,
	},
	[VoiceAnnouncementsTypes.DO_NOT_TOUCH_THAT]: {
		url: `do_not_touch_that.mp3`,
		text: 'pleaseDoNotTouchThat',
		id: VoiceAnnouncementsTypes.DO_NOT_TOUCH_THAT,
	},
	[VoiceAnnouncementsTypes.STOP_WHAT_YOU_ARE_DOING]: {
		url: `stop_what_you_are_doing.mp3`,
		text: 'pleaseStopWhatYouAreDoing',
		id: VoiceAnnouncementsTypes.STOP_WHAT_YOU_ARE_DOING,
	},
	[VoiceAnnouncementsTypes.STOP_PLEASE]: {
		url: `stop_please.mp3`,
		text: 'stopPlease',
		id: VoiceAnnouncementsTypes.STOP_PLEASE,
	},
	[VoiceAnnouncementsTypes.GET_SOMEONE_TO_YOUR_ROOM]: {
		url: `someone_is_on_the_way.mp3`,
		text: 'someoneIsOnTheWay',
		id: VoiceAnnouncementsTypes.GET_SOMEONE_TO_YOUR_ROOM,
	},
	[VoiceAnnouncementsTypes.USE_YOUR_CALL_LIGHT]: {
		url: `use_your_call_light.mp3`,
		text: 'useYourCallLight',
		id: VoiceAnnouncementsTypes.USE_YOUR_CALL_LIGHT,
	},
	[VoiceAnnouncementsTypes.DO_YOU_NEED_NURSE]: {
		url: `do_you_need_nurse.mp3`,
		text: 'doYouNeedYourNurse',
		id: VoiceAnnouncementsTypes.DO_YOU_NEED_NURSE,
	},
};

export const VerbalRediretionLanguages = {
	ENGLISH: 1,
	SPANISH: 2,
	ARABIC: 3,
	MANDARIN_CHINESE: 4,
	TAGALOG: 5,
};
