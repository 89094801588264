import AiFeatureFlags from 'components/AiFeatureFlags.jsx';
import FeatureFlags from 'components/FeatureFlags.jsx';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from 'components/Tabs.jsx';
import {
	AiSettingFeaturesCategory,
	AiSettingsTabs,
	MonitoringFeatureFlagCategories,
	SettingsCategory,
	configurableAISettings,
	configurableMonitoringMenu,
} from 'constants/configurationEnums.js';
import { getUserRole } from 'infrastructure/auth.js';
import React, { useState } from 'react';

const MonitoringAiFeatureFlags = ({ healthSystemId, levelId, currentSector, setError, setShowHandHygiene }) => {
	const [currentTab, setCurrenTab] = useState(0);

	return (
		<Tabs
			className='feature-flags-categories'
			isVertical={false}
			activeIndex={currentTab}
			onChange={index => setCurrenTab(index)}>
			<TabList>
				{AiSettingsTabs.map(item => (
					<Tab key={item.id}>{item.title}</Tab>
				))}
			</TabList>
			<TabPanels>
				<TabPanel key='0'>
					<FeatureFlags
						settingCategory={SettingsCategory.MONITORING}
						currentSector={currentSector}
						healthSystemId={healthSystemId}
						levelId={levelId}
						defaultConfigurations={configurableMonitoringMenu(getUserRole())}
						setError={setError}
						categoryOrder={Object.values(MonitoringFeatureFlagCategories)}
						setShowHandHygiene={setShowHandHygiene}
					/>
				</TabPanel>
				<TabPanel key='1'>
					<AiFeatureFlags
						settingCategory={SettingsCategory.AI_SETTINGS}
						currentSector={currentSector}
						healthSystemId={healthSystemId}
						levelId={levelId}
						setError={setError}
						categoryOrder={Object.values(AiSettingFeaturesCategory)}
						defaultConfigurations={configurableAISettings()}
						setShowHandHygiene={setShowHandHygiene}
					/>
				</TabPanel>
			</TabPanels>
		</Tabs>
	);
};

export default MonitoringAiFeatureFlags;
