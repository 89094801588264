import React, { useState } from 'react';
import { MeasurementActivityTypes } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import { addAlertActivity } from 'api/alerts.js';
import Alert from 'components/Alert.jsx';

const AiAlertsOnInterventions = props => {
	const [alertType, setAlertType] = useState(null);
	const [error, setError] = useState(null);

	const onSubmit = async activityType => {
		const params = {
			alertId: props.warningObject.alertId,
			activityType,
			isAiAlert: props.warningObject.isAiAlert,
			comment: '',
			isDraft: true,
		};
		const response = await addAlertActivity(params);
		if (response.error) {
			setError(response.error.message);
		}
		props.setSnoozeType(props.feedDeviceId, alertType);
		setAlertType(null);
		props.onAlertCloseClick();
	};

	return (
		<>
			{props.warningObject?.value && <p className='font-14 semi-bold'>Active Alerts</p>}
			{!alertType && (
				<div>
					<div className={`alert-body fall-alert-body ${props.alertType} intervention-alert-content`}>
						<div className='alert-content'>
							<div>
								<p className='warning-wrapper-text'>{props.warningObject.text}</p>
							</div>
							{props.warningObject.isToastMessage && (
								<div>
									<i className='material-icons cursor-pointer close-alert' onClick={props.onAlertClose}>
										close
									</i>
								</div>
							)}
						</div>
						{props.warningObject?.value && !props.warningObject.isToastMessage && (
							<div className='alert-feed-sub-option'>
								<div className='alert-feed-sub-options-wrapper flex interventions-alerts-wrapper'>
									<button
										type='button'
										className='flex flex-align-center flex-justify-center false-alert-btn warning-btn'
										onClick={() => {
											setAlertType(MeasurementActivityTypes.MEASUREMENT_FAKE_ALERT);
											onSubmit(MeasurementActivityTypes.MEASUREMENT_FAKE_ALERT);
										}}>
										<i className='material-icons-outlined'>close</i>
										{translate('falseAlert')}
									</button>
									<button
										type='button'
										className='flex flex-align-center flex-justify-center acknowledge-alert-btn warning-btn'
										onClick={() => {
											setAlertType(MeasurementActivityTypes.ACKNOWLEDGED);
											onSubmit(MeasurementActivityTypes.ACKNOWLEDGED);
										}}>
										<i className='material-icons-outlined'>done</i>
										{translate('acknowledge')}
									</button>
									{props.showForwardToNurses && (
										<button
											className='flex flex-align-center flex-justify-center forward-alert-btn warning-btn'
											onClick={() => {
												props.onForwardToNurses(props.warningObject.alertId, props.warningObject.isAiAlert);
												props.onAlertCloseClick();
											}}>
											<i className='material-icons-outlined'>arrow_forward</i>
											{translate('forwardToNurses')}
										</button>
									)}
								</div>
							</div>
						)}
					</div>
				</div>
			)}
			{error && <Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />}
		</>
	);
};

export default AiAlertsOnInterventions;
