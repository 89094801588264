import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import { useIntl } from 'react-intl';
import { actionCreators as PatientsActionCreators } from 'state/patients/actions.js';
import translate from 'i18n-translations/translate.jsx';
import ProfilePicture from 'components/ProfilePicture.jsx';
import { skipDuplicatedObjects, mapDevicesToPatients } from 'infrastructure/helpers/commonHelpers.js';
import { getMyPatients } from 'api/patients.js';
import Loader from 'components/Loader.jsx';

const AdvancedSearch = () => {
	const [patientList, setPatientList] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const history = useHistory();
	const dispatch = useDispatch();
	const intl = useIntl();
	const myPatients = useSelector(state => state.myPatients);

	useEffect(() => {
		const getPatients = async () => {
			setIsLoading(true);
			const patients = await getMyPatients({ search: myPatients.searchedLetters });
			const patientsMapped = skipDuplicatedObjects(patients, 'userId');
			const patientsWithDevices = mapDevicesToPatients(patientsMapped, patients);
			setPatientList(patientsWithDevices);
			setIsLoading(false);
		};
		getPatients();
	}, [myPatients.searchedLetters]);

	const setSelectedSearchedPatient = item => {
		dispatch(PatientsActionCreators.setSelectedPatient(item));
		history.push(`/patients/${item.userId}`);
		dispatch(PatientsActionCreators.changeAdvancedSearchActiveStatus(false));
	};

	const closeAdvancedSearch = () => {
		dispatch(PatientsActionCreators.changeAdvancedSearchActiveStatus(false));
		if (myPatients.patients.length > 0) {
			dispatch(PatientsActionCreators.setSelectedPatient(myPatients.patients[0]));
			history.push(`/patients/${myPatients.patients[0].deviceId}`);
		}
	};

	const searchWords = myPatients.searchedLetters.split(' ');

	return (
		<>
			{myPatients.isAdvancedSearchActive && (
				<div className='advanced-search-side'>
					<div className='sub-page-header flex header-elements'>
						<h2>{translate('advancedSearch')}</h2>
						<i className='material-icons cursor-pointer' onClick={closeAdvancedSearch}>
							close
						</i>
					</div>

					<div className='advanced-search-list'>
						{patientList.length > 0 &&
							!isLoading &&
							patientList.map(patient => {
								const searchedDevices = patient.devices.filter(item =>
									item.name.toLowerCase().includes(myPatients.searchedLetters.toLowerCase())
								);
								return (
									<div
										className={
											myPatients.selectedPatient && myPatients.selectedPatient.id === patient.id
												? 'chat-list-item flex active'
												: 'chat-list-item flex'
										}
										onClick={() => setSelectedSearchedPatient(patient)}>
										<div className='chat-list-img'>
											<ProfilePicture
												className='chat-list-img'
												fullName={`${patient.firstName} ${patient.lastName}`}
												profilePicture={patient.profilePicture}
											/>
										</div>
										<div className='chat-list-name header-search-name flex flex-1'>
											<p className='header-search-primary'>
												<Highlighter
													searchWords={searchWords}
													autoEscape={true}
													textToHighlight={`${patient.firstName} ${patient.lastName}`}
												/>
											</p>
											<span>
												{searchedDevices.map(item => (
													<>
														<Highlighter searchWords={searchWords} autoEscape={true} textToHighlight={item.name} />
														<br />
													</>
												))}
												<br />
											</span>

											{patient.devices.length > 0 && patient.devices.length - searchedDevices.length > 0 && (
												<span className='--blue-color'>
													{`${intl.formatMessage({ id: 'and' })} ${patient.devices.length -
														searchedDevices.length} ${intl.formatMessage({ id: 'otherDevices' })}`}
												</span>
											)}
										</div>
										{patient.devicesInfos.length > 0 && (
											<div className='chat-list-status flex'>
												{patient.devicesInfos[0].status && <span className='is-on' />}
												{!patient.devicesInfos[0].status && <span className='is-off' />}
											</div>
										)}
									</div>
								);
							})}
						{!isLoading && patientList.length === 0 && (
							<div className='chat-item-title'>
								<span className='semi-bold'>{translate('noResultsFound')}</span>
							</div>
						)}
						{isLoading && (
							<div className='patient-request-loader'>
								<Loader />
							</div>
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default AdvancedSearch;
