import React from 'react';

const SemiPieProgressBar = ({ options, strokeWidth = 16, size = 200, totalProgressTitle, totalProgress, showLegend = true }) => {
	const minDimension = Math.min(size, size);
	const radius = (minDimension - strokeWidth) / 2;

	const totalCircumference = Math.PI * radius * 2;
	const orderedOptions = options.sort((a, b) => b.percentage - a.percentage);

	return (
		<div className='semi-pie-progress-bar'>
			<div className='sm-progress-bar' style={{ height: `${size / 2}px` }} key='chart'>
				<div style={{ width: `${size}px`, height: `${size / 2}px` }} key='0'>
					<svg width={`${size}px`} height={`${size}px`} className='progress-circles'>
						<circle
							key='0'
							cx={size / 2}
							cy={size - radius}
							r={radius}
							fill='transparent'
							strokeWidth={strokeWidth}
							stroke='var(--gray-2)'
						/>

						{orderedOptions.map(({ percentage, stroke, label }) => {
							const individualCircumference = (percentage / 2 / 100) * totalCircumference;
							const remainingCircumference = totalCircumference - individualCircumference;

							return (
								<circle
									key={label}
									strokeWidth={strokeWidth}
									stroke={stroke}
									cx={size / 2}
									cy={size - radius}
									r={radius}
									fill='transparent'
									style={{
										strokeDasharray: `${individualCircumference} ${remainingCircumference}`,
										strokeDashoffset: totalCircumference * 0.5,
										strokeLinecap: 'round',
									}}
								/>
							);
						})}
					</svg>
				</div>
				<div className='sm-total-progress' style={{ width: `${size}px` }} key='1'>
					<span>{totalProgress}</span>
					<span>{totalProgressTitle}</span>
				</div>
			</div>
			{showLegend && (
				<div className='sm-legend' key='legend'>
					{orderedOptions.map(item => {
						return (
							<div key={item.label}>
								<div style={{ background: item.stroke }} />
								<span>{item.label}</span>
								<span>{item.value}</span>
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};

export default SemiPieProgressBar;
