import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreators as aiSettingsActionCreators } from 'state/aiSettings/actions.js';
import { updatePatientsAiSettings } from 'api/patients.js';
import Modal from 'components/Modal.jsx';
import Alert from 'components/Alert.jsx';
import translate from 'i18n-translations/translate.jsx';
import { useIntl } from 'react-intl';
import { ExcludedAiSettings, PatientsAiSettings, RailType, SensitivityType } from 'constants/enums.js';
import ToastMessage from 'components/ToastMessage.jsx';
import { MonitoringSettings } from 'constants/configurationEnums.js';
import { getConfigurationValue } from 'infrastructure/helpers/commonHelpers.js';

const FallPreventionModal = props => {
	const intl = useIntl();
	const [error, setError] = useState(null);
	const [isMoreThanOneAi, setIsMoreThanOneAi] = useState(false);
	const aiSettingList = useSelector(state => state.aiSettingsList.aiSettings);
	const dispatch = useDispatch();
	const setAiSettingsAction = ai => dispatch(aiSettingsActionCreators.setPatientAiSettings(ai));

	useEffect(() => {
		const toastTimeOut = setTimeout(() => {
			setIsMoreThanOneAi(false);
		}, 3000);

		return () => {
			clearTimeout(toastTimeOut);
		};
	}, [isMoreThanOneAi]);

	const shouldDisableClick = (settingTypeId, value) => {
		const settings = _.cloneDeep(aiSettingList);
		const found = settings.find(item => item.deviceId === props.deviceId);
		const fallPreventionTypes = [
			PatientsAiSettings.FALL_PREVENTION,
			PatientsAiSettings.RAIL_DOWN,
			PatientsAiSettings.FALL_DETECTED,
			PatientsAiSettings.PERSON_GETTING_OUT_OF_BED,
		];

		const foundInFallPrevention = fallPreventionTypes.find(item => item === settingTypeId);

		let isAiSettingEnabled = found.settings.some(
			item =>
				!ExcludedAiSettings.includes(item.settingTypeId) &&
				!['false', undefined, null].includes(item.value) &&
				item.settingTypeId !== settingTypeId
		);

		if (foundInFallPrevention) {
			isAiSettingEnabled = found.settings.reduce((acc, item) => {
				const isValid =
					!ExcludedAiSettings.includes(item.settingTypeId) &&
					!['false', undefined, null].includes(item.value) &&
					item.settingTypeId !== settingTypeId &&
					!fallPreventionTypes.includes(item.settingTypeId);

				return acc || isValid;
			}, false);
		}

		return isAiSettingEnabled && !['false', undefined, null].includes(value) && settingTypeId !== PatientsAiSettings.HAND_WASHING;
	};

	const handleSubmit = async () => {
		if (shouldDisableClick(PatientsAiSettings.FALL_PREVENTION, true)) {
			setIsMoreThanOneAi(true);
			return;
		}
		const dataToSend = [
			{
				settingTypeId: PatientsAiSettings.PERSON_GETTING_OUT_OF_BED,
				value: getConfigurationValue(props.adminAiSettingsConfigurations[MonitoringSettings.GetOutOfBed])
					? SensitivityType.MEDIUM
					: null,
			},
			{
				settingTypeId: PatientsAiSettings.RAIL_DOWN,
				value: getConfigurationValue(props.adminAiSettingsConfigurations[MonitoringSettings.Rails])
					? `${RailType.BOTTOM_LEFT}-${RailType.BOTTOM_RIGHT}`
					: null,
			},
			{
				settingTypeId: PatientsAiSettings.FALL_DETECTED,
				value: getConfigurationValue(props.adminAiSettingsConfigurations[MonitoringSettings.FallDetection]) ? 'true' : 'false',
			},
		];

		props.toggleFallPrevention(true, props.deviceId);
		const found = aiSettingList.find(item => item.deviceId === props.deviceId);
		if (!found) {
			return;
		}
		const settings = _.cloneDeep(aiSettingList);
		const initialSettings = settings.find(item => item.deviceId === props.deviceId).settings;
		const filteredSettings = _.cloneDeep(initialSettings).filter(
			item =>
				![PatientsAiSettings.PERSON_GETTING_OUT_OF_BED, PatientsAiSettings.RAIL_DOWN, PatientsAiSettings.FALL_DETECTED].includes(
					item.settingTypeId
				)
		);

		const newDataToSend = dataToSend.map(item => {
			const matchingSetting = initialSettings.find(setting => setting.settingTypeId === item.settingTypeId);
			return {
				...item,
				snoozeTime: matchingSetting?.snoozeTime ?? null,
			};
		});

		setAiSettingsAction({
			settings: [...filteredSettings, ...newDataToSend],
			deviceId: props.deviceId,
		});

		const params = {
			patientId: props.owner?.id,
			deviceId: props.deviceId,
			roomId: props.roomId,
			sendData: newDataToSend,
		};

		const response = await updatePatientsAiSettings(params);

		if (response.error) {
			setError(response.error.message);
			setAiSettingsAction({
				settings: initialSettings,
				deviceId: props.deviceId,
			});

			return;
		}
		props.onModalClose();
		props.onAiSettingClick(true);
	};

	return (
		<>
			<Modal
				className='wrapper-modal border-radius-modal-wrapper disable-modal'
				display={true}
				position='center'
				onModalSubmit={handleSubmit}
				onModalClose={props.onModalClose}
				primaryButtonLabel={intl.formatMessage({ id: 'aiMonitoringOn' })}
				closeButtonText={intl.formatMessage({ id: 'continueNoAi' })}>
				<form>
					<h3>{translate('aiFallPrevention')}</h3>
					<p>{translate('wantToTurnOnFallPrevention')}</p>
				</form>
				<Alert display={error} message={error} variant='dark' fixed={true} onClose={() => setError(null)} />
			</Modal>
			<ToastMessage
				showToast={isMoreThanOneAi}
				onClose={() => setIsMoreThanOneAi(false)}
				className='video-feed-toast-message emergency-toast-message'>
				<span>{translate('noMoreThanOneAiType')}</span>
			</ToastMessage>
		</>
	);
};

export default FallPreventionModal;
