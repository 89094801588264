import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import { getUserRoles } from 'api/roles.js';
import { getCompanyId, getUserId, checkIPPremission } from 'infrastructure/auth.js';
import Alert from 'components/Alert.jsx';
import Button from 'components/Button.jsx';
import { HealthcareErrorCode, Roles, UserTypes } from 'constants/enums.js';
import { getDoctorProfile } from 'api/doctors.js';
import { getStorage, setDoctorSpecialty } from 'infrastructure/helpers/commonHelpers.js';
import { actionCreators as userActionCreators } from 'state/user/actions.js';
import { updateSession } from 'api/users.js';

const ContinueAs = () => {
	const [userType, setUserType] = useState(null);
	const [roles, setRoles] = useState([]);
	const [error, setError] = useState(null);
	const history = useHistory();
	const dispatch = useDispatch();
	const userSession = useSelector(state => state.user.userSession);

	useEffect(() => {
		const getRoles = async () => {
			const rolesResponse = await getUserRoles(getUserId());
			if (rolesResponse.error) {
				setError(rolesResponse.error.message);
				return;
			}
			setRoles(rolesResponse.roles);
			if (rolesResponse.roles.length < 2) {
				getStorage().setItem('isContinueAs', 'true');
				await checkIPPremission();
				if (rolesResponse.roles.find(item => item.id === UserTypes.DOCTOR)) {
					const doctorDetails = await getDoctorProfile(getUserId());
					if (doctorDetails?.doctorProfile?.specialty) {
						setDoctorSpecialty(doctorDetails.doctorProfile.specialty);
						history.push('/');
					} else {
						history.push('/complete-registration');
					}
				} else {
					history.push('/');
				}
			}
		};
		getRoles();
	}, [dispatch, history]);

	const onLinkClick = async () => {
		const selectedRole = Roles.find(item => item.id === userType);
		if (selectedRole.id !== UserTypes.ADMIN) {
			await checkIPPremission();
		}
		const response = await updateSession({
			healthSystemId: userSession.healthSystem.id,
			checkedInChannelId: userSession.checkedInChannelId || null,
			floorId: userSession.floorId || null,
			departmentId: userSession.departmentId || null,
			companyId: getCompanyId(),
			roleId: selectedRole.id,
		});
		getStorage().setItem('userRole', selectedRole.role);
		getStorage().setItem('isContinueAs', 'true');
		if (response.error) {
			if (response.error.response.data.code === HealthcareErrorCode.PATIENT_IN_QUEUE) {
				setError(translate('patientInQueue'));
			} else if (response.error.response.data.code === HealthcareErrorCode.MEDICAL_VISIT_INITIATED) {
				setError(translate('medicalVisitInitiated'));
			} else {
				setError(response.error.message);
			}
			return;
		}
		dispatch(userActionCreators.setCurrentUserRole(selectedRole.role));
		history.push(userType === UserTypes.DOCTOR ? '/complete-registration' : '/');
	};

	return (
		roles?.length >= 2 && (
			<div className='flex continue-as-wrapper'>
				<img src={`${healthCareCdnUrl}hellocare-logo.svg`} className='form-part-logo-lw' alt='hellocare' />
				<h1>{translate('continueAs')}</h1>
				<div className='flex'>
					{roles.map(role => (
						<div key={role.id} className={`item ${userType === role.id ? 'active' : ''}`} onClick={() => setUserType(role.id)}>
							<img
								src={`${healthCareCdnUrl}user-types/${role.name.toLowerCase()}${userType === role.id ? '-active' : ''}.svg`}
								alt='icon'
							/>
							<h1>{translate(role.name.toLowerCase())}</h1>
							<input
								type='radio'
								name='type-of-account'
								value='patient'
								required
								checked={userType === role.id}
								onClick={() => null}
							/>
						</div>
					))}
				</div>
				{userType && <Button onClick={onLinkClick} className={userType ? '' : 'disabled-link'} text={translate('select')} />}
				<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
			</div>
		)
	);
};

export default ContinueAs;
