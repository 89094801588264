import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { createNoteOrUpdate, deleteNote, getNote } from 'api/notes';
import Alert from 'components/Alert.jsx';
import SpeechToText from 'SpeechToText';

const MonitoringNotes = props => {
	const modeEnums = { SAVED: 1, EDIT: 2 };
	const [error, setError] = useState('');
	const [fontSize, setFontSize] = useState(14);
	const [mode, setMode] = useState(modeEnums.SAVED);
	const modeRef = useRef(modeEnums.SAVED);
	const noteRef = useRef('');
	const maxFontSize = 16;
	const minFontSize = 12;
	const maxCharacterLength = 256;
	const intl = useIntl();
	const [recognizedTranscription, setRecognizedTranscription] = useState('');
	const [liveTranscription, setLiveTranscription] = useState('');
	const [isSpeechToText, setIsSpeechToText] = useState(false);

	useEffect(() => {
		const fetchNote = async () => {
			const response = await getNote(props.roomId);
			if (response.error) {
				setError(response.error.message);
				setMode(modeEnums.EDIT);
				modeRef.current = modeEnums.EDIT;
			} else {
				const noteToSet = response.content || '';
				const modeToSet = response.content ? modeEnums.SAVED : modeEnums.EDIT;
				setRecognizedTranscription(noteToSet);
				setMode(modeToSet);
				noteRef.current = noteToSet;
				modeRef.current = modeToSet;
			}
		};
		fetchNote();
	}, [props.roomId]);

	useEffect(() => {
		const updateOnLeave = () => {
			if (modeRef.current === modeEnums.EDIT && noteRef.current.length > 0) {
				createNoteOrUpdate(props.roomId, noteRef.current);
			}
		};
		return updateOnLeave;
	}, []);

	const handleSaved = async () => {
		const response = await createNoteOrUpdate(props.roomId, recognizedTranscription);
		if (response.error) {
			setError(response.error.message);
		}
		setMode(modeEnums.SAVED);
		modeRef.current = modeEnums.SAVED;
	};

	const handleDeleteNote = async () => {
		const response = await deleteNote(props.roomId);
		if (response.error) {
			setError(response.error.message);
		} else {
			setRecognizedTranscription('');
			noteRef.current = '';
		}
		setMode(modeEnums.EDIT);
		modeRef.current = modeEnums.EDIT;
	};

	return (
		<>
			<div className='markdown-form monitoring-notes-wrapper' style={{ height: props.height ? `${props.height}px` : `${125}px` }}>
				<div className='convert-text-buttons'>
					<button
						type='button'
						disabled={fontSize === minFontSize}
						onClick={fontSize > minFontSize ? () => setFontSize(prevFont => prevFont - 2) : null}>
						<i className='material-icons-outlined'>text_decrease</i>
					</button>
					<button
						type='button'
						disabled={fontSize === maxFontSize}
						onClick={fontSize < maxFontSize ? () => setFontSize(prevFont => prevFont + 2) : null}>
						<i className='material-icons-outlined'>text_increase</i>
					</button>

					{mode === modeEnums.SAVED && (
						<button
							style={{ color: 'var(--gray-4)' }}
							onClick={() => {
								setMode(modeEnums.EDIT);
								modeRef.current = modeEnums.EDIT;
							}}
							type='button'>
							{intl.formatMessage({ id: 'edit' }).toUpperCase()}
						</button>
					)}

					{mode === modeEnums.EDIT && (
						<button
							style={{ color: 'var(--blue-2)' }}
							type='button'
							onClick={handleSaved}
							className={recognizedTranscription.length === 0 ? 'disabled' : ''}>
							{intl.formatMessage({ id: 'save' }).toUpperCase()}
						</button>
					)}

					{mode === modeEnums.SAVED && (
						<button style={{ color: 'var(--red-1)' }} type='button' onClick={handleDeleteNote}>
							{intl.formatMessage({ id: 'delete' }).toUpperCase()}
						</button>
					)}
				</div>
				<div className='position-relative full-height'>
					<textarea
						className='full-width full-height no-border'
						maxLength={maxCharacterLength}
						onChange={event => {
							event.preventDefault();
							setRecognizedTranscription(event.target.value);
							noteRef.current = event.target.value;
						}}
						value={isSpeechToText ? `${recognizedTranscription} ${liveTranscription}`.trim() : recognizedTranscription}
						name='note'
						rows={2}
						placeholder={intl.formatMessage({ id: 'enterNoteHere' })}
						readOnly={mode === modeEnums.SAVED || isSpeechToText}
						style={{ fontSize: `${fontSize}px` }}
					/>
					{props.showDictationButton && mode === modeEnums.EDIT && (
						<div className='position-absolute textarea-icon'>
							<SpeechToText
								setRecognizedTranscription={setRecognizedTranscription}
								setLiveTranscription={setLiveTranscription}
								setIsSpeechToText={setIsSpeechToText}
								isSpeechToText={isSpeechToText}
								maxCharacterLength={256}
							/>
						</div>
					)}
				</div>

				<div className='note-text-counter'>{`${recognizedTranscription.length}/${maxCharacterLength}`}</div>
			</div>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};

export default MonitoringNotes;
