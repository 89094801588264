import React, { Component, createRef } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import moment from 'moment';
import Input from 'components/Input.jsx';
import Table from 'components/Table.jsx';
import Badge from 'components/Badge.jsx';
import Pagination from 'components/Common/Pagination.jsx';
import Modal from 'components/Modal.jsx';
import Alert from 'components/Alert.jsx';
import translate from 'i18n-translations/translate.jsx';
import { getMembers, deleteMember, getHealthSystemMembers } from 'api/users.js';
import { UserRoles, MembersType, AuthenticationOptions, CompanyRoles } from 'constants/enums.js';
import {
	decodeHtml,
	getPreferredLanguageLocale,
	getRoleValueById,
	getUserTypeBasedOnCompanyRole,
} from 'infrastructure/helpers/commonHelpers.js';
import { getUserInfo, getUserRole, getUserId, getCompanyId } from 'infrastructure/auth.js';
import { getUserRoles, addMemberRole, deleteMemberRole } from 'api/roles.js';
import ManageHealthSystems from 'containers/UserManagement/Lists/EditUser/ManageHealthSystems.jsx';
import Form from 'components/Form.jsx';
import AddMemberRole from 'containers/UserManagement/Lists/AddMemberRole.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { companyLevelAdData, companyLevelExists, getActiveDirectories } from 'api/activeDirectory.js';
import { deleteUserLogins } from 'api/adminConfigurations.js';

class AdMembers extends Component {
	state = {
		pageSize: 10,
		pageIndex: 0,
		totalCount: 0,
		members: [],
		loading: true,
		currentMemberObj: null,
		currentMemberId: null,
		searchValue: '',
		isDeleteModalOpen: false,
		deletingMember: false,
		isEditMemberModalOpen: false,
		editingMember: false,
		manageUsersSelectedTab: 0,
		healthSystemAddition: {
			loading: false,
			loaded: false,
			error: false,
		},
		updateNurse: {
			loading: false,
			loaded: false,
			error: false,
		},
		isCurrentUserOwner: false,
		error: null,
		isAddRoleModalOpen: false,
		rolesToAdd: null,
		selectedRole: null,
		selectedRoleError: '',
		isDeleteRoleModalOpen: false,
		roleId: null,
		isBtnLoading: false,
		isHealthSystemShown: false,
		companyLevelExists: false,
	};

	shouldHideRemoveIcon = (roleId, hasMultipleHs) =>
		this.userRole === UserRoles.SUPER_USER && (roleId === CompanyRoles.ADMIN || hasMultipleHs);

	wrapperRef = createRef();

	usersTableHeaders = [
		{
			title: this.props.intl.formatMessage({ id: 'userName' }),
			id: 0,
		},
		{
			title: this.props.intl.formatMessage({ id: 'email' }),
			id: 1,
		},
		{
			title: this.props.intl.formatMessage({ id: 'dateAdded' }),
			id: 4,
		},
		getUserRole() !== UserRoles.SUPER_ADMIN && {
			title: this.props.intl.formatMessage({ id: 'healthSystems' }),
			id: 5,
		},
		{
			title: this.props.intl.formatMessage({ id: 'status' }),
			id: 6,
		},
		getUserRole() !== UserRoles.SUPER_ADMIN && { title: this.props.intl.formatMessage({ id: 'role' }), id: 7 },
		{ edit: '', id: 8 },
	].filter(Boolean);

	userId = getUserId();

	companyId = getCompanyId();

	userRole = getUserRole();

	fetchActiveDirectories = async () => {
		const formData = {
			pageSize: 10,
			pageIndex: 0,
			healthSystemId: this.props.userSession.healthSystem.id,
			hospitalId: null,
		};
		const response = await getActiveDirectories(formData);
		if (!response.error) {
			const selectedAdConfig =
				response.activeDirectories.length > 0 ? response.activeDirectories[0] : { adConfigurationTypeId: 1 };
			this.setState({ selectedAdConfig });
		}
	};

	async componentDidMount() {
		this.fetchActiveDirectories();
		this.fetchMembers();
		document.addEventListener('mousedown', this.handleClickOutside);
		const params = { usersHealthSystemId: this.props.userSession.healthSystem.id };
		const response = await companyLevelExists(params);
		if (response.error) {
			this.setState({ error: response.message });
		} else {
			this.setState({ companyLevelExists: response.exists });
			if (response.exists) {
				const companyLevelAdDataRes = await companyLevelAdData(params);
				if (companyLevelAdDataRes) {
					this.setState({ hideDeleteBtn: companyLevelAdDataRes.autoDelete });
				}
			}
		}
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	handleClickOutside = e => {
		if (this.wrapperRef && !this.wrapperRef.current?.contains(e.target)) {
			this.setState({
				isInviteUsersDropDown: false,
			});
		}
	};

	fetchMembers = async () => {
		let response = {};
		const params = {
			pageSize: this.state.pageSize,
			pageIndex: this.state.pageIndex,
			searchValue: this.state.searchValue,
			filterType: MembersType.AD_MEMBERS,
		};
		if (UserRoles.SUPER_USER !== this.userRole) {
			response = await getMembers(params);
			if (response.error) {
				this.setState({ error: this.props.intl.formatMessage({ id: 'errorDuringRequestProcessing' }), loading: false });
				return;
			}
		} else {
			response = await getHealthSystemMembers({
				healthSystemId: this.props.userSession.healthSystem.id,
				...params,
			});
			if (response.error) {
				this.setState({ error: this.props.intl.formatMessage({ id: 'errorDuringRequestProcessing' }) });
				return;
			}
		}
		let isCurrentUserOwner = false;
		const rolesResponse = await getUserRoles(this.userId);
		if (rolesResponse.error) {
			this.setState({ error: rolesResponse.error.message });
		} else {
			isCurrentUserOwner = rolesResponse.roles.some(role => role.id === CompanyRoles.OWNER);
		}

		this.setState({
			loading: false,
			members: response.members,
			totalCount: response.totalCount,
			currentHealthSystemId: this.props.userSession.healthSystem.id,
			isCurrentUserOwner,
		});
	};

	handleDeleteMember = async () => {
		const userId = this.state.userGuid;
		const configurationId = this.state.selectedAdConfig.id;
		const [deleteUserResponse, deleteMemberResponse] = await Promise.all([
			deleteUserLogins(userId, configurationId),
			deleteMember(this.state.currentMemberId),
		]);
		if (deleteUserResponse.error) {
			this.setState({ error: deleteUserResponse.error.message });
		}
		if (!deleteUserResponse.HasSucceed) {
			this.setState({ error: translate('somethingWentWrong') });
		}
		if (deleteMemberResponse.error) {
			this.setState({ error: deleteMemberResponse.error.message });
		} else {
			this.setState(prevState => ({
				members: prevState.members.filter(member => member.memberId !== prevState.currentMemberId),
				currentMemberId: null,
				deletingMember: false,
				isDeleteModalOpen: false,
			}));
		}
	};

	async onChange(pageSize, pageIndex) {
		this.setState({ loading: true });
		let members = {};

		const params = {
			pageSize,
			pageIndex,
			searchValue: this.state.searchValue,
			filterType: MembersType.AD_MEMBERS,
		};
		if (UserRoles.SUPER_USER !== this.userRole) {
			members = await getMembers(params);
			if (members.error) {
				this.setState({ error: this.props.intl.formatMessage({ id: 'errorDuringRequestProcessing' }) });
				return;
			}
		} else {
			members = await getHealthSystemMembers({
				healthSystemId: this.state.currentHealthSystemId,
				...params,
			});
			if (members.error) {
				this.setState({ error: this.props.intl.formatMessage({ id: 'errorDuringRequestProcessing' }) });
				return;
			}
		}

		this.setState({
			loading: false,
			members: members.members,
			pageSize,
			pageIndex,
			totalCount: members.totalCount,
		});
	}

	getRoles(roles, memberId, hasMultipleHs) {
		if (!roles) {
			return 'N/A';
		}
		if (roles.length === 1) {
			return <div className='badge blue'>{getRoleValueById(getUserTypeBasedOnCompanyRole(roles[0].id))}</div>;
		}
		const result = roles.map(role => (
			<div key={role.id} className='badge blue'>
				{getRoleValueById(getUserTypeBasedOnCompanyRole(role.id))}
				{!this.shouldHideRemoveIcon(role.id, hasMultipleHs) && (
					<i className='material-icons remove-role-btn' onClick={() => this.openDeleteRoleModal(role.id, memberId)}>
						close
					</i>
				)}
			</div>
		));
		return result.length > 0 ? result : 'N/A';
	}

	isNurseOrDoctor(roles) {
		if (!roles) return false;
		return roles.some(role =>
			[
				CompanyRoles.DOCTOR,
				CompanyRoles.VIRTUAL_CARE_PROVIDER,
				CompanyRoles.DIGITAL_CLINICIAN,
				CompanyRoles.VIRTUAL_SITTER,
			].includes(role.id)
		);
	}

	showDeleteButton = roles => {
		if (roles && roles.length > 0) {
			if (this.userRole === UserRoles.ADMIN) {
				return !roles?.some(role => [CompanyRoles.OWNER].includes(role.id));
			}
			if (this.userRole === UserRoles.SUPER_USER) {
				return !roles?.some(role => [CompanyRoles.OWNER, CompanyRoles.ADMIN].includes(role.id));
			}
		}
		return true;
	};

	shouldShowAddRoleButton = roles => {
		return !roles || roles.length >= 5
			? false
			: roles.some(role =>
					[
						CompanyRoles.DOCTOR,
						CompanyRoles.VIRTUAL_CARE_PROVIDER,
						CompanyRoles.DIGITAL_CLINICIAN,
						CompanyRoles.ADMIN,
						CompanyRoles.SUPER_USER,
					].includes(role.id)
			  );
	};

	getMemberDetails = (user, presence, presenceColor) => {
		const currentUser = getUserInfo().userId === user.userInfo.userId;
		return {
			id: user.userInfo.id,
			profileBox: this.getProfileBox(decodeHtml(`${user.userInfo.firstName} ${user.userInfo.lastName}`), currentUser),
			email: user.userInfo.email,
			dateAdded: user.dateAdded
				? moment.utc(user.dateAdded).local().locale(getPreferredLanguageLocale()).format('MM/DD/YYYY-hh:mm A')
				: 'N/A',
			...(this.userRole !== UserRoles.SUPER_ADMIN && {
				healthSystem: user.healthSystems.length > 0 ? this.displayHealthSystems(user.healthSystems) : 'N/A',
			}),
			status: <Badge text={presence} variant={presenceColor} />,
			...(this.userRole !== UserRoles.SUPER_ADMIN && {
				role: this.getRoles(user.roles, user.memberId, user.healthSystems.length > 1),
			}),
			edit: !currentUser && [UserRoles.ADMIN, UserRoles.SUPER_USER].includes(this.userRole) && this.getEditButtons(user),
		};
	};

	displayUsers() {
		if (this.state.members.length === 0) return [];
		return this.state.members.map(user => {
			let presence;
			let presenceColor;
			if (user.userInfo.presenceStatusTypeId === 1) {
				presence = translate('available');
				presenceColor = 'green';
			} else {
				presence = translate('unavailable');
				presenceColor = 'red';
			}
			return this.getMemberDetails(user, presence, presenceColor);
		});
	}

	displayHealthSystems = healthSystems => {
		return healthSystems.map(hs => hs.name).join(',');
	};

	getProfileBox = (name, currentUser) => (
		<div className='div-container'>
			<span className={currentUser ? 'bold' : ''}>
				{name} {currentUser && `(${this.props.intl.formatMessage({ id: 'you' })})`}
			</span>
		</div>
	);

	openResetTwoFaAuthenticationModal = email => {
		this.setState({
			isTwoFaResetModalOpen: true,
			resetTwoFaModalData: {
				email,
			},
		});
	};

	getEditButtons = ({ memberId, roles, userInfo }) => {
		const is2FAEnabled = this.props?.companySettings?.twoFactorAuthenticator === AuthenticationOptions.EXTERNAL_AUTHENTICATOR;

		return (
			<div className='wrapped'>
				{is2FAEnabled && (
					<i
						className='material-icons boxed-icon two-fa-icon'
						data-tooltip={this.props.intl.formatMessage({ id: 'resetTwoFa' })}
						data-position='top'
						onClick={() => this.openResetTwoFaAuthenticationModal(userInfo?.email)}>
						lock_reset
					</i>
				)}
				{this.shouldShowAddRoleButton(roles) && (
					<i
						className='material-icons-outlined boxed-icon add-icon'
						data-tooltip={this.props.intl.formatMessage({ id: 'addARole' })}
						data-position='top'
						onClick={() => this.openAddRoleModal(memberId, roles)}>
						add
					</i>
				)}
				{this.isNurseOrDoctor(roles) && (
					<i
						className='material-icons-outlined boxed-icon edit-icon'
						onClick={() => this.openEditMemberModal(memberId)}
						data-position='top'
						data-tooltip={this.props.intl.formatMessage({ id: 'edit' })}>
						edit
					</i>
				)}
				{!this.state.hideDeleteBtn && this.showDeleteButton(roles) && (
					<i
						className='material-icons-outlined boxed-icon'
						onClick={() => this.openDeleteMemberModal(memberId, userInfo)}
						data-position='top'
						data-tooltip={this.props.intl.formatMessage({ id: 'delete' })}>
						delete
					</i>
				)}
			</div>
		);
	};

	openEditMemberModal(memberId) {
		this.setState(prevState => ({
			isEditMemberModalOpen: true,
			currentMemberId: memberId,
			currentMemberObj: prevState.members.find(m => m.memberId === memberId),
		}));
	}

	openDeleteMemberModal(memberId, userInfo) {
		this.setState({ isDeleteModalOpen: true, currentMemberId: memberId, userGuid: userInfo.id });
	}

	handleSearchChanges = async event => {
		const searchValue = event.target.value;
		this.setState({ loading: true, pageIndex: 0, searchValue: searchValue });

		if (this.timeout) clearTimeout(this.timeout);
		this.timeout = setTimeout(async () => {
			let response = {};
			const params = {
				pageSize: this.state.pageSize,
				pageIndex: 0,
				searchValue,
				filterType: MembersType.AD_MEMBERS,
			};
			if (UserRoles.SUPER_USER !== this.userRole) {
				response = await getMembers(params);
				if (response.error) {
					this.setState({ error: this.props.intl.formatMessage({ id: 'errorDuringRequestProcessing' }) });
					return;
				}
			} else {
				response = await getHealthSystemMembers({
					healthSystemId: this.state.currentHealthSystemId,
					...params,
				});
				if (response.error) {
					this.setState({ error: this.props.intl.formatMessage({ id: 'errorDuringRequestProcessing' }) });
					return;
				}
			}

			this.setState({
				loading: false,
				members: response.members,
				totalCount: response.totalCount,
			});
		}, 500);
	};

	onLoading = tab => {
		this.setState(prevState => ({
			[tab]: {
				...prevState[tab],
				loading: true,
			},
		}));
	};

	onSuccess = tab => {
		this.setState(prevState => ({
			[tab]: {
				...prevState[tab],
				loading: false,
				loaded: true,
			},
		}));
	};

	onCloseAlert = tab => {
		this.setState(prevState => ({
			[tab]: {
				...prevState[tab],
				loading: false,
				loaded: false,
				error: false,
			},
		}));
	};

	userUpdated = (userId, firstName, lastName) => {
		this.setState(prevState => {
			const userIndex = prevState.members.findIndex(member => member.userInfo.userId === userId);
			const items = [...prevState.members];
			const item = {
				...items[userIndex],
				userInfo: {
					...items[userIndex].userInfo,
					firstName: firstName,
					lastName: lastName,
				},
			};
			items[userIndex] = item;
			return { members: items };
		});
	};

	updateMemberHealthSystems = healthSystems => {
		this.setState(prevState => {
			const userIndex = prevState.members.findIndex(member => member.memberId === this.state.currentMemberId);
			if (userIndex === -1) {
				return prevState;
			}
			const items = [...prevState.members];
			const item = {
				...items[userIndex],
				healthSystems,
			};
			items[userIndex] = item;
			return { members: items };
		});
	};

	membersOnList = [
		{ label: this.props.intl.formatMessage({ id: 'nurseOnList' }), value: CompanyRoles.VIRTUAL_CARE_PROVIDER },
		{ label: this.props.intl.formatMessage({ id: 'digitalClinicianOnList' }), value: CompanyRoles.DIGITAL_CLINICIAN },
		{ label: this.props.intl.formatMessage({ id: 'doctorOnList' }), value: CompanyRoles.DOCTOR },
		{ label: this.props.intl.formatMessage({ id: 'adminOnList' }), value: CompanyRoles.ADMIN },
		{ label: this.props.intl.formatMessage({ id: 'virtualSitterOnList' }), value: CompanyRoles.VIRTUAL_SITTER },
		{ label: this.props.intl.formatMessage({ id: 'superUserOnList' }), value: CompanyRoles.SUPER_USER },
	];
	maxRolesLengthWithoutAdmin = 5;

	openAddRoleModal = (memberId, roles = []) => {
		const rolesToAdd = this.membersOnList.filter(role => !roles.map(item => item.id).includes(role.value));
		const notAdminRoles = rolesToAdd.filter(member => member.value !== CompanyRoles.ADMIN);
		const isHealthSystemShown = notAdminRoles.length === this.maxRolesLengthWithoutAdmin;
		this.setState({
			selectedRole: null,
			isBtnLoading: false,
			selectedRoleError: '',
			isAddRoleModalOpen: true,
			currentMemberId: memberId,
			rolesToAdd,
			isHealthSystemShown,
		});
	};

	setSelectedRole = selectedRole => {
		const showHealthSystem = () => {
			const notAdminRoles = this.state.rolesToAdd.filter(member => member.value !== CompanyRoles.ADMIN);
			if (notAdminRoles.length === this.maxRolesLengthWithoutAdmin) {
				return true;
			}
			if (selectedRole.value === CompanyRoles.SUPER_USER && selectedMember.healthSystems?.length === 0) {
				return true;
			}
			return false;
		};

		const selectedMember = this.state.members.find(m => m.memberId === this.state.currentMemberId);
		const selectedRoleError =
			selectedRole.value === CompanyRoles.SUPER_USER && selectedMember.healthSystems?.length > 1
				? this.props.intl.formatMessage({ id: 'superUserRoleValidation' })
				: '';
		this.setState({ isHealthSystemShown: showHealthSystem(), selectedRole, selectedRoleError });
	};

	addMemberRole = async teamId => {
		this.setState({ isBtnLoading: true });
		const params = {
			companyId: this.companyId,
			memberId: this.state.currentMemberId,
			role: this.state.selectedRole.value,
			teamId,
		};
		const addRoleResponse = await addMemberRole(params);
		if (addRoleResponse.error) {
			this.setState({ error: addRoleResponse.error.message, isAddRoleModalOpen: false });
			return;
		}
		this.setState({ isAddRoleModalOpen: false, loading: true, isBtnLoading: false }, () => this.fetchMembers());
	};

	openDeleteRoleModal = (roleId, memberId) => {
		this.setState({
			isDeleteRoleModalOpen: true,
			currentMemberId: memberId,
			roleId,
			isBtnLoading: false,
		});
	};

	handleDeleteMemberRole = async () => {
		this.setState({ isBtnLoading: true });
		const params = {
			companyId: this.companyId,
			memberId: this.state.currentMemberId,
			roleId: this.state.roleId,
		};
		const deleteRoleResponse = await deleteMemberRole(params);
		if (deleteRoleResponse.error) {
			this.setState({ error: deleteRoleResponse.error.message, isDeleteRoleModalOpen: false });
			return;
		}
		this.setState(
			{
				isDeleteRoleModalOpen: false,
				loading: true,
				isBtnLoading: false,
			},
			() => this.fetchMembers()
		);
	};

	selectInviteType = id => {
		this.props.toggleSendInvitationsModal(id, this.state.selectedAdConfig.adConfigurationTypeId);
		this.setState({ isInviteUsersDropDown: false });
	};

	render() {
		return (
			<div>
				<Table
					isLoading={this.state.loading}
					headers={this.usersTableHeaders}
					rows={this.state.loading ? [] : this.displayUsers()}
					className='users-list-table'>
					<div className='flex flex-align-center'>
						<Input
							validationOptions={{}}
							type='text'
							placeholder={this.props.intl.formatMessage({ id: 'search' })}
							bottomSpace='0'
							variant='filled flex-1'
							name='searchUsers'
							onChange={this.handleSearchChanges}
							maxLength={100}
							inputWidth='300px'
						/>
						{UserRoles.ADMIN === this.userRole && this.state.companyLevelExists && (
							<div className='position-relative invite-users-wrapper' ref={this.wrapperRef}>
								<button
									type='button'
									className='invite-user-btn'
									onClick={() => this.setState(prevState => ({ isInviteUsersDropDown: !prevState.isInviteUsersDropDown }))}>
									<img
										src={`${healthCareCdnUrl}appointments/create-appointment.svg`}
										alt='icon'
										className='create-appointment-icon'
									/>
									{translate('inviteUsers')}
								</button>
								{this.state.isInviteUsersDropDown && (
									<div className='full-page-input-results' data-cy='symptomResults'>
										{this.props.inviteType.map(type => (
											<div
												key={type.inviteTypeId}
												className='cursor-pointer'
												onClick={() => this.selectInviteType(type.inviteTypeId)}>
												<span>{type.name}</span>
											</div>
										))}
									</div>
								)}
							</div>
						)}
					</div>
				</Table>
				{!this.state.loading && (
					<Pagination
						totalCount={this.state.totalCount}
						pageSize={this.state.pageSize}
						pageIndex={this.state.pageIndex}
						onChange={(pageSize, pageIndex) => this.onChange(pageSize, pageIndex)}
					/>
				)}
				<Modal
					display={this.state.isDeleteModalOpen}
					position='center'
					className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal admin-delete-modal'
					primaryButtonLabel={translate('delete')}
					onModalSubmit={() => this.handleDeleteMember()}
					onModalClose={() => this.setState({ isDeleteModalOpen: false })}
					submitImgIcon={`${healthCareCdnUrl}appointments/save-icon.svg`}
					closeImgIcon={`${healthCareCdnUrl}appointments/cancel-appointment.svg`}>
					<form>
						<h3>{translate('deleteMember')}</h3>
						<p>{translate('areYouSureDeleteMember')}</p>
					</form>
				</Modal>
				{this.state.currentMemberObj !== null && (
					<ManageHealthSystems
						onLoading={this.onLoading}
						loaded={this.state.healthSystemAddition.loaded}
						error={this.state.healthSystemAddition.error}
						onSuccess={this.onSuccess}
						onCloseAlert={this.onCloseAlert}
						member={this.state.currentMemberObj}
						updateMemberHealthSystems={this.updateMemberHealthSystems}
						healthSystems={this.props.healthSystems}
						role={this.getRoles(this.state.currentMemberObj.roles)}
						modalSelector='editMemberModal'
						display={this.state.isEditMemberModalOpen}
						className='admin-page-modal'
						position='right'
						onModalClose={() => this.setState({ isEditMemberModalOpen: false, currentMemberId: null, currentMemberObj: null })}
					/>
				)}
				<AddMemberRole
					isAddRoleModalOpen={this.state.isAddRoleModalOpen}
					onAddMemberRole={this.addMemberRole}
					onModalClose={() => this.setState({ isAddRoleModalOpen: false })}
					isSubmitDisabled={!this.state.selectedRole?.value || !!this.state.selectedRoleError}
					rolesToAdd={this.state.rolesToAdd}
					selectedRole={this.state.selectedRole}
					selectedRoleError={this.state.selectedRoleError}
					setSelectedRole={this.setSelectedRole}
					healthSystems={this.props.healthSystems}
					isBtnLoading={this.state.isBtnLoading}
					isHealthSystemShown={this.state.isHealthSystemShown}
				/>
				<Modal
					className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal'
					display={this.state.isDeleteRoleModalOpen}
					position='center'
					onModalSubmit={this.handleDeleteMemberRole}
					onModalClose={() => this.setState({ isDeleteRoleModalOpen: false })}
					shouldSubmitOnEnter={false}
					primaryButtonLabel={this.props.intl.formatMessage({ id: 'yes' })}
					closeImgIcon={`${healthCareCdnUrl}appointments/cancel-appointment.svg`}
					submitImgIcon={`${healthCareCdnUrl}appointments/save-icon.svg`}
					primaryButtonLoading={this.state.isBtnLoading}>
					<Form height={200} className='modal-form-wrapper'>
						<h3>{translate('deleteRole')}</h3>
						<p>{translate('areYouSureDeleteRole')}</p>
					</Form>
				</Modal>
				<Alert display={this.state.error} fixed hideCloseButton message={this.state.error} variant='dark' />
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		userSession: state.user.userSession,
	};
};

export default injectIntl(connect(mapStateToProps)(AdMembers));
