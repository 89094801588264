import React from 'react';
import { useIntl } from 'react-intl';
import translate from 'i18n-translations/translate.jsx';
import { validateMaxLength } from 'infrastructure/helpers/commonHelpers.js';

const ContactInformation = props => {
	const intl = useIntl();
	return (
		<div className='flex personal-details-wrapper'>
			<div className='flex column-direction personal-details'>
				<label>
					{translate('emailAddress')}
					<input
						type='email'
						placeholder={intl.formatMessage({ id: 'emailAddress' })}
						value={props.values.emailAddress}
						readOnly={props.values.emailAddress}
						onChange={props.onChange}
						maxLength={100}
						name='emailAddress'
					/>
					<span className='red-error'>{props.errors.emailAddress}</span>
				</label>
			</div>
			<div className='flex column-direction personal-details'>
				<label>
					{translate('phoneNumber')}
					<input
						type='number'
						placeholder={intl.formatMessage({ id: 'phoneNumber' })}
						value={props.values.phoneNumber}
						onChange={props.onChange}
						name='phoneNumber'
						onKeyDown={event => validateMaxLength(event, 15)}
						className='number-input-wo-arrows'
					/>
					<span className='red-error'>{props.errors.phoneNumber}</span>
				</label>
			</div>
		</div>
	);
};
export default ContactInformation;
