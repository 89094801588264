import React, { useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import ReactSelect from 'react-select';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import { saveManualAlert } from 'api/alerts.js';
import { getRoomSettings, updateRoomSettings } from 'api/adminConfigurations.js';
import { CompanySettings } from 'constants/configurationEnums.js';
import { generateCustomStyles } from 'constants/react-select-style.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import SocketEvents from 'constants/socket-events.js';
import { VerbalRediretionLanguages, VoiceAnnouncementsEnums, VoiceAnnouncementsTypes } from 'components/Monitoring/enums.js';
import { PlayIcon, VoiceIcon } from 'icons/Monitoring/index.js';
import translate from 'i18n-translations/translate.jsx';
import { getAudioDuration } from 'infrastructure/helpers/commonHelpers.js';

const VerbalRedirections = ({ feed, conferenceInfo, verbalRedirection, patientId }) => {
	const intl = useIntl();
	const languages = [
		{ label: 'English', id: VerbalRediretionLanguages.ENGLISH, locale: '' },
		{ label: 'Spanish', id: VerbalRediretionLanguages.SPANISH, locale: 'spanish-' },
		{ label: 'Arabic', id: VerbalRediretionLanguages.ARABIC, locale: 'arabic-' },
		{ label: 'Tagalog', id: VerbalRediretionLanguages.TAGALOG, locale: 'tagalog-' },
		{
			label: 'Mandarin Chinese',
			id: VerbalRediretionLanguages.MANDARIN_CHINESE,
			locale: 'chinese-mandarin-',
		},
	];
	const [error, setError] = useState(true);
	const [expandedBox, setExpandedBox] = useState(true);
	const doNotGetUpObj = VoiceAnnouncementsEnums[VoiceAnnouncementsTypes.DO_NOT_GET_UP];
	const [selectedAnnouncement, setSelectedAnnouncement] = useState({
		label: intl.formatMessage({ id: doNotGetUpObj.text }),
		value: doNotGetUpObj.id,
		url: doNotGetUpObj.url,
	});
	const baseUrl = `${healthCareCdnUrl}sound/`;
	const socket = useContext(SocketContext);
	const user = useSelector(state => state.user);
	const [isLoading, setIsLoading] = useState(true);
	const [isVoicePlaying, setIsVoicePlaying] = useState(false);
	const timeout = useRef(null);
	const patientNotes = useSelector(state => state.patientNotes.savedMode);
	const [selectedLanguage, setSelectedLanguage] = useState(patientNotes[feed.deviceId]?.roomLanguage || languages[0]);
	useEffect(() => {
		const fetchRoomSettings = async () => {
			const response = await getRoomSettings(feed.roomId);
			if (response.error) {
				return;
			}
			const foundItem = response.settings.find(item => item.settingTypeId === CompanySettings.LANGUAGE);
			if (foundItem) {
				const foundLanguage = languages.find(item => item.label === foundItem.value);
				setSelectedLanguage(foundLanguage ? foundLanguage : languages[0]);
			}
			setIsLoading(false);
		};
		fetchRoomSettings();
		return () => {
			if (timeout) {
				clearTimeout(timeout.current);
			}
		};
	}, [feed.roomId]);

	const playVoiceAnnouncements = () => {
		if (!feed || isVoicePlaying) {
			return;
		}
		const audioUrl = `${baseUrl}${selectedLanguage.locale}${selectedAnnouncement.url}`;
		getAudioDuration(audioUrl, duration => {
			socket.emit(SocketEvents.Conference.PLAY_VERBAL_REDIRECTION, {
				conferenceId: conferenceInfo.conferenceId,
				participantId: conferenceInfo.participantId,
				actioneeParticipantId: feed.participantId,
				data: audioUrl,
				shouldForward: true,
			});
			setIsVoicePlaying(true);
			timeout.current = setTimeout(() => {
				setIsVoicePlaying(false);
			}, duration * 1000);
		});
	};

	const handleSelectedLanguage = async item => {
		const settings = [
			{
				isLocked: false,
				value: item.label,
				settingTypeId: CompanySettings.LANGUAGE,
			},
		];
		const response = await updateRoomSettings(feed.roomId, settings);
		if (!response.error) {
			setSelectedLanguage(item);
		}
	};

	return (
		<>
			<div className='monitoring-timeline-box voice-announcements'>
				<div className={classNames('timeline-box-header', expandedBox ? 'expanded' : '')}>
					<p className='timeline-box-title flex-1'>
						<VoiceIcon />
						{translate('voiceAnnouncements')}
					</p>
					<ReactSelect
						options={languages.map(item => ({
							label: item.label,
							value: item.id,
							locale: item.locale,
						}))}
						value={!isLoading ? selectedLanguage : null}
						onChange={item => handleSelectedLanguage(item)}
						id='select'
						className='select-voice-announcements'
						classNamePrefix='react-select'
						styles={generateCustomStyles({ darkMode: user.darkMode })}
					/>
					<div className='timeline-box-actions'>
						<button type='button' onClick={() => setExpandedBox(prevState => !prevState)}>
							<i className={classNames('material-icons', expandedBox ? ' rotate' : '')}>expand_more</i>
						</button>
					</div>
				</div>
				{expandedBox && (
					<div className={classNames('timeline-box-content', expandedBox ? ' expanded' : '')}>
						<div className='flex'>
							<ReactSelect
								options={Object.values(VoiceAnnouncementsEnums).map(item => ({
									label: intl.formatMessage({ id: item.text }),
									value: item.id,
									url: item.url,
								}))}
								isDisabled={isVoicePlaying}
								value={selectedAnnouncement}
								onChange={item => setSelectedAnnouncement(item)}
								id='select'
								className='select-voice-announcements'
								classNamePrefix='react-select'
								styles={generateCustomStyles({ darkMode: user.darkMode })}
							/>
							<button
								type='button'
								className={classNames('play-voice-announcements', isVoicePlaying ? 'played' : '')}
								onClick={playVoiceAnnouncements}
								disabled={!selectedAnnouncement}>
								{!isVoicePlaying && <PlayIcon />}
							</button>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default VerbalRedirections;
