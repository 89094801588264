import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';
import Alert from 'components/Alert.jsx';
import Grid from 'components/Grid.jsx';
import Loader from 'components/Loader.jsx';
import FormInput from 'components/FormInput.jsx';
import translate from 'i18n-translations/translate.jsx';
import { getUserId } from 'infrastructure/auth.js';
import { getUsersRpmList } from 'api/rpm.js';

const Logistics = () => {
	const [searchBox, setSearchBox] = useState('');
	const [patientLogistics, setPatientLogistics] = useState([]);
	const [pageIndex, setPageIndex] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const hasReachedEnd = useRef(null);
	const intl = useIntl();

	const getRpmPatients = useCallback(async () => {
		const response = await getUsersRpmList({ userId: getUserId(), pageIndex });
		if (response.error) {
			setError(response.error.message);
			setIsLoading(false);
			return;
		}
		setPatientLogistics(prevState => prevState.concat(response.rpmListPatients));
		hasReachedEnd.current = response.totalCount.length <= 20;
		setIsLoading(false);
	}, [pageIndex]);

	useEffect(() => {
		getRpmPatients();
	}, [getRpmPatients]);

	const getSearchedPatients = item => {
		const fullName = `${item.firstName} ${item.lastName}`;
		return (
			item.firstName?.toUpperCase().includes(searchBox.toUpperCase()) ||
			item.lastName?.toUpperCase().includes(searchBox.toUpperCase()) ||
			fullName?.toUpperCase().includes(searchBox.toUpperCase())
		);
	};

	const getFilteredPatients = () => {
		const patientsList = [...patientLogistics];
		let filteredList = patientsList;
		if (searchBox) {
			filteredList = patientsList.filter(item => getSearchedPatients(item));
		}
		return filteredList;
	};

	const handleScroll = event => {
		const isBottom = event.target.scrollHeight - Math.ceil(event.target.scrollTop) === event.target.clientHeight;
		if (isBottom && !hasReachedEnd.current) {
			setPageIndex(prevState => prevState + 1);
		}
	};

	return (
		<MainLayout>
			{isLoading && (
				<Grid width='100%' stretch='100vh' vertAlign='center' horizAlign='center' rows='auto'>
					<Loader />
				</Grid>
			)}
			{!isLoading && (
				<div className='patient-alerts-wrapper rpm full-width' onScroll={handleScroll}>
					<div className='flex flex-space-between flex-align-center'>
						<h4>{translate('logistics')}</h4>
					</div>
					<div className='flex full-width patients-alert-filter'>
						<FormInput
							id='searchBox'
							labelClassName='full-width'
							name='searchBox'
							text=''
							type='search'
							onChange={event => setSearchBox(event.target.value)}
							value={searchBox}
							placeholder={intl.formatMessage({ id: 'searchByName' })}
							className='full-width'
						/>
					</div>
					<div className='patients-alert-table'>
						<div className='flex patients-alert-table-header logistics'>
							<div className='flex-initial'>
								<p>MRN</p>
							</div>
							<div>
								<p>{translate('patientName')}</p>
							</div>
							<div>
								<p>{translate('rpmProgram')}</p>
							</div>
							<div>
								<p>{translate('shippingAddress')}</p>
							</div>
							<div>
								<p>{translate('dateAdded')}</p>
							</div>
							<div>
								<p>{translate('status')}</p>
							</div>
						</div>
						<div className='patients-alert-table-body'>
							{getFilteredPatients().map(patient => (
								<div key={patient.userId} className='flex full-width cursor-pointer' onClick={null}>
									<div className='patient-alert-body-vs logistics mrn position-relative'>
										<span>{patient.mrn}</span>
									</div>
									<div className='patient-alert-body-vs logistics position-relative'>
										<span>
											{patient.firstName} {patient.lastName}
										</span>
									</div>
									<div className='patient-alert-body-vs logistics position-relative'>
										<span>{patient.rpmProgram?.name}</span>
									</div>
									<div className='patient-alert-body-vs logistics position-relative'>
										<span>{`${patient.postalCode} ${patient.address} ${patient.city} ${patient?.country ?? ''}`}</span>
									</div>
									<div className='patient-alert-body-vs logistics position-relative'>
										<span>{formattedDate(patient.dateCreated)}</span>
									</div>
									<div className='patient-alert-body-vs logistics position-relative'>
										<div className='shipped-wrapper'>
											<div className='green'>
												<span>{translate('shipped')}</span>
											</div>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			)}
			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
		</MainLayout>
	);
};

export default Logistics;
