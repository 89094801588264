import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from 'components/Tabs.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import Grid from 'components/Grid.jsx';
import translate from 'i18n-translations/translate.jsx';
import Button from 'components/Button.jsx';
import { generateMRN } from 'infrastructure/helpers/commonHelpers.js';
import { getPatientByIdCard } from 'api/patients.js';
import { AddEditPatientRadioTypes, MeasurementUnitOptions, PatientAgeLimit, TaskStatus, MemberType } from 'constants/enums.js';
import RpmPrograms from 'containers/Rpm/RpmPrograms.jsx';
import CareTeam from 'containers/Rpm/CareTeam.jsx';
import { getCompanyId } from 'infrastructure/auth.js';
import { Allergies } from 'constants/visitEnums.js';
import { convertToMmol, convertFeetToCm, convertInchToCm, convertToKg } from 'infrastructure/helpers/measurementsHelper.js';
import { getCountries } from 'api/users.js';
import { addRpmPatient, addRpmToExistingPatient } from 'api/rpm.js';
import useOutsideClick from 'infrastructure/helpers/useOutsideClick.js';
import PatientDetailsForm from 'components/CheckInAPatient/PatientDetailsForm.jsx';
import SelectPatient from 'containers/Rpm/SelectPatient.jsx';
import SocketEvents from 'constants/socket-events.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';

const RpmPatientOnboarding = () => {
	const intl = useIntl();
	const history = useHistory();
	const [currentTab, setCurrentTab] = useState(0);
	const [selectedProgram, setSelectedProgram] = useState(null);
	const [foodAllergies, setFoodAllergies] = useState([]);
	const [medicationAllergies, setMedicationAllergies] = useState([]);
	const [environmentalAllergies, setEnvironmentalAllergies] = useState([]);
	const [biologicalAllergies, setBiologicalAllergies] = useState([]);
	const [birthDateError, setBirthDateError] = useState(null);
	const [countries, setCountries] = useState([]);
	const [addPatientError, setAddPatientError] = useState(null);
	const [isAddLoading, setIsAddLoading] = useState(false);
	const [isTaskLoading, setIsTaskLoading] = useState(false);
	const [newPatient, setNewPatient] = useState(null);
	const [isGenderOpen, setIsGenderOpen] = useState(false);
	const [updatedValues, setUpdatedValues] = useState(null);
	const [hasErrors, setHasErrors] = useState(true);
	const genderWrapperRef = useRef(null);
	const companyId = getCompanyId();
	const [mrn, setMrn] = useState('');
	const [isAddNewPatient, setIsAddNewPatient] = useState(false);
	const [isViewProfileOpen, setIsViewProfileOpen] = useState(false);
	const [patientDetails, setPatientDetails] = useState(null);
	const [patientUserId, setPatientUserId] = useState(null);
	const userSession = useSelector(state => state.user.userSession);
	const [doctors, setDoctors] = useState([]);
	const [nurses, setNurses] = useState([]);
	const [familyMembers, setFamilyMembers] = useState([]);
	const [specialties, setSpecialties] = useState([]);
	const [patientId, setPatientId] = useState(null);
	const [tobaccoCode, setTobaccoCode] = useState(null);
	const [diabeticCode, setDiabeticCode] = useState(null);
	const [solDeviceId, setSolDeviceId] = useState(null);
	const socket = useContext(SocketContext);

	const genders = [
		{ id: 1, name: intl.formatMessage({ id: 'male' }) },
		{ id: 2, name: intl.formatMessage({ id: 'female' }) },
	];

	const healthInformationStatusList = [
		{
			code: AddEditPatientRadioTypes.YES,
			name: translate('yes'),
			status: true,
		},
		{
			code: AddEditPatientRadioTypes.NO,
			name: translate('no'),
			status: false,
		},
		{
			code: AddEditPatientRadioTypes.NO_ANSWER,
			name: 'N/A',
			status: null,
		},
	];

	useOutsideClick(genderWrapperRef, () => {
		if (isGenderOpen) {
			setIsGenderOpen(false);
		}
	});

	useEffect(() => {
		getCountriesList();
	}, []);

	useEffect(() => {
		setMrn(generateMRN(8));
	}, [isAddNewPatient, isViewProfileOpen]);

	const getCountriesList = async () => {
		const countriesList = await getCountries();
		if (countriesList.error) {
			setAddPatientError(countriesList.error.message);
			return;
		}
		setCountries(countriesList.countries);
	};

	const getPatientByCard = useCallback(async () => {
		if (!newPatient) {
			return;
		}
		const addedPatientRes = await getPatientByIdCard(newPatient.idCard);
		if (addedPatientRes.error) {
			setIsAddLoading(false);
			setAddPatientError(addedPatientRes.error.message);
			return;
		}
		setIsAddLoading(false);
		setIsTaskLoading(false);
	}, [newPatient]);

	useEffect(() => {
		const handleTaskStatusUpdated = async data => {
			if (data.taskStatusId === TaskStatus.COMPLETED) {
				await getPatientByCard();
				history.push('/patient-monitoring');
			}
			if (data.taskStatusId === TaskStatus.FAULTED) {
				setAddPatientError(translate('somethingWentWrong'));
				setIsAddLoading(false);
				setIsTaskLoading(false);
			}
		};
		socket.on(SocketEvents.BACKGROUND.TASK_UPDATED, handleTaskStatusUpdated);
		return () => {
			socket.off(SocketEvents.BACKGROUND.TASK_UPDATED, handleTaskStatusUpdated);
		};
	}, [socket, getPatientByCard, history]);

	const tabs = {
		ADD_PATIENT: 0,
		RPM_PROGRAM: 1,
		CARE_TEAM: 2,
	};

	const imgUrl = `${healthCareCdnUrl}requests/`;

	const tabList = [
		{
			id: 0,
			title: translate('selectPatient'),
			mainImg: `${imgUrl}1-gray.svg`,
			activeImg: `${imgUrl}1-blue.svg`,
			pastImg: `${imgUrl}1-blue.svg`,
		},
		{
			id: 1,
			title: translate('rpmProgram'),
			mainImg: `${imgUrl}2-gray.svg`,
			activeImg: `${imgUrl}2-blue.svg`,
			pastImg: `${imgUrl}2-blue.svg`,
		},
		{
			id: 2,
			title: translate('careTeam'),
			mainImg: `${imgUrl}3-gray.svg`,
			activeImg: `${imgUrl}3-blue.svg`,
			pastImg: `${imgUrl}3-blue.svg`,
		},
	];

	const tabChange = tab => {
		if (canChangeTab(tab)) {
			if (tab === tabs.CARE_TEAM && !selectedProgram) {
				return;
			}
			setCurrentTab(tab);
		}
	};

	const canChangeTab = nextTab => {
		let result = false;
		switch (currentTab) {
			case tabs.ADD_PATIENT: {
				if (!hasErrors) {
					result = true;
				}
				break;
			}
			case tabs.RPM_PROGRAM: {
				if (selectedProgram || nextTab < currentTab) {
					result = true;
				}
				break;
			}
			case tabs.CARE_TEAM: {
				if (nextTab < currentTab) {
					result = true;
				}
				break;
			}
			default: {
				return true;
			}
		}
		return result;
	};

	const getDateOfBirth = values => {
		if (!values.birthMonth || !values.birthYear) {
			return null;
		}
		const month = moment(values.birthMonth).format('MM');
		const year = moment(values.birthYear).format('YYYY');
		let day = values.birthDay.toString();
		if (day.length === 1) {
			day = `${0}${day}`;
		}
		return `${year}-${month}-${day}`;
	};

	const toggleGenderDropDown = (item, formikProps) => {
		formikProps.setFieldValue('gender', item);
		setIsGenderOpen(false);
	};

	const getAllergies = values => {
		const allergies = [];
		if (values.allergies?.length > 0) {
			values.allergies.forEach(element => {
				let note = '';
				switch (parseInt(element, 10)) {
					case Allergies.FOOD.id:
						if (foodAllergies.length > 0) {
							const foodArr = [...foodAllergies];
							note = JSON.stringify(foodArr);
						}
						break;

					case Allergies.MEDICATION.id:
						if (medicationAllergies.length > 0) {
							const medicArr = [...medicationAllergies];
							note = JSON.stringify(medicArr);
						}
						break;

					case Allergies.ENVIRONMENTAL.id:
						if (environmentalAllergies.length > 0) {
							const environmentalArr = [...environmentalAllergies];
							note = JSON.stringify(environmentalArr);
						}
						break;

					case Allergies.BIOLOGICAL.id:
						if (biologicalAllergies.length > 0) {
							const biologicalArr = [...biologicalAllergies];
							note = JSON.stringify(biologicalArr);
						}
						break;
					default:
						note = '';
				}

				allergies.push({
					categoryId: parseInt(element, 10),
					note,
				});
			});
		}
		return allergies;
	};

	const getHealthInformation = values => {
		const allergies = getAllergies(values);
		let tobaccoSmokeCode = '';
		if (parseInt(values.isTobaccoSmoker, 10) === AddEditPatientRadioTypes.YES) {
			tobaccoSmokeCode = values.tobaccoYesCode;
		}
		if (parseInt(values.isTobaccoSmoker, 10) === AddEditPatientRadioTypes.NO) {
			tobaccoSmokeCode = values.tobaccoNoCode;
		}
		return {
			isTobaccoSmoker: parseInt(values.isTobaccoSmoker, 10),
			hasDiabet: parseInt(values.hasDiabet, 10),
			hasHyperTension: parseInt(values.hasHyperTension, 10),
			isTakingMedication: parseInt(values.isTakingMedication, 10),
			hasAllergy: parseInt(values.hasAllergy, 10),
			hasPreExistingCondition: parseInt(values.hasPreExistingCondition, 10),
			height:
				values.heightUnit === MeasurementUnitOptions.HEIGHT.value[1]?.text || !values.height
					? values.height.toString()
					: convertFeetToCm(parseFloat(values.height)).toString(),
			heightUnit: 'cm',
			weight:
				values.weightUnit === MeasurementUnitOptions.WEIGHT.value[1]?.text || !values.weight
					? values.weight.toString()
					: convertToKg(parseFloat(values.weight)).toString(),
			weightUnit: 'kg',
			totalCholesterol:
				values.totalCholesterolUnit === MeasurementUnitOptions.BLOOD_GLUCOSE.value[1]?.text || !values.totalCholesterol
					? values.totalCholesterol.toString()
					: convertToMmol(parseFloat(values.totalCholesterol)).toString(),
			totalCholesterolUnit: values.totalCholesterol ? 'mmol/L' : '',
			hdlCholesterol:
				values.hdlCholesterolUnit === MeasurementUnitOptions.BLOOD_GLUCOSE.value[1]?.text || !values.hdlCholesterol
					? values.hdlCholesterol.toString()
					: convertToMmol(parseFloat(values.hdlCholesterol)).toString(),
			hdlCholesterolUnit: values.hdlCholesterol ? 'mmol/L' : '',
			waistCircumference:
				values.waistCircumferenceUnit === MeasurementUnitOptions.WAIST_CIRCUMFERENCE.value[1]?.text || !values.waistCircumference
					? values.waistCircumference.toString()
					: convertInchToCm(parseFloat(values.waistCircumference)).toString(),
			waistCircumferenceUnit: values.waistCircumference ? 'cm' : '',
			tobaccoSmokeCode,
			diabeticStatusCode: parseInt(values.hasDiabet, 10) === AddEditPatientRadioTypes.YES ? values.diabeticStatusCode : '',
			allergies,
			preExistingCondition: values.preExistingCondition.trim(),
		};
	};

	const onSubmitForm = async values => {
		const combinedArr = doctors.map((member, index) => ({ ...member, ...specialties[index] }));
		const careTeam = [
			...combinedArr.map(item => ({ userId: item.value, email: null, careMemberType: MemberType.DOCTOR })),
			...nurses.map(item => ({ userId: item.value, email: null, careMemberType: MemberType.NURSE })),
			...familyMembers.map(item => ({ userId: null, email: item.value, careMemberType: MemberType.FAMILY_MEMBER })),
		];
		setAddPatientError(null);
		if (!selectedProgram) {
			setAddPatientError(intl.formatMessage({ id: 'pleaseSelectRpmProgram' }));
			return;
		}
		if (!moment(getDateOfBirth(values)).isValid() || moment(getDateOfBirth(values)).diff(moment(), 'days') >= 0) {
			setBirthDateError(intl.formatMessage({ id: 'dateOfBirthNotValid' }));
			return;
		}
		if (moment().diff(getDateOfBirth(values), 'years') > PatientAgeLimit.MAX) {
			setBirthDateError(intl.formatMessage({ id: 'maxBirthdayLimit' }));
			return;
		}
		setBirthDateError(null);
		const params = {
			companyId,
			mrn,
			idCard: values.idNumber.toString(),
			firstName: values.firstName.trim(),
			lastName: values.lastName.trim(),
			address: values.address,
			phoneNumber: values.phoneNumber.toString(),
			email: values.emailAddress,
			genderId: values.gender.id,
			birthDate: getDateOfBirth(values) || null,
			showConsent: values.showConsent,
			healthInformation: getHealthInformation(values),
			postalCode: values.zipCode?.toString(),
			city: values?.city,
			country: values.country,
			communicationLanguage: null,
			rpmProgramId: selectedProgram?.value,
			healthSystemId: userSession.healthSystem.id,
			careTeam: careTeam.filter(item => (item.careMemberType !== MemberType.FAMILY_MEMBER ? item.userId : item.email)),
			solDeviceId,
		};
		if (params.healthInformation?.allergies?.some(item => !item.note)) {
			return;
		}
		setIsAddLoading(true);
		const response = isAddNewPatient ? await addRpmPatient(params) : await addRpmToExistingPatient(patientUserId, params);

		if (response.error) {
			setAddPatientError(response.error.message);
			setIsAddLoading(false);
			return;
		}
		if (!isAddNewPatient) {
			setIsAddLoading(false);
			history.push('/patient-monitoring');
			return;
		}
		setNewPatient(params);
		setIsTaskLoading(true);
	};

	return (
		<MainLayout>
			{currentTab !== tabs.ADD_PATIENT && (
				<Button
					onClick={() => tabChange(currentTab - 1)}
					imgIcon={`${healthCareCdnUrl}backward-arrow-dark-gray.svg`}
					text={translate('goBack')}
					className='requests-wrapper-button'
					variant='backward'
				/>
			)}
			{currentTab === tabs.ADD_PATIENT && (isAddNewPatient || isViewProfileOpen) && (
				<Button
					onClick={() => {
						setIsAddNewPatient(false);
						setIsViewProfileOpen(false);
					}}
					imgIcon={`${healthCareCdnUrl}backward-arrow-dark-gray.svg`}
					text={translate('goBack')}
					className='requests-wrapper-button'
					variant='backward'
				/>
			)}
			{currentTab !== tabs.CARE_TEAM && (
				<Button
					onClick={() => {
						tabChange(currentTab + 1);
					}}
					imgIcon={`${healthCareCdnUrl}forward-arrow-white.svg`}
					text={translate('next')}
					className='requests-wrapper-button'
					variant='forward'
				/>
			)}
			<Tabs activeIndex={currentTab} onChange={index => tabChange(index)}>
				<TabList className='doctor-wrapper-tabs rpm-tabs-wrapper'>
					{tabList.map(tab => (
						<Tab
							className={classNames(currentTab > tab.id ? 'tab-past' : '', currentTab < tab.id ? 'tab-next' : '')}
							key={tab.id}>
							{currentTab === tab.id && <img src={tab.activeImg} alt='icon' />}
							{currentTab > tab.id && <img src={tab.pastImg} alt='icon' />}
							{currentTab < tab.id && <img src={tab.mainImg} alt='icon' />}
							{tab.title}
						</Tab>
					))}
				</TabList>
				<TabPanels>
					<TabPanel>
						<Grid columns='1fr' stretch='100%'>
							<div className='check-in-patient-wrapper select-doctor-tabs-wrapper add-edit-patient-form-wrapper'>
								{!isAddNewPatient && !isViewProfileOpen && (
									<SelectPatient
										setIsAddNewPatient={setIsAddNewPatient}
										setIsViewProfileOpen={setIsViewProfileOpen}
										setUpdatedValues={setUpdatedValues}
										setPatientDetails={setPatientDetails}
										setCountries={setCountries}
										setPatientUserId={setPatientUserId}
										setPatientId={setPatientId}
										setTobaccoCode={setTobaccoCode}
										setDiabeticCode={setDiabeticCode}
										setSolDeviceId={setSolDeviceId}
									/>
								)}
								{(isAddNewPatient || isViewProfileOpen) && (
									<PatientDetailsForm
										title={translate(isAddNewPatient ? 'addNewPatient' : 'patientProfile')}
										genderWrapperRef={genderWrapperRef}
										isAddPatient={isAddNewPatient}
										setIsGenderOpen={() => setIsGenderOpen(prevState => !prevState)}
										isGenderOpen={isGenderOpen}
										genders={genders}
										toggleGenderDropDown={toggleGenderDropDown}
										birthDateError={birthDateError}
										setBirthDateError={value => setBirthDateError(value)}
										submitButtonText={intl.formatMessage({ id: 'addPatient' })}
										countries={countries}
										foodAllergies={foodAllergies}
										environmentalAllergies={environmentalAllergies}
										biologicalAllergies={biologicalAllergies}
										setFoodAllergies={setFoodAllergies}
										medicationAllergies={medicationAllergies}
										setMedicationAllergies={setMedicationAllergies}
										setEnvironmentalAllergies={setEnvironmentalAllergies}
										setBiologicalAllergies={setBiologicalAllergies}
										getDateOfBirth={getDateOfBirth}
										setUpdatedValues={setUpdatedValues}
										setHasErrors={setHasErrors}
										updatedValues={updatedValues}
										patientDetails={patientDetails}
										healthInformationStatusList={healthInformationStatusList}
										isRpmPatient={true}
										mrn={mrn}
										isViewProfileOpen={isViewProfileOpen}
										tobaccoCode={tobaccoCode}
										diabeticCode={diabeticCode}
									/>
								)}
							</div>
						</Grid>
					</TabPanel>
					<TabPanel>
						<RpmPrograms selectedProgram={selectedProgram} setSelectedProgram={setSelectedProgram} />
					</TabPanel>
					<CareTeam
						submitButtonText={intl.formatMessage({ id: 'addPatient' })}
						selectedProgram={selectedProgram}
						onSubmitForm={onSubmitForm}
						isAddLoading={isAddLoading}
						error={addPatientError}
						isTaskLoading={isTaskLoading}
						updatedValues={updatedValues}
						setUpdatedValues={setUpdatedValues}
						setDoctors={setDoctors}
						setNurses={setNurses}
						setSpecialties={setSpecialties}
						setFamilyMembers={setFamilyMembers}
						patientId={patientId}
					/>
				</TabPanels>
			</Tabs>
		</MainLayout>
	);
};

export default RpmPatientOnboarding;
