import { HomeActionTypes, CallsActionTypes } from 'constants/action-types.js';

const initialState = {
	isLeftNavigationExpanded: false,
	isFullscreen: false,
	isUsersModalOpen: false,
	isDeviceModalOpen: false,
	isSwitchHealthSystemModalOpen: false,
	isRoomModalOpen: false,
	breadcrumb: [],
	allHealthSystems: [],
	hospitalTree: {},
	currentHospitalId: null,
	currentHealthSystemId: null,
	currentHealthSystemName: '',
	currentRegionId: null,
	treeData: {
		tree: [],
		preSelected: [],
	},
	isRoomsOnlyView: false,
	leftNavigationItems: [
		{
			id: 1,
			icon: 'panorama_fish_eye',
			linkTo: '/health-system',
			title: 'Organization',
		},
		{
			id: 2,
			icon: 'person',
			linkTo: '/users',
			title: 'User Management',
		},
		{
			id: 3,
			icon: 'assessment',
			linkTo: '/call-logs',
			title: 'Call logs',
		},
		{
			id: 4,
			icon: 'settings',
			linkTo: '#',
			title: 'Settings',
		},
		{
			id: 5,
			icon: 'unfold_more',
			linkTo: '#',
			title: 'Collapse',
			role: 'toggleLeftNavigation',
		},
	],
	nurseLeftNavigationItems: [
		{
			id: 1,
			linkTo: '/patients',
			title: 'Patients',
			icon: 'account_circle',
		},
		{
			id: 2,
			linkTo: '/appointments',
			title: 'Appointments',
			icon: 'calendar_today',
		},
		{
			id: 3,
			icon: 'panorama_fish_eye',
			linkTo: '/health-system',
			title: 'Hospitals',
			image: 'https://static.solaborate.com/americanwell/hospital-item.png',
		},
		{
			id: 4,
			icon: 'settings',
			linkTo: '#',
			title: 'Settings',
		},
		{
			id: 5,
			icon: 'unfold_more',
			linkTo: '#',
			title: 'Collapse',
			role: 'toggleLeftNavigation',
		},
	],
	callLogsHeaders: [
		{
			title: 'Call Initiator',
		},
		{
			title: 'Virtual Care Provider',
		},
		{
			title: 'Room',
		},
		{
			title: 'Date/Time',
		},
		{
			title: 'Call Type',
		},
		{
			title: 'Call Status',
		},
		{
			title: 'Call Duration',
		},
		{
			title: 'Audio Used',
		},
		{
			title: 'VIdeo Used',
		},
		{
			title: 'Content Sharing',
		},
	],
	lists: [
		{
			title: 'Change presence',
			icon: 'person',
			subLists: [
				{
					component: null,
				},
				{
					title: 'Available',
					icon: 'fiber_manual_record',
					action: 1,
				},
				{
					title: 'Unavailable',
					icon: 'fiber_manual_record',
					action: 2,
				},
			],
		},
		{
			title: 'Change Language',
			icon: 'person',
			subLists: [
				{
					component: null,
				},
				{
					title: 'English',
					icon: 'fiber_manual_record',
					action: 1,
				},
				{
					title: 'Albanian',
					icon: 'fiber_manual_record',
					action: 2,
				},
			],
		},
		{
			title: 'Edit profile',
			icon: 'person',
		},
		{
			title: 'Account settings',
			icon: 'settings',
		},
	],
	deivceStatusData: { status: false },
	selectedDevice: { deviceName: '' },
	queueDeviceToMonitoring: null,
	currentSectorLocation: [],
	streamPermission: null,
	monitoredDevices: { inCallDevices: [], initiatedDevices: [] },
	isHealthSystemFetched: false,
	measurementAlertData: null,
};

export const healthSystems = (state = initialState, action) => {
	if (action.type === HomeActionTypes.SET_IS_HEALTH_SYSTEM_FETCHED) {
		return {
			...state,
			isHealthSystemFetched: action.payload,
		};
	}

	if (action.type === HomeActionTypes.UPDATE_MONITORING_DEVICES) {
		return { ...state, monitoredDevices: action.payload };
	}

	if (action.type === HomeActionTypes.UPDATE_BREADCRUMB) {
		return { ...state, breadcrumb: action.payload };
	}

	if (action.type === HomeActionTypes.SET_ALL_HEALTH_SYSTEMS) {
		return {
			...state,
			allHealthSystems: action.payload,
		};
	}

	if (action.type === HomeActionTypes.SET_CURRENT_HEALTH_SYSTEM_INFO) {
		return {
			...state,
			currentHealthSystemId: action.payload.currentHealthSystemId,
			currentRegionId: action.payload.currentRegionId,
			currentHealthSystemName: action.payload.currentHealthSystemName,
		};
	}

	if (action.type === HomeActionTypes.SET_STREAM_PERMISSION_MESSAGE) {
		return { ...state, streamPermission: action.payload };
	}

	if (action.type === HomeActionTypes.CREATE_NEW_HOSPITAL) {
		const newSection = state.treeData.tree.find(item => item.isNewOption);
		if (newSection) {
			return {
				...state,
			};
		}
		state.treeData.tree.push({
			icon: 'business',
			type: 'hospital',
			isNewOption: true,
			subOptions: [],
		});

		const newTreeData = state.treeData.tree.slice();
		return {
			...state,
			treeData: {
				tree: newTreeData,
			},
		};
	}

	if (action.type === HomeActionTypes.SET_CURRENT_HOSPITAL_ID) {
		return { ...state, currentHospitalId: action.payload };
	}

	if (action.type === HomeActionTypes.SET_HOSPITAL_TREE) {
		return { ...state, hospitalTree: action.payload };
	}

	if (action.type === HomeActionTypes.SET_TREE_DATA) {
		return { ...state, treeData: action.payload };
	}
	if (action.type === HomeActionTypes.SET_HEALTH_SYSTEM) {
		return { ...state, selectedHealthSystem: action.payload };
	}

	if (action.type === HomeActionTypes.TOGGLE_LEFT_NAVIGATION) {
		return {
			...state,
			isLeftNavigationExpanded: !state.isLeftNavigationExpanded,
		};
	}

	if (action.type === HomeActionTypes.TOGGLE_LEFT_NAVIGATION_VALUE) {
		return {
			...state,
			isLeftNavigationExpanded: action.payload,
		};
	}

	if (action.type === HomeActionTypes.TOGGLE_FULLSCREEEN) {
		return {
			...state,
			isFullscreen: !state.isFullscreen,
		};
	}

	if (action.type === HomeActionTypes.TOGGLE_IS_ROOMS_VIEW) {
		return {
			...state,
			isRoomsOnlyView: !state.isRoomsOnlyView,
		};
	}

	if (action.type === HomeActionTypes.TOGGLE_HEALTH_SYTEM_MODAL) {
		return {
			...state,
			isSwitchHealthSystemModalOpen: !state.isSwitchHealthSystemModalOpen,
		};
	}

	if (action.type === HomeActionTypes.TOGGLE_USERS_MODAL) {
		return {
			...state,
			isUsersModalOpen: !state.isUsersModalOpen,
			inviteUserType: action.userType,
		};
	}

	if (action.type === HomeActionTypes.TOGGLE_DEVICE_MODAL) {
		return { ...state, isDeviceModalOpen: !state.isDeviceModalOpen };
	}

	if (action.type === HomeActionTypes.SET_USER) {
		return { ...state, user: action.user };
	}

	if (action.type === CallsActionTypes.monitoringDeviceAddedSuccessfully) {
		const { queueDeviceToMonitoring } = state,
			{ deviceId } = action.payload;

		if (queueDeviceToMonitoring == null || queueDeviceToMonitoring.deviceId !== deviceId) {
			return {
				...state,
				queueDeviceToMonitoring,
			};
		}

		return {
			...state,
			queueDeviceToMonitoring: null,
		};
	}

	if (action.type === HomeActionTypes.NEW_MEASUREMENT_DATA) {
		return { ...state, measurementAlertData: action.payload };
	}

	return state;
};
