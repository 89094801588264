import React, { useState, useRef, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import { enums } from '@solaborate/calls';
import { ToggleGroup, Modal, Button, Icon, Input } from 'calls/components/index.js';
import { CameraMaxZoomLevels, CameraTypes } from 'calls/enums/index.js';
import { LOCALES } from 'i18n-translations/locales.js';
import translate from 'i18n-translations/translate.jsx';
import SocketEvents from 'constants/socket-events.js';
import { CameraDirections, CameraType, CameraZoomLevels } from 'constants/enums.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import { getPreferredLanguageLocale } from 'infrastructure/helpers/commonHelpers.js';
import { Dropdown } from 'components/index.js';
import {
	StyledPTZ,
	StyledCameraControls,
	StyledCameraBookmarks,
	StyledAddNewCameraBookmarksButton,
} from 'css/StyledComponents/index.js';

const { CameraEventActions } = enums;

/**
 * @param {object} props
 */
const CameraControls = ({
	feed,
	sendCameraEvent,
	conferenceInfo,
	showPTZ,
	showDeviceControlsLockedModal,
	setActiveDevice,
	isDarkMode,
	deviceListChanged,
	hasIconsAboveCameraControls,
}) => {
	const socket = useContext(SocketContext);
	const [isRebootHuddleCamModalOpen, setIsRebootHuddleCamModalOpen] = useState(false);
	const [isSwitchDeviceDisabled, setIsSwitchDeviceDisabled] = useState(false);
	const [toggleBookmark, setToggleBookmark] = useState(false);
	const [bookmark, setBookmark] = useState('');
	const [bookmarkToDelete, setBookmarkToDelete] = useState(null);
	const [isDeleteBookmarkModalOpen, setIsDeleteBookmarkModalOpen] = useState(false);
	const [isBookmarkModalOpen, setIsBookmarkModalOpen] = useState(false);
	const [sliderRange, setSliderRange] = useState(feed.mediaDevices?.find(item => item.isActive)?.capabilities?.zoom?.current);
	const [activePreset, setActivePreset] = useState(null);
	const locale = getPreferredLanguageLocale();
	const bookmarksLimit = 9;
	const intl = useIntl();

	const disabledSwitchTimeout = useRef(null);
	const moveCamInterval = useRef(null);

	const [bookmarks, setBookmarks] = useState([]);
	const [availableDirections, setAvailableDirections] = useState(
		feed.mediaDevices?.find(item => item.isActive)?.capabilities?.tilt?.availableDirection
	);

	const filteredDevices = feed.mediaDevices
		? feed.mediaDevices.filter(device => [CameraTypes.HELLO, CameraTypes.HUDDLE].includes(device.type))
		: [];

	const selectedCamera = filteredDevices?.find(device => device.isActive);

	const bookmarkEvents = {
		REMOVE_BOOKMARK: 'removeBookmark',
		EDIT_BOOKMARK: 'editBookmark',
		MOVE_TO_BOOKMARK: 'moveToBookmark',
	};

	useEffect(() => {
		const onMediaControlsResponse = data => {
			if (data.participantId === feed.participantId) {
				if (data.command === enums.MediaControlsCommands.BOOKMARK_LIST) {
					if (activePreset) {
						const isActivePresetInList = data.data.some(item => item.id === activePreset?.id);
						if (!isActivePresetInList) {
							setActivePreset(null);
						}
					}
					setBookmarks(data.data);
				}

				if (data.command === enums.MediaControlsCommands.MOVE_TO_BOOKMARK) {
					const activeBookmark = bookmarks?.find(x => x.id === data.data);
					setActivePreset(activeBookmark);
				}

				if (data.command === enums.MediaControlsCommands.ACTIVE_DEVICE) {
					setActiveDevice(feed.deviceId, data.data?.name);
					setActivePreset(null);
				}

				if (data.command === enums.MediaControlsCommands.DEVICES_LIST) {
					const activeCamera = data?.data?.find(x => x.isActive);
					const activeBookmarks = activeCamera?.capabilities?.bookmarks?.isSupported
						? activeCamera?.capabilities?.bookmarks?.list
						: [];
					setAvailableDirections(activeCamera?.capabilities?.tilt?.availableDirection);
					setSliderRange(activeCamera?.capabilities?.zoom?.current);
					setBookmarks(activeBookmarks);
					deviceListChanged();
				}

				if (data.command === enums.MediaControlsCommands.ZOOM) {
					const activeCamera = data?.data?.find(x => x.isActive);
					setSliderRange(activeCamera?.capabilities?.zoom?.current);
				}
			}
		};

		socket.on(SocketEvents.Conference.ON_MEDIA_CONTROLS_RESPONSE, onMediaControlsResponse);
		socket.on(SocketEvents.HelloDevice.ON_INITIAL_STATE, onInitialState);

		return () => {
			socket.off(SocketEvents.Conference.ON_MEDIA_CONTROLS_RESPONSE, onMediaControlsResponse);
			socket.off(SocketEvents.HelloDevice.ON_INITIAL_STATE, onInitialState);
		};
	}, [socket, bookmarks, activePreset, feed.participantId, feed.deviceId]);

	const onInitialState = data => {
		if (+data.helloDeviceId === +feed.deviceId) {
			setBookmarks(data.bookmarkList);
		}
	};

	useEffect(() => {
		return () => {
			if (disabledSwitchTimeout.current) {
				clearTimeout(disabledSwitchTimeout.current);
			}
			if (moveCamInterval.current) {
				clearInterval(moveCamInterval.current);
			}
		};
	}, []);

	const sendDirection = async (direction, action) => {
		if (!selectedCamera?.capabilities?.tilt?.isSupported || !availableDirections[direction]) {
			return;
		}
		sendCameraEvent(
			SocketEvents.Conference.ON_MEDIA_CONTROLS,
			{
				command: 'move',
				type: 'camera',
				data: JSON.stringify({
					cameraId: selectedCamera?.id,
					direction,
					action,
				}),
				participantId: conferenceInfo.participantId,
				conferenceId: conferenceInfo.conferenceId,
				actioneeParticipantId: feed.participantId,
			},
			feed
		);
		enableOppositeDirection(direction);
	};

	const zoomCameraEvent = async level => {
		sendCameraEvent(
			SocketEvents.Conference.ON_MEDIA_CONTROLS,
			{
				command: 'zoom',
				type: 'camera',
				data: JSON.stringify({
					cameraId: selectedCamera?.id,
					level,
				}),
				participantId: conferenceInfo.participantId,
				conferenceId: conferenceInfo.conferenceId,
				actioneeParticipantId: feed.participantId,
			},
			feed
		);
	};

	const sendBookmarkEvent = async (command, id) => {
		sendCameraEvent(
			SocketEvents.Conference.ON_MEDIA_CONTROLS,
			{
				command,
				type: 'camera',
				data: JSON.stringify({
					bookmarkId: id,
					cameraId: selectedCamera?.id,
				}),
				participantId: conferenceInfo.participantId,
				conferenceId: conferenceInfo.conferenceId,
				actioneeParticipantId: feed.participantId,
			},
			feed
		);
	};

	const enableOppositeDirection = direction => {
		if (['right', 'left'].includes(direction)) {
			setAvailableDirections({
				...availableDirections,
				right: true,
				left: true,
			});
		} else {
			setAvailableDirections({
				...availableDirections,
				up: true,
				down: true,
			});
		}
	};

	const resetCamera = () => {
		if (!selectedCamera?.capabilities?.reboot?.isSupported) {
			return;
		}
		sendCameraEvent(
			SocketEvents.Conference.ON_MEDIA_CONTROLS,
			{
				command: enums.MediaControlsCommands.REBOOT,
				type: 'camera',
				data: JSON.stringify({
					cameraId: selectedCamera?.id,
				}),
				participantId: conferenceInfo.participantId,
				conferenceId: conferenceInfo.conferenceId,
				actioneeParticipantId: feed.participantId,
			},
			feed
		);
		setIsRebootHuddleCamModalOpen(false);
	};

	const addNewBookmark = text => {
		if (bookmarks?.length >= bookmarksLimit) {
			return;
		}
		sendCameraEvent(
			SocketEvents.Conference.ON_MEDIA_CONTROLS,
			{
				command: 'addBookmark',
				type: 'camera',
				data: text,
				participantId: conferenceInfo.participantId,
				conferenceId: conferenceInfo.conferenceId,
				actioneeParticipantId: feed.participantId,
			},
			feed
		);
	};

	const switchCamera = cameraId => {
		if (isSwitchDeviceDisabled) {
			return;
		}

		const helloCam = feed.mediaDevices?.find(device => device.type === CameraType.HELLO);
		if (
			feed.mediaDevices?.some(
				device =>
					[CameraType.HUDDLE].includes(device.type) && device.id === cameraId && !device.capabilities?.nightvision?.isSupported
			)
		) {
			sendCameraEvent(
				SocketEvents.Conference.ON_MEDIA_CONTROLS,
				{
					command: 'nightVision',
					type: 'camera',
					data: JSON.stringify({
						cameraId: helloCam?.id,
						enable: false,
					}),
					participantId: conferenceInfo.participantId,
					conferenceId: conferenceInfo.conferenceId,
					actioneeParticipantId: feed.participantId,
				},
				feed
			);
		}

		setIsSwitchDeviceDisabled(true);

		// If no response is received from Hello enable switch after 3 seconds
		if (disabledSwitchTimeout.current) {
			clearTimeout(disabledSwitchTimeout.current);
		}

		disabledSwitchTimeout.current = setTimeout(() => {
			setIsSwitchDeviceDisabled(false);
		}, 3000);

		sendCameraEvent(
			SocketEvents.Conference.ON_MEDIA_CONTROLS,
			{
				command: 'activeDevice',
				type: 'camera',
				data: cameraId,
				participantId: conferenceInfo.participantId,
				conferenceId: conferenceInfo.conferenceId,
				actioneeParticipantId: feed.participantId,
			},
			feed
		);
		setActivePreset(null);
	};

	const getCameraName = (name, type) => {
		if (CameraTypes.HUDDLE === type) {
			return name || '20x';
		}
		return name || type;
	};

	const saveHomePosition = () => {
		sendCameraEvent(
			SocketEvents.Conference.ON_MEDIA_CONTROLS,
			{
				command: enums.MediaControlsCommands.UPDATE_HOME_POSITION,
				type: 'camera',
				data: JSON.stringify({ cameraId: selectedCamera?.id }),
				participantId: conferenceInfo.participantId,
				conferenceId: conferenceInfo.conferenceId,
				actioneeParticipantId: feed.participantId,
			},
			feed
		);
	};

	const moveToHomePosition = async event => {
		event.stopPropagation();
		if (!selectedCamera?.capabilities?.home?.isSupported) {
			return;
		}
		const response = await sendCameraEvent(
			SocketEvents.Conference.ON_MEDIA_CONTROLS,
			{
				command: enums.MediaControlsCommands.HOME,
				type: 'camera',
				data: JSON.stringify({ cameraId: selectedCamera?.id }),
				participantId: conferenceInfo.participantId,
				conferenceId: conferenceInfo.conferenceId,
				actioneeParticipantId: feed.participantId,
			},
			feed
		);
		if (!response.ok) {
			// error
		}
	};

	if (!showPTZ) {
		return <></>;
	}

	const getTopMargin = () => {
		let topMargin = 50;

		if (hasIconsAboveCameraControls) {
			topMargin = 85;
		}

		return topMargin;
	};

	const handleDeleteBookmark = (itemId, itemName) => {
		setBookmarkToDelete({ itemId, itemName });
		setIsDeleteBookmarkModalOpen(true);
	};

	// send direction event every 500ms so camera won't stop moving
	const handleMoveTimeout = (direction, action) => {
		// clear interval on function call to prevent creating one on every call
		clearInterval(moveCamInterval.current);
		if (action === CameraEventActions.STOP) {
			sendDirection(direction, action);
		} else {
			sendDirection(direction, action);
			moveCamInterval.current = setInterval(() => {
				handleMoveTimeout(direction, action);
			}, 500);
		}
	};

	const directionIcon = {
		[CameraDirections.UP]: 'keyboard_arrow_up',
		[CameraDirections.RIGHT]: 'keyboard_arrow_right',
		[CameraDirections.LEFT]: 'keyboard_arrow_left',
		[CameraDirections.DOWN]: 'keyboard_arrow_down',
	};

	const isMouseDown = document.body.matches(':active');

	return (
		<StyledPTZ top={getTopMargin()} isDarkMode={isDarkMode} isRightToLeft={locale === LOCALES.ARABIC}>
			{filteredDevices.length > 1 && (
				<header>
					<ToggleGroup>
						{filteredDevices.map(({ id, name, capabilities, isActive }) => (
							<ToggleGroup.Item
								key={id}
								onChange={() => switchCamera(id)}
								disabled={isSwitchDeviceDisabled}
								name='camera'
								checked={isActive}>
								{getCameraName(capabilities.customName, name)}
							</ToggleGroup.Item>
						))}
					</ToggleGroup>
				</header>
			)}
			{filteredDevices?.find(item => item.isActive)?.capabilities?.tilt?.isSupported && (
				<StyledCameraControls isDarkMode={isDarkMode}>
					<div>
						{Object.values(CameraDirections).map(direction => (
							<button
								key={direction}
								type='button'
								onMouseDown={() => {
									handleMoveTimeout(direction, CameraEventActions.START);
								}}
								onMouseUp={() => {
									handleMoveTimeout(direction, CameraEventActions.STOP);
								}}
								onMouseOut={() => {
									if (!isMouseDown) {
										return;
									}
									handleMoveTimeout(direction, CameraEventActions.STOP);
								}}
								disabled={availableDirections && availableDirections[direction] === false}
								onBlur={() => {}}>
								<Icon name={directionIcon[direction]} />
							</button>
						))}
						<div>
							{filteredDevices?.find(item => item.isActive)?.capabilities?.home?.isSupported && (
								<button type='button' onClick={moveToHomePosition}>
									<Icon name='home' />
								</button>
							)}
						</div>
					</div>
					<div>
						<input
							type='range'
							min={0}
							max={filteredDevices?.find(item => item.isActive)?.capabilities?.zoom?.levels}
							step={1}
							value={sliderRange}
							onChange={evt => {
								evt.stopPropagation();
								if (showDeviceControlsLockedModal) {
									return;
								}
								setSliderRange(+evt.target.value);
							}}
							onMouseUp={evt => {
								if (showDeviceControlsLockedModal) {
									return;
								}
								zoomCameraEvent(+evt.target.value);
							}}
							onTouchEnd={evt => {
								if (showDeviceControlsLockedModal) {
									return;
								}
								zoomCameraEvent(+evt.target.value);
							}}
							disabled={filteredDevices?.find(item => item.isActive)?.capabilities?.zoom <= CameraZoomLevels.NO_ZOOM}
						/>
						{selectedCamera?.capabilities?.reboot?.isSupported && selectedCamera?.capabilities?.home?.isSupported && (
							<Dropdown position='right' icon='more_vert'>
								<div>
									<div>
										<button type='button' onClick={saveHomePosition} disabled={!selectedCamera?.capabilities?.home?.isSupported}>
											<Icon name='my_location' />
											{intl.formatMessage({ id: 'updateToCurrentPosition' })}
										</button>
									</div>
									<div>
										<button
											type='button'
											onClick={evt => {
												evt.stopPropagation();
												setIsRebootHuddleCamModalOpen(true);
											}}
											disabled={!selectedCamera?.capabilities?.reboot?.isSupported}>
											<Icon name='refresh' />
											{intl.formatMessage({ id: 'resetCamera' })}
										</button>
									</div>
								</div>
							</Dropdown>
						)}
					</div>
				</StyledCameraControls>
			)}
			{filteredDevices?.find(device => device?.isActive)?.capabilities?.bookmarks?.isSupported && (
				<StyledCameraBookmarks isDarkMode={isDarkMode} isRightToLeft={locale === LOCALES.ARABIC}>
					<Button onClick={() => setToggleBookmark(prevState => !prevState)}>
						<div>
							<div>
								<Icon name='bookmark' />
								<div>
									<p>{intl.formatMessage({ id: 'presets' })}</p>
									<span>
										{activePreset && (
											<>
												{intl.formatMessage({ id: 'activePreset' })} {activePreset?.name}
											</>
										)}
										{!activePreset && intl.formatMessage({ id: 'noActivePreset' })}
									</span>
								</div>
							</div>
							<Icon name={toggleBookmark ? 'expand_less' : 'expand_more'} />
						</div>
					</Button>
					{toggleBookmark && (
						<div>
							{bookmarks?.length === 0 && <p>{intl.formatMessage({ id: 'noPresets' })}</p>}
							{bookmarks?.map(item => (
								<div>
									<button type='button' onClick={() => sendBookmarkEvent(bookmarkEvents.MOVE_TO_BOOKMARK, item.id)}>
										{!item.isEdit && item.name}
									</button>
									<Dropdown position='right' icon='more_vert'>
										<div>
											<div>
												<button
													type='button'
													onClick={() => sendBookmarkEvent(bookmarkEvents.MOVE_TO_BOOKMARK, item.id)}
													disabled={
														!filteredDevices?.find(device => device.isActive && [CameraType.HUDDLE].includes(device.type))
															?.capabilites?.bookmarks?.isSupported
													}>
													<Icon name='my_location' />
													{intl.formatMessage({ id: 'goToPreset' })}
												</button>
											</div>
											<div>
												<button
													type='button'
													onClick={() =>
														sendCameraEvent(SocketEvents.Conference.ON_MEDIA_CONTROLS, {
															command: 'editBookmark',
															type: 'camera',
															data: item.id,
															participantId: conferenceInfo.participantId,
															conferenceId: conferenceInfo.conferenceId,
															actioneeParticipantId: feed.participantId,
														})
													}>
													<Icon name='edit' />
													{intl.formatMessage({ id: 'updateToCurrentPosition' })}
												</button>
											</div>
											<div>
												<button type='button' onClick={() => handleDeleteBookmark(item.id, item.name)}>
													<Icon name='delete' />
													{intl.formatMessage({ id: 'deletePreset' })}
												</button>
											</div>
										</div>
									</Dropdown>
								</div>
							))}
							{/* bookmarksLimit - 1 => (because 1 is for the home position) */}
							{bookmarks?.length < bookmarksLimit - 1 && (
								<StyledAddNewCameraBookmarksButton>
									<button
										type='button'
										onClick={() => setIsBookmarkModalOpen(true)}
										disabled={bookmarks.length >= bookmarksLimit}>
										<Icon name='add' />
										{intl.formatMessage({ id: 'addNewPreset' })}
									</button>
								</StyledAddNewCameraBookmarksButton>
							)}
						</div>
					)}
				</StyledCameraBookmarks>
			)}
			{isBookmarkModalOpen && (
				<Modal
					onDismiss={() => {
						setIsBookmarkModalOpen(null);
						setBookmark(null);
					}}
					title={intl.formatMessage({ id: 'createPreset' })}>
					<Modal.Content>
						<Input>
							<Input.Label>{translate('presetName')}</Input.Label>
							<Input.Field
								value={bookmark}
								placeholder={intl.formatMessage({ id: 'typePresetDescription' })}
								onChange={e => setBookmark(e.target.value)}
							/>
							<Input.Description>{translate('presetChangeDescription')}</Input.Description>
						</Input>
					</Modal.Content>
					<Modal.Actions>
						<Button
							type='submit'
							onClick={() => {
								addNewBookmark(bookmark);
								setIsBookmarkModalOpen(null);
								setBookmark(null);
							}}>
							{intl.formatMessage({ id: 'createNewPreset' })}
						</Button>
					</Modal.Actions>
				</Modal>
			)}
			{bookmarkToDelete && isDeleteBookmarkModalOpen && (
				<Modal onDismiss={() => setIsDeleteBookmarkModalOpen(false)} title={intl.formatMessage({ id: 'deleteConfirmation' })}>
					<Modal.Content>
						<p>
							{translate('areYouSureToDeletePreset', {
								value: bookmarkToDelete.itemName,
							})}
						</p>
					</Modal.Content>
					<Modal.Actions>
						<Button
							type='submit'
							onClick={() => {
								sendBookmarkEvent(bookmarkEvents.REMOVE_BOOKMARK, bookmarkToDelete.itemId);
								setIsDeleteBookmarkModalOpen(false);
							}}>
							{translate('delete')}
						</Button>
					</Modal.Actions>
				</Modal>
			)}
			{isRebootHuddleCamModalOpen && (
				<Modal onDismiss={() => setIsRebootHuddleCamModalOpen(false)} title={intl.formatMessage({ id: 'rebootCamera' })}>
					<Modal.Content>
						<p>
							{translate('areYouSureRebootHuddleCam', {
								value: filteredDevices.find(device => device?.name === CameraTypes.HUDDLE)?.capabilities?.customName || '20x',
							})}
						</p>
					</Modal.Content>
					<Modal.Actions>
						<Button
							type='submit'
							onClick={() => {
								resetCamera();
							}}>
							{translate('reboot')}
						</Button>
					</Modal.Actions>
				</Modal>
			)}
		</StyledPTZ>
	);
};

export default CameraControls;
