import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import Table from 'components/Table.jsx';
import Grid from 'components/Grid.jsx';
import Button from 'components/Button.jsx';
import Modal from 'components/Modal.jsx';
import Alert from 'components/Alert.jsx';
import Pagination from 'components/Common/Pagination.jsx';
import { getHealthSystemHospitals } from 'api/userIdleConfigurations.js';
import { getTeamConfigurations, deleteTVDeviceConfiguration } from 'api/deviceConfig.js';
import { UserRoles, TeamTypes, TreeHierarchyType } from 'constants/enums.js';
import {
	ConfigHistoryTypes,
	TeamConfigurationIntegrationTypes,
	IntegrationTypesSettings,
	EndCallSource,
} from 'constants/configurationEnums.js';
import { getUserRole } from 'infrastructure/auth.js';
import { deleteTeamConfigurationProfile, getTeamConfigurationProfiles } from 'api/teamConfigurationProfiles.js';
import { getHospitalDepartments } from 'api/healthSystems.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import HealthSystemConfigForm from 'containers/Configurations/HealthSystemConfigForm.jsx';
import TVConfig from 'containers/Configurations/TVConfig.jsx';
import { getFormattedDateWithHour } from 'infrastructure/helpers/dateHelper.js';

const DeviceConfig = props => {
	const intl = useIntl();

	const [isLoading, setIsLoading] = useState(true);
	const [hospitals, setHospitals] = useState([]);
	const [departments, setDepartments] = useState([]);
	const [teamProfiles, setTeamProfiles] = useState([]);
	const [isHospitalDropdownDisabled, setIsHospitalDropdownDisabled] = useState(true);
	const [isDepartmentDropdownDisabled, setIsDepartmentDropdownDisabled] = useState(true);
	const [configurations, setConfigurations] = useState([]);
	const [healthSystemConfigs, setHealthSystemConfigs] = useState([]);
	const [isFormLoading, setIsFormLoading] = useState(false);
	const [totalConfigs, setTotalConfigs] = useState(null);
	const [isDeleteConfigModalOpen, setIsDeleteConfigModalOpen] = useState(false);
	const [deleteConfig, setDeleteConfig] = useState(null);
	const [error, setError] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [editConfig, setEditConfig] = useState(null);
	const healthSystemsState = useSelector(state => state.healthSystems.allHealthSystems);

	const deviceConfigHeaders = [
		{ id: 1, title: intl.formatMessage({ id: 'healthSystem' }) },
		{ id: 2, title: intl.formatMessage({ id: 'hospital' }) },
		{ id: 3, title: intl.formatMessage({ id: 'sector' }) },
		{ id: 4, title: intl.formatMessage({ id: 'hdmiPort' }) },
		{ id: 5, title: intl.formatMessage({ id: 'defaultSource' }) },
		{ id: 6, title: intl.formatMessage({ id: 'ringtoneVolume' }) },
		{ id: 7, title: 'TV' },
		{ id: 8, title: intl.formatMessage({ id: 'profileName' }) },
		{ id: 9, title: intl.formatMessage({ id: 'createdBy' }) },
		{ id: 10, title: intl.formatMessage({ id: 'dateCreated' }) },
		{ id: 11, title: '' },
	];

	const healthSystemConfigHeaders = [
		{ id: 1, title: intl.formatMessage({ id: 'healthSystem' }) },
		{ id: 2, title: intl.formatMessage({ id: 'profileName' }) },
		{ id: 3, title: 'URL' },
		{ id: 4, title: intl.formatMessage({ id: 'port/siteId' }) },
		{ id: 5, title: intl.formatMessage({ id: 'dateCreated' }) },
		{ id: 6, title: '' },
	];

	const activeSubTab = {
		HealthSystem: 0,
		TV: 1,
	};

	const endCallSource = Object.values(EndCallSource(intl));

	useEffect(() => {
		if (
			!props.shouldShowConfigTab([
				IntegrationTypesSettings.TELEHEALTH,
				IntegrationTypesSettings.EVIDEON,
				IntegrationTypesSettings.PCARE,
				IntegrationTypesSettings.GET_WELL,
			])
		) {
			props.setCurrentSubTab(1);
		}
	}, [props]);

	useEffect(() => {
		const getTeamProfiles = async () => {
			const response = await getTeamConfigurationProfiles({
				pageSize: props.pageSize,
				pageIndex: props.pageIndex,
				teamTypeId: TeamTypes.HEALTH_SYSTEM,
			});
			if (response.error) {
				setError(response.error.message);
				return;
			}
			setTeamProfiles(response.teamConfigurationProfiles.map(config => ({ id: config.id, value: config.profileName })));
		};
		getTeamProfiles();
		getConfigurations();
	}, [props.selectedHealthSystem, props.selectedHospitalId, props.selectedDepartmentId, props.pageIndex, props.pageSize]);

	const getHospitals = async healthSystem => {
		let hospitalsList,
			transformedHospitals = [];
		if (healthSystem.value !== '0') {
			hospitalsList = await getHealthSystemHospitals(healthSystem.value);
			if (hospitalsList.error) {
				setError(hospitalsList.error.message);
			}
			transformedHospitals = transformHospitalsForSelect(hospitalsList);
		}
		setIsHospitalDropdownDisabled(healthSystem.value === '0');
		setIsDepartmentDropdownDisabled(healthSystem.value === '0' || isDepartmentDropdownDisabled);
		setHospitals(transformedHospitals);
	};

	const getDepartments = async (healthSystem, hospitalId) => {
		let departmentsList;
		if (healthSystem.value !== '0' && hospitalId !== 0) {
			const response = await getHospitalDepartments(healthSystem.value, hospitalId);
			if (response.error) {
				setError(response.error.message);
				return;
			}
			departmentsList = response.hospital.departments.map(department => ({ value: department.id, label: department.name }));
		}
		setIsDepartmentDropdownDisabled(healthSystem.value === '0' || hospitalId === 0);
		setDepartments(departmentsList);
	};

	const getConfigurations = async () => {
		const { selectedHealthSystem, selectedHospitalId, selectedDepartmentId } = props;
		let hsId = null;
		if (selectedHealthSystem && selectedHealthSystem.value !== '0') {
			hsId = selectedHealthSystem.value;
		}
		if (getUserRole() === UserRoles.SUPER_USER) {
			hsId = healthSystemsState[0].id;
			selectedHealthSystem.id = healthSystemsState[0].id;
			selectedHealthSystem.label = healthSystemsState[0].name;
		}
		if (props.activeSubTab === activeSubTab.TV) {
			const response = await getTeamConfigurations({
				healthSystemId: hsId,
				hospitalId: selectedHospitalId || null,
				departmentId: selectedDepartmentId || null,
				pageSize: props.pageSize,
				pageIndex: props.pageIndex,
				teamTypeId: TeamTypes.HOSPITAL,
			});
			if (response.error) {
				setError(response.error.message);
				return;
			}
			if (selectedHealthSystem) {
				await getHospitals(selectedHealthSystem);
			}
			if (selectedHealthSystem && selectedHospitalId) {
				await getDepartments(selectedHealthSystem, selectedHospitalId);
			}
			getDeviceConfigurations(response.deviceConfigurations);
			setTotalConfigs(response.total);
		} else {
			const response = await getTeamConfigurationProfiles({
				pageSize: props.pageSize,
				pageIndex: props.pageIndex,
				teamId: hsId,
				teamTypeId: TeamTypes.HEALTH_SYSTEM,
			});
			if (response.error) {
				setError(response.error.message);
				return;
			}
			getHealthSystemConfigs(response.teamConfigurationProfiles);
			setTotalConfigs(response.total);
		}
	};

	const transformHospitalsForSelect = healthSystems =>
		healthSystems.map(healthSystem => ({ label: healthSystem.name, value: healthSystem.id }));

	const getDeviceConfigurations = configurationsList => {
		if (!configurationsList?.length) {
			setConfigurations([]);
			setIsLoading(false);
			return;
		}
		const transformConfigs = configurationsList.map(config => ({
			healthSystem: config.team.healthSystemName,
			hospitalName: config.team.hospitalName,
			hospital: config.team.name,
			hdmiPort: config.hdmiPort,
			defaultSource: endCallSource.find(source => source.id === config.defaultSource)?.value,
			ringtoneVolume: config.ringtoneVolume,
			tv: config.tv,
			profileName: config.teamConfigurationProfile ? config.teamConfigurationProfile.profileName : '',
			createdBy: `${config.userCreated.firstName} ${config.userCreated.lastName}`,
			dateCreated: getFormattedDateWithHour(config.dateCreated),
			actions: (
				<div className='wrapped' key={config.id}>
					<Link
						to={`/configurations/${config.team.id}/type/${ConfigHistoryTypes.Devices}`}
						onClick={props.pushFiltersToQueryString}>
						<span
							className='material-icons-outlined cursor-pointer mr-20'
							data-tooltip={intl.formatMessage({ id: 'viewHistory' })}
							data-position='top'>
							list_alt
						</span>
					</Link>
					<span
						className='material-icons-outlined cursor-pointer mr-20'
						data-tooltip={intl.formatMessage({ id: 'edit' })}
						data-position='top'
						onClick={() => openEditModal(config)}>
						create
					</span>
					<i
						className='material-icons-outlined cursor-pointer'
						style={{ color: 'red', backgroundColor: 'white' }}
						data-tooltip={intl.formatMessage({ id: 'delete' })}
						data-position='top'
						onClick={() => openDeleteModal(config)}>
						delete
					</i>
				</div>
			),
		}));
		setConfigurations(transformConfigs);
		setIsLoading(false);
	};

	const transformArray = (array, allHealthSystems = false) => {
		if (!array) {
			return [];
		}
		const newArray = array.map(item => ({ value: item.id, label: item.name || item.value }));
		if (allHealthSystems && getUserRole() === UserRoles.ADMIN) {
			newArray.unshift({ value: '0', label: intl.formatMessage({ id: 'all' }) });
		}
		return newArray;
	};

	const onTabChange = async tabIndex => {
		await props.onSubTabChange(tabIndex);
	};

	const getTabsConfigurations = () => {
		if (props.activeSubTab === activeSubTab.HealthSystem) {
			return healthSystemConfigs;
		}
		return configurations;
	};

	const openDeleteModal = deleteConf => {
		setDeleteConfig(deleteConf);
		setIsDeleteConfigModalOpen(true);
	};

	const getIntegrationName = integrationTypeId => {
		let label = '';
		switch (integrationTypeId) {
			case TeamConfigurationIntegrationTypes.TELEHEALTH:
				label = 'TeleHealth';
				break;
			case TeamConfigurationIntegrationTypes.PCARE:
				label = 'pCare';
				break;
			case TeamConfigurationIntegrationTypes.EVIDEON:
				label = 'eVideon';
				break;
			case TeamConfigurationIntegrationTypes.GET_WELL:
				label = 'Get Well';
				break;
			case TeamConfigurationIntegrationTypes.SONIFI:
				label = 'Sonifi';
				break;
			default:
				break;
		}
		return label;
	};

	const getIntegrationType = integrationTypeId => ({
		label: getIntegrationName(integrationTypeId),
		value: integrationTypeId,
	});

	const getHealthSystemConfigs = configurationsList => {
		if (!configurationsList.length) {
			setHealthSystemConfigs([]);
			setIsLoading(false);
			return;
		}
		const transformConfigs = configurationsList.map(config => ({
			healthSystem: config.team.name,
			profileName: config.profileName,
			url: config.url,
			...(config.port && { port: config.port }),
			...(config.siteId && { siteId: config.siteId }),
			dateCreated: getFormattedDateWithHour(config.dateCreated),
			actions: (
				<div className='wrapped' key={config.id}>
					<Link
						to={`/configurations/${config.id}/type/${ConfigHistoryTypes.TeamConfigurationProfiles}`}
						onClick={props.pushFiltersToQueryString}>
						<span
							className='material-icons-outlined cursor-pointer mr-20'
							data-cy='viewHistory'
							data-tooltip={intl.formatMessage({ id: 'viewHistory' })}
							data-position='top'>
							list_alt
						</span>
					</Link>
					<span
						className='material-icons-outlined cursor-pointer mr-20'
						data-cy='edit'
						data-tooltip={intl.formatMessage({ id: 'edit' })}
						data-position='top'
						onClick={() =>
							openEditModal({
								...config,
								integrationType: getIntegrationType(config.integrationTypeId),
							})
						}>
						create
					</span>
					<i
						className='material-icons-outlined cursor-pointer'
						data-cy='deleteActiveDirectory'
						style={{ color: 'red', backgroundColor: 'white' }}
						data-tooltip={intl.formatMessage({ id: 'delete' })}
						data-position='top'
						onClick={() => openDeleteModal(config)}>
						delete
					</i>
				</div>
			),
		}));
		setHealthSystemConfigs(transformConfigs);
		setIsLoading(false);
	};

	const deleteTeamProfile = async () => {
		setIsLoading(true);
		setIsDeleteConfigModalOpen(false);
		if (props.activeSubTab === activeSubTab.HealthSystem) {
			await deleteTeamConfigurationProfile(deleteConfig.team.id, deleteConfig.team.typeId, deleteConfig.id);
		} else if (props.activeSubTab === activeSubTab.TV) {
			await deleteTVDeviceConfiguration(deleteConfig.team.id, deleteConfig.id);
		}
		getConfigurations();
	};

	const toggleConfigModal = () => {
		setIsModalOpen(prevState => !prevState);
	};

	const openEditModal = config => {
		setEditConfig(config);
		setIsModalOpen(true);
	};

	return (
		<div className='device-config'>
			<Table
				isLoading={isLoading}
				headers={props.activeSubTab === activeSubTab.HealthSystem ? healthSystemConfigHeaders : deviceConfigHeaders}
				rows={isLoading ? [] : getTabsConfigurations()}>
				<Grid
					columns={classNames(
						props.activeSubTab === activeSubTab.TV &&
							[TreeHierarchyType.HOSPITAL_FLOOR_ROOM, TreeHierarchyType.HOSPITAL_ROOM].includes(props.treeHierarchyTypeId)
							? '1fr 1fr 2fr'
							: '',
						props.activeSubTab === activeSubTab.TV &&
							![TreeHierarchyType.HOSPITAL_FLOOR_ROOM, TreeHierarchyType.HOSPITAL_ROOM].includes(props.treeHierarchyTypeId)
							? '1fr 1fr 1fr 2fr'
							: '',
						props.activeSubTab === activeSubTab.HealthSystem ? '1fr 2fr' : ''
					)}
					gridGap='10px'
					vertAlign='center'>
					<Select
						value={props.selectedHealthSystem}
						placeholder={intl.formatMessage({ id: 'all' })}
						classNamePrefix='react-select'
						options={transformArray(props.healthSystems, true)}
						onChange={props.setSelectedHealthSystem}
					/>
					{props.activeSubTab === activeSubTab.TV && (
						<>
							<Select
								value={props.selectedHospitalId ? hospitals.find(x => x.value === props.selectedHospitalId) : null}
								isDisabled={isHospitalDropdownDisabled}
								classNamePrefix='react-select'
								options={hospitals}
								onChange={props.setSelectedHospital}
							/>
							{([
								TreeHierarchyType.DEFAULT_TREE,
								TreeHierarchyType.HOSPITAL_DEPT_FLOOR_ROOM,
								TreeHierarchyType.HOSPITAL_DEPT_ROOM,
							].includes(props.treeHierarchyTypeId) ||
								!props.treeHierarchyTypeId) && (
								<Select
									value={props.selectedDepartmentId ? departments.find(x => x.value === props.selectedDepartmentId) : null}
									isDisabled={isDepartmentDropdownDisabled}
									classNamePrefix='react-select'
									options={departments}
									onChange={props.setSelectedDepartment}
								/>
							)}
						</>
					)}
					<Button
						text={
							props.activeSubTab === activeSubTab.HealthSystem ? translate('addHSConfiguration') : translate('addTVConfiguration')
						}
						horizAlign='end'
						className='text-transform-none border-radius-m'
						onClick={toggleConfigModal}
					/>
				</Grid>
				<div>
					<ul className='tabs active-directory-tabs' data-cy='activeDirectoryTabs'>
						{props.shouldShowConfigTab([
							IntegrationTypesSettings.TELEHEALTH,
							IntegrationTypesSettings.EVIDEON,
							IntegrationTypesSettings.PCARE,
							IntegrationTypesSettings.GET_WELL,
						]) && (
							<li className={props.activeSubTab === activeSubTab.HealthSystem ? 'active' : ''}>
								<span onClick={() => onTabChange(activeSubTab.HealthSystem)}>{translate('healthSystem')}</span>
							</li>
						)}
						<li className={props.activeSubTab === activeSubTab.TV ? 'active' : ''}>
							<span onClick={() => onTabChange(activeSubTab.TV)}>TV</span>
						</li>
					</ul>
				</div>
			</Table>
			<Pagination
				totalCount={totalConfigs}
				pageSize={props.pageSize}
				pageIndex={props.pageIndex}
				onChange={(pageSize, pageIndex) => props.onPaginationChange(pageSize, pageIndex)}
			/>
			{props.activeSubTab === activeSubTab.HealthSystem && (
				<HealthSystemConfigForm
					isModalOpen={isModalOpen}
					toggleModal={() => {
						setIsModalOpen(prevState => !prevState);
						setEditConfig(null);
					}}
					isFormLoading={isFormLoading}
					setIsFormLoading={setIsFormLoading}
					getConfigurations={getConfigurations}
					transformArray={transformArray}
					healthSystems={props.healthSystems}
					initialValues={editConfig}
				/>
			)}
			{props.activeSubTab === activeSubTab.TV && (
				<TVConfig
					isModalOpen={isModalOpen}
					toggleModal={() => {
						setIsModalOpen(prevState => !prevState);
						setEditConfig(null);
					}}
					isFormLoading={isFormLoading}
					setIsFormLoading={setIsFormLoading}
					getConfigurations={getConfigurations}
					transformArray={transformArray}
					healthSystems={props.healthSystems}
					teamProfiles={teamProfiles}
					initialValues={editConfig}
				/>
			)}
			<Modal
				modalSelector='deleteTeamProfileModal'
				display={isDeleteConfigModalOpen}
				position='center'
				className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal'
				onModalSubmit={deleteTeamProfile}
				onModalClose={() => {
					setIsDeleteConfigModalOpen(false);
					setDeleteConfig(null);
				}}
				submitImgIcon={`${healthCareCdnUrl}appointments/save-icon.svg`}
				closeImgIcon={`${healthCareCdnUrl}appointments/cancel-appointment.svg`}
				shouldSubmitOnEnter={false}
				primaryButtonLabel={translate('yes')}>
				<form>
					<h3>{translate('deleteConfiguration')}</h3>
					<p>{translate('areYouSureToDeleteConfig')}</p>
				</form>
			</Modal>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</div>
	);
};

export default DeviceConfig;
