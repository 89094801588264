import React from 'react';
import { Link } from 'react-router-dom';
import translate from 'i18n-translations/translate.jsx';

const Help = () => (
	<div className='account-settings-inner-wrapper'>
		<div className='measurement-units-table about-settings-table'>
			<div className='flex'>
				<div className='flex column-direction'>
					<p>{translate('termsAndConditions')}</p>
					<span>{translate('readTermsAndConditions')}</span>
				</div>
				<Link to='/terms-of-use' target='_blank'>
					{translate('goTo')} {translate('termsAndConditions')}
				</Link>
			</div>
			<div className='flex'>
				<div className='flex column-direction'>
					<p>{translate('privacyPolicy')}</p>
					<span>{translate('readPrivacyPolicy')}</span>
				</div>
				<Link to='/privacy-policy' target='_blank'>
					{translate('goTo')} {translate('privacyPolicy')}
				</Link>
			</div>
		</div>
	</div>
);

export default Help;
