import React from 'react';

const InputFeedback = ({ children }) => <small>{children}</small>;

const Select = ({ field: { name, ...field }, form: { touched, errors, setFieldValue }, ...props }) => {
	const error = errors ? errors[name] : '';
	const touch = touched ? touched[name] : '';
	const _handleChange = (e) => {
		setFieldValue(name, e.target.value);
		if (props.onChange) {
			props.onChange(e);
		}
	}
	return (
		<div className={`input ${touch && error && 'error'}`} style={{ marginBottom: props.bottomSpace }}>
			<p className='label'>{props.label}</p>
			<p>{props.description}</p>
			<select required name={name} {...field} onChange={_handleChange} value={field.value}>
				<option className='select-placeholder' value='' disabled hidden selected>
					{props.placeholder}
				</option>
				{props.items.map(item => (
					<option key={item.id} value={item.id}>
						{item.value}
					</option>
				))}
			</select>
			{touch && error && <InputFeedback>{error}</InputFeedback>}
		</div>
	);
};

export default Select;
