import React from 'react';
import classNames from 'classnames';
import { buildProfilePicUrl } from 'infrastructure/helpers/thumbnailHelper.js';

const getInitialsFromFullName = fullName => {
	if (fullName && fullName.length) {
		const fullNameSplit = fullName.split(/\b\s+/);
		const fullNameInitials = `${fullNameSplit[0][0]}${fullNameSplit[1][0]}`;
		return fullNameInitials;
	}
	return '';
};

const Avatar = props => (
	<>
		{!!props.src && (
			<img
				data-test-id='avatar-img'
				src={buildProfilePicUrl(props.src, 150)}
				className={classNames('avatar', props.size, 'border-radius-50')}
				alt='avatar-ing'
			/>
		)}
		{!props.src && <span data-test-id='avatar-name'>{getInitialsFromFullName(props.fullName)}</span>}
	</>
);
export default Avatar;
