import { APP_CONFIG, gatewayApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';

const companyId = getCompanyId();
const useOlderAPIVersion = [APP_CONFIG.amwellTenantId, APP_CONFIG.banyanTenantId].some(tenantId => companyId === tenantId);

export const getDevicePastConferences = async params => {
	try {
		const { data } = await gatewayApi.get(`/v1/conferences/past/organization/${companyId}`, {
			params,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const getDeviceOngoingConferencesByLevel = async ({ level = 0, id = '', pageSize = 10, pageIndex = 0 } = {}) => {
	try {
		const params = {
			level,
			pageSize,
			pageIndex,
			id: id || companyId,
		};
		const { data } = await gatewayApi.get(
			`/${useOlderAPIVersion ? 'v1.1' : 'v1.2'}/conferences/ongoing/organization-devices/${companyId}`,
			{
				params,
			}
		);
		return useOlderAPIVersion ? data.result : data;
	} catch (error) {
		return { error };
	}
};
