import React, { useEffect, useState } from 'react';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import Select from 'components/Common/FormElements/Select.jsx';
import Alert from 'components/Alert.jsx';
import Form from 'components/Form.jsx';
import Modal from 'components/Modal.jsx';
import { createTranslationProvider, getTranslationProviders } from 'api/translationServices.js';
import { default as FormElementInput } from 'components/Common/FormElements/Input.jsx';
import { useSelector } from 'react-redux';
import { IntegrationTypesSettings } from 'constants/configurationEnums.js';
import { getConfigurationValue } from 'infrastructure/helpers/commonHelpers.js';

const TranslationServicesForm = props => {
	const intl = useIntl();
	const translator = id => intl.formatMessage({ id });
	const [isErrorsAlertOn, setErrorAlertOn] = useState(false);
	const [errorText, setErrorText] = useState('');
	const [isLoading, setisLoading] = useState(true);
	const [translationProviders, setTranslationProviders] = useState([]);
	const [selectedTranslator, setSelectedTranslator] = useState({ id: null, value: '', fields: [] });
	const [hasPhoneNumber, setHasPhoneNumber] = useState(false);

	const amnNumberExtension = '@csip.stratusvideo.com';
	const companyConfigurations = useSelector(state => state.company.companySettings?.companyConfigurations);

	const translationServices = [
		getConfigurationValue(companyConfigurations[IntegrationTypesSettings.GLOBO]) && { value: 'Globo' },
		getConfigurationValue(companyConfigurations[IntegrationTypesSettings.AMN]) && { value: 'AMN' },
		{ value: 'Language Line' },
	].filter(Boolean);

	useEffect(() => {
		const fetchTranslationServices = async () => {
			const response = await getTranslationProviders();
			if (response.error) {
				setErrorAlertOn(response.error.message);
			} else {
				const filteredResponse = response.translationProviders.reduce((acc, item) => {
					if (translationServices.some(el => el.value === item.name)) {
						acc.push({
							id: item.id,
							value: item.name,
							fields: item.fields,
						});
					}
					return acc;
				}, []);
				setTranslationProviders(filteredResponse);
			}
			setisLoading(false);
		};
		fetchTranslationServices();
	}, []);

	const getValidationSchema = () => {
		const validation = { healthSystem: Yup.string().required(intl.formatMessage({ id: 'healthSystemRequired' })) };
		selectedTranslator.fields.forEach(item => {
			if (!item.value) {
				validation[item.name] = Yup.string().required(translator('requiredField'));
			}
		});
		return validation;
	};

	const getInitialValues = () => {
		const initial = { healthSystem: null };
		selectedTranslator.fields.forEach(item => {
			initial[item.name] = '';
		});
		return initial;
	};

	const handleSubmitMyForm = async (values, { resetForm }) => {
		const params = {
			translationProviderId: selectedTranslator.id,
			values: selectedTranslator.fields.map(field => ({
				translationProviderFieldId: field.id,
				value: field.name === 'PHONE_NUMBER' ? `${field.value}${amnNumberExtension}` : field.value,
			})),
		};
		const response = await createTranslationProvider(values.healthSystem, params);
		if (response.error) {
			setErrorAlertOn(response.error.message);
		} else {
			resetForm();
		}
		props.onSucceeded();
		props.toggleModal();
	};

	const onCloseModal = resetForm => {
		setErrorText('');
		setErrorAlertOn(false);
		resetForm();
		props.toggleModal();
	};

	const handlePreventDefaultOnEnter = event => {
		if (event.key === 'Enter') event.preventDefault();
	};

	const onTranslatorChange = (event, setFieldValue) => {
		const { value } = event.target;
		setFieldValue('selectedTranslator', value);
		const foundTranslator = _.cloneDeep(translationProviders.find(item => item.id === value));
		setSelectedTranslator(foundTranslator);
		setHasPhoneNumber(foundTranslator?.fields.find(item => item.name === 'PHONE_NUMBER'));
	};

	const handleFieldChange = (value, fieldId) => {
		const newTranslator = _.cloneDeep(selectedTranslator);
		const foundField = newTranslator.fields.find(item => item.id === fieldId);
		foundField.value = value;
		setSelectedTranslator(newTranslator);
	};

	const addPhoneNumber = (event, fieldId) => {
		handleFieldChange(event.target.value, fieldId);
	};

	return (
		<>
			{!isLoading && (
				<Formik
					enableReinitialize={true}
					initialValues={getInitialValues()}
					validationSchema={Yup.object().shape(getValidationSchema())}
					onSubmit={handleSubmitMyForm}>
					{({ values, handleSubmit, isSubmitting, resetForm, setFieldValue }) => (
						<Modal
							display={props.isModalOpen}
							position='right'
							onModalSubmit={() => {
								if (!isSubmitting) {
									handleSubmit();
								}
							}}
							onModalClose={() => onCloseModal(resetForm)}
							onKeyDown={handlePreventDefaultOnEnter}
							isLoading={isSubmitting}>
							<Form>
								<>
									<Field
										name='selectedTranslator'
										type='select'
										label={translator('selectedTranslator')}
										placeholder={intl.formatMessage({ id: 'selectedTranslator' })}
										items={translationProviders}
										onChange={event => onTranslatorChange(event, setFieldValue)}
										component={Select}
									/>
									<Field
										name='healthSystem'
										type='select'
										label={intl.formatMessage({ id: 'selectHealthSystem' })}
										placeholder={intl.formatMessage({ id: 'selectHealthSystem' })}
										description={intl.formatMessage({ id: 'selectHealthSystemOrAll' })}
										items={props.healthSystems}
										component={Select}
									/>
									{selectedTranslator?.fields.map(field => {
										return (
											<>
												{!hasPhoneNumber && (
													<Field
														name={field.name}
														type='select'
														label={field.name}
														placeholder={field.name}
														value={field.value}
														items={props.healthSystems}
														component={FormElementInput}
														onChange={event => {
															handleFieldChange(event.target.value, field.id);
														}}
													/>
												)}
												{hasPhoneNumber && (
													<div className='flex right-align-items sip-default'>
														<Field
															name={field.name}
															type='text'
															label='SIP Endpoint'
															placeholder='E.g. 123456'
															value={field.value}
															component={FormElementInput}
															onChange={event => addPhoneNumber(event, field.id)}
														/>
														<span>{amnNumberExtension}</span>
													</div>
												)}
											</>
										);
									})}
								</>
								<Alert
									alertSelector='networkAccessConfigsMessage'
									display={isErrorsAlertOn}
									message={translator(errorText || 'somethingWentWrong')}
									variant='error'
									onClose={() => {
										setErrorAlertOn(false);
										setErrorText('');
									}}
								/>
							</Form>
						</Modal>
					)}
				</Formik>
			)}
		</>
	);
};

export default TranslationServicesForm;
