import classNames from 'classnames';
import React from 'react';

const baseImgUrl = 'https://static.solaborate.com/healthcare-system/empty-state/';

const EmptyState = props => (
	<div className={classNames('empty-state', props.className)}>
		<img src={`${baseImgUrl}${props.image}`} alt='empty-state-img' />
		<h3>{props.title}</h3>
		{props.paragraph && <p>{props.paragraph}</p>}
		{props.children}
	</div>
);

export default EmptyState;
