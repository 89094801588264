import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getNursePatients } from 'api/patients.js';
import { getUserId } from 'infrastructure/auth.js';
import { useIntl } from 'react-intl';
import translate from 'i18n-translations/translate.jsx';
import { getConversationByType } from 'api/messenger.js';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from 'components/Tabs.jsx';
import Grid from 'components/Grid.jsx';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import Conversation from 'views/Chat/Conversation.jsx';
import Alert from 'components/Alert.jsx';
import Input from 'components/Input.jsx';
import Files from 'views/Partials/Files.jsx';
import ProfilePicture from 'components/ProfilePicture.jsx';
import classNames from 'classnames';
import { ConversationType } from 'constants/enums.js';
import _ from 'lodash';
import { getConfigurationValue } from 'infrastructure/helpers/commonHelpers.js';
import { GeneralSettings, PatientTabs } from 'constants/configurationEnums.js';
import { getAdminGeneralSettingConfigValue } from 'infrastructure/helpers/configurationsHelpers.js';

const NursePatients = () => {
	const history = useHistory();
	const userSession = useSelector(state => state.user.userSession);
	const [pageIndex, setPageIndex] = useState(0);
	const [totalCount, setTotalCount] = useState(0);
	const [isLoading, setIsLoading] = useState(true);
	const [selectedPatient, setSelectedPatient] = useState(null);
	const [conversationId, setConversationId] = useState(null);
	const [patients, setPatients] = useState([]);
	const [error, setError] = useState('');
	const [searchPatient, setSearchPatient] = useState('');
	const [currentTab, setCurrentTab] = useState(0);
	const bottomOfListRef = useRef(null);
	const intl = useIntl();
	const healthSystemConfigurations = useSelector(state => state.configurations.healthSystemConfigurations);
	const adminConfigurableMenu = useSelector(state => state.configurations.adminConfigurableMenu);

	useEffect(() => {
		const fetchPatients = async () => {
			const response = await getNursePatients({
				healthSystemId: userSession.healthSystem?.id,
				userId: getUserId(),
				pageIndex,
				search: searchPatient,
			});
			if (response.error) {
				setError(response.error.message);
				setIsLoading(false);
				return;
			}
			setTotalCount(response.totalCount);
			if (response.patients.length === 0) {
				setIsLoading(false);
				return;
			}
			const clonedPatients = _.cloneDeep(patients);
			const filteredPatients = clonedPatients.concat(
				response.patients.filter(member => clonedPatients.every(patient => patient.id !== member.id))
			);
			setPatients(filteredPatients);
			if (pageIndex === 0 && !searchPatient) {
				setSelectedPatient(filteredPatients[0]);
			}
			setIsLoading(false);
		};
		fetchPatients();
	}, [pageIndex, searchPatient, userSession.healthSystem?.id]);

	useEffect(() => {
		const getConversationId = async () => {
			const response = await getConversationByType(ConversationType.INDIVIDUAL, selectedPatient.userId, 0);
			if (response.error) {
				setError(response.error.message);
			} else {
				setConversationId(response.conversation.id);
			}
		};
		if (selectedPatient) {
			getConversationId();
		}
	}, [selectedPatient]);

	const handleScroll = e => {
		const isBottom = e.target.scrollHeight - Math.ceil(e.target.scrollTop) === e.target.clientHeight;
		const hasReachedEnd = totalCount - patients.length <= 0;
		if (isBottom && !hasReachedEnd) {
			setPageIndex(prevState => prevState + 1);
		}
	};

	const setPatient = patient => {
		setCurrentTab(0);
		setSelectedPatient(patient);
		history.push(`/nurse-patients/${patient.userId}`);
	};
	const openTab = tabIndex => {
		setCurrentTab(tabIndex);
	};
	const setSearchValue = event => {
		setPageIndex(0);
		setPatients([]);
		setSearchPatient(event.target.value);
	};

	const allPatientTabsDisabled = () =>
		!getConfigurationValue(healthSystemConfigurations[PatientTabs.FILES]) &&
		!getAdminGeneralSettingConfigValue(adminConfigurableMenu, GeneralSettings.ConversationHistory);

	return (
		<MainLayout>
			<Grid columns='1fr 3fr' stretch='100%'>
				<aside className='patients-list nurses-list'>
					<div>
						<div>
							<div className='patients-list-header sub-page-header'>
								<h2>{translate('myPatients')}</h2>
							</div>
							<Input
								type='text'
								name='sectorSearchValue'
								placeholder={intl.formatMessage({ id: 'search' })}
								value={searchPatient}
								onChange={setSearchValue}
								validationOptions={{}}
								bottomSpace='0px'
								autoComplete='off'
							/>
						</div>
						<div className='chat-items-area scroll-hover nurse-patient-list' ref={bottomOfListRef} onScroll={handleScroll}>
							{!isLoading &&
								patients.map(patient => (
									<div
										key={patient.id}
										className={classNames(
											'chat-list-item position-relative flex ',
											selectedPatient && selectedPatient.id === patient.id ? ' active' : ''
										)}
										onClick={() => setPatient(patient)}>
										<div className='align-self-start position-relative'>
											<div className='flex position-relative chat-list-img-status-wrapper'>
												<ProfilePicture
													className='chat-list-img'
													firstName={patient.firstName}
													lastName={patient.lastName}
													profilePicture={patient.profilePicture}
												/>
											</div>
										</div>
										<div className='chat-list-name header-search-name flex flex-1'>
											<p>
												{patient.firstName} {patient.lastName}
											</p>
										</div>
									</div>
								))}
							{patients.length === 0 && !isLoading && (
								<div className='chat-item-title'>
									<span className='semi-bold'>{translate('noResultsFound')}</span>
								</div>
							)}
						</div>
					</div>
				</aside>
				{selectedPatient && (
					<>
						{!allPatientTabsDisabled() && (
							<Tabs activeIndex={currentTab} onChange={index => openTab(index)}>
								<TabList>
									{getAdminGeneralSettingConfigValue(adminConfigurableMenu, GeneralSettings.ConversationHistory) && (
										<Tab>{translate('conversation')}</Tab>
									)}
									{getConfigurationValue(healthSystemConfigurations[PatientTabs.FILES]) && <Tab>{translate('files')}</Tab>}
								</TabList>
								<TabPanels>
									{getAdminGeneralSettingConfigValue(adminConfigurableMenu, GeneralSettings.ConversationHistory) && (
										<TabPanel>
											<div className='full-height'>
												<Conversation
													selectedUser={{
														id: selectedPatient?.userId,
														name: `${selectedPatient?.firstName} ${selectedPatient?.lastName}`,
														profilePicture: selectedPatient?.profilePicture,
													}}
													conversationId={conversationId}
													hideRightSide={true}
												/>
											</div>
										</TabPanel>
									)}
									{getConfigurationValue(healthSystemConfigurations[PatientTabs.FILES]) && (
										<TabPanel>
											<Files
												selectedUser={{
													owner: {
														id: selectedPatient?.userId,
														fullName: `${selectedPatient?.firstName} ${selectedPatient?.lastName}`,
														profilePicture: selectedPatient?.profilePicture,
													},
												}}
											/>
										</TabPanel>
									)}
								</TabPanels>
							</Tabs>
						)}
						{allPatientTabsDisabled() && (
							<div className='flex flex-justify-center flex-align-center'>
								<p>{translate('checkFeatureAvailability')}</p>
							</div>
						)}
					</>
				)}
			</Grid>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</MainLayout>
	);
};

export default NursePatients;
