import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import Modal from 'components/Modal.jsx';
import translate from 'i18n-translations/translate.jsx';
import Form from 'components/Form.jsx';
import Textarea from 'components/Textarea.jsx';
import { ManualAlertTypes } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import SpeechToText from 'SpeechToText.jsx';

const ManualReportModal = props => {
	const intl = useIntl();

	const messageTypes = [
		{
			id: ManualAlertTypes.FALL_DETECTED,
			title: intl.formatMessage({ id: 'fallDetected' }),
		},
		{
			id: ManualAlertTypes.RISK_OF_FALL,
			title: intl.formatMessage({ id: 'riskOfFall' }),
		},
		{
			id: ManualAlertTypes.PATIENT_NEEDS_HELP,
			title: intl.formatMessage({ id: 'patientNeedsHelp' }),
		},
		{
			id: ManualAlertTypes.PATIENT_REQUESTED_HELP,
			title: intl.formatMessage({ id: 'patientRequiresHelp' }),
		},
		{
			id: ManualAlertTypes.CUSTOM,
			title: intl.formatMessage({ id: 'otherActivity' }),
		},
	];

	const [selectedAlertType, setSelectedAlertType] = useState(null);
	const [recognizedTranscription, setRecognizedTranscription] = useState('');
	const [liveTranscription, setLiveTranscription] = useState('');
	const [isSpeechToText, setIsSpeechToText] = useState(false);

	return (
		<Modal
			display={true}
			onModalSubmit={() => {
				props.onForwardToNurses(selectedAlertType, recognizedTranscription);
			}}
			primaryButtonLabel={translate('forwardToNurses')}
			primaryButtonVariant='white'
			isLoading={props.isManualReportLoading || props.isForwardMSTeamsLoading}
			position='center'
			onModalClose={() => props.onReportClose()}
			shouldSubmitOnEnter={false}
			className='border-radius-modal-wrapper appoinment-next-arrow-modal'
			isSubmitDisabled={!selectedAlertType}
			submitImgIcon={`${healthCareCdnUrl}forward-arrow-blue.svg`}
			primaryButtonLoading={props.isManualReportLoading}>
			<Form
				height={450}
				className='modal-form-wrapper send-group-message add-care-events'
				onSubmit={event => event.preventDefault()}>
				<h3>{translate('raiseAlert')}</h3>
				<p>{translate('selectCareEvent')}:</p>
				<div className='flex flex-wrap' style={{ marginTop: '20px' }}>
					{messageTypes.map(message => (
						<div
							key={message.id}
							className={selectedAlertType === message.id ? `pill-button selected-pill` : `pill-button`}
							onClick={() => setSelectedAlertType(message.id)}>
							<input type='radio' name='choice' checked={selectedAlertType === message.id} />
							<h5 className={selectedAlertType === message.id ? 'selected-pill' : ''}>{message.title}</h5>
						</div>
					))}
				</div>
				<p>{translate('message')}</p>
				<Textarea
					disabled={isSpeechToText || !selectedAlertType}
					maxLength={400}
					onChange={event => setRecognizedTranscription(event.target.value)}
					value={isSpeechToText ? `${recognizedTranscription} ${liveTranscription}`.trim() : recognizedTranscription}
					maxNumberLimit={40}
					rows={10}
					name='text'
					placeholder={intl.formatMessage({ id: 'message' })}
					icon={
						<SpeechToText
							setRecognizedTranscription={setRecognizedTranscription}
							setLiveTranscription={setLiveTranscription}
							setIsSpeechToText={setIsSpeechToText}
							isSpeechToText={isSpeechToText}
							isDisabled={!selectedAlertType}
						/>
					}
				/>
			</Form>
		</Modal>
	);
};

export default ManualReportModal;
