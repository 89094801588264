import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useSelector } from 'react-redux';
import MaskedTextInput from 'react-text-mask';
import { useIntl } from 'react-intl';
import sq from 'date-fns/locale/sq/index.js';
import en from 'date-fns/locale/en-US/index.js';
import { Formik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import useOutsideClick from 'infrastructure/helpers/useOutsideClick.js';
import { getPatientByIdCard, registerPatient, updatePatient } from 'api/patients.js';
import {
	AddEditPatientRadioTypes,
	MeasurementUnitOptions,
	PatientAgeLimit,
	PatientHeightLimit,
	PatientWeightLimit,
	TaskStatus,
	UnitCategoryTypes,
	UnitSystemTypes,
} from 'constants/enums.js';
import { Allergies, MaritalStatusList } from 'constants/visitEnums.js';
import { capitalizeFirstLetter, getStorage, isDecimal, validateMaxLength } from 'infrastructure/helpers/commonHelpers.js';
import translate from 'i18n-translations/translate.jsx';
import Form from 'components/Form.jsx';
import { getCompanyId } from 'infrastructure/auth.js';
import Loader from 'components/Loader.jsx';
import { primaryCareImageUrl } from 'constants/global-variables.js';
import { Alert, Button } from 'components';
import {
	convertFeetToCm,
	convertHeight,
	convertToKg,
	convertWeight,
	getMeasurementValueBasedOnUnit,
} from 'infrastructure/helpers/measurementsHelper.js';
import { profileCreationMeasurementElements } from 'constants/measurements.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';

const PatientRegistration = props => {
	const userSession = useSelector(state => state.user.userSession);
	const [isGenderOpen, setIsGenderOpen] = useState(false);
	const [isMaritalStatusOpen, setIsMaritalStatusOpen] = useState(false);
	const intl = useIntl();
	registerLocale('en-US', en);
	registerLocale('sq', sq);
	const locale = useSelector(state => state.language.locale);
	const genderWrapperRef = useRef(null);
	const maritalWrapperRef = useRef(null);
	const [birthDateError, setBirthDateError] = useState(null);
	const [error, setError] = useState(null);
	const [foodAllergies, setFoodAllergies] = useState([]);
	const [medicationAllergies, setMedicationAllergies] = useState([]);
	const [environmentalAllergies, setEnvironmentalAllergies] = useState([]);
	const [biologicalAllergies, setBiologicalAllergies] = useState([]);
	const foodInput = useRef(null);
	const medicationInput = useRef(null);
	const environmentalInput = useRef(null);
	const biologicalInput = useRef(null);
	const [isTaskLoading, setIsTaskLoading] = useState(false);
	const [, setIsLoading] = useState(false);
	const [isNextBtnLoading, setIsNextBtnLoading] = useState(false);
	const [newPatient, setNewPatient] = useState(null);
	const [errorOnAllergyEnter, setErrorOnAllergyEnter] = useState({});
	const socket = useContext(SocketContext);

	const genders = [
		{ id: 1, name: intl.formatMessage({ id: 'male' }) },
		{ id: 2, name: intl.formatMessage({ id: 'female' }) },
	];

	const maritalStatusList = [
		{ id: MaritalStatusList.UNMARRIED.id, name: intl.formatMessage({ id: 'single' }) },
		{ id: MaritalStatusList.MARRIED.id, name: intl.formatMessage({ id: 'married' }) },
		{ id: MaritalStatusList.DIVORCED.id, name: intl.formatMessage({ id: 'divorced' }) },
		{ id: MaritalStatusList.WIDOWED.id, name: intl.formatMessage({ id: 'widowWidower' }) },
	];

	const toggleGenderDropDown = (item, formikProps) => {
		formikProps.setFieldValue('genderId', item.id);
		setIsGenderOpen(false);
	};

	const toggleMaritalDropdown = (item, formikProps) => {
		formikProps.setFieldValue('maritalStatusId', item.id);
		setIsMaritalStatusOpen(false);
	};

	useOutsideClick(genderWrapperRef, () => {
		if (isGenderOpen) {
			setIsGenderOpen(false);
		}
	});

	useOutsideClick(maritalWrapperRef, () => {
		if (isMaritalStatusOpen) {
			setIsMaritalStatusOpen(false);
		}
	});

	const allergiesStatusList = [
		{
			code: 0,
			status: null,
		},
		{
			code: 1,
			status: true,
		},
		{
			code: 2,
			status: false,
		},
	];

	const height = props.selectedPatient?.height
		? getMeasurementValueBasedOnUnit(
				parseFloat(props.selectedPatient?.height),
				convertHeight(parseFloat(props.selectedPatient?.height), UnitSystemTypes.IMPERIAL).toFixed(2).toString(),
				profileCreationMeasurementElements.height,
				props.unitPreferences
		  )
		: '';

	const weight = props.selectedPatient?.weight
		? getMeasurementValueBasedOnUnit(
				parseFloat(props.selectedPatient?.weight),
				convertWeight(parseFloat(props.selectedPatient?.weight), UnitSystemTypes.IMPERIAL).toFixed(2).toString(),
				profileCreationMeasurementElements.weight,
				props.unitPreferences
		  )
		: '';

	const getInitialValues = () => {
		return {
			firstName: props.selectedPatient?.firstName ?? '',
			lastName: props.selectedPatient?.lastName ?? '',
			dateOfBirth: props.selectedPatient?.birthDate ?? '',
			birthDay: props.selectedPatient?.birthDate ? new Date(props.selectedPatient?.birthDate).getDate() : '',
			birthMonth: props.selectedPatient?.birthDate ? new Date(props.selectedPatient?.birthDate) : '',
			birthYear: props.selectedPatient?.birthDate ? new Date(props.selectedPatient?.birthDate) : '',
			idNumber: props.selectedPatient?.idCard ?? '',
			parentName: props.selectedPatient?.parentName ?? '',
			patientHealthCardNumber: props.selectedPatient?.patientHealthCardNumber ?? '',
			emailAddress: props.selectedPatient?.email ?? '',
			phoneNumber: props.selectedPatient?.phoneNumber ?? '',
			genderId: props.selectedPatient?.genderId ?? '',
			address: props.selectedPatient?.address ?? '',
			city: props.selectedPatient?.city ?? '',
			profession: props.selectedPatient?.profession ?? '',
			maritalStatusId: props.selectedPatient?.maritalStatusId ?? '',
			allergies:
				props.selectedPatient?.allergies?.length > 0
					? props.selectedPatient.allergies.map(allergy => allergy.categoryId.toString())
					: [],
			hasAllergy:
				props.selectedPatient?.hasAllergy !== undefined
					? allergiesStatusList.find(item => item.status === props.selectedPatient?.hasAllergy).code.toString()
					: AddEditPatientRadioTypes.NO_ANSWER.toString(),
			foodAllergy: '',
			medicationAllergy: '',
			environmentalAllergy: '',
			biologicalAllergy: '',
			height,
			heightUnit:
				MeasurementUnitOptions.HEIGHT.value[
					props.unitPreferences.find(item => item.unitCategoryId === UnitCategoryTypes.HEIGHT)?.unitSystemId
				]?.text || '',
			weight,
			weightUnit:
				MeasurementUnitOptions.WEIGHT.value[
					props.unitPreferences.find(item => item.unitCategoryId === UnitCategoryTypes.WEIGHT)?.unitSystemId
				]?.text || '',
		};
	};

	const getPatientByCard = useCallback(async () => {
		if (!newPatient) {
			return;
		}
		const addedPatientRes = await getPatientByIdCard(newPatient.idCard);
		if (addedPatientRes.error) {
			setIsLoading(false);
			setError(addedPatientRes.error.message);
			return;
		}
		setIsLoading(false);
		setIsTaskLoading(false);

		props.setSelectedPatient({
			...newPatient,
			userId: addedPatientRes.userId,
			healthcareUserId: addedPatientRes.healthcareUserId,
			id: addedPatientRes.id,
		});
	}, [newPatient]);

	useEffect(() => {
		const handleTaskStatusUpdated = async data => {
			if (data.taskStatusId === TaskStatus.COMPLETED) {
				await getPatientByCard();
			}
			if (data.taskStatusId === TaskStatus.FAULTED) {
				setError(translate('somethingWentWrong'));
				setIsTaskLoading(false);
			}
		};
		socket.on(SocketEvents.BACKGROUND.TASK_UPDATED, handleTaskStatusUpdated);
		return () => {
			socket.off(SocketEvents.BACKGROUND.TASK_UPDATED, handleTaskStatusUpdated);
		};
	}, [socket]);

	useEffect(() => {
		const fetchPatientData = async () => {
			if (!props.selectedPatient.allergies) {
				return;
			}
			props.selectedPatient.allergies.forEach(allergy => {
				try {
					if (allergy.categoryId === Allergies.FOOD.id && allergy.note.length > 0) {
						setFoodAllergies(JSON.parse(allergy.note));
					}
					if (allergy.categoryId === Allergies.MEDICATION.id && allergy.note.length > 0) {
						setMedicationAllergies(JSON.parse(allergy.note));
					}
					if (allergy.categoryId === Allergies.ENVIRONMENTAL.id && allergy.note.length > 0) {
						setEnvironmentalAllergies(JSON.parse(allergy.note));
					}
					if (allergy.categoryId === Allergies.BIOLOGICAL.id && allergy.note.length > 0) {
						setBiologicalAllergies(JSON.parse(allergy.note));
					}
				} catch (err) {
					setError(err.message);
				}
			});
		};
		if (props.selectedPatient) {
			fetchPatientData();
		}
	}, [props.selectedPatient]);

	const getDateOfBirth = values => {
		if (!values.birthMonth || !values.birthYear) {
			return null;
		}
		const month = moment(values.birthMonth).format('MM');
		const year = moment(values.birthYear).format('YYYY');
		let day = values.birthDay.toString();
		if (day.length === 1) {
			day = `${0}${day}`;
		}
		return `${year}-${month}-${day}`;
	};

	const onEnter = (e, formikProps) => {
		if (!e.target.value) {
			return;
		}

		const enter = 13;
		const value = capitalizeFirstLetter(e.target.value);
		const foodArr = [...foodAllergies];
		const medicArr = [...medicationAllergies];
		const environmentalArr = [...environmentalAllergies];
		const biologicalArr = [...biologicalAllergies];

		if (e.keyCode === enter) {
			if (!/^[a-zA-Z\s]*$/.test(value)) {
				setErrorOnAllergyEnter({ errorAt: e.target.name, errorMessage: intl.formatMessage({ id: 'allergyNameNonNumber' }) });
				return;
			}
			if (e.target.name === 'foodAllergy') {
				if (!foodArr.find(item => item.toLowerCase() === value.toLowerCase())) {
					foodArr.push(value);
					setFoodAllergies(foodArr);
					formikProps.setFieldValue('foodAllergy', '');
				}
			}
			if (e.target.name === 'medicationAllergy') {
				if (!medicArr.find(item => item.toLowerCase() === value.toLowerCase())) {
					medicArr.push(value);
					setMedicationAllergies(medicArr);
					formikProps.setFieldValue('medicationAllergy', '');
				}
			}
			if (e.target.name === 'environmentalAllergy') {
				if (!environmentalArr.find(item => item.toLowerCase() === value.toLowerCase())) {
					environmentalArr.push(value);
					setEnvironmentalAllergies(environmentalArr);
					formikProps.setFieldValue('environmentalAllergy', '');
				}
			}
			if (e.target.name === 'biologicalAllergy') {
				if (!biologicalArr.find(item => item.toLowerCase() === value.toLowerCase())) {
					biologicalArr.push(value);
					setBiologicalAllergies(biologicalArr);
					formikProps.setFieldValue('biologicalAllergy', '');
				}
			}
			setErrorOnAllergyEnter({});
		}
	};

	const removeAllergy = (item, type) => {
		const foodArr = [...foodAllergies];
		const medicArr = [...medicationAllergies];
		const environmentalArr = [...environmentalAllergies];
		const biologicalArr = [...biologicalAllergies];

		let foundIndex = null;

		if (type.id === Allergies.FOOD.id) {
			foundIndex = foodArr.findIndex(el => el === item);
			if (foundIndex > -1) {
				foodArr.splice(foundIndex, 1);
			}
			setFoodAllergies(foodArr);
		}
		if (type.id === Allergies.MEDICATION.id) {
			foundIndex = medicArr.findIndex(el => el === item);
			if (foundIndex > -1) {
				medicArr.splice(foundIndex, 1);
			}
			setMedicationAllergies(medicArr);
		}
		if (type.id === Allergies.ENVIRONMENTAL.id) {
			foundIndex = environmentalArr.findIndex(el => el === item);
			if (foundIndex > -1) {
				environmentalArr.splice(foundIndex, 1);
			}
			setEnvironmentalAllergies(environmentalArr);
		}
		if (type.id === Allergies.BIOLOGICAL.id) {
			foundIndex = biologicalArr.findIndex(el => el === item);
			if (foundIndex > -1) {
				biologicalArr.splice(foundIndex, 1);
			}
			setBiologicalAllergies(biologicalArr);
		}
	};

	const getValidationSchema = () =>
		Yup.object().shape({
			firstName: Yup.string()
				.required(intl.formatMessage({ id: 'firstNameCannotBeEmpty' }))
				.matches(/^[a-zA-Z-ëË' ]+$/, `${intl.formatMessage({ id: 'firstNameNonNumber' })}`)
				.max(30, `${intl.formatMessage({ id: 'maxLengthIs' })} 30`)
				.trim(),
			lastName: Yup.string()
				.required(intl.formatMessage({ id: 'lastNameCannotBeEmpty' }))
				.matches(/^[a-zA-Z-ëË' ]+$/, `${intl.formatMessage({ id: 'lastNameNonNumber' })}`)
				.max(30, `${intl.formatMessage({ id: 'maxLengthIs' })} 30`)
				.trim(),
			parentName: Yup.string()
				.required(intl.formatMessage({ id: 'parentNameCannotBeEmpty' }))
				.matches(/^[a-zA-Z-ëË' ]+$/, `${intl.formatMessage({ id: 'parentNameNonNumber' })}`)
				.max(30, `${intl.formatMessage({ id: 'maxLengthIs' })} 30`)
				.trim(),
			idNumber: Yup.string()
				.required(intl.formatMessage({ id: 'idNumberCannotBeEmpty' }))
				.min(
					10,
					intl.formatMessage({
						id: 'idNumberLength',
					})
				)
				.max(
					10,
					intl.formatMessage({
						id: 'idNumberLength',
					})
				)
				.trim(),
			address: Yup.string()
				.max(100, `${intl.formatMessage({ id: 'maxLengthIs' })} 100`)
				.trim(),
			city: Yup.string()
				.matches(/^[a-zA-Z-ëË' ]+$/, `${intl.formatMessage({ id: 'cityNameNonNumber' })}`)
				.max(100, `${intl.formatMessage({ id: 'maxLengthIs' })} 100`)
				.trim(),
			emailAddress: Yup.string()
				.max(100, `${intl.formatMessage({ id: 'maxLengthIs' })} 100`)
				.email(intl.formatMessage({ id: 'invalidEmail' }))
				.trim(),
			phoneNumber: Yup.string()
				.max(20, `${intl.formatMessage({ id: 'maxLengthIs' })} 20`)
				.trim(),
			genderId: Yup.string().required(intl.formatMessage({ id: 'pleaseSelectSex' })),
			height: Yup.number()
				.required(intl.formatMessage({ id: 'pleaseWriteHeight' }))
				.when('heightUnit', {
					is: MeasurementUnitOptions.HEIGHT.value[1]?.text,
					then: () =>
						Yup.number()
							.min(
								PatientHeightLimit.CENTIMETER.MIN,
								`${intl.formatMessage({ id: 'minHeightIs' })} ${PatientHeightLimit.CENTIMETER.MIN} cm`
							)
							.max(
								PatientHeightLimit.CENTIMETER.MAX,
								`${intl.formatMessage({ id: 'maxHeightIs' })} ${PatientHeightLimit.CENTIMETER.MAX} cm`
							),
				})
				.when('heightUnit', {
					is: MeasurementUnitOptions.HEIGHT.value[2]?.text,
					then: () =>
						Yup.number()
							.min(PatientHeightLimit.FEET.MIN, `${intl.formatMessage({ id: 'minHeightIs' })} ${PatientHeightLimit.FEET.MIN} ft`)
							.max(PatientHeightLimit.FEET.MAX, `${intl.formatMessage({ id: 'maxHeightIs' })} ${PatientHeightLimit.FEET.MAX} ft`),
				}),

			weight: Yup.number()
				.required(intl.formatMessage({ id: 'pleaseWriteWeight' }))
				.when('weightUnit', {
					is: MeasurementUnitOptions.WEIGHT.value[1]?.text,
					then: () =>
						Yup.number()
							.min(
								PatientWeightLimit.KILOGRAMS.MIN,
								`${intl.formatMessage({ id: 'minWeightIs' })} ${PatientWeightLimit.KILOGRAMS.MIN} kg`
							)
							.max(
								PatientWeightLimit.KILOGRAMS.MAX,
								`${intl.formatMessage({ id: 'maxWeightIs' })} ${PatientWeightLimit.KILOGRAMS.MAX} kg`
							),
				})
				.when('weightUnit', {
					is: MeasurementUnitOptions.WEIGHT.value[2]?.text,
					then: () =>
						Yup.number()
							.min(PatientWeightLimit.LBS.MIN, `${intl.formatMessage({ id: 'minWeightIs' })} ${PatientWeightLimit.LBS.MIN} lbs`)
							.max(PatientWeightLimit.LBS.MAX, `${intl.formatMessage({ id: 'maxWeightIs' })} ${PatientWeightLimit.LBS.MAX} lbs`),
				}),
			allergies: Yup.array().when('hasAllergy', {
				is: AddEditPatientRadioTypes.YES.toString(),
				then: () => Yup.array().required(intl.formatMessage({ id: 'cannotBeEmpty' })),
			}),
		});

	const savePatient = async values => {
		setIsNextBtnLoading(true);
		const allergies = [];
		if (values.allergies?.length > 0) {
			values.allergies.forEach(element => {
				let note = '';
				if (parseInt(element, 10) === Allergies.FOOD.id && foodAllergies.length > 0) {
					const foodArr = [...foodAllergies];
					note = JSON.stringify(foodArr);
				}
				if (parseInt(element, 10) === Allergies.MEDICATION.id && medicationAllergies.length > 0) {
					const medicArr = [...medicationAllergies];
					note = JSON.stringify(medicArr);
				}
				if (parseInt(element, 10) === Allergies.ENVIRONMENTAL.id && environmentalAllergies.length > 0) {
					const environmentalArr = [...environmentalAllergies];
					note = JSON.stringify(environmentalArr);
				}
				if (parseInt(element, 10) === Allergies.BIOLOGICAL.id && biologicalAllergies.length > 0) {
					const biologicalArr = [...biologicalAllergies];
					note = JSON.stringify(biologicalArr);
				}
				allergies.push({
					categoryId: parseInt(element, 10),
					note,
				});
			});
		}

		const healthInformation = {
			hasAllergy: parseInt(values.hasAllergy, 10),
			allergies,
			height:
				values.heightUnit === MeasurementUnitOptions.HEIGHT.value[1]?.text || !values.height
					? values.height.toString()
					: convertFeetToCm(parseFloat(values.height)).toString(),
			heightUnit: 'cm',
			weight:
				values.weightUnit === MeasurementUnitOptions.WEIGHT.value[1]?.text || !values.weight
					? values.weight.toString()
					: convertToKg(parseFloat(values.weight)).toString(),
			weightUnit: 'kg',
		};

		if (!moment(getDateOfBirth(values)).isValid() || moment(getDateOfBirth(values)).diff(moment(), 'days') >= 0) {
			setBirthDateError(intl.formatMessage({ id: 'dateOfBirthNotValid' }));
			return;
		}
		if (moment().diff(getDateOfBirth(values), 'years') > PatientAgeLimit.MAX) {
			setBirthDateError(intl.formatMessage({ id: 'maxBirthdayLimit' }));
			return;
		}
		setBirthDateError(null);
		const deviceId = getStorage().getItem('helloDeviceId');

		const dataToSend = {
			companyId: getCompanyId(),
			idCard: values.idNumber.toString(),
			patientHealthCardNumber: values.patientHealthCardNumber.toString(),
			email: values.emailAddress,
			firstName: values.firstName,
			lastName: values.lastName,
			parentName: values.parentName,
			address: values.address,
			city: values.city,
			phoneNumber: values.phoneNumber.toString(),
			birthDate: getDateOfBirth(values) || null,
			profession: values.profession,
			maritalStatusId: values.maritalStatusId ? parseInt(values.maritalStatusId, 10) : null,
			healthInformation,
			genderId: values.genderId,
			active: true,
			allergies: +values.hasAllergy === AddEditPatientRadioTypes.YES ? allergies : [],
			hasAllergy: +values.hasAllergy === AddEditPatientRadioTypes.YES,
			healthSystemId: userSession.healthSystem.id,
			deviceId: parseInt(deviceId, 10),
		};
		let result;
		if (dataToSend.allergies?.some(item => !item.note)) {
			return;
		}
		if (props.selectedPatient) {
			dataToSend.id = props.selectedPatient.id;
			result = await updatePatient(dataToSend);

			const addedPatientRes = await getPatientByIdCard(dataToSend.idCard);
			if (!addedPatientRes.error) {
				// props.setPatientUserId(addedPatientRes.userId);
				// should send to next step
				setIsLoading(false);
				props.setSelectedPatient({
					...dataToSend,
					userId: addedPatientRes.userId,
					healthcareUserId: addedPatientRes.healthcareUserId,
					id: addedPatientRes.id,
				});
			}
		} else {
			result = await registerPatient(dataToSend);
		}

		setIsNextBtnLoading(false);

		if (result.error) {
			setError(result.error.message);
			return;
		}

		if (!props.selectedPatient) {
			setNewPatient(dataToSend);
			setIsTaskLoading(true);
		}
	};
	return (
		<div className='position-relative patient-registration-pc'>
			{isTaskLoading && (
				<div className='full-width full-height flex flex-align-center flex-justify-center column-direction'>
					<Loader />
					<h4>{translate('patientIsBeingRegistered')}...</h4>
				</div>
			)}
			{!isTaskLoading && (
				<>
					<Formik
						enableReinitialize={true}
						validateOnBlur={true}
						initialValues={getInitialValues()}
						onSubmit={values => {
							savePatient(values);
						}}
						validationSchema={getValidationSchema()}>
						{formikProps => {
							const { values, handleChange, errors, handleSubmit, setFieldValue, touched, handleBlur } = formikProps;
							if (Object.keys(errors).length > 0) {
								if (Object.keys(values).length === Object.keys(touched).length) {
									setBirthDateError(null);
									if (!moment(getDateOfBirth(values)).isValid() || moment(getDateOfBirth(values)).diff(moment(), 'days') >= 0) {
										setBirthDateError(intl.formatMessage({ id: 'dateOfBirthNotValid' }));
									}
									if (moment().diff(getDateOfBirth(values), 'years') > PatientAgeLimit.MAX) {
										setBirthDateError(intl.formatMessage({ id: 'maxBirthdayLimit' }));
									}
								}
							}

							return (
								<>
									<div className='flex flex-space-between'>
										<button type='button' className='visit-btn' onClick={props.goBack}>
											<img src={`${primaryCareImageUrl}back-arrow.svg`} alt='back arrow icon' />
											{translate('goBack')}
										</button>

										<div className='flex'>
											{props.selectedPatient && (
												<button type='button' className='visit-btn blue-highlight-btn' onClick={props.setIsEditProfile}>
													<img src={`${primaryCareImageUrl}profile.svg`} alt='patient profile icon' />
													{translate('patientHistory')}
												</button>
											)}

											<Button
												type='button'
												className='visit-btn blue-btn'
												onClick={() => handleSubmit()}
												isLoading={isNextBtnLoading}
												isDisabled={isNextBtnLoading}
												imgIcon={`${primaryCareImageUrl}next-arrow.svg`}
												text={translate('next')}
											/>
										</div>
									</div>

									<div className='flex flex-align-center column-direction position-relative'>
										<div className='check-in-title'>
											<h3>{translate(props.selectedPatient ? 'editPatient' : 'registerNewPatient')}</h3>
											<p className='text-align-center'>{translate('getPatientData')}</p>
										</div>
									</div>
									<Form className='add-edit-patient-form flex-1 overflow-auto'>
										<div className='flex view-profile-modal-wrapper flex-wrap'>
											<div>
												<div>
													<p>*{translate('firstName')}</p>
													<input
														type='text'
														value={capitalizeFirstLetter(values.firstName)}
														onChange={handleChange}
														onBlur={handleBlur}
														name='firstName'
														minLength={2}
														maxLength={30}
													/>
												</div>
												{touched?.firstName && errors?.firstName && <span className='red-error'>{errors.firstName}</span>}
											</div>
											<div>
												<div>
													<p>*{translate('lastName')}</p>
													<input
														type='text'
														value={capitalizeFirstLetter(values.lastName)}
														onChange={handleChange}
														onBlur={handleBlur}
														name='lastName'
														minLength={2}
														maxLength={30}
													/>
												</div>
												{touched?.lastName && errors?.lastName && <span className='red-error'>{errors.lastName}</span>}
											</div>
											<div>
												<div>
													<p>*{translate('parentName')}</p>
													<input
														type='text'
														value={capitalizeFirstLetter(values.parentName)}
														onChange={handleChange}
														onBlur={handleBlur}
														name='parentName'
														minLength={2}
														maxLength={30}
													/>
												</div>
												{touched?.parentName && errors?.parentName && <span className='red-error'>{errors.parentName}</span>}
											</div>
											<div>
												<div>
													<p>{translate('patientHealthCardNumber')}</p>
													<input
														type='number'
														value={values.patientHealthCardNumber}
														onChange={handleChange}
														onBlur={handleBlur}
														name='patientHealthCardNumber'
														onKeyDown={event => validateMaxLength(event, 10)}
														min='0'
													/>
												</div>
												{touched?.patientHealthCardNumber && errors?.patientHealthCardNumber && (
													<span className='red-error'>{errors.patientHealthCardNumber}</span>
												)}
											</div>
											<div className='patient-birth-date'>
												<div>
													<p>*{translate('dateOfBirth')}</p>
													<div className='flex'>
														<input
															type='number'
															value={values.birthDay}
															onChange={handleChange}
															name='birthDay'
															onBlur={val => {
																if (!val.target.value) {
																	setBirthDateError(intl.formatMessage({ id: 'dateOfBirthRequired' }));
																} else if (val.target.value && values.birthYear && values.birthMonth) {
																	setBirthDateError(null);
																}
															}}
															onKeyDown={event => validateMaxLength(event, 2)}
															placeholder={`${intl.formatMessage({ id: 'day' })} (DD)`}
														/>
														<DatePicker
															selected={values.birthMonth}
															onChange={val => {
																setFieldValue('birthMonth', val);
																if (val && values.birthYear && values.birthDay) {
																	setBirthDateError(null);
																}
															}}
															onBlur={val => {
																if (!val.target.value) {
																	setBirthDateError(intl.formatMessage({ id: 'dateOfBirthRequired' }));
																}
															}}
															showMonthYearPicker
															showFullMonthYearPicker
															dateFormat='MMMM'
															placeholderText={`${intl.formatMessage({ id: 'month' })} (MM)`}
															locale={locale}
														/>
														<DatePicker
															selected={values.birthYear}
															onChange={val => {
																setFieldValue('birthYear', val);
																if (val && values.birthMonth && values.birthDay) {
																	setBirthDateError(null);
																}
															}}
															showYearPicker
															dateFormat='yyyy'
															onBlur={val => {
																if (!val.target.value) {
																	setBirthDateError(intl.formatMessage({ id: 'dateOfBirthRequired' }));
																}
															}}
															placeholderText={`${intl.formatMessage({ id: 'year' })} (MM)`}
															maxDate={new Date()}
															customInput={
																<MaskedTextInput
																	type='text'
																	mask={[/\d/, /\d/, /\d/, /\d/]}
																	onKeyDown={event => validateMaxLength(event, 4)}
																/>
															}
														/>
													</div>
												</div>
												{birthDateError && <span className='red-error'>{birthDateError}</span>}
											</div>

											<div className='position-relative' ref={genderWrapperRef}>
												<div>
													<p>*{translate('sex')}</p>
													<div className='flex no-margin' onClick={setIsGenderOpen}>
														<input
															type='select'
															value={values.genderId && genders.find(item => item.id === +values.genderId)?.name}
															disabled
															className='cursor-pointer'
														/>
														<i className='material-icons'>expand_more</i>
													</div>
												</div>
												{touched?.genderId && errors?.genderId && <span className='red-error'>{errors.genderId}</span>}
												{isGenderOpen && (
													<div className='custom-select-result'>
														{genders.map(item => (
															<div
																className='flex cursor-pointer header-elements'
																key={item.id}
																onClick={() => toggleGenderDropDown(item, formikProps)}>
																<p>{item.name}</p>
															</div>
														))}
													</div>
												)}
											</div>

											<div>
												<div>
													<p>*{translate('idNumber')}</p>
													<input
														type='number'
														value={values.idNumber}
														onChange={handleChange}
														onBlur={handleBlur}
														name='idNumber'
														onKeyDown={event => validateMaxLength(event, 10)}
														min='1'
													/>
												</div>
												{touched?.idNumber && errors?.idNumber && <span className='red-error'>{errors.idNumber}</span>}
											</div>
											<div>
												<div>
													<p>{translate('city')}</p>
													<input
														type='text'
														value={capitalizeFirstLetter(values.city)}
														onChange={handleChange}
														onBlur={handleBlur}
														name='city'
														minLength={2}
														maxLength={30}
													/>
												</div>
												{errors?.city && <span className='red-error'>{errors.city}</span>}
											</div>

											<div>
												<div>
													<p>{translate('address')}</p>
													<input
														type='text'
														value={capitalizeFirstLetter(values.address)}
														onChange={handleChange}
														onBlur={handleBlur}
														name='address'
														minLength={2}
														maxLength={30}
													/>
												</div>
												{touched?.address && errors?.address && <span className='red-error'>{errors.address}</span>}
											</div>
											<div>
												<div>
													<p>{translate('profession')}</p>
													<input
														type='text'
														value={capitalizeFirstLetter(values.profession)}
														onChange={handleChange}
														onBlur={handleBlur}
														name='profession'
														minLength={2}
														maxLength={30}
													/>
												</div>
												{touched?.profession && errors?.profession && <span className='red-error'>{errors.profession}</span>}
											</div>
											<div className='position-relative' ref={maritalWrapperRef}>
												<div>
													<p>{translate('maritalStatus')}</p>
													<div className='flex no-margin' onClick={setIsMaritalStatusOpen}>
														<input
															type='select'
															value={
																values.maritalStatusId && maritalStatusList.find(item => item.id === +values.maritalStatusId).name
															}
															disabled
															className='cursor-pointer'
														/>
														<i className='material-icons'>expand_more</i>
													</div>
												</div>
												{touched?.maritalStatusId && errors?.maritalStatusId && (
													<span className='red-error'>{errors.maritalStatusId}</span>
												)}
												{isMaritalStatusOpen && (
													<div className='custom-select-result'>
														{maritalStatusList.map(item => (
															<div
																className='flex cursor-pointer header-elements'
																key={item.id}
																onClick={() => toggleMaritalDropdown(item, formikProps)}>
																<p>{item.name}</p>
															</div>
														))}
													</div>
												)}
											</div>
											<div>
												<div>
													<p>{translate('emailAddress')}</p>
													<input
														type='email'
														value={values.emailAddress}
														onChange={handleChange}
														onBlur={handleBlur}
														name='emailAddress'
														maxLength={100}
													/>
												</div>
												{touched?.emailAddress && errors?.emailAddress && (
													<span className='red-error'>{errors.emailAddress}</span>
												)}
											</div>
											<div>
												<div>
													<p>{translate('phoneNumber')}</p>
													<input
														type='number'
														value={values.phoneNumber}
														onChange={handleChange}
														onBlur={handleBlur}
														name='phoneNumber'
														onKeyDown={event => validateMaxLength(event, 15)}
														min='0'
													/>
												</div>
												{touched?.phoneNumber && errors?.phoneNumber && <span className='red-error'>{errors.phoneNumber}</span>}
											</div>
											<h4 className='other-information-data'>{translate('otherInformation')}</h4>
											<div className='flex view-profile-modal-wrapper flex-wrap patient-input-wrapper primary-care'>
												<div className='row-direction'>
													<div>
														<p>*{translate('height')}</p>
														<input
															type='text'
															value={values.height}
															onChange={event => {
																if (isDecimal(event.target.value)) {
																	handleChange(event);
																}
															}}
															onBlur={handleBlur}
															name='height'
															className='number-input-wo-arrows'
														/>
													</div>
													<div>
														<p>{translate('unit')}</p>
														<input
															type='text'
															value={
																MeasurementUnitOptions.HEIGHT.value[
																	props.unitPreferences.find(item => item.unitCategoryId === UnitCategoryTypes.HEIGHT)
																		?.unitSystemId
																]?.text
															}
															onChange={handleChange}
															name='unit'
															readOnly
														/>
													</div>
													{touched?.height && errors?.height && <span className='red-error'>{errors.height}</span>}
												</div>
												<div className='row-direction'>
													<div>
														<p>*{translate('weight')}</p>
														<input
															type='text'
															value={values.weight}
															onChange={event => {
																if (isDecimal(event.target.value)) {
																	handleChange(event);
																}
															}}
															onBlur={props.onBlur}
															name='weight'
															className='number-input-wo-arrows'
														/>
													</div>
													<div>
														<p>{translate('unit')}</p>
														<input
															type='text'
															value={
																MeasurementUnitOptions.WEIGHT.value[
																	props.unitPreferences.find(item => item.unitCategoryId === UnitCategoryTypes.WEIGHT)
																		?.unitSystemId
																]?.text
															}
															onChange={handleChange}
															name='unit'
															readOnly
														/>
													</div>
													{touched?.weight && errors?.weight && <span className='red-error'>{errors.weight}</span>}
												</div>
											</div>
											<div className='patient-registration-checkboxes'>
												<div>
													<p>{translate('doesPatientHaveAllergies')}</p>
													<div className='flex'>
														<label>
															<input
																type='radio'
																name='hasAllergy'
																value={AddEditPatientRadioTypes.NO_ANSWER}
																onChange={handleChange}
																onBlur={handleBlur}
																checked={values.hasAllergy === AddEditPatientRadioTypes.NO_ANSWER.toString()}
															/>
															{translate('noResponseFromPatient')}
														</label>
														<label>
															<input
																type='radio'
																name='hasAllergy'
																value={AddEditPatientRadioTypes.YES}
																onChange={handleChange}
																onBlur={handleBlur}
																checked={values.hasAllergy === AddEditPatientRadioTypes.YES.toString()}
															/>
															{translate('yes')}
														</label>
														<label>
															<input
																type='radio'
																name='hasAllergy'
																value={AddEditPatientRadioTypes.NO}
																onChange={handleChange}
																onBlur={handleBlur}
																checked={values.hasAllergy === AddEditPatientRadioTypes.NO.toString()}
															/>
															{translate('no')}
														</label>
													</div>
												</div>
											</div>
											{parseInt(values.hasAllergy, 10) === AddEditPatientRadioTypes.YES && (
												<>
													<div className='patient-registration-checkboxes'>
														<div>
															<p>{translate('whatTypeOfAllergies')}</p>
															<div className='flex'>
																<label>
																	<input
																		type='checkbox'
																		name='allergies'
																		value={Allergies.FOOD.id}
																		onChange={handleChange}
																		onBlur={handleBlur}
																		checked={values.allergies.find(item => item === Allergies.FOOD.id.toString())}
																	/>
																	{translate('food')}
																</label>
																<label>
																	<input
																		type='checkbox'
																		name='allergies'
																		value={Allergies.MEDICATION.id}
																		onChange={handleChange}
																		onBlur={handleBlur}
																		checked={values.allergies.find(item => item === Allergies.MEDICATION.id.toString())}
																	/>
																	{translate('medication')}
																</label>
																<label>
																	<input
																		type='checkbox'
																		name='allergies'
																		value={Allergies.ENVIRONMENTAL.id}
																		onChange={handleChange}
																		onBlur={handleBlur}
																		checked={values.allergies.find(item => item === Allergies.ENVIRONMENTAL.id.toString())}
																	/>
																	{translate('environmental')}
																</label>
																<label>
																	<input
																		type='checkbox'
																		name='allergies'
																		value={Allergies.BIOLOGICAL.id}
																		onChange={handleChange}
																		onBlur={handleBlur}
																		checked={values.allergies.find(item => item === Allergies.BIOLOGICAL.id.toString())}
																	/>
																	{translate('biological')}
																</label>
															</div>
														</div>
														{touched?.allergies && errors?.allergies && <span className='red-error'>{errors.allergies}</span>}
													</div>
													{values.allergies.find(item => item === Allergies.FOOD.id.toString()) && (
														<div className='patient-registration-allergy-list'>
															<div>
																<p>{translate('foodAllergies')}</p>
																<input
																	type='text'
																	value={values.foodAllergy}
																	onChange={handleChange}
																	onBlur={handleBlur}
																	name='foodAllergy'
																	placeholder={intl.formatMessage({ id: 'addAllergies' })}
																	ref={foodInput}
																	onKeyDown={e => onEnter(e, formikProps)}
																	enterKeyHint='go'
																/>
															</div>
															<div className='allergies-errors'>
																{errorOnAllergyEnter.errorAt === 'foodAllergy' && (
																	<span className='red-error'>{errorOnAllergyEnter.errorMessage}</span>
																)}
																{foodAllergies.length === 0 && (
																	<span className='red-error'>{intl.formatMessage({ id: 'pleaseWriteAllergy' })}</span>
																)}
															</div>
															{foodAllergies.length > 0 && (
																<div className='flex  allergies-list'>
																	{foodAllergies.map(item => (
																		<div className='flex'>
																			<i className='material-icons' onClick={() => removeAllergy(item, Allergies.FOOD)}>
																				close
																			</i>
																			<p>{item}</p>
																		</div>
																	))}
																</div>
															)}
														</div>
													)}
													{values.allergies.find(item => item === Allergies.MEDICATION.id.toString()) && (
														<div className='patient-registration-allergy-list'>
															<div>
																<p>{translate('medicationAllergies')}</p>
																<input
																	type='text'
																	value={values.medicationAllergy}
																	onChange={handleChange}
																	onBlur={handleBlur}
																	name='medicationAllergy'
																	placeholder={intl.formatMessage({ id: 'addAllergies' })}
																	ref={medicationInput}
																	onKeyDown={e => onEnter(e, formikProps)}
																	enterKeyHint='go'
																/>
															</div>
															<div className='allergies-errors'>
																{errorOnAllergyEnter.errorAt === 'medicationAllergy' && (
																	<span className='red-error'>{errorOnAllergyEnter.errorMessage}</span>
																)}
																{medicationAllergies.length === 0 && (
																	<span className='red-error'>{intl.formatMessage({ id: 'pleaseWriteAllergy' })}</span>
																)}
															</div>
															{medicationAllergies.length > 0 && (
																<div className='flex allergies-list'>
																	{medicationAllergies.map(item => (
																		<div className='flex'>
																			<i className='material-icons' onClick={() => removeAllergy(item, Allergies.MEDICATION)}>
																				close
																			</i>
																			<p>{item}</p>
																		</div>
																	))}
																</div>
															)}
														</div>
													)}
													{values.allergies.find(item => item === Allergies.ENVIRONMENTAL.id.toString()) && (
														<div className='patient-registration-allergy-list'>
															<div>
																<p>{translate('environmentalAllergies')}</p>
																<input
																	type='text'
																	value={values.environmentalAllergy}
																	onChange={handleChange}
																	onBlur={handleBlur}
																	name='environmentalAllergy'
																	placeholder={intl.formatMessage({ id: 'addAllergies' })}
																	ref={environmentalInput}
																	onKeyDown={e => onEnter(e, formikProps)}
																	enterKeyHint='go'
																/>
															</div>
															<div className='allergies-errors'>
																{errorOnAllergyEnter.errorAt === 'environmentalAllergy' && (
																	<span className='red-error'>{errorOnAllergyEnter.errorMessage}</span>
																)}
																{environmentalAllergies.length === 0 && (
																	<span className='red-error'>{intl.formatMessage({ id: 'pleaseWriteAllergy' })}</span>
																)}
															</div>
															{environmentalAllergies.length > 0 && (
																<div className='flex allergies-list'>
																	{environmentalAllergies.map(item => (
																		<div className='flex'>
																			<i className='material-icons' onClick={() => removeAllergy(item, Allergies.ENVIRONMENTAL)}>
																				close
																			</i>
																			<p>{item}</p>
																		</div>
																	))}
																</div>
															)}
														</div>
													)}
													{values.allergies.find(item => item === Allergies.BIOLOGICAL.id.toString()) && (
														<div className='patient-registration-allergy-list'>
															<div>
																<p>{translate('biologicalAllergies')}</p>
																<input
																	type='text'
																	value={values.biologicalAllergy}
																	onChange={handleChange}
																	onBlur={handleBlur}
																	name='biologicalAllergy'
																	placeholder={intl.formatMessage({ id: 'addAllergies' })}
																	ref={biologicalInput}
																	onKeyDown={e => onEnter(e, formikProps)}
																	enterKeyHint='go'
																/>
															</div>
															<div className='allergies-errors'>
																{errorOnAllergyEnter.errorAt === 'biologicalAllergy' && (
																	<span className='red-error'>{errorOnAllergyEnter.errorMessage}</span>
																)}
																{biologicalAllergies.length === 0 && (
																	<span className='red-error'>{intl.formatMessage({ id: 'pleaseWriteAllergy' })}</span>
																)}
															</div>
															{biologicalAllergies.length > 0 && (
																<div className='flex allergies-list'>
																	{biologicalAllergies.map(item => (
																		<div className='flex'>
																			<i className='material-icons' onClick={() => removeAllergy(item, Allergies.BIOLOGICAL)}>
																				close
																			</i>
																			<p>{item}</p>
																		</div>
																	))}
																</div>
															)}
														</div>
													)}
												</>
											)}

											<div className='submit-btn-wrapper full-width'>
												<button type='button' className='position-relative margin-left-auto' onClick={() => handleSubmit()}>
													{translate(props.selectedPatient ? 'editPatient' : 'registerPatient')}
												</button>
											</div>
										</div>
									</Form>
								</>
							);
						}}
					</Formik>
				</>
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</div>
	);
};

export default PatientRegistration;
