import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import ReactSelect from 'react-select';
import { useHistory } from 'react-router';
import { CSVLink } from 'react-csv';
import Table from 'components/Table.jsx';
import Button from 'components/Button.jsx';
import { actionCreators } from 'state/healthSystems/actions.js';
import Modal from 'components/Modal.jsx';
import Form from 'components/Form.jsx';
import DescriptionBox from 'components/DescriptionBox.jsx';
import SkeletonLoader from 'components/SkeletonLoader.jsx';
import TreeView from 'components/TreeView.jsx';
import {
	DeviceListLevel,
	ConnectivityStatus,
	DeviceStatus,
	InviteStatuses,
	InviteTypes,
	HealthSystemType,
	VisitorType,
} from 'constants/enums.js';
import { gatewayApi, healthCareCdnUrl } from 'constants/global-variables.js';
import { getDeviceList, getDeviceSettings, getUnassociatedDevices, updateDeviceSettings } from 'api/devices.js';
import { deleteOrgUnit } from 'api/healthSystems.js';
import {
	buildTree,
	decodeHtml,
	findSectorById,
	getParentSector,
	getAppVersion,
	getCodeNameByOsVersion,
	getHierarchyByHelloDeviceId,
	getHealthSystemDevices,
	getConfigurationValue,
	getLevelDescriptionById,
} from 'infrastructure/helpers/commonHelpers.js';
import translate from 'i18n-translations/translate.jsx';
import Alert from 'components/Alert.jsx';
import { getCompanyId, getUserRole } from 'infrastructure/auth.js';
import DeviceSettings from 'containers/DeviceSettings.jsx';
import EditSectorModal from 'containers/EditSectorModal.jsx';
import AssignPatient from 'containers/AssignPatient.jsx';
import { getRegionSubTree } from 'api/tree.js';
import DeviceActions from 'components/DeviceActions.jsx';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from 'components/Tabs.jsx';
import AssignFamilyMemberOrVisitor from 'containers/AssignFamilyMemberOrVisitor.jsx';
import { getRoomMembersAndInvitees } from 'api/organization.js';
import { monthDayYear } from 'infrastructure/helpers/dateHelper.js';
import { deleteMemberV1M2 } from 'api/users.js';
import { UserRoles } from 'calls/enums/index.js';
import Dropdown from 'components/Dropdown.jsx';
import VisitingHoursConfiguration from 'components/VisitingHoursConfiguration.jsx';
import { getVisitingHours } from 'api/visitingHours.js';
import { getInitialVisitingHoursMaped, reArrangeVisitingHours } from 'infrastructure/helpers/visitingHoursHelper.js';
import { RoomTypes } from 'constants/visitEnums.js';
import ScheduleDataAcquisition from 'containers/ScheduleDataAcquisition.jsx';
import Wearables from 'components/Wearables.jsx';
import { actionCreators as devicesActionCreators } from 'state/devices/actions.js';
import AlexaSmartPropertiesWrapper from 'containers/AlexaSmartPropertiesWrapper.jsx';
import { getRoomSettings } from 'api/adminConfigurations.js';
import {
	GeneralSettings,
	PTZCameraSettings,
	MonitoringSettings,
	SettingsCategory,
	CompanySettings,
} from 'constants/configurationEnums.js';
import HandHygieneAudit from 'components/HandHygieneAudit.jsx';
import TreeViewMayo from 'components/TreeViewMayo.jsx';
import MonitoringAiFeatureFlags from 'containers/Organization/MonitoringAiFeatureFlags.jsx';

const Room = props => {
	const [isAddDeviceModalLoading, setIsAddDeviceModalLoading] = useState(false);
	const [isRoomModalOpen, setIsRoomModalOpen] = useState(false);
	const [isDevicesModalOpen, setIsDevicesModalOpen] = useState(false);
	const [isAddPatientModalOpen, setIsAddPatientModalOpen] = useState(false);
	const [visitorType, setVisitorType] = useState(null);
	const [isRoomLoaded, setIsRoomLoaded] = useState(false);
	const [preSelected, setPreSelected] = useState(null);
	const [devices, setDevices] = useState([]);
	const [helloDeviceId, setHelloDeviceId] = useState('');
	const [unassociatedDevicesToSelect, setUnassociatedDevicesToSelect] = useState([]);
	const [isAddPatientModalLoading, setIsAddPatientModalLoading] = useState(false);
	const [selectedOrg, setSelectedOrg] = useState({ name: '', level: null, id: null });
	const [isDeleteOrgUnitModalOpen, setIsDeleteOrgUnitModalOpen] = useState(false);
	const [isAddDeviceModalSubmitDisabled, setIsAddDeviceModalSubmitDisabled] = useState(true);
	const [isDeleteDeviceModalLoading, setIsDeleteDeviceModalLoading] = useState(false);
	const [error, setError] = useState(null);
	const [isDeviceSettingsModalVisible, setIsDeviceSettingsModalVisible] = useState(false);
	const [currentRoom, setCurrentRoom] = useState({
		sectorName: '',
		roomName: '',
		hospitalId: '',
		departmentId: '',
		floorId: '',
		roomId: '',
		sectorType: '',
		type: null,
	});
	const [currentTab, setCurrentTab] = useState(0);
	const [roomFamilyMembers, setRoomFamilyMembers] = useState([]);
	const intl = useIntl();
	const history = useHistory();
	const dispatch = useDispatch();
	const setHealthSystem = hs => dispatch(actionCreators.setHealthSystem(hs));
	const updateBreadcrumb = breadcrumb => dispatch(actionCreators.updateBreadcrumb(breadcrumb));
	const setTreeData = tree => dispatch(actionCreators.setTreeData(tree));
	const healthSystems = useSelector(state => state.healthSystems);
	const userSession = useSelector(state => state.user.userSession);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [isReversePTZCamModalOpen, setIsReversePTZCamModalOpen] = useState(false);
	const [isReversePTZCamModalLoading, setIsReversePTZCamModalLoading] = useState(false);
	const [currentMember, setCurrentMember] = useState(null);
	const [isPrimaryCareType, setIsPrimaryCareType] = useState(false);
	const [roomVisitors, setRoomVisitors] = useState([]);
	const [visitingHours, setVisitingHours] = useState([]);
	const [visitorsOrFamilyMembersUpdated, setVisitorsOrFamilyMembersUpdated] = useState(false);
	const [isVisitingHoursModalOpen, setIsVisitingHoursModalOpen] = useState(false);
	const [isAlexaSmartPropertiesOpen, setIsAlexaSmartPropertiesOpen] = useState(false);
	const [isDataAcquisitionOpen, setIsDataAcquisitionOpen] = useState(false);
	const pairedRemoteDevices = useSelector(state => state.devices.pairedRemoteDevices);
	const [isAlexaPropertiesEnabled, setIsAlexaPropertiesEnabled] = useState(false);
	const [isReverseCameraEnabled, setIsReverseCameraEnabled] = useState(false);
	const [showHandHygiene, setShowHandHygiene] = useState(false);
	const companyConfigurations = useSelector(state => state.company.companySettings?.companyConfigurations);
	const isNewExperience = useSelector(state => state.configurations.isNewExperience);
	const [sectorData, setSectorData] = useState({ sectorName: '', hospitalId: '' });

	useEffect(() => {
		setIsPrimaryCareType(userSession.healthSystem.workflowTypeId === HealthSystemType.PRIMARY_CARE);
	}, [userSession]);

	const openEditSectorModal = selectedSector => {
		const sector = findSectorById(healthSystems.treeData.tree, selectedSector[`${selectedSector.sectorType}Id`]);
		const sectorParent = getParentSector(healthSystems.treeData.tree, sector);
		selectedSector.parentSectorName = sectorParent.name;
		selectedSector.parentSectorType = sectorParent.type;
		setSectorData(selectedSector);
		setIsRoomModalOpen(prevState => !prevState);
	};

	useEffect(() => {
		if (isDevicesModalOpen) {
			setHelloDeviceId('');
			setIsAddDeviceModalSubmitDisabled(true);
		}
	}, [isDevicesModalOpen]);

	const loadRoom = async (deviceId, voiceCommandCalling) => {
		const [devicesRes, unassociatedDevicesRes] = await Promise.all([
			getDeviceList(DeviceListLevel.ROOM, props.match.params.roomId),
			getUnassociatedDevices(),
			fetchRoomFamilyMembers(),
		]);
		if (devicesRes.error) {
			setError(devicesRes.error.message);
		} else {
			const foundDevice = devicesRes.find(device => device.solHelloDeviceId === deviceId);
			if (foundDevice) {
				foundDevice.voiceCommandCalling = voiceCommandCalling;
			}
			setDevices(devicesRes);
		}
		if (unassociatedDevicesRes.error) {
			setError(unassociatedDevicesRes.error.message);
		} else {
			setUnassociatedDevicesToSelect(transformUnassociatedDevices(unassociatedDevicesRes));
		}
		setIsRoomLoaded(true);
		setIsAddDeviceModalLoading(false);
		setIsAddPatientModalLoading(false);
		fetchTreeData();
	};

	useEffect(() => {
		const fetchVisitingHours = async () => {
			const response = await getVisitingHours(DeviceListLevel.ROOM, props.match.params.roomId);
			if (response.error) {
				setError(response.error.message);
			} else {
				const result = getInitialVisitingHoursMaped(response.visitingHours);
				const visitingHoursRearragned = reArrangeVisitingHours(result, intl);
				setVisitingHours(visitingHoursRearragned);
			}
		};
		const fetchRoomSettings = async () => {
			const response = await getRoomSettings(props.match.params.roomId, SettingsCategory.GENERAL);
			if (response.error) {
				setError(response.error.message);
				return;
			}
			const alexaSetting = response.settings.filter(x => x.settingTypeId === GeneralSettings.Alexa)[0];
			setIsAlexaPropertiesEnabled(alexaSetting?.value === 'true');
			const handHygieneSetting = response.settings.filter(x => x.settingTypeId === MonitoringSettings.HandWashing)[0];
			setShowHandHygiene(handHygieneSetting?.value === 'true' && getUserRole() !== UserRoles.SUPER_USER);
		};
		fetchRoomSettings();
		loadRoom();
		fetchVisitingHours();
	}, [props.match.params.roomId, visitorsOrFamilyMembersUpdated, intl]);

	const getSettingsOfDevices = async () => {
		setIsReversePTZCamModalLoading(true);
		const selectedRoom = findSectorById(healthSystems.treeData.tree, props.match.params.roomId);
		const response = await getDeviceSettings(selectedRoom?.helloDeviceId);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		const reversePtzCamEnabled = response?.deviceSettings.find(x => x.settingTypeId === PTZCameraSettings.REVERSE_CAMERA)?.value;
		setIsReverseCameraEnabled(reversePtzCamEnabled === 'true');
		setIsReversePTZCamModalLoading(false);
	};

	const fetchRoomFamilyMembers = useCallback(async () => {
		const response = await getRoomMembersAndInvitees(userSession.healthSystem.id, props.match.params.roomId);
		if (response.error) {
			setRoomFamilyMembers([]);
			setRoomVisitors([]);
			setError(response.error.message);
			return;
		}
		setRoomFamilyMembers(response?.roomMembers.filter(item => item.visitorTypeId === VisitorType.FAMILY_MEMBER));
		setRoomVisitors(response?.roomMembers.filter(item => item.visitorTypeId === VisitorType.VISITOR));
	}, [props.match.params.roomId, userSession.healthSystem.id]);

	useEffect(() => {
		if (userSession.healthSystem.id) {
			fetchRoomFamilyMembers();
		}
	}, [
		userSession.healthSystem.id,
		healthSystems.currentSectorLocation,
		props.match.params.hospitalId,
		props.match.params.roomId,
		fetchRoomFamilyMembers,
	]);

	useEffect(() => {
		if (!userSession.healthSystem.id) {
			return;
		}
		const fetchData = async () => {
			setPreSelected(getPreSelected(healthSystems.currentSectorLocation));
		};
		fetchData();
	}, [userSession.healthSystem.id, healthSystems.currentSectorLocation]);

	useEffect(() => {
		const room = getCurrentRoom(healthSystems.treeData.tree, props.match.params.roomId);
		setCurrentRoom(room);
	}, [healthSystems.treeData.tree, props.match.params.roomId]);

	const hasPairedRemote = helloId => {
		if (!helloId) {
			return false;
		}
		return pairedRemoteDevices.some(id => id === +helloId);
	};

	const getCurrentRoom = (tree, roomId) => {
		let selectedRoom;
		if (tree.length > 0) {
			selectedRoom = findSectorById(tree, roomId);
		}
		return {
			sectorName: selectedRoom ? selectedRoom.name : '',
			roomName: selectedRoom ? selectedRoom.name : '',
			hospitalId: selectedRoom ? selectedRoom.hospitalId : '',
			departmentId: selectedRoom ? selectedRoom.departmentId : '',
			floorId: selectedRoom ? selectedRoom.floorId : '',
			roomId: selectedRoom ? selectedRoom.roomId : '',
			sectorType: selectedRoom ? selectedRoom.type : '',
			hasPairedRemote: hasPairedRemote(selectedRoom?.helloDeviceId),
			isOnline: selectedRoom?.status === DeviceStatus.ONLINE,
			solHelloDeviceId: selectedRoom?.helloDeviceId,
			type: selectedRoom?.roomType,
			isMultiBed: selectedRoom?.isMultiBed,
		};
	};

	const getPreSelected = currentSectorLocation => {
		const currentSector = [...currentSectorLocation];
		let preSelectedItems = {};
		if (currentSector.length) {
			preSelectedItems = {
				[currentSector[0].name]: {
					[currentSector[0].subOptions[0].name]: {
						[currentSector[0].subOptions[0].subOptions[0].name]: {
							[currentSector[0].subOptions[0].subOptions[0].subOptions[0]]: {},
						},
					},
				},
			};
		}
		return preSelectedItems;
	};

	const transformUnassociatedDevices = items => items.map(item => ({ value: item.uid, id: item.solHelloDeviceId }));

	const getConnectionType = device => (
		<>
			{[device.wifi, device.ethernet].includes(ConnectivityStatus.ON) && (
				<>
					{device.wifi === ConnectivityStatus.ON && 'WiFi'}
					{device.ethernet === ConnectivityStatus.ON && device.wifi === ConnectivityStatus.ON && ','}{' '}
					{device.ethernet === ConnectivityStatus.ON && 'Ethernet'}
				</>
			)}

			{device.wifi === ConnectivityStatus.OFF && device.ethernet === ConnectivityStatus.OFF && 'N/A'}
		</>
	);

	const getDeviceOwner = device => (
		<div className='flex'>{isPatientAssigned(device) ? decodeHtml(device.owner) : translate('unassigned')}</div>
	);

	const displayDevices = () => {
		if (!devices.length || healthSystems.treeData.tree.length === 0) {
			return [];
		}
		return devices.map(device => {
			return {
				id: device.id,
				roomName: currentRoom.roomName,
				...(isPatientDisabled() || isPatientVirtual(device) ? [] : { owner: getDeviceOwner(device) }),
				serialNumber: device.serialNumber || 'N/A',
				macAddress: device.macAddress || 'N/A',
				appVersion: device.appVersion ? getAppVersion(device.appVersion) : 'N/A',
				androidVersion: device.appVersion ? getCodeNameByOsVersion(device.osVersion) : 'N/A',
				osVersion: device.firmwareRevision || 'N/A',
				ipAddress: device.ipAddress || 'N/A',
				ssid: device.ssid || 'N/A',
				connectionType: getConnectionType(device),
				voiceCommandCalling: intl.formatMessage({ id: device.voiceCommandCalling ? 'active' : 'notActive' }),
				actions: (
					<DeviceActions
						setHelloDeviceId={setHelloDeviceId}
						setIsDeleteDeviceModalLoading={setIsDeleteDeviceModalLoading}
						fetchTreeData={fetchTreeData}
						isDeleteDeviceModalLoading={isDeleteDeviceModalLoading}
						userSession={userSession}
						healthSystems={healthSystems}
						loadRoom={(deviceId, voiceCommandCalling) => loadRoom(deviceId, voiceCommandCalling)}
						helloDeviceId={helloDeviceId}
						device={device}
						isVoiceCommand={true}
						isPatientDisabled={isPatientDisabled()}
					/>
				),
			};
		});
	};

	const deleteOrgUnitSubmit = async () => {
		await deleteOrgUnit(selectedOrg.level, selectedOrg.id);
		setIsDeleteOrgUnitModalOpen(prevState => !prevState);
		fetchTreeData();
		if (selectedOrg.level === DeviceListLevel.ROOM) {
			history.push('/health-system');
			updateBreadcrumb([]);
		}
	};

	const assignDevicesSubmit = async () => {
		setIsAddDeviceModalLoading(true);
		const { hospitalId, departmentId, floorId, roomId } = props.match.params;
		const url = `/v1/organizations/${getCompanyId()}/hospitals/${hospitalId}/departments/${departmentId}/floors/${floorId}/rooms/${roomId}/devices`;
		const response = await gatewayApi.put(url, {
			devices: [+helloDeviceId],
		});
		if (response.data.error) {
			setError(response.data.error.message);
			return;
		}
		loadRoom();
		setIsAddDeviceModalLoading(false);
		setIsDevicesModalOpen(prevState => !prevState);
		fetchTreeData();
	};

	const toggleDeviceSettingsModal = () => {
		setIsDeviceSettingsModalVisible(prevState => !prevState);
	};

	const transformToDeviceList = array => array.map(item => ({ label: item.value, value: item.id }));

	const fetchTreeData = async () => {
		const subTreeResponse = await getRegionSubTree(userSession.healthSystem.id, userSession.regionId);
		if (subTreeResponse.error) {
			setError(subTreeResponse.error.message);
		} else {
			const { healthSystem } = subTreeResponse.organization;
			const tree = buildTree(healthSystem);
			const { online, busy, privacy, pairedRemote } = getHealthSystemDevices(healthSystem);
			dispatch(devicesActionCreators.setBulkDevicesBusy(busy));
			dispatch(devicesActionCreators.setBulkDevicesOnline(online));
			dispatch(devicesActionCreators.setBulkDevicesPrivacy(privacy));
			dispatch(devicesActionCreators.setBulkPairedRemoteDevice(pairedRemote));
			setHealthSystem(healthSystem);
			setTreeData(tree);
		}
	};

	const getSelectedDeviceId = () => {
		let result = {};
		if (helloDeviceId) {
			const label = unassociatedDevicesToSelect.find(item => item.id === helloDeviceId);
			if (label) {
				result = {
					label: label.value,
					value: helloDeviceId,
				};
			}
		}
		return result;
	};

	const exportAsCsv = () => {
		return devices.map(({ serialNumber, macAddress, appVersion, firmwareRevision, osVersion, solHelloDeviceId }) => ({
			serialNumber,
			room: getHierarchyByHelloDeviceId(healthSystems.treeData.tree, solHelloDeviceId),
			macAddress,
			appVersion,
			osVersion: firmwareRevision,
			androidVersion: getCodeNameByOsVersion(osVersion),
		}));
	};

	const getInviteStatus = inviteStatusId => {
		switch (inviteStatusId) {
			case InviteStatuses.SENT.type: {
				return translate('sentOnList');
			}
			case InviteStatuses.ACCEPTED.type: {
				return translate('registeredOnList');
			}
			case InviteStatuses.PENDING.type: {
				return translate('pendingOnList');
			}
			case InviteStatuses.CANCELLED.type: {
				return translate('cancelledOnList');
			}
			case InviteStatuses.FAILED.type: {
				return translate('failedOnList');
			}
			default:
				return '';
		}
	};

	const displayFamilyMembersOrVisitors = list => {
		if (!list || !list.length) {
			return [];
		}
		return list.map(member => ({
			member: member.name || 'N/A',
			email: member.email,
			invitedBy: member.invitedBy,
			invitedDate: monthDayYear(member.inviteDate),
			memberStatus: getInviteStatus(member.memberStatus),
			disableAccess: getEditButtons(member.memberId, member.memberStatus),
		}));
	};

	const getEditButtons = (memberId, memberStatusId) => {
		if (memberStatusId === InviteStatuses.SENT.type) {
			return <></>;
		}
		return (
			<div className='wrapped'>
				&nbsp;
				<i
					className='material-icons-outlined boxed-icon'
					onClick={() => openDeleteMemberModal(memberId)}
					data-position='top'
					data-tooltip={intl.formatMessage({ id: 'delete' })}>
					delete
				</i>
			</div>
		);
	};

	const openDeleteMemberModal = memberId => {
		setCurrentMember(memberId);
		setIsDeleteModalOpen(true);
	};

	const handleDeleteMember = async () => {
		const response = await deleteMemberV1M2(currentMember, props.match.params.roomId);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		setCurrentMember(null);
		setRoomFamilyMembers(prevState => prevState.filter(member => member.memberId !== currentMember));
		setIsDeleteModalOpen(false);
	};

	const handleReversePTZCameraOption = async () => {
		const params = {
			deviceId: currentRoom?.solHelloDeviceId,
			settingTypeId: PTZCameraSettings.REVERSE_CAMERA,
			settingValue: (!isReverseCameraEnabled).toString(),
		};
		const response = await updateDeviceSettings(params);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		setIsReverseCameraEnabled(value => !value);
	};

	const isPatientDisabled = () =>
		getConfigurationValue(companyConfigurations[CompanySettings.PATIENT_VIRTUAL_ADMISSION]) &&
		!getConfigurationValue(companyConfigurations[CompanySettings.PATIENT_MANUAL_ADMISSION]);

	const isPatientAssigned = device => !device?.isVirtualPatientAssigned && !device?.isDefaultOwner;

	const isPatientVirtual = device => device?.isVirtualPatientAssigned;

	const submitSucceed = () => {
		setVisitorType(null);
		fetchTreeData();
		setVisitorsOrFamilyMembersUpdated(prevState => !prevState);
	};

	const getHealthSystemName = () => {
		const foundHealthSystem = healthSystems.allHealthSystems.find(hs => hs.id === userSession.healthSystem.id);
		return foundHealthSystem ? foundHealthSystem.name : '';
	};

	return (
		<div className='users room-wrapper'>
			{!!isRoomLoaded && (
				<>
					<h3>
						<Button
							text={translate('deleteRoom')}
							variant='red'
							imgIcon={`${healthCareCdnUrl}admin/delete-room.svg`}
							onClick={() => {
								setSelectedOrg({ level: DeviceListLevel.ROOM, id: props.match.params.roomId, name: currentRoom.roomName });
								setIsDeleteOrgUnitModalOpen(prevState => !prevState);
							}}
						/>
						<Button
							text={translate('editDetails')}
							variant='yellow'
							onClick={() => openEditSectorModal(currentRoom)}
							imgIcon={`${healthCareCdnUrl}admin/edit.svg`}
						/>
						<Dropdown position='bottom' icon='expand_more' className='organization-dropdown'>
							{UserRoles.SUPER_USER !== getUserRole() && !devices.length && (
								<Button
									text={translate('addDevice')}
									variant='green'
									onClick={() => setIsDevicesModalOpen(prevState => !prevState)}
									imgIcon={`${healthCareCdnUrl}admin/add-device-blue.svg`}
								/>
							)}
							<Button
								variant='white'
								imgIcon={`${healthCareCdnUrl}admin/visiting-hours.svg`}
								onClick={() => setIsVisitingHoursModalOpen(true)}
								text={intl.formatMessage({ id: 'visitingHours' })}
							/>

							{devices.length > 0 && (
								<>
									<div className='csv-link'>
										<CSVLink
											className='button white'
											data-cy='exportAsCSVBtn'
											data={exportAsCsv()}
											filename={`${currentRoom.roomName}-${(+new Date()).toString()}.csv`}>
											{translate('exportAsCSV')}
										</CSVLink>
									</div>
									{!isPatientDisabled() && [UserRoles.ADMIN, UserRoles.SUPER_USER].includes(getUserRole()) && (
										<Button
											text={translate('assignPatient')}
											variant='white'
											onClick={() => {
												setIsAddPatientModalOpen(true);
												setIsAddPatientModalLoading(false);
											}}
											imgIcon={`${healthCareCdnUrl}admin/assign-patient-blue.svg`}
										/>
									)}
									<Button
										text={translate('assignFamilyMember')}
										variant='white'
										onClick={() => {
											setVisitorType(InviteTypes.FAMILY_MEMBER.type);
										}}
										imgIcon={`${healthCareCdnUrl}admin/assign-family-members.svg`}
									/>
									{!isPrimaryCareType && RoomTypes.BABY_ROOM.type !== currentRoom.type && (
										<Button
											text={translate('assignVisitor')}
											variant='white'
											onClick={() => {
												setVisitorType(InviteTypes.VISITOR.type);
											}}
											imgIcon={`${healthCareCdnUrl}admin/assign-visitors.svg`}
										/>
									)}
									<Button
										text={intl.formatMessage({ id: 'dataAcquisitionSchedule' })}
										variant='white'
										onClick={() => {
											setIsDataAcquisitionOpen(prevState => !prevState);
										}}
										imgIcon={`${healthCareCdnUrl}admin/data-acquisition.svg`}
									/>
									{isAlexaPropertiesEnabled && (
										<Button
											variant='white blue-icon'
											text={intl.formatMessage({ id: 'alexaSmartProperties' })}
											size='medium'
											icon='home_max'
											onClick={() => setIsAlexaSmartPropertiesOpen(prev => !prev)}
										/>
									)}
								</>
							)}
							<Button
								text={intl.formatMessage({ id: 'ptzCamSettingsTitle' })}
								variant='white'
								onClick={() => {
									getSettingsOfDevices();
									setIsReversePTZCamModalOpen(true);
								}}
								icon='settings'
							/>
						</Dropdown>
					</h3>
					<div className='full-width top-50'>
						{devices.length > 0 && (
							<button type='button' className='device-settings-btn' onClick={toggleDeviceSettingsModal}>
								<img src={`${healthCareCdnUrl}admin/device-settings.svg`} alt='icon' />
								{translate('deviceSettings')}
							</button>
						)}
					</div>
					<DescriptionBox data={[{ title: translate('enrolledDevices'), description: devices ? devices.length : 0, id: 0 }]} />
					<Tabs activeIndex={currentTab} onChange={index => setCurrentTab(index)}>
						<TabList>
							<Tab>{translate('roomDetails')}</Tab>
							<Tab>{translate('familyMembers')}</Tab>
							{RoomTypes.BABY_ROOM.type !== currentRoom.type && <Tab>{translate('visitors')}</Tab>}
							{RoomTypes.BABY_ROOM.type !== currentRoom.type && <Tab>{translate('visitingHours')}</Tab>}
							{getUserRole() !== UserRoles.SUPER_USER && <Tab>{translate('featureFlags')}</Tab>}
							{devices?.length > 0 && !devices[0].isDefaultOwner && <Tab>{translate('wearables')}</Tab>}
							{getUserRole() !== UserRoles.SUPER_USER && showHandHygiene && <Tab>{translate('handHygieneAudits')}</Tab>}
						</TabList>
						<TabPanels>
							<TabPanel>
								<Table
									headers={[
										{ title: `${intl.formatMessage({ id: 'room' })}`, id: 0 },
										...(isPatientDisabled() || isPatientVirtual(devices[0]) ? [] : [{ title: translate('patient'), id: 1 }]),
										{ title: translate('deviceSerialNumber'), id: 2 },
										{ title: translate('macAddress'), id: 3 },
										{ title: translate('appVersion'), id: 4 },
										{ title: translate('androidVersion'), id: 5 },
										{ title: translate('osVersion'), id: 6 },
										{ title: translate('ipAddress'), id: 7 },
										{ title: 'SSID', id: 8 },
										{ title: translate('connectionType'), id: 9 },
										{ title: translate('voiceCommandCalling'), id: 10 },
										{ title: translate('actions'), id: 11 },
									]}
									rows={displayDevices()}
									className='admin-table'
									isEditable={false}
								/>
							</TabPanel>
							<TabPanel>
								<Table
									headers={[
										{ title: translate('name'), id: 0 },
										{ title: translate('email'), id: 1 },
										{ title: translate('invitedBy'), id: 2 },
										{ title: translate('dateSent'), id: 3 },
										{ title: translate('memberStatus'), id: 4 },
										{ title: translate('disableAccess'), id: 5 },
									]}
									rows={displayFamilyMembersOrVisitors(roomFamilyMembers)}
									className='admin-table'
									isEditable={false}
								/>
							</TabPanel>
							{RoomTypes.BABY_ROOM.type !== currentRoom.type && (
								<TabPanel>
									<Table
										headers={[
											{ title: translate('name'), id: 0 },
											{ title: translate('email'), id: 1 },
											{ title: translate('invitedBy'), id: 2 },
											{ title: translate('dateSent'), id: 3 },
											{ title: translate('memberStatus'), id: 4 },
											{ title: translate('disableAccess'), id: 5 },
										]}
										rows={displayFamilyMembersOrVisitors(roomVisitors)}
										className='admin-table'
										isEditable={false}
									/>
								</TabPanel>
							)}
							{RoomTypes.BABY_ROOM.type !== currentRoom.type && (
								<TabPanel>
									<Table
										headers={[
											{ title: translate('monday'), id: 0 },
											{ title: translate('tuesday'), id: 1 },
											{ title: translate('wednesday'), id: 2 },
											{ title: translate('thursday'), id: 3 },
											{ title: translate('friday'), id: 4 },
											{ title: translate('saturday'), id: 5 },
											{ title: translate('sunday'), id: 6 },
										]}
										rows={visitingHours}
										className='admin-table'
										isEditable={false}
									/>
								</TabPanel>
							)}
							{getUserRole() !== UserRoles.SUPER_USER && (
								<TabPanel>
									<MonitoringAiFeatureFlags
										healthSystemId={userSession.healthSystem.id}
										levelId={props.match.params.roomId}
										currentSector={DeviceListLevel.ROOM}
										setError={setError}
										setShowHandHygiene={setShowHandHygiene}
									/>
								</TabPanel>
							)}
							{devices?.length > 0 && !devices[0].isDefaultOwner && (
								<TabPanel>
									<Wearables currentSector={currentRoom} ownerId={devices[0].ownerUserId} helloDeviceId={devices[0].id} />
								</TabPanel>
							)}
							{getUserRole() !== UserRoles.SUPER_USER && showHandHygiene && (
								<TabPanel>
									<HandHygieneAudit currentSector={currentRoom} />
								</TabPanel>
							)}
						</TabPanels>
					</Tabs>
				</>
			)}
			{!isRoomLoaded && <SkeletonLoader rows={10} padding='20px 0' />}
			{isRoomModalOpen && (
				<EditSectorModal
					onEditSector={() => {
						fetchTreeData();
						fetchRoomFamilyMembers();
						setIsRoomModalOpen(false);
					}}
					onModalClose={() => setIsRoomModalOpen(false)}
					sectorData={sectorData}
				/>
			)}
			<Modal
				display={isDeleteModalOpen}
				position='center'
				onModalSubmit={handleDeleteMember}
				primaryButtonLabel={translate('delete')}
				className='warning-delete-member'
				onModalClose={() => setIsDeleteModalOpen(false)}>
				<form>
					<h3>{translate('warning')}</h3>
					<p>{translate('areYouSureDeleteMember')}</p>
				</form>
			</Modal>
			<Modal
				display={isReversePTZCamModalOpen}
				isLoading={isReversePTZCamModalLoading}
				position='center'
				className='border-radius-modal-wrapper configurable-settings-modal ptz-cam-settings'
				onModalClose={() => setIsReversePTZCamModalOpen(false)}>
				<div className='configurable-settings-modal-inner'>
					<h3 className='configurable-settings-main-title'>{translate('ptzCamSettingsTitle')}</h3>
					<div className='flex flex-wrap'>
						<div className='flex cursor-pointer' onClick={handleReversePTZCameraOption}>
							<i className='material-icons-outlined'>switch_video</i>
							<p>{translate('reverseCamera')}</p>
							<div className='rounded-slider-switch'>
								<input type='checkbox' checked={isReverseCameraEnabled} onChange={() => null} />
								<span className='rounded-slider' />
							</div>
						</div>
					</div>
				</div>
			</Modal>
			<Modal
				isLoading={isAddDeviceModalLoading}
				display={isDevicesModalOpen}
				position='center'
				onModalClose={() => setIsDevicesModalOpen(prevState => !prevState)}
				onModalSubmit={assignDevicesSubmit}
				isSubmitDisabled={isAddDeviceModalSubmitDisabled}>
				<Form title={translate('roomDevice')}>
					<p className='label'>{translate('assignDevice')}</p>
					<p>{translate('selectSerialNumberAssociateWithRoom')}</p>
					<ReactSelect
						value={getSelectedDeviceId()}
						onChange={event => {
							setHelloDeviceId(event.value);
							setIsAddDeviceModalSubmitDisabled(false);
						}}
						options={transformToDeviceList(unassociatedDevicesToSelect)}
						placeholder={intl.formatMessage({ id: 'selectSerialNumber' })}
						classNamePrefix='react-select'
					/>
					{preSelected && isNewExperience && (
						<TreeViewMayo data={healthSystems.currentSectorLocation} preSelected={preSelected} />
					)}
					{preSelected && !isNewExperience && <TreeView data={healthSystems.currentSectorLocation} preSelected={preSelected} />}
				</Form>
			</Modal>
			{!isAddPatientModalLoading && [UserRoles.ADMIN, UserRoles.SUPER_USER].includes(getUserRole()) && (
				<AssignPatient
					devices={devices}
					setDevices={setDevices}
					toggleAddPatientModal={() => {
						setIsAddPatientModalOpen(prevState => !prevState);
						setIsAddPatientModalLoading(false);
					}}
					loadRoom={() => {
						fetchTreeData();
						fetchRoomFamilyMembers();
						loadRoom();
					}}
					isAddPatientModalOpen={isAddPatientModalOpen}
					isLoading={false}
					isAddPatientModalLoading={isAddPatientModalLoading}
					setError={setError}
					roomType={currentRoom.type}
					healthSystemId={userSession.healthSystem?.id}
				/>
			)}
			{visitorType && (
				<AssignFamilyMemberOrVisitor
					devices={devices}
					setDevices={setDevices}
					toggleAddFamilyMemberModal={() => setVisitorType(null)}
					isLoading={false}
					sectorData={currentRoom}
					setError={setError}
					submitSucceed={submitSucceed}
					visitorType={visitorType}
					getHealthSystemName={getHealthSystemName}
				/>
			)}
			<Modal
				modalSelector='deleteRoomModal'
				display={isDeleteOrgUnitModalOpen}
				position='center'
				primaryButtonLabel={translate('delete')}
				onModalSubmit={deleteOrgUnitSubmit}
				onModalClose={() => {
					setIsDeleteOrgUnitModalOpen(prevState => !prevState);
					setIsAddPatientModalLoading(false);
				}}>
				<Form title={translate('warning')} onSubmit={event => event.preventDefault()}>
					<p className='paragraph-break-word'>
						{translate('sureToDeleteOrg', {
							value: `${intl.formatMessage({ id: getLevelDescriptionById(selectedOrg.level) })} ${selectedOrg.name}`,
						})}
					</p>
				</Form>
			</Modal>
			{isDeviceSettingsModalVisible && (
				<DeviceSettings
					isDeviceSettingsModalVisible={isDeviceSettingsModalVisible}
					toggleDeviceSettingsModal={toggleDeviceSettingsModal}
					selectedDevice={devices[0]}
					currentRoom={currentRoom}
				/>
			)}
			{isDataAcquisitionOpen && (
				<ScheduleDataAcquisition
					isSetUpAvailabilityModalVisible={isDataAcquisitionOpen}
					toggleSetUpAvailability={() => setIsDataAcquisitionOpen(prevState => !prevState)}
					roomId={props.match.params.roomId}
					helloDeviceId={currentRoom?.solHelloDeviceId}
				/>
			)}
			<Alert display={error !== null} message={error} variant='dark' fixed={true} onClose={() => setError(null)} />
			{isVisitingHoursModalOpen && (
				<VisitingHoursConfiguration setIsVisitingHoursModalOpen={setIsVisitingHoursModalOpen} sector={currentRoom} />
			)}
			{isAlexaSmartPropertiesOpen && (
				<AlexaSmartPropertiesWrapper
					sector={currentRoom}
					setIsAlexaSmartPropertiesOpen={setIsAlexaSmartPropertiesOpen}
					isAlexaSmartPropertiesOpen={isAlexaSmartPropertiesOpen}
				/>
			)}
		</div>
	);
};

export default Room;
