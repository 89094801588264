import React, { useContext, useEffect, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import OverviewDataBox from 'components/HealthMeasurements/OverviewDataBox';
import { MeasurementTypes, UnitCategoryTypes, UserRoles } from 'constants/enums.js';
import { getUserMeasurementUnits } from 'api/measurements.js';
import { convertMeasurementTypes } from 'infrastructure/helpers/measurementsHelper.js';
import { cameraMeasurementsToShow } from 'constants/measurements.js';
import { getUserId, getUserRole } from 'infrastructure/auth.js';
import Alert from 'components/Alert.jsx';
import SocketEvents from 'constants/socket-events.js';
import { ControlsActions } from 'calls/enums/index.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import { useConference, useConferenceConfigurations } from 'calls/hooks/index.js';
import LocalParticipant from 'calls/LocalParticipant.js';

/**
 * @param {object} props
 * @param {import('calls/LocalParticipant.js').default | import('calls/RemoteParticipant.js').default} props.patient
 */
const CameraMeasurements = ({ patient }) => {
	const socket = useContext(SocketContext);
	const conference = useConference();
	const conferenceConfigurations = useConferenceConfigurations();
	const [analyses, setAnalyses] = useState({
		[MeasurementTypes.HEART_RATE]: {
			value: 0,
			startDate: null,
		},
		[MeasurementTypes.BLOOD_PRESSURE]: {
			value: 0,
			startDate: null,
		},
		[MeasurementTypes.STRESS_LEVEL]: {
			value: '--',
			startDate: null,
		},
		[MeasurementTypes.BLOOD_GLUCOSE]: {
			value: 0,
			startDate: null,
			unitCategoryId: UnitCategoryTypes.BLOOD_GLUCOSE,
		},
		[MeasurementTypes.RESPIRATORY_RATE]: {
			value: 0,
			startDate: null,
		},
		[MeasurementTypes.OXYGEN]: {
			value: 0,
			startDate: null,
		},
		[MeasurementTypes.HRV_INDEX]: {
			value: 0,
			startDate: null,
		},
		[MeasurementTypes.BILIRUBIN]: {
			value: 0,
			startDate: null,
		},
	});
	const [isLoading, setIsLoading] = useState(true);
	const [unitPreferences, setUnitPreferences] = useState([]);
	const [error, setError] = useState('');
	const { current: userId } = useRef(getUserId());
	const {
		isDiagnosesVisible,
		isNotesVisible,
		isPrescriptionsVisible,
		isProceduresVisible,
		isMedicalFormsVisible,
		isCareEventsFormVisible,
	} = conferenceConfigurations.medicalDataControls;
	const isMedicalInfoActive =
		isDiagnosesVisible ||
		isNotesVisible ||
		isPrescriptionsVisible ||
		isProceduresVisible ||
		isMedicalFormsVisible ||
		isCareEventsFormVisible;

	const { current: userRole } = useRef(getUserRole());

	useEffect(() => {
		socket.on(SocketEvents.Conference.ON_CAMERA_MEASUREMENTS, cameraMeasurementsListener);
		return () => {
			socket.off(SocketEvents.Conference.ON_CAMERA_MEASUREMENTS, cameraMeasurementsListener);
		};
	}, []);

	useEffect(() => {
		fetchMeasurements();
	}, [patient]);

	const fetchMeasurements = async () => {
		if (
			![UserRoles.DOCTOR, UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN].includes(userRole) ||
			patient instanceof LocalParticipant
		) {
			return;
		}
		const response = await getUserMeasurementUnits(userId);
		if (response.error) {
			setError(response.error.message);
		} else {
			setUnitPreferences(response.unitPreferences);
		}
		setIsLoading(false);
	};

	const getCategoryPreference = categoryId => unitPreferences.find(item => item.unitCategoryId === categoryId);

	const getUnitPreference = categoryId => {
		const selectedPreference = getCategoryPreference(categoryId);
		return selectedPreference?.options.find(item => item.unitSystemId === selectedPreference.unitSystemId);
	};

	const getMeasurementValue = measurement => {
		return !measurement.unitCategoryId
			? measurement.value
			: convertMeasurementTypes(
					measurement.unitCategoryId,
					measurement.value,
					getUnitPreference(measurement.unitCategoryId)?.unitSystemId
			  );
	};

	const cameraMeasurementsListener = data => {
		// Show data only if it belongs to the current conference.
		if (conference.conferenceId === data.conferenceId) {
			const newAnalyses = { ...analyses };
			const { measurementType, measurementValue, startDate, unitCategoryId } = data;
			if (newAnalyses[measurementType]) {
				newAnalyses[measurementType].value = convertMeasurementTypes(
					unitCategoryId,
					measurementValue,
					getUnitPreference(unitCategoryId)?.unitSystemId
				);
				newAnalyses[measurementType].startDate = startDate;
				// static atm
				newAnalyses[MeasurementTypes.BILIRUBIN].startDate = startDate;
				newAnalyses[MeasurementTypes.BILIRUBIN].value = 115;
				newAnalyses[MeasurementTypes.BLOOD_GLUCOSE].startDate = startDate;
				newAnalyses[MeasurementTypes.BLOOD_GLUCOSE].value = 126;
				setAnalyses(newAnalyses);
				conferenceConfigurations.onHealthDataToggleAction({
					[ControlsActions.TOGGLE_CAMERA_MEASUREMENTS]: true,
					[ControlsActions.TOGGLE_HEALTH_MEASUREMENTS]: false,
					[ControlsActions.TOGGLE_LIVE_EXAMINATIONS]: false,
					[ControlsActions.TOGGLE_PATIENT_HISTORY]: false,
				});
			}
		}
	};

	return (
		<div
			className={classNames(
				!conferenceConfigurations.isGridView && conferenceConfigurations.isCameraMeasurementsVisible ? '' : 'hidden',
				conferenceConfigurations.medicalDataControls.isConversationModalVisible || isMedicalInfoActive
					? 'conversation-modal-visible'
					: ''
			)}>
			{!isLoading && (
				<>
					<aside className='left-side right-side-measurements-show'>
						{cameraMeasurementsToShow.map(
							item =>
								item.id <= 3 && (
									<OverviewDataBox
										title={item.title}
										measurement={item}
										measurementUnit={
											analyses[item.type].unitCategoryId ? getUnitPreference(analyses[item.type].unitCategoryId)?.unit : item.unit
										}
										measurementValue={getMeasurementValue(analyses[item.type])}
										startDate={analyses[item.type].startDate}
										key={item.type}
									/>
								)
						)}
					</aside>
					<aside className='right-side right-side-measurements-show'>
						{cameraMeasurementsToShow.map(
							item =>
								item.id > 3 && (
									<OverviewDataBox
										title={item.title}
										measurement={item}
										measurementUnit={
											analyses[item.type].unitCategoryId ? getUnitPreference(analyses[item.type].unitCategoryId)?.unit : item.unit
										}
										measurementValue={getMeasurementValue(analyses[item.type])}
										startDate={analyses[item.type].startDate}
										key={item.type}
									/>
								)
						)}
					</aside>
				</>
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</div>
	);
};

export default injectIntl(CameraMeasurements);
