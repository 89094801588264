import React, { useState } from 'react';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from 'components/Tabs.jsx';
import AiAlerts from 'containers/AiAlerts.jsx';
import MeasurementAlerts from 'containers/MeasurementAlerts.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import ManualAlerts from 'components/ManualAlerts.jsx';
import CareEvents from 'components/CareEvents.jsx';
import CareEventsIcon from 'calls/icons/CareEventsIcon.jsx';
import LightTheme from 'calls/styles/LightTheme.js';

const TabsContent = props => {
	const [currentTab, setCurrentTab] = useState(props.defaultTab || 0);
	const tabList = [
		{
			id: 0,
			text: translate('healthData'),
			img: `${healthCareCdnUrl}alert-history/Health${currentTab === 0 ? '-1' : ''}.svg`,
		},
		{
			id: 1,
			text: translate('artificialIntelligence'),
			img: `${healthCareCdnUrl}alert-history/AIControls${currentTab === 1 ? '-1' : ''}.svg`,
		},
		{
			id: 2,
			text: translate('manualAlerts'),
			img: `${healthCareCdnUrl}alert-history/Health${currentTab === 2 ? '-1' : ''}.svg`,
		},
		{
			id: 3,
			text: translate('careEvents'),
			img: null,
			icon: (
				<CareEventsIcon
					color={currentTab === 3 ? LightTheme.colors.blueFour : LightTheme.colors.grayFour}
					width={20}
					height={20}
				/>
			),
		},
	];
	return (
		<div className='patient-summary-tabs-wrapper alert-history-tabs-wrapper'>
			<Tabs isVertical={true} activeIndex={currentTab} onChange={index => setCurrentTab(index)}>
				<TabList>
					{tabList.map(tab => (
						<Tab key={tab.id}>
							{tab.img && <img src={tab.img} alt='icon' />}
							{tab.icon && <span style={{ marginRight: '8px' }}>{tab.icon}</span>}
							{tab.text}
						</Tab>
					))}
				</TabList>
				<TabPanels>
					<TabPanel>
						<MeasurementAlerts patientId={props.patientId} isCallView={props.isCallView} />
					</TabPanel>
					<TabPanel>
						<AiAlerts patientId={props.patientId} />
					</TabPanel>
					<TabPanel>
						<ManualAlerts patientId={props.patientId} />
					</TabPanel>
					<TabPanel>
						<CareEvents patientId={props.patientId} />
					</TabPanel>
				</TabPanels>
			</Tabs>
		</div>
	);
};

const AlertHistory = props => (
	<>
		{!props.patientId && !props.isCallView && (
			<MainLayout>
				<TabsContent patientId={props.patientId} defaultTab={props.defaultTab} />
			</MainLayout>
		)}
		{(props.patientId || props.isCallView) && (
			<TabsContent patientId={props.patientId} defaultTab={props.defaultTab} isCallView={props.isCallView} />
		)}
	</>
);

export default AlertHistory;
