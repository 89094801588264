import { updatePatientsAiSettings } from 'api/patients.js';
import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';
import classNames from 'classnames';
import { PatientsAiSettings } from 'constants/enums.js';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import SoundOnAiAlertIcon from 'icons/Monitoring/SoundOnAiAlertIcon.jsx';
import AiDrawingIcon from 'components/AiDrawingIcon.jsx';
import Alert from 'components/Alert.jsx';
import translate from 'i18n-translations/translate.jsx';
import { Grid, Loader } from 'components/index.js';
import { getConfigurationValue } from 'infrastructure/helpers/commonHelpers.js';
import { MonitoringSettings } from 'constants/configurationEnums.js';

const AiGeneralSettings = ({ patientId, deviceId, isDarkMode, roomId, adminAiSettingsConfigurations }) => {
	const aiSettingList = useSelector(state => state.aiSettingsList.aiSettings);
	const isNewExperience = useSelector(state => state.configurations.isNewExperience);
	const [isAiDrawing, setIsAiDrawing] = useState(false);
	const [isSoundOnFeed, setIsSoundOnFeed] = useState(false);
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const intl = useIntl();

	const getAiSetting = (res, settingTypeId) => res.find(item => item.settingTypeId === settingTypeId)?.value === 'true';

	useEffect(() => {
		const getPatientAiSettings = async () => {
			const settings = _.cloneDeep(aiSettingList);
			const found = settings.find(item => item.deviceId === deviceId);
			if (!found) {
				setIsLoading(false);
				return;
			}
			setIsAiDrawing(getAiSetting(found.settings, PatientsAiSettings.AI_DRAWING));
			setIsSoundOnFeed(getAiSetting(found.settings, PatientsAiSettings.SOUND_ON_AI_ALERT));
			setIsLoading(false);
		};
		getPatientAiSettings();
	}, [patientId, deviceId]);

	const handleAiCheckboxChange = (settingTypeId, value = false) => {
		if (settingTypeId === PatientsAiSettings.AI_DRAWING) {
			setIsAiDrawing(value);
		}
		if (settingTypeId === PatientsAiSettings.SOUND_ON_AI_ALERT) {
			setIsSoundOnFeed(value);
		}
		updatePatientAiSetting(settingTypeId, value?.toString());
	};

	const updatePatientAiSetting = async (settingTypeId, value) => {
		const dataToSend = [
			{
				settingTypeId,
				value,
			},
		];

		const params = {
			patientId,
			deviceId,
			roomId,
			sendData: dataToSend,
		};

		const response = await updatePatientsAiSettings(params);
		if (response.error) {
			setError(response.error.message);
			return;
		}
	};

	const aiTypesToShow = [
		{
			aiTypeId: PatientsAiSettings.AI_DRAWING,
			label: intl.formatMessage({ id: 'aIDetectionDrawings' }),
			img: <AiDrawingIcon color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
			subTitle: intl.formatMessage({ id: 'general' }),
			noBorder: true,
			show: getConfigurationValue(adminAiSettingsConfigurations[MonitoringSettings.AiDetectionDrawing]),
		},
		{
			aiTypeId: PatientsAiSettings.SOUND_ON_AI_ALERT,
			label: intl.formatMessage({ id: 'soundOnAiAlert' }),
			img: <SoundOnAiAlertIcon color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
			noBorder: true,
			show: getConfigurationValue(adminAiSettingsConfigurations[MonitoringSettings.SoundOnAiAlert]),
		},
	];

	const getAiTypes = () => {
		const settings = _.cloneDeep(aiSettingList);
		const found = settings.find(item => item.deviceId === deviceId);
		if (!found) {
			return [];
		}
		return found.settings;
	};

	const getAiTypeValue = aiType => getAiTypes().find(item => item.settingTypeId === aiType)?.value;

	const isOptionChecked = aiTypeId => {
		return !['false', undefined, null].includes(getAiTypeValue(aiTypeId));
	};

	return (
		<div className='ai-alerts-wrapper'>
			{isLoading && (
				<Grid columns='1fr' rows='1fr' stretch='250px' horizAlign='center' vertAlign='center'>
					<div style={{ textAlign: 'center' }}>
						<Loader />
					</div>
				</Grid>
			)}
			{!isLoading &&
				aiTypesToShow.map(
					aiType =>
						aiType.show && (
							<div
								className={classNames(
									'flex flex-space-between alert-settings',
									aiType.subTitle || aiType.aiTypeId === PatientsAiSettings.AI_DRAWING ? 'flex-wrap' : '',
									aiType.noBorder ? 'no-border-bottom' : '',
									aiType.isTitle ? 'title-alert-setting' : ''
								)}
								key={aiType.aiTypeId}>
								{aiType.subTitle && <span>{aiType.subTitle}</span>}
								<div className='flex flex-justify-center flex-align-center'>
									{aiType.img}
									<label className='left-s right-s'>{aiType.label}</label>
								</div>
								<div className='flex flex-align-center'>
									{isNewExperience && (
										<label className='switch-label'>
											<div className='toggle-switch toggle-blue'>
												<input
													type={isOptionChecked(aiType.aiTypeId) ? 'checkbox' : ''}
													checked={isOptionChecked(aiType.aiTypeId)}
													onClick={() =>
														handleAiCheckboxChange(
															aiType.aiTypeId,
															aiType.aiTypeId === PatientsAiSettings.AI_DRAWING ? !isAiDrawing : !isSoundOnFeed
														)
													}
												/>
												<span className='toggle-body'>
													<span className='on-text'>{translate('on')}</span>
													<span className='off-text'>{translate('off')}</span>
												</span>
											</div>
										</label>
									)}
									{!isNewExperience && (
										<label className='switch-label'>
											<input
												type={isOptionChecked(aiType.aiTypeId) ? 'checkbox' : ''}
												checked={isOptionChecked(aiType.aiTypeId)}
												onClick={() =>
													handleAiCheckboxChange(
														aiType.aiTypeId,
														aiType.aiTypeId === PatientsAiSettings.AI_DRAWING ? !isAiDrawing : !isSoundOnFeed
													)
												}
											/>
											<span className='slider-input-alert round' />
										</label>
									)}
								</div>
							</div>
						)
				)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</div>
	);
};

export default AiGeneralSettings;
