/* eslint-disable no-console */
import moment from 'moment-timezone';
import { RecurrenceType } from 'constants/enums.js';

class GoogleCalendarService {
	constructor() {
		this.gapi = null;
		if (process.env.NODE_ENV === 'development') {
			this.config = {
				clientId: process.env.REACT_APP_GOOGLEAPI_CLIENT_ID,
				apiKey: process.env.REACT_APP_GOOGLEAPI_API_KEY,
				scope: process.env.REACT_APP_GOOGLEAPI_SCOPE,
				discoveryDocs: [process.env.REACT_APP_GOOGLEAPI_DISCOVERYDOCS],
			};
		} else {
			this.config = {
				clientId: window.__env__.REACT_APP_GOOGLEAPI_CLIENT_ID,
				apiKey: window.__env__.REACT_APP_GOOGLEAPI_API_KEY,
				scope: window.__env__.REACT_APP_GOOGLEAPI_SCOPE,
				discoveryDocs: [window.__env__.REACT_APP_GOOGLEAPI_DISCOVERYDOCS],
			};
		}
		this.isSignedIn = false;
		this.onLoadCallback = null;
		this.calendar = 'primary';
		try {
			this.updateSigninStatus = this.updateSigninStatus.bind(this);
			this.initClient = this.initClient.bind(this);
			this.handleSignoutClick = this.handleSignoutClick.bind(this);
			this.handleAuthClick = this.handleAuthClick.bind(this);
			this.createEvent = this.createEvent.bind(this);
			this.listUpcomingEvents = this.listUpcomingEvents.bind(this);
			this.listenSign = this.listenSign.bind(this);
			this.onLoad = this.onLoad.bind(this);
			this.setCalendar = this.setCalendar.bind(this);
			this.getSignedInUserData = this.getSignedInUserData.bind(this);
			this.handleClientLoad();
		} catch (e) {
			console.log(e);
		}
	}

	/**
	 * Update connection status.
	 * @param {boolean} isSignedIn
	 */
	updateSigninStatus(isSignedIn) {
		this.isSignedIn = isSignedIn;
	}

	/**
	 * Auth to the google Api.
	 */
	initClient() {
		this.gapi = window.gapi;
		this.gapi.client
			.init(this.config)
			.then(() => {
				// Listen for sign-in state changes.
				this.gapi.auth2.getAuthInstance().isSignedIn.listen(this.updateSigninStatus);
				// Handle the initial sign-in state.
				this.updateSigninStatus(this.gapi.auth2.getAuthInstance().isSignedIn.get());
				if (this.onLoadCallback) {
					this.onLoadCallback();
				}
			})
			.catch(e => {
				// eslint-disable-next-line no-console
				console.log(e);
			});
	}

	getSignedInUserData() {
		return this.gapi.auth2.getAuthInstance().currentUser.ee.Hs;
	}

	/**
	 * Init Google Api
	 * And create gapi in global
	 */
	handleClientLoad() {
		this.gapi = window.gapi;
		const script = document.createElement('script');
		script.src = 'https://apis.google.com/js/api.js';
		document.body.appendChild(script);
		script.onload = () => {
			window.gapi.load('client:auth2', this.initClient);
		};
	}

	/**
	 * Sign in Google user account
	 */
	async handleAuthClick() {
		if (this.gapi && !this.isSignedIn) {
			await this.gapi.auth2.getAuthInstance().signIn();
		}
	}

	/**
	 * Set the default attribute calendar
	 * @param {string} newCalendar
	 */
	setCalendar(newCalendar) {
		this.calendar = newCalendar;
	}

	/**
	 * Execute the callback function when a user is disconnected or connected with the sign status.
	 * @param callback
	 */
	listenSign(callback) {
		if (this.gapi) {
			this.gapi.auth2.getAuthInstance().isSignedIn.listen(callback);
		}
	}

	/**
	 * Execute the callback function when gapi is loaded
	 * @param callback
	 */
	onLoad(callback) {
		if (this.gapi) {
			callback();
		} else {
			this.onLoadCallback = callback;
		}
	}

	/**
	 * Resets the onLoadCallback to null
	 */
	removeOnLoadCallback() {
		this.onLoadCallback = null;
	}

	/**
	 * Sign out user google account
	 */
	handleSignoutClick() {
		if (this.gapi) {
			this.gapi.auth2.getAuthInstance().signOut();
		}
	}
	/**
	 * List all events in the calendar
	 * @param {number} maxResults to see
	 * @param {string} calendarId to see by default use the calendar attribute
	 * @returns {Promise<>}
	 */

	async listUpcomingEvents(
		maxResults = null,
		nextPageToken = null,
		timeMin = new Date().toISOString(),
		calendarId = this.calendar
	) {
		let returnVal = null;
		if (this.gapi) {
			const config = {
				calendarId: calendarId,
				timeMin: timeMin,
				showDeleted: false,
				singleEvents: true,
				maxResults: maxResults,
				orderBy: 'startTime',
			};
			if (nextPageToken !== null) {
				config.pageToken = nextPageToken;
			}
			returnVal = await this.gapi.client.calendar.events.list(config);
		}
		return returnVal;
	}

	/**
	 * Create Calendar event
	 * @param {string} calendarId for the event.
	 * @param {object} event with start and end dateTime
	 * @returns {any}
	 */
	createEvent(event, calendarId = this.calendar) {
		try {
			const timeZone = moment.tz.guess();
			let recurrence = null;
			if (event.hasRecurrence) {
				if (event.recurrence === RecurrenceType.WORKDAYS) recurrence = 'RRULE:FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR';
				else recurrence = `RRULE:FREQ=${event.recurrence}`;

				recurrence = `${recurrence};UNTIL=${moment(event.recurrenceEndDate)
					.add(1, 'days')
					.format('YYYYMMDD')}`;
			}

			const googleEvent = {
				summary: event.topic,
				description: event.description,
				start: {
					dateTime: event.startDateTime,
					timeZone: timeZone,
				},
				end: {
					dateTime: event.endDateTime,
					timeZone: timeZone,
				},
				recurrence: [recurrence],
				attendees: [
					{
						email: event.invitedMemberEmail,
						displayName: event.invitedMemberEmail,
						optional: false,
					},
				],
			};

			return this.gapi.client.calendar.events.insert({
				calendarId: calendarId,
				resource: googleEvent,
				sendUpdates: 'all',
			});
		} catch (ex) {
			throw new Error(`Error: ${ex.message}`);
		}
	}
}

export default new GoogleCalendarService();
