import React, { useEffect, useState, forwardRef, useRef } from 'react';
import styled from 'styled-components';
import { enums } from '@solaborate/calls';

import { Mic } from '@solaborate/calls/webrtc';
import { isIOS } from 'react-device-detect';
import LocalParticipant from 'calls/LocalParticipant.js';
import {
	Avatar,
	ParticipantAudio,
	ParticipantVideo,
	Loader,
	PrivacyNotification,
	Modal,
	Button,
} from 'calls/components/index.js';
import {
	useConference,
	useControllerTracks,
	useHelloDeviceState,
	useConferenceConfigurations,
	useScreenType,
} from 'calls/hooks/index.js';
import RemoteHelloParticipant from 'calls/RemoteHelloParticipant.js';
import LightTheme from 'calls/styles/LightTheme.js';

import { buildProfilePic } from 'infrastructure/helpers/thumbnailHelper.js';
import translate from 'i18n-translations/translate.jsx';
import { getUserRole } from 'infrastructure/auth.js';
import SpeechToTextOverlay from 'SpeechToTextOverlay.jsx';
import { CameraTypes, UserRoles } from 'calls/enums/index.js';
import { ButtonType } from 'constants/enums.js';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

const StyledLoadingMainParticipant = styled.div`
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;

	p {
		color: var(--gray-0);
	}
`;

const FullScreenButton = styled.button`
	background: white;
	padding: 0;
	line-height: 0;
	position: fixed;
	top: ${LightTheme.spacing[2]}px;
	left: ${LightTheme.spacing[2]}px;
	z-index: 10;
`;

const LimitedVisitorSideView = styled.div`
	position: absolute;
	z-index: 1;
	top: 0;
	height: 100%;
	width: 20%;
	left: ${props => (props.position === 'left' ? '0' : 'auto')};
	right: ${props => (props.position === 'right' ? '0' : 'auto')};
	background: ${LightTheme.colors.graySix};

	@media (max-width: 768px) {
		display: none;
	}
`;

const DragToZoomVideo = forwardRef(
	/**
	 * @param {object} props
	 * @param {HTMLElement} props.mainRefElement
	 * @param {LocalParticipant | import('calls/RemoteParticipant.js').default} props.participant Local or remote participant
	 * @param {React.Dispatch<React.SetStateAction<boolean>>} props.setIsVideoLessThanAspectRatio
	 * @param {import('@solaborate/calls/webrtc').Cam | import('@solaborate/calls/webrtc').ScreenShare} props.activeTrackType Main participant active track type {VIDEO | SCREEN}
	 * @param {React.RefObject<HTMLVideoElement>} ref
	 */
	(
		{ mainRefElement, participant, activeTrackType, setIsVideoLessThanAspectRatio },
		/** @type {React.RefObject<HTMLVideoElement>} */
		ref
	) => {
		const tracks = useControllerTracks(
			participant instanceof LocalParticipant ? participant.localTrackController : participant.remoteTrackController
		);
		const deviceState = useHelloDeviceState(participant);

		const [isDrawing, setIsDrawing] = useState(false);
		const [coordinates, setCoordinates] = useState({
			x1: 0,
			x2: 0,
			y1: 0,
			y2: 0,
		});

		const selectedCamera = deviceState?.mediaDevices?.find(
			mediaDevice => mediaDevice.type === enums.MediaTypes.CAMERA && mediaDevice.isActive
		);
		const conferenceConfigs = useConferenceConfigurations();

		useEffect(() => {
			const handleResize = () => {
				const result = mainRefElement?.clientWidth / window.innerHeight < 16 / 9;
				setIsVideoLessThanAspectRatio(result);
			};

			window.addEventListener('resize', handleResize);

			return () => window.removeEventListener('resize', handleResize);
		}, [mainRefElement?.clientWidth]);

		useEffect(() => {
			const result = mainRefElement?.clientWidth / window.innerHeight < 16 / 9;

			setIsVideoLessThanAspectRatio(result);
		}, [
			conferenceConfigs.isLiveExaminationOpen,
			conferenceConfigs.isPatientHistoryOpen,
			conferenceConfigs.isTelemetryModalOpen,
			conferenceConfigs.medicalDataControls,
			mainRefElement?.clientWidth,
		]);

		const getDifferenceVideoAspectRatio = () => {
			const aspectRatio = 16 / 9;
			const containerWidth = ref.current?.clientWidth;
			const containerHeight = ref.current?.clientHeight;
			const originalWidth = containerHeight * aspectRatio;
			const widthDifference = originalWidth - containerWidth;
			const originalHeight = containerWidth / aspectRatio;
			const heightDifference = originalHeight - containerHeight;
			return {
				widthDifference,
				heightDifference,
			};
		};

		const handleMouseDown = e => {
			const container = e.currentTarget;
			const offsetX = e.clientX - container.offsetLeft;
			const offsetY = e.clientY - container.offsetTop;

			setIsDrawing(true);
			setCoordinates({
				x1: offsetX,
				x2: offsetX,
				y1: offsetY,
				y2: offsetY,
			});
		};

		const handleMouseMove = e => {
			if (!isDrawing) return;

			const container = e.currentTarget;
			const offsetX = e.clientX - container.offsetLeft;
			const offsetY = e.clientY - container.offsetTop;

			setCoordinates(prevState => ({
				...prevState,
				x2: offsetX,
				y2: offsetY,
			}));
		};

		const handleMouseUp = () => {
			setIsDrawing(false);

			if (
				!ref ||
				!shouldAllowDragOrClickToMove ||
				coordinates.x1.toFixed(2) === coordinates.x2.toFixed(2) ||
				coordinates.y1.toFixed(2) === coordinates.y2.toFixed(2)
			) {
				return;
			}

			let { x1, x2, y1, y2 } = coordinates;

			const container = ref.current;
			const containerRect = container?.getBoundingClientRect();

			const containerWidth = containerRect.width;
			const containerHeight = containerRect.height;

			let width = containerWidth;
			let height = containerHeight;

			if (!conferenceConfigs.isFitScreen) {
				if (getDifferenceVideoAspectRatio().widthDifference > 0) {
					x1 += getDifferenceVideoAspectRatio().widthDifference / 2;
					x2 += getDifferenceVideoAspectRatio().widthDifference / 2;
					width = containerWidth + getDifferenceVideoAspectRatio().widthDifference;
				}
				if (getDifferenceVideoAspectRatio().widthDifference <= 0 && getDifferenceVideoAspectRatio().heightDifference > 0) {
					y1 += getDifferenceVideoAspectRatio().heightDifference / 2;
					y2 += getDifferenceVideoAspectRatio().heightDifference / 2;
					height = containerHeight + getDifferenceVideoAspectRatio().heightDifference;
				}
			}

			const x1Percentage = (x1 / width) * 100;
			const y1Percentage = (y1 / height) * 100;
			const x2Percentage = (x2 / width) * 100;
			const y2Percentage = (y2 / height) * 100;

			participant.sendMediaControlsEvent(
				enums.MediaControlsCommands.DRAG_TO_ZOOM,
				enums.MediaTypes.CAMERA,
				JSON.stringify({
					x1: x1Percentage.toFixed(2),
					y1: y1Percentage.toFixed(2),
					x2: x2Percentage.toFixed(2),
					y2: y2Percentage.toFixed(2),
				})
			);
		};

		const handleMouseLeave = () => {
			setIsDrawing(false);
			setCoordinates({
				x1: 0,
				x2: 0,
				y1: 0,
				y2: 0,
			});
		};

		const shouldAllowDragOrClickToMove =
			participant instanceof RemoteHelloParticipant &&
			![UserRoles.PATIENT, UserRoles.VISITOR, UserRoles.GUEST].includes(getUserRole()) &&
			!conferenceConfigs.isGridView &&
			!conferenceConfigs.isEmbeddedView;

		const getCoordinates = e => {
			const rect = ref.current?.getBoundingClientRect();
			const containerWidth = rect.width;
			const containerHeight = rect.height;
			const aspectRatio = 16 / 9;
			const originalWidth = containerHeight * aspectRatio;
			const widthDifference = originalWidth - containerWidth;
			const originalHeight = containerWidth / aspectRatio;
			const heightDifference = originalHeight - containerHeight;
			let width = containerWidth;
			let height = containerHeight;
			let offsetX = e.clientX - rect.left;
			let offsetY = e.clientY - rect.top;
			if (!conferenceConfigs.isFitScreen) {
				if (widthDifference > 0) {
					width = containerWidth + widthDifference;
					offsetX += widthDifference / 2;
				}
				if (widthDifference <= 0 && heightDifference > 0) {
					height = containerHeight + heightDifference;
					offsetY += heightDifference / 2;
				}
			}
			const x = (offsetX / width) * 100;
			const y = (offsetY / height) * 100;
			const xFloat = parseFloat(x.toFixed(2));
			const yFloat = parseFloat(y.toFixed(2));
			return {
				x: xFloat > 0 ? xFloat : 0,
				y: yFloat > 0 ? yFloat : 0,
			};
		};

		const sendCoordinates = e => {
			if (!ref || !shouldAllowDragOrClickToMove || conferenceConfigs.isGridView) {
				return;
			}

			e.stopPropagation();

			const { x, y } = getCoordinates(e);
			participant.sendMediaControlsEvent(
				enums.MediaControlsCommands.MOVE_TO_POSITION,
				enums.MediaTypes.CAMERA,
				JSON.stringify({
					x,
					y,
				})
			);
		};

		let dragToZoomStyle = {
			left: `${coordinates.x2 > coordinates.x1 ? coordinates.x1 : coordinates.x2}px`,
			top: `${coordinates.y2 > coordinates.y1 ? coordinates.y1 : coordinates.y2}px`,
			width: `${coordinates.x2 > coordinates.x1 ? coordinates.x2 - coordinates.x1 : coordinates.x1 - coordinates.x2}px`,
			height: `${coordinates.y2 > coordinates.y1 ? coordinates.y2 - coordinates.y1 : coordinates.y1 - coordinates.y2}px`,
		};

		if (ref?.current?.offsetLeft > 0 || ref?.current?.offsetTop > 0) {
			const x1 = ref?.current?.offsetLeft > 0 ? coordinates.x1 + ref?.current?.offsetLeft : coordinates.x1;
			const x2 = ref?.current?.offsetLeft > 0 ? coordinates.x2 + ref?.current?.offsetLeft : coordinates.x2;
			const y1 = ref?.current?.offsetTop > 0 ? coordinates.y1 + ref?.current?.offsetTop : coordinates.y1;
			const y2 = ref?.current?.offsetTop > 0 ? coordinates.y2 + ref?.current?.offsetTop : coordinates.y2;

			dragToZoomStyle = {
				left: `${coordinates.x2 > coordinates.x1 ? x1 : x2}px`,
				top: `${coordinates.y2 > coordinates.y1 ? y1 : y2}px`,
				width: `${coordinates.x2 > coordinates.x1 ? coordinates.x2 - coordinates.x1 : coordinates.x1 - coordinates.x2}px`,
				height: `${coordinates.y2 > coordinates.y1 ? coordinates.y2 - coordinates.y1 : coordinates.y1 - coordinates.y2}px`,
			};
		}

		const videoTrack = tracks[activeTrackType];

		return (
			<>
				<ParticipantVideo
					ref={ref}
					track={videoTrack}
					onDoubleClick={shouldAllowDragOrClickToMove ? sendCoordinates : null}
					onMouseDown={shouldAllowDragOrClickToMove && selectedCamera?.name === CameraTypes.HUDDLE ? handleMouseDown : null}
					onMouseMove={shouldAllowDragOrClickToMove && selectedCamera?.name === CameraTypes.HUDDLE ? handleMouseMove : null}
					onMouseUp={shouldAllowDragOrClickToMove && selectedCamera?.name === CameraTypes.HUDDLE ? handleMouseUp : null}
					onMouseLeave={shouldAllowDragOrClickToMove && selectedCamera?.name === CameraTypes.HUDDLE ? handleMouseLeave : null}
					muted
				/>
				{isDrawing && <div className='drag-zoom-square' style={dragToZoomStyle} />}
			</>
		);
	}
);

const MainParticipantView = forwardRef(
	/**
	 * @param {object} props
	 * @param {LocalParticipant | import('calls/RemoteParticipant.js').default} props.participant Local or remote participant
	 * @param {import('@solaborate/calls/webrtc').Cam | import('@solaborate/calls/webrtc').ScreenShare} props.activeTrackType Main participant active track type {VIDEO | SCREEN}
	 * @param {React.RefObject<HTMLVideoElement>} ref
	 */ ({ participant, activeTrackType }, ref) => {
		const conference = useConference();
		const intl = useIntl();
		const [loadingConference, setLoadingConference] = useState(true);
		const [userInterActivityModal, setUserInteractivityModal] = useState(false);
		const [isVideoLessThanAspectRatio, setIsVideoLessThanAspectRatio] = useState(false);
		const tracks = useControllerTracks(
			participant instanceof LocalParticipant ? participant.localTrackController : participant.remoteTrackController
		);
		const deviceState = useHelloDeviceState(participant);

		const conferenceConfigs = useConferenceConfigurations();

		const isCameraPrivacyOn = deviceState?.isCameraPrivacyOn;
		const isMicPrivacyOn = deviceState?.isMicPrivacyOn;
		const isTvMuted = conferenceConfigs.tvVolumeRange <= 0 && conferenceConfigs.tvVolumeRange >= -100 && deviceState?.tvPowerOn;

		const mainRef = useRef(null);
		const audioRef = useRef(null);

		const screenType = useScreenType();

		useEffect(() => {
			setLoadingConference(false);
		}, [tracks]);

		const videoTrack = tracks[activeTrackType];

		useEffect(() => {
			const onLoaded = ({ target }) => {
				if (target) {
					const { videoHeight, videoWidth } = target;
					conferenceConfigs.setIsMainParticipantPortraitVideo(videoWidth <= videoHeight);
				}
			};

			if (ref.current) {
				ref.current.addEventListener('loadedmetadata', onLoaded);
			}

			return () => {
				if (ref.current) {
					// eslint-disable-next-line react-hooks/exhaustive-deps
					ref.current.removeEventListener('loadedmetadata', onLoaded);
				}
			};
		}, [conferenceConfigs, ref]);

		useEffect(() => {
			if (audioRef.current && tracks[Mic]) {
				audioRef.current.play().catch(error => {
					console.error(error);
					setUserInteractivityModal(true);
				});
			}
		}, [tracks]);

		const isMobileClient = () =>
			(conferenceConfigs.isLiveExaminationOpen ||
				conferenceConfigs.isPatientHistoryOpen ||
				conferenceConfigs.isTelemetryModalOpen ||
				conferenceConfigs.medicalDataControls) &&
			conferenceConfigs.isMainParticipantPortraitVideo;

		return (
			<main
				ref={mainRef}
				className={classNames(
					isVideoLessThanAspectRatio ? 'less-aspect-ratio' : '',
					isMobileClient() ? 'mobile-client-wrapper' : ''
				)}>
				{loadingConference && !videoTrack && (
					<StyledLoadingMainParticipant>
						<Loader color='white' />
						<p>
							{conference.callType === enums.CallTypes.SECURITYCAM
								? translate('connectingToHello', { value: conferenceConfigs.helloName })
								: translate('loadingCamera')}
						</p>
					</StyledLoadingMainParticipant>
				)}
				{videoTrack && (
					<DragToZoomVideo
						ref={ref}
						mainRefElement={mainRef.current}
						participant={participant}
						activeTrackType={activeTrackType}
						setIsVideoLessThanAspectRatio={setIsVideoLessThanAspectRatio}
					/>
				)}
				{!videoTrack && !loadingConference && (
					<Avatar
						size={96}
						src={participant.picture?.includes('user') ? null : buildProfilePic(participant.picture)}
						name={participant.name}
					/>
				)}
				{conference.callType === enums.CallTypes.SECURITYCAM && participant instanceof RemoteHelloParticipant && (
					<ParticipantAudio track={tracks[Mic]} ref={audioRef} autoPlay={false} />
				)}
				{participant instanceof RemoteHelloParticipant &&
					(isCameraPrivacyOn || isMicPrivacyOn || isTvMuted) &&
					!conferenceConfigs.isGridView &&
					!screenType.isSmall && <PrivacyNotification participant={participant} />}
				{videoTrack && screenType.isSmall && [UserRoles.VISITOR, UserRoles.FAMILY_MEMBER].includes(getUserRole()) && (
					<FullScreenButton
						onClick={() => {
							if (document.fullscreenElement) {
								document.exitFullscreen();
								return;
							}
							if (isIOS) {
								ref.current.webkitEnterFullScreen();
								return;
							}
							ref.current.requestFullscreen();
						}}>
						<span className='material-icons'>fullscreen</span>
					</FullScreenButton>
				)}
				{conferenceConfigs.showClosedCaptions && tracks[Mic] && (
					<SpeechToTextOverlay
						setShowClosedCaptions={conferenceConfigs.setShowClosedCaptions}
						setClosedCaptionsError={conferenceConfigs.setConferenceErrorMessages}
						track={tracks[Mic] ? tracks[Mic].track : null}
					/>
				)}
				{participant instanceof RemoteHelloParticipant && conferenceConfigs.isMultiBed && getUserRole() === UserRoles.VISITOR && (
					<>
						<LimitedVisitorSideView position='left'></LimitedVisitorSideView>
						<LimitedVisitorSideView position='right'></LimitedVisitorSideView>
					</>
				)}
				{userInterActivityModal && (
					<Modal onDismiss={() => setUserInteractivityModal(false)} title={intl.formatMessage({ id: 'patientAudio' })}>
						<Modal.Content>
							<p>{translate('allowPatientAudio')}</p>
						</Modal.Content>
						<Modal.Actions>
							<Button
								type='submit'
								variant={ButtonType.SUBMIT}
								onClick={() => {
									setUserInteractivityModal(false);
									if (audioRef.current) {
										audioRef.current.play();
									}
								}}>
								Okay
							</Button>
						</Modal.Actions>
					</Modal>
				)}
			</main>
		);
	}
);
MainParticipantView.displayName = 'MainParticipantView';

export default MainParticipantView;
