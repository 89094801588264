import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import Grid from 'components/Grid.jsx';
import Alert from 'components/Alert.jsx';
import { convertSecondsToHoursFormat } from 'infrastructure/helpers/commonHelpers.js';
import { useLocation } from 'react-router';
import { getTimeKpis } from 'api/dashboard.js';
import { convertToTimestampSecondsWithMaxTime } from 'infrastructure/helpers/dateHelper.js';
import styled from 'styled-components';
import { Chart } from 'primereact/chart.js';
import Loader from 'components/Loader.jsx';
import { CallTypes } from 'constants/enums.js';

const ChartWrapper = styled.div`
	border: 1px solid var(--gray-15);
	background: var(--gray-0);
	padding: var(--spacing-xl);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 380px;
	.p-chart {
		height: 250px;
		position: relative;
		z-index: 1;
	}
	> div {
		&:first-of-type {
			p {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				font-size: 24px;
				font-weight: 700;
				margin: 0;
				padding: 0;

				span {
					font-size: 16px;
					font-weight: 400;
					width: 120px;
					line-height: 20px;
				}
			}
		}
		&:last-of-type {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: var(--spacing-l);
			margin-top: var(--spacing-l);
			> div {
				display: flex;
				align-items: center;
				overflow: hidden;
				justify-content: center;
				> div {
					&:first-of-type {
						width: 12px;
						height: 12px;
						border-radius: 5px;
						margin-right: 5px;
						flex-shrink: 0;
					}
				}
				p {
					margin: 0;
					padding: 0;
					text-align: left;
					display: block;
					font-size: 12px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;

					&:last-of-type {
						font-size: 18px;
						font-weight: 600;
						line-height: 18px;
					}
				}
			}
		}
	}
`;

const SessionByTime = () => {
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const intl = useIntl();
	const location = useLocation();
	const translator = useCallback(id => intl.formatMessage({ id }), [intl]);
	const [values, setValues] = useState([
		{ id: 0, text: translator('viewPatient'), value: 0, displayValue: '0', color: '#5390FF' },
		// { id: 1, text: translator('monitoring'), value: 0, displayValue: '0', color: '#6BC6B5' },
		{ id: 2, text: translator('talkToPatient'), value: 0, displayValue: '0', color: '#BED5FF' },
	]);
	const [totalCount, setTotalCount] = useState({ value: 0, displayValue: '0' });

	useEffect(() => {
		const sumDuration = (condition, data) =>
			data.reduce((accumulator, item) => {
				if (condition.includes(item.callType)) {
					return accumulator + item.durationInSeconds;
				} else {
					return accumulator;
				}
			}, 0);

		const { state } = location;
		const { patient, from, to } = state;

		const fetchKpis = async () => {
			setIsLoading(true);
			const response = await getTimeKpis({
				patientId: patient.healthcareUserIntId,
				start: Math.floor(new Date(from).getTime() / 1000),
				end: Math.floor(convertToTimestampSecondsWithMaxTime(to ?? new Date())),
				solHelloDeviceId: patient.solHelloDeviceId,
			});
			if (response.error) {
				setIsLoading(false);
				return;
			}
			const rounding = sumDuration([CallTypes.AUDIO, CallTypes.VIDEO, CallTypes.SECURITY_CAM], response.data);
			const monitoring = sumDuration([CallTypes.MONITORING], response.data);
			const ambinetMonitoring = sumDuration([CallTypes.AMBIENT], response.data);
			const total = rounding + monitoring + ambinetMonitoring;
			setTotalCount({ value: total, displayValue: convertSecondsToHoursFormat(total, true) });
			setValues([
				{
					id: 0,
					text: translator('monitoring'),
					value: monitoring,
					displayValue: convertSecondsToHoursFormat(monitoring, true),
					color: '#6BC6B5',
				},
				// {
				// 	id: 1,
				// 	text: translator('ambientMonitoring'),
				// 	value: ambinetMonitoring,
				// 	displayValue: convertSecondsToHoursFormat(ambinetMonitoring, true),
				// 	color: '#BED5FF',
				// },
				{
					id: 2,
					text: translator('talkToPatient'),
					value: rounding,
					displayValue: convertSecondsToHoursFormat(rounding, true),
					color: '#5390FF',
				},
			]);
			setIsLoading(false);
		};
		fetchKpis();
	}, [location]);

	return useMemo(() => {
		const data = {
			labels: [
				translator('monitoring'),
				// translator('ambientMonitoring'),
				translator('talkToPatient'),
			],
			datasets: [
				{
					data: [
						values[0].value,
						//  values[1].value,
						values[1].value,
					],
					backgroundColor: [
						'#6BC6B5',
						//  '#BED5FF',
						'#5390FF',
					],
				},
			],
		};

		const options = {
			legend: {
				display: false,
			},
			maintainAspectRatio: false,
			cutoutPercentage: 80,
		};

		return (
			<>
				<ChartWrapper>
					<h3>{translator('sessionByTime')}</h3>
					{isLoading && (
						<Grid columns='1fr' rows='1fr' stretch='250px' horizAlign='center' vertAlign='center'>
							<Loader />
						</Grid>
					)}
					{!isLoading && (
						<>
							<div className='full-width position-relative'>
								<p className='flex column-direction'>
									<span>{translator('totalHours')}</span>
									{totalCount.displayValue}
								</p>
								<Chart type='doughnut' data={data} options={options} />
							</div>
							<div>
								{values.map(item => (
									<div key={item.id}>
										<div style={{ backgroundColor: item.color }} />
										<div>
											<p>{item.text}</p>
											<p>{item.displayValue}</p>
										</div>
									</div>
								))}
							</div>
						</>
					)}
				</ChartWrapper>
				<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
			</>
		);
	}, [totalCount, values, isLoading]);
};

export default SessionByTime;
