import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import CareTeamMembers from 'containers/Rpm/CareTeamMembers.jsx';
import { getSpecialties } from 'api/doctorOnBoarding.js';
import Alert from 'components/Alert.jsx';
import Loader from 'components/Loader.jsx';
import { getRpmProgramDetails, validateVisitorEmail } from 'api/rpm.js';
import { MemberType } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';

const CareTeam = props => {
	const [specialties, setSpecialties] = useState([]);
	const [doctors, setDoctors] = useState([]);
	const [nurses, setNurses] = useState([]);
	const [familyMembers, setFamilyMembers] = useState([]);
	const [selectedSpecialties, setSelectedSpecialties] = useState([]);
	const [programCareTeam, setProgramCareTeam] = useState([]);
	const [error, setError] = useState(null);
	const intl = useIntl();
	const translator = id => intl.formatMessage({ id });

	useEffect(() => {
		const getSpecialtiesList = async () => {
			const response = await getSpecialties();
			if (response.error) {
				setError(response.error.message);
				return;
			}
			setSpecialties(response.specialties);
		};
		getSpecialtiesList();
	}, []);

	const getCareTeamMembers = (careTeam, careMemberType) =>
		careTeam.reduce((result, item) => {
			if (item.careMemberType === careMemberType) {
				result.push({
					value: item.userId || item.email,
					label: `${item.firstName} ${item.lastName}`,
					profilePicture: item.profilePicture,
				});
			}
			return result;
		}, []);

	useEffect(() => {
		const getRpmDetails = async () => {
			const response = await getRpmProgramDetails(props.selectedProgram?.value);
			if (response.error) {
				setError(response.error.message);
				return;
			}
			setDoctors(getCareTeamMembers(response.rpmProgram?.careTeam, MemberType.DOCTOR));
			setNurses(getCareTeamMembers(response.rpmProgram?.careTeam, MemberType.NURSE));
			setFamilyMembers(getCareTeamMembers(response.rpmProgram?.careTeam, MemberType.FAMILY_MEMBER));
			setSelectedSpecialties(response.rpmProgram?.careTeam.filter(item => item.careMemberType === MemberType.DOCTOR));
			setProgramCareTeam(response.rpmProgram?.careTeam);
		};
		getRpmDetails();
	}, [props.selectedProgram]);

	const validateEmail = async email => {
		let emailExists = false;
		const schema = Yup.string().email().required();
		if (await schema.isValid(email)) {
			const response = await validateVisitorEmail(email, props.patientId);
			if (response.error) {
				setError(response.error.message);
				emailExists = false;
			} else {
				emailExists = response.isValid;
			}
		}
		return emailExists;
	};

	const getValidationSchema = () => {
		return Yup.object().shape({
			doctors: Yup.array()
				.min(1, translator('atLeastOneMember'))
				.test('non-empty-values', translator('valuesCannotBeEmpty'), array => array.every(item => item?.value)),
			nurses: Yup.array().test('non-empty-values', translator('valuesCannotBeEmpty'), array => array.every(item => item?.value)),
			familyMembers: Yup.array().of(
				Yup.object().shape({
					value: Yup.string()
						.email(intl.formatMessage({ id: 'invalidEmail' }))
						.required(translator('valuesCannotBeEmpty'))
						.test(
							'is-not-specific-value',
							translator('cannotBeFamilyMember'),
							value => value !== props.updatedValues?.emailAddress
						)
						.test('existing-email', intl.formatMessage({ id: 'userAlreadyExists' }), val => validateEmail(val)),
				})
			),
		});
	};

	return (
		<Formik
			enableReinitialize={true}
			validateOnBlur={true}
			initialValues={{ ...props.updatedValues, doctors, nurses, familyMembers, specialties: selectedSpecialties }}
			validationSchema={getValidationSchema}
			onSubmit={() => props.onSubmitForm(props.updatedValues)}>
			{formikProps => {
				const { handleSubmit, values, errors, setFieldValue } = formikProps;
				return (
					<div className='care-member-padding'>
						{props.isTaskLoading && (
							<div className='full-width full-height flex flex-align-center flex-justify-center column-direction'>
								<Loader />
								<h4>{translate('patientIsBeingRegistered')}...</h4>
							</div>
						)}
						{!props.isTaskLoading && (
							<>
								{!props.isAddLoading && (
									<>
										<button type='button' className='button requests-wrapper-button add-patient' onClick={handleSubmit}>
											{props.submitButtonText}
										</button>
										<div className='care-team'>
											<CareTeamMembers
												doctorValues={values.doctors}
												nurseValues={values.nurses}
												specialtyValues={values.specialties}
												doctorErrors={errors.doctors}
												nurseErrors={errors.nurses}
												setFieldValue={setFieldValue}
												specialties={specialties}
												isAddPatient={true}
												setDoctorValues={props.setDoctors}
												setNurseValues={props.setNurses}
												programCareTeam={programCareTeam}
												familyMemberValues={values.familyMembers}
												setFamilyMemberValues={props.setFamilyMembers}
												familyMemberErrors={errors.familyMembers}
											/>
										</div>
									</>
								)}
								{props.isAddLoading && (
									<div className='submit-btn-wrapper flex flex-justify-center'>
										<Loader />
									</div>
								)}
							</>
						)}
						<Alert
							display={props.error || error}
							fixed={true}
							hideCloseButton={true}
							message={props.error || error}
							variant='dark'
						/>
					</div>
				);
			}}
		</Formik>
	);
};

export default CareTeam;
