import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ReactCrop from 'react-image-crop';
import { useIntl } from 'react-intl';
import { CSVLink } from 'react-csv';
import _ from 'lodash';
import { actionCreators } from 'state/healthSystems/actions.js';
import Button from 'components/Button.jsx';
import Table from 'components/Table.jsx';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from 'components/Tabs.jsx';
import DescriptionBox from 'components/DescriptionBox.jsx';
import Modal from 'components/Modal.jsx';
import Form from 'components/Form.jsx';
import { DeviceListLevel, SectorTypes, TeamTypes, ConnectivityStatus, TreeHierarchyType, UserRoles } from 'constants/enums.js';
import { deleteOrgUnit, getLogoUrl, saveLogo, uploadLogo } from 'api/healthSystems.js';
import EditSectorModal from 'containers/EditSectorModal.jsx';
import { getDeviceList } from 'api/devices.js';
import {
	findSectorById,
	getParentSector,
	buildTree,
	decodeHtml,
	getCodeNameByOsVersion,
	getAppVersion,
	getHierarchyByHelloDeviceId,
	findDeviceById,
	getHealthSystemDevices,
	sortDevicesByTreeRoomsOrder,
	getConfigurationValue,
	getLevelDescriptionById,
} from 'infrastructure/helpers/commonHelpers.js';
import translate from 'i18n-translations/translate.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import Alert from 'components/Alert.jsx';
import AssignDoctor from 'components/AssignDoctor.jsx';
import { getDoctorAssigned } from 'api/doctors.js';
import { unAssignMember } from 'api/users.js';
import 'react-image-crop/dist/ReactCrop.css';
import { getRegionSubTree } from 'api/tree.js';
import VoiceCommandCallingModal from 'containers/VoiceCommandCallingModal.jsx';
import DeviceActions from 'components/DeviceActions.jsx';
import Dropdown from 'components/Dropdown.jsx';
import VisitingHoursConfiguration from 'components/VisitingHoursConfiguration.jsx';
import { getVisitingHours } from 'api/visitingHours.js';
import { getInitialVisitingHoursMaped, reArrangeVisitingHours } from 'infrastructure/helpers/visitingHoursHelper.js';
import Pagination from 'components/Common/Pagination.jsx';
import { actionCreators as devicesActionCreators } from 'state/devices/actions.js';
import AlexaSmartPropertiesWrapper from 'containers/AlexaSmartPropertiesWrapper.jsx';
import { getTeamSettings } from 'api/adminConfigurations.js';
import { GeneralSettings, SettingsCategory, CompanySettings } from 'constants/configurationEnums.js';
import { getUserRole } from 'infrastructure/auth.js';
import { mapSectionsToRender } from 'infrastructure/helpers/mapSectorsToRender.js';
import SelectTimeZone from 'components/SelectTimeZone.jsx';
import PinLockWrapper from 'containers/PinLockWrapper.jsx';
import MonitoringAiFeatureFlags from 'containers/Organization/MonitoringAiFeatureFlags.jsx';

const Hospital = props => {
	const [devices, setDevices] = useState([]);
	const [selectedOrg, setSelectedOrg] = useState({ name: '', level: null, id: null });
	const [isDeleteOrgUnitModalOpen, setIsDeleteOrgUnitModalOpen] = useState(false);
	const [sectorData, setSectorData] = useState({ sectorName: '', hospitalId: '' });
	const [isDeleteDeviceModalLoading, setIsDeleteDeviceModalLoading] = useState(false);
	const [currentTab, setCurrentTab] = useState(0);
	const [isEditSectorModalOpen, setIsEditSectorModalOpen] = useState(false);
	const [error, setError] = useState(null);
	const [doctors, setDoctors] = useState([]);
	const [isUploadLogoModalOpen, setIsUploadLogoModalOpen] = useState(false);
	const [src, setSrc] = useState(null);
	const [crop, setCrop] = useState({ unit: '%', width: 100, height: 100, aspect: 16 / 9, x: 0, y: 0 });
	const [hospitalLogo, setHospitalLogo] = useState('');
	const [isHospitalLogoModalLoading, setIsHospitalLogoModalLoading] = useState(false);
	const [fileError, setFileError] = useState('');
	const [selectedDoctor, setSelectedDoctor] = useState(null);
	const [isAddDoctorShown, setIsAddDoctorShown] = useState(false);
	const [helloDeviceId, setHelloDeviceId] = useState('');
	const [currentHospital, setCurrentHospital] = useState({
		sectorName: '',
		hospitalName: '',
		hospitalId: '',
		sectorType: '',
		timezone: {},
		pin: '',
	});
	const [currentSector, setCurrentSector] = useState([]);
	const [isVoiceCommandCallingModalOpen, setVoiceCommandCallingModalOpen] = useState(false);
	const [isVisitingHoursModalOpen, setIsVisitingHoursModalOpen] = useState(false);
	const [isTimeZoneModalOpen, setIsTimeZoneModalOpen] = useState(false);
	const [visitingHours, setVisitingHours] = useState([]);
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0 });
	const [doctorsTotalCount, setDoctorsTotalCount] = useState(0);
	const [isAlexaSmartPropertiesOpen, setIsAlexaSmartPropertiesOpen] = useState(false);
	const [isTableDataLoading, setIsTableDataLoading] = useState(true);
	const [isAlexaPropertiesEnabled, setIsAlexaPropertiesEnabled] = useState(false);
	const [isPinLockOpen, setIsPinLockOpen] = useState(false);
	const imgRef = useRef(null);
	const companyConfigurations = useSelector(state => state.company.companySettings?.companyConfigurations);

	const intl = useIntl();

	const history = useHistory();

	const dispatch = useDispatch();

	const setHealthSystem = hs => dispatch(actionCreators.setHealthSystem(hs));

	const updateBreadcrumb = breadcrumb => dispatch(actionCreators.updateBreadcrumb(breadcrumb));

	const setTreeData = tree => dispatch(actionCreators.setTreeData(tree));

	const treeData = useSelector(state => state.healthSystems.treeData.tree);

	const allHealthSystems = useSelector(state => state.healthSystems.allHealthSystems);

	const userSession = useSelector(state => state.user.userSession);

	const healthSystems = useSelector(state => state.healthSystems);

	const treeHierarchyTypeId = allHealthSystems.find(item => item.id === userSession.healthSystem.id)?.treeHierarchyTypeId;

	let fileUrl;

	const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
		const targetWidth = mediaHeight * aspect;
		if (targetWidth <= mediaWidth) {
			const width = targetWidth;
			const x = (mediaWidth - targetWidth) / 2;
			return {
				unit: 'px',
				x,
				y: 0,
				width,
				height: mediaHeight,
				aspect,
			};
		} else {
			const height = mediaWidth / aspect;
			const y = (mediaHeight - height) / 2;
			return {
				unit: 'px',
				x: 0,
				y,
				width: mediaWidth,
				height,
				aspect,
			};
		}
	};

	const onImageLoad = event => {
		const { naturalWidth, naturalHeight } = event.target;
		const newCrop = centerAspectCrop(naturalWidth, naturalHeight, crop.aspect);
		setCrop(newCrop);
	};

	const initDeleteOrg = useCallback(data => {
		setSelectedOrg(data);
		toggleDeleteOrgUnitModal();
	}, []);

	const getSections = useCallback(() => {
		let allSections = {
			hospitals: [],
			departments: [],
			floors: [],
			rooms: [],
		};
		if (treeData.length > 0) {
			allSections = mapSectionsToRender(
				treeData,
				currentHospital,
				sector => openEditSectorModal(sector),
				initDeleteOrg,
				treeHierarchyTypeId
			);
		}

		return allSections;
	}, [currentHospital, initDeleteOrg, treeData, treeHierarchyTypeId]);

	const sections = useMemo(() => getSections(), [getSections]);

	useEffect(() => {
		const loadHospital = async () => {
			const params = {
				pageIndex: pagination.pageIndex,
				pageSize: pagination.pageSize,
			};
			const [devicesRes, assignedDoctorsRes] = await Promise.all([
				getDeviceList(DeviceListLevel.HOSPITAL, props.match.params.hospitalId),
				getDoctorAssigned(props.match.params.hospitalId, params),
			]);
			if (!devicesRes.error) {
				const sector = findSectorById(treeData, props.match.params.hospitalId);
				if (sector?.subOptions) {
					for (let index = 0; index < devicesRes.length; index += 1) {
						const device = findDeviceById(sector.subOptions, devicesRes[index].solHelloDeviceId);
						if (device) {
							device.voiceCommandCalling = devicesRes[index].voiceCommandCalling;
							devicesRes[index].roomName = device.name;
							devicesRes[index].roomId = device.roomId;
						}
					}
				}
				if (sector) {
					setCurrentSector([sector]);
				}

				setDevices(devicesRes);
			}

			if (!assignedDoctorsRes.error) {
				setDoctors(mapDoctors(assignedDoctorsRes.doctors));
				setDoctorsTotalCount(assignedDoctorsRes.totalCount);
			}
			setIsTableDataLoading(false);
		};
		const fetchVisitingHours = async () => {
			const response = await getVisitingHours(DeviceListLevel.HOSPITAL, props.match.params.hospitalId);
			if (response.error) {
				setError(response.error.message);
			} else {
				const result = getInitialVisitingHoursMaped(response.visitingHours);
				const visitingHoursRearragned = reArrangeVisitingHours(result, intl);
				setVisitingHours(visitingHoursRearragned);
			}
		};
		const fetchSectorSettings = async () => {
			const response = await getTeamSettings({
				teamId: props.match.params.hospitalId,
				levelId: DeviceListLevel.HOSPITAL,
				settingsCategory: [SettingsCategory.GENERAL],
			});

			if (response.error) {
				setError(response.error.message);
				return;
			}

			const alexaSetting = response.settings.filter(x => x.settingTypeId === GeneralSettings.Alexa)[0];
			setIsAlexaPropertiesEnabled(alexaSetting?.value.toLowerCase() === 'true');
		};

		fetchSectorSettings();
		fetchVisitingHours();
		loadHospital();

		return () => {
			setIsTableDataLoading(true);
		};
	}, [currentHospital, intl, pagination, props.match.params.hospitalId, treeData]);

	useEffect(() => {
		const getCurrentHospital = () => {
			let hospital = {
				sectorName: '',
				hospitalName: '',
				hospitalId: '',
				sectorType: '',
				timezone: {},
				pin: '',
			};
			if (treeData.length > 0) {
				const fondHospital = treeData.find(item => item.hospitalId === props.match.params.hospitalId);
				hospital = {
					sectorName: fondHospital ? fondHospital.name : '',
					hospitalName: fondHospital ? fondHospital.name : '',
					hospitalId: fondHospital ? fondHospital.hospitalId : '',
					sectorType: fondHospital ? fondHospital.type : '',
					timezone: fondHospital ? fondHospital.timezone : '',
					pin: fondHospital ? fondHospital.pin : '',
				};
				if (fondHospital) {
					setCurrentSector([fondHospital]);
				}
			}
			return hospital;
		};
		setCurrentHospital(getCurrentHospital());
	}, [props.match.params.hospitalId, treeData]);

	const openEditHospitalModal = async selectedSector => {
		selectedSector.parentSectorName = userSession.healthSystem.name;
		selectedSector.parentSectorType = SectorTypes.HEALTH_SYSTEM;
		setSectorData(selectedSector);
		const response = await getLogoUrl(currentHospital.hospitalId);
		setHospitalLogo(response);
		setIsEditSectorModalOpen(prevState => !prevState);
	};

	const openEditSectorModal = selectedSector => {
		const sector = findSectorById(healthSystems.treeData.tree, selectedSector[`${selectedSector.sectorType}Id`]);
		const sectorParent = getParentSector(healthSystems.treeData.tree, sector);
		selectedSector.parentSectorName = sectorParent.name;
		selectedSector.parentSectorType = sectorParent.type;
		setSectorData(selectedSector);
		setIsEditSectorModalOpen(prevState => !prevState);
	};

	const mapDoctors = docs =>
		docs?.map(item => ({
			id: item.id,
			name: `${item.firstName} ${item.lastName}`,
			actions: (
				<>
					{item.teamTypeId === TeamTypes.HOSPITAL && (
						<div className='wrapped'>
							<i className='material-icons-outlined boxed-icon' id={item.id} onClick={() => setSelectedDoctor(item)}>
								delete
							</i>
						</div>
					)}
				</>
			),
		}));

	const getConnectionType = device => (
		<>
			{[device.wifi, device.ethernet].includes(ConnectivityStatus.ON) && (
				<>
					{device.wifi === ConnectivityStatus.ON && 'WiFi'}
					{device.ethernet === ConnectivityStatus.ON && device.wifi === ConnectivityStatus.ON && ','}{' '}
					{device.ethernet === ConnectivityStatus.ON && 'Ethernet'}
				</>
			)}

			{device.wifi === ConnectivityStatus.OFF && device.ethernet === ConnectivityStatus.OFF && 'N/A'}
		</>
	);

	const getDeviceOwner = device => (
		<div className='flex'>{isPatientAssigned(device) ? decodeHtml(device.owner) : translate('unassigned')}</div>
	);

	const displayDevices = () => {
		if (devices.length === 0 || healthSystems.treeData.tree.length === 0) return [];
		const sortedDevicesList = sortDevicesByTreeRoomsOrder(devices, getSections().rooms);

		return sortedDevicesList.map(device => ({
			id: device.id,
			roomName: device.roomName,
			...(isPatientDisabled() ? [] : { owner: getDeviceOwner(device) }),
			serialNumber: device.serialNumber || 'N/A',
			macAddress: device.macAddress || 'N/A',
			appVersion: device.appVersion ? getAppVersion(device.appVersion) : 'N/A',
			androidVersion: device.appVersion ? getCodeNameByOsVersion(device.osVersion) : 'N/A',
			osVersion: device.firmwareRevision || 'N/A',
			ipAddress: device.ipAddress || 'N/A',
			ssid: device.ssid || 'N/A',
			connectionType: getConnectionType(device),
			voiceCommandCalling: intl.formatMessage({ id: device.voiceCommandCalling ? 'active' : 'notActive' }),
			actions: (
				<DeviceActions
					setHelloDeviceId={setHelloDeviceId}
					setIsDeleteDeviceModalLoading={setIsDeleteDeviceModalLoading}
					fetchTreeData={fetchTreeData}
					isDeleteDeviceModalLoading={isDeleteDeviceModalLoading}
					userSession={userSession}
					healthSystems={healthSystems}
					helloDeviceId={helloDeviceId}
					device={device}
					owner={getDeviceOwner(device)}
					isPatientDisabled={isPatientDisabled()}
				/>
			),
		}));
	};

	const deleteOrgUnitSubmit = async () => {
		await deleteOrgUnit(selectedOrg.level, selectedOrg.id);
		toggleDeleteOrgUnitModal();
		fetchTreeData();
		if (selectedOrg.level === DeviceListLevel.HOSPITAL) {
			history.push('/health-system');
			updateBreadcrumb([]);
		}
	};

	const toggleDeleteOrgUnitModal = () => {
		setIsDeleteOrgUnitModalOpen(prevState => !prevState);
	};

	const fetchTreeData = async () => {
		const subTreeResponse = await getRegionSubTree(userSession.healthSystem.id, userSession.regionId);
		if (subTreeResponse.error) {
			setError(subTreeResponse.error.message);
		} else {
			const { healthSystem } = subTreeResponse.organization;
			const tree = buildTree(healthSystem);
			const { online, busy, privacy, pairedRemote } = getHealthSystemDevices(healthSystem);
			dispatch(devicesActionCreators.setBulkDevicesBusy(busy));
			dispatch(devicesActionCreators.setBulkDevicesOnline(online));
			dispatch(devicesActionCreators.setBulkDevicesPrivacy(privacy));
			dispatch(devicesActionCreators.setBulkPairedRemoteDevice(pairedRemote));
			setHealthSystem(healthSystem);
			setTreeData(tree);
		}
	};

	const onAssignDoctorSubmit = async () => {
		const params = {
			pageIndex: pagination.pageIndex,
			pageSize: pagination.pageSize,
		};
		const response = await getDoctorAssigned(props.match.params.hospitalId, params);
		if (response.error) {
			setError(response.error.message);
		} else {
			setIsAddDoctorShown(false);
			setDoctors(mapDoctors(response.doctors));
		}
	};

	const deleteDoctor = async () => {
		const response = await unAssignMember(userSession.healthSystem.id, props.match.params.hospitalId, selectedDoctor.id);
		if (response.error) {
			setError(response.error.message);
		}
		setSelectedDoctor(null);
		const params = {
			pageIndex: pagination.pageIndex,
			pageSize: pagination.pageSize,
		};
		const doctorsAssignedRes = await getDoctorAssigned(props.match.params.hospitalId, params);
		if (doctorsAssignedRes.error) {
			setError(response.error.message);
		} else {
			setDoctors(mapDoctors(doctorsAssignedRes.doctors));
		}
	};

	const onSelectFile = async e => {
		if (e.target.files && e.target.files.length > 0) {
			const reader = new FileReader();
			reader.addEventListener('load', () => setSrc(reader.result));
			reader.readAsDataURL(e.target.files[0]);
		}
		setIsUploadLogoModalOpen(true);
		setIsEditSectorModalOpen(false);
	};

	const makeClientCrop = async () => {
		try {
			setIsHospitalLogoModalLoading(true);
			if (imgRef?.current && crop.width && crop.height) {
				const croppedImageUrl = await getCroppedImg(imgRef?.current, crop, 'newFile.jpeg');
				const response = await fetch(croppedImageUrl);
				const blob = await response.blob();
				const image = await uploadLogo(blob);
				setIsHospitalLogoModalLoading(true);
				setSrc('');
				const isLogoSaved = await saveLogo(sectorData.hospitalId, {
					picture: image.url,
				});
				let logo;
				if (isLogoSaved.error) {
					setFileError(intl.formatMessage({ id: 'errorDuringRequestProcessing' }));
					return;
				}
				if (isLogoSaved === '') {
					logo = await getLogoUrl(sectorData.hospitalId);
					if (logo.error) {
						setError(intl.formatMessage({ id: 'errorDuringRequestProcessing' }));
						return;
					}
				}
				setIsUploadLogoModalOpen(false);
				setIsEditSectorModalOpen(true);
				setIsHospitalLogoModalLoading(false);
				setHospitalLogo(logo);
			}
		} catch (e) {
			setError(`${intl.formatMessage({ id: 'errorOccurred' })} ${e.message}`);
		}
	};

	const getCroppedImg = (image, cropObj, fileName) => {
		const canvas = document.createElement('canvas');
		const scale = image.naturalWidth > 580 ? image.naturalWidth / 580 : 1;
		canvas.width = cropObj.width * scale;
		canvas.height = cropObj.height * scale;
		const ctx = canvas.getContext('2d');
		ctx.drawImage(
			image,
			cropObj.x * scale,
			cropObj.y * scale,
			cropObj.width * scale,
			cropObj.height * scale,
			0,
			0,
			cropObj.width * scale,
			cropObj.height * scale
		);
		return new Promise((resolve, reject) => {
			canvas.toBlob(blob => {
				if (!blob) {
					return;
				}
				// eslint-disable-next-line no-param-reassign
				blob.name = fileName;
				window.URL.revokeObjectURL(fileUrl);
				fileUrl = window.URL.createObjectURL(blob);
				const reader = new FileReader();
				reader.onerror = reject;
				reader.onloadend = () => resolve(fileUrl);
				reader.readAsDataURL(blob);
			}, 'image/png');
		});
	};

	const toggleHospitalLogoCrop = () => {
		setIsUploadLogoModalOpen(prevState => !prevState);
		setCrop(prevState => ({ ...prevState, unit: '%', width: 100, height: 100 }));
	};

	const exportAsCsv = () => {
		return devices.map(({ serialNumber, macAddress, appVersion, firmwareRevision, osVersion, solHelloDeviceId }) => ({
			serialNumber,
			room: getHierarchyByHelloDeviceId(treeData, solHelloDeviceId),
			macAddress,
			appVersion,
			osVersion: firmwareRevision,
			androidVersion: getCodeNameByOsVersion(osVersion),
		}));
	};

	const updateVCCList = (solHelloDeviceIds, isChecked) => {
		const sector = _.cloneDeep(currentSector);
		solHelloDeviceIds.forEach(solHelloDeviceId => {
			const device = findDeviceById(sector, solHelloDeviceId);
			if (device) {
				device.voiceCommandCalling = isChecked;
			}
		});

		if (sector) {
			setCurrentSector(sector);
		}
	};

	const hasHospitalFloors = () =>
		[TreeHierarchyType.DEFAULT_TREE, TreeHierarchyType.HOSPITAL_DEPT_FLOOR_ROOM, TreeHierarchyType.HOSPITAL_FLOOR_ROOM].includes(
			treeHierarchyTypeId
		);

	const hasHospitalDepts = () =>
		[TreeHierarchyType.DEFAULT_TREE, TreeHierarchyType.HOSPITAL_DEPT_FLOOR_ROOM, TreeHierarchyType.HOSPITAL_DEPT_ROOM].includes(
			treeHierarchyTypeId
		);

	const onPageChange = async (pageSize, pageIndex) => {
		setPagination(prevState => ({ ...prevState, pageSize, pageIndex }));
	};

	const getDescriptionData = () => {
		let result = [
			{
				title: translate('roomsInThisHospital'),
				description: sections.departments.length,
				id: 0,
			},
		];
		if (!healthSystems.isRoomsOnlyView) {
			result = [
				hasHospitalDepts() && {
					title: translate('departmentsInThisHospital'),
					description: sections.departments.length,
					id: 0,
				},
				hasHospitalFloors() && {
					title: translate('floorsInThisHospital'),
					description: sections.floors.length,
					id: 1,
				},
				{ title: translate('roomsInThisHospital'), description: sections.rooms.length, id: 2 },
				{ title: translate('enrolledDevices'), description: devices ? devices.length : 0, id: 3 },
			];
		}
		return result;
	};

	const getRoomsData = () => sections[!healthSystems.isRoomsOnlyView ? 'rooms' : 'departments'];

	const isPatientDisabled = () =>
		getConfigurationValue(companyConfigurations[CompanySettings.PATIENT_VIRTUAL_ADMISSION]) &&
		!getConfigurationValue(companyConfigurations[CompanySettings.PATIENT_MANUAL_ADMISSION]);

	const isPatientAssigned = device => !device?.isVirtualPatientAssigned && !device?.isDefaultOwner;

	useEffect(() => {
		const getTreeData = async () => {
			await fetchTreeData();
		};
		getTreeData();
	}, [isPinLockOpen]);

	return (
		<div className='organization'>
			<h3>
				<Button
					text={translate('deleteHospital')}
					imgIcon={`${healthCareCdnUrl}admin/delete-room.svg`}
					variant='red'
					onClick={() =>
						initDeleteOrg({
							level: DeviceListLevel.HOSPITAL,
							id: props.match.params.hospitalId,
							name: currentHospital.hospitalName,
						})
					}
				/>
				<Button
					text={translate('editDetails')}
					onClick={() => openEditHospitalModal(currentHospital)}
					imgIcon={`${healthCareCdnUrl}admin/edit.svg`}
					variant='yellow'
				/>
				<Dropdown position='bottom' icon='expand_more' className='organization-dropdown'>
					<Button
						text={translate('addDoctor')}
						imgIcon={`${healthCareCdnUrl}admin/add-doctor.svg`}
						onClick={() => setIsAddDoctorShown(true)}
					/>
					<Button
						variant='white'
						imgIcon={`${healthCareCdnUrl}admin/visiting-hours.svg`}
						onClick={() => setIsVisitingHoursModalOpen(true)}
						text={intl.formatMessage({ id: 'visitingHours' })}
					/>
					{devices.length > 0 && (
						<>
							<div className='csv-link'>
								<CSVLink
									className='button white'
									data-cy='exportAsCSVBtn'
									data={exportAsCsv()}
									filename={`${currentHospital.hospitalName}-${(+new Date()).toString()}.csv`}>
									{translate('exportAsCSV')}
								</CSVLink>
							</div>
							<Button
								variant='white'
								text={intl.formatMessage({ id: 'voiceCommandCalling' })}
								size='medium'
								imgIcon={`${healthCareCdnUrl}admin/voice-command-calling.svg`}
								onClick={() => setVoiceCommandCallingModalOpen(prev => !prev)}
							/>
							{isAlexaPropertiesEnabled && (
								<Button
									variant='white blue-icon'
									text={intl.formatMessage({ id: 'alexaSmartProperties' })}
									size='medium'
									icon='home_max'
									onClick={() => setIsAlexaSmartPropertiesOpen(prev => !prev)}
								/>
							)}
							<Button
								variant='white blue-icon'
								text={intl.formatMessage({ id: 'pinLock' })}
								size='medium'
								imgIcon={`${healthCareCdnUrl}admin/pin-lock.svg`}
								onClick={() => setIsPinLockOpen(prev => !prev)}
							/>
						</>
					)}
					<Button
						variant='white'
						imgIcon={`${healthCareCdnUrl}admin/visiting-hours.svg`}
						onClick={() => setIsTimeZoneModalOpen(true)}
						text={intl.formatMessage({ id: 'setTimeZone' })}
					/>
				</Dropdown>
			</h3>
			<DescriptionBox data={getDescriptionData()} />
			<Tabs activeIndex={currentTab} onChange={index => setCurrentTab(index)}>
				<TabList>
					<Tab>{translate('devices')}</Tab>
					<Tab>{translate('rooms')}</Tab>
					{hasHospitalFloors() && !healthSystems.isRoomsOnlyView && <Tab>{translate('floors')}</Tab>}
					{hasHospitalDepts() && !healthSystems.isRoomsOnlyView && <Tab>{translate('departments')}</Tab>}
					<Tab>{translate('doctorPooling')}</Tab>
					<Tab>{translate('visitingHours')}</Tab>
					{getUserRole() !== UserRoles.SUPER_USER && <Tab>{translate('featureFlags')}</Tab>}
				</TabList>
				<TabPanels>
					<TabPanel>
						<Table
							headers={[
								{ title: `${intl.formatMessage({ id: 'room' })}`, id: 0 },
								...(isPatientDisabled() ? [] : [{ title: translate('patient'), id: 1 }]),
								{ title: translate('deviceSerialNumber'), id: 2 },
								{ title: translate('macAddress'), id: 3 },
								{ title: translate('appVersion'), id: 4 },
								{ title: translate('androidVersion'), id: 5 },
								{ title: translate('osVersion'), id: 6 },
								{ title: translate('ipAddress'), id: 7 },
								{ title: 'SSID', id: 8 },
								{ title: translate('connectionType'), id: 9 },
								{ title: translate('voiceCommandCalling'), id: 10 },
								{ title: translate('actions'), id: 11 },
							]}
							rows={displayDevices()}
							className='admin-table'
							isEditable={false}
							isLoading={isTableDataLoading}
						/>
					</TabPanel>
					<TabPanel>
						<Table headers={[{ title: translate('roomNames'), id: 0 }]} rows={getRoomsData()} isEditable={false} />
					</TabPanel>
					{hasHospitalFloors() && !healthSystems.isRoomsOnlyView && (
						<TabPanel>
							<Table headers={[{ title: translate('floorNames'), id: 0 }]} rows={sections.floors} isEditable={false} />
						</TabPanel>
					)}
					{hasHospitalDepts() && !healthSystems.isRoomsOnlyView && (
						<TabPanel>
							<Table headers={[{ title: translate('departmentNames'), id: 0 }]} rows={sections.departments} isEditable={false} />
						</TabPanel>
					)}
					<TabPanel>
						<Table headers={[{ title: translate('doctorPooling'), id: 0 }]} rows={doctors} isEditable={false} />
						{doctorsTotalCount > 10 && (
							<Pagination
								totalCount={doctorsTotalCount}
								pageSize={pagination.pageSize}
								pageIndex={pagination.pageIndex}
								onChange={(pageSize, pageIndex) => onPageChange(pageSize, pageIndex)}
							/>
						)}
					</TabPanel>
					<TabPanel>
						<Table
							headers={[
								{ title: translate('monday'), id: 0 },
								{ title: translate('tuesday'), id: 1 },
								{ title: translate('wednesday'), id: 2 },
								{ title: translate('thursday'), id: 3 },
								{ title: translate('friday'), id: 4 },
								{ title: translate('saturday'), id: 5 },
								{ title: translate('sunday'), id: 6 },
							]}
							rows={visitingHours}
							className='admin-table'
							isEditable={false}
						/>
					</TabPanel>
					{getUserRole() !== UserRoles.SUPER_USER && (
						<TabPanel>
							<MonitoringAiFeatureFlags
								healthSystemId={userSession.healthSystem.id}
								levelId={props.match.params.hospitalId}
								currentSector={DeviceListLevel.HOSPITAL}
								setError={setError}
							/>
						</TabPanel>
					)}
				</TabPanels>
			</Tabs>
			<Modal
				modalSelector='deleteHospitalModal'
				display={isDeleteOrgUnitModalOpen}
				position='center'
				primaryButtonLabel={translate('delete')}
				onModalSubmit={deleteOrgUnitSubmit}
				onModalClose={toggleDeleteOrgUnitModal}>
				<Form title={translate('warning')} onSubmit={event => event.preventDefault()}>
					<p className='paragraph-break-word'>
						{translate('sureToDeleteOrg', {
							value: `${intl.formatMessage({ id: getLevelDescriptionById(selectedOrg.level) })} ${selectedOrg.name}`,
						})}
					</p>
				</Form>
			</Modal>
			<Modal
				className='crop-modal'
				display={isUploadLogoModalOpen}
				position='center'
				submitButtonText='Save'
				onModalSubmit={makeClientCrop}
				onModalClose={toggleHospitalLogoCrop}
				isLoading={isHospitalLogoModalLoading}>
				{src && (
					<div>
						<ReactCrop crop={crop} onChange={newCrop => setCrop(newCrop)}>
							<img ref={imgRef} alt='ico' src={src} onLoad={onImageLoad} />
						</ReactCrop>
					</div>
				)}
			</Modal>
			{isEditSectorModalOpen && (
				<EditSectorModal
					onEditSector={fetchTreeData}
					onModalClose={() => setIsEditSectorModalOpen(false)}
					isEditSectorModalOpen={true}
					sectorData={sectorData}
					onSelectFile={onSelectFile}
					hospitalLogo={hospitalLogo}
					fileError={fileError}
				/>
			)}
			{isAddDoctorShown && (
				<AssignDoctor
					onSubmit={onAssignDoctorSubmit}
					onModalClose={() => setIsAddDoctorShown(false)}
					levelId={props.match.params.hospitalId}
					doctors={doctors}
				/>
			)}
			{selectedDoctor && (
				<Modal
					display={true}
					position='center'
					primaryButtonLabel={translate('delete')}
					onModalSubmit={deleteDoctor}
					onModalClose={() => setSelectedDoctor(null)}>
					<Form title={translate('deleteDoctor')} onSubmit={event => event.preventDefault()}>
						<p>
							{translate('sureToDeleteDoctor', {
								value: `${selectedDoctor.firstName} ${selectedDoctor.lastName}`,
							})}
						</p>
					</Form>
				</Modal>
			)}
			<Alert display={error} message={error} variant='dark' fixed={true} onClose={() => setError(null)} />
			<VoiceCommandCallingModal
				currentSector={currentSector}
				isVoiceCommandCallingModalOpen={isVoiceCommandCallingModalOpen}
				toggleVoiceCommandCallingModal={() => setVoiceCommandCallingModalOpen(prev => !prev)}
				updateVoiceCommandCallingList={updateVCCList}
			/>
			{isVisitingHoursModalOpen && (
				<VisitingHoursConfiguration setIsVisitingHoursModalOpen={setIsVisitingHoursModalOpen} sector={currentHospital} />
			)}
			{isTimeZoneModalOpen && (
				<SelectTimeZone onModalClose={() => setIsTimeZoneModalOpen(false)} currentHospital={currentHospital} />
			)}
			{isAlexaSmartPropertiesOpen && (
				<AlexaSmartPropertiesWrapper
					sector={currentHospital}
					setIsAlexaSmartPropertiesOpen={setIsAlexaSmartPropertiesOpen}
					isAlexaSmartPropertiesOpen={isAlexaSmartPropertiesOpen}
				/>
			)}
			{isPinLockOpen && (
				<PinLockWrapper
					pin={currentHospital.pin}
					isPinLockOpen={isPinLockOpen}
					setIsPinLockOpen={setIsPinLockOpen}
					healthSystemId={userSession.healthSystem.id}
					regionId={userSession.regionId}
					hospitalId={props.match.params.hospitalId}
				/>
			)}
		</div>
	);
};

export default Hospital;
