import React, { useState } from 'react';
import translate from 'i18n-translations/translate.jsx';
import classNames from 'classnames';

const RoomInfo = () => {
	const [expanded, setExpanded] = useState(true);

	return (
		<>
			<div className={classNames('timeline-box-header sub-timeline-box-header', expanded ? 'expanded' : '')}>
				<p className='timeline-box-title'>{translate('roomInfo')}</p>
				<div className='timeline-box-actions'>
					<button type='button' onClick={() => setExpanded(prevState => !prevState)}>
						<i className={classNames('material-icons', expanded ? ' rotate' : '')}>expand_more</i>
					</button>
				</div>
			</div>
			{expanded && (
				<table className='patient-table-informations' style={{ width: '100%' }}>
					<tbody>
						<tr>
							<td>{translate('rapidResponse')}</td>
							<td>+1 (346) 231-4444</td>
						</tr>
						<tr>
							<td>{translate('roomServiceNumber')}</td>
							<td>+1 (346) 231-6368</td>
						</tr>
					</tbody>
				</table>
			)}
		</>
	);
};

export default RoomInfo;
