import * as React from 'react';
import LightTheme from 'calls/styles/LightTheme.js';

const InfusionRoom = props => (
	<svg width={29} height={28} fill='none' xmlns='http://www.w3.org/2000/svg' color={props.color} {...props}>
		<path
			d='M26 19.096v-3.54a2.722 2.722 0 0 0-2.722-2.723v0a2.722 2.722 0 0 0-2.723 2.723v4.666a5.445 5.445 0 0 1-5.444 5.445v0a5.444 5.444 0 0 1-5.445-5.445v-.082'
			stroke='currentColor'
			strokeWidth={props.strokeWidth}
			strokeLinecap='round'
		/>
		<path
			d='m4.913 16.066 1.991 1.66c.272.226.43.562.43.916 0 .658.533 1.191 1.191 1.191h2.283c.658 0 1.192-.533 1.192-1.191 0-.354.157-.69.429-.916l1.992-1.66a3 3 0 0 0 1.079-2.304V5.333a3 3 0 0 0-3-3H6.833a3 3 0 0 0-3 3v8.429a3 3 0 0 0 1.08 2.304Z'
			stroke='currentColor'
			strokeWidth={props.strokeWidth}
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M3.833 5.833h1.75M3.833 9.333h1.75M3.833 12.833h1.75M24.833 20.417c0-.645.523-1.167 1.167-1.167v0c.644 0 1.167.522 1.167 1.167v1.166c0 .645-.523 1.167-1.167 1.167v0a1.167 1.167 0 0 1-1.167-1.167v-1.166ZM26 22.75v1.167'
			stroke='currentColor'
			strokeWidth={props.strokeWidth}
			strokeLinecap='round'
		/>
	</svg>
);

InfusionRoom.defaultProps = {
	color: LightTheme.colors.graySeven,
	strokeWidth: 2,
};

export default InfusionRoom;
