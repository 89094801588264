import React, { useState } from 'react';
import PropTypes from 'prop-types';
import NavigationMenu from 'containers/LandingPage/NavigationMenu';
import Footer from 'components/LandingPage/GeneralPages/Footer';
import Modal from 'components/Modal.jsx';
import { AlertTypes, CookiesAnswer } from 'constants/enums.js';
import PopUpAlert from 'components/PopUpAlert.jsx';
import { getStorage } from 'infrastructure/helpers/commonHelpers.js';

const Layout = props => {
	const [isNavBarVisible, setNavBarVisible] = useState(false);
	const [isCookiesAlertVisible, setIsCookiesAlertVisible] = useState(true);
	const [isCookiesModalVisible, setIsCookiesModalVisible] = useState(false);
	const [cookiePreferences, setCookiePreferences] = useState({
		targetingCookies: false,
		functionalCookies: false,
		performanceCookies: false,
	});
	const [activeCookieDetail, setActiveCookieDetail] = useState(null);

	const acceptCookies = () => {
		setCookiePreferences({
			targetingCookies: true,
			functionalCookies: true,
			performanceCookies: true,
		});
		getStorage().setItem(
			'cookiePreferences',
			JSON.stringify({
				targetingCookies: true,
				functionalCookies: true,
				performanceCookies: true,
			})
		);
		getStorage().setItem('cookieAnswer', CookiesAnswer.YES.toString());
		setIsCookiesAlertVisible(false);
		setIsCookiesModalVisible(false);
	};
	const onChange = event => {
		const preferences = { ...cookiePreferences };
		preferences[event.target.name] = event.target.checked;
		setCookiePreferences(preferences);
	};
	const manageChoices = () => {
		getStorage().setItem('cookiePreferences', JSON.stringify(cookiePreferences));
		getStorage().setItem('cookieAnswer', CookiesAnswer.YES.toString());
		setIsCookiesModalVisible(false);
	};
	const cookieTypes = [
		{
			id: 0,
			title: 'Targeting Cookies',
			name: 'targetingCookies',
			content:
				'These cookies may be set through our site by our advertising partners. They may be used by those companies to track your usage of our website and, depending on their policies, may combine that information with other information to show you relevant advertisements on other sites. If you do not allow these cookies, you may experience less relevant advertising.',
		},
		{
			id: 1,
			title: 'Functional Cookies',
			name: 'functionalCookies',
			content:
				'These cookies enable the website to provide enhanced functionality and customization. They may be set by us or by third party providers whose services we have added to our pages. If you do not allow these cookies then some or all of these services may not function properly.',
		},
		{
			id: 2,
			title: 'Performance Cookies',
			name: 'performanceCookies',
			content:
				'These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site. If you do not allow these cookies we will not know when you have visited our site, and will not be able to monitor its performance.',
		},
	];
	const setCookieDetailIndex = id => {
		if (activeCookieDetail === id) {
			setActiveCookieDetail(null);
		} else {
			setActiveCookieDetail(id);
		}
	};

	return (
		<div
			className={`lp-wrapper ${props.className} ${props.isSubPage ? ' sub-page-wrapper' : ''}`}
			style={{ overflow: isNavBarVisible ? 'hidden' : 'auto', overflowX: 'hidden' }}>
			<NavigationMenu isNavBarVisible={isNavBarVisible} setNavBarVisible={setNavBarVisible} isSubPage={props.isSubPage} />
			{props.children}
			{getStorage().getItem('cookieAnswer') !== CookiesAnswer.YES.toString() && (
				<PopUpAlert
					alertType={AlertTypes.INFORMATION}
					display={isCookiesAlertVisible}
					onAlertClose={acceptCookies}
					className='cookies-alert'
					contentText={
						<>
							This site uses cookies to enhance your user experience. Accept or manage the usage of cookies.
							<br />
							<button type='button' onClick={acceptCookies}>
								Accept Cookies
							</button>
							<button type='button' onClick={() => setIsCookiesModalVisible(true)}>
								Manage Cookies
							</button>
						</>
					}
					isSilent={true}
					isBottom={true}
					removeCloseBtn={true}
				/>
			)}
			{getStorage().getItem('cookieAnswer') !== CookiesAnswer.YES.toString() && (
				<Modal
					className='standard-modal-wrapper modal-wrapper-wo-btn cookies-modal'
					position='center'
					display={isCookiesModalVisible}
					onModalClose={() => setIsCookiesModalVisible(false)}>
					<div className='standard-modal-inner modal-wrapper-wo-btn-inner'>
						<h2>Cookies Preference Center</h2>
						<p>
							Cookies and other similar technologies are import to the proper functioning of a site and to provide visitors with a
							seamless and customized experience. <span className='bold'>hellocare</span> uses cookies to enable you to use our
							site. We also use cookies to enable you to personalize your use of our site, to provide you enhanced functionality
							and to continuously improve the performance of our site. We may allow third-party advertisers to show you
							advertising relevant to you on our website, using their cookies on our site. You can accept or decline all but
							Strictly Necessary cookies and go directly to the site, or customize your cookie settings below.
						</p>
						<button type='button' onClick={acceptCookies}>
							Accept All Cookies
						</button>
						<h3>Manage Consent Preferences</h3>
						<form>
							{cookieTypes.map(item => (
								<div key={item.id}>
									<div className='flex'>
										<div className='position-absolute cursor-pointer' onClick={() => setCookieDetailIndex(item.id)} />
										<i className='material-icons'>
											{activeCookieDetail !== null && activeCookieDetail === item.id ? 'remove' : 'add'}
										</i>
										<div className='cookie-label'>
											<span>{item.title}</span>
											<label className='rounded-slider-switch'>
												<input type='checkbox' name={item.name} onChange={onChange} />
												<span className='rounded-slider' />
											</label>
										</div>
									</div>
									{activeCookieDetail !== null && activeCookieDetail === item.id && (
										<p className='cookie-content'>{item.content}</p>
									)}
								</div>
							))}
							<div className='cookie-label'>
								<span>Strictly Necessary Cookies</span> <span>Always Active</span>
							</div>
							<button type='button' onClick={manageChoices}>
								Confirm My Choices
							</button>
						</form>
					</div>
				</Modal>
			)}
			<Footer isSubPage={props.isSubPage} />
		</div>
	);
};

Layout.defaultProps = {
	className: '',
};

Layout.protoTypes = {
	className: PropTypes.string,
	children: PropTypes.element.isRequired,
};

export default Layout;
