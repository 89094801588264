import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import translate from 'i18n-translations/translate.jsx';
import { handleOnKeyDownNumeric } from 'infrastructure/helpers/commonHelpers.js';
import {
    InfusionRoomIcon,
    InjectionRoomIcon,
    PillIcon
} from 'calls/icons/index.js';
import { PrimaryCareForms } from 'constants/visitEnums.js';
import { Icon } from 'calls/components/index.js';

const ParenteralTherapyForm = props => {
    const intl = useIntl();
    const [medicalId, setMedicalID] = useState(1);

    const handleMedicalValues = (value, type, medicalIndex) => {
        let result = value;
        const maxParenteralInputsValue = 200;
        if (type !== 'name' && +value > maxParenteralInputsValue) {
            result = maxParenteralInputsValue;
        }
        props.setAddedForms(prevState => {
            const formsCopied = _.cloneDeep(prevState);
            formsCopied[props.index].medicationList[medicalIndex][type] = result;
            return formsCopied;
        });
    };

    const addAnotherMedication = (formType) => {
        const maxMedicationList = 5;
        if (props.addedForms[props.index].medicationList.length === maxMedicationList) {
            return;
        }

        if (formType === PrimaryCareForms.DRUGS) {
            props.setAddedForms(prevState => {
                const formsCopied = [...prevState];
                formsCopied[props.index].medicationList.push({
                    id: medicalId,
                    name: '',
                });
                return formsCopied;
            });
            return;
        }

        props.setAddedForms(prevState => {
            const formsCopied = [...prevState];
            formsCopied[props.index].medicationList.push({
                id: medicalId,
                name: '',
                dosage: '',
                frequency: '',
                duration: '',
            });
            return formsCopied;
        });
        setMedicalID(prevState => prevState + 1);
    };

    const removeMedication = value => {
        props.setAddedForms(prevState => {
            const formsCopied = [...prevState];
            const medicalsCopied = formsCopied[props.index].medicationList.filter(item => !_.isEqual(item, value));
            formsCopied[props.index].medicationList = medicalsCopied;
            return formsCopied;
        });
    };

    return (
        <div key={props.item.id} className={`form-item ${props.editable ? '' : 'disabled'}`}>
            <div className='flex'>
                <h3>
                    <span>{props.item.code}</span>
                    <br />
                    {props.item.title}
                </h3>
                {props.editable && (
                    <div
                        className='flex cursor-pointer --blue-color'
                        onClick={() => props.setAddedForms(prevState => [...prevState.filter(form => form.id !== props.item.id)])}>
                        <i className='material-icons-outlined'>close</i> {translate('remove')}
                    </div>
                )}
            </div>
            <form key={props.item.id} className='parenteral-therapy' onSubmit={e => e.preventDefault()}>
                {props.item.medicationList.map((medical, medicalIndex) => (
                    <div key={medical.id} className='values position-relative full-width'>
                        {props.item.id === PrimaryCareForms.PTH_INFUSION &&
                            <span className='infusions'>
                                <InfusionRoomIcon />
                            </span>}
                        {props.item.id === PrimaryCareForms.PTH_INJECTION &&
                            <span className='injections'>
                                <InjectionRoomIcon />
                            </span>}
                        {props.item.id === PrimaryCareForms.DRUGS &&
                            <span className='drugs'>
                                <PillIcon />
                            </span>}
                        {[PrimaryCareForms.PTH_INFUSION, PrimaryCareForms.PTH_INJECTION].includes(props.item.id) &&
                            <>
                                <label>
                                    *
                                    {intl.formatMessage({
                                        id: 'medication',
                                    })}
                                    <input
                                        placeholder={intl.formatMessage({ id: 'medicationNameHere' })}
                                        type='text'
                                        name='name'
                                        value={medical.name}
                                        onChange={event => handleMedicalValues(event.target.value, 'name', medicalIndex)}
                                    />
                                </label>
                                <label>
                                    *
                                    {intl.formatMessage({
                                        id: 'dosage',
                                    })}
                                    <div className='position-relative'>
                                        <input
                                            max={99}
                                            placeholder='--'
                                            type='number'
                                            name='dosage'
                                            value={medical.dosage}
                                            onKeyDown={e => handleOnKeyDownNumeric(e, true)}
                                            onChange={event => handleMedicalValues(event.target.value, 'dosage', medicalIndex)}
                                        />
                                        <span>ml</span>
                                    </div>
                                </label>
                                <label>
                                    *
                                    {intl.formatMessage({
                                        id: 'frequency',
                                    })}
                                    <div className='position-relative'>
                                        <input
                                            max={99}
                                            placeholder='--'
                                            type='number'
                                            name='frequency'
                                            value={medical.frequency}
                                            onKeyDown={handleOnKeyDownNumeric}
                                            onChange={event => handleMedicalValues(event.target.value, 'frequency', medicalIndex)}
                                        />
                                        <span>{translate('timesADay')}</span>
                                    </div>
                                </label>
                                <label>
                                    *
                                    {intl.formatMessage({
                                        id: 'duration',
                                    })}
                                    <div className='position-relative'>
                                        <input
                                            max={99}
                                            placeholder='--'
                                            type='number'
                                            name='duration'
                                            value={medical.duration}
                                            onKeyDown={handleOnKeyDownNumeric}
                                            onChange={event => handleMedicalValues(event.target.value, 'duration', medicalIndex)}
                                        />
                                        <span>{translate('durationDays')}</span>
                                    </div>
                                </label>
                            </>
                        }
                        {PrimaryCareForms.DRUGS === props.item.id &&
                            <label className='drugs-label'>
                                *
                                {intl.formatMessage({
                                    id: 'medication',
                                })}
                                <input
                                    placeholder={intl.formatMessage({ id: 'medicationNameHere' })}
                                    type='text'
                                    name='name'
                                    value={medical.name}
                                    onChange={event => handleMedicalValues(event.target.value, 'name', medicalIndex)}
                                />
                            </label>
                        }
                        {props.item.medicationList.length > 1 && props.editable && (
                            <div className='cursor-pointer remove-medical' onClick={() => removeMedication(medical)}>
                                <Icon name='close' size={22} />
                            </div>
                        )}
                    </div>
                ))}
                {props.editable && (
                    <button type='button' onClick={() => addAnotherMedication(props.item.id)}>
                        <i className='material-icons'>add</i>
                        {translate('addAnotherMedication')}
                    </button>
                )}
            </form>
        </div>
    );
};

export default ParenteralTherapyForm;
