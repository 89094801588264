import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';

export const generateCustomStyles = ({ darkMode, menuListMaxHeight = 110, menuMaxHeight = 110 }) => ({
	control: provided => ({
		...provided,
		backgroundColor: darkMode && DarkTheme.colors.grayThree,
		color: darkMode && DarkTheme.colors.grayFive,
	}),
	option: provided => ({
		...provided,
		backgroundColor: darkMode && DarkTheme.colors.grayThree,
		color: darkMode && DarkTheme.colors.grayFive,
		':hover, :active': {
			backgroundColor: darkMode ? DarkTheme.colors.grayFour : LightTheme.colors.grayOne,
			color: darkMode && DarkTheme.colors.grayFive,
		},
	}),
	singleValue: provided => ({
		...provided,
		color: darkMode && DarkTheme.colors.grayFive,
	}),
	menu: provided => ({
		...provided,
		backgroundColor: darkMode ? DarkTheme.colors.grayThree : LightTheme.colors.grayZero,
		maxHeight: menuMaxHeight,
	}),
	menuList: provided => ({
		...provided,
		maxHeight: menuListMaxHeight,
		color: darkMode && DarkTheme.colors.grayFive,
	}),
	placeholder: provider => ({
		...provider,
		color: darkMode && DarkTheme.colors.grayFive,
	}),
	valueContainer: provider => ({
		...provider,
		color: darkMode && DarkTheme.colors.grayFive,
		backgroundColor: darkMode ? DarkTheme.colors.grayThree : LightTheme.colors.grayZero,
	}),
});
