import React from 'react';

const SitCenter = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none' viewBox='0 0 24 24' {...props}>
		<path
			stroke='currentColor'
			strokeMiterlimit={10}
			strokeWidth={1.25}
			d='M12.228 5.467a1.734 1.734 0 1 0 0-3.467 1.734 1.734 0 0 0 0 3.467ZM13.396 17.5a.941.941 0 0 1-.942-.942v-3.994a.94.94 0 0 1 .942-.94.941.941 0 0 1 .941.94v3.994a.942.942 0 0 1-.94.942ZM11.06 17.5a.941.941 0 0 1-.942-.942v-3.994a.94.94 0 1 1 1.883 0v3.994a.939.939 0 0 1-.94.942Z'
		/>
		<path
			stroke='currentColor'
			strokeMiterlimit={10}
			strokeWidth={1.25}
			d='m17.404 11.488-2.12-4.822a1.506 1.506 0 0 0-1.38-.898h-3.352a1.507 1.507 0 0 0-1.379.898L7.05 11.488a.717.717 0 0 0 1.311.576l1.614-3.67a.076.076 0 0 1 .144.03v3.533a1.122 1.122 0 0 1 2.064.591h.091a1.122 1.122 0 0 1 2.064-.593V8.428a.074.074 0 0 1 .059-.076.075.075 0 0 1 .085.045l1.614 3.671a.715.715 0 1 0 1.31-.576v-.004Z'
		/>
		<path
			stroke='currentColor'
			strokeLinejoin='round'
			strokeWidth={1.25}
			d='M14.3 16H19v-2h2v6h-2v-2H5v2H3v-6h2v2h5.1M11.8 12h.8M16.1 12h-2M14.3 14H23v-2h-5.6M10.1 14H1v-2h6M10.3 12H8.4'
		/>
	</svg>
);
export default SitCenter;
