import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { Modal, Form, Alert } from 'components';
import {
	addUpdateCompanionDevice,
	deleteCompanionDevice,
	getCompanionDevicesByRoom,
	getUnassignedCompanionDevices,
} from 'api/devices.js';
import { CompanionTypes } from 'constants/enums.js';
import FormInput from 'components/FormInput.jsx';

const Companion = props => {
	const { hospitalId, departmentId, floorId, roomId } = useParams();
	const [isAddDeviceModalLoading, setIsAddDeviceModalLoading] = useState(true);
	const [error, setError] = useState(null);
	const [companionDevice, setCompanionDevice] = useState(null);
	const [hasCompanion, setHasCompanion] = useState(false);
	const intl = useIntl();

	useEffect(() => {
		const fetchCompanionDevices = async () => {
			const response = await getCompanionDevicesByRoom(roomId, props.companionType);
			if (response.error) {
				setError(response.error.message);
				return;
			}
			if ('result' in response) {
				setHasCompanion(true);
				setCompanionDevice(response.result);
			}

			setIsAddDeviceModalLoading(false);
		};
		fetchCompanionDevices();
	}, [props.companionType, props.deviceId, roomId]);

	const getMappedCompanionDevices = async () => {
		const response = await getUnassignedCompanionDevices(props.companionType);
		if (response.error) {
			setError(response.error?.message);
			return [];
		}
		return response.result.map(device => ({
			...device,
			value: device.solHelloDeviceId,
			label: `${device.serialNumber} / ${device.deviceFamilyName}`,
		}));
	};

	const handleSubmit = async () => {
		if (!companionDevice) {
			setError(intl.formatMessage({ id: 'pleaseSelectCompanionDevice' }));
			return;
		}
		const { solHelloDeviceId } = companionDevice;
		const roomName = props.roomName;
		setIsAddDeviceModalLoading(true);

		const response = hasCompanion
			? await deleteCompanionDevice(solHelloDeviceId)
			: await addUpdateCompanionDevice({ solHelloDeviceId, roomName, hospitalId, departmentId, floorId, roomId });
		if (response.error) {
			setError(response.error?.message);
		}
		props.setCompanionType(false);
		setIsAddDeviceModalLoading(false);
	};

	const getDescriptionByCompanionType = () => {
		let result = '';
		switch (props.companionType) {
			case CompanionTypes.BED_SIDE: {
				result = 'manageBedside';
				break;
			}
			case CompanionTypes.FRONT_DOOR: {
				result = 'roomSignage';
				break;
			}
			case CompanionTypes.TV: {
				result = 'tv';
				break;
			}
			case CompanionTypes.WHITE_BOARD: {
				result = 'whiteboard';
				break;
			}
			default: {
				result = 'N/A';
			}
		}
		return result;
	};

	const renderFormField = () => {
		const includedDeviceKeys = ['serialNumber', 'deviceName', 'deviceFamilyName', 'companionModeName'];
		return (
			<Form title={intl.formatMessage({ id: getDescriptionByCompanionType() })} onSubmit={evt => evt.preventDefault()}>
				{hasCompanion &&
					Object.keys(companionDevice)
						.filter(key => includedDeviceKeys.includes(key))
						.map(item => (
							<FormInput
								key={item}
								text={intl.formatMessage({ id: item })}
								value={companionDevice[item]}
								labelClassName='header-searchbar'
								isDisabled
							/>
						))}
				{!hasCompanion && (
					<AsyncSelect
						name='serialNumber'
						id='serialNumber'
						placeholder={intl.formatMessage({ id: 'serialNumber' })}
						loadOptions={getMappedCompanionDevices}
						defaultOptions
						cacheOptions
						value={companionDevice}
						onChange={setCompanionDevice}
					/>
				)}
			</Form>
		);
	};

	return (
		<Modal
			modalSelector={getDescriptionByCompanionType()}
			display={true}
			isLoading={isAddDeviceModalLoading}
			onModalSubmit={handleSubmit}
			position='center'
			className='assign-patients-modal'
			onModalClose={() => props.setCompanionType(prevState => !prevState)}
			hideCloseButton={true}
			primaryButtonVariant={hasCompanion ? 'red' : ''}
			primaryButtonLabel={intl.formatMessage({ id: hasCompanion ? 'unassign' : 'save' })}>
			<div>
				{renderFormField()}
				<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
			</div>
		</Modal>
	);
};

export default Companion;
