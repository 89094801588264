import React from 'react';


const LifeSignalsPopup = props => (
	<div className='monitoring-timeline-wrapper no-padding'>
		<span className='timeline-close cursor-pointer frame-timeline-close' onClick={() => props.onClose()}>
			<i className='material-icons-outlined'>close</i>
		</span>
		<iframe
			src={`https://us-trials-2.lifesignals.com/#/dashboard/dashboard-zoom-view/${props.lifeSignalsWearableId}`}
			title='LifeSignals'
		/>
	</div>
);

export default LifeSignalsPopup;
