import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';

export const getActiveDirectories = async ({ pageSize = 10, pageIndex = 0, healthSystemId = null, hospitalId = null }) => {
	try {
		const { data } = await gatewayApi.get(`v1/healthcare/organizations/${getCompanyId()}/configurations/active-directories`, {
			params: {
				pageSize,
				pageIndex,
				healthSystemId,
				hospitalId,
			},
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const getActiveDirectoriesHistory = async ({ id, pageSize = 10, pageIndex = 0 }) => {
	try {
		const { data } = await gatewayApi.get(
			`v1/healthcare/organizations/${getCompanyId()}/configurations/active-directories/${id}/history`,
			{
				params: {
					pageSize,
					pageIndex,
				},
			}
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getADGroupRoles = async ({ pageSize = 10, pageIndex = 0, healthSystemId = null, hospitalId = null }) => {
	try {
		const { data } = await gatewayApi.get(
			`v1/healthcare/organizations/${getCompanyId()}/configurations/active-directories/group-roles`,
			{
				params: {
					pageSize,
					pageIndex,
					healthSystemId,
					hospitalId,
				},
			}
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const updateActiveDirectory = async (id, activeDirectory) => {
	try {
		const { data } = await gatewayApi.put(
			`v1/healthcare/organizations/${getCompanyId()}/configurations/active-directories/${id}`,
			activeDirectory
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const addActiveDirectory = async activeDirectory => {
	try {
		const { data } = await gatewayApi.post(
			`v1/healthcare/organizations/${getCompanyId()}/configurations/active-directories`,
			activeDirectory
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const addGroupRole = async groupRole => {
	try {
		const dataForm = {
			hospitalId: groupRole.hospitalId,
			roleId: +groupRole.roleId,
			group: groupRole.group,
			healthSystemId: groupRole.healthSystemId,
		};
		const { data } = await gatewayApi.post(
			`v1/healthcare/organizations/${getCompanyId()}/configurations/active-directories/group-roles`,
			dataForm
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const updateGroupRole = async (id, groupRole) => {
	try {
		const dataForm = {
			hospitalId: groupRole.hospitalId,
			roleId: +groupRole.roleId,
			group: groupRole.group,
			healthSystemId: groupRole.healthSystemId,
		};
		const { data } = await gatewayApi.put(
			`v1/healthcare/organizations/${getCompanyId()}/configurations/active-directories/group-roles/${id}`,
			dataForm
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const deleteActiveDirectory = async id => {
	try {
		const { data } = await gatewayApi.delete(
			`v1/healthcare/organizations/${getCompanyId()}/configurations/active-directories/${id}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const deleteGroupRole = async id => {
	try {
		const { data } = await gatewayApi.delete(
			`v1/healthcare/organizations/${getCompanyId()}/configurations/active-directories/group-roles/${id}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const companyLevelExists = async params => {
	try {
		const { data } = await gatewayApi.post(
			`v1/healthcare/organizations/${getCompanyId()}/configurations/active-directories/company-level-exists`,
			params
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const createAdUserInvite = async params => {
	try {
		const { data } = await gatewayApi.post(`v1/tasks/companies/${getCompanyId()}/ad-users/sync`, params);
		return data;
	} catch (error) {
		return { error };
	}
};

export const companyAdUserExists = async (email, params) => {
	try {
		const { data } = await gatewayApi.post(`v1/healthcare/organizations/${getCompanyId()}/ad-users/${email}/exists`, params);

		return data;
	} catch (error) {
		return { error };
	}
};

export const companyLevelAdData = async params => {
	try {
		const { data } = await gatewayApi.post(
			`v1/healthcare/organizations/${getCompanyId()}/configurations/active-directories/company-level`,
			params
		);
		return data;
	} catch (error) {
		return { error };
	}
};
