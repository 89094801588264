import React, { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';
import Calendar from 'react-calendar';
import moment from 'moment';
import { useIntl } from 'react-intl';
import translate from 'i18n-translations/translate.jsx';
import { Alert, Grid, Loader } from 'components';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { getCalendarClass, getPhysicalExerciseIcon } from 'infrastructure/helpers/physicalExerciseHelper';
import { HttpStatusCodes, IntensityTypes } from 'constants/enums.js';
import { editExercise, getExerciseById, getExerciseSettingTypes } from 'api/physicalExercises';
import { stringToCamelCase } from 'infrastructure/helpers/commonHelpers.js';

const EditPTReport = props => {
	const [intensities, setIntensities] = useState([]);
	const [selectedDate, setSelectedDate] = useState(new Date(props.exerciseToEdit.date));
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState('');
	const [validationError, setValidationError] = useState(null);
	const [selectedIntensityType, setSelectedIntensityType] = useState(null);
	const intl = useIntl();

	useEffect(() => {
		fetchExerciseSettingTypes(props.exerciseToEdit);
	}, [props.exerciseToEdit]);

	const transformIntensities = array =>
		array.map(item => ({
			value: item,
			label: item,
		}));

	const changeSelectedIntensityValue = (event, intensity) => {
		setIntensities(prevState => {
			const newIntensities = [...prevState];
			const selectedIntensity = newIntensities.find(item => item.type === intensity.type);
			selectedIntensity.selectedValue = selectedIntensity.values.find(val => val === event.value) ?? +event.value;
			return newIntensities;
		});
		setValidationError(null);
	};

	const fetchExerciseSettingTypes = async exercise => {
		const response = await getExerciseSettingTypes(exercise.type.id || exercise.type);
		const exerciseDetails = await getExerciseById(exercise.id);
		if (response.error || exerciseDetails.error) {
			setError(response.error.message);
		} else {
			const { item } = response;
			const selectedIntensities = item.settingsType.map(settingType => ({
				...settingType,
				selectedValue: exerciseDetails.item.settingsExercise.find(setting => setting.type === settingType.type)?.value ?? null,
			}));
			setIntensities(selectedIntensities);
		}
		setIsLoading(false);
	};

	const transformSettingsExercise = () =>
		intensities.map(item => ({
			value: item.selectedValue,
			type: item.type,
		}));

	const editExerciseData = async () => {
		const dateToSave = moment(selectedDate).format('MM/DD/YYYY');

		const dataToSend = {
			typeId: props.exerciseToEdit.type.id,
			categoryId: props.exerciseToEdit.category.id,
			date: dateToSave,
			settingsExercise: transformSettingsExercise(),
		};
		const response = await editExercise(props.exerciseToEdit.id, dataToSend, props.selectedPhysicalTherapyId);
		if (response.error) {
			const errorMessage =
				response.error.response.status === HttpStatusCodes.CONFLICT
					? response.error.response.data.message
					: response.error.message;
			setError(errorMessage);
			return;
		}

		props.setPtPrescriptionReport(prevState => {
			const reportsCopied = [...prevState];
			const foundIndex = reportsCopied.findIndex(report => report.id === props.exerciseToEdit.id);
			reportsCopied[foundIndex].date = dateToSave;
			return reportsCopied;
		});
		fetchExerciseSettingTypes(response.item);
		props.setSelectedView(props.viewToShow.PT_PRESCRIPTION);
	};

	const validateIntensityValues = (event, intensity) => {
		const { value } = event.target;
		const numValue = parseInt(value, 10);
		const intensityValues = intensity.values;
		const minValue = intensity.type === IntensityTypes.MAX_RANGE_OF_MOTION ? 0 : intensityValues.reduce((a, b) => Math.min(a, b));
		const maxValue =
			intensity.type === IntensityTypes.MAX_RANGE_OF_MOTION ? 100 : intensityValues.reduce((a, b) => Math.max(a, b));
		setSelectedIntensityType(intensity.type);
		if (/^[0-9]*$/.test(value) && numValue >= minValue && numValue <= maxValue) {
			setValidationError(null);
			return;
		}
		if (numValue < minValue || numValue > maxValue) {
			setValidationError(
				translate('rangeExceeded', {
					value1: minValue,
					value2: maxValue,
				})
			);
			return;
		}
		setValidationError(translate('containOnlyNumbers'));
	};

	return (
		<>
			{isLoading && (
				<Grid columns='1fr' rows='1fr' stretch='calc(100vh - 200px)' horizAlign='center' vertAlign='center'>
					<div className='text-align-center'>
						<Loader />
					</div>
				</Grid>
			)}
			{!isLoading && (
				<div className='patient-summary-wrapper text-align-left'>
					<span className='therapy-reports-back'>
						<i
							className='material-icons therapy-reports-back-btn'
							onClick={() => props.setSelectedView(props.viewToShow.PT_PRESCRIPTION)}>
							keyboard_arrow_left
						</i>
						{translate('goBack')}
					</span>
					<h3>{translate('editExercise')}</h3>
					<div className='flex right-align-items'>
						<img
							src={`${healthCareCdnUrl}physical-exercises/${getPhysicalExerciseIcon(
								props.exerciseToEdit?.category?.type
							)}.svg?v2`}
							className='physical-icons'
							alt='ico'
						/>
						<div>
							<p className='bold margin-bottom-0'>
								{intl.formatMessage({ id: stringToCamelCase(props.exerciseToEdit.type.name) })}
							</p>
							<span className='gray-color'>
								{intl.formatMessage({ id: stringToCamelCase(props.exerciseToEdit.category.name) })}
							</span>
						</div>
					</div>

					<p className='exercise-intensity'>{translate('regulateExerciseIntensity')}</p>
					<div className='flex physical-exercises-calendar-wrapper'>
						<div>
							{intensities.map(intensity => (
								<>
									<div className='flex flex-space-between flex-align-center' style={{ marginBottom: '10px' }}>
										<h5>{intl.formatMessage({ id: stringToCamelCase(intensity.name) })}</h5>
										<CreatableSelect
											isClearable={false}
											options={transformIntensities(intensity.values)}
											onChange={event => {
												changeSelectedIntensityValue(event, intensity);
											}}
											defaultValue={[{ label: intensity.selectedValue, value: intensity.selectedValue }]}
											className='exercise-intensity-values'
											classNamePrefix='react-select width-20'
											name='selectedIntensity'
											isDisabled={intensity.type === IntensityTypes.DAYS_TO_ACHIEVE_TARGET}
											onKeyDown={event => validateIntensityValues(event, intensity)}
										/>
									</div>
									<div className='exercise-validation'>
										<span className='red-error'>{selectedIntensityType === intensity.type && validationError}</span>
									</div>
								</>
							))}
						</div>

						<div>
							<p className='no-padding-top no-margin-bottom'>{translate('selectDatesForExercise')}</p>
							<Calendar
								className='appointment-calendar'
								formatShortWeekday={(locale, value) => ['S', 'M', 'T', 'W', 'T', 'F', 'S'][value.getDay()]}
								calendarType='US'
								onChange={date => {
									setSelectedDate(date);
								}}
								minDate={new Date()}
								value={selectedDate}
								tileClassName={({ date }) => getCalendarClass(date, selectedDate)}
								prev2Label={null}
								next2Label={null}
							/>
						</div>
					</div>
					<div className='flex'>
						<button className='edit-pt-report-btn' type='button' onClick={editExerciseData} disabled={validationError}>
							{translate('saveChanges')}
						</button>
					</div>
					<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
				</div>
			)}
		</>
	);
};
export default EditPTReport;
