import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import { healthCareCdnUrl, APP_CONFIG } from 'constants/global-variables.js';
import { AlertTypes } from 'constants/enums.js';
import Layout from 'components/LandingPage/GeneralPages/Layout.jsx';
import Header from 'components/LandingPage/GeneralPages/Header.jsx';
import PartnersLogos from 'components/LandingPage/PartnersLogos.jsx';
import Services from 'components/LandingPage/Services';
import 'css/LandingPage/general-pages.scss';
import DemoRequest from 'components/LandingPage/DemoRequest';
import PopUpAlert from 'components/PopUpAlert.jsx';
import { Alert, Form, Modal, Textarea } from 'components';
import FormInput from 'components/FormInput.jsx';
import GoogleReCaptchaV3 from 'services/GoogleReCaptchaV3.js';
import { sendDemoRequest } from 'api/demoRequest';

const LandingPage = () => {
	const [isMessageModalVisible, setIsMessageModalVisible] = useState(false);
	const [error, setError] = useState('');
	const [isButtonLoading, setIsButtonLoading] = useState(false);
	const [isSuccessfullySentDemoPopupOpen, setIsSuccessfullySentDemoPopupOpen] = useState(false);
	const { current: reCaptchaV3 } = useRef(new GoogleReCaptchaV3(APP_CONFIG.googleRecaptchaKey));
	const history = useHistory();

	useEffect(() => {
		const loadReCaptcha = async () => {
			const googleReCaptcha = await reCaptchaV3.loadReCaptcha();
			if (!googleReCaptcha) {
				setError('Error loading recaptcha script.');
			}
		};
		loadReCaptcha();
	}, [reCaptchaV3]);

	useEffect(() => {
		const successfulDemoRequestTimeout = setTimeout(() => {
			if (isSuccessfullySentDemoPopupOpen) {
				setIsSuccessfullySentDemoPopupOpen(false);
			}
		}, 3000);
		return () => {
			clearTimeout(successfulDemoRequestTimeout);
		};
	}, [isSuccessfullySentDemoPopupOpen]);

	useEffect(() => {
		autoPlayVideoOnViewPort();
		redirectToHealthSystemIfRefToken();
	}, []);

	const redirectToHealthSystemIfRefToken = () => {
		const { search } = window.location;
		const query = queryString.parse(search);
		if (query.refToken) {
			history.push(`login${window.location.search}`);
		}
	};

	const autoPlayVideoOnViewPort = () => {
		const observer = new IntersectionObserver(
			(entries, observerElem) => {
				entries.forEach(entry => {
					if (entry.isIntersecting) {
						if (entry.target.paused) {
							entry.target.play();
						}

						observerElem.unobserve(entry.target);
					}
				});
			},
			{ rootMargin: '0px' }
		);

		document.querySelectorAll('#remoteExaminationKitVideo').forEach(elem => {
			observer.observe(elem);
		});
	};

	const firstLogos = [
		{
			id: 0,
			src: 'align.png',
			alt: 'ALign',
		},
		{
			id: 2,
			src: 'hitrust.png',
			alt: 'HiTrust',
		},
		{
			id: 3,
			src: 'hipaa.png',
			alt: 'Hipaa',
		},
		{
			id: 4,
			src: '21972-312_SOC_NonCPA.png',
			alt: 'AICPA | SOC',
			customSize: true,
		},
		{
			id: 5,
			src: 'fc.png',
			alt: 'FC',
		},
		{
			id: 6,
			src: 'ic.png',
			alt: 'IC',
		},
		{
			id: 7,
			src: 'ce.png',
			alt: 'CE',
		},
	];

	const secondaryLogos = [
		{
			id: 0,
			src: 'amwell.svg',
			alt: 'Amwell',
		},
		{
			id: 2,
			src: 'banyan.svg',
			alt: 'Banyan',
		},
		{
			id: 3,
			src: 'northwell.svg',
			alt: 'Northwell',
		},
		{
			id: 4,
			src: 'swiss.svg',
			alt: 'Swiss',
		},
	];

	const services = [
		{
			id: 0,
			isLeftToRight: true,
			hasBackground: false,
			title: 'Telehealth',
			content: (
				<>
					hellocare is an integrated and end-to-end telehealth and virtual healthcare delivery platform consisting of software,
					hardware and AI by providing turnkey solutions for Hospitals, Home Care and Primary Care by allowing Staff clinicians to
					seamlessly interact with and, when needed, monitor patients effectively. You can convert any setting into a virtual
					delivery care via Hello.
				</>
			),
			src: 'telehealth.jpg?v2',
			htmlId: 'telehealth',
		},
		{
			id: 1,
			isLeftToRight: false,
			hasBackground: true,
			title: 'Virtual Patient Observation',
			content: (
				<>
					24/7 continuous patient observation for up to 16 rooms on a single remote clinicians monitor by providing all the tools,
					alerts, workflows, services and devices required to deliver care via a single integrated hellocare virtual care
					platform. Clinicians who are doing the observations can also communicate with frontline caregivers in real time and also
					bring in health professionals, family members, interpreters, and consultants for video and audio sessions.
				</>
			),
			src: 'virtual-patient-observation.jpg?v2',
			htmlId: 'virtual-patient-observation',
		},
		{
			id: 2,
			isLeftToRight: true,
			hasBackground: false,
			title: 'Alerting, Communications & Workflow Management',
			content: (
				<>
					hellocare platform provides streamlined communication tools to enable staff clinicians, nurses, doctors, and other team
					members to connect and communicate instantly and directly in real-time via audio and video calls, secure messaging,
					walkie talkie, access information from EHR systems, and dynamic workflows tailored how your company delivers care.
				</>
			),
			src: 'alerting.jpg?v2',
			htmlId: 'alerting',
		},
		{
			id: 3,
			isLeftToRight: false,
			hasBackground: false,
			title: 'Remote Patient Monitoring',
			content: (
				<>
					We provide all the digital devices required to do remote patient monitoring by capturing health data from patients
					medical devices and electronically transmitting this information to hellocare platform. Using our advanced Artificial
					Intelligence (AI) algorithms, we provide the right alerts and information to providers including all the services to
					communicate with the patient in real-time when needed.
				</>
			),
			src: 'remote-patient-monitoring.jpg?v2',
			htmlId: 'remote-patient-monitoring',
		},
		{
			id: 4,
			isLeftToRight: true,
			hasBackground: false,
			title: 'Digital Clinic',
			content: (
				<>
					Virtual care delivery platform that allows physicians, specialists and other professionals to work from their desired
					location while serving their patients anywhere via our turnkey digital clinic solution.
				</>
			),
			src: 'digital-clinic-4.jpg?v3',
			htmlId: 'digital-clinic',
		},

		{
			id: 5,
			isLeftToRight: false,
			hasBackground: false,
			title: 'Remote Examination Kit',
			content: (
				<>
					We provide all the tools to providers so that patients can see a doctor from the comfort of their home. Doctors can also
					see patients from any location and avoid physical presence. Using Remote Examination Kit, physicians can examine Ears,
					Lungs, Heart, Heart Rate, Throat, Temperature, Skin, Abdomen, Blood Pressure, Blood Glucose, Weight, Body Fat, and many
					other vital signs all from the comfort of home.
				</>
			),
			videoSrc: 'medical-compressed.mp4?v2',
			variant: '#f7f8fb',
			htmlId: 'remote-examination-kit',
		},

		{
			id: 6,
			isLeftToRight: true,
			hasBackground: false,
			title: 'Telemedicine Carts',
			content: (
				<>
					Telemedicine cart is an integrated hardware and software mobile solution by providing healthcare clinicians all the
					tools, alerts, workflows, services and devices required to deliver care. Clinicians who are doing the observations can
					also communicate with frontline caregivers in real time and also bring in health professionals, family members,
					interpreters, and consultants for video and audio sessions.
				</>
			),
			src: 'med_cart.jpg?v4',
			htmlId: 'telemedicine-carts',
		},
		{
			id: 7,
			isLeftToRight: false,
			hasBackground: false,
			title: 'Artificial Intelligence (AI)',
			content: (
				<>
					Our Artificial Intelligence algorithms that run on the edge of the devices can protect patients privacy, track a patient
					for their behavior, monitor them for falls and alert staff clinicians when there is a risk.
				</>
			),
			src: 'ai-service.jpg?v2',
			htmlId: 'ai',

			variant: '#f7f8fb',
		},
		{
			id: 8,
			isLeftToRight: true,
			hasBackground: false,
			title: <>The World’s Most Powerful All-in-One Virtual Care Communication Device</>,
			background: 'hello-hand.png?v2',
			noImage: true,
			htmlId: 'hello-2',
			showSecondBtn: true,
		},
	];

	const getInitialValues = () => ({
		fullName: '',
		email: '',
		companyName: '',
		message: '',
	});

	const validateForm = () => {
		const minLength = 2;
		const maxLength = 30;

		return Yup.object().shape({
			fullName: Yup.string()
				.required('Please write full name')
				.min(minLength, `Minimum length of full name is ${minLength}`)
				.max(maxLength, 'Maximum number of characters exceeded')
				.trim(),
			email: Yup.string()
				.email('Provided e-mail is invalid')
				.required('Please write your e-mail'),
			companyName: Yup.string()
				.required('Please write your company')
				.min(minLength, `Minimum length of company name is ${minLength}`)
				.max(maxLength, 'Maximum number of characters exceeded')
				.trim(),
			message: Yup.string()
				.required('Please write your request')
				.trim(),
		});
	};

	const submitModal = async (values, { resetForm }) => {
		setIsButtonLoading(true);
		const reCaptchaToken = await reCaptchaV3.getToken();

		if (!reCaptchaToken) {
			setError('Error loading recaptcha script.');
		}

		const params = {
			...values,
			reCaptchaToken,
		};

		const response = await sendDemoRequest(params);
		setIsButtonLoading(false);
		if (response.error) {
			setError('Demo request was not sent successfully. Please try again!');
			return;
		}
		setIsMessageModalVisible(false);
		setIsSuccessfullySentDemoPopupOpen(true);
		resetForm();
	};

	return (
		<>
			<Helmet>
				<meta property='og:image' content={`${healthCareCdnUrl}meta/preview-images_hellocare_all.jpg`} />
				<meta name='twitter:image' content={`${healthCareCdnUrl}meta/preview-images_hellocare_twitter.jpg`} />
				<meta
					name='og:url'
					content={
						window.location.hostname === 'healthcare.solaborate.com'
							? 'https://healthcare.solaborate.com'
							: 'https://www.hellocare.ai'
					}
				/>
			</Helmet>

			<Layout className='landing-page'>
				<Header
					mainImg={`${healthCareCdnUrl}landing-page/header/desktop.jpg`}
					mobileImg={`${healthCareCdnUrl}landing-page/header/mobile.jpg`}
					setIsMessageModalVisible={setIsMessageModalVisible}
				/>
				<PartnersLogos logos={firstLogos} showCarousel={true} />
				{services.map(item => (
					<Services
						key={item.id}
						isLeftToRight={item.isLeftToRight}
						hasBackground={item.hasBackground}
						title={item.title}
						content={item.content}
						src={item.src}
						videoSrc={item.videoSrc || ''}
						variant={item.variant || ''}
						id={item.htmlId}
						setIsMessageModalVisible={setIsMessageModalVisible}
						noImage={item.noImage}
						background={item.background}
						showSecondBtn={item.showSecondBtn}
						redirect={() => history.push('/hello')}
					/>
				))}

				<PartnersLogos logos={secondaryLogos} title='Trusted by' />
				<DemoRequest setIsMessageModalVisible={setIsMessageModalVisible} />

				<PopUpAlert
					alertType={AlertTypes.SUCCESS}
					display={isSuccessfullySentDemoPopupOpen}
					onAlertClose={() => setIsSuccessfullySentDemoPopupOpen(false)}
					contentText='Your demo request was sent successfully'
					isSilent={true}
					center={true}
				/>
				<Alert display={error} fixed={true} message={error} variant='dark' />
				<Modal
					className='standard-modal-wrapper modal-wrapper-wo-btn request-demo-modal'
					position='center'
					display={isMessageModalVisible}
					onModalClose={() => setIsMessageModalVisible(false)}>
					<div className='standard-modal-inner modal-wrapper-wo-btn-inner'>
						<Formik
							initialValues={getInitialValues()}
							onSubmit={submitModal}
							validateOnBlur={true}
							validateOnChange={false}
							validationSchema={validateForm}>
							{props => {
								const { values, errors, touched, handleSubmit, handleChange, handleBlur } = props;
								return (
									<Form title='Request a demo' className='full-width request-demo'>
										<div>
											<FormInput
												onChange={handleChange}
												onBlur={handleBlur}
												labelClassName='full-width'
												className='full-width'
												type='text'
												placeholder='Full Name'
												name='fullName'
												value={values.fullName}
												error={errors.fullName}
												touched={touched.fullName}
											/>

											<FormInput
												onChange={handleChange}
												onBlur={handleBlur}
												labelClassName='full-width'
												className='full-width'
												type='email'
												placeholder='Your Email Address'
												name='email'
												value={values.email}
												error={errors.email}
												touched={touched.email}
											/>
											<FormInput
												onChange={handleChange}
												onBlur={handleBlur}
												labelClassName='full-width'
												className='full-width'
												type='text'
												placeholder='Company'
												name='companyName'
												value={values.companyName}
												error={errors.companyName}
												touched={touched.companyName}
											/>
											<Textarea
												onChange={handleChange}
												onBlur={handleBlur}
												className='full-width'
												type='text'
												placeholder='Your specific request'
												name='message'
												value={values.message}
												error={errors.message}
												touched={touched.message}
											/>

											<label className='google-recaptcha-wrapper full-width'>
												<span>
													This site is protected by reCAPTCHA and the Google&nbsp;
													<a rel='noopener noreferrer' target='_blank' href='https://policies.google.com/privacy'>
														Privacy Policy
													</a>
													&nbsp;and&nbsp;
													<a rel='noopener noreferrer' target='_blank' href='https://policies.google.com/terms'>
														Terms of Service
													</a>
													&nbsp;apply.
												</span>
											</label>
											{error && (
												<div>
													<span className='red-error'>{error}</span>
												</div>
											)}
											<button type='button' onClick={handleSubmit} className='top-15'>
												<i className='material-icons'>east</i>
												{isButtonLoading ? 'Submitting...' : 'Submit'}
											</button>
										</div>
									</Form>
								);
							}}
						</Formik>
					</div>
				</Modal>
			</Layout>
		</>
	);
};

export default LandingPage;
