import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import translate from 'i18n-translations/translate.jsx';
import Form from 'components/Form.jsx';
import { CompanySettings, getConfig } from 'constants/configurationEnums.js';
import { getCompanyId } from 'infrastructure/auth.js';
import Alert from 'components/Alert.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import Modal from 'components/Modal.jsx';
import {
	addHealthSystemCallBackgroundPicture,
	deleteHealthSystemCallBackgroundPicture,
	getBackgroundImages,
	uploadDigitalBackgroud,
} from 'api/configurations.js';
import { getBlobSasPicture } from 'api/doctors.js';
import SpinLoader from 'icons/Monitoring/SpinLoader.jsx';
import { updateTeamSettings } from 'api/adminConfigurations.js';

const DigitalBackground = ({ selectedHealthSystemId, wallpaperBackgroundConfigs, setWallpaperBackgroundConfigs }) => {
	const intl = useIntl();
	const [isBackgroundImgsLoading, setIsBackgroundImgsLoading] = useState(true);
	const [error, setError] = useState(null);
	const [backGroundImages, setBackGroundImages] = useState([]);
	const [loadingBgIds, setLoadingBgIds] = useState([]);
	const [selectedPhotoId, setSelectedPhotoId] = useState(null);

	const digitalBackgroundConfigs = {
		[CompanySettings.DIGITAL_BACKGROUND]: {
			type: CompanySettings.DIGITAL_BACKGROUND,
			icon: `${healthCareCdnUrl}admin/background-image-icon.svg`,
			title: 'backgroundImage',
			description: 'backgroundImagesAllowed',
		},
		[CompanySettings.BLURRY_BACKGROUND]: {
			type: CompanySettings.DIGITAL_BACKGROUND,
			icon: `${healthCareCdnUrl}admin/blurry-background-icon.svg`,
			title: 'blurryBackground',
			description: 'blurryBackgroundDefault',
		},
	};

	const updateCompanySetting = async (key, val, isLocked) => {
		const settings = { ...wallpaperBackgroundConfigs };
		const found = settings[key];
		if (found) {
			found.value = val.toString();
			found.isLocked = isLocked;
		} else {
			settings[key] = {
				isLocked: false,
				value: val.toString(),
				description: '',
			};
		}
		const params = {
			teamSettings: [
				{
					settingTypeId: key,
					value: val.toString(),
					isLocked,
				},
			],
			teamId: selectedHealthSystemId,
			isOverride: false,
			healthSystemId: selectedHealthSystemId,
		};
		const response = await updateTeamSettings(params);
		if (response.error) {
			setError(response.error.message);
		} else {
			setWallpaperBackgroundConfigs(settings);
		}
	};

	const mapBlob = async (pictureUrl, index) => {
		const response = await getBlobSasPicture(pictureUrl, 'team-call-background-pictures');
		const responseError = response.errorResponse || response.error;
		if (responseError) {
			setError(responseError.message);
			return { url: null, id: index, pictureUrl };
		}
		const imgToUpload = { url: response.result.uri.uri, id: index, pictureUrl };
		return imgToUpload;
	};

	const onImageAdded = async event => {
		setError(null);
		setLoadingBgIds([backGroundImages.length + 1]);
		const file = event.target.files[0];
		const formData = new FormData();
		formData.append('File', file);
		const response = await uploadDigitalBackgroud(formData, 'team-call-background');
		const responseError = response.errorResponse || response.error;
		if (responseError) {
			setError(responseError.message);
			return;
		}
		const addBgReponse = await addHealthSystemCallBackgroundPicture(response.fileName, getCompanyId(), selectedHealthSystemId);
		if (addBgReponse.error || !addBgReponse.hasSucceeded) {
			setError(response.error ? response.error.message : intl.formatMessage({ id: 'somethingWentWrong' }));
			return;
		}
		const mappedBlob = await mapBlob(response.fileName, backGroundImages.length + 1);
		const imgToUpload = { url: mappedBlob.url, pictureUrl: response.fileName, id: backGroundImages.length + 1 };
		setBackGroundImages(prevState => [...prevState, imgToUpload]);
		setLoadingBgIds([]);
	};

	const handleDelete = async () => {
		const response = await deleteHealthSystemCallBackgroundPicture(
			{
				pictureUrl: selectedPhotoId,
				isActive: false,
			},
			getCompanyId(),
			selectedHealthSystemId
		);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		setBackGroundImages(prevState => prevState.filter(item => item.pictureUrl !== selectedPhotoId));
		setSelectedPhotoId(null);
	};

	useEffect(() => {
		const fetchImages = async () => {
			const response = await getBackgroundImages(getCompanyId(), selectedHealthSystemId);
			if (response.error) {
				setError(response.error.message);
				return;
			}
			const ids = response.map((_, index) => index + 1);
			setLoadingBgIds(ids);
			const images = await Promise.all(response.map((item, index) => mapBlob(item.pictureUrl, index)));
			const imagesWithoutUrl = images.filter(item => item.url);
			if (imagesWithoutUrl.length > 0) {
				setBackGroundImages(images);
			} else {
				setBackGroundImages([]);
			}
			setIsBackgroundImgsLoading(false);
			setLoadingBgIds([]);
		};
		fetchImages();
	}, [selectedHealthSystemId]);

	const renderConfigItem = type => {
		return (
			<div className='flex call-config-settings'>
				<div className='call-config-type'>
					<div>
						<img src={digitalBackgroundConfigs[type].icon} alt='icon' />
					</div>
					<div className='call-config-description'>
						<p>{translate(digitalBackgroundConfigs[type].title)}</p>
						<span>{translate(digitalBackgroundConfigs[type].description)}</span>
					</div>
				</div>
				<div
					className='toggle-switch call-config-toggle'
					onClick={() =>
						updateCompanySetting(
							type,
							!getConfig(wallpaperBackgroundConfigs[type]).value,
							getConfig(wallpaperBackgroundConfigs[type]).isLocked
						)
					}>
					<input
						type='checkbox'
						onChange={() => null}
						checked={
							getConfig(wallpaperBackgroundConfigs[type]).value
								? JSON.parse(getConfig(wallpaperBackgroundConfigs[type]).value)
								: false
						}
					/>
					<span className='toggle-body' />
				</div>
			</div>
		);
	};

	const showImages = () => getConfig(wallpaperBackgroundConfigs[CompanySettings.DIGITAL_BACKGROUND]).value;

	return (
		<>
			<Form className='digital-background'>
				<h3>{translate('digitalBackground')}</h3>
				<p>{translate('selectPreferences')}</p>
				{renderConfigItem(CompanySettings.DIGITAL_BACKGROUND)}
				{showImages() && (
					<div className='present-images'>
						<p>{translate('presentImage')}</p>
						<div className='background-images flex'>
							{backGroundImages.length < 5 && (
								<div className='add-background-image'>
									<label htmlFor='image-upload'>
										<img src={`${healthCareCdnUrl}admin/add-image-icon.svg`} alt='icon' />
										<span className='virtual-background-text'>{translate('addImage')}</span>
									</label>
									<input
										type='file'
										accept='image/png, image/jpeg'
										id='image-upload'
										onChange={event => onImageAdded(event)}
										style={{ display: 'none' }}
									/>
								</div>
							)}
							{!isBackgroundImgsLoading &&
								backGroundImages.map(item => (
									<div className='background-item' key={item.id}>
										<>
											<img src={item.url} alt={intl.formatMessage({ id: 'backgroundImage' })} />
											<button className='delete-img-button' type='button' onClick={() => setSelectedPhotoId(item.pictureUrl)}>
												<i className='fa fa-trash'></i>
											</button>
										</>
									</div>
								))}
							{loadingBgIds.map(item => (
								<div className='center-circle-loader empty-box-img' key={item}>
									<SpinLoader color={'#5390FF'} />
								</div>
							))}
						</div>
					</div>
				)}
				{renderConfigItem(CompanySettings.BLURRY_BACKGROUND)}
			</Form>
			<Modal
				display={selectedPhotoId}
				onModalSubmit={handleDelete}
				onModalClose={() => setSelectedPhotoId(null)}
				position='center'
				primaryButtonLabel={translate('delete')}>
				<form>
					<h3>{translate('delete')}</h3>
					<p>
						{translate('areYouSureDeleteItem', {
							value: intl.formatMessage({ id: 'backgroundImage' }),
						})}
					</p>
				</form>
			</Modal>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};

export default DigitalBackground;
