import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import { addAlertActivity } from 'api/alerts.js';
import Modal from 'components/Modal.jsx';
import Form from 'components/Form.jsx';
import Alert from 'components/Alert.jsx';
import SpeechToText from 'SpeechToText.jsx';
import Textarea from 'components/Textarea.jsx';
import ReactSelect from 'react-select';
import { MeasurementActivityTypes } from 'constants/enums.js';
import { generateCustomStyles } from 'constants/react-select-style.js';

const AmbientMonitoringAlertModal = ({ feed, display, onCloseClick, onForwardToNurses, intl, darkMode, showForwardToNurses }) => {
	const ref = useRef(null);
	const charactersLimit = 255;
	const [alertType, setAlertType] = useState(null);
	const [recognizedTranscription, setRecognizedTranscription] = useState('');
	const [error, setError] = useState('');
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const timerOptions = useRef([
		{ value: 30, label: `30 ${intl.formatMessage({ id: 'seconds' })}` },
		{ value: 60, label: `1 ${intl.formatMessage({ id: 'minute' })}` },
		{ value: 180, label: `3 ${intl.formatMessage({ id: 'minutes' })}` },
		{ value: 300, label: `5 ${intl.formatMessage({ id: 'minutes' })}` },
		{ value: 600, label: `10 ${intl.formatMessage({ id: 'minutes' })}` },
		{ value: 900, label: `15 ${intl.formatMessage({ id: 'minutes' })}` },
		{ value: 1500, label: `30 ${intl.formatMessage({ id: 'minutes' })}` },
		{ value: 3600, label: `1 ${intl.formatMessage({ id: 'hour' })}` },
		{ value: 7200, label: `2 ${intl.formatMessage({ id: 'hours' })}` },
		{ value: 10800, label: `3 ${intl.formatMessage({ id: 'hours' })}` },
		{ value: 14400, label: `4 ${intl.formatMessage({ id: 'hours' })}` },
	]);
	const [selectedTimer, setSelectedTimer] = useState(timerOptions.current[0]);
	const [isSpeechToText, setIsSpeechToText] = useState(false);
	const [liveTranscription, setLiveTranscription] = useState('');

	const onDiscardClick = () => {
		setAlertType(null);
		setRecognizedTranscription('');
	};

	const onSubmit = async () => {
		if (
			!recognizedTranscription ||
			recognizedTranscription.trim() === '' ||
			(!selectedTimer && feed.warning.isAiAlert) ||
			recognizedTranscription.length > charactersLimit
		) {
			return;
		}

		setIsSubmitLoading(true);
		const params = {
			alertId: feed.warning.alertId,
			activityType: alertType,
			isAiAlert: feed.warning.isAiAlert,
			comment: recognizedTranscription,
		};
		const response = await addAlertActivity(params);
		if (response.error) {
			setError(response.error.message);
		}
		setAlertType(null);
		setRecognizedTranscription('');
		onCloseClick(feed);
		setIsSubmitLoading(false);
	};

	const isSubmitDisabled = () =>
		!recognizedTranscription ||
		recognizedTranscription.trim() === '' ||
		isSubmitLoading ||
		recognizedTranscription.length > charactersLimit;

	return (
		<>
			{!alertType && (
				<div className={classNames('flex column-direction ambient-monitoring-alert-modal', display ? 'show' : '')}>
					<div className='flex column-direction ambient-monitoring-alert-modal-wrapper' ref={ref}>
						<div className='flex flex-space-between flex-align-center'>
							<span>
								{feed?.roomName} {translate('alert')}
							</span>
							<span className='flex flex-align-center flex-justify-center' onClick={onCloseClick}>
								<i className='material-icons-outlined'>close</i>
							</span>
						</div>
						<div className='flex flex-justify-center flex-align-center'>
							<span className='flex flex-align-center flex-justify-center'>{feed?.warning?.icon}</span>
							<span>{feed?.warning?.text}</span>
						</div>

						<div className='flex flex-justify-center'>
							<span
								className={classNames('flex flex-justify-center', !showForwardToNurses ? 'forward-to-nurses-disabled' : '')}
								onClick={() => setAlertType(MeasurementActivityTypes.ACKNOWLEDGED)}>
								<span>{translate('acknowledge')}</span>
							</span>
							{showForwardToNurses && (
								<span className='flex flex-justify-center' onClick={() => onForwardToNurses(feed)}>
									<span>{translate('forwardToNurses')}</span>
								</span>
							)}
						</div>
					</div>
				</div>
			)}
			{alertType && (
				<Modal
					display={true}
					onModalSubmit={onSubmit}
					primaryButtonLabel={translate('saveComment')}
					closeButtonText={translate('discard')}
					position='center'
					onModalClose={onDiscardClick}
					shouldSubmitOnEnter={false}
					className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal alert-modal-absolute ambient-monitoring-snooze-modal'
					isSubmitDisabled={isSubmitDisabled()}
					submitImgIcon={`${healthCareCdnUrl}forward-arrow-white.svg`}
					closeImgIcon={`${healthCareCdnUrl}appointments/cancel-appointment.svg`}
					primaryButtonLoading={isSubmitLoading}>
					<Form className='modal-form-wrapper send-group-message mark-as-ack-false' onSubmit={event => event.preventDefault()}>
						{feed.warning.isAiAlert && (
							<>
								<h3>{translate('selectTimer')}</h3>
								<ReactSelect
									options={timerOptions.current}
									value={selectedTimer}
									onChange={value => setSelectedTimer(value)}
									placeholder={intl.formatMessage({ id: 'selectTimer' })}
									classNamePrefix='react-select'
									className='react-select top-15'
									styles={generateCustomStyles({ darkMode })}
								/>
								{selectedTimer && (
									<span className='red-error'>
										{translate('snoozeAlert', {
											value: selectedTimer.label,
										})}
									</span>
								)}
							</>
						)}
						<h3 className='add-comment'>{translate('addComment')}</h3>
						<p>{translate('message')}</p>
						<Textarea
							disabled={isSpeechToText}
							maxLength={charactersLimit}
							maxNumberLimit={40}
							rows={5}
							onChange={event => setRecognizedTranscription(event.target.value)}
							value={isSpeechToText ? `${recognizedTranscription} ${liveTranscription}`.trim() : recognizedTranscription}
							name='message'
							icon={
								<SpeechToText
									setRecognizedTranscription={setRecognizedTranscription}
									setLiveTranscription={setLiveTranscription}
									setIsSpeechToText={setIsSpeechToText}
									isSpeechToText={isSpeechToText}
								/>
							}
						/>
						{recognizedTranscription.length > charactersLimit && (
							<span className='red-error'>{translate('exceededCharactersLimit')}</span>
						)}
					</Form>
				</Modal>
			)}
			{error && <Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />}
		</>
	);
};
export default AmbientMonitoringAlertModal;
