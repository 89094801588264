import React, { useState } from 'react';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Input from 'components/Common/FormElements/Input.jsx';
import Select from 'components/Common/FormElements/Select.jsx';
import { Form, Modal, Alert } from 'components';
import translate from 'i18n-translations/translate.jsx';
import { HealthcareErrorCode, TeamTypes } from 'constants/enums.js';
import { getHealthSystemHospitals } from 'api/userIdleConfigurations.js';
import { addNtpConfiguration, editNtpConfiguration } from 'api/ntpConfig.js';
import { handleOnKeyDownNumeric } from 'infrastructure/helpers/commonHelpers.js';

const NtpForm = props => {
	const [isSuccessfulAlertOn, setSuccessfulAlertOn] = useState(false);
	const [isErrorsAlertOn, setErrorAlertOn] = useState(false);
	const [errorText, setErrorText] = useState('');
	const [formHospitals, setFormHospitals] = useState([]);

	const intl = useIntl();
	const translator = id => intl.formatMessage({ id });

	const healthSystems = useSelector(state => state.healthSystems);
	const mappedHealthSystems = healthSystems.allHealthSystems.map(item => ({ id: item.id, value: item.name }));

	const onHealthSystemChange = async (e, setFieldValue) => {
		const { value } = e.target;

		setFieldValue('hospitalId', '');

		const hsHospitals = value ? await getHealthSystemHospitals(value) : [];

		if (hsHospitals.error) {
			setErrorText(intl.formatMessage({ id: 'somethingWentWrong' }));
			return;
		}
		const hospitalArray = hsHospitals.map(hospital => ({ id: hospital.id, value: hospital.name }));
		hospitalArray.unshift({ id: '0', value: 'All' });
		setFormHospitals(hospitalArray);
	};

	const handleSubmitMyForm = async (values, { setSubmitting, setFieldError }) => {
		setSubmitting(true);

		const { healthSystemId, hospitalId, ntpUrl1, ntpPort1, ntpUrl2, ntpPort2 } = values;

		const maximumPortNumber = 65535;

		const teamId = hospitalId && hospitalId !== '0' ? hospitalId : healthSystemId;
		const teamType = hospitalId && hospitalId !== '0' ? TeamTypes.HOSPITAL : TeamTypes.HEALTH_SYSTEM;

		const ntpUrls = [];

		if (!ntpUrl1 && !ntpUrl2) {
			setFieldError('ntpUrl1', translator('atLeastOneNtpUrlIsRequired'));
			return;
		}

		if (ntpUrl1 && ntpUrl2 && ntpPort1 && ntpPort2 && ntpUrl1 === ntpUrl2 && ntpPort1 === ntpPort2) {
			setFieldError('ntpUrl2', translator('urlPortCannotBeSame'));
			return;
		}

		if (ntpPort1 && +ntpPort1 > maximumPortNumber) {
			setFieldError('ntpPort1', `${translator('maxPortNumber')} ${maximumPortNumber}`);
			return;
		}

		if (ntpPort1 && +ntpPort1 < 0) {
			setFieldError('ntpPort1', translator('portCannotBeNegative'));
			return;
		}

		if (ntpPort2 && +ntpPort2 > maximumPortNumber) {
			setFieldError('ntpPort2', `${translator('maxPortNumber')} ${maximumPortNumber}`);
			return;
		}

		if (ntpPort2 && +ntpPort2 < 0) {
			setFieldError('ntpPort2', translator('portCannotBeNegative'));
			return;
		}

		if (ntpUrl1) {
			const firstUrlPort = `${ntpUrl1}:${ntpPort1}`;
			ntpUrls.push(firstUrlPort);
		}
		if (ntpUrl2) {
			const secondUrlPort = `${ntpUrl2}:${ntpPort2}`;
			ntpUrls.push(secondUrlPort);
		}

		let res = null;

		if (!props.initialValues) {
			res = await addNtpConfiguration(teamId, teamType, ntpUrls);
		} else {
			const editParams = {
				teamId,
				teamType,
				id: props.initialValues.id,
				ntpUrls,
			};
			res = await editNtpConfiguration(editParams);
		}

		if (res.hasSucceeded) {
			setSuccessfulAlertOn(true);
			props.onSucceeded();
			setErrorText('');
		}

		if (res.error) {
			setErrorAlertOn(true);
			setSuccessfulAlertOn(false);
			if (res.error?.response?.data?.code === HealthcareErrorCode.RESOURCE_EXISTS) {
				setErrorText(intl.formatMessage({ id: 'configurationExists' }));
			} else {
				setErrorText('');
			}
		}

		setSubmitting(false);
	};

	const onCloseModal = resetForm => {
		setSuccessfulAlertOn(false);
		setErrorText('');
		setErrorAlertOn(false);
		resetForm();
		props.toggleModal();
	};

	const getValidationSchema = () => {
		const validation = {};
		if (!props.initialValues) {
			validation.healthSystemId = Yup.string().required(translator('healthSystemRequired'));
		}
		// eslint-disable-next-line
		const URL =
			/^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

		validation.ntpUrl1 = Yup.string().matches(URL, intl.formatMessage({ id: 'enterValidUrl' }));

		validation.ntpPort1 = Yup.string().when('ntpUrl1', {
			is: ntpUrl1 => ntpUrl1?.length > 0,
			then: () => Yup.string().required(translator('portIsRequired')),
		});

		validation.ntpUrl2 = Yup.string().matches(URL, intl.formatMessage({ id: 'enterValidUrl' }));

		validation.ntpPort2 = Yup.string().when('ntpUrl2', {
			is: ntpUrl2 => ntpUrl2?.length > 0,
			then: () => Yup.string().required(translator('portIsRequired')),
		});

		return validation;
	};

	const getInitialValues = () => {
		if (props.initialValues) {
			return { ...props.initialValues };
		}

		const defaultPort = '123';

		return {
			healthSystemId: '',
			hospitalId: '',
			ntpUrl1: '',
			ntpPort1: defaultPort,
			ntpUrl2: '',
			ntpPort2: defaultPort,
		};
	};

	return (
		<Formik
			enableReinitialize={true}
			initialValues={getInitialValues()}
			validationSchema={Yup.object().shape(getValidationSchema())}
			onSubmit={handleSubmitMyForm}>
			{({ handleSubmit, isSubmitting, resetForm, setFieldValue }) => (
				<Modal
					modalSelector='ntpFormModal'
					display={props.isModalOpen}
					position='right'
					shouldSubmitOnEnter={false}
					onModalSubmit={handleSubmit}
					onModalClose={() => onCloseModal(resetForm)}
					isLoading={isSubmitting}
					className='ntp-form-modal'>
					<Form>
						<h3>{translate('ntpConfigurations')}</h3>
						{!props.initialValues ? (
							<>
								<Field
									name='healthSystemId'
									type='select'
									label={translator('selectHealthSystem')}
									placeholder={translator('selectHealthSystem')}
									description={translator('applyConfig')}
									items={mappedHealthSystems}
									onChange={e => onHealthSystemChange(e, setFieldValue)}
									component={Select}
								/>

								<Field
									name='hospitalId'
									type='select'
									label={translator('selectHospital')}
									placeholder={translator('selectHospital')}
									description={translator('applyConfig')}
									items={formHospitals}
									component={Select}
								/>
							</>
						) : (
							<Field
								name='name'
								label={translator(props.initialValues.hospitalId ? 'hospital' : 'healthSystem')}
								disabled={true}
								component={Input}
							/>
						)}

						<div className='grid'>
							<Field
								name='ntpUrl1'
								type='text'
								label={translator('setNtpUrl')}
								placeholder={intl.formatMessage({ id: 'ntpUrl' })}
								description={translator('pleaseEnterNtpUrl')}
								component={Input}
							/>
							<Field
								name='ntpPort1'
								type='number'
								label={translator('setNtpPort')}
								placeholder={intl.formatMessage({ id: 'ntpPort' })}
								description={translator('pleaseEnterNtpPort')}
								component={Input}
								onKeyDown={handleOnKeyDownNumeric}
							/>
						</div>
						<div className='grid'>
							<Field
								name='ntpUrl2'
								type='text'
								label={translator('setNtpUrl')}
								placeholder={intl.formatMessage({ id: 'ntpUrl' })}
								description={translator('pleaseEnterNtpUrl')}
								component={Input}
							/>
							<Field
								name='ntpPort2'
								type='number'
								label={translator('setNtpPort')}
								placeholder={intl.formatMessage({ id: 'ntpPort' })}
								description={translator('pleaseEnterNtpPort')}
								component={Input}
								onKeyDown={handleOnKeyDownNumeric}
							/>
						</div>
						<Alert
							alertSelector='networkAccessConfigsMessage'
							display={isSuccessfulAlertOn || isErrorsAlertOn}
							message={translator(isSuccessfulAlertOn ? 'configurationSuccessfullyAdded' : errorText || 'somethingWentWrong')}
							variant={isSuccessfulAlertOn ? 'success' : 'error'}
							onClose={() => {
								setErrorAlertOn(false);
								setErrorText('');
								setSuccessfulAlertOn(false);
							}}
						/>
					</Form>
				</Modal>
			)}
		</Formik>
	);
};

export default NtpForm;
