import React from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { MeasurementTypes } from 'constants/enums.js';
import { defaultDateFormat } from 'infrastructure/helpers/dateHelper.js';
import { getPreferredLanguageLocale, stringToCamelCase } from 'infrastructure/helpers/commonHelpers.js';
import translate from 'i18n-translations/translate.jsx';

const HealthDataAverages = props => {
	const intl = useIntl();
	return (
		<div className='patient-iot-history-basic-info flex'>
			{getData(props, 'Lowest', intl)}
			{getData(props, 'Average', intl)}
			{getData(props, 'Highest', intl)}
		</div>
	);
};

const getData = (props, type, intl) => {
	const unit = stringToCamelCase(props.measurement.unit);
	const property = type.toLowerCase();
	return (
		<div>
			<div className='flex'>
				<img src={`https://static.solaborate.com/healthcare-system/measurements-icons/${property}-measurements.svg`} alt='icon' />
				<p>{translate(type.toLowerCase())}</p>
			</div>
			<div className='flex full-width'>
				{props[property] && (
					<>
						<h1>
							{props[property.toLowerCase()].value}
							{MeasurementTypes.SLEEP !== props.measurement.type && unit && (
								<span>{intl.formatMessage({ id: unit }).toLowerCase()}</span>
							)}
						</h1>
						{property !== 'average' && <span>{defaultDateFormat(props[property].date)}</span>}
						{property === 'average' && (
							<span>
								{moment
									.utc(props.average.startDate)
									.local()
									.locale(getPreferredLanguageLocale())
									.format('MMM DD')}
								{' - '}
								{moment
									.utc(props.average.endDate)
									.local()
									.locale(getPreferredLanguageLocale())
									.format('MMM DD')}
							</span>
						)}
					</>
				)}
			</div>
		</div>
	);
};

export default HealthDataAverages;
