import React from 'react';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import 'css/LandingPage/landing-page.scss';

const Services = props => {
	let style = {};
	if (props.variant) {
		style = { background: props.variant };
	}
	if (props.background) {
		style = {
			backgroundImage: `url(${healthCareCdnUrl}landing-page/${props.background})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'right center',
			height: '500px',
			backgroundSize: '50% auto',
		};
	}
	return (
		<>
			<div className={`services-wrapper${props.hasBackground ? ' services-w-background' : ''}`} style={style} id={props.id}>
				<div
					className={`lp-container${!props.isLeftToRight ? ' reverse-row-direction' : ''}${
						props.background ? ' mobile-full' : ''
					}`}>
					<div>
						<h2 className='primary-title-lp'>{props.title}</h2>
						<p className='primary-paragraph-lp'>{props.content}</p>
						<div className='flex'>
							<button type='button' onClick={() => props.setIsMessageModalVisible(true)}>
								<i className='material-icons'>east</i>Request a demo
							</button>
							{props.showSecondBtn && (
								<button type='button' onClick={props.redirect}>
									<i className='material-icons'>east</i>Learn More
								</button>
							)}
						</div>
					</div>

					<div>
						{!props.videoSrc && !props.background && (
							<img className='patient-monitoring-screen-lp' src={`${healthCareCdnUrl}landing-page/${props.src}`} alt={props.id} />
						)}
						{props.background && (
							<img
								className='patient-monitoring-screen-lp mobile-service'
								src={`${healthCareCdnUrl}landing-page/${props.background}`}
								alt={props.id}
							/>
						)}
						{props.videoSrc && (
							<video loop muted playsInline preload='auto' id='remoteExaminationKitVideo'>
								<source src={`${healthCareCdnUrl}landing-page/${props.videoSrc}`} type='video/mp4' />
								Your browser does not support the video tag.
							</video>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default Services;
