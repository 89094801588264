import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import { useIntl } from 'react-intl';
import Grid from 'components/Grid.jsx';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from 'components/Tabs.jsx';
import { ExternalIdentityProviders, InviteTypes, UserRoles } from 'constants/enums.js';
import { getHealthSystems } from 'api/healthSystems.js';
import InviteNurses from 'containers/UserManagement/InviteNurses.jsx';
import InviteAdmins from 'containers/UserManagement/InviteAdmins.jsx';
import InvitedUsersList from 'containers/UserManagement/Lists/InvitedUsersList.jsx';
import UsersList from 'containers/UserManagement/Lists/UsersList.jsx';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import translate from 'i18n-translations/translate.jsx';
import InviteSuperUsers from 'containers/UserManagement/InviteSuperUsers.jsx';
import AdMembers from 'containers/UserManagement/Lists/AdMembers.jsx';
import { getUserRole } from 'infrastructure/auth.js';
import ActiveDirectory from 'containers/Configurations/ActiveDirectory.jsx';
import UserIdleConfiguration from 'components/Configurations/UserIdleConfiguration.jsx';
import Alert from 'components/Alert.jsx';
import { useSelector } from 'react-redux';
import { IntegrationTypesSettings } from 'constants/configurationEnums.js';
import BadgeAuthorization from 'components/Configurations/BadgeAuthorization.jsx';
import { getSomeConfigurationsValues } from 'infrastructure/helpers/commonHelpers.js';

const Users = () => {
	const companyConfigurations = useSelector(state => state.company.companySettings?.companyConfigurations);
	const [healthSystems, setHealthSystems] = useState([]);
	const [isSendInvitationsModalOpen, setIsSendInvitationsModalOpen] = useState(false);
	const [selectedHealthSystem, setSelectedHealthSystem] = useState({ value: '0', label: 'All' });
	const [error, setError] = useState(null);
	const history = useHistory();
	const [currentSubTab, setCurrentSubTab] = useState(0);
	const location = useLocation();
	const [pagination, setPagination] = useState({ index: 0, size: 10 });
	const [selectedRole, setSelectedRole] = useState({ value: '0', label: 'All' });
	const [isLoading, setLoading] = useState(true);
	const [selectedHospital, setSelectedHospital] = useState(null);
	const intl = useIntl();
	const [inviteTypeId, setInviteTypeId] = useState(0);
	const [currentTab, setCurrentTab] = useState(0);
	const [adConfigType, setAdConfigType] = useState(ExternalIdentityProviders.AZURE);
	const [sendInvitations, setSendInvitations] = useState({
		loading: false,
		success: false,
		error: false,
	});
	const userSession = useSelector(state => state.user.userSession);

	const inviteType = [
		getUserRole() !== UserRoles.SUPER_USER && {
			inviteTypeId: InviteTypes.ADMIN.type,
			name: intl.formatMessage({ id: 'adminOnList' }),
		},
		{ inviteTypeId: InviteTypes.SUPER_USER.type, name: intl.formatMessage({ id: 'superUserOnList' }) },
		{ inviteTypeId: InviteTypes.VIRTUAL_CARE_PROVIDER.type, name: intl.formatMessage({ id: 'virtualCareProvider' }) },
		{ inviteTypeId: InviteTypes.VIRTUAL_SITTER.type, name: intl.formatMessage({ id: 'virtualSitterOnList' }) },
		{ inviteTypeId: InviteTypes.DOCTOR.type, name: intl.formatMessage({ id: 'doctorOnList' }) },
		{ inviteTypeId: InviteTypes.DIGITAL_CLINICIAN.type, name: intl.formatMessage({ id: 'digitalClinicianOnList' }) },
	].filter(Boolean);

	const shouldShowExternalIdentityTab =
		getSomeConfigurationsValues(companyConfigurations, [
			IntegrationTypesSettings.AZURE_AD,
			IntegrationTypesSettings.PING_FEDERATE,
			IntegrationTypesSettings.OKTA,
		]) && getUserRole() !== UserRoles.SUPER_USER;
	const submitMyForm = useRef(null);

	useEffect(() => {
		const setStateFromQueryString = async queryParams => {
			setLoading(false);
			const response = await getHealthSystems();
			if (!response.error) {
				const { pageIndex, activeTab, healthSystemId, pageSize, activeSubTab } = queryParams;
				const selectedHealthSystemId = healthSystemId ?? userSession.healthSystem.id;
				const selectedHs = response.find(x => x.id === selectedHealthSystemId);
				setPagination({
					index: pageIndex ? parseInt(pageIndex, 10) : 0,
					size: pageSize ? parseInt(pageSize, 10) : 10,
				});
				setCurrentTab(activeTab ? parseInt(activeTab, 10) : 0);
				setCurrentSubTab(activeSubTab ? parseInt(activeSubTab, 10) : 0);
				setSelectedHealthSystem(
					selectedHs ? { value: selectedHs.id, label: selectedHs.name } : { value: '0', label: intl.formatMessage({ id: 'all' }) }
				);
				const items = response.map(item => ({ id: item.id, value: item.name }));
				setHealthSystems(items);
			} else {
				setError(response.error.message);
			}
		};
		const currentParams = queryString.parse(location.search);
		setStateFromQueryString(currentParams);
	}, [location.search, intl]);

	useEffect(() => {
		setSendInvitations(prevState => ({
			...prevState,
			success: false,
			error: false,
		}));
	}, [isSendInvitationsModalOpen]);

	const handleSubmitMyForm = e => {
		if (submitMyForm.current) {
			submitMyForm.current(e);
		}
	};

	const bindSubmitForm = submitForm => {
		submitMyForm.current = submitForm;
	};

	const onSuccess = () => {
		setSendInvitations(prevState => ({
			...prevState,
			loading: false,
			success: true,
		}));
	};

	const onSubTabChange = index => {
		setLoading(true);
		setPagination({
			index: 0,
			size: 10,
		});
		setCurrentSubTab(index);
		setLoading(false);
		updateQueryParam('activeSubTab', index, index === 0);
	};

	const updateQueryParam = (prop, val, shouldDeleteProp = false) => {
		const currentParams = queryString.parse(location.search);
		if (shouldDeleteProp) {
			delete currentParams[prop];
		} else {
			currentParams[prop] = val;
		}
		history.push({ search: queryString.stringify(currentParams) });
	};

	const onError = () => {
		setSendInvitations(prevState => ({
			...prevState,
			loading: false,
			success: false,
			error: true,
		}));
	};

	const onCloseAlert = () => {
		setSendInvitations({ loading: false, success: false, error: false });
	};

	const onLoading = () => {
		setSendInvitations(prevState => ({
			...prevState,
			success: true,
		}));
	};
	const onTabChange = index => {
		setLoading(true);
		setPagination({
			index: 0,
			size: 10,
		});
		setCurrentTab(index);
		setLoading(false);
		updateQueryParam('activeTab', index, index === 0);
		setCurrentSubTab(0);
	};

	const onRoleChange = param => {
		setSelectedRole(param);
		setPagination(prev => ({
			...prev,
			index: 0,
		}));
		updateQueryParam('role', param.value, param.value === '0');
	};

	const toggleSendInvitationsModal = (type, configType) => {
		setIsSendInvitationsModalOpen(prevState => !prevState);
		setInviteTypeId(type);
		setAdConfigType(configType);
	};

	const displayInviteForm = () => {
		if ([InviteTypes.ADMIN.type, InviteTypes.SUPER_ADMIN.type].includes(inviteTypeId) && isSendInvitationsModalOpen) {
			return (
				<InviteAdmins
					inviteTypeId={inviteTypeId}
					onLoading={onLoading}
					success={sendInvitations.success}
					error={sendInvitations.error}
					bindSubmitForm={bindSubmitForm}
					onSuccess={onSuccess}
					onCloseAlert={onCloseAlert}
					display={isSendInvitationsModalOpen}
					onModalClose={() => setIsSendInvitationsModalOpen(prevState => !prevState)}
					onModalSubmit={handleSubmitMyForm}
					isLoading={sendInvitations.loading}
					onError={onError}
					currentTab={currentTab}
					adConfigType={adConfigType}
				/>
			);
		}

		if (inviteTypeId === InviteTypes.SUPER_USER.type && isSendInvitationsModalOpen) {
			return (
				<InviteSuperUsers
					inviteTypeId={inviteTypeId}
					onLoading={onLoading}
					healthSystems={healthSystems}
					success={sendInvitations.success}
					error={sendInvitations.error}
					bindSubmitForm={bindSubmitForm}
					onSuccess={onSuccess}
					onCloseAlert={onCloseAlert}
					display={isSendInvitationsModalOpen}
					onModalClose={() => setIsSendInvitationsModalOpen(prevState => !prevState)}
					onModalSubmit={handleSubmitMyForm}
					isLoading={sendInvitations.loading}
					onError={onError}
					currentTab={currentTab}
					adConfigType={adConfigType}
				/>
			);
		}

		return (
			<>
				{isSendInvitationsModalOpen && (
					<InviteNurses
						inviteTypeId={inviteTypeId}
						healthSystems={healthSystems}
						onLoading={onLoading}
						success={sendInvitations.success}
						error={sendInvitations.error}
						bindSubmitForm={bindSubmitForm}
						onSuccess={onSuccess}
						onCloseAlert={onCloseAlert}
						display={isSendInvitationsModalOpen}
						onModalClose={() => setIsSendInvitationsModalOpen(prevState => !prevState)}
						onModalSubmit={handleSubmitMyForm}
						isLoading={sendInvitations.loading}
						onError={onError}
						currentTab={currentTab}
						adConfigType={adConfigType}
					/>
				)}
			</>
		);
	};

	return (
		<MainLayout>
			<div className='users-view'>
				<Grid stretch='100%'>
					<main className='main-view'>
						<section>
							<Grid width='100%' vertAlign='start'>
								<h3>{translate('userManagement')}</h3>
								<Tabs activeIndex={currentTab} onChange={onTabChange}>
									<TabList>
										<Tab>{translate('members')}</Tab>
										<Tab>{translate('invitedMembers')}</Tab>
										<Tab>{translate('externalIdentityProviderMembers')}</Tab>
										{shouldShowExternalIdentityTab && (
											<Tab className='cursor-pointer'>{translate('externalIdentityProviders')}</Tab>
										)}
										<Tab className='cursor-pointer'>{translate('userIdleConfigurations')}</Tab>
										<Tab className='cursor-pointer'>{translate('badgingAccess')}</Tab>
									</TabList>
									<TabPanels>
										<TabPanel>
											<UsersList
												inviteType={inviteType}
												healthSystems={healthSystems}
												toggleSendInvitationsModal={toggleSendInvitationsModal}
											/>
										</TabPanel>
										<TabPanel>
											<InvitedUsersList inviteType={inviteType} toggleSendInvitationsModal={toggleSendInvitationsModal} />
										</TabPanel>
										<TabPanel>
											<AdMembers
												inviteType={inviteType}
												healthSystems={healthSystems}
												toggleSendInvitationsModal={toggleSendInvitationsModal}
											/>
										</TabPanel>
										{shouldShowExternalIdentityTab && (
											<TabPanel>
												<ActiveDirectory
													healthSystems={healthSystems}
													selectedHealthSystem={selectedHealthSystem}
													selectedHospitalId={selectedHospital}
													activeSubTab={currentSubTab}
													pagination={pagination}
													setSelectedHealthSystem={setSelectedHealthSystem}
													setSelectedHospital={setSelectedHospital}
													onSubTabChange={onSubTabChange}
													isLoading={isLoading}
													onPaginationChange={setPagination}
												/>
											</TabPanel>
										)}
										<TabPanel>
											<UserIdleConfiguration
												healthSystems={healthSystems}
												selectedHealthSystem={selectedHealthSystem}
												pagination={pagination}
												isLoading={isLoading}
												onPaginationChange={setPagination}
												setSelectedHealthSystem={setSelectedHealthSystem}
												selectedRole={selectedRole}
												setSelectedRole={onRoleChange}
												setError={setError}
											/>
										</TabPanel>
										<TabPanel>
											<BadgeAuthorization healthSystems={healthSystems} />
										</TabPanel>
									</TabPanels>
								</Tabs>
							</Grid>
						</section>
					</main>
				</Grid>
			</div>
			{(inviteTypeId === InviteTypes.SUPER_ADMIN.type || (healthSystems.length !== 0 && inviteTypeId !== 0)) &&
				displayInviteForm()}
			<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
		</MainLayout>
	);
};

export default Users;
