import React from 'react';

const PulseEcg = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none' viewBox='0 0 24 18' {...props}>
		<path
			stroke='currentColor'
			strokeLinejoin='round'
			d='M11 18.308C-6.13 8.859 5.86-1.457 10.998 5.029c5.14-6.486 17.13 3.83 0 13.279z'
		/>
		<path
			stroke='currentColor'
			strokeLinecap='round'
			strokeLinejoin='round'
			d='M1 11.95h5.555l1.111-1.667 1.667 4.444 1.666-7.222 1.667 5.555 1.615-3.502 1.164 1.836H21'
		/>
	</svg>
);

export default PulseEcg;
