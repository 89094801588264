import React from 'react';

const DemoRequest = props => (
	<div className='demo-request lp-container'>
		<h2>Care to see a live demo?</h2>
		<button type='button' onClick={() => props.setIsMessageModalVisible(true)}>
			Request a Demo
		</button>
	</div>
);

export default DemoRequest;
