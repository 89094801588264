import React from 'react';
import styled, { css } from 'styled-components';
import LightTheme from 'calls/styles/LightTheme.js';
import { Icon } from 'calls/components/index.js';

/**
 * @typedef {{ size: number, background: any }} props
 * @type {import('styled-components').StyledComponent<"button", any, props, never>}
 */
const Button = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: ${LightTheme.spacing[3]}px;
	border-radius: 100%;
	border: none;
	box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
	transition: 0.2s;
	user-select: none;

	${props =>
		props.background &&
		css`
			background: ${props.background};
		`}

	span {
		${props =>
			props.color &&
			css`
				color: ${props.color};
			`}
	}

	&:disabled {
		pointer-events: none;
		opacity: 0.85;
	}
`;

/**
 * @param {object} props
 * @param {any} [props.children]
 * @param {string} [props.icon] Material icon name
 * @param {string} props.background
 * @param {string} props.color
 * @param {number} [props.size] Size in pixels
 * @param {boolean} [props.disabled=null]
 * @param {(...args: any) => void} [props.onClick=null]
 */
const IconButton = ({ children, icon, size, background, color, disabled = null, onClick = null, ...props }) => (
	<Button type='button' size={size} background={background} color={color} disabled={disabled} onClick={onClick} {...props}>
		{children || <Icon name={icon} />}
	</Button>
);

export default IconButton;
