import React, { useEffect, useState, useCallback } from 'react';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Table from 'components/Table.jsx';
import Grid from 'components/Grid.jsx';
import Button from 'components/Button.jsx';
import Modal from 'components/Modal.jsx';
import Alert from 'components/Alert.jsx';
import Pagination from 'components/Common/Pagination.jsx';
import { getHealthSystemHospitals } from 'api/userIdleConfigurations.js';
import { ExternalIdentityProviders, TeamTypes, UserRoles } from 'constants/enums.js';
import { ConfigHistoryTypes } from 'constants/configurationEnums.js';
import { getUserRole } from 'infrastructure/auth.js';
import { deleteGroupRole, getActiveDirectories, getADGroupRoles, deleteActiveDirectory } from 'api/activeDirectory.js';
import ActiveDirectoryForm from 'containers/Configurations/ActiveDirectoryForm.jsx';
import { stringToCamelCase } from 'infrastructure/helpers/commonHelpers.js';
import { getFormattedDateWithHour } from 'infrastructure/helpers/dateHelper.js';

const ActiveDirectory = props => {
	const role = getUserRole();
	const [activeDirectories, setActiveDirectories] = useState([]);
	const [groupRoles, setGroupRoles] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const organizationState = useSelector(state => state.healthSystems);
	const [error, setError] = useState(null);
	const [isHospitalDisabled, setHospitalDisabled] = useState(true);
	const [hospitals, setHospitals] = useState([]);
	const [formHospitals, setFormHospitals] = useState([]);
	const [isModalOpen, setModalOpen] = useState(false);
	const [isGroupRoleModal, setIsGroupRoleModal] = useState(false);
	const [editConfig, setEditConfig] = useState(null);
	const intl = useIntl();
	const [total, setTotal] = useState(0);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [hasDeleteError, setDeleteError] = useState(false);

	const translator = id => intl.formatMessage({ id });

	const activeSubTab = {
		ActiveDirectory: 0,
		GroupRoles: 1,
	};

	const activeDirectoriesHeaders = [
		{ id: 1, title: translator('hospital') },
		{ id: 2, title: translator('domain') },
		{ id: 3, title: translator('validGroupName') },
		{ id: 4, title: translator('createdBy') },
		{ id: 5, title: translator('dateCreated') },
		{ id: 6, title: translator('type') },
		{ id: 7, title: '' },
	];

	const groupRoleHeaders = [
		{ id: 1, title: translator('healthSystem') },
		{ id: 1, title: translator('hospital') },
		{ id: 2, title: translator('role') },
		{ id: 3, title: translator('group') },
		{ id: 4, title: translator('createdBy') },
		{ id: 5, title: translator('dateCreated') },
		{ id: 6, title: '' },
	];

	const transformTypes = {
		WithValues: 1,
		WithLabels: 2,
	};

	const onPaginationChange = value => {
		setIsLoading(true);
		props.onPaginationChange(value);
	};

	const transformRows = () => {
		if (props.activeSubTab === activeSubTab.ActiveDirectory) {
			if (activeDirectories.length === 0) {
				return [];
			}
			return activeDirectories.map(item => ({
				id: item.id,
				hospital: item?.team?.name,
				domain: item.domain,
				validGroupName: item.validGroupName,
				createdBy: `${item.userCreated.firstName} ${item.userCreated.lastName}`,
				dateCreated: getFormattedDateWithHour(item.dateCreated),
				adConfigurationTypeId: getActiveConfigurationTypeId(item.adConfigurationTypeId),
				edit: getConfigButtons(item),
			}));
		}
		if (groupRoles.length === 0) {
			return [];
		}
		return groupRoles.map(item => ({
			healthSystem: TeamTypes.HEALTH_SYSTEM === item?.team?.typeId ? item?.team?.name : 'N/A',
			hospital: TeamTypes.HOSPITAL === item?.team?.typeId ? item?.team?.name : 'N/A',
			role: item.role?.name ? translator(stringToCamelCase(item.role?.name)) : 'N/A',
			group: item.group,
			createdBy: `${item.userCreated.firstName} ${item.userCreated.lastName}`,
			dateCreated: getFormattedDateWithHour(item.dateCreated),
			edit: getConfigButtons(item),
		}));
	};

	const transformArray = (array, type, isHealthSystem, shouldAppendAll) => {
		if (type === transformTypes.WithValues) {
			const newArray = array.map(item => {
				return { id: item.id, value: item.name };
			});
			if (shouldAppendAll) {
				if (isHealthSystem) {
					if (role === UserRoles.ADMIN) {
						newArray.unshift({ id: '0', value: translator('all') });
					}
				} else {
					newArray.unshift({ id: '0', value: translator('all') });
				}
			}

			return newArray;
		}
		if (type === transformTypes.WithLabels) {
			const newArray = array.map(item => {
				return { value: item.id, label: item.name };
			});
			if (shouldAppendAll) {
				if (isHealthSystem) {
					if (role === UserRoles.ADMIN) {
						newArray.unshift({ value: '0', label: translator('all') });
					}
				} else {
					newArray.unshift({ value: '0', label: translator('all') });
				}
			}
			return newArray;
		}
		return [];
	};

	const onHealthSystemSelect = healthSystem => {
		setActiveDirectories([]);
		setGroupRoles([]);
		setIsLoading(true);
		props.setSelectedHealthSystem(healthSystem);
	};

	const getHospitals = async healthSystem => {
		let hospitalsArray;
		if (healthSystem.value !== '0') {
			hospitalsArray = await getHealthSystemHospitals(healthSystem?.value || healthSystem?.id);
		}
		setHospitalDisabled(healthSystem.value === '0');
		setHospitals(
			hospitalsArray?.length > 0
				? transformArray(hospitalsArray, isModalOpen ? transformTypes.WithValues : transformTypes.WithLabels, true, true)
				: []
		);
	};

	useEffect(() => {
		getHospitals(props.selectedHealthSystem);
	}, [props.selectedHealthSystem]);

	const DropdownIndicator = () => {
		return <i className='material-icons-outlined'>arrow_drop_down</i>;
	};

	const onHospitalSelect = selection => {
		setActiveDirectories([]);
		setGroupRoles([]);
		setIsLoading(true);
		props.setSelectedHospital(selection);
	};

	const onHealthSystemChange = async e => {
		const { value } = e.target;
		const hsHospitals = value !== '0' ? await getHealthSystemHospitals(value) : [];
		const hospitalArray = hsHospitals.map(hospital => ({ id: hospital.id, value: hospital.name }));
		setFormHospitals(hospitalArray);
	};

	const onTabChange = async tabIndex => {
		setActiveDirectories([]);
		setGroupRoles([]);
		setIsLoading(true);
		await props.onSubTabChange(tabIndex);
	};

	const fetchDirectories = useCallback(async () => {
		setIsLoading(true);
		const formData = {
			pageSize: props.pagination.size,
			pageIndex: props.pagination.index,
			healthSystemId: +props.selectedHealthSystem.value === 0 ? null : props.selectedHealthSystem.value,
			hospitalId: props.selectedHospitalId,
		};
		const response = await getActiveDirectories(formData);
		if (response.error) {
			setIsLoading(false);
			setError(response.error.message);
			return;
		}
		setActiveDirectories(response.activeDirectories);
		setTotal(response.total);
		setIsLoading(false);
	}, [props.pagination.index, props.pagination.size, props.selectedHealthSystem.value, props.selectedHospitalId]);

	const fetchGroupRoles = useCallback(async () => {
		setIsLoading(true);
		const formData = {
			pageSize: props.pagination.size,
			pageIndex: props.pagination.index,
			healthSystemId: +props.selectedHealthSystem.value === 0 ? null : props.selectedHealthSystem.value,
			hospitalId: props.selectedHospitalId,
		};

		const response = await getADGroupRoles(formData);
		if (response.error) {
			setIsLoading(false);
			setError(response.error.message);
			return;
		}
		setGroupRoles(response.adGroupRoles);
		setTotal(response.total);
		setIsLoading(false);
	}, [props.pagination.index, props.pagination.size, props.selectedHealthSystem.value, props.selectedHospitalId]);

	useEffect(() => {
		if (props.activeSubTab === activeSubTab.ActiveDirectory) {
			fetchDirectories();
			return;
		}
		fetchGroupRoles();
	}, [props.activeSubTab, fetchGroupRoles, fetchDirectories]);

	const getActiveConfigurationTypeId = config => {
		const configDomain = {
			[ExternalIdentityProviders.AZURE]: translator('azureAd'),
			[ExternalIdentityProviders.PING_FEDERATE]: translator('pingFederate'),
			[ExternalIdentityProviders.OKTA]: translator('okta'),
		};
		return configDomain[config];
	};

	const getConfigButtons = config => {
		let editConfiguration;
		if (props.activeSubTab === activeSubTab.ActiveDirectory) {
			editConfiguration = {
				id: config.id,
				hospitalId: config?.team?.name,
				validGroupName: config.validGroupName,
				username: config.username,
				password: config.password,
				domain: config.domain,
				includeRoles: config.includeRoles,
				isSingleSignOutEnabled: config.isSingleSignOutEnabled,
				autoAdd: config.autoAdd,
				autoDelete: config.autoDelete,
				autoUpdate: config.autoUpdate,
				isCompanyLevelConfiguration: config.isCompanyLevelConfiguration,
				adConfigurationTypeId: config.adConfigurationTypeId,
			};
		} else {
			editConfiguration = {
				id: config.id,
				hospitalId: config?.team?.name,
				roleId: config.role.id,
				group: config.group,
			};
		}

		return (
			<div className='wrapped'>
				{props.activeSubTab === activeSubTab.ActiveDirectory && (
					<Link to={`/configurations/${config.id}/type/${ConfigHistoryTypes.ADs}`} onClick={props.onHistoryClick}>
						<span
							className='material-icons-outlined cursor-pointer mr-20'
							data-cy='viewHistory'
							data-tooltip={translator('viewHistory')}
							data-position='top'>
							list_alt
						</span>
					</Link>
				)}
				<span
					className='material-icons-outlined cursor-pointer mr-20'
					data-cy='edit'
					data-tooltip={translator('edit')}
					data-position='top'
					onClick={() => openEditModal(editConfiguration)}>
					create
				</span>
				<i
					className='material-icons-outlined cursor-pointer'
					data-cy='deleteActiveDirectory'
					style={{ color: 'red', backgroundColor: 'white' }}
					onClick={() => openDeleteModal(editConfiguration)}
					data-tooltip={translator('delete')}
					data-position='top'>
					delete
				</i>
			</div>
		);
	};

	const openEditModal = config => {
		setHospitals([]);
		setEditConfig(config);
		setModalOpen(true);
		if (props.activeSubTab === activeSubTab.ActiveDirectory) {
			setIsGroupRoleModal(false);
			return;
		}
		setIsGroupRoleModal(true);
	};

	const openModal = () => {
		if (props.activeSubTab === activeSubTab.ActiveDirectory) {
			setIsGroupRoleModal(false);
			setModalOpen(prevState => !prevState);
			setFormHospitals([]);
			return;
		}
		setIsGroupRoleModal(true);
		setModalOpen(prevState => !prevState);
	};
	const getHeaders = () => {
		switch (props.activeSubTab) {
			case activeSubTab.ActiveDirectory:
				return activeDirectoriesHeaders;
			case activeSubTab.GroupRoles:
				return groupRoleHeaders;
			default:
				return [''];
		}
	};

	const openDeleteModal = async values => {
		setEditConfig(values);
		setIsDeleteModalOpen(true);
	};

	const handleDelete = async () => {
		setIsLoading(true);
		setDeleteError(false);

		let res = null;
		if (props.activeSubTab === activeSubTab.ActiveDirectory) {
			res = await deleteActiveDirectory(editConfig.id);
		} else if (props.activeSubTab === activeSubTab.GroupRoles) {
			res = await deleteGroupRole(editConfig.id);
		}

		if (res.error) {
			setDeleteError(true);
		} else {
			setDeleteError(false);
			setEditConfig(null);
			setIsDeleteModalOpen(false);
			if (props.activeSubTab === activeSubTab.ActiveDirectory) {
				setActiveDirectories(prevState => prevState.filter(item => item.id !== editConfig.id));
			} else {
				setGroupRoles(prevState => prevState.filter(item => item.id !== editConfig.id));
			}
			setIsLoading(false);
		}
	};

	const fetchData = () => {
		if (props.activeSubTab === activeSubTab.ActiveDirectory) {
			setActiveDirectories([]);
			fetchDirectories();
			toggleModal();
			return;
		}
		setGroupRoles([]);
		fetchGroupRoles();
		toggleModal();
	};

	const toggleModal = () => {
		setModalOpen(prevState => !prevState);
		setEditConfig(null);
	};

	return (
		<div>
			<Table isLoading={isLoading} headers={getHeaders()} rows={isLoading ? [] : transformRows()}>
				<Grid columns='1fr 1fr 2fr' gridGap='10px' vertAlign='center'>
					<Select
						value={props.selectedHealthSystem}
						placeholder={translator('all')}
						classNamePrefix='custom-select'
						options={transformArray(organizationState.allHealthSystems, transformTypes.WithLabels, true, true)}
						components={{ DropdownIndicator }}
						onChange={onHealthSystemSelect}
						isDisabled={role === UserRoles.SUPER_USER}
					/>
					<Select
						placeholder={translator('select')}
						value={props.selectedHospitalId ? hospitals.find(x => x.value === props.selectedHospitalId) : null}
						isDisabled={isHospitalDisabled}
						classNamePrefix='custom-select'
						options={hospitals}
						components={{ DropdownIndicator }}
						onChange={onHospitalSelect}
					/>
					{activeDirectories.length === 0 && !isLoading && (
						<Button
							onClick={openModal}
							horizAlign='end'
							text={translator(props.activeSubTab === activeSubTab.ActiveDirectory ? 'addConfiguration' : 'addGroupRoles')}
							className='text-transform-none border-radius-m'
						/>
					)}
				</Grid>
				<div>
					<ul className='tabs active-directory-tabs' data-cy='activeDirectoryTabs'>
						<li className={props.activeSubTab === activeSubTab.ActiveDirectory ? 'active' : ''}>
							<span onClick={() => onTabChange(activeSubTab.ActiveDirectory)}>{translator('externalIdentityProviders')}</span>
						</li>
						<li className={props.activeSubTab === activeSubTab.GroupRoles ? 'active' : ''}>
							<span onClick={() => onTabChange(activeSubTab.GroupRoles)}>{translator('groupRoles')}</span>
						</li>
					</ul>
				</div>
			</Table>
			{!isLoading && (
				<Pagination
					totalCount={total}
					pageSize={props.pagination.size}
					pageIndex={props.pagination.index}
					onChange={(pageSize, pageIndex) => onPaginationChange({ pageSize, pageIndex })}
				/>
			)}
			{isModalOpen && (
				<ActiveDirectoryForm
					activeTab={props.activeSubTab}
					isModalOpen={isModalOpen}
					isSuperUser={role === UserRoles.SUPER_USER}
					healthSystems={transformArray(organizationState.allHealthSystems, transformTypes.WithValues, true, true)}
					toggleModal={toggleModal}
					hospitals={formHospitals}
					onHealthSystemChange={onHealthSystemChange}
					initialValues={editConfig}
					isGroupRoleModal={isGroupRoleModal}
					onSucceeded={fetchData}
				/>
			)}
			<Alert display={error !== null} message={error} variant='dark' fixed={true} onClose={() => setError(null)} />
			<Modal
				modalSelector='delete'
				display={isDeleteModalOpen}
				position='center'
				submitButtonText='Delete'
				onModalSubmit={() => handleDelete()}
				onModalClose={() => {
					setIsDeleteModalOpen(false);
					setEditConfig(null);
				}}>
				<form>
					<h3>{translator('warning')}</h3>
					<p>{translator('areYouSureToDeleteConfig')}</p>
					{hasDeleteError && <p className='error'>{translator('somethingWentWrong')}</p>}
				</form>
			</Modal>
		</div>
	);
};

export default ActiveDirectory;
