import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { getLatestMeasurements, getUserMeasurementUnits } from 'api/measurements.js';
import Alert from 'components/Alert.jsx';
import { MeasurementTypes } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import SocketEvents from 'constants/socket-events.js';
import translate from 'i18n-translations/translate.jsx';
import { getUserId } from 'infrastructure/auth.js';
import { convertMeasurementTypes } from 'infrastructure/helpers/measurementsHelper.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';

const RpmMeasurements = props => {
	const socket = useContext(SocketContext);
	const intl = useIntl();
	const [preferredUnits, setPreferredUnits] = useState([]);
	const [errorApiResponse, setErrorApiResponse] = useState(null);
	const [vitalSigns, setVitalSigns] = useState([
		{
			id: 1,
			name: intl.formatMessage({ id: 'heartRate' }),
			value: 'N/A',
			unit: '',
			img: 'heart',
			type: MeasurementTypes.HEART_RATE,
		},
		{
			id: 2,
			name: intl.formatMessage({ id: 'oxygenSaturation' }),
			value: 'N/A',
			unit: '',
			img: 'respiratory',
			type: MeasurementTypes.OXYGEN,
		},
		{
			id: 3,
			name: intl.formatMessage({ id: 'bloodPressure' }),
			value: 'N/A',
			unit: '',
			img: 'heart',
			type: MeasurementTypes.BLOOD_PRESSURE,
		},
		{
			id: 4,
			name: intl.formatMessage({ id: 'weight' }),
			value: 'N/A',
			unit: '',
			img: 'body-measurements',
			type: MeasurementTypes.WEIGHT,
		},
	]);
	const [ewsScore, setEwsScore] = useState(null);

	const getLatestMeasurementData = async () => {
		const latestMeasurements = await getLatestMeasurements(props.patientId, true);
		if (latestMeasurements.error) {
			setErrorApiResponse(latestMeasurements.error.message);
			return;
		}
		const newVitalSigns = [...vitalSigns];
		latestMeasurements.measurements.forEach(item => {
			const measurementType =
				item.measurementType === MeasurementTypes.DIABETES ? MeasurementTypes.BLOOD_GLUCOSE : item.measurementType;
			if (newVitalSigns.some(el => el.type === measurementType)) {
				const found = newVitalSigns.find(element => element.type === measurementType);
				found.value = convertMeasurementTypes(
					item.unitCategoryId,
					item.measurementValue,
					getUnitPreference(item.unitCategoryId)?.unitSystemId
				);
				found.startDate = item.startDate;
				found.unit = item.measurementUnit;
			}
		});
		setEwsScore(latestMeasurements.totalEarlyWarningScore);
		setVitalSigns(newVitalSigns);
	};

	useEffect(() => {
		const getMeasurementData = async () => {
			const { error, unitPreferences } = await getUserMeasurementUnits(getUserId());
			if (error) {
				setErrorApiResponse(error.message);
			} else {
				setPreferredUnits(unitPreferences);
			}
		};
		getMeasurementData();
		getLatestMeasurementData();
	}, []);

	useEffect(() => {
		socket.on(SocketEvents.HelloDevice.PATIENT_HEALTH_MEASUREMENTS, healthMeasurementsListener);
		socket.on(SocketEvents.HealthCare.MEASUREMENT_ADDED, data => {
			if (props.patientId === data.patientId) {
				getLatestMeasurementData();
			}
		});

		return () => {
			socket.off(SocketEvents.HelloDevice.PATIENT_HEALTH_MEASUREMENTS, healthMeasurementsListener);
			socket.off(SocketEvents.HealthCare.MEASUREMENT_ADDED, data => {
				if (props.patientId === data.patientId) {
					getLatestMeasurementData();
				}
			});
		};
	}, [socket]);

	const healthMeasurementsListener = data => {
		const newVitalSigns = [...vitalSigns];
		const { measurementType, measurementValue, measurementUnit, unitCategoryId } = data;
		const found = newVitalSigns.find(element => measurementType === element.type);
		if (found) {
			found.value =
				measurementValue === 'Measuring'
					? `${intl.formatMessage({ id: 'measuring' })}...`
					: convertMeasurementTypes(unitCategoryId, measurementValue, getUnitPreference(unitCategoryId)?.unitSystemId);
			found.unit = measurementValue === 'Measuring' ? '' : measurementUnit;
			setVitalSigns(newVitalSigns);
		}
	};

	const getCategoryPreference = categoryId => preferredUnits.find(item => item.unitCategoryId === categoryId);

	const getUnitPreference = categoryId => {
		const selectedPreference = getCategoryPreference(categoryId);
		return selectedPreference?.options.find(item => item.unitSystemId === selectedPreference.unitSystemId);
	};

	const getVitalSignsDetails = () => (
		<div className='vital-signs-ai rpm-measurements-ttp z-index-1'>
			{vitalSigns.map(vitalSign => {
				return (
					<div className='flex vital-signs-details rpm-measurements-ttp-details' key={vitalSign.id}>
						<img src={`${healthCareCdnUrl}vital-signs/${vitalSign.img}.svg`} alt='icon' />
						<div className='flex column-direction'>
							<span>{vitalSign.name}</span>
							<div className='flex'>
								<span className='right-s'>{vitalSign.value}</span>
								<span>{vitalSign.unit}</span>
							</div>
						</div>
					</div>
				);
			})}
			<div className='flex vital-signs-details rpm-measurements-ttp-details'>
				<img src={`${healthCareCdnUrl}vsm/score-red-bg.svg`} alt='icon' />
				<div className='flex column-direction'>
					<span>{translate('ewsScore')}</span>
					<div className='flex'>
						<span className='right-s'>{ewsScore || 'N/A'}</span>
					</div>
				</div>
			</div>
		</div>
	);

	return (
		<>
			<div className={classNames('flex monitoring-vital-signs-ai-wrapper', !props.showAllVitalSigns ? 'row' : '')}>
				{getVitalSignsDetails()}
			</div>
			<Alert display={errorApiResponse} fixed={true} hideCloseButton={true} message={errorApiResponse} variant='dark' />
		</>
	);
};

export default RpmMeasurements;
