import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Modal, Form } from 'components';
import { DefaultCameraTypes, HelloSettings } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import { getDeviceSetting, updateDeviceSettings } from 'api/devices.js';
import { useSelector } from 'react-redux';

const DefaultCameraModal = props => {
	const intl = useIntl();
	const cameraNames = useSelector(state => state.company.companySettings);

	const cameraTypes = [
		{
			id: 1,
			label: `${cameraNames.helloName} ${intl.formatMessage({ id: 'camera' })}`,
			value: DefaultCameraTypes.HELLO,
		},
		{
			id: 2,
			label: `${cameraNames.huddleName} ${intl.formatMessage({ id: 'camera' })}`,
			value: DefaultCameraTypes.HUDDLE,
		},
	];

	const [isSettingsModalLoading, setIsSettingsModalLoading] = useState(false);

	useEffect(() => {
		getCameraTypeDeviceSetting(props.deviceId);
	}, []);

	const getCameraTypeDeviceSetting = async deviceId => {
		if (deviceId) {
			const response = await getDeviceSetting(deviceId, HelloSettings.CAMERA_TYPE);
			if (response.error) {
				setIsSettingsModalLoading(false);
				return;
			}
			let defaultCamType = DefaultCameraTypes.HELLO.toString();
			if (response.deviceSettings.length) {
				defaultCamType = response.deviceSettings[0].value.toString();
			}
			setIsSettingsModalLoading(false);
			props.getDefaultCamType(defaultCamType, deviceId);
		}
	};

	const updateDeviceSetting = async settingTypeId => {
		const { defaultCamType } = props.selectedDevice;
		setIsSettingsModalLoading(true);
		const params = {
			deviceId: props.deviceId,
			settingTypeId,
			settingValue: defaultCamType.toString(),
		};
		const response = await updateDeviceSettings(params);
		if (response.error) {
			props.setError(response.error.message);
			return;
		}
		setIsSettingsModalLoading(false);
		props.setIsDefaultCameraModalOpen(false);
	};

	const onChangeDefaultCamera = defaultCamType => {
		if (defaultCamType) {
			props.getDefaultCamType(defaultCamType);
		}
	};

	return (
		<Modal
			display={props.display}
			position='center'
			submitButtonText='Save'
			isLoading={isSettingsModalLoading}
			onModalClose={() => props.setIsDefaultCameraModalOpen(false)}
			onModalSubmit={() => updateDeviceSetting(HelloSettings.CAMERA_TYPE)}
			className='wrapper-modal border-radius-modal-wrapper appoinment-next-arrow-modal'
			submitImgIcon={`${healthCareCdnUrl}appointments/save-icon.svg`}
			closeImgIcon={`${healthCareCdnUrl}appointments/cancel-appointment.svg`}
			isSubmitDisabled={props.isSubmitDisabled}>
			<Form title={translate('defaultCamera')} onSubmit={event => event.preventDefault()} height={500}>
				<div>
					<p className='semi-bold'>{translate('selectDefaultCamera')}</p>
					<div className='flex'>
						{cameraTypes.map(item => (
							<label key={item.id} className='semi-bold margin-top-m margin-bottom-m'>
								<input
									type='radio'
									name='cameraType'
									value={item.value.toString()}
									onChange={event => onChangeDefaultCamera(event.target.value)}
									checked={props.selectedDevice?.defaultCamType === item.value.toString()}
								/>
								{item.label}
							</label>
						))}
					</div>
				</div>
				<p>
					{translate('selectedDefaultCameraDescription')} <br />
					{translate('defaultCameraDescription', { value: cameraNames.huddleName })}
				</p>
			</Form>
		</Modal>
	);
};

export default DefaultCameraModal;
