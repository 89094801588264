import React from 'react';
import styled from 'styled-components';
import LightTheme from 'calls/styles/LightTheme.js';

/**
 * @typedef {{variant?: number}} props
 * @type {import('styled-components').StyledComponent<"button", any, props, never>}
 */
const StyledCheckbox = styled.div`
	display: inline-flex;
	position: relative;
	width: 18px;
	height: 18px;
	box-shadow: 0 1px 2px var(--gray-8-o10);

	input {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		margin: 0;

		~ span {
			display: block;
			width: 100%;
			height: 100%;
			border: 1px solid ${LightTheme.colors.grayThree};
			border-radius: 3px;
		}

		&:checked ~ span {
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='4' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'%3E%3Cpolyline points='20 6 9 17 4 12'/%3E%3C/svg%3E");
			background-size: 14px;
			background-repeat: no-repeat;
			background-position: center;
		}
	}
`;

/**
 * @param {props & React.PropsWithRef<React.ButtonHTMLAttributes<HTMLButtonElement>>} props
 */
const Checkbox = ({ ...htmlAttributes }) => {
	return (
		<StyledCheckbox>
			<input {...htmlAttributes} type='checkbox' />
			<span />
		</StyledCheckbox>
	);
};

export default Checkbox;
