import ProfilePicture from 'components/ProfilePicture.jsx';
import { Fragment } from 'react';

const HeaderInfo = ({ sections, goBack = null, hideProfile = null, patient = { name: '', url: null } }) => {
	return (
		<div className='patient-header-info flex'>
			{goBack && (
				<div className='back-icon' onClick={goBack}>
					<i className='material-icons'>keyboard_arrow_left</i>
				</div>
			)}
			{!goBack && <div className='no-back-icon' />}
			<div>
				<div className='flex flex-align-center'>
					<div>
						<ProfilePicture fullName={patient.name} profilePicture={patient.url} className='provider-intervening-img-wrapper' />
					</div>
					<div className='left-s'>
						<p>{patient.name}</p>
					</div>
				</div>
			</div>
			{sections.map(section => (
				<Fragment key={section.id}>
					<div className='info-description'>
						{section.labels.map(label => (
							<p key={label}>
								<span>{label}</span>
							</p>
						))}
					</div>
					<div className='info-value'>
						{section.values.map((value, index) => (
							<p key={value + index}>
								<span>{value}</span>
							</p>
						))}
					</div>
				</Fragment>
			))}
			{hideProfile}
		</div>
	);
};

export default HeaderInfo;
