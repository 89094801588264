import React from 'react';
import classNames from 'classnames';

const InputFeedback = ({ children }) => <small>{children}</small>;

const Input = ({ field: { name, ...field }, form: { touched, errors }, ...props }) => {
	const error = errors ? errors[name] : '';
	const touch = touched ? touched[name] : '';
	return (
		<div className={classNames('input', props.variant, touch && error && 'error')} style={{ marginBottom: props.bottomSpace, ...props.style }}>
			<p className='label'>{props.label}</p>
			{props.description && <p>{props.description}</p>}
			<input id={props.name ? props.name : name} type={props.type || 'text'} {...field} {...props} />
			{touch && error && <InputFeedback>{error}</InputFeedback>}
		</div>
	);
};

export default Input;
