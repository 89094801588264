import { Cam, Mic, ScreenAudio, ScreenShare } from '@solaborate/calls/webrtc';
import ControlsActions from 'calls/enums/ControlsActions.js';

// eslint-disable-next-line no-control-regex
export const emailValidationRegEx =
	/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export const countriesData = {
	KOS: {
		name: 'Kosovo',
		code: 'KOS',
		phonePrefix: '+383',
		phonePattern: '+{383} 00 000-000',
		flag: '🇽🇰',
	},
	US: {
		name: 'United States',
		code: 'US',
		phonePrefix: '+1',
		phonePattern: '+{1} (000) 000-0000',
		flag: '🇺🇸',
	},
};

/**
 * @type {import('@solaborate/calls/webrtc').Constraints}
 */
export const defaultConstraints = {
	[Mic]: true,
	[Cam]: {
		width: { ideal: 1280 },
		height: { ideal: 720 },
	},
	[ScreenAudio]: true,
	[ScreenShare]: {
		width: { ideal: 1920 },
		height: { ideal: 1080 },
	},
};

export const MedicalControlsActions = [
	ControlsActions.TOGGLE_HEALTH_MEASUREMENTS,
	ControlsActions.TOGGLE_CAMERA_MEASUREMENTS,
	ControlsActions.TOGGLE_PATIENT_HISTORY,
	ControlsActions.TOGGLE_LIVE_EXAMINATIONS,
	ControlsActions.TOGGLE_TELEMETRY,
	ControlsActions.TOGGLE_INVITE_PARTICIPANTS_MODAL,
];
