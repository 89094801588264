import React, { useState, useEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import Bar from 'components/Bar.jsx';
import { getRoleKpis } from 'api/dashboard.js';
import Grid from 'components/Grid.jsx';
import Alert from 'components/Alert.jsx';
import translate from 'i18n-translations/translate.jsx';
import Loader from 'components/Loader.jsx';
import { adjustTimeForUTC, formatTimeDurationWithSeconds } from 'infrastructure/helpers/dateHelper.js';
import { doesParamValueExist } from 'infrastructure/helpers/commonHelpers.js';

const ChartWrapper = styled.div`
	> p {
		font-size: 12px;
		margin: 0;
		padding: 0;
		margin-top: var(--spacing-xl);
		text-align: left;
	}
	> div {
		&:last-of-type {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: var(--spacing-l);
			margin-top: var(--spacing-l);
			> div {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 3px;
				> div {
					&:first-of-type {
						width: 12px;
						height: 12px;
						border-radius: 5px;
						margin-right: 5px;
						flex-shrink: 0;
					}
				}
				p {
					margin: 0;
					padding: 0;
					text-align: center;
					display: block;
					font-size: 12px;
					line-height: 14px;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}
	}
`;

const CallsByInitiator = props => {
	const intl = useIntl();
	const translator = useCallback(id => intl.formatMessage({ id }), [intl]);

	const [chartData, setChartData] = useState(props.initialData);
	const [chartSummary, setChartSummary] = useState({ totalSessions: 0, averageDuration: 0, totalDuration: 0 });
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		const getChartSummary = data => {
			let totalSessions = 0;
			let totalDuration = 0;
			props.callTypes.forEach(callType => {
				const callTypeData = data.find(item => item.callType === callType);
				if (callTypeData) {
					totalSessions += callTypeData.count;
					totalDuration += callTypeData.totalDuration;
				}
			});
			const averageDuration = totalSessions !== 0 ? Math.round(totalDuration / totalSessions) : 0;
			return { totalSessions, totalDuration, averageDuration };
		};

		const getChartData = (data, totalSessions) => {
			const chartData = [];
			props.initialData.forEach(item => {
				const newItem = { ...item };
				const roleData = data.filter(data => data.companyMemberRoleId === item.roleId && props.callTypes.includes(data.callType));
				let count = roleData.reduce((acc, curr) => acc + curr.count, 0);
				const percentage = Math.round((count / totalSessions) * 100);
				newItem.percentage = percentage;
				newItem.count = count;
				if (count !== 0) {
					chartData.push(newItem);
				}
			});
			return chartData;
		};

		const fetchData = async () => {
			setIsLoading(true);
			const response = await getRoleKpis({
				start: Math.floor(adjustTimeForUTC(props.start).getTime() / 1000),
				end: Math.floor(adjustTimeForUTC(props.end, true).getTime() / 1000),
				...(doesParamValueExist(props.selectedHealthSystem) && { healthSystemId: props.selectedHealthSystem.value }),
				...(doesParamValueExist(props.selectedHospital) && {
					regionId: props.selectedHospital.regionId,
					hospitalId: props.selectedHospital.value,
				}),
				...(doesParamValueExist(props.selectedDepartment) && { departmentId: props.selectedDepartment.value }),
				...(doesParamValueExist(props.selectedFloor) && { floorId: props.selectedFloor.value }),
				...(doesParamValueExist(props.selectedRoom) && { roomId: props.selectedRoom.value }),
				...(props.selectedTimezone.value && { timezone: props.selectedTimezone.value }),
			});
			if (!response.error) {
				const newChartSummary = getChartSummary(response.callTypes);
				const newChartData = getChartData(response.data, newChartSummary.totalSessions);

				setChartSummary(newChartSummary);
				setChartData(newChartData);
			} else {
				setError(
					translate('somethingWentWrongSpecific', {
						value: translator('providers'),
					})
				);
			}
			setIsLoading(false);
		};
		if (props.selectedHealthSystem) {
			fetchData();
		}
	}, [
		props.start,
		props.end,
		props.selectedHealthSystem,
		props.selectedHospital,
		props.selectedDepartment,
		props.selectedFloor,
		props.selectedRoom,
		props.selectedTimezone,
	]);

	return useMemo(() => {
		return (
			<>
				<ChartWrapper>
					<h3>
						{translate('sessionByRole', {
							value: intl.formatMessage({ id: props.type }),
						})}
					</h3>
					{isLoading && (
						<Grid columns='1fr' rows='1fr' stretch='300px' horizAlign='center' vertAlign='center'>
							<Loader />
						</Grid>
					)}
					{!isLoading && (
						<>
							<div className='bar-data'>
								<span>
									<p>{translator('totalNumberSessions')}</p>
									<span>{chartSummary.totalSessions > 0 ? chartSummary.totalSessions : translator('noData')}</span>
								</span>
								<span>
									<p>{translator('averageSessionDuration')}</p>
									<span>
										{chartSummary.totalSessions > 0
											? formatTimeDurationWithSeconds(chartSummary.averageDuration, ' ')
											: translator('noData')}
									</span>
								</span>
								<span>
									<p>{translator('totalDuration')}</p>
									<span>
										{chartSummary.totalSessions > 0
											? formatTimeDurationWithSeconds(chartSummary.totalDuration, ' ')
											: translator('noData')}
									</span>
								</span>
							</div>
							<Bar data={chartData} />
							<div style={{ gridTemplateColumns: `repeat(${chartData.length}, 1fr)`, gridGap: '40px' }}>
								{chartData.map(item => (
									<div key={item.id} data-tooltip={`${translator('sessions')}: ${item.count}`} data-position='top'>
										<div style={{ backgroundColor: item.color }} />
										<p>
											{translator(item.text)} {`(${item.percentage}%)`}
										</p>
									</div>
								))}
							</div>
						</>
					)}
				</ChartWrapper>
				<Alert display={error} fixed={true} onClose={() => setError(null)} message={error} variant='dark' />
			</>
		);
	}, [isLoading, chartData, props.type]);
};

export default CallsByInitiator;
