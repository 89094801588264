import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import translate from 'i18n-translations/translate.jsx';
import Button from 'components/Button.jsx';
import Input from 'components/Input.jsx';
import { updateOrganizationSettings } from 'api/companies.js';
import PopUpAlert from 'components/PopUpAlert.jsx';
import { AlertTypes, StatAlarmType } from 'constants/enums.js';
import { reorderObjects } from 'infrastructure/helpers/commonHelpers.js';
import { urlRegex } from 'infrastructure/helpers/validationHelper.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import StatAlarms from 'components/StatAlarms.jsx';
import { IntegrationTypesSettings, RoundingSettings } from 'constants/configurationEnums.js';
import classNames from 'classnames';
import AiConfigurationFeatureFlags from 'components/AiConfigurationFeatureFlags.jsx';

const CreateEditFeatureFlags = props => {
	const intl = useIntl();
	const [featureFlags, setFeaturesFlags] = useState(props.dataToSubmit[props.settingCategory]);
	const [isSaveLoading, setIsSaveLoading] = useState(false);
	const [alertType, setAlertType] = useState(null);
	const [isStatAlarmModalOpen, setIsStatAlarmModalOpen] = useState(false);
	const [selectedStatAlarm, setSelectedStatAlarm] = useState(null);
	const [selectedType, setSelectedType] = useState({});
	const [expandedRows, setExpandedRows] = useState([]);
	const statAlarmCdnUrl = `${healthCareCdnUrl}admin/super-admin/stat-alarms/alarm-`;
	const isGloboEnabled = props.dataToSubmit?.integrationTypesSettings?.[IntegrationTypesSettings.GLOBO]?.value;
	const isAmnEnabled = props.dataToSubmit?.integrationTypesSettings?.[IntegrationTypesSettings.AMN]?.value;
	const availableAlarms = [
		{ label: `${intl.formatMessage({ id: 'alarm' })} 1`, id: StatAlarmType.ALARM_1, url: `${statAlarmCdnUrl}1.mp3` },
		{ label: `${intl.formatMessage({ id: 'alarm' })} 2`, id: StatAlarmType.ALARM_2, url: `${statAlarmCdnUrl}2.mp3` },
	];

	useEffect(() => {
		if (props.featureTypes) {
			setSelectedType({
				value: props.featureTypes[0].value,
				label: intl.formatMessage({ id: props.featureTypes[0].label }),
			});
			const filtered = filterConfigsByType(props.featureTypes[0].value);
			setFeaturesFlags(filtered);
		}
	}, []);

	const onSubmit = async e => {
		e.preventDefault();
		if (!props.selectedCompany) {
			props.setDataToSubmit(featureFlags);
			props.goToNextTab();
		} else {
			setIsSaveLoading(true);

			const updatedState = {
				...props.dataToSubmit,
				roundingSettings: {
					...props.dataToSubmit.roundingSettings,
					[RoundingSettings.TranslationServices]: {
						...props.dataToSubmit.roundingSettings?.[RoundingSettings.TranslationServices],
						value: false,
					},
				},
			};
			const updatedFeatureFlags =
				props.dataToSubmit?.integrationTypesSettings?.[IntegrationTypesSettings.GLOBO].value === false &&
				props.dataToSubmit?.integrationTypesSettings?.[IntegrationTypesSettings.AMN].value === false
					? updatedState
					: props.dataToSubmit;

			const {
				monitoringSettings,
				roundingSettings,
				generalAndMenuSettings,
				integrationTypesSettings,
				roomModesSettings,
				customRoomModesSettings,
				helloCareEngagementSettings,
				whiteboardSettings,
				aiSettings,
				patients,
				userExperience,
				dashboardSettings,
			} = updatedFeatureFlags;
			const featureFlagsParams = Object.entries({
				...monitoringSettings,
				...roundingSettings,
				...generalAndMenuSettings,
				...integrationTypesSettings,
				...roomModesSettings,
				...customRoomModesSettings,
				...helloCareEngagementSettings,
				...whiteboardSettings,
				...aiSettings,
				...patients,
				...userExperience,
				...dashboardSettings,
			}).flatMap(([key, config]) => {
				return {
					settingTypeId: config.settingTypeId ? config.settingTypeId : key,
					value: config.value.toString(),
					...(config.variant ? { variant: config.variant.value } : {}),
					...(config.roomTypeId ? { roomTypeId: config.roomTypeId } : {}),
					...(config.isURLField && { value: !config.value ? 'false' : config.value }),
					...(config.aiConfigurationTypeId &&
						config.value && {
							aiConfigurations: [
								{
									aiConfigurationTypeId: config.aiConfigurationTypeId,
									value: config.selectedSensitivity || config.selectedRails || 'true',
									snoozeTime: config.selectedSnoozeTime,
									alertNotificationOptions: Array.isArray(config.alertNotificationOptions)
										? config.alertNotificationOptions
										: [config.alertNotificationOptions],
								},
							],
						}),
				};
			});
			const settingsParams = {
				companySettings: featureFlagsParams,
				companyId: props.selectedCompany.id,
			};
			const response = await updateOrganizationSettings(settingsParams);
			if (!response.error) {
				setAlertType(AlertTypes.SUCCESS);
				props.setDataToSubmit(featureFlags);
			} else {
				setAlertType(AlertTypes.DANGER);
				if (props.featureTypes) {
					const filtered = filterConfigsByType(props.featureTypes[0].value);
					setFeaturesFlags(filtered);
				} else {
					setFeaturesFlags(props.dataToSubmit[props.settingCategory]);
				}
			}

			setIsSaveLoading(false);
		}
	};

	const doubleToggleItem = (obj, key) => {
		const clonedObj = { ...obj };
		if (
			key === RoundingSettings.CareEventsForNurses &&
			clonedObj[key].value &&
			clonedObj[RoundingSettings.CareEventsForSafetyCompanions].value
		) {
			clonedObj[RoundingSettings.CareEventsForSafetyCompanions].value = false;
		}
		if (
			key === RoundingSettings.CareEventsForSafetyCompanions &&
			clonedObj[key].value &&
			clonedObj[RoundingSettings.CareEventsForNurses].value
		) {
			clonedObj[RoundingSettings.CareEventsForNurses].value = false;
		}

		return clonedObj;
	};

	const toggleItem = key => {
		setFeaturesFlags(prevState => {
			const configsCopied = _.cloneDeep(prevState);
			configsCopied[key].value = !configsCopied[key].value;
			if (configsCopied[key].variant && configsCopied[key].options && !configsCopied[key].value) {
				configsCopied[key].variant = configsCopied[key].options[0];
			}
			const result = doubleToggleItem(configsCopied, key);
			return result;
		});
		const createCompanyState = props.dataToSubmit[props.settingCategory];
		createCompanyState[key].value = !createCompanyState[key].value;
		if (createCompanyState[key].variant && createCompanyState[key].options && !createCompanyState[key].value) {
			createCompanyState[key].variant = createCompanyState[key].options[0];
		}
		const result = doubleToggleItem(createCompanyState, key);
		props.setDataToSubmit(result);
	};

	const customizeConfig = (key, variant) => {
		setFeaturesFlags(prevState => {
			const configsCopied = _.cloneDeep(prevState);
			configsCopied[key].variant = configsCopied[key].options.find(opt => opt.value === variant);
			return configsCopied;
		});
		const createCompanyState = props.dataToSubmit[props.settingCategory];
		createCompanyState[key].variant = createCompanyState[key].options.find(opt => opt.value === variant);
		props.setDataToSubmit(createCompanyState);
	};

	const handleURLInput = (key, value) => {
		const validURL = urlRegex.test(value);
		setFeaturesFlags(prevState => {
			const configsCopied = _.cloneDeep(prevState);
			configsCopied[key].value = value.trim() ? value : 'false';
			configsCopied[key].valid = validURL;

			return configsCopied;
		});
		const createCompanyState = props.dataToSubmit[props.settingCategory];
		createCompanyState[key].value = value;
		props.setDataToSubmit(createCompanyState);
	};

	const transformArray = array => array.map(item => ({ value: item.value, label: intl.formatMessage({ id: item.label }) }));

	const groupedCategories = Object.entries(featureFlags).reduce((acc, [key, item]) => {
		const { category } = item;
		if (!acc[category]) {
			acc[category] = [];
		}
		acc[category].push([key, item]);
		return acc;
	}, []);

	const featureFlagsCategories = props.categoryOrder ? reorderObjects(groupedCategories, props.categoryOrder) : groupedCategories;

	const setConfigurationsByType = type => {
		setSelectedType(type);
		const filtered = filterConfigsByType(type.value);
		setFeaturesFlags(filtered);
	};

	const filterConfigsByType = type =>
		Object.keys(props.dataToSubmit[props.settingCategory]).reduce((acc, key) => {
			if (props.dataToSubmit[props.settingCategory][key].roomTypeId === type) {
				acc[key] = props.dataToSubmit[props.settingCategory][key];
			}
			return acc;
		}, {});

	const customizeAiConfig = (key, property, value) => {
		setFeaturesFlags(prevState => {
			const configsCopied = _.cloneDeep(prevState);
			configsCopied[key][property] = value;
			return configsCopied;
		});
		const createCompanyState = props.dataToSubmit[props.settingCategory];
		createCompanyState[key][property] = value;
		props.setDataToSubmit(createCompanyState);
	};

	const customizeRails = (key, selectedRails) => {
		if (selectedRails.length < 1) {
			return;
		}
		const stringifiedValues = selectedRails.map(rail => rail.value).join('-');
		setFeaturesFlags(prevState => {
			const configsCopied = _.cloneDeep(prevState);
			configsCopied[key].selectedRails = stringifiedValues;
			return configsCopied;
		});
		const createCompanyState = props.dataToSubmit[props.settingCategory];
		createCompanyState[key].selectedRails = stringifiedValues;
		props.setDataToSubmit(createCompanyState);
	};

	const viewAiConfigurationDetails = rowIndex => {
		setExpandedRows(prevExpandedRows => {
			if (prevExpandedRows.includes(rowIndex)) {
				return prevExpandedRows.filter(row => row !== rowIndex);
			} else {
				return [...prevExpandedRows, rowIndex];
			}
		});
	};

	return (
		<div>
			{props.featureTypes && (
				<div className='feature-flags-header'>
					<div>
						<h4>{translate(props.typeTitle)}</h4>
						<Select
							value={selectedType}
							classNamePrefix='react-select'
							options={transformArray(props.featureTypes)}
							onChange={val => setConfigurationsByType(val)}
						/>
					</div>
				</div>
			)}
			{Object.keys(featureFlagsCategories).map(category => {
				const categoryItems = groupedCategories[category];
				return (
					<div className='feature-flags-category' key={category}>
						{category !== props.settingCategory && <h4>{translate(category)}</h4>}
						{categoryItems?.map(
							([key, item]) =>
								(item.title !== 'translationServices' ||
									((isGloboEnabled || isAmnEnabled) && item.title === 'translationServices')) && (
									<div className='feature-flag flex' key={key}>
										{!item.isURLField && (
											<div>
												<div
													className='rounded-slider-switch'
													onClick={() => {
														toggleItem(+key);
													}}>
													<input type='checkbox' checked={item.value} onChange={() => null} />
													<span className='rounded-slider' />
												</div>
												<p>{item.value ? translate('on') : translate('off')}</p>
											</div>
										)}
										<div className={classNames('feature-description', item.isURLField ? 'no-toggle-shown' : '')}>
											<p className='flex-1'>{translate(item.title)}</p>
											<p>
												{translate(item.description, {
													value: 'user',
													huddleName: props.cameraNames?.huddleName,
													helloName: props.cameraNames?.helloName,
												})}
											</p>
											{item.options && item.value && !item.hasButton && (
												<div className='feature-flag-options flex'>
													<p>{translate('customize')}</p>
													<Select
														value={transformArray([item.variant])}
														classNamePrefix='react-select'
														options={transformArray(item.options)}
														onChange={event => customizeConfig(key, event.value)}
													/>
												</div>
											)}
											{item.hasButton && item.value && (
												<div className='flex flex-align-center'>
													{(item.variant?.value || selectedStatAlarm) && (
														<div className='flex flex-space-between stat-alarm-details selected-alarm cursor-pointer active'>
															<div className='flex flex-align-center full-width'>
																<input
																	type='radio'
																	name='choice'
																	checked={true}
																	className='cursor-pointer'
																	onChange={() => null}
																/>
																<span className='font-14 left-s --blue-light-5'>
																	{selectedStatAlarm?.label ||
																		availableAlarms.find(element => element.id === item.variant?.value)?.label}
																</span>
															</div>
														</div>
													)}
													<div className='flex stat-alarm-flag cursor-pointer' onClick={() => setIsStatAlarmModalOpen(true)}>
														<img src={`${healthCareCdnUrl}icon/change.svg`} alt='icon' />
														<span>{translate('selectOtherAlarm')}</span>
													</div>
												</div>
											)}
											{item.isURLField && (
												<div className='feature-flag-text-input'>
													<Input
														type='text'
														placeholder={`http://example.com ${intl.formatMessage({ id: 'or' })} https://example.com`}
														value={item.value === 'false' ? '' : item.value}
														inputWidth='300px'
														onChange={event => handleURLInput(key, event.target.value)}
														name='item.title'
													/>
													{!!item.value && !item?.valid && <span className='red-error'>{translate('badURL')}</span>}
												</div>
											)}

											{item.aiConfigurationTypeId && (
												<div className='flex'>
													{expandedRows.includes(item.aiConfigurationTypeId) && (
														<AiConfigurationFeatureFlags
															aiConfigurationTypeId={item.aiConfigurationTypeId}
															isEnabled={item.value}
															title={item.title}
															selectedSensitivity={item.selectedSensitivity}
															selectedRails={item.selectedRails}
															selectedSnoozeTime={item.selectedSnoozeTime}
															onSensitivityChange={event => customizeAiConfig(key, 'selectedSensitivity', event.value)}
															onRailsChange={event => item.selectedRails && customizeRails(key, event)}
															onSnoozeTimeChange={event => customizeAiConfig(key, 'selectedSnoozeTime', event.value)}
															onAlertNotificationChange={event => customizeAiConfig(key, 'alertNotificationOptions', event)}
															alertNotificationOptions={item.alertNotificationOptions}
														/>
													)}
												</div>
											)}
										</div>
										{item.aiConfigurationTypeId && (
											<span
												className={classNames('cursor-pointer', props.selectedCompany ? 'ai-config-details' : '')}
												onClick={() => viewAiConfigurationDetails(item.aiConfigurationTypeId)}>
												<img
													src={`${healthCareCdnUrl}expand-
															${expandedRows.includes(item.aiConfigurationTypeId) ? 'less' : 'more'}.svg`}
													alt='ico'
												/>
											</span>
										)}
									</div>
								)
						)}
					</div>
				);
			})}
			<div className='create-hs__add'>
				{!props.selectedCompany && (
					<>
						<Button text={translate('goBack')} variant='white' onClick={props.goToPreviousTab} disabled={!props.isValid} />
						&nbsp;&nbsp;
					</>
				)}
				<Button
					type='submit'
					text={translate(props.selectedCompany ? 'save' : 'nextStep')}
					onClick={onSubmit}
					isLoading={isSaveLoading}
				/>
			</div>
			<PopUpAlert
				alertType={alertType}
				display={alertType}
				onAlertClose={() => setAlertType(null)}
				contentText={intl.formatMessage({ id: alertType === AlertTypes.SUCCESS ? 'changesSaved' : 'somethingWentWrong' })}
				isSilent={true}
				center={true}
				selfCloseTimeOut={1500}
			/>
			<StatAlarms
				isStatAlarmModalOpen={isStatAlarmModalOpen}
				setIsStatAlarmModalOpen={setIsStatAlarmModalOpen}
				availableAlarms={availableAlarms}
				setSelectedStatAlarm={setSelectedStatAlarm}
				customizeConfig={customizeConfig}
			/>
		</div>
	);
};

export default CreateEditFeatureFlags;
