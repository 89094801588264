import { PatientsAiSettings, SensitivityType } from 'constants/enums.js';

const getTooltipDescription = id => {
	switch (id) {
		case SensitivityType.HIGH:
			return 'highSensitivity';
		case SensitivityType.MEDIUM:
			return 'mediumSensitivity';
		case SensitivityType.LOW:
			return 'lowSensitivity';
		default:
			return ' ';
	}
};

export const aiFormatOptionLabel = (data, intl) => (
	<div className='flex flex-space-between'>
		<div>{data.label}</div>
		<div data-position='left' data-tooltip={intl.formatMessage({ id: getTooltipDescription(data.value) })}>
			<i className='material-icons-outlined font-13'>info</i>
		</div>
	</div>
);

export const convertStringToArray = inputString => {
	const positions = inputString.split('-');
	const labelMapping = {
		TL: 'leftheadrail',
		TR: 'rightheadrail',
		BL: 'leftfootrail',
		BR: 'rightfootrail',
	};

	return positions.map(position => ({
		label: labelMapping[position],
		value: position,
	}));
};

export const getSelectedAiType = settingTypeId => {
	switch (settingTypeId) {
		case PatientsAiSettings.PERSON_GETTING_OUT_OF_BED:
			return 'getOutOfBed';
		case PatientsAiSettings.RAIL_DOWN:
			return 'rails';
		case PatientsAiSettings.FALL_DETECTED:
			return 'fallDetection';
		default:
			return '';
	}
};
