import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import Select from 'react-select';
import classNames from 'classnames';
import { ConfigSettingType, MonitoringSettings } from 'constants/configurationEnums.js';
import translate from 'i18n-translations/translate.jsx';
import {
	updateRoomSettings,
	updateTeamSettings,
	getRoomsBySectorId,
	getTeamSettings,
	getRoomSettings,
} from 'api/adminConfigurations.js';
import { DeviceListLevel, PatientsAiSettings } from 'constants/enums.js';
import { EmptyState, Modal } from 'components/index.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import SkeletonLoader from 'components/SkeletonLoader.jsx';
import { reorderObjects } from 'infrastructure/helpers/commonHelpers.js';
import { useSelector } from 'react-redux';
import AiConfigurationFeatureFlags from 'components/AiConfigurationFeatureFlags.jsx';

const AiFeatureFlags = props => {
	const disabledToggleTimeouts = useRef({});
	const cameraNames = useSelector(state => state.company.companySettings);
	const [roomsToBeOverwritten, setRoomsToBeOverwritten] = useState([]);
	const [selectedConfig, setSelectedConfig] = useState(null);
	const [adminConfigurations, setAdminConfigurations] = useState({});
	const [isLoading, setLoading] = useState(true);
	const [isEmptyState, setIsEmptyState] = useState(false);
	const [expandedRows, setExpandedRows] = useState([]);
	const [selectedAiSettings, setSelectedAiSettings] = useState(null);

	useEffect(() => {
		setLoading(true);
		const fetchSectorSettings = async () => {
			const response =
				props.currentSector !== DeviceListLevel.ROOM
					? await getTeamSettings({
							teamId: props.levelId,
							levelId: props.currentSector,
							settingsCategory: [props.settingCategory],
					  })
					: await getRoomSettings(props.levelId, props.settingCategory);

			if (response.error) {
				props.setError(response.error.message);
			} else {
				const featureFlagConfigs = {};
				response.settings.forEach(item => {
					const config = props.defaultConfigurations[item.settingTypeId];
					if (config) {
						config.value = JSON.parse(item.value);
						config.isLocked = item.isLocked;

						if (item?.aiConfigurations?.length > 0) {
							config.selectedSensitivity = item.aiConfigurations.find(
								item => item.aiConfigurationTypeId === PatientsAiSettings.PERSON_GETTING_OUT_OF_BED
							)?.value;

							config.selectedRails = item.aiConfigurations.find(
								item => item.aiConfigurationTypeId === PatientsAiSettings.RAIL_DOWN
							)?.value;

							config.selectedSnoozeTime = item.aiConfigurations.find(item => item)?.snoozeTime;

							config.alertNotificationOptions = item.aiConfigurations.find(item => item)?.alertNotificationOptions;
						}

						featureFlagConfigs[item.settingTypeId] = config;
					}
				});

				setAdminConfigurations(featureFlagConfigs);
				setIsEmptyState(Object.keys(featureFlagConfigs).length === 0);
			}
			setLoading(false);
		};

		fetchSectorSettings();

		return () => {
			Object.values(disabledToggleTimeouts.current).forEach(timeout => {
				clearTimeout(timeout);
			});
		};
	}, [props.levelId, props.settingCategory, props.selectedRole]);

	const updateConfigState = (key, settingType) => {
		setAdminConfigurations(prevState => {
			const configsCopied = _.cloneDeep(prevState);
			configsCopied[key].value = settingType === ConfigSettingType.TOGGLE ? !configsCopied[key].value : configsCopied[key].value;
			configsCopied[key].isLocked =
				settingType === ConfigSettingType.LOCK ? !configsCopied[key].isLocked : configsCopied[key].isLocked;

			return configsCopied;
		});
	};

	const updateRoomConfiguration = async (setting, settingType) => {
		const isAi = setting.aiConfigurations && setting.aiConfigurations.length > 0;
		const response = await updateRoomSettings(props.levelId, [setting], isAi);
		const key = setting.settingTypeId;
		if (response.error) {
			props.setError(response.error.message);
		} else {
			updateConfigState(key, settingType);
			if (MonitoringSettings.HandWashing === key) {
				props.setShowHandHygiene(setting.value === 'true');
			}
		}
	};

	const updateTeamsConfigurations = async ({ isOverride, settingType, key, selectedItem = null }) => {
		const item = adminConfigurations[key];
		const setting = {
			teamSettings: [
				{
					settingTypeId: key,
					value: settingType === ConfigSettingType.TOGGLE ? (!item.value).toString() : item.value.toString(),
					isLocked: settingType === ConfigSettingType.LOCK ? !item.isLocked : item.isLocked,
					...(item.aiConfigurationTypeId &&
						selectedItem && {
							aiConfigurations: [
								{
									aiConfigurationTypeId: item.aiConfigurationTypeId,
									value: selectedItem.selectedSensitivity || selectedItem.selectedRails || 'true',
									snoozeTime: selectedItem.selectedSnoozeTime,
									alertNotificationOptions: selectedItem.alertNotificationOptions,
								},
							],
						}),
				},
			],
			teamId: props.levelId,
			isOverride,
			healthSystemId: props.healthSystemId,
			...(item.aiConfigurationTypeId &&
				selectedItem && {
					isAi: true,
				}),
		};

		const response = await updateTeamSettings(setting);
		if (response.error) {
			props.setError(response.error.message);
		} else {
			updateConfigState(key, settingType);
			if (
				MonitoringSettings.HandWashing === key &&
				isOverride &&
				[DeviceListLevel.DEPARTMENT, DeviceListLevel.FLOOR].includes(props.currentSector)
			) {
				props.setShowHandHygiene(!item.value);
			}
		}
		setSelectedConfig(null);
	};

	const toggleHealthSystemTeamItem = (settingType, key) => {
		updateTeamsConfigurations({ isOverride: true, settingType, key });
	};

	const toggleTeamsConfigsItem = async (settingType, key, selectedItem = null) => {
		const response = await getRoomsBySectorId(props.levelId, key, adminConfigurations[key].value.toString());
		if (response.error) {
			props.setError(response.error.message);
			return;
		}
		if (response.rooms.length > 0) {
			setRoomsToBeOverwritten(response.rooms);
			setSelectedAiSettings(selectedItem);
			setSelectedConfig(key);
		} else {
			updateTeamsConfigurations({ isOverride: true, settingType, key, selectedItem });
		}
	};

	const toggleRoomConfigsItem = (settingType, key, selectedItem = null) => {
		const item = adminConfigurations[key];
		const value = settingType === ConfigSettingType.TOGGLE ? (!item.value).toString() : item.value.toString();
		const setting = {
			settingTypeId: key,
			value,
			isLocked: item.isLocked,
			...(item.variant ? { variant: item.variant.value } : {}),
			...(item.aiConfigurationTypeId &&
				selectedItem && {
					aiConfigurations: [
						{
							aiConfigurationTypeId: item.aiConfigurationTypeId,
							value: selectedItem.selectedSensitivity || selectedItem.selectedRails || 'true',
							snoozeTime: selectedItem.selectedSnoozeTime,
							alertNotificationOptions: selectedItem.alertNotificationOptions,
						},
					],
				}),
		};

		updateRoomConfiguration(setting, settingType);
	};

	const toggleItem = key => {
		const settingType = ConfigSettingType.TOGGLE;

		clearTimeout(disabledToggleTimeouts.current[key]);

		setAdminConfigurations(prevState => {
			const updatedConfig = {
				...prevState[key],
				isDisabled: true,
			};

			return {
				...prevState,
				[key]: updatedConfig,
			};
		});

		disabledToggleTimeouts.current[key] = setTimeout(() => {
			setAdminConfigurations(prevState => {
				const updatedConfig = {
					...prevState[key],
					isDisabled: false,
				};

				return {
					...prevState,
					[key]: updatedConfig,
				};
			});
		}, 1000);
		if (props.currentSector === DeviceListLevel.HEALTH_SYSTEM) {
			toggleHealthSystemTeamItem(settingType, key);
			return;
		}
		if (props.currentSector !== DeviceListLevel.ROOM) {
			toggleTeamsConfigsItem(settingType, key);
		} else {
			toggleRoomConfigsItem(settingType, key);
		}
	};

	const toggleLock = async key => {
		const item = adminConfigurations[key];
		const setting = {
			settingTypeId: key,
			value: item.value.toString(),
			isLocked: !item.isLocked,
		};

		if (props.currentSector === DeviceListLevel.ROOM) {
			updateRoomConfiguration(setting, ConfigSettingType.LOCK);
		} else {
			updateTeamsConfigurations({ isOverride: true, settingType: ConfigSettingType.LOCK, key });
		}
	};

	const isLockEditable = () =>
		[DeviceListLevel.ROOM, DeviceListLevel.FLOOR, DeviceListLevel.DEPARTMENT, DeviceListLevel.HOSPITAL].includes(
			props.currentSector
		);

	const groupedCategories = Object.entries(adminConfigurations).reduce((acc, [key, item]) => {
		const { category } = item;
		if (!acc[category]) {
			acc[category] = [];
		}
		acc[category].push([key, item]);
		return acc;
	}, []);

	const featureFlagsCategories = props.categoryOrder ? reorderObjects(groupedCategories, props.categoryOrder) : groupedCategories;

	const customizeAiConfig = (key, property, selectedValue) => {
		const updatedItem = { ...adminConfigurations[key], [property]: selectedValue };
		setAdminConfigurations(prevState => ({
			...prevState,
			[key]: updatedItem,
		}));
	};

	const customizeRails = (key, selectedRails) => {
		if (selectedRails.length < 1) {
			return;
		}
		const stringifiedValues = selectedRails.map(rail => rail.value).join('-');
		const updatedItem = { ...adminConfigurations[key], selectedRails: stringifiedValues };
		setAdminConfigurations(prevState => ({
			...prevState,
			[key]: updatedItem,
		}));
	};

	const handleSaveAiConfigurations = async (key, item) => {
		const settingType = ConfigSettingType.SELECT;
		if (props.currentSector === DeviceListLevel.HEALTH_SYSTEM) {
			await updateTeamsConfigurations({
				isOverride: true,
				settingType,
				key,
				selectedItem: item,
			});
			return;
		}
		if (props.currentSector !== DeviceListLevel.ROOM) {
			await toggleTeamsConfigsItem(settingType, key, item);
		} else {
			await toggleRoomConfigsItem(settingType, key, item);
		}
	};

	const viewAiConfigurationDetails = rowIndex => {
		setExpandedRows(prevExpandedRows => {
			if (prevExpandedRows.includes(rowIndex)) {
				return prevExpandedRows.filter(row => row !== rowIndex);
			} else {
				return [...prevExpandedRows, rowIndex];
			}
		});
	};

	return (
		<>
			<div className='feature-flags'>
				{props.currentSector === DeviceListLevel.HEALTH_SYSTEM && Object.keys(adminConfigurations).length > 0 && (
					<div className='feature-flags-header'>
						<div>
							<h4>{translate('selectHealthSystem')}</h4>
							<Select
								value={props.selectedHealthSystem}
								classNamePrefix='react-select'
								options={props.healthSystems.map(item => ({ value: item.id, label: item.name || item.value }))}
								onChange={val => props.setSelectedHealthSystem(val)}
							/>
						</div>
						{props.selectedRole && (
							<div>
								<h4>{translate('selectRole')}</h4>
								<Select
									value={props.selectedRole}
									classNamePrefix='react-select'
									options={props.roleList}
									onChange={props.setSelectedRole}
								/>
							</div>
						)}
					</div>
				)}
				{!isLoading && (
					<>
						{Object.keys(featureFlagsCategories).map(category => (
							<div className='feature-flags-category' key={category}>
								{props.categoryOrder && <h4>{translate(category)}</h4>}
								{groupedCategories[category]?.map(([key, item]) => (
									<div className='feature-flag flex' key={key}>
										<div>
											{isLockEditable() && (
												<i
													className='material-icons cursor-pointer right-s opacity-transformation'
													onClick={() => toggleLock(+key)}>
													{item.isLocked ? 'lock' : 'lock_open'}
												</i>
											)}
											<div
												className={classNames('rounded-slider-switch', item.isDisabled ? 'disabled' : '')}
												onClick={() => toggleItem(+key)}>
												<input type='checkbox' checked={item.value} onChange={() => null} />
												<span className='rounded-slider' />
											</div>
											<p>{item.value ? translate('on') : translate('off')}</p>
										</div>
										<div className='feature-description'>
											<p className='flex-1 ai-feature-title'>{translate(item.title)}</p>
											<div className='flex'>
												<p>
													{translate(item.description, {
														value: translate(props.selectedRole?.value?.toLowerCase()) ?? 'user',
														huddleName: cameraNames.huddleName,
														helloName: cameraNames.helloName,
													})}
												</p>
												{item.aiConfigurationTypeId && (
													<span
														className={classNames(
															'cursor-pointer left-margin-l',
															props.selectedCompany ? 'ai-config-details' : ''
														)}
														onClick={() => viewAiConfigurationDetails(item.aiConfigurationTypeId)}>
														<img
															src={`${healthCareCdnUrl}expand-
																${expandedRows.includes(item.aiConfigurationTypeId) ? 'less' : 'more'}.svg`}
															alt='ico'
														/>
													</span>
												)}
											</div>
											{item.aiConfigurationTypeId && expandedRows.includes(item.aiConfigurationTypeId) && (
												<div className='flex column-direction'>
													<AiConfigurationFeatureFlags
														aiConfigurationTypeId={item.aiConfigurationTypeId}
														isEnabled={item.value}
														title={item.title}
														selectedSensitivity={item.selectedSensitivity}
														selectedRails={item.selectedRails}
														selectedSnoozeTime={item.selectedSnoozeTime}
														onSensitivityChange={event => customizeAiConfig(key, 'selectedSensitivity', event.value)}
														onRailsChange={event => item.selectedRails && customizeRails(key, event)}
														onSnoozeTimeChange={event => customizeAiConfig(key, 'selectedSnoozeTime', event.value)}
														onAlertNotificationChange={event => customizeAiConfig(key, 'alertNotificationOptions', event)}
														alertNotificationOptions={item.alertNotificationOptions}
													/>
													<button
														type='button'
														className={classNames('ai-config-btn', !item.value ? 'disabled' : '')}
														onClick={() => handleSaveAiConfigurations(key, item)}>
														{translate('save')}
													</button>
												</div>
											)}
										</div>
									</div>
								))}
							</div>
						))}
					</>
				)}
				{!isLoading && isEmptyState && <EmptyState title={translate('pleaseReachAdmin')} image='no-files.svg' />}
				{isLoading && <SkeletonLoader rows={10} padding='35px 20px' />}
			</div>
			<Modal
				position='center'
				modalSelector='confirmOverwritingAdminConfigs'
				onModalClose={() => setSelectedConfig(null)}
				className='confirm-overwrite-configs'
				display={selectedConfig}
				primaryButtonLabel={translate('applyToAll')}
				onModalSubmit={() =>
					updateTeamsConfigurations({
						isOverride: true,
						settingType: selectedAiSettings ? ConfigSettingType.SELECT : ConfigSettingType.TOGGLE,
						key: selectedConfig,
						selectedItem: selectedAiSettings,
					})
				}
				isThirdButtonShown
				onThirdButtonClick={() =>
					updateTeamsConfigurations({
						isOverride: false,
						settingType: selectedAiSettings ? ConfigSettingType.SELECT : ConfigSettingType.TOGGLE,
						key: selectedConfig,
						selectedItem: selectedAiSettings,
					})
				}
				thirdButtonLabel={translate('exclude')}>
				<div className='overwrite-configs-content'>
					<h2>
						{translate('warning')} - {translate('featureOverwrite')}
					</h2>
					<p>{translate('waringOverwriteDescription')}</p>
					<h4>{translate('roomsWithDifferentConfigs')}:</h4>
					<div className='rooms-list flex'>
						{roomsToBeOverwritten.map(item => (
							<div key={item.room.id}>
								<img className='device-owner-ico' src={`${healthCareCdnUrl}treeview/Room.svg`} alt='ico' />
								<span>{item.room.name}</span>
							</div>
						))}
					</div>
					<p>{translate('bySavingThisDescription')}</p>
				</div>
			</Modal>
		</>
	);
};

export default AiFeatureFlags;
