import { getPreferredLanguageLocale } from 'infrastructure/helpers/commonHelpers.js';
import moment from 'moment';
import 'moment/locale/ar.js';
import 'moment/locale/de.js';
import 'moment/locale/es.js';
import 'moment/locale/sq.js';

export function formatDate(dateObj) {
	let month = `${dateObj.getMonth() + 1}`;
	let day = `${dateObj.getDate()}`;
	const year = dateObj.getFullYear();

	if (month.length < 2) month = `0${month}`;
	if (day.length < 2) day = `0${day}`;

	return [day, month, year].join('/');
}

export function dateMonthNameFormat(dateObj) {
	return `${dateObj.toLocaleString('default', { month: 'long' })}, ${dateObj.getDate()} ${dateObj.getFullYear()}`;
}

export function calculateHoursBetween(startDate, endDate) {
	const difference = endDate.getTime() - startDate.getTime();
	return (difference / (1000 * 60 * 60)).toFixed(2);
}

export function formatTime(dateObj) {
	let h = `${dateObj.getHours()}`;
	let m = `${dateObj.getMinutes()}`;

	if (h.length < 2) h = `0${h}`;
	if (m.length < 2) m = `0${m}`;

	return `${h}:${m}`;
}

export function calculateDuration(startedAt, endedAt) {
	let delta = Math.abs(startedAt - endedAt) / 1000;

	const days = Math.floor(delta / 86400);
	delta -= days * 86400;

	let hours = Math.floor(delta / 3600) % 24;
	delta -= hours * 3600;

	let minutes = Math.floor(delta / 60) % 60;
	delta -= minutes * 60;

	let seconds = Math.round(delta % 60);

	if (hours < 10) hours = `0${hours}`;
	if (minutes < 10) minutes = `0${minutes}`;
	if (seconds < 10) seconds = `0${seconds}`;

	return `${hours}:${minutes}:${seconds}`;
}

export function getLastActivityTime(unixTimestamp) {
	if (moment.utc(unixTimestamp).isBefore(moment.utc(), 'days')) {
		return moment(unixTimestamp).local().locale(getPreferredLanguageLocale()).format('DD MMM');
	}
	return moment(unixTimestamp).local().locale(getPreferredLanguageLocale()).format('HH:mm');
}

export function getRequestAcceptedTime(unixTimestamp) {
	if (moment.utc(unixTimestamp).isBefore(moment.utc(), 'days')) {
		return moment(unixTimestamp).local().locale(getPreferredLanguageLocale()).format('DD MMM');
	}
	return moment(unixTimestamp).local().locale(getPreferredLanguageLocale()).format('HH:mm');
}

/**
 * @deprecated Use convertUTCDateToLocalDate instead
 */
export function utcToLocalTime(date) {
	const dateNow = moment.utc(new Date()).toDate();
	const stillUtc = moment.utc(date).toDate();
	const localDate = moment(stillUtc);
	const hours = differenceInHours(dateNow, stillUtc);
	return hours > 24
		? moment(localDate).locale(getPreferredLanguageLocale()).format('ll, LT')
		: moment(localDate).locale(getPreferredLanguageLocale()).format('LT');
}

export function differenceInHours(date1, date2) {
	// returns the difference in hours between the two dates given as parameters,
	// CAUTION: returns negative or postitive value
	return moment.duration(moment(date1).diff(moment(date2))).asHours();
}

export function formattedDate(date) {
	const currentDate = moment.utc(date);

	let res = moment.utc(currentDate).locale(getPreferredLanguageLocale()).local().format('MMM DD, YYYY, LT');

	const isThisYear = moment().year() - currentDate.year() === 0;
	const isToday = currentDate.diff(moment(), 'days') === 0;

	if (isThisYear) {
		res = moment.utc(currentDate).locale(getPreferredLanguageLocale()).local().format('MMM DD, LT');
	}
	if (isToday) {
		res = moment.utc(currentDate).locale(getPreferredLanguageLocale()).local().format('LT');
	}
	return res;
}

export const formatTimestampDate = date => {
	const currentDate = moment.utc(date);

	let res = moment.utc(currentDate).locale(getPreferredLanguageLocale()).format('MMM DD, YYYY, LT');

	const isThisYear = moment().year() - currentDate.year() === 0;
	const isToday = currentDate.diff(moment(), 'days') === 0;

	if (isThisYear) {
		res = moment.utc(currentDate).locale(getPreferredLanguageLocale()).format('MMM DD, LT');
	}
	if (isToday) {
		res = moment.utc(currentDate).locale(getPreferredLanguageLocale()).format('LT');
	}

	return res;
};

export function defaultDateFormat(date) {
	return moment.utc(date).local().locale(getPreferredLanguageLocale()).format('MMM DD, LT');
}

export function getFormattedDate(date) {
	const currentDate = moment.utc(date).local();
	let newDate = moment.utc(currentDate).locale(getPreferredLanguageLocale()).format('MMM DD, YYYY');
	const isToday = currentDate.diff(moment(), 'days') === 0;
	const isYesterday = moment().diff(currentDate, 'days') === 1;
	const isThisYear = moment().year() - currentDate.year() === 0;
	if (isThisYear) {
		newDate = moment(currentDate).locale(getPreferredLanguageLocale()).format('MMM DD');
	}
	if (isYesterday) {
		newDate = 'Yesterday';
	}
	if (isToday) {
		newDate = moment(currentDate).locale(getPreferredLanguageLocale()).format('LT');
	}
	return newDate;
}

export const getFormattedHours = date => {
	const currentDate = moment.utc(date).local();
	const hours = moment(currentDate).format('HH:mm');
	return hours;
};

export const addMinutes = (date, minutes) => {
	return new Date(moment(date).add(minutes, 'minutes').format('YYYY-MM-DDTHH:mm:ss'));
};

export const subtractMinutes = (date, minutes) => {
	return new Date(moment(date).subtract(minutes, 'minutes').format('YYYY-MM-DDTHH:mm:ss'));
};

export const monthDayYear = date => {
	return moment.utc(date).local().locale(getPreferredLanguageLocale()).format('MMM DD, YYYY');
};

export const getDateDifference = (start, end) => moment.duration(moment(end).diff(start)).asDays();

export const timeDifference = (date1, date2) => (date1.getTime() - date2.getTime()) / 1000;

export const convertUTCDateToLocalDate = date => {
	const newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
	const offset = date.getTimezoneOffset() / 60;
	const hours = date.getHours();
	newDate.setHours(hours - offset);
	return newDate;
};

export const getDay = (weekDay, intl) => {
	let result;
	switch (weekDay) {
		case 1: {
			result = intl.formatMessage({ id: 'monday' });
			break;
		}
		case 2: {
			result = intl.formatMessage({ id: 'tuesday' });
			break;
		}
		case 3: {
			result = intl.formatMessage({ id: 'wednesday' });
			break;
		}
		case 4: {
			result = intl.formatMessage({ id: 'thursday' });
			break;
		}
		case 5: {
			result = intl.formatMessage({ id: 'friday' });
			break;
		}
		case 6: {
			result = intl.formatMessage({ id: 'saturday' });
			break;
		}
		case 7: {
			result = intl.formatMessage({ id: 'sunday' });
			break;
		}
		default: {
			result = intl.formatMessage({ id: 'N/A' });
			break;
		}
	}
	return result;
};

export const parseStringToDate = dateString => {
	const date = new Date();
	const [hours, minutes] = dateString.split(':');
	date.setMinutes(minutes);
	date.setHours(hours);
	return date;
};

export const scheduledDays = [
	{
		weekDay: 1,
		hours: [{ from: null, to: null, id: 0 }],
	},
	{
		weekDay: 2,
		hours: [{ from: null, to: null, id: 0 }],
	},
	{
		weekDay: 3,
		hours: [{ from: null, to: null, id: 0 }],
	},
	{
		weekDay: 4,
		hours: [{ from: null, to: null, id: 0 }],
	},
	{
		weekDay: 5,
		hours: [{ from: null, to: null, id: 0 }],
	},
	{
		weekDay: 6,
		hours: [{ from: null, to: null, id: 0 }],
	},
	{
		weekDay: 7,
		hours: [{ from: null, to: null, id: 0 }],
	},
];

export const getDateWithSeconds = date => {
	return moment.utc(date).locale(getPreferredLanguageLocale()).local().format('MMM DD, YYYY h:mm:ss A');
};

export const getHoursWithMinutes = date => {
	return moment.utc(date).locale(getPreferredLanguageLocale()).local().format('h:mm A');
};

export const getPositionDuration = (seconds, intl) => {
	if (seconds < 60) {
		return `${seconds} ${intl.formatMessage({ id: seconds === 1 ? 'second' : 'seconds' })}`;
	}
	return formatTimeDurationWithSeconds(seconds);
};

export const formatTimeDurationWithSeconds = (seconds, joinWith) => {
	const hours = Math.floor(seconds / 3600);
	const minutes = Math.floor((seconds % 3600) / 60);
	const remainingSeconds = Math.floor(seconds % 60);

	const parts = [];

	if (hours > 0) {
		parts.push(`${hours}h`);
	}

	if (minutes > 0) {
		parts.push(`${minutes}m`);
	}

	if (remainingSeconds > 0 || (hours === 0 && minutes === 0)) {
		parts.push(`${remainingSeconds}s`);
	}

	return parts.join(joinWith || ':');
};

export const convertUTCHourToCurrentTimezone = hour => {
	const utcDate = new Date();
	utcDate.setUTCHours(hour);

	const localHour = utcDate.getHours();
	return localHour;
};

export const getMonthDayYearDateFormat = date => moment.utc(date).format('MM/DD/YYYY');

export const getDateDifferenceInMinutes = (from, to) => {
	if (!from || !to) return 0;
	const fromUnix = +from;
	const toUnix = +to;
	const unixDiff = toUnix - fromUnix;
	return unixDiff / 1000 / 60;
};

export const getLocalTimeHHmmss = startDate => moment.utc(startDate).local().format('HH:mm:ss');

export const getHoursMinutesSeconds = (startDate, endDate) => {
	const diffMilliseconds = endDate.diff(startDate);
	const diffDuration = moment.duration(diffMilliseconds);

	const days = diffDuration.days();
	const hours = diffDuration.hours();
	const minutes = diffDuration.minutes();
	const seconds = diffDuration.seconds();
	if (days === 0 && hours === 0 && minutes === 0) {
		return `${seconds} sec`;
	}
	if (days === 0 && hours === 0 && minutes !== 0) {
		return `${minutes} min`;
	}
	if (days === 0 && hours !== 0 && minutes !== 0) {
		return `${hours}h : ${minutes} min`;
	}
	return `${days}d : ${hours}h`;
};

export const convertToTimestampSecondsWithMaxTime = inputDate => {
	const dateObj = new Date(inputDate);
	dateObj.setHours(23, 59, 59, 999);

	return dateObj.getTime() / 1000;
};

export const getFormattedDateWithHour = date => {
	const newDate = moment.utc(date);
	const isToday = newDate.diff(moment(), 'days') === 0;
	if (isToday) {
		return moment.utc(date).locale(getPreferredLanguageLocale()).format('LT');
	}
	return moment.utc(date).locale(getPreferredLanguageLocale()).format('MM/DD/YYYY-hh:mm A');
};

export const getHumanizedDateWithHour = date => moment.utc(date).locale(getPreferredLanguageLocale()).format('lll');

export const adjustTimeForUTC = (date, endOfDay = false) => {
	if (!date) {
		return null;
	}
	const dateObj = new Date(date);
	if (endOfDay) {
		dateObj.setHours(23, 59, 59, 999);
	} else {
		dateObj.setHours(0, 0, 0, 0);
	}
	dateObj.setMinutes(dateObj.getMinutes() - dateObj.getTimezoneOffset());

	return dateObj;
};

export const minutesToMilliseconds = minutes => minutes * 60 * 1000;

export const convertToUnixTimeStamp = date => Math.floor(new Date(date).getTime() / 1000);

// Used whenever date is given in seconds
export const getDurationFormat = seconds => {
	const minutes = Math.floor(seconds / 60);
	const hours = Math.floor(seconds / 3600);
	const days = Math.floor(seconds / 86400);

	if (!seconds) {
		return 'N/A';
	}

	if (seconds < 60) {
		return `${seconds}s`;
	}
	if (seconds < 3600) {
		const s = minutes * 60;
		return `${minutes}m ${seconds - s}s`;
	}
	if (seconds < 86400) {
		const s = hours * 3600 + (minutes % 60) * 60;
		return `${hours}h ${minutes % 60}m ${seconds - s}s`;
	}

	const s = days * 86400 + (hours % 24) * 3600 + (minutes % 60) * 60;
	return `${days}d ${hours % 24}h ${minutes % 60}m ${seconds - s}s`;
};
export const getTimeDuration = seconds => {
	const hours = Math.floor(seconds / 3600);
	const minutes = Math.floor((seconds % 3600) / 60);
	const remainingSeconds = Math.floor(seconds % 60);
	const timeStamp = [];

	const getTime = (value, isSecond = false) => {
		const secondsString = !isSecond ? ':' : '';

		if (value === 0) {
			timeStamp.push(`00${secondsString}`);
		} else if (value < 10) {
			timeStamp.push(`0${value}${secondsString}`);
		} else {
			timeStamp.push(`${value}${secondsString}`);
		}
	};

	getTime(hours);
	getTime(minutes);
	getTime(remainingSeconds, true);

	return timeStamp;
};
