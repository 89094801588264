import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { ChatOptionsViews, CompanyRoles, UserRoles } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import ImagesOrVideos from 'containers/Chat/ImagesOrVideos';
import Participants from 'containers/Chat/Participants';
import Links from 'containers/Chat/Links';
import Attachments from 'containers/Chat/Attachments';
import { getUserRole } from 'infrastructure/auth.js';

const ChatRightSide = props => {
	const [selectedChatOption, setSelectedOption] = useState(ChatOptionsViews.DEFAULT);
	const intl = useIntl();

	const getNursesOnDutyLength = () => {
		let result = 0;
		result = props.members.filter(item => item?.roles.some(role => role.id === CompanyRoles.VIRTUAL_CARE_PROVIDER)).length;
		if (UserRoles.NURSE === getUserRole()) {
			result -= 1;
		}
		return result;
	};

	const getChatRightSideOptionsItem = ({
		imageLink = '',
		title = '',
		count = null,
		content = '',
		className = '',
		option = 0,
	}) => {
		const imgLink = `${healthCareCdnUrl}chat/`;
		return (
			<div className='flex cursor-pointer' onClick={() => setSelectedOption(option)}>
				<div className={`chat-option-item ${className}`}>
					<img src={`${imgLink}${imageLink}`} alt='chat option icon' />
				</div>
				<div>
					<p>{title}</p>
					<p>
						{count} {content}
					</p>
				</div>
				<img src={`${healthCareCdnUrl}teams/arrow.svg`} alt='arrow icon' />
			</div>
		);
	};

	const getChatRightOptionsList = () => {
		return [
			{
				id: ChatOptionsViews.PARTICIPANTS,
				component: (
					<Participants
						setDefaultOptions={setSelectedOption}
						conversationId={props.conversationId}
						members={props.members}
						nursesOnDutyLength={getNursesOnDutyLength()}
					/>
				),
			},
			{
				id: ChatOptionsViews.IMAGES,
				component: (
					<ImagesOrVideos
						setDefaultOptions={setSelectedOption}
						type={ChatOptionsViews.IMAGES}
						conversationId={props.conversationId}
						members={props.members}
					/>
				),
			},
			{
				id: ChatOptionsViews.VIDEOS,
				component: (
					<ImagesOrVideos
						setDefaultOptions={setSelectedOption}
						type={ChatOptionsViews.VIDEOS}
						conversationId={props.conversationId}
						members={props.members}
					/>
				),
			},
			{
				id: ChatOptionsViews.ATTACHMENTS,
				component: <Attachments setDefaultOptions={setSelectedOption} conversationId={props.conversationId} />,
			},
			{
				id: ChatOptionsViews.LINKS,
				component: <Links setDefaultOptions={setSelectedOption} conversationId={props.conversationId} />,
			},
		];
	};

	return (
		<aside className='chat-right-side'>
			{selectedChatOption === ChatOptionsViews.DEFAULT && (
				<>
					<div className='chat-right-side-info'>
						<img src={`${healthCareCdnUrl}teams/department-ico.svg?v2`} alt='department icon' />
						<p>{props.conversationName}</p>
					</div>
					<div className='chat-right-side-buttons'>
						<div className='flex patient-info-call'>
							{/* to be defined what action these buttons should have */}
							{/* <Link to={getCallLink('audio')} target='_blank'>
								<div className='patient-info-audio patient-info-inner-div' />
							</Link>
							<Link to={getCallLink('video')} target='_blank'>
								<div className='patient-info-video patient-info-inner-div' />
							</Link> */}
						</div>
					</div>
					<div className='chat-right-side-options'>
						{getChatRightSideOptionsItem({
							imageLink: 'participants.svg',
							count: getNursesOnDutyLength(),
							title: intl.formatMessage({ id: 'participants' }),
							content: `${getNursesOnDutyLength() === 1 ? ' Nurse is' : ' Nurses are'} currently on duty`,
							className: 'participants',
							option: ChatOptionsViews.PARTICIPANTS,
						})}
						{getChatRightSideOptionsItem({
							imageLink: 'image.svg',
							title: intl.formatMessage({ id: 'images' }),
							content: intl.formatMessage({ id: 'sharedImages' }),
							className: 'images',
							option: ChatOptionsViews.IMAGES,
						})}
						{getChatRightSideOptionsItem({
							imageLink: 'videos.svg',
							title: intl.formatMessage({ id: 'videos' }),
							content: intl.formatMessage({ id: 'sharedVideos' }),
							className: 'videos',
							option: ChatOptionsViews.VIDEOS,
						})}
						{getChatRightSideOptionsItem({
							imageLink: 'attachments.svg',
							title: intl.formatMessage({ id: 'attachments' }),
							content: intl.formatMessage({ id: 'sharedAttachments' }),
							className: 'attachments',
							option: ChatOptionsViews.ATTACHMENTS,
						})}
						{getChatRightSideOptionsItem({
							imageLink: 'links.svg',
							title: intl.formatMessage({ id: 'links' }),
							content: intl.formatMessage({ id: 'sharedLinks' }),
							className: 'links',
							option: ChatOptionsViews.LINKS,
						})}
					</div>
				</>
			)}
			{selectedChatOption !== ChatOptionsViews.DEFAULT &&
				getChatRightOptionsList().find(item => item.id === selectedChatOption)?.component}
		</aside>
	);
};

export default ChatRightSide;
