import React from 'react';
import classNames from 'classnames';
import ProfilePicture from 'components/ProfilePicture.jsx';
import { decodeHtml } from 'infrastructure/helpers/commonHelpers.js';

const ChatAlert = props => (
	<div className={classNames('chat-alert alert fixed')}>
		{props.alerts.map(alert => (
			<div key={alert.id} className='chat-list-item flex position-relative' onClick={() => props.sendToRelevantChannel(alert)}>
				<i className='material-icons' onClick={e => props.closeChatAlert(e, alert.id)}>
					close
				</i>
				{!alert.isEvent && (
					<div className='align-self-start position-relative'>
						<div className='flex position-relative chat-list-img-status-wrapper '>
							<ProfilePicture className='chat-list-img' fullName={alert.displayName} profilePicture={alert.profilePicture} />
						</div>
					</div>
				)}
				<div
					className='chat-list-name header-search-name flex flex-1'
					style={{ paddingLeft: alert.isEvent ? '0' : 'var(--spacing-l)' }}>
					<p title={decodeHtml(`${alert.displayName}`)}>{decodeHtml(`${alert.displayName}`)}</p>
					<p className='chat-list-channel-name' title={decodeHtml(`${alert.displayName}`)}>
						{decodeHtml(`${alert.from}`)}
					</p>
					<span className='chat-list-recent-message'>{alert.message}</span>
					<span className='chat-list-time'>{alert.date}</span>
				</div>
			</div>
		))}
	</div>
);

export default ChatAlert;
