import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { getUserMeasurementUnits } from 'api/measurements.js';
import Alert from 'components/Alert.jsx';
import { MeasurementTypes, MeasurementUnits, UnitCategoryTypes } from 'constants/enums.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { convertMeasurementTypes } from 'infrastructure/helpers/measurementsHelper.js';
import { getUserId } from 'infrastructure/auth.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';

const LifeSignalsMeasurements = ({
	isPtzActive,
	shouldShowAIVitals,
	shouldShowAllItems,
	numberOfFeeds,
	notesShowing,
	hasConditions,
	showLifeSignalsIframe,
	deviceOwner,
	isHidden,
}) => {
	const intl = useIntl();
	const LifeSignalsAlertTypes = {
		LOW: 'LOW',
		MEDIUM: 'MEDIUM',
		HIGH: 'HIGH',
	};
	const measurementsInitialArr = [
		{
			id: 1,
			name: intl.formatMessage({ id: 'respiration' }),
			value: 'RR',
			unit: intl.formatMessage({ id: MeasurementUnits.BREATHS_PER_MIN }),
			img: 'respiratory-rate',
			type: MeasurementTypes.RESPIRATORY_RATE,
		},
		{
			id: 2,
			name: intl.formatMessage({ id: 'oxygen' }),
			value: 'SPO2',
			unit: MeasurementUnits.PERCENTAGE,
			img: 'spo2',
			type: MeasurementTypes.OXYGEN,
			shortName: 'SPO2',
		},
		{
			id: 3,
			name: intl.formatMessage({ id: 'heartRate' }),
			value: 'HR',
			unit: MeasurementUnits.BPM,
			img: 'heart-rate',
			type: MeasurementTypes.HEART_RATE,
			shortName: 'HR',
		},

		{
			id: 4,
			name: intl.formatMessage({ id: 'bloodPressure' }),
			value: 'BPSYS',
			secondValue: 'BPDIA',
			unit: MeasurementUnits.MMHG,
			img: 'blood-pressure',
			type: MeasurementTypes.BLOOD_PRESSURE,
			shortName: 'BP',
		},
		{
			id: 5,
			name: intl.formatMessage({ id: 'bodyTemperature' }),
			value: 'BODYTEMP',
			unit: MeasurementUnits.CELSIUS,
			img: 'temperature',
			type: MeasurementTypes.TEMPERATURE,
		},
	];

	const socket = useContext(SocketContext);

	const [preferredUnits, setPreferredUnits] = useState([]);
	const [errorApiResponse, setErrorApiResponse] = useState(null);
	const [measurements, setMeasurements] = useState(null);
	const [lifeSignalsAlerts, setLifeSignalsAlerts] = useState([]);

	useEffect(() => {
		const getLifeSignalVitalSign = (type, lifeSignals, isTemperature = false) => {
			const lastValidValue = lifeSignals.SensorData.reduce((acc, current) => {
				if (current[type] && current[type].length > 0 && current[type][current[type].length - 1] >= 0) {
					return current[type][current[type].length - 1];
				}
				return acc;
			}, null);

			return lastValidValue && isTemperature ? lastValidValue / 1000 : lastValidValue;
		};

		const measurementsListener = result => {
			if (+deviceOwner?.userId === +result.PatientId) {
				let newMeasurements = { ...measurements };
				newMeasurements = {
					RR: getLifeSignalVitalSign('RR', result),
					SPO2: getLifeSignalVitalSign('SPO2', result),
					HR: getLifeSignalVitalSign('HR', result),
					BPSYS: getLifeSignalVitalSign('BPSYS', result),
					BPDIA: getLifeSignalVitalSign('BPDIA', result),
					BODYTEMP: getLifeSignalVitalSign('BODYTEMP', result, true),
				};
				if (result.StreamAlert?.length > 0) {
					setLifeSignalsAlerts(result.StreamAlert);
				}

				setMeasurements(newMeasurements);
			}
		};

		socket.on(SocketEvents.LifeSignals.PATCH_DATA, measurementsListener);
		return () => {
			socket.off(SocketEvents.LifeSignals.PATCH_DATA, measurementsListener);
		};
	}, [deviceOwner?.userId, measurements, socket]);

	const noMeasurementsValueText = shouldShowAllItems ? `${intl.formatMessage({ id: 'measuring' })}...` : '....';

	useEffect(() => {
		const getMeasurementData = async () => {
			const { error, unitPreferences } = await getUserMeasurementUnits(getUserId());
			if (error) {
				setErrorApiResponse(error.message);
			} else {
				setPreferredUnits(unitPreferences);
			}
		};
		getMeasurementData();
	}, []);

	const getCategoryPreference = categoryId => preferredUnits.find(item => item.unitCategoryId === categoryId);

	const getUnitPreference = categoryId => {
		const selectedPreference = getCategoryPreference(categoryId);
		return selectedPreference?.options.find(item => item.unitSystemId === selectedPreference.unitSystemId);
	};

	const getMeasurementValue = item => {
		if (item.type === MeasurementTypes.BLOOD_PRESSURE) {
			return measurements?.BPSYS && measurements?.BPDIA
				? `${measurements.BPSYS}/${measurements?.BPDIA}`
				: noMeasurementsValueText;
		}
		if (item.type === MeasurementTypes.TEMPERATURE) {
			return measurements?.BODYTEMP
				? convertMeasurementTypes(
						UnitCategoryTypes.TEMPERATURE,
						measurements?.BODYTEMP,
						getUnitPreference(UnitCategoryTypes.TEMPERATURE)?.unitSystemId
				  )
				: noMeasurementsValueText;
		}
		return measurements?.[item.value] || noMeasurementsValueText;
	};

	const getMeasurementUnit = item => {
		if (getMeasurementValue(item) === noMeasurementsValueText) {
			return '';
		}
		if (item.type === MeasurementTypes.TEMPERATURE) {
			return getUnitPreference(UnitCategoryTypes.TEMPERATURE)?.unit || item.unit;
		}
		return item.unit;
	};

	const getAlertClassName = alert => {
		let className = '';
		const alertType = alert[2];
		switch (alertType) {
			case LifeSignalsAlertTypes.LOW:
				className = 'low-alert';
				break;
			case LifeSignalsAlertTypes.MEDIUM:
				className = 'medium-alert';
				break;
			case LifeSignalsAlertTypes.HIGH:
				className = 'high-alert';
				break;
			default:
				break;
		}
		return className;
	};

	const shouldShowItem = item => {
		let showItem = true;

		if (!shouldShowAllItems) {
			showItem = [MeasurementTypes.HEART_RATE, MeasurementTypes.OXYGEN, MeasurementTypes.BLOOD_PRESSURE].includes(item.type);
		}
		return showItem;
	};

	const getVitalSignsDetails = isRight => {
		return (
			<div
				className={classNames(
					'vital-signs-ai',
					isHidden ? 'hidden' : 'flex',
					isRight && shouldShowAllItems ? 'right' : '',
					isPtzActive ? 'ptz-active' : '',
					numberOfFeeds > 1 ? 'multiple-feeds' : '',
					shouldShowAIVitals ? 'ai-vital-signs-showing' : '',
					hasConditions ? 'has-conditions' : '',
					notesShowing ? 'feed-notes-showing' : '',
					!shouldShowAllItems ? 'flex-wrap' : ''
				)}>
				{shouldShowAllItems && (
					<div
						className='flex vital-signs-details cursor-pointer'
						onClick={showLifeSignalsIframe}
						style={{ background: 'var(--blue-2-o50)' }}>
						<img src={`${healthCareCdnUrl}monitoring/open-monitoring-white.svg`} alt='icon' />

						<div className='flex column-direction left-5'>
							<span>{intl.formatMessage({ id: 'monitor' })}</span>
						</div>
					</div>
				)}
				{measurementsInitialArr.map(item => {
					const foundAlert = lifeSignalsAlerts.find(alert => alert[0] === item.value);
					return (
						shouldShowItem(item) && (
							<div
								className={classNames('flex vital-signs-details', foundAlert ? getAlertClassName(foundAlert) : '')}
								key={item.id}>
								<img src={`${healthCareCdnUrl}monitoring/biobeat/${item.img}.svg`} alt='icon' />

								<div className='flex column-direction left-5'>
									<span>{shouldShowAllItems ? item.name : item.shortName}</span>
									<div className='flex'>
										<span className='right-2'>{getMeasurementValue(item)}</span>
										<span>{getMeasurementUnit(item)}</span>
									</div>
								</div>
							</div>
						)
					);
				})}
			</div>
		);
	};

	return (
		<>
			<div className={classNames('flex monitoring-vital-signs-ai-wrapper', !shouldShowAllItems ? 'row' : '')}>
				{getVitalSignsDetails(true)}
			</div>
			<Alert display={errorApiResponse} fixed={true} hideCloseButton={true} message={errorApiResponse} variant='dark' />
		</>
	);
};

export default LifeSignalsMeasurements;
