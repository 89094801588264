import * as React from 'react';

const BabyCrying = props => (
	<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none' {...props}>
		<g transform='scale(0.75)'>
			<path
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth={1.5}
				d='M5.52 1.2H2.2a1 1 0 0 0-1 1v3.32M18.48 22.8h3.32a1 1 0 0 0 1-1v-3.32m0-12.96V2.2a1 1 0 0 0-1-1h-3.32M1.2 18.48v3.32a1 1 0 0 0 1 1h3.32'
			/>
			<path
				fill='currentColor'
				d='M10.165 12.373a.688.688 0 1 0 0-1.377.688.688 0 0 0 0 1.377ZM14.227 12.373a.688.688 0 1 0 0-1.377.688.688 0 0 0 0 1.377Z'
			/>
			<path
				stroke='currentColor'
				strokeLinecap='round'
				strokeMiterlimit={10}
				strokeWidth={1.5}
				d='M9.523 14.236a3.221 3.221 0 0 0 4.956 0'
			/>
			<path
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth={1.5}
				d='M18.35 11.464h.073a1.377 1.377 0 0 1 0 2.753c-.086.01-.172.01-.257 0a6.424 6.424 0 0 1-12.333 0 1.169 1.169 0 0 1-.257-.009 1.376 1.376 0 1 1 0-2.753h.073a6.424 6.424 0 0 1 12.7 0v.01Z'
			/>
			<path
				stroke='currentColor'
				strokeLinecap='round'
				strokeMiterlimit={10}
				strokeWidth={1.5}
				d='M12.644 6.14a1.376 1.376 0 1 1-1.726 2.103'
			/>
		</g>
	</svg>
);
export default BabyCrying;
