import React, { useEffect } from 'react';
import classNames from 'classnames';
import Button from 'components/Button.jsx';
import Loader from 'components/Loader.jsx';
import translate from 'i18n-translations/translate.jsx';
import { useConferenceConfigurations } from 'calls/hooks/index.js';

const Modal = props => {
	const modalReference = React.createRef();
	const { display, shouldSubmitOnEnter, onModalSubmit, onModalClose } = props;

	useEffect(() => {
		const handleKeyPress = event => {
			if (event.key === 'Escape' && display === true) {
				onModalClose();
			}
			if (event.key === 'Enter' && display === true && shouldSubmitOnEnter) {
				onModalSubmit();
			}
		};
		document.addEventListener('keydown', handleKeyPress);
		return () => {
			document.removeEventListener('keydown', handleKeyPress);
		};
	}, [display, shouldSubmitOnEnter, onModalSubmit, onModalClose]);

	const handleOutsideClick = event => {
		const modalClass = `.${modalReference.current.classList[0]}`;
		if (!event.target.closest(modalClass) && !props.keepOpenOnOutsideClick) {
			props.onModalClose();
		}
	};

	const conferenceConfigs = useConferenceConfigurations();

	return (
		<div
			data-cy={props.modalSelector}
			className={classNames(
				'modal',
				props.className,
				props.position,
				props.display ? 'show' : '',
				props.isLoading ? 'loading' : '',
				props.hideActionButtons ? 'hide-buttons' : '',
				conferenceConfigs.isDarkMode ? 'dark-modal' : ''
			)}
			onClick={evt => handleOutsideClick(evt)}
			onKeyDown={props.onKeyDown}>
			<div className='modal__wrapper' ref={modalReference}>
				<div className='modal__content' data-test-id='modal-content'>
					{props.children}
				</div>
				{!(props.hideCloseIcon ?? props.hideCloseButton) && (
					<span className='modal__close cursor-pointer' data-cy={props.modalSelector} onClick={props.onModalClose}>
						<i className='material-icons-outlined'>close</i>
					</span>
				)}
				<div className='modal__actions'>
					{props.isLoading && (
						<div className='modal__loader'>
							<Loader />
						</div>
					)}
					{!props.isLoading && !props.isViewOnly && (
						<>
							{!props.hideCloseButton && (
								<Button
									className='cancel-btn'
									text={props.closeButtonText || translate('cancel')}
									variant='white'
									size='large'
									onClick={props.onModalClose}
									type='button'
									icon={props.closeIcon || props.submitIcon}
									imgIcon={props.closeImgIcon}
								/>
							)}
							{props.isThirdButtonShown && (
								<Button
									className='save-btn third-btn'
									type='button'
									text={props.thirdButtonLabel}
									isLoading={props.isThirdButtonLoading}
									size='large'
									onClick={() => props.onThirdButtonClick()}
									isDisabled={props.isSubmitDisabled}
									icon={props.thirdBtnIcon || props.submitIcon}
									imgIcon={props.thirdImgIcon || props.submitImgIcon}
									background={props.thirdBtnBackground}
									variant={props.thirdButtonVariant || ''}
									color={props.thirdButtonColor}
								/>
							)}
							{props.primaryButtonLabel !== '' && (
								<Button
									className='save-btn'
									type='button'
									text={props.primaryButtonLabel || translate('save')}
									isLoading={props.primaryButtonLoading || false}
									size='large'
									onClick={props.onModalSubmit}
									isDisabled={props.isSubmitDisabled}
									icon={props.submitIcon || props.primaryButtonIcon}
									imgIcon={props.submitImgIcon}
									background={props.background}
									variant={props.primaryButtonVariant || ''}
									color={props.color}
								/>
							)}
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default Modal;

Modal.defaultProps = {
	shouldSubmitOnEnter: true,
};
