import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';

const companyId = getCompanyId();

export const getUserIdleConfigurations = async ({ pageSize = 10, pageIndex = 0, healthSystemId = null, roleId = null }) => {
	try {
		const { data } = await gatewayApi.get(`v1/healthcare/organizations/${companyId}/configurations/user-idle`, {
			params: {
				pageSize,
				pageIndex,
				healthSystemId,
				roleId,
			},
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const addUserIdleConfiguration = async ({ healthSystemIds, roleId, timeoutMinutes }) => {
	try {
		const config = {
			healthSystemIds,
			roleId,
			timeoutMinutes,
		};
		const { data } = await gatewayApi.post(`v1/healthcare/organizations/${companyId}/configurations/user-idle`, config);
		return data;
	} catch (error) {
		return { error };
	}
};

export const updateUserIdleConfiguration = async (idleId, isEnabled) => {
	try {
		const { data } = await gatewayApi.put(`v1/healthcare/organizations/${companyId}/configurations/user-idle/${idleId}`, {
			isEnabled,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const getUserIdleConfigurationHistory = async ({ id, pageSize = 10, pageIndex = 0 }) => {
	try {
		const { data } = await gatewayApi.get(`v1/healthcare/organizations/${companyId}/configurations/user-idle/${id}/history`, {
			params: {
				pageSize,
				pageIndex,
			},
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const getUserIdleConfigurationMinutes = async (healthSystemId, roleId) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/healthcare/organizations/${companyId}/health-systems/${healthSystemId}/configurations/user-idle/minutes?roleId=${roleId}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getHealthSystemHospitals = async healthSystemId => {
	try {
		const { data } = await gatewayApi.get(`v1/organizations/${companyId}/healthsystems/${healthSystemId}/hospitals`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getUserIdleConfigurationHealthSystems = async () => {
	try {
		const { data } = await gatewayApi.get(`/v1/healthcare/organizations/${companyId}/health-systems/configurations/user-idle`, {
			params: {
				companyId,
			},
		});
		return data;
	} catch (error) {
		return { error };
	}
};
