import React from 'react';
import translate from 'i18n-translations/translate.jsx';
import ProfilePicture from 'components/ProfilePicture.jsx';
import { stringToCamelCase } from 'infrastructure/helpers/commonHelpers.js';

const DoctorProfile = props => {
	const {
		isActive,
		firstName,
		lastName,
		profilePicture,
		welcomeMessage,
		specialty,
		visitCost,
		visitCostCurrency,
		userLanguages,
		userEducations,
		yearsOfExperience,
		fellowshipSpecialty,
	} = props.doctor;

	return (
		<div className='standard-modal-inner modal-wrapper-wo-btn-inner view-doctor-profile-modal'>
			<div>
				<ProfilePicture
					className='doctor-request-img available-doctor-request-img'
					isActive={isActive}
					firstName={firstName}
					lastName={lastName}
					profilePicture={profilePicture}
				/>
				<div>
					<p>
						Dr. {firstName} {lastName}
					</p>
					<span>{specialty && specialty.name ? translate(stringToCamelCase(specialty.name)) : translate('familyPhysician')}</span>
				</div>
			</div>
			<div className='view-profile-info flex'>
				<div>
					<img src='https://static.solaborate.com/healthcare-system/doctor-request/bio.svg' alt='icon' />
				</div>
				<div>
					<p>{translate('welcomeMessage')}</p>
					<span>{welcomeMessage}</span>
				</div>
			</div>
			<div className='view-profile-info flex'>
				<div>
					<img src='https://static.solaborate.com/healthcare-system/doctor-request/visit-cost.svg' alt='icon' />
				</div>
				<div>
					<p>{translate('visitCost')}</p>
					<span>{visitCost ? `${visitCost} ${visitCostCurrency}` : 'N/A'}</span>
				</div>
			</div>
			<div className='view-profile-info flex'>
				<div>
					<img src='https://static.solaborate.com/healthcare-system/doctor-request/language.svg' alt='icon' />
				</div>
				<div>
					<p>{translate('languagesSpoken')}</p>
					{userLanguages && (
						<span>
							{userLanguages.map((lang, index) => (
								<>
									{translate(lang.toLowerCase())}
									{index + 1 !== userLanguages.length && `, `}
								</>
							))}
						</span>
					)}
				</div>
			</div>
			<div className='view-profile-info flex'>
				<div>
					<img src='https://static.solaborate.com/healthcare-system/doctor-request/education.svg' alt='icon' />
				</div>
				<div>
					<p>{translate('professionalEducation')}</p>
					{userEducations && (
						<span>
							<ul>
								{userEducations.map(education => (
									<li key={education.school}>
										{education.school}, {education.year}
									</li>
								))}
							</ul>
						</span>
					)}
				</div>
			</div>
			<div className='view-profile-info flex'>
				<div>
					<img src='https://static.solaborate.com/healthcare-system/doctor-request/years-of-experience.svg' alt='icon' />
				</div>
				<div>
					<p>{translate('yearsOfExperience')}</p>
					<span>{yearsOfExperience || 'N/A'}</span>
				</div>
			</div>
			<div className='view-profile-info flex'>
				<div>
					<img src='https://static.solaborate.com/healthcare-system/doctor-request/fellowship.svg' alt='icon' />
				</div>
				<div>
					<p>{translate('fellowship')}</p>
					<span>{fellowshipSpecialty}</span>
				</div>
			</div>
		</div>
	);
};

export default DoctorProfile;
