import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { getAiSettingsConfigurations, getConfigurationValue, updateConfigsList } from 'infrastructure/helpers/commonHelpers.js';
import { getUserRole } from 'infrastructure/auth.js';
import {
	configurableAISettings,
	configurableMonitoringMenu,
	MonitoringSettings,
	SettingsCategory,
	UserSettingTypes,
} from 'constants/configurationEnums.js';
import { getRoomSettings } from 'api/adminConfigurations.js';
import { getUserPreferences } from 'api/users.js';
import { getCareEvents } from 'api/teamConfigurationProfiles.js';
import { CareEventTypes, DataAcquisitionAlert, WearableProviderIds, WearablesStatus } from 'constants/enums.js';
import { Alert, Grid, Loader } from 'components/index.js';
import VerbalRedirections from 'components/Monitoring/VerbalRedirections.jsx';
import InterventionsMonitoring from 'components/InterventionsMonitoring.jsx';
import PrivacyMode from 'components/Monitoring/PrivacyMode.jsx';
import PatientOutOfRoom from 'containers/Monitoring/PatientOutOfRoom.jsx';
import PatientNotesSimplified from 'containers/Monitoring/PatientNoteSimplified.jsx';
import AiSettings from 'containers/Monitoring/AiSettings.jsx';
import VideoFeedActions from 'components/Monitoring/VideoFeedActions.jsx';
import PatientNotes from 'containers/Monitoring/PatientNotes.jsx';
import { forwardAiAlert } from 'api/alerts.js';
import { actionCreators as patientNotesActionCreators } from 'state/patientNotes/actions.js';
import TelemetryItems from 'components/Monitoring/TelemetryItems.jsx';
import { getDeviceOwner } from 'api/patients.js';
import { getExternalDevices } from 'api/lifeSignals.js';
import Whiteboard from 'containers/Monitoring/Whiteboard.jsx';

const MonitoringTimeline = ({
	isDarkMode,
	conferenceInfo,
	feed,
	toggleOffPrivacy,
	togglePrivacyMode,
	isSimplifiedPatientForm,
	hierarchyNaming,
	onAiSettingClick,
	isNoteShowing,
	toggleFallPrevention,
	isFromAlertCenter = false,
	toggleNightVision,
	numberOfFeeds,
	setSnoozeType,
	stopVoiceOver,
	onAlertInfoClose,
	verbalRedirection,
	setVerbalRedirection,
	aiSettings,
	isDefaultOwner,
	patientId,
	conversationId,
	isBioBeatActive,
	toggleBioBeat,
	isLifeSignalsActive,
	toggleLifeSignals,
	setLifeSignalsWearableId,
	setBiobeatWearableId,
}) => {
	const dispatch = useDispatch();
	const patientNotes = useSelector(state => state.patientNotes.savedMode);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(true);
	const [adminConfigurations, setAdminConfigurations] = useState(configurableMonitoringMenu(getUserRole()));
	const [adminAiSettingsConfigurations, setAdminAiConfigurations] = useState(configurableAISettings());
	const [careEvents, setCareEvents] = useState([]);
	const userSession = useSelector(state => state.user.userSession);
	const [patientOutOfRoomCareEvent, setPatientOutOfRoomCareEvent] = useState(null);
	const [patientReturnedCareEvent, setPatientReturnedCareEvent] = useState(null);
	const [patientUserId, setPatientUserId] = useState(null);
	const [lifeSignalsWearable, setLifeSignalsWearable] = useState(null);
	const [biobeatWearable, setBiobeatWearable] = useState(null);
	const intl = useIntl();

	const excludedCareEvents = [
		'Patient Privacy'.toLowerCase(),
		'Set stat alarm'.toLowerCase(),
		'Patient Out of Room'.toLowerCase(),
		'Patient Returned to Room'.toLowerCase(),
	];

	const editablePanels = [
		{ description: 'nightVision', key: MonitoringSettings.NightVision },
		{ description: 'rails', key: MonitoringSettings.Rails },
		{ description: 'getOutOfBed', key: MonitoringSettings.GetOutOfBed },
		{ description: 'fallDetection', key: MonitoringSettings.FallDetection },
		{ description: 'patientMobility', key: MonitoringSettings.PatientWalking },
		{ description: 'inactiveTime', key: MonitoringSettings.InactiveTime },
		{ description: 'aiPrivacyMode', key: MonitoringSettings.AIPrivacyMode },
		{ description: 'handWashing', key: MonitoringSettings.HandWashing },
		{ description: 'ewsAiVitalSigns', key: MonitoringSettings.EWSAIVitalSigns },
		{ description: 'ivBagFluidLevel', key: MonitoringSettings.IVBagFluidLevel },
		{ description: 'patientInfo', key: MonitoringSettings.PatientInfo },
		{ description: 'whiteboard', key: MonitoringSettings.Whiteboard },
	];

	const FeedTypes = {
		Monitoring: 0,
		AmbientMonitoring: 1,
	};

	const getEventByName = (arr, name) => {
		if (!arr) {
			return null;
		}
		return arr.find(item => item.name.toLowerCase() === name.toLowerCase());
	};

	const getCareEventsFiltered = arr => {
		if (!arr) {
			return [];
		}
		return arr.filter(item => !excludedCareEvents.includes(item.name.toLowerCase())) || [];
	};

	useEffect(() => {
		const getEvents = async () => {
			const response = await getCareEvents({
				pageSize: 10,
				pageIndex: 0,
				healthSystemId: userSession.healthSystem.id,
				teamCareEventType: CareEventTypes.SafetyCompanions,
			});
			const teamCareEvents = response?.teamCareEvents;
			if (response.error) {
				setIsLoading(false);
				return;
			}
			setCareEvents(getCareEventsFiltered(teamCareEvents));
			setVerbalRedirection(getEventByName(teamCareEvents, 'Verbal Redirection'));
			setPatientOutOfRoomCareEvent(getEventByName(teamCareEvents, intl.formatMessage({ id: 'patientOutOfRoom' })));
			setPatientReturnedCareEvent(getEventByName(teamCareEvents, intl.formatMessage({ id: 'patientReturnedToRoom' })));
			setIsLoading(false);
		};
		getEvents();
	}, [userSession.healthSystem.id]);

	useEffect(() => {
		setIsLoading(true);
		const fetchHelloDeviceOwner = async id => {
			const response = await getDeviceOwner(id);
			if (response.error) {
				setError(response.error.message);
			} else {
				setPatientUserId(response.userId);
			}
			setIsLoading(false);
		};
		if (!isDefaultOwner) {
			fetchHelloDeviceOwner(feed.deviceId);
		}
	}, [feed.deviceId, isDefaultOwner]);

	useEffect(() => {
		const getWearable = async () => {
			const response = await getExternalDevices({ patientId: patientUserId, status: WearablesStatus.ACTIVE });
			if (response.error) {
				setError(response.error.message);
				return;
			}
			if (response?.patientExternalIotDevices?.length > 0) {
				if (
					getConfigurationValue(adminConfigurations[MonitoringSettings.LifeSignals]) &&
					response.patientExternalIotDevices.some(
						wearable => wearable.externalIotDeviceType?.vendorId === WearableProviderIds.LIFE_SIGNALS
					)
				) {
					const found = response.patientExternalIotDevices.find(
						wearable => wearable.externalIotDeviceType.vendorId === WearableProviderIds.LIFE_SIGNALS
					);
					if (found) {
						setLifeSignalsWearable(found);
						setLifeSignalsWearableId(feed.deviceId, found.iotDeviceId);
					}
				}
				if (
					getConfigurationValue(adminConfigurations[MonitoringSettings.BiobeatVitalSigns]) &&
					response.patientExternalIotDevices.some(
						wearable => wearable.externalIotDeviceType?.vendorId === WearableProviderIds.BIO_BEAT
					)
				) {
					const found = response.patientExternalIotDevices.find(
						wearable => wearable.externalIotDeviceType.vendorId === WearableProviderIds.BIO_BEAT
					);
					if (found) {
						setBiobeatWearable(found);
						setBiobeatWearableId(feed.deviceId, found.iotDeviceId);
					}
				}
			}
		};

		if (
			!isDefaultOwner &&
			patientUserId &&
			(getConfigurationValue(adminConfigurations[MonitoringSettings.BiobeatVitalSigns]) ||
				getConfigurationValue(adminConfigurations[MonitoringSettings.LifeSignals]))
		) {
			getWearable();
		}
	}, [feed.deviceId, patientUserId, isDefaultOwner, adminConfigurations]);

	useEffect(() => {
		const fetchRoomSettings = async () => {
			const [adminRoomSettings, myRoomSettings, adminAiSettings, aiRoomSettings] = await Promise.all([
				getRoomSettings(feed.roomId, SettingsCategory.MONITORING),
				getUserPreferences(UserSettingTypes.Monitoring, feed.roomId),
				getRoomSettings(feed.roomId, SettingsCategory.AI_SETTINGS),
				getUserPreferences(UserSettingTypes.AiSettings, feed.roomId),
			]);
			const responseError = adminRoomSettings.error || myRoomSettings.error || adminAiSettings.error || aiRoomSettings.error;
			if (responseError) {
				setError(responseError.message);
				return;
			}
			const response = updateConfigsList(adminRoomSettings, myRoomSettings);
			const aiResponse = getAiSettingsConfigurations(adminAiSettings, aiRoomSettings);
			const resError = response.error || aiResponse.error;
			if (resError) {
				setError(resError);
			} else {
				setAdminConfigurations(response.configs);
				setAdminAiConfigurations(aiResponse.configs);
				const result = patientNotes[feed.deviceId];
				editablePanels.forEach(item => {
					result[item.description] = response.configs[item.key]?.value;
				});
				updatePatientData(result);
			}
			setIsLoading(false);
		};
		fetchRoomSettings();
	}, [feed.roomId]);

	const updatePatientData = (items = []) => {
		if (items.length === 0) {
			return;
		}
		dispatch(
			patientNotesActionCreators.setPatientDataBulk({
				...patientNotes,
				...items,
			})
		);
	};

	const isAnyTelemetryEnabled = () =>
		(getConfigurationValue(adminConfigurations[MonitoringSettings.LifeSignals]) && lifeSignalsWearable) ||
		(getConfigurationValue(adminConfigurations[MonitoringSettings.BiobeatVitalSigns]) && biobeatWearable) ||
		getConfigurationValue(adminConfigurations[MonitoringSettings.SmartWatchVitalSigns]);

	// const showNotes = getConfigurationValue(adminConfigurations[MonitoringSettings.Notes]);
	const showForwardToNurses = getConfigurationValue(adminConfigurations[MonitoringSettings.ForwardToNurses]);

	const isAnyAiSettingEnabled = () =>
		Object.values(adminAiSettingsConfigurations).some(item => item.title !== DataAcquisitionAlert.TITLE && item.value);

	const shouldShowTelemetryTab = () => conferenceInfo && isAnyTelemetryEnabled() && feed?.feedType === FeedTypes.Monitoring;

	const sendAutomaticAlert = async (alertId, isAiAlert) => {
		const { hospital, department, floor, room } = hierarchyNaming;
		const dataToSend = {
			conversationId,
			alertId,
			hospital,
			department,
			floor,
			room,
		};
		if (isAiAlert) {
			const response = await forwardAiAlert(dataToSend);
			if (response.error) {
				setError(response.error.message);
			}
		}
	};

	return (
		<div className='monitoring-timeline'>
			{isLoading && (
				<Grid columns='1fr' rows='1fr' stretch='calc(100vh - 200px)' horizAlign='center' vertAlign='center'>
					<div style={{ textAlign: 'center' }}>
						<Loader />
					</div>
				</Grid>
			)}
			{!isLoading && (
				<>
					{patientNotes[feed.deviceId]?.whiteboard && !isDefaultOwner && !feed.deviceOwner?.isOwnerVirtualPatient && (
						<Whiteboard
							deviceOwnerId={feed.deviceOwner?.id}
							deviceId={feed.deviceId}
							patientName={feed.deviceOwner?.fullName || ''}
							patientAge={feed.deviceOwner?.age || ''}
						/>
					)}
					{!isFromAlertCenter && (
						<>
							{patientNotes[feed.deviceId]?.patientInfo && !isSimplifiedPatientForm && feed.deviceId && (
								<PatientNotes
									deviceId={feed.deviceId}
									roomId={feed.roomId}
									hierarchyNaming={hierarchyNaming}
									deviceOwnerId={feed.deviceOwner?.id}
									aiSettings={aiSettings}
									toggleFallPrevention={toggleFallPrevention}
									onAiSettingClick={onAiSettingClick}
									isDefaultOwner={isDefaultOwner}
									isDarkMode={isDarkMode}
								/>
							)}
							{isSimplifiedPatientForm && (
								<PatientNotesSimplified deviceId={feed.deviceId} roomId={feed.roomId} hierarchyNaming={hierarchyNaming} />
							)}
							{getConfigurationValue(adminConfigurations[MonitoringSettings.CareEventsForSafetyCompanions]) &&
								!isDefaultOwner && (
									<InterventionsMonitoring
										isDarkMode={isDarkMode}
										patientId={patientId}
										deviceId={feed.deviceId}
										careEvents={careEvents}
										feed={feed}
										numberOfFeeds={numberOfFeeds}
										setSnoozeType={setSnoozeType}
										stopVoiceOver={stopVoiceOver}
										showForwardToNurses={showForwardToNurses}
										onForwardToNurses={(alertId, isAiAlert) => sendAutomaticAlert(alertId, isAiAlert)}
										conferenceId={conferenceInfo?.conferenceId}
									/>
								)}

							{feed.feedType === FeedTypes.Monitoring && !isDefaultOwner && (
								<>
									<PrivacyMode
										toggleOffPrivacy={toggleOffPrivacy}
										feed={feed}
										patientId={patientId}
										togglePrivacyMode={togglePrivacyMode}
									/>
									{getConfigurationValue(adminConfigurations[MonitoringSettings.VerbalRedirection]) && (
										<VerbalRedirections
											feed={feed}
											conferenceInfo={conferenceInfo}
											verbalRedirection={verbalRedirection}
											patientId={patientId}
										/>
									)}
									{getConfigurationValue(adminConfigurations[MonitoringSettings.PatientOutOfRoom]) && (
										<PatientOutOfRoom
											feed={feed}
											patientId={patientId}
											patientOutOfRoomCareEvent={patientOutOfRoomCareEvent}
											patientReturnedCareEvent={patientReturnedCareEvent}
											conferenceId={conferenceInfo?.conferenceId}
										/>
									)}
								</>
							)}
						</>
					)}
					{patientNotes[feed.deviceId]?.nightVision && feed.feedType === FeedTypes.Monitoring && (
						<VideoFeedActions feed={feed} toggleNightVision={toggleNightVision} />
					)}
					{isAnyAiSettingEnabled() && !isDefaultOwner && (
						<AiSettings
							feed={feed}
							patientId={patientId}
							isDarkMode={isDarkMode}
							onAiSettingClick={onAiSettingClick}
							isNoteShowing={isNoteShowing}
							toggleFallPrevention={toggleFallPrevention}
							stopVoiceOver={stopVoiceOver}
							onAlertInfoClose={onAlertInfoClose}
							adminAiSettingsConfigurations={adminAiSettingsConfigurations}
						/>
					)}

					{shouldShowTelemetryTab() && (
						<TelemetryItems
							deviceId={feed.deviceId}
							conferenceId={conferenceInfo?.conferenceId}
							participantId={feed.participantId}
							patientUserId={patientUserId}
							isBioBeatActive={isBioBeatActive}
							toggleBioBeat={toggleBioBeat}
							isLifeSignalsActive={isLifeSignalsActive}
							toggleLifeSignals={toggleLifeSignals}
							healthDataConfigurableMonitoring={{
								[MonitoringSettings.SmartWatchVitalSigns]: getConfigurationValue(
									adminConfigurations[MonitoringSettings.SmartWatchVitalSigns]
								),
								[MonitoringSettings.BiobeatVitalSigns]: getConfigurationValue(
									adminConfigurations[MonitoringSettings.BiobeatVitalSigns]
								),
								[MonitoringSettings.LifeSignals]: getConfigurationValue(adminConfigurations[MonitoringSettings.LifeSignals]),
							}}
							lifeSignalsWearable={lifeSignalsWearable}
							biobeatWearable={biobeatWearable}
							isDarkMode={isDarkMode}
						/>
					)}
				</>
			)}

			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</div>
	);
};

export default MonitoringTimeline;
