import React, { useState } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { SectorTypes } from 'constants/enums.js';
import CheckBox from 'components/Common/FormElements/CheckBox';

const OptionName = ({ option }) => {
	return (
		<>
			{option.name}
			{option.helloDeviceId && (
				<i className={classNames('material-icons-outlined', 'room-status', option.status)}>fiber_manual_record</i>
			)}
		</>
	);
};

const OptionsList = ({
	options,
	expandedOptions,
	onChange,
	selectedSectorId,
	setSelectedId,
	handleCheckBoxChange,
}) => {
	const findProperId = item => item[`${item.type}Id`];

	const toggleExpand = (selectedOptionId, isAddingSection) => {
		const opt = _.cloneDeep(expandedOptions);
		if (opt[selectedOptionId] && !isAddingSection) {
			delete opt[selectedOptionId];
		} else {
			opt[selectedOptionId] = {};
		}
		onChange(opt);
	};

	const handleSubOptionsListChange = (optionId, subSelections) => {
		const opt = _.cloneDeep(expandedOptions);
		opt[optionId] = subSelections;
		onChange(opt);
	};

	const getCheckBoxId = option => {
		switch (option.type) {
			case SectorTypes.HOSPITAL:
				return option.hospitalId;
			case SectorTypes.DEPARTMENT:
				return option.departmentId;
			case SectorTypes.FLOOR:
				return option.floorId;
			case SectorTypes.ROOM:
				return option.helloDeviceId;
			default:
				return null;
		}
	};

	return (
		<div className='voice-command-calling-devices-selection'>
			{options.map(option => (
				<div key={option.id} className={classNames('tree', !option?.name && !option?.isNewOption ? 'hidden' : '')}>
					<div className={classNames('tree__child', option[`${option?.type}Id`] === selectedSectorId ? 'selected' : '')}>
						<div>
							<i
								className={classNames('material-icons-outlined', option.type === SectorTypes.ROOM ? 'hide' : '')}
								onClick={() => {
									toggleExpand(findProperId(option));
								}}>
								{expandedOptions[findProperId(option)] ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
							</i>
							<i className='material-icons-outlined'>{option.icon}</i>
							<span className='link'>
								<OptionName option={option} />
							</span>
							<CheckBox
								id={getCheckBoxId(option)}
								checked={option.hasVisitingHours}
								onChange={e => handleCheckBoxChange(option, e)}
							/>
						</div>
					</div>
					{option.subOptions && expandedOptions[findProperId(option)] && (
						<OptionsList
							options={option.subOptions}
							expandedOptions={expandedOptions ? expandedOptions[findProperId(option)] : {}}
							onChange={subSelections => handleSubOptionsListChange(findProperId(option), subSelections)}
							selectedSectorId={selectedSectorId}
							setSelectedId={setSelectedId}
							handleCheckBoxChange={handleCheckBoxChange}
						/>
					)}
				</div>
			))}
		</div>
	);
};

const VisitingHoursTree = props => {
	const [selectedSectorId, setSelectedSectorId] = useState(null);
	const [expandedOptions, setExpandedOptions] = useState(null);

	const getExpandedOptions = () => {
		if (expandedOptions) return expandedOptions;
		if (props.preSelected) return props.preSelected;
		return {};
	};

	return (
		<OptionsList
			options={props.data}
			onChange={setExpandedOptions}
			expandedOptions={getExpandedOptions()}
			selectedSectorId={selectedSectorId || props.selectedSectorId}
			setSelectedId={setSelectedSectorId}
			handleCheckBoxChange={props.handleCheckBoxChange}
		/>
	);
};

export default VisitingHoursTree;
