import { gatewayApi } from 'constants/global-variables.js';

export const getInterventions = async ({ patientId, deviceId, isDraft, pageIndex, pageSize }) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/alerts/patients/${patientId}/devices/${deviceId}/interventions?isDraft=${isDraft}&pageIndex=${pageIndex}&pageSize=${pageSize}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const updateIntervention = async (patientId, deviceId, dataToSend) => {
	try {
		const { data } = await gatewayApi.put(`/v1/alerts/patients/${patientId}/devices/${deviceId}/interventions`, dataToSend);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getInterventionsCount = async (patientId, deviceId) => {
	try {
		const { data } = await gatewayApi.get(`v1/alerts/patients/${patientId}/devices/${deviceId}/interventions/count?isDraft=true`);
		return data;
	} catch (error) {
		return { error };
	}
};
