import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { getMeasurements } from 'api/measurements.js';
import Loader from 'components/Loader.jsx';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';
import { convertMeasurementTypes } from 'infrastructure/helpers/measurementsHelper.js';
import { getUserRole } from 'infrastructure/auth.js';
import { UserRoles } from 'constants/enums.js';
import translate from 'i18n-translations/translate.jsx';
import Alert from 'components/Alert.jsx';

const MeasurementHistory = props => {
	const measurementRecentElement = useRef(null);
	const [measurements, setMeasurements] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [pageIndex, setPageIndex] = useState(0);
	const [reachedEnd, setReachedEnd] = useState(false);
	const [error, setError] = useState(false);

	useEffect(() => {
		const fetchMeasurements = async () => {
			setIsLoading(true);
			const params = {
				patientId: props.patientId,
				measurementType: props.selectedMeasurementType.type,
				startDate: moment(new Date(1970)).format('MM/DD/YYYY'),
				endDate: moment(new Date()).format('MM/DD/YYYY'),
				pageIndex: pageIndex,
				pageSize: 20,
			};
			const response = await getMeasurements(params);
			if (!response.error) {
				setReachedEnd(response.measurements.length < 20);
				setMeasurements(prevState =>
					prevState.concat(response.measurements).sort((a, b) => (+new Date(b.startDate) > +new Date(a.startDate) ? 1 : -1))
				);
				setIsLoading(false);
			} else {
				setError(response.error.message);
				setIsLoading(false);
			}
		};
		fetchMeasurements();
	}, [props.patientId, props.selectedMeasurementType.type, pageIndex]);

	const getMeasurementUnit = measurement => (props.unitCategoryId ? props.unitPreference?.unit : measurement.measurementUnit);

	const getMeasurementValue = measurement => {
		return !props.unitCategoryId
			? measurement.measurementValue
			: convertMeasurementTypes(props.unitCategoryId, measurement.measurementValue, props.unitPreference?.unitSystemId);
	};

	const handleScroll = e => {
		if (
			Math.round(measurementRecentElement.current.scrollHeight - e.target.scrollTop) ===
			measurementRecentElement.current.clientHeight
		) {
			setPageIndex(prevState => prevState + 1);
		}
	};

	const openHealthData = () => {
		const userRole = getUserRole();
		let link = '';
		if (userRole === UserRoles.PATIENT) {
			link = `health-data/${props.selectedMeasurementType.type}`;
		} else {
			link = `/patients/${props.patientId}/health-data/${props.selectedMeasurementType.type}`;
		}
		window.open(link, '_blank');
	};

	return (
		<>
			<div className='measurement-history'>
				<div className='measurement-history-buttons'>
					<div className='back-to-overview' onClick={() => props.setSelectedMeasurementType(null)}>
						<i className='material-icons'>keyboard_arrow_left</i>
						<p>{translate('overview')}</p>
					</div>
					<div>
						<button type='button' className='button small health-data-tab' onClick={openHealthData}>
							{translate('seeMoreData')}
						</button>
					</div>
				</div>
				<div className='measurement-recent-data' onScroll={handleScroll} ref={measurementRecentElement}>
					<div>
						<p>{translate('historicalData')}</p>
					</div>
					<div className='measurement-latest-data-wrapper'>
						{measurements.map(measurement => (
							<div className='measurement-latest-data'>
								<div className='flex'>
									<img alt='icon' src={props.selectedMeasurementType.title.icon} />
									<p>
										{getMeasurementValue(measurement)} {getMeasurementUnit(measurement)}
									</p>
									<span>{formattedDate(measurement.startDate)}</span>
								</div>
							</div>
						))}
						{!reachedEnd && isLoading && (
							<div style={{ textAlign: 'center' }}>
								<Loader />
							</div>
						)}
						{!isLoading && measurements.length === 0 && (
							<div className='measurement-latest-data'>
								<div className='flex'>
									<p>{translate('noData')}</p>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</>
	);
};

export default MeasurementHistory;
