import React, { useState, useRef, useEffect } from 'react';
import SocketEvents from 'constants/socket-events.js';
import classNames from 'classnames';
import CameraTypes from 'calls/enums/CameraTypes.js';

const DragToMove = props => {
	const selectedCamera = props.selectedCamera;
	const [startX, setStartX] = useState(0);
	const [startY, setStartY] = useState(0);
	const [endX, setEndX] = useState(0);
	const [endY, setEndY] = useState(0);
	const [lastLoggedX, setLastLoggedX] = useState(0);
	const [lastLoggedY, setLastLoggedY] = useState(0);

	const wrapperRef = useRef(null);
	const isDragging = useRef(false);

	const currentZoom = useRef(selectedCamera?.capabilities?.zoom?.current);

	const handleMouseDown = async e => {
		if (!selectedCamera?.id) return;
		e.preventDefault();
		isDragging.current = true;
		const response = await props.context.emitWithAck(SocketEvents.Conference.ON_MEDIA_CONTROLS, {
			actioneeParticipantId: props.feed.participantId,
			conferenceId: props.conferenceInfo.conferenceId,
			participantId: props.conferenceInfo.participantId,
			data: JSON.stringify({ x: 0, y: 0, cameraId: selectedCamera?.id }),
			command: 'consoleMove',
			type: 'camera',
		});
		if (response.deviceControlsLocked) {
			props.showDeviceControlsLockedModal();
			return;
		}
		props.setIsDragging(true);
		if (!wrapperRef?.current) {
			return;
		}
		setStartX(e.clientX - wrapperRef.current.getBoundingClientRect().left);
		setStartY(e.clientY - wrapperRef.current.getBoundingClientRect().top);
		setEndX(e.clientX - wrapperRef.current.getBoundingClientRect().left);
		setEndY(e.clientY - wrapperRef.current.getBoundingClientRect().top);
	};

	const handleMouseMove = e => {
		if (!selectedCamera?.id) return;
		if (!props.isDragging || !isDragging.current) return;
		if (!wrapperRef?.current) return;

		const newEndX = e.clientX - wrapperRef.current.getBoundingClientRect().left;
		const newEndY = e.clientY - wrapperRef.current.getBoundingClientRect().top;

		const wrapperWidth = wrapperRef.current.clientWidth;
		const wrapperHeight = wrapperRef.current.clientHeight;

		const diffXFromStart = newEndX - startX;
		const diffYFromStart = startY - newEndY;

		const horizontalPercentageFromStart = (diffXFromStart / wrapperWidth) * 100;
		const verticalPercentageFromStart = (diffYFromStart / wrapperHeight) * 100;

		if (
			Math.abs(newEndX - lastLoggedX) >= (wrapperWidth * 5) / 100 ||
			Math.abs(newEndY - lastLoggedY) >= (wrapperHeight * 5) / 100
		) {
			props.context.emit(SocketEvents.Conference.ON_MEDIA_CONTROLS, {
				actioneeParticipantId: props.feed.participantId,
				conferenceId: props.conferenceInfo.conferenceId,
				participantId: props.conferenceInfo.participantId,
				data: JSON.stringify({
					x: horizontalPercentageFromStart.toFixed(0),
					y: verticalPercentageFromStart.toFixed(0),
					cameraId: selectedCamera?.id,
				}),
				command: 'consoleMove',
				type: 'camera',
			});
			setLastLoggedX(newEndX);
			setLastLoggedY(newEndY);
		}
		setEndX(newEndX);
		setEndY(newEndY);
	};

	const handleMouseUp = async () => {
		if (!selectedCamera?.id) {
			return;
		}
		const isDraggingLine = isDragging.current;
		isDragging.current = false;
		props.setIsDragging(false);
		if (isDraggingLine) {
			props.context.emit(SocketEvents.Conference.ON_MEDIA_CONTROLS, {
				actioneeParticipantId: props.feed.participantId,
				conferenceId: props.conferenceInfo.conferenceId,
				participantId: props.conferenceInfo.participantId,
				data: JSON.stringify({ x: 0, y: 0, cameraId: selectedCamera?.id }),
				command: 'consoleMove',
				type: 'camera',
			});
		}
	};

	const svgLineStyle = {
		stroke: 'var(--blue-2)',
		strokeWidth: 3,
	};

	const svgWidth = Math.abs(endX - startX);
	const svgHeight = Math.abs(endY - startY);

	const svgStyle = {
		position: 'absolute',
		left: Math.min(startX, endX),
		top: Math.min(startY, endY),
		width: svgWidth,
		height: svgHeight,
	};

	let accumulatedDelta = 0;
	let zoomTimer;

	const handleWheel = event => {
		const maxZoom = selectedCamera?.capabilities?.zoom?.levels;
		const deltaY = event.deltaY;

		if (deltaY < 0) {
			accumulatedDelta += 1;
		} else {
			accumulatedDelta -= 1;
		}

		clearTimeout(zoomTimer);

		zoomTimer = setTimeout(() => {
			currentZoom.current = Math.min(Math.max(currentZoom.current + accumulatedDelta, 0), maxZoom);

			props.sendCameraEvent(SocketEvents.Conference.ON_MEDIA_CONTROLS, {
				command: 'zoom',
				type: 'camera',
				data: JSON.stringify({
					cameraId: selectedCamera?.id,
					level: currentZoom.current,
				}),
				participantId: props.conferenceInfo.participantId,
				conferenceId: props.conferenceInfo.conferenceId,
				actioneeParticipantId: props.feed.participantId,
			});

			accumulatedDelta = 0;
		}, 200);
	};

	const handleWheelHello = event => {
		const maxZoom = selectedCamera?.capabilities?.zoom?.levels;
		const deltaY = event.deltaY;

		if (deltaY < 0) {
			accumulatedDelta += 1;
		} else {
			accumulatedDelta -= 1;
		}

		currentZoom.current = Math.min(Math.max(currentZoom.current + accumulatedDelta, 0), maxZoom);

		props.sendCameraEvent(SocketEvents.Conference.ON_MEDIA_CONTROLS, {
			command: 'zoom',
			type: 'camera',
			data: JSON.stringify({
				cameraId: selectedCamera?.id,
				level: currentZoom.current,
			}),
			participantId: props.conferenceInfo.participantId,
			conferenceId: props.conferenceInfo.conferenceId,
			actioneeParticipantId: props.feed.participantId,
		});

		accumulatedDelta = 0;
	};

	useEffect(() => {
		return () => {
			clearTimeout(zoomTimer);
		};
	});

	return (
		<div
			ref={wrapperRef}
			onClick={props.allowDrag}
			onMouseDown={handleMouseDown}
			onMouseMove={handleMouseMove}
			onMouseUp={handleMouseUp}
			onWheel={selectedCamera?.type === CameraTypes.HUDDLE ? handleWheel : handleWheelHello}
			className={classNames('drag-layer', props.isFitToScreen ? 'drag-fit' : '', props.hasAlert ? 'z-index-1' : '')}>
			{props.isDragging && (
				<svg style={svgStyle}>
					<line
						x1={startX < endX ? 0 : svgWidth}
						y1={startY < endY ? 0 : svgHeight}
						x2={startX < endX ? svgWidth : 0}
						y2={startY < endY ? svgHeight : 0}
						style={svgLineStyle}
					/>
				</svg>
			)}
		</div>
	);
};

export default DragToMove;
