import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import translate from 'i18n-translations/translate.jsx';
import {
	AiAlertActivityType,
	AiAlertTypes,
	MeasurementActivityTypes,
	MeasurementTypes,
	WarningIndexes,
	ManualAlertTypes,
	ManualAlertActivityTypes,
} from 'constants/enums.js';
import { formattedDate } from 'infrastructure/helpers/dateHelper.js';
import { stringToCamelCase } from 'infrastructure/helpers/commonHelpers.js';
import { EmptyState } from 'components';
import BedRails from 'icons/Monitoring/BedRails';
import PatientPosition from 'icons/Monitoring/PatientPosition';
import HandWashing from 'icons/Monitoring/HandWashing';
import Conversation from 'icons/Monitoring/Conversation.jsx';
import FallDetection from 'icons/Monitoring/FallDetection';
import Doctor from 'icons/Monitoring/Doctor';
import PatientGettingOutOfChairIcon from 'icons/Monitoring/PatientGettingOutOfChairIcon';
import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';
import HeartIcon from 'icons/HealthData/HeartIcon';
import RespiratoryIcon from 'icons/HealthData/RespiratoryIcon';
import OtherDataIcon from 'icons/HealthData/OtherDataIcon';
import SleepIcon from 'icons/HealthData/SleepIcon';
import GlucoseIcon from 'icons/HealthData/GlucoseIcon';
import BodyMeasurementsIcon from 'icons/HealthData/BodyMeasurementsIcon';
import classNames from 'classnames';
import IvBag from 'icons/Monitoring/IvBag.js';

const AlertHistoryModal = props => {
	const intl = useIntl();
	const user = useSelector(state => state.user);
	const [visibleNotes, setVisibleNotes] = useState([]);

	const getImageUrl = item => {
		if (item.aiAlertActivityType) {
			if (item.properties && JSON.parse(item.properties)?.Id) {
				return getAiAlertImage(JSON.parse(item.properties)?.Name);
			}
			switch (item.aiAlertActivityType.id) {
				case AiAlertActivityType.AI_ALERT_SENT:
				case AiAlertActivityType.AI_ALERT_RECEIVED:
				case AiAlertActivityType.ADDED_AS_HOSPITAL_CARETAKER:
				case AiAlertActivityType.ACKNOWLEDGED:
				case AiAlertActivityType.AI_FAKE_ALERT:
				case AiAlertActivityType.AI_FORWARD_TO_NURSES:
					return <Doctor color={user.darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />;
				case AiAlertActivityType.COMMENTED:
					return <Conversation color={user.darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />;
				default:
					return <Doctor color={user.darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />;
			}
		}
		if (item.measurementAlertActivityType) {
			if (item.properties && JSON.parse(item.properties)) {
				return getMeasurementAlertImage(JSON.parse(item.properties)?.Type);
			}
			switch (item.measurementAlertActivityType.id) {
				case MeasurementActivityTypes.MEASUREMENT_ALERT_SENT:
				case MeasurementActivityTypes.MEASUREMENT_ALERT_RECEIVED:
				case MeasurementActivityTypes.ADDED_AS_HOSPITAL_CARETAKER:
				case MeasurementActivityTypes.ACKNOWLEDGED:
				case MeasurementActivityTypes.MEASUREMENT_FAKE_ALERT:
				case MeasurementActivityTypes.MEASUREMENT_FORWARD_TO_NURSES:
					return <Doctor color={user.darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />;
				case MeasurementActivityTypes.COMMENTED:
					return <Conversation color={user.darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />;
				default:
					return <Doctor color={user.darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />;
			}
		}
		if (item.manualAlertActivityType) {
			if (item.properties && JSON.parse(item.properties)?.Id) {
				return getManualAlertImage(JSON.parse(item.properties)?.Type);
			}
			switch (item.manualAlertActivityType.id) {
				case AiAlertActivityType.AI_ALERT_SENT:
				case AiAlertActivityType.AI_ALERT_RECEIVED:
				case AiAlertActivityType.ADDED_AS_HOSPITAL_CARETAKER:
				case AiAlertActivityType.ACKNOWLEDGED:
				case AiAlertActivityType.AI_FAKE_ALERT:
				case AiAlertActivityType.AI_FORWARD_TO_NURSES:
					return <Doctor color={user.darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />;
				case AiAlertActivityType.COMMENTED:
					return <Conversation color={user.darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />;
				default:
					return <Doctor color={user.darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />;
			}
		}
		return null;
	};

	const getAiAlertImage = alertType => {
		switch (alertType) {
			case AiAlertTypes.RAIL_DOWN:
				return <BedRails color={user.darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />;
			case AiAlertTypes.PERSON_SITTING:
			case AiAlertTypes.PERSON_STANDING:
			case AiAlertTypes.PERSON_FACE_DOWN:
			case AiAlertTypes.PERSON_LAYING_SIDE:
			case AiAlertTypes.PERSON_LAYING_FRONT:
			case AiAlertTypes.BABY_FACE_DOWN:
				return <PatientPosition color={user.darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />;
			case AiAlertTypes.FALL_DETECTION:
				return <FallDetection color={user.darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />;
			case AiAlertTypes.HANDS_DISINFECTED:
				return <HandWashing color={user.darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />;
			case AiAlertTypes.IV_BAG:
			case AiAlertTypes.LOW_IV_BAG:
				return <IvBag color={user.darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />;
			case AiAlertTypes.PERSON_GETTING_OUT_OF_CHAIR:
				return <PatientGettingOutOfChairIcon color={user.darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />;
			default:
				return <PatientPosition color={user.darkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />;
		}
	};

	const getMeasurementAlertImage = alertType => {
		switch (alertType) {
			case MeasurementTypes.BLOOD_PRESSURE:
			case MeasurementTypes.HEART_RATE:
				return <HeartIcon />;
			case MeasurementTypes.OXYGEN:
				return <RespiratoryIcon />;
			case MeasurementTypes.DIABETES:
				return <OtherDataIcon />;
			case MeasurementTypes.TEMPERATURE:
			case MeasurementTypes.WEIGHT:
				return <BodyMeasurementsIcon />;
			case MeasurementTypes.SLEEP:
				return <SleepIcon />;
			case MeasurementTypes.BLOOD_GLUCOSE:
				return <GlucoseIcon />;
			default:
				return <HeartIcon />;
		}
	};

	const getManualAlertImage = alertType => {
		switch (alertType) {
			case ManualAlertTypes.FALL_DETECTED:
				return <RespiratoryIcon />;
			case ManualAlertTypes.CUSTOM:
				return <OtherDataIcon />;
			case ManualAlertTypes.RISK_OF_FALL:
				return <BodyMeasurementsIcon />;
			case ManualAlertTypes.PATIENT_NEEDS_HELP:
				return <SleepIcon />;
			case ManualAlertTypes.PATIENT_REQUESTED_HELP:
				return <GlucoseIcon />;
			default:
				return <HeartIcon />;
		}
	};

	const getDescription = item => {
		if (item.aiAlertActivityType) {
			switch (item.aiAlertActivityType.id) {
				case AiAlertActivityType.AI_ALERT_SENT:
					return translate('isAtRisk');
				case AiAlertActivityType.AI_ALERT_RECEIVED:
					return translate('receivedAlert');
				case AiAlertActivityType.ADDED_AS_HOSPITAL_CARETAKER:
					return (
						<>
							{translate('wasAddedAs')}
							<span className='hello-device'>{translate('hospitalCareTaker')}</span>
						</>
					);
				case AiAlertActivityType.COMMENTED:
					return translate('addedComment');
				case AiAlertActivityType.ACKNOWLEDGED:
					return (
						<>
							{translate('markedThe')}
							<span className='completed-alert'>{translate('alertAcknowledged')}</span>
						</>
					);
				case AiAlertActivityType.AI_FAKE_ALERT:
					return (
						<>
							{translate('markedThe')}
							<span className='false-alert'>{translate('alertAsFalse')}</span>
						</>
					);
				case AiAlertActivityType.AI_FORWARD_TO_NURSES:
					return (
						<>
							{translate('has')}
							<span className='hello-device'>{translate('forwardedToNurses')}</span>
						</>
					);
				default:
					return '';
			}
		}

		if (item.measurementAlertActivityType) {
			switch (item.measurementAlertActivityType.id) {
				case MeasurementActivityTypes.MEASUREMENT_ALERT_SENT:
					return translate('measured');
				case MeasurementActivityTypes.MEASUREMENT_ALERT_RECEIVED:
					return translate('receivedAlert');
				case MeasurementActivityTypes.ADDED_AS_HOSPITAL_CARETAKER:
					return (
						<>
							{translate('wasAddedAs')}
							<span className='hello-device'>{translate('hospitalCareTaker')}</span>
						</>
					);
				case MeasurementActivityTypes.COMMENTED:
					return translate('addedComment');
				case MeasurementActivityTypes.ACKNOWLEDGED:
					return (
						<>
							{translate('markedThe')}
							<span className='completed-alert'>{translate('alertAcknowledged')}</span>
						</>
					);
				case MeasurementActivityTypes.MEASUREMENT_FAKE_ALERT:
					return (
						<>
							{translate('markedThe')}
							<span className='false-alert'>{translate('alertAsFalse')}</span>
						</>
					);
				case MeasurementActivityTypes.MEASUREMENT_FORWARD_TO_NURSES:
					return (
						<>
							{translate('has')}
							<span className='hello-device'>{translate('forwardedToNurses')}</span>
						</>
					);
				default:
					return '';
			}
		}

		if (item.manualAlertActivityType) {
			switch (item.manualAlertActivityType.id) {
				case ManualAlertActivityTypes.MANUAL_ALERT_SENT:
					return translate('raisedAnAlert');
				case ManualAlertActivityTypes.MANUAL_ALERT_RECEIVED:
					return translate('receivedAlert');
				case ManualAlertActivityTypes.ADDED_AS_AN_HOSPITAL_CARETAKER:
					return (
						<>
							{translate('wasAddedAs')}
							<span className='hello-device'>{translate('hospitalCareTaker')}</span>
						</>
					);
				case ManualAlertActivityTypes.COMMENTED:
					return translate('addedComment');
				case ManualAlertActivityTypes.ACKNOWLEDGED:
					return (
						<>
							{translate('markedThe')}
							<span className='completed-alert'>{translate('alertAcknowledged')}</span>
						</>
					);
				case ManualAlertActivityTypes.MANUAL_FAKE_ALERT:
					return (
						<>
							{translate('markedThe')}
							<span className='false-alert'>{translate('alertAsFalse')}</span>
						</>
					);
				case ManualAlertActivityTypes.MANUAL_FORWARD_TO_NURSES:
					return (
						<>
							{translate('has')}
							<span className='hello-device'>{translate('forwardedToNurses')}</span>
						</>
					);
				case ManualAlertActivityTypes.SAVED_ACTIVITY:
					return (
						<>
							{translate('has')}
							<span className='hello-device'>{translate('savedCareEvent')}</span>
						</>
					);
				case ManualAlertActivityTypes.STOPPED_STAT_ALARM:
					return (
						<>
							{translate('has')}
							<span className='hello-device'>{translate('hasStoppedStatAlarm')}</span>
						</>
					);
				default:
					return '';
			}
		}
		return null;
	};

	const getProperties = item => {
		if (!item.properties) {
			return null;
		}
		return JSON.parse(item.properties);
	};

	const getComment = item => {
		if (!item) {
			return '';
		}
		const result = item.Comment || (item.Name && intl.formatMessage({ id: item.Name }));
		if (!result) {
			return '';
		}
		return result;
	};

	const activitiesWithIds = props.alertDetails.map((item, index) => ({
		...item,
		id: index + 1,
	}));

	return (
		<>
			{activitiesWithIds?.length === 0 && (
				<div className='flex flex-align-center flex-justify-center empty-state-wrapper'>
					<EmptyState title={translate('noResultsFound')} image='alerts.svg?v2' />
				</div>
			)}
			<div className='full-width'>
				{activitiesWithIds?.length > 0 &&
					activitiesWithIds?.map(item => {
						const properties = getProperties(item);
						return (
							<div
								className={`view-request-history-item alert-history flex full-width flex-space-between${
									activitiesWithIds?.length === 1 ? ' one-alert-history' : ''
								}`}>
								<div className='flex'>
									<div className='alert-history-modal-icon-wrapper'>{getImageUrl(item)}</div>
									<p className={classNames('alert-history-desc', props.isFromMonitoring ? ' break-word' : '')}>
										<span>
											{item?.causer?.firstName} {item?.causer?.lastName}
										</span>
										{getDescription(item)} {properties?.Name && !properties?.Comment && getComment(properties)}
										{properties?.Comment && (
											<>
												<div
													className='flex cursor-pointer top-15 flex-align-center'
													onClick={() => {
														setVisibleNotes(prevState =>
															prevState.includes(item.id) ? prevState.filter(id => id !== item.id) : [...prevState, item.id]
														);
													}}>
													<span className='no-padding'>{translate('additionalNotes')}</span>
													<i className='material-icons-outlined font-16'>
														{visibleNotes.find(el => el === item.id) ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}
													</i>
												</div>
												{visibleNotes.find(el => el === item.id) && <p>{getComment(properties)}</p>}
											</>
										)}
										{item.measurementAlertActivityType && props.isMeasurementAlert && properties && (
											<span
												className={`${
													[WarningIndexes.LOW, WarningIndexes.HIGH].includes(properties?.WarningIndex)
														? 'moderate-risk'
														: 'high-risk'
												}`}>
												{properties?.Type &&
													`${intl.formatMessage({ id: stringToCamelCase(properties?.Type) })} 	
									(${props.isMeasurementAlert && properties && `${properties?.Value} ${properties.Unit}`})`}
											</span>
										)}
									</p>
									<p className='alert-time no-padding align-self-end'>
										{!props.isFromMonitoring && <img src={`${healthCareCdnUrl}vsm/Time.svg`} alt='ico' />}
										{formattedDate(item.createdAt)}
									</p>
								</div>
							</div>
						);
					})}
			</div>
		</>
	);
};

export default AlertHistoryModal;
