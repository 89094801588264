import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { createVisit } from 'api/medicalInfo.js';
import translate from 'i18n-translations/translate.jsx';
import Loader from 'components/Loader.jsx';
import Grid from 'components/Grid.jsx';
import { Alert, EmptyState } from 'components';
import PTPrescriptionReport from 'containers/PhysicalTherapies/PTPrescriptionReport';
import { getUserInfo, getUserRole } from 'infrastructure/auth.js';
import Pagination from 'components/Common/Pagination.jsx';
import { monthDayYear } from 'infrastructure/helpers/dateHelper.js';
import EditPTReport from 'containers/PhysicalTherapies/EditPTReport';
import AddPTExercise from 'containers/PhysicalTherapies/AddPTExercise';
import { HttpStatusCodes, UserRoles } from 'constants/enums.js';
import {
	addPhysicalTherapy,
	deletePhysicalTherapy,
	getGroupedExercisesByType,
	getPhysicalTherapies,
	submitPhysicalTherapies,
} from 'api/physicalExercises';
import classNames from 'classnames';

const PhysicalTherapyReports = props => {
	const [reports, setReports] = useState([]);
	const [ptPrescriptionReport, setPtPrescriptionReport] = useState([]);
	const [error, setError] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [exerciseToEdit, setExerciseToEdit] = useState({});
	const [selectedView, setSelectedView] = useState(1);
	const [pagination, setPagination] = useState({ pageSize: 10, pageIndex: 0, totalCount: 0 });
	const [isAdded, setIsAdded] = useState(false);
	const [physicalTherapyId, setPhysicalTherapyId] = useState('');
	const [isBackClicked, setIsBackClicked] = useState(false);
	const [selectedPhysicalTherapyId, setSelectedPhysicalTherapyId] = useState(null);
	const [addedTherapy, setAddedTherapy] = useState(null);
	const intl = useIntl();

	const viewToShow = {
		REPORTS: 1,
		PT_PRESCRIPTION: 2,
		EDIT_EXERCISE: 3,
		ADD_EXERCISE: 4,
	};
	const userInfo = getUserInfo();

	useEffect(() => {
		const getItems = async (pageSize, pageIndex) => {
			const params = {
				patientId: props.patientId || getUserInfo().userId,
				pageIndex,
				pageSize,
			};
			const response = await getPhysicalTherapies(params);
			if (response.error) {
				setError(response.error.message);
			} else {
				setReports(response.items);
				const newExerciseAdded = response.items.filter(item => item.id === addedTherapy);
				if (newExerciseAdded.length > 0) {
					setPtPrescriptionReport(newExerciseAdded[0].exercises);
					setAddedTherapy(null);
				}
				setPagination({ pageSize, pageIndex, totalCount: response.totalCount });
			}
			setIsLoading(false);
		};
		getItems(pagination.pageSize, pagination.pageIndex);
	}, [props.patientId, props.patientFirstName, isAdded, pagination.pageSize, pagination.pageIndex, isBackClicked, addedTherapy]);

	const addPhysicalTherapyId = async () => {
		const params = { patientId: props.patientId, doctorId: getUserInfo().userId, place: props.patientFirstName };
		const visitRes = await createVisit(params);
		if (visitRes.error) {
			setError(visitRes.error.message);
			return;
		}
		const dataToSend = {
			conversationId: visitRes.id,
			patientId: props.patientId,
			doctorId: getUserInfo().userId,
			exercises: [],
		};
		const response = await addPhysicalTherapy(dataToSend);
		if (response.error) {
			setError(response.error.message);
		} else {
			setPtPrescriptionReport([]);
			setPhysicalTherapyId(response.item.id);
			submitPhysicalTherapy(response.item.id);
			setSelectedView(viewToShow.ADD_EXERCISE);
			if (response.item.exercises.length) {
				const groupedExercises = await getGroupedExercisesByType(response.item.id);
				if (groupedExercises.error) {
					setError(groupedExercises.error.message);
					setIsLoading(false);
					return;
				}
			}
		}
		setIsLoading(false);
	};

	const setPrescriptionReport = item => {
		setSelectedPhysicalTherapyId(item.id);
		setPtPrescriptionReport(item.exercises);
		setSelectedView(viewToShow.PT_PRESCRIPTION);
	};

	const setEditExerciseView = item => {
		if (!item) {
			setSelectedView(viewToShow.PT_PRESCRIPTION);
			return;
		}
		setExerciseToEdit(item);
		setSelectedView(viewToShow.EDIT_EXERCISE);
	};

	const onPageChange = async (pageSize, pageIndex) => {
		setIsLoading(true);
		setPagination(prevState => ({ ...prevState, pageSize, pageIndex }));
	};

	const submitPhysicalTherapy = async id => {
		setIsLoading(true);
		const submitRes = await submitPhysicalTherapies(id, 'Completed');
		if (submitRes.error) {
			const errorMessage =
				submitRes.error.response.status === HttpStatusCodes.CONFLICT
					? submitRes.error.response.data.message
					: submitRes.error.message;
			setError(errorMessage);
			setIsLoading(false);
			return;
		}
		setIsLoading(false);
	};

	const deletePhysicalTherapyId = async therapyId => {
		const response = await deletePhysicalTherapy(therapyId);
		if (response.error) {
			setError(response.error.message);
			return;
		}
		setIsAdded(prevState => !prevState);
	};

	return (
		<>
			{isLoading && (
				<Grid columns='1fr' rows='1fr' stretch='calc(100vh - 200px)' horizAlign='center' vertAlign='center'>
					<div className='text-align-center'>
						<Loader />
					</div>
				</Grid>
			)}
			{!isLoading && (
				<>
					{selectedView === viewToShow.REPORTS && (
						<div className={classNames('patient-summary-wrapper', !reports.length ? 'physical-therapies unset-height' : '')}>
							<div className='flex flex-space-between right-align-items'>
								<h3 className='physical-therapy-title'>{translate('physicalTherapies')}</h3>
								{getUserRole() === UserRoles.DOCTOR && selectedView === viewToShow.REPORTS && (
									<button type='button' className='add-pt-btn' onClick={addPhysicalTherapyId}>
										{translate('addPhysicalTherapy')}
									</button>
								)}
							</div>
							{reports.length > 0 && (
								<>
									<div className='patient-summary-table-wrapper'>
										<table cellPadding='0' cellSpacing='0'>
											<thead>
												<tr>
													<th>{translate('physicalTherapy')}</th>
													<th>{translate('physicalTherapist')}</th>
													<th>{translate('startingDate')}</th>
													<th>{translate('endDate')}</th>
													<th>{translate('targetedDays')}</th>
													<th>{translate('report')}</th>
												</tr>
											</thead>
											<tbody>
												{reports.map(
													item =>
														item.targetDays !== '0/0' && (
															<tr key={item.id}>
																<td>
																	<p className='--dark-gray-color bold margin-bottom-0 break-word'>
																		{translate('pTPrescriptionReport')}
																	</p>
																	{monthDayYear(item.callingDate)}
																</td>
																<td>{item.doctorName}</td>
																<td>{monthDayYear(item.startingDate)}</td>
																<td>{monthDayYear(item.endDate)}</td>
																<td>{item.targetDays}</td>
																<td>
																	<div>
																		<button className='generate-pdf' type='button' onClick={() => setPrescriptionReport(item)}>
																			{translate('viewDetails')}
																		</button>
																		{getUserRole() === UserRoles.DOCTOR && (
																			<button
																				className='generate-pdf'
																				type='button'
																				onClick={() => deletePhysicalTherapyId(item.id)}>
																				{translate('delete')}
																			</button>
																		)}
																	</div>
																</td>
															</tr>
														)
												)}
											</tbody>
										</table>
									</div>

									<Pagination
										totalCount={pagination.totalCount}
										pageSize={pagination.pageSize}
										pageIndex={pagination.pageIndex}
										onChange={(pageSize, pageIndex) => onPageChange(pageSize, pageIndex)}
									/>
								</>
							)}
						</div>
					)}
					{reports.length === 0 && selectedView === viewToShow.REPORTS && (
						<div className='empty-state-wrapper'>
							<EmptyState title={intl.formatMessage({ id: 'noPhysicalExercisesFound' })} image='Procedure.svg' />
						</div>
					)}
					{selectedView === viewToShow.PT_PRESCRIPTION && ptPrescriptionReport.length > 0 && (
						<PTPrescriptionReport
							ptPrescriptionReport={ptPrescriptionReport}
							setSelectedView={setSelectedView}
							setEditExerciseView={setEditExerciseView}
							viewToShow={viewToShow}
							selectedPhysicalTherapyId={selectedPhysicalTherapyId}
							setPtPrescriptionReport={setPtPrescriptionReport}
							setIsBackClicked={setIsBackClicked}
							setIsAdded={setIsAdded}
							isBackClicked={isBackClicked}
							isAdded={isAdded}
						/>
					)}
					{selectedView === viewToShow.EDIT_EXERCISE && (
						<EditPTReport
							setPtPrescriptionReport={setPtPrescriptionReport}
							exerciseToEdit={exerciseToEdit}
							setSelectedView={setSelectedView}
							viewToShow={viewToShow}
							patientId={props.patientId}
							selectedPhysicalTherapyId={selectedPhysicalTherapyId}
						/>
					)}
					{selectedView === viewToShow.ADD_EXERCISE && (
						<AddPTExercise
							setSelectedView={setSelectedView}
							viewToShow={viewToShow}
							patientId={props.patientId}
							doctorId={userInfo.userId}
							setIsAdded={setIsAdded}
							addedTherapy={addedTherapy}
							setAddedTherapy={setAddedTherapy}
							isAdded={isAdded}
							physicalTherapyId={physicalTherapyId}
							ptPrescriptionReport={ptPrescriptionReport}
						/>
					)}

					<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
				</>
			)}
		</>
	);
};

export default PhysicalTherapyReports;
