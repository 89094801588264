import React, { useState, useEffect } from 'react';
import { FieldArray, Formik } from 'formik';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import translate from 'i18n-translations/translate.jsx';
import { AnswerType, RpmTabsList, rpmMeasurements } from 'constants/rpm.js';
import Alert from 'components/Alert.jsx';
import Grid from 'components/Grid.jsx';
import { createRpmProgram, getRpmProgramDetails, updateRpmProgram } from 'api/rpm.js';
import { HttpStatusCodes, MemberType } from 'constants/enums.js';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from 'components/Tabs.jsx';
import { getSpecialties } from 'api/doctorOnBoarding.js';
import CreateSurvey from 'containers/Rpm/CreateSurvey.jsx';
import CareTeamMembers from 'containers/Rpm/CareTeamMembers.jsx';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import { healthCareCdnUrl } from 'constants/global-variables.js';

const AddEditRpmProgram = () => {
	const tabs = {
		RPM: 0,
		SURVEY: 1,
		CARE_TEAM: 2,
	};
	const [error, setError] = useState(null);
	const [currentTab, setCurrentTab] = useState(tabs.RPM);
	const [specialties, setSpecialties] = useState([]);
	const [selectedSurveyType, setSelectedSurveyType] = useState(null);
	const [selectedScheduleType, setSelectedScheduleType] = useState(null);
	const [selectedProgram, setSelectedProgram] = useState(null);
	const [isAddModalOpen, setIsAddModalOpen] = useState(false);
	const intl = useIntl();
	const location = useLocation();
	const translator = id => intl.formatMessage({ id });

	useEffect(() => {
		const getProgramDetails = async id => {
			setSelectedProgram(null);
			const response = await getRpmProgramDetails(id);
			if (response.error) {
				setError(response.error.message);
				return;
			}
			setSelectedProgram(response.rpmProgram);
		};
		if (location.state?.rpmProgramId) {
			getProgramDetails(location.state?.rpmProgramId);
		} else {
			setIsAddModalOpen(true);
		}
	}, [location]);

	const getInitialValues = () => ({
		rpmProgramName: selectedProgram?.name ?? '',
		questions: selectedProgram?.questions?.length > 0 ? selectedProgram?.questions : [],
		measurements: selectedProgram ? getTransformedArr() : [],
		doctors: selectedProgram?.careTeam ? getSelectedMembers(MemberType.DOCTOR) : [],
		nurses: selectedProgram?.careTeam ? getSelectedMembers(MemberType.NURSE) : [],
		specialties: selectedProgram?.careTeam ? getSelectedSpecialties() : [],
	});

	const getTransformedArr = () => {
		const transformedArr = [];
		selectedProgram.devices.forEach(item => {
			const foundMeasurement = transformedArr.find(measurement => measurement.measurementType === item.measurementType);
			if (foundMeasurement) {
				foundMeasurement.devices.push(item.deviceId);
			} else {
				transformedArr.push({
					isSelected: true,
					measurementType: item.measurementType,
					devices: [item.deviceId],
				});
			}
		});
		return transformedArr;
	};

	const getSelectedMembers = careMemberType =>
		selectedProgram.careTeam.reduce((result, item) => {
			if (item.careMemberType === careMemberType) {
				result.push({
					value: item.userId,
					label: `${item.firstName} ${item.lastName}`,
					profilePicture: item.profilePicture,
				});
			}
			return result;
		}, []);

	const getSelectedSpecialties = () => selectedProgram.careTeam.filter(item => item.careMemberType === MemberType.DOCTOR);

	const getValidationSchema = () => {
		return Yup.object().shape({
			rpmProgramName: Yup.string()
				.trim()
				.required(translator('pleaseWriteProgramName'))
				.max(100, `${translator('maxLengthIs')} 100`),
			measurements: Yup.array()
				.min(1, translator('pleaseSelectMeasurement'))
				.test('maximum-devices', translator('maximumDevices'), array => array.every(arr => arr.devices.length <= 2))
				.test('minimum-devices', translator('pleaseSelectDevice'), array => array.every(arr => arr.devices.length > 0)),
			questions: Yup.array()
				.test('options-array-validation', translator('atLeastTwoOptions'), array => array.every(arr => arr.options.length >= 2))
				.test('non-empty', translator('valuesCannotBeEmpty'), array =>
					array.every(item => item.text && item.options.every(el => el))
				),
			doctors: Yup.array()
				.min(1, translator('atLeastOneMember'))
				.test('non-empty-values', translator('valuesCannotBeEmpty'), array => array.every(item => item?.value)),
			nurses: Yup.array().test('non-empty-values', translator('valuesCannotBeEmpty'), array => array.every(item => item?.value)),
		});
	};

	const getDevices = values =>
		values.measurements.reduce((selectedDevices, measurement) => {
			if (measurement?.isSelected) {
				const foundMeasurement = rpmMeasurements.find(item => item.type === measurement.measurementType);
				const selectedDeviceIds = measurement.devices || [];
				foundMeasurement.devices.forEach(device => {
					if (selectedDeviceIds.includes(device.id)) {
						selectedDevices.push({ measurementType: measurement.measurementType, deviceId: device.id });
					}
				});
			}
			return selectedDevices;
		}, []);

	const handleOnSubmit = async values => {
		setError(null);
		const combinedArr = values.doctors.map((member, index) => {
			return { ...member, ...values.specialties[index] };
		});
		const careTeam = [
			...combinedArr.map(item => ({ userId: item.value, careMemberType: MemberType.DOCTOR })),
			...values.nurses.map(item => ({ userId: item.value, careMemberType: MemberType.NURSE })),
		];
		const updatedValues = {
			...values,
			devices: getDevices(values),
			careTeam,
		};
		const response = selectedProgram
			? await updateRpmProgram({
					id: selectedProgram.id,
					name: updatedValues.rpmProgramName,
					devices: updatedValues.devices,
					questions: updatedValues.questions.map(item => ({ ...item, answerType: AnswerType.MULTIPLE_CHOICE })),
					careTeam: updatedValues.careTeam,
			  })
			: await createRpmProgram({
					name: updatedValues.rpmProgramName,
					devices: updatedValues.devices,
					questions: updatedValues.questions.map(item => ({ ...item, answerType: AnswerType.MULTIPLE_CHOICE })),
					careTeam: updatedValues.careTeam,
			  });
		if (response.error) {
			const errorMessage =
				response.error.response.status === HttpStatusCodes.CONFLICT
					? intl.formatMessage({ id: 'programExists' })
					: response.error.message;
			setError(errorMessage);
		} else {
			setIsAddModalOpen(false);
			window.history.back();
		}
	};

	const handleChangeTab = (tab, values) => {
		if (values.rpmProgramName) {
			setCurrentTab(tab);
		}
	};

	useEffect(() => {
		if (currentTab === tabs.CARE_TEAM) {
			const getSpecialtiesList = async () => {
				const response = await getSpecialties();
				if (response.error) {
					setError(response.error.message);
					return;
				}
				setSpecialties(response.specialties);
			};
			getSpecialtiesList();
		}
	}, [currentTab, tabs.CARE_TEAM]);

	return (
		<MainLayout>
			{(selectedProgram || isAddModalOpen) && (
				<Formik initialValues={getInitialValues()} validationSchema={getValidationSchema} onSubmit={handleOnSubmit}>
					{({ values, errors, setFieldValue, handleChange, handleBlur, handleSubmit }) => {
						if (Object.keys(errors).length > 0) {
							if (errors.rpmProgramName || errors.measurements) {
								setCurrentTab(tabs.RPM);
							}
						}
						return (
							<>
								<Tabs activeIndex={currentTab} onChange={index => handleChangeTab(index, values)}>
									<TabList className='equal-children doctor-wrapper-tabs full-width'>
										{RpmTabsList.map(tab => (
											<Tab
												className={classNames(currentTab > tab.id ? 'tab-past' : '', currentTab < tab.id ? 'tab-next' : '')}
												key={tab.id}>
												{currentTab === tab.id && <img src={tab.activeImg} alt='icon' />}
												{currentTab > tab.id && <img src={tab.pastImg} alt='icon' />}
												{currentTab < tab.id && <img src={tab.mainImg} alt='icon' />}
												{tab.title}
											</Tab>
										))}
									</TabList>
									<TabPanels>
										<TabPanel>
											<Grid columns='1fr' stretch='100%'>
												<div className='add-edit-rpm-program-wrapper'>
													<div className='rpm-program-info'>
														<p className='rpm-program-title semi-bold'>
															{translate(selectedProgram ? 'editingProgram' : 'creatingProgram')}
														</p>
														<p className='no-margin font-14 semi-bold'>{translate('programName')}</p>
														<input
															type='text'
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.rpmProgramName}
															name='rpmProgramName'
															placeholder={intl.formatMessage({ id: 'programName' })}
														/>
														<div>
															<span className='red-error'>{errors.rpmProgramName}</span>
														</div>
													</div>
													<div>
														<p className='rpm-program-title semi-bold'>{translate('measurementsSelection')}</p>
														<p className='rpm-description'>{translate('pickMeasurements')}</p>
														{values.measurements?.some(item => item?.isSelected) && (
															<p className='semi-bold'>{translate('selectedMeasurements')}</p>
														)}
														<FieldArray name='measurements'>
															{measurementsArrHelper => (
																<div className='flex flex-wrap'>
																	{values.measurements.map((measurement, index) => {
																		const foundMeasurement = rpmMeasurements.find(
																			item => item.type === measurement.measurementType
																		);
																		if (foundMeasurement) {
																			return (
																				<div key={foundMeasurement.type} className='rpm-measurement-details rpm-device-details'>
																					<div className='flex flex-align-center'>
																						<img src={foundMeasurement.activeImg} className='active' alt='ico' />
																						<p className='no-margin no-padding'>{translate(`${foundMeasurement.name}`)}</p>
																					</div>
																					<div
																						className='checkbox-wrapper measurement-checkbox'
																						onClick={() => measurementsArrHelper.remove(index)}>
																						<input
																							type='checkbox'
																							name={`values.measurements[${index}].isSelected`}
																							checked={values.measurements[index]?.isSelected}
																							onChange={handleChange}
																						/>
																						<span className='checkmark' />
																					</div>
																					{foundMeasurement.devices.map((device, devicesIndex) => (
																						<div
																							key={device.id}
																							className='flex flex-align-center'
																							onClick={() => {
																								const selectedDeviceIds = [...measurement.devices];
																								if (!selectedDeviceIds.includes(device.id)) {
																									measurement.devices.push(device.id);
																								} else {
																									const foundIndex = selectedDeviceIds.indexOf(device.id);
																									if (foundIndex !== -1) {
																										measurement.devices.splice(foundIndex, 1);
																									}
																								}
																								setFieldValue(`measurements[${index}].devices`, measurement.devices);
																							}}>
																							<div className='checkbox-wrapper devices-checkbox'>
																								<input
																									type='checkbox'
																									name={`values.measurements[${index}].devices[${devicesIndex}]`}
																									value={device.id}
																									checked={measurement.devices.includes(device.id)}
																								/>
																								<span className='checkmark' />
																							</div>
																							<p className='no-margin'>{device.value}</p>
																						</div>
																					))}
																				</div>
																			);
																		}
																		return null;
																	})}
																</div>
															)}
														</FieldArray>

														<p className='semi-bold'>{translate('measurements')}</p>
														<FieldArray name='measurements'>
															{measurementsArrHelper => {
																const filteredArr = rpmMeasurements.filter(allMeasurements =>
																	values.measurements.every(
																		selectedMeasurements => selectedMeasurements.measurementType !== allMeasurements.type
																	)
																);
																return (
																	<div className='flex flex-wrap'>
																		{filteredArr.map((measurement, index) => (
																			<div
																				key={measurement.type}
																				className='rpm-measurement-details'
																				onClick={() =>
																					measurementsArrHelper.push({
																						isSelected: true,
																						measurementType: measurement.type,
																						devices: [],
																					})
																				}>
																				<div className='flex flex-align-center'>
																					<img src={measurement.img} alt='ico' />
																					<p className='no-margin no-padding'>{translate(`${measurement.name}`)}</p>
																				</div>
																				<div className='checkbox-wrapper'>
																					<input
																						type='checkbox'
																						name={`values.measurements[${index}].isSelected`}
																						onChange={handleChange}
																					/>
																					<span className='checkmark' />
																				</div>
																			</div>
																		))}
																	</div>
																);
															}}
														</FieldArray>
														<div>
															<span className='red-error'>{errors.measurements}</span>
														</div>
													</div>
													<div className='flex right-align-content top-15 rpm-modal-buttons'>
														<button type='button' className='rpm-cancel-btn' onClick={() => window.history.back()}>
															<img src={`${healthCareCdnUrl}cancel.svg`} alt='ico' />
															{translate('cancel')}
														</button>
														<button type='submit' className='rpm-next-btn' onClick={() => handleChangeTab(tabs.SURVEY, values)}>
															<img src={`${healthCareCdnUrl}next.svg`} alt='ico' />
															{translate('nextStep')}
														</button>
													</div>
												</div>
											</Grid>
										</TabPanel>
										<Grid columns='1fr' stretch='100%'>
											<div className='add-edit-rpm-program-wrapper'>
												<CreateSurvey
													questions={values.questions}
													handleChange={handleChange}
													errors={errors.questions}
													selectedSurveyType={selectedSurveyType}
													setSelectedSurveyType={setSelectedSurveyType}
													selectedScheduleType={selectedScheduleType}
													setSelectedScheduleType={setSelectedScheduleType}
													setFieldValue={setFieldValue}
												/>
												<div className='flex right-align-content top-30 rpm-modal-buttons'>
													<button type='button' className='rpm-cancel-btn' onClick={() => window.history.back()}>
														<img src={`${healthCareCdnUrl}cancel.svg`} alt='ico' />
														{translate('cancel')}
													</button>
													<button type='submit' className='rpm-next-btn' onClick={() => handleChangeTab(tabs.CARE_TEAM, values)}>
														<img src={`${healthCareCdnUrl}next.svg`} alt='ico' />
														{translate('nextStep')}
													</button>
												</div>
											</div>
										</Grid>
										<Grid columns='1fr' stretch='100%'>
											<div className='add-edit-rpm-program-wrapper'>
												<CareTeamMembers
													doctorValues={values.doctors}
													nurseValues={values.nurses}
													specialtyValues={values.specialties}
													doctorErrors={errors.doctors}
													nurseErrors={errors.nurses}
													setFieldValue={setFieldValue}
													specialties={specialties}
													isAddPatient={false}
													setDoctorValues={() => null}
													setNurseValues={() => null}
													setFamilyMemberValues={() => null}
													programCareTeam={[]}
												/>
												<div className='flex right-align-content top-15 rpm-modal-buttons'>
													<button type='button' className='rpm-cancel-btn' onClick={() => window.history.back()}>
														<img src={`${healthCareCdnUrl}cancel.svg`} alt='ico' />
														{translate('cancel')}
													</button>
													<button type='submit' className='rpm-next-btn' onClick={handleSubmit}>
														<img src={`${healthCareCdnUrl}next.svg`} alt='ico' />
														{translate('complete')}
													</button>
												</div>
											</div>
										</Grid>
									</TabPanels>
								</Tabs>
								<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
							</>
						);
					}}
				</Formik>
			)}
		</MainLayout>
	);
};

export default AddEditRpmProgram;
